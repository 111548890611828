import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';
import { withFeatures } from 'src/components/Feature';
import { useState } from 'react';
import AudienceUpload from './AudienceUpload';
import AudiencesTable from './AudiencesTable';
import Heading from '../../components/PageElements/Heading';

const pageText = () => ({
  title: t('audiences:title'),
  subTitle: t('audiences:subTitle'),
  errorMessage: t('audiences:error.failedToLoad')
});

const Audiences = props => {
  const {
    features: { audienceUpload }
  } = props;

  const text = pageText();

  /**
   * We want to re-fetch the audiences when a new audience is uploaded.
   * However, the data table the audiences table uses does not have a way to
   * re-fetch the data. So we use a unique key to force the table to re-fetch.
   *
   * The actual data in this key doesn't matter, as long as it's unique.
   */
  const [audienceUniqueKey, setAudienceUniqueKey] = useState(Date.now());

  const onAudienceUploaded = () => {
    setAudienceUniqueKey(Date.now());
  };

  return (
    <div data-cy="audiences-wrapper">
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
        divider
      />
      {audienceUpload && (
        <AudienceUpload onAudienceUploaded={onAudienceUploaded} />
      )}
      <AudiencesTable key={audienceUniqueKey} />
    </div>
  );
};

export default flow(withFeatures, withRouter)(Audiences);
