import { identity } from 'lodash';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ReduxThunk from 'redux-thunk';
import { createHashHistory as createHistory } from 'history';

// eslint-disable-next-line no-underscore-dangle
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ || (() => identity);

// eslint-disable-next-line import/no-mutable-exports
let globalStore = null;

function configureStore(history, reducers = {}, middleware = []) {
  const middlewares = [...middleware, ReduxThunk];

  const enhancers = [applyMiddleware(...middlewares), reduxDevTools()];

  const store = createStore(
    combineReducers({
      form: formReducer,
      ...reducers // external reducers
    }),
    undefined, // initialState
    compose(...enhancers)
  );

  // if (module.hot) {
  //     // Enable Webpack hot module replacement for reducers
  //     module.hot.accept('./reducers', () => {
  //         // eslint-disable-next-line global-require
  //         const nextRootReducer = require('./reducers/index');
  //         store.replaceReducer(nextRootReducer);
  //     });
  // }

  globalStore = store;

  return store;
}

// if on page load the url isn't formatted as a hash url and there are query parameters
// we need to make sure react router aka history are passed the params
// if this seems odd well it is, but it avoids the following:
// evocalize.com/?foo=bar ---> evocalize.com/?foo=bar#/dashboard
// and instead we get:
// evocalize.com/?foo=bar ---> evocalize.com/#/dashboard?foo=bar ᕙ(`▿´)ᕗ
let query = null;
if (!window.location.hash && window.location.search) {
  // remove query string but add it back into the router after it's created.
  query = window.location.search;
  window.history.replaceState(null, '', `/`);
}

const history = createHistory();

if (query) {
  history.replace(`${window.location.pathname}${query}`);
}

export { history, globalStore, configureStore };
