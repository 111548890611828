import { handleActions } from 'redux-actions';
import { get } from 'lodash';

import { constants } from './actions';

const initialState = {
  layersById: {},
  elementsByLayerId: {}
};

export default handleActions(
  {
    [constants.addLayer]: state => {
      const currentLayersById = get(state, 'layersById');
      return {
        ...state,
        layersById: {
          ...currentLayersById,
          [`layer-${Date.now()}`]: {
            elementsById: {}
          }
        }
      };
    },

    [constants.removeLayer]: (state, { payload: { layerId } }) => {
      const { layersById } = state;
      const updatedLayersById = Object.keys(layersById).reduce((acc, key) => {
        if (key !== layerId) {
          acc[key] = layersById[key];
        }

        return acc;
      }, {});

      return {
        ...state,
        layersById: { ...updatedLayersById }
      };
    },

    [constants.restTemplate]: () => {
      return initialState;
    },

    [constants.addElement]: (state, { payload: { layerId, element } }) => {
      const currentLayerElements = get(
        state,
        `layersById[${layerId}].elementsById`
      );

      return {
        ...state,
        layersById: {
          ...state.layersById,
          [layerId]: {
            elementsById: {
              ...currentLayerElements,
              [`element-${Date.now()}`]: {
                layerId,
                ...element
              }
            }
          }
        }
      };
    },

    [constants.removeElement]: (state, { payload: { layerId, elementId } }) => {
      const { layersById } = state;
      const layerElementsById = get(layersById, `[${layerId}].elementsById`);

      const updatedElements = Object.keys(layerElementsById).reduce(
        (acc, key) => {
          if (key !== elementId) {
            acc[key] = layerElementsById[key];
          }

          return acc;
        },
        {}
      );

      return {
        ...state,
        layersById: {
          ...layersById,
          [layerId]: {
            elementsById: updatedElements
          }
        }
      };
    }
  },
  initialState
);
