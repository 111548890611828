import { get } from 'lodash';

import { SvgIcon } from '@mui/material';

import withTheme from '@mui/styles/withTheme';
import { Theme } from '@mui/system';

export interface FacebookProps {
  theme: Theme;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const Facebook = ({ theme, fontSize = 'medium' }: FacebookProps) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <g fill="none" fillRule="evenodd">
        <path
          fill={get(theme, 'palette.blueprintIcons.channel.facebook')}
          d="M18.897 0H1.103C.494 0 0 .494 0 1.103v17.794C0 19.506.494 20 1.103 20h9.587v-7.734H8.086V9.238h2.604V7.01c0-2.582 1.579-3.99 3.882-3.99.777-.001 1.552.038 2.325.118v2.7H15.31c-1.255 0-1.5.593-1.5 1.469v1.927h3l-.39 3.028h-2.627V20h5.104c.609 0 1.103-.494 1.103-1.103V1.103C20 .494 19.506 0 18.897 0z"
        />
        <path
          fill="#FFF"
          d="M14.124 20v-7.809h2.54l.378-3.057h-2.918V7.188c0-.884.238-1.483 1.459-1.483h1.56v-2.73a20.76 20.76 0 0 0-2.265-.118c-2.244 0-3.774 1.42-3.774 4.028v2.25H8.571v3.056h2.533V20h3.02z"
        />
      </g>
    </SvgIcon>
  );
};

export default withTheme<Theme, typeof Facebook>(Facebook);
