import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { getGoogleDisplayImageHeight, getPreviewWidth } from '../../helpers';
import { GOOGLE_PREVIEW_WIDTH, STYLES } from '../GoogleAdPreviewConstants';

const Container = styled('div')(props => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: props.width
}));

const ImageRow = styled('div')(props => ({
  height: props.height,
  overflow: 'hidden',
  width: props.width,
  '& img': {
    width: '100%'
  }
}));

const Headline = styled('div')({
  color: STYLES.color.headline,
  display: 'block',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '17px',
  padding: 1,
  whiteSpace: 'nowrap',
  width: '100%'
});

const Description = styled('div')({
  color: STYLES.color.description,
  display: 'inline-block',
  flexGrow: '2',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: ' 12px',
  fontWeight: ' 300',
  lineHeight: ' 14px',
  padding: '0',
  whiteSpace: 'normal'
});

const OpenButton = styled('div')({
  alignItems: 'center',
  background: 'primary.main',
  borderRadius: '100%',
  color: STYLES.color.headline,
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  width: '40px',
  '& svg': {
    color: 'white'
  }
});

const DisplayAdTwo = props => {
  const {
    mainImage,
    logoImage,
    headline,
    description,
    hasUniformPreviewWidth,
    isResponsive
  } = props;

  const previewWidth = getPreviewWidth(
    hasUniformPreviewWidth,
    GOOGLE_PREVIEW_WIDTH,
    isResponsive
  );

  const imageHeight = getGoogleDisplayImageHeight(
    hasUniformPreviewWidth,
    isResponsive
  );

  return (
    <Container width={previewWidth}>
      <ImageRow width={previewWidth} height={imageHeight}>
        {mainImage}
      </ImageRow>

      <Headline>{headline}</Headline>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 1,
          paddingTop: 0,
          width: '100%'
        }}
      >
        {!!logoImage && (
          <Box
            sx={{
              '& img': {
                marginRight: '12px',
                width: '50px'
              }
            }}
          >
            {logoImage}
          </Box>
        )}

        <Description>
          {description && description.length > 90
            ? `${description.substring(0, 90)} ...`
            : description}
        </Description>

        <Box>
          <OpenButton>
            <ArrowRightIcon />
          </OpenButton>
        </Box>
      </Box>
    </Container>
  );
};

export default DisplayAdTwo;
