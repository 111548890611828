import { Button, Tooltip } from '@mui/material';
import { maybeAddProtocol } from 'src/components/ReduxForm/RenderLinkUrl/helpers';
import WebIcon from '@mui/icons-material/Web';
import { linkUrlPageText } from 'src/components/ReduxForm/RenderLinkUrl/linkUrlPageText';

export interface PreviewUrlButtonProps {
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  previewUrl?: boolean;
  error?: unknown;
}

export const PreviewUrlButton = ({
  disabled,
  readOnly,
  previewUrl,
  error,
  value
}: PreviewUrlButtonProps) => {
  const text = linkUrlPageText();

  if (!previewUrl || error || !value?.trim()) {
    return null;
  }

  return (
    <Tooltip title={text.tipText} aria-label={text.tipText}>
      <div>
        <Button
          href={maybeAddProtocol(value?.trim())}
          target="_blank"
          size="small"
          color="primary"
          startIcon={<WebIcon />}
          disabled={disabled || readOnly}
        >
          {text.previewButton}
        </Button>
      </div>
    </Tooltip>
  );
};
