import { useState, MouseEvent } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { t } from 'i18next';
import { cloneDeep, flow } from 'lodash';
import { useSnackbar } from 'notistack';
import { change } from 'redux-form';
import { connect } from 'react-redux';

import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import LabelIcon from '@mui/icons-material/Label';
import CancelIcon from '@mui/icons-material/Cancel';
import PreviewIcon from '@mui/icons-material/Preview';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

import AutomationContentPreviewTable from 'src/components/ContentTable/AutomationContentPreviewTable';
import IconComponent from 'src/components/IconSelector/IconComponent';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { getRulesAsChips } from 'src/pages/Programs/helpers';
import {
  convertFiltersArrayToJSON,
  convertFilterJSONtoFiltersArray
} from 'src/components/AutomatedProgramFilter/helpers';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { FORM_NAME } from '../constants';

const pageText = () => ({
  deleteConfirmTitle: t('admin:manageQuickstart.modal.confirmDeleteTitle'),
  deleteConfirmBody: t('admin:manageQuickstart.modal.confirmDeleteBody'),
  previewTitle: t('admin:manageQuickstart.previewModal.previewTitle'),
  noResults: t('admin:manageQuickstart.previewModal.noResults')
});

const DRAG_ITEM_TYPE = 'quickAutomationRule';
interface GetMenuItemsProps {
  setEditingIndex: (index: null | number) => void;
  setAnchorEl: any;
  index: number;
  openDeleteConfirm: () => void;
  setDetailModalOpen: () => void;
  openPreview: () => void;
  copyRule: () => Promise<void>;
  pasteRule: () => Promise<void>;
}
const getMenuItems = ({
  setEditingIndex,
  setAnchorEl,
  index,
  openDeleteConfirm,
  setDetailModalOpen,
  openPreview,
  copyRule,
  pasteRule
}: GetMenuItemsProps) => {
  return [
    {
      key: 'rebuildRule',
      label: t('admin:manageQuickstart.ruleMenu.rebuildRule'),
      icon: <BuildIcon />,
      action: () => {
        // set rules to edit
        setEditingIndex(index);
        // close menu
        setAnchorEl(null);
      }
    },
    {
      key: 'editNameIcon',
      label: t('admin:manageQuickstart.ruleMenu.editNameIcon'),
      icon: <LabelIcon />,
      action: () => {
        // set rules to edit
        setEditingIndex(index);
        // open edit modal
        setDetailModalOpen();
        // close menu
        setAnchorEl(null);
      }
    },
    {
      key: 'preview',
      label: t('admin:manageQuickstart.ruleMenu.preview'),
      icon: <PreviewIcon />,
      action: () => {
        openPreview();
        // close menu
        setAnchorEl(null);
      }
    },
    {
      key: 'copy',
      label: t('admin:manageQuickstart.ruleMenu.copy'),
      icon: <CopyAllIcon />,
      action: async () => {
        await copyRule();
        // close menu
        setAnchorEl(null);
      }
    },
    {
      key: 'paste',
      label: t('admin:manageQuickstart.ruleMenu.paste'),
      icon: <ContentPasteIcon />,
      action: async () => {
        await pasteRule();
        // close menu
        setAnchorEl(null);
      }
    },
    {
      key: 'delete',
      label: t('admin:manageQuickstart.ruleMenu.delete'),
      icon: <DeleteIcon />,
      action: () => {
        openDeleteConfirm();
        // close menu
        setAnchorEl(null);
      }
    }
  ];
};

const getFilteredColumnsFromFieldData = (fieldData: { catalogFilter: any }) => {
  return fieldData?.catalogFilter.reduce(
    (accum: string[], filter: { column: string }) => {
      accum.push(filter?.column);
      return accum;
    },
    []
  );
};

interface DraggableRuleProps {
  fields: any;
  field: any;
  fieldData: any;
  index: number;
  setHasMoved: (value: boolean) => void;
  setEditingIndex: (index: null | number) => void;
  deleteRule: (index: number) => void;
  setDetailModalOpen: () => void;
  contentMeta: any[];
  contentFriendlyName: string;
  architectureData: any;
  change: (form: string, field: string, value: any) => void;
}

const DraggableRule = ({
  fields,
  field,
  fieldData,
  index,
  setHasMoved,
  setEditingIndex,
  deleteRule,
  setDetailModalOpen,
  contentMeta,
  contentFriendlyName,
  architectureData,
  change
}: DraggableRuleProps) => {
  const text = pageText();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const openPreview = () => {
    setPreviewModalOpen(true);
  };

  const closePreview = () => {
    setPreviewModalOpen(false);
  };

  const onDropRule = (oldIndex: number, newIndex: number) => {
    // clear selected rule when moving
    setEditingIndex(null);

    // i like to move it move it
    fields.move(oldIndex, newIndex);

    // let parent component know rule has been moved
    setHasMoved(true);
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_ITEM_TYPE,
    item: () => {
      return { field, index };
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAG_ITEM_TYPE,
      drop: (value: any) => {
        // item being dragged index
        const oldIndex = value.index;
        // index of the item being dragged over
        const newIndex = index;
        onDropRule(oldIndex, newIndex);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [index]
  );

  const openDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = () => {
    deleteRule(index);
    setDeleteConfirmOpen(false);
  };

  const { copy } = useCopyToClipboard();
  const copyRule = async () => {
    const rulesToCopy = cloneDeep(fieldData?.catalogFilter);
    const convertedRules = convertFiltersArrayToJSON(rulesToCopy);

    try {
      // needs to be a string to store in the clipboard
      const stringifyRules = JSON.stringify(convertedRules);
      // copy it to the clipboard
      await copy(stringifyRules);

      enqueueSnackbar(t('admin:manageQuickstart.ruleMenu.copySuccess'), {
        variant: 'success'
      });
    } catch (err) {
      enqueueSnackbar(t('admin:manageQuickstart.ruleMenu.copyFail'), {
        variant: 'error'
      });
    }
  };

  const pasteRule = async () => {
    try {
      const rulesToPaste = await navigator.clipboard.readText();
      // unstringify from the clipboard
      const parseRules = JSON.parse(rulesToPaste);
      // convert to array format
      const convertedRules = convertFilterJSONtoFiltersArray(parseRules);
      // update redux form with new rules
      change(
        FORM_NAME,
        `${fields.name}[${index}].catalogFilter`,
        convertedRules
      );

      enqueueSnackbar(t('admin:manageQuickstart.ruleMenu.pasteSuccess'), {
        variant: 'success'
      });
      // show the rules that have been pasted in
      setEditingIndex(index);
    } catch (error) {
      enqueueSnackbar(t('admin:manageQuickstart.ruleMenu.pasteFail'), {
        variant: 'error'
      });
    }
  };

  const menuItems = getMenuItems({
    setEditingIndex,
    setAnchorEl,
    index,
    openDeleteConfirm,
    setDetailModalOpen,
    openPreview,
    copyRule,
    pasteRule
  });

  return (
    <Box
      key={field}
      ref={drop}
      sx={{
        background: isOver
          ? theme => `${theme.palette.primary.main}`
          : 'transparent'
      }}
    >
      <ListItem
        ref={drag}
        key={field}
        divider
        style={{
          opacity: isDragging ? 0.3 : 1
        }}
        secondaryAction={
          <>
            <IconButton aria-label="show menu" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>

            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
              {menuItems.map(item => (
                <MenuItem key={item.key} onClick={item?.action || null}>
                  {item?.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText>{item?.label}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </>
        }
      >
        <ListItemIcon>
          <DragIndicatorIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              {fieldData.icon && (
                <ListItemIcon>
                  <IconComponent icon={fieldData.icon} />
                </ListItemIcon>
              )}
              {fieldData.name}

              <Box sx={{ marginLeft: theme => theme.spacing() }}>
                {getRulesAsChips(
                  fieldData?.catalogFilter,
                  contentMeta,
                  true,
                  contentFriendlyName,
                  false
                )}
              </Box>
            </Box>
          }
        />
      </ListItem>

      <ConfirmationModal
        icon={<CancelIcon />}
        open={deleteConfirmOpen}
        title={text.deleteConfirmTitle}
        onClose={(event: any, reason: any) => {
          if (reason === 'backdropClick') {
            // don't close on backdrop click
            return;
          }
          setDeleteConfirmOpen(false);
        }}
        onConfirm={confirmDelete}
      >
        <Typography>{text.deleteConfirmBody}</Typography>
      </ConfirmationModal>

      <ConfirmationModal
        icon={<PreviewIcon />}
        open={previewModalOpen}
        title={text.previewTitle}
        onClose={(event: any, reason: any) => {
          if (reason === 'backdropClick') {
            // don't close on backdrop click
            return;
          }
          closePreview();
        }}
      >
        <AutomationContentPreviewTable
          architectureId={architectureData?.architecture?.id}
          displayCollapseKey={
            architectureData?.architecture?.catalog?.displayCollapseKey ?? 'id'
          }
          tableFilters={
            convertFiltersArrayToJSON(fieldData?.catalogFilter) || {}
          }
          highlightedColumns={getFilteredColumnsFromFieldData(fieldData)}
        />
      </ConfirmationModal>
    </Box>
  );
};

export default flow(connect(null, { change }))(DraggableRule);
