import { createAction } from 'redux-actions';

export const actionConstants = {
  closeBusinessObjectSelectorModal: createAction(
    'BUSINESS_OBJECT_SELECTOR_CLOSE_MODAL'
  ),
  showBusinessObjectSelectorModal: createAction(
    'BUSINESS_OBJECT_SELECTOR_OPEN_MODAL'
  )
};

export const closeBusinessObjectSelectorModal = () => dispatch => {
  dispatch(actionConstants.closeBusinessObjectSelectorModal());
};

export const showBusinessObjectSelectorModal = () => dispatch => {
  dispatch(actionConstants.showBusinessObjectSelectorModal());
};
