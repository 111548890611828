import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { getGoogleDisplayImageHeight, getPreviewWidth } from '../../helpers';
import { GOOGLE_PREVIEW_WIDTH, STYLES } from '../GoogleAdPreviewConstants';

const Container = styled('div')(props => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: props.width
}));

const ImageRow = styled('div')(props => ({
  height: props.height,
  overflow: 'hidden',
  width: props.width,
  '& img': {
    width: '100%'
  }
}));

const Headline = styled('div')(props => ({
  background: 'black',
  color: 'white',
  display: 'block',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '17px',
  padding: props.theme.spacing(1),
  whiteSpace: 'nowrap',
  width: '100%',
  '& span': {
    color: 'white' // chip text color
  }
}));

const Description = styled('div')({
  color: STYLES.color.description,
  display: 'inline-block',
  flexGrow: '2',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: ' 12px',
  fontWeight: ' 300',
  lineHeight: ' 14px',
  padding: '0',
  whiteSpace: 'normal'
});

const OpenButton = styled('div')({
  alignItems: 'center',
  background: 'black',
  borderRadius: '100%',
  color: STYLES.color.headline,
  display: 'flex',
  height: '40px',
  justifyContent: 'center',
  width: '40px',
  '& svg': {
    color: 'white'
  }
});

const DisplayAdThree = props => {
  const {
    mainImage,
    headline,
    description,
    hasUniformPreviewWidth,
    isResponsive
  } = props;

  const previewWidth = getPreviewWidth(
    hasUniformPreviewWidth,
    GOOGLE_PREVIEW_WIDTH,
    isResponsive
  );

  const imageHeight = getGoogleDisplayImageHeight(
    hasUniformPreviewWidth,
    isResponsive
  );

  return (
    <Container width={previewWidth}>
      <ImageRow width={previewWidth} height={imageHeight}>
        {mainImage}
      </ImageRow>

      <Headline>{headline}</Headline>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 1,
          width: '100%'
        }}
      >
        <Description>{description}</Description>
        <Box>
          <OpenButton>
            <ArrowRightIcon />
          </OpenButton>
        </Box>
      </Box>
    </Container>
  );
};

export default DisplayAdThree;
