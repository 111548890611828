import { flow, isNull } from 'lodash';
import { Trans } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SelectBluePrintIcon from '@mui/icons-material/Directions';

import AdPreview from 'src/components/AdPreview';
import BlueprintSelectorList from '../BlueprintSelector/BlueprintSelectorList';

const styles = theme => ({
  headline: {
    fontSize: '20px',
    margin: `${theme.spacing(2)} 0`,
    textAlign: 'center'
  },
  adPreviewNoData: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
  adPreviewNoDataText: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold'
  },
  adPreviewNoDataIcon: {
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
    transform: 'scaleX(-1)',
    width: theme.spacing(10)
  }
});

const AutomatedProgramChooseBlueprint = props => {
  const {
    architecture,
    blueprints,
    formValues,
    classes,
    selectedBlueprint,
    handleSelectBlueprint
  } = props;

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs: formValues?.dynamicUserInputs ?? {},
    businessObjects: []
  };

  return (
    <Grid container spacing={3} data-cy="bp-step">
      <Grid item xs={12}>
        <Typography className={classes.headline} variant="h3">
          <Trans i18nKey="automatedProgramFilter:wizard.blueprintSelectionHeading">
            Choose a blueprint to determine the platform, targeting & layout
          </Trans>
        </Typography>
      </Grid>
      <Grid item sm={6}>
        <BlueprintSelectorList
          architectureName={architecture?.name}
          blueprints={blueprints}
          selectedBlueprint={selectedBlueprint}
          handleSelectBlueprint={handleSelectBlueprint}
        />
      </Grid>
      <Grid item sm={6} data-cy="ad-preview">
        {isNull(selectedBlueprint) ? (
          <div className={classes.adPreviewNoData}>
            <SelectBluePrintIcon className={classes.adPreviewNoDataIcon} />
            <Typography className={classes.adPreviewNoDataText} variant="h3">
              <Trans i18nKey="automatedProgramFilter:wizard.adPreviewNoData">
                Please select a blueprint to see previews
              </Trans>
            </Typography>
          </div>
        ) : (
          <AdPreview
            architecture={architecture}
            previewData={previewData}
            isAutomatedProgram
            showMessaging={false}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default flow(withStyles(styles))(AutomatedProgramChooseBlueprint);
