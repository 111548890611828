import { useEffect, useState } from 'react';
import { flow, get, isEmpty, reduce, isString } from 'lodash';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import { touch, stopSubmit, isSubmitting, getFormSyncErrors } from 'redux-form';

import { Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Loading from 'src/components/Loading';

import { BLUEPRINT_BUILDER_FORM_NAME } from '../Constants';
import { selectExactStep, selectPreviousStep } from '../actions';

const styles = theme => ({
  container: {
    marginTop: theme.spacing(3)
  },
  button: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
});

let errorSnackbarKey;

const BlueprintBuilderStepFooter = ({
  classes,
  selectExactStep,
  selectPreviousStep,
  selectedStep,
  steps,
  submitting = false,
  isSubmitting,
  formSyncErrors,
  disabled = false,
  touch,
  stopSubmit,
  closeSnackbar,
  handleNextWithValidation,
  loading
}) => {
  const isFirstStep = selectedStep === 0;
  const isLastStep = Object.keys(steps).length - 1 === selectedStep;
  const [lockSubmit, setLockSubmit] = useState(false);
  const formStepErrors = formSyncErrors;
  const { enqueueSnackbar } = useSnackbar();

  const handleNext = currentStepWhenFired => {
    if (isEmpty(formStepErrors)) {
      // If submitting is locked, stop here immediately. This will prevent
      // the race condition of the user clicking the next button twice.
      // When that occurs this component is rendered again with _new_
      // props (i.e. a new selectedStep) and tries to fire again. Checking
      // the lock in state prevents that from occurring.
      if (lockSubmit) {
        return;
      }

      // If we got here, then this is the only or first click. Lock
      // submitting to prevent multi-fire race conditions.
      setLockSubmit(true);

      // Set the next step to currentStepWhenFired + 1 (i.e. the next step).
      selectExactStep(currentStepWhenFired + 1);

      // Add a little more buffer - similar to a debounce on a button -
      // before we unlock submitting.
      window.setTimeout(() => {
        setLockSubmit(false);
      }, 250);

      return;
    }

    if (!errorSnackbarKey) {
      errorSnackbarKey = enqueueSnackbar('Fix form errors to continue', {
        variant: 'error'
      });
    }

    // flatten our error object keys into an array like:
    // { subform: { field: 'error' }, field2 } => ['subform.field', 'field2']
    const mapper = (deepObject, parent) =>
      reduce(
        deepObject,
        (arr, value, key) => {
          if (!isString(value)) {
            return [
              ...arr,
              ...mapper(value, parent ? `${parent}.${key}` : key)
            ];
          }

          return [...arr, parent ? `${parent}.${key}` : key];
        },
        []
      );

    // mark all fields with errors as "touched" so we show the errors
    touch(BLUEPRINT_BUILDER_FORM_NAME, ...mapper(formStepErrors));
    stopSubmit(BLUEPRINT_BUILDER_FORM_NAME, formStepErrors);

    // If we got here, unlock submit as well.
    setLockSubmit(false);
  };

  useEffect(() => {
    // remove validation snackbar when erros resolve
    if (errorSnackbarKey && isEmpty(formStepErrors)) {
      closeSnackbar(errorSnackbarKey);
      errorSnackbarKey = null;
    }
  });

  return (
    <div className={classes.container}>
      <Button
        disabled={isFirstStep}
        onClick={() => selectPreviousStep()}
        className={classes.button}
        data-cy="step-footer-back"
      >
        Back
      </Button>
      {!isLastStep && (
        <Button
          disabled={lockSubmit || disabled || submitting || loading}
          variant="contained"
          color="primary"
          onClick={() => {
            return handleNextWithValidation
              ? handleNextWithValidation(handleNext, selectedStep)
              : handleNext(selectedStep);
          }}
          className={classes.button}
          data-cy="step-footer-next"
        >
          Next
        </Button>
      )}
      {isLastStep && (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={disabled || isSubmitting}
          data-cy="step-footer-save"
        >
          Save
        </Button>
      )}
      {(loading || isSubmitting || submitting) && <Loading inline size={20} />}
    </div>
  );
};

export default flow(
  connect(
    state => ({
      selectedStep: get(state, 'blueprintBuilder.selectedStep'),
      steps: get(state, 'blueprintBuilder.steps'),
      isSubmitting: isSubmitting(BLUEPRINT_BUILDER_FORM_NAME)(state),
      formSyncErrors: getFormSyncErrors(BLUEPRINT_BUILDER_FORM_NAME)(state)
    }),
    {
      selectExactStep,
      selectPreviousStep,
      touch,
      stopSubmit
    }
  ),
  withStyles(styles)
)(BlueprintBuilderStepFooter);
