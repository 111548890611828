export const EDIT_PROGRAM_FORM_NAME = 'editProgram';

export const mostRecentBudgetAdjustmentOutcomes = {
  success: 'success',
  pending: 'pending',
  error: 'error'
};

export const programHistoryChangeTypes = {
  adChanged: 'Ad Changed',
  cancelled: 'Canceled',
  fulfilled: 'Order Fulfilled',
  orderPlaced: 'Order Placed',
  scheduleChange: 'Schedule Change',
  priceChange: 'Price Change',
  unknown: '-'
};

export const CANCELLATION_TYPES = {
  immediate: 'immediate',
  deferred: 'deferred'
} as const;

export type CancellationType =
  (typeof CANCELLATION_TYPES)[keyof typeof CANCELLATION_TYPES];

export const ORDER_PROCESSING_STATUS = {
  pending: 'pending',
  fulfilled: 'fulfilled',
  error: 'error',
  cancelled: 'cancelled',
  billingFailure: 'billing_failure',
  cancelling: 'cancelling'
} as const;

export type OrderProcessingStatus =
  (typeof ORDER_PROCESSING_STATUS)[keyof typeof ORDER_PROCESSING_STATUS];

export const SUBSCRIPTION_TIER_CHANGE_METHODS = {
  scheduled: 'scheduled',
  immediate: 'immediate',
  disabled: 'disabled'
};
