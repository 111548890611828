import { SyntheticEvent, useMemo, useState } from 'react';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@mui/material';
import {
  AiErrorText,
  AiLoadingSpinner,
  aiLoadingSpinnerSize
} from 'src/components/AiSuggestion/AiSuggestionStyles';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';
import { stylizeTextWithAi } from 'src/components/AiSuggestion/queries';
import Instrumentation from 'src/instrumentation';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { Events } from 'src/instrumentation/constants';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  selectableStyles,
  SelectableStyle,
  getAiStylizeText
} from 'src/common/aiAdCopySuggestion';

const dropdownButtonId = 'ai-text-stylization-dropdown';
const menuId = 'ai-text-stylization-menu';

export interface AiTextStylizationButtonProps {
  onAiStylizationReceived: (text: string) => void;
  blueprintVariableId: string;
  inputValue: string;
  productId: string;
}

export const AiTextStylizationButton = ({
  onAiStylizationReceived,
  blueprintVariableId,
  inputValue,
  productId
}: AiTextStylizationButtonProps) => {
  const text = useMemo(() => getAiStylizeText(), []);
  const [loadingIndex, setLoadingIndex] = useState<number | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);
  const [menuAnchorEle, setMenuAnchorEle] = useState<Element | null>(null);
  const stylizeTextWithAiQuery = useInvokableQuery(stylizeTextWithAi);
  const architecture = useArchitecture();

  const handleDropdownButtonClick = (event: SyntheticEvent) => {
    setMenuAnchorEle(event.currentTarget);
  };

  const handleMenuItemClick = async (style: SelectableStyle, index: number) => {
    setLoadingIndex(index);
    setErrorText(null);

    let didSucceed = false;
    try {
      const stylizationResult = await stylizeTextWithAiQuery({
        inputs: [
          {
            sourceBlueprintVariable: blueprintVariableId,
            sourceText: inputValue,
            style: selectableStyles[style]
          }
        ]
      });

      const { styledText } = stylizationResult.data.stylizeWithAi[0];
      onAiStylizationReceived(styledText);

      didSucceed = true;
    } catch (e) {
      setErrorText(text.errorMessage);
    } finally {
      // Make sure that no matter what we do, error or otherwise, we
      // always reset our variables around loading
      setLoadingIndex(null);

      // Also make sure we log our interaction.
      Instrumentation.logEvent(Events.ClickAiStylize, {
        success: didSucceed,
        style,
        architectureId: architecture.id,
        productId
      });
    }
  };

  const open = Boolean(menuAnchorEle);

  return (
    <div>
      <Button
        disabled={inputValue == null || inputValue.length === 0}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        id={dropdownButtonId}
        onClick={handleDropdownButtonClick}
        endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        {text.styleDropdown}
      </Button>
      <Menu
        id={menuId}
        anchorEl={menuAnchorEle}
        open={open}
        onClose={() => setMenuAnchorEle(null)}
        MenuListProps={
          {
            'aria-labelledby': { dropdownButtonId }
          } as any
        }
      >
        {Object.keys(selectableStyles).map((style, index) => {
          return (
            <MenuItem
              key={style}
              value={style}
              onClick={
                (() =>
                  handleMenuItemClick(
                    style as SelectableStyle,
                    index
                  )) as () => void
              }
              disabled={loadingIndex != null}
            >
              <ListItemText sx={{ marginRight: '12px' }}>
                {text.styleOption[style as SelectableStyle]}
              </ListItemText>
              <ListItemIcon>
                {loadingIndex === index && (
                  <AiLoadingSpinner size={aiLoadingSpinnerSize} />
                )}
              </ListItemIcon>
            </MenuItem>
          );
        })}
      </Menu>
      {errorText && <AiErrorText variant="body1">{errorText}</AiErrorText>}
    </div>
  );
};
