import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { t } from 'i18next';

import withStyles from '@mui/styles/withStyles';
import { Alert, AlertTitle } from '@mui/material';

import WarningIcon from '@mui/icons-material/Warning';

import { RESOURCE_LIB_REGEX, FB_ACCOUNT_REGEX } from './Constants';

const styles = theme => ({
  resourceWarning: {
    marginBottom: theme.spacing(2)
  },
  resourceWarningInline: {
    marginBottom: theme.spacing(2)
  }
});

const pageText = () => ({
  resourceLibraryWarning: t('admin:blueprintBuilder.warnings.resourceLibrary'),
  resourceLibraryFacebookWarning: t(
    'admin:blueprintBuilder.warnings.resourceLibraryFacebookAccount'
  ),
  example: t('admin:blueprintBuilder.warnings.example')
});

const BlueprintBuilderWarnings = props => {
  const { classes, formValues } = props;

  const text = useMemo(() => pageText(), []);

  const stringFormValues = JSON.stringify(formValues || '');

  const facebookAccountMatches = stringFormValues.match(FB_ACCOUNT_REGEX) || [];
  const hasFacebookAccounts = !isEmpty(facebookAccountMatches);

  const resourceLibraryVariableMatches =
    stringFormValues.match(RESOURCE_LIB_REGEX) || [];

  return (
    <div>
      {(isEmpty(resourceLibraryVariableMatches) || hasFacebookAccounts) && (
        <Alert
          severity="warning"
          icon={<WarningIcon />}
          className={classes.resourceWarning}
        >
          <AlertTitle>
            {hasFacebookAccounts
              ? text.resourceLibraryFacebookWarning
              : text.resourceLibraryWarning}
          </AlertTitle>
          <br />
          {text.example}:
          <ul>
            <li>
              <code>{`{{ RESOURCE_ITEM 'collection type' 'collection key' 'item key' }}`}</code>
            </li>
          </ul>
        </Alert>
      )}
    </div>
  );
};

export default withStyles(styles)(BlueprintBuilderWarnings);
