import { t } from 'i18next';
import { UrlValidationResultCode } from 'src/generated/gql/graphql';

export const linkUrlPageText = () => ({
  tipText: t('program:previewLink.help'),
  previewButton: t('program:previewLink.button'),
  validationErrors: {
    [UrlValidationResultCode.FailedToLoad]: t(
      'program:previewLink.validationError.failure'
    ),
    [UrlValidationResultCode.NotFound]: t(
      'program:previewLink.validationError.notFound'
    ),
    // This is not a mistake, we are using the same error message from our common validation messages
    [UrlValidationResultCode.CrossDomainRedirect]: t(
      'validations:EV_NO_CROSS_DOMAIN_REDIRECTS'
    )
  }
});
