export const OPERATOR_TYPES = [
  { displayName: 'Equals', value: 'eq' },
  //  we are going to add this back in later
  //  { displayName: 'Does not equal', value: 'noteq' },
  { displayName: 'Contains', value: 'contains' },
  { displayName: 'Does Not Contain', value: 'notcontains' },
  { displayName: 'Greater Than', value: 'gt' },
  { displayName: 'Less Than', value: 'lt' },
  // spacial cases for dates...
  // not implimented but here for reference (these exist on backend)
  // comparing exact dates don't really make sense
  // { displayName: 'After', value: 'after' },
  // { displayName: 'Before', value: 'before' },
  // { displayName: 'Exactly', value: 'daysExactly' },
  // we might add these at some point
  // { displayName: 'Plus', value: 'daysAfterNowEq' },
  // { displayName: 'Minus', value: 'daysBeforeNowEq' },
  { displayName: 'within the next', value: 'daysAfterNowRange' },
  { displayName: 'within the last', value: 'daysBeforeNowRange' },
  // special cases boolean (no value)
  { displayName: 'Is True', value: 'is_true' },
  { displayName: 'Is False', value: 'is_false' },
  // special cases multiple values
  { displayName: 'Between', value: 'range' }, // range of numbers 'AND'
  { displayName: 'Between', value: 'between' }, // range of dates (actual comparator)
  { displayName: 'One of', value: 'in' }
] as const;

export type OperatorType = (typeof OPERATOR_TYPES)[number]['value'];
type ValueType<T extends OperatorType> = T extends 'between'
  ? { gt: string; lt: string }
  : string;

export type AutomatedProgramFilterRule<T extends OperatorType = OperatorType> =
  {
    operator: T;
    value: ValueType<T>;
  };

export type AutomatedProgramFilterType = {
  column: string;
  rules: AutomatedProgramFilterRule[];
};

export const NOT_OPERATOR_PREFIX = 'not';

export const OPERATOR_VALUES = OPERATOR_TYPES.reduce<{
  [key in OperatorType]: OperatorType;
}>((values, { value }) => ({ ...values, [value]: value }), {} as any);

export const CONTENT_COL_TYPES = {
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  BOOLEAN: 'BOOLEAN',
  DATE_TIME: 'DATE_TIME'
};

// A local storage bool that will skip step one of the wizard if set to true
export const AUTOMATED_PROGRAMS_WIZARD_DISMISS_HELP =
  'evAutomatedProgramWizardDismissHelp';

export const AUTOMATED_PROGRAM_SECTION_NAME = 'automatedPrograms';
