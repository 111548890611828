import { isArray, map, startCase } from 'lodash';
import { Box, Typography } from '@mui/material';
import { t } from 'i18next';

export type ChannelValidationErrors = Record<string, any>;

interface FooterValidationErrorsProps {
  channelValidationErrors: ChannelValidationErrors | null;
}

const pageText = () => ({
  validationErrorDescription: t('program:footerValidationErrors.description')
});

const NestedErrorMessage = ({ errorData, validationKey }: any) => {
  if (isArray(errorData) || errorData?.errors) {
    const errors = isArray(errorData) ? errorData : errorData.errors;

    return (
      <Box data-cy="footer-validation-container">
        <Typography
          component="h2"
          variant="body1"
          data-cy={`footer-validation-title-${validationKey}`}
          sx={{ fontWeight: '600' }}
        >{`${startCase(validationKey)}: `}</Typography>
        {map(errors, errorMessage => (
          <Typography data-cy={`footer-validation-error-${validationKey}`}>
            {errorMessage}
          </Typography>
        ))}
      </Box>
    );
  }

  return (
    <Box data-cy="footer-validation-title-container">
      <Typography
        component="h2"
        variant="body1"
        data-cy={`footer-validation-title-${validationKey}`}
        sx={{ fontWeight: '600' }}
      >{`${startCase(validationKey)}: `}</Typography>
      {map(errorData, (nextErrorData, nextValidationKey) => {
        return (
          <NestedErrorMessage
            errorData={nextErrorData}
            validationKey={nextValidationKey}
          />
        );
      })}
    </Box>
  );
};

const FooterValidationErrors = ({
  channelValidationErrors
}: FooterValidationErrorsProps) => {
  const text = pageText();

  return (
    <>
      {channelValidationErrors && (
        <Box sx={{ py: 2, color: 'error.main' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography data-cy="footer-validation-description">
              {text.validationErrorDescription}
            </Typography>
            {map(channelValidationErrors, (errorData, validationKey) => {
              return (
                <NestedErrorMessage
                  errorData={errorData}
                  validationKey={validationKey}
                />
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default FooterValidationErrors;
