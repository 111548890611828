import { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import { Typography, Grid, Link, Tooltip, Box } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/system';

import Instrumentation from 'src/instrumentation';
import HookForm from 'src/components/ReduxForm/HookForm';
import {
  getTemplateData,
  getOneOfEachChannelKeys
} from 'src/common/blueprints';
import { demoUserIds } from 'src/common/demoUser/demoUsers';
import { demoUserData } from 'src/common/demoUser/demoUserData';
import { hasPermissions, PERMISSIONS } from 'src/common/permissions';
import { isOrderItemLeadAd } from 'src/common/objective';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { useInterval } from 'src/hooks/useInterval';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { useAppSettings } from 'src/AppSettings';
import { useFeatures } from 'src/components/Feature';
import PlaceIcon from '@mui/icons-material/Place';

import LeadsTable from 'src/pages/Leads/LeadsTable';
import { QuickAutomation as AutomationIcon } from 'src/components/Icons';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Kpi from 'src/components/Kpi';
import Loading from 'src/components/Loading';
import PageTitle from 'src/components/PageTitle';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';

import { getArchitectureById } from './queries';
import useGetOrder from './useGetOrder';
import EditProgram from './EditProgram/EditProgram';
import CancelProgramButton from './CancelProgram/CancelProgramButton';
import CloneProgram from './CloneProgram';
import AdsList from './AdsList';
import ProgramDetails from './ProgramDetails';
import OrderHistoryTable from './OrderHistoryTable';
import LandingPagePreview from './LandingPagePreview';

import {
  getDemoInsights,
  getKpiMetadata,
  getDisableProgramEdit,
  getProgramStatuses,
  getDisableProgramCancel,
  editProgramInitValuesFromOrder
} from './helpers';
import { mostRecentBudgetAdjustmentOutcomes } from './Constants';

const POLLING_DELAY = 5000;

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const pageText = ({ orderId }) => {
  return {
    apiError: t('programPerf:error.apiError'),
    notFound: t('programPerf:messages.notFound', {
      orderId
    }),
    noPermission: t('programPerf:messages.cannotViewNoPermission', {
      orderId
    }),
    pageTitle: t('programPerf:page.title'),
    programs: t('programPerf:header.programs'),
    updatingBudgetSchedule: t('programPerf:status.updatingBudgetSchedule'),
    running: t('programPerf:status.running'),
    contacts: t('programPerf:headers.leadsTitle'),
    supervisorName: t('programPerf:supervisor.name'),
    belongsToMLP: t('programPerf:headers.belongsToMLP')
  };
};

const ProgramPerformance = () => {
  const { orderId, architectureId } = useParams();

  const architectureResult = useQuery(getArchitectureById, {
    fetchPolicy: 'no-cache',
    variables: {
      architectureId
    }
  });

  const architecture = architectureResult?.data?.architecture;
  const architectureMeta = {
    loading: architectureResult?.loading,
    error: architectureResult?.error
  };

  const text = pageText({ orderId });

  const {
    orderResult: { loading, data, error, refetch }
  } = useGetOrder({ orderId });
  const order = data?.order;

  const isProgramActive = order?.isProgramActive;
  const rebelIqAssetUrl = order?.rebelIqAsset?.url;
  const isRebelIqBlueprint = order?.product?.isRebelIqEnabled;
  const isRebelIqAssetActive = order?.rebelIqAsset?.isRebelIqSubscriptionActive;
  const globalContext = useGlobalContext();
  const userId = globalContext?.me?.id;
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;
  const [hasLoadedPage, setHasLoadedPage] = useState(false);
  const [pollForUpdates, setPollForUpdates] = useState(false);

  const appSettings = useAppSettings();
  const { contacts } = useFeatures();

  useMemo(() => {
    if (order && !hasLoadedPage) {
      Instrumentation.logEvent(Instrumentation.Events.ViewOrderClicked, {
        orderId: order.orderItem?.id,
        architectureId: order.architectureId,
        productId: order.orderItem?.product?.id,
        paymentAmount: order.billingDetails?.amount,
        channel: getOneOfEachChannelKeys(order.channels?.[0]?.channels)
      });

      setHasLoadedPage(true);
    }
  }, [hasLoadedPage, order]);

  const program = order;
  const blueprint = program?.orderItem?.product?.blueprint;
  const promoCode = program?.billingDetails?.promoCode;
  const programName = program?.name;

  const { isExecutionRunning, isCancelling, isPending, isSubscription } =
    getProgramStatuses(program);

  // we want to poll for updates if the program is pending or cancelling
  useEffect(() => {
    if (isPending || isCancelling) {
      return setPollForUpdates(true);
    }

    setPollForUpdates(false);
  }, [isCancelling, isPending]);

  // poll for completion of cancellation
  useInterval(refetch, pollForUpdates ? POLLING_DELAY : null);

  if (loading || architectureMeta.loading || error || architectureMeta.error) {
    let errorMessage = null;

    if (error || architectureMeta.error) {
      if (
        error?.graphQLErrors?.[0]?.extensions?.errorName ===
        'LithiumUnauthorizedException'
      ) {
        errorMessage = text.noPermission;
      } else {
        errorMessage = text.apiError;
      }
    }

    return <Loading error={errorMessage} errorMessage={errorMessage} />;
  }

  const orderPermissions = order?.accessControl?.permissions || [];

  const hasWritePermission = hasPermissions({
    permissions: [PERMISSIONS.write],
    contentPermissions: orderPermissions
  });

  const hasDeletePermission = hasPermissions({
    permissions: [PERMISSIONS.delete],
    contentPermissions: orderPermissions
  });

  const hasReadPermission = hasPermissions({
    permissions: [PERMISSIONS.read],
    contentPermissions: orderPermissions
  });

  const hasOwnerPermission = hasPermissions({
    permissions: [PERMISSIONS.owner],
    contentPermissions: orderPermissions
  });

  const hideSpend =
    program?.belongsToMultiLocationProgram && !hasOwnerPermission;

  // Handle the case for when we don't find a program.
  if (!program) {
    return (
      <ErrorMessage
        sx={{
          margin: '0 auto'
        }}
      >
        {text.notFound}
      </ErrorMessage>
    );
  }

  // Handle the case for the user doesn't have permission to view the program.
  if (isTeamsEnabled && !hasReadPermission) {
    return (
      <ErrorMessage
        sx={{
          margin: '0 auto'
        }}
      >
        {text.noPermission}
      </ErrorMessage>
    );
  }

  // we must nest blueprint in an object to work with getTemplateData
  const templateData = getTemplateData({
    blueprint
  });
  const channel = templateData?.channel;

  const isLeadAd = isOrderItemLeadAd(order?.orderItem);
  const kpis = getKpiMetadata(program);

  const programKpis = appSettings?.app?.features?.programKpis;

  const demoInsights = getDemoInsights({
    userId,
    appSettings,
    channel,
    demoUserIds,
    demoUserData
  });

  const mostRecentBudgetAdjustment =
    program?.billingDetails?.mostRecentBudgetAdjustment;

  const minimumDurationDays =
    program?.offer?.subscriptionMinimumDurationDays ?? 0;
  const disableEdit = getDisableProgramEdit(program);
  const disableCancel = getDisableProgramCancel(program);

  let subPageTitle = text.pageTitle;
  if (programName) {
    subPageTitle += `: ${programName}`;
  }

  return (
    <>
      <Section>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          rowGap={1}
        >
          <Grid item xs={6}>
            <PageTitle subPageTitle={subPageTitle} />
            <BreadcrumbTrail
              sx={theme => ({ marginBottom: theme.spacing(1) })}
              pieces={[
                {
                  text: text.programs,
                  to: paths.programs.base
                },
                {
                  text: text.pageTitle
                }
              ]}
            />
            {programName && (
              <>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                  {programName}
                </Typography>
                {program?.belongsToMultiLocationProgram && (
                  <Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>
                    <PlaceIcon
                      fontSize="small"
                      sx={{ position: 'relative', top: '3px' }}
                    />{' '}
                    {program?.multiLocationProgram?.id ? (
                      <>
                        {text.belongsToMLP}{' '}
                        <Link
                          component={RouterLink}
                          to={generateLinkPath(
                            paths.architecture.multiLocationProgram,
                            {
                              architectureId,
                              orderId: program?.multiLocationProgram?.id
                            }
                          )}
                        >
                          {program?.multiLocationProgram?.name ||
                            program?.multiLocationProgram?.id}
                        </Link>
                      </>
                    ) : (
                      t('programPerf:headers.createdByMLP')
                    )}
                  </Typography>
                )}
              </>
            )}
            {!isEmpty(program.supervisorOrder) && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AutomationIcon />
                <Typography variant="subtitle1" sx={{ margin: '0 5px' }}>
                  {text.supervisorName}
                </Typography>
                <Link
                  component={RouterLink}
                  to={generateLinkPath(paths.architecture.automatedProgram, {
                    architectureId,
                    programId: program.supervisorOrder.id
                  })}
                >
                  {program.supervisorOrder?.name || program.supervisorOrder?.id}
                </Link>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              justifyContent: 'flex-start'
            }}
          >
            {mostRecentBudgetAdjustment?.outcome ===
              mostRecentBudgetAdjustmentOutcomes.pending && (
              <Tooltip title={text.updatingBudgetSchedule}>
                <div style={{ margin: '5px 0 0 0' }}>
                  <Loading size={20} />
                </div>
              </Tooltip>
            )}
            <HookForm initialValues={editProgramInitValuesFromOrder(program)}>
              <EditProgram
                architecture={architecture}
                order={program}
                refetch={refetch}
                disabled={disableEdit}
                hasWritePermission={!isTeamsEnabled || hasWritePermission}
                contentName={
                  architecture?.catalog?.friendlyName ||
                  t('programCreate:configure.contentDefaultName')
                }
              />
            </HookForm>
            <CloneProgram order={order} />
            <CancelProgramButton
              order={program}
              refetch={refetch}
              disabled={disableCancel}
              minimumDurationDays={minimumDurationDays}
              hasMinimumDuration={
                isSubscription && (minimumDurationDays ?? 0) > 0
              }
              hasDeletePermission={!isTeamsEnabled || hasDeletePermission}
            />
            {isExecutionRunning && (
              <Tooltip title={text.running}>
                <Box
                  component="span"
                  sx={{
                    display: 'inline-block',
                    padding: 0,
                    position: 'relative'
                  }}
                >
                  <HelpIcon />
                </Box>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Section>

      <ProgramDetails
        program={program}
        promoCode={promoCode}
        refetch={refetch}
        hideSpend={hideSpend}
      />

      {programKpis && (
        <Section>
          <Kpi
            demoInsights={demoInsights}
            kpis={kpis}
            programIds={[program?.programId]}
            sourcePage="programDetails"
          />
        </Section>
      )}
      <Section
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          gap: 2,
          flexWrap: 'wrap'
        }}
      >
        <AdsList
          architecture={architecture}
          demoInsights={demoInsights}
          program={program}
        />
        {!isPending &&
          isProgramActive &&
          isRebelIqBlueprint &&
          isRebelIqAssetActive && <LandingPagePreview src={rebelIqAssetUrl} />}
      </Section>
      {contacts && isLeadAd && (
        <Section>
          <Typography variant="h5">{text.contacts}</Typography>
          <LeadsTable
            programId={program?.programId}
            architectureId={architectureId}
          />
        </Section>
      )}
      {isTeamsEnabled && (
        <Section>
          <OrderHistoryTable orderId={orderId} />
        </Section>
      )}
    </>
  );
};

export default ProgramPerformance;
