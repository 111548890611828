import { get } from 'lodash';

const INVALID_TOKEN = 'Received invalid token';

// Since the graphql error object itself doesn't have actual network error
// codes, we need to ahve a list of codes ourselves to match.
const GRAPHQL_AUTH_ERROR_MESSAGES = [
  'jwt expired',
  'permission denied',
  // 'auth',
  // The postgraphile implementation throws a 400 when the token is null.
  // Using a hackish method to check the message to determine that this is
  // actually an authentication error.
  'Authorization header is not',
  'EXPIRED, Message: Provided Token has expired'
];

const GRAPHQL_AUTH_ERROR_NAMES = new Set([
  'LithiumTokenAuthorizationException'
]);

const GRAPHQL_AUTH_ERROR_STATUS_CODES = { 401: true, 403: true, 405: true };

const isAuthError = (gqlErrors, networkError = {}) => {
  if (networkError.statusCode === 401 || networkError.statusCode === 403) {
    return true;
  }

  if (Array.isArray(gqlErrors)) {
    for (let i = 0; i < gqlErrors.length; i++) {
      // Check for network errors. Note: this will only ever get set by
      // a graphql api object. Postgraphile graphql errors do not contain
      // networkError objects (at least not the version used for d2a).
      // This section mostly supports office.
      const gqlNetworkErrorStatusCode = get(
        gqlErrors[i],
        'networkError.statusCode'
      );

      if (GRAPHQL_AUTH_ERROR_STATUS_CODES[gqlNetworkErrorStatusCode]) {
        return true;
      }

      if (gqlErrors[i].message) {
        for (let k = 0; k < GRAPHQL_AUTH_ERROR_MESSAGES.length; k++) {
          if (
            gqlErrors[i].message.indexOf(GRAPHQL_AUTH_ERROR_MESSAGES[k]) !== -1
          ) {
            return true;
          }
        }
      }

      const errorName = get(gqlErrors, `[${i}].extensions.errorName`);

      if (GRAPHQL_AUTH_ERROR_NAMES.has(errorName)) {
        return true;
      }
    }
  }

  // The Helium API throws a 500 when the token is invalid - this should be
  // fixed on the backend's side but I don't have time to wait.
  // Note that the networkError object uses status and not statusCode as the
  // checks above - we absolutely *love* inconsistencies.
  if (
    networkError.status === 500 &&
    networkError.message &&
    networkError.message.indexOf(INVALID_TOKEN) !== -1
  ) {
    return true;
  }

  if (
    networkError.data &&
    networkError.data.message &&
    networkError.data.message.indexOf(INVALID_TOKEN) !== -1
  ) {
    return true;
  }

  return false;
};

export default isAuthError;
