import { startCase } from 'lodash';
import { EXPERIMENT_NAMES, ExperimentNameKey } from 'src/experiments/constants';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const experimentsInputs = (
  Object.keys(EXPERIMENT_NAMES) as unknown as ExperimentNameKey[]
).map(experiment => ({
  name: EXPERIMENT_NAMES[experiment],
  displayName: startCase(EXPERIMENT_NAMES[experiment]),
  displayMethodId: INPUT_TYPES.BOOLEAN,
  initialValue: '',
  reduxValidations: [],
  isRequired: false,
  isHidden: false,
  displayParameters: {
    inputData: {
      columnWidth: 4
    }
  }
}));

export const FORM_NAME = 'experiments';
export const EXPERIMENTS_OVERRIDE_LOCAL_STORAGE = 'evExperimentOverride';
