import { gql } from 'src/generated/gql';

export const createContentDocumentVersion = gql(`
  mutation createContentDocumentVersion($contentTable: String!) {
    createContentDocumentVersion(contentTable: $contentTable) {
      id
    }
  }
`);

export const updateContentDocumentVersion = gql(`
  mutation updateContentDocumentVersion(
    $id: ID!
    $document: ContentDocumentInput!
  ) {
    updateContentDocumentVersion(id: $id, document: $document) {
      id
      versionTimestamp
      status
    }
  }
`);

export const submitContentDocumentVersion = gql(`
  mutation submitContentDocumentVersion($id: ID!) {
    submitContentDocumentVersion(id: $id) {
      id
      versionTimestamp
      status
    }
  }
`);

export const newContentDocument = gql(`
  mutation newContentDocument($document: ContentDocumentInput!) {
    newContentDocument(document: $document) {
      id
    }
  }
`);
