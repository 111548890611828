import { gql } from 'src/generated/gql';

export const placeOrder = gql(`
  mutation OrderPlacement($input: CreateOrderInput!) {
    orderPlacement(input: $input) {
      id
      userId
      architectureId
      paymentMethodId
      groupId
      programId
    }
  }
`);

export const createSupervisorOrder = gql(`
  mutation CreateSupervisorOrder($input: CreateSupervisorInput!) {
    createSupervisorOrder(input: $input) {
      id
      userId
      promoCode
    }
  }
`);

export const updateSupervisorOrder = gql(`
  mutation UpdateSupervisorOrder($input: UpdateSupervisorInput!) {
    updateSupervisorOrder(input: $input) {
      id
      userId
      promoCode
    }
  }
`);

export const placeMultiLocationProgram = gql(`
  mutation PlaceMultiLocationProgram($input: PlaceMultiLocationProgramInput!) {
    placeMultiLocationProgram(input: $input) {
      id
      architectureId
      userId
      groupId
      summedPriceAmount
    }
  }
`);

export const saveProgramDraft = gql(`
  mutation SaveProgramDraft($input: SaveProgramDraftInput!) {
    saveProgramDraft(input: $input) {
      id
    }
  }`);

export const saveMultiLocationProgramDraft = gql(`
  mutation SaveMultiLocationProgramDraft($input: SaveMultiLocationProgramDraftInput!) {
    saveMultiLocationProgramDraft(input: $input) {
      id
    }
  }`);

export const saveAutomationDraft = gql(`
  mutation SaveAutomationDraft($input: SaveAutomationDraftInput!) {
    saveAutomationDraft(input: $input) {
      id
    }
  }
`);
