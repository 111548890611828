import { t } from 'i18next';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import {
  validateRequired,
  validateUrlHasHttps,
  exactArrayValue
} from 'src/common/validations';

export const CREATE_FORM_NAME = 'createWebhook';
export const UPDATE_FORM_NAME = 'updateWebhook';
export const TEST_FORM_NAME = 'testWebhook';

const exactlyOne = exactArrayValue(1);

export const getInputs = isUpdate => {
  return [
    {
      name: 'url',
      displayName: t(
        'admin:webhookSettings.inputs.webhookUrl.displayName',
        'Webhook Url'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateRequired, validateUrlHasHttps()],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 4
        }
      }
    },
    {
      name: 'providers',
      displayName: t(
        'admin:webhookSettings.inputs.customHeaders.displayName',
        'Custom Headers'
      ),
      displayMethodId: null, // not needed for the multi input group
      initialValue: [],
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      isMultiInput: true,
      displaySortOrder: 2,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          groupInputs: (isNew = false) => [
            {
              name: 'name',
              displayName: t(
                'admin:webhookSettings.inputs.webhookUrl.displayName',
                'Header Name'
              ),
              displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
              initialValue: '',
              reduxValidations: [validateRequired],
              isRequired: true,
              isHidden: false,
              displaySortOrder: 1,
              disabled: !isNew,
              displayParameters: {
                inputData: {
                  columnWidth: 4
                }
              }
            },
            {
              name: 'value',
              displayName:
                isUpdate && !isNew
                  ? t(
                      'admin:webhookSettings.inputs.newValue.displayName',
                      'Update Header Value'
                    )
                  : t(
                      'admin:webhookSettings.inputs.updateValue.displayName',
                      'Header Value'
                    ),
              displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
              initialValue: '',
              reduxValidations: isUpdate && !isNew ? [] : [validateRequired],
              // At this time we don't return the value so we don't want to require it on update
              isRequired: isNew,
              isHidden: false,
              displaySortOrder: 2,
              displayParameters: {
                inputData: {
                  columnWidth: 4
                }
              }
            }
          ]
        }
      }
    }
  ];
};

export const getTestWebhookInputs = (options = {}) => {
  return [
    {
      name: 'architectureId',
      displayName: t(
        'admin:webhookSettings.testWebhookInputs.architectureId.displayName',
        'Architecture'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          options: options.architectureOptions || []
        }
      }
    },
    {
      name: 'productId',
      displayName: t(
        'admin:webhookSettings.testWebhookInputs.productId.displayName',
        'Product'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 2,
      disabled: !options?.selectedArchitectureId,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          options: options.productOptions || [],
          helperText: options?.selectedArchitectureId
            ? ''
            : t(
                'admin:webhookSettings.testWebhookInputs.productId.helperText',
                'Please select architecture first'
              )
        }
      }
    },
    {
      name: 'catalogId',
      displayName: t(
        'admin:webhookSettings.testWebhookInputs.catalogId.displayName',
        'Catalog'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 3,
      disabled: !options?.selectedArchitectureId,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          options: options.catalogOptions || [],
          helperText: options?.selectedArchitectureId
            ? ''
            : t(
                'admin:webhookSettings.testWebhookInputs.catalogId.helperText',
                'Please select architecture first'
              )
        }
      }
    },
    {
      name: 'catalogGroupKey',
      displayName: t(
        'admin:webhookSettings.testWebhookInputs.catalogGroupKey.displayName',
        'Catalog Group Key'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 4,
      disabled: !options?.selectedArchitectureId,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          options: options.catalogGroupKeyOptions || [],
          helperText: options?.selectedArchitectureId
            ? ''
            : t(
                'admin:webhookSettings.testWebhookInputs.catalogGroupKey.helperText',
                'Please select architecture first'
              )
        }
      }
    },
    {
      name: 'contentItemId',
      displayName: t(
        'admin:webhookSettings.testWebhookInputs.contentItemId.displayName',
        `Select {contentName}`,
        { contentName: options?.contentName || 'Content' }
      ),
      displayMethodId: INPUT_TYPES.BUSINESS_OBJECT_SELECTOR,
      initialValue: [],
      reduxValidations: [exactlyOne],
      isRequired: true,
      isHidden: false,
      disabled: !options?.selectedArchitectureId || !options?.catalogGroupKey,
      displaySortOrder: 5,
      displayParameters: {
        inputData: {
          architectureId: options?.selectedArchitectureId,
          columnWidth: 4,
          contentGroupKey: options?.catalogGroupKey || 'id',
          minMaxSelection: { min: 1, max: 1 },
          contentName: options?.contentName || 'Content'
        }
      }
    },
    {
      name: 'isDryRun',
      displayName: t(
        'admin:webhookSettings.testWebhookInputs.isDryRun.displayName',
        'Dry Run'
      ),
      displayMethodId: INPUT_TYPES.BOOLEAN,
      initialValue: true,
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 6,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          tooltip: t(
            'admin:webhookSettings.testWebhookInputs.isDryRun.tooltip',
            'Show the payload we would send to the webhook without calling it.'
          )
        }
      }
    }
  ];
};

export const advancedTestWebhookInputs = () => [
  {
    name: 'onBehalfOfUserExternalId',
    displayName: t(
      'admin:webhookSettings.advancedTestWebhookInputs.onBehalfOfUserExternalId.displayName',
      'On Behalf of User External ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'onBehalfOfGroupExternalId',
    displayName: t(
      'admin:webhookSettings.advancedTestWebhookInputs.onBehalfOfGroupExternalId.displayName',
      'On Behalf of Group External ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];
