import { t } from 'i18next';

import {
  PersonOutlined as PersonIcon,
  PeopleAltOutlined as PeopleIcon
} from '@mui/icons-material';
import { SxProps, Tooltip } from '@mui/material';

interface SharedAssetIconProps {
  sx?: SxProps;
  isSharedAsset: boolean;
}

const pageText = () => {
  return {
    shared: t('teams:icon.tooltip.shared'),
    notShared: t('teams:icon.tooltip.notShared')
  };
};

const SharedAssetIcon = ({ sx, isSharedAsset }: SharedAssetIconProps) => {
  const text = pageText();

  return isSharedAsset ? (
    <Tooltip title={text.shared}>
      <PeopleIcon sx={sx} data-cy="share-icon-team" />
    </Tooltip>
  ) : (
    <Tooltip title={text.notShared}>
      <PersonIcon sx={sx} data-cy="share-icon-individual" />
    </Tooltip>
  );
};

export default SharedAssetIcon;
