import { GridRowId, useGridApiContext } from '@mui/x-data-grid-pro';
import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { t } from 'i18next';

import { AssetMenu, MenuIconButton, useMenu } from '../Menus';

export interface ActionMenuOption {
  label: string;
  onClick: (id: GridRowId) => void;
  icon: ReactElement;
}

interface DataTableRowActionMenuCellProps {
  menuOptions: ActionMenuOption[];
  id: GridRowId;
}

const DataTableRowActionMenuCell = ({
  menuOptions,
  id
}: DataTableRowActionMenuCellProps) => {
  const api = useGridApiContext();
  const { handleCloseMenu, handleOpenMenu, isMenuOpen, menuAnchorEl } = useMenu(
    { stopPropagation: true }
  );

  const row = api.current.getRow(id);

  const idString = id.toString();

  return (
    <Box>
      <MenuIconButton
        ariaLabel={t('programs:programsTable.menuAriaLabel')}
        ariaControls={isMenuOpen ? idString : undefined}
        ariaExpanded={isMenuOpen}
        ariaHaspopup
        onClick={handleOpenMenu}
        id={idString}
        size="small"
      />
      <AssetMenu
        id={idString}
        open={isMenuOpen}
        ariaLabeledBy={idString}
        anchorEl={menuAnchorEl}
        handleClose={handleCloseMenu}
        options={menuOptions}
        onClickArgValue={row}
      />
    </Box>
  );
};

export default DataTableRowActionMenuCell;
