import { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';
import Logger from '../common/Logger';
import auth from './Auth';

import Constants from '../common/Constants';

import Loading from '../components/Loading';

const ERROR_DIV_STYLE = {
  color: 'red',
  border: '1px solid red',
  padding: '1.5rem',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '16px',
  position: 'relative'
};

const FULL_VIEW_STYLE = {
  height: '100vh',
  width: '100vw',
  padding: '5rem',
  display: 'grid',
  textAlign: 'center',
  justifyItems: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  gridTemplate: 'auto / auto'
};

const AuthCallback = props => {
  const { history } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    auth
      .handleAuthentication()
      .then(() => {
        Logger.debug('We got a valid response!');

        if (auth.getAuthUrl()) {
          window.location = auth.getAuthUrl();
        }

        history.push(Constants.paths.home);
      })
      .catch(error => {
        Logger.debug('Login failed: ', error);
        setLoading(false);
        setError(true);
      });
  }, []);

  const handleClickLogin = () => {
    auth.login();
  };

  return (
    <div style={FULL_VIEW_STYLE}>
      {loading && <Loading zIndex="10" />}
      {error && (
        <div style={ERROR_DIV_STYLE}>
          <p>There was a problem logging in.</p>
          <p>
            Please
            <p
              style={{ textDecoration: 'underline' }}
              onClick={handleClickLogin}
            >
              {' '}
              try again.
            </p>
          </p>
        </div>
      )}
    </div>
  );
};

export default flow(withRouter)(AuthCallback);
