import { t } from 'i18next';

import { Divider, Grid, Paper, Typography } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CredidCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import EmailIcon from '@mui/icons-material/Email';

import { paths } from 'src/routes/paths';
import AdminQuickStartButton from 'src/pages/Admin/AdminQuickStartButton';
import PartnerAppBar from 'src/pages/Partner/PartnerAppBar';

const PartnerLanding = () => {
  return (
    <>
      <PartnerAppBar title={t('partner:landing.title')} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: theme => theme.spacing(2) }}>
            <Typography variant="body2">
              {t('partner:landing.stripe')}
            </Typography>
            <Divider />
            <Grid
              sx={{
                marginTop: theme => theme.spacing(2)
              }}
              container
              spacing={3}
            >
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: '200px',
                  maxWidth: '350px'
                }}
              >
                <AdminQuickStartButton
                  quickStart={{
                    name: t('partner:stripeOnboarding.landingTitle'),
                    description: t(
                      'partner:stripeOnboarding.landingDescription'
                    ),
                    disabled: false,
                    icon: CredidCardIcon,
                    path: paths.partner.onboarding.stripe,
                    visible: true
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: theme => theme.spacing(2) }}>
            <Typography variant="body2">{t('partner:landing.okta')}</Typography>
            <Divider />
            <Grid
              sx={{
                marginTop: theme => theme.spacing(2)
              }}
              container
              spacing={3}
            >
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: '200px',
                  maxWidth: '350px'
                }}
              >
                <AdminQuickStartButton
                  quickStart={{
                    name: t('partner:oktaOnboarding.landingTitle'),
                    description: t('partner:oktaOnboarding.landingDescription'),
                    disabled: false,
                    icon: VpnKeyIcon,
                    path: paths.partner.onboarding.okta,
                    visible: true
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: theme => theme.spacing(2) }}>
            <Typography variant="body2">
              {t('partner:landing.ticktok')}
            </Typography>
            <Divider />
            <Grid
              sx={{
                marginTop: theme => theme.spacing(2)
              }}
              container
              spacing={3}
            >
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: '200px',
                  maxWidth: '350px'
                }}
              >
                <AdminQuickStartButton
                  quickStart={{
                    name: t('partner:tiktokOnboarding.landingTitle'),
                    description: t(
                      'partner:tikTokOnboarding.landingDescription'
                    ),
                    disabled: false,
                    icon: VpnKeyIcon,
                    path: paths.partner.onboarding.tiktok,
                    visible: true
                  }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: '200px',
                  maxWidth: '350px'
                }}
              >
                <AdminQuickStartButton
                  quickStart={{
                    name: t('partner:tiktokSettings.landingTitle'),
                    description: t('partner:tiktokSettings.landingDescription'),
                    disabled: false,
                    icon: SettingsIcon,
                    path: paths.partner.tiktok,
                    visible: true
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: theme => theme.spacing(2) }}>
            <Typography variant="body2">
              {t('partner:emailNotificationSetup.landingTitle')}
            </Typography>
            <Divider />
            <Grid
              sx={{
                marginTop: theme => theme.spacing(2)
              }}
              container
              spacing={3}
            >
              <Grid
                item
                xs={3}
                sx={{
                  minWidth: '200px',
                  maxWidth: '350px'
                }}
              >
                <AdminQuickStartButton
                  quickStart={{
                    name: t('partner:emailNotificationSetup.landingTitle'),
                    description: t(
                      'partner:emailNotificationSetup.landingDescription'
                    ),
                    disabled: false,
                    icon: EmailIcon,
                    path: paths.partner.emailNotificationSetup,
                    visible: true
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PartnerLanding;
