import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';

import PreviewTopBar from 'src/images/tiktok/tt-preview-topbar.svg';
import PreviewPlus from 'src/images/tiktok/tt-preview-plus.png';
import ShareIcon from 'src/images/tiktok/tt-share-icon.svg';
import CommentIcon from 'src/images/tiktok/vi-comment.svg';
import WhiteHeartIcon from 'src/images/tiktok/vi-white-heart.svg';
import MusicIcon from 'src/images/tiktok/tt-music-tag.svg';
import TiktokMusic from 'src/images/tiktok/tiktok-music.png';
import PreviewNavBar from 'src/images/tiktok/tt-preview-nav-bar.jpeg';
import TiktokVideoPlayer from './TiktokVideoPlayer';
import { getPreviewWidth } from '../helpers';

export const TIKTOK_PREVIEW_WIDTH = 260;

export const styles = () => ({
  container: props => ({
    width: props.isResponsive ? '100%' : '400px',
    display: 'flex',
    justifyContent: 'center'
  }),
  previewContent: props => {
    return {
      width: getPreviewWidth(
        props.hasUniformPreviewWidth,
        TIKTOK_PREVIEW_WIDTH,
        props.isResponsive
      ),
      height: '460px',
      overflow: 'hidden',
      position: 'relative',
      borderRadius: '12px',
      boxSizing: 'content-box',
      backgroundColor: '#111213',
      fontSize: '10px',
      color: 'white'
    };
  },
  videoContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: '0 0'
  },
  topBanner: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px'
  },
  rightBar: {
    position: 'absolute',
    top: '163px',
    right: '6px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  avatar: {
    width: '38px',
    height: '38px',
    overflow: 'hidden',
    border: '1px solid #fff',
    borderRadius: '100%'
  },
  avatarImg: {
    width: '100%',
    height: '100%'
  },
  plusIcon: {
    position: 'absolute',
    top: '32px',
    width: '16px',
    height: '16px'
  },
  engagementIcon: {
    height: '24px',
    width: '24px'
  },
  shareIconSize: {
    height: '16px',
    width: '24px'
  },
  infoContainer: {
    position: 'absolute',
    bottom: '42px',
    left: '12px',
    width: '190px',
    fontSize: '12px'
  },
  infoLabelContainer: {
    position: 'relative',
    display: 'box',
    margin: '6px 12px 6px 0',
    overflow: 'hidden',
    lineHeight: '14px',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    lineClamp: 2,
    boxOrient: 'vertical'
  },
  adIcon: {
    display: 'inline-block',
    marginLeft: '2px',
    padding: '2px 4px',
    color: 'rgba(0,0,0,.75)',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '14px',
    background: 'rgba(255,255,255,.75)',
    borderRadius: '3px',
    transform: 'scale(.5)',
    transformOrigin: 'left'
  },
  musicIcon: {
    width: '10px',
    height: '10px',
    marginRight: '4px'
  },
  callToAction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '190px',
    height: '24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    background: '#3a3a3a',
    borderRadius: '4px',
    opacity: '.5'
  },
  tiktokMusic: {
    position: 'absolute',
    right: '6px',
    bottom: '35px'
  },
  navBarContainer: {
    width: '100%',
    left: 0,
    bottom: 0,
    position: 'absolute',
    lineHeight: 0
  },
  navBarImage: {
    height: '100%',
    width: '100%'
  },
  infoOverlay: {
    position: 'absolute',
    inset: 0,
    zIndex: 100,
    pointerEvents: 'none'
  }
});

const TiktokAdPreview = props => {
  const { classes, adData, hasUniformPreviewWidth, isResponsive } = props;

  return (
    <div className={`${classes.container} notranslate`}>
      <div className={classes.previewContent}>
        <div className={classes.videoContainer}>
          <TiktokVideoPlayer
            url={adData?.videoUrl}
            poster={adData?.coverImageUrl}
            width={getPreviewWidth(
              hasUniformPreviewWidth,
              TIKTOK_PREVIEW_WIDTH,
              isResponsive
            )}
            height={462}
          />
        </div>
        <div className={classes.infoOverlay}>
          <div className={classes.topBanner}>
            <img src={PreviewTopBar} alt="Preview Top Bar" />
          </div>
          <div className={classes.rightBar}>
            <div className={classes.avatar}>
              <img
                className={classes.avatarImg}
                src={adData?.identityImageUrl}
                alt="Identity Profile"
              />
            </div>
            <img src={PreviewPlus} alt="Follow" className={classes.plusIcon} />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2.5}
              alignItems="center"
            >
              <img
                src={WhiteHeartIcon}
                alt="Like"
                className={classes.engagementIcon}
              />
              <span>71.1k</span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <img
                src={CommentIcon}
                alt="Comment"
                className={classes.engagementIcon}
              />
              <span>1281</span>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <img
                src={ShareIcon}
                alt="Share"
                className={classes.shareIconSize}
              />
              <span>232</span>
            </Box>
          </div>
          <div className={classes.infoContainer}>
            <div>{adData?.adDisplayName}</div>
            <div className={classes.infoLabelContainer}>
              <span>{adData?.adText} </span>
              <span className={classes.adIcon}>Ad</span>
            </div>
            <Box display="flex" alignItems="center">
              <img
                src={MusicIcon}
                alt="Promotional Music"
                className={classes.musicIcon}
              />
              <span>Promotional Music</span>
            </Box>
            <div className={classes.callToAction}>
              <span>{adData?.callToAction}</span>
            </div>
          </div>
          <div className={classes.tiktokMusic}>
            <img src={TiktokMusic} alt="Tiktok Music" />
          </div>
          <div className={classes.navBarContainer}>
            <img
              src={PreviewNavBar}
              alt="Nav Bar"
              className={classes.navBarImage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(TiktokAdPreview);
