type CallbackFn = (text: string) => void;

interface ResetAiTextAnalysisFieldArgs {
  inputName: string;
  formName: string;
  change: (form: string, field: string, value: any) => void;
}

interface ConfigArgs extends ResetAiTextAnalysisFieldArgs {
  callback: CallbackFn;
}

export const AI_ANALYSIS_FIELD_NAME = 'genAiAnalysis';

export const createHandleFooterAiTextChange =
  ({ callback, inputName, formName, change }: ConfigArgs) =>
  (text: string) => {
    callback(text);
    // Save the unaltered generated Ai text on the parent form for analysis during form submission
    change(
      formName,
      `${AI_ANALYSIS_FIELD_NAME}.${inputName}.generatedText`,
      text
    );
  };

export const createHandleApplyAiText =
  ({ callback, inputName, formName, change }: ConfigArgs) =>
  (text: string) => {
    callback(text);

    // Save the potentially altered generated Ai text on the parent form for analysis during form submission
    change(
      formName,
      `${AI_ANALYSIS_FIELD_NAME}.${inputName}.appliedText`,
      text
    );
  };

export const resetAiTextAnalysisField = ({
  change,
  inputName,
  formName
}: ResetAiTextAnalysisFieldArgs) => {
  change(formName, `${AI_ANALYSIS_FIELD_NAME}.${inputName}`, null);
};
