import { useAppSettings } from 'src/AppSettings';

const useClientUrl = () => {
  const appSettings = useAppSettings();

  let clientUrl = `https://${appSettings.organizationFqdn}`;

  if (import.meta.env.EVOCALIZE_IS_LOCAL) {
    clientUrl = 'https://lilo.evocalize.com:3003';
  }

  return clientUrl;
};

export default useClientUrl;
