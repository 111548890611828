import { get } from 'lodash';

import { SvgIcon } from '@mui/material';

import withTheme from '@mui/styles/withTheme';
import { Theme } from '@mui/system';

export interface AudienceNetworkProps {
  theme: Theme;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const AudienceNetwork = ({
  theme,
  fontSize = 'medium'
}: AudienceNetworkProps) => {
  return (
    <SvgIcon fontSize={fontSize} viewBox="0 -2 24 24">
      <path
        fill={get(theme, 'palette.blueprintIcons.channel.audienceNetwork')}
        d="M19.55 17.15v2.35a.47.47 0 0 1-.47.46h-2.47a.48.48 0 0 1-.34-.14l-1.86-1.8H5.6l-1.87 1.8a.47.47 0 0 1-.33.14H.92a.48.48 0 0 1-.48-.46v-2.39a.47.47 0 0 1 .14-.33l1.73-1.67A8.86 8.86 0 0 1-.01 9.19c0-5.1 4.48-9.23 10-9.23s10 4.13 10 9.23a8.81 8.81 0 0 1-2.32 5.92l1.73 1.67a.47.47 0 0 1 .15.37zM14.29 13a4.59 4.59 0 0 0 1.67-3.67c0-3-2.49-5.19-6-5.19s-6 2.16-6 5.19A4.6 4.6 0 0 0 5.64 13a7 7 0 0 0 8.57 0z"
      />
    </SvgIcon>
  );
};

export default withTheme<Theme, typeof AudienceNetwork>(AudienceNetwork);
