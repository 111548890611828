export const AUDIENCE_UPLOAD_SHOW_HELP = 'evAudienceUploadShowHelp';
export const MIN_AUDIENCE_SIZE = 300;
export const MIN_AUDIENCE_MATCH_SIZE = 100;
export const RESULTS_PER_PAGE = 100;

export const AUDIENCE_STATUS = {
  active: 'active',
  inactive: 'inactive',
  pending: 'pending',
  error: 'error',
  deleted: 'deleted'
} as const;
