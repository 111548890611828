import withStyles from '@mui/styles/withStyles';
import CardMedia from './DisplayCardMedia';

import { styles } from './DisplaySquareAd';

const DisplayPortraitAd = props => {
  const { classes, portraitImageUrl, headline, description, businessName } =
    props;

  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.profileContainer}>
          <div className={classes.fakeImage} />
          <div className={classes.placeholderTextShort} />
        </div>
      </div>

      <CardMedia
        headline={headline}
        description={description}
        businessName={businessName}
        image={portraitImageUrl}
      />
    </div>
  );
};

export default withStyles(styles)(DisplayPortraitAd);
