import { gql } from 'src/generated/gql';

export const getWebhookSlug = gql(`
  query getWebhookSlug {
    myOrganization {
      id
      webhookSubscription {
        slug
      }
    }
  }
`);
