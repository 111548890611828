import { createAction } from 'redux-actions';

export const actionConstants = {
  selectStep: {
    next: createAction('BLUEPRINT_BUILDER_SELECT_NEXT_STEP'),
    previous: createAction('BLUEPRINT_BUILDER_SELECT_PREVIOUS_STEP'),
    exact: createAction('BLUEPRINT_BUILDER_SELECT_EXACT_STEP')
  }
};

export const selectExactStep = step => dispatch => {
  dispatch(actionConstants.selectStep.exact(step));
};

export const selectNextStep = () => dispatch => {
  dispatch(actionConstants.selectStep.next());
};

export const selectPreviousStep = () => dispatch => {
  dispatch(actionConstants.selectStep.previous());
};

export const resetBlueprintBuilder = () => dispatch => {
  dispatch(actionConstants.reset());
};
