import { facebookRegions } from './constants';
import RenderMultiSelect from './RenderMultiSelect';

const RenderFacebookRegionSelector = ({ ...props }) => {
  const menuItems = [
    {
      key: 'disabled',
      value: 'disabled',
      disabled: true,
      content: 'Make your selection(s)'
    },
    ...facebookRegions.map(region => ({
      key: region.facebook_region_id,
      value: region.facebook_region_id,
      content: (
        <>
          {region.name} - {region.country}
        </>
      )
    }))
  ];

  return <RenderMultiSelect {...{ ...props, menuItems }} />;
};

export default RenderFacebookRegionSelector;
