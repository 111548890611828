import { useCallback, useState } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import { t } from 'i18next';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import {
  Paper,
  Typography,
  Button,
  CircularProgress,
  Grid
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Alert from '@mui/material/Alert';

import PageTitle from 'src/components/PageTitle/PageTitle';
import { DynamicForm } from 'src/components/ReduxForm';
import { upsertOktaConfig } from './mutations';

const styles = theme => ({
  content: {
    padding: theme.spacing(2)
  }
});

const getText = () => ({
  title: t('partner:oktaOnboarding.title'),
  header: t('partner:oktaOnboarding.title'),
  submit: t('partner:oktaOnboarding.submit'),
  success: t('partner:oktaOnboarding.success'),
  error: t('partner:oktaOnboarding.error')
});

const Okta = ({
  handleSubmit,
  classes,
  submitting,
  invalid,
  upsertOktaConfig
}) => {
  const text = getText();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = useCallback(async formValues => {
    try {
      await upsertOktaConfig({
        variables: { input: formValues }
      });
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  });

  const inputs = [
    {
      name: 'clientId',
      displayName: 'Client ID',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: 'This is the client id for your Okta application'
        }
      }
    },
    {
      name: 'clientSecret',
      displayName: 'Client Secret',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: 'This is the client secret for your Okta application'
        }
      }
    },
    {
      name: 'authServerFqdn',
      displayName: 'Okta Tenant Fully Qualified Domain Name',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip:
            'This is the url for your Okta tenant. Example: example-123.oktapreview.com'
        }
      }
    }
  ];
  return (
    <>
      <PageTitle subPageTitle={text.title} />
      <Typography variant="h5">{text.title}</Typography>
      <Typography variant="subtitle2">{text.header}</Typography>
      <br />

      <Paper className={classes.content}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {error && <Alert severity="error">{text.error}</Alert>}
            {success ? (
              <Alert severity="success">{text.success}</Alert>
            ) : (
              <form
                autoComplete="off"
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
              >
                <DynamicForm disabled={false} inputs={inputs} />
                <br />
                <Button
                  color="primary"
                  disabled={submitting || invalid}
                  endIcon={submitting && <CircularProgress size={15} />}
                  variant="contained"
                  type="submit"
                >
                  {text.submit}
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'oktaOnboardingForm',
    initialValues: {}
  }),
  graphql(upsertOktaConfig, { name: 'upsertOktaConfig' }),
  withStyles(styles)
)(Okta);
