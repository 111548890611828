import { t } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { getAccessToken } from 'src/Auth/common';
import Heading from 'src/components/PageElements/Heading';

import ChannelSpendAllocationConfigTypeSelector from './ChannelSpendAllocationConfigTypeSelector';
import ChannelSpendAllocationConfigFinder from './ChannelSpendAllocationConfigFinder';
import ChannelSpendAllocationConfigUpdater from './ChannelSpendAllocationConfigUpdater';
import { loadOrganizations } from './queries';
import { CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION } from './constants';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
});

const pageText = () => ({
  title: t('adminChannelSpendAllocation:title'),
  description: t('adminChannelSpendAllocation:description'),
  findConfigHeader: t('adminChannelSpendAllocation:findConfigHeader')
});

const ChannelSpendAllocationSettings = props => {
  const { classes } = props;

  const text = useMemo(() => pageText(), []);

  const [configType, setConfigType] = useState(
    CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug
  );

  const [currentConfig, setCurrentConfig] = useState(undefined);

  // Use apollo's useLazyQuery hook so that we can call the graphql
  // query only once we need it, rather than when this component loads
  // for the first time.
  const [getOrganizations, { data: organizationQueryResult }] = useLazyQuery(
    loadOrganizations,
    {
      variables: {
        masterToken: getAccessToken()
      }
    }
  );

  // Use an effect (in combination with the useLazyQuery hook above) so
  // that we only bother fetching orgs if they select the organization config type.
  useEffect(() => {
    if (configType === CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug) {
      getOrganizations();
    }
  }, [configType]);

  const organizations = organizationQueryResult?.admin?.organizations;

  const getInitialUpdaterValues = useCallback(() => {
    const weights = currentConfig?.rules
      ? currentConfig.rules.reduce((acc, currentRule) => {
          return {
            ...acc,
            [currentRule.channelCode]: currentRule.weight
          };
        }, {})
      : {};

    return {
      ...weights,
      enabled: !!currentConfig?.enabled
    };
  }, [currentConfig]);

  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.description}
        pageTitle={text.title}
      />

      <Paper className={classes.paper}>
        <Typography component="h6" variant="h6">
          {text.findConfigHeader}
        </Typography>
        <br />
        <ChannelSpendAllocationConfigTypeSelector
          setConfigType={setConfigType}
          selectedConfigType={configType}
        />
        <ChannelSpendAllocationConfigFinder
          configType={configType}
          organizations={organizations || []}
          storeConfig={setCurrentConfig}
        />
      </Paper>
      {currentConfig && (
        <ChannelSpendAllocationConfigUpdater
          config={currentConfig}
          setCurrentConfig={setCurrentConfig}
          initialValues={getInitialUpdaterValues()}
        />
      )}
    </>
  );
};

export default withStyles(styles)(ChannelSpendAllocationSettings);
