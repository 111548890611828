import { adCreativeTypes } from 'src/common/adChannels';
import { getCreativeType } from 'src/common/blueprints';
import { differenceWith, includes } from 'lodash';

import { RenderBusinessObjectSelector } from 'src/components/ReduxForm';
import BusinessObjectBulkSelector from 'src/components/BusinessObjectBulkSelector';

import { minMaxArrayValue, exactArrayValue } from 'src/common/validations';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import { Trans } from 'react-i18next';
import { t } from 'i18next';

const exactlyOne = exactArrayValue(1);

const threeToTen = minMaxArrayValue(3, 10);

const memoizedValidator = new Map();
// returns the same validation keyed on the object passed in
// keeps from infinite refresh
const BusinessObjectsExistValidator = BOs => {
  if (!memoizedValidator.has(BOs)) {
    memoizedValidator.set(BOs, values => {
      if (BOs.loading) {
        return;
      }

      const missingBusinessObjectIds = differenceWith(
        values,
        BOs?.selectedBusinessObjects,
        (id, bo) => {
          return bo.id === id;
        }
      );

      if (missingBusinessObjectIds.length) {
        return t('programCreate:configure.BOValidationError');
      }
    });
  }
  return memoizedValidator.get(BOs);
};

const ContentSelector = ({
  architecture,
  architectureHasCatalog,
  contentName,
  selectedBlueprint,
  isContentSelectable,
  setSelectedBusinessObjects,
  selectedBusinessObjects,
  skipStep,
  setSkipStep,
  fetchingContent,
  setFetchingContent,
  isHookForm,
  formSectionName
}) => {
  const contentGroupKey = architecture?.catalog?.displayCollapseKey ?? 'id';

  const creativeType = getCreativeType(
    selectedBlueprint?.blueprint?.publishers
  );

  const contentTitle = contentName || 'content';

  const validateBusinessObjectsExist = BusinessObjectsExistValidator(
    selectedBusinessObjects
  );

  const minMaxSelection = includes(creativeType, adCreativeTypes.fbMultiImage)
    ? { min: 3, max: 10 }
    : { min: 1, max: 1 };

  const fieldProps = {
    component: RenderBusinessObjectSelector,
    name: 'businessObjectSelector',
    label: (
      <Trans
        i18nKey="programCreate:configure.contentDescription"
        values={{ contentTitle }}
      />
    ),
    validate: isHookForm
      ? {
          validateBusinessObjectsExist: () => validateBusinessObjectsExist,
          // validateBusinessObjectsExist,
          minMaxSelection: minMaxSelection.max > 1 ? threeToTen : exactlyOne
        }
      : [
          validateBusinessObjectsExist,
          minMaxSelection.max > 1 ? threeToTen : exactlyOne
        ],
    formNamespace: formSectionName,
    extraProps: {
      contentName,
      selectedBusinessObjects,
      setSelectedBusinessObjects,
      architecture,
      minMaxSelection,
      selectedBlueprint: selectedBlueprint?.blueprint,
      skipStep,
      setSkipStep,
      fetchingContent,
      setFetchingContent,
      contentGroupKey
    }
  };

  return (
    <div>
      {creativeType[0] === adCreativeTypes.fbDynamicCreative ||
      !isContentSelectable ? (
        <BusinessObjectBulkSelector
          contentName={contentName}
          hasCatalog={architectureHasCatalog}
        />
      ) : (
        <>
          <HookFormWrapper {...fieldProps} />
        </>
      )}
    </div>
  );
};

export default ContentSelector;
