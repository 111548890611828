import { capitalize, includes } from 'lodash';
import { t } from 'i18next';

import { validateNotBlank, validateRequired } from 'src/common/validations';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

const capitalName = option => ({
  ...option,
  name: capitalize(option.name)
});

export const getGalleryInputs = (isUpdate, assetTypeEnums) => [
  {
    name: 'id',
    displayName: 'ID',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [],
    isRequired: isUpdate,
    isHidden: !isUpdate,
    disabled: true,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'name',
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: false,
    isHidden: false,
    disabled: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'galleryType',
    displayName: 'Type',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: undefined,
    reduxValidations: isUpdate ? [] : [validateRequired],
    isRequired: true,
    isHidden: false,
    disabled: isUpdate,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: assetTypeEnums.map(capitalName)
      }
    }
  }
];

export const getTableColumnSchema = () => [
  {
    columnName: t('adminGalleries:galleryTable.galleryId'),
    accessor: 'id',
    key: 'id',
    type: 'ID'
  },
  {
    columnName: t('adminGalleries:galleryTable.galleryName'),
    accessor: 'name',
    key: 'name',
    type: 'single_line_string'
  },
  {
    columnName: t('adminGalleries:galleryTable.galleryAssetType'),
    accessor: r => capitalize(r.galleryType),
    key: 'galleryType',
    type: 'single_line_string'
  }
];

export const mapAssetTypeIntrospectionToProps = ({
  loading,
  error,
  __type
}) => {
  const assetTypeEnums = __type?.enumValues?.map(v => ({
    name: v.name,
    value: v.name
  }));

  return {
    assetTypeEnums,
    assetTypeIntrospectionMeta: {
      introspectionLoading: loading,
      introspectionError: error
    }
  };
};

// today we only allow one gallery per asset type, so filter out
// the existing gallery types from the available asset types
export const getAvailableAssetTypeEnums = (galleries, assetTypeEnums) => {
  const currentGalleryTypes = galleries.map(g => g.galleryType);
  return assetTypeEnums.filter(at => !includes(currentGalleryTypes, at.value));
};
