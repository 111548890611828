import {
  TextField,
  InputAdornment,
  Tooltip,
  FormHelperText,
  FormControl
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import HelpIcon from '@mui/icons-material/HelpOutline';
import ClientHtml from 'src/components/ClientHtml';

const styles = theme => ({
  helpTip: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: '3px',
    top: '0'
  },
  helpIcon: {
    width: '20px'
  },
  helperText: {
    marginTop: '0'
  },
  swatch: {
    border: '1px solid',
    height: '25px',
    marginRight: theme.spacing(2),
    width: '25px'
  }
});

const RenderColorInput = props => {
  const {
    children,
    input,
    margin = 'normal',
    meta: { touched, error },
    variant = 'outlined',
    businessObjects, // was causing a warning when getting passed with ...rest
    tooltip,
    helperText,
    classes,
    type,
    startAdornment,
    ...rest
  } = props;

  return (
    <FormControl fullWidth>
      <TextField
        error={touched && !!error}
        margin={margin}
        type={type}
        variant={variant}
        InputProps={{
          // Note: Type is how we determine if the the input is hidden && the
          //       endAdornment is showing up even when the input is hidden.
          ...(type !== 'hidden' && {
            endAdornment: (
              <InputAdornment position="end">
                <div
                  className={classes.swatch}
                  style={{ background: input?.value }}
                />

                {tooltip && (
                  <div className={classes.helpTip}>
                    <Tooltip arrow title={tooltip}>
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  </div>
                )}
              </InputAdornment>
            )
          })
        }}
        {...rest}
        {...input}
      >
        {children && children}
      </TextField>
      {helperText && (
        <FormHelperText
          className={classes.helperText}
          error={touched && !!error}
          variant="outlined"
        >
          <ClientHtml html={helperText} />
        </FormHelperText>
      )}
      {touched && !!error && (
        <FormHelperText
          className={helperText ? '' : classes.helperText} // if no helper text remove margin
          error
          variant="outlined"
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(RenderColorInput);
