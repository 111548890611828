import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';

import { Button, Grid, Typography, Box } from '@mui/material';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { QuickStartItem } from 'src/components/Quickstart';

interface DeactivatedQuickstartsProps {
  handleItemStatus: (id: string, isActive: boolean) => any;
  deactivatedQuickstarts: any[];
}

const DeactivatedQuickstarts = ({
  deactivatedQuickstarts,
  handleItemStatus
}: DeactivatedQuickstartsProps) => {
  const history = useHistory();

  return (
    <>
      <Typography
        sx={{ margin: theme => `${theme.spacing(2)} 0` }}
        component="h2"
        variant="h5"
      >
        <Trans i18nKey="dashboard:headers.deactivatedQuickStart">
          Deactivated Quick Starts
        </Trans>
      </Typography>
      <Grid data-cy="deactivated-quickstarts-container" container spacing={0}>
        {isEmpty(deactivatedQuickstarts) ? (
          <Grid item>
            <Trans i18nKey="dashboard:headers.emptyDeactivatedQuickStarts">
              You don't have any deactivated quickstarts at this time
            </Trans>
          </Grid>
        ) : (
          <>
            {deactivatedQuickstarts.map((quickStartItem: any) => (
              <Grid
                item
                sx={{ padding: theme => theme.spacing(1.5) }}
                key={quickStartItem.id}
              >
                {quickStartItem ? (
                  <>
                    <Box
                      sx={{
                        height: '100%',
                        position: 'relative',

                        '&:hover button': {
                          display: 'initial'
                        }
                      }}
                    >
                      <Button
                        sx={{
                          position: 'absolute',
                          zIndex: '1',
                          top: theme => theme.spacing(2),
                          right: theme => theme.spacing(2),
                          display: 'none'
                        }}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          const linkPath = generateLinkPath(
                            paths.admin.settings.quickStartEdit,
                            {
                              quickStartId: quickStartItem.id
                            }
                          );
                          history.push(linkPath);
                        }}
                      >
                        <Trans i18nKey="admin:manageQuickstart.editButton">
                          Edit
                        </Trans>
                      </Button>
                      <QuickStartItem
                        key={`quickStart-${quickStartItem.id}-${quickStartItem.architectureId}`}
                        id={quickStartItem.id}
                        architectureId={quickStartItem.architectureId}
                        catalogContentType={quickStartItem.catalogContentType}
                        isCustom={quickStartItem.isCustom}
                        customImageUrl={quickStartItem.customImageUrl}
                        iconImageUrl={quickStartItem.iconImageUrl}
                        channels={quickStartItem.channels}
                        notes={quickStartItem.notes}
                        objective={quickStartItem.objective}
                        productIds={quickStartItem.productIds}
                        description={quickStartItem.description}
                        name={quickStartItem.name}
                        productName={quickStartItem.productName}
                        isEditing
                        buttonComponent={
                          <>
                            <Button
                              color="primary"
                              size="small"
                              variant="contained"
                              onClick={() =>
                                handleItemStatus(
                                  quickStartItem.id,
                                  !quickStartItem.isActive
                                )
                              }
                              data-cy="activate-button"
                            >
                              <Trans i18nKey="admin.manageQuickstart.activateButton">
                                Activate
                              </Trans>
                            </Button>
                          </>
                        }
                      />
                    </Box>
                  </>
                ) : null}
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </>
  );
};

export default DeactivatedQuickstarts;
