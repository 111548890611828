import { useQuery } from '@apollo/client';

import { useGlobalContext } from 'src/GlobalContextProvider';

import { loadGalleriesV2, teamsLoadGalleriesV2 } from '../queries';
import { propsToGalleryFilter } from '../utilities';
import { RESULTS_PER_PAGE } from '../constants';

interface UseLoadGalleriesV2Args {
  mediaType: string;
  defaultMediaSources: string[];
  selectedMediaSources: string[];
  internalOnly: boolean;
}

const useLoadGalleriesV2 = ({
  mediaType,
  defaultMediaSources,
  selectedMediaSources,
  internalOnly
}: UseLoadGalleriesV2Args) => {
  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;

  const props = {
    mediaType,
    defaultMediaSources,
    selectedMediaSources,
    internalOnly
  };

  const loadGalleriesV2Result = useQuery(loadGalleriesV2, {
    skip: isTeamsEnabled,
    variables: {
      filter: propsToGalleryFilter(props),
      first: RESULTS_PER_PAGE,
      after: null
    }
  });

  const teamLoadGalleriesV2Result = useQuery(teamsLoadGalleriesV2, {
    skip: !isTeamsEnabled,
    variables: {
      filter: propsToGalleryFilter(props),
      first: RESULTS_PER_PAGE,
      after: null
    }
  });

  return isTeamsEnabled ? teamLoadGalleriesV2Result : loadGalleriesV2Result;
};

export default useLoadGalleriesV2;
