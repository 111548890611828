import { useState } from 'react';
import { flow } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';

import { validateNotBlank } from 'src/common/validations';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Loading from 'src/components/Loading';

import { updatePageToUsePageBackedInstagram } from './mutations';

const styles = theme => ({
  buttonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  success: {
    color: theme.palette.success[500],
    fontSize: '18px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  successList: {
    marginTop: theme.spacing(1),
    padding: 0,
    listStyle: 'none'
  }
});

const validations = {
  notBlank: validateNotBlank()
};

const InstagramPageAccess = props => {
  const {
    updatePageToUsePageBackedInstagram,
    classes,
    dirty,
    handleSubmit,
    reset
  } = props;

  const [executionId, setExecutionId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = ({ orderId }) => {
    setLoading(true);

    updatePageToUsePageBackedInstagram({
      variables: {
        orderId
      }
    })
      .then(
        ({
          data: {
            updatePageToUsePageBackedInstagram: { executionId }
          }
        }) => {
          setLoading(false);
          setError(null);
          setExecutionId(executionId);
          setOrderId(orderId);
          reset();
        }
      )
      .catch(() => {
        setLoading(false);
        setError(true);
        setExecutionId(null);
        setOrderId(orderId);
      });
  };

  const clearForm = () => {
    setLoading(false);
    setError(null);
    setExecutionId(null);
    setOrderId(null);

    reset();
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography>
        <Trans i18nKey="admin:facebookSupportTools.instagramPageAccessInputQuestion">
          What order ID would you like to update?
        </Trans>
      </Typography>
      <Field
        validate={[validations.notBlank]}
        component={RenderTextField}
        label={t('admin:facebookSupportTools.instagramPageAccessInputLabel')}
        tooltip={t(
          'admin:facebookSupportTools.instagramPageAccessInputTooltip'
        )}
        name="orderId"
      />
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty || loading}
          type="submit"
          variant="contained"
        >
          <Trans i18nKey="admin:facebookSupportTools.instagramPageAccessSubmitButton">
            Update Order
          </Trans>
        </Button>
        <Button onClick={clearForm}>
          <Trans i18nKey="admin:facebookSupportTools.instagramPageAccessClearButton">
            Clear
          </Trans>
        </Button>
      </div>
      {loading && <Loading />}

      {!error && executionId && orderId && (
        <>
          <Typography className={classes.success}>
            <CheckIcon /> Success!
          </Typography>
          <ul className={classes.successList}>
            <li>
              <Typography>
                Updated Order ID: <strong>{orderId}</strong>
              </Typography>
            </li>
            <li>
              <Typography>
                Execution ID: <strong>{executionId}</strong>
              </Typography>
            </li>
          </ul>
        </>
      )}

      {error && (
        <ErrorMessage>
          <Trans i18nKey="admin:facebookSupportTools.instagramPageAccessErrorMessage">
            There was an error updating Order ID: {orderId}. Please make sure
            you are in the correct account & order ID.
          </Trans>
        </ErrorMessage>
      )}
    </form>
  );
};

export default flow(
  reduxForm({
    form: 'instagramPageAccess',
    touchOnChange: true
  }),
  graphql(updatePageToUsePageBackedInstagram, {
    name: 'updatePageToUsePageBackedInstagram'
  }),
  withStyles(styles)
)(InstagramPageAccess);
