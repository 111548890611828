import { useController, useFieldArray, useFormContext } from 'react-hook-form';

interface HookFormFieldArrayProps {
  name: string;
  formSectionName?: string;
  component: React.ComponentType<{
    fields: Record<'id', string>[];
    name: string;
    hookFormFieldArrayMethods: any;
    meta: any;
    hookFormMethods: any;
  }>;
}

const HookFormFieldArray = (props: HookFormFieldArrayProps) => {
  const { name, component: Component, formSectionName, ...extraProps } = props;

  let fullName = name;

  if (formSectionName) {
    fullName = `${formSectionName}.${name}`;
  }

  const { control, ...hookFormMethods } = useFormContext();
  const { formState, fieldState } = useController({ name: fullName, control });
  const { fields, ...fieldArrayMethods } = useFieldArray({
    name: fullName,
    control
  });

  const meta = {
    dirty: fieldState.isDirty,
    error: fieldState.error?.message || fieldState.error?.type,
    submitFailed: !formState.isSubmitSuccessful
  };

  return (
    <Component
      fields={fields}
      name={fullName}
      hookFormFieldArrayMethods={fieldArrayMethods}
      meta={meta}
      hookFormMethods={hookFormMethods}
      {...extraProps}
    />
  );
};

export default HookFormFieldArray;
