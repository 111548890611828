import { GridFilterModel } from '@mui/x-data-grid-pro';
import { isArray, isEmpty } from 'lodash';

import {
  FilterInterface,
  mapParamToFilter
} from 'src/components/DataTable/helpers';
import { OrderItemProcessingStatus } from 'src/generated/gql/graphql';

import { PROGRAM_TABLE_FIELDS } from './constants';
import {
  supportedOrderIdOperators,
  supportedProgramNameOperators
} from '../filterOperators';

export const getFilterFromProgramTableModel = (
  filterModel?: GridFilterModel,
  supervisorOrderIds?: string[],
  showDeleted?: boolean
) => {
  const filter: {
    productIds?: string[];
    showDeleted?: boolean;
    statusTypes?: OrderItemProcessingStatus[];
    supervisorOrderIds?: string[];
    architectureIds?: string[];
    orderIds?: string[];
    programNames?: string[];
  } = {};

  if (supervisorOrderIds) {
    filter.supervisorOrderIds = supervisorOrderIds;
  }

  if (showDeleted) {
    filter.showDeleted = showDeleted;
  }

  filterModel?.items?.forEach(item => {
    const { field, value } = item;

    if (field === 'theme') {
      const currentThemeTypes = filter?.architectureIds ?? [];
      filter.architectureIds = [...currentThemeTypes, value];
    }

    if (field === 'type') {
      const currentTypes = filter?.productIds ?? [];
      filter.productIds = [...currentTypes, value];
    }

    if (field === 'id') {
      const currentIds = filter?.orderIds ?? [];
      filter.orderIds = [...currentIds, value];
    }

    if (field === 'name') {
      const currentNames = filter?.programNames ?? [];
      filter.programNames = [...currentNames, value];
    }
  });

  return filter;
};

export const getProgramTableFiltersFromParams = (parsedParams: {
  themes?: any;
  types?: any;
  ids?: any;
  names?: any;
}) => {
  // Supported query params:
  //   - themes: comma-separated list of architectureIds to filter by
  //   - types: comma-separated list of productIds to filter by

  const themesParam = parsedParams?.themes;
  let themeFilters: FilterInterface[] = [];

  if (themesParam) {
    const preSelectedThemes = isArray(themesParam)
      ? themesParam
      : [themesParam];
    themeFilters = mapParamToFilter(
      preSelectedThemes,
      PROGRAM_TABLE_FIELDS.theme
    );
  }

  const typesParam = parsedParams?.types;
  let typeFilters: FilterInterface[] = [];

  if (typesParam) {
    const preSelectedTypes = isArray(typesParam) ? typesParam : [typesParam];
    typeFilters = mapParamToFilter(preSelectedTypes, PROGRAM_TABLE_FIELDS.type);
  }

  const idsParam = parsedParams?.ids;
  let idFilters: FilterInterface[] = [];

  if (idsParam) {
    const preSelectedOrderIds = isArray(idsParam) ? idsParam : [idsParam];
    idFilters = mapParamToFilter(
      preSelectedOrderIds,
      PROGRAM_TABLE_FIELDS.id,
      supportedOrderIdOperators[0]
    );
  }

  const namesParam = parsedParams?.names;
  let nameFilters: FilterInterface[] = [];

  if (namesParam) {
    const preSelectedNames = isArray(namesParam) ? namesParam : [namesParam];
    nameFilters = mapParamToFilter(
      preSelectedNames,
      PROGRAM_TABLE_FIELDS.name,
      supportedProgramNameOperators[0]
    );
  }

  const filterItems = [
    ...(themeFilters && themeFilters),
    ...(typeFilters && typeFilters),
    ...(idFilters && idFilters),
    ...(nameFilters && nameFilters)
  ];

  const initialFilters = isEmpty(filterItems)
    ? undefined
    : {
        filter: {
          filterModel: {
            items: filterItems
          }
        }
      };

  return initialFilters;
};
