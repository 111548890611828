import { Box } from '@mui/material';

const dotStyles = {
  width: '3px',
  height: '3px',
  borderRadius: '50%',
  backgroundColor: 'gray',
  margin: '0 2px',
  position: 'relative',
  top: '2px',
  animation: 'bounce 0.45s infinite alternate',
  '&:nth-child(2)': {
    animationDelay: '0.15s'
  },
  '&:nth-child(3)': {
    animationDelay: '0.3s'
  },

  '@keyframes bounce': {
    to: {
      transform: 'translateY(-4px)'
    }
  }
};

const AiLoadingSpinner = () => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={dotStyles} />
      <Box sx={dotStyles} />
      <Box sx={dotStyles} />
    </Box>
  );
};

export default AiLoadingSpinner;
