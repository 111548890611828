import {
  SnackbarEnqueueAction,
  SnackbarRemoveAction,
  SnackbarState
} from 'src/components/AdmiralSnackBar/actions';

const defaultState = {
  notifications: [] as SnackbarState[]
};

type Action = SnackbarRemoveAction | SnackbarEnqueueAction;

export default (state = defaultState, action: Action) => {
  switch (action.type) {
    case 'ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification
          }
        ]
      };

    case 'REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key
        )
      };

    default:
      return state;
  }
};
