import { useMemo, useState } from 'react';
import { t } from 'i18next';
import { flow, noop } from 'lodash';

import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import Modal from 'src/components/Modal';

import StepComponent from './StepComponent';
import StepDownloadTemplate from './StepDownloadTemplate';
import StepUploadFile from './StepUploadFile';
import StepWaitForPublish from './StepWaitForPublish';
import StepViewResults from './StepViewResults';

const styles = () => ({});

const pageText = () => ({
  download: t('adminContentDataManager:uploadModal.downloadTemplate'),
  downloadDescription: t(
    'adminContentDataManager:uploadModal.downloadDescription'
  ),
  upload: t('adminContentDataManager:uploadModal.upload'),
  wait: t('adminContentDataManager:uploadModal.wait'),
  waitDescription: t('adminContentDataManager:uploadModal.waitDescription'),
  results: t('adminContentDataManager:uploadModal.results'),
  finish: t('adminContentDataManager:uploadModal.finish'),
  continue: t('adminContentDataManager:uploadModal.continue'),
  back: t('adminContentDataManager:uploadModal.back')
});

const UploadModal = ({ handleClose, contentSetSlug, contentSetFields }) => {
  const text = useMemo(() => pageText(), []);

  const [activeStep, setActiveStep] = useState(0);
  const [fileParseResults, setFileParseResults] = useState();
  const [reportIds, setReportIds] = useState([]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const steps = useMemo(
    () => [
      {
        label: text.download,
        description: text.downloadDescription,
        component: StepDownloadTemplate,
        canCancel: true
      },
      {
        label: text.upload,
        component: StepUploadFile,
        canCancel: true
      },
      {
        label: text.wait,
        description: text.waitDescription,
        component: StepWaitForPublish,
        canCancel: false
      },
      {
        label: text.results,
        component: StepViewResults,
        canCancel: true
      }
    ],
    [text]
  );

  const canCanelCurrentStep = steps[activeStep].canCancel;

  return (
    <Modal
      showClose={canCanelCurrentStep}
      fullWidth
      maxWidth="md"
      onClose={canCanelCurrentStep ? handleClose : noop}
      open
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(step => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.description && <Typography>{step.description}</Typography>}
              <StepComponent
                component={step.component}
                handleNext={handleNext}
                handleBack={handleBack}
                handleClose={handleClose}
                contentSetSlug={contentSetSlug}
                contentSetFields={contentSetFields}
                fileParseResults={fileParseResults}
                setFileParseResults={setFileParseResults}
                reportIds={reportIds}
                setReportIds={setReportIds}
              />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Modal>
  );
};

export default flow(withStyles(styles))(UploadModal);
