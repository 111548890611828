import { useState } from 'react';
import { clone, isEmpty } from 'lodash';

import {
  Avatar,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import HelpIcon from '@mui/icons-material/HelpOutline';

const styles = theme => ({
  helpTip: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '3px',
    right: '3px'
  },
  helpIcon: {
    width: '20px'
  },
  helperText: {
    marginTop: '0'
  }
});

const RenderKeyBasedSettings = props => {
  const { input, label, tooltip, type, classes } = props;
  const value = input?.value || {};

  const [inputValue, inputOnChange] = useState('');

  const handleOnChange = ({ target: { value } }) => {
    inputOnChange(value);
  };

  const handleAddId = () => {
    // don't add if empty
    if (isEmpty(inputValue)) {
      return;
    }
    const newValue = clone(value);
    newValue[inputValue] = true;
    // update redux input value
    input.onChange(newValue);
    // reset input
    inputOnChange('');
  };

  const handelKeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleAddId();
    }
  };

  return (
    <div>
      {!isEmpty(value) && (
        <List>
          {Object.keys(value).map(id => {
            return (
              <ListItem>
                <ListItemAvatar>
                  <Avatar>ID</Avatar>
                </ListItemAvatar>
                <ListItemText primary={id} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      const newValue = clone(value);
                      delete newValue[id];
                      input.onChange(newValue);
                    }}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
      <FormControl fullWidth>
        <TextField
          label={label}
          variant="outlined"
          onChange={handleOnChange}
          value={inputValue}
          onKeyPress={handelKeyPress}
          InputProps={{
            ...(type !== 'hidden' &&
              tooltip && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleAddId}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                    <Tooltip className={classes.helpTip} arrow title={tooltip}>
                      <HelpIcon className={classes.helpIcon} />
                    </Tooltip>
                  </InputAdornment>
                )
              })
          }}
        />
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(RenderKeyBasedSettings);
