import { forwardRef } from 'react';
import { get } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import validator from 'validator';

import { Box, Link as MuiLink } from '@mui/material';

// Note: We need this component b/c there are situations where we have
//       mixed internal and external links like in the case of menu items.
const Link = forwardRef(({ to, refresh, children, ...props }, ref) => {
  if (!to) {
    // it's a simple element with nothing to link to
    return (
      <Box component="span" {...props}>
        {children}
      </Box>
    );
  }

  // Note: to url needs to have http:// || https://
  if (validator.isURL(to)) {
    // it's intended to be an external link
    // default for external links will be target="_blank"
    const target = get(props, 'target', '_blank');

    return (
      <Box
        component="a"
        href={to}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : ''}
        {...props}
      >
        {children}
      </Box>
    );
  }

  if (refresh) {
    // assuming the condition above filters out full urls
    const cleanTo = to[0] !== '/' ? `/${to}` : to;
    return (
      <MuiLink
        href={`#${cleanTo}`}
        onClick={e => {
          e.preventDefault();
          window.location.hash = cleanTo;
          window.location.reload();
        }}
        {...props}
        ref={ref}
      >
        {children}
      </MuiLink>
    );
  }

  // it's an internal link
  return (
    <RouterLink to={to} component={MuiLink} {...props} ref={ref}>
      {children}
    </RouterLink>
  );
});

export default Link;
