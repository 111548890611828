/* eslint-disable no-console */

const LOG_LEVELS = {
  DEBUG: 3,
  INFO: 2,
  WARNING: 1,
  ERROR: 0
} as const;

type LogLevel = (typeof LOG_LEVELS)[keyof typeof LOG_LEVELS];

class Logger {
  logFuncMap = {
    [LOG_LEVELS.DEBUG]: console.debug,
    [LOG_LEVELS.INFO]: console.info,
    [LOG_LEVELS.WARNING]: console.warn,
    [LOG_LEVELS.ERROR]: console.error
  };

  fatal = (...args: unknown[]) => {
    this.log(LOG_LEVELS.ERROR, ...args);
  };

  error = (...args: unknown[]) => {
    this.log(LOG_LEVELS.ERROR, ...args);
  };

  warn = (...args: unknown[]) => {
    this.warning(...args);
  };

  warning = (...args: unknown[]) => {
    this.log(LOG_LEVELS.WARNING, ...args);
  };

  info = (...args: unknown[]) => {
    this.log(LOG_LEVELS.INFO, ...args);
  };

  debug = (...args: unknown[]) => {
    this.log(LOG_LEVELS.DEBUG, ...args);
  };

  log = (level: LogLevel, ...args: unknown[]) => {
    if (level > this.getLogLevel() || !window.console) {
      return;
    }

    this.logFuncMap[level](...args);
  };

  getLogLevel = () => {
    if (window.hLogLevel !== null && window.hLogLevel !== undefined) {
      return window.hLogLevel;
    }

    return LOG_LEVELS.DEBUG;
  };
}

window.hLogLevel = LOG_LEVELS.ERROR;

if (
  import.meta.env.EVOCALIZE_LOG_LEVEL !== null &&
  import.meta.env.EVOCALIZE_LOG_LEVEL !== undefined
) {
  window.hLogLevel = (LOG_LEVELS as any)[import.meta.env.EVOCALIZE_LOG_LEVEL];
}

const logger = new Logger();

export default logger;
