import { Helmet } from 'react-helmet';
import { flow } from 'lodash';
import { withRouter } from 'react-router-dom';

import Router from 'src/routes/Router';
import { CssBaseline } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { generatePageTitle } from 'src/routes/RouteUtil';
import ArchitectureRoute from 'src/pages/Architecture/ArchitectureRoute';
import Chrome from 'src/pages/Chrome';
import GlobalContextProvider from './GlobalContextProvider';
import { withAppSettings } from './AppSettings';
import PostAuthGlobals from './PostAuthGlobals';
import AppStateProvider from './AppStateProvider';

const styles = theme => ({
  root: {
    display: 'flex',
    fontFamily: theme?.typography?.fontFamily,
    flexDirection: 'column'
  }
});

const App = props => {
  const { appSettings, classes } = props;

  const googleAnalyticsId =
    import.meta.env.EVOCALIZE_GOOGLE_ANALYTICS_ID ||
    appSettings?.app?.trackers?.googleAnalyticsId;

  return (
    <div className={classes.root}>
      <Helmet>
        {/*
                        Send the origin, path, and query string when performing a same-origin request to the same protocol level.
                        Send origin (only) for cross origin requests and requests to less secure destinations.
                    */}
        <meta name="referrer" content="origin-when-cross-origin" />
        <title>
          {generatePageTitle('', appSettings?.app?.general?.baseTitle)}
        </title>
        <link rel="shortcut icon" href={appSettings?.evAssets?.favIconUrl} />
        {googleAnalyticsId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
          />
        )}
      </Helmet>
      <CssBaseline />
      <GlobalContextProvider>
        <PostAuthGlobals>
          <AppStateProvider>
            <Chrome>
              <ArchitectureRoute>
                <Router />
              </ArchitectureRoute>
            </Chrome>
          </AppStateProvider>
        </PostAuthGlobals>
      </GlobalContextProvider>
    </div>
  );
};

export default flow(withStyles(styles), withAppSettings, withRouter)(App);
