import { t } from 'i18next';
import { sortBy } from 'lodash';

import { Box, Typography } from '@mui/material';

import { GroupedChannelIcon } from 'src/components/ChannelIcon';
import { HelpTip } from 'src/components/Icons';

const pageText = () => ({
  adPreviewHeader: t('programCreate:ProgramPreviewDrawer.adPreviewHeader'),
  orderSummaryHeader: t(
    'programCreate:ProgramPreviewDrawer.orderSummaryHeader'
  ),
  automationOrderSummaryHeader: t(
    'programCreate:ProgramPreviewDrawer.automationOrderSummaryHeader'
  ),
  automationSummaryTip: t(
    'programCreate:ProgramPreviewDrawer.automationSummaryTip'
  )
});

const PreviewDrawerHeader = ({ isSummary, channels, isAutomation }) => {
  const text = pageText();
  const summaryHeader = isAutomation ? (
    <span style={{ marginRight: '8px' }}>
      {text.automationOrderSummaryHeader}
    </span>
  ) : (
    text.orderSummaryHeader
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          ...(isAutomation ? { display: 'flex', alignItems: 'center' } : {})
        }}
        variant="h5"
      >
        {isSummary ? summaryHeader : text.adPreviewHeader}
        {isSummary && isAutomation && (
          <HelpTip tipText={text.automationSummaryTip} />
        )}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme => theme.spacing(3)
        }}
      >
        {/* Sorting channels so that they are the same order as the channel tabs in the ad preview */}
        {sortBy(channels).map(channel => (
          <GroupedChannelIcon key={channel} channel={channel} />
        ))}
      </Box>
    </Box>
  );
};

export default PreviewDrawerHeader;
