import { gql } from 'src/generated/gql';

export const deleteAsset = gql(`
  mutation DeleteAudienceAsset($galleryId: ID!, $assetId: ID!) {
    deleteAsset(galleryId: $galleryId, assetId: $assetId)
  }
`);

export const generateAudienceUrl = gql(`
  mutation generateAudienceUrl($input: GenerateAudienceUrlInput!) {
    generateAudienceUrl(input: $input) {
      id
      url
    }
  }
`);
