import { useQuery } from '@apollo/client';

import { getBillingHistory } from '../queries';
import { RESULTS_PER_PAGE } from '../constants';

const useGetBillingHistory = () => {
  const billingHistoryResult = useQuery(getBillingHistory, {
    variables: {
      first: RESULTS_PER_PAGE,
      after: null
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  const billingHistory = {
    error: billingHistoryResult?.error,
    loading: billingHistoryResult?.loading,
    refetch: billingHistoryResult?.refetch,
    pageInfo: billingHistoryResult?.data?.billingHistory?.pageInfo,
    rawRows: billingHistoryResult?.data?.billingHistory?.edges || []
  };

  return billingHistory;
};

export default useGetBillingHistory;
