import { t } from 'i18next';
import { orderBy, invert } from 'lodash';

import { isDevEnvironment } from 'src/common/env';

// ⚠️ See important note below about adding new locales! ⚠️
export const LOCALE_CODE = {
  english: 'en',
  spanish: 'es',
  spanishLatin: 'es-419',
  french: 'fr',
  frenchCanadian: 'fr-CA',
  portuguese: 'pt',
  portugueseBrazil: 'pt-BR',
  turkish: 'tr',
  german: 'de',
  arabic: 'ar',
  italian: 'it',
  greek: 'el',
  hebrew: 'he',
  japanese: 'ja',
  polish: 'pl',
  czech: 'cs',
  slovenian: 'sl',
  serbian: 'sr',
  albanian: 'sq',
  romanian: 'ro',
  catalan: 'ca',
  malay: 'ms',
  chineseSimplified: 'cmn-Hans',
  mongolianCyrillic: 'mn-Cyrl',
  vietnamese: 'vi',
  thai: 'th',
  dutch: 'nl',
  pseudo: '00'

  // 👆Add new locales here 👆
  // The flow for adding a new locale:
  // 1. Add the new locale code here
  // 2. Add the new locale to the list in /bin/translate.js ⚠️ IMPORTANT! ⚠️
  // 3. Run `npm run strings:translate` to generate the new locale files
  // 4. Commit and push that in a new PR
};

export const LOCALE_SLUGS = invert(LOCALE_CODE);

export const getLocaleOptions = () => {
  // NOTE:
  // This is the ONLY place in our app we are okay with manual concatenation of strings.
  // Since we want this to be "<locale in current language> (<locale in locale's language>)"
  // You can create this list by first translating all locales then going to the locale
  // and copy-pasting the value from that locale.
  const localeOptions = [
    {
      value: LOCALE_CODE.english,
      name: `${t('localization:languages.english')} (English)`
    },
    {
      value: LOCALE_CODE.spanish,
      name: `${t('localization:languages.spanish')} (Español)`
    },
    {
      value: LOCALE_CODE.spanishLatin,
      name: `${t('localization:languages.spanishLatinAmerican')} (Español - Latinoamericano)`
    },
    {
      value: LOCALE_CODE.french,
      name: `${t('localization:languages.french')} (Français)`
    },
    {
      value: LOCALE_CODE.frenchCanadian,
      name: `${t('localization:languages.frenchCanadian')} (Français canadien)`
    },
    {
      value: LOCALE_CODE.portuguese,
      name: `${t('localization:languages.portuguese')} (Português)`
    },
    {
      value: LOCALE_CODE.portugueseBrazil,
      name: `${t('localization:languages.portugueseBrazilian')} (Português - Brasileiro)`
    },
    {
      value: LOCALE_CODE.turkish,
      name: `${t('localization:languages.turkish')} (Türkçe)`
    },
    {
      value: LOCALE_CODE.german,
      name: `${t('localization:languages.german')} (Deutsch)`
    },
    {
      value: LOCALE_CODE.arabic,
      name: `${t('localization:languages.arabic')} (عربي)`
    },
    {
      value: LOCALE_CODE.italian,
      name: `${t('localization:languages.italian')} (Italiano)`
    },
    {
      value: LOCALE_CODE.greek,
      name: `${t('localization:languages.greek')} (Ελληνικά)`
    },
    {
      value: LOCALE_CODE.hebrew,
      name: `${t('localization:languages.hebrew')} (עִברִית)`
    },
    {
      value: LOCALE_CODE.japanese,
      name: `${t('localization:languages.japanese')} (日本語)`
    },
    {
      value: LOCALE_CODE.polish,
      name: `${t('localization:languages.polish')} (Polski)`
    },
    {
      value: LOCALE_CODE.czech,
      name: `${t('localization:languages.czech')} (čeština)`
    },
    {
      value: LOCALE_CODE.slovenian,
      name: `${t('localization:languages.slovenian')} (Slovenščina)`
    },
    {
      value: LOCALE_CODE.serbian,
      name: `${t('localization:languages.serbian')} (Српски)`
    },
    {
      value: LOCALE_CODE.albanian,
      name: `${t('localization:languages.albanian')} (shqiptare)`
    },
    {
      value: LOCALE_CODE.romanian,
      name: `${t('localization:languages.romanian')} (Română)`
    },
    {
      value: LOCALE_CODE.catalan,
      name: `${t('localization:languages.catalan')} (català)`
    },
    {
      value: LOCALE_CODE.malay,
      name: `${t('localization:languages.malay')} (Melayu)`
    },
    {
      value: LOCALE_CODE.chineseSimplified,
      name: `${t('localization:languages.chineseSimplified')} (简体中文)`
    },
    {
      value: LOCALE_CODE.mongolianCyrillic,
      name: `${t('localization:languages.mongolianCyrillic')} (Монгол - кирилл)`
    },
    {
      value: LOCALE_CODE.vietnamese,
      name: `${t('localization:languages.vietnamese')} (Tiếng Việt)`
    },
    {
      value: LOCALE_CODE.thai,
      name: `${t('localization:languages.thai')} (แบบไทย)`
    },
    {
      value: LOCALE_CODE.dutch,
      name: `${t('localization:languages.dutch')} (Nederlands)`
    }
  ];

  const sortedLocaleOptions = orderBy(localeOptions, 'name', 'asc');

  if (isDevEnvironment) {
    // Add psuedo locale to the top of the list in dev environment for visibility
    sortedLocaleOptions.unshift({
      value: LOCALE_CODE.pseudo,
      name: 'Pseudo Locale'
    });
  }

  return sortedLocaleOptions;
};
