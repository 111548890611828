import { pick } from 'lodash';
import Auth from 'src/Auth/Auth';
import { isSuperAdmin } from 'src/Auth/common';
import {
  GranteeScope,
  GrantInput,
  OrganizationUserRole,
  Permission
} from 'src/generated/gql/graphql';

// sizes
export const IMAGE_GALLERY_HEIGHT = 495;
export const RESULTS_PER_PAGE = 15;
export const GALLERY_ROW_HEIGHT = 150;

export const MIN_IMG_HEIGHT = 250;
export const MIN_IMG_WIDTH = 476;

export const SEARCH_TABS = {
  shutterstock: 'shutterstock',
  giphy: 'giphy'
} as const;

export const GALLERY_TYPE = {
  audience: 'audience',
  image: 'image',
  video: 'video',
  blueprint: 'blueprint',
  media: 'media'
} as const;

// Note these are in bytes (binary)
export const MAX_FILE_SIZE_BY_GALLERY_TYPE = {
  [GALLERY_TYPE.image]: 7864320, //  7.5mb
  [GALLERY_TYPE.video]: 94371840 // 90mb
} as const;

// 1 Megabyte is equal to 1048576 bytes (binary)
export const MAX_FILE_SIZE_MB_BY_GALLERY_TYPE = {
  [GALLERY_TYPE.image]:
    ([MAX_FILE_SIZE_BY_GALLERY_TYPE.image] as any) / 1048576,
  [GALLERY_TYPE.video]: ([MAX_FILE_SIZE_BY_GALLERY_TYPE.video] as any) / 1048576
} as const;

export const GALLERY_SCOPES = {
  private: 'private_',
  public: 'public_',
  internal: 'internal'
};

export const SUB_GALLERY_TYPE = [
  { scope: GALLERY_SCOPES.private },
  { scope: GALLERY_SCOPES.public },
  { scope: GALLERY_SCOPES.internal }
];

export const ASSET_STATUS = {
  complete: 'complete',
  error: 'error',
  pending: 'pending'
};

export const MEDIA_TYPE = {
  image: 'image',
  video: 'video'
};

export const MEDIA_TYPE_FILTER_VALUES = {
  all: 'all',
  ...MEDIA_TYPE
};

// Note: Here is a good resource for accept types
//       https://stackoverflow.com/questions/11832930/html-input-file-accept-attribute-file-type-csv
// supported files as per facebook. minus [webp] which errors.
// https://www.facebook.com/business/help/523719398041952?id=1240182842783684
const supportedImgFormats = [
  'bmp',
  'heic',
  'jfif',
  'jp2',
  'jpe',
  'jpeg',
  'jpg',
  'png'
];

const internalSupportedImgFormats = [...supportedImgFormats, 'svg+xml', 'svg'];

const formatImageFormats = (imageFormatList: string[]) => {
  return imageFormatList.map(format => `image/${format}`).join(', ');
};

const supportedImg = formatImageFormats(supportedImgFormats);

const internalSupportedImg = formatImageFormats(internalSupportedImgFormats);
const internalSupportedImgNoSvg = formatImageFormats(supportedImgFormats);

// Supported cloudinary video formats for upload and delivery, excluding formats for streaming
// https://cloudinary.com/documentation/video_manipulation_and_delivery#supported_video_formats
// NOTE: quicktime format is the MIME type for .mov & .qt files
const cloudinaryVideoFormats = ['mp4', 'mkv', 'ogv', 'quicktime'];

const supportedVideo = cloudinaryVideoFormats
  .map(format => `video/${format}`)
  .join(', ');

export const UPLOAD_ACCEPTS = {
  [GALLERY_TYPE.audience]: '.csv',
  [GALLERY_TYPE.image]: supportedImg,
  [GALLERY_TYPE.video]: supportedVideo,
  [GALLERY_TYPE.blueprint]: '.json',
  [GALLERY_TYPE.media]: `${supportedVideo}, ${supportedImg}`
};

export const INTERNAL_UPLOAD_ACCEPTS = {
  [GALLERY_TYPE.image]: internalSupportedImg,
  [GALLERY_TYPE.video]: supportedVideo,
  [GALLERY_TYPE.media]: `${supportedVideo}, ${internalSupportedImg}`
};

export const INTERNAL_UPLOAD_ACCEPTS_NO_SVG = {
  [GALLERY_TYPE.image]: internalSupportedImgNoSvg,
  [GALLERY_TYPE.video]: supportedVideo,
  [GALLERY_TYPE.media]: `${supportedVideo}, ${internalSupportedImgNoSvg}`
};

export const MEDIA_SOURCES = {
  User: 'User',
  Corporate: 'Corporate',
  Partner: 'Partner',
  Internal: 'Internal',
  Giphy: 'Giphy',
  Shutterstock: 'Shutterstock'
};

export const MEDIA_SOURCE_THIRD_PARTY = 'thirdParty';

export const THIRD_PARTY_MEDIA_SOURCES = [
  MEDIA_SOURCES.Giphy,
  MEDIA_SOURCES.Shutterstock
];

/**
 * Converts from an object of media sources into a list of media sources that
 * can be filtered on by the user.
 *
 * These sources need to be converted back into the actual media sources when
 * used for actually filtering.
 *
 * @param mediaSources {object} - The object of media sources, derived from MEDIA_SOURCES
 */
export const getFilterableMediaSources = (
  mediaSources: Record<string, unknown>
) => {
  let hasThirdParty = false;

  let filteredMediaSources = Object.keys(mediaSources).filter(source => {
    if (THIRD_PARTY_MEDIA_SOURCES.includes(source)) {
      hasThirdParty = true;
      return false;
    }
    // Pass through all non-third-party types
    return true;
  });

  if (hasThirdParty) {
    filteredMediaSources = [...filteredMediaSources, MEDIA_SOURCE_THIRD_PARTY];
  }

  return filteredMediaSources;
};

interface GetDefaultMediaSourcesParams {
  giphySearch: boolean;
  galleryType?: string;
  galleryAdminImages?: boolean;
  shutterstockSearch?: boolean;
}

export const getDefaultMediaSources = ({
  giphySearch,
  galleryType,
  galleryAdminImages,
  shutterstockSearch
}: GetDefaultMediaSourcesParams) => {
  const defaults = [MEDIA_SOURCES.User];

  if (galleryAdminImages) {
    defaults.push(MEDIA_SOURCES.Corporate);
  }

  // if gipfy feature is on and we aren't in an image only modal
  if (
    giphySearch &&
    (!galleryType ||
      galleryType === GALLERY_TYPE.video ||
      galleryType === GALLERY_TYPE.media)
  ) {
    defaults.push(MEDIA_SOURCES.Giphy);
  }

  // if shutter feature is on and aren't in a video only modal
  if (
    shutterstockSearch &&
    (!galleryType ||
      galleryType === GALLERY_TYPE.image ||
      galleryType === GALLERY_TYPE.media)
  ) {
    defaults.push(MEDIA_SOURCES.Shutterstock);
  }

  return pick(MEDIA_SOURCES, defaults);
};

export const LIBRARY_SCOPES = {
  Internal: 'Internal',
  User: 'User',
  Corporate: 'Corporate'
};

export const GRANTEE_SCOPES = {
  // Requires userId
  user: 'user',

  // Requires groupId
  group: 'group',

  // Requires groupId and role
  group_role: 'group_role',

  // Requires userId and groupId,
  user_in_group: 'user_in_group',

  // Requires organizationId
  org_wide: 'org_wide',

  // Requires organizationId and organizationUserRole
  organization_user_role: 'organization_user_role'
} as const;

interface Me {
  id: string;
  name: string;
  organizationId: string;
}

export const getGrantTypesForMe = (me: Me) => {
  // Otherwise, public scope - only corporate admins can upload.
  // TODO: Right now isCorporateAdmin will always return false; Once we
  //       add support for corporate admins, this check will start working
  //       after we update the check in the Auth class.
  const isAdmin = isSuperAdmin() || Auth.isCorporateAdmin();

  // TS-MIGRATION NOTE: These (and the types above) should use the generated types
  // everyone can upload user grants
  let grants: Record<string, GrantInput[]> = {
    [LIBRARY_SCOPES.User]: [
      {
        grantee: {
          scope: GRANTEE_SCOPES.user as GranteeScope,
          userId: me.id
        },
        permissions: ['read', 'write', 'delete', 'owner'] as Permission[]
      }
    ]
  };

  if (isAdmin) {
    grants = {
      ...grants,
      [LIBRARY_SCOPES.Corporate]: [
        {
          grantee: {
            scope: GRANTEE_SCOPES.org_wide as GranteeScope,
            organizationId: me.organizationId
          },
          permissions: ['read'] as Permission[]
        },
        {
          grantee: {
            scope: GRANTEE_SCOPES.organization_user_role as GranteeScope,
            organizationId: me.organizationId,
            organizationUserRole: 'admin' as OrganizationUserRole
          },
          permissions: ['read', 'write', 'delete'] as Permission[]
        }
      ],
      [LIBRARY_SCOPES.Internal]: [
        {
          grantee: {
            scope: GRANTEE_SCOPES.organization_user_role as GranteeScope,
            organizationId: me.organizationId,
            organizationUserRole: 'admin' as OrganizationUserRole
          },
          permissions: ['read', 'write', 'delete'] as Permission[]
        }
      ]
    };
  }
  return grants;
};

export const modalTypes = {
  delete: 'delete',
  shareWithTeam: 'shareWithTeam',
  unshareWithTeam: 'unshareWithTeam'
} as const;

export type GalleryModalType = (typeof modalTypes)[keyof typeof modalTypes];
