import { Box } from '@mui/material';

interface MessageBackgroundProps {
  children: React.ReactNode;
  bgcolor?: string;
}

const defaultBgColor = 'grey.100';

const MessageBackground = ({
  bgcolor = defaultBgColor,
  children,
  ...rest
}: MessageBackgroundProps) => {
  return (
    <Box
      {...rest}
      sx={{
        bgcolor,
        px: 2.5,
        py: 1,
        borderRadius: 2
      }}
    >
      {children}
    </Box>
  );
};

export default MessageBackground;
