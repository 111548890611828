import { t } from 'i18next';

import {
  GridColDef,
  GridRenderCellParams,
  GridAlignment
} from '@mui/x-data-grid-pro';

import { formatDate } from 'src/common/dates';
import StatusGroupingCell from 'src/components/Table/StatusGroupingCell';

import {
  getThemeOperators,
  getTypeOperators,
  getAutomationNameOperators
} from '../filterOperators';
import {
  getBlueprintOptionsFromArchitectures,
  getRulesAsChips,
  getTypeByIdFromArchitectures
} from '../helpers';
import { AUTOMATION_TABLE_FIELDS } from './constants';

export const groupingColumn = (
  setGroupExpandedByDefault: (expanded: boolean) => Promise<void>
) => {
  return {
    align: 'center' as GridAlignment,
    disableColumnMenu: true,
    disableReorder: true,
    editable: true,
    field: AUTOMATION_TABLE_FIELDS.row,
    filterable: false,
    headerAlign: 'center' as GridAlignment,
    headerName: t('automations:tableHeader.status'),
    sortable: false,
    type: 'string',
    width: 125,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <StatusGroupingCell
          params={params}
          setGroupExpandedByDefault={setGroupExpandedByDefault}
          isAutomation
        />
      );
    }
  };
};

export const getAutomationsColumns = (
  architectures: {
    products: any[];
  }[] = [],
  architectureNameById: any
) => {
  const initialColumns: GridColDef[] = [
    {
      minWidth: 150,
      flex: 1,
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      field: AUTOMATION_TABLE_FIELDS.name,
      filterable: false,
      headerAlign: 'left',
      headerName: t('automations:tableHeader.name'),
      sortable: false,
      type: 'string',
      filterOperators: getAutomationNameOperators(),
      renderCell: (params: GridRenderCellParams) => {
        if (params.rowNode.type === 'group') {
          return params.rowNode.groupingKey;
        }

        return params.value;
      }
    },
    {
      minWidth: 100,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      field: 'scheduleStartEpochSeconds',
      filterable: false,
      headerAlign: 'center',
      headerName: t('automations:tableHeader.startDate'),
      sortable: false,
      type: 'string',
      renderCell: ({ value, rowNode }: GridRenderCellParams) => {
        if (rowNode.type === 'group') {
          return null;
        }

        if (!value) {
          return '-';
        }

        return (
          <>
            {formatDate({
              date: new Date(value * 1000),
              type: 'DAY'
            })}
          </>
        );
      }
    },
    {
      minWidth: 200,
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      field: AUTOMATION_TABLE_FIELDS.theme,
      filterable: true,
      headerAlign: 'left',
      headerName: t('automations:tableHeader.theme'),
      hideable: true,
      sortable: false,
      type: 'singleSelect',
      valueOptions: architectures.map((architecture: any) => {
        return {
          label: architecture.name,
          value: architecture.id
        };
      }),
      renderCell: (params: GridRenderCellParams) => {
        const { row, rowNode } = params;

        if (rowNode.type === 'group') {
          return null;
        }

        return (
          architectureNameById?.[row?.offer?.product?.architectureId] ||
          architectureNameById?.[row?.architecture?.id] || // draft automations
          row?.offer?.product?.architectureId ||
          row?.architecture?.id // draft automations
        );
      },
      filterOperators: getThemeOperators(architectureNameById)
    },
    {
      minWidth: 200,
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      field: AUTOMATION_TABLE_FIELDS.type,
      filterable: true,
      headerAlign: 'left',
      headerName: t('automations:tableHeader.type'),
      sortable: false,
      type: 'singleSelect',
      valueOptions: getBlueprintOptionsFromArchitectures(architectures, true),
      renderCell: ({ row, rowNode }: GridRenderCellParams) => {
        if (rowNode.type === 'group') {
          return null;
        }

        return <>{row?.offer?.product?.name || row?.product?.name}</>; // regular || draft automations
      },
      filterOperators: getTypeOperators(
        getTypeByIdFromArchitectures(architectures, true)
      )
    },
    {
      minWidth: 150,
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      field: AUTOMATION_TABLE_FIELDS.filters,
      filterable: false,
      headerAlign: 'center',
      headerName: t('automations:tableHeader.rules'),
      sortable: false,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => {
        const { row, rowNode } = params;

        if (rowNode.type === 'group') {
          return null;
        }

        const { catalogFilter, catalog } = row;
        const { fieldMetadata, friendlyName } = catalog ?? {};
        const collapseView = true;

        if (!catalog) {
          return '-';
        }

        return getRulesAsChips(
          catalogFilter,
          fieldMetadata,
          collapseView,
          friendlyName
        );
      }
    }
  ];

  return initialColumns;
};
