import { gql } from 'src/generated/gql';

export const loadOrganizations = gql(`
  query LoadOrganizationsForChannelSpend($masterToken: String!) {
    admin(masterToken: $masterToken) {
      organizations {
        id
        name
      }
    }
  }
`);

export const loadChannelSpendAllocationConfigs = gql(`
  query getChannelSpendAllocationConfig(
    $filter: ChannelSpendAllocationConfigQueryFilter!
  ) {
    getChannelSpendAllocationConfigs(filter: $filter) {
      configs {
        poolTypeSlug
        priority
        enabled
        projectId
        offerId
        organizationId
        rules {
          channelCode
          weight
        }
      }
    }
  }
`);

export const upsertChannelSpendAllocationConfig = gql(`
  mutation upsertChannelSpendAllocationConfig(
    $upsert: ChannelSpendAllocationConfigUpsertInput!
  ) {
    upsertChannelSpendAllocationConfig(upsert: $upsert) {
      poolTypeSlug
      priority
      enabled
      projectId
      offerId
      organizationId
      rules {
        channelCode
        weight
      }
    }
  }
`);
