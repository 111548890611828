import { isString } from 'lodash';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import numeral from 'numeral';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { formatNumber } from 'src/common/numbers';
import { useController } from 'react-hook-form';

import { ContentSetFieldMetadata } from './helpers';

type RenderFilterValueNumberProps = {
  selectedColumn: ContentSetFieldMetadata;
  name: string;
  normalize: (value: string) => string;
  validate: any;
};

const RenderFilterValueNumber = ({
  selectedColumn,
  name,
  normalize,
  validate
}: RenderFilterValueNumberProps) => {
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ name, rules: { validate } });
  const showError = !!error;

  const isCurrency =
    selectedColumn?.displayMethodId === INPUT_TYPES.PRICE_INT ||
    selectedColumn?.displayMethodId === INPUT_TYPES.PRICE_DECIMAL;

  const updateValue = ({ target: { value } }: React.BaseSyntheticEvent) => {
    if (value === '') {
      return onChange(normalize(value));
    }
    const newVal = isCurrency ? numeral(value).value() : value;
    return onChange(normalize(newVal));
  };

  const formatedValue = isCurrency
    ? `$${value === '' ? '' : formatNumber(value)}`
    : value;

  if (error && !isString(error?.message)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }

  return (
    <FormControl error={showError}>
      <TextField
        value={formatedValue}
        onChange={updateValue}
        name={name}
        error={showError}
        placeholder="Enter Value"
        variant="standard"
      />
      {showError && (
        <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
          {error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderFilterValueNumber;
