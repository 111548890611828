import { t } from 'i18next';
import { AppSettingsContextType } from 'src/AppSettings';

export const getAppName = (appSettings: AppSettingsContextType) => {
  return (
    appSettings?.app?.general?.appName || appSettings?.app?.organizationName
  );
};

export const getOfficeName = (appSettings: AppSettingsContextType) => {
  return appSettings?.app?.general?.officeName || t('office.name');
};
