import { useMemo, useState } from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { t } from 'i18next';
import classnames from 'classnames';
import { Button, Typography, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import RollbackIcon from '@mui/icons-material/History';
import CopyIcon from '@mui/icons-material/FileCopy';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import { formatDate } from 'src/common/dates';
import Heading from 'src/components/PageElements/Heading';
import Modal from 'src/components/Modal';

import { selectNextStep } from './actions';
import { statusMap, statusTextMap } from './Constants';

const styles = theme => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(4)}`,

    '& button': {
      marginRight: theme.spacing(3)
    },

    '& button:last-child': {
      marginRight: 0
    }
  },
  status: {
    padding: `0 ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius,

    '& svg': {
      verticalAlign: 'sub'
    }
  },
  statusClosed: {
    marginRight: theme.spacing(3),
    '& span': {
      color: theme.palette.success[400]
    }
  },
  errorButton: {
    background: theme.palette.error.main,
    borderColor: theme.palette.error.dark,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark
    }
  },
  iconButtonText: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),

    '& svg': {
      height: '40px',
      marginBottom: theme.spacing(2),
      width: '40px'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  modified: {
    fontSize: `14px`,
    fontWeight: 'normal',
    color: theme.palette.grey[500]
  }
});

const pageText = () => ({
  copy: t('admin:blueprintBuilder.stepSetupCopy'),
  edit: t('admin:blueprintBuilder.stepSetupEdit'),
  rollback: t('admin:blueprintBuilder.stepSetupRollback'),
  selectBlueprint: t('admin:blueprintBuilder.stepSetupSelectBlueprintLabel'),
  tableHeading: t('admin:blueprintBuilder.stepSetupTableHeading'),
  importExport: t('admin:blueprintBuilder.stepSetupImportExport'),
  pageTitle: t('admin:blueprintBuilder.pageTitle')
});

const BlueprintBuilderHeader = props => {
  const {
    classes,
    openRollback,
    selectedProduct,
    selectNextStep,
    openImportExport
  } = props;
  const text = useMemo(() => pageText(), []);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const openErrorModal = () => {
    setErrorModalOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  return (
    <div>
      <Heading
        title={
          <>
            {selectedProduct?.document?.name} -{' '}
            {selectedProduct?.productId ?? '-'}
          </>
        }
        subTitle={
          <>
            Modified:{' '}
            {selectedProduct?.versionTimestamp
              ? formatDate({ date: selectedProduct?.versionTimestamp })
              : '-'}
          </>
        }
        pageTitle={text.pageTitle}
      />

      <div className={classes.buttonContainer}>
        {selectedProduct?.status === statusMap.error ? (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={openErrorModal}
            startIcon={<ErrorIcon />}
            className={classes.errorButton}
          >
            {statusTextMap.error}
          </Button>
        ) : (
          <Typography
            variant="button"
            className={classnames(classes.status, {
              [classes.statusClosed]:
                statusMap.closed === selectedProduct?.status,
              [classes.statusOpen]: statusMap.open === selectedProduct?.status,
              [classes.statusCommitted]:
                statusMap.committed === selectedProduct?.status
            })}
          >
            <span>
              {statusMap.closed === selectedProduct?.status && (
                <>
                  <CheckIcon /> {statusTextMap[statusMap.closed]}
                </>
              )}
              {statusMap.open === selectedProduct?.status && (
                <>
                  <EditIcon /> {statusTextMap[statusMap.open]}
                </>
              )}
            </span>
            {statusMap.committed === selectedProduct?.status && (
              <>
                <CircularProgress size={20} />{' '}
                {statusTextMap[statusMap.committed]}
              </>
            )}
          </Typography>
        )}
        <Button
          color="primary"
          disabled
          variant="outlined"
          size="small"
          onClick={selectNextStep}
          startIcon={<CopyIcon />}
        >
          {text.copy}
        </Button>

        <Button
          color="primary"
          disabled={selectedProduct?.status === statusMap.committed}
          variant="outlined"
          size="small"
          onClick={openRollback}
          startIcon={<RollbackIcon />}
        >
          {text.rollback}
        </Button>

        <Button
          color="primary"
          disabled={selectedProduct?.status === statusMap.committed}
          variant="outlined"
          size="small"
          onClick={openImportExport}
          startIcon={<ImportExportIcon />}
        >
          {text.importExport}
        </Button>
      </div>
      <Modal
        open={errorModalOpen}
        headerText="Error"
        fullWidth
        maxWidth="lg"
        onClose={closeErrorModal}
        FooterComponent={() => (
          <>
            <Button onClick={closeErrorModal}>{text.close}</Button>
          </>
        )}
      >
        {selectedProduct?.errorMessage}
      </Modal>
    </div>
  );
};

export default flow(
  connect(null, { selectNextStep }),
  withStyles(styles)
)(BlueprintBuilderHeader);
