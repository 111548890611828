import { FormSection } from 'redux-form';

type ReduxFormSectionProps = {
  children: React.ReactNode;
  isHookForm: boolean;
  name: string;
};

const ReduxFormSection = ({
  children,
  isHookForm,
  name
}: ReduxFormSectionProps) => {
  if (isHookForm) {
    return <>{children}</>;
  }
  return <FormSection name={name}>{children}</FormSection>;
};

export default ReduxFormSection;
