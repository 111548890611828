import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { GOOGLE_PREVIEW_WIDTH, STYLES } from '../GoogleAdPreviewConstants';
import { getPreviewWidth } from '../../helpers';

const Container = styled('div')(props => ({
  alignItems: 'center',
  border: `1px solid ${props.theme.palette.grey[200]}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: props.width
}));

const Headline = styled('div')({
  color: STYLES.color.headline,
  display: 'block',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '17px',
  whiteSpace: 'nowrap',
  width: '100%'
});

const BusinessName = styled('div')({
  color: STYLES.color.businessName,
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  whiteSpace: 'nowrap'
});

const Description = styled('div')(props => ({
  color: STYLES.color.description,
  display: 'inline-block',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: ' 12px',
  fontWeight: ' 300',
  lineHeight: ' 14px',
  padding: props.theme.spacing(1, 0),
  whiteSpace: 'normal',
  width: '100%'
}));

const OpenButton = styled('div')({
  alignItems: 'center',
  background: 'grey[800]',
  borderRadius: '40px',
  color: 'white',
  display: 'flex',
  fontWeight: 'bold',
  height: '30px',
  justifyContent: 'center',
  width: '150px'
});

const DisplayAdNoMainImage = props => {
  const {
    logoImage,
    headline,
    description,
    businessName,
    hasUniformPreviewWidth,
    isResponsive
  } = props;

  const previewWidth = getPreviewWidth(
    hasUniformPreviewWidth,
    GOOGLE_PREVIEW_WIDTH,
    isResponsive
  );

  return (
    <Container width={previewWidth}>
      <Box
        sx={{
          padding: 1,
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%'
          }}
        >
          {!!logoImage && (
            <Box
              sx={{
                '& img': {
                  marginRight: '12px',
                  width: '50px'
                }
              }}
            >
              {logoImage}
            </Box>
          )}

          <Box
            sx={{
              flexGrow: '2'
            }}
          >
            <Headline>{headline}</Headline>
            <BusinessName>{businessName}</BusinessName>
          </Box>
        </Box>
        <Description>{description}</Description>
      </Box>
      <Box
        sx={{
          background: 'grey[200]',
          display: 'flex',
          justifyContent: 'center',
          padding: 1,
          width: '100%'
        }}
      >
        <OpenButton>OPEN</OpenButton>
      </Box>
    </Container>
  );
};

export default DisplayAdNoMainImage;
