import { Events } from './constants';
import {
  initialize,
  setUserProperties,
  logEvent,
  getContextHeader,
  setGroup
} from './instrumentation';

export default {
  Events,
  initialize,
  setUserProperties,
  logEvent,
  getContextHeader,
  setGroup
};
