import { useState, useCallback } from 'react';
import { t } from 'i18next';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Table from 'src/components/Table';
import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';

import { getImplicitFilterInputs } from './constants';

import ImplicitFilterForm from './ImplicitFilterForm';

const ImplicitFilterList = ({ refetch, catalog }) => {
  const [editFilter, setEditFilter] = useState(null);
  const implicitFilters = catalog?.catalogImplicitFilters;
  const handleRowClick = useCallback(
    row => {
      setEditFilter(row);
    },
    [catalog]
  );
  const clearEditFilter = useCallback(() => {
    setEditFilter(null);
  }, []);
  const newImplicitFilter = useCallback(() => {
    setEditFilter({
      ...getInitialValuesFromInputsConfig(getImplicitFilterInputs()),
      catalogId: catalog.id,
      isNew: true
    });
  }, []);
  const columnSchema = [
    {
      key: 'editme',
      columnName: '',
      accessor: 'editme',
      CellComponent: () => (
        <div>
          <EditIcon />
        </div>
      )
    },
    ...getImplicitFilterInputs().map(field => ({
      key: field.name,
      columnName: field.name,
      accessor: field.name,
      type: field.displayMethodId
    }))
  ];
  return (
    <>
      <Table
        columnSchema={columnSchema}
        rows={implicitFilters}
        onClickBodyRow={handleRowClick}
        loading={false}
      />
      <br />
      <Button
        variant="outlined"
        color="primary"
        onClick={newImplicitFilter}
        startIcon={<AddIcon />}
      >
        {t('admin:catalogContent.implicitFilterNewButton')}
      </Button>
      <br />
      {editFilter && (
        <ImplicitFilterForm
          form={`implicitFilter${catalog.id}-${editFilter?.id}`}
          initialValues={editFilter}
          refetch={refetch}
          close={clearEditFilter}
        />
      )}
    </>
  );
};

export default ImplicitFilterList;
