import { difference } from 'lodash';

export type Permission =
  | 'read'
  | 'write'
  | 'delete'
  | 'share'
  | 'owner'
  | 'manage_tiktok'
  | 'partner_portal_login'
  | 'manage_audience';

interface HasPermissionsArgs {
  permissions: Permission[];
  contentPermissions: Permission[];
}

export const PERMISSIONS = {
  read: 'read',
  write: 'write',
  delete: 'delete',
  share: 'share',
  owner: 'owner',
  manage_tiktok: 'manage_tiktok',
  partner_portal_login: 'partner_portal_login',
  manage_audience: 'manage_audience'
};

// Pass this function an array of any number of permissions to check and an array of content permissions returned from the accessControl: { permissions }
// field when querying for content to determine if the user has necessary permissions.
export const hasPermissions = ({
  permissions,
  contentPermissions
}: HasPermissionsArgs) => {
  return !difference(permissions, contentPermissions).length;
};
