import { cloneDeep, isEmpty } from 'lodash';
import { OFFICE_THEME_OVERRIDE_LOCAL_STORAGE } from 'src/components/theme/Constants';
import { deepOmitEmpty } from 'src/common/utilities';
import {
  SKIN_PREVIEW_LOCAL_STORAGE,
  FONT_INPUT_METHOD,
  getDefaultFontList
} from './Constants';

export const clearSkinPreview = () => {
  localStorage.removeItem(OFFICE_THEME_OVERRIDE_LOCAL_STORAGE);
  localStorage.removeItem(SKIN_PREVIEW_LOCAL_STORAGE);
};

export const formatSkinData = data => {
  const formatted = deepOmitEmpty(cloneDeep(data));

  if (formatted?.fonts) {
    const cdnFontFamily = formatted?.fonts?.primary?.cdnFontFamily;

    const isCustomCdn =
      data?.fonts?.fontInputMethod === FONT_INPUT_METHOD.customCdn;

    if (isCustomCdn) {
      formatted.fonts.primary.fontFamily = cdnFontFamily;
      delete formatted.fonts.primary.cdnFontFamily;
    } else if (
      formatted.fonts.primary.fontFamily === getDefaultFontList()[0].value
    ) {
      delete formatted.fonts;
    } else {
      delete formatted.fonts.primary.url;
      delete formatted.fonts.primary.cdnFontFamily;
    }

    if (formatted?.fonts?.fontInputMethod) {
      delete formatted.fonts.fontInputMethod;
    }
  }

  return formatted;
};

export const formatInitialFontValues = initialValues => {
  const initialFontInputMethod = initialValues?.fonts?.primary?.url
    ? FONT_INPUT_METHOD.customCdn
    : FONT_INPUT_METHOD?.selectDefault;

  const defaultFonts = getDefaultFontList();

  const isCustomCdn = initialFontInputMethod === FONT_INPUT_METHOD.customCdn;

  const initialFontValues = !isEmpty(initialValues?.fonts?.primary)
    ? {
        fontInputMethod: initialFontInputMethod,
        primary: {
          url: initialValues?.fonts?.primary?.url || '',
          cdnFontFamily: isCustomCdn
            ? initialValues?.fonts?.primary?.fontFamily
            : '',
          fontFamily: isCustomCdn
            ? defaultFonts[0].value
            : initialValues?.fonts?.primary?.fontFamily || defaultFonts[0].value
        }
      }
    : {
        fontInputMethod: FONT_INPUT_METHOD.selectDefault,
        primary: { fontFamily: defaultFonts[0].value }
      };

  return initialFontValues;
};
