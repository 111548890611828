import GalleryModal from 'src/pages/Gallery/GalleryModal';
import { ReactNode } from 'react';
import { WrappedFieldProps } from 'redux-form';

export interface RenderGallerySelectorProps extends WrappedFieldProps {
  galleryType: string;
  mediaType?: string;
  showFilter?: boolean;
  sizeConstraint?: boolean;
  children?: ReactNode;
  tooltip?: string;
  helperText?: string;
  type?: string;
  label: string;
}

const RenderGallerySelector = (props: RenderGallerySelectorProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    children,
    input: { onChange, value, name },
    galleryType,
    mediaType,
    showFilter,
    sizeConstraint,
    ...rest
  } = props;

  return (
    <GalleryModal
      name={name}
      onSelect={asset => {
        return onChange(asset == null ? '' : asset);
      }}
      galleryType={galleryType}
      selection={value}
      sizeConstraint={sizeConstraint}
      mediaType={mediaType}
      showFilter={showFilter}
      {...rest}
    />
  );
};

export default RenderGallerySelector;
