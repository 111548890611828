import { SVGProps } from 'react';

const Visa = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="20"
      viewBox="0 0 66 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.0616 19.7716L26.3633 0.3961H31.6444L28.3402 19.7716H23.0616ZM47.4204 0.813777C46.3744 0.42124 44.7347 0 42.6873 0C37.4687 0 33.7931 2.6279 33.7616 6.39422C33.7322 9.17831 36.3859 10.7315 38.3891 11.6582C40.4449 12.6082 41.1359 13.2137 41.126 14.0617C41.1128 15.3605 39.4844 15.9536 37.9663 15.9536C35.8527 15.9536 34.7297 15.6599 32.9952 14.9368L32.3145 14.6289L31.5735 18.9662C32.807 19.5073 35.0879 19.9757 37.4564 20C43.0079 20 46.6118 17.4021 46.6528 13.38C46.6725 11.1758 45.2655 9.49869 42.2186 8.1157C40.3727 7.21938 39.2422 6.62127 39.2542 5.71366C39.2542 4.9083 40.2111 4.04711 42.2789 4.04711C44.0056 4.02029 45.2568 4.39689 46.2315 4.78943L46.7047 5.01302L47.4204 0.813777ZM61.0108 0.395803H56.93C55.6659 0.395803 54.7197 0.740833 54.1646 2.00257L46.3213 19.7588H51.867C51.867 19.7588 52.7735 17.3714 52.9786 16.8471C53.5846 16.8471 58.9721 16.8554 59.7422 16.8554C59.9002 17.5338 60.3848 19.7588 60.3848 19.7588H65.2852L61.0108 0.395209V0.395803ZM54.5361 12.9073C54.973 11.791 56.6403 7.49097 56.6403 7.49097C56.6092 7.54253 57.0739 6.36918 57.3405 5.64171L57.6974 7.31222C57.6974 7.31222 58.7086 11.9372 58.92 12.907H54.5361V12.9073ZM18.5783 0.395803L13.4079 13.609L12.8571 10.9239C11.8944 7.82858 8.89557 4.47498 5.54297 2.79606L10.2707 19.7407L15.8584 19.7345L24.1728 0.395605L18.5783 0.395507"
        fill="#0E4595"
      />
      <path
        d="M8.58346 0.394531H0.0675017L0 0.797659C6.62533 2.40146 11.0091 6.27725 12.8292 10.9347L10.9775 2.03089C10.6579 0.803992 9.73069 0.437783 8.58366 0.395025"
        fill="#F2AE14"
      />
    </svg>
  );
};

export default Visa;
