import { SyntheticEvent, useState } from 'react';
import { sortBy } from 'lodash';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import {
  Fab,
  Theme,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Menu,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/styles';
import HelpIcon from '@mui/icons-material/HelpOutline';

import { ThemeAugmentations } from 'src/AppThemeWrapper';
import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { QuickAutomation as QuickAutomationIcon } from 'src/components/Icons';
import { convertFilterJSONtoFiltersArray } from 'src/components/AutomatedProgramFilter/helpers';
import IconComponent, {
  IconType
} from 'src/components/IconSelector/IconComponent';

interface AutomationQuickStart {
  catalogFilter: Record<string, unknown>;
  icon: IconType;
  id: string;
  name: string;
  priority: number;
  productQuickStartId: string;
}

interface QuickAutomationButtonProps {
  architectureId: string;
  automationQuickStarts: AutomationQuickStart[];
  productIds: string[];
  channels: string[];
}

const pageText = () => ({
  menuHeader: t('quickAutomation:menu.header'),
  menuTooltip: t('quickAutomation:menu.tooltip')
});

const menuId = 'quick-automations-menu';

const QuickAutomationButton = ({
  automationQuickStarts,
  architectureId,
  productIds,
  channels
}: QuickAutomationButtonProps) => {
  const theme = useTheme();
  const text = pageText();

  const [menuAnchorEle, setMenuAnchorEle] = useState<Element | null>(null);

  const handleDropdownButtonClick = (event: SyntheticEvent) => {
    Instrumentation.logEvent(Events.ClickQuickAutomationButton, {
      architectureId,
      productIds,
      channel: channels
    });
    setMenuAnchorEle(event.currentTarget);
  };

  const open = Boolean(menuAnchorEle);

  const handleMenuItemClick = (automation: AutomationQuickStart) => {
    Instrumentation.logEvent(Events.ClickQuickAutomationRule, {
      architectureId,
      productIds,
      channel: channels,
      name: automation?.name
    });
  };

  const dropdownButtonId = `quick-automations-button-${productIds.join('-')}`;

  return (
    <>
      <Tooltip title={t('quickAutomation:fab.tooltip')}>
        <Fab
          id={dropdownButtonId}
          size="medium"
          onClick={handleDropdownButtonClick}
          sx={{
            position: 'absolute',
            bottom: '-10px',
            right: '-20px',
            backgroundColor: 'white',
            borderColor: 'white',
            fontWeight: 400,
            '&:hover': {
              backgroundColor: theme => theme.palette.grey[200],
              borderColor: theme => theme.palette.grey[200]
            },
            '& svg': {
              height: '30px',
              width: '30px'
            }
          }}
        >
          <QuickAutomationIcon
            fill={(theme as Theme & ThemeAugmentations).palette.primary.main}
          />
        </Fab>
      </Tooltip>
      <Menu
        id={menuId}
        anchorEl={menuAnchorEle}
        open={open}
        onClose={() => setMenuAnchorEle(null)}
        MenuListProps={
          {
            'aria-labelledby': { dropdownButtonId }
          } as any
        }
      >
        <MenuItem
          sx={{
            pointerEvents: 'auto !important', // allow the tooltip to work on the disabled menu item
            opacity: '1 !important',
            '&:hover': { background: 'transparent ' }
          }}
          disabled
          key="quick-automation-menu-header"
        >
          <ListItemText
            sx={{
              marginRight: '12px',
              '& span': {
                fontWeight: '600',
                color: 'rgb(117, 117, 117)'
              }
            }}
          >
            {text.menuHeader}
          </ListItemText>

          <ListItemIcon
            sx={{
              cursor: 'pointer',
              '& svg': { width: '20px', height: '20px' }
            }}
          >
            <Tooltip title={text.menuTooltip} placement="top" arrow>
              <HelpIcon fill="rgb(117, 117, 117)" />
            </Tooltip>
          </ListItemIcon>
        </MenuItem>
        {sortBy(automationQuickStarts, 'priority').map(automation => {
          const filters = convertFilterJSONtoFiltersArray(
            automation?.catalogFilter
          );
          const hasFilterParams = filters && filters.length > 0;

          const linkPath = generateLinkPathWithQueryParams(
            paths.architecture.automatedProgramCreate,
            {
              architectureId
            },
            {
              showWizard: false,
              productIds,
              ...(hasFilterParams && {
                filters: encodeURI(JSON.stringify(filters))
              })
            }
          );

          return (
            <MenuItem
              component={Link} // setting this to a link allows command || right click to open in new tab
              to={linkPath}
              key={automation.id}
              onClick={() => handleMenuItemClick(automation)}
            >
              {automation?.icon && (
                <ListItemIcon>
                  <IconComponent icon={automation.icon} />
                </ListItemIcon>
              )}

              <ListItemText sx={{ marginRight: '12px' }}>
                {automation.name}
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default QuickAutomationButton;
