import { gql } from 'src/generated/gql';

export const getApiSecrets = gql(`
  query getApiSecrets {
    myOrganization {
      id
      adminApiSecrets {
        id
        clientKeyId
        clientKeySecret
        createdAt
      }
      internalAuthConfig {
        id
        jwtSigningSecret
      }
    }
  }
`);
