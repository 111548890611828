import { get } from 'lodash';
import { ErrorResponse } from '@apollo/client/link/error';
import { t } from 'i18next';

export const BILLING_METHODS = {
  card: 'USER_CREDIT_CARD',
  partnerInvoice: 'PARTNER_INVOICE'
};

interface PaymentMethod {
  id: string;
  stripeSourceId?: string | null;
}

export const extractPaymentMethodId = (
  paymentMethods: PaymentMethod[] = [],
  stripeSourceId: string
) => {
  const foundPaymentMethod = paymentMethods.find(
    o => o.stripeSourceId === stripeSourceId
  );

  return get(foundPaymentMethod, 'id');
};

// Initial setup used to pull extra messages (such as invalid cvc, etc.) from
// the API response. We can extend this later as we see fit.
export const extractCcErrorMessage = (error: ErrorResponse) => {
  return (
    get(error, 'graphQLErrors[0].exception.localizedMessage', null) ||
    get(error, 'graphQLErrors[0].exception.message', null)
  );
};

export const genericCardDeclinedError = () =>
  t('paymentErrors:snackbar.cardDeclined');

export const incompleteCardNumberError = () =>
  t('paymentErrors:snackbar.incomplteCardNumber');

export const paymentErrorByBackendDisplayCode = (displayCode: string) => {
  const messages = {
    INSUFFICIENT_FUNDS_CARD_DECLINE: t(
      'paymentErrors:snackbar.insufficientFundsCardDecline'
    ),
    LOST_CARD_DECLINE: t('paymentErrors:snackbar.lostCardDecline'),
    STOLEN_CARD_DECLINE: t('paymentErrors:snackbar.stolenCardDecline'),
    CARD_VELOCITY_EXCEEDED_DECLINE: t(
      'paymentErrors:snackbar.cardVelocityExceededDecline'
    ),
    PROCESSING_ERROR_DECLINE: t(
      'paymentErrors:snackbar.processingErrorDecline'
    ),
    INCORRECT_CVC_DECLINE: t('paymentErrors:snackbar.incorrectCvcDecline'),
    EXPIRED_CARD_DECLINE: t('paymentErrors:snackbar.expiredCardDecline'),
    TRY_AGAIN_LATER_DECLINE: t('paymentErrors:snackbar.tryAgainLaterDecline'),
    THREE_DS_NOT_SUPPORTED_DECLINE: t(
      'paymentErrors:snackbar.threeDsNotSupportedDecline'
    ),
    GENERIC_CARD_DECLINE: t('paymentErrors:snackbar.genericCardDecline'),
    UNKNOWN_ERROR: t('paymentErrors:snackbar.unknownError')
  };

  return (
    messages[displayCode as keyof typeof messages] || messages.UNKNOWN_ERROR
  );
};
