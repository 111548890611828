import { useState, ChangeEvent, useEffect } from 'react';
import { isEmpty, isNull, flow } from 'lodash';
import { t } from 'i18next';
import { change } from 'redux-form';
import { connect } from 'react-redux';

import { Box, TextField, FormControl, FormHelperText } from '@mui/material';
import LabelIcon from '@mui/icons-material/Label';

import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import IconSelector from 'src/components/IconSelector';

import { FORM_NAME } from '../constants';

const pageText = () => ({
  confirm: t('admin:manageQuickstart.buttons.addRuleSave'),
  addRuleHeader: t('admin:manageQuickstart.headings.addRule'),
  ruleLabel: t('admin:manageQuickstart.label.ruleLabel')
});

interface QuickAutomationDetailsModalProps {
  modalOpen: boolean;
  setModalClose: () => void;
  fields: any;
  quickStartId: string;
  setEditingIndex: (index: null | number) => void;
  editingIndex: number | null;
  change: any;
  setHasMoved: (hasMoved: boolean) => void;
}

const QuickAutomationDetailsModal = ({
  modalOpen,
  setModalClose,
  fields,
  quickStartId,
  setEditingIndex,
  editingIndex,
  change,
  setHasMoved
}: QuickAutomationDetailsModalProps) => {
  const text = pageText();

  const isEditing = !isNull(editingIndex);
  const fieldData = isEditing ? fields.get(editingIndex) : null;

  const [ruleLabel, setRuleLabel] = useState<string>(
    fieldData ? fieldData.name : ''
  );
  const [ruleIcon, setRuleIcon] = useState<string>(
    fieldData ? fieldData.icon : ''
  );
  const [ruleLabelError, setRuleLabelError] = useState<string>('');

  useEffect(() => {
    setRuleLabel(fieldData ? fieldData.name : '');
    setRuleIcon(fieldData ? fieldData.icon : '');
  }, [fieldData]);

  const ruleLabelChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setRuleLabel(value);
  };

  const ruleIconChange = (_: any, selected: any) => {
    setRuleIcon(selected?.value || '');
  };

  const handleAddRule = () => {
    if (isEmpty(ruleLabel)) {
      setRuleLabelError('Rule Label is required');
      return;
    }

    if (isEditing) {
      change(FORM_NAME, `${fields.name}[${editingIndex}].name`, ruleLabel);
      change(FORM_NAME, `${fields.name}[${editingIndex}].icon`, ruleIcon);
      setModalClose();
      return;
    }

    const fieldLength = fields.length;
    const newIndex = fieldLength;

    const newRule = {
      newRule: true,
      catalogFilter: [],
      icon: ruleIcon,
      name: ruleLabel,
      priority: ((newIndex as number) + 1) * 1000,
      productQuickStartId: quickStartId
    };

    fields.push(newRule);
    setEditingIndex(newIndex);
    setHasMoved(true); // ensure priorities are all correct after new rule
    setModalClose();
  };

  return (
    <ConfirmationModal
      icon={<LabelIcon />}
      open={modalOpen}
      title={text.addRuleHeader}
      onClose={(event: any, reason: any) => {
        if (reason === 'backdropClick') {
          // don't close on backdrop click
          return;
        }
        setModalClose();
      }}
      onConfirm={handleAddRule}
      confirmButtonText={text.confirm}
    >
      <Box sx={{ padding: theme => theme.spacing(2), minWidth: '300px' }}>
        <FormControl fullWidth>
          <TextField
            value={ruleLabel}
            onChange={ruleLabelChange}
            error={!!ruleLabelError}
            placeholder={text.ruleLabel}
            variant="outlined"
            label={text.ruleLabel}
            sx={{
              marginBottom: theme => theme.spacing(2)
            }}
          />
          {ruleLabelError && (
            <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
              {ruleLabelError}
            </FormHelperText>
          )}
        </FormControl>

        <IconSelector onChange={ruleIconChange} value={ruleIcon} />
      </Box>
    </ConfirmationModal>
  );
};

export default flow(connect(null, { change }))(QuickAutomationDetailsModal);
