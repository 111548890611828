import { t } from 'i18next';

import { Box } from '@mui/material';
import { paths } from 'src/routes/paths';
import SelectQuickstartModal from 'src/pages/Programs/SelectQuickStartModal';
import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Heading from 'src/components/PageElements/Heading';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';

import { programActions, ProgramActionType } from './Constants';
import ProgramProvider from './utils/ProgramProvider';
import useQuickstartHandler from './utils/useQuickstartHandler';
import Program from './Program';
import { MainContainer } from './MainContainer';

const pageText = () => ({
  title: t('program:create.title'),
  previewButtonTip: t('program:preview.button'),
  missingBlueprintSubtitle: t('program:create.missingBlueprintSubtitle'),
  blueprintError: t('blueprintSelector:messages.errorGettingBps'),
  kind: t('program:kind')
});

type ProgramCreate = {
  type?: ProgramActionType;
  isEditing?: boolean;
};

const BOUNDING_BOX_STYLE = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  position: 'relative'
};

const ProgramCreate = ({ type, isEditing = false }: ProgramCreate) => {
  const text = pageText();

  const isAutomation =
    type === programActions.automatedCreate ||
    type === programActions.automatedEdit;

  const baseType = programActions.create;

  const {
    modalOpen,
    openBlueprintsModal,
    handleCloseModal,
    handleSelectBlueprint,
    blueprints,
    selectedBlueprint,
    selectedBlueprintId,
    loading,
    error,
    automation
  } = useQuickstartHandler(isAutomation, isEditing, type || baseType);

  if (error) {
    return <ErrorMessage>{text.blueprintError}</ErrorMessage>;
  }

  const computedType: ProgramActionType =
    selectedBlueprint?.isMlp && type !== programActions.automatedEdit
      ? programActions.multiLocationCreate
      : type || baseType;

  const QuickStartModal = () => (
    <SelectQuickstartModal
      open={modalOpen}
      onClose={handleCloseModal}
      isSupervisable={isAutomation}
      selectedProductId={selectedBlueprintId}
      selectProduct={handleSelectBlueprint}
    />
  );

  if (loading || !selectedBlueprint) {
    // loading can be in a state where selectedBlueprint is undefined but loading is false
    // we have "selected" a blueprint but it hasn't started loading yet
    // this causes some issues with showing the wrong version of the program
    return (
      <>
        <Loading />
        <QuickStartModal />
      </>
    );
  }

  return (
    <>
      <ProgramProvider
        type={computedType}
        isEditing={isEditing}
        blueprints={blueprints}
        selectedBlueprint={selectedBlueprint}
        openBlueprintsModal={openBlueprintsModal}
        handleSelectBlueprint={handleSelectBlueprint}
        automation={automation}
      >
        {computedType === programActions.multiLocationCreate ? (
          <MainContainer>
            <Box sx={BOUNDING_BOX_STYLE}>
              <BreadcrumbTrail
                sx={theme => ({ marginBottom: theme.spacing(1) })}
                pieces={[
                  {
                    text: text.kind,
                    to: paths.programs.base
                  },
                  {
                    text: text.title
                  }
                ]}
              />
              <Heading
                title={selectedBlueprint?.name ?? text.missingBlueprintSubtitle}
                pageTitle={text.title}
              />
              <Program type={computedType} isEditing={isEditing} />
            </Box>
          </MainContainer>
        ) : (
          <Box sx={BOUNDING_BOX_STYLE}>
            <Program type={computedType} isEditing={isEditing} />
          </Box>
        )}
      </ProgramProvider>
      <QuickStartModal />
    </>
  );
};

export default ProgramCreate;
