import { t } from 'i18next';
import { startCase } from 'lodash';

import { validateNotBlank, validateRequired } from 'src/common/validations';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const FORM_NAME = 'Admin_Auth0_form';

export const getAuth0Input = (name, isUpdate = false) => ({
  name,
  displayName: startCase(name),
  displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
  reduxValidations: isUpdate ? [] : [validateRequired, validateNotBlank()],
  isRequired: !isUpdate,
  isHidden: false,
  displaySortOrder: 1,
  displayParameters: {
    inputData: {
      columnWidth: 2
    }
  }
});

const getDefaultConfig = isUpdate => ({
  reduxValidations: isUpdate ? [] : [validateRequired, validateNotBlank()],
  displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
  isRequired: !isUpdate,
  isHidden: false,
  displaySortOrder: 1,
  displayParameters: {
    inputData: {
      columnWidth: 2
    }
  }
});

export const getAuthInputs = isUpdate => {
  const defaultConfig = getDefaultConfig(isUpdate);

  const fields = [
    {
      name: 'domain',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.domain.displayName',
        'Domain'
      )
    },
    {
      name: 'connectionType',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.connectionType.displayName',
        'Connection Type'
      )
    },
    {
      name: 'configurationBaseUrl',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.configurationBaseUrl.displayName',
        'Configuration Base Url'
      )
    },
    {
      name: 'spaClientId',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaClientId.displayName',
        'SPA Client Id'
      )
    },
    {
      name: 'spaAudience',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaAudience.displayName',
        'SPA Audience'
      )
    },
    {
      name: 'spaSigningSecret',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaSigningSecret.displayName',
        'SPA Signing Secret'
      )
    },
    {
      name: 'spaCallbackUrl',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaCallbackUrl.displayName',
        'SPA Callback Url'
      )
    },
    {
      name: 'spaDomain',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaDomain.displayName',
        'SPA Domain'
      )
    },
    {
      name: 'spaLogoutUrl',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaLogoutUrl.displayName',
        'SPA Logout Url'
      )
    },
    {
      name: 'spaResponseType',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaResponseType.displayName',
        'SPA Response Type'
      )
    },
    {
      name: 'spaScope',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.spaScope.displayName',
        'SPA Scope'
      )
    },
    {
      name: 'managementClientSecret',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.managementClientSecret.displayName',
        'Management Client Secret'
      )
    },
    {
      name: 'managementClientId',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.managementClientId.displayName',
        'Management Client Id'
      )
    },
    {
      name: 'managementBaseUrl',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.managementBaseUrl.displayName',
        'Management Base Url'
      )
    },
    {
      name: 'managementAudience',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.managementAudience.displayName',
        'Management Audience'
      )
    },
    {
      name: 'loginBaseUrl',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.loginBaseUrl.displayName',
        'Login Base Url'
      )
    },
    {
      name: 'loginClientSecret',
      displayName: t(
        'admin:authenticationSettings.getAuthInputs.loginClientSecret.displayName',
        'Login Client Secret'
      )
    }
  ];

  return fields.map(field => ({
    ...field,
    ...defaultConfig
  }));
};
