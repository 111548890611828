import { map, startCase } from 'lodash';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';

interface ObjectivesCount {
  [key: string]: number;
}

// This function gives us a list of objective metadata that we can ues to build
// filters for the quick starts. It returns three important fields for each objective:
// label (display name), slug (actual value that is passed to the API when filtering),
// count (how many quick starts were returned with this objective)

export const formatQuickStartObjectivesForFilters = (
  objectives: (string | undefined)[] | undefined = [],
  objectivesBySlug: { [key: string]: string } = {}
) => {
  const objectivesCount = objectives.reduce<ObjectivesCount>(
    (objectivesCount, objective) => {
      if (!objective) return objectivesCount;

      const count = objectivesCount[objective];

      let newObjectivesCount: ObjectivesCount | null = null;

      if (count) {
        newObjectivesCount = { ...objectivesCount, [objective]: count + 1 };
      } else {
        newObjectivesCount = { ...objectivesCount, [objective]: 1 };
      }

      return newObjectivesCount;
    },
    {}
  );

  // key is the objective name and value is the number of quickstarts with this objective
  return map(objectivesCount, (value, key) => ({
    label: objectivesBySlug[key] || startCase(key),
    value: key,
    count: value
  }));
};

interface GetQuickStartItemClickHandlerProps {
  selectProduct?: (productId: string, architectureId: string) => void;
  linkPath: string;
  productId: string;
  architectureId: string;
}

export const getQuickStartItemClickHandlerProps = ({
  selectProduct,
  linkPath,
  productId,
  architectureId
}: GetQuickStartItemClickHandlerProps) => {
  return selectProduct
    ? {
        onClick: () => {
          selectProduct(productId, architectureId);
        }
      }
    : {
        to: generateLinkPathWithQueryParams(
          linkPath,
          {
            architectureId
          },
          { productIds: productId }
        )
      };
};
