import AutomatedProgramFilterWizard from 'src/components/AutomatedProgramFilter/AutomatedProgramFilterWizard';

const FilterSelector = ({
  architecture = {},
  blueprints,
  contentName,
  type,
  selectedBlueprint,
  handleSelectBlueprint
}) => {
  return (
    <AutomatedProgramFilterWizard
      architecture={architecture}
      blueprints={blueprints}
      contentName={contentName}
      type={type}
      selectedBlueprint={selectedBlueprint}
      handleSelectBlueprint={handleSelectBlueprint}
    />
  );
};

export default FilterSelector;
