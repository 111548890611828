import { t } from 'i18next';

import { Divider, Accordion, AccordionDetails, Grid } from '@mui/material';

import CreateGoogleAccount from 'src/pages/Admin/AudienceTools/Forms/CreateGoogleAccount/CreateGoogleAccount';
import Heading from 'src/components/PageElements/Heading';

import AudienceToolHeading from './AudienceToolHeading';
import { AudienceCreationRuleTable } from './AudienceCreationRuleTable';
import CreateFacebookAudienceAccountCreationRuleForm from './Forms/CreateFacebookAudienceAccountCreationRuleForm';
import CreateGoogleUserListForm from './Forms/CreateGoogleUserListAccountCreationRule/CreateGoogleUserListForm';

const pageText = () => ({
  pageTitle: t('audienceTools:mainPage.pageTitle'),
  pageSubtitle: t('audienceTools:mainPage.pageSubtitle'),
  fbAudienceAccountCreationRule: t(
    'audienceTools:mainPage.fbAudienceAccountCreationRule'
  ),
  addGoogleUserList: t('audienceTools:mainPage.addGoogleUserList'),
  addGoogleAccount: t('audienceTools:mainPage.addGoogleAccount'),
  audienceCreationRule: t('audienceTools:mainPage.audienceCreationRule')
});

const AudienceTool = () => {
  const text = pageText();

  return (
    <>
      <Heading
        title={text.pageTitle}
        subTitle={text.pageSubtitle}
        pageTitle={text.pageTitle}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <AudienceToolHeading title={text.fbAudienceAccountCreationRule} />
            <AccordionDetails>
              <CreateFacebookAudienceAccountCreationRuleForm />
            </AccordionDetails>
            <Divider />
          </Accordion>
          <Accordion>
            <AudienceToolHeading title={text.addGoogleUserList} />
            <AccordionDetails>
              <CreateGoogleUserListForm />
            </AccordionDetails>
            <Divider />
          </Accordion>
          <Accordion>
            <AudienceToolHeading title={text.addGoogleAccount} />
            <AccordionDetails>
              <CreateGoogleAccount />
            </AccordionDetails>
            <Divider />
          </Accordion>
          <Accordion>
            <AudienceToolHeading title={text.audienceCreationRule} />
            <AccordionDetails>
              <AudienceCreationRuleTable />
            </AccordionDetails>
            <Divider />
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default AudienceTool;
