import { useRef, useState } from 'react';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import {
  ImageListItem,
  ImageListItemBar,
  Slide,
  Box,
  styled
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { AssetMenu, MenuIconButton, useMenu } from 'src/components/Menus';
import SharedAssetIcon from 'src/components/Teams/SharedAssetIcon';
import { useGlobalContext } from 'src/GlobalContextProvider';
import {
  logAssetEvent,
  assetEventTypes,
  assetTypes
} from 'src/common/orgAssets';

import { getAssetMenuOptions } from './utilities';
import TileWrapper from './Tiles/TileWrapper';
import { useToggleShareMediaAsset } from './hooks';
import {
  MEDIA_SOURCES,
  MEDIA_SOURCE_THIRD_PARTY,
  modalTypes,
  GalleryModalType
} from './constants';
import GalleryAssetModal from './GalleryAssetModal';
import { deleteAsset } from './mutations';

interface GalleryContentItemProps {
  allowSelect: boolean;
  isSelected: boolean;
  setHoveredId: (id: string | null) => void;
  hoveredId: string | null;
  asset: any;
  galleryType: string;
  tileClick: (e: React.MouseEvent<HTMLElement>) => void;
  canDelete: boolean;
  canShare: boolean;
  setLightboxedItem: (asset: any) => void;
  galleryRefetch: () => void;
}

interface PageTextArgs {
  groupName: string;
}

const pageText = ({ groupName }: PageTextArgs) => {
  return {
    filterContentSource: {
      [MEDIA_SOURCES.User]: t('gallery:content.user'),
      [MEDIA_SOURCES.Corporate]: t('gallery:content.corporate'),
      [MEDIA_SOURCE_THIRD_PARTY]: t('gallery:content.thirdParty')
    },
    menuButtonLabel: t('gallery:content.menuButtonLabel'),
    shareSuccess: t('gallery:toggleShare.success', {
      groupName
    }),
    unshareSuccess: t('gallery:toggleunShare.snackSuccess', {
      groupName
    }),
    unshareError: t('gallery:toggleunShare.snackError'),
    shareError: t('gallery:toggleShare.snackError'),
    removeSuccess: t('gallery:delete.snackSuccess'),
    removeError: t('gallery:delete.snackError')
  };
};

const CheckIconWrap = styled('span')(({ theme }) => ({
  height: '40px',
  width: '40px',
  borderRadius: '20px',
  margin: theme.spacing(0.5),
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: '10',
  background: '#fff'
}));

const GalleryContentItem = ({
  allowSelect,
  isSelected,
  setHoveredId,
  hoveredId,
  asset,
  galleryType,
  tileClick,
  canDelete,
  canShare,
  setLightboxedItem,
  galleryRefetch
}: GalleryContentItemProps) => {
  const [modalType, setModalType] = useState<GalleryModalType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { id, type, source, teamCapabilities } = asset;

  const isSharedAsset = teamCapabilities?.isSharedWithTeam;

  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;
  const groupName = globalContext?.office?.officeName;
  const text = pageText({ groupName });

  const { menuAnchorEl, handleOpenMenu, handleCloseMenu, isMenuOpen } =
    useMenu();

  const { toggleShareMediaAsset } = useToggleShareMediaAsset({
    isShared: teamCapabilities?.isSharedWithTeam
  });

  const [deleteAssetMutation] = useMutation(deleteAsset);

  const handleToggleShare = () => {
    toggleShareMediaAsset({ variables: { mediaAssetId: id } })
      .then(() => {
        enqueueSnackbar(
          isSharedAsset ? text.unshareSuccess : text.shareSuccess,
          {
            variant: 'success'
          }
        );

        setIsModalOpen(false);
        galleryRefetch();
      })
      .catch(() => {
        enqueueSnackbar(isSharedAsset ? text.unshareError : text.shareError, {
          variant: 'error'
        });
      });
  };

  const containerRef = useRef(null);

  const menuId = `media-asset-menu-${id}`;
  const buttonId = `media-asset-menu-button-${id}`;

  const handleView = () => {
    setLightboxedItem(asset);
  };

  const handleRemove = () => {
    deleteAssetMutation({
      variables: { assetId: id }
    })
      .then(() => {
        setIsModalOpen(false);
        galleryRefetch();
        enqueueSnackbar(text.removeSuccess, {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(text.removeError, {
          variant: 'error'
        });
      });
  };

  const options = getAssetMenuOptions({
    canDelete,
    canShare,
    isShared: isSharedAsset,
    handleRemove: () => {
      logAssetEvent({
        type: assetEventTypes.delete,
        properties: { object: assetTypes.mediaAsset }
      });
      setModalType(modalTypes.delete);
      setIsModalOpen(true);
    },
    handleView,
    toggleShare: () => {
      logAssetEvent({
        type: isSharedAsset ? assetEventTypes.unshare : assetEventTypes.share,
        properties: { object: assetTypes.mediaAsset }
      });
      setModalType(
        isSharedAsset ? modalTypes.unshareWithTeam : modalTypes.shareWithTeam
      );
      setIsModalOpen(true);
    }
  });

  return (
    <>
      <ImageListItem
        key={id}
        cols={1}
        sx={{
          overflow: 'hidden',
          position: 'relative',
          cursor: allowSelect ? 'pointer' : 'default',
          border: theme =>
            isSelected ? `5px solid ${theme.palette.primary.main}` : 'none',

          '& > div': {
            height: '100%'
          }
        }}
        onMouseEnter={() => setHoveredId(id)}
        onMouseLeave={() => setHoveredId(null)}
        data-cy="gallery-tile"
        data-cy-id={id}
      >
        <div ref={containerRef}>
          {isSelected && (
            <CheckIconWrap>
              <CheckCircleIcon
                sx={{
                  height: '40px',
                  width: '40px'
                }}
                color="primary"
              />
            </CheckIconWrap>
          )}
          <TileWrapper
            allowSelect={allowSelect}
            asset={asset}
            type={type}
            galleryType={galleryType}
            onClick={tileClick}
            showThumbnail
            data-amp-click-media-library-select-asset
          />

          <Slide
            direction="down"
            in={(!allowSelect || type === 'video') && hoveredId === id}
            container={containerRef.current}
          >
            <ImageListItemBar
              onClick={tileClick}
              data-amp-click-media-library-select-asset
              position="top"
              sx={{
                pl: 1.5,
                pt: 0,
                pb: isTeamsEnabled ? 1.5 : 0,
                '.MuiImageListItemBar-titleWrap': { p: 0 }
              }}
              title={
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}
                  >
                    {text.filterContentSource[source]}
                    <MenuIconButton
                      ariaLabel={text.menuButtonLabel}
                      ariaControls={isMenuOpen ? menuId : undefined}
                      ariaExpanded={isMenuOpen || undefined}
                      onClick={handleOpenMenu}
                      id={buttonId}
                      iconColor="#fff"
                    />

                    <AssetMenu
                      isYourAsset={teamCapabilities?.isOwnedByMe}
                      id={menuId}
                      handleClose={handleCloseMenu}
                      open={isMenuOpen}
                      ariaLabeledBy={buttonId}
                      anchorEl={menuAnchorEl}
                      options={options}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center'
                    }}
                  >
                    {isTeamsEnabled && (
                      <SharedAssetIcon isSharedAsset={isSharedAsset} />
                    )}
                  </Box>
                </Box>
              }
            />
          </Slide>
        </div>
      </ImageListItem>
      <GalleryAssetModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        remove={handleRemove}
        toggleShare={handleToggleShare}
        hasTeamCapabilities={isTeamsEnabled}
        groupName={groupName}
        type={modalType}
      />
    </>
  );
};

export default GalleryContentItem;
