import { gql } from 'src/generated/gql';

export const createGallery = gql(`
  mutation CreateGallery($name: String!, $galleryType: AssetType!) {
    createGallery(name: $name, type: $galleryType) {
      id
      name
      galleryType
    }
  }
`);

export const updateGallery = gql(`
  mutation UpdateGallery($galleryId: ID!, $name: String!) {
    updateGallery(galleryId: $galleryId, name: $name) {
      id
      name
      galleryType
    }
  }
`);
