import { useState, useMemo, ReactElement } from 'react';
import { flow } from 'lodash';
import { t } from 'i18next';

import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Tooltip
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import HelpIcon from '@mui/icons-material/HelpOutline';

import Modal from 'src/components/Modal';
import ClientHtml from 'src/components/ClientHtml';
import { withFeatures } from 'src/components/Feature/Feature';

import { Theme } from '@mui/system';
import { AssetType, MediaAsset } from 'src/generated/gql/graphql';
import { InjectedStyledClasses } from 'src/common/Style';
import { InjectedFeatures } from 'src/components/Feature/constants';
import { WrappedFieldMetaProps } from 'redux-form/lib/Field';
import { GALLERY_TYPE, getDefaultMediaSources } from './constants';
import GalleryModalButton from './GalleryModalButton';
import Gallery from './Gallery';

const styles = (theme: Theme) => ({
  container: {
    width: '100%'
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    background: '#FFFFFF'
  },
  helpIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    width: '20px'
  },
  clearButton: {
    marginTop: '4px'
  },
  noModalContentPadding: {
    '& div:nth-child(3) > div > div:first-child': {
      padding: '0'
    }
  }
});

const getText = () => ({
  close: t('gallery:modal.close'),
  confirm: t('gallery:modal.confirm'),
  remove: t('gallery:buttons.remove')
});

export interface GalleryModalProps {
  onSelect: (selection: any) => void;
  galleryType: string;
  label: string;
  selection: AssetType | AssetType[] | { type: AssetType };
  tooltip?: string;
  helperText?: string;
  internalOnly?: boolean;
  sizeConstraint?: boolean;
  name: string;
  mediaType?: string;
  showFilter?: boolean;
  meta: WrappedFieldMetaProps;
  disabled?: boolean;
  internalOnlyAllowSvg?: boolean;
  isHookForm?: boolean;
}

const GalleryModal = (
  props: GalleryModalProps &
    InjectedStyledClasses<typeof styles> &
    InjectedFeatures
) => {
  const {
    onSelect,
    classes,
    features: { giphySearch, galleryAdminImages, shutterstockSearch },
    galleryType,
    label,
    selection,
    meta,
    tooltip,
    helperText,
    internalOnly,
    internalOnlyAllowSvg,
    sizeConstraint,
    name,
    mediaType,
    showFilter = false,
    disabled = false,
    isHookForm
  } = props;
  const text = useMemo(() => getText(), []);
  // get feature flagged media sources:
  const defaultMediaSources = getDefaultMediaSources({
    giphySearch,
    galleryType,
    shutterstockSearch,
    galleryAdminImages
  });

  const [selectedMediaSources, setSelectedMediaSources] = useState([]);

  const { touched, error } = meta;
  const [modalOpen, setModalOpen] = useState(false);
  const [, setAsset] = useState({});

  const [modalMediaType, setModalMediaType] = useState(mediaType);

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSelection = (selectedAsset: MediaAsset) => {
    if (galleryType === GALLERY_TYPE.media) {
      return onSelect({
        id: selectedAsset?.id,
        type: selectedAsset?.type
      });
    }
    // for legacy inputs that only send the link
    onSelect(selectedAsset?.link);
  };

  const inputInError = error && touched;

  return (
    <div className={classes.container}>
      <Modal
        className={classes.noModalContentPadding}
        open={modalOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        showClose={false}
        FooterComponent={() => (
          <>
            <Button
              variant={selection ? 'contained' : 'text'}
              color="primary"
              onClick={handleClose}
            >
              {selection ? text.confirm : text.close}
            </Button>
          </>
        )}
      >
        <>
          <Gallery
            allowSelect
            internalOnly={internalOnly}
            internalOnlyAllowSvg={internalOnlyAllowSvg}
            selectedGalleryItem={selection}
            setSelectedGalleryItem={handleSelection}
            sizeConstraint={sizeConstraint}
            selectedMediaSources={selectedMediaSources}
            defaultMediaSources={defaultMediaSources}
            setSelectedMediaSources={setSelectedMediaSources}
            setAsset={setAsset}
            isModal
            showFilter={showFilter}
            mediaType={modalMediaType}
            setMediaType={setModalMediaType}
            galleryType={galleryType}
          />
        </>
      </Modal>
      <FormControl margin="normal" fullWidth>
        {label && (
          <InputLabel shrink className={classes.label} error={inputInError}>
            {label}
            {tooltip && (
              <Tooltip title={tooltip}>
                <HelpIcon className={classes.helpIcon} />
              </Tooltip>
            )}
          </InputLabel>
        )}
        <GalleryModalButton
          name={name}
          // @ts-expect-error This doesn't exist on GalleryModalButton
          onClearSelection={event => {
            event.stopPropagation();

            onSelect(null);
          }}
          onClick={handleOpen}
          selection={selection}
          galleryType={galleryType}
          meta={meta}
          disabled={disabled}
          isHookForm={isHookForm}
        />
        {selection && (
          <Button
            disabled={disabled}
            data-cy={`gallery-modal-remove-button-${name.replace('.', '-')}`}
            className={classes.clearButton}
            color="secondary"
            variant="contained"
            onClick={event => {
              event.stopPropagation();

              onSelect(null);
            }}
          >
            {text.remove}
          </Button>
        )}
        {helperText && (
          <FormHelperText error={inputInError}>
            <ClientHtml html={helperText} />
          </FormHelperText>
        )}
        {inputInError && <FormHelperText error>{error}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default flow(withStyles(styles), withFeatures)(GalleryModal) as (
  props: GalleryModalProps
) => ReactElement;
