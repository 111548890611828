import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Trans, useTranslation } from 'react-i18next';

import TooltipMenuItem from 'src/components/TooltipMenuItem';
import { TooltipLink } from 'src/components/Link';
import { FACEBOOK_URLS } from 'src/common/FacebookUtil';

import PageLinkCard from '../PageLinkCard';
import ActionsContainer from '../ActionsContainer';

interface FacebookPage {
  id: string;
  name: string;
  // eslint-disable-next-line camelcase
  is_published: boolean;
  // eslint-disable-next-line camelcase
  promotion_eligible: boolean;
  isPageLinked: boolean;
}

interface FacebookPageSelectorProps {
  pages?: FacebookPage[];
  isLoadingPages: boolean;
  isLinkingPage: boolean;
  selectedPageId?: string;
  setSelectedPageId: (event: SelectChangeEvent<string>) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

const FacebookPageSelector = ({
  pages = [],
  selectedPageId = '',
  setSelectedPageId,
  isLoadingPages,
  isLinkingPage,
  onCancel,
  onConfirm
}: FacebookPageSelectorProps) => {
  const { t } = useTranslation();

  const isLoading = isLoadingPages || isLinkingPage;

  return (
    <PageLinkCard
      width={545}
      title={t('facebookSettings:facebookPageSelector.title')}
      actions={
        <ActionsContainer justifyContent="flex-end">
          <Button onClick={onCancel}>
            {t('facebookSettings:buttons.cancel')}
          </Button>
          <LoadingButton
            data-amp-facebook-link-page-confirm-click
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={isLoading || !selectedPageId}
            onClick={onConfirm}
          >
            {t('facebookSettings:buttons.linkPage')}
          </LoadingButton>
        </ActionsContainer>
      }
    >
      <FormControl fullWidth>
        <Select
          fullWidth
          disabled={isLoading}
          value={selectedPageId}
          onChange={setSelectedPageId}
        >
          {pages.map(page => {
            const key = `rop${page.id}`;

            // If page is not published, render this tooltip and disable menu item
            if (!page.is_published) {
              return (
                <TooltipMenuItem
                  key={key}
                  value={page.id}
                  tooltipTitle={
                    <Trans
                      i18nKey="facebookSettings:facebookPageSelector.pageNotPublished"
                      components={[
                        <TooltipLink
                          to={FACEBOOK_URLS.businessManager}
                          target="_blank"
                        />
                      ]}
                    />
                  }
                >
                  {page.name}
                </TooltipMenuItem>
              );
            }

            // If page is not able to be used to create ads, render this tooltip and disable menu item
            if (!page.promotion_eligible) {
              return (
                <TooltipMenuItem
                  key={key}
                  value={page.id}
                  tooltipTitle={
                    <Trans
                      i18nKey="facebookSettings:facebookPageSelector.promotionNotEligible"
                      components={[
                        <TooltipLink
                          to={FACEBOOK_URLS.support}
                          target="_blank"
                        />
                      ]}
                    />
                  }
                >
                  {page.name}
                </TooltipMenuItem>
              );
            }

            if (page.isPageLinked) {
              return (
                <TooltipMenuItem
                  key={key}
                  value={page.id}
                  type="success"
                  tooltipTitle={
                    <Trans i18nKey="facebookSettings:facebookPageSelector.pageAlreadyLinked" />
                  }
                >
                  {page.name}
                </TooltipMenuItem>
              );
            }

            return (
              <MenuItem
                key={key}
                value={page.id}
                data-amp-facebook-link-page-select-click={JSON.stringify({
                  pageId: page.id
                })}
                selected={page.id === selectedPageId}
              >
                {page.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {t('facebookSettings:facebookPageSelector.helperText')}
        </FormHelperText>
      </FormControl>
    </PageLinkCard>
  );
};

export default FacebookPageSelector;
