import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  quickStart: {
    height: '100%',
    minWidth: '160px',
    width: '100%',
    color: theme.palette.text.primary,
    textAlign: 'center'
  },
  quickStartLabel: {
    display: 'flex',
    flexDirection: 'column'
  },
  quickStartIcon: {
    height: theme.spacing(10),
    width: theme.spacing(10)
  },
  quickStartName: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  quickStartDescription: {
    fontSize: '12px',
    textTransform: 'initial',
    textAlign: 'center'
  }
});

const AdminQuickStartButton = ({ classes, quickStart }) => {
  const QuickStartIcon = quickStart?.icon;

  return (
    <Button
      component={Link}
      disabled={quickStart?.disabled}
      to={quickStart?.path}
      variant="outlined"
      className={classes.quickStart}
      classes={{
        label: classes.quickStartLabel
      }}
      color="inherit"
    >
      <span>
        {QuickStartIcon && (
          <QuickStartIcon className={classes.quickStartIcon} />
        )}
        <div className={classes.quickStartName}>{quickStart?.name}</div>
        <div className={classes.quickStartDescription}>
          {quickStart?.description}
        </div>
      </span>
    </Button>
  );
};

export default withStyles(styles)(AdminQuickStartButton);
