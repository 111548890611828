import { connect } from 'react-redux';
import { flow, pick, isString, pickBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { reduxForm, getFormValues } from 'redux-form';
import { CircularProgress, Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';
import { updateContentSet } from './mutations';
import { getContentSetInputs } from './constants';
import { formatContentSetSubmissionData } from './helpers';

const style = theme => ({
  updateButton: { marginTop: theme.spacing(2) }
});

const ContentSetForm = ({
  handleSubmit,
  submitting,
  invalid,
  initialValues,
  formValues,
  tableNames,
  refetch,
  classes,
  updateContentSet
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const onSubmit = async data => {
    const onlyAPIFields = pick(data, [
      ...getContentSetInputs({
        isAccessTableControlled: data?.isAccessTableControlled
      })
        .filter(f => !f.readOnly)
        .map(f => f.name)
    ]);
    const onlyChanged = pickBy(onlyAPIFields, (val, key) => {
      if (isString(val)) {
        return val !== initialValues?.[key];
      }
      return true;
    });

    const formatted = formatContentSetSubmissionData(onlyChanged);

    try {
      await updateContentSet({
        variables: {
          catalogId: initialValues.catalogId,
          input: formatted
        }
      });

      enqueueSnackbar(t('admin:catalogContent.contentSetFormSuccess'), {
        variant: 'success'
      });
      refetch();
    } catch (e) {
      enqueueSnackbar(t('admin:catalogContent.contentSetFormError'), {
        variant: 'error'
      });
      refetch();
    }
  };

  return (
    <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
      <DynamicForm
        inputs={getContentSetInputs({
          enums: { tableNames },
          isAccessTableControlled: formValues?.isAccessTableControlled
        })}
      />
      <Button
        color="primary"
        variant="outlined"
        type="submit"
        disabled={submitting || invalid}
        endIcon={submitting && <CircularProgress size={15} />}
        className={classes.updateButton}
      >
        <Trans i18nKey="admin:catalogContent.updateContentSet">
          Update Content Set
        </Trans>
      </Button>
    </form>
  );
};

export default flow(
  connect((state, ownProps) => {
    const formValues = getFormValues(ownProps.form)(state);

    return { formValues };
  }),
  reduxForm({
    // formName from parent
    // initialValues from parent
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  graphql(updateContentSet, {
    name: 'updateContentSet'
  }),
  withStyles(style)
)(ContentSetForm);
