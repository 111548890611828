// from [{ short_name: '1234', types: ['street_number'] }, ...]
// to { street_number: '1234`, ... }
export const keyByAddressPart = places => {
  return {
    ...places?.address_components?.reduce(
      (obj, part) => ({
        ...obj,
        ...part.types.reduce((types, type) => {
          if (type !== 'political') {
            return { ...types, [type]: part.short_name };
          }
          return types;
        }, {})
      }),
      {}
    ),
    longNames: places.address_components.reduce(
      (obj, part) => ({
        ...obj,
        [part.types[0]]: part.long_name
      }),
      {}
    ),
    latitude: places?.geometry?.location?.lat,
    longitude: places?.geometry?.location?.lng
  };
};
