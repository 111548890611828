import { useState, useMemo } from 'react';
import v from 'validator';

import withStyles from '@mui/styles/withStyles';
import { IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { isAdPreviewTemplate } from 'src/common/templateTranslator';
import { useGetVerticalPlaceholderImages } from 'src/common/verticals';

import DisplaySquareAd from './GoogleDiscoveryAdPreviews/DisplaySquareAd';
import DisplayRectangleAd from './GoogleDiscoveryAdPreviews/DisplayRectangleAd';
import DisplayPortraitAd from './GoogleDiscoveryAdPreviews/DisplayPortraitAd';
import {
  cloudinaryDiscoveryAdRectangleBaseUrl,
  cloudinaryDiscoveryAdSquareBaseUrl
} from '../Constants';
import { s3BaseUrl } from '../S3Image';
import { getImage } from './GoogleDisplayAdPreview';

const s3BaseUrlFull = `https:${s3BaseUrl}`;

const styles = () => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%'
  }
});

const imageUrlTypes = {
  portraitImageUrl: 'portraitImageUrl',
  rectangleImageUrl: 'rectangleImageUrl',
  squareImageUrl: 'squareImageUrl'
};

const allAdComponents = [
  DisplayRectangleAd,
  DisplaySquareAd,
  DisplayPortraitAd
];

const GoogleDiscoveryAdPreview = props => {
  const { classes, adData } = props;
  const [selectedAd, setSelectedAd] = useState(0);

  const placeholderImages = useGetVerticalPlaceholderImages();

  const imageConfig = [
    {
      type: imageUrlTypes.portraitImageUrl,
      styles: { height: '370px', width: '100%' },
      // the portrait image is optional so we don't want a placeholder for it
      placeholder: null
    },
    {
      type: imageUrlTypes.rectangleImageUrl,
      styles: { height: '155px', width: '100%' },
      placeholder: `${cloudinaryDiscoveryAdRectangleBaseUrl}${s3BaseUrlFull}${placeholderImages?.image}`
    },
    {
      type: imageUrlTypes.squareImageUrl,
      styles: { height: '295px', width: '100%' },
      placeholder: `${cloudinaryDiscoveryAdSquareBaseUrl}${s3BaseUrlFull}${placeholderImages?.image}`
    }
  ];

  const adPreviews = useMemo(() => {
    const previewsWithImages = [];
    const previewsWithPlaceholders = [];

    adData?.forEach(data => {
      let hasRealImage = false;
      const headline = data?.headline;
      const description = data?.description;
      const businessName = data?.businessName;

      const images = imageConfig.reduce((accum, image) => {
        // if there is a valid image in the adData without a template string we want to show that first
        if (
          data?.[image.type] &&
          v.isURL(data?.[image.type]) &&
          !isAdPreviewTemplate(data?.[image.type]) &&
          !isAdPreviewTemplate(decodeURIComponent(data?.[image.type]))
        ) {
          hasRealImage = true;
        }

        return {
          ...accum,
          [image.type]: getImage({
            adDataImage: data?.[image.type],
            placeholder: image.placeholder,
            styles: image.styles
          })
        };
      }, {});

      allAdComponents.forEach(AdComponent =>
        hasRealImage
          ? previewsWithImages.push(
              <AdComponent
                headline={headline}
                description={description}
                businessName={businessName}
                {...images}
              />
            )
          : previewsWithPlaceholders.push(
              <AdComponent
                headline={headline}
                description={description}
                businessName={businessName}
                {...images}
              />
            )
      );
    });

    // ensure that the previews with real images are shown first
    return [...previewsWithImages, ...previewsWithPlaceholders];
  }, [adData]);

  // if you have no preview at the current position reset the selected ad to initial state
  if (adPreviews[selectedAd] === undefined) {
    setSelectedAd(0);
  }
  const SelectedAdComponent = adPreviews[selectedAd];

  return (
    <div className={`${classes.container} notranslate`}>
      <div>
        <IconButton
          onClick={() =>
            setSelectedAd(
              selectedAd === 0 ? adPreviews.length - 1 : selectedAd - 1
            )
          }
          size="large"
        >
          <ArrowLeftIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            setSelectedAd(
              selectedAd === adPreviews.length - 1 ? 0 : selectedAd + 1
            )
          }
          size="large"
        >
          <ArrowRightIcon />
        </IconButton>
      </div>

      {SelectedAdComponent}
    </div>
  );
};

export default withStyles(styles)(GoogleDiscoveryAdPreview);
