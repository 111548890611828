import { Typography, Grid, Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Sandbox from './Sandbox';

const styles = theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2)
  }
});

const Playground = ({ classes }) => {
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4">
            <span aria-label="eyes" role="img">
              👀 🏝 Sandbox 🏖 👀
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Sandbox />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Playground);
