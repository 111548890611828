import { useMemo } from 'react';
import { FieldArray } from 'redux-form';
import { t } from 'i18next';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import RenderContentInputs from './RenderContentInputs';
import BlueprintBuilderStepFooter from './BlueprintBuilderStepFooter';

const styles = () => ({});

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepContentStepHeading')
});

const StepContent = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <div>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <br />

      <FieldArray
        component={RenderContentInputs}
        name="document.blueprint.contentSets"
        validate={[fieldArrayFixDumbReduxFormError]}
      />

      <BlueprintBuilderStepFooter />
    </div>
  );
};

export default withStyles(styles)(StepContent);
