import { useMemo, useState } from 'react';
import { first, isEmpty } from 'lodash';
import ReactDiffViewer from 'react-diff-viewer';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import { Typography, Alert, CircularProgress, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { dayjs } from 'src/common/dates';

import { getProductByProductId } from '../../queries';
import { sortKeys } from '../../helpers';
import DocumentVersionSelector from '../../DocumentVersionSelector';

const pageText = () => ({
  emptyState: t('admin:blueprintBuilder.importExportModal.emptyState'),
  leftTitle: t('admin:blueprintBuilder.importExportModal.leftDiffTitle'),
  rightTitle: t('admin:blueprintBuilder.importExportModal.rightDiffTitle'),
  exportButton: t('admin:blueprintBuilder.importExportModal.exportButton'),
  errorMessage: t('admin:blueprintBuilder.importExportModal.error')
});

const ExportBlueprint = props => {
  const { currentDocument = {} } = props;
  const text = useMemo(() => pageText(), []);

  const [selectedVersionId, setSelectedVersionId] = useState('');
  const hasSelectedId = !isEmpty(selectedVersionId);

  const {
    loading: selectedDocumentLoading,
    data,
    error: selectedDocumentError
  } = useQuery(getProductByProductId, {
    skip: !hasSelectedId,
    fetchPolicy: 'no-cache',
    variables: {
      documentId: selectedVersionId,
      productId: currentDocument?.productId,
      limit: 1
    }
  });

  const selectedDocument = data?.productDocumentVersions
    ? first(data?.productDocumentVersions)
    : {};

  // Note: Sorting these keys so that the diff works correctly redux form and the
  //       object that the we compare can have properties out of order
  const currentDocumentState = currentDocument ? sortKeys(currentDocument) : {};
  const selectedDocumentState = selectedDocument
    ? sortKeys(selectedDocument)
    : {};

  const downloadFile = content => {
    const a = document.createElement('a');
    const file = new Blob([JSON.stringify(content, null, 2)], {
      type: 'json'
    });
    a.href = URL.createObjectURL(file);
    a.download = `document-export-${dayjs().toISOString()}.json`;
    a.click();
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ padding: theme => theme.spacing(2) }}
      >
        <DocumentVersionSelector
          productId={currentDocument?.productId}
          currentDocumentId={currentDocument?.id}
          setSelected={value => setSelectedVersionId(value)}
        />
      </Box>

      {selectedDocumentError && (
        <Alert severity="error" sx={{ display: 'flex', alignItems: 'center' }}>
          {text.errorMessage}
        </Alert>
      )}

      {selectedDocumentLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {hasSelectedId ? (
            <ReactDiffViewer
              oldValue={JSON.stringify(currentDocumentState, null, 2)}
              newValue={JSON.stringify(selectedDocumentState, null, 2)}
              splitView
              disableWordDiff
              leftTitle={text.leftTitle}
              rightTitle={text.rightTitle}
            />
          ) : (
            <Typography
              sx={{
                padding: theme => theme.spacing(2),
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
              variant="h6"
            >
              {text.emptyState}
            </Typography>
          )}
        </>
      )}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop={3}
        marginBottom={3}
      >
        <LoadingButton
          disabled={!selectedVersionId}
          color="primary"
          variant="contained"
          onClick={() => downloadFile(selectedDocumentState)}
          loading={selectedDocumentLoading}
        >
          {text.exportButton}
        </LoadingButton>
      </Box>
    </>
  );
};

export default ExportBlueprint;
