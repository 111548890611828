import { gql } from 'src/generated/gql';

export const getOrgGroups = gql(`
  query getOrgGroups {
    myOrganization {
      id
      name
      groups {
        id
        externalId
        name
      }
    }
  }
`);

export const generateSsoToken = gql(`
  query generateSsoToken(
    $masterToken: String!
    $targetUserId: ID!
    $lifespan: String
  ) {
    admin(masterToken: $masterToken) {
      generateSsoToken(
        input: { targetUserId: $targetUserId, lifespan: $lifespan }
      ) {
        token
      }
    }
  }
`);
