import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { STYLES } from '../GoogleAdPreviewConstants';

const styles = () => ({
  bunsinessNameText: {
    color: '#3c4043',
    fontSize: '0.685rem',
    paddingLeft: '6px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: '1'
  },
  imageContainer: {
    maxWidth: '300px'
  },
  adBadge: {
    fontWeight: 'bold',
    fontSize: '0.685rem'
  },
  headline: {
    display: 'box',
    lineHeight: '110%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxOrient: 'vertical',
    lineClamp: '3'
  },
  headlineText: {
    fontSize: '0.8rem',
    fontFamily: STYLES.fontFamily.googleSans,
    color: '#202124',
    letterSpacing: '0.008rem'
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '12px',
    marginTop: '12px',
    marginBottom: '12px'
  }
});

const CardMedia = ({ classes, headline, description, image, businessName }) => {
  return (
    <Card sx={{ margin: '8px', minWidth: '300px' }}>
      {image && <div className={classes.imageContainer}>{image}</div>}
      <CardContent>
        <div className={classes.headline}>
          <span className={classes.headlineText}>
            {headline} | {description}
          </span>
        </div>
        <div className={classes.footerContainer}>
          <div className={classes.adBadge}>Ad • </div>
          <div className={classes.bunsinessNameText}>{businessName}</div>
        </div>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(CardMedia);
