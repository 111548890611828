import { gql } from 'src/generated/gql';

export const getWebhook = gql(`
  query getWebhook {
    myOrganization {
      id
      name
      webhookSubscription {
        id
        organizationId
        url
        hasAuthenticationParameters
        authenticationParameters {
          providers {
            ... on CustomHeaderAuthenticationProvider {
              type
              value {
                name
              }
            }
          }
        }
      }
    }
  }
`);

export const getArchitectures = gql(`
  query getArchitecturesForWebhookSettings {
    us {
      id
      architectures(showInactive: true) {
        id
        name
        isContentSelectable
        isActive
        products(showInactive: true) {
          id
          name
          isAvailable
        }
        catalogs(showInactive: true) {
          id
          contentSetId
          friendlyName
          defaultGroupKey
          isActive
        }
      }
    }
  }
`);

export const getProductsByArchitectureId = gql(`
  query getProductsByArchitectureIdForWebhookSettings($architectureId: ID!) {
    products(architectureId: $architectureId, showInactive: true) {
      id
      name
      isAvailable
    }
  }
`);
