import { createAction } from 'redux-actions';

export const constants = {
  addLayer: createAction('CLOUDINARY_TEMPLATE_BUILDER_ADD_LAYER'),
  removeLayer: createAction('CLOUDINARY_TEMPLATE_BUILDER_REMOVE_LAYER'),
  restTemplate: createAction('CLOUDINARY_TEMPLATE_BUILDER_RESET'),
  addElement: createAction('CLOUDINARY_TEMPLATE_BUILDER_ADD_ELEMENT'),
  removeElement: createAction('CLOUDINARY_TEMPLATE_BUILDER_REMOVE_ELEMENT')
};

export const addLayer = () => dispatch => {
  dispatch(constants.addLayer());
};

export const removeLayer = layerId => dispatch => {
  dispatch(constants.removeLayer({ layerId }));
};

export const restTemplate = () => dispatch => {
  dispatch(constants.restTemplate());
};

export const addElement =
  ({ layerId, element }) =>
  dispatch => {
    dispatch(constants.addElement({ layerId, element }));
  };

export const removeElement =
  ({ layerId, elementId }) =>
  dispatch => {
    dispatch(constants.removeElement({ layerId, elementId }));
  };
