import { Switch, Route, withRouter } from 'react-router-dom';

import FourZeroFour from 'src/pages/Error';

import { getRoutes } from './RouteMap';

const Router = () => {
  const routes = getRoutes();
  return (
    <Switch>
      {routes &&
        routes.map(route => {
          return route.getComponent();
        })}
      <Route component={FourZeroFour} />
    </Switch>
  );
};

export default withRouter(Router);
