import { isNumber } from 'lodash';

import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';

import { s3BaseUrl } from 'src/components/AdPreview/S3Image';

const PREVIEW_DRAWER_IMAGE = 'phone-mock-up.svg';
const PHONE_MOCK_HEIGHT = 210;
const PHONE_MOCK_WIDTH = 195;
const DEFAULT_CONTAINER_WIDTH = '240px';
const WIDTH_UNIT = 'px';

const useStyles = makeStyles(theme => ({
  phoneMockUpContainer: props => ({
    overflow: 'hidden',
    width: props.width,
    height: `calc((${PHONE_MOCK_HEIGHT}/${PHONE_MOCK_WIDTH}) * ${props.width})`,
    backgroundImage: `url("${s3BaseUrl}${PREVIEW_DRAWER_IMAGE}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    paddingTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center'
  })
}));

const PhoneMockUp = ({
  children,
  width = DEFAULT_CONTAINER_WIDTH,
  isPreviewDrawer
}) => {
  // If width is a number, the height calculation will still work.
  const formattedWidth = isNumber(width) ? `${width}${WIDTH_UNIT}` : width;

  const classes = useStyles({ width: formattedWidth, isPreviewDrawer });
  return (
    <Box className={classes.phoneMockUpContainer}>{children && children}</Box>
  );
};

export default PhoneMockUp;
