import withStyles from '@mui/styles/withStyles';
import CardMedia from './DisplayCardMedia';
import { getPreviewWidth } from '../../helpers';
import { SQUARE_AD_PREVIEW_WIDTH } from '../GoogleAdPreviewConstants';

export const styles = theme => ({
  container: props => ({
    background: '#fff',
    border: '1px solid #dadce0',
    borderBottomWidth: '0',
    overflow: 'hidden',
    position: 'relative',
    textAlign: 'start',
    width: getPreviewWidth(
      props.hasUniformPreviewWidth,
      SQUARE_AD_PREVIEW_WIDTH,
      props.isResponsive
    )
  }),
  wrapper: {
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0',
    width: '100%'
  },
  filler: {
    backgroundColor: '#5f6368',
    height: '200px',
    opacity: '.26',
    width: '360px'
  },
  placeholderText: {
    background: theme.palette.grey[200],
    height: theme.spacing(1),
    margin: '5px 0',
    width: '100%'
  },
  fakeTextContainer: {
    padding: `${theme.spacing(1)}`,
    width: '100%'
  },
  profileContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: `${theme.spacing(1)}`,
    width: '100%'
  },
  fakeImage: {
    backgroundColor: '#9aa0a6',
    borderRadius: '16px',
    height: '16px',
    width: '16px',
    marginRight: '10px'
  },
  placeholderTextShort: {
    background: theme.palette.grey[200],
    height: theme.spacing(1),
    margin: '5px 0',
    width: '20%'
  },
  contentContainer: {
    display: 'flex',
    height: '296px',
    width: '300px'
  }
});

const DisplaySquareAd = props => {
  const { classes, squareImageUrl, headline, description, businessName } =
    props;
  return (
    <div className={classes.container}>
      <div className={classes.cardContainer}>
        <div className={classes.fakeTextContainer}>
          <div className={classes.placeholderText} />
          <div className={classes.placeholderText} />
          <div className={classes.placeholderText} />
        </div>
        <div className={classes.profileContainer}>
          <div className={classes.fakeImage} />
          <div className={classes.placeholderTextShort} />
        </div>
      </div>
      <CardMedia
        headline={headline}
        description={description}
        businessName={businessName}
        image={squareImageUrl}
      />
    </div>
  );
};

export default withStyles(styles)(DisplaySquareAd);
