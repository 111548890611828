import {
  cloneElement,
  ReactElement,
  ComponentType,
  JSXElementConstructor
} from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  componentProps,
  children,
  ...rest
}: {
  component: ComponentType<any>;
  componentProps: any;
  children?: ReactElement<any, string | JSXElementConstructor<any>>;
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            {' '}
            {Component ? (
              <Component {...componentProps} {...props} />
            ) : (
              <>
                {children &&
                  cloneElement(children, {
                    componentProps,
                    props
                  })}
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default PublicRoute;
