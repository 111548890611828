import {
  maxValuePercentage,
  minValue,
  validateIsNumber,
  validateNotBlank,
  validateNotBlankIfNotNil,
  validateRequired
} from 'src/common/validations';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const FORM_NAME = 'createOrUpdateOrganizationBillingConfig';
export const CREATE_FORM_NAME = 'organizationPrepaymentForm';
export const SEARCH_FORM = 'searchPrepaymentAccountForm';

const MODES = {
  CREATE: 0,
  UPDATE: 1
};

export const createOrganizationPrePaymentInputs = [
  {
    name: 'initialAmount',
    displayName: 'Initial Amount',
    displayMethodId: INPUT_TYPES.PRICE_DECIMAL,
    initialValue: null,
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'effectiveFrom',
    displayName: 'Effective From',
    displayMethodId: INPUT_TYPES.DATE_UTC,
    initialValue: null,
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'effectiveUntil',
    displayName: 'Effective Until',
    displayMethodId: INPUT_TYPES.DATE_UTC,
    initialValue: null,
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

const getInputs = mode => [
  {
    name: 'stripePublicKey',
    displayName: 'Stripe Publishable Key',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: mode === MODES.CREATE ? '' : undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  // the stripe secret key value is always present if the organization's
  // billing config exits. so, it's safe to assume that if the billing
  // config is not not null, then the stripe key is not null
  {
    name: 'stripeKey',
    displayName:
      mode === MODES.CREATE ? 'Stripe Secret Key' : 'New Stripe Secret Key',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: mode === MODES.CREATE ? '' : undefined,
    reduxValidations:
      mode === MODES.CREATE
        ? [validateRequired, validateNotBlank()]
        : [validateNotBlankIfNotNil()],
    isRequired: mode === MODES.CREATE,
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          mode === MODES.CREATE
            ? undefined
            : 'The existing value is not shown. Only enter a value here if you need to update the secret key.'
      }
    }
  },
  {
    name: 'cancellationFeePercentage',
    displayName: 'Cancellation Fee Percentage',
    displayMethodId: INPUT_TYPES.PERCENTAGE_DECIMAL,
    reduxValidations: [
      validateRequired,
      validateIsNumber,
      minValue(0),
      maxValuePercentage(100)
    ],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 3,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'This should be a value between 0 and 100',
        endAdornment: '%'
      }
    }
  }
];

export const createBillingConfigInputs = getInputs(MODES.CREATE);
export const updateBillingConfigInputs = getInputs(MODES.UPDATE);
