import { find } from 'lodash';
import { GridSortModel } from '@mui/x-data-grid-pro';

export function sortRowsOnPriority<R extends { priority: number }>(
  sortModel?: GridSortModel,
  rows?: Array<R>
) {
  if (rows == null) {
    return rows;
  }

  const prioritySort = find(sortModel, { field: 'priority' });

  // Sort priority by descending because the currently active rule will be
  // the one with the highest priority
  return [...rows].sort((ruleA, ruleB) => {
    if (prioritySort?.sort === 'asc') {
      return ruleA?.priority - ruleB?.priority;
    }

    return ruleB?.priority - ruleA?.priority;
  });
}
