import { Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { useTheme } from '@mui/system';

export interface HelpTipProps {
  tipText: string;
}

/**
 * Renders a vertically centered help tip
 *
 * Looks like: (?)
 *
 * You must provide the translated tip text.
 */
const HelpTip = ({ tipText }: HelpTipProps) => {
  const theme = useTheme();
  return (
    <Tooltip title={tipText}>
      <HelpIcon
        sx={{
          color: theme.palette.grey[500],
          width: '20px',
          marginTop: 'auto',
          marginBottom: 'auto'
        }}
      />
    </Tooltip>
  );
};

export default HelpTip;
