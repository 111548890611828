import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { GridRowId, useGridApiContext } from '@mui/x-data-grid-pro';
import { kebabCase, noop } from 'lodash';

export interface CustomAction {
  onClick: (row: any, api: any) => void | Promise<void>;
  icon: JSX.Element;
  label: (row: any) => string;
  disabled?: (row: any) => boolean;
  ariaLabel: string;
}

interface DataTableRowCustomActionCellProps {
  id: GridRowId;
  customAction: CustomAction;
}

const DataTableRowCustomActionCell = ({
  id,
  customAction
}: DataTableRowCustomActionCellProps) => {
  const api = useGridApiContext();
  const row = api.current.getRow(id);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    customAction.onClick(row, api)?.catch(() => {});
  };

  const disabled = customAction.disabled && customAction.disabled(row);
  const label = customAction.label(row);

  // Prevent navigation to the program details page when disabled button is clicked
  const handleDisabledClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <Tooltip arrow title={label}>
      <Box
        component="span"
        display="inline-block"
        onClick={disabled ? handleDisabledClick : noop}
      >
        <IconButton
          aria-label={customAction.ariaLabel}
          data-cy={`custom-table-action-${kebabCase(label)}`}
          onClick={handleClick}
          disabled={disabled}
        >
          {customAction.icon}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default DataTableRowCustomActionCell;
