import { get } from 'lodash';

import withTheme from '@mui/styles/withTheme';

const FbVideoAd = ({ className, theme }) => {
  return (
    <svg className={className} width="100%" viewBox="0 0 218 197">
      <defs>
        <filter
          id="FbVideoAd-id-a"
          width="112%"
          height="113.9%"
          x="-6.2%"
          y="-7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <rect
          id="FbVideoAd-id-b"
          width="22.427"
          height="22.946"
          x=".531"
          y=".099"
          rx="11.213"
        />
      </defs>
      <g
        fill={get(theme, 'palette.blueprintIcons.background')}
        fillRule="evenodd"
        filter="url(#FbVideoAd-id-a)"
        transform="translate(5 3)"
      >
        {/* background */}
        <path
          fill={get(theme, 'palette.blueprintIcons.background')}
          fillRule="nonzero"
          stroke="#CECECC"
          d="M14.17 0h179.685c7.826 0 14.17 6.344 14.17 14.17v158.563c0 7.826-6.344 14.17-14.17 14.17H14.17c-7.826 0-14.17-6.344-14.17-14.17V14.17C0 6.344 6.344 0 14.17 0z"
        />
        {/* profile icon */}
        <use
          fill={get(theme, 'palette.blueprintIcons.profileIcon')}
          fillRule="nonzero"
          transform="translate(10.614 10.62)"
          xlinkHref="#FbVideoAd-id-b"
        />
        {/* profile icon text */}
        <path
          fill={get(theme, 'palette.blueprintIcons.text')}
          fillRule="nonzero"
          d="M45.64 12.743h53.064a3.186 3.186 0 1 1 0 6.372H45.64a3.186 3.186 0 0 1 0-6.372zM45.64 23.363h40.328a3.186 3.186 0 1 1 0 6.372H45.64a3.186 3.186 0 1 1 0-6.372z"
        />
        <path
          fill={get(theme, 'palette.blueprintIcons.text')}
          fillRule="nonzero"
          d="M13.8 55.221h165.567a3.186 3.186 0 1 1 0 6.372H13.799a3.186 3.186 0 0 1 0-6.372z"
        />
        {/* description */}
        <rect
          width="186.798"
          height="6.372"
          x="10.614"
          y="42.478"
          fill={get(theme, 'palette.blueprintIcons.text')}
          fillRule="nonzero"
          rx="3.186"
        />
        {/* video */}
        <path
          fill={get(theme, 'palette.blueprintIcons.video')}
          fillRule="nonzero"
          d="M22.614 70.089H185.41c6.628 0 12 5.372 12 12v82.194c0 6.628-5.372 12-12 12H22.614c-6.628 0-12-5.372-12-12V82.09c0-6.628 5.372-12 12-12z"
        />
        {/* play icon */}
        <g fillRule="nonzero" transform="translate(89.154 110.443)">
          <ellipse
            cx="13.798"
            cy="13.805"
            fill={get(theme, 'palette.blueprintIcons.video')}
            rx="13.798"
            ry="13.805"
          />
          <path
            fill={get(theme, 'palette.blueprintIcons.background')}
            d="M13.798.599c5.34-.001 10.154 3.217 12.198 8.153a13.214 13.214 0 0 1-2.861 14.396A13.196 13.196 0 0 1 8.747 26.01 13.209 13.209 0 0 1 .598 13.805C.608 6.515 6.512.608 13.798.6zm0-.599C6.178 0 0 6.18 0 13.805s6.177 13.806 13.798 13.806c7.62 0 13.797-6.181 13.797-13.806C27.595 6.181 21.418 0 13.798 0z"
          />
          <path
            fill={get(theme, 'palette.blueprintIcons.background')}
            d="M10.533 19.882V7.377h1.632l6.145 6.251-6.145 6.254z"
          />
        </g>
      </g>
    </svg>
  );
};

export default withTheme(FbVideoAd);
