import { Grid, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import LocationOnIcon from '@mui/icons-material/LocationOn';

const styles = theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
});

const AddressRenderOptions = props => {
  const { classes, option } = props;

  return (
    <Grid container alignItems="center">
      <Grid item>
        <LocationOnIcon className={classes.icon} />
      </Grid>
      <Grid item xs>
        <Typography variant="body1" color="textPrimary">
          {option?.description || '-'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddressRenderOptions);
