import { useState, SyntheticEvent } from 'react';
import { t } from 'i18next';

import { Button, Popover, Box } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { GridColumnsPanel } from '@mui/x-data-grid-pro';

import { getColumnNamesFiltered } from '../constants';

const ToolbarColumnsButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button startIcon={<ViewColumnIcon />} onClick={handleClick}>
        {t('common:dataTable.toolbar.buttons.columns')}
      </Button>
      <Popover
        marginThreshold={30}
        disableScrollLock
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: {
              '& .MuiDataGrid-panelHeader': {
                padding: theme => `0 ${theme.spacing(4)} 0 ${theme.spacing(2)}`
              },
              '& .MuiDataGrid-panelContent ': {
                padding: theme => `0 ${theme.spacing(4)} 0 ${theme.spacing(2)}`
              },
              '& .MuiDataGrid-panelFooter': {
                marginTop: theme => theme.spacing(1),
                borderTop: theme => `1px solid ${theme.palette.divider}`,
                justifyContent: 'center',
                gap: 2
              }
            }
          }
        }}
      >
        <Box>
          <GridColumnsPanel getTogglableColumns={getColumnNamesFiltered} />
        </Box>
      </Popover>
    </>
  );
};

export default ToolbarColumnsButton;
