/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useMemo, ReactElement } from 'react';
import { some, uniq, isEmpty, flow, toString, mapValues } from 'lodash';
import { Button, Typography, TextField, Tabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from 'src/components/Modal';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { FileError, FileRejection, useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import validator from 'validator';
import { useSnackbar } from 'notistack';

import s3ImageUpload from 'src/common/s3ImageUpload';
import Loading from 'src/components/Loading';
import { Box, styled, Theme } from '@mui/system';
import { InjectedStyledClasses } from 'src/common/Style';
import { useMutation } from '@apollo/client';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import SentryUtil from 'src/common/SentryUtil';
import { FlexExpander } from 'src/components/Styling/FlexExpander';
import { HelpTip } from 'src/components/Icons';
import { GoogleAudienceRequirements } from 'src/pages/Audiences/GoogleAudienceRequirements';
import { FacebookAudienceRequirements } from 'src/pages/Audiences/FacebookAudienceRequirements';
import { AudienceDownloadCsvLink } from 'src/pages/Audiences/AudienceDownloadCsvLink';
import AddIcon from '@mui/icons-material/Add';
import { hashStringSHA256 } from './util';
import { generateAudienceUrl } from './mutations';

const CSV_EXT_REGEX = /.+(\.csv)$/;
const MIN_AUDIENCE_SIZE = 300;

const ModalFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),
  alignItems: 'center'
}));

const styles = (theme: Theme) =>
  ({
    uploadContainer: {
      alignItems: 'center',
      border: '2px dashed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(2),
      minHeight: '100px'
    },
    buttonContainer: {
      marginTop: theme.spacing(2),

      '& a:first-of-type': {
        marginRight: theme.spacing(2)
      }
    },
    inputContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '400px'
    },
    pleaseUploadWarning: {
      alignItems: 'center',
      display: 'flex'
    },
    countContainer: {
      alignItems: 'center',
      display: 'flex'
    },
    countDivider: {
      padding: `0 ${theme.spacing(1)}`
    },
    dropzoneContent: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
      background: '#fff',
      zIndex: 1
    },
    uploadIcon: {
      height: '25%',
      width: '25%'
    }
  }) as const;

const pageText = () => ({
  nameInput: t('audiences:uploadModal.nameInput'),
  nameInputLabel: t('audiences:uploadModal.nameInputLabel'),
  retry: t('audiences:uploadModal.retry'),
  back: t('audiences:uploadModal.back'),
  continue: t('audiences:uploadModal.continue'),
  error: t('audiences:uploadModal.error'),
  errorNumberOfColumns: t('audiences:uploadModal.errorNumberOfColumns'),
  errorColumnNames: t('audiences:uploadModal.errorColumnNames'),
  errorNoData: t('audiences:uploadModal.errorNoData'),
  errorLength: t('audiences:uploadModal.errorLength', {
    data: MIN_AUDIENCE_SIZE
  }),
  errorEmailColumn: t('audiences:uploadModal.missingEmailColumn'),
  errorEmailFirst: t('audiences:uploadModal.firstColumnEmailError'),
  errorNotCsv: t('audiences:uploadModal.errorNotCsv'),
  uploadErrorGeneric: t('audiences:uploadModal.uploadErrorGeneric'),
  processingErrorGeneric: t('audiences:uploadModal.processingErrorGeneric'),
  dragAndDropOrClick: t('audiences:uploadModal.dragAndDropOrClick'),
  dropzoneText: t('audiences:uploadModal.dragAndDrop'),
  uploadSuccess: t('audiences:uploadModal.uploadSuccess'),
  processingNotice: t('audiences:uploadModal.24hourNotice'),
  submitButton: t('audiences:button.submit'),
  requirementsTab: t('audiences:uploadModal.requirements'),
  requirementsTooltip: t('audiences:uploadModal.requirementsTooltip')
});

const isEmailString = (str = '') => {
  return str && str.toLowerCase() === 'email';
};

// Validate that at least one of the fields has an email column - case
// insensitive.
const hasEmailColumn = (fields: string[] = []) => {
  for (let i = 0; i < fields.length; i++) {
    if (isEmailString(fields[i])) {
      return true;
    }
  }

  return false;
};

const parserBaseConfig = {
  delimiter: ',',
  header: true,
  dynamicTyping: true,
  skipEmptyLines: true
} as const;

const SNACKBAR_VARIANTS = {
  error: 'error',
  success: 'success'
} as const;

export interface AudiencesUploadProps {
  variant?: 'text' | 'outlined' | 'contained';
  onAudienceUploaded?: () => void;
}

const AudiencesUpload = ({
  classes,
  variant = 'contained',
  onAudienceUploaded
}: AudiencesUploadProps & InjectedStyledClasses<typeof styles>) => {
  const [generateAudienceUrlMutation] = useMutation(generateAudienceUrl);
  const [modalOpen, setModalOpen] = useState(false);

  // selecting and validating CSV state
  const [fileErrors, setFileErrors] = useState<string[]>([]);
  const [audienceName, setAudienceName] = useState('');
  const [hashedAudienceFile, setHashedAudienceFile] = useState<File | null>(
    null
  );
  const [emailMeta, setEmailMeta] = useState({ valid: 0, invalid: 0 });

  // uploading CSV state
  const [isUploading, setIsUploading] = useState(false);

  const [requirementsOpen, setRequirementsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<0 | 1>(0);

  const { enqueueSnackbar } = useSnackbar();

  const text = useMemo(() => pageText(), []);

  const handleModalClose = () => {
    // reset everything
    setModalOpen(false);
    setAudienceName('');
    setFileErrors([]);
    setEmailMeta({ valid: 0, invalid: 0 });
    setIsUploading(false);
    setHashedAudienceFile(null);
    setRequirementsOpen(false);
    setSelectedTab(0);
  };

  const setEmailCount = (valid: number, invalid: number) => {
    setEmailMeta({
      valid,
      invalid
    });
  };

  const clearUploadState = () => {
    setEmailCount(0, 0);
    setHashedAudienceFile(null);
    setFileErrors([]);
  };

  const resetForm = (errors?: string[]) => {
    setAudienceName('');
    setEmailCount(0, 0);
    setHashedAudienceFile(null);
    if (errors) {
      setFileErrors(errors);
    }
  };

  // Creates and stores CSV with hashed identification strings in state
  const createHashedAudienceFile = (
    hashedPII: Record<string, string>,
    file: File
  ) => {
    // Parse file, transform values into their respective sha256 hash digest and save the hashed csv
    let parseErrors: string[] = [];

    Papa.parse(file, {
      ...parserBaseConfig,
      complete: (results, hashedFile) => {
        const { errors } = results;

        if (some(errors)) {
          parseErrors = [...parseErrors, ...errors.map(e => e.message)];
        }

        if (isEmpty(parseErrors)) {
          setHashedAudienceFile(hashedFile);
        } else {
          resetForm(parseErrors);
        }
      },
      transform: value => {
        // Return hashed values
        return hashedPII[value];
      }
    });
  };

  const getIdStrings = ({
    Email,
    email,
    Phone,
    phone,
    Firstname,
    firstname,
    firstName,
    Lastname,
    lastname,
    lastName
  }: Record<string, string>) => {
    // Flexible column name support
    const emailString = Email || email;
    const phoneString = Phone || phone;
    const firstNameString = Firstname || firstname || firstName;
    const lastNameString = Lastname || lastname || lastName;

    return { emailString, phoneString, firstNameString, lastNameString };
  };

  const handleFileUpload = async () => {
    setIsUploading(true);

    // get a url
    let assetReservation;
    try {
      assetReservation = await generateAudienceUrlMutation({
        variables: {
          input: {
            name: audienceName,
            description: hashedAudienceFile!.name
          }
        }
      });
    } catch (e) {
      enqueueSnackbar(text.uploadErrorGeneric, {
        variant: SNACKBAR_VARIANTS.error
      });
      handleModalClose();
      return;
    }

    // upload the file
    try {
      const { url } = assetReservation.data!.generateAudienceUrl;

      await s3ImageUpload(hashedAudienceFile!, url!);
      // success
      enqueueSnackbar(text.uploadSuccess, {
        variant: SNACKBAR_VARIANTS.success
      });
      handleModalClose();
      onAudienceUploaded?.();
    } catch (e) {
      enqueueSnackbar(text.uploadErrorGeneric, {
        variant: SNACKBAR_VARIANTS.error
      });
      handleModalClose();
    }
  };

  const processAudienceFile = (file: File) => {
    let allErrors: string[] = [];

    Papa.parse(file, {
      ...parserBaseConfig,
      complete: results => {
        const { data, meta, errors } = results;
        if (some(errors)) {
          allErrors = [...allErrors, ...errors.map(e => e.message)];
        }

        if (!some(data)) {
          allErrors = [...allErrors, text.errorNoData];
        }

        (async () => {
          const hashedPII: Record<string, string> = {};
          let valid = 0;
          let invalid = 0;

          // validate emails and store hashes
          // map was used here so we can iterate asynchronously
          await Promise.all(
            data.map(async idStrings => {
              const {
                emailString,
                phoneString,
                firstNameString,
                lastNameString
              } = mapValues(getIdStrings(idStrings as any), toString);

              const idStringsArray = [
                emailString,
                phoneString,
                firstNameString,
                lastNameString
              ];

              if (emailString && validator.isEmail(emailString)) {
                valid++;
              } else {
                invalid++;
              }

              // map was used here so we can iterate asynchronously
              await Promise.all(
                idStringsArray.map(async idString => {
                  /*
                Adding this check because duplicate id strings will have identical hashes.
                With the potential for duplicate first/last names, we only need to hash these
                values once and cache.
              */
                  if (idString && !hashedPII[idString]) {
                    const digest = await hashStringSHA256(idString).catch(
                      () => {
                        resetForm([text.processingErrorGeneric]);
                      }
                    );

                    hashedPII[idString] = digest as string;
                  }
                })
              );
            })
          );

          return { hashedPII, valid, invalid, file };
        })()
          .then(({ hashedPII, valid, invalid, file }) => {
            // validate size of email list
            if (valid < MIN_AUDIENCE_SIZE) {
              allErrors = [...allErrors, text.errorLength];
            }

            // Validate email column exists - case insensitive
            if (!hasEmailColumn(meta.fields)) {
              allErrors = [...allErrors, text.errorEmailColumn];
            }

            const firstColumn = meta?.fields?.[0] || '';
            if (!isEmailString(firstColumn)) {
              allErrors = [...allErrors, text.errorEmailFirst];
            }
            if (isEmpty(allErrors)) {
              // populate the name field with the file name as default
              if (!audienceName || audienceName === '') {
                setAudienceName(file.name.split('.')[0]);
              }
              // no errors so the file is ready to upload
              setEmailCount(valid, invalid);
              createHashedAudienceFile(hashedPII, file);
            } else {
              resetForm(allErrors);
            }
          })
          .catch(() => {
            resetForm([text.processingErrorGeneric]);
          });
      }
    });
  };

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    // Before we do anything, clear all our old state
    clearUploadState();

    let allErrors: string[] = [];

    if (some(fileRejections)) {
      const errors = fileRejections.flatMap(r => r.errors.map(e => e.message));
      allErrors = [...allErrors, ...uniq(errors)];
      setFileErrors(allErrors);
    } else if (some(acceptedFiles)) {
      const file = acceptedFiles[0];
      processAudienceFile(file);
    }
  };

  const csvValidation = (file: File) => {
    const errors: FileError[] = [];

    if (!CSV_EXT_REGEX.test(file.name)) {
      errors.push({
        message: text.errorNotCsv,
        code: 'UNSUPPORTED_FILE_TYPE'
      });
    }

    return some(errors) ? errors : null;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    validator: csvValidation
  });

  const audienceSelected = !!hashedAudienceFile;
  const uploadDisabled =
    !audienceSelected || !isEmpty(fileErrors) || isUploading;
  const errorMessage = fileErrors.join(' | ');

  return (
    <>
      <Button
        color="primary"
        onClick={() => setModalOpen(true)}
        variant={variant}
        data-amp-click-add-audience
        data-cy="add-audience-button"
        sx={theme => ({
          marginBottom: theme.spacing(2)
        })}
        endIcon={<AddIcon />}
      >
        <Trans i18nKey="audiences:header.addButton" />
      </Button>

      <Modal
        fullWidth
        headerText={t('audiences:uploadModal.title')}
        maxWidth="md"
        onClose={() => handleModalClose()}
        open={modalOpen}
        data-cy="audience-upload-modal"
      >
        {isUploading ? (
          <div className={classes.uploadContainer}>
            <Typography variant="h6">
              <Trans i18nKey="audiences:uploadModal.uploadingTitle" />
            </Typography>
            <br />
            <Loading />
          </div>
        ) : (
          <>
            <Typography
              variant="body1"
              sx={theme => ({ marginBottom: theme.spacing(1) })}
            >
              {text.nameInputLabel}
            </Typography>
            <TextField
              id="audienceName"
              label={text.nameInput}
              variant="outlined"
              fullWidth
              onChange={e => setAudienceName(e.target.value)}
              value={audienceName}
              data-cy="audience-name-text-field"
            />

            <Typography
              variant="body1"
              sx={theme => ({
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(2)
              })}
            >
              <Trans
                i18nKey="audiences:uploadModal.uploadLabel"
                components={[<AudienceDownloadCsvLink />]}
              />
            </Typography>
            <div className={classes.uploadContainer} {...getRootProps()}>
              {isDragActive && (
                <div className={classes.dropzoneContent}>
                  <CloudUploadIcon className={classes.uploadIcon} />
                  <Typography variant="h5">{text.dropzoneText}</Typography>
                </div>
              )}
              <input
                data-cy="audiences-csv-drag-and-drop"
                {...getInputProps()}
              />
              <Typography variant="subtitle2">
                <Trans
                  i18nKey="audiences:uploadModal.dropzone"
                  components={[
                    // Note that this button has no click handler on purpose.
                    // The click event bubbles up to the root div that wraps the whole input section together
                    <Button
                      sx={{ textTransform: 'none', padding: 0 }}
                      data-cy="select-file-button"
                      color="primary"
                      variant="text"
                    />
                  ]}
                />
              </Typography>
              <div className={classes.inputContainer}>
                {emailMeta.valid > 0 && (
                  <div className={classes.countContainer}>
                    <Typography
                      data-cy="valid-email-count"
                      color={
                        audienceSelected && emailMeta?.valid < MIN_AUDIENCE_SIZE
                          ? 'error'
                          : 'textSecondary'
                      }
                    >
                      <Trans
                        i18nKey="audiences:uploadModal.emailValidCount"
                        values={emailMeta}
                      />
                    </Typography>
                    <span className={classes.countDivider}>|</span>
                    <Typography
                      data-cy="invalid-email-count"
                      color="textSecondary"
                    >
                      <Trans
                        i18nKey="audiences:uploadModal.emailInvalidCount"
                        values={emailMeta}
                      />
                    </Typography>
                  </div>
                )}
                {errorMessage && (
                  <>
                    <br />
                    <Typography
                      data-cy="audience-upload-error-message"
                      color="error"
                    >
                      {errorMessage}
                    </Typography>
                  </>
                )}
              </div>
            </div>
            <ModalFooter>
              {!requirementsOpen && <ExpandMore />}
              {requirementsOpen && <ExpandLess />}
              <Button
                variant="text"
                onClick={() => setRequirementsOpen(open => !open)}
              >
                {text.requirementsTab}
              </Button>
              <HelpTip tipText={text.requirementsTooltip} />
              <FlexExpander />
              <Button
                color="primary"
                disabled={uploadDisabled}
                onClick={e => {
                  e.stopPropagation();
                  handleFileUpload().catch(e => {
                    SentryUtil.captureException(e);
                    enqueueSnackbar(text.uploadErrorGeneric, {
                      variant: SNACKBAR_VARIANTS.error
                    });
                  });
                }}
                variant="contained"
                data-cy="create-audience-button"
              >
                {text.submitButton}
              </Button>
            </ModalFooter>
            {requirementsOpen && (
              <Box>
                <Tabs
                  value={selectedTab}
                  onChange={(e, value) => setSelectedTab(value)}
                  sx={theme => ({
                    marginBottom: theme.spacing(2)
                  })}
                >
                  <Tab
                    label="google"
                    id="google-requirements"
                    aria-controls="requirements-tab-panel-google"
                  />
                  <Tab
                    label="facebook"
                    id="facebook-requirements"
                    aria-controls="requirements-tab-panel-facebook"
                  />
                </Tabs>
                <div
                  hidden={selectedTab !== 0}
                  role="tabpanel"
                  id="requirements-tab-panel-google"
                  aria-labelledby="google-requirements"
                >
                  <GoogleAudienceRequirements />
                </div>
                <div
                  role="tabpanel"
                  hidden={selectedTab !== 1}
                  id="requirements-tab-panel-facebook"
                  aria-labelledby="facebook-requirements"
                >
                  <FacebookAudienceRequirements />
                </div>
              </Box>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default flow(withStyles(styles))(AudiencesUpload) as (
  props: AudiencesUploadProps
) => ReactElement;
