import { useMemo } from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { reduxForm, submit } from 'redux-form';
import { Typography, CircularProgress, Button } from '@mui/material';

import Modal from 'src/components/Modal';
import { DynamicForm } from 'src/components/ReduxForm';
import Loading from 'src/components/Loading';

import { getContentSetFieldDisplayMethods } from './queries';
import { FORM_STATES, getFieldMetaFields } from './constants';

const textMap = () => ({
  edit: t('admin:catalogContent.fieldMetaEditHeader'),
  new: t('admin:catalogContent.fieldMetaNewHeader')
});

const FieldMetaForm = ({
  submit,
  form,
  submitting,
  invalid,
  formState,
  handleCloseModal,
  initialValues,
  contentTableColumns,
  contentSetFieldDisplayMethods: {
    loading,
    error,
    contentSetFieldDisplayMethods = []
  }
}) => {
  const text = useMemo(() => textMap(), []);

  const displayMethodIds = useMemo(
    () =>
      contentSetFieldDisplayMethods.map(field => ({
        name: field.name,
        value: field.id
      })),
    [contentSetFieldDisplayMethods]
  );

  const fieldMetaFields = useMemo(
    () =>
      getFieldMetaFields({
        formState,
        enums: { displayMethodIds, contentTableColumns }
      }),
    [formState, displayMethodIds, contentTableColumns]
  );

  if (loading || error) {
    return <Loading error={error} />;
  }

  return (
    <Modal
      open
      headerText={`${
        formState === FORM_STATES.edit
          ? `${text.edit} - ${initialValues?.id}`
          : text.new
      }`}
      onClose={handleCloseModal}
      showClose
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
      FooterComponent={() => (
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={() => submit(form)}
            disabled={submitting || invalid}
            endIcon={submitting && <CircularProgress size={15} />}
          >
            <Trans i18nKey="admin:catalogContent.saveFieldMeta">Save</Trans>
          </Button>
          <Button onClick={handleCloseModal}>
            <Trans i18nKey="admin:catalogContent.cancelFieldMeta">Cancel</Trans>
          </Button>
        </>
      )}
    >
      <form autoComplete="false">
        <Typography component="h2" variant="h5">
          <Trans i18nKey="admin:catalogContent.fieldMetadataForm">
            Field MetaData
          </Trans>
        </Typography>
        <DynamicForm inputs={fieldMetaFields} />

        {/* 
        Removed for now because Matthew B doesn't use this section at all.
        This might still be needed for the future use case of public CMP style product.
        
        <br />
        <br />
        <Typography component="h2" variant="h5">
          <Trans i18nKey="admin:catalogContent.displayParametersForm">
            Display Parameters
          </Trans>
        </Typography>
        <Field component={RenderDisplayParameters} name="displayParameters" /> */}
      </form>
    </Modal>
  );
};

export default flow(
  connect(null, { submit }),
  reduxForm({
    // formName from parent
    // initialValues from parent
    // onSubmit passed from props
    destroyOnUnmount: true
  }),
  graphql(getContentSetFieldDisplayMethods, {
    name: 'contentSetFieldDisplayMethods',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    })
  })
)(FieldMetaForm);
