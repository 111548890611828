import { t } from 'i18next';
import { flow } from 'lodash';
import { reduxForm, SubmitHandler, getFormValues } from 'redux-form';

import { Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { DynamicForm } from 'src/components/ReduxForm';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { validateEmail, validateUrl } from 'src/common/validations';
import { connect } from 'react-redux';

const FORM_NAME = 'partnerEmailNotificationSetupForm';

interface FormProps {
  handleSubmit: SubmitHandler;
  onSubmit: () => void;
  disabledAll: boolean;
  dirty: boolean;
  isLoading: boolean;
  domain?: string;
  mailFromSubdomain: string;
  mailFromAddress: string;
}

interface FormValues {
  domain?: string;
}

const SesForm = ({
  handleSubmit,
  onSubmit,
  disabledAll,
  dirty,
  isLoading,
  domain,
  mailFromSubdomain,
  mailFromAddress
}: FormProps) => {
  // custom validation b/c the domain is in the end adornment of the input
  const validateMailFromSubdomain = (value: string, allValues: any) => {
    return validateUrl()(`${value}.${allValues.domain}`);
  };

  const validateMailFromAddress = (value: string, allValues: any) => {
    const email = `${value}@${allValues.domain}`;
    const validation = validateEmail()(email);
    return validation;
  };

  const inputs = [
    {
      name: 'domain',
      displayName: 'Domain',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateUrl()],
      isRequired: true,
      isHidden: false,
      disabled: disabledAll,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t('partner:emailNotificationSetup.domainTip')
        }
      }
    },
    {
      name: 'mailFromSubdomain',
      displayName: 'Mail From Subdomain',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateMailFromSubdomain],
      isRequired: true,
      isHidden: false,
      disabled: disabledAll,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t('partner:emailNotificationSetup.mailFromSubDomainTip'),
          ...(domain && { endAdornment: `.${domain}` }),
          ...(domain &&
            mailFromSubdomain && {
              helperText: `${mailFromSubdomain}.${domain}`
            })
        }
      }
    },
    {
      name: 'mailFromAddress',
      displayName: 'Mail From Address',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateMailFromAddress],
      isRequired: true,
      isHidden: false,
      disabled: disabledAll,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t('partner:emailNotificationSetup.mailFromAddressTip'),
          ...(domain && { endAdornment: `@${domain}` }),
          ...(domain &&
            mailFromAddress && {
              helperText: `${mailFromAddress}@${domain}`
            })
        }
      }
    },
    {
      name: 'senderName',
      displayName: 'Sender Name',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      disabled: disabledAll,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t('partner:emailNotificationSetup.senderNameTip')
        }
      }
    }
  ];

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <DynamicForm disabled={false} inputs={inputs} />
      <Tooltip
        arrow
        title={
          disabledAll
            ? t('partner:emailNotificationSetup.disableInputInfo')
            : ''
        }
      >
        <>
          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={!dirty || disabledAll}
            loading={isLoading}
            sx={{
              marginTop: theme => theme.spacing()
            }}
          >
            {t('partner:emailNotificationSetup.submitButton')}
          </LoadingButton>
        </>
      </Tooltip>
    </form>
  );
};

const extractSubdomain = (url: string, knownDomain: string) => {
  // Ensure the URL starts with a protocol for accurate parsing
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    // eslint-disable-next-line no-param-reassign
    url = `http://${url}`;
  }

  const { hostname } = new URL(url);

  // Remove the known domain including its preceding dot from the hostname
  const subdomain = hostname.replace(`.${knownDomain}`, '');

  // Check if the remaining part is different than the known domain, which indicates a subdomain is present
  return subdomain !== knownDomain ? subdomain : '';
};

const mapStateToProps = (state: any, ownProps: any) => {
  const formValues: FormValues = getFormValues(FORM_NAME)(state);

  const initialValues = {
    domain: ownProps?.emailIdentity?.domain,
    mailFromSubdomain: ownProps?.emailIdentity?.mailFromSubdomain
      ? extractSubdomain(
          ownProps?.emailIdentity?.mailFromSubdomain,
          ownProps?.emailIdentity?.domain
        )
      : undefined,
    mailFromAddress: ownProps?.emailIdentity?.mailFromAddress
      ? ownProps?.emailIdentity?.mailFromAddress.split('@')[0]
      : undefined,
    senderName: ownProps?.emailIdentity?.senderName
  };

  return {
    ...formValues,
    initialValues
  };
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME
  }),
  connect(mapStateToProps, {})
)(SesForm);
