import { ApolloLink, Operation } from '@apollo/client';
import SentryUtil from 'src/common/SentryUtil';
import { flattenObject } from 'src/common/utilities';

const operationInfo = (operation: Operation) => {
  const context = operation.getContext();

  const REQUEST_ID = 'x-request-id';
  const CORRELATION_ID = 'x-correlation-id';
  return {
    type: (
      operation?.query?.definitions?.find(
        defn => (defn as any).operation
      ) as any
    )?.operation,
    name: operation?.operationName,
    fragments: operation?.query?.definitions
      ?.filter(definition => definition.kind === 'FragmentDefinition')
      .map(definition => (definition as any).name.value)
      .join(', '),
    queryBody: operation?.query?.loc?.source?.body,
    variables: operation?.variables,
    headers: {
      [REQUEST_ID]: context?.response?.headers?.get(REQUEST_ID),
      [CORRELATION_ID]: context?.response?.headers?.get(CORRELATION_ID)
    }
  };
};

// This gets all of our requests with variables, query bodies, etc added to the
// sentry breadcrumbs as a user users the application and will aid in debugging
// as issues arise.
const apolloSentryLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const data = operationInfo(operation);
    SentryUtil.addBreadcrumb({
      category: 'graphql',
      data
    });
    SentryUtil.addContext('graphqlError', flattenObject(data));
    return response;
  });
});

export default apolloSentryLink;
