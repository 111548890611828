import { createRoot } from 'react-dom/client';
import { Trans } from 'react-i18next';
import 'typeface-roboto';

import { Paper } from '@mui/material';

// import Constants from 'src/common/Constants';
// import { ConnectedInvertibleThemeProvider } from 'src/components/theme/InvertibleThemeProvider';

import * as appReducers from './reducers';
// import AppThemeLoading from './AppThemeLoading';
import configureApp from './configureApp';
import ReduxAndRouter from './ReduxAndRouter';
import AppThemeWrapper from './AppThemeWrapper';
import AppSettings from './AppSettings';
import TranslationProvider from './TranslationProvider';

const hasLocalStorageAccess = () => {
  try {
    window.localStorage.getItem('t');
  } catch (err) {
    return false;
  }

  return true;
};

const isFacebookBlocking = configureApp();

const container = document.getElementById('root');
const root = createRoot(container);

const load = () =>
  root.render(
    <div>
      {hasLocalStorageAccess() ? (
        <TranslationProvider>
          <ReduxAndRouter reducers={appReducers}>
            <AppSettings>
              <AppThemeWrapper />
            </AppSettings>
          </ReduxAndRouter>
        </TranslationProvider>
      ) : (
        <Paper
          style={{
            padding: '40px',
            maxWidth: '500px',
            margin: '40px auto',
            textAlign: 'center',
            color: '#ff2b00'
          }}
        >
          <Trans i18nKey="skinErrors.noLocalStorage" />
        </Paper>
      )}
    </div>
  );
if (!isFacebookBlocking) {
  load();
}
