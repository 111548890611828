import { flow, isEmpty, sortBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { isApolloLoading } from 'src/common/apollo';
import { formatDate } from 'src/common/dates';

import {
  convertFilterJSONtoFiltersArray,
  translateFiltersWithMeta,
  filterToString
} from 'src/components/AutomatedProgramFilter/helpers';
import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Loading from 'src/components/Loading';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { usePagination } from 'src/components/Pagination/hooks';
import PaginationControls from 'src/components/Pagination/PaginationControls';
import Table from 'src/components/Table';
import IconLink from 'src/components/Table/IconLink';
import { withFeatures } from 'src/components/Feature';

import Instrumentation from 'src/instrumentation';

import { getAutomations } from './queries';
import { RESULTS_PER_PAGE } from './constants';

const styles = theme => ({
  root: {
    width: '100%'
  },
  highlight: {
    background: theme.palette.success[50]
  },
  tableTitle: {
    flexGrow: 1
  },
  icon: {
    width: '50px'
  },
  list: {
    textAlign: 'left'
  }
});

const AutomatedProgramsTable = props => {
  const {
    architectureId,
    history,
    classes,
    automations,
    loading,
    refetch,
    architectureName,
    features: { addProgramButton }
  } = props;
  const automatedPrograms = automations?.edges?.map(automation => ({
    ...automation?.node
  }));
  const { navigateNext, navigatePrev } = usePagination({
    edges: automations?.edges,
    resultsPerPage: RESULTS_PER_PAGE,
    refetchCallback: refetch,
    refetchOptions: { architectureId }
  });

  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;

  const selectedOffice = globalContext?.office?.selectedOffice;

  const handleRowClick = data => {
    const { id, group } = data;

    const rowOfficeMatchesCurrentOffice = group?.id === selectedOffice?.id;

    // we only want to edit if your current office matches the selected rows office
    if (!rowOfficeMatchesCurrentOffice) {
      return;
    }

    const linkPath = generateLinkPath(paths.architecture.automatedProgram, {
      architectureId,
      programId: id
    });

    Instrumentation.logEvent(Instrumentation.Events.ViewProgramsClicked, {
      architectureId,
      type: 'automated',
      programId: id
    });

    return history.push(linkPath);
  };

  // const viewPrograms = (e, programId) => {
  //     e.stopPropagation();
  //     const linkPath = generateLinkPath(paths.architecture.programs, {
  //         architectureId
  //     });

  //     return history.push(`${linkPath}?supervisorIds=${programId}`);
  // };

  if (loading) {
    return <Loading />;
  }

  const automatedProgramsSorted = sortBy(automatedPrograms, p =>
    p.deactivatedAt ? 1 : 0
  );

  const niceName =
    architectureName || t('automatedPrograms:programsTable.programName');

  const columnSchema = [
    {
      columnName: t('automatedPrograms:programsTable.edit'),
      key: 'edit',
      CellComponent: props => {
        // eslint-disable-next-line react/destructuring-assignment
        if (props?.row?.deactivatedAt) {
          return null;
        }

        // we only want to edit if your current office matches the selected rows office
        const rowOfficeMatchesCurrentOffice =
          // eslint-disable-next-line react/destructuring-assignment
          props?.row?.group?.id === selectedOffice?.id;

        // eslint-disable-next-line react/destructuring-assignment
        const officeName = props?.row?.group?.name;
        // eslint-disable-next-line react/destructuring-assignment
        const id = props?.row?.id;

        const linkPath = generateLinkPath(paths.architecture.automatedProgram, {
          architectureId,
          programId: id
        });

        const logClickEvent = () => {
          Instrumentation.logEvent(Instrumentation.Events.ViewProgramsClicked, {
            architectureId,
            type: 'automated',
            programId: id
          });
        };

        return (
          <IconLink
            {...props}
            title={
              rowOfficeMatchesCurrentOffice
                ? t('automatedPrograms:programsTable.edit')
                : t('automatedPrograms:programsTable.noEdit', {
                    officeName
                  })
            }
            icon={EditIcon}
            disabled={!rowOfficeMatchesCurrentOffice}
            to={linkPath}
            logClickEvent={logClickEvent}
          />
        );
      }
    },
    {
      columnName: t('automatedPrograms:programsTable.id'),
      accessor: 'id',
      type: 'ID'
    },
    {
      columnName: t('automatedPrograms:programsTable.name'),
      accessor: 'name',
      type: 'STRING'
    },
    {
      columnName: t('automatedPrograms:programsTable.automationStart'),
      accessor: 'scheduleStartEpochSeconds',
      CellComponent: ({ row: { scheduleStartEpochSeconds } }) => (
        <>
          {formatDate({
            date: scheduleStartEpochSeconds * 1000,
            type: 'DAY'
          })}
        </>
      )
    },
    ...(isTeamsEnabled
      ? [
          {
            columnName: t('automatedPrograms:programsTable.initiatedBy'),
            accessor: 'user.name',
            type: 'STRING'
          }
        ]
      : []),
    {
      columnName: t('automatedPrograms:programsTable.blueprint'),
      accessor: 'offer.product.name',
      type: 'STRING'
    },
    {
      columnName: t('automatedPrograms:programsTable.filters'),
      key: 'filters',
      CellComponent: ({
        row: {
          catalogFilter,
          catalog: { fieldMetadata }
        }
      }) => {
        const filters = convertFilterJSONtoFiltersArray(catalogFilter);
        const translatedFilters = translateFiltersWithMeta(
          filters,
          fieldMetadata
        );
        return (
          <>
            <ul className={classes.list}>
              <li>
                {t('automatedPrograms:programsTable.filterEmpty', {
                  niceName
                })}
              </li>
              {translatedFilters.map((filter, i) => (
                <li key={filter?.id}>
                  {i > 0
                    ? t('automatedPrograms:programsTable.filterAnd')
                    : t('automatedPrograms:programsTable.filterWhere')}{' '}
                  {filterToString(filter)}
                </li>
              ))}
            </ul>
          </>
        );
      }
    },
    {
      columnName: t('automatedPrograms:programsTable.automationStatus'),
      accessor: 'status',
      CellComponent: ({ row: { deactivatedAt } }) => (
        <>
          {deactivatedAt
            ? t('automatedPrograms:programsTable.inactive')
            : t('automatedPrograms:programsTable.active')}
        </>
      )
    },
    {
      columnName: t('automatedPrograms:programsTable.office'),
      accessor: 'group.name',
      type: 'STRING',
      key: 'officeName'
    }
    // we don't have a way to filter by supervisor but if they go to details
    // it shows a table of generated programs. we might want to add this later
    // {
    //     columnName: '',
    //     key: 'tools',
    //     CellComponent: ({
    //         row: {
    //             offer: { productId }
    //         }
    //     }) => {
    //         const programName = t(
    //             'automatedPrograms:programsTable.programsDefault',
    //             'Programs'
    //         );

    //         return (
    //             <Button
    //                 onClick={e => this.viewPrograms(e, productId)}
    //                 color="primary"
    //             >
    //                 {t('automatedPrograms:programsTable.viewPrograms', {
    //                     defaultValue: 'View {programName}',
    //                     programName
    //                 })}
    //             </Button>
    //         );
    //     }
    // }
  ];

  let buttonText = null;
  let buttonTo = null;

  if (addProgramButton) {
    buttonText = t('automatedPrograms:programsTable.createProgram');

    buttonTo = generateLinkPath(paths.architecture.automatedProgramCreate, {
      architectureId
    });
  }

  return !loading && isEmpty(automations) ? (
    <TableEmptyState
      buttonText={buttonText}
      buttonTo={buttonTo}
      emptyMessage={t('automatedPrograms:programsTable.empty')}
    />
  ) : (
    <Paper className={classes.root}>
      <Table
        columnSchema={columnSchema}
        rows={automatedProgramsSorted || automations}
        onClickBodyRow={handleRowClick}
        loading={loading}
      />
      <PaginationControls
        pageInfo={automations?.pageInfo}
        navigateNext={navigateNext}
        navigatePrev={navigatePrev}
      />
    </Paper>
  );
};

export default flow(
  withStyles(styles),
  graphql(getAutomations, {
    name: 'getAutomations',
    options: props => {
      const variables = {
        first: RESULTS_PER_PAGE,
        after: null,
        filter: { showDeleted: true, architectureIds: [props.architectureId] }
      };

      return {
        variables,
        fetchPolicy: 'no-cache'
      };
    },
    props: ({
      getAutomations: { error, refetch, networkStatus, automations }
    }) => {
      const loading = isApolloLoading(networkStatus);

      return {
        loading,
        error,
        refetch,
        automations
      };
    }
  }),
  withRouter,
  withFeatures
)(AutomatedProgramsTable);
