import { isValidElement, JSXElementConstructor, ReactElement } from 'react';

// E.G. <Component /> or <Component></Component>
export function isComposite(element: unknown): element is ReactElement {
  return isValidElement(element) && typeof element === 'object';
}

// E.G. Component
export function isComponentConstructor(
  element: unknown
): element is JSXElementConstructor<unknown> {
  return typeof element === 'function' && isValidElement(element());
}

export function getByType(Element: any) {
  if (isComposite(Element)) {
    return Element;
  }

  if (isComponentConstructor(Element)) {
    return <Element />;
  }

  return null;
}
