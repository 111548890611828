import { useState } from 'react';
import { sortBy } from 'lodash';
import { t } from 'i18next';
import pluralize from 'pluralize';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { useAppSettings } from 'src/AppSettings';

import {
  CircularProgress,
  List,
  ListItemIcon,
  ListItemText,
  Autocomplete,
  TextField,
  Popper,
  ListItemButton
} from '@mui/material';
import { LocationCity, Groups } from '@mui/icons-material';

import Auth from 'src/Auth/Auth';

import { getOfficeName } from 'src/common/appBranding';

import { useFeatures } from 'src/components/Feature';
import { useSnackbar } from 'notistack';

const OfficeSelector = () => {
  const appSettings = useAppSettings();
  const globalContext = useGlobalContext();
  const features = useFeatures();
  const { enqueueSnackbar } = useSnackbar();

  const [switchOfficeLoading, setSwitchOfficeLoading] = useState(false);
  const officeName = getOfficeName(appSettings);

  const selectedOffice = globalContext?.office?.selectedOffice;
  const offices = globalContext?.office?.offices;
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;

  const handleMenuItemClick = (event, newOfficeId) => {
    // Do nothing if they aren't switching to a different office.
    if (selectedOffice?.id === newOfficeId) {
      return;
    }

    setSwitchOfficeLoading(true);

    Auth.switchOfficeToken(newOfficeId)
      .then(() => {
        // Turn off loading state now. Note: the page will likely be
        // refreshing already by the time this is called but we're
        // calling it just in case the reload breaks or gets stopped
        // so the site doesn't appear broken.
        setSwitchOfficeLoading(false);
      })
      .catch(() => {
        // Message the user that an error occurred.
        enqueueSnackbar(
          t('officeSelector:errorSwitchingOffice', {
            officeName: pluralize(officeName.toLowerCase())
          }),
          {
            variant: 'error'
          }
        );

        // Turn off loading state now.
        setSwitchOfficeLoading(false);
      });
  };

  const isLoading = globalContext.loading || switchOfficeLoading;

  // check to see if the office selector is turned off
  if (!features.officeSelector) {
    return null;
  }

  return (
    <>
      <List
        sx={{ width: theme => `${theme.evSizes.navigationWidth}px` }}
        component="nav"
      >
        <ListItemButton
          aria-controls="office-nav-menu"
          aria-haspopup="true"
          aria-label={t('officeSelector:selectYourOffice', {
            officeName: officeName.toLowerCase()
          })}
          disabled={isLoading}
          sx={{ pl: 4 }}
        >
          <ListItemIcon
            sx={{
              margin: 0
            }}
          >
            {isTeamsEnabled ? <Groups /> : <LocationCity />}
          </ListItemIcon>

          {isLoading ? (
            <ListItemText
              disableTypography
              primary={t('officeSelector:listItems.officePrimary', {
                officeName
              })}
              secondary={<CircularProgress size={20} />}
            />
          ) : (
            <>
              {offices.length <= 1 ? (
                <>
                  <ListItemText
                    primary={t('officeSelector:listItems.officePrimary', {
                      officeName
                    })}
                    secondary={selectedOffice?.name}
                    secondaryTypographyProps={{
                      sx: {
                        color: 'grey.500'
                      }
                    }}
                    sx={{
                      whiteSpace: 'normal'
                    }}
                  />
                </>
              ) : (
                <>
                  <Autocomplete
                    id="filter-office"
                    value={selectedOffice}
                    disableClearable
                    options={sortBy(offices, o => o.name)}
                    getOptionLabel={option => option.name}
                    onChange={(event, office) => {
                      handleMenuItemClick(event, office.id);
                    }}
                    style={{ width: '100%' }}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        sx={{
                          width: 'auto !important',
                          zIndex: theme => theme.zIndex.modal
                        }}
                        placement="bottom-start"
                      />
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('officeSelector:listItems.officePrimary', {
                          officeName
                        })}
                        variant="standard"
                        sx={theme => ({
                          '& label': {
                            color: theme.palette.text.primary,
                            fontSize: theme.typography.h6.fontSize
                          },
                          '& input': {
                            color: theme.palette.text.secondary,
                            fontSize: theme.typography.overline.fontSize
                          },
                          '& > div': {
                            marginTop: theme.spacing(2.25)
                          },
                          '& > div::before': {
                            borderBottom: '0px'
                          }
                        })}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
        </ListItemButton>
      </List>
    </>
  );
};

export default OfficeSelector;
