import { useMemo, useState } from 'react';
import { t } from 'i18next';
import { Paper, Typography } from '@mui/material';

import DataTable from 'src/components/DataTable/DataTable';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';
import { OrderConnection } from 'src/generated/gql/graphql';

import { getOrderHistory } from './queries';
import {
  getProgramHistoryChangeColumns,
  formatOrderHistoryRows
} from './helpers';

const pageText = () => {
  return {
    activityHistory: t('programPerf:title.activityHistory'),
    activityHistoryTableHeaderUser: t('programPerf:activityTableHeader.user'),
    activityHistoryTableHeaderChange: t(
      'programPerf:activityTableHeader.change'
    ),
    activityHistoryTableHeaderDateTime: t(
      'programPerf:activityTableHeader.dateTime'
    )
  };
};

const defaultPageSize = 15;

interface OrderHistoryTableProps {
  orderId: string;
}

const OrderHistoryTable = ({ orderId }: OrderHistoryTableProps) => {
  const text = useMemo(pageText, []);
  const [nextCursor, setNextCursor] = useState<any>();

  // we need an extra row so we can calculate the diff for the last row
  const pageSizeWithExtraRow = defaultPageSize + 1;

  const orderHistoryColumns = getProgramHistoryChangeColumns(text);

  const queryGetOrderHistory = useInvokableQuery(getOrderHistory);

  const loadMoreRows = async (reset: any) => {
    if (reset) {
      setNextCursor(undefined);
    }

    const variables = {
      orderId,
      first: pageSizeWithExtraRow,
      after: reset ? undefined : nextCursor
    };

    const getResponse = await queryGetOrderHistory(variables);
    const { edges, pageInfo } = getResponse?.data?.order?.orderItem
      ?.history as OrderConnection;

    // we can't use pageInfo.endCursor in this case b/c we are fetching one extra row to calculate the diff
    const lastVisibleRowCursor = edges?.[edges.length - 2]?.cursor || undefined;

    setNextCursor(lastVisibleRowCursor);

    const hasMoreRows = pageInfo?.hasNextPage;
    const rows = formatOrderHistoryRows(edges, hasMoreRows);

    return { rows, hasMoreRows };
  };

  return (
    <Paper
      sx={{
        marginTop: theme => theme.spacing(2),
        padding: theme => theme.spacing(2)
      }}
    >
      <Typography variant="h5" sx={{ marginBottom: theme => theme.spacing() }}>
        {text.activityHistory}
      </Typography>

      <DataTable
        columns={orderHistoryColumns}
        loadMoreRows={loadMoreRows}
        hideFooter
      />
    </Paper>
  );
};

export default OrderHistoryTable;
