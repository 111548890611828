import { forIn, some, values } from 'lodash';
import { t } from 'i18next';

import { useMediaQuery } from '@mui/material';
import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  GroupRemove as GroupRemoveIcon,
  ZoomIn as ZoomIcon
} from '@mui/icons-material';

import PhotoIcon from '@mui/icons-material/Photo';
import VideocamIcon from '@mui/icons-material/Videocam';

import {
  GALLERY_TYPE,
  MEDIA_TYPE_FILTER_VALUES,
  MEDIA_TYPE,
  MEDIA_SOURCES,
  ASSET_STATUS
} from './constants';

export const getGalleryIcon = type => {
  switch (type) {
    case GALLERY_TYPE.image:
      return <PhotoIcon />;
    case GALLERY_TYPE.video:
      return <VideocamIcon />;
    default:
      break;
  }
};

export const useGalleryColumnCount = theme => {
  // xs
  let columns = 1;

  if (useMediaQuery(theme.breakpoints.up('sm'))) {
    columns = 3;
  }
  if (useMediaQuery(theme.breakpoints.up('md'))) {
    columns = 4;
  }
  if (useMediaQuery(theme.breakpoints.up('lg'))) {
    columns = 5;
  }
  if (useMediaQuery(theme.breakpoints.up('xl'))) {
    columns = 6;
  }

  return columns;
};

// generates asset filter from props
export const propsToGalleryFilter = ({
  mediaType,
  defaultMediaSources,
  selectedMediaSources,
  internalOnly
}) => {
  const selectAllMediaSources = selectedMediaSources.length === 0;
  const types =
    mediaType === MEDIA_TYPE_FILTER_VALUES.all
      ? values(MEDIA_TYPE)
      : MEDIA_TYPE[mediaType];

  let allMediaSources = selectAllMediaSources
    ? values(defaultMediaSources)
    : selectedMediaSources;
  // anytime we have a corporate source, we also have partner source we treat them the same as far as filtering goes
  if (
    selectAllMediaSources ||
    some(selectedMediaSources, source => source === MEDIA_SOURCES.Corporate)
  ) {
    allMediaSources = [...allMediaSources, MEDIA_SOURCES.Partner];
  }

  return {
    mediaFilter: {
      types,
      sources: internalOnly ? [MEDIA_SOURCES.Internal] : allMediaSources,
      statuses: [ASSET_STATUS.complete, ASSET_STATUS.pending],
      showDeleted: false // there is a bug on the backend when show deleted is not passed
    }
  };
};

export const getCompletedCount = uploadStatus => {
  let completed = 0;
  forIn(uploadStatus, value => {
    if (value.status === ASSET_STATUS.complete) completed++;
  });
  return completed;
};

export const getAssetMenuOptions = ({
  canDelete,
  canShare,
  isShared,
  handleRemove,
  handleView,
  toggleShare
}) => {
  const options = [
    {
      label: t('gallery:mediaAssetMenu.view'),
      icon: <ZoomIcon />,
      onClick: handleView
    }
  ];

  if (canDelete) {
    options.unshift({
      label: t('gallery:mediaAssetMenu.delete'),
      icon: <DeleteIcon />,
      onClick: handleRemove
    });
  }

  if (canShare) {
    options.unshift({
      label: isShared
        ? t('gallery:mediaAssetMenu.unshare')
        : t('gallery:mediaAssetMenu.share'),
      icon: isShared ? <GroupRemoveIcon /> : <GroupAddIcon />,
      onClick: toggleShare
    });
  }

  return options;
};
