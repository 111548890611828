export const readUploadedFileAsText = (inputFile: Blob) => {
  return new Promise((resolve, reject) => {
    const temporaryFileReader = new FileReader();
    temporaryFileReader.onerror = reject;

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result);
    };

    temporaryFileReader.readAsText(inputFile);
  });
};
