export const modalTypes = {
  delete: 'delete',
  shareWithTeam: 'shareWithTeam',
  unshareWithTeam: 'unshareWithTeam'
};

export const cardListDirections = {
  vertical: 'vertical',
  horizontal: 'horizontal'
};
