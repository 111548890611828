import { useState, useMemo } from 'react';
import { FieldArray, Field } from 'redux-form';
import { t } from 'i18next';

import { AppBar, Tabs, Tab } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import RenderVariablesInputs from './RenderVariablesInputs';
import RenderDefaultVariableValues from './RenderDefaultVariableValues';

const styles = theme => ({
  container: {
    position: 'relative'
  },
  subHeading: {
    marginBottom: theme.spacing(2)
  }
});

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepVariablesStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepVariablesStepSubHeading')
});

const TabContent = [
  {
    label: 'Variables',
    key: 'variables',
    content: () => {
      return (
        <div>
          <FieldArray
            component={RenderVariablesInputs}
            name="document.blueprint.variables"
            validate={[fieldArrayFixDumbReduxFormError]}
          />
        </div>
      );
    }
  },
  {
    label: 'Default Variable Values',
    key: 'export',
    content: () => {
      return (
        <Field
          component={RenderDefaultVariableValues}
          name="document.defaultVariableValues"
        />
      );
    }
  }
];

const Variables = ({ classes, inModal }) => {
  const text = useMemo(() => pageText(), []);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const SelectedTabContent = TabContent[selectedTab].content;

  return (
    <div className={classes.container}>
      <AppBar position="static" color="secondary">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {TabContent.map(tab => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </AppBar>

      <SelectedTabContent classes={classes} text={text} inModal={inModal} />
    </div>
  );
};

export default withStyles(styles)(Variables);
