import { matchPath, useLocation } from 'react-router';
import { paths } from 'src/routes/paths';

/**
 * Returns true if we're on either a program or automation page
 * that involves some sort of user input.
 * Specifically, any *Create or *Edit page.
 */
export const useIsProgramOrAutomationPage = () => {
  const { pathname } = useLocation();
  return (
    matchPath(pathname, paths.architecture.programCreate) ||
    matchPath(pathname, paths.architecture.programEdit) ||
    matchPath(pathname, paths.architecture.automatedProgramCreate) ||
    matchPath(pathname, paths.architecture.automatedProgramEdit)
  );
};
