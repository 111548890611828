import { get, isFunction } from 'lodash';

import Logger from '../Logger';

import FacebookTracker from './FacebookTracker';
import GoogleAnalyticsTracker from './GoogleAnalyticsTracker';

class Tracker {
  initialize(options = {}) {
    // Pull the ev Facebook ID and the client's (if set) Facebook ID.
    const facebookPixelId = get(options, 'trackers.facebookPixelId');
    const clientFacebookPixelId = get(
      options,
      'trackers.clientFacebookPixelId'
    );

    // Pull the ev GA ID and the client's (if set) GA ID.
    const googleAnalyticsId = get(options, 'trackers.googleAnalyticsId');
    const clientGoogleAnalyticsId = get(
      options,
      'trackers.clientGoogleAnalyticsId'
    );

    this.trackers = [
      new FacebookTracker(facebookPixelId, clientFacebookPixelId),
      new GoogleAnalyticsTracker(googleAnalyticsId, clientGoogleAnalyticsId)
    ];

    this.initialized = true;

    this.initializeTrackers(options.initialPage);
  }

  initializeTrackers = initialPath => {
    this.callTrackers('init', initialPath);
  };

  trackPageView(path) {
    this.callTrackers('pageView', path);
  }

  trackPurchase(data) {
    this.callTrackers('trackPurchase', data);
  }

  trackOrderView(data) {
    this.callTrackers('trackOrderView', data);
  }

  trackCancelOrder(data) {
    this.callTrackers('trackCancelOrder', data);
  }

  trackQuickStart(data) {
    this.callTrackers('trackQuickStart', data);
  }

  trackProgramPreviewVisibility(data) {
    this.callTrackers('trackProgramPreviewVisibility', data);
  }

  trackProgramChooseBlueprintModal() {
    this.callTrackers('trackProgramChooseBlueprintModal');
  }

  trackProgramLinkFacebookPage() {
    this.callTrackers('trackProgramLinkFacebookPage');
  }

  trackProgramSelectFacebookPage() {
    this.callTrackers('trackProgramSelectFacebookPage');
  }

  trackProgramStartStep() {
    this.callTrackers('trackProgramStartStep');
  }

  trackProgramSelectStep(data) {
    this.callTrackers('trackProgramSelectStep', data);
  }

  trackProgramSubmitPromoCode(data) {
    this.callTrackers('trackProgramSubmitPromoCode', data);
  }

  trackEditProgram() {
    this.callTrackers('trackEditProgram');
  }

  trackEditProgramActions(data) {
    this.callTrackers('trackEditProgramActions', data);
  }

  trackCancelProgram(data) {
    this.callTrackers('trackCancelProgram', data);
  }

  trackProgramsAddNew(data) {
    this.callTrackers('trackProgramsAddNew', data);
  }

  trackViewProgram(data) {
    this.callTrackers('trackViewProgram', data);
  }

  trackGoogleAdDetails(data) {
    this.callTrackers('trackGoogleAdDetails', data);
  }

  trackContacts(data) {
    this.callTrackers('trackContacts', data);
  }

  trackGallery(data) {
    this.callTrackers('trackGallery', data);
  }

  trackAudience(data) {
    this.callTrackers('trackAudience', data);
  }

  trackManageFacebookPages(data) {
    this.callTrackers('trackManageFacebookPages', data);
  }

  trackSupport(data) {
    this.callTrackers('trackSupport', data);
  }

  trackAccountMenu(data) {
    this.callTrackers('trackAccountMenu', data);
  }

  trackBillingHistory() {
    this.callTrackers('trackBillingHistory');
  }

  trackNotificationPreferences(data) {
    this.callTrackers('trackNotificationPreferences', data);
  }

  trackAudiences(data) {
    this.callTrackers('trackAudiences', data);
  }

  callTrackers(funcName, ...args) {
    if (!this.initialized) {
      return;
    }

    this.trackers.forEach(tracker => {
      Logger.debug(`calling ${tracker.name}.${funcName} with args: `, args);

      if (tracker[funcName] && isFunction(tracker[funcName])) {
        return tracker[funcName](...args);
      }

      Logger.error(
        `Error missing tracking function: ${tracker.name}.${funcName}`
      );
    });
  }
}

export default new Tracker();
