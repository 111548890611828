import { ReactNode } from 'react';
import Loading from 'src/components/Loading';
import { Box, Grid } from '@mui/material';

import { PROGRAM_STEP_NAMES, PROGRAM_STEP_NAME_TO_ORDER } from '../Constants';

interface StepSkipperProps {
  children: ReactNode;
  skipStep: any;
}

const StepSkipper = ({ children, skipStep }: StepSkipperProps) => {
  if (
    !skipStep ||
    !skipStep?.contentVerified ||
    skipStep.targetStep === PROGRAM_STEP_NAMES.configure ||
    skipStep.targetStep ===
      PROGRAM_STEP_NAME_TO_ORDER[PROGRAM_STEP_NAMES.summary]
  ) {
    return children;
  }

  return (
    <Grid item xs={12}>
      <Loading />
      <Box
        sx={{
          overflow: 'hidden',
          height: '0px'
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default StepSkipper;
