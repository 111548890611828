import { gql } from 'src/generated/gql';

export const loadOrganizations = gql(`
  query LoadOrganizationsForOrgSelector($masterToken: String!) {
    admin(masterToken: $masterToken) {
      organizations {
        id
        name
        fqdn
      }
    }
  }
`);
