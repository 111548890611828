import { Trans } from 'react-i18next';

import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import AssignmentIcon from '@mui/icons-material/Assignment';

const BusinessObjectBulkListItem = ({
  contentName = 'content',
  hasCatalog
}) => {
  let primaryText = (
    <Trans
      i18nKey="businessObjectSelector:businessObjectBulkSelector.primaryText"
      values={{ contentName }}
    />
  );

  let secondaryText = (
    <Trans i18nKey="businessObjectSelector:businessObjectBulkSelector.secondaryTextLine2" />
  );

  if (!hasCatalog) {
    primaryText = <span />;

    secondaryText = (
      <Trans i18nKey="businessObjectSelector:businessObjectBulkSelector.noContentForBlueprint" />
    );
  }

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          <AssignmentIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primaryText} secondary={secondaryText} />
    </ListItem>
  );
};

export default BusinessObjectBulkListItem;
