import { handleActions } from 'redux-actions';

import StepConfigure from './BlueprintBuilderSteps/StepConfigure';
import StepPublishers from './BlueprintBuilderSteps/PublisherBuilder/StepPublishers';
import StepVariables from './BlueprintBuilderSteps/StepVariables';
import StepUserInput from './BlueprintBuilderSteps/StepUserInput';
import StepOffers from './BlueprintBuilderSteps/StepOffers';
import StepFinalize from './BlueprintBuilderSteps/StepFinalize';
import StepContent from './BlueprintBuilderSteps/StepContent';

import { actionConstants } from './actions';

const initialState = {
  selectedStep: 0, // Note selectedStep needs to be a number for the stepper
  steps: {
    0: {
      completed: false,
      content: StepConfigure,
      error: false,
      label: 'Setup Blueprint'
    },
    1: {
      completed: false,
      content: StepContent,
      error: false,
      label: 'Configure Content'
    },
    2: {
      completed: false,
      content: StepPublishers,
      error: false,
      label: 'Configure Publishers'
    },
    3: {
      completed: false,
      content: StepVariables,
      error: false,
      label: 'Configure Variables'
    },
    4: {
      completed: false,
      content: StepUserInput,
      error: false,
      label: 'Configure User Inputs'
    },
    5: {
      completed: false,
      content: StepOffers,
      error: false,
      label: 'Configure Offers'
    },
    6: {
      completed: false,
      content: StepFinalize,
      error: false,
      label: 'Finalize blueprint'
    }
  }
};

export default handleActions(
  {
    [actionConstants.selectStep.exact]: (state, { payload }) => {
      // Note selectedStep needs to be a number for the stepper component and the
      //      payload is returned as a string
      const selectedStep = Number(payload);

      return {
        ...state,
        selectedStep
      };
    },

    [actionConstants.selectStep.next]: state => {
      const { selectedStep } = state;
      const newSelectedStep = selectedStep + 1;

      return {
        ...state,
        selectedStep: newSelectedStep
      };
    },

    [actionConstants.selectStep.previous]: state => {
      const { selectedStep } = state;
      const newSelectedStep = selectedStep - 1;

      return {
        ...state,
        selectedStep: newSelectedStep
      };
    },

    [actionConstants.reset]: () => {
      return {
        ...initialState
      };
    }
  },
  initialState
);
