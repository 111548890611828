import { useState, useCallback } from 'react';

import { IconButton, TextField } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

const styles = theme => ({
  sectionInput: {
    margin: `${theme.spacing(2)} 0`,
    width: '100%'
  },
  endAdornmentContainer: {
    alignItems: 'center',
    display: 'flex',
    margin: theme.spacing(1, 1, 1, 0)
  },
  saveCancelContainer: {
    alignItems: 'center',
    display: 'flex'
  }
});

const RenderSectionHeading = props => {
  const { input, classes, onRemove } = props;
  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(input?.value);

  const save = useCallback(() => {
    input.onChange(inputValue);
    setEdit(false);
  }, [input, inputValue]);

  const cancel = useCallback(() => {
    setInputValue(input?.value);
    setEdit(false);
  }, [input]);

  const onEdit = useCallback(() => {
    setInputValue(input?.value);
    setEdit(true);
  }, [input]);

  return (
    <TextField
      disabled={!edit}
      className={classes.sectionInput}
      onKeyPress={({ key }) => {
        if (key === 'Enter') {
          save();
        }
      }}
      InputProps={{
        endAdornment: (
          <div className={classes.endAdornmentContainer}>
            {edit ? (
              <div className={classes.saveCancelContainer}>
                <IconButton onClick={save} size="small">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={cancel} size="small">
                  <ClearIcon />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={onEdit} size="small">
                <EditIcon />
              </IconButton>
            )}
            <IconButton onClick={onRemove} size="small">
              <DeleteIcon />
            </IconButton>
          </div>
        )
      }}
      onChange={({ target: { value } }) => setInputValue(value)}
      value={inputValue}
    />
  );
};
export default withStyles(styles)(RenderSectionHeading);
