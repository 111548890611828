import { useRef } from 'react';
import { flow } from 'lodash';
import { Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Video from 'src/components/Video';

import { MEDIA_TYPE } from '../constants';

const style = () => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  asset: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  popperTop: {
    top: '13px'
  }
});

const ThirdPartySearch = ({
  classes,
  media,
  mediaType,
  tooltip,
  clipBottom
}) => {
  const videoRef = useRef(null);
  return (
    <Tooltip
      arrow
      placement="top"
      classes={{
        tooltipPlacementTop: classes.popperTop
      }}
      title={tooltip || ''}
    >
      <div
        data-cy="media-search-result"
        onMouseEnter={() => {
          if (mediaType === MEDIA_TYPE.video) {
            videoRef.current.play();
          }
        }}
        onMouseLeave={() => {
          if (mediaType === MEDIA_TYPE.video) {
            videoRef.current.pause();
          }
        }}
        className={classes.container}
      >
        {mediaType === MEDIA_TYPE.video && (
          <Video
            videoRef={videoRef}
            alt={media.name || media.id}
            src={media.previewUrl}
            preload="metadata"
            className={classes.asset}
            loop
          />
        )}
        {mediaType === MEDIA_TYPE.image && (
          <>
            <img
              src={media.previewUrl}
              className={classes.asset}
              alt={media.name || media.id}
              style={{
                clipPath: `inset(0px 0px ${clipBottom || '0px'} 0px)`
              }}
              onContextMenu={e => {
                // Prevent the context menu from showing
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </>
        )}
      </div>
    </Tooltip>
  );
};

export default flow(withStyles(style))(ThirdPartySearch);
