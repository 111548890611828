import { green } from '@mui/material/colors';

import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  root: {
    borderColor: green[900],
    background: green[100]
  }
});

export default withStyles(styles)(Paper);
