import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Paper, Grid, Typography } from '@mui/material';

import Heading from 'src/components/PageElements/Heading';

import InstagramPageAccess from './InstagramPageAccess';

const sections = [
  {
    heading: <Trans i18nKey="admin:facebookSupportTools.pageAccessHeading" />,
    subHeading: (
      <Trans i18nKey="admin:facebookSupportTools.pageAccessSubheading" />
    ),
    component: <InstagramPageAccess />,
    xs: 6,
    key: 'instagramPageAccessTool'
  }
];

const FacebookSupportToolsLanding = () => {
  return (
    <div>
      <Heading
        title={t('admin:headings.facebookSupportTools')}
        subTitle={t('admin:headings.facebookSupportToolsDescription')}
        pageTitle={t('admin:headings.facebookSupportTools')}
      />

      <Grid container spacing={3}>
        {sections.map(section => {
          return (
            <Grid item xs={section.xs} key={section.key}>
              <Paper
                sx={{
                  marginTop: theme => theme.spacing(2),
                  padding: theme => theme.spacing(2)
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: theme => theme.spacing(1)
                  }}
                >
                  {section.heading}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={{
                    fontWeight: 'normal',
                    fontSize: '14px',
                    marginBottom: theme => theme.spacing(4)
                  }}
                >
                  {section.subHeading}
                </Typography>

                {section.component}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default FacebookSupportToolsLanding;
