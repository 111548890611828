import { useEffect, useMemo } from 'react';
import { orderBy, isEmpty } from 'lodash';
import { t } from 'i18next';
import { useFormContext, useWatch } from 'react-hook-form';

import { FormControlLabel, Radio, Box } from '@mui/material';

import { Offer } from 'src/generated/gql/graphql';
import { BILLING_METHODS } from 'src/common/paymentUtils';

import {
  scheduleTypes,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';
import { RenderRadioGroup } from 'src/components/ReduxForm';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import CardOffer from './CardOffer';
import InvoiceOffer from './InvoiceOffer';

interface OffersProps {
  selectedBlueprint: any;
}

const pageText = () => ({
  PARTNER_INVOICE: t('programCreate:Checkout.billingMethodInvoice'),
  USER_CREDIT_CARD: t('programCreate:Checkout.billingMethodCreditCard')
});

const required = (value?: string) => (value ? undefined : 'Required');

const Offers = ({ selectedBlueprint }: OffersProps) => {
  const text = useMemo(() => pageText(), []);
  const { setValue, formState } = useFormContext();

  const { errors } = formState;
  const paymentMethodIdError = errors?.paymentMethodId?.message;

  const billingMethod = useWatch({
    name: 'spendStep.billingMethod',
    defaultValue: ''
  });

  const isCardOffer = billingMethod === BILLING_METHODS.card;

  const offerId = useWatch({ name: 'spendStep.offerId', defaultValue: '' });

  const scheduleType = useWatch({
    name: 'spendStep.scheduleType',
    defaultValue: ''
  });

  const isSubscription = scheduleType === scheduleTypes.subscription.value;

  // ordering by billingMethod for now but future us should have a priority on the offers
  const offersBySelectedType = orderBy(
    selectedBlueprint.offers.filter((o: Offer) => {
      // filter out subscription offers that don't have plans associated with them
      if (isSubscription && isEmpty(o?.stripeSubscriptionPlans)) {
        return false;
      }

      return o.type === scheduleType;
    }),
    ['billingMethod'],
    ['asc']
  );

  useEffect(() => {
    // if we switch offer types we need to select the first offer of that type
    // or just keep the current offer and set the billing method
    const currentOffer = offersBySelectedType.find(
      (o: Offer) => o.id === offerId
    );

    if (!currentOffer) {
      setValue(
        'spendStep.billingMethod',
        offersBySelectedType?.[0]?.billingMethod
      );
      setValue('spendStep.offerId', offersBySelectedType?.[0]?.id);
    } else {
      setValue('spendStep.billingMethod', currentOffer.billingMethod);
    }
  }, [offersBySelectedType?.[0]?.type]); // this effect only happens when we change type purchase vs subscription.

  const offerIdPropsRedux = {
    component: RenderRadioGroup,
    name: 'offerId',
    validate: [required],
    formNamespace: PROGRAM_FORM_SECTION_SPEND_NAME,
    onChange: (e: any, newVal: string) => {
      // update the billing method
      setValue(
        'spendStep.billingMethod',
        (offersBySelectedType.find((o: Offer) => o.id === newVal) as Offer)
          .billingMethod
      );
    }
  };

  const offerIdPropsHook = {
    ...offerIdPropsRedux,
    // hook form doesn't pass the event so it needs its own onChange
    onChange: (newVal: string) => {
      // update the billing method
      setValue(
        'spendStep.billingMethod',
        (offersBySelectedType.find((o: Offer) => o.id === newVal) as Offer)
          .billingMethod
      );
      setValue('spendStep.offerId', newVal);
    }
  };

  const offerIdOptions = offersBySelectedType.map((offer: Offer) => {
    return (
      <FormControlLabel
        data-cy={`payment-billingMethod-${offer.billingMethod}`}
        key={`${offer.type}-${offer.billingMethod}`}
        control={<Radio />}
        label={text[offer.billingMethod]}
        value={offer.id}
      />
    );
  });

  return (
    <div data-cy="payment-card-container">
      {/* eslint-disable-next-line no-nested-ternary */}
      {offersBySelectedType?.length > 1 && (
        <HookFormWrapper {...offerIdPropsHook} validateBackendOnly={false}>
          {offerIdOptions}
        </HookFormWrapper>
      )}

      {paymentMethodIdError && (
        <Box
          sx={{
            color: 'error.main'
          }}
        >
          {paymentMethodIdError}
        </Box>
      )}

      {isCardOffer && <CardOffer />}

      {!isCardOffer && <InvoiceOffer />}
    </div>
  );
};

export default Offers;
