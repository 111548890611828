import { lowerCase } from 'lodash';

/**
 * A helper function for getting the DataTable column type from a lithium
 * AdminContentSetField.
 * @param {object} field The lithium AdminContenetSetField.
 * @returns A DataTable column type
 */
export const getColumnType = field => {
  switch (field.type) {
    case 'string':
      return 'string';
    case 'number':
      return 'number';
    case 'boolean':
      return 'boolean';
    case 'date_time':
      return 'dateTime';
    case 'date':
      return 'date';
    default:
      return 'string';
  }
};

/**
 * A helper function for getting a value getter from a lithium
 * AdminContentSetField. The value getting converts a raw value from the
 * DataTable into an appropriate type.
 * @param {object} field The lithium AdminContenetSetField.
 * @returns A value getter.
 */
export const getValueGetter = field => {
  switch (field.type) {
    case 'number':
      return ({ value }) => value && Number(value);
    case 'boolean':
      return ({ value }) => lowerCase(value) === 'true';
    case 'date_time':
    case 'date':
      return ({ value }) => value && new Date(value);
    case 'string':
    default:
      return ({ value }) => value && String(value);
  }
};
