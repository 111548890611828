import { useSnackbar } from 'notistack';
import SentryUtil from 'src/common/SentryUtil';
import { t } from 'i18next';

const useHandleError = () => {
  const { enqueueSnackbar } = useSnackbar();

  const handleError = (error: any) => {
    enqueueSnackbar(t('aiSuggestion:chat.error'), { variant: 'error' });
    SentryUtil.captureException(error);
  };

  return handleError;
};

export default useHandleError;
