import { gql } from 'src/generated/gql';

export const getFacebookPagesV2 = gql(`
  query FacebookPagesV2 {
    facebookPagesV2 {
      id
      userId
      groupId
      pageId
      pageName
      facebookUserId
      facebookDisplayName
      instagramId
      hasAcceptedTos
      pageStatus
      businessManagerId
      createdAt
      updatedAt
      activeOrders {
        id
        name
        architectureId
        supervisorOrder { id }
      }
    }
  }
`);

export const getFacebookPagesV2Teams = gql(`
  query FacebookPagesV2Teams {
    facebookPagesV2 {
      id
      userId
      groupId
      pageId
      pageName
      facebookUserId
      facebookDisplayName
      instagramId
      hasAcceptedTos
      pageStatus
      businessManagerId
      createdAt
      updatedAt
      activeOrders {
        id
        name
        architectureId
        supervisorOrder { id }
      }
      teamCapabilities {
        isOwnedByMe
        isSharedWithMe
        isSharedWithTeam
      }
      accessControl {
        permissions(filter: {})
      }
    }
  }
`);
