import { useMemo, useCallback } from 'react';
import { flow } from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';
import { t } from 'i18next';
import { connect } from 'react-redux';

import { Box, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { Link as LinkIcon } from '@mui/icons-material';

import { DynamicForm } from 'src/components/ReduxForm';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

import { LINK_FORM_NAME, manuallyManageInputs } from './Constants';

const ChangeTable = styled('table')(({ theme }) => ({
  borderCollapse: 'collapse',
  margin: theme.spacing(2, 1, 0, 1),
  minWidth: '350px',

  '& tr': {
    padding: '5px'
  },

  '& tr:nth-child(odd)': {
    backgroundColor: theme.palette.grey[300]
  },

  '& th': {
    textAlign: 'left',
    padding: '0px 20px 0px 0px'
  },

  '& td': {
    textAlign: 'left'
  }
}));

const ModifiedTableRow = styled('tr')(({ theme }) => ({
  backgroundColor: theme.palette.success[300]
}));

const OutputForm = styled('form')(({ theme }) => ({
  margin: theme.spacing(0, 1, 0, 1),
  width: '300px'
}));

const getText = () => ({
  confirmHeaderStart: t('admin:manuallyLinkOrders.headerStartManuallyManage'),
  confirmHeaderStop: t('admin:manuallyLinkOrders.headerStopManuallyManage'),
  confirmButton: t('admin:manuallyLinkOrders.confirmButton'),
  cancelButton: t('admin:manuallyLinkOrders.cancelButton')
});

const ConfirmModal = props => {
  const {
    columnSchema,
    formValues,
    handleSubmit,
    onCancelConfirm,
    onConfirm,
    open,
    reset,
    selectedFragment,
    loading,
    dirty,
    modalError,
    onReset
  } = props;
  const text = useMemo(() => getText(), []);
  const isManuallyManaged = selectedFragment?.isManuallyManaged;

  const onCancel = useCallback(() => {
    reset();
    onCancelConfirm();
  }, [onCancelConfirm, reset]);

  return (
    <ConfirmationModal
      icon={<LinkIcon />}
      open={open}
      title={
        isManuallyManaged ? text.confirmHeaderStop : text.confirmHeaderStart
      }
      cancelButtonText={text.cancelButton}
      confirmButtonText={text.confirmButton}
      onClose={onCancel}
      onConfirm={
        isManuallyManaged
          ? () => onReset(selectedFragment?.fragmentId)
          : handleSubmit(onConfirm)
      }
      confirmButtonDisabled={isManuallyManaged ? false : !dirty || loading}
    >
      <>
        {modalError && (
          <Alert
            severity="error"
            sx={{
              marginTop: '15px'
            }}
          >
            {modalError}
          </Alert>
        )}
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          sx={{ width: '100%' }}
        >
          <ChangeTable>
            <tbody>
              {selectedFragment &&
                columnSchema.map(column => {
                  if (column.accessor === 'isManuallyManaged') {
                    return (
                      <ModifiedTableRow key={column.columnName}>
                        <th>{column.columnName}</th>
                        <td>{`${!isManuallyManaged}`}</td>
                      </ModifiedTableRow>
                    );
                  }
                  if (column.accessor === 'outputValue') {
                    return (
                      <ModifiedTableRow key={column.columnName}>
                        <th>{column.columnName}</th>
                        {isManuallyManaged ? (
                          <td>-</td>
                        ) : (
                          <td>{formValues?.outputValue}</td>
                        )}
                      </ModifiedTableRow>
                    );
                  }
                  return (
                    <tr key={column.columnName}>
                      <th>{column.columnName}</th>
                      <td>{`${selectedFragment[column.accessor]}`}</td>
                    </tr>
                  );
                })}
            </tbody>
          </ChangeTable>

          {!isManuallyManaged && (
            <OutputForm autoComplete="off">
              <DynamicForm
                inputs={manuallyManageInputs(isManuallyManaged || false)}
              />
            </OutputForm>
          )}
        </Box>
      </>
    </ConfirmationModal>
  );
};

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(LINK_FORM_NAME)(state);
  const { selectedFragment } = ownProps;

  return {
    formValues,
    initialValues: {
      projectId: selectedFragment?.projectId,
      publisherId: selectedFragment?.publisherId,
      contentValue: selectedFragment?.contentValue
    }
  };
};

export default flow(
  reduxForm({
    form: LINK_FORM_NAME,
    destroyOnUnmount: true,
    enableReinitialize: true
  }),
  connect(mapStateToProps)
)(ConfirmModal);
