import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/client';

import { Box } from '@mui/material';

import { UpdateQuickstartInBulkMutationVariables } from 'src/generated/gql/graphql';
import { formatQuickStartItems } from 'src/common/quickstart';
import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';

import ActiveQuickstarts from './Components/ActiveQuickstarts';
import DeactivatedQuickstarts from './Components/DeactivatedQuickstarts';

import { getManageQuickStarts } from './queries';
import { updateQuickstartInBulk } from './mutations';

const getText = () => ({
  title: t('admin:manageQuickstart.title'),
  subTitle: t('admin:manageQuickstart.subTitle'),
  statusUpdateSuccess: t('admin:manageQuickstart.updateStatusSuccess'),
  statusUpdateError: t('admin:manageQuickstart.updateStatusError')
});

const ManageQuickstarts = () => {
  const text = getText();
  const { enqueueSnackbar } = useSnackbar();

  const { data, loading, refetch } = useQuery(getManageQuickStarts);
  const activeQuickStarts = data?.activeQuickStarts || [];
  const quickStarts = formatQuickStartItems(activeQuickStarts);

  const [updateQuickstartMutation] =
    useMutation<UpdateQuickstartInBulkMutationVariables>(
      updateQuickstartInBulk
    );

  const handleItemStatus = async (id: string, isActive: boolean) => {
    const mutationParams = {
      quickstarts: [{ id, values: { isActive } }]
    };
    try {
      await updateQuickstartMutation({
        variables: {
          Input: { ...mutationParams }
        }
      });
      await refetch();
      enqueueSnackbar(text?.statusUpdateSuccess, {
        variant: 'success'
      });
    } catch (err) {
      enqueueSnackbar(text?.statusUpdateError, {
        variant: 'error'
      });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '150px',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Loading />
      </Box>
    );
  }

  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
      />

      <ActiveQuickstarts
        activeQuickstarts={quickStarts.filter(qs => qs.isActive)}
        handleItemStatus={handleItemStatus}
        refetch={() => refetch()}
        mutationText={text}
      />

      <DeactivatedQuickstarts
        deactivatedQuickstarts={quickStarts.filter(qs => !qs.isActive)}
        handleItemStatus={handleItemStatus}
      />
    </>
  );
};

export default ManageQuickstarts;
