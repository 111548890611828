import { ApolloLink } from '@apollo/client';

import Instrumentation from 'src/instrumentation';
// import { randomUUID } from '../Auth/AuthUtil';

// const getTimingLabels = name => ({
//   start: `start-${name}`,
//   end: `end-${name}`,
//   latency: `latency-${name}`
// });

export const apolloInstrumentationLink = new ApolloLink(
  (operation, forward) => {
    // if (!window.performance) {
    //   return forward(operation);
    // }
    // const { operationName } = operation;
    // const performanceEntryName = `${operationName}-${randomUUID()}`;
    // const { start } = getTimingLabels(performanceEntryName);

    // create an entry in window.performance to mark the start of the request
    // window.performance.mark(start);

    // append this property to the existing context
    operation.setContext((prevContext: Record<string, any>) => {
      const { headers = {} } = prevContext;

      // grab any instrumentation context values that need to be passed
      // along to the backend, e.g. session or device id
      const newHeaders = {
        ...headers,
        'X-Instrumentation-Context': Instrumentation.getContextHeader()
      };

      return {
        ...prevContext,
        headers: newHeaders
        // performanceEntryName
      };
    });

    return forward(operation).map(data => {
      // const { performanceEntryName } = operation.getContext();
      // const { start, end, latency } = getTimingLabels(performanceEntryName);

      // // create an entry in window.performance to mark the end of the request
      // window.performance.mark(end);

      // const startTime =
      //   window.performance?.getEntriesByName(start)?.[0]?.startTime || 0;
      // const endTime =
      //   window.performance?.getEntriesByName(end)?.[0]?.startTime || 0;

      // // clean up the entries so they don't pile up in memory
      // window.performance.clearMarks(start);
      // window.performance.clearMarks(end);
      // window.performance.clearMeasures(latency);

      // if (endTime - startTime > 0) {
      //   // log the event
      //   const eventProperties = {
      //     operationName: operation.operationName,
      //     latency: Math.round(endTime - startTime)
      //   };

      //   Instrumentation.logEvent(
      //     Instrumentation.Events.GraphQLRequest,
      //     eventProperties
      //   );
      // }

      // return this data back to the link chain
      return data;
    });
  }
);
