import { t } from 'i18next';
import { useMemo } from 'react';
import { flow } from 'lodash';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  formContainer: {
    width: '455px'
  }
});

const pageText = () => ({
  label: t('adminContentDataManager:selector.label')
});

const ContentSetSelector = ({
  classes,
  contentSets,
  setContentSet,
  selectedContentSet
}) => {
  const text = useMemo(() => pageText(), []);

  const handleChange = event => {
    setContentSet(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formContainer}
      data-cy="content-data-manager-content-set-selector"
    >
      <InputLabel id="content-set-selector-label">{text.label}</InputLabel>
      <Select
        labelId="content-set-selector-label"
        id="content-set-selector"
        value={selectedContentSet}
        label={text.label}
        onChange={handleChange}
      >
        {contentSets.map(contentSet => (
          <MenuItem value={contentSet.slug} key={contentSet.slug}>
            {contentSet.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default flow(withStyles(styles))(ContentSetSelector);
