import { gql } from 'src/generated/gql';

export const addPaymentMethod = gql(`
  mutation AddPaymentMethod($stripeSourceId: String!) {
    addPaymentMethod(stripeSourceId: $stripeSourceId) {
      id
      userId
      payer
      organizationId
      officeId
      stripeSourceId
      stripeCustomerId
      stripeSource {
        cardBrand
        cardLastFour
        cardExpiryMonth
        cardExpiryYear
      }
    }
  }
`);

export const markPaymentMethodAsRemoved = gql(`
  mutation MarkPaymentMethodAsRemoved($paymentMethodId: ID!) {
    markPaymentMethodAsRemoved(paymentMethodId: $paymentMethodId) {
      id
    }
  }
`);
