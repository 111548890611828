import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Menu,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  MEDIA_SOURCE_THIRD_PARTY,
  MEDIA_TYPE_FILTER_VALUES,
  THIRD_PARTY_MEDIA_SOURCES
} from 'src/pages/Gallery/constants';
import { useRef, useState } from 'react';
import { intersection } from 'lodash';
import { TextIconAligner } from 'src/components/TextIconAligner';
import TuneIcon from '@mui/icons-material/Tune';

const FilterGroupHeader = styled(FormLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1)
}));

const FilterMenuIcon = styled(TextIconAligner)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

const FormControlLeftAligned = styled(FormControl)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}));

const FilterMenuDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1)
}));

const GalleryFilterOptions = ({
  mediaSources,
  text,
  selectedMediaSources,
  setSelectedMediaSources,
  showFilter,
  mediaType,
  setMediaType
}) => {
  // Both media source and type have some odd conversions to do to appease the
  // data models and UI...
  const handleSourceSelection = source => {
    // by default, we'll just toggle our own value
    let valuesToToggle = [source];

    // but if we're toggling third party media, turn on/off all third
    // party media types
    if (source === MEDIA_SOURCE_THIRD_PARTY) {
      valuesToToggle = THIRD_PARTY_MEDIA_SOURCES;
    }

    // This is a pretty inefficient loop, but considering our array
    // sizes will be like 2-5... leaving it as-is.
    let resultValues = selectedMediaSources;
    valuesToToggle.forEach(val => {
      if (resultValues.includes(val)) {
        resultValues = resultValues.filter(v => v !== val);
      } else {
        resultValues = [...resultValues, val];
      }
    });

    setSelectedMediaSources(resultValues);
  };

  /**
   * Determine if we're selected or not, taking third party media into account
   * @returns {boolean}
   */
  const isSourceSelected = source => {
    if (source === MEDIA_SOURCE_THIRD_PARTY) {
      return (
        intersection(THIRD_PARTY_MEDIA_SOURCES, selectedMediaSources).length > 0
      );
    }
    return selectedMediaSources.includes(source);
  };
  return (
    <>
      {Object.keys(mediaSources).length > 1 && (
        <>
          <FormControlLeftAligned>
            <FilterGroupHeader>{text.categoryHeader}</FilterGroupHeader>
            <FormGroup>
              {mediaSources.map(source => {
                return (
                  <FormControlLabel
                    key={`button-${source}`}
                    control={
                      <Checkbox
                        checked={isSourceSelected(source)}
                        onChange={() => handleSourceSelection(source)}
                      />
                    }
                    label={text.filterMenuSource[source]}
                  />
                );
              })}
            </FormGroup>
          </FormControlLeftAligned>
        </>
      )}
      {showFilter && (
        <>
          <FilterMenuDivider variant="middle" />
          <FormControlLeftAligned>
            <FilterGroupHeader id="gallery-filter-menu-media-type">
              {text.filterMenuType.title}
            </FilterGroupHeader>
            <RadioGroup
              aria-labelledby="gallery-filter-menu-media-type"
              value={mediaType}
              onChange={e => setMediaType(e.target.value)}
            >
              {Object.keys(MEDIA_TYPE_FILTER_VALUES).map(type => {
                return (
                  <FormControlLabel
                    key={type}
                    value={type}
                    control={<Radio />}
                    label={text.filterMenuType[type]}
                  />
                );
              })}
            </RadioGroup>
          </FormControlLeftAligned>
        </>
      )}
    </>
  );
};

export const GalleryFilterSideBar = ({
  text,
  mediaSources,
  selectedMediaSources,
  setSelectedMediaSources,
  showFilter,
  mediaType,
  setMediaType
}) => {
  const useFullSizeFilter = useMediaQuery(theme => theme.breakpoints.up('md'));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const filterButtonRef = useRef();

  if (useFullSizeFilter) {
    return (
      <>
        <FilterMenuIcon>
          <TuneIcon />
          <Typography variant="h6">{text.filterHeader}</Typography>
        </FilterMenuIcon>
        <FilterMenuDivider variant="middle" />
        <GalleryFilterOptions
          mediaSources={mediaSources}
          text={text}
          selectedMediaSources={selectedMediaSources}
          setSelectedMediaSources={setSelectedMediaSources}
          showFilter={showFilter}
          mediaType={mediaType}
          setMediaType={setMediaType}
        />
      </>
    );
  }

  return (
    <>
      <Button
        id="filter-menu-dropdown-button"
        aria-controls={isMenuOpen ? 'filter-menu-dropdown' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
        startIcon={<TuneIcon />}
        onClick={() => setIsMenuOpen(true)}
        ref={filterButtonRef}
      >
        {text.filterHeader}
      </Button>
      <Menu
        id="filter-menu-dropdown"
        anchorEl={filterButtonRef.current}
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        MenuListProps={{
          'aria-labelledby': 'filter-menu-dropdown-button'
        }}
      >
        <GalleryFilterOptions
          mediaSources={mediaSources}
          text={text}
          selectedMediaSources={selectedMediaSources}
          setSelectedMediaSources={setSelectedMediaSources}
          showFilter={showFilter}
          mediaType={mediaType}
          setMediaType={setMediaType}
        />
      </Menu>
    </>
  );
};
