import { cloneElement } from 'react';
import { Route } from 'react-router-dom';
import Loading from 'src/components/Loading';

import Auth from 'src/Auth/Auth';

const PrivateRoute = ({
  component: Component,
  componentProps,
  children,
  ...rest
}) => {
  // if we are authenticated / logged in then we can just render
  // the component if not launch the login() action
  return (
    <Route
      {...rest}
      render={props => {
        const isAuthenticated = Auth.isAuthenticated();
        if (!isAuthenticated) {
          // we used to redirect to login here but
          // we should let the Auth class be the authority
          // of what to do when a user is not authenticated
          // we have multiple types of auth and each one usees a different path
          // we don't necessarily want to redirect to /login
          Auth.login();
        }
        return isAuthenticated ? (
          <>
            {Component ? (
              <Component {...componentProps} {...props} />
            ) : (
              <>
                {cloneElement(children, {
                  componentProps,
                  props
                })}
              </>
            )}
          </>
        ) : (
          <Loading />
        );
      }}
    />
  );
};

export default PrivateRoute;
