import { RenderCellProps } from 'src/pages/Audiences/AudiencesTableTypes';
import { AUDIENCE_STATUS } from 'src/pages/Audiences/constants';
import { AccessTimeFilled, Cancel, CheckCircle } from '@mui/icons-material';
import { t } from 'i18next';
import { Box, Tooltip } from '@mui/material';
import { GroupedChannelIcon } from 'src/components/ChannelIcon';
import { Channel } from 'src/common/adChannels';

const pageText = () => ({
  tooltips: {
    [AUDIENCE_STATUS.active]: t('audiences:audiencesTable.tooltip.active'),
    [AUDIENCE_STATUS.pending]: t('audiences:audiencesTable.tooltip.pending'),
    [AUDIENCE_STATUS.error]: t('audiences:audiencesTable.tooltip.failed')
  }
});

const statusIconSx = {
  transform: 'translate(-4px, -10px)'
};

const StatusIcon = ({ status }: { status: string }) => {
  const text = pageText();
  switch (status) {
    case AUDIENCE_STATUS.active:
      return (
        <Tooltip title={text.tooltips.active}>
          <CheckCircle sx={statusIconSx} color="success" />
        </Tooltip>
      );
    case AUDIENCE_STATUS.pending:
      return (
        <Tooltip title={text.tooltips.pending}>
          <AccessTimeFilled sx={statusIconSx} color="warning" />
        </Tooltip>
      );
    case AUDIENCE_STATUS.error:
    case AUDIENCE_STATUS.inactive:
    default:
      return (
        <Tooltip title={text.tooltips.error}>
          <Cancel sx={statusIconSx} color="error" />
        </Tooltip>
      );
  }
};

export const AudiencesTableChannelCell = ({
  value,
  id
}: RenderCellProps<'channelAudiences'>) => {
  if (value?.edges == null) {
    return null;
  }

  const channels = value?.edges?.map(v => {
    if (v?.node == null) {
      return null;
    }

    if (v?.node?.status === AUDIENCE_STATUS.deleted) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex' }} key={v.node.id}>
        <GroupedChannelIcon channel={v.node.channelCode as Channel} />
        <StatusIcon status={v.node.status} />
      </Box>
    );
  });
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      key={`${id}-channelAudiences`}
    >
      {channels}
    </Box>
  );
};
