import { isString, isObject } from 'lodash';
import { Tooltip, Box } from '@mui/material';

const objectToString = (obj: any, pretty = false) => {
  if (isObject(obj) && !isString(obj)) {
    try {
      return pretty ? JSON.stringify(obj, undefined, 2) : JSON.stringify(obj);
    } catch (error) {
      // it's not valid json, but we don't judge here
      return obj;
    }
  }
  // I'm not an object so hey why not try showing me
  return obj;
};

const JsonCell = ({ value }: any) => {
  return (
    <Tooltip
      title={
        <>
          <pre>{objectToString(value, true)}</pre>
        </>
      }
    >
      <Box
        sx={{
          maxWidth: '250px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingRight: '1rem',
          color: theme => theme.palette.primary.main,
          cursor: 'pointer'
        }}
      >
        {objectToString(value)}
      </Box>
    </Tooltip>
  );
};

export default JsonCell;
