import { getOrgGroups } from 'src/pages/Admin/CreateUser/queries';
import { GetOrgGroupsQuery } from 'src/generated/gql/graphql';
import { QueryHookOptions, useQuery } from '@apollo/client';

const useGetOrgGroups = (options: QueryHookOptions<GetOrgGroupsQuery> = {}) => {
  const { data, loading, error } = useQuery<GetOrgGroupsQuery>(
    getOrgGroups,
    options
  );

  return {
    groups: data?.myOrganization?.groups,
    loading,
    error
  };
};

export default useGetOrgGroups;
