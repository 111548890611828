import { useQuery } from '@apollo/client';
import { Trans } from 'react-i18next';
import { flow, get, pick } from 'lodash';

import { Grid } from '@mui/material';

import { withAppSettings } from 'src/AppSettings';
import { withGlobalContext } from 'src/GlobalContextProvider';

import { demoUserIds } from 'src/common/demoUser/demoUsers';
import { demoUserData } from 'src/common/demoUser/demoUserData';

import Kpi from 'src/components/Kpi';
import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import { ordersV2 } from './queries';

const extractKPIs = (programs = []) => {
  return programs.reduce(
    (accum, program) => {
      const kpis = program?.products?.[0]?.kpis || [];
      kpis.forEach(kpi => {
        // Note: some kpis like impressions have the same slug in google and facebook
        //       so we also need to check on channel as well.
        if (
          !accum.kpiKeys.has(kpi.slug) ||
          !accum.kpiChannels.has(kpi.channelCode)
        ) {
          accum.kpiKeys.add(kpi.slug);
          accum.kpiChannels.add(kpi.channelCode);
          accum.kpis.push(pick(kpi, ['slug', 'channelCode']));
        }
      });

      return accum;
    },
    { kpiChannels: new Set(), kpiKeys: new Set(), kpis: [] }
  ).kpis;
};

const getDemoInsights = (userId, appSettings) => {
  const localInsights = appSettings?.app?.demoOverrides?.dashboardInsights;

  let demoInsights = null;

  // Demo overrides for Facebook Demo User.
  if (demoUserIds[userId]) {
    demoInsights = get(demoUserData, 'dashboardInsights');
  }

  // Always return local overrides if those are set
  if (localInsights) {
    demoInsights = localInsights;
  }

  return demoInsights;
};

const DashboardKPIs = ({ userId, appSettings }) => {
  const demoInsights = getDemoInsights(userId, appSettings);
  const { data, loading, error } = useQuery(ordersV2);

  const orders = (data?.ordersV2?.edges || []).map(order => order.node);
  const kpis = extractKPIs(orders);

  return (
    <Grid item xs={12}>
      {loading && <Loading />}
      {error && (
        <ErrorMessage>
          <Trans i18nKey="programPerf:kepi.error">
            There was an error fetching metrics data
          </Trans>
        </ErrorMessage>
      )}
      {!loading && !error && (
        <Kpi
          demoInsights={demoInsights}
          kpis={kpis}
          programIds={orders.map(p => p.programId)}
          sourcePage="dashboard"
        />
      )}
    </Grid>
  );
};

export default flow(withGlobalContext, withAppSettings)(DashboardKPIs);
