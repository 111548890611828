import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { find } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { Dispatch } from 'redux';
import { useFormContext, useWatch } from 'react-hook-form';

import {
  Button,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem
} from '@mui/material';

import { Info as InfoIcon, Add as AddIcon } from '@mui/icons-material';

import {
  isCorporateFacebookPage,
  facebookPageTypes,
  isFacebookPageUsable,
  FACEBOOK_URLS
} from 'src/common/FacebookUtil';
import Instrumentation from 'src/instrumentation';
import { FacebookPageSelection } from 'src/generated/gql/graphql';

import { paths } from 'src/routes/paths';
import { TooltipLink } from 'src/components/Link';
import TooltipMenuItem from 'src/components/TooltipMenuItem';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';

import { setContinueCreateProgram } from '../../actions';

type FormValues = Record<string, any>;

interface Facebook {
  getSelectedFacebookPage: (formValues: FormValues) => FacebookPageSelection;
  facebookPages: FacebookPageSelection[];
}
interface FacebookSettingsProps {
  facebook: Facebook;
  setContinueCreateProgram: (url: string) => (dispatch: Dispatch) => void;
  isLeadFormFacebookBlueprint: boolean;
}

const FacebookSettings = ({
  facebook,
  setContinueCreateProgram,
  isLeadFormFacebookBlueprint
}: FacebookSettingsProps) => {
  const history = useHistory();
  const location = useLocation();
  const { setValue } = useFormContext();
  const { facebookPages = [] } = facebook;

  const pageId = useWatch({
    name: 'dynamicUserInputs.pageId',
    defaultValue: ''
  });
  const pageGroupId = useWatch({
    name: 'dynamicUserInputs.pageGroupId',
    defaultValue: ''
  });

  const setPageIds = ({
    target: { value: newValue }
  }: {
    target: { value: string };
  }) => {
    if (newValue) {
      Instrumentation.logEvent(
        Instrumentation.Events.ProgramSelectFacebookPageClicked
      );

      const selectedPage = find(
        facebookPages,
        page => page.pageGroupId === newValue || page.pageId === newValue
      );

      setValue('dynamicUserInputs.pageId', selectedPage?.pageId ?? null);

      setValue(
        'dynamicUserInputs.instagramActorId',
        selectedPage?.instagramId ?? null
      );

      setValue(
        'dynamicUserInputs.pageGroupId',
        selectedPage?.pageGroupId ?? null
      );
    }
  };

  const linkNewFacebook = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ProgramLinkFacebookPageClicked
    );
    setContinueCreateProgram(`${location.pathname}${location.search}`);
    history.push(
      generateLinkPathWithQueryParams(paths.facebook.link, undefined, {
        returnUrl: true
      })
    );
  };

  const label = t('programCreate:configure.facebookPageLabel');

  const renderSelectedValue = (value: string) => {
    const selectedPage = facebookPages.find(
      page =>
        (page.type === facebookPageTypes.pageGroup &&
          page.pageGroupId === value) ||
        page.pageId === value
    );

    return selectedPage?.name;
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel shrink variant="outlined" id="facebookSelector">
          {label}
        </InputLabel>
        <Select
          labelId="facebookSelector"
          id="facebookSelector"
          value={pageGroupId || pageId}
          label={label}
          onChange={setPageIds}
          renderValue={renderSelectedValue}
        >
          {facebookPages.map(page => {
            const valueToUse = (
              page?.type === facebookPageTypes.pageGroup
                ? page?.pageGroupId
                : page?.pageId
            ) as string;

            const isUsable = isFacebookPageUsable(
              page,
              isLeadFormFacebookBlueprint
            );
            const hasAcceptedTos = page?.hasAcceptedTos;

            const TosLinkComponent = (
              <TooltipLink to={FACEBOOK_URLS.leadgenTos} target="_blank" />
            );

            if (!hasAcceptedTos && isLeadFormFacebookBlueprint) {
              return (
                <TooltipMenuItem
                  key={valueToUse}
                  value={valueToUse}
                  tooltipTitle={
                    <Trans
                      i18nKey="programCreate:configure.facebookPageTosWarning"
                      components={[TosLinkComponent]}
                    />
                  }
                >
                  {page?.name}
                </TooltipMenuItem>
              );
            }

            return (
              <MenuItem
                value={valueToUse}
                disabled={!isUsable}
                key={`fb-page-${valueToUse}`}
              >
                {page?.name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          {t('programCreate:configure.facebookPageHelperText')}
        </FormHelperText>
      </FormControl>
      {!isCorporateFacebookPage(
        facebook?.getSelectedFacebookPage({ pageId, pageGroupId })
      ) ? (
        <div>
          <Button color="primary" size="small" onClick={linkNewFacebook}>
            <AddIcon />
            <Trans i18nKey="programCreate:configure.linkNewButton" />
          </Button>
        </div>
      ) : (
        <div>
          <InfoIcon sx={{ position: 'relative', top: '5px' }} />{' '}
          <Trans i18nKey="programCreate:configure.facebookDefaultWarning" />{' '}
          <Button color="primary" size="small" onClick={linkNewFacebook}>
            <AddIcon />
            <Trans i18nKey="programCreate:configure.linkNewDefaultButton" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default connect(null, {
  setContinueCreateProgram
})(FacebookSettings);
