import { Box, Theme } from '@mui/material';
import { styled } from '@mui/system';

import { DRAWER_FULL_SCREEN_BREAKPOINT } from '../ProgramPreviewDrawer/constants';

const InnerContainer = styled(Box)<{ previewDrawerOpen: boolean }>(
  ({ theme, previewDrawerOpen }) => ({
    flexDirection: 'row',
    display: 'flex',
    flexGrow: 1,
    transition: 'transition: padding 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    gap: theme.spacing(7.5),
    paddingRight: previewDrawerOpen ? 0 : theme.spacing(12),
    // drawer open styles
    ...(previewDrawerOpen && {
      [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
        paddingRight: (theme: Theme) =>
          `calc(${theme.spacing(3)} + ${theme.evSizes.previewDrawerWidth}px) !important`
      }
    }),
    // drawer closed styles
    ...(!previewDrawerOpen && {
      [theme.breakpoints.down('xl')]: {
        paddingRight: theme.spacing(6)
      }
    }),
    ...(!previewDrawerOpen && {
      [theme.breakpoints.down('md')]: {
        paddingRight: theme.spacing(0)
      }
    })
  })
);

export default InnerContainer;
