import { Button, CircularProgress } from '@mui/material';
import { useRef, useState } from 'react';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';
import { getAiTextSuggestions } from 'src/components/AiSuggestion/queries';
import { t } from 'i18next';
import { styled } from '@mui/system';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import Instrumentation from 'src/instrumentation';
import {
  AiErrorText,
  aiLoadingSpinnerSize
} from 'src/components/AiSuggestion/AiSuggestionStyles';
import { Events } from 'src/instrumentation/constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import useOnMount from 'src/hooks/useOnMount';

const getText = () => {
  return {
    errorMessage: t('aiSuggestion:errorMessage'),
    suggestionButton: {
      first: t('aiSuggestion:suggestionButton.first'),
      again: t('aiSuggestion:suggestionButton.again')
    }
  };
};

const AiSuggestionWrapper = styled('div')(() => ({
  // Without this we get bad alignment on some of our child components.
  position: 'relative'
}));

export interface AiTextSuggestionButtonProps {
  /**
   * The current value in the parent input.
   */
  value: string;
  onAiSuggestionReceived: (suggestion: string) => void;
  productId: string;
  blueprintVariableId: string;
  catalogItemId: string | undefined;
}

/**
 * Renders a button that can be used to generate text suggestions
 * via ✨AI✨
 *
 * This fires a callback when an AI suggestion is received. However,
 * note that this callback is highly asynchronous. Don't expect a click
 * to immediately trigger a callback.
 *
 * Implementation notes:
 * Right now this is pretty hardcoded to our specific scenario.
 * Perhaps it would be good to expand the query capabilities such that
 * you don't have to pass in such specifics like blueprint variables
 * and catalogs.
 * For now though, the backend only supports these things, so we require them.
 */
const AiTextSuggestionButton = ({
  onAiSuggestionReceived,
  value,
  productId,
  blueprintVariableId,
  catalogItemId
}: AiTextSuggestionButtonProps) => {
  const fetchAiSuggestions = useInvokableQuery(getAiTextSuggestions);
  const architecture = useArchitecture();
  const catalogId = architecture?.catalog?.id;

  // We have a different string on the first vs subsequent generations
  const [didSuggest, setDidSuggest] = useState(false);

  // Track the number of clicks on this button that way we can log that to
  // amplitude and know how many times users repeatedly clicked on this.
  const clickCountRef = useRef(0);

  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState<string | null>(null);
  const text = getText();

  const handleFetchSuggestion = async () => {
    setIsLoading(true);
    setErrorText(null);

    // Keep a local var separate from react state, so that we can log success/failure
    let didSucceed = false;
    if (!catalogId) {
      return;
    }
    try {
      const textSuggestionResult = await fetchAiSuggestions({
        inputs: [
          {
            blueprintVariableId,
            catalogId,
            // Manually construct our catalog filter.
            // Again, very hardcoded but we only support 1 content item
            // on the backend, so we don't really need any more flexible
            // queries here.
            catalogFilter: {
              id: {
                in: [catalogItemId]
              }
            }
          }
        ]
      });

      onAiSuggestionReceived(
        textSuggestionResult.data.getAiTextSuggestions[0].suggestions[0]
      );

      // If we at least attempted to broadcast an AI suggestion note that.
      // Nothing may have been done with it, but at least we tried!
      setDidSuggest(true);
      didSucceed = true;
    } catch (e) {
      setErrorText(text.errorMessage);
    } finally {
      // Make sure that no matter what we do, error or otherwise, we
      // always reset our variables around loading
      setIsLoading(false);

      // Also make sure we log our interaction.
      // Incrementing click count prior to sending so that we start on 1
      clickCountRef.current++;
      Instrumentation.logEvent(Events.ClickAiCopy, {
        success: didSucceed,
        click: clickCountRef.current,
        architectureId: architecture.id,
        productId
      });
    }
  };

  // Just to be a bit safer, let's make sure the button is actually disabled
  // if any of the inputs we need are missing, or we're actively loading
  const isButtonDisabled =
    blueprintVariableId == null ||
    catalogId == null ||
    catalogItemId == null ||
    isLoading;

  useOnMount(() => {
    // If we don't have a value yet, provide one
    const valueMissing = value == null || value === '';
    // Also make sure that we're in a valid state to actually do a fetch
    if (valueMissing && !isButtonDisabled) {
      handleFetchSuggestion().catch(() => {
        setErrorText(text.errorMessage);
      });
    }
  });

  return (
    <AiSuggestionWrapper>
      <Button
        onClick={handleFetchSuggestion as () => void}
        disabled={isButtonDisabled}
        endIcon={
          !isLoading ? (
            <RefreshIcon />
          ) : (
            <CircularProgress size={aiLoadingSpinnerSize} />
          )
        }
      >
        {didSuggest ? text.suggestionButton.again : text.suggestionButton.first}
      </Button>
      {errorText && <AiErrorText variant="body1">{errorText}</AiErrorText>}
    </AiSuggestionWrapper>
  );
};

export default AiTextSuggestionButton;
