import { useEffect, useCallback, useMemo, useState } from 'react';
import { flow, sortBy } from 'lodash';
import { reduxForm } from 'redux-form';
import { useLazyQuery } from '@apollo/client';

import { Button } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import {
  CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION,
  CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT,
  getOrganizationChannelAllocationConfigTypesInputs,
  getProjectChannelAllocationConfigTypesInputs
} from './constants';
import { loadChannelSpendAllocationConfigs } from './queries';

const CHANNEL_SPEND_ALLOCATION_CONFIG_FORM = 'channelSpendAllocationConfigForm';

const DEFAULT_RULES = [
  {
    channelCode: 'fb',
    weight: 0
  },
  {
    channelCode: 'google',
    weight: 0
  },
  {
    channelCode: 'tiktok',
    weight: 0
  }
];

const ChannelSpendAllocationConfigFinder = props => {
  const { configType, organizations, storeConfig, handleSubmit } = props;

  const [getConfig, { data: getConfigResult, loading }] = useLazyQuery(
    loadChannelSpendAllocationConfigs,
    { fetchPolicy: 'network-only', nextFetchPolicy: 'network-only' }
  );

  const [selectedOrg, setSelectedOrg] = useState({});

  const [selectedProjectId, setSelectedProjectId] = useState(undefined);

  const configIsValid = useCallback(
    cfg => {
      if (cfg.poolTypeSlug !== configType) {
        return false;
      }

      switch (cfg.poolTypeSlug) {
        case CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug:
          return !!cfg.organizationId;
        case CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT.slug:
          return !!cfg.projectId;
        default:
          return false;
      }
    },
    [configType]
  );

  // The default configuration to use if one doesn't exist in the backend yet
  const defaultConfig = useMemo(() => {
    return {
      poolTypeSlug: configType,
      organizationId:
        configType === CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug
          ? selectedOrg?.id
          : undefined,
      projectId:
        configType === CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT.slug
          ? selectedProjectId
          : undefined,
      rules: DEFAULT_RULES
    };
  }, [configType, selectedOrg, selectedProjectId]);

  // Wrapper around the storeConfig callback from props
  const storeConfigResultOrDefault = useCallback(() => {
    if (loading) {
      return;
    }

    const configsFromResult =
      getConfigResult?.getChannelSpendAllocationConfigs?.configs || [];

    const resultingConfig =
      configsFromResult.length === 1 ? configsFromResult[0] : defaultConfig;

    if (configIsValid(resultingConfig)) {
      storeConfig(resultingConfig);
    } else {
      storeConfig(undefined);
    }
  }, [getConfigResult, loading, defaultConfig, configIsValid, storeConfig]);

  // If the config found has changed, store it.
  useEffect(() => {
    if (getConfigResult && !loading) {
      storeConfigResultOrDefault();
    }
  }, [getConfigResult, loading, storeConfigResultOrDefault]);

  // Callback for fetching a new channel spend config when
  // the user clicks the find config button
  const submitConfigCallback = useCallback(
    data => {
      switch (configType) {
        case CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug:
          if (data.organizationName) {
            const org = organizations.find(
              o => o.name === data.organizationName
            );

            setSelectedOrg(org);
            getConfig({
              variables: {
                filter: {
                  includeGlobalDefault: false,
                  includeGlobalOverride: false,
                  organizationIds: [org.id]
                }
              }
            });
          }
          break;

        case CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT.slug:
          if (data.projectId) {
            setSelectedProjectId(data.projectId);
            getConfig({
              variables: {
                filter: {
                  includeGlobalDefault: false,
                  includeGlobalOverride: false,
                  projectIds: [data.projectId]
                }
              }
            });
          }
          break;

        default:
          // should never happen
          break;
      }
    },
    [setSelectedProjectId, setSelectedOrg, getConfig, configType, organizations]
  );

  return (
    <form autoComplete="false" onSubmit={handleSubmit(submitConfigCallback)}>
      <>
        {configType === CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug && (
          <DynamicForm
            inputs={getOrganizationChannelAllocationConfigTypesInputs(
              sortBy(organizations, o => o.name.toLowerCase()).map(o => o.name)
            )}
          />
        )}
        {configType === CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT.slug && (
          <DynamicForm
            inputs={getProjectChannelAllocationConfigTypesInputs()}
          />
        )}
        {(configType === CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION.slug ||
          configType === CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT.slug) && (
          <Button color="primary" type="submit" variant="outlined">
            Find configuration
          </Button>
        )}
      </>
    </form>
  );
};

export default flow(
  reduxForm({
    form: CHANNEL_SPEND_ALLOCATION_CONFIG_FORM,
    enableReinitialize: true
  })
)(ChannelSpendAllocationConfigFinder);
