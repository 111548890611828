import { useMemo, useState, useCallback } from 'react';
import { flow } from 'lodash';
import { t } from 'i18next';
import { graphql } from '@apollo/client/react/hoc';

import { Menu, MenuItem, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getFacetHelperData } from '../../queries';

const pageText = () => ({
  addFacetMenuItem: t(
    'admin:blueprintBuilder.stepPublishersFacet.addFacetMenuItem'
  )
});

const AddFacet = props => {
  const { facetEnums, onAddFacet } = props;

  const text = useMemo(() => pageText(), []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    facet => {
      onAddFacet(facet);
      handleClose();
    },
    [handleClose, onAddFacet]
  );

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        variant="contained"
        onClick={handleClick}
      >
        <AddIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem key="addFacetMenuItem" disabled>
          {text.addFacetMenuItem}
        </MenuItem>
        {facetEnums.map(option => (
          <MenuItem
            key={option.value}
            onClick={() => handleSelect(option.value)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default flow(
  graphql(getFacetHelperData, {
    name: 'getFacetHelperData',
    options: props => {
      const { unitId, prototype } = props;

      const variables = {
        prototype,
        unitId
      };

      return {
        variables
      };
    },
    props: ({ getFacetHelperData }) => {
      const facets =
        getFacetHelperData?.getUnits?.[0]?.prototypes?.[0]?.facets || [];
      const facetEnums = facets.map(facet => {
        return {
          name: facet?.name,
          value: facet?.slug
        };
      });
      return {
        facetEnums
      };
    }
  })
)(AddFacet);
