import {
  Box,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardHeader
} from '@mui/material';
import { FacebookIconSquare } from 'src/components/Icons';

interface PageLinkCardProps {
  children: React.ReactNode;
  actions: React.ReactNode;
  title: string;
  width?: number;
}

const PageLinkCard = ({
  children,
  actions,
  title,
  width = 659
}: PageLinkCardProps) => {
  return (
    <Card
      sx={{
        width,
        py: 3.5,
        px: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 2.5
      }}
    >
      <CardHeader
        sx={{ p: 0 }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FacebookIconSquare width={22} height={22} />
            <Typography variant="h6" fontWeight={500} component="h2">
              {title}
            </Typography>
          </Box>
        }
      />
      <CardContent sx={{ p: 0 }}>{children}</CardContent>
      <CardActions sx={{ pb: 0, px: 0, pt: 1 }}>{actions}</CardActions>
    </Card>
  );
};

export default PageLinkCard;
