import { connect, DefaultRootState } from 'react-redux';
import { getFormValues, isSubmitting, reduxForm } from 'redux-form';
import {
  createGoogleAccountInputs,
  defaultAudienceToolColumnSettings,
  FORM_NAME_CREATE_GOOGLE_ACCOUNT
} from 'src/pages/Admin/AudienceTools/constants';
import { flow } from 'lodash';
import { ReactElement, RefObject } from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import DataTable from 'src/components/DataTable';
import { DynamicForm } from 'src/components/ReduxForm';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import SentryUtil from 'src/common/SentryUtil';
import { GridSortModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import {
  fetchGoogleAccountsQuery,
  GoogleAccount,
  upsertGoogleAccountMutation
} from 'src/pages/Admin/AudienceTools/Forms/CreateGoogleAccount/queries';
import { GridColDef } from '@mui/x-data-grid';

const columns = (): GridColDef[] => [
  {
    ...defaultAudienceToolColumnSettings,
    field: 'name',
    headerName: t('audienceTools:googleAccount.tableHeader.name'),
    editable: true
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'description',
    headerName: t('audienceTools:googleAccount.tableHeader.description'),
    editable: true
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'managerAccountId',
    headerName: t('audienceTools:googleAccount.tableHeader.managerAccountId')
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'customerAccountId',
    headerName: t('audienceTools:googleAccount.tableHeader.customerAccountID')
  },
  {
    ...defaultAudienceToolColumnSettings,
    field: 'isActive',
    headerName: t('audienceTools:googleAccount.tableHeader.isActive'),
    editable: true,
    type: 'boolean'
  }
];

const pageText = () => ({
  genericError: t('audienceTools:googleAccount.genericError'),
  createAccountSuccess: t('audienceTools:googleAccount.createSuccess'),
  editAccountSuccess: t('audienceTools:googleAccount.editSuccess'),
  createRule: t('audienceTools:googleAccount.createRule'),
  editRule: t('audienceTools:googleAccount.editRule')
});

interface GoogleAccountFormValues {
  name: string;
  description: string;
  managerAccountId: string;
  customerAccountId: string;
  isActive: boolean;
}

const isFormValueInList = (
  formValues: GoogleAccountFormValues | undefined,
  existingAccounts: GoogleAccount[] | undefined,
  gridApiRef: RefObject<GridApiPro>
) => {
  if (formValues == null || existingAccounts == null) {
    return false;
  }

  const existingAccount = existingAccounts.find(acc => {
    return (
      acc.managerAccountId === formValues.managerAccountId &&
      acc.customerAccountId === formValues.customerAccountId
    );
  });

  if (existingAccount == null) {
    return false;
  }

  return gridApiRef.current?.getRow(existingAccount.id) != null;
};

interface InternalCreateGoogleAccountProps
  extends InjectedFormProps<GoogleAccountFormValues> {
  formValues?: GoogleAccountFormValues;
}

const CreateGoogleAccount = ({
  formValues,
  submitting,
  dirty,
  handleSubmit,
  invalid,
  reset
}: InternalCreateGoogleAccountProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const text = pageText();

  const [fetchGoogleAccounts, existingAccounts] = useLazyQuery(
    fetchGoogleAccountsQuery
  );

  const [upsertGoogleAccount] = useMutation(upsertGoogleAccountMutation);

  const gridApiRef = useGridApiRef();

  const isEditingExistingAccount = isFormValueInList(
    formValues,
    existingAccounts?.data?.googleAccounts,
    gridApiRef
  );

  const onSubmit = async (formValues: GoogleAccountFormValues) => {
    try {
      const result = await upsertGoogleAccount({
        variables: {
          input: {
            name: formValues.name,
            description: formValues.description,
            isActive: formValues.isActive,
            managerAccountId: formValues.managerAccountId,
            customerAccountId: formValues.customerAccountId
          }
        }
      });

      if (result.data?.upsertGoogleAccount == null || result.errors != null) {
        const message = result.errors?.[0]?.message;
        enqueueSnackbar(message || text.genericError, {
          variant: 'error'
        });
        return;
      }

      enqueueSnackbar(
        isEditingExistingAccount
          ? text.editAccountSuccess
          : text.createAccountSuccess,
        {
          variant: 'success'
        }
      );

      gridApiRef.current?.updateRows([result.data.upsertGoogleAccount]);

      reset();
    } catch (ex) {
      SentryUtil.captureException(ex as Error);
      enqueueSnackbar(text.genericError, {
        variant: 'error'
      });
    }
  };

  const loadMoreRows = async (
    _reset: boolean,
    _newRowsLength?: number,
    _sortModel?: GridSortModel
  ) => {
    const results = await fetchGoogleAccounts({
      fetchPolicy: 'no-cache'
    });

    // This endpoint is not paginated and thus never has more rows
    return {
      rows: results.data?.googleAccounts ?? [],
      hasMoreRows: false
    };
  };

  const saveRow = async (row: GoogleAccount) => {
    const result = await upsertGoogleAccount({
      variables: {
        input: {
          customerAccountId: row.customerAccountId,
          managerAccountId: row.managerAccountId,
          name: row.name,
          description: row.description,
          isActive: row.isActive
        }
      }
    });

    return {
      success: true,
      values: result?.data?.upsertGoogleAccount
    };
  };

  return (
    <Box>
      <Box>
        <Typography
          component="h3"
          sx={theme => ({ marginBottom: theme.spacing(1) })}
        >
          <Trans i18nKey="audienceTools:googleAccount.tableTitle">Rules</Trans>
        </Typography>
        <DataTable
          columns={columns()}
          loadMoreRows={loadMoreRows}
          saveRow={saveRow}
          customApiRef={gridApiRef}
        />
      </Box>
      <Box>
        <form
          autoComplete="off"
          data-cy="create-google-account"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={theme => ({
              marginBottom: theme.spacing(1),
              marginTop: theme.spacing(2)
            })}
          >
            <Typography component="h3">
              <Trans i18nKey="audienceTools:googleAccount.formCreateTitle">
                Create an Account
              </Trans>
            </Typography>
            {isEditingExistingAccount && (
              <Typography sx={{ color: 'warning.main' }}>
                <Trans i18nKey="audienceTools:googleAccount.editWarning">
                  You are editing an existing account
                </Trans>
              </Typography>
            )}
          </Box>
          <DynamicForm inputs={createGoogleAccountInputs()} />
          <Button
            variant="contained"
            color="primary"
            disabled={!dirty || invalid || submitting}
            type="submit"
          >
            {isEditingExistingAccount ? text.editRule : text.createRule}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

function mapStateToProps(state: DefaultRootState) {
  const formValues = getFormValues(FORM_NAME_CREATE_GOOGLE_ACCOUNT)(state);
  const submitting = isSubmitting(FORM_NAME_CREATE_GOOGLE_ACCOUNT)(state);

  const initialValues = {
    isActive: true
  };

  return { formValues, initialValues, submitting };
}

export default flow(
  reduxForm({
    form: FORM_NAME_CREATE_GOOGLE_ACCOUNT,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps)
)(CreateGoogleAccount) as () => ReactElement;
