import { get } from 'lodash';

import withTheme from '@mui/styles/withTheme';

const FbSingleImageAd = ({ className = '', theme }) => {
  return (
    <svg className={className} width="100%" viewBox="0 0 218 197">
      <defs>
        <filter
          id="FbSingleImageAd-id-a"
          width="112%"
          height="113.9%"
          x="-6.2%"
          y="-7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="2"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <rect
          id="FbSingleImageAd-id-b"
          width="22.427"
          height="22.946"
          x=".531"
          y=".099"
          rx="11.213"
        />
      </defs>
      <g
        fill={get(theme, 'palette.blueprintIcons.background')}
        fillRule="evenodd"
        filter="url(#FbSingleImageAd-id-a)"
        transform="translate(5 3)"
      >
        {/* background */}
        <path
          fill={get(theme, 'palette.blueprintIcons.background')}
          fillRule="nonzero"
          stroke="#CECECC"
          d="M14.17 0h179.685c7.826 0 14.17 6.344 14.17 14.17v158.563c0 7.826-6.344 14.17-14.17 14.17H14.17c-7.826 0-14.17-6.344-14.17-14.17V14.17C0 6.344 6.344 0 14.17 0z"
        />
        {/* profile icon */}
        <use
          fill={get(theme, 'palette.blueprintIcons.profileIcon')}
          fillRule="nonzero"
          transform="translate(10.614 10.62)"
          xlinkHref="#FbSingleImageAd-id-b"
        />
        {/* profile icon text */}
        <path
          fill={get(theme, 'palette.blueprintIcons.text')}
          fillRule="nonzero"
          d="M45.64 12.743h53.064a3.186 3.186 0 1 1 0 6.372H45.64a3.186 3.186 0 0 1 0-6.372zM45.64 23.363h40.328a3.186 3.186 0 1 1 0 6.372H45.64a3.186 3.186 0 1 1 0-6.372z"
        />
        {/* description text */}
        <rect
          width="186.798"
          height="6.372"
          x="10.614"
          y="42.478"
          fill={get(theme, 'palette.blueprintIcons.text')}
          fillRule="nonzero"
          rx="3.186"
        />
        <path
          fill={get(theme, 'palette.blueprintIcons.text')}
          fillRule="nonzero"
          d="M13.8 55.221h165.567a3.186 3.186 0 1 1 0 6.372H13.799a3.186 3.186 0 0 1 0-6.372z"
        />
        {/* image */}
        <path
          fill={get(theme, 'palette.blueprintIcons.singleImage')}
          fillRule="nonzero"
          d="M22.614 70.089H185.41c6.628 0 12 5.372 12 12v82.194c0 6.628-5.372 12-12 12H22.614c-6.628 0-12-5.372-12-12V82.09c0-6.628 5.372-12 12-12z"
        />
      </g>
    </svg>
  );
};

export default withTheme(FbSingleImageAd);
