import { Cloudinary } from '@cloudinary/url-gen';

export const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/evocalize';

export const CLOUDINARY_BASE_VIDEO_URL = `${CLOUDINARY_BASE_URL}/video/fetch/f_auto/`;

const CLOUDINARY_REGEX = /^CLOUDINARY_URL: ?(.*)$/;

export const isUnsignedCloudinaryUrl = (url = '') => {
  return url.match(CLOUDINARY_REGEX) !== null;
};

export const extractCloudinaryUrl = (unsignedUrl = '') => {
  const val = unsignedUrl.match(CLOUDINARY_REGEX);

  if (Array.isArray(val) && val[1]) {
    return val[1];
  }

  return null;
};

export const CLOUDINARY_INVALID_SOURCE = 'CLOUDINARY_INVALID_SOURCE';
export const CLOUDINARY_URL_PREFIX = 'CLOUDINARY_URL:';

export const hasInvalidCloudinarySourceUrl = (url = '') => {
  return url.indexOf(CLOUDINARY_INVALID_SOURCE) !== -1;
};

export const cld = new Cloudinary({
  cloud: {
    cloudName: 'evocalize'
  },
  url: {
    secureDistribution: 'res.cloudinary.com',
    secure: true
  }
});
