import { useState } from 'react';
import { flow, get, set } from 'lodash';
import { Trans } from 'react-i18next';

import Loading from 'src/components/Loading';

import { Button, Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  errorMessage: {
    margin: '0 auto'
  },
  highlight: {
    background: theme.palette.success[50],
    display: 'flex',
    justifyContent: 'space-between'
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(2)
  }
});

const LoadMore = props => {
  const { pageInfo = {}, classes, fetchMore, mergePath } = props;

  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(null);

  const loadMore = () => {
    setIsFetching(true);

    fetchMore({
      variables: {
        endCursor: get(pageInfo, 'endCursor')
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        // Note: this all gets run through the graphql props below so we only need to
        //       merge in the new edges. By merging the old data into the new it will keep
        //       the cursor correct so we can keep adding items.
        if (!fetchMoreResult) return prev;

        const newResult = fetchMoreResult;
        const oldData = get(prev, mergePath);
        const newData = get(fetchMoreResult, mergePath);

        set(newResult, mergePath, [...oldData, ...newData]);

        return newResult;
      }
    })
      .then(() => {
        setIsFetching(false);
      })
      .catch(e => {
        setIsError(e);
      });
  };

  const { hasNextPage } = pageInfo;

  return (
    <div>
      {hasNextPage && (
        <Paper className={classes.pagination}>
          {isFetching || isError ? (
            <Loading error={isError} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={loadMore}
            >
              <Trans i18nKey="common:loadMoreButton">Load More</Trans>
            </Button>
          )}
        </Paper>
      )}
    </div>
  );
};

export default flow(withStyles(styles))(LoadMore);
