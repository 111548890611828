import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { flow, sortBy, kebabCase } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { useSnackbar } from 'notistack';
import { reduxForm, getFormValues } from 'redux-form';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, Typography, Divider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AddIcon from '@mui/icons-material/Add';

import Loading from 'src/components/Loading';
import Modal from 'src/components/Modal';
import NavigationBlocker from 'src/components/NavigationBlocker';

import { DynamicForm } from 'src/components/ReduxForm';

import {
  getCatalogContentTypes,
  getContentTableNames,
  getContentTableColumns
} from './queries';
import { createCatalogAndContentSet } from './mutations';
import { getCatalogCreateInputs, getContentThumbnail } from './constants';
import {
  formatContentSetSubmissionData,
  formatContentTableColumns
} from './helpers';

const styles = theme => ({
  subHeading: {
    margin: `${theme.spacing(4)} 0 0 0`,

    '& p': {
      fontWeight: 'bold'
    }
  }
});

const CreateNewCatalogForm = ({
  classes,
  history,
  handleSubmit,
  submitting,
  dirty,
  reset,
  refetch,
  selectedArchitecture,
  createCatalogAndContentSet,
  getCatalogContentTypes: { loading: contentTypesLoading, catalogContentTypes },
  getContentTableNames: { loading: tableNamesLoading, contentTableMetadata },
  contentTableColumns,
  isAccessTableControlled
}) => {
  const tableNames = sortBy(contentTableMetadata?.contentTableNames || []);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const openModal = useCallback(() => {
    setNewModalOpen(true);
  }, [setNewModalOpen]);

  const closeModal = useCallback(() => {
    reset();
    setNewModalOpen(false);
  }, [setNewModalOpen]);

  const confirmCloseModal = () => {
    // eslint-disable-next-line no-alert
    const fart = window.confirm('Are you sure you wish to close this form?');
    if (fart) {
      closeModal();
    }
  };

  const redirect = useCallback(
    (architectureId, catalogId) => {
      const linkPath = generateLinkPath(paths.admin.settings.catalogContent, {
        architectureId,
        catalogId
      });

      return history.push(linkPath);
    },
    [history]
  );

  const createCatalog = useCallback(
    async data => {
      const formatted = formatContentSetSubmissionData(data);

      try {
        const catalog = await createCatalogAndContentSet({
          variables: {
            architectureId: selectedArchitecture?.id,
            input: formatted
          }
        });
        enqueueSnackbar(t('admin:catalogContent.catalogCreateFormSuccess'), {
          variant: 'success'
        });
        closeModal();

        redirect(
          selectedArchitecture?.id,
          catalog.data.createCatalogAndContentSet.id
        );
      } catch (error) {
        // error
        enqueueSnackbar(t('admin:catalogContent.catalogCreateFormError'), {
          variant: 'error'
        });
        closeModal();
        refetch();
      }
    },
    [createCatalogAndContentSet, selectedArchitecture, refetch, closeModal]
  );

  if (contentTypesLoading || tableNamesLoading) {
    return <Loading />;
  }

  return (
    <>
      <NavigationBlocker block={dirty} />
      <Button
        color="primary"
        onClick={openModal}
        disabled={submitting}
        startIcon={<AddIcon />}
      >
        {t('admin:catalogContent.createCatalogButton')}{' '}
        {selectedArchitecture.name}
      </Button>
      <div>
        <Modal
          open={newModalOpen}
          headerText={`${t('admin:catalogContent.createCatalogModalHeader')} ${
            selectedArchitecture.name
          }`}
          fullWidth
          maxWidth="lg"
          onClose={confirmCloseModal}
          FooterComponent={() => (
            <>
              <Button onClick={confirmCloseModal}>
                {t('admin:catalogContent.createCatalogModalclose')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(createCatalog)}
              >
                {t('admin:catalogContent.createCatalogModalCreateButton')}
              </Button>
            </>
          )}
        >
          <DynamicForm
            inputs={getCatalogCreateInputs({
              enumFields: {
                catalogContentTypes,
                tableNames
              },
              isAccessTableControlled
            })}
          />

          <div className={classes.subHeading}>
            <Typography>
              <Trans i18nKey="admin:catalogContent.contentThumbnailHeading" />
            </Typography>
            <Divider />
          </div>

          <DynamicForm
            inputs={getContentThumbnail({ contentTableColumns }, true)}
          />
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const formValues = getFormValues(form)(state);
  const initialValues = {
    organizationSlug: ownProps.orgSlug
  };
  return {
    contentSetTable: formValues?.contentSetTable,
    isAccessTableControlled: formValues?.isAccessTableControlled,
    initialValues
  };
};

export default flow(
  graphql(getContentTableColumns, {
    name: 'getContentTableColumns',
    skip: props => {
      return !props?.contentSetTable;
    },
    options: props => {
      return {
        fetchPolicy: 'no-cache',
        variables: {
          tableName: props?.contentSetTable
        }
      };
    },
    props: ({ getContentTableColumns }) => {
      const contentTableMetadata = getContentTableColumns?.contentTableMetadata;
      return {
        contentTableColumns: formatContentTableColumns(contentTableMetadata)
      };
    }
  }),
  reduxForm({
    // form name from props
    enableReinitialize: true,
    destroyOnUnmount: true,
    onChange: (values, dispatch, props, previousValues) => {
      // slug is based on the contentSetName so whenever it changes push the change
      // to the slug field. Note that we need to slugify it.
      if (values?.contentSetName !== previousValues?.contentSetName) {
        dispatch(props.change('slug', kebabCase(values?.contentSetName)));
      }
    }
  }),
  connect(mapStateToProps),
  graphql(createCatalogAndContentSet, {
    name: 'createCatalogAndContentSet'
  }),
  graphql(getCatalogContentTypes, {
    name: 'getCatalogContentTypes'
  }),
  graphql(getContentTableNames, {
    name: 'getContentTableNames'
  }),
  withRouter,
  withStyles(styles)
)(CreateNewCatalogForm);
