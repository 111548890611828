import { useState, useEffect, SetStateAction, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useLocation, useHistory } from 'react-router-dom';

import { Alert, Card, Typography, Box, Chip } from '@mui/material';

import {
  Home as HomeIcon,
  GroupAdd as GroupAddIcon,
  GroupRemove as GroupRemoveIcon,
  Done as DoneIcon,
  Error as ErrorIcon,
  LinkOff as LinkOffIcon
} from '@mui/icons-material';

import { SettingsUpdateType } from 'src/generated/gql/graphql';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { teamRoles } from 'src/common/teams';
import { isFacebookPageUsable } from 'src/common/FacebookUtil';
import { FACEBOOK_DEFAULT_PAGE_ID } from 'src/common/userSettings';

import { FB_STATUSES } from 'src/pages/FacebookLink/api';
import { POLL_FOR_TOS_PARAM } from 'src/pages/FacebookLink/Constants';

import Link from 'src/components/Link/Link';
import HelpTooltip from 'src/components/HelpTooltip';
import { FacebookIconSquare } from 'src/components/Icons';
import SharedAssetIcon from 'src/components/Teams/SharedAssetIcon';
import {
  AssetMenu,
  MenuIconButton,
  useMenu,
  AssetMenuOption
} from 'src/components/Menus';
import {
  assetEventTypes,
  assetTypes,
  logAssetEvent
} from 'src/common/orgAssets';

import FacebookCardModal from './FacebookCardModal';
import { setDefaultFacebookPage } from './mutations';
import { modalTypes } from './Constants';
import FacebookPageCardSkeleton from './FacebookPageCardSkeleton';

interface FacebookPageCardProps {
  facebookPage: any;
  refetchFacebookPages: any;
  setPollForUpdates: SetStateAction<any>;
}

const TosLinkComponent = (
  <Link to="https://www.facebook.com/ads/leadgen/tos" target="_blank" />
);

const pageText = () => {
  return {
    setDefault: t('facebookPages:fbCard.setDefault'),
    defaultText: t('facebookPages:fbCard.defaultText'),
    setDefaultError: t('facebookPages:fbCard.setDefaultError'),
    setDefaultSuccess: t('facebookPages:fbCard.setDefaultSuccess'),
    statusError: t('facebookPages:fbCard.error'),
    statusComplete: t('facebookPages:fbCard.complete'),
    statusWarn: t('facebookPages:fbCard.needsAttention'),
    haveToCreateNew: t('facebookPages:fbCard.haveToCreateNew'),
    completeSetup: t('facebookPages:fbCard.completeSetup'),
    facebookAccount: t('facebookPages:labels.fbAccountName'),
    facebookAccountStatus: t('facebookPages:fbCard.status'),
    viewPageDetails: t('facebookPages:fbCard.goToPage'),
    shareWithTeamOptionLabel: t(
      'facebookPages:fbCard.shareWithTeamOptionLabel'
    ),
    unshareWithTeamOptionLabel: t(
      'facebookPages:fbCard.unshareWithTeamOptionLabel'
    ),
    modalCancel: t('facebookPages:fbCard.modalCancel'),
    share: t('facebookPages:fbCard.share'),
    unshare: t('facebookPages:fbCard.share')
  };
};

const FacebookPageCard = ({
  facebookPage,
  refetchFacebookPages,
  setPollForUpdates
}: FacebookPageCardProps) => {
  const text = pageText();
  const location = useLocation();
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const globalContext = useGlobalContext();
  const group = globalContext?.office;
  const isTeamsEnabled = group?.isTeamsEnabled;
  const teamRole = globalContext?.us?.role;

  const settingsLoading = globalContext?.loading;
  const defaultFacebookId =
    globalContext?.me?.settings?.[FACEBOOK_DEFAULT_PAGE_ID];

  const id = facebookPage?.id;
  const isDefault = id === defaultFacebookId;
  const hasAcceptedTos = facebookPage?.hasAcceptedTos;
  const accountName = facebookPage?.facebookDisplayName;
  const pageName = facebookPage?.pageName;
  const pageStatus = facebookPage?.pageStatus;
  const teamCapabilities = facebookPage?.teamCapabilities;
  const permissions = facebookPage?.accessControl?.permissions;

  const hasAcceptedTosPrevious = useRef<undefined | boolean>();
  useEffect(() => {
    hasAcceptedTosPrevious.current = hasAcceptedTos;
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // check for polling param and if has accepted TOS
    if (!!queryParams.get(POLL_FOR_TOS_PARAM) && !hasAcceptedTos) {
      setPollForUpdates(true);
    }

    // check if TOS was accepted. stop polling & clear param
    if (!hasAcceptedTosPrevious.current && hasAcceptedTos) {
      setPollForUpdates(false);
      history.push({ ...location, search: '' });
    }
  }, [
    setPollForUpdates,
    hasAcceptedTos,
    hasAcceptedTosPrevious,
    history,
    location
  ]);

  const { handleCloseMenu, handleOpenMenu, isMenuOpen, menuAnchorEl } =
    useMenu();

  const refetch = globalContext?.refetch;

  const [setDefaultFacebookPageMutation] = useMutation(setDefaultFacebookPage);

  const setDefault = () => {
    setDefaultFacebookPageMutation({
      variables: {
        updateSettings: {
          [FACEBOOK_DEFAULT_PAGE_ID]: id
        },
        updateType: SettingsUpdateType.DeepMerge
      }
    })
      .then(() => {
        refetch();
        enqueueSnackbar(text.setDefaultSuccess, {
          variant: 'success'
        });
      })
      .catch(() => {
        enqueueSnackbar(text.setDefaultError, {
          variant: 'success'
        });
      });
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(modalTypes.shareWithTeam);

  const confirmUnlink = () => {
    setModalType(modalTypes.unlink);
    setModalOpen(true);
    handleCloseMenu();
  };

  const openConfirmShareWithTeam = () => {
    if (teamCapabilities?.isSharedWithTeam) {
      // unshare
      setModalType(modalTypes.unshareWithTeam);
    }
    if (!teamCapabilities?.isSharedWithTeam) {
      // share
      setModalType(modalTypes.shareWithTeam);
    }

    setModalOpen(true);
    handleCloseMenu();
  };

  const handleModalClose = () => {
    setModalOpen(false);
    refetchFacebookPages();
  };

  const hasShareWithTeamOption =
    isTeamsEnabled &&
    permissions.includes('share') &&
    (teamRoles.admin === teamRole || teamRoles.lead === teamRole);

  let actionMenuOptions: AssetMenuOption[] = [
    {
      label: isDefault ? text.defaultText : text.setDefault,
      icon: <HomeIcon />,
      onClick: setDefault,
      disabled: isDefault || !isFacebookPageUsable(facebookPage)
    }
  ];

  if (hasShareWithTeamOption && pageStatus === FB_STATUSES.COMPLETED) {
    actionMenuOptions = [
      ...actionMenuOptions,
      {
        label: teamCapabilities?.isSharedWithTeam
          ? text.unshareWithTeamOptionLabel
          : text.shareWithTeamOptionLabel,
        onClick: () => {
          // share
          logAssetEvent({
            type: teamCapabilities?.isSharedWithTeam
              ? assetEventTypes.unshare
              : assetEventTypes.share,
            properties: { object: assetTypes.facebookPage }
          });
          openConfirmShareWithTeam();
        },
        icon: teamCapabilities.isSharedWithTeam ? (
          <GroupRemoveIcon />
        ) : (
          <GroupAddIcon />
        )
      }
    ];
  }

  actionMenuOptions.push({
    label: t('facebookPages:fbCard.unlink'),
    icon: <LinkOffIcon />,
    onClick: confirmUnlink
  });

  const actionMenu = (
    <>
      <MenuIconButton
        ariaLabel={pageName}
        ariaControls={isMenuOpen ? id : undefined}
        ariaExpanded={isMenuOpen}
        ariaHaspopup
        onClick={handleOpenMenu}
        id={id}
        size="small"
      />

      <AssetMenu
        id={id}
        handleClose={handleCloseMenu}
        open={isMenuOpen}
        ariaLabeledBy={id}
        anchorEl={menuAnchorEl}
        options={actionMenuOptions}
      />
    </>
  );

  let statusChip = null;
  switch (pageStatus) {
    case FB_STATUSES.ERROR:
      // Error
      statusChip = (
        <Chip
          color="error"
          icon={<ErrorIcon fontSize="small" />}
          label={text.statusError}
          sx={{ color: 'white' }}
        />
      );
      break;
    case FB_STATUSES.COMPLETED:
      // Complete
      statusChip = (
        <Chip
          color="success"
          icon={<DoneIcon fontSize="small" />}
          label={text.statusComplete}
          sx={{ color: 'white' }}
        />
      );
      break;
    case FB_STATUSES.BOT_ASSIGNED:
    // Still pending, it just means they have granted us permissions
    // but they still need to accept the TOS.
    // falls through
    case FB_STATUSES.PENDING:
    // falls through
    default:
      statusChip = (
        <Chip
          color="success"
          icon={<DoneIcon fontSize="small" />}
          label={text.statusComplete}
          sx={{ color: 'white' }}
        />
      );
      break;
  }

  if (settingsLoading) {
    return <FacebookPageCardSkeleton />;
  }

  return (
    <Card
      data-cy={`fb-page-${pageName}`}
      key={`fbp-${id}`}
      sx={{ width: '350px' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: theme => theme.palette.grey[50],
          padding: theme => `${theme.spacing(0.5)} ${theme.spacing(2)}`
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FacebookIconSquare width={22} height={22} />
          {isDefault && (
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  color: theme => theme.palette.grey[500],
                  margin: theme =>
                    `0 ${theme.spacing(0.5)} 0 ${theme.spacing(1)}`
                }}
              >
                {text.defaultText}
              </Typography>
              <Box component="span" sx={{ position: 'relative', top: '1px' }}>
                <HelpTooltip title={t('facebookPages:fbCard.defaultTip')} />
              </Box>
            </Box>
          )}
        </Box>
        {actionMenu}
      </Box>

      <Box
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        <Typography sx={{ fontSize: '21px' }}>{pageName}</Typography>
        <Typography sx={{ fontSize: '16px' }}>{accountName}</Typography>
      </Box>

      {!hasAcceptedTos && (
        <Box
          sx={{
            padding: theme =>
              `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`
          }}
        >
          <Alert severity="warning">
            <Trans
              i18nKey="facebookPages:fbCard.facebookPageTosWarning"
              components={[<strong />, TosLinkComponent]}
            />
          </Alert>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme =>
            `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`
        }}
      >
        {isTeamsEnabled ? (
          <SharedAssetIcon isSharedAsset={teamCapabilities?.isSharedWithTeam} />
        ) : (
          <div /> // keep the space between when no icon is shown
        )}

        {statusChip}
      </Box>

      <FacebookCardModal
        facebookPage={facebookPage}
        groupName={group?.officeName}
        hasTeamCapabilities={!!teamCapabilities}
        onClose={handleModalClose}
        open={isModalOpen}
        type={modalType}
      />
    </Card>
  );
};

export default FacebookPageCard;
