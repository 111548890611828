import { isEmpty } from 'lodash';

import { dayjs } from 'src/common/dates';

import { SPEND_VALIDATION_MESSAGES } from './constants';

export const getMinSpendInput = ({
  architecture,
  formValues,
  endDate,
  startDate,
  isEdit,
  orderItemId,
  offerId
}) => {
  const today = dayjs().startOf('d');
  const startDateInPast = dayjs(dayjs(startDate).startOf('d')).diff(today) < 0;

  return {
    variableValues: formValues?.dynamicUserInputs,
    catalogId: architecture?.catalog?.id,
    catalogFilter: isEmpty(formValues?.configureStep?.businessObjectSelector)
      ? {}
      : {
          id: {
            in: formValues?.configureStep?.businessObjectSelector.map(id => {
              return id;
            })
          }
        },
    // offerId is not in the edit form so it get passed in from the orderItem
    offerId: isEdit ? offerId : formValues?.spendStep?.offerId,
    /*
            if start date is in the past we need to use today in our diff for the calculation. the BE will account for
            what has already been spent to "now" so this is calculating from "now" into the future.
        */
    durationDays: startDateInPast
      ? dayjs(endDate).diff(dayjs(today).startOf('d'), 'days') + 1
      : dayjs(endDate).diff(dayjs(startDate).startOf('d'), 'days') + 1,
    /*
            passing orderItemId informs the BE that this is the edit case & will account for whats has already
            been spent.
        */
    ...(isEdit && orderItemId && { orderItemId })
  };
};

const MIN_DAILY_SPEND_FALLBACK = 2;

export const validateSpend = ({
  spend, // form value
  startDate, // form value
  endDate, // form value
  minDays,
  min,
  max,
  isAutomated,
  scheduleDays,
  minDailySpendAmount = MIN_DAILY_SPEND_FALLBACK,
  performancePredictions,
  isPredictionTreatment,
  performancePredictionError,
  isEdit,
  currentEndDate, // from billing details
  currentStartDate, // from billing details
  currentSpend // from billing details
}) => {
  const dateRangeDiff = isAutomated
    ? scheduleDays
    : dayjs(endDate || currentEndDate).diff(
        dayjs(startDate || currentStartDate),
        'days'
      ) + 1;
  const dailySpend = spend / dateRangeDiff;

  // If a user increases their spend within 48 hours of the end date
  // they also need to increase the scheduled end date to at least 3 days from the current end date
  const MIN_END_DATE_INCREASE_TO_UPDATE_BUDGET = 3;
  const isWithin48HoursOfEndDate = dayjs(endDate).diff(dayjs(), 'hours') <= 48;
  const hasEndDateBeenIncreasedBy3Days =
    dayjs(endDate)
      .add(MIN_END_DATE_INCREASE_TO_UPDATE_BUDGET, 'days')
      .diff(endDate, 'days') <= 0;
  const hasSpendChanged = currentSpend !== spend;
  const hasEndDateChanged = dayjs(endDate).diff(dayjs(currentEndDate)) !== 0;

  if (isEdit && !hasSpendChanged && !hasEndDateChanged) {
    // if you are editing and don't touch the spend or end date, we should not be validating the spend
    return { dailySpend, message: '', validationFail: false };
  }

  if (
    isEdit &&
    hasSpendChanged &&
    isWithin48HoursOfEndDate &&
    !hasEndDateBeenIncreasedBy3Days
  ) {
    return {
      dailySpend,
      message: SPEND_VALIDATION_MESSAGES.increaseEndDate,
      validationFail: true
    };
  }

  if (spend > max) {
    return {
      dailySpend: '-',
      message: SPEND_VALIDATION_MESSAGES.maxSpend,
      validationFail: true
    };
  }

  if (spend < min) {
    return {
      dailySpend: '-',
      message: SPEND_VALIDATION_MESSAGES.minSpend,
      validationFail: true
    };
  }

  if (dateRangeDiff <= 0) {
    return {
      dailySpend: '-',
      message: SPEND_VALIDATION_MESSAGES.dateRange,
      validationFail: true
    };
  }

  if (dateRangeDiff < minDays) {
    return {
      dailySpend,
      message: SPEND_VALIDATION_MESSAGES.dateRange,
      validationFail: true
    };
  }

  if (dailySpend < minDailySpendAmount) {
    return {
      dailySpend,
      message: SPEND_VALIDATION_MESSAGES.dailySpend,
      validationFail: true
    };
  }

  if (performancePredictions)
    return {
      dailySpend,
      message: SPEND_VALIDATION_MESSAGES.success,
      validationFail: false
    };

  if (!isPredictionTreatment || performancePredictionError)
    return {
      dailySpend,
      message: SPEND_VALIDATION_MESSAGES.noPredictionSuccess,
      validationFail: false
    };

  return {
    dailySpend,
    message: '',
    validationFail: false
  };
};
