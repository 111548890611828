import { flow } from 'lodash';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  formContainer: {
    width: '455px'
  }
});

const ResourceItemTypeSelector = ({
  classes,
  setResourceItemType,
  selectedResourceItemType,
  resourceItemTypes
}) => {
  const handleChange = event => {
    setResourceItemType(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formContainer}>
      <InputLabel id="resource-item-type-selector-label">
        Resource Item Type
      </InputLabel>
      <Select
        labelId="resource-item-type-selector-label"
        id="resource-item-type-selector"
        value={selectedResourceItemType}
        label="Resource Item Type"
        onChange={handleChange}
      >
        {resourceItemTypes.map(resourceItemtype => (
          <MenuItem value={resourceItemtype.slug} key={resourceItemtype.slug}>
            {resourceItemtype.friendlyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default flow(withStyles(styles))(ResourceItemTypeSelector);
