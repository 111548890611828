import { useEffect } from 'react';
import { isEmpty, some } from 'lodash';
import { useMutation, useLazyQuery } from '@apollo/client';
import Instrumentation from 'src/instrumentation';

import {
  LOCALE_CODE,
  LOCALE_SLUGS
} from './pages/Account/LocalizationPreferences/helpers';
import i18n, { localStorageLocaleKey } from './i18n';
import { useGlobalContext } from './GlobalContextProvider';
import { updateUserLocale } from './mutations';
import { getLocales } from './queries';
import SentryUtil from './common/SentryUtil';

interface LocaleDocumentation {
  locale: string;
}

// Locale codes sometimes come in as en_US, en-US
// the output should be in this format en-us
const normalizeLocaleCode = (localeCode: string) => {
  return localeCode.toLowerCase().replace(/_/g, '-');
};

const browserLocaleToInternalLocaleCode = (browserLocale: string) => {
  switch (normalizeLocaleCode(browserLocale)) {
    case 'en-us':
      return LOCALE_CODE.english;
    case 'zh-CN':
    case 'cmn':
      return LOCALE_CODE.chineseSimplified;
    case 'mn':
      return LOCALE_CODE.mongolianCyrillic;
    default:
      return browserLocale;
  }
};

const detectBrowserLocale = () => {
  return navigator.language;
};

const getIsLocaleSupported = (
  localeCode: string,
  supportedLocales?: LocaleDocumentation[]
) => {
  return some(supportedLocales, supportedLocale => {
    return (
      normalizeLocaleCode(supportedLocale.locale) ===
      normalizeLocaleCode(browserLocaleToInternalLocaleCode(localeCode))
    );
  });
};

const TranslationAutodetector = () => {
  const currentLanguage = i18n.language;
  const globalContext = useGlobalContext();
  const userData = globalContext?.me;
  const userLocale = userData?.locale;
  const localStorageLocale = window.localStorage.getItem(localStorageLocaleKey);

  // If the user is logged in and their locale doesn't match the current language, change the language
  if (!isEmpty(userData) && userLocale && userLocale !== currentLanguage) {
    // set in local storage so we can use it before the user is loaded in the future
    window.localStorage.setItem(localStorageLocaleKey, userLocale);
    // set the language
    i18n.changeLanguage(userLocale).catch(err => {
      if (err) {
        SentryUtil.captureException(err);
      }
    });
  } else if (isEmpty(userData)) {
    // Public route (routes in the allowList), user is not authenticated
    // Check to see if user has locale set in local storage and if they do, load that locale
    // We do NOT have access to whether or not the locale feature is enabled when the user is not logged in
    // If the locale local storage has been set, this means that the user has set their locale before
    // and is indicative that the feature is or has been enabled.
    if (localStorageLocale) {
      i18n.changeLanguage(localStorageLocale).catch(err => {
        if (err) {
          SentryUtil.captureException(err);
        }
      });
    }
  }

  const [getSupportedLocales] = useLazyQuery(getLocales);
  const [updateLocale] = useMutation(updateUserLocale);

  useEffect(() => {
    // if user data has loaded AND doesn't have a locale
    // detect browser locale and set it
    if (!isEmpty(userData) && !userLocale) {
      // if no browser locale is set, locale is set to our default locale
      const browserLocale = normalizeLocaleCode(
        browserLocaleToInternalLocaleCode(detectBrowserLocale())
      );

      getSupportedLocales()
        .then(res => {
          const supportedLocales = res?.data?.localeDocumentation || [];

          const browserLocaleSupported = getIsLocaleSupported(
            browserLocale,
            supportedLocales
          );

          if (browserLocaleSupported) {
            updateLocale({
              variables: {
                input: { locale: browserLocale }
              }
            })
              .then(res => {
                // If mutation successful, set locale in local storage and refresh page
                if (res?.data?.updateUserLocale?.locale === browserLocale) {
                  window.localStorage.setItem(
                    localStorageLocaleKey,
                    browserLocale
                  );
                  window.location.reload();
                }

                Instrumentation.logEvent(Instrumentation.Events.UpdateLocale, {
                  action: 'auto-detected',
                  'language-code': browserLocale,
                  'language-name': LOCALE_SLUGS[browserLocale]
                });
              })
              .catch(err => {
                SentryUtil.captureException(err);
              });
          }
        })
        .catch(err => {
          SentryUtil.captureException(err);
        });
    }
  }, [getSupportedLocales, userLocale, updateLocale]);

  return null;
};

export default TranslationAutodetector;
