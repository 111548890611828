import { Box, Grid } from '@mui/material';
import { translateMaps } from 'src/common/templateTranslator';

import SummaryValue from './SummaryValue';

const BusinessObjectsSummary = ({
  selectedBusinessObjects = [],
  displayNameTemplate
}) => {
  return (
    <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
      {selectedBusinessObjects?.map(businessObject => {
        const displayName = translateMaps(displayNameTemplate, businessObject);
        return (
          <Grid container spacing={2} component="li">
            <Grid item xs={7}>
              <SummaryValue>{displayName}</SummaryValue>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default BusinessObjectsSummary;
