import { t } from 'i18next';
import { Theme } from '@mui/system';

export const programOrderStatusCode = {
  billingFailed: 'billing_failure',
  cancelled: 'cancelled',
  error: 'error',
  fulfilled: 'fulfilled',
  pending: 'pending',
  cancelling: 'cancelling',
  draft: 'draft'
};

export const mlpParentOrderStatusCode = {
  initializing: 'initializing',
  active: 'active',
  cancelled: 'cancelled',
  cancelling: 'cancelling',
  cancellationUnknownError: 'cancellation_unknown_error',
  placementJobRunning: 'placement_job_running',
  placementJobUnknownError: 'placement_job_unknown_error',
  editUnknownError: 'edit_unknown_error',
  editInProgress: 'edit_in_progress'
};

export const statusType = {
  stopped: 'stopped',
  running: 'running',
  cancelled: 'cancelled',
  pending: 'pending',
  error: 'error'
};

const isFulfilledOrCancelled = (orderStatus: string) => {
  return (
    orderStatus === programOrderStatusCode.fulfilled ||
    orderStatus === programOrderStatusCode.cancelled ||
    orderStatus === programOrderStatusCode.cancelling
  );
};

interface StatusChecks {
  isProgramActive: boolean;
  orderStatus: string;
}

interface StatusCheckColor extends StatusChecks {
  theme: Theme;
}

export const getStatusText = ({
  isProgramActive,
  orderStatus
}: StatusChecks) => {
  const fulfilledOrCancelled = isFulfilledOrCancelled(orderStatus);
  let text = t('programs:messages.adsInactive');

  if (orderStatus === programOrderStatusCode.draft) {
    text = t('programs:messages.orderDraft');
  }

  if (orderStatus === programOrderStatusCode.pending) {
    text = t('programs:messages.adPending');
  }

  if (fulfilledOrCancelled) {
    if (isProgramActive) {
      text = t('programs:messages.adsPublished');
    } else if (isProgramActive === false) {
      text = t('programs:messages.adsInactive');
    }
  }

  return text;
};

export const getAutomationStatusText = ({
  deactivatedAt,
  orderItem
}: {
  deactivatedAt: string;
  orderItem?: { status: string };
}) => {
  let text = t('automations:table.active');

  if (deactivatedAt) {
    text = t('automations:table.inactive');
  }

  if (orderItem?.status === programOrderStatusCode.draft) {
    text = t('programs:messages.orderDraft');
  }

  return text;
};

export const getStatusColor = ({
  isProgramActive = false,
  orderStatus,
  theme
}: StatusCheckColor) => {
  const fulfilledOrCancelled = isFulfilledOrCancelled(orderStatus);

  if (fulfilledOrCancelled && isProgramActive) {
    return theme.palette.success.main;
  }

  if (fulfilledOrCancelled && !isProgramActive) {
    return '#000';
  }

  if (orderStatus === programOrderStatusCode.pending) {
    return theme.palette.info.main;
  }

  if (
    orderStatus === programOrderStatusCode.error ||
    orderStatus === programOrderStatusCode.billingFailed
  ) {
    return theme.palette.error.main;
  }

  if (orderStatus === programOrderStatusCode.draft) {
    return theme.palette.action.active;
  }

  return 'inherit';
};

export const getStatusColorAutomation = ({
  theme,
  deactivatedAt,
  orderItem
}: {
  theme: Theme;
  deactivatedAt: string;
  orderItem?: { status: string };
}) => {
  if (deactivatedAt) {
    return '#000';
  }

  if (orderItem?.status === programOrderStatusCode.draft) {
    return theme.palette.action.active;
  }

  return theme.palette.success.main;
};
