import { useEffect, useRef, useState } from 'react';
import { camelCase } from 'lodash';
import { t } from 'i18next';

import { Grid, IconButton, Box } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import SummaryLabel from './SummaryLabel';
import SummaryValue from './SummaryValue';

const pageText = ({ labelText }) => {
  return {
    title: t('editableSummaryItem:title', {
      labelText
    })
  };
};

const ICON_SIZE = 'small';
const EditableSummaryItem = ({
  labelText,
  uneditableContent,
  isDrawerWidth,
  children
}) => {
  const [isEditable, setIsEditable] = useState(false);

  const text = pageText({ labelText });

  const toggleEditable = () => {
    setIsEditable(prevState => !prevState);
  };

  const getFieldGridSize = () => {
    if (isEditable && isDrawerWidth) return 12;
    if (isEditable && !isDrawerWidth) return 8;
    if (!isEditable && isDrawerWidth) return 11;
    return 7;
  };

  const handlePressEnter = event => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      toggleEditable();
    }
  };

  const inputContainerRef = useRef(null);

  useEffect(() => {
    if (inputContainerRef.current) {
      inputContainerRef.current.focus();
    }
  }, [isEditable]);

  return (
    <Grid
      container
      spacing={isDrawerWidth ? 0 : 2}
      {...(isDrawerWidth ? { alignItems: 'center' } : {})}
      data-cy={`editable-summary-item-${camelCase(labelText)}`}
    >
      <Grid item xs={isDrawerWidth ? 12 : 4}>
        {labelText && <SummaryLabel variant="body1">{labelText}</SummaryLabel>}
      </Grid>
      <Grid item xs={getFieldGridSize()}>
        {isEditable ? (
          <Box
            // Added tabIndex and ref so that the user can press enter immediately after toggling to edit to return
            // the component to its uneditable state. This is a workaround for the complexity involved with focusing the
            // input field when isEditable is toggled.
            tabIndex={0}
            ref={inputContainerRef}
            onKeyDown={handlePressEnter}
          >
            {children}
          </Box>
        ) : (
          <SummaryValue>{uneditableContent}</SummaryValue>
        )}
      </Grid>
      {!isEditable && (
        <Grid item xs={1} alignSelf="center">
          <IconButton
            title={text.title}
            aria-label={text.title}
            onClick={toggleEditable}
            sx={{ mt: '-6px' }}
          >
            <EditIcon fontSize={ICON_SIZE} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default EditableSummaryItem;
