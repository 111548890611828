import { useState, useEffect } from 'react';
import { flow, filter, get, sortBy, find, isString, isEmpty } from 'lodash';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { change } from 'redux-form';

import { Button, MenuItem, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import UploadIcon from '@mui/icons-material/CloudUpload';
import SelectIcon from '@mui/icons-material/ListAlt';

import { isTemplate } from 'src/common/templateTranslator';

import {
  GALLERY_TYPE,
  MEDIA_TYPE_FILTER_VALUES
} from 'src/pages/Gallery/constants';

import { getChangeFormValue } from 'src/common/utilities/inputConversionHelpers';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import RenderSelect from './RenderSelect';
import RenderGallerySelector from './RenderGallerySelector';

const stripTemplateWhitespace = (str = '') =>
  str.replace(/\{{2}\s+/g, '{{').replace(/\s+\}{2}/g, '}}');

const imageFormatter = (item = {}, businessObjects = [], classes = {}) => {
  const label = get(item, 'label');

  // Business objects don't have the {{ }} around their attribute names so
  // we strip them out here.
  const accessor = get(item, 'value', '').replace('{{', '').replace('}}', '');

  let imgContent = <span />;
  const businessObject = get(businessObjects, '[0]');
  const boValue = get(businessObject, accessor);

  // Only render the image thumbnail if we found a value.
  if (boValue) {
    imgContent = (
      <Tooltip
        title={<img width="200" height="200" src={boValue} alt={label} />}
      >
        <img className={classes.imgThumbnail} alt={label} src={boValue} />
      </Tooltip>
    );
  }

  return (
    <span>
      {get(item, 'label')}
      {imgContent}
    </span>
  );
};

// We only support image content selection at this time
const DISPLAY_METHOD_ID_FORMATTERS = {
  [INPUT_TYPES.IMAGE_URL]: imageFormatter,
  [INPUT_TYPES.MEDIA_ASSET]: imageFormatter
};

const renderMenuItemContent = (
  item = {},
  businessObjects = [],
  displayMethodId,
  classes,
  isMissingData
) => {
  // Default to the label for rendering a menu item.
  let menuItemContent = item.label;

  // If we have a custom formatter, use that to render the content instead.
  if (DISPLAY_METHOD_ID_FORMATTERS[displayMethodId]) {
    menuItemContent = DISPLAY_METHOD_ID_FORMATTERS[displayMethodId](
      item,
      businessObjects,
      classes
    );
  }

  if (isMissingData) {
    const tooltipLabelName = item.label;
    const tooltip = t(
      'renderTemplateStringTextField:missingSelectDataTooltip',
      {
        tooltipLabelName
      }
    );
    // Note: If an items is selected it won't show up correctly if wrapped in a tooltip & div
    //       so we are returning them separately. The only way we could get the tooltip to work
    //       was to wrap in a div.
    return (
      <Tooltip title={tooltip} aria-label={tooltip} key={item.value}>
        <div>
          <MenuItem
            key={item.value}
            value={item.value}
            disabled={isMissingData}
          >
            {menuItemContent}
          </MenuItem>
        </div>
      </Tooltip>
    );
  }
  return (
    <MenuItem key={item.value} value={item.value} disabled={isMissingData}>
      {menuItemContent}
    </MenuItem>
  );
};

const styles = () => ({
  imgThumbnail: {
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    transform: 'translateY(6px)'
  }
});

const RenderBusinessObjectAssetSelector = props => {
  const {
    classes,
    children,
    displayMethodId,
    dynamicValues,
    input,
    businessObjects,
    missingColumns,
    allowGallery = false,
    change: reduxChange,
    formName,
    galleryType,
    contentName,
    allowContentSelection,
    hookFormContext,
    extraProps,
    ...rest
  } = props;
  // Note: We are checking if there is a template string in the input value
  //       to deal with changing steps & edit. We can assume if there is a
  //       template string it will be the select.
  const [useGallery, toggleGallery] = useState(
    allowGallery && !isTemplate(input.value)
  );

  const change = getChangeFormValue({
    reduxChange,
    hookSetValue: hookFormContext?.setValue
  });

  useEffect(() => {
    // componentWillMount
    if (
      galleryType === GALLERY_TYPE.media &&
      !isEmpty(input?.value) &&
      isString(input?.value)
    ) {
      // change input value to media format if its a string this will allow us to
      // be backwards compatible with the old url string format
      change(formName, input.name, {
        url: input?.value,
        type: MEDIA_TYPE_FILTER_VALUES.image
      });
    }
  }, [hookFormContext?.setValue]);

  const handelToggleGallery = () => {
    // Note: We are clearing the value on toggle or else the template looks
    //       odd in the gallery selector && if you select a gallery image
    //       and switch back it would not render properly in the select.
    change(formName, input.name, null);
    toggleGallery(!useGallery);
  };

  const menuItems = sortBy(
    filter(dynamicValues, { displayMethodId: extraProps?.displayMethodId }),
    ['displaySortOrder', 'label']
  );

  // clear the default if the selected value is empty in content
  if (input.value) {
    const selected = find(menuItems, [
      'value',
      isString(input?.value) ? input.value : input.value.url
    ]);

    if (selected && missingColumns.has(selected.fieldName)) {
      change(formName, input.name, null);
    }
  }

  return (
    <>
      {useGallery ? (
        <RenderGallerySelector {...props} />
      ) : (
        <RenderSelect
          input={{
            ...input,
            // Note: select only supports strings and for our new media assets we pass around an object
            onBlur: ({ target: { value } }) => {
              return galleryType === GALLERY_TYPE.media
                ? input.onChange({
                    url: value,
                    type: MEDIA_TYPE_FILTER_VALUES.image
                  })
                : input.onChange(value);
            },
            onChange: ({ target: { value } }) => {
              return galleryType === GALLERY_TYPE.media
                ? input.onChange({
                    url: value,
                    type: MEDIA_TYPE_FILTER_VALUES.image
                  })
                : input.onChange(value);
            },
            // sometimes there is naughty whitespace in our template tags
            value: isString(input?.value)
              ? stripTemplateWhitespace(input?.value) // legacy inputs
              : stripTemplateWhitespace(input?.value?.url) // media asset input is an object
          }}
          {...rest}
        >
          {menuItems.reduce(
            (accum, item) => {
              const isMissingData = missingColumns.has(item.fieldName);
              if (businessObjects.length === 0 || !isMissingData) {
                accum.push(
                  renderMenuItemContent(
                    item,
                    businessObjects,
                    displayMethodId,
                    classes,
                    isMissingData,
                    galleryType
                  )
                );
              }

              return accum;
            },
            [
              <MenuItem key="disabled" value="disabled" disabled>
                {t(
                  'renderBusinessObjectAssetSelector:menuItems.makeSelection',
                  'Make a selection'
                )}
              </MenuItem>
            ]
          )}
        </RenderSelect>
      )}
      {allowGallery && allowContentSelection && (
        <Button
          color="primary"
          startIcon={useGallery ? <SelectIcon /> : <UploadIcon />}
          size="small"
          onClick={handelToggleGallery}
        >
          {useGallery
            ? t(
                'renderBusinessObjectAssetSelector:buttons.toggleUploadSelect',
                {
                  contentName,
                  galleryType
                }
              )
            : t(
                'renderBusinessObjectAssetSelector:buttons.toggleUploadGallery',
                { galleryType }
              )}
        </Button>
      )}
    </>
  );
};

export default flow(
  withStyles(styles),
  connect(() => ({}), { change })
)(RenderBusinessObjectAssetSelector);
