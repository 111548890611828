import { useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import { EXPERIMENT_NAMES } from 'src/experiments/constants';
import { useExperiment } from 'src/experiments/ExperimentContextProvider';

import { minimumSpend } from '../queries';

const DEBOUNCE_DELAY = 1000;

export const usePerformancePredictions = (
  oneTimeSpend,
  minSpendInput,
  isSubscription
) => {
  // disable perforance preditction for now
  // if we re-add this we should refactor the code a bit.
  // It's confusing and hard to follow downstream
  return {
    loading: false,
    data: null,
    isTreatment: false,
    error: null
  };

  /* eslint-disable no-unreachable */
  const isTreatment = useExperiment(
    EXPERIMENT_NAMES.UIX_PROGRAMS_PERFORMANCE_PREDICTION
  );
  const [computePerformancePrediction, { loading, data, error }] = useLazyQuery(
    minimumSpend,
    { skip: isSubscription }
  );

  // refactor: we shouldn't rely on the nullness of this to be determining downstream actions
  const performancePredictions =
    data?.computeMinimumSpend?.expectedResults?.predictions;

  const debouncedComputePrediction = useCallback(
    debounce(computePerformancePrediction, DEBOUNCE_DELAY),
    [computePerformancePrediction]
  );

  useEffect(() => {
    if (isTreatment)
      debouncedComputePrediction({
        variables: {
          input: minSpendInput,
          orderAmount: oneTimeSpend
        }
      });
    return () => {
      debouncedComputePrediction.cancel();
    };
  }, [oneTimeSpend, minSpendInput, isTreatment]);

  return { loading, data: performancePredictions, isTreatment, error };
};
