import { useMemo } from 'react';
import { t } from 'i18next';

import { Grid, Paper } from '@mui/material';

import Heading from 'src/components/PageElements/Heading';

import FacebookConfiguration from './FacebookConfiguration';
import DefaultFacebookPage from './DefaultFacebookPage';
import ActiveFacebookConfiguration from './ActiveFacebookConfiguration';
import FacebookPageLinkButtons from './FacebookPageLinkButtons';

const pageText = () => ({
  pageTitle: t('admin:pageTitle.facebookSettings'),
  pageHeading: t('admin:headings.facebookSettings'),
  pageDescription: t('admin:headings.facebookSettingsDescription')
});

const AdminFacebookSettings = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <>
      <Heading
        title={text.pageHeading}
        subTitle={text.pageDescription}
        pageTitle={text.pageHeading}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <FacebookConfiguration />
          </Paper>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <ActiveFacebookConfiguration />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <DefaultFacebookPage />
          </Paper>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <FacebookPageLinkButtons />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminFacebookSettings;
