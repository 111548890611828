import { t } from 'i18next';

import { Alert, AlertTitle } from '@mui/material';

import { FormSection } from 'src/components/ReduxForm';

const NoUserInputs = () => {
  return (
    <FormSection>
      <Alert severity="info">
        <AlertTitle>{t('programCreate:configure.nothingToDo')}</AlertTitle>
      </Alert>
    </FormSection>
  );
};

export default NoUserInputs;
