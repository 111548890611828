import { flow, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';

import Heading from 'src/components/PageElements/Heading';

import LeadsTable from './LeadsTable';

interface Match {
  params: {
    architectureId: string;
  };
}

interface LeadsProps {
  match: Match;
}

const Leads = ({ match }: LeadsProps) => {
  const architectureId = get(match, 'params.architectureId');

  return (
    <>
      <Heading
        title={t('leads:header.title')}
        subTitle={t('leads:header.subtitle')}
        pageTitle={t('leads:header.title')}
      />
      <LeadsTable architectureId={architectureId} />
    </>
  );
};

export default flow(withRouter)(Leads);
