import { get } from 'lodash';

import { SvgIcon } from '@mui/material';

import withTheme from '@mui/styles/withTheme';
import { Theme } from '@mui/system';

export interface MessengerProps {
  theme: Theme;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const Messenger = ({ theme, fontSize = 'medium' }: MessengerProps) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <path
        fill={get(theme, 'palette.blueprintIcons.channel.messenger')}
        fillRule="nonzero"
        d="M10 0C4.477 0 0 3.92 0 8.755c0 2.75 1.449 5.205 3.715 6.81v3.35l3.413-1.772c.91.239 1.874.367 2.872.367 5.523 0 10-3.92 10-8.755C20 3.92 15.523 0 10 0zm1.053 11.74L8.467 9.225l-4.981 2.579 5.46-5.426 2.587 2.516 4.981-2.58-5.46 5.426z"
      />
    </SvgIcon>
  );
};

export default withTheme<Theme, typeof Messenger>(Messenger);
