import { uniq } from 'lodash';
import {
  validateFiltersByContentMeta,
  ContentSetFieldMetadata
} from 'src/components/AutomatedProgramFilter/helpers';
import { AutomatedProgramFilterType } from 'src/components/AutomatedProgramFilter/constants';
import { Features } from 'src/components/Feature/constants';

interface GetPreselectedFiltersArgs {
  params: any;
  fieldMetadata: ContentSetFieldMetadata[];
}

type PromoCode = {
  promoCode: string;
};

export const getPreselectedFilters = ({
  params,
  fieldMetadata
}: GetPreselectedFiltersArgs) => {
  let preselectedFilters: AutomatedProgramFilterType[] = [];
  if (params.filters) {
    try {
      const queryFilters = JSON.parse(decodeURIComponent(params.filters));
      preselectedFilters = validateFiltersByContentMeta(
        queryFilters.map((f: any, i: any) => ({
          ...f,
          key: i
        })),
        fieldMetadata
      );
    } catch {
      // do nothing - invalid param
    }
  }

  return preselectedFilters;
};

interface GetPreselectedPromoCodesArgs {
  isAutomated: boolean;
  features: Features;
  params: any;
}

export const getPreselectedPromoCodes = ({
  isAutomated,
  features,
  params
}: GetPreselectedPromoCodesArgs) => {
  let preselectedPromoCodes: PromoCode[] = [];
  if (
    params?.promoCodes &&
    ((!isAutomated && features?.promoCodes) ||
      (isAutomated && features?.automationPromoCodes))
  ) {
    // Note: This puts the promo code in but does not validate it. Validation happens in the spend
    //       step and that will fill out the rest of the promo with discount etc ...
    const preselectedPromoCodesParams = uniq(params?.promoCodes.split(','));
    preselectedPromoCodes = [
      { promoCode: preselectedPromoCodesParams[0] } as PromoCode
    ];
  }

  return preselectedPromoCodes;
};
