import { SVGProps } from 'react';

const Diners = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M37.7919 15.1304C37.7919 6.18214 30.3231 -0.00269919 22.1395 1.06902e-06H15.0971C6.81607 -0.00296921 0 6.18403 0 15.1304C0 23.315 6.81625 30.0394 15.0971 29.9998H22.1395C30.3224 30.0392 37.7919 23.3128 37.7919 15.1304Z"
        fill="#0079BE"
      />
      <path
        d="M15.1415 1.26758C7.5746 1.26992 1.44313 7.41588 1.44043 15.0011C1.44232 22.585 7.57406 28.7301 15.1415 28.7328C22.7102 28.7305 28.8435 22.5851 28.8444 15.0011C28.8434 7.4157 22.7103 1.27028 15.1415 1.26758Z"
        fill="white"
      />
      <path
        d="M6.48145 14.9641C6.48865 11.2575 8.79889 8.0968 12.0566 6.84082V23.0855C8.79889 21.8303 6.48856 18.6711 6.48145 14.9641ZM18.2726 23.0896V6.84127C21.5316 8.09428 23.8454 11.2568 23.8513 14.9651C23.8453 18.6745 21.5316 21.8347 18.2726 23.0901V23.0896Z"
        fill="#0079BE"
      />
    </svg>
  );
};

export default Diners;
