import { Box, Divider, IconButton, Typography } from '@mui/material';
import {
  UnfoldMore as UnfoldMoreIcon,
  UnfoldLess as UnfoldLessIcon
} from '@mui/icons-material';
import { ReactElement } from 'react';

interface AccordionListHeadingProps {
  title: string | ReactElement;
  openIndexes: number[];
  handleToggleAll: () => void;
  children?: JSX.Element;
}

const AccordionListHeading = ({
  title,
  openIndexes,
  handleToggleAll,
  children
}: AccordionListHeadingProps) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2
        }}
      >
        <Typography
          sx={{
            mt: 1,
            fontWeight: 'bold'
          }}
        >
          {title}
          <IconButton size="small" onClick={handleToggleAll}>
            {openIndexes.length > 0 ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
          </IconButton>
        </Typography>
        {children}
      </Box>
      <Divider
        sx={{
          margin: theme => `${theme.spacing(1)} 0 ${theme.spacing(2.5)} 0`
        }}
      />
    </Box>
  );
};

export default AccordionListHeading;
