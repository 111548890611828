import { gql } from 'src/generated/gql';
import { GoogleUserListAccountSelectionStrategy } from 'src/generated/gql/graphql';

export interface GoogleUserListCreationRule {
  id: string;
  accountSelectionStrategy: GoogleUserListAccountSelectionStrategy;
  priority: number;
  googleAccount?: {
    id: string;
    name: string;
  } | null;
  userMetadata?: {
    managerAccountUserMetadataKey: string;
    customerAccountUserMetadataKey: string;
  } | null;
  group?: { id: string } | null;
}

export const fetchGoogleUserListCreationRulesQuery = gql(`
query FetchGoogleUserListAccountCreationRules {
    googleUserListAccountCreationRules {
        id
        accountSelectionStrategy
        priority
        googleAccount {
            id
            name
        }
        userMetadata {
            managerAccountUserMetadataKey
            customerAccountUserMetadataKey
        }
        group {
            id
        }
    }
}
`);

export const deleteGoogleUserListCreationRuleMutation = gql(`
mutation DeleteGoogleUserListAccountCreationRule($ruleId: ID!) {
    deleteGoogleUserListAccountCreationRule(ruleId: $ruleId)
}
`);

export const upsertGoogleUserListCreationRuleMutation = gql(`
mutation UpsertGoogleUserListAccountCreationRule(
  $priority: Int!
  $accountSelectionStrategy: GoogleUserListAccountSelectionStrategy!
  $groupId: ID
  $internalAccountId: ID
  $userMetadata: GoogleUserListAccountCreationUserMetadataInput
 ) {
    upsertGoogleUserListAccountCreationRule(
        input: {
            priority: $priority
            groupId: $groupId
            internalAccountId: $internalAccountId
            accountSelectionStrategy: $accountSelectionStrategy
            userMetadata: $userMetadata
        }
    ) {
        id
        accountSelectionStrategy
        priority
        googleAccount {
            id
            name
        }
        userMetadata {
            managerAccountUserMetadataKey
            customerAccountUserMetadataKey
        }
        group {
            id
        }
    }
}
`);

export const fetchActiveGoogleAccountsMinimalQuery = gql(`
query ActiveMinimalGoogleAccounts {
    googleAccounts(filter: { showInactive: false }) {
        id
        name
    }
}
`);
