import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const useGoogleTagManager = appSettings => {
  useEffect(() => {
    const gtmId = appSettings?.app?.trackers?.clientGoogleTagManagerId;

    if (!appSettings.loading && gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, [appSettings.loading]);
};

export default useGoogleTagManager;
