import { useMemo } from 'react';
import { FieldArray } from 'redux-form';
import { t } from 'i18next';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';

import RenderOffersInputs from './RenderOffersInputs';
import BlueprintBuilderStepFooter from '../BlueprintBuilderStepFooter';

const styles = theme => ({
  subHeading: {
    marginBottom: theme.spacing(2)
  }
});

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepOffersStepHeading'),
  stepSubHeading: t('admin:blueprintBuilder.stepOffersStepSubHeading')
});

const StepOffers = props => {
  const { classes } = props;
  const text = useMemo(() => pageText(), []);

  return (
    <div>
      <Typography variant="h6">{text.stepHeading}</Typography>
      <Typography className={classes.subHeading} variant="subtitle2">
        {text.stepSubHeading}
      </Typography>

      <FieldArray
        component={RenderOffersInputs}
        name="document.offers"
        validate={[fieldArrayFixDumbReduxFormError]}
      />

      <BlueprintBuilderStepFooter />
    </div>
  );
};

export default withStyles(styles)(StepOffers);
