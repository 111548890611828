import withTheme from '@mui/styles/withTheme';

const AutomatedProgramsRefineIcon = props => {
  const { active, completed, theme } = props;

  const primary =
    active || completed ? theme.palette.primary.main : theme.palette.grey[300];

  return (
    <div>
      <svg
        viewBox="0 0 24 24"
        width="72"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path id="a" d="M0 0h23.87v24H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            d="M.317 2.415C3.347 6.3 8.942 13.5 8.942 13.5v9c0 .825.676 1.5 1.5 1.5h3c.825 0 1.5-.675 1.5-1.5v-9s5.58-7.2 8.61-11.085c.765-.99.06-2.415-1.184-2.415H1.503C.257 0-.448 1.425.317 2.415"
            fill={primary}
            mask="url(#b)"
          />
        </g>
      </svg>
    </div>
  );
};

export default withTheme(AutomatedProgramsRefineIcon);
