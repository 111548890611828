import { t } from 'i18next';

import { Grid, Paper } from '@mui/material';

import Heading from 'src/components/PageElements/Heading';

import CreateArchitecture from './CreateArchitecture';
import UpdateArchitecture from './UpdateArchitecture';

const AdminArchitectureSettings = () => {
  return (
    <div>
      <Heading
        title={t('admin:headings.architectureSettings')}
        subTitle={t('admin:headings.architectureSettingsDescription')}
        pageTitle={t('admin:pageTitle.architectureSettings')}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <CreateArchitecture />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <UpdateArchitecture />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminArchitectureSettings;
