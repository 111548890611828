import { useCallback, useState, useMemo } from 'react';
import { cloneDeep, flow, isNil, omitBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { t } from 'i18next';

import { Button, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Modal from 'src/components/Modal';
import { useSnackbar } from 'notistack';

import { getWebhook } from './queries';
import { sendTestFacebookLeadWebhookNotification } from './mutations';
import TestWebhook from './TestWebhook';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    marginTop: theme.spacing(2)
  },
  modalButtonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
});

const pageText = ({ webhookUrl }) => ({
  cardHeading: t('admin:testWebhook.cardHeading'),
  cardSubheading: t('admin:testWebhook.cardSubheading', {
    webhookUrl
  }),
  cardSubheadingNoUrl: t('admin:testWebhook.cardSubheadingNoUrl'),
  drySuccessSnack: t('admin:testWebhook.drySuccessSnack'),
  successSnack: t('admin:testWebhook.successSnack'),
  errorSnack: t('admin:testWebhook.errorSnack'),
  modalHeader: t('admin:testWebhook.modalHeader'),
  closeButton: t('admin:testWebhook.modalClose'),
  testWebhookButton: t('admin:testWebhook.testWebhookButton')
});

const TestWebhookLanding = props => {
  const {
    classes,
    sendTestFacebookLeadWebhookNotification,
    webhookSubscription
  } = props;
  const webhookUrl = webhookSubscription?.url;
  const text = useMemo(() => pageText({ webhookUrl }), [webhookUrl]);
  const { enqueueSnackbar } = useSnackbar();

  const [showForm, setFormState] = useState(false);
  const [showResponseModal, setResponseModalState] = useState(false);
  const [webhookNotification, setWebhookNotification] = useState(null);

  const toggleForm = useCallback(
    modalState => {
      setFormState(modalState);
    },
    [webhookNotification, showForm]
  );

  const toggleResponseModal = useCallback(() => {
    setResponseModalState(!showResponseModal);
  }, [webhookNotification, showResponseModal]);

  const onSubmit = useCallback(async data => {
    const allMutationParameters = cloneDeep(data);
    const mutationParams = omitBy(allMutationParameters, isNil);

    try {
      const mutation = await sendTestFacebookLeadWebhookNotification({
        variables: {
          ...mutationParams,
          contentItemId: mutationParams?.contentItemId?.[0] // for this we only need one id & the selector returns an array
        }
      });

      const isDryRun = mutationParams?.isDryRun;

      const message = isDryRun ? text.drySuccessSnack : text.successSnack;

      enqueueSnackbar(message, {
        variant: 'success'
      });

      setWebhookNotification(
        JSON.stringify(
          mutation?.data?.sendTestFacebookLeadWebhookNotification?.message,
          null,
          2
        )
      );

      toggleResponseModal();
    } catch (error) {
      const message = text.errorSnack;
      enqueueSnackbar(message, {
        variant: 'error'
      });

      throw new SubmissionError(error);
    }
  }, []);

  return (
    <>
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>
      {webhookUrl ? (
        <Typography variant="subtitle2">{text.cardSubheading}</Typography>
      ) : (
        <Typography variant="subtitle2">{text.cardSubheadingNoUrl}</Typography>
      )}

      {showForm ? (
        <TestWebhook onSubmit={onSubmit} toggleForm={toggleForm} />
      ) : (
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            disabled={!webhookUrl}
            variant="contained"
            onClick={() => toggleForm(true)}
          >
            {text.testWebhookButton}
          </Button>
        </div>
      )}

      <Modal
        fullWidth
        open={showResponseModal}
        headerText={text.modalHeader}
        onClose={toggleResponseModal}
        FooterComponent={() => (
          <div>
            <Button onClick={toggleResponseModal}>{text.closeButton}</Button>
          </div>
        )}
      >
        <pre>{webhookNotification}</pre>
      </Modal>
    </>
  );
};

export default flow(
  connect(null, { SubmissionError }),
  graphql(getWebhook, {
    name: 'getWebhook',
    props: ({ getWebhook }) => {
      const { myOrganization } = getWebhook;
      const webhookSubscription = myOrganization?.webhookSubscription;

      return { webhookSubscription };
    }
  }),
  graphql(sendTestFacebookLeadWebhookNotification, {
    name: 'sendTestFacebookLeadWebhookNotification'
  }),
  withStyles(styles)
)(TestWebhookLanding);
