import { useEffect, useState } from 'react';
import { values, omit, sortBy } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
  Box,
  Grid,
  Typography,
  Button,
  Checkbox,
  useTheme,
  useMediaQuery,
  darken,
  Link,
  Alert
} from '@mui/material';

import { useAppSettings } from 'src/AppSettings';
import { paths } from 'src/routes/paths';

import { DynamicForm } from 'src/components/ReduxForm';
import TotalExpertIcon from 'src/components/Icons/TotalExpert';
import WorkatoConnect from 'src/components/Workato/WorkatoConnect';
import Outlook from 'src/components/Icons/Outlook';
import Gmail from 'src/components/Icons/Gmail';
import Instrumentation from 'src/instrumentation';
import { registerPlgUser as registerPlgUserMutation } from 'src/pages/Plg/SignUp/mutations';

const amplitudePlgAuthResponses = {
  success: 'success',
  failure: 'failure'
};

const defaultValues = {
  email: '',
  externalGroupId: '',
  name: ''
};

const SignUp = () => {
  const theme = useTheme();
  const appSettings = useAppSettings();
  const [acceptedTos, setAcceptedTos] = useState<boolean>(false);
  const [registerPlgUser] = useMutation(registerPlgUserMutation);
  const isMobileSize = useMediaQuery(theme.breakpoints.down('md'));

  const formMethods = useForm({
    defaultValues,
    mode: 'all'
  });

  const {
    organizationPlgConfig,
    organizationFqdn,
    organizationTos,
    organizationId
  } = appSettings;

  const [showConnect, setShowConnect] = useState<boolean>(false);
  const [showCheckEmail, setShowCheckEmail] = useState<boolean>(false);

  // we will consider the mobile start complete if the user is on a desktop
  const [mobileStartComplete, setMobileStartComplete] =
    useState<boolean>(!isMobileSize);

  const formValues = formMethods.watch();
  const organizationPlgConfigType = organizationPlgConfig.type;

  // In lieu of doing something complex to try to hook into the change callback to fire this event
  // we are just watching the externalGroupId field in the form values.
  useEffect(() => {
    if (formValues?.externalGroupId) {
      Instrumentation.logEvent(Instrumentation.Events.ClickSelectLenderName, {
        lenderName: formValues?.externalGroupId || 'None',
        plgEcosystem: organizationPlgConfigType
      });
    }
  }, [formValues?.externalGroupId]);

  // redirect to dashboard if the users does not have the organizationPlgConfig
  if (!organizationPlgConfig) {
    return (
      <Redirect
        to={{
          pathname: paths.dashboard.base,
          state: { from: window?.location?.href }
        }}
      />
    );
  }

  const generalFormError = formMethods.formState.errors?.root?.generalError;

  const dynamicInputs = values(
    omit(organizationPlgConfig?.userRegistration, ['__typename'])
  );

  // ensure the inputs are in the correct order
  const inputSortOrder = ['name', 'externalGroupId', 'email'];
  const orderedInputs = sortBy(dynamicInputs, field =>
    inputSortOrder.indexOf(field.name)
  );

  const onSubmit = () => {
    // clear any errors from the form we have some top level general errors that may need to be cleared
    formMethods.clearErrors();

    setShowConnect(true);

    Instrumentation.logEvent(Instrumentation.Events.ClickActivateAccount, {
      lenderName: formValues?.externalGroupId,
      plgEcosystem: organizationPlgConfigType
    });
  };

  const onSuccess = async (connected: boolean) => {
    if (connected) {
      Instrumentation.logEvent(Instrumentation.Events.OauthLoginResponse, {
        lenderName: formValues?.externalGroupId,
        plgEcosystem: organizationPlgConfigType,
        response: amplitudePlgAuthResponses.success
      });

      try {
        // TODO: once BE is updated we will need to pass the user agent and ip address to registerPlgUser to accept TOS (ticket: EP-1497)
        // const ipify = await fetch('https://api.ipify.org?format=json');
        // const ipData = await ipify.json();
        // const ipAddress = ipData.ip;
        // userAgent: navigator.userAgent,
        // ipAddress

        const variables = {
          input: {
            externalUserId: formValues.email,
            externalGroupId: formValues.externalGroupId,
            organizationId,
            name: formValues.name,
            email: formValues.email
          }
        };

        // create user
        await registerPlgUser({ variables });

        Instrumentation.logEvent(Instrumentation.Events.CreatePlgUser, {
          lenderName: formValues?.externalGroupId || 'None',
          plgEcosystem: organizationPlgConfigType,
          response: amplitudePlgAuthResponses.success
        });

        // direct to check email landing
        setShowCheckEmail(true);
      } catch (error: any) {
        // go back to the form when in error
        setShowConnect(false);
        setShowCheckEmail(false);

        Instrumentation.logEvent(Instrumentation.Events.CreatePlgUser, {
          lenderName: formValues?.externalGroupId || 'None',
          plgEcosystem: organizationPlgConfigType,
          response: amplitudePlgAuthResponses.failure
        });

        if (error?.message?.includes('EV_USER_EMAIL_ALREADY_EXISTS')) {
          return formMethods.setError(
            'email',
            {
              type: 'emailInUse',
              message: t('common:plg.signUp.error.emailInUse')
            },
            { shouldFocus: true }
          );
        }
        // all other general errors
        formMethods.setError('root.generalError', {
          type: 'generalError',
          message: t('common:plg.signUp.error.registrationFailed')
        });
      }
    }
  };

  const onError = () => {
    Instrumentation.logEvent(Instrumentation.Events.OauthLoginResponse, {
      lenderName: formValues?.externalGroupId,
      plgEcosystem: organizationPlgConfigType,
      response: amplitudePlgAuthResponses.failure
    });
  };

  const onResendActivationEmail = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickResendActivationEmail,
      {
        lenderName: formValues?.externalGroupId,
        plgEcosystem: organizationPlgConfigType
      }
    );
  };

  const userEmail = formValues?.email;

  const showStepOne = (isMobileSize && !mobileStartComplete) || !isMobileSize;
  const showStepTwo = (isMobileSize && mobileStartComplete) || !isMobileSize;

  return (
    <Grid container sx={{ height: '100vh' }}>
      {showStepOne && (
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            background: '#0C121D',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              position: 'relative', // Ensure the button is positioned relative to the image
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '100vh',
              minHeight: '800px'
            }}
          >
            <img
              src="https://ev-prod.s3.amazonaws.com/public/plg/plg-signup.png"
              alt="Collaborative Marketing Platform"
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
                maxHeight: '100vh',
                minHeight: '800px'
              }}
            />
            {isMobileSize && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '22%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => setMobileStartComplete(true)}
                  sx={{
                    background: 'white',
                    color: '#0E0035',
                    width: '300px',
                    '&:hover': {
                      background: darken('#fff', 0.2)
                    }
                  }}
                >
                  {t('common:plg.signUp.getStarted')}
                </Button>
                <Box sx={{ color: 'white', '& a': { color: 'white' } }}>
                  <Box
                    sx={{
                      marginTop: theme => theme.spacing(1),
                      fontSize: theme => theme.typography.body2.fontSize
                    }}
                  >
                    <Trans
                      i18nKey="common:plg.signUp.haveAccount"
                      components={[
                        <Link
                          component={RouterLink}
                          to={paths.dashboard.base}
                        />
                      ]}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {showStepTwo && (
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            background: theme => theme.palette.grey[200],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '8%'
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '600px' }}>
            <Typography
              sx={{
                fontSize: theme => theme.typography.h4.fontSize,
                fontWeight: '600',
                textAlign: 'center'
              }}
            >
              {showCheckEmail
                ? t('common:plg.signUp.titleEmail')
                : t('common:plg.signUp.title')}
            </Typography>
            {!generalFormError && (
              <>
                <Typography
                  sx={{
                    fontSize: theme => theme.typography.body1.fontSize,
                    marginTop: theme => theme.spacing(3),
                    marginBottom: theme => theme.spacing(3)
                  }}
                >
                  {showCheckEmail
                    ? t('common:plg.signUp.subTitleEmail')
                    : t('common:plg.signUp.subTitle')}
                </Typography>
              </>
            )}

            {generalFormError && (
              <Alert
                severity="error"
                sx={{
                  mt: 1,
                  marginBottom: theme => theme.spacing(2),
                  marginLeft: theme => theme.spacing(2)
                }}
              >
                {generalFormError.message}
              </Alert>
            )}

            <FormProvider {...formMethods}>
              <form
                autoComplete="off"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit={formMethods.handleSubmit(onSubmit)}
                style={{ width: '100%' }}
              >
                <Box
                  sx={{
                    display: showConnect || showCheckEmail ? 'none' : 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <DynamicForm isHookForm inputs={orderedInputs} />

                  <Box sx={{ marginTop: theme => theme.spacing(3) }}>
                    <Checkbox
                      checked={acceptedTos}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        Instrumentation.logEvent(
                          Instrumentation.Events.ClickTos,
                          {
                            action: event.target.checked,
                            plgEcosystem: organizationPlgConfigType
                          }
                        );
                        setAcceptedTos(event.target.checked);
                      }}
                    />{' '}
                    <Trans
                      i18nKey="common:plg.signUp.acceptTos"
                      components={[
                        // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                        <Box
                          component="a"
                          href={organizationTos?.link}
                          target="_blank"
                          rel="noreferrer"
                          sx={{
                            textDecoration: 'none'
                          }}
                        />
                      ]}
                    />
                  </Box>

                  <Button
                    sx={{ width: '100%', marginTop: theme => theme.spacing(3) }}
                    variant="contained"
                    type="submit"
                    disabled={!acceptedTos}
                  >
                    <Box sx={{ marginRight: theme => theme.spacing(2) }}>
                      {t('common:plg.signUp.activateVia')}
                    </Box>{' '}
                    <TotalExpertIcon />
                  </Button>

                  <Box
                    sx={{
                      marginTop: theme =>
                        isMobileSize ? theme.spacing(1) : theme.spacing(2),
                      fontSize: theme => theme.typography.body1.fontSize
                    }}
                  >
                    {t('common:plg.signUp.noAccount')}{' '}
                    <Box
                      component="a"
                      href="https://totalexpert.net/register/form"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ textDecoration: 'none' }}
                    >
                      {t('common:plg.signUp.signUp')}
                    </Box>
                  </Box>
                </Box>

                {!showCheckEmail && showConnect && (
                  <Box
                    sx={{
                      maxWidth: '100%',
                      marginTop: theme => theme.spacing(3),
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      overflow: 'scroll'
                    }}
                  >
                    <WorkatoConnect
                      onSuccess={onSuccess}
                      onError={onError}
                      isPublic={{
                        fqdn: organizationFqdn,
                        externalId: userEmail || ''
                      }}
                    />

                    <Button
                      sx={{
                        marginTop: theme => theme.spacing(3),
                        width: 'auto'
                      }}
                      onClick={() => setShowConnect(false)}
                    >
                      Back
                    </Button>
                  </Box>
                )}

                {showCheckEmail && (
                  <Box
                    sx={{
                      maxWidth: '100%',
                      marginTop: theme => theme.spacing(3),
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Box
                        component="a"
                        href="https://mail.google.com/mail/u/0/#inbox"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          mr: theme => theme.spacing(3),
                          textDecoration: 'none'
                        }}
                      >
                        <Gmail />
                        {t('common:plg.signUp.openGmail')}
                      </Box>

                      <Box
                        component="a"
                        href="https://outlook.office.com/mail/inbox"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                          textDecoration: 'none'
                        }}
                      >
                        <Outlook />
                        {t('common:plg.signUp.openOutlook')}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: '100%',
                        marginTop: theme => theme.spacing(3)
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: theme => theme.typography.h6.fontSize,
                          fontWeight: '600'
                        }}
                      >
                        {t('common:plg.signUp.checkSpam')}
                      </Typography>

                      <Box
                        onClick={onResendActivationEmail}
                        component="a"
                        href={`/#/activate/check?email=${userEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          marginTop: theme => theme.spacing(2),
                          display: 'block',
                          textDecoration: 'none'
                        }}
                      >
                        {t('common:plg.signUp.reEnterEmail', {
                          email: userEmail
                        })}
                      </Box>
                    </Box>
                  </Box>
                )}
              </form>
            </FormProvider>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SignUp;
