import { Typography } from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';

import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

interface InputConfirmationModalProps {
  onClose: () => void;
  open: boolean;
  title: string;
  body: string;
  onConfirm: () => void;
}

const InputConfirmationModal = ({
  onClose,
  open,
  title,
  body,
  onConfirm
}: InputConfirmationModalProps) => {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      icon={<WarningIcon />}
      title={title}
      onConfirm={onConfirm}
    >
      <Typography>{body}</Typography>
    </ConfirmationModal>
  );
};

export default InputConfirmationModal;
