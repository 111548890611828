import { isEmpty, isFunction, reduce } from 'lodash';
import { LazyQueryExecFunction } from '@apollo/client';

import {
  channelCodes,
  channelTypes,
  facebookCreativeTypes,
  googleCreativeTypes
} from 'src/common/adChannels';
import {
  AdPreview,
  Exact,
  ProgramValidateUrlQuery,
  UrlValidationRequest
} from 'src/generated/gql/graphql';
import SentryUtil from 'src/common/SentryUtil';

import {
  GOOGLE_VALIDATIONS,
  FACEBOOK_VALIDATIONS,
  ValidationName,
  VALIDATION_NAMES,
  GENERIC_GOOGLE_VALIDATIONS,
  GENERIC_FACEBOOK_VALIDATIONS
} from './constants';

type ValidateUrl = LazyQueryExecFunction<
  ProgramValidateUrlQuery,
  Exact<{ urlData: UrlValidationRequest }>
>;

const hasVariables = (value: string) => {
  const hasChips = value.includes('[[ ') && value.includes(' ]]');
  const hasVariables = value.includes('{') && value.includes('}');
  return hasChips || hasVariables;
};

type AdPreviewFields = Record<string, any>;
type CreativeErrors = Record<string, any>;

interface AddErrorArgs {
  errors: CreativeErrors;
  validationKey: string;
  key: string;
  message: string;
}

const createNewErrorsAccumulator = ({
  errors,
  validationKey,
  key,
  message
}: AddErrorArgs) => {
  if (validationKey === 'longHeadline') return errors;

  return {
    ...errors,
    [validationKey]: {
      errors: {
        ...errors[validationKey]?.errors,
        [key]: message
      }
    }
  };
};

type Validations = {
  [key in ValidationName]?: string;
};

interface ErrorsAccumulator {
  [key: string]: { errors: Validations; facetName: string };
}

interface GetAdPreviewErrorsArgs {
  preview: AdPreviewFields;
  validationConfig: Record<string, any>;
  validateUrl?: ValidateUrl;
}

// Returns the errors for a single preview variation (permutation or card)
const getAdPreviewErrors =
  ({ preview, validationConfig, validateUrl }: GetAdPreviewErrorsArgs) =>
  async (errorsAccumulator: ErrorsAccumulator, validationKey: string) => {
    const creativeValidations = validationConfig[validationKey];
    const value = preview[validationKey];

    const isEmptyString = typeof value === 'string' && !value.trim();

    // Short circuit check of typeof vlue is important here othewise we are potentially passing non-string values
    // to hasVariables which accepts a string
    if (typeof value !== 'string' || hasVariables(value) || isEmptyString) {
      return errorsAccumulator;
    }

    const fieldErrors = await reduce(
      creativeValidations,
      async (errorsPromise, validator, key) => {
        const errors = await errorsPromise;
        let message;
        let updatedErrorsAccumulator;

        if (
          typeof validator !== 'function' ||
          errors?.[validationKey]?.errors?.[key as ValidationName]
        ) {
          return errors;
        }

        if (
          key === VALIDATION_NAMES.noCrossDomainRedirectUrl &&
          isFunction(validateUrl)
        ) {
          message = await validateUrl({
            variables: { urlData: { url: value } }
          })
            .then(result => {
              const validationResult = result?.data?.validateUrl.result;
              return validator(validationResult);
            })
            .catch(err => {
              SentryUtil.captureException(err);
            });

          updatedErrorsAccumulator = createNewErrorsAccumulator({
            errors,
            validationKey,
            key,
            message
          });
        } else {
          message = validator(value);

          updatedErrorsAccumulator = createNewErrorsAccumulator({
            errors,
            validationKey,
            key,
            message
          });
        }

        if (!message) {
          return errors;
        }

        return updatedErrorsAccumulator;
      },
      Promise.resolve(errorsAccumulator)
    );

    return Promise.resolve(fieldErrors);
  };

interface GetErrorsForAllPreviewVariationsArgs {
  channel: string;
  validateUrl?: ValidateUrl;
}

type ValidationConfigField = Record<string, any>;

// A preview variation is either a permutation if google or a card if facebook
const getErrorsForAllPreviewVariations =
  ({ channel, validateUrl }: GetErrorsForAllPreviewVariationsArgs) =>
  (allErrors: Promise<CreativeErrors>, preview: AdPreviewFields) => {
    const isGoogleChannel = channel === channelTypes.google;
    const validationConfig = isGoogleChannel
      ? GOOGLE_VALIDATIONS.default
      : FACEBOOK_VALIDATIONS.default;

    const errors = Object.keys(validationConfig).reduce<ValidationConfigField>(
      getAdPreviewErrors({
        preview,
        validationConfig,
        ...(isGoogleChannel && { validateUrl })
      }),
      Promise.resolve(allErrors as CreativeErrors)
    );

    return Promise.resolve(errors);
  };

interface ValidateAdSetContentArgs {
  creative: Record<string, any>[];
  channel: string;
  validateUrl?: ValidateUrl;
}

// Validates the ad creative preview content for a single channel
const validateAdSetContent = ({
  creative = [],
  channel,
  validateUrl
}: ValidateAdSetContentArgs) => {
  return creative.reduce(
    getErrorsForAllPreviewVariations({
      channel,
      validateUrl
    }),
    Promise.resolve({} as CreativeErrors)
  );
};

interface ValidateChannelsArgs {
  previews: AdPreview[];
  validateUrl?: ValidateUrl;
}

// Returns the creative and facet args for a given preview
const getCreativeSpecificValues = (preview: AdPreview) => {
  let creative: Record<string, any>[] = [];
  let facetArgs = {};
  switch (preview.creativeType) {
    // Google Creatives
    case googleCreativeTypes.googleResponsiveDisplay:
      creative = preview.googleResponsiveDisplayCreative?.permutations || [];
      facetArgs =
        preview.googleResponsiveDisplayFacetArgs?.permutations?.[0] || {};
      break;

    case googleCreativeTypes.googleSearch:
      creative = preview.googleSearchCreative?.permutations || [];
      facetArgs = preview.googleSearchFacetArgs?.permutations?.[0] || {};
      break;

    case googleCreativeTypes.googleDiscoveryMultiAsset:
      creative = preview.googleDiscoveryMultiAssetCreative?.permutations || [];
      facetArgs =
        preview.googleDiscoveryMultiAssetFacetArgs?.permutations?.[0] || {};
      break;

    // Facebook Creatives
    case facebookCreativeTypes.fbSingleImage:
      facetArgs = preview.fbSingleImageFacetArgs || {};
      creative = preview?.fbSingleImageCreative
        ? [preview.fbSingleImageCreative]
        : [];
      break;

    case facebookCreativeTypes?.fbSingleVideo:
      creative = preview?.fbSingleVideoCreative
        ? [preview.fbSingleVideoCreative]
        : [];
      facetArgs = preview?.fbSingleVideoFacetArgs || {};
      break;

    case facebookCreativeTypes?.fbDareCarousel:
    case facebookCreativeTypes?.fbSingleProductCarousel:
    case facebookCreativeTypes?.fbCarousel:
      creative =
        preview?.fbCarouselCreative?.cards?.map(card => ({
          ...card,
          message: preview?.fbCarouselCreative?.message
        })) || [];
      facetArgs = {
        ...preview?.fbCarouselFacetArgs?.cards?.[0],
        ...(preview?.fbCarouselFacetArgs?.message && {
          message: preview?.fbCarouselFacetArgs?.message
        })
      };
      break;

    case facebookCreativeTypes?.fbDco:
      creative = preview?.fbDcoCreative?.singleImageAdPermutations?.[0]
        ? [preview.fbDcoCreative.singleImageAdPermutations[0]]
        : [];
      facetArgs = preview?.fbDcoFacetArgs?.singleImageAdPermutations?.[0] || {};
      break;
    default:
      break;
  }

  return { creative, facetArgs };
};

// Gets the validattion config based on channel and returns it
// as a formatted array of objects with a name and validator function
const getChannelValidators = (
  channel: string
): { [name: string]: () => string | undefined } => {
  switch (channel) {
    case channelCodes.google:
      return reduce(
        GENERIC_GOOGLE_VALIDATIONS,
        (accum, validator, name) => ({
          ...accum,
          [name]: validator
        }),
        {}
      );
    case channelCodes.facebook:
      return reduce(
        GENERIC_FACEBOOK_VALIDATIONS,
        (accum, validator, name) => ({
          ...accum,
          [name]: validator
        }),
        {}
      );
    default:
      return {};
  }
};

// Returns input validators object for a single channel
// { inputName: [ { name: validatorName, validator: validatorFunction } ] }
const getChannelInputValidators = (
  facetArgs: Record<string, any>,
  channel: string,
  currentValidators: Record<string, any>
) => {
  const inputValidators = reduce(
    facetArgs,
    (accum, vars: string, creativeField: string) => {
      // This captures variables in the string that are not preceded by __var__. and end with }}
      const regex = /(^(?!.*__var__\.)|(?<=__var__\.)).*?((?=}})|$)/g;
      const isFinalUrl = creativeField === 'finalUrl';
      const isGoogle = channel === channelCodes.google;

      if (creativeField === '__typename') return accum;

      let newAccum = { ...accum };

      if (isFinalUrl && !isGoogle) {
        return accum;
      }

      if (isFinalUrl) {
        // The first input variable is the destination URL
        const inputNameKey = vars.match(regex)?.[0]?.split(' ')[0];

        if (!inputNameKey) {
          return accum;
        }
        return {
          ...newAccum,
          [inputNameKey]: {
            noCrossDomainRedirectsUrl: () => undefined
          }
        };
      }
      vars?.match(regex)?.forEach(inputName => {
        const inputNameKey = inputName?.split(' ')[0];

        if (isEmpty(inputNameKey)) {
          return;
        }

        const validators = getChannelValidators(channel);

        if (isEmpty(newAccum[inputNameKey])) {
          newAccum = { ...newAccum, [inputNameKey]: validators };
        } else {
          // Ensures that we don't have duplicate validators
          const uniqueValidators = {
            ...newAccum[inputNameKey],
            ...validators
          };

          newAccum = {
            ...newAccum,
            [inputNameKey]: uniqueValidators
          };
        }
      });

      return newAccum;
    },
    currentValidators
  );

  return inputValidators;
};

type ValidateChannelsResult = Promise<Record<string, any>>;

// Validates the ad creative preview contet for all channels in a multi-channel BP
// These errors are displayed in the footer of the program/ automation create and program edit forms.
export const validateChannels = ({
  previews,
  validateUrl
}: ValidateChannelsArgs) => {
  const channelErrors = previews.reduce(
    async (errorsPromise, preview) => {
      const { channel } = preview;

      const errors = await errorsPromise;

      const { creative } = getCreativeSpecificValues(preview);

      const newErrors = await validateAdSetContent({
        creative,
        channel,
        validateUrl
      });

      return { ...errors, ...newErrors };
    },
    Promise.resolve({}) as ValidateChannelsResult
  );
  return channelErrors;
};

// Gets all input validators for all inputs which takes into account all channels in multi-channel
// BPs. This is an object with keys of input names and values of an array of validator objects that contain
// a name and a validator function
export const getInputValidators = (previews: AdPreview[]) => {
  const allChannelInputValidators = previews.reduce((accum, preview) => {
    const { channel } = preview;

    const { facetArgs } = getCreativeSpecificValues(preview);

    const inputValidators = getChannelInputValidators(
      facetArgs,
      channel,
      accum
    );

    return inputValidators;
  }, {});
  return allChannelInputValidators;
};
