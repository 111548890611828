import { isEmpty, get, isArray, isString, camelCase } from 'lodash';
import Handlebars from 'handlebars';

import formatters from 'src/common/formatters';

const locationDataRegex =
  /{{#with\s\(LOCATION\s[^)]+\)}}{{([^}]+)}}{{\/with}}/g;

// Adding ability to automatically setup Handlebars formatters for known types.
Object.keys(formatters).forEach(formatterKey => {
  Handlebars.registerHelper(formatterKey, (...args) => {
    return (formatters as any)[formatterKey](...args);
  });
});

export const translateMaps = (templateString = '', context = {}) => {
  if (isEmpty(templateString)) {
    return templateString;
  }

  let template: string | HandlebarsTemplateDelegate = '';

  try {
    // Note: This accounts for when we have an array of descriptions and headlines for google
    //       ad previews.
    // When we try to translate an array it just outputs a comma separated string so
    // all array based values need to be translated before getting here
    if (isArray(get(context, templateString))) {
      return get(context, templateString);
    }

    // Replace location handlebar expressions with a regular tag format
    const formattedTemplateString = templateString.replace(
      locationDataRegex,
      (_, p1) => {
        const formattedTag = camelCase(`location ${p1}`);
        return `{{${formattedTag}}}`;
      }
    );

    template = Handlebars.compile(formattedTemplateString, { noEscape: true });
    return template(context);
  } catch (e) {
    return 'Finish {{}} template...';
  }
};

export const isTemplate = (text: any) => {
  if (isString(text)) {
    // check if text has the double template brackets
    return text.indexOf('{{') >= 0 || locationDataRegex.test(text);
  }
};

export const isAdPreviewTemplate = (text: string) => {
  // check if text has the double template brackets
  return isString(text) && text.indexOf('[[') >= 0;
};
