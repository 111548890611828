import { useState } from 'react';
import { flow, get, isFunction, noop, isEmpty } from 'lodash';
import classNames from 'classnames';
import { t } from 'i18next';
import Dropzone from 'react-dropzone';

import { alpha } from '@mui/material/styles';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {
  GALLERY_TYPE,
  UPLOAD_ACCEPTS,
  MIN_IMG_HEIGHT,
  MIN_IMG_WIDTH
} from 'src/pages/Gallery/constants';

import { useSnackbar } from 'notistack';

import { Typography, LinearProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  container: {
    outline: 'none',
    position: 'relative'
  },
  content: {
    background: alpha(theme.palette.grey[300], 0.8),
    height: '100%',
    position: 'absolute',
    width: '100%',
    zIndex: theme.zIndex.appBar - 10
  },
  dropzoneContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%'
  },
  uploadIcon: {
    height: '25%',
    width: '25%'
  },
  progress: {
    width: '100%'
  }
});

const getErrorMessage = (galleryTypeKey, name) => {
  const galleryTypeMap = {
    [GALLERY_TYPE.image]: t('gallery:galleryType.image'),
    [GALLERY_TYPE.video]: t('gallery:galleryType.video'),
    [GALLERY_TYPE.audience]: t('gallery:galleryType.audience')
  };

  const galleryType = get(
    galleryTypeMap,
    galleryTypeKey,
    t('gallery:galleryType.unknown')
  );

  return t('gallery:errors.wrongFileType.image', {
    name,
    galleryType
  });
};

// Note: Component Info
//       1. It is up to you to deal with displaying the files. If you want to
//          access file contents you have to use the FileReader API:
//          https://developer.mozilla.org/en-US/docs/Web/API/FileReader
//
//       2. If you want to trigger the input from another component you will have to
//          pass in inputRef and then you have access and can do something like
//          this.inputRef.current.click()
//
//       3. If you want to trigger a submit on upload you will need to pass the
//          onSubmit prop
//
//       4. If you want the dropzone to cover an area you can wrap it in this component
//          for example <Field component={RenderDropzone}><div>dropzone will cover me</div></Field>
const RenderDropzone = props => {
  const {
    isStaticDropzone = false, // do we ever pass this???
    galleryType,
    input: { onChange },
    onSubmit,
    customOnChange,
    sizeConstraint,
    children,
    classes,
    containerClass,
    contentClass,
    disableClick = false,
    disabled = false,
    dropzoneText,
    inputRef,
    meta: { error, touched }
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [visible, setVisible] = useState(isStaticDropzone);

  const setDropzoneActive = () => {
    setVisible(true);
  };

  const setDropzoneInactive = () => {
    setVisible(false);
  };

  const onDrop = (acceptedFiles, failedFiles) => {
    // Note: Docs on how we access files that have been uploaded:
    //       https://react-dropzone.js.org/

    if (!isEmpty(failedFiles)) {
      // create error snacks for all failed files
      failedFiles.forEach(file => {
        enqueueSnackbar(getErrorMessage(galleryType, get(file, 'name')), {
          variant: 'error'
        });
      });
    }

    const promises = [];
    const validatedFiles = [];

    // validate image attributes here: (min width/height)
    acceptedFiles.forEach(acceptedFile => {
      promises.push(
        new Promise(resolve => {
          // we are only checking image files;
          if (!acceptedFile.type.includes('image')) {
            validatedFiles.push(acceptedFile);
            return resolve();
          }
          const fr = new FileReader();
          fr.onload = () => {
            // file is loaded
            const img = new Image();

            img.onload = () => {
              if (
                sizeConstraint &&
                (img.height < MIN_IMG_HEIGHT || img.width < MIN_IMG_WIDTH)
              ) {
                // set error
                enqueueSnackbar(
                  t('gallery:errors.minDimensions', {
                    fileName: acceptedFile.name,
                    MIN_IMG_WIDTH,
                    MIN_IMG_HEIGHT
                  }),
                  {
                    variant: 'error'
                  }
                );
              } else {
                validatedFiles.push(acceptedFile);
              }
              resolve();
            };

            img.src = fr.result; // is the data URL because called with readAsDataURL
          };

          fr.readAsDataURL(acceptedFile);
        })
      );
    });

    Promise.allSettled(promises).then(() => {
      // hide dropzone if not static
      if (!isStaticDropzone) {
        setDropzoneInactive();
      }

      // passing a customOnChange will require you to update the form input yourself
      if (isFunction(customOnChange)) {
        customOnChange(validatedFiles);
      } else {
        // update the redux form field with the accepted files
        onChange(validatedFiles);
      }

      // Note: If you pass an onSubmit we will trigger it when files are accepted.
      //       This will account for the case where we only want a form to upload
      //       images and it does not have a submit button like the Gallery page.
      if (isFunction(onSubmit)) {
        // submit the form if the function exists
        onSubmit(validatedFiles);
      }
    });
  };

  return (
    <Dropzone
      accept={UPLOAD_ACCEPTS[galleryType]}
      disableClick={disableClick}
      disabled={disabled}
      onDragEnter={isStaticDropzone ? noop : setDropzoneActive}
      onDragLeave={isStaticDropzone ? noop : setDropzoneInactive}
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => {
        return (
          <section
            className={classNames(classes.container, {
              [containerClass]: !!containerClass
            })}
            {...getRootProps()}
          >
            {visible && (
              <div
                className={classNames(classes.content, {
                  [contentClass]: !!contentClass
                })}
              >
                <LinearProgress
                  className={classes.progress}
                  color="secondary"
                />
                <div className={classes.dropzoneContent}>
                  <CloudUploadIcon className={classes.uploadIcon} />
                  <Typography variant="h5">
                    {dropzoneText || t('gallery:dropZone.uploadMessage')}
                  </Typography>
                  {error && touched ? error : ''}
                </div>
              </div>
            )}

            <input {...getInputProps()} ref={inputRef} />

            {children}
          </section>
        );
      }}
    </Dropzone>
  );
};

export default flow(withStyles(styles))(RenderDropzone);
