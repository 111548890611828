import { useState, useEffect } from 'react';
import { flow } from 'lodash';
import { Trans } from 'react-i18next';

import Auth from 'src/Auth/Auth';

import { paths } from 'src/routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { CircularProgress, Grid, Link, Paper, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { withAppSettings } from 'src/AppSettings';

import PageTitle from 'src/components/PageTitle';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(5)
  },
  subTitle: {
    marginTop: theme.spacing(2)
  },
  progress: {
    margin: theme.spacing(2)
  },
  centeredWrap: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: '500px',
    minWidth: '300px'
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  companyLogo: {
    width: `${theme.evSizes.navigationWidth}px`
  }
});

const Logout = props => {
  const { classes, appSettings } = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Auth.logout().then(() => {
      if (appSettings?.logoutUrl) {
        window.location = appSettings.logoutUrl;
      }
      setLoading(false);
    });
  }, []);

  const showLoginBackLink = appSettings?.app?.features?.loginBackLink;

  return (
    <Grid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle="Logout" />
      <Paper className={classes.centeredWrap}>
        <div className={classes.logoContainer}>
          <img
            alt={`${appSettings?.app.general.baseTitle} Logo`}
            className={classes.companyLogo}
            src={appSettings?.evAssets?.logoUrl}
          />
        </div>
        {loading ? (
          <Grid item xs>
            <Typography component="h1" variant="h4">
              <Trans i18nKey="login:title.loggingOut">Logging out</Trans>
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle1">
              <Trans i18nKey="login:subTitle.standby">Please standby</Trans>
            </Typography>
            <CircularProgress className={classes.progress} />
          </Grid>
        ) : (
          <Grid item xs>
            <Typography component="h1" variant="h4">
              <Trans i18nKey="login:title.loggedOut">Logged out</Trans>
            </Typography>
            <Typography className={classes.subTitle} variant="subtitle1">
              <Trans i18nKey="login:subTitle.loggedOut">
                You have been logged out.
              </Trans>

              {showLoginBackLink && (
                <Trans
                  i18nKey="login:subTitle.signInAgain"
                  components={[
                    <Link component={RouterLink} to={paths.auth.login} />
                  ]}
                />
              )}
            </Typography>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default flow(withAppSettings, withStyles(styles))(Logout);
