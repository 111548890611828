/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useEffect, useRef } from 'react';
import { isNumber } from 'lodash';

type Callback = (() => void) | (() => Promise<void>);

export const useInterval = (callback: Callback, delay: number | null) => {
  const savedCallback = useRef<Callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      savedCallback.current!();
    }
    // Checks for Number primitive or object
    if (isNumber(delay)) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
