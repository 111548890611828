import { useState } from 'react';
import { t } from 'i18next';

import { Box, Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

interface DeleteDraftModalProps {
  onConfirm: ({
    dismissModal
  }: {
    dismissModal?: boolean;
  }) => void | Promise<void>;
  onClose: () => void;
  open: boolean;
}

const DeleteDraftModal = ({
  onConfirm,
  onClose,
  open
}: DeleteDraftModalProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={t('programs:programsTable.draftDeleteTitle')}
      onConfirm={() => onConfirm({ dismissModal: checked })}
      icon={<DeleteIcon />}
    >
      <>
        {t('programs:programsTable.draftDeleteMessage')}
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={() =>
                  setChecked(prev => {
                    return !prev;
                  })
                }
              />
            }
            label={t('programs:programsTable.draftDeleteDismiss')}
          />
        </Box>
      </>
    </ConfirmationModal>
  );
};

export default DeleteDraftModal;
