import { gql } from 'src/generated/gql';

export const getAdminContentSets = gql(`
  query GetAdminContentSets {
    adminContentSets {
      slug
      name
      fields {
        name
        type
        isNullable
      }
    }
  }
`);

export const getAdminContentSetItems = gql(`
  query GetAdminContentSet(
    $slug: String!
    $first: Int!
    $after: String
    $sort: SortSettings
    $filter: JSON
  ) {
    adminContentSet(slug: $slug) {
      slug
      items(first: $first, after: $after, sort: $sort, filter: $filter) {
        edges {
          node {
            values
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);

export const createOrUpdateAdminContentSetItem = gql(`
  mutation CreateOrUpdateAdminContentSetItem(
    $contentSetSlug: String!
    $item: AdminContentSetItemInput!
  ) {
    createOrUpdateAdminContentSetItem(
      contentSetSlug: $contentSetSlug
      item: $item
    ) {
      values
    }
  }
`);

export const deleteAdminContentSetItem = gql(`
  mutation DeleteAdminContentSetItem($contentSetSlug: String!, $itemId: ID!) {
    deleteAdminContentSetItem(contentSetSlug: $contentSetSlug, itemId: $itemId)
  }
`);

export const getBulkOperationReportStatus = gql(`
  query GetBulkOperationReportStatus($reportId: String!) {
    getBulkOperationReportStatus(reportId: $reportId) {
      totalItems
      remainingItems
      completedItems
      successfulItems
      errorItems
      isCompleted
    }
  }
`);

export const getBulkOperationReportErrors = gql(`
  query GetBulkOperationReportErrors($reportId: String!, $pageToken: String) {
    getBulkOperationReportErrors(reportId: $reportId, pageToken: $pageToken) {
      errors {
        input
        errorMessage
      }
      nextPageToken
      previousPageToken
    }
  }
`);
