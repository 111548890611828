import { createContext } from 'react';

export const THEME_NAMES = {
  default: 'default',
  automationPage: 'automationPage',
  programCreatePage: 'programCreatePage',
  facebookPageLinking: 'facebookPageLinking'
};

export const ThemeSwitcherContext = createContext(() => {});

export const ThemeSwitcherProvider = ({ children, value }) => (
  <ThemeSwitcherContext.Provider value={value}>
    {children}
  </ThemeSwitcherContext.Provider>
);
