import { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans } from 'react-i18next';

import { Box, Button } from '@mui/material';

import { QuickStartItem } from 'src/components/Quickstart';

interface DraggableQuickStartItemProps {
  architectureId: string;
  catalogContentType: string;
  channels: any[];
  handleItemStatus: (id: string, isActive: boolean) => any;
  id: string;
  index: number;
  isActive: boolean;
  itemType: string;
  notes: string;
  objective: string;
  isCustom: boolean;
  customImageUrl: string;
  iconImageUrl: string;
  productIds: string[];
  setDraggingId: (id: string | null) => void;
  setHoverIndex: (index: number | null) => void;
  description: string;
  productName: string;
  name: string;
}

const DraggableQuickStartItem = ({
  architectureId,
  catalogContentType,
  channels,
  handleItemStatus,
  id,
  index,
  isActive,
  itemType,
  notes,
  objective,
  isCustom,
  customImageUrl,
  iconImageUrl,
  productIds,
  setDraggingId,
  setHoverIndex,
  description,
  productName,
  name
}: DraggableQuickStartItemProps) => {
  const draggableQuickstartRef = useRef(null);
  const [{ opacity, isDragging }, drag] = useDrag(() => ({
    type: itemType,
    item: () => {
      return { id };
    },
    end: () => setDraggingId(null),
    isDragging: monitor => monitor.getItem().id === id,
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0 : 1,
      isDragging: monitor.isDragging()
    })
  }));

  useEffect(() => {
    if (isDragging) {
      setDraggingId(id);
    }
  }, [isDragging]);

  const [, drop] = useDrop(
    () => ({
      accept: itemType,
      drop: () => {
        setHoverIndex(null);
      }
    }),
    []
  );

  drag(drop(draggableQuickstartRef));

  return (
    <Box
      sx={{
        height: '100%',
        cursor: 'move'
      }}
      style={{ opacity }}
      ref={isActive ? draggableQuickstartRef : null}
      data-cy={`draggable-item-${index}`}
    >
      <QuickStartItem
        id={id}
        architectureId={architectureId}
        catalogContentType={catalogContentType}
        channels={channels}
        isCustom={isCustom}
        customImageUrl={customImageUrl}
        iconImageUrl={iconImageUrl}
        notes={notes}
        objective={objective}
        productIds={productIds}
        description={description}
        name={name}
        productName={productName}
        isEditing
        buttonComponent={
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => handleItemStatus(id, !isActive)}
            data-cy="deactivate-button"
          >
            <Trans i18nKey="admin.manageQuickstart.deactivateButton">
              Deactivate
            </Trans>
          </Button>
        }
      />
    </Box>
  );
};

export default DraggableQuickStartItem;
