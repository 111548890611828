import { Trans } from 'react-i18next';

import { Divider, List, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import BusinessObjectBulkListItem from './BusinessObjectBulkListItem';

const styles = () => ({
  list: {
    maxHeight: '200px',
    minHeight: '200px',
    overflow: 'scroll'
  }
});

const BusinessObjectBulkSelector = props => {
  const { classes, contentName, hasCatalog } = props;
  const contentNameLowercase = contentName
    ? contentName.toLowerCase()
    : 'content';

  let title = (
    <Trans
      i18nKey="businessObjectSelector:businessObjectBulkSelector.title"
      values={{ contentName: contentNameLowercase }}
    />
  );

  if (!hasCatalog) {
    title = (
      <Trans i18nKey="businessObjectSelector:businessObjectBulkSelector.noContentTitle" />
    );
  }

  return (
    <div>
      <Typography variant="body2">{title}</Typography>
      <Divider />
      <List className={classes.list}>
        <BusinessObjectBulkListItem
          contentName={contentNameLowercase}
          hasCatalog={hasCatalog}
        />
      </List>
    </div>
  );
};

export default withStyles(styles)(BusinessObjectBulkSelector);
