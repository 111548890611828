import { Fragment, useState } from 'react';

import { Collapse, List } from '@mui/material';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

import Link from 'src/components/Link';
import NavigationListItem from './NavigationListItem';

export interface MenuItem {
  key: string;
  name: string;
  icon?: JSX.Element;
  path: string;
  customProps?: object;
}

interface DropdownMenuListItemProps {
  name: string;
  menuItems: MenuItem[];
  getIcon?: (open: boolean) => JSX.Element;
  isSelected?: (path: string) => boolean;
  getDefaultState?: () => boolean;
  customProps?: object;
}

const DropdownMenuListItem = ({
  name,
  menuItems,
  getIcon,
  isSelected,
  getDefaultState,
  customProps
}: DropdownMenuListItemProps) => {
  const defaultState = getDefaultState ? getDefaultState() : false;
  const [open, setOpen] = useState(defaultState);

  const toggleMenu = () => {
    setOpen(prevState => !prevState);
  };

  return (
    <Fragment key={`nav-list-${name}`}>
      <NavigationListItem
        onClick={toggleMenu}
        icon={getIcon && getIcon(open)}
        name={name}
        customProps={customProps}
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </NavigationListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menuItems.map(item => (
            <NavigationListItem
              customProps={item?.customProps}
              key={item.key}
              component={Link}
              selected={isSelected && isSelected(item.path)}
              to={item.path}
              name={item.name}
              isDropdownMenuItem
            />
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default DropdownMenuListItem;
