import { useState, useMemo, useLayoutEffect } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

import Loading from 'src/components/Loading';

const DiffViewer = props => {
  const {
    oldValue,
    newValue,
    leftTitle,
    rightTitle,
    showDiffOnly,
    selectedDocumentLoading
  } = props;

  const [loading, setLoading] = useState(true);
  const oldV = useMemo(() => JSON.stringify(oldValue, null, 2), [oldValue]);
  const newV = useMemo(() => JSON.stringify(newValue, null, 2), [newValue]);

  useLayoutEffect(() => {
    setLoading(true);
    setTimeout(() => {
      // doing this b/c ReactDiffViewer is slow and we want the drawer open and show some form of loading
      setLoading(false);
    }, 500);
  }, [oldValue, newValue]);

  const Differ = (
    <ReactDiffViewer
      oldValue={oldV}
      newValue={newV}
      splitView
      disableWordDiff
      leftTitle={leftTitle}
      rightTitle={rightTitle}
      showDiffOnly={showDiffOnly}
    />
  );

  return loading || selectedDocumentLoading ? <Loading /> : Differ;
};

export default DiffViewer;
