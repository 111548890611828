import { cloneDeep, isEmpty, sortBy } from 'lodash';

import {
  nullEmptyStringValues,
  omitDeep,
  replaceEmptyArraysWithNull
} from 'src/components/ReduxForm/helpers';

const normalizeBlueprintVariables = formValues => {
  const newValues = cloneDeep(formValues);

  if (isEmpty(newValues)) {
    return newValues;
  }

  return newValues?.document?.blueprint?.variables?.map(variable => {
    const currentVariable = variable;

    // document.blueprint.variables.array should be null if both the min & max value are empty
    if (
      currentVariable?.array &&
      currentVariable?.array?.min === '' &&
      currentVariable?.array?.max === ''
    ) {
      currentVariable.array = null;
    }
    return currentVariable;
  });
};

const cleanOffers = offers => {
  // Localize the empty array cleaning to just offers since other
  // arrays are non-nullable
  // (specifically grantees at the root. Here they're nullable)
  return replaceEmptyArraysWithNull(offers, new Set(['grantees']));
};

export const cleanData = (formValues, isPublishing = true) => {
  let newValues = cloneDeep(formValues);

  if (isEmpty(newValues)) {
    return newValues;
  }

  if (newValues?.document?.blueprint?.variables) {
    newValues.document.blueprint.variables =
      normalizeBlueprintVariables(newValues);
  }

  // change empty strings to null values
  newValues = nullEmptyStringValues(newValues);

  // Careful with adding in new cleaning functions!
  // Some properties like arrays have a mixture of null and non-null
  // graph types!

  if (newValues?.document?.offers != null) {
    newValues.document.offers = cleanOffers(newValues.document.offers);
  }

  // set the product name and description to match blueprint name and description
  newValues.document.name = newValues.document.blueprint.name;
  newValues.document.description = newValues.document.blueprint.description;

  if (isPublishing) {
    // __typename: Comes back in the original graph query and is all over the form tree so
    //             we need to recursively remove it on publish.
    return omitDeep(newValues, ['__typename']);
  }

  return newValues;
};

export const sortKeys = objectToSort => {
  return Object.keys(objectToSort)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: objectToSort[key]
      }),
      {}
    );
};

export const mapEnumeratedValues = ({ getEnumeratedValues }) => {
  const enumerationValues =
    getEnumeratedValues?.listEnumeratedValues?.enumerationValues;

  return {
    enumerationValues
  };
};

// Sorts the facets by priority value
const prioritySortFacets = publishers => {
  const newPublishers = cloneDeep(publishers);

  newPublishers.forEach(publisher => {
    const updatedPublisher = publisher;
    updatedPublisher.facets = sortBy(updatedPublisher.facets, ['priority']);
  });

  return newPublishers;
};

export const sortInitialValues = initialValues => {
  if (isEmpty(initialValues)) {
    return initialValues;
  }
  const { publishers } = initialValues?.document?.blueprint;

  const newInitialValues = cloneDeep(initialValues);

  newInitialValues.document.blueprint.publishers =
    prioritySortFacets(publishers);

  return newInitialValues;
};
