import { gql } from 'src/generated/gql';

export const getArchitectures = gql(`
  query getArchitecturesForBlueprintBuilder {
    us {
      id
      architectures(showInactive: true) {
        id
        slug
        name
        isContentSelectable
        isActive
      }
    }
  }
`);

export const getProducts = gql(`
  query getProducts {
    productDocumentVersions {
      id
      productId
      versionTimestamp
      status
      errorMessage
      document {
        id
        name
        architectureId
        architecture
        organization
        description
        available
        requiresContent
        isMlp
        contentDrivenPreviewConfig {
          enabled
        }
      }
    }
  }
`);

export const getAllProductVersions = gql(`
  query getAllProductVersions($productId: ID, $limit: Int, $documentId: ID) {
    productDocumentVersions(
      productId: $productId
      limit: $limit
      documentId: $documentId
    ) {
      id
      productId
      versionTimestamp
      status
      errorMessage
      document {
        id
        name
        blueprint {
          name
          description
        }
      }
    }
  }
`);

export const getProductByProductId = gql(`
  query productDocumentVersions($productId: ID, $documentId: ID, $limit: Int) {
    productDocumentVersions(
      productId: $productId
      documentId: $documentId
      limit: $limit
    ) {
      id
      productId
      versionTimestamp
      status
      errorMessage
      document {
        id
        architectureId
        architecture
        organization
        slug
        name
        description
        available
        requiresContent
        isMlp
        grantees {
          userExternalId
          groupExternalId
          organizationSlug
          groupRole
          organizationUserRole
          scope
        }
        blueprint {
          slug
          name
          description
          variables {
            name
            type
            friendlyName
            description
            defaultValue
            required
            expressionAllowed
            array {
              min
              max
            }
            stringMaxLength
            integerMinValue
            integerMaxValue
            isImmutable
            includeInWebhooks
          }
          contentSets {
            alias
            description
            contentSet
            groupKey
            filter
          }
          publishers {
            slug
            name
            description
            prototype
            contentSetAlias
            condition
            previewKeys
            facets {
              slug
              name
              facet
              parameterValues
              condition
              priority
            }
          }
          objectiveSlug
        }
        contentDrivenPreviewConfig {
          contentMaxAgeDays
          enabled
          maxNumPreviews
          requiredNumContentItems
          groupKey
        }
        defaultVariableValues
        inputSections {
          name
          description
          mutable
          collapsed
          fields {
            variableName
            displayName
            displayMethod
            displayParameters
            editable
            hidden
          }
        }
        offers {
          slug
          name
          type
          payer
          availableTo
          mediaSpendMultiplier
          cancellationType
          purchase {
            defaultDurationDays
            price
            userSet
            userSetMin
            userSetMax
            scheduleAdjustmentEnabled
            applyScheduleAdjustmentEnabledChangeToExistingOrders
            orderAmountAdjustmentEnabled
            applyOrderAmountAdjustmentEnabledChangeToExistingOrders
          }
          subscription {
            stripeProductId
            intervalDays
            pricingPlan
            minimumDurationDays
            tierChangeMethod
            applyTierChangeMethodToExistingOrders
          }
          active
          isSupervisable
          billingMethod
          billingProfileId
          displaySortOrder
          grantees {
            userExternalId
            groupExternalId
            organizationSlug
            groupRole
            organizationUserRole
            scope
          }
          tikTokDailyMinSpend
          facebookDailyMinSpend
          googleDailyMinSpend
          platformFeeBase
          platformFeeMultiplier
          partnerFeeBase
          partnerFeeMultiplier
        }
        quickStart {
          name
          description
          priority
          active
        }
        messaging {
          configure
          spend
          summary
          pendingState
          schedule
          endDate
          adPreviewFooter
          sellingPoints
        }
      }
    }
  }
`);

export const getContentSetFieldDisplayMethods = gql(`
  query contentSetFieldDisplayMethods {
    contentSetFieldDisplayMethods {
      id
      name
    }
  }
`);

export const getEnumeratedValues = gql(`
  query listEnumeratedValues($request: ListEnumerationValuesRequest!) {
    listEnumeratedValues(request: $request) {
      enumerationValues {
        enumeration
        values {
          key
          name
        }
      }
    }
  }
`);

export const billingMethodIntrospection = gql(`
  query billingMethodIntrospection {
    __type(name: "OrderBillingMethod") {
      name
      enumValues {
        name
      }
    }
  }
`);

export const getPrototypeHelperData = gql(`
  query getPrototypeHelperData {
    getUnits {
      id
      channelCode
      code
      name
      priority
      prototypes {
        id
        slug
        name
        description
        visibility
        facets {
          slug
          name
          parameters {
            name
            friendlyName
            isRequired
            isArray
          }
        }
      }
    }
  }
`);

export const getFacetHelperData = gql(`
  query getFacetHelperData($unitId: ID, $prototype: String) {
    getUnits(unitId: $unitId) {
      id
      prototypes(prototypeSlug: $prototype) {
        id
        facets {
          id
          code
          slug
          name
          description
          visibility
        }
      }
    }
  }
`);

export const getParameterValueHelperData = gql(`
  query getParameterValueHelperData($unitId: ID, $prototype: String) {
    getUnits(unitId: $unitId) {
      id
      prototypes(prototypeSlug: $prototype) {
        id
        facets {
          id
          code
          slug
          name
          description
          visibility
          parameters {
            id
            name
            type
            friendlyName
            isRequired
            enumValues
            isArray
          }
        }
      }
    }
  }
`);

export const getAllPricingPlans = gql(`
  query getAllPricingPlans {
    allPricingPlans {
      id
      currencyCode
      slug
      name
      description
      intervalUnit
      intervalLength
      tiers {
        id
        pricingPlan {
          id
          currencyCode
          slug
          name
          description
          intervalUnit
          intervalLength
          isActive
        }
        slug
        name
        amount
        isActive
      }
      isActive
    }
  }
`);
