import { useState } from 'react';
import { isEqual, isEmpty, xorWith } from 'lodash';
import { t } from 'i18next';
import { useWatch } from 'react-hook-form';

import { styled } from '@mui/system';
import { Box, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { contentColumnsFromMetadata } from 'src/common/dynamicUserInputs';
import { validateLength } from 'src/common/validations';
import { useGlobalContext } from 'src/GlobalContextProvider';
import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';

import {
  validateDynamic,
  getDynamicColumnValues
} from 'src/components/ReduxForm/DynamicForm/helpers';

import RenderTemplateStringTextField from 'src/components/ReduxForm/RenderTemplateStringTextField';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';

import { getProgramNameTranslated } from './ProgramNameUtil';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from '../Constants';

const PROGRAM_FIELD_NAME = 'programName';

const HelpText = styled('div')(({ theme }) => ({
  alignItems: 'center',
  color: theme.palette.grey[700],
  display: 'flex',
  fontSize: '0.8rem',
  marginBottom: theme.spacing(1),

  '& svg': {
    marginRight: theme.spacing(1)
  }
}));

const ProgramName = props => {
  const {
    disabled,
    selectedBlueprint,
    displayNameTemplate,
    contentColumns,
    businessObjects,
    isAutomated,
    showHelpTextAsTooltip,
    inputVariant,
    label,
    containerSx = {},
    isContentSelectable = false,
    contentName
  } = props;

  const globalContext = useGlobalContext();
  const userMetadataFields = globalContext?.me?.metadata?.fields;
  const userFieldMetadata = globalContext?.me?.metadata?.fieldMetadata;
  const userContentColumns =
    contentColumnsFromMetadata(userFieldMetadata) || [];

  const programName = useWatch({
    name: `spendStep.${PROGRAM_FIELD_NAME}`,
    defaultValue: ''
  });

  const generateValidations = () => [
    validateLength(1, 1000),
    value =>
      validateDynamic(
        value,
        contentColumns,
        businessObjects,
        t('programCreate:programNameInput.validation.missing'),
        userContentColumns,
        [userMetadataFields] // needs to be an array
      )
  ];

  const [validations, setValidations] = useState({
    validations: generateValidations(),
    businessObjects
  });

  if (xorWith(validations.businessObjects, businessObjects, isEqual).length) {
    validations.validations = generateValidations();
    validations.businessObjects = businessObjects;
    setValidations(validations);
  }

  let inputValueTranslated = '';
  if (programName !== undefined && programName !== '') {
    inputValueTranslated = getProgramNameTranslated(
      programName,
      selectedBlueprint,
      businessObjects,
      displayNameTemplate,
      userMetadataFields
    );
  }

  const requiresContent = selectedBlueprint?.requiresContent;

  const InputComponent = requiresContent
    ? RenderTemplateStringTextField
    : RenderTextField;

  const { dynamicValues, dynamicValuesMap, missingColumns, validColumns } =
    getDynamicColumnValues([
      {
        shouldRenderChips: isContentSelectable,
        columns: contentColumns,
        content: businessObjects,
        type: 'content',
        friendlyName: contentName || 'Listing'
      },
      {
        shouldRenderChips: !isEmpty(userMetadataFields),
        columns: userContentColumns,
        content: [userMetadataFields],
        type: 'userMetadata',
        friendlyName: 'My Profile'
      }
    ]);

  // props for template string text field
  const extraProps = requiresContent
    ? {
        dynamicValues,
        dynamicValuesMap,
        validColumns,
        missingColumns,
        businessObjects
      }
    : {};

  const helpText = isAutomated
    ? t('programCreate:programNameInput.automatedHelpText')
    : t('programCreate:programNameInput.helpText');

  const sharedProps = {
    disabled,
    label,
    component: InputComponent,
    fullWidth: true,
    autoComplete: 'completely-off',
    // note: we don't seem to be validating anything here atm.
    // we probalby don't really care for the time being.
    // validate: validations.validations,
    name: PROGRAM_FIELD_NAME
  };

  const extra = {
    ...extraProps,
    blockEmojiPicker: true,
    multiline: false,
    variant: inputVariant,
    ...(showHelpTextAsTooltip ? { tooltip: helpText } : {})
  };

  return (
    <Grid
      item
      xs={12}
      sx={{ padding: theme => theme.spacing(2, 0), ...containerSx }}
    >
      {!showHelpTextAsTooltip && (
        <HelpText>
          <InfoIcon />
          {helpText}
        </HelpText>
      )}

      <HookFormWrapper
        {...sharedProps}
        extraProps={extra}
        formNamespace={PROGRAM_FORM_SECTION_SPEND_NAME}
      />

      {/* Don't render the formatted version if there's nothing to
                  translate.
               */}
      {Array.isArray(contentColumns) &&
      contentColumns.length &&
      selectedBlueprint?.requiresContent ? (
        <Box
          sx={{
            color: 'grey.700',
            fontSize: '0.8rem',
            marginBottom: 1,
            whiteSpace: 'normal'
          }}
        >
          <strong>{inputValueTranslated}</strong>
        </Box>
      ) : (
        <span />
      )}
    </Grid>
  );
};

export default ProgramName;
