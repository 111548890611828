import { DEFAULT_FORMATTERS } from 'src/common/formatters';
import { INTERNAL_ONLY_FIELDS } from 'src/components/ContentTable/utils';

// Warning: We recommend to only use this for the DynamicUserInputs components
//          as the values will get prefixed with formatting helper functions so
//          if your component needs to rely on the static fieldName without any
//          formatting helpers, we advise you to use a different function (or
//          update this one).
export const contentColumnsFromMetadata = (metadata = []) => {
  return metadata
    .filter(mdRow => {
      // Ensure that we exclude columns that the user doesn't need to
      // worry about.
      return !INTERNAL_ONLY_FIELDS[mdRow?.fieldName];
    })
    .map(column => {
      // If we have a formatting function for this column's displayMethodId,
      // automatically add it as a prefix for the chip so Handlebars will
      // format it for the user.
      let prefix = '';
      const displayMethodId = column?.displayMethodId;
      const displaySortOrder = column?.displaySortOrder;

      if (DEFAULT_FORMATTERS[displayMethodId]) {
        prefix = `${DEFAULT_FORMATTERS[displayMethodId]} `;
      }

      return {
        displayMethodId,
        displaySortOrder,
        fieldName: column?.fieldName,
        label: column?.displayName,
        value: `{{${prefix}${column?.fieldName}}}`,
        isHidden: column.isHidden
      };
    });
};

export const contentColumnsFromArchitecture = arch => {
  const metaData = arch?.catalog?.fieldMetadata || [];
  return contentColumnsFromMetadata(metaData);
};

const DEFAULT_DYNAMIC_COPY_DISPLAY_METHOD_ID = 'single_line_string';
const DEFAULT_DYNAMIC_COPY_SORT_ORDER = 100000;

export const contentColumnsFromDynamicCopy = (dynamicFieldMetadata = []) => {
  return dynamicFieldMetadata.map(f => {
    return {
      displayMethodId: DEFAULT_DYNAMIC_COPY_DISPLAY_METHOD_ID,
      displaySortOrder: DEFAULT_DYNAMIC_COPY_SORT_ORDER,
      fieldName: f.fieldName,
      label: f.label,
      value: `{{DEFAULT ${f.fieldName} '${f.fieldName}'}}`
    };
  });
};

export const contentFieldsFromDynamicCopy = (dynamicFieldMetadata = []) => {
  return dynamicFieldMetadata?.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.fieldName]: cur.sampleValue
    }),
    {}
  );
};
