import { useCallback, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { flow, find, pickBy } from 'lodash';
import { t } from 'i18next';

import { Paper, Button, CircularProgress, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Loading from 'src/components/Loading';
import { useSnackbar } from 'notistack';
import { DynamicForm } from 'src/components/ReduxForm';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import Heading from 'src/components/PageElements/Heading';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';

import { createGallery, updateGallery } from './mutations';
import { assetTypeIntrospection, getGalleries } from './queries';

import {
  getAvailableAssetTypeEnums,
  getGalleryInputs,
  mapAssetTypeIntrospectionToProps
} from './constants';

const styles = theme => ({
  content: {
    padding: theme.spacing(2)
  }
});

const pageText = () => ({
  title: t('adminGalleries:edit.title'),
  subTitle: t('adminGalleries:edit.subTitle'),
  errorMessage: t('adminGalleries:edit.errorDescription'),
  successMessage: t('adminGalleries:edit.successDescription'),
  updateButton: t('adminGalleries:edit.submitUpdate'),
  createButton: t('adminGalleries:edit.submitCreate'),
  cancelButton: t('adminGalleries:edit.cancel'),
  resetButton: t('adminGalleries:edit.reset')
});

const AdminGalleryForm = ({
  classes,
  reset,
  handleSubmit,
  submitting,
  dirty,
  invalid,
  galleries,
  galleriesMeta: { galleriesLoading, galleriesError, galleriesRefetch },
  isUpdate,
  initialValues,
  history,
  createGallery,
  updateGallery,
  assetTypeEnums,
  assetTypeIntrospectionMeta: { introspectionLoading, introspectionError }
}) => {
  const text = useMemo(() => pageText(), []);
  const { enqueueSnackbar } = useSnackbar();

  const routeToGalleriesTable = () =>
    history.push({
      pathname: generateLinkPath(paths.admin.settings.galleries)
    });

  const onSubmit = useCallback(
    async data => {
      // grab only data that has changed (as of now, just the 'name')
      const onlyChanged = pickBy(
        data,
        (val, key) => val !== initialValues?.[key]
      );

      const cleanData = {
        ...onlyChanged,
        // for the update case, provide the id as 'galleryId'
        ...(isUpdate ? { galleryId: data.id } : {})
      };

      try {
        await (isUpdate
          ? updateGallery({
              variables: { ...cleanData }
            })
          : createGallery({
              variables: { ...cleanData }
            }));
      } catch (error) {
        enqueueSnackbar(text.errorMessage, {
          variant: 'error'
        });
        await galleriesRefetch();
        reset();
        return;
      }

      enqueueSnackbar(text.successMessage, {
        variant: 'success'
      });

      // take the user back to the galleries table
      routeToGalleriesTable();
    },
    [initialValues, reset, galleriesRefetch]
  );

  const isLoading = galleriesLoading || introspectionLoading;
  const hasError = galleriesError || introspectionError;

  if (isLoading || hasError) {
    return <Loading error={introspectionError || galleriesError} />;
  }

  const availableAssetTypeEnums = getAvailableAssetTypeEnums(
    galleries,
    assetTypeEnums
  );

  const inputs = getGalleryInputs(
    isUpdate,
    isUpdate ? assetTypeEnums : availableAssetTypeEnums
  );

  const breadcrumbPieces = [
    {
      text: t('admin:crumbs.adminDashboard'),
      to: paths.admin.base
    },
    {
      text: t('admin:crumbs.galleries'),
      to: paths.admin.settings.galleries
    },
    {
      text: t('admin:crumbs.createUpdateGallery')
    }
  ];

  return (
    <>
      <BreadcrumbTrail
        sx={theme => ({ marginBottom: theme.spacing(1) })}
        pieces={breadcrumbPieces}
      />
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.content}>
            <form
              autoComplete="off"
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <DynamicForm disabled={false} inputs={inputs} />
              <br />
              <Button
                color="primary"
                disabled={submitting || !dirty || invalid}
                endIcon={submitting && <CircularProgress size={15} />}
                variant="contained"
                type="submit"
              >
                {isUpdate ? text.updateButton : text.createButton}
              </Button>{' '}
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => reset()}
              >
                {text.resetButton}
              </Button>{' '}
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  reset();
                  routeToGalleriesTable();
                }}
              >
                {text.cancelButton}
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'updateGalleryForm'
  }),
  graphql(createGallery, {
    name: 'createGallery'
  }),
  graphql(updateGallery, {
    name: 'updateGallery'
  }),
  graphql(assetTypeIntrospection, {
    name: 'assetTypeIntrospection',
    props: ({ assetTypeIntrospection }) =>
      mapAssetTypeIntrospectionToProps(assetTypeIntrospection)
  }),
  graphql(getGalleries, {
    name: 'getGalleries',
    options: () => ({
      fetchPolicy: 'no-cache'
    }),
    props: ({
      getGalleries: { error, loading, refetch, galleries },
      ownProps: { match }
    }) => {
      const gallery = find(galleries, g => g.id === match.params.galleryId);

      return {
        galleries,
        gallery,
        galleriesMeta: {
          galleriesError: error,
          galleriesLoading: loading,
          galleriesRefetch: refetch
        },
        isUpdate: !!gallery,
        initialValues: gallery
      };
    }
  }),
  withRouter,
  withStyles(styles)
)(AdminGalleryForm);
