import { useRef } from 'react';
import { initial, last } from 'lodash';

export const usePagination = ({
  edges = [],
  resultsPerPage,
  refetchCallback: refetch,
  refetchOptions
}) => {
  // pagination
  // we save an array of cursors so we can go backwards
  // use ref instead of state because state get's wonky when we call it
  // from the child component.
  const previousCursors = useRef([]);

  const navigateNext = () => {
    if (!edges.length) {
      return;
    }
    previousCursors.current = [...previousCursors.current, last(edges).cursor];
    refetch({
      ...refetchOptions,
      // filter: propsToGalleryFilter(props),
      first: resultsPerPage,
      after: last(edges).cursor
    });
  };

  const navigatePrev = () => {
    // remove the last cursor first!
    previousCursors.current = initial(previousCursors.current);

    refetch({
      ...refetchOptions,
      first: resultsPerPage,
      after:
        previousCursors.current.length > 0
          ? last(previousCursors.current)
          : null
    });
  };

  const resetPagination = () => {
    previousCursors.current = [];
  };

  return {
    navigateNext,
    navigatePrev,
    resetPagination
  };
};
