import { useState } from 'react';
import { isEmpty } from 'lodash';

import useProgram from './useProgram';

interface UseHandleStepNavigationProps {
  trackingData: any; // TODO: yo dawg add types
  showValidationErrors: any; // TODO: yo dawg add types
  skipStep: any; // TODO: yo dawg add types
  setSkipStep: any; // TODO: yo dawg add types
}

interface handleNextProps {
  currentStep: number;
  formStepErrors: any; // TODO: yo dawg add types
}

const useHandleStepNavigation = ({
  trackingData,
  showValidationErrors,
  skipStep,
  setSkipStep
}: UseHandleStepNavigationProps) => {
  const {
    programStepper: { stepRefs, selectExactStep }
  } = useProgram();
  const [lockSubmit, setLockSubmit] = useState(false);

  const handleNext = ({ currentStep, formStepErrors }: handleNextProps) => {
    if (isEmpty(formStepErrors)) {
      // If submitting is locked, stop here immediately. This will prevent
      // the race condition of the user clicking the next button twice.
      // When that occurs this component is rendered again with _new_
      // props (i.e. a new selectedStep) and tries to fire again. Checking
      // the lock in state prevents that from occurring.
      if (lockSubmit) {
        return;
      }

      // If we got here, then this is the only or first click. Lock
      // submitting to prevent multi-fire race conditions.
      setLockSubmit(true);

      // Set the next step to currentStepWhenFired + 1 (i.e. the next step).
      selectExactStep(currentStep + 1, trackingData);

      // Scroll to top of section
      stepRefs[currentStep]?.current?.scrollIntoView();

      // tell step skipper we've validated this step
      if (skipStep) {
        setSkipStep({ ...skipStep, currentStep: skipStep.currentStep + 1 });
      }
      // Add a little more buffer - similar to a debounce on a button -
      // before we unlock submitting.
      window.setTimeout(() => {
        setLockSubmit(false);
      }, 250);

      return;
    }

    // if we have errors we give up on skipping
    if (skipStep) {
      setSkipStep(null);
    }

    showValidationErrors();

    // If we got here, unlock submit as well.
    setLockSubmit(false);
  };

  return { handleNext, lockSubmit };
};

export default useHandleStepNavigation;
