import { useState, useMemo } from 'react';
import { flow, keyBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { FormSection, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Button, Tooltip } from '@mui/material';

import { withGlobalContext } from 'src/GlobalContextProvider';

import {
  validateUrl,
  validateIsNumber,
  validateIsInt
} from 'src/common/validations';
import {
  AUTOMATION_ADOPTION_MODAL,
  AUTOMATION_ADOPTION_MODAL_REMIND
} from 'src/common/userSettings';

import Modal from 'src/components/Modal';
import { DynamicForm } from 'src/components/ReduxForm';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { configureInputs } from 'src/components/ReduxForm/helpers';

import { setDismissModal } from './mutations';

// Note: these are here instead of in Constants to prevent a circular dependency b/c this modal is in the label of a switch
// initial values for these are set via src/components/Feature/constants.js:FEATURE_DEFAULTS
const automationAdoptionModalInputs = [
  {
    name: 'startDate',
    displayName: 'Start Date',
    displayMethodId: INPUT_TYPES.DATE_UTC,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'When will this start being active?'
      }
    }
  },
  {
    name: 'modalHeader',
    displayName: 'Modal Header',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Text that shows up at the very top of the modal (optional)'
      }
    }
  },
  {
    name: 'headline',
    displayName: 'Headline',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Header Text that shows up below the image (optional)'
      }
    }
  },
  {
    name: 'body',
    displayName: 'Body',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Text that shows up below headline and image (optional)'
      }
    }
  },
  {
    name: 'callToActionText',
    displayName: 'Call to Action Text',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Text for main button'
      }
    }
  },
  {
    name: 'dismissText',
    displayName: 'Dismiss Text',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Text for dismiss button'
      }
    }
  },
  {
    name: 'remindText',
    displayName: 'Remind Later Text',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Text for remind me later button'
      }
    }
  },
  {
    name: 'remindInterval',
    displayName: 'Remind Later in Hours',
    displayMethodId: INPUT_TYPES.POSITIVE_INT,
    initialValue: '',
    reduxValidations: [validateIsNumber, validateIsInt],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'How many hours do we wait to show modal again',
        endAdornment: 'hours'
      }
    }
  },
  {
    name: 'architectureId',
    displayName: 'Architecture',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Architecture ID of the Automation to navigate to on call to action'
      }
    }
  },
  {
    name: 'productId',
    displayName: 'Product ID',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Product ID of the Automation to navigate to on call to action'
      }
    }
  },
  {
    name: 'promoCodes',
    displayName: 'Promo Code',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    isMultiInput: true,
    blueprintVariable: {
      arrayMaxItems: 1
    },
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Promo Code to auto populate after clicking call to action'
      }
    }
  },
  {
    name: 'mainImage',
    displayName: 'Main Image URL',
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true,
        tooltip: 'Main image that shows in the center of the modal (optional)'
      }
    }
  }
];

const pageText = () => ({
  clearSettingsErrorMessage: t(
    'adminSkinSettings:sections.featuresAutomationAdoptionModalClearError'
  ),
  doneButton: t(
    'adminSkinSettings:sections.featuresAutomationAdoptionModalDone'
  ),
  clearButton: t(
    'adminSkinSettings:sections.featuresAutomationAdoptionModalClear'
  ),
  clearTooltip: t(
    'adminSkinSettings:sections.featuresAutomationAdoptionModalClearTooltip'
  )
});

const ConfigureAutomationAdoptionModal = props => {
  const {
    closeModal,
    globalContext,
    isOpen,
    selectedArchitectureId,
    setDismissModal
  } = props;
  const text = useMemo(() => pageText(), []);
  const [clearSettingsError, setClearSettingsError] = useState(false);

  const architectures = globalContext?.architectures || [];
  const architecturesById = useMemo(() => {
    return keyBy(architectures, 'id');
  }, [architectures]);

  const updatedAutomationAdoptionModalInputs = useMemo(() => {
    const architectureOptions =
      architectures.map(architecture => ({
        name: architecture.name,
        value: architecture.id
      })) || [];

    const productOptions =
      architecturesById?.[selectedArchitectureId]?.products.map(product => ({
        name: product.name,
        value: product.id
      })) || [];

    return configureInputs({
      inputs: automationAdoptionModalInputs,
      enumInputs: {
        architectureId: 'architectureId',
        productId: 'productId'
      },
      enumerationValues: [
        {
          enumeration: 'architectureId',
          values: architectureOptions
        },
        {
          enumeration: 'productId',
          values: productOptions
        }
      ]
    });
  }, [architectures, selectedArchitectureId]);

  const clearSetting = () => {
    setDismissModal({
      variables: {
        updateSettings: {
          [AUTOMATION_ADOPTION_MODAL]: '',
          [AUTOMATION_ADOPTION_MODAL_REMIND]: ''
        },
        updateType: 'DEEP_MERGE'
      }
    })
      .then(() => {
        globalContext.refetch();
        setClearSettingsError(null);
      })
      .catch(() => {
        setClearSettingsError(text.clearSettingsErrorMessage);
      });
  };

  return (
    <>
      <Modal
        fullWidth
        maxWidth="lg"
        open={isOpen}
        showClose
        headerText={t(
          'adminSkinSettings:sections.featuresAutomationAdoptionModalHeader'
        )}
        onClose={closeModal}
        FooterComponent={() => (
          <>
            <Tooltip arrow title={text.clearTooltip}>
              <Button color="primary" variant="outlined" onClick={clearSetting}>
                {text.clearButton}
              </Button>
            </Tooltip>
            {clearSettingsError && <div>{clearSettingsError}</div>}
            <Button color="primary" variant="contained" onClick={closeModal}>
              {text.doneButton}
            </Button>
          </>
        )}
      >
        <FormSection name="automationAdoptionConfig">
          <DynamicForm inputs={updatedAutomationAdoptionModalInputs} />
        </FormSection>
      </Modal>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const formValues = getFormValues(formName)(state);

  const selectedArchitectureId =
    formValues?.evApp?.featureSettings?.automationAdoptionConfig
      ?.architectureId;
  const selectedProductId =
    formValues?.evApp?.featureSettings?.automationAdoptionConfig?.productId;

  return {
    selectedArchitectureId,
    selectedProductId
  };
};

export default flow(
  connect(mapStateToProps),
  graphql(setDismissModal, { name: 'setDismissModal' }),
  withGlobalContext
)(ConfigureAutomationAdoptionModal);
