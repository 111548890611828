import { Box } from '@mui/material';

interface AccordionListWrapperProps {
  children: JSX.Element;
}

const AccordionListWrapper = ({ children }: AccordionListWrapperProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {children}
    </Box>
  );
};

export default AccordionListWrapper;
