import { useQuery } from '@apollo/client';
import { first, isArray } from 'lodash';
import { t } from 'i18next';

import {
  Box,
  Chip,
  Skeleton,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/system';

import Instrumentation from 'src/instrumentation';
import useIsMobile from 'src/hooks/useIsMobile';

import { getQuickstartsForObjectiveFilter } from './queries';
import { formatQuickStartObjectivesForFilters } from './utils';

interface QuickStartFiltersProps {
  setFilter: (value: string) => void;
  showInactive: boolean;
  filter: string[] | '';
  isSupervisable: boolean;
}

const pageText = () => ({
  allObjectivesLabel: t('dashboard:quickStartFilters.allObjectivesLabel'),
  selectInputFilterLabel: t(
    'dashboard:quickStartFilters.selectInputFilterLabel'
  )
});

const SKELETON_WIDTH = 120;
const SKELETON_HEIGHT = 32;

const ChipSkeleton = styled(Skeleton)({
  borderRadius: '16px'
});

const QuickStartFilters = ({
  setFilter,
  filter,
  showInactive,
  isSupervisable
}: QuickStartFiltersProps) => {
  const text = pageText();
  const isMobile = useIsMobile();

  const { data, loading } = useQuery(getQuickstartsForObjectiveFilter, {
    variables: {
      showInactive,
      filter: {
        isSupervisable
      }
    }
  });

  const objectivesBySlug: { [key: string]: string } = {};
  const objectives = data?.activeQuickStarts?.map(
    // after talking with the BE we are not supporting multiple objectives so we can just grab the first one
    quickStart => {
      const qsObjectives = first(quickStart?.product?.blueprint?.objectives);
      objectivesBySlug[qsObjectives?.slug || 'unknown'] =
        qsObjectives?.name || '';
      return qsObjectives?.slug;
    }
  );

  const formattedObjectiveFilters = formatQuickStartObjectivesForFilters(
    objectives,
    objectivesBySlug
  );

  const totalQuickStarts = data?.activeQuickStarts?.length;

  const formatLabel = ({ label, count }: { label: string; count?: number }) => {
    if (!count) return label;
    return `${label} (${count})`;
  };

  const allFilterObjectives = [
    {
      label: text.allObjectivesLabel,
      value: 'all',
      count: totalQuickStarts
    },
    ...formattedObjectiveFilters
  ];

  const changeFilter = (e: SelectChangeEvent) => {
    setFilter(e.target.value);
  };

  if (isMobile)
    return (
      <FormControl fullWidth variant="outlined">
        <InputLabel>{text.selectInputFilterLabel}</InputLabel>
        <Select
          label={text.selectInputFilterLabel}
          value={isArray(filter) ? filter[0] : filter}
          onChange={changeFilter}
        >
          {allFilterObjectives.map(({ label, count, value }) => {
            return (
              <MenuItem value={value}>{formatLabel({ label, count })}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );

  return (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      {loading && (
        <>
          <ChipSkeleton
            variant="rounded"
            width={SKELETON_WIDTH}
            height={SKELETON_HEIGHT}
          />
          <ChipSkeleton
            variant="rounded"
            width={SKELETON_WIDTH}
            height={SKELETON_HEIGHT}
          />
          <ChipSkeleton
            variant="rounded"
            width={SKELETON_WIDTH}
            height={SKELETON_HEIGHT}
          />
        </>
      )}

      {!loading &&
        allFilterObjectives?.map(({ value, label, count }) => {
          const handleFilterClick = () => {
            Instrumentation.logEvent(
              Instrumentation.Events.ClickQuickstartFilter,
              {
                property: 'objective',
                value
              }
            );
            setFilter(value);
          };

          const isSelected = filter === value;

          const formattedLabel = formatLabel({ label, count });
          return (
            <Chip
              data-cy="objective-filter-chip"
              key={label}
              label={formattedLabel}
              onClick={handleFilterClick}
              color={isSelected ? 'primary' : 'default'}
            />
          );
        })}
    </Box>
  );
};

export default QuickStartFilters;
