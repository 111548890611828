import { useState } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import { configureStore, history } from './store';

const AppBase = ({ children, reducers = {}, middleware = [] }) => {
  const [store] = useState(() => configureStore(history, reducers, middleware));

  return (
    <Provider store={store}>
      <Router history={history}>{children}</Router>
    </Provider>
  );
};

export default AppBase;
