import { Box, TypographyVariant } from '@mui/material';

interface ModalHeaderProps {
  icon?: JSX.Element;
  children: string | JSX.Element;
  typographyVariant?: TypographyVariant;
}

const ModalHeader = ({
  icon,
  children,
  typographyVariant = 'h5'
}: ModalHeaderProps) => {
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        gap: 1,
        fontSize: theme => theme?.typography?.[typographyVariant].fontSize,
        fontWeight: 'bold',
        alignItems: 'center'
      }}
    >
      {icon}

      {children}
    </Box>
  );
};

export default ModalHeader;
