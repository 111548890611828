import { SvgIcon } from '@mui/material';

const Outlook = () => {
  return (
    <SvgIcon
      fill="none"
      viewBox="0 0 50 50"
      sx={{ width: '50px', height: '50px' }}
    >
      <g clip-path="url(#a)">
        <path
          fill="#0A2767"
          d="M41.25 25.75a.72.72 0 0 0-.344-.619h-.003l-.014-.007-11.175-6.615a1.5 1.5 0 0 0-1.675 0l-11.175 6.615-.014.007a.728.728 0 0 0 .017 1.247l11.175 6.615a1.5 1.5 0 0 0 1.675 0l11.176-6.614a.72.72 0 0 0 .357-.629Z"
        />
        <path
          fill="#0364B8"
          d="M18.166 21.333H25.5v6.723h-7.334v-6.723ZM39.75 14.5v-3.075A1.394 1.394 0 0 0 38.39 10H19.36A1.393 1.393 0 0 0 18 11.425V14.5l11.25 3 10.5-3Z"
        />
        <path fill="#0078D4" d="M18 14.5h7.5v6.75H18V14.5Z" />
        <path fill="#28A8EA" d="M33 14.5h-7.5v6.75L33 28h6.75v-6.75L33 14.5Z" />
        <path fill="#0078D4" d="M25.5 21.25H33V28h-7.5v-6.75Z" />
        <path fill="#0364B8" d="M25.5 28H33v6.75h-7.5V28Z" />
        <path fill="#14447D" d="M18.166 28.055H25.5v6.111h-7.334v-6.11Z" />
        <path fill="#0078D4" d="M33 28h6.75v6.75H33V28Z" />
        <path
          fill="url(#b)"
          d="m40.907 26.337-.015.008-11.175 6.285a1.557 1.557 0 0 1-.756.234l-.61-.357a1.528 1.528 0 0 1-.15-.087l-11.325-6.463h-.006l-.37-.207v12.723A1.538 1.538 0 0 0 18.047 40h21.68c.013 0 .024-.006.037-.006.18-.011.356-.048.525-.11a1.51 1.51 0 0 0 .211-.109c.05-.029.136-.09.136-.09.385-.285.612-.734.614-1.212V25.75a.675.675 0 0 1-.343.587Z"
        />
        <path
          fill="#0A2767"
          d="M40.65 25.7v.78l-11.685 8.045-12.098-8.563a.008.008 0 0 0-.007-.007l-1.11-.668v-.562l.457-.008.968.555.023.008.082.052s11.37 6.488 11.4 6.503l.435.255c.038-.015.075-.03.12-.045.023-.015 11.287-6.353 11.287-6.353l.128.008Z"
          opacity=".5"
        />
        <path
          fill="#026451"
          d="m40.907 26.337-.015.009-11.175 6.284a1.567 1.567 0 0 1-1.675 0l-11.175-6.284-.014-.009a.675.675 0 0 1-.353-.587v12.723A1.537 1.537 0 0 0 18.046 40h21.657a1.537 1.537 0 0 0 1.547-1.527V25.75a.675.675 0 0 1-.343.587Z"
        />
        <path
          fill="#000"
          d="m29.88 32.538-.167.093a1.555 1.555 0 0 1-.74.24l4.252 5.029 7.417 1.787a1.53 1.53 0 0 0 .47-.587L29.88 32.537Z"
          opacity=".1"
        />
        <path
          fill="#000"
          d="m30.637 32.111-.924.52a1.553 1.553 0 0 1-.74.24l1.992 5.493 9.679 1.321c.381-.286.606-.735.606-1.212v-.164l-10.613-6.197Z"
          opacity=".05"
        />
        <path
          fill="#28A8EA"
          d="M18.067 40h21.634c.333.002.658-.103.926-.3L28.35 32.508a1.517 1.517 0 0 1-.15-.086l-11.325-6.463h-.005l-.37-.209v12.68c0 .866.7 1.57 1.567 1.57Z"
        />
        <path
          fill="#000"
          d="M27 17.375v15.997a1.378 1.378 0 0 1-.863 1.275 1.29 1.29 0 0 1-.51.105H16.5V16.75H18V16h7.628c.757.003 1.37.617 1.372 1.375Z"
          opacity=".1"
        />
        <path
          fill="#000"
          d="M26.25 18.125v15.997c.002.181-.037.36-.113.525-.208.514-.706.85-1.26.853H16.5V16.75h8.378a1.26 1.26 0 0 1 .622.157c.46.232.75.703.75 1.218Z"
          opacity=".2"
        />
        <path
          fill="#000"
          d="M26.25 18.125v14.497A1.385 1.385 0 0 1 24.878 34H16.5V16.75h8.378a1.26 1.26 0 0 1 .622.157c.46.232.75.703.75 1.218Z"
          opacity=".2"
        />
        <path
          fill="#000"
          d="M25.5 18.125v14.497A1.38 1.38 0 0 1 24.128 34H16.5V16.75h7.628c.758 0 1.372.616 1.372 1.374Z"
          opacity=".2"
        />
        <path
          fill="url(#c)"
          d="M10.375 16.75h13.75c.76 0 1.375.616 1.375 1.375v13.75c0 .76-.616 1.375-1.375 1.375h-13.75c-.76 0-1.375-.615-1.375-1.375v-13.75c0-.76.616-1.375 1.375-1.375Z"
        />
        <path
          fill="#fff"
          d="M13.297 22.516a4 4 0 0 1 1.57-1.735 4.748 4.748 0 0 1 2.496-.625 4.418 4.418 0 0 1 2.309.593c.659.393 1.19.969 1.528 1.658a5.35 5.35 0 0 1 .535 2.437 5.647 5.647 0 0 1-.551 2.55 4.056 4.056 0 0 1-1.575 1.715 4.594 4.594 0 0 1-2.396.61 4.515 4.515 0 0 1-2.36-.6 4.024 4.024 0 0 1-1.548-1.661 5.222 5.222 0 0 1-.541-2.409 5.801 5.801 0 0 1 .533-2.533Zm1.674 4.073c.18.456.487.852.883 1.141.404.282.888.428 1.38.415a2.42 2.42 0 0 0 1.473-.428c.392-.289.69-.686.858-1.142a4.44 4.44 0 0 0 .274-1.592 4.849 4.849 0 0 0-.258-1.613 2.55 2.55 0 0 0-.83-1.19 2.282 2.282 0 0 0-1.461-.45 2.38 2.38 0 0 0-1.412.42c-.404.29-.716.69-.9 1.151a4.575 4.575 0 0 0-.006 3.289l-.001-.002Z"
        />
        <path fill="#50D9FF" d="M33 14.5h6.75v6.75H33V14.5Z" />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1="28.875"
          x2="28.875"
          y1="25.75"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#35B8F1" />
          <stop offset="1" stop-color="#28A8EA" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="11.866"
          x2="22.634"
          y1="15.676"
          y2="34.324"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1784D9" />
          <stop offset=".5" stop-color="#107AD5" />
          <stop offset="1" stop-color="#0A63C9" />
        </linearGradient>
        <clipPath id="a">
          <path fill="#fff" d="M9 10h32.25v30H9z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Outlook;
