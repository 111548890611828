const Video = ({ videoRef, ...props }) => {
  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef} {...props}>
      No support for this type of video
    </video>
  );
};

export default Video;
