import { t } from 'i18next';
import { useMemo } from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { ALL_CHANNEL_SPEND_ALLOCATION_CONFIG_TYPES } from './constants';

const pageText = () => ({
  configTypeSelector: t('adminChannelSpendAllocation:configTypeSelector')
});

const ChannelSpendAllocationConfigTypeSelector = props => {
  const { setConfigType, selectedConfigType } = props;

  const text = useMemo(() => pageText(), []);

  const handleConfigTypeSelected = event => {
    setConfigType(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      data-cy="channel-spend-allocation-settings-config-type-selector"
    >
      <InputLabel id="channel-spend-allocation-config-type-selector-label">
        {text.configTypeSelector}
      </InputLabel>
      <Select
        labelId="channel-spend-allocation-config-type-selector-label"
        id="channel-spend-allocation-config-type-selector"
        value={selectedConfigType}
        label={text.configTypeSelector}
        onChange={handleConfigTypeSelected}
      >
        {ALL_CHANNEL_SPEND_ALLOCATION_CONFIG_TYPES.map(configType => (
          <MenuItem value={configType.slug} key={configType.slug}>
            {configType.friendlyName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ChannelSpendAllocationConfigTypeSelector;
