import { createAction } from 'redux-actions';

export const actionConstants = {
  selectOrganization: createAction('ADMIN_IMPERSONATE_SELECT_ORGANIZATION'),
  selectUser: createAction('ADMIN_IMPERSONATE_SELECT_USER'),
  selectOffice: createAction('ADMIN_IMPERSONATE_SELECT_OFFICE'),
  resetForm: createAction('ADMIN_IMPERSONATE_RESET_FORM')
};

export const selectOrganization = org => dispatch => {
  dispatch(actionConstants.selectOrganization(org));
};

export const selectUser = user => dispatch => {
  dispatch(actionConstants.selectUser(user));
};

export const selectOffice = office => dispatch => {
  dispatch(actionConstants.selectOffice(office));
};

export const resetForm = () => dispatch => {
  dispatch(actionConstants.resetForm());
};
