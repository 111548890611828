import { gql } from 'src/generated/gql';

export const createFacebookConfiguration = gql(`
  mutation createFacebookConfiguration(
    $clientId: String
    $clientSecret: String
    $businessManagerId: String
    $adminToken: String
    $botToken: String
    $botUserId: String
  ) {
    createFacebookConfiguration(
      clientId: $clientId
      clientSecret: $clientSecret
      businessManagerId: $businessManagerId
      adminToken: $adminToken
      botUserId: $botUserId
      botToken: $botToken
    ) {
      id
      businessManagerId
      botUserId
      createdAt
      updatedAt
      isActive
    }
  }
`);

export const createOrganizationDefaultFacebookPageSettings = gql(`
  mutation createOrganizationDefaultFacebookPageSettings(
    $pageId: String!
    $pageName: String!
    $facebookDisplayName: String!
    $facebookUserId: String!
  ) {
    createOrganizationDefaultFacebookPageSettings(
      pageId: $pageId
      pageName: $pageName
      facebookDisplayName: $facebookDisplayName
      facebookUserId: $facebookUserId
    ) {
      id
      userId
      groupId
      pageId
      pageName
      facebookUserId
      facebookDisplayName
      instagramId
      hasAcceptedTos
      pageStatus
      businessManagerId
    }
  }
`);

export const setActiveFacebookConfiguration = gql(`
  mutation setActiveFacebookConfiguration($facebookConfigId: Long!) {
    setActiveFacebookConfiguration(facebookConfigId: $facebookConfigId)
  }
`);

export const createOrUpdateSkinSettings = gql(`
  mutation CreateOrUpdateSkinSettings(
    $updateSettings: JSON!
    $updateType: SettingsUpdateType!
  ) {
    createOrUpdateSkinSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      previousSettings
      newSettings
      updateType
    }
  }
`);
