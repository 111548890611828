import { SVGProps } from 'react';

const JCB = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="27"
      height="20"
      viewBox="0 0 41 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.1652 15.6068C31.2505 15.6304 32.3419 15.5589 33.4228 15.644C34.5175 15.8482 34.7813 17.5054 33.8088 18.0481C33.1452 18.4056 32.357 18.1813 31.6376 18.2443H30.1652V15.6068ZM34.0503 12.6215C34.2914 13.4726 33.471 14.2367 32.6514 14.1195H30.1652C30.1827 13.3169 30.1312 12.4458 30.1905 11.6855C31.1864 11.7135 32.1916 11.6283 33.1817 11.7301C33.6072 11.837 33.9632 12.1867 34.0503 12.6215ZM40.0335 0.000547538C40.0797 1.62586 40.0402 3.33707 40.0534 4.99535C40.0504 11.7373 40.0599 18.4791 40.0481 25.2214C40.0045 27.7481 37.7652 29.9437 35.256 29.9941C32.7443 30.0043 30.2323 29.9957 27.7203 29.9985V19.806C30.4572 19.7919 33.1956 19.8347 35.9314 19.7847C37.2006 19.705 38.5907 18.8676 38.6498 17.4709C38.7992 16.0682 37.4768 15.098 36.2212 14.9448C35.7385 14.9322 35.7526 14.8041 36.2212 14.7482C37.4187 14.4893 38.3591 13.2499 38.0068 12.0086C37.7065 10.703 36.2635 10.1977 35.0633 10.2002C32.616 10.1836 30.1682 10.1979 27.7208 10.1931C27.7366 8.29028 27.6879 6.38543 27.7471 4.48402C27.941 2.00291 30.2367 -0.0431944 32.7106 0.00119727C35.1519 0.00082579 37.5928 0.000826045 40.0335 0.000733175V0.000547538Z"
        fill="url(#paint0_linear_1095_9877)"
      />
      <path
        d="M0.0650963 4.74145C0.127598 2.21874 2.37644 0.0412224 4.88243 0.00435316C7.3848 -0.00335502 9.88755 0.00323915 12.3899 0.00101028C12.383 8.44147 12.4035 16.8829 12.3796 25.3228C12.2832 27.8149 10.0589 29.9509 7.5802 29.995C5.07309 30.0041 2.5657 29.9963 0.0585026 29.9988V19.4628C2.49382 20.0381 5.04765 20.283 7.53209 19.9014C9.01717 19.6624 10.642 18.9331 11.1448 17.3924C11.5148 16.0745 11.3065 14.6875 11.3616 13.3349V10.1935H7.06199C7.04267 12.2711 7.10155 14.3523 7.03088 16.4274C6.91497 17.7029 5.65185 18.5133 4.44891 18.47C2.95706 18.4858 0.000736822 17.3888 0.000736822 17.3888C-0.00669275 13.4963 0.0440149 8.62145 0.0650963 4.74182V4.74145Z"
        fill="url(#paint1_linear_1095_9877)"
      />
      <path
        d="M13.9935 11.4606C13.7672 11.5086 13.948 10.6897 13.8902 10.379C13.9056 8.41483 13.858 6.4485 13.9165 4.4857C14.1098 1.9941 16.4231 -0.0571084 18.9071 0.00121377H26.2223C26.2154 8.44167 26.2357 16.8831 26.2119 25.323C26.1155 27.8151 23.8909 29.951 21.4122 29.9952C18.905 30.0047 16.3975 29.9967 13.8901 29.9991V18.4554C15.6025 19.8605 17.9299 20.0792 20.0633 20.083C21.6716 20.0824 23.2706 19.8345 24.8325 19.4635V17.3487C23.0723 18.226 21.0032 18.7832 19.052 18.2792C17.6909 17.9404 16.7028 16.625 16.7248 15.2203C16.5672 13.7596 17.4234 12.2174 18.8589 11.7831C20.6412 11.2252 22.5836 11.6519 24.2543 12.3773C24.6122 12.5647 24.9755 12.7972 24.8324 12.1989V10.5366C22.0383 9.87186 19.0648 9.62725 16.2577 10.3504C15.4452 10.5796 14.6536 10.9272 13.9935 11.4608V11.4606Z"
        fill="url(#paint2_linear_1095_9877)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1095_9877"
          x1="27.6361"
          y1="14.3581"
          x2="39.9757"
          y2="14.3581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007B40" />
          <stop offset="1" stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1095_9877"
          x1="-0.203864"
          y1="15.2872"
          x2="12.1877"
          y2="15.2872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1D2970" />
          <stop offset="1" stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1095_9877"
          x1="13.872"
          y1="14.6494"
          x2="26.2199"
          y2="14.6494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6E2B2F" />
          <stop offset="1" stopColor="#E30138" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default JCB;
