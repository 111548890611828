import withTheme from '@mui/styles/withTheme';

const AutomatedProgramsGetStartedIcon = props => {
  const { active, completed, theme } = props;

  const primary =
    active || completed ? theme.palette.primary.main : theme.palette.grey[300];
  const outline =
    active || completed ? theme.palette.grey[900] : theme.palette.grey[300];

  return (
    <svg viewBox="0 0 24 24" width="72" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M5.168 3.204h18.707V0H5.168v3.204zm.62-.62h17.467V.62H5.788v1.964zM0 3.204h3.204V0H0v3.204zm.62-.62h1.964V.62H.62v1.964z"
          fill={outline}
        />
        <path
          fill={primary}
          d="M5.478 8.062h18.087V5.478H5.478zM.311 8.062h2.584V5.478H.311z"
        />
        <path
          d="M5.168 13.539h18.707v-3.204H5.168v3.204zm.62-.62h17.467v-1.964H5.788v1.964zM0 13.539h3.204v-3.204H0v3.204zm.62-.62h1.964v-1.964H.62v1.964z"
          fill={outline}
        />
        <path
          fill={primary}
          d="M5.478 18.523h18.087v-2.584H5.478zM.311 18.523h2.584v-2.584H.311z"
        />
        <path
          d="M5.168 24h18.707v-3.203H5.168V24zm.62-.62h17.467v-1.964H5.788v1.964zM0 24h3.204v-3.203H0V24zm.62-.62h1.964v-1.964H.62v1.964z"
          fill={outline}
        />
      </g>
    </svg>
  );
};

export default withTheme(AutomatedProgramsGetStartedIcon);
