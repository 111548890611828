import { flow, find, sortBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';

import CatalogDetails from './CatalogDetails';

import { getArchitectureAndCatalog, getContentTableNames } from './queries';

const styles = theme => ({
  catalogWrap: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  architectureSelector: {
    marginBottom: theme.spacing(2),
    maxWidth: '350px'
  }
});

const AdminCatalogContentSettings = ({
  match,
  architectureAndCatalog,
  getContentTableNames: { loading: tableNamesLoading, contentTableMetadata }
}) => {
  const {
    loading,
    error,
    architecture: selectedArchitecture,
    refetch
  } = architectureAndCatalog;

  const selectedCatalog = find(selectedArchitecture?.catalogs ?? [], [
    'id',
    match?.params?.catalogId
  ]);

  if (loading || tableNamesLoading || error) {
    return <Loading error={error} />;
  }

  // some meta for our dropdowns
  const tableNames = sortBy(contentTableMetadata?.contentTableNames || []);

  return (
    <>
      <Heading
        title={t('admin:catalogContent.heading')}
        subTitle={
          <>
            {t('admin:catalogContent.headingArchitecture')}{' '}
            {selectedArchitecture?.name}
          </>
        }
        pageTitle={t('admin:catalogContent.heading')}
        divider
      />

      <Typography variant="h5" style={{ marginTop: '20px' }}>
        <Trans i18nKey="admin:catalogContent.headingCatalog" />{' '}
        {selectedCatalog?.friendlyName}
      </Typography>

      <div>
        {selectedArchitecture && (
          <>
            <CatalogDetails
              catalog={selectedCatalog}
              key={selectedCatalog.id}
              refetch={refetch}
              tableNames={tableNames}
              selectedArchitecture={selectedArchitecture}
            />
          </>
        )}
      </div>
    </>
  );
};

export default flow(
  graphql(getContentTableNames, {
    name: 'getContentTableNames'
  }),
  graphql(getArchitectureAndCatalog, {
    name: 'architectureAndCatalog',
    options: props => ({
      variables: {
        architectureId: props?.match?.params?.architectureId
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    })
  }),
  withRouter,
  withStyles(styles)
)(AdminCatalogContentSettings);
