import { gql } from 'src/generated/gql';

export const initiateStripeOnBoardingOauth = gql(`
  mutation InitiateStripeConnectOnBoardingOauth {
    initiateStripeOnBoardingOauth {
      link
    }
  }
`);

export const processStripeConnectOauthCode = gql(`
  mutation ProcessStripeConnectOauthCode($stripeOauthCode: String!) {
    processStripeConnectOauthCode(stripeOAuthCode: $stripeOauthCode) {
      accountId
      accountName
      createdAt
      updatedAt
      completedAt
      connectedStatus
    }
  }
`);

export const upsertOktaConfig = gql(`
  mutation upsertOktaConfig($input: CreateOktaDirectSSOConfigInput!) {
    upsertOktaConfig(input: $input) {
      id
      clientId
      clientSecret
      authServerFqdn
    }
  }
`);

export const authorizeTikTokAdvertisers = gql(`
  mutation authorizeTikTokAdvertisers($authCode: String!) {
    authorizeTikTokAdvertisers(authCode: $authCode) {
      success
    }
  }
`);
