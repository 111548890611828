import { Box, Button, SxProps } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  DataGridPro,
  GridAlignment,
  GridRowSelectionModel
} from '@mui/x-data-grid-pro';
import { isEmpty, pickBy, startCase } from 'lodash';
import type { GridFilterPanelProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel';
import { useSnackbar } from 'notistack';
import Toolbar from 'src/components/DataTable/Toolbar/Toolbar';
import { t } from 'i18next';

import useProgram from 'src/pages/Program/utils/useProgram';
import Modal from 'src/components/Modal';
import Instrumentation from 'src/instrumentation';

import {
  LOCATIONS_OVERRIDES_BY_ID_NAME,
  locationsFilterOperators
} from './utils';

type SelectLocationsModalProps = {
  open: boolean;
  closeSelectLocationsModal: () => void;
  selectedLocations: string[];
  setSelectedLocations: (ids: string[]) => void;
};

const SelectLocationsModal = ({
  open,
  closeSelectLocationsModal,
  selectedLocations,
  setSelectedLocations
}: SelectLocationsModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setValue } = useFormContext();
  const locationOverrides = useWatch({
    name: LOCATIONS_OVERRIDES_BY_ID_NAME,
    defaultValue: {}
  });
  const initialSelectedLocations = useRef<string[]>([]);

  const {
    allMlpLocations: { loading, error, data }
  } = useProgram();

  const rowSelectionModel = selectedLocations;

  const setSelection = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedLocations(rowSelectionModel as string[]);

    // If location with overrides has been removed from selection, remove override!
    const newLocationOverrides = pickBy(locationOverrides, (_, locationId) => {
      return rowSelectionModel.includes(locationId);
    });

    setValue(LOCATIONS_OVERRIDES_BY_ID_NAME, newLocationOverrides);
  };

  const onCloseSelectLocationsModal = () => {
    setSelectedLocations(initialSelectedLocations.current);
    initialSelectedLocations.current = [];
    closeSelectLocationsModal();
  };

  useEffect(() => {
    if (open && !isEmpty(selectedLocations)) {
      initialSelectedLocations.current = selectedLocations;
    }
  }, [open]);

  /* == Locations Meta (hardcoding until we have propper meta) == */

  const columnHeaders = [
    'name',
    'street',
    'region',
    'city',
    'postalCode',
    'websiteUrl'
  ];

  const locationsFieldMetaData = columnHeaders.map(field => {
    return {
      align: 'left' as GridAlignment,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field,
      filterable: true,
      flex: 2,
      headerAlign: 'left' as GridAlignment,
      headerName: startCase(field),
      hideable: true,
      sortable: true,
      type: 'string',
      id: field,
      displaySortOrder: 0,
      filterOperators: locationsFilterOperators
    };
  });

  if (error) {
    enqueueSnackbar('Error loading locations', { variant: 'error' });
  }

  const rows = data?.locations?.edges?.map(edge => ({ ...edge?.node })) || [];
  const onSelectLocations = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickSelectLocationsConfirm,
      { locationIds: selectedLocations }
    );

    closeSelectLocationsModal();
  };

  return (
    <Modal
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={onCloseSelectLocationsModal}
      data-cy="mlp-select-locations-modal"
      headerText={t('programCreate:manageMultiLocation.selectLocationsHeader')}
      FooterComponent={
        <>
          <Button
            disabled={selectedLocations?.length < 1}
            variant="contained"
            onClick={onSelectLocations}
            data-cy="select-locations-confirm"
          >
            {t('programCreate:manageMultiLocation.selectLocationsCta')}
          </Button>
        </>
      }
    >
      <Box sx={{ pb: 2 }}>
        {!error && (
          <DataGridPro
            sx={{
              height: '100%',
              minHeight: '100px',
              maxHeight: '684px',
              padding: '0',
              width: '100%',
              '& .MuiDataGrid-virtualScroller': { minHeight: '50px' }
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            edit={false}
            columns={locationsFieldMetaData}
            rows={rows}
            pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field] }}
            pagination
            checkboxSelection
            disableRowSelectionOnClick
            rowSelection
            onRowSelectionModelChange={setSelection}
            rowSelectionModel={rowSelectionModel}
            loading={loading}
            slots={{ toolbar: Toolbar }}
            slotProps={{
              filterPanel: {
                filterFormProps: {
                  'data-cy': 'filterForm',
                  logicOperatorInputProps: {
                    size: 'small',
                    sx: { display: 'none' }
                  },
                  columnInputProps: {
                    'data-cy': 'columnInput',
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  operatorInputProps: {
                    size: 'small',
                    sx: { mt: 'auto' }
                  },
                  valueInputProps: {
                    'data-cy': 'valueInput',
                    InputComponentProps: {
                      size: 'small'
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormColumnInput': { mr: 2 },
                    '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 }
                  }
                } as GridFilterPanelProps['filterFormProps'] & { sx: SxProps }
              }
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default SelectLocationsModal;
