import { Button } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import Modal, { ModalHeader } from 'src/components/Modal';

interface OrderTypeConfirmationModalProps {
  open: boolean;
  onClose: () => void;
}

const OrderTypeConfirmationModal = ({
  open,
  onClose
}: OrderTypeConfirmationModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      HeaderComponent={
        <ModalHeader>
          {t(
            'programCreate:configureMultiLocation.orderTypeConfirmationModalTitle'
          )}
        </ModalHeader>
      }
      FooterComponent={
        <Button onClick={onClose} variant="contained">
          {t(
            'programCreate:configureMultiLocation.orderTypeConfirmationModalConfirm'
          )}
        </Button>
      }
    >
      <Trans i18nKey="programCreate:configureMultiLocation.orderTypeConfirmationModalMessage" />
    </Modal>
  );
};

export default OrderTypeConfirmationModal;
