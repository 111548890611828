import { useState } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { t } from 'i18next';

import { Box, Button } from '@mui/material';

import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';
import { ThemeAugmentations } from 'src/AppThemeWrapper';

export interface CheckoutFormProps {
  handleClose: () => void;
  disabled?: boolean;
  inline?: boolean;
  onStripeReady?: () => void;
  handleAddCard: () => void;
}

const CheckoutForm = (props: CheckoutFormProps) => {
  const {
    handleClose,
    handleAddCard,
    inline = false,
    onStripeReady,
    disabled
  } = props;

  const isProgramCreatePage = useIsProgramCreatePage();

  const [disableAddCard, setDisableAddCard] = useState(true);

  const cardFailed = () => {
    setDisableAddCard(true);
  };

  const cardSuccess = () => {
    setDisableAddCard(false);
    if (inline) {
      handleAddCard();
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            maxWidth: `400px`,
            p: 2,
            ...(isProgramCreatePage
              ? {
                  p: theme => theme.spacing(2, 0, 0, 0),
                  maxHeight: inline ? '47px' : '100%'
                }
              : {})
          }}
        >
          <Box
            sx={
              isProgramCreatePage && {
                borderBottom: theme =>
                  `1px solid ${
                    (theme as unknown as ThemeAugmentations).palette
                      .inputBorderColor
                  }`,
                pb: '6px'
              }
            }
          >
            <CardElement
              onChange={({ complete }) => {
                return complete ? cardSuccess() : cardFailed();
              }}
              onReady={onStripeReady}
              options={{
                disabled,
                disableLink: true,
                ...(isProgramCreatePage && {
                  style: {
                    base: {
                      fontSize: '16px',
                      lineHeight: '24px',
                      color: 'rgba(0, 0, 0, 0.87)',
                      '::placeholder': {
                        color: 'rgba(0, 0, 0, 0.6)'
                      }
                    }
                  }
                })
              }}
            />
          </Box>

          <br />
        </Box>

        {!inline && (
          <>
            <Button onClick={handleClose} className="t-CheckoutButton">
              {t('programCreate:CheckoutForm.cancelButton')}
            </Button>

            <Button
              className="t-CheckoutButton"
              variant="contained"
              color="primary"
              onClick={handleAddCard}
              disabled={disableAddCard}
              data-cy="add-card-button-checkout-form"
            >
              {t('programCreate:CheckoutForm.addCardButton')}
            </Button>
          </>
        )}
      </Box>
    </>
  );
};

export default CheckoutForm;
