import {
  FacebookIcon,
  Tiktok as TiktokIcon,
  GoogleIcon
} from 'src/components/Icons';

export const channelCodes = {
  facebook: 'fb',
  google: 'google',
  tiktok: 'tiktok',
  instagram: 'ig'
} as const;

export const channelFriendlyName = {
  [channelCodes.facebook]: 'Facebook',
  [channelCodes.google]: 'Google',
  [channelCodes.tiktok]: 'TikTok'
} as const;

export const channelIcon = {
  [channelCodes.facebook]: <FacebookIcon />,
  [channelCodes.google]: <GoogleIcon />,
  [channelCodes.tiktok]: <TiktokIcon />
} as const;

export const facebookCreativeTypes = {
  singleImage: 'single-image',
  video: 'video',
  videoUpload: 'video-upload-from-url',
  multiProductCarousel: 'multi-product-carousel',
  dynamicCreativeOptimization: 'dynamic-creative-optimization',
  dynamicAdCreative: 'dynamic-ad-creative',
  singleProductCarousel: 'single-product-carousel',
  // new preview api creative types
  fbSingleImage: 'fb_single_image',
  fbSingleVideo: 'fb_single_video',
  fbCarousel: 'fb_carousel',
  fbSingleProductCarousel: 'fb_single_product_carousel',
  fbDareCarousel: 'fb_dare_carousel',
  fbDco: 'fb_dco'
} as const;

export const adCreativeTypes = {
  fbMultiImage: 'fb-multi-product-carousel',
  fbSingleImage: 'fb-single-image',
  fbVideo: 'fb-video',
  fbDynamicCreative: 'fb-dynamic-ad-creative'
} as const;

export const channelTypes = {
  audienceNetwork: 'fan', // facebook audience network
  facebook: 'fb',
  google: 'google',
  instagram: 'ig',
  messenger: 'msgr',
  tiktok: 'tiktok'
} as const;

export type Channel = (typeof channelTypes)[keyof typeof channelTypes];

export const googleCreativeTypes = {
  responsiveDisplayAd: 'responsive-display-ad',
  responsiveSearchAd: 'responsive-search-ad',
  // new preview api creative types
  googleResponsiveDisplay: 'google_responsive_display',
  googleSearch: 'google_search',
  googleDiscoveryMultiAsset: 'google_discovery_multi_asset'
} as const;

export const tiktokCreativeType = {
  tiktokSingleVideo: 'tiktok_single_video'
} as const;

export const googleCreativeTypesBySlug = Object.keys(
  googleCreativeTypes
).reduce((accum, key) => {
  return {
    ...accum,
    [(googleCreativeTypes as Record<string, string>)[key]]: key
  };
}, {});

export const allCreativeTypes = {
  ...googleCreativeTypes,
  ...facebookCreativeTypes,
  ...tiktokCreativeType
} as const;
