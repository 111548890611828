import {
  DynamicInputConfig,
  INPUT_TYPES
} from 'src/components/ReduxForm/DynamicForm/constants';
import {
  validateStringContains,
  validateRequired,
  validateEmail
} from 'src/common/validations';
import {
  EVOCALIZE_EMAIL_DOMAIN,
  EVOCALIZE_EMAIL_VALIDATION_MESSAGE
} from './Constants';

const getEmailValidations = (isEVUser: boolean) => {
  let validations = [validateEmail()];

  if (isEVUser)
    validations = [
      ...validations,
      validateStringContains(
        EVOCALIZE_EMAIL_DOMAIN,
        EVOCALIZE_EMAIL_VALIDATION_MESSAGE
      )
    ];

  return validations;
};

// This is here just in case we got the autocomplete route with the org group input
// const getGroupOptions = groups => {
//   return groups.map(group => ({ name: group.name, value: group.externalId }));
// };

export const createUserInputs = (isEVUser: boolean, options: any) => {
  // const userTypeOptions = getUserTypeOptions(isEvocalizeOrg);
  const emailValidations = getEmailValidations(isEVUser);

  let inputs: DynamicInputConfig[] = [
    {
      name: 'name',
      displayName: 'Full Name',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'email',
      displayName: 'Email',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: emailValidations,
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  ];

  if (isEVUser) {
    inputs = [
      ...inputs,
      {
        name: 'isSuperAdmin',
        displayName: 'Super Admin',
        displayMethodId: INPUT_TYPES.ANY_BOOLEAN,
        initialValue: false,
        reduxValidations: [],
        isRequired: false,
        isHidden: false,
        displaySortOrder: 1,
        displayParameters: {
          inputData: {
            columnWidth: 1,
            tooltip:
              'When this toggle is set to true, an admin user will be created.'
          }
        }
      }
    ];
  }

  if (!isEVUser) {
    inputs = [
      ...inputs,
      {
        name: 'externalGroupId',
        displayName: 'Organization Group Id',
        displayMethodId: INPUT_TYPES.SINGLE_SELECT,
        initialValue: '',
        reduxValidations: [validateRequired],
        isRequired: true,
        isHidden: isEVUser,
        displayParameters: {
          inputData: {
            isAutocomplete: true,
            columnWidth: 2,
            ...(options && { options })
          }
        }
      }
    ];
  }

  return inputs;
};
