import { createAction } from 'redux-actions';

export const audienceConstants = {
  clearUploadStatus: createAction('CLEAR_AUDIENCE_UPLOAD_STATUS'),
  removeUploadStatus: createAction('REMOVE_AUDIENCE_UPLOAD_STATUS'),
  setEmailCount: createAction('SET_EMAIL_COUNT'),
  setFileNotReady: createAction('SET_FILE_NOT_READY'),
  setFileReady: createAction('SET_FILE_READY'),
  setUploadStatus: createAction('SET_AUDIENCE_UPLOAD_STATUS')
};

export const setUploadStatus = statusObject => dispatch => {
  dispatch(
    audienceConstants.setUploadStatus({
      statusObject
    })
  );
};

export const removeUploadStatus = assetId => dispatch => {
  dispatch(
    audienceConstants.removeUploadStatus({
      assetId
    })
  );
};

export const clearUploadStatus = () => dispatch => {
  dispatch(audienceConstants.clearUploadStatus());
};

export const setEmailCount = (validCount, invalidCount) => dispatch => {
  dispatch(
    audienceConstants.setEmailCount({
      invalidCount,
      validCount
    })
  );
};

export const setFileReady = () => dispatch => {
  dispatch(audienceConstants.setFileReady());
};

export const setFileNotReady = error => dispatch => {
  dispatch(
    audienceConstants.setFileNotReady({
      error
    })
  );
};
