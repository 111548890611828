import i18n from 'i18next';
import ICU from 'i18next-icu';
import Pseudo from 'i18next-pseudo';
import resourcesToBackend from 'i18next-resources-to-backend';

import { en } from './locales';

export const DEFAULT_LOCALE = 'en';
export const localStorageLocaleKey = 'evI18nLocale';

// Use this for default language detection.
// import LanguageDetector from 'i18next-browser-languagedetector';

const nsMap: Record<string, number> = {};

[en].forEach(locale => {
  Object.keys(locale).forEach(namespace => {
    nsMap[namespace] = 1;
  });
});

const namespaces = Object.keys(nsMap);

const options = {
  interpolation: {
    escapeValue: false // not needed for react!!
  },

  // https://github.com/i18next/i18next/discussions/1866
  returnNull: false,

  // Note: Testing for another language. Later we can extend this to be
  //       driven by config/build-time env variables, etc.
  // lng: 'de',
  lng: window.localStorage.getItem(localStorageLocaleKey) || DEFAULT_LOCALE,

  // loading in our en translations to start
  resources: { en },
  ns: namespaces,
  fallbackLng: DEFAULT_LOCALE,
  defaultNS: 'common',
  // We need this so some resources or no resources can be set on initialization while others can be loaded using a backend connector.
  partialBundledLanguages: true,

  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },

  postProcess: ['pseudo'],

  // Use these to debug missing translations
  debug: true,
  saveMissing: true
} as const;

// Right now we'll only enable this if localstorage says it should be.
// The value in localstorage must be 'true' for this to work. Any non-'true' value will
// turn pseudo-loc off.
// NOTE: in the future we should change the languageToPseudo to something like
// '00' or 'zz' or another non-locale value so that we can enable this without
// messing with localstorage.
let isPseudoLocEnabled = false;
try {
  isPseudoLocEnabled = localStorage.getItem('pseudoLoc') === 'true';
} catch (e) {
  // Ignored. If localStorage is disabled, we'll just use the default
}

// Leaving other functionality commented out for future reference:
// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(
    new Pseudo({
      enabled: isPseudoLocEnabled,
      languageToPseudo: 'en',
      letterMultiplier: 4
    })
  )
  .use(new ICU())
  .use(
    resourcesToBackend(
      (language: any, namespace: any) =>
        import(`./locales/${language}/${namespace}.json`)
    )
  )
  .init(options);

export default i18n;
