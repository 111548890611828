import { useMemo, useState } from 'react';
import { first, isEmpty } from 'lodash';
import ReactDiffViewer from 'react-diff-viewer';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import {
  Alert,
  Button,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import Modal from 'src/components/Modal';
import { sortKeys } from '../helpers';
import { getProductByProductId } from '../queries';
import DocumentVersionSelector from '../DocumentVersionSelector';

const pageText = () => ({
  modalHeader: t('admin:blueprintBuilder.rollbackModal.header'),
  emptyState: t('admin:blueprintBuilder.rollbackModal.emptyState'),
  leftTitle: t('admin:blueprintBuilder.rollbackModal.leftDiffTitle'),
  rightTitle: t('admin:blueprintBuilder.rollbackModal.rightDiffTitle'),
  errorMessage: t('admin:blueprintBuilder.rollbackModal.error')
});

const RollbackModal = props => {
  const {
    currentDocument,
    isPublishing,
    onPublish,
    rollbackModalOpen,
    setRollbackModalClose
  } = props;
  const text = useMemo(() => pageText(), []);

  const [selectedVersionId, setSelectedVersionId] = useState('');
  const hasSelectedId = !isEmpty(selectedVersionId);

  const handleSetSelectVersion = id => {
    setSelectedVersionId(id);
  };

  const {
    loading: selectedDocumentLoading,
    data,
    error: selectedDocumentError
  } = useQuery(getProductByProductId, {
    skip: !hasSelectedId,
    fetchPolicy: 'no-cache',
    variables: {
      documentId: selectedVersionId,
      productId: currentDocument?.productId,
      limit: 1
    }
  });

  const selectedDocument = data?.productDocumentVersions
    ? first(data?.productDocumentVersions)
    : {};

  // Note: Sorting these keys so that the diff works correctly redux form and the
  //       object that the we compare can have properties out of order
  const currentDocumentState = currentDocument ? sortKeys(currentDocument) : {};
  const selectedDocumentState = selectedDocument
    ? sortKeys(selectedDocument)
    : {};

  const handleClose = () => {
    setSelectedVersionId('');
    setRollbackModalClose();
  };

  return (
    <Modal
      open={rollbackModalOpen}
      headerText={text.modalHeader}
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      FooterComponent={() => (
        <>
          <Button onClick={handleClose}>Close</Button>
        </>
      )}
    >
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ padding: theme => theme.spacing(2) }}
        >
          <DocumentVersionSelector
            productId={currentDocument?.productId}
            currentDocumentId={currentDocument?.id}
            setSelected={id => handleSetSelectVersion(id)}
          />
        </Box>

        {selectedDocumentError && (
          <Alert
            severity="error"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {text.errorMessage}
          </Alert>
        )}

        {selectedDocumentLoading ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {hasSelectedId ? (
              <ReactDiffViewer
                oldValue={JSON.stringify(currentDocumentState, null, 2)}
                newValue={JSON.stringify(selectedDocumentState, null, 2)}
                splitView
                disableWordDiff
                leftTitle={text.leftTitle}
                rightTitle={text.rightTitle}
              />
            ) : (
              <Typography
                sx={{
                  padding: theme => theme.spacing(2),
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%'
                }}
                variant="h6"
              >
                {text.emptyState}
              </Typography>
            )}
          </>
        )}

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop={3}
          marginBottom={3}
        >
          <LoadingButton
            disabled={!hasSelectedId || isPublishing || selectedDocumentLoading}
            loading={selectedDocumentLoading || isPublishing}
            color="primary"
            variant="contained"
            onClick={() =>
              onPublish({
                isDraft: false,
                rollbackData: selectedDocumentState
              })
            }
          >
            Rollback
          </LoadingButton>
        </Box>
      </>
    </Modal>
  );
};

export default RollbackModal;
