import Tracker from 'src/common/Tracker';
import { Events } from './constants';

const initialize = settings => {
  Tracker.initialize(settings);
};

const setUserProperties = () => {};

const logEvent = (event, eventProperties) => {
  switch (event) {
    case Events.QuickStartClicked:
      Tracker.trackQuickStart(eventProperties);
      break;

    case Events.DeleteAudienceClicked:
      Tracker.trackAudiences({
        action: 'delete'
      });
      break;

    case Events.AddAudienceClicked:
      Tracker.trackAudiences({
        action: 'add'
      });
      break;

    case Events.HandleUserLeadPreferencesClicked:
      Tracker.trackNotificationPreferences(eventProperties);
      break;

    case Events.BillingHistoryClicked:
      Tracker.trackBillingHistory();
      break;

    case Events.AccountMenuClick:
      Tracker.trackAccountMenu(eventProperties);
      break;

    case Events.SupportClick:
      Tracker.trackSupport(eventProperties);
      break;

    case Events.ManageFacebookPagesDetailsClick:
      Tracker.trackManageFacebookPages({
        action: 'details'
      });
      break;

    case Events.ManageFacebookPagesCompleteClick:
      Tracker.trackManageFacebookPages({
        action: 'complete'
      });
      break;

    case Events.ManageFacebookPagesSetDefaultClick:
      Tracker.trackManageFacebookPages({
        action: 'default'
      });
      break;

    case Events.ManageFacebookPagesLinkClick:
      Tracker.trackManageFacebookPages({
        action: 'link'
      });
      break;

    case Events.ManageFacebookPagesUnlinkClick:
      Tracker.trackManageFacebookPages({
        action: 'unlink'
      });
      break;

    case Events.DeleteGalleryClicked:
      Tracker.trackGallery({
        action: 'delete'
      });
      break;

    case Events.DownloadContactsClicked:
      Tracker.trackContacts({
        action: 'download'
      });
      break;

    case Events.ViewContactClicked:
      Tracker.trackContacts({
        action: 'view'
      });
      break;

    case Events.GoogleAdDetailsClicked:
      Tracker.trackGoogleAdDetails(eventProperties);
      break;

    case Events.ViewProgramsClicked:
      Tracker.trackViewProgram(eventProperties);
      break;

    case Events.AddNewProgramClicked:
      Tracker.trackProgramsAddNew(eventProperties);
      break;

    case Events.CancelProgramClicked:
      Tracker.trackCancelProgram(eventProperties);
      break;

    case Events.EditProgramClicked:
      Tracker.trackEditProgram();
      break;

    case Events.EditProgramActionsClicked:
      Tracker.trackEditProgramActions(eventProperties);
      break;

    case Events.SubmitProgramPromoCodeClicked:
      Tracker.trackProgramSubmitPromoCode(eventProperties);
      break;

    case Events.SelectProgramStepClicked:
      Tracker.trackProgramSelectStep(eventProperties);
      break;

    case Events.ProgramPreviewVisibilityClicked:
      Tracker.trackProgramPreviewVisibility(eventProperties);
      break;

    case Events.ProgramStartStepClicked:
      Tracker.trackProgramStartStep();
      break;

    case Events.ProgramSelectFacebookPageClicked:
      Tracker.trackProgramSelectFacebookPage();
      break;

    case Events.ProgramLinkFacebookPageClicked:
      Tracker.trackProgramLinkFacebookPage();
      break;

    case Events.ProgramChooseBlueprintModalClicked:
      Tracker.trackProgramChooseBlueprintModal();
      break;

    case Events.ViewOrderClicked:
      Tracker.trackOrderView(eventProperties);
      break;

    case Events.PurchaseClicked:
      Tracker.trackPurchase(eventProperties);
      break;

    case Events.PageView:
      Tracker.trackPageView(eventProperties.path);
      break;

    default:
      break;
  }
};

const getContextHeaderMap = () => ({});

export default {
  initialize,
  setUserProperties,
  logEvent,
  getContextHeaderMap
};
