import { useState } from 'react';
import { flow } from 'lodash';
import { t } from 'i18next';

import { withFeatures } from 'src/components/Feature/Feature';
import Heading from 'src/components/PageElements/Heading';

import Gallery from './Gallery';
import { MEDIA_TYPE_FILTER_VALUES, getDefaultMediaSources } from './constants';

const GalleryLanding = ({
  features: { giphySearch, galleryAdminImages, shutterstockSearch }
}) => {
  const [mediaType, setMediaType] = useState(MEDIA_TYPE_FILTER_VALUES.all);
  // get feature flagged media sources:
  const defaultMediaSources = getDefaultMediaSources({
    giphySearch,
    galleryAdminImages,
    shutterstockSearch
  });
  const [selectedMediaSources, setSelectedMediaSources] = useState([]);
  return (
    <>
      <Heading
        title={t('gallery:headers.mediaLibrary')}
        subTitle={t('gallery:subHeaders.uploadMedia')}
        pageTitle={t('gallery:headers.mediaLibrary')}
      />

      <br />
      <Gallery
        showFilter
        mediaType={mediaType}
        setMediaType={setMediaType}
        selectedMediaSources={selectedMediaSources}
        defaultMediaSources={defaultMediaSources}
        setSelectedMediaSources={setSelectedMediaSources}
      />
    </>
  );
};

export default flow(withFeatures)(GalleryLanding);
