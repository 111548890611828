import { t } from 'i18next';

import { Alert, AlertTitle } from '@mui/material';

import { FormSection } from 'src/components/ReduxForm';

const NoBlueprint = () => {
  return (
    <FormSection>
      <Alert severity="info">
        <AlertTitle>
          {t('programCreate:configure.pleaseSelectBlueprint')}
        </AlertTitle>
      </Alert>
    </FormSection>
  );
};

export default NoBlueprint;
