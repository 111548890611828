import classNames from 'classnames';

import { Divider, AccordionSummary, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';
import { Theme } from '@mui/system';
import { ReactElement } from 'react';
import { InjectedStyledClasses } from 'src/common/Style';

const styles = (theme: Theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  error: {
    color: theme.palette.error.main
  }
});

export interface AudienceToolHeadingProps {
  title: string;
  error?: boolean;
}

const AudienceToolHeading = ({
  title,
  error,
  classes
}: AudienceToolHeadingProps & InjectedStyledClasses<typeof styles>) => {
  return (
    <>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="audience-tool-summary"
      >
        <Typography
          variant="subtitle1"
          className={classNames(classes.title, {
            [classes.error]: error
          })}
        >
          {title}
          {error ? <ErrorIcon /> : null}
        </Typography>
      </AccordionSummary>
      <Divider />
    </>
  );
};

export default withStyles(styles)(AudienceToolHeading) as (
  props: AudienceToolHeadingProps
) => ReactElement;
