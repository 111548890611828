import generateColors from './generateColors';

// TODO: make this a grey theme for initial loading
// Evocalize
const primary = '#3b1b87';
const secondary = '#00b8e6';

export const seattleSkies = {
  100: '#E5E5E5',
  200: '#CCCCCC',
  300: '#B2B2B2',
  400: '#999999',
  500: '#7F7F7F',
  600: '#666666',
  700: '#4C4C4C',
  800: '#333333',
  900: '#191919'
};

const error = '#ff2b00';
const warn = '#ffaa00';
const success = '#99e600';

export const DEFAULT_THEME = {
  seattleSkies,
  primary: generateColors(primary),
  secondary: generateColors(secondary),
  error: generateColors(error),
  warn: generateColors(warn),
  success: generateColors(success),
  white: '#FFFFFF',
  black: '#000000',
  facebook: {
    link: '#365899'
  }
};
