import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import { Box, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';

import ContentSetSelector from './ContentSetSelector';
import ContetnSetViewer from './ContentSetViewer';
import UploadModal from './Upload/UploadModal';

import { getAdminContentSets } from './queries';

const pageText = () => ({
  title: t('adminContentDataManager:title'),
  description: t('adminContentDataManager:description')
});

const ContentDataManager = ({
  contentSets,
  contentSetsMeta: { contentSetsLoading, contentSetsError }
}) => {
  const text = useMemo(() => pageText(), []);

  const [contentSetSlug, setcontentSetSlug] = useState('');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  if (contentSetsLoading || contentSetsError) {
    return <Loading error={contentSetsError} />;
  }

  const contentSetFields =
    (contentSetSlug &&
      contentSets.filter(c => c.slug === contentSetSlug)?.[0]?.fields) ||
    [];

  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.description}
        pageTitle={text.title}
        divider
      />

      <Box
        sx={{
          marginTop: theme => theme.spacing(2),
          display: 'flex'
        }}
      >
        <ContentSetSelector
          setContentSet={setcontentSetSlug}
          selectedContentSet={contentSetSlug}
          contentSets={contentSets}
        />
        {contentSetSlug && (
          <Button
            sx={{
              marginTop: theme => theme.spacing(1),
              marginBottom: theme => theme.spacing(1),
              marginLeft: theme => theme.spacing(2)
            }}
            variant="contained"
            data-cy="content-data-manager-import-button"
            startIcon={<CloudUploadIcon />}
            disabled={isUploadModalOpen}
            onClick={() => setIsUploadModalOpen(true)}
          >
            Upload CSV
          </Button>
        )}
      </Box>
      {isUploadModalOpen && (
        <UploadModal
          handleClose={() => setIsUploadModalOpen(false)}
          contentSetSlug={contentSetSlug}
          contentSetFields={contentSetFields}
        />
      )}
      {!isUploadModalOpen && contentSetSlug && (
        <Box
          sx={{
            marginTop: theme => theme.spacing(2)
          }}
        >
          <ContetnSetViewer
            contentSetSlug={contentSetSlug}
            contentSetFields={contentSetFields}
          />
        </Box>
      )}
    </>
  );
};

export default flow(
  graphql(getAdminContentSets, {
    name: 'getAdminContentSets',
    options: () => ({
      fetchPolicy: 'no-cache'
    }),
    props: ({ getAdminContentSets: { error, loading, adminContentSets } }) => {
      return {
        contentSets: adminContentSets,
        contentSetsMeta: {
          contentSetsLoading: loading,
          contentSetsError: error
        }
      };
    }
  })
)(ContentDataManager);
