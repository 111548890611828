import { useAppSettings } from 'src/AppSettings';
import { s3ImageSubfolders } from 'src/components/AdPreview/S3Image';

export const VERTICALS = {
  residentialRealEstate: 'Real Estate - Residential',
  multifamilyRealEstate: 'Real Estate - Multifamily',
  restaurants: 'Restaurants',
  generalFranchiseOrOther: 'General Franchise or Other',
  mortgage: 'Mortgage'
} as const;

type Vertical = (typeof VERTICALS)[keyof typeof VERTICALS];

interface VerticalPlaceholderImages {
  image: string;
  video: string;
}

const DEFAULT_VERTICAL_PLACEHOLDER_IMAGES: VerticalPlaceholderImages = {
  image: `${s3ImageSubfolders.adPreviewPlaceholders}/single-family-image.png`,
  video: `${s3ImageSubfolders.adPreviewPlaceholders}/single-family-video.png`
};

const getPlaceholderImagesByVertical = (vertical: Vertical) => {
  switch (vertical) {
    case VERTICALS.residentialRealEstate:
    case VERTICALS.mortgage:
      return DEFAULT_VERTICAL_PLACEHOLDER_IMAGES;
    case VERTICALS.multifamilyRealEstate:
      return {
        image: `${s3ImageSubfolders.adPreviewPlaceholders}/multifamily-image.png`,
        video: `${s3ImageSubfolders.adPreviewPlaceholders}/multifamily-video.png`
      };
    case VERTICALS.restaurants:
      return {
        image: `${s3ImageSubfolders.adPreviewPlaceholders}/restaurant-image.png`,
        video: `${s3ImageSubfolders.adPreviewPlaceholders}/restaurant-video.png`
      };
    case VERTICALS.generalFranchiseOrOther:
      return {
        image: `${s3ImageSubfolders.adPreviewPlaceholders}/general-franchise-image.png`,
        video: `${s3ImageSubfolders.adPreviewPlaceholders}/general-franchise-video.png`
      };
    default:
      return DEFAULT_VERTICAL_PLACEHOLDER_IMAGES;
  }
};

export const useGetVerticalPlaceholderImages = () => {
  const appSettings = useAppSettings();

  const vertical =
    appSettings?.app?.general?.vertical || VERTICALS.residentialRealEstate;

  return getPlaceholderImagesByVertical(vertical);
};
