import { isFunction } from 'lodash';
import axios from 'src/api/axiosWithInterceptors';
import { AxiosRequestConfig } from 'axios';

export default (
  file: Blob,
  s3PreSignedUrl: string,
  onUploadProgress?: AxiosRequestConfig['onUploadProgress']
) => {
  const data = new FormData();
  data.append('file', file);

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': file.type
    }
  };

  if (isFunction(onUploadProgress)) {
    config.onUploadProgress = onUploadProgress;
  }

  return axios.put(s3PreSignedUrl, file, config);
};
