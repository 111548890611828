export const FB_BLUE = '#4665b6';

export const BUTTON_TYPES = ['LEARN_MORE'];

export const PREVIEW_CARD_WIDTH = '300';
export const SOLO_CARD_WIDTH_LARGE = '496';
export const SOLO_CARD_WIDTH = '490';
export const SOLO_IMAGE_WIDTH = `${Number(SOLO_CARD_WIDTH) - 1}`;
export const SOLO_IMAGE_HEIGHT = '256px';
export const NOT_SQUARE_IMAGE_RATIO = '1.91 / 1';
export const SQUARE_IMAGE_RATIO = '1 / 1';
export const FACEBOOK_PREVIEW_WIDTH = '385';
