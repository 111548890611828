import { createRef, Component } from 'react';
import { isEmpty, uniq, remove } from 'lodash';
import { Chip, IconButton, InputAdornment, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';

const styles = theme => ({
  container: {
    width: '100%'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  input: {
    width: '100%'
  }
});

class RenderListTextField extends Component {
  constructor(props) {
    super(props);
    this.inputRef = createRef();
    this.state = {
      textValue: ''
    };
  }

  handelOnChange = ({ target: { value } }) => {
    this.setState({
      textValue: value
    });
  };

  handelKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.addValueToList();
    }
  };

  addValueToList = () => {
    const { textValue } = this.state;
    const {
      input: { onChange, value },
      uniqValues = true
    } = this.props;

    if (isEmpty(textValue)) {
      // we don't want to add empty chips
      return;
    }

    // reset input
    this.setState({
      textValue: ''
    });

    // update redux state
    onChange(uniqValues ? uniq([...value, textValue]) : [...value, textValue]);

    // refocus on input this will keep the focus when you click the + button
    this.inputRef.current.focus();
  };

  handelDeleteChip = item => {
    const {
      input: { onChange, value }
    } = this.props;

    onChange(
      remove(value, val => {
        return val !== item;
      })
    );
  };

  render() {
    const { textValue } = this.state;
    const {
      classes,
      input: { value },
      meta: { touched, error },
      variant = 'outlined',
      ...rest
    } = this.props;

    return (
      <div className={classes.container}>
        <TextField
          error={touched && !!error}
          variant={variant}
          {...rest}
          className={classes.input}
          helperText={touched && error}
          inputRef={this.inputRef}
          onChange={this.handelOnChange}
          onKeyPress={this.handelKeyPress}
          value={textValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.addValueToList} size="large">
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        {!isEmpty(value) && (
          <>
            {value.map((item, index) => {
              return (
                <Chip
                  key={`chip-${item}`}
                  className={classes.chip}
                  label={item}
                  onDelete={() => this.handelDeleteChip(item, index)}
                />
              );
            })}
          </>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(RenderListTextField);
