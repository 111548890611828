import { connect } from 'react-redux';
import { flow } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';

import { Button, CircularProgress, FormControl } from '@mui/material';

const required = value => (value ? undefined : 'Required');
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? t('activation:emailForm.invalidEmail')
    : undefined;

const ActivateEmailForm = props => {
  const { submitting, handleSubmit, invalid } = props;

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Field
          name="email"
          component={RenderTextField}
          label={t('activation:emailForm.email')}
          type="email"
          validate={[required, email]}
        />
        <Button
          variant="contained"
          size="large"
          color="primary"
          disabled={submitting || invalid}
          type="submit"
        >
          <Trans i18nKey="activation:emailForm.submit" />
        </Button>
      </FormControl>
      {submitting && <CircularProgress />}
    </form>
  );
};

export default flow(
  reduxForm({
    form: 'ActivateEmailForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    touchOnBlur: false,
    touchOnChange: true
  }),
  connect((state, ownProps) => ({
    initialValues: { email: ownProps.email } // pull initial values from account reducer
  }))
)(ActivateEmailForm);
