import { useMemo } from 'react';
import { isEmpty, mapValues, omit } from 'lodash';

import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useGridApiContext } from '@mui/x-data-grid-pro';

import { diffTwoObjects } from 'src/common/utilities';

import { DEFAULT_NEW_ITEM_ID } from './constants';
import { createHandleDeleteClick } from './helpers';

const DataTableRowActionCell = props => {
  const {
    id,
    onSaveRow,
    onDeleteRow,
    editFocusField,
    setTableIsEditing,
    isTableEditing,
    alreadyEditingTooltip
  } = props;
  const api = useGridApiContext();

  const hasDeleteMode = useMemo(() => !!onDeleteRow, [onDeleteRow]);
  const hasEditMode = useMemo(() => !!onSaveRow, [onSaveRow]);
  const allColumns = useMemo(() => api.current.getAllColumns(), [api]);
  const rowMode = api.current.getRowMode(id);
  const isInEditMode = rowMode === 'edit';

  const handleEditClick = event => {
    event.stopPropagation();

    // set table to edit mode
    setTableIsEditing(true);

    // set row to edit mode and focus on the first field unless otherwise specified
    api.current.startRowEditMode({
      id,
      fieldToFocus: editFocusField || allColumns[0].field
    });
  };

  const handleSaveClickAsync = async event => {
    event.stopPropagation();
    const unEditedValues = api.current.getRow(id);
    const editedValues = api.current.getRowWithUpdatedValues(id);
    const hasMadeChanges = !isEmpty(
      diffTwoObjects(editedValues, unEditedValues)
    );

    // stop editing
    api.current.stopRowEditMode({ id });

    const extractedValues = omit(
      mapValues(editedValues, ev => ev),
      'isNew'
    );

    // make sure the row with the default id is no longer in the table
    api.current.updateRows([{ id: DEFAULT_NEW_ITEM_ID, _action: 'delete' }]);

    if (hasMadeChanges) {
      // save the row
      const saveResponse = await onSaveRow(extractedValues);
      const values = saveResponse?.values;

      // success
      if (saveResponse?.success && values) {
        api.current.startRowEditMode({ id: values.id });
        // update the row
        api.current.updateRows([values]);

        // set table to edit mode
        setTableIsEditing(false);
        // stop editing
        api.current.stopRowEditMode({ id: values.id });

        return;
      }
    }
    // unset table edit mode
    setTableIsEditing(false);
    // no changes were made, so just stop editing
    api.current.stopRowEditMode({ id });
  };

  const handleCancelClick = event => {
    event.stopPropagation();
    const row = api.current.getRowWithUpdatedValues(id);

    // remove the row if was new and not saved
    if (row.isNew) {
      api.current.updateRows([{ id: row?.id, _action: 'delete' }]);
      // stop editing
      api.current.stopRowEditMode({ id });
    } else {
      // stop editing & discard changes
      api.current.stopRowEditMode({ id, ignoreModifications: true });
    }

    // unset table edit mode
    setTableIsEditing(false);
  };

  if (isInEditMode) {
    return (
      <>
        <IconButton
          aria-label="save"
          color="primary"
          onClick={handleSaveClickAsync}
          size="small"
        >
          <SaveIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="cancel"
          sx={{ color: theme => theme.palette.text.primary }}
          color="inherit"
          onClick={handleCancelClick}
          size="small"
        >
          <CancelIcon fontSize="small" />
        </IconButton>
      </>
    );
  }

  return (
    <>
      {hasEditMode && (
        <Tooltip title={alreadyEditingTooltip}>
          <span>
            <IconButton
              aria-label="edit"
              color="inherit"
              onClick={handleEditClick}
              size="small"
              sx={{ color: theme => theme.palette.text.primary }}
              disabled={isTableEditing}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {hasDeleteMode && (
        <IconButton
          aria-label="delete"
          color="inherit"
          onClick={createHandleDeleteClick({ id, onDeleteRow, api })}
          size="small"
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};

export default DataTableRowActionCell;
