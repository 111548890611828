export const notificationTypes = {
  email: 'EMAIL',
  sms: 'SMS'
};

export const userLeadPreferencesMediumTypes = {
  email: 'email',
  sms: 'sms'
};

export const notificationFormNames = {
  email: 'userLeadPreferencesEmail',
  sms: 'userLeadPreferencesSms'
};

export const RESULTS_PER_PAGE = 10;
