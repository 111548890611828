import { gql } from 'src/generated/gql';

export const setManuallyManagedFragment = gql(`
  mutation setManuallyManagedFragment(
    $projectId: ID!
    $publisherId: ID!
    $contentValue: String!
    $outputValue: String!
  ) {
    setManuallyManagedFragment(
      projectId: $projectId
      publisherId: $publisherId
      contentValue: $contentValue
      outputValue: $outputValue
    ) {
      outcome
      message
    }
  }
`);

export const resetManuallyManagedFragment = gql(`
  mutation resetManuallyManagedFragment($fragmentId: ID!) {
    resetManuallyManagedFragment(fragmentId: $fragmentId)
  }
`);
