import { useContext } from 'react';

import { CreditCardContext } from './CreditCardProvider';

const useCreditCard = () => {
  const context = useContext(CreditCardContext);

  if (!context) {
    throw new Error('useCreditCard should be used inside CreditCardProvider');
  }

  return {
    ...context
  };
};

export default useCreditCard;
