import { useMemo } from 'react';
import { t } from 'i18next';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Grid } from '@mui/material';

import { useAppSettings } from 'src/AppSettings';

import useProgram from 'src/pages/Program/utils/useProgram';
import { FormSection } from 'src/components/ReduxForm';
import Offers from 'src/components/Checkout/Offers';
import CreditCardProvider from 'src/components/Checkout/CreditCardProvider';
import PriceSummary from 'src/pages/Program/ProgramSteps/PriceSummary/PriceSummary';

const Billing = () => {
  const appSettings = useAppSettings();
  const { selectedBlueprint, isAutomated } = useProgram();

  // eslint-disable-next-line prefer-destructuring
  const stripeKey = appSettings.app.general.stripeKey;
  const stripePromise = useMemo(() => loadStripe(stripeKey), []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FormSection
          title={t('programCreate:Checkout.billingSectionTitle')}
          description={t('programCreate:Checkout.billingSectionDescription')}
        >
          <Elements stripe={stripePromise}>
            <CreditCardProvider>
              <Offers selectedBlueprint={selectedBlueprint} />
            </CreditCardProvider>
          </Elements>
        </FormSection>
      </Grid>
      <Grid item xs={12}>
        <PriceSummary
          selectedBlueprint={selectedBlueprint}
          isAutomated={isAutomated}
        />
      </Grid>
    </Grid>
  );
};

export default Billing;
