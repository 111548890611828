export const facebookRegions = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
    country: 'US',
    facebook_region_id: 3843
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
    country: 'US',
    facebook_region_id: 3844
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
    country: 'US',
    facebook_region_id: 3845
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
    country: 'US',
    facebook_region_id: 3846
  },
  {
    name: 'California',
    abbreviation: 'CA',
    country: 'US',
    facebook_region_id: 3847
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
    country: 'US',
    facebook_region_id: 3848
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
    country: 'US',
    facebook_region_id: 3849
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
    country: 'US',
    facebook_region_id: 3850
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
    country: 'US',
    facebook_region_id: 3852
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
    country: 'US',
    facebook_region_id: 3853
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
    country: 'US',
    facebook_region_id: 3854
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
    country: 'US',
    facebook_region_id: 3855
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
    country: 'US',
    facebook_region_id: 3856
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
    country: 'US',
    facebook_region_id: 3857
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
    country: 'US',
    facebook_region_id: 3858
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
    country: 'US',
    facebook_region_id: 3859
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
    country: 'US',
    facebook_region_id: 3860
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
    country: 'US',
    facebook_region_id: 3861
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
    country: 'US',
    facebook_region_id: 3862
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
    country: 'US',
    facebook_region_id: 3863
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
    country: 'US',
    facebook_region_id: 3864
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
    country: 'US',
    facebook_region_id: 3865
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
    country: 'US',
    facebook_region_id: 3866
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
    country: 'US',
    facebook_region_id: 3867
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
    country: 'US',
    facebook_region_id: 3868
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
    country: 'US',
    facebook_region_id: 3869
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
    country: 'US',
    facebook_region_id: 3870
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
    country: 'US',
    facebook_region_id: 3871
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
    country: 'US',
    facebook_region_id: 3872
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
    country: 'US',
    facebook_region_id: 3873
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
    country: 'US',
    facebook_region_id: 3874
  },
  {
    name: 'New York',
    abbreviation: 'NY',
    country: 'US',
    facebook_region_id: 3875
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
    country: 'US',
    facebook_region_id: 3876
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
    country: 'US',
    facebook_region_id: 3877
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
    country: 'US',
    facebook_region_id: 3878
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
    country: 'US',
    facebook_region_id: 3879
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
    country: 'US',
    facebook_region_id: 3880
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
    country: 'US',
    facebook_region_id: 3881
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
    country: 'US',
    facebook_region_id: 3882
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
    country: 'US',
    facebook_region_id: 3883
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
    country: 'US',
    facebook_region_id: 3884
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
    country: 'US',
    facebook_region_id: 3885
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
    country: 'US',
    facebook_region_id: 3886
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
    country: 'US',
    facebook_region_id: 3887
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
    country: 'US',
    facebook_region_id: 3888
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
    country: 'US',
    facebook_region_id: 3889
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
    country: 'US',
    facebook_region_id: 3890
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
    country: 'US',
    facebook_region_id: 3891
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
    country: 'US',
    facebook_region_id: 3892
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
    country: 'US',
    facebook_region_id: 3893
  },
  // Commenting out due to HEC updates not allowing us to target DC since it's
  // a city. We can uncomment this later if/when Facebook updates their
  // targeting logic.
  // {
  //     name: 'Washington DC',
  //     abbreviation: 'DC',
  //     country: 'US',
  //     facebook_region_id: 3851
  // },
  {
    name: 'Alberta',
    abbreviation: 'AB',
    country: 'CA',
    facebook_region_id: 527
  },
  {
    name: 'British Columbia',
    abbreviation: 'BC',
    country: 'CA',
    facebook_region_id: 528
  },
  {
    name: 'Manitoba',
    abbreviation: 'MB',
    country: 'CA',
    facebook_region_id: 529
  },
  {
    name: 'New Brunswick',
    abbreviation: 'NB',
    country: 'CA',
    facebook_region_id: 530
  },
  {
    name: 'Newfoundland and Labrador',
    abbreviation: 'NL',
    country: 'CA',
    facebook_region_id: 531
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'NS',
    country: 'CA',
    facebook_region_id: 532
  },
  {
    name: 'Ontario',
    abbreviation: 'ON',
    country: 'CA',
    facebook_region_id: 533
  },
  {
    name: 'Prince Edward Island',
    abbreviation: 'PE',
    country: 'CA',
    facebook_region_id: 534
  },
  {
    name: 'Quebec',
    abbreviation: 'QC',
    country: 'CA',
    facebook_region_id: 535
  },
  {
    name: 'Saskatchewan',
    abbreviation: 'SK',
    country: 'CA',
    facebook_region_id: 536
  },
  {
    name: 'Northwest Territories',
    abbreviation: 'NT',
    country: 'CA',
    facebook_region_id: 538
  },
  {
    name: 'Nunavut',
    abbreviation: 'NU',
    country: 'CA',
    facebook_region_id: 539
  }
];

export const googleAddressMappings = {
  addressStreet: place =>
    // we want to return undefined if these don't exist
    place.street_number &&
    place.route &&
    `${place.street_number} ${place.route}`,
  // sometimes borough or neighborhood is used instead of city
  addressCity: place =>
    place.locality || place.sublocality || place.neighborhood,
  addressState: place => place.administrative_area_level_1,
  addressStateLong: place => place.longNames.administrative_area_level_1,
  addressZip: place => place.postal_code,
  addressCountry: place => place.country,
  addressCountryLong: place => place.longNames.country,
  latitude: place => place.latitude(),
  longitude: place => place.longitude()
};
