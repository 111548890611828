import { List } from '@mui/material';

import { useGlobalContext } from 'src/GlobalContextProvider';
import {
  formatAutomationOrders,
  formatProgramOrders
} from 'src/components/BlockingOrdersList';

import CreditCardListItem from './CreditCardListItem';

import useGetPaymentMethods from './useGetPaymentMethods';
import { cardListDirections } from './Constants';

const CreditCardList = ({ direction = cardListDirections.vertical }) => {
  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;
  const isVerticalList = direction === cardListDirections.vertical;

  const { paymentMethods, refetch } = useGetPaymentMethods({ isTeamsEnabled });

  return (
    <List
      sx={{
        display: 'inline-flex',
        flexDirection: isVerticalList ? 'column' : 'row',
        ...(!isVerticalList && { width: '100%', flexWrap: 'wrap' })
      }}
    >
      {paymentMethods.map(
        ({
          id,
          stripeSourceId,
          stripeSource,
          teamCapabilities,
          purchaseOrders,
          subscriptionOrders,
          automationOrders,
          accessControl
        }) => {
          const automations = formatAutomationOrders({
            orders: automationOrders
          });
          const programs = formatProgramOrders({
            orders: {
              edges: [
                ...(subscriptionOrders?.edges || []),
                ...(purchaseOrders?.edges || [])
              ]
            }
          });

          return (
            <CreditCardListItem
              {...stripeSource}
              paymentMethodId={id}
              stripeSourceId={stripeSourceId}
              refetch={refetch}
              teamCapabilities={teamCapabilities}
              programs={programs}
              automations={automations}
              permissions={accessControl?.permissions}
              isVerticalList={isVerticalList}
            />
          );
        }
      )}
    </List>
  );
};

export default CreditCardList;
