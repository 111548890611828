const Constants = {
  paths: {
    dashboard: {
      base: '/dashboard',
      linkFacebook: '/dashboard/facebook'
    },
    home: '/'
  },
  appNames: {
    agent: 'D2A',
    office: 'OFFICE'
  }
};

export default Constants;
