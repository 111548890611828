import { MouseEvent, useState } from 'react';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ErrorResponse } from '@apollo/client/link/error';

import { IconButton, Tooltip, Box, ListItemText } from '@mui/material';
import { Delete as TrashIconFilled } from '@mui/icons-material';

import { extractCcErrorMessage } from 'src/common/paymentUtils';
import { CardSelectOptionInterface } from 'src/components/Checkout/CardOffer';
import CreditCardLogo from 'src/components/Icons/CreditCardLogos/CreditCardLogo';
import { markPaymentMethodAsRemoved } from 'src/components/Checkout/mutations';

type Refetch = any;

interface CardSelectOptionProps {
  optionProps: any;
  key: string;
  option: CardSelectOptionInterface;
  refetch?: Refetch;
}

const CardSelectOption = (props: CardSelectOptionProps) => {
  const { optionProps, key, option, refetch } = props;
  const { enqueueSnackbar } = useSnackbar();

  const paymentMethodId = option.paymentMethod?.id;

  const [pending, setSubmitting] = useState(false);

  const [markPaymentMethodAsRemovedMutation] = useMutation(
    markPaymentMethodAsRemoved
  );

  const handleRemove = (e: MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    setSubmitting(true);
    markPaymentMethodAsRemovedMutation({
      variables: { paymentMethodId }
    })
      .then(() => {
        return refetch();
      })
      .catch(error => {
        let errorMessage = t('programCreate:Checkout.removeError');

        if (error && error.toString().includes('associated')) {
          errorMessage = t('programCreate:Checkout.removeAssociatedError');
        }

        // Note: The api will throw an exception if the payment method is
        //       still associated with an active order.
        const extraErrorMessage = extractCcErrorMessage(error as ErrorResponse);

        enqueueSnackbar(
          <span>
            {errorMessage}
            {extraErrorMessage && (
              <>
                <br />
                {extraErrorMessage}
              </>
            )}
          </span>,
          {
            variant: 'error'
          }
        );
        setSubmitting(false);
      });
  };

  const cardExpiryMonth = option?.paymentMethod?.stripeSource?.cardExpiryMonth;
  const cardExpiryYear = option?.paymentMethod?.stripeSource?.cardExpiryYear;
  const cardBrand = option?.paymentMethod?.stripeSource?.cardBrand;
  const cardLastFour = option?.paymentMethod?.stripeSource?.cardLastFour;

  let cardExpiry = '';

  if (cardExpiryMonth && cardExpiryYear) {
    const cardExpiryMonthFormatted = cardExpiryMonth
      ?.toString()
      ?.padStart(2, '0');

    const cardExpiryYearFormatted = cardExpiryYear?.toString()?.slice(2, 4);

    cardExpiry = `${cardExpiryMonthFormatted}/${cardExpiryYearFormatted}`;
  }

  return (
    <div key={key} {...optionProps} data-cy="cc-label" aria-disabled={pending}>
      <Box
        sx={{
          borderRadius: 0.5,
          width: 50,
          height: 30,
          border: theme => `1px solid ${theme.palette.grey[200]}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mr: 1.5
        }}
      >
        <CreditCardLogo brand={cardBrand} />
      </Box>

      <ListItemText
        primary={t('programCreate:Checkout.endingV3', {
          cardBrand,
          mask: '••••',
          cardLastFour
        })}
        secondary={t('programCreate:Checkout.expiresV3', {
          cardExpiry
        })}
        data-cy="cc-label"
        primaryTypographyProps={{
          sx: { fontWeight: 600 },
          variant: 'body2',
          component: 'span'
        }}
        secondaryTypographyProps={{
          variant: 'caption',
          component: 'span'
        }}
      />
      {!!refetch && paymentMethodId && (
        <Tooltip
          title={t('programCreate:Checkout.removeCcTip')}
          aria-label={t('programCreate:Checkout.removeCcTip')}
        >
          {/* we need this span to remove material error warning */}
          <span>
            <IconButton
              data-cy={`payment-remove-card-button-${cardLastFour}`}
              onClick={handleRemove}
              disabled={pending}
              edge="end"
              aria-label="remove"
              size="large"
            >
              <TrashIconFilled sx={{ color: 'text.primary' }} />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default CardSelectOption;
