import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router';
import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { AI_CHAT_WINDOW_OPEN } from 'src/common/userSettings';
import { SettingsUpdateType } from 'src/generated/gql/graphql';
import { useGlobalContext } from 'src/GlobalContextProvider';
import SentryUtil from 'src/common/SentryUtil';
import Instrumentation from 'src/instrumentation';

import { modifyUserSettingsForAiChat } from './mutations';
import { AiChatType } from './constants';

interface UseAiChatArgs {
  selectedBlueprint?: Record<string, any>;
  type: AiChatType;
}
export interface UseAiChatResponse {
  aiChatOpen: boolean;
  setAiChatOpen: (open: boolean) => void;
  toggleAiChatWindow: () => void;
  aiChatType: AiChatType;
}

const useAiChat = ({ selectedBlueprint, type }: UseAiChatArgs) => {
  const globalContext = useGlobalContext();
  const location = useLocation();
  const userSettingsAiChatWindowOpen: boolean =
    globalContext?.me?.settings?.[AI_CHAT_WINDOW_OPEN];

  const [aiChatOpen, setAiChatOpen] = useState(
    userSettingsAiChatWindowOpen ?? true
  );

  const [modifyUserSettings] = useMutation(modifyUserSettingsForAiChat);

  const updateAiChatWindowOpenUserSetting = async (open: boolean) => {
    try {
      await modifyUserSettings({
        variables: {
          updateSettings: {
            [AI_CHAT_WINDOW_OPEN]: open
          },
          updateType: SettingsUpdateType.DeepMerge
        }
      });
      await globalContext?.refetch();
    } catch (error: any) {
      SentryUtil.captureException(error);
    }
  };

  const debouncedUpdateAiChatWindowOpenUserSetting = useCallback(
    debounce(updateAiChatWindowOpenUserSetting, 1000),
    []
  );

  const toggleAiChatWindow = () => {
    setAiChatOpen(prevState => {
      const isOpen = !prevState;
      Instrumentation.logEvent(Instrumentation.Events.ClickEvocalizeAi, {
        path: location.pathname,
        action: isOpen ? 'expand' : 'collapse',
        architectureId: selectedBlueprint?.architectureId,
        productId: selectedBlueprint?.id,
        feature: type
      });
      debouncedUpdateAiChatWindowOpenUserSetting(isOpen)?.catch(_ => {});
      return isOpen;
    });
  };

  return {
    aiChatOpen,
    setAiChatOpen,

    toggleAiChatWindow,
    aiChatType: type
  };
};

export default useAiChat;
