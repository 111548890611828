import v from 'validator';

import { createStringValidator } from 'src/common/validations';

const isSkinAssetUrl = value => {
  if (!value) {
    return true;
  }
  return v.isURL(value, { allow_protocol_relative_urls: true });
};

export const validateSkinAssetUrl = () =>
  createStringValidator(isSkinAssetUrl, () => `Invalid URL.`);
