import { useEffect } from 'react';
import { flow } from 'lodash';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { parse, stringify } from 'qs';

import {
  AppBar,
  IconButton,
  LinearProgress,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import { withStyles, useTheme } from '@mui/styles';
import { AppSettingsContextType, withAppSettings } from 'src/AppSettings';

import MenuIcon from '@mui/icons-material/Menu';

import { WithStyledClasses } from 'src/common/Style';
import { History, Location } from 'history';
import { Theme } from '@mui/system';
import { MuiThemeTransitions } from 'src/AppThemeWrapper';
import { NotificationIconButton } from 'src/components/Notification/NotificationIconButton';
import { FlexExpander } from 'src/components/Styling/FlexExpander';
import { useFeatures } from 'src/components/Feature';
import { useAppState } from 'src/AppStateProvider';
import { DRAWER_OPEN } from './constants';
import AccountMenu from './AccountMenu';
import SupportMenu from './SupportMenu';

const styles = (theme: Theme & MuiThemeTransitions) => ({
  appBarOpen: {
    marginLeft: `${theme.evSizes.navigationWidth}px`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: `calc(100% - ${theme.evSizes.navigationWidth}px)`
  },
  appBarClosed: {
    marginLeft: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: '100%'
  },
  menuUserDetails: {
    marginLeft: theme.spacing(1)
  },
  menuCurrentUser: {
    margin: theme.spacing(1)
  }
});

interface ChromeAppBarProps {
  classes: WithStyledClasses<typeof styles>;
  history: History;
  loading: boolean;
  location: Location;
  appSettings: AppSettingsContextType;
}

const ChromeAppBar = ({
  classes,
  history,
  loading,
  location: { pathname, search },
  appSettings
}: ChromeAppBarProps) => {
  const theme = useTheme<Theme>();
  const upToSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const eqSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const { sideNavOpen, toggleSideNav } = useAppState();

  const { showNotificationPanel } = useFeatures();

  useEffect(() => {
    const drawerPrefOpen = localStorage.getItem(DRAWER_OPEN);

    // hide on small by default
    if (upToSmall && sideNavOpen && drawerPrefOpen === null) {
      // pass 'sm' so we don't save pref on small and the click handler passes event
      toggleSideNav(true);
      return;
    }

    // show on >sm by default if user is resizing its window
    if (!upToSmall && !sideNavOpen && drawerPrefOpen === null) {
      toggleSideNav(true);
      return;
    }

    // if query parameter set it overrides
    const currentQueryParams = parse(search.substr(1)) || {};
    const sideNav = currentQueryParams?.sideNav || false;

    if (sideNav) {
      if (
        (sideNav === 'closed' && sideNavOpen) ||
        (sideNav === 'open' && !sideNavOpen)
      ) {
        toggleSideNav(true);
      }

      // Remove the sideNav param.
      delete currentQueryParams.sideNav;
      history.push({
        pathname,
        search: stringify(currentQueryParams)
      });
      return;
    }

    if (drawerPrefOpen === null || eqSmall) {
      // no preference set and we don't keep preference for small
      // this would be a place to set defaults from a theme if we have them
      return;
    }

    // we convert bool to str because localstorage only stores strings
    if (drawerPrefOpen !== sideNavOpen.toString()) {
      toggleSideNav();
    }
  }, [upToSmall]);

  return (
    <AppBar
      className={classNames({
        [classes.appBarOpen]: sideNavOpen,
        [classes.appBarClosed]: !sideNavOpen
      })}
      color="default"
      position="fixed"
      data-cy="chrome-app-bar"
    >
      <Toolbar>
        <IconButton
          aria-label="Menu"
          color="inherit"
          onClick={() => toggleSideNav()}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <FlexExpander />
        {showNotificationPanel && !loading && <NotificationIconButton />}
        {appSettings?.app?.support?.enableAppBar && (
          <SupportMenu loading={loading} />
        )}
        <AccountMenu loading={loading} />
      </Toolbar>
      {loading ? (
        <LinearProgress />
      ) : (
        <LinearProgress variant="determinate" value={100} />
      )}
    </AppBar>
  );
};

export default flow(
  withStyles(styles),
  withRouter,
  withAppSettings
)(ChromeAppBar);
