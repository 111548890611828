import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Modal from 'src/components/Modal';

const styles = () => ({});

const AdminSkinPreviewModal = props => {
  const { open, close, submit } = props;

  return (
    <Modal
      fullWidth
      open={open}
      headerText={t('adminSkinSettings:modal.previewHeader')}
      onClose={close}
      FooterComponent={() => (
        <>
          <Button onClick={close}>
            <Trans i18nKey="adminSkinSettings:modal.cancel">Cancel</Trans>
          </Button>
          <Button color="primary" onClick={submit}>
            <Trans i18nKey="adminSkinSettings:modal.previewSkin">
              Preview Skin
            </Trans>
          </Button>
        </>
      )}
    >
      <div>
        <Typography variant="h5">
          <Trans i18nKey="adminSkinSettings:modal.previewInfoHeading">
            You are about to go into skin preview mode
          </Trans>
        </Typography>
        <br />
        <Typography variant="subtitle1">
          <Trans i18nKey="adminSkinSettings:modal.previewInfo">
            In order to preview your changes we are going to temporarily apply
            your skin updates and put you into preview mode. This will cause the
            page to refresh so you can see your changes.
          </Trans>
        </Typography>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(AdminSkinPreviewModal);
