import { gql } from 'src/generated/gql';

export const calculatePromotion = gql(`
  query CalculatePromotion(
    $promoCode: String!
    $purchaseAmount: Float!
    $timeZoneRegionName: String!
    $supportType: PromotionSupportType
  ) {
    calculatePromotion(
      promoCode: $promoCode
      purchaseAmount: $purchaseAmount
      timeZoneRegionName: $timeZoneRegionName
      supportType: $supportType
    ) {
      discountAmount
      amountAfterDiscount
      promotionId
      promoCode
      promoDescription
      message
      isValid
    }
  }
`);
