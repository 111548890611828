import Instrumentation from 'src/instrumentation';

export const assetTypes = {
  facebookPage: 'facebook page',
  mediaAsset: 'media asset',
  paymentMethod: 'payment method'
} as const;

export const assetEventTypes = {
  delete: 'delete',
  deleteConfirm: 'deleteConfirm',
  share: 'share',
  shareConfirm: 'shareConfirm',
  unshare: 'unshare',
  unshareConfirm: 'unshareConfirm',
  unlinkConfirm: 'unlinkConfirm',
  unknownType: 'unknownType'
} as const;

type AssetEventTypesKeys = keyof typeof assetEventTypes;

type AssetEventTypesValues = (typeof assetEventTypes)[AssetEventTypesKeys];

type AssetTypesKeys = keyof typeof assetTypes;

interface LogTeamsEventArgs {
  type: AssetEventTypesValues;
  properties: {
    object: (typeof assetTypes)[AssetTypesKeys];
  };
}

// Logs any type of event for org assets, this will replace specific delete events since it is flexible.
export const logAssetEvent = ({ type, properties }: LogTeamsEventArgs) => {
  let event;

  switch (type) {
    case assetEventTypes.share:
      event = Instrumentation.Events.ClickTeamObjectShare;
      break;
    case assetEventTypes.shareConfirm:
      event = Instrumentation.Events.ClickTeamObjectShareConfirm;
      break;
    case assetEventTypes.unshare:
      event = Instrumentation.Events.ClickTeamObjectUnshare;
      break;
    case assetEventTypes.unshareConfirm:
      event = Instrumentation.Events.ClickTeamObjectUnshareConfirm;
      break;
    case assetEventTypes.delete:
      event = Instrumentation.Events.ClickObjectDelete;
      break;
    case assetEventTypes.deleteConfirm:
      event = Instrumentation.Events.ClickObjectDeleteConfirm;
      break;
    default:
      break;
  }

  if (event) {
    Instrumentation.logEvent(event, properties);
  }
};
