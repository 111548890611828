import { useState, useRef } from 'react';
import { debounce, get, isNumber } from 'lodash';

import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  Slider,
  FormLabel,
  Tooltip
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import HelpIcon from '@mui/icons-material/HelpOutline';
import ClientHtml from 'src/components/ClientHtml';

const styles = theme => ({
  error: {
    color: theme.palette.error.main
  },
  sliderWrapper: {
    padding: '5px 0 0 0',
    minWidth: '70px'
  },
  formControl: {
    width: '100%',
    margin: '16px 0'
  },
  numberInput: {
    maxWidth: '40px',
    marginRight: '20px'
  },
  numberInputAdornment: {
    maxWidth: '70px',
    marginRight: '20px'
  },
  label: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '4px',
    width: '100%'
  },
  helpIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    width: '20px'
  }
});

const numberfy = num => {
  let value = Number(num);
  if (Number.isNaN(value)) {
    value = 0;
  }
  return value;
};

const RenderSlider = props => {
  const {
    input,
    classes,
    showInput,
    type,
    min = 0,
    max = 100,
    step,
    label,
    meta: { touched, error },
    endAdornment,
    fullWidth,
    tooltip,
    helperText,
    businessObjects, // was causing a warning when getting passed with ...rest,
    thumb,
    track,
    roundingFunction,
    readOnly = false,
    disabled,
    showSlider = false,
    marks = [],
    sx = {},
    ...rest
  } = props;

  const { value, ...inputProps } = input;
  const [localValue, setValue] = useState(
    isNumber(value) ? value : numberfy(value)
  );

  const cachedValue = useRef(value);
  const debouncedOnChange = useRef(
    debounce((input, value) => {
      return input.onChange(numberfy(value));
    }, 1)
  );

  // if value changes outside slider
  if (cachedValue.current !== value) {
    setValue(isNumber(value) ? value : numberfy(value));
    debouncedOnChange.current(input, value);
    cachedValue.current = value;
  }

  const slider = (
    <Slider
      {...rest}
      {...inputProps}
      value={localValue}
      min={min}
      max={max}
      marks={marks}
      disabled={disabled || readOnly}
      sx={{
        ...sx,
        '& .MuiSlider-mark': {
          display: 'none'
        }
      }}
      onChange={(event, value) => {
        const rounded = roundingFunction ? roundingFunction(value) : value;
        setValue(rounded);
        debouncedOnChange.current(input, rounded);
      }}
    />
  );

  if (!showInput) {
    return slider;
  }

  return (
    <FormControl fullWidth className={classes.formControl}>
      {label && (
        <FormLabel
          htmlFor={input.name}
          className={classes.label}
          error={touched && error}
        >
          {label}
          {tooltip && (
            <Tooltip title={tooltip}>
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          )}
        </FormLabel>
      )}
      <Box display="flex" flexDirection="row">
        <Box>
          <TextField
            className={
              endAdornment ? classes.numberInputAdornment : classes.numberInput
            }
            variant="standard"
            value={localValue}
            onChange={event => {
              const newValue = get(event, 'target.value');
              setValue(newValue);
              debouncedOnChange.current(input, newValue);
            }}
            // Note: InputProps = Props applied to the Input element
            //       inputProps = input attributes
            InputProps={{
              ...(endAdornment && {
                endAdornment: (
                  <InputAdornment position="end">{endAdornment}</InputAdornment>
                )
              })
            }}
          />
        </Box>
        {showSlider && (
          <Box flexGrow={2} className={classes.sliderWrapper}>
            {slider}
          </Box>
        )}
      </Box>
      {helperText && (
        <FormHelperText error={touched && !!error}>
          <ClientHtml html={helperText} />
        </FormHelperText>
      )}
      {touched && !!error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default withStyles(styles)(RenderSlider);
