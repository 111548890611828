import { useMemo, useState, useCallback } from 'react';
import { t } from 'i18next';

import { Menu, MenuItem, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { OfferType, OFFER_TYPES } from 'src/common/offers';

const pageText = () => ({
  addOfferMenuItem: t('admin:blueprintBuilder.stepOffers.addOfferMenuItem')
});

const menuItems = [
  { value: OFFER_TYPES.purchase, name: OFFER_TYPES.purchase },
  { value: OFFER_TYPES.subscription, name: OFFER_TYPES.subscription }
];

interface AddOfferProps {
  onAddOffer: (type: OfferType) => void;
}

const AddOffer = ({ onAddOffer }: AddOfferProps) => {
  const text = useMemo(() => pageText(), []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    type => {
      onAddOffer(type);
      handleClose();
    },
    [handleClose, onAddOffer]
  );

  return (
    <>
      <IconButton size="small" color="primary" onClick={handleClick}>
        <AddIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem key="addFacetMenuItem" disabled>
          {text.addOfferMenuItem}
        </MenuItem>
        {menuItems.map(option => (
          <MenuItem
            sx={{ textTransform: 'capitalize' }}
            key={option.value}
            onClick={() => handleSelect(option.value)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddOffer;
