import { channelTypes } from 'src/common/adChannels';
import {
  isValidDate,
  maxValue,
  minValue,
  nonFacebookLink,
  validateExclamationMarkLimit,
  validateIsInt,
  validateIsNumber,
  validatePhone,
  validateUrl,
  validateUrlWithProtocol,
  validationOptions
} from './validations';

export const validationsByType = {
  boolean: [],
  any_number: { validateIsNumber },
  date_utc: { isValidDate },
  facebook_audience_id: [],
  image_url: { validateIsUrl: validateUrl() },
  link_url: { validateIsUrl: validateUrl(), maxValue: maxValue(1000) },
  positive_integer: { validateIsNumber, validateIsInt, minValue: minValue(0) },
  positive_number: { validateIsNumber, minValue: minValue(0) },
  price_decimal: { validateIsNumber, minValue: minValue(0) },
  price_integer: { validateIsNumber, validateIsInt, minValue: minValue(0) },
  single_line_string: {},
  video_url: { validateUrl: validateUrl() },
  zip_code: {},
  phone_number: { validatePhone: validatePhone() },
  multi_line_string: {},
  facebook_region_code: {},
  radio_select: {},
  integer_slider: { validateIsNumber, validateIsInt }
};

type ValidationByTypeKey = keyof typeof validationsByType;

// Allows any combination of INPUT_TYPE keys as keys for the config object
type ValidationConfigObject = Partial<Record<ValidationByTypeKey, any>>;

const facebookValidationsByType: ValidationConfigObject = {
  link_url: { nonFacebookLink }
};

const googleCharacterCountLimits = {
  headline: {
    exclamationMark: 0,
    questionMark: 2
  },
  description: {
    exclamationMark: 2,
    questionMark: 2
  }
};

type Field = { displayName: string; displayMethodId: ValidationByTypeKey };

const googleValidationsByType = (type: ValidationByTypeKey, field: Field) => {
  const typeValidationMap: ValidationConfigObject = {
    link_url: { validateUrlWithProtocol }
  };
  const headlinesRegex = /(Long)?\s?Headline\s?\d*$/;

  let validations = typeValidationMap[type] || {};

  // Note: Until we have publisher or preview level validations we will have to have these field
  //       level validations based on display name. This will not account for the cases where
  //       a blueprint joins data together for an ad creative.
  if (headlinesRegex.test(field.displayName)) {
    validations = {
      ...validations,
      validateExclamationMarkLimit: validateExclamationMarkLimit(
        googleCharacterCountLimits.headline.exclamationMark
      )
    };
  }

  return validations;
};

const getValidationsByType = (type: ValidationByTypeKey) => {
  return validationsByType[type] || {};
};

export const getValidationsByDynamicField = (
  field: Field,
  channels: string[]
) => {
  const validations = {};

  const type = field?.displayMethodId;

  // custom channel overrides
  let channelSpecificValidations = {};

  if (channels) {
    if (channels.includes(channelTypes.facebook)) {
      channelSpecificValidations = {
        ...channelSpecificValidations,
        ...(facebookValidationsByType[type] || {})
      };
    }

    if (channels.includes(channelTypes.google)) {
      channelSpecificValidations = {
        ...channelSpecificValidations,
        ...googleValidationsByType(type, field)
      };
    }
  }

  return {
    ...validations,
    ...getValidationsByType(type),
    ...channelSpecificValidations
  };
};

export const getExtraValidationRules = (extraValidationRules = []) => {
  const newRules = extraValidationRules.reduce((rules, rule) => {
    const validationRule = validationOptions.find(
      (option: { name: string; value: string; method: any }) =>
        option.value === rule
    );

    if (validationRule) {
      const updatedRules = {
        ...rules,
        [validationRule.name]: validationRule?.method?.()
      };
      return updatedRules;
    }
    return rules;
  }, {});

  return newRules;
};
