import { SyntheticEvent, useState } from 'react';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { IconButton, Popover, Tooltip } from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { t } from 'i18next';
import { useLocaleText } from './helpers';

// Implementation note:
// Right now we load up all emoji data from emoji-mart/data.
// This means at compile time we bundle all the emoji data and
// users pay that cost even if they never use the emoji picker.
// There are ways we can lazy-load emoji data only when a user
// actually needs to use the emoji picker.

const getText = () => ({
  buttonLabel: t('emojiSelector:buttonLabel')
});

interface EmojiPickerIconProps {
  /**
   * Callback when an emoji is selected.
   * @param emoji - The actual emoji selected. Like 🍅 or 🤖
   */
  onEmojiSelected: (emoji: string) => void;

  onEmojiPanelOpen?: () => void;
}

/**
 * Props for the `onEmojiSelect` callback from EmojiMart's picker.
 * This is ONLY provided because EmojiMart's typings are not correct.
 * Or rather they just say `any` for the `emoji` parameter.
 */
interface EmojiMartOnEmojiSelectProps {
  /**
   * The native emoji. Like 🍅 or 🤖
   */
  native: string;
  /**
   * The name of the emoji. Like "Red Apple" or "Robot"
   */
  name: string;
  /**
   * The emoji's ID. Like "apple" or "robot_face"
   */
  id: string;
  /**
   * The shortcodes for the emoji. Like ":apple:" or ":robot_face:"
   */
  shortcodes: string[] | string;
  /**
   * The escape code for the unified emoji. Like "if34e" or "1f916"
   */
  unified: string;
  /**
   * The emoji's keywords. Like ["fruit", "mac", "school"] or ["face", "computer", "machine"]
   */
  keywords: string[];
  /**
   * The emoji's emoticons. Like ["=D", ":-D", ":D"].
   * If there are no emoticons, this will be undefined.
   */
  emoticons?: string[] | string;
}

/**
 * Emoji picker button and popover.
 * This will display a button suggesting "Adding a new emoji" and when clicked
 * a popover will appear with a full list of all emojis.
 * Whenever an emoji is selected the `onEmojiSelected` callback will be called
 * with the actual emoji selected.
 */
const EmojiPickerIcon = ({
  onEmojiSelected,
  onEmojiPanelOpen
}: EmojiPickerIconProps) => {
  const text = getText();
  const localeText = useLocaleText();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    onEmojiPanelOpen?.();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'emoji-picker-popover' : undefined;
  return (
    <>
      <Tooltip title={t('emojiSelector:tooltip')}>
        <IconButton
          described-by={id}
          onClick={handleClick}
          aria-label={text.buttonLabel}
        >
          <AddReactionIcon sx={{ fontSize: '1rem' }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ zIndex: theme => theme.zIndex.tooltip + 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        // We need to stop propagation of the click event so that
        // the input doesn't keep stealing focus from the emoji picker search
        onClick={(e: SyntheticEvent) => {
          e.stopPropagation();
        }}
      >
        <Picker
          data={data}
          onEmojiSelect={(emoji: EmojiMartOnEmojiSelectProps) => {
            onEmojiSelected(emoji.native);
          }}
          // Force light theme to match our current UI.
          // Once we have dark mode we should switch to 'auto'
          theme="light"
          i18n={localeText}
        />
      </Popover>
    </>
  );
};
export default EmojiPickerIcon;
