import { useState } from 'react';
import { isEmpty } from 'lodash';
import { useLazyQuery } from '@apollo/client';

import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid-pro';

import { SortDirection } from 'src/generated/gql/graphql';
import Instrumentation from 'src/instrumentation';

import DataTable from 'src/components/DataTable/DataTable';
import Toolbar from 'src/components/DataTable/Toolbar/Toolbar';

import { getContentSelectorByArchitectureId } from './queries';
import { getContentRows, getTableColumns, buildContentFilter } from './utils';

interface ContentTableProps {
  architectureId: string;
  displayCollapseKey: string;
  setRowSelectionModel?: (newSelectionModel: any[]) => void;
  rowSelectionModel?: string[];
  minMaxSelection?: { min: number; max: number };
  setContentCountMeta?: (eventMeta: any) => void;
  initialFilters?: any;
  pageSize: number;
  showBorder: boolean;
}

const ContentTable = (props: ContentTableProps) => {
  const {
    architectureId,
    displayCollapseKey,
    rowSelectionModel = [],
    setRowSelectionModel,
    minMaxSelection,
    setContentCountMeta, // currently only used on the program selector to track amp events
    pageSize = 25,
    showBorder = true
  } = props;
  const singleSelect = minMaxSelection?.max === 1;
  const [dataTableColumns, setdataTableColumns] = useState<any[]>([]);

  const [queryGetContentByArchitectureId] = useLazyQuery(
    getContentSelectorByArchitectureId
  );

  const [endCursor, setEndCursor] = useState<string | undefined | null>();

  const loadMoreRows = async (
    reset: boolean,
    tablePageSize?: number,
    sortModel?: GridSortModel,
    filterModel?: GridFilterModel
  ) => {
    if (reset) {
      setEndCursor(undefined);
    }

    // we only allow one sort column at this time
    const sort = {
      direction: sortModel?.[0]?.sort as SortDirection,
      orderBy: sortModel?.[0]?.field || ''
    };

    const filter = filterModel ? buildContentFilter(filterModel) : undefined;

    // we don't want to pass the included IDs if we have an endCursor as that will
    // cause the api to return the IDs on each call
    const shouldIncludedSelectedRows =
      (!endCursor || reset) && !!rowSelectionModel;

    const variables = {
      ...(!isEmpty(filter) && { filter }),
      ...(!isEmpty(sortModel) && { sort }),
      ...(shouldIncludedSelectedRows && {
        alwaysInclude: {
          id: { in: rowSelectionModel }
        }
      }),
      architectureId,
      first: pageSize,
      groupKey: displayCollapseKey,
      endCursor: reset ? undefined : endCursor
    };

    try {
      const getResponse = await queryGetContentByArchitectureId({
        fetchPolicy: 'no-cache',
        variables
      });

      const catalogFieldMetadata =
        getResponse?.data?.architecture?.catalog?.fieldMetadata;
      const catalogContent =
        getResponse?.data?.architecture?.catalog?.contentV2;

      // Note: Filter out selected content when we have an endCursor to avoid duplicates.
      //       There is a case where content is alwaysIncluded and then on the next pages it
      //       will show up in the response again. This is b/c we are at the data's location in the
      //       "paged data" so we need to filter it out in that case.
      const catalogContentFiltered = !variables.endCursor
        ? catalogContent?.edges
        : catalogContent?.edges?.filter(content => {
            const contentItemId = content?.node?.items?.[0]?.id || '';

            return !rowSelectionModel?.includes(contentItemId);
          });

      const pageInfo = catalogContent?.pageInfo;

      const formattedColumns = getTableColumns(
        catalogFieldMetadata,
        singleSelect
      );

      setdataTableColumns(formattedColumns);

      setEndCursor(pageInfo?.endCursor);

      const rows = getContentRows(catalogContentFiltered);

      if (setContentCountMeta) {
        const eventMeta = {
          hasContent: !!rows?.length,
          contentCount: rows?.length
        };
        setContentCountMeta(eventMeta);

        Instrumentation.logEvent(
          Instrumentation.Events.ProgramBusinessObjectsSelectorClicked,
          eventMeta
        );
      }

      return {
        rows,
        hasMoreRows: pageInfo?.hasNextPage,
        rowSelectionModel
      };
    } catch (error) {
      return { rows: [], hasMoreRows: false };
    }
  };

  return (
    <DataTable
      columns={dataTableColumns}
      loadMoreRows={loadMoreRows}
      checkboxSelection={!isEmpty(minMaxSelection)}
      disableRowSelectionOnClick
      checkboxSelectionVisibleOnly
      rowSelection
      onRowSelectionModelChange={newSelection => {
        if (setRowSelectionModel) {
          if (newSelection.length > 0) {
            let updatedSelection = newSelection;

            // If the updated selection if it exceeds the allowed maximum
            // trim the earliest entries
            if (minMaxSelection && newSelection.length > minMaxSelection?.max) {
              updatedSelection = newSelection.slice(
                newSelection.length - minMaxSelection.max
              );
            }
            Instrumentation.logEvent(
              Instrumentation.Events.ProgramBusinessObjectSelectedClicked,
              { contentIds: updatedSelection }
            );
            // Update the state with the new selection model
            setRowSelectionModel(updatedSelection);
          } else {
            Instrumentation.logEvent(
              Instrumentation.Events.ProgramBusinessObjectSelectedClicked,
              { contentIds: newSelection }
            );
            setRowSelectionModel(newSelection);
          }
        }
      }}
      rowSelectionModel={rowSelectionModel}
      pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_COL_DEF.field] }}
      toolbar={Toolbar}
      scrollEndThreshold={10}
      hideSelectAll
      showBorder={showBorder}
      shouldAutosizeColumns
    />
  );
};

export default ContentTable;
