import { isEmpty, get, head } from 'lodash';
import { t } from 'i18next';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import HelpIcon from '@mui/icons-material/HelpOutline';

import ClientHtml from 'src/components/ClientHtml';
import { translateMaps } from 'src/common/templateTranslator';

const getLabelRowStyles = theme => {
  return {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(4)
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(1)
    }
  };
};

const useStyles = makeStyles(theme => ({
  container: {
    margin: `${theme.spacing(2)} 0`,
    width: '100%'
  },
  labelRoot: props => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
    ...(props.displayAsRow && getLabelRowStyles(theme))
  }),
  subLabel: {
    color: theme.palette.grey[700],
    fontSize: '0.8rem',
    overflow: 'hidden',
    width: '100%',
    wordBreak: 'break-word'
  },
  radio: {
    alignSelf: 'start',
    paddingTop: '5px'
  },
  errorText: {
    marginLeft: '0'
  },
  helperText: {
    margin: '0'
  },
  groupLabel: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '4px',
    width: '100%'
  },
  helpIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    width: '20px'
  }
}));

const RenderDynamicRadioGroup = props => {
  const {
    businessObjects,
    children,
    displayName,
    input,
    meta: { error, touched },
    options,
    helperText,
    tooltip,
    fullWidth, // was causing a warning when getting passed with ...rest
    userMetadataFields,
    readOnly = false,
    displayAsRow,
    ...rest
  } = props;
  const value = input.value.toString();

  const classes = useStyles({ displayAsRow });

  const exampleString = t('common:radioGroup.messages.example');

  return (
    <FormControl className={classes.container}>
      <FormLabel error={touched && !!error} className={classes.groupLabel}>
        {displayName}
        {tooltip && (
          <Tooltip title={tooltip}>
            <HelpIcon className={classes.helpIcon} />
          </Tooltip>
        )}
      </FormLabel>
      <RadioGroup
        {...rest}
        name={input.name}
        onChange={(event, value) => input.onChange(value)}
        value={value}
        row={displayAsRow}
      >
        {options.map(option => {
          const value = get(option, 'value');
          const renderValue = get(option, 'renderValue');
          let label = get(option, 'label');

          // If fieldName exists && we have BOs that means we have extra
          // data to display on the label.
          if (renderValue && !isEmpty(businessObjects)) {
            // combine BOs and userMetadataFields so we can use userMetadata in the input
            let translatedValue = translateMaps(value, {
              ...head(businessObjects),
              ...userMetadataFields
            });

            // If there's more than one businessObject, add "ex: " so the user
            // knows it's an example of one translation.
            if (businessObjects.length > 1) {
              translatedValue = `${exampleString}: ${translatedValue}`;
            }

            label = (
              <>
                <span>{`${label}`}</span>
                <br />
                <span className={classes.subLabel}>{translatedValue}</span>
              </>
            );
          }

          return (
            <FormControlLabel
              classes={{
                root: classes.labelRoot
              }}
              control={<Radio className={classes.radio} disabled={readOnly} />}
              key={`${value}`}
              label={label}
              value={value}
            />
          );
        })}
      </RadioGroup>
      {helperText && (
        <FormHelperText
          className={classes.helperText}
          error={touched && !!error}
          variant="outlined"
        >
          <ClientHtml html={helperText} />
        </FormHelperText>
      )}
      {touched && !!error && (
        <FormHelperText
          className={helperText ? classes.errorText : classes.helperText}
          error
          variant="outlined"
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderDynamicRadioGroup;
