import { t } from 'i18next';

import { Box, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';

const InvoiceOffer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        pt: 2,
        // Positioning to negate the "padding" added around the svg via the SVG viewBox
        left: '-2px',
        position: 'relative'
      }}
    >
      <DescriptionIcon />
      <Typography variant="body2">
        {t('programCreate:Checkout.invoiceBody')}
      </Typography>
    </Box>
  );
};

export default InvoiceOffer;
