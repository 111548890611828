import { useCallback, useState } from 'react';
import { getFormValues, reduxForm, SubmitHandler } from 'redux-form';
import { flow } from 'lodash';
import { useMutation } from '@apollo/client';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { connect, DefaultRootState } from 'react-redux';

import { Typography, Button, Paper, Box } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';

import { DynamicForm, FormAlert } from 'src/components/ReduxForm';
import Modal from 'src/components/Modal';
import {
  CopyToClipboardActionButton,
  CopyToClipboardContainer
} from 'src/components/CopyToClipboard/CopyToClipboard';
import Heading from 'src/components/PageElements/Heading';

import { cancelOrder as cancelOrderMutation } from './mutations';
import { cancelOrderInputs } from './utilities';

const FORM_NAME = 'adminCancelOrder';

interface FormValues {
  orderId?: string;
}

interface AdminCancelOrderProps {
  handleSubmit: SubmitHandler;
  formValues: FormValues;
  reset: () => void;
}

const pageText = () => ({
  modalHeader: t('admin:cancelOrder.modalHeader'),
  modalBody: t('admin:cancelOrder.modalBody'),
  closeButton: t('admin:cancelOrder.cancelButton'),
  cancelOrderButton: t('admin:cancelOrder.cancelOrderButton'),
  pageHeading: t('admin:headings.cancelOrder'),
  pageSubtitle: t('admin:subtitles.cancelOrder'),
  cancellationError: t('admin:cancelOrder.cancellationError'),
  cancelAnotherOrderButton: t('admin:cancelOrder.cancelAnotherOrderButton')
});

const AdminCancelOrder = ({
  handleSubmit,
  formValues,
  reset
}: AdminCancelOrderProps) => {
  const [open, setOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [cancelOrder, { loading, error }] = useMutation(cancelOrderMutation);

  const text = pageText();

  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  const onSubmit = useCallback(
    async data => {
      const { orderId } = data;

      const result = await cancelOrder({
        variables: {
          orderId
        }
      }).catch(() => {
        closeModal();
      });

      if (result) {
        setIsSuccess(true);
      }

      closeModal();
    },
    [cancelOrder]
  );

  const submitForm = () => {
    handleSubmit(onSubmit)(formValues);
  };

  const resetForm = () => {
    reset();
    setIsSuccess(false);
  };

  const isCancelDisabled = !formValues?.orderId?.trim() || loading;

  return (
    <>
      <Heading
        title={text.pageHeading}
        subTitle={text.pageSubtitle}
        pageTitle={text.pageHeading}
      />
      <form autoComplete="off">
        <Paper sx={theme => ({ mt: theme.spacing(2), p: theme.spacing(2) })}>
          {isSuccess ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme => theme.spacing(4),
                alignItems: 'flex-start'
              }}
            >
              <FormAlert severity="success">
                <Trans
                  i18nKey="admin:cancelOrder.successDescription"
                  values={{ orderId: formValues?.orderId }}
                  components={[
                    // Ignored these rules because the Trans component will render the anchor tag with content
                    // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
                    <a
                      href="https://evocalize.tryretool.com/apps/07229ba0-c4a4-11e9-ae3e-878392615fde/Office/Orders#order_id=&org="
                      target="_blank"
                      rel="noreferrer"
                    />
                  ]}
                />
              </FormAlert>
              <Box
                sx={{
                  display: 'flex',
                  gap: theme => theme.spacing(1)
                }}
              >
                <CopyToClipboardContainer>
                  <CopyToClipboardActionButton text={formValues?.orderId} />
                </CopyToClipboardContainer>
                <Button
                  data-cy="cancel-another-order-button"
                  variant="contained"
                  onClick={resetForm}
                >
                  {text.cancelAnotherOrderButton}
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {error && (
                <FormAlert severity="error">
                  <>
                    <Typography variant="body1">
                      {text.cancellationError}
                    </Typography>
                  </>
                </FormAlert>
              )}
              <div data-cy="admin-create-user-form">
                <DynamicForm inputs={cancelOrderInputs} />
              </div>
              <div>
                <Button
                  color="primary"
                  disabled={isCancelDisabled}
                  variant="contained"
                  data-cy="admin-cancel-order-button"
                  sx={{ mt: theme => theme.spacing(2) }}
                  onClick={openModal}
                >
                  {text.cancelOrderButton}
                </Button>
              </div>
            </>
          )}
        </Paper>
      </form>
      <Modal
        showClose
        open={open}
        onClose={closeModal}
        HeaderComponent={() => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: -1 }}>
            <CancelIcon />
            <Typography component="h1" variant="h6" sx={{ fontWeight: 600 }}>
              {text.modalHeader}
            </Typography>
          </Box>
        )}
        FooterComponent={() => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2
            }}
          >
            <Button onClick={closeModal}>{text.closeButton}</Button>
            <Button
              disabled={isCancelDisabled}
              variant="contained"
              onClick={submitForm}
              data-cy="admin-cancel-order-confirmation-button"
            >
              {text.cancelOrderButton}
            </Button>
          </Box>
        )}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: -0.5 }}>
          <Typography variant="body1">{text.modalBody}</Typography>
        </Box>
      </Modal>
    </>
  );
};

interface StateProps {
  formValues: FormValues;
}

interface OwnProps {
  handleSubmit: SubmitHandler;
}

const mapStateToProps = (state: DefaultRootState) => {
  const formValues = getFormValues(FORM_NAME)(state);

  return { formValues };
};

export default flow(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: true,
    initialValues: {
      orderId: ''
    }
  }),
  connect<StateProps, null, OwnProps>(mapStateToProps, null)
)(AdminCancelOrder);
