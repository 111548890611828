import { Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { startCase } from 'lodash';

import PageLinkCard from '../PageLinkCard';
import FacebookAuthButton from '../../FacebookAuthButton';
import ActionsContainer from '../ActionsContainer';

interface DeniedScopesProps {
  onCancel: () => void;
  deniedScopes: string[];
}

const DeniedScopes = ({ onCancel, deniedScopes = [] }: DeniedScopesProps) => {
  return (
    <PageLinkCard
      width={545}
      title={t('facebookSettings:addFacebookAccount.title')}
      actions={
        <ActionsContainer justifyContent="flex-end">
          <Button onClick={onCancel}>
            {t('facebookSettings:buttons.cancel')}
          </Button>
          <FacebookAuthButton
            text={t('facebookSettings:pageLinker.relinkButton')}
          />
        </ActionsContainer>
      }
    >
      <Typography sx={{ color: 'error.main' }}>
        <Trans i18nKey="facebookSettings:deniedScopes.listOfScopes" />
      </Typography>
      <Box component="ul" sx={{ pl: 2 }}>
        {deniedScopes.map(scope => {
          return (
            <Typography sx={{ color: 'error.main' }} component="li" key={scope}>
              {startCase(scope)}
            </Typography>
          );
        })}
      </Box>
      <Typography sx={{ color: 'error.main' }}>
        <Trans i18nKey="facebookSettings:deniedScopes.instructions" />
      </Typography>
    </PageLinkCard>
  );
};

export default DeniedScopes;
