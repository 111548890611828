import { createAction } from 'redux-actions';

export const actionConstants = {
  onGraphqlError: createAction('GLOBAL_GRAPHQL_ERROR'),
  clearErrors: createAction('GLOBAL_GRAPHQL_CLEAR_ERRORS')
};

export const onGraphqlError = ({ slug, query, variables, errorMessages }) =>
  actionConstants.onGraphqlError({ slug, query, variables, errorMessages });

export const clearErrors = () => actionConstants.clearErrors();
