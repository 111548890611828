import { flow, isEmpty, get, isArray } from 'lodash';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PageviewIcon from '@mui/icons-material/Pageview';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { isApolloLoading } from 'src/common/apollo';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Loading from 'src/components/Loading';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { usePagination } from 'src/components/Pagination/hooks';
import PaginationControls from 'src/components/Pagination/PaginationControls';
import Table from 'src/components/Table';
import IconLink from 'src/components/Table/IconLink';
import DateCell from 'src/components/Table/DateCell';
import { withFeatures } from 'src/components/Feature';

import Instrumentation from 'src/instrumentation';

import AdStatusCell from './AdStatusCell';
import SpendCell from './SpendCell';
import ChannelsCell from './ChannelsCell';

import { RESULTS_PER_PAGE } from './constants';
import { getProgramsv2Legacy } from './queries';

const DATE_FORMAT = 'L';

const styles = theme => ({
  container: { paddingBottom: theme.spacing(2) },
  root: {
    width: '100%'
  },
  highlight: {
    background: theme.palette.success[50]
  },
  tableTitle: {
    flexGrow: 1
  },
  icon: {
    width: '50px'
  }
});

const ProgramsTable = props => {
  const {
    architectureId,
    history,
    classes,
    loading,
    refetch,
    orders,
    features: { addProgramButton }
  } = props;
  const { navigateNext, navigatePrev } = usePagination({
    edges: orders?.edges,
    resultsPerPage: RESULTS_PER_PAGE,
    refetchCallback: refetch,
    refetchOptions: { architectureId }
  });
  const globalContext = useGlobalContext();
  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;

  const ordersPaginated = orders?.edges?.reduce(
    (orders, { node }) => [...orders, ...(node ? [node] : [])],
    []
  );

  const handelRowClick = ({ id }) => {
    const linkPath = generateLinkPath(paths.architecture.program, {
      architectureId,
      orderId: id
    });

    Instrumentation.logEvent(Instrumentation.Events.ViewProgramsClicked, {
      architectureId,
      type: 'program',
      programId: id
    });

    return history.push(linkPath);
  };

  // In some components we pass orders as an object with edges and pageInfo
  // for pagination and in other components we directly pass an array of orders
  // this constant will help us figure out that none of those are empty
  // and validate if should show empty status or not
  const hasOrders = !isEmpty(ordersPaginated) || isArray(orders);

  if (loading) {
    return <Loading />;
  }

  const columnSchema = [
    {
      key: 'view',
      CellComponent: ({ row }) => {
        const { id } = row;
        const linkPath = generateLinkPath(paths.architecture.program, {
          architectureId,
          orderId: id
        });

        const logClickEvent = () => {
          Instrumentation.logEvent(Instrumentation.Events.ViewProgramsClicked, {
            architectureId,
            type: 'program',
            programId: id
          });
        };

        return (
          <IconLink
            title={t('programs:programsTable.view')}
            icon={PageviewIcon}
            to={linkPath}
            logClickEvent={logClickEvent}
          />
        );
      }
    },
    {
      columnName: t('programs:programsTable.id'),
      accessor: 'id',
      type: 'ID'
    },
    {
      columnName: t('programs:programsTable.name'),
      accessor: 'name',
      type: 'STRING'
    },
    {
      columnName: t('programs:programsTable.spend'),
      accessor: 'billingDetails',
      type: 'STRING',
      CellComponent: SpendCell
    },
    {
      columnName: t('programs:programsTable.startDate'),
      accessor: r => get(r, 'billingDetails.startDate'),
      key: 'startDate',
      type: 'STRING',
      CellComponent: props => (
        <DateCell {...props} dateFormat={DATE_FORMAT} size="small" />
      )
    },
    {
      columnName: t('programs:programsTable.endDate'),
      accessor: 'billingDetails.endDate',
      type: 'STRING',
      CellComponent: props => (
        <DateCell {...props} dateFormat={DATE_FORMAT} size="small" />
      )
    },
    {
      columnName: t('programs:programsTable.renewsOn'),
      accessor: 'billingDetails.renewsOn',
      type: 'STRING',
      CellComponent: props => (
        <DateCell {...props} dateFormat={DATE_FORMAT} size="small" />
      )
    },
    ...(isTeamsEnabled
      ? [
          {
            columnName: t('programs:programsTable.initiatedBy'),
            accessor: 'user.name',
            type: 'STRING'
          }
        ]
      : []),
    {
      columnName: t('programs:programsTable.blueprint'),
      accessor: 'products[0].name',
      type: 'STRING'
    },
    {
      accessor: 'channels',
      columnName: t('programs:programsTable.channels'),
      type: 'STRING',
      CellComponent: ChannelsCell
    },
    {
      columnName: t('programs:programsTable.orderStatus'),
      accessor: 'orderItem.status',
      type: 'STATUS'
    },
    {
      columnName: t('programs:programsTable.adStatus'),
      accessor: 'orderItem',
      type: 'STATUS',
      CellComponent: AdStatusCell
    }
  ];

  let buttonText = null;
  let buttonTo = null;

  if (addProgramButton) {
    buttonText = t('programs:programsTable.createProgram');

    buttonTo = generateLinkPath(paths.architecture.programCreate, {
      architectureId
    });
  }
  return !loading && !hasOrders ? (
    <TableEmptyState
      buttonText={buttonText}
      buttonTo={buttonTo}
      emptyMessage={t('programs:programsTable.empty')}
    />
  ) : (
    <div className={classes.container}>
      <Paper className={classes.root}>
        <Table
          columnSchema={columnSchema}
          rows={ordersPaginated || orders}
          onClickBodyRow={handelRowClick}
          loading={!!loading}
        />
      </Paper>
      <PaginationControls
        pageInfo={orders?.pageInfo}
        navigateNext={navigateNext}
        navigatePrev={navigatePrev}
      />
    </div>
  );
};

export default flow(
  withStyles(styles),
  graphql(getProgramsv2Legacy, {
    name: 'programs',
    options: props => ({
      variables: {
        first: RESULTS_PER_PAGE,
        after: null,
        filter: {
          architectureIds: [props.architectureId],
          ...(props.productIds.length ? { productIds: props.productIds } : {})
        }
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    }),
    skip: ({ orders }) => {
      // skip if we pass orders ourself manually
      return !!orders;
    },
    props: ({ programs }) => {
      const { error, ordersV2, networkStatus, refetch } = programs;
      // Note: this is dumb hack but seems to be the only way to deal with the issues of apollo not
      //       triggering the loading state to change on setVariables when we change pages and the
      //       architectureId is updated.
      const loading = isApolloLoading(networkStatus);
      return { loading, error, orders: ordersV2, refetch };
    }
  }),
  withRouter,
  withFeatures
)(ProgramsTable);
