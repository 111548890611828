import { Box, Button } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import { t } from 'i18next';

import Modal, { ModalHeader } from 'src/components/Modal';
import { LoadingButton } from '@mui/lab';

interface SaveOverridesConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onDiscard: () => void;
  onSaveLoading: boolean;
  hasErrors?: boolean;
}

const SaveOverridesConfirmationModal = ({
  open,
  onClose,
  onSave,
  onDiscard,
  onSaveLoading,
  hasErrors
}: SaveOverridesConfirmationModalProps) => {
  return (
    <Modal
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      HeaderComponent={
        <ModalHeader icon={<SaveIcon />}>
          {t(
            'programCreate:manageMultiLocation.saveOverridesConfirmationTitle'
          )}
        </ModalHeader>
      }
      FooterComponent={
        <Box sx={{ display: 'flex', gap: 1.5 }}>
          <Button onClick={onClose} data-cy="location-override-cancel-button">
            {t(
              'programCreate:manageMultiLocation.saveOverridesConfirmationCancel'
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={onDiscard}
            data-cy="location-override-discard-button"
          >
            {t(
              'programCreate:manageMultiLocation.saveOverridesConfirmationDiscard'
            )}
          </Button>
          <LoadingButton
            loading={onSaveLoading}
            disabled={hasErrors}
            variant="contained"
            onClick={onSave}
            data-cy="location-override-save-button"
          >
            {t(
              'programCreate:manageMultiLocation.saveOverridesConfirmationSave'
            )}
          </LoadingButton>
        </Box>
      }
    >
      <Box sx={{ pb: 2 }}>
        {t(
          'programCreate:manageMultiLocation.saveOverridesConfirmationMessage'
        )}
      </Box>
    </Modal>
  );
};

export default SaveOverridesConfirmationModal;
