import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { lowerCase } from 'lodash';
import { useLocation } from 'react-router';
import { parse } from 'qs';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import SentryUtil from 'src/common/SentryUtil';
import Link from 'src/components/Link';

import { unsubscribeFromEmailNotifications } from './queries';

/*
  The Unsubscribe component is responsible for handling the unsubscribe process.
  It extracts the query parameters from the URL and passes them to the unsubscribeFromEmailNotifications query.
  The query is executed when the Unsubscribe button is clicked.
  If the query is successful, the component displays a success message.


  Manually testing this logged in as yourself can be tricky because you need to generate an hmac value.
  You can do this by going to: https://www.devglan.com/online-tools/hmac-sha256-online
  The secret key can be found in the lithium code base at: application-developmentCommon.yml => notifications.email.unsubscribeHmacSecret
  The hashed text is a valid userId

  You can also log in as the test user (userId=194) and use the HMAC from the unsubscribe_from_email.cy.ts file.
*/

const TOP_PAGE_SPACING = 200;

const Unsubscribe = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const searchParams =
    parse(location.search, {
      ignoreQueryPrefix: true
    }) || {};

  // For all intents and purposes, this query functions like a mutation!
  // It was written as a query on the BE
  const [unsubscribe, { loading, data, error }] = useLazyQuery(
    unsubscribeFromEmailNotifications
  );

  const category = searchParams?.category?.toString();
  const formattedCategory = lowerCase(category);

  const handleUnsubscribe = () => {
    unsubscribe({
      variables: {
        userId: searchParams?.userId?.toString(),
        category,
        hmac: searchParams?.hmac?.toString()
      }
    }).catch(error => {
      SentryUtil.captureException(error);
      enqueueSnackbar('An error occurred, please try again', {
        variant: 'error',
        preventDuplicate: true
      });
    });
  };

  useEffect(() => {
    if (error) {
      SentryUtil.captureException(error as any);
      enqueueSnackbar('An error occurred, please try again', {
        variant: 'error',
        preventDuplicate: true
      });
    }
  }, [error]);

  return (
    <Box
      sx={{
        height: `calc(100vh - ${TOP_PAGE_SPACING}px)`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1,
          maxWidth: 600,
          textAlign: 'center'
        }}
      >
        {!data && (
          <>
            <Typography component="h1" variant="h4">
              {t('notifications:unsubscribePage.headline')}
            </Typography>
            <Typography component="p" variant="body1" color="text.secondary">
              {t('notifications:unsubscribePage.subheadline', {
                category: formattedCategory
              })}
            </Typography>
            <LoadingButton
              data-cy="unsubscribe-from-email-button"
              sx={{ mt: 1 }}
              variant="contained"
              color="primary"
              onClick={handleUnsubscribe}
              loading={loading}
            >
              {t('notifications:unsubscribePage.unsubscribeButton')}
            </LoadingButton>
          </>
        )}
        {data && (
          <>
            <Typography component="h1" variant="h4">
              {t('notifications:unsubscribePage.successHeadline')}
            </Typography>
            <Typography component="p" variant="body1" color="text.secondary">
              <Trans
                i18nKey="notifications:unsubscribePage.successSubheadline"
                components={[<Link to="/account/notifications" refresh />]}
              />
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Unsubscribe;
