import { gql } from 'src/generated/gql';

export const updateAuth0Config = gql(`
  mutation updateAuth0Config($updateInput: UpdateAuth0ConfigInput!) {
    updateAuth0Config(updateInput: $updateInput) {
      id
    }
  }
`);

export const createAuth0Config = gql(`
  mutation createAuth0Config($createInput: CreateAuth0ConfigInput!) {
    createAuth0Config(createInput: $createInput) {
      id
    }
  }
`);
