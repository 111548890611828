import { union } from 'lodash';

import {
  getChannelIconByType,
  getAllChannels
} from 'src/components/BlueprintIcon/BlueprintIconUtil';

const ChannelsCell = ({ data = [], inline = false }) => {
  // we combine all the addtypes into one array of unique channels
  const channels = union(...data.map(c => c.channels));
  return (
    <span>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          padding: '5px',
          width: '100%',
          justifyContent: 'center',
          ...(inline && {
            justifyContent: 'left',
            display: 'inline-flex',
            width: 'auto'
          })
        }}
        key="channels"
      >
        {getAllChannels(channels).map(channel => {
          return (
            <span
              style={{
                padding: '5px',
                width: '30px'
              }}
              key={channel}
            >
              {getChannelIconByType(channel)}
            </span>
          );
        })}
      </div>
    </span>
  );
};

export default ChannelsCell;
