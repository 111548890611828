import { find } from 'lodash';
import { isFacebookSelectionUsable } from 'src/common/FacebookUtil';
import { FACEBOOK_DEFAULT_PAGE_ID } from 'src/common/userSettings';
import { GetFacebookPageSelectionsQuery } from 'src/generated/gql/graphql';

interface GetDefaultFacebookPageArgs {
  eligibleLinkedFacebookPage: any;
  orgDefaultFacebookPage: any;
  facebookPageSelections: GetFacebookPageSelectionsQuery['getFacebookPageSelections'];
  globalContext: any;
  isLeadFormFacebookBlueprint: boolean;
}

export const getDefaultFacebookPage = ({
  eligibleLinkedFacebookPage,
  orgDefaultFacebookPage,
  facebookPageSelections,
  globalContext,
  isLeadFormFacebookBlueprint
}: GetDefaultFacebookPageArgs) => {
  let defaultFacebookPage = eligibleLinkedFacebookPage;

  if (
    orgDefaultFacebookPage &&
    isFacebookSelectionUsable(orgDefaultFacebookPage)
  ) {
    defaultFacebookPage = orgDefaultFacebookPage;
  }

  // user set default set from /#/facebook/pages
  const userDefaultFacebookPageId =
    globalContext?.me?.settings?.[FACEBOOK_DEFAULT_PAGE_ID];

  const userDefaultFacebookPage = find(
    facebookPageSelections,
    // pageSettingsId is the id from the old facebookV2 this is the legacy id used for user default page
    p =>
      p.pageSettingsId === userDefaultFacebookPageId ||
      p.pageGroupId === userDefaultFacebookPageId
  );

  if (
    userDefaultFacebookPage &&
    isFacebookSelectionUsable(
      userDefaultFacebookPage,
      isLeadFormFacebookBlueprint
    )
  ) {
    defaultFacebookPage = userDefaultFacebookPage;
  }

  const nonSystemFacebookPage = find(
    facebookPageSelections,
    p => !p?.isOrgDefault
  );

  if (
    nonSystemFacebookPage &&
    isFacebookSelectionUsable(
      nonSystemFacebookPage,
      isLeadFormFacebookBlueprint
    )
  ) {
    defaultFacebookPage = nonSystemFacebookPage;
  }

  return defaultFacebookPage;
};
