import { useState, useEffect, createContext, ReactNode } from 'react';
import { Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import SentryUtil from 'src/common/SentryUtil';
import {
  extractPaymentMethodId,
  genericCardDeclinedError,
  paymentErrorByBackendDisplayCode,
  incompleteCardNumberError
} from 'src/common/paymentUtils';
import useSetStripeElementLocale from 'src/hooks/useSetStripeElementLocale';

import { programErrorTypes } from 'src/pages/Program/Constants';
import { getPaymentMethods } from 'src/components/Checkout/queries';

import { addPaymentMethod } from './mutations';

interface GraphQLError {
  extensions: {
    errorName: string;
    additionalExceptionDetails: {
      displayCode: string;
    };
  };
}

interface GraphQLResponseError {
  graphQLErrors: GraphQLError[];
}

interface CreditCardProvider {
  children: ReactNode;
}

type ContextType = {
  handleAddCard: (supressError?: boolean) => Promise<void>;
  updatingPayment: boolean;
  stripeError: string | null;
  addPaymentMethodModal: boolean;
  setAddPaymentMethodModal: (value: boolean) => void;
  toggleAddPaymentModal: () => void;
  stripeLoading: boolean;
  setStripeLoading: (value: boolean) => void;
  onStripeReady: () => void;
  updateSourceId: (e: any, value: any) => void;
};

export const CreditCardContext = createContext<ContextType | undefined>(
  undefined
);

const CreditCardProvider = ({ children }: CreditCardProvider) => {
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const { setValue, clearErrors } = useFormContext();
  const stripeSourceIdValue = useWatch({
    name: 'spendStep.stripeSourceId',
    defaultValue: ''
  });

  useSetStripeElementLocale({ elements });

  const { data: paymentMethods, refetch: paymentMethodsRefetch } =
    useQuery(getPaymentMethods);

  const allPaymentMethods = paymentMethods?.paymentMethod || [];

  const [addPaymentMutation] = useMutation(addPaymentMethod);

  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);
  const toggleAddPaymentModal = () => {
    setAddPaymentMethodModal(!addPaymentMethodModal);
  };

  const [updatingPayment, setUpdatingPayment] = useState(false);
  const [stripeError, setStripeError] = useState<string | null>(null);

  const [stripeLoading, setStripeLoading] = useState(true);
  const onStripeReady = () => {
    setStripeLoading(false);
  };

  const captureCardError = (error: Error, context?: string) => {
    // Capture the error in Sentry if the URL contains the debug flag.
    if (location.search.includes('sentry-stripe-debug')) {
      SentryUtil.captureException(error, {
        extra: {
          message: context || ''
        }
      });
    }
  };

  const handleAddCard = async (
    supressError = false // if we are skipping steps we don't want to visually show errors
  ): Promise<any> => {
    setAddPaymentMethodModal(false);
    setStripeError(null);

    setUpdatingPayment(true);

    const cardElement = (elements as StripeElements).getElement(CardElement);
    let stripeSource;

    // 1. Call Stripe directly to create the source.
    try {
      stripeSource = await (stripe as Stripe).createSource(
        cardElement as StripeCardElement,
        { type: 'card' }
      );
    } catch (error) {
      if (!supressError) {
        enqueueSnackbar(genericCardDeclinedError(), {
          variant: 'error'
        });
        setStripeError(genericCardDeclinedError());
      }
      captureCardError(error as Error, 'Failed while creating stripe source');

      setUpdatingPayment(false);

      // Return the stripeSource (containing the error) so
      // handleNextWithValidation can check the value to determine if it
      // should allow the user to go to the next stage.
      return stripeSource;
    }

    // This will catch when the stripe.createSource call doesn't throw an
    // exception but still has an error.
    if (stripeSource?.error) {
      let message = genericCardDeclinedError();

      if (stripeSource?.error?.code === 'incomplete_number') {
        message = incompleteCardNumberError();
      }

      setUpdatingPayment(false);

      if (!supressError) {
        enqueueSnackbar(message, {
          variant: 'error'
        });
        setStripeError(message);
      }

      captureCardError(
        stripeSource.error as any,
        'Stripe source error after creating source'
      );

      // Return the stripeSource (containing the error) so
      // handleNextWithValidation can check the value to determine if it
      // should allow the user to go to the next stage.
      return stripeSource;
    }

    const stripeSourceId = stripeSource?.source.id;

    // 2. Now make the request to our API to add the credit card to the
    //    user's account.
    try {
      await addPaymentMutation({
        variables: {
          stripeSourceId
        }
      });
    } catch (error) {
      const errorName = (error as GraphQLResponseError)?.graphQLErrors[0]
        ?.extensions?.errorName;
      const errorDisplayCode = (error as any)?.graphQLErrors[0]?.extensions
        ?.additionalExceptionDetails?.displayCode;

      captureCardError(
        error as Error,
        `Failed while adding payment method. error:${errorName} - displaycode: ${errorDisplayCode}`
      );

      let errorMessage = genericCardDeclinedError();

      // if card declined / billing error display our specific error message
      if (
        errorName === programErrorTypes.billingException ||
        errorName === programErrorTypes.paymentAuthorizationException
      ) {
        errorMessage =
          paymentErrorByBackendDisplayCode(errorDisplayCode) ||
          genericCardDeclinedError();

        enqueueSnackbar(<span>{errorMessage}</span>, {
          variant: 'error'
        });
      }

      setUpdatingPayment(false);
      setStripeError(errorMessage);

      return error;
    }

    // 3. Get the new card data to keep the UI in sync with the db.
    await paymentMethodsRefetch();

    // 4. Extract the new payment method's ID from the refresh response.
    const updatedPaymentMethods = await paymentMethodsRefetch();
    const paymentMethodId = extractPaymentMethodId(
      updatedPaymentMethods?.data?.paymentMethod,
      stripeSourceId
    );

    // 5. Update the component with the new card's IDs.
    setValue('spendStep.stripeSourceId', stripeSourceId);

    setValue('spendStep.paymentMethodId', paymentMethodId);

    // clear the submit errors that we set when a card has been declined
    clearErrors();
    // 6. Finally, unset loading state now that we've succeeded.
    setUpdatingPayment(false);
  };

  const updateSourceId = (e: any, value: any) => {
    // Note: since several APIs rely on the
    // stripeSourceId but the orderPlacement API relies
    // on the paymentMethodId, we set both of them here
    // manually. You'll notice we also have a
    // hidden field for paymentMethodId to ensure it
    // gets passed along on form submit.
    const paymentMethodId = extractPaymentMethodId(allPaymentMethods, value);

    setValue('spendStep.paymentMethodId', paymentMethodId);

    setValue('spendStep.stripeSourceId', value);

    // clear the submit errors that we set when a card has been declined
    clearErrors();
  };

  // if the user switches to a card offer, select the first one
  useEffect(() => {
    if (
      allPaymentMethods &&
      allPaymentMethods.length > 0 &&
      !stripeSourceIdValue
    ) {
      updateSourceId(null, allPaymentMethods[0].stripeSourceId);
    }
  }, [allPaymentMethods]);

  return (
    <CreditCardContext.Provider
      value={{
        handleAddCard,
        updatingPayment,
        stripeError,
        addPaymentMethodModal,
        setAddPaymentMethodModal,
        toggleAddPaymentModal,
        stripeLoading,
        setStripeLoading,
        onStripeReady,
        updateSourceId
      }}
    >
      {children}
    </CreditCardContext.Provider>
  );
};

export default CreditCardProvider;
