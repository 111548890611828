import ClientHtml from 'src/components/ClientHtml';
import { FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  helperText: {
    marginLeft: '14px'
  }
});

export interface HelperTextFooterProps {
  helperText?: string | HTMLElement;
  touched: boolean;
  error?: Error;
  variant?: 'standard' | 'outlined' | 'filled';
}

/**
 * Renders the helper text footer for redux form components in a consistent way.
 */
export const HelperTextFooter = ({
  helperText,
  touched,
  error,
  variant = 'outlined'
}: HelperTextFooterProps) => {
  const classes = useStyles();
  if (helperText == null) {
    return null;
  }
  return (
    <ClientHtml
      html={helperText}
      element={props => (
        <FormHelperText
          className={classes.helperText}
          error={touched && !!error}
          variant={variant}
          {...props}
        />
      )}
    />
  );
};
