import { useState, useEffect, useRef } from 'react';
import { t } from 'i18next';

import { Box, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import Heading from 'src/components/PageElements/Heading';
import { useModalState } from 'src/components/Modal';
import {
  calculateRowsInView,
  WindowDimensionsInterface
} from 'src/components/DataTable/helpers';

import ProgramsTable from './ProgramsTable';
import SelectQuickStartModal from './SelectQuickStartModal';

const pageText = () => {
  return {
    title: t('programs:headers.title'),
    subTitle: t('programs:headers.subTitle'),
    ctaButton: t('programs:headers.ctaButton')
  };
};

const defaultPageSize = 25;
// offset hight from the top of the page to the top of the table
const offestHeight = 330;
const standardRowHeight = 50;

const Programs = () => {
  const text = pageText();
  const { openModal, closeModal, open } = useModalState();

  const [windowDimensions, setWindowDimensions] =
    useState<WindowDimensionsInterface>({
      height: window.innerHeight - offestHeight
    });

  // We are calculating the page size based on the window height so the table
  // will fill the screen & the onscroll loadMoreRows will work properly
  const pageSize = useRef(
    calculateRowsInView(windowDimensions, defaultPageSize, standardRowHeight)
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ height: window.innerHeight - offestHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    pageSize.current = calculateRowsInView(
      windowDimensions,
      defaultPageSize,
      standardRowHeight
    );
  }, [windowDimensions]);

  return (
    <div>
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
        divider
      />

      <Button
        variant="contained"
        color="primary"
        endIcon={<AddIcon />}
        onClick={openModal}
        data-amp-click-new-program
        sx={{ margin: '8px 0' }}
      >
        {text.ctaButton}
      </Button>

      <SelectQuickStartModal
        open={open}
        onClose={closeModal}
        isSupervisable={false}
      />

      <Box
        sx={{
          height: windowDimensions.height,
          marginTop: '20px',
          minHeight: '400px'
        }}
      >
        <ProgramsTable pageSize={pageSize.current} />
      </Box>
    </div>
  );
};

export default Programs;
