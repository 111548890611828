import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import { validateRequired } from 'src/common/validations';

export const CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION = {
  slug: 'organization',
  friendlyName: 'Organization'
};

export const CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT = {
  slug: 'project',
  friendlyName: 'Project'
};

export const ALL_CHANNEL_SPEND_ALLOCATION_CONFIG_TYPES = [
  CHANNEL_SPEND_ALLOCATION_TYPE_ORGANIZATION,
  CHANNEL_SPEND_ALLOCATION_TYPE_PROJECT
];

export const getChannelAllocationWeightInputs = weights => [
  {
    name: 'enabled',
    displayName: 'Enabled',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  ...weights.map(weight => {
    return {
      name: weight.channelCode,
      displayName: weight.channelCode,
      displayMethodId: INPUT_TYPES.POSITIVE_INT,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 1
        }
      }
    };
  })
];

export const getOrganizationChannelAllocationConfigTypesInputs =
  organizationNames => [
    {
      name: 'organizationName',
      displayName: 'Organization',
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      reduxValidations: [validateRequired],
      isHidden: false,
      isRequired: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          options: organizationNames
        }
      }
    }
  ];

export const getProjectChannelAllocationConfigTypesInputs = () => [
  {
    name: 'projectId',
    displayName: 'Project ID',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    reduxValidations: [validateRequired],
    isHidden: false,
    isRequired: true,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];
