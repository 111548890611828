import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Box, Link } from '@mui/material';
import { useRouteMatch } from 'react-router';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

const ProgramNameColumn = ({ row, rowNode }: GridRenderCellParams) => {
  const match = useRouteMatch();
  const isParent = match.path === paths.architecture.multiLocationProgram;
  const hasMultiLocationProgramData =
    row?.multiLocationProgram?.architectureId && row?.multiLocationProgram?.id;

  if (rowNode.type === 'group') {
    return rowNode.groupingKey;
  }

  return (
    <span>
      {row?.name}
      {row?.belongsToMultiLocationProgram && !isParent && (
        <Box
          component="span"
          sx={{ fontSize: '10px', display: 'block', paddingTop: '3px' }}
        >
          From:{' '}
          {hasMultiLocationProgramData ? (
            <Link
              href={`#${generateLinkPath(
                paths.architecture.multiLocationProgram,
                {
                  architectureId: row?.multiLocationProgram?.architectureId,
                  orderId: row?.multiLocationProgram?.id
                }
              )}`}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {row?.multiLocationProgram?.name}{' '}
            </Link>
          ) : (
            t('programPerf:MLP.defaultName')
          )}
        </Box>
      )}
    </span>
  );
};

export default ProgramNameColumn;
