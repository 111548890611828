import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { Events } from './constants';

const AMPLITUDE_API_KEY = import.meta.env.EVOCALIZE_AMPLITUDE_API_KEY;

/**
 * A set of events to not send to Amplitude.
 */
const DISABLED_EVENTS = { [Events.GraphQLRequest]: true };

/**
 * A helper function to safely use the amplitude client instance.
 * @param {(amplitude: AmplitudeClient) => void} fn A function to invoke with the amplitude client.
 */
const withAmplitude = fn => {
  const instance = AMPLITUDE_API_KEY && amplitude;

  if (instance) {
    return fn(instance);
  }
};

const onExitPage = () =>
  withAmplitude(amplitude => {
    amplitude.track(Events.PageExit, {
      // Normally we would be using react-router's useLocation hook
      // but this needs to be usable in any location, so we've gotta
      // fallback to the window property.
      // Please don't copy this! Use useLocation instead!
      path: window.location.pathname
    });
  });

const initialize = settings =>
  withAmplitude(amplitude => {
    const initPromise = amplitude.init(
      AMPLITUDE_API_KEY,
      settings.userId, // user id (we don't know it yet)
      {
        onExitPage,
        defaultTracking: {
          sessions: true
        }
        // autocapture: {
        //   elementInteractions: true
        // }
        // We cannot use includeUtm because it does not support
        // parsing from the hash fragment. Which is what react router uses.
        // See store.js for the query param -> hash conversion logic.
        // Instead, we place these in setUserProperties
        // includeUtm: true
      }
    ).promise;

    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 0.25
    });
    // returns a promise
    const replayPromise = amplitude.add(sessionReplayTracking).promise;
    return Promise.all([initPromise, replayPromise]);
  });

const setUserProperties = (userId, userProperties) =>
  withAmplitude(amplitude => {
    const identify = new amplitude.Identify();
    identify
      .set('organizationId', userProperties.organizationId)
      .set('organizationSlug', userProperties.organizationSlug)
      // must be snake case to match amplitude
      .set('initial_utm_source', userProperties.initialUtmSource)
      .set('initial_utm_medium', userProperties.initialUtmMedium)
      .set('initial_utm_campaign', userProperties.initialUtmCampaign)
      .set('initial_utm_term', userProperties.initialUtmTerm)
      .set('initial_utm_content', userProperties.initialUtmContent);
    return amplitude.identify(identify).promise;
  });

const setGroup = (groupType, groupName) => {
  withAmplitude(amplitude => {
    return amplitude.setGroup(groupType, groupName).promise;
  });
};

const logEvent = (event, eventProperties) =>
  withAmplitude(amplitude => {
    if (!DISABLED_EVENTS[event]) {
      amplitude.track(event, eventProperties);
    }
  });

// Change these with care, they are consumed by the backend to correlate
// instrumentation events.
const AMPLITUDE_SESSION_ID_HEADER_VALUE_NAME = 'amplitudeSessionId';
const AMPLITUDE_DEVICE_ID_HEADER_VALUE_NAME = 'amplitudeDeviceId';

const getContextHeaderMap = () =>
  withAmplitude(amplitude => ({
    [AMPLITUDE_SESSION_ID_HEADER_VALUE_NAME]: amplitude.getSessionId(),
    [AMPLITUDE_DEVICE_ID_HEADER_VALUE_NAME]: amplitude.getDeviceId()
  }));

export default {
  initialize,
  setUserProperties,
  logEvent,
  getContextHeaderMap,
  setGroup
};
