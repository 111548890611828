import { t } from 'i18next';

import { Box } from '@mui/material';

import Heading from 'src/components/PageElements/Heading';

import PromotionsTable from './PromotionsTable';

// TODO: 1. Determine what totalDollarDiscount means/does - does it have any
//          effect on percentDiscount?
//          * This is the maximum pool of redemption money allowed for all
//            redemptions - i.e. if totalDollarDiscount is $100 and 2 users
//            redeem their promos for $50 each, the total amount will be $100
//            and the rest of promotion applications will fail because it's been
//            used.
// TODO: 2. Need from Backend: totalRedemptions (total number of promotion
//          redemptions for each promotion).
// TODO: 3. Set up menu item system for "org admin" concept (different from
//          ev admin/super admin).
//          * from jwt:   "li_organization_role": "admin"

const AdminPromotions = () => {
  return (
    <>
      <Heading
        title={t('adminPromotions:title')}
        subTitle={t('adminPromotions:subTitle')}
        pageTitle={t('adminPromotions:title')}
      />

      <Box
        sx={{
          margin: theme => `${theme.spacing(3)} 0`
        }}
      >
        <PromotionsTable />
      </Box>
    </>
  );
};

export default AdminPromotions;
