import { gql } from 'src/generated/gql';

export const activateUserByEmail = gql(`
  mutation ActivateUserByEmail($emailAddress: String!, $organizationId: ID!) {
    activateUserByEmail(
      emailAddress: $emailAddress
      organizationId: $organizationId
    )
  }
`);

export const redeemTokenAndSetPassword = gql(`
  mutation RedeemTokenAndSetPassword(
    $activationToken: String!
    $password: String!
  ) {
    redeemTokenAndSetPassword(
      activationToken: $activationToken
      password: $password
    ) {
      evAccessToken
      auth0AccessToken
      auth0IdToken
    }
  }
`);
