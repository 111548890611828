import { formatDate } from 'src/common/dates';

const styles = {
  small: {
    width: '100px'
  },
  medium: {
    width: '150px'
  },
  inherit: {
    width: 'inherit'
  }
};

const DateCell = ({ data, dateFormat, size = 'medium' }) => {
  return (
    <div style={{ ...styles[size] }}>
      {formatDate({ date: data, format: dateFormat })}
    </div>
  );
};

export default DateCell;
