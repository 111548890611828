interface GetChangeFormValueArgs {
  reduxChange: (form: string, name: string, value: any) => void;
  hookSetValue?: (name: string, value: any) => void;
}

type ChangeFunction = (form: string, name: string, value: any) => void;

export const getChangeFormValue = ({
  reduxChange,
  hookSetValue
}: GetChangeFormValueArgs): ChangeFunction => {
  if (!reduxChange && !hookSetValue) {
    throw new Error('Must provide either reduxChange or hookSetValue');
  }
  if (hookSetValue) {
    return (form: string, name: string, value: any) =>
      hookSetValue(name, value);
  }
  return reduxChange;
};

interface GetRegisterFieldArgs {
  registerFieldRedux: (form: string, name: string, value: any) => void;
  hookRegister?: (name: string) => void;
}

export const getRegisterField = ({
  registerFieldRedux,
  hookRegister
}: GetRegisterFieldArgs) => {
  return hookRegister
    ? (form: string, name: string) => hookRegister(name)
    : registerFieldRedux;
};
