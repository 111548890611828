import { createAction } from 'redux-actions';

export const galleryConstants = {
  initialSelectGallery: createAction('INITIAL_SELECT_GALLERY'),
  removeUploadStatus: createAction('REMOVE_GALLERY_UPLOAD_STATUS'),
  clearUploadStatus: createAction('CLEAR_GALLERY_UPLOAD_STATUS'),
  selectGallery: createAction('SELECT_GALLERY'),
  selectSubGallery: createAction('SELECT_SUB_GALLERY'),
  setUploadStatus: createAction('SET_GALLERY_UPLOAD_STATUS'),
  selectGalleryItem: createAction('SELECT_GALLERY_ITEM'),
  deselectGalleryItem: createAction('DESELECT_GALLERY_ITEM'),
  clearSelectedGalleryItems: createAction('CLEAR_SELECTED_GALLERY_ITEMS')
};

export const initialSelectGallery =
  (selectedGallery, selectedGalleryIndex) => dispatch => {
    dispatch(
      galleryConstants.initialSelectGallery({
        selectedGallery,
        selectedGalleryIndex
      })
    );
  };

export const selectGallery =
  (selectedGallery, selectedGalleryIndex) => dispatch => {
    dispatch(
      galleryConstants.selectGallery({
        selectedGallery,
        selectedGalleryIndex
      })
    );
  };

export const selectSubGallery = scope => dispatch => {
  dispatch(
    galleryConstants.selectSubGallery({
      scope
    })
  );
};

export const setUploadStatus = statusObject => dispatch => {
  dispatch(
    galleryConstants.setUploadStatus({
      statusObject
    })
  );
};

export const removeUploadStatus = assetId => dispatch => {
  dispatch(
    galleryConstants.removeUploadStatus({
      assetId
    })
  );
};

export const clearUploadStatus = () => dispatch => {
  dispatch(galleryConstants.clearUploadStatus());
};

export const selectGalleryItem = assetUrl => dispatch => {
  dispatch(
    galleryConstants.selectGalleryItem({
      assetUrl
    })
  );
};

export const deselectGalleryItem = assetUrl => dispatch => {
  dispatch(
    galleryConstants.deselectGalleryItem({
      assetUrl
    })
  );
};

export const clearSelectedGalleryItems = () => dispatch => {
  dispatch(galleryConstants.clearSelectedGalleryItems());
};
