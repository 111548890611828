import { get } from 'lodash';

import { getOrganizationIdFromToken } from 'src/Auth/AuthUtil';

class FacebookTracker {
  constructor(evPixelId, clientPixelId) {
    this.name = 'FacebookTracker';
    this.pixelIds = [];

    // Evocalize Facebook Pixel ID - this comes from the .env file.
    if (import.meta.env.EVOCALIZE_FACEBOOK_PIXEL_ID) {
      this.pixelIds.push(import.meta.env.EVOCALIZE_FACEBOOK_PIXEL_ID);
    }

    // EV white-label-specific Facebook Pixel ID - this comes from the skin.
    if (evPixelId) {
      this.pixelIds.push(evPixelId);
    }

    // Client Facebook Pixel ID - this comes from the skin.
    if (clientPixelId) {
      this.pixelIds.push(clientPixelId);
    }

    // Stop here if we don't have any Pixel IDs.
    if (!this.hasPixelIds()) {
      return;
    }

    /* eslint-disable */
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod(...arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    /* eslint-enable */

    this.fbq = window.fbq;
  }

  hasPixelIds = () => {
    return this.pixelIds.length > 0;
  };

  init = () => {
    if (this.hasFbq() && this.hasPixelIds()) {
      this.pixelIds.forEach(pixelId => {
        this.fbq('init', pixelId);
      });

      this.initialized = true;

      // Log the initial page view.
      this.pageView();
    }
  };

  pageView = () => {
    /* eslint-disable no-unused-expressions */
    this.isEnabled() && this.fbq('track', 'PageView');
  };

  trackPurchase = data => {
    if (!this.isEnabled()) {
      return;
    }

    const trackingData = this.generateTrackingData(data);

    // For purchases, we send a view content on the order for tracking
    // purposes so our clients have the option to advertise to their users
    // based on products they purchase, etc.
    // this.fbq('track', 'ViewContent', trackingData);
    this.trackOrderView(data);

    // Now track the purchase as well.
    this.fbq('track', 'Purchase', trackingData);
  };

  generateTrackingData = data => {
    return {
      content_ids: [get(data, 'orderId')],
      price: get(data, 'paymentAmount'),
      currency: 'USD',
      offer_id: get(data, 'offerId'),
      stripe_plan_id: get(data, 'chosenStripePlanId'),
      order_id: get(data, 'orderId'), // Duplicated but more specific
      program_id: get(data, 'programId'),
      group_id: get(data, 'groupId'),
      architecture_id: get(data, 'architectureId'),
      organization_id: getOrganizationIdFromToken(),
      user_id: get(data, 'userId')
    };
  };

  trackOrderView = data => {
    if (!this.isEnabled()) {
      return;
    }

    const trackingData = {
      ...this.generateTrackingData(data),
      content_type: 'product'
    };

    this.fbq('track', 'ViewContent', trackingData);
  };

  trackCancelOrder = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'OrderCancel', data);
  };

  trackQuickStart = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'GetStarted', data);
  };

  trackProgramPreviewVisibility = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'PreviewVisibility', data);
  };

  trackProgramChooseBlueprintModal = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramChooseBlueprintModal');
  };

  trackProgramLinkFacebookPage = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramLinkFacebookPage');
  };

  trackProgramSelectFacebookPage = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramSelectFacebookPage');
  };

  trackProgramStartStep = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramSelectStep', {
      event_category: 'create_ad_open_step_1',
      event_label: 'initial_load'
    });
  };

  trackProgramSelectStep = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramSelectStep', data);
  };

  trackProgramSubmitPromoCode = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramSubmitPromoCode', data);
  };

  trackEditProgram = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'EditProgram');
  };

  trackEditProgramActions = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'EditProgramActions', data);
  };

  trackCancelProgram = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'EditProgramCancel', data);
  };

  trackProgramsAddNew = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ProgramsAddNew', data);
  };

  trackViewProgram = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ViewProgram', data);
  };

  trackGoogleAdDetails = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'GoogleAdDetails', data);
  };

  trackContacts = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'Contacts', data);
  };

  trackGallery = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'Gallery', data);
  };

  trackAudience = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'Audience', data);
  };

  trackManageFacebookPages = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'ManageFacebookPages', data);
  };

  trackSupport = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'SupportMenus', data);
  };

  trackAccountMenu = data => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'Account', data);
  };

  trackBillingHistory = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'BillingHistoryRowClick');
  };

  trackNotificationPreferences = () => {
    if (!this.isEnabled()) {
      return;
    }

    this.fbq('trackCustom', 'NotificationPreferences');
  };

  trackAudiences = data => {
    if (!this.isEnabled()) {
      return;
    }

    switch (data.action) {
      case 'add':
        this.fbq('trackCustom', 'AddAudience');
        break;
      case 'delete':
        this.fbq('trackCustom', 'DeleteAudience');
        break;
      case 'deleteConfirm':
        this.fbq('trackCustom', 'DeleteConfirmAudience');
        break;
      default:
        this.fbq('trackCustom', 'Audience');
        break;
    }
  };

  hasFbq = () => {
    return this.fbq !== null && this.fbq !== undefined;
  };

  isEnabled = () => {
    return this.hasFbq() && this.initialized;
  };
}

export default FacebookTracker;
