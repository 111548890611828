export const demoUserIds = {
  '313113878496086991': true,
  '330494701146841934': true,
  '330356342667715371': true,
  '489648141717584009': true,
  '742172094669866655': true,
  '944732821832938712': true, // tech+google | Production
  '944730710537750136': true, // tech+google | Staging
  '947703990936762037': true, // tech+gteam | Production
  '947706784276112353': true, // tech+gteam | Staging
  '1168070307009865951': true
};
