import { isEmpty } from 'lodash';
import { History } from 'history';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { OrderPlacementMutation } from 'src/generated/gql/graphql';
import { TrackUserflowEvent } from 'src/PostAuthGlobals';

import { submitProgramPropsInterface } from './useSubmitHandlerByType';
import { trackOrderPlacementProgram } from './trackOrders';
import { extractProgramMutationParams } from './utils';

// TODO: update type interface
export interface submitCreateProgramDataInterface {
  [any: string]: any;
}

export interface createResponseInterface {
  data: OrderPlacementMutation;
}

export interface createProgramSubmitDataInterface {
  data: submitCreateProgramDataInterface;
  programProps: submitProgramPropsInterface;
  isClone: boolean;
  draftId?: string;
  mutation: any;
  history: History;
  enqueueSnackbar: any;
  trackUserflowEvent: TrackUserflowEvent;
}

export const createProgramHandleSubmit = async ({
  data,
  programProps,
  isClone,
  draftId,
  mutation,
  history,
  enqueueSnackbar,
  trackUserflowEvent
}: createProgramSubmitDataInterface) => {
  const {
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    userMetadataFields
  } = programProps;

  const promoCodes = data?.spendStep?.promoCodes;

  const mutationParams = extractProgramMutationParams({
    data,
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    userMetadataFields,
    draftId
  });

  // if promoCodes add them
  if (!isEmpty(promoCodes)) {
    mutationParams.promoCode = promoCodes?.[0]?.promoCode;
  }

  let response: createResponseInterface;

  const postErrorMessage = t('programCreate:snackbar.submitError');

  try {
    response = await mutation({
      variables: {
        input: mutationParams
      }
    });
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw {
      error,
      postErrorMessage
    };
  }

  trackUserflowEvent(events => events.PurchaseClicked);

  trackOrderPlacementProgram({
    mutationParams,
    response,
    selectedBlueprint,
    isClone
  });

  return {
    response,
    afterSuccess: () => {
      enqueueSnackbar(t('programCreate:snackbar.orderSuccess'), {
        variant: 'success',
        persist: false
      });

      history.push(
        generateLinkPath(paths.architecture.program, {
          architectureId: architecture.id,
          orderId: response?.data?.orderPlacement?.id
        })
      );
    }
  };
};
