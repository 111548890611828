import { useMemo } from 'react';
import { flow, isEmpty, some } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import { Button, Paper } from '@mui/material';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Loading from 'src/components/Loading';
import Table from 'src/components/Table';

import {
  mapAssetTypeIntrospectionToProps,
  getTableColumnSchema,
  getAvailableAssetTypeEnums
} from './constants';
import { assetTypeIntrospection, getGalleries } from './queries';

const pageText = () => ({
  createButtonText: t('adminGalleries:galleryTable.createGallery'),
  emptyTableMessage: t('adminGalleries:galleryTable.empty')
});

const AdminGalleriesTable = ({
  galleries,
  galleriesMeta: { galleriesLoading, galleriesError },
  history,
  assetTypeEnums,
  assetTypeIntrospectionMeta: { introspectionLoading, introspectionError }
}) => {
  const text = useMemo(() => pageText(), []);

  const createButtonLinkPath = generateLinkPath(
    paths.admin.settings.galleryCreate
  );

  const handleRowClick = ({ id }) => {
    const linkPath = generateLinkPath(paths.admin.settings.galleryDetails, {
      galleryId: id
    });

    return history.push(linkPath);
  };

  const isLoading = galleriesLoading || introspectionLoading;
  const hasError = galleriesError || introspectionError;

  if (isLoading || hasError) {
    return <Loading error={introspectionError || galleriesError} />;
  }

  if (isEmpty(galleries)) {
    return (
      <TableEmptyState
        buttonText={text.createButtonText}
        buttonTo={createButtonLinkPath}
        emptyMessage={text.emptyTableMessage}
      />
    );
  }

  const canCreate = some(getAvailableAssetTypeEnums(galleries, assetTypeEnums));

  return (
    <>
      <Button
        sx={{ marginBottom: theme => theme.spacing(2) }}
        color="primary"
        component={Link}
        size="large"
        to={createButtonLinkPath}
        variant="outlined"
        disabled={!canCreate}
      >
        {text.createButtonText}
      </Button>

      <Paper
        sx={{
          width: '100%'
        }}
      >
        <Table
          columnSchema={getTableColumnSchema()}
          rows={galleries}
          onClickBodyRow={handleRowClick}
          loading={isLoading}
        />
      </Paper>
    </>
  );
};

export default flow(
  graphql(assetTypeIntrospection, {
    name: 'assetTypeIntrospection',
    props: ({ assetTypeIntrospection }) =>
      mapAssetTypeIntrospectionToProps(assetTypeIntrospection)
  }),
  graphql(getGalleries, {
    name: 'getGalleries',
    options: () => ({
      fetchPolicy: 'no-cache'
    }),
    props: ({ getGalleries: { error, loading, galleries } }) => {
      return {
        galleries,
        galleriesMeta: {
          galleriesLoading: loading,
          galleriesError: error
        }
      };
    }
  }),
  withRouter
)(AdminGalleriesTable);
