import { map, sortBy } from 'lodash';
import { Industry } from 'src/generated/gql/graphql';

import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import {
  getCreateOrganizationInternalAuthInputs,
  getCreateOrganizationOktaDirectSSOAuthInputs,
  authConfigByProviderType,
  AUTH_CONFIG_TYPES,
  createIntegrationStripeConnectProfileName,
  getCreateOrganizationAuth0Inputs
} from './Constants';

export const formatIndustryOptions = (industries: Industry[] = []) => {
  return sortBy(
    map(industries, industry => {
      return {
        value: industry.slug,
        name: industry.name
      };
    }),
    'name'
  );
};

export const getInitialValuesAndAuthConfigType = (
  formValues: Record<string, any>
) => {
  // We want the name & fqdn to persist when someone toggles enableAuth0 so we will set their initial
  // values here.
  const name = formValues?.name ?? '';
  const fqdn = formValues?.fqdn ?? '';
  const slug = formValues?.slug ?? undefined;
  const industrySlug = formValues?.industrySlug ?? undefined;

  const authProviderType =
    formValues?.authProviderType ?? AUTH_CONFIG_TYPES.auth0;
  const authConfigType = authConfigByProviderType(authProviderType);

  let initialValues = {
    fqdn,
    name,
    slug,
    authProviderType,
    industrySlug,
    [createIntegrationStripeConnectProfileName]:
      formValues?.[createIntegrationStripeConnectProfileName] ?? false
  };

  // We only want the initial values set if that section is showing
  if (authConfigType === AUTH_CONFIG_TYPES.auth0) {
    initialValues = {
      ...initialValues,
      ...getInitialValuesFromInputsConfig(getCreateOrganizationAuth0Inputs())
    };
  } else if (authConfigType === AUTH_CONFIG_TYPES.internal) {
    initialValues = {
      ...initialValues,
      ...getInitialValuesFromInputsConfig(
        getCreateOrganizationInternalAuthInputs()
      )
    };
  } else if (authConfigType === AUTH_CONFIG_TYPES.okta_direct_sso) {
    initialValues = {
      ...initialValues,
      ...getInitialValuesFromInputsConfig(
        getCreateOrganizationOktaDirectSSOAuthInputs()
      )
    };
  }

  return {
    initialValues,
    authConfigType
  };
};
