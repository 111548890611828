import { useState } from 'react';

import { Box, Button, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

interface RemoveButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  stopPropagation?: boolean;
}

const RemoveButton = ({
  onClick,
  text,
  stopPropagation
}: RemoveButtonProps) => {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Box>
      {showConfirm ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <Button
            color="warning"
            onClick={onClick}
            startIcon={<DeleteForeverIcon />}
            variant="contained"
          >
            {text}
          </Button>
          <IconButton
            onClick={e => {
              if (stopPropagation) {
                e.stopPropagation();
              }
              setShowConfirm(false);
            }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={e => {
            if (stopPropagation) {
              e.stopPropagation();
            }
            setShowConfirm(true);
          }}
          size="large"
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default RemoveButton;
