import { useCallback, useMemo, useState } from 'react';
import { flow, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import { Box } from '@mui/material';

import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';

import DataTable from 'src/components/DataTable';
import DataTableToolbar from 'src/components/DataTable/DataTableToolbar';

import {
  buildContentFilter,
  getSupportedFilterOperators
} from 'src/components/ContentTable/utils';

import { getColumnType, getValueGetter } from './constants';
import {
  getAdminContentSetItems,
  createOrUpdateAdminContentSetItem,
  deleteAdminContentSetItem
} from './queries';

const ContentSetViewer = ({
  contentSetSlug,
  contentSetFields,
  createOrUpdateAdminContentSetItem,
  deleteAdminContentSetItem
}) => {
  const [nextCursor, setNextCursor] = useState();

  const getItems = useInvokableQuery(getAdminContentSetItems);

  const columns = useMemo(
    () =>
      contentSetFields.map(f => {
        const column = {
          field: f.name,
          headerName: f.name,
          type: getColumnType(f),
          valueGetter: getValueGetter(f),
          width: 180,
          editable: true
        };

        const supportedFilterOperators = getSupportedFilterOperators(f?.type);

        if (supportedFilterOperators) {
          column.filterOperators = supportedFilterOperators;
        }

        return column;
      }),
    [contentSetFields]
  );

  const loadMoreRows = async (reset, pageSize, sortModel, filterModel) => {
    if (reset) {
      setNextCursor(undefined);
    }

    const variables = {
      slug: contentSetSlug,
      first: pageSize || 20,
      after: reset ? undefined : nextCursor
    };

    if (!isEmpty(sortModel)) {
      variables.sort = {
        orderBy: sortModel[0].field,
        direction: sortModel[0].sort
      };
    }

    if (!isEmpty(filterModel?.items)) {
      variables.filter = buildContentFilter(filterModel);
    }

    const getResponse = await getItems(variables);

    const { edges, pageInfo } = getResponse.data.adminContentSet.items;

    setNextCursor(pageInfo.endCursor);

    const rows = edges.map(e => e.node.values);
    const hasMoreRows = pageInfo.hasNextPage;

    return { rows, hasMoreRows };
  };

  const saveRow = async values => {
    const request = {
      variables: {
        contentSetSlug,
        item: {
          values
        }
      }
    };

    const response = await createOrUpdateAdminContentSetItem(request);

    return {
      success: true,
      values: response.data.createOrUpdateAdminContentSetItem.values
    };
  };

  const deleteRow = async id => {
    const request = {
      variables: {
        contentSetSlug,
        itemId: id
      }
    };

    await deleteAdminContentSetItem(request);

    return { success: true };
  };

  const getRowId = useCallback(row => row.id, []);

  return (
    <Box sx={{ height: '500px' }}>
      <DataTable
        columns={columns}
        loadMoreRows={loadMoreRows}
        saveRow={saveRow}
        deleteRow={deleteRow}
        getRowId={getRowId}
        toolbar={DataTableToolbar}
      />
    </Box>
  );
};

export default flow(
  graphql(createOrUpdateAdminContentSetItem, {
    name: 'createOrUpdateAdminContentSetItem'
  }),
  graphql(deleteAdminContentSetItem, {
    name: 'deleteAdminContentSetItem'
  })
)(ContentSetViewer);
