import { InputAdornment, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';
import { makeStyles } from '@mui/styles';
import { ThemeAugmentations } from 'src/AppThemeWrapper';
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme & ThemeAugmentations) => ({
  helpTip: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '13px',
    right: '3px'
  },
  helpIcon: {
    width: '20px'
  }
}));

export interface InputTooltipProps {
  tooltipText: string;
}

/**
 * Input tooltip suited for placement at the end of a form input.
 */
export const InputTooltip = ({ tooltipText }: InputTooltipProps) => {
  const classes = useStyles();
  return (
    <InputAdornment className={classes.helpTip} position="end">
      <Tooltip arrow title={tooltipText}>
        <HelpIcon className={classes.helpIcon} />
      </Tooltip>
    </InputAdornment>
  );
};
