import { includes, uniq } from 'lodash';

import {
  FacebookIcon,
  AudienceNetwork as AudienceNetworkIcon,
  GoogleIcon,
  InstagramIcon,
  MessengerIcon
} from 'src/components/Icons';

import { adCreativeTypes, channelTypes } from 'src/common/adChannels';
import FbMultiImageAd from './FbMultiImageAd';
import FbSingleImageAd from './FbSingleImageAd';
import FbVideoAd from './FbVideoAd';

export const getBlueprintIconByType = type => {
  switch (type) {
    case adCreativeTypes.fbSingleImage:
      return FbSingleImageAd;
    case adCreativeTypes.fbMultiImage:
      return FbMultiImageAd;
    case adCreativeTypes.fbVideo:
      return FbVideoAd;
    default:
      return FbSingleImageAd;
  }
};

export const getChannelIconByType = (type, fontSize) => {
  switch (type) {
    case channelTypes.facebook:
      return <FacebookIcon fontSize={fontSize} />;
    case channelTypes.instagram:
      return <InstagramIcon fontSize={fontSize} />;
    case channelTypes.google:
      return <GoogleIcon fontSize={fontSize} />;
    case channelTypes.messenger:
      return <MessengerIcon fontSize={fontSize} />;
    case channelTypes.audienceNetwork:
      return <AudienceNetworkIcon fontSize={fontSize} />;
    default:
      return '';
  }
};

export const getAllChannels = (channels = []) => {
  const blueprintChannels = uniq(channels);
  const updatedChannels = blueprintChannels;
  // Note We are currently going to assume that if you are running on FB
  //      that we will also run on IG, MSGN and FAN. FB practice is to do
  //      auto-placements on the other channels.

  // if FB and no IG add IG
  if (
    includes(blueprintChannels, channelTypes.facebook) &&
    !includes(blueprintChannels, channelTypes.instagram)
  ) {
    updatedChannels.push(channelTypes.instagram);
  }

  // if FB and no MSGR add MSGR
  if (
    includes(blueprintChannels, channelTypes.facebook) &&
    !includes(blueprintChannels, channelTypes.messenger)
  ) {
    updatedChannels.push(channelTypes.messenger);
  }

  // if FB and no FAN add FAN
  if (
    includes(blueprintChannels, channelTypes.facebook) &&
    !includes(blueprintChannels, channelTypes.audienceNetwork)
  ) {
    updatedChannels.push(channelTypes.audienceNetwork);
  }

  return updatedChannels;
};
