import { keys } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Grid } from '@mui/material';

import { testFormInputMap } from './Constants';

const Testing = () => {
  return (
    <div>
      <h1>
        <span role="img" aria-label="testing zone">
          🚨🦺 Testing Zone!! 🦺🚨
        </span>
      </h1>

      <h2>Form Inputs</h2>
      <Grid container spacing={3} data-cy="bp-step">
        {keys(testFormInputMap).map(key => {
          const component = testFormInputMap[key];
          return (
            <Grid key={`${key}-input-button`} item>
              <Button
                variant="contained"
                key={key}
                component={RouterLink}
                to={component.path}
              >
                {component.buttonText}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Testing;
