import classNames from 'classnames';

import { Divider, AccordionSummary, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorIcon from '@mui/icons-material/Error';

const styles = theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  error: {
    color: theme.palette.error.main
  }
});

const SkinSettingHeading = ({ title, error, classes }) => {
  return (
    <>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="palette-skin-summary"
        id="palette-skin-summary"
      >
        <Typography
          variant="subtitle1"
          className={classNames(classes.title, {
            [classes.error]: error
          })}
        >
          {title}
          {error ? <ErrorIcon /> : null}
        </Typography>
      </AccordionSummary>
      <Divider />
    </>
  );
};

export default withStyles(styles)(SkinSettingHeading);
