import { Link } from 'react-router-dom';
import { Tooltip, IconButton } from '@mui/material';
import { isFunction } from 'lodash';

const IconLink = ({
  title,
  icon = null,
  disabled = false,
  to = '',
  logClickEvent = null
}) => {
  const Icon = icon;
  return (
    <Tooltip title={title}>
      <span>
        <IconButton
          aria-label={title}
          disabled={disabled}
          component={Link}
          to={to}
          size="large"
          onClick={e => {
            if (isFunction(logClickEvent)) {
              logClickEvent();
            }
            // allows us to use command + click and not triggering onClickBodyRow
            e.stopPropagation();
          }}
        >
          <Icon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default IconLink;
