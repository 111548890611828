import { gql } from 'src/generated/gql';

export const getCatalogByArchitectureId = gql(`
  query getArchitectureAndCatalogByArchitectureIdForBOSelector(
    $architectureId: ID!
    $externalIds: [String]!
  ) {
    architecture(architectureId: $architectureId) {
      id
      name
      catalogs {
        id
        contentSetId
        defaultGroupKey
        displayCollapseKey
        displayNameTemplate
        displayImageTemplate
        friendlyName
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          contentColumnType
          isFilterable
        }
        content(filter: { id: { in: $externalIds } }) {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
    }
  }
`);
