import { gql } from 'src/generated/gql';

export const getPromotions = gql(`
  query getPromotions {
    myOrganization {
      id
      name
      promotions {
        id
        organizationId
        promoCode
        description
        percentageDiscount
        maxDollarDiscountPerUse
        minimumSpend
        totalDollarDiscount
        totalNumberOfUsesAllowed
        totalNumberOfUsesAllowedPerUser
        totalAmountRedeemablePerUser
        startDate
        endDate
        createdAt
        isDeleted
      }
    }
  }
`);

export const getPromotion = gql(`
  query getPromotion($promotionId: ID) {
    myOrganization {
      id
      name
      promotions(promotionId: $promotionId) {
        id
        organizationId
        promoCode
        description
        percentageDiscount
        maxDollarDiscountPerUse
        minimumSpend
        totalDollarDiscount
        totalNumberOfUsesAllowed
        totalNumberOfUsesAllowedPerUser
        totalAmountRedeemablePerUser
        startDate
        endDate
        createdAt
        isDeleted
        supportType
        platformFeeCalculation
        promotionRedemptions {
          id
        }
      }
    }
  }
`);

export const isPromoCodeTaken = gql(`
  query isPromoCodeTaken($promoCode: String!) {
    isPromoCodeTaken(promoCode: $promoCode)
  }
`);
