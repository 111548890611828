import { gql } from 'src/generated/gql';

export const getFacebookPageStatusV2 = gql(`
  query FacebookPagesForFbLink($facebookSettingsId: ID) {
    facebookPagesV2(facebookSettingsId: $facebookSettingsId) {
      id
      userId
      groupId
      pageId
      pageName
      facebookUserId
      facebookDisplayName
      instagramId
      hasAcceptedTos
      pageStatus
      businessManagerId
      createdAt
      updatedAt
      activeOrders {
        name
        id
        architectureId
      }
      accessControl {
        permissions(filter: {})
      }
    }
  }
`);

export const getFacebookPageIds = gql(`
  query FacebookPagesForPageLinker {
    facebookPagesV2 {
      pageId
    }
  }
`);

export const getOrgDefaultFacebookPageGroup = gql(`
  query getOrgDefaultFacebookPageGroupForPageLinker {
    getOrgDefaultFacebookPageGroup {
      id
      name
      isOrgDefault
    }
  }
`);
