import { SVGProps } from 'react';

const FacebookIconSquare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6524 3.30801V18.3444C21.6524 20.1668 20.1668 21.6524 18.3444 21.6524H15.0364V13.232H17.2137C17.5204 13.232 17.779 13.0035 17.8151 12.7028L18.0377 10.8984C18.0617 10.73 18.0076 10.5556 17.8933 10.4292C17.779 10.2969 17.6166 10.2248 17.4422 10.2248H15.0364V8.11966C15.0364 7.45806 15.5777 6.91675 16.2393 6.91675H17.4422C17.773 6.91675 18.0437 6.64609 18.0437 6.31529V4.28237C18.0437 3.97562 17.8031 3.717 17.4964 3.68693C17.4603 3.68091 16.6122 3.60874 15.5356 3.60874C12.8892 3.60874 11.4277 5.18455 11.4277 8.04147V10.2248H9.02184C8.69104 10.2248 8.42039 10.4954 8.42039 10.8262V12.6306C8.42039 12.9614 8.69104 13.232 9.02184 13.232H11.4277V21.6524H3.30801C1.4856 21.6524 0 20.1668 0 18.3444V3.30801C0 1.4856 1.4856 0 3.30801 0H18.3444C20.1668 0 21.6524 1.4856 21.6524 3.30801Z"
      fill="#1565C0"
    />
  </svg>
);

export default FacebookIconSquare;
