import { useMemo, useRef, useEffect } from 'react';
import { debounce, keys, merge, pickBy } from 'lodash';
import {
  clearAsyncError,
  FormErrors,
  getFormAsyncErrors,
  startAsyncValidation,
  stopAsyncValidation
} from 'redux-form';
import { useSelector } from 'react-redux';
import { ErrorOption } from 'react-hook-form';
import { UrlValidationResultCode } from 'src/generated/gql/graphql';
import SentryUtil from 'src/common/SentryUtil';
import { linkUrlPageText } from 'src/components/ReduxForm/RenderLinkUrl/linkUrlPageText';
import { Dispatch } from 'redux';
import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';
import { fetchUrlValidation } from 'src/components/ReduxForm/RenderLinkUrl/queries';
import { ImmutableRef } from 'src/common/utilities/utilTypes';
import { PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME } from 'src/pages/Program/Constants';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const maybeAddProtocol = (url: string): string => {
  if (/^https?:\/\//g.test(url)) {
    return url;
  }
  return `http://${url}`;
};

const httpValidationDebounceDelayMs = 500;

/**
 * Merges some existing async error set with a new field error.
 * Always use this instead of manually merging to account for
 * edge cases around empty objects!
 */
const mergeAsyncValidationStates = (
  existingAsyncErrors: FormErrors = {},
  outerFieldName: string,
  innerFieldName: string,
  error: string | null | ErrorOption
) => {
  merge(existingAsyncErrors, {
    [outerFieldName]: {
      [innerFieldName]: error
    }
  });
  // If, after merging, we find that our outer field name has no more values in it
  // then we need to clear that outer field.
  // Otherwise, we'll store an empty object in redux and redux-form will error out
  // because the object exists.
  let cleanedOuterField: Partial<any> | null = pickBy(
    (existingAsyncErrors as any)[outerFieldName]
  );
  if (keys(cleanedOuterField).length === 0) {
    cleanedOuterField = null;
  }

  merge(existingAsyncErrors, {
    [outerFieldName]: cleanedOuterField
  });

  const cleanedFullObject = pickBy(existingAsyncErrors);
  if (keys(cleanedFullObject).length === 0) {
    return undefined;
  }

  return cleanedFullObject;
};

export interface AsyncUrlValidationObject {
  /**
   * Validates the given URL through the async form validation API.
   * When URL validation is not active this function will return a
   * placeholder no-op function.
   */
  validateUrl: ImmutableRef<(updatedUrl: string) => void>;

  /**
   * Validates multiple URLs, same as validateUrl.
   * The keys provided in the object represent some form of "label" that identifies
   * the validation result in some human-understandable way.
   * For example, this could be content item names.
   */
  validateMultipleUrls: ImmutableRef<
    (urlsByLabel: Record<string, string>) => void
  >;
}

export const useAsyncUrlValidation = (
  dispatch: Dispatch,
  formName: string,
  fieldName: string,
  noCrossDomainRedirects = false
): AsyncUrlValidationObject => {
  const text = useMemo(() => linkUrlPageText(), []);

  const existingAsyncErrors = useSelector(getFormAsyncErrors(formName));

  const rawValidateUrlQuery = useInvokableQuery(fetchUrlValidation);

  const doValidationOnMultipleUrls = (urlsByLabel: Record<string, string>) => {
    // startAsyncValidation is typed incorrectly.
    // Passing just form as they say results in a failure to start
    // async validation.
    // https://github.com/redux-form/redux-form/blob/88866f08120c1297daf54a5b887b90708e7f35e9/src/actions.js#L329
    dispatch((startAsyncValidation as any)(formName, fieldName));
    dispatch(clearAsyncError(formName, fieldName));

    // Field name is in the format of 'dynamicUserInputs.<NAME>'
    // or in a plain <NAME>
    let outerFieldName: string;
    let innerFieldName: string;
    if (fieldName.includes('.')) {
      [outerFieldName, innerFieldName] = fieldName.split('.');
    } else {
      // if we don't have the outer field name, assume it's the usual
      outerFieldName = PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME;
      innerFieldName = fieldName;
    }

    const reduxDispatchError = (error: string) => {
      dispatch(
        stopAsyncValidation(
          formName,
          mergeAsyncValidationStates(
            existingAsyncErrors,
            outerFieldName,
            innerFieldName,
            error
          )
        )
      );
    };

    const dispatchError = (error: string) => {
      reduxDispatchError(error);
    };

    // Since we use index-access later in this, get a stable array of entries before
    // doing anything else.
    const urlsWithLabel = Object.entries(urlsByLabel);

    /**
     * Validation results AFTER we get a graphql response.
     * If there is a string present, that value is in error.
     * If there is a null present, that value is valid.
     * MUST maintain iteration order so that we can do index lookups!
     */
    const urlValidationPromises = urlsWithLabel
      .filter(([_label, url]) => url != null && url.trim() !== '')
      .map(([_label, url]) =>
        rawValidateUrlQuery({
          urlData: { url: maybeAddProtocol(url.trim()) }
        })
          .then(result => {
            const validationResult = result.data.validateUrl.result;
            // For generic links, redirects are okay.
            // There are some link types that do not allow redirections, but at
            // this generic level we don't know that and thus let them through.

            const crossDomainRedirectsAllowed = !noCrossDomainRedirects;

            if (
              validationResult === UrlValidationResultCode.Valid ||
              (crossDomainRedirectsAllowed &&
                validationResult ===
                  UrlValidationResultCode.CrossDomainRedirect) ||
              validationResult === UrlValidationResultCode.SameDomainRedirect
            ) {
              return null;
            }

            return text.validationErrors[validationResult];
          })
          .catch(err => {
            SentryUtil.captureException(err);
            return text.validationErrors[UrlValidationResultCode.FailedToLoad];
          })
      );

    /**
     * Resolve all our promises, which should contain either the error text or null
     * for no error.
     * This will look for all errors, link them up with their label, and push that
     * error object into redux.
     * If there are errors the error state is simply cleared.
     */
    Promise.all(urlValidationPromises)
      .then(results => {
        let fullErrorText = '';
        results.forEach((errorText, index) => {
          if (errorText == null) {
            return;
          }

          const label = urlsWithLabel[index][0];
          if (label != null && label !== '') {
            fullErrorText += `${label}: ${errorText}\n`;
          } else {
            fullErrorText += `${errorText}\n`;
          }
        });

        if (fullErrorText === '') {
          // no error was found in the result set, clear our error

          dispatch(
            stopAsyncValidation(
              formName,
              mergeAsyncValidationStates(
                existingAsyncErrors,
                outerFieldName,
                innerFieldName,
                null
              )
            )
          );
        } else {
          // Some error was found, update our error
          dispatchError(fullErrorText);
        }
      })
      .catch(error => {
        // This shouldn't ever happen since we should be catching the errors
        // within each URL validation request
        SentryUtil.captureException(error);
        dispatchError(
          text.validationErrors[UrlValidationResultCode.FailedToLoad]
        );
      });
  };

  const doValidation = (updatedUrl: string) => {
    doValidationOnMultipleUrls({ '': updatedUrl });
  };

  // Since our debounced func below is ref'ed, we need the actual logic to be too.
  // Doing it this way means that we can safely reference whatever values we want.
  const validationFunc = useRef(doValidation);
  validationFunc.current = doValidation;

  const debouncedValidationFunc = useRef<(updatedUrl: string) => void>(
    debounce(
      updatedUrl => validationFunc.current(updatedUrl),
      httpValidationDebounceDelayMs
    )
  );

  const multipleValidationFunc = useRef(doValidationOnMultipleUrls);
  multipleValidationFunc.current = doValidationOnMultipleUrls;

  const debouncedMultipleValidationFunc = useRef<
    (urlsByLabel: Record<string, string>) => void
  >(
    debounce(
      updatedUrl => multipleValidationFunc.current(updatedUrl),
      httpValidationDebounceDelayMs
    )
  );

  return {
    validateUrl: debouncedValidationFunc,
    validateMultipleUrls: debouncedMultipleValidationFunc
  };
};

export const getValidateLinkUrl =
  (
    apolloClient: ApolloClient<NormalizedCacheObject>,
    noCrossDomainRedirects?: boolean
  ) =>
  async (value: string) => {
    const text = linkUrlPageText();
    if (!value || value.trim() === '') {
      return;
    }

    let result;

    try {
      result = await apolloClient.query({
        query: fetchUrlValidation,
        variables: {
          urlData: { url: maybeAddProtocol(value?.trim()) }
        }
      });

      const validationResult = result.data.validateUrl.result;

      const crossDomainRedirectsAllowed = !noCrossDomainRedirects;

      if (
        validationResult === UrlValidationResultCode.Valid ||
        (crossDomainRedirectsAllowed &&
          validationResult === UrlValidationResultCode.CrossDomainRedirect) ||
        validationResult === UrlValidationResultCode.SameDomainRedirect
      ) {
        return;
      }

      return text.validationErrors[validationResult];
    } catch (err: any) {
      SentryUtil.captureException(err);
      return text.validationErrors[UrlValidationResultCode.FailedToLoad];
    }
  };

export const useAsyncUrlValidationHookForm = (
  apolloClient: ApolloClient<NormalizedCacheObject>,
  noCrossDomainRedirects?: boolean
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  // this stupid crossDomain ref is because we send this value AFTER creative validations returns
  // and then creative validations triggers validation on the form BEFORE we're able to
  // update this validation function with the correct value so it's a GD nightmare
  const crossDomainRedirectsRef = useRef(noCrossDomainRedirects);

  // if we use lodash debounce hookForm won't remember that we are still validating when we call it multiple times
  const chadDebouncedValidationFunc = useRef<(updatedUrl: string) => void>(
    (updatedUrl: string) => {
      return new Promise((resolve, reject) => {
        const validateLinkUrl = getValidateLinkUrl(
          apolloClient,
          crossDomainRedirectsRef.current
        );
        // cancel the previous timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          // launch the async request
          validateLinkUrl(updatedUrl)
            .then(d => {
              resolve(d);
            })
            .catch(() => {
              reject();
            });
        }, httpValidationDebounceDelayMs);
      });
    }
  );

  useEffect(() => {
    if (noCrossDomainRedirects) {
      crossDomainRedirectsRef.current = noCrossDomainRedirects;
    }
  }, [noCrossDomainRedirects]);

  return chadDebouncedValidationFunc.current;
};
