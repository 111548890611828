import { gql } from 'src/generated/gql';

export const getManageQuickStarts = gql(`
  query getManageQuickStarts {
    activeQuickStarts(showInactive: true) {
      id
      name
      isCustom
      customImageUrl
      iconImageUrl
      description
      isActive
      priority
      productId
      product {
        id
        isAvailable
        architectureId
        description
        name
        notes
        catalogContentType
        channels
        blueprint {
          id
          objective
        }
      }
      automationQuickStarts {
        id
        productQuickStartId
        name
        icon
        priority
        catalogFilter
      }
      localizedImages {
        locale
        customImageUrl
        iconImageUrl
      }
    }
  }
`);

export const getCatalogByArchitectureId = gql(`
  query getCatalogByArchitectureId($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      catalog {
        id
        friendlyName
        displayCollapseKey
        fieldMetadata {
          id
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          includeInLeadNotifications
          filterColumn
          contentColumnType
          isFilterable
          enumValues
        }
      }
    }
  }
`);
