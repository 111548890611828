import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import i18n from './i18n';

import 'dayjs/locale/fr';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/de';
import 'dayjs/locale/ja';
import 'dayjs/locale/pt-br';
// The i18n epic mentioned spain spanish and latin american spanish
// the es locales below are for spain us, puerto rico, dominican republic, mexico respectively
// and are all of the spanish locales offered by dayjs
import 'dayjs/locale/es';
import 'dayjs/locale/es-us';
import 'dayjs/locale/es-pr';
import 'dayjs/locale/es-do';
import 'dayjs/locale/es-mx';
import 'dayjs/locale/tr';
import 'dayjs/locale/el';

interface MuiLocalizationProviderProps {
  children: React.ReactNode;
}

const MuiLocalizationProvider = ({
  children
}: MuiLocalizationProviderProps) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.language}
    >
      {children}
    </LocalizationProvider>
  );
};

export default MuiLocalizationProvider;
