import { flow } from 'lodash';

import { Theme, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

import { WithStyledClasses } from 'src/common/Style';
import { Box } from '@mui/system';

const ICON_TYPES = {
  error: ErrorIcon,
  info: InfoIcon,
  warn: ErrorIcon
} as const;

type MessageType = keyof typeof ICON_TYPES;

const styles = (theme: Theme) => ({
  container: {
    maxWidth: '450px',
    padding: theme.spacing(2)
  },
  childContainer: {
    width: '100%',
    padding: '5px'
  },
  small: {
    width: '20px',
    height: '20px'
  },
  medium: {
    width: '35px',
    height: '35px'
  },
  large: {
    width: '50px',
    height: '50px'
  },
  info: {},
  warn: {
    color: theme.palette.warning.main
  },
  error: {
    color: theme.palette.error.dark
  }
});

interface MessageProps {
  classes: WithStyledClasses<typeof styles>;
  className?: string;
  children: JSX.Element | string;
  iconSize: 'small' | 'medium' | 'large';
  type: MessageType;
  maxWidth?: string;
}

const Message = (props: MessageProps) => {
  const {
    classes,
    children,
    iconSize = 'large',
    type = 'info',
    maxWidth = '100%'
  } = props;

  const colorClass = classes[type] ? classes[type] : classes.info;

  const IconComponent = ICON_TYPES[type] ? ICON_TYPES[type] : ICON_TYPES.info;

  const iconClass = classes[iconSize] ? classes[iconSize] : classes.large;

  const iconComponent = (
    <IconComponent className={`${colorClass} ${iconClass}`} />
  );

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1.5,
        whiteSpace: 'normal !important',
        maxWidth
      }}
    >
      {iconComponent}
      <Typography className={colorClass} variant="body2">
        {children}
      </Typography>
    </Box>
  );
};

export default flow(withStyles(styles))(Message);
