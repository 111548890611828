import { Button, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import Link from 'src/components/Link';
import { FACEBOOK_URLS } from 'src/common/FacebookUtil';

import PageLinkCard from '../PageLinkCard';
import ActionsContainer from '../ActionsContainer';

interface PageNotVisibleProps {
  onCancel: () => void;
  onConfirm: () => void;
  pageName: string;
}

const PageNotVisible = ({
  onCancel,
  onConfirm,
  pageName
}: PageNotVisibleProps) => {
  return (
    <PageLinkCard
      title={t('facebookSettings:selectedFacebookPage.title', { pageName })}
      actions={
        <ActionsContainer justifyContent="center">
          <Button variant="text" onClick={onCancel}>
            {t('facebookSettings:buttons.goBack')}
          </Button>
          <Button variant="contained" onClick={onConfirm}>
            {t('facebookSettings:buttons.selectAnotherPage')}
          </Button>
        </ActionsContainer>
      }
    >
      <Typography>
        <Trans
          i18nKey="facebookSettings:pageNotVisible.description"
          components={[<Link to={FACEBOOK_URLS.support} target="_blank" />]}
        />
      </Typography>
    </PageLinkCard>
  );
};

export default PageNotVisible;
