import { gql } from 'src/generated/gql';

// input: {
//   primaryColor: "string"
//   secondaryColor: "string"
//   mainLogo: "string"
//   productName: "string"
//   font: "string"
// }

export const createEmailNotificationAsset = gql(`
  mutation createEmailNotificationAsset($input: CreateEmailNotificationAssetInput!) {
    createEmailNotificationAsset(input: $input) {
      id
      organizationId
      emailIdentityId
      primaryColor
      secondaryColor
      mainLogo
      productName
      font
      createdAt
      updatedAt
      deletedAt
    }
  }
`);

export const updateEmailNotificationAsset = gql(`
  mutation updateEmailNotificationAsset($input: UpdateEmailNotificationAssetInput!) {
    updateEmailNotificationAsset(input: $input) {
      id
      organizationId
      emailIdentityId
      primaryColor
      secondaryColor
      mainLogo
      productName
      font
      createdAt
      updatedAt
      deletedAt
    }
  }
`);

// export const deleteEmailNotificationAsset = gql(`
//   mutation deleteEmailNotificationAsset(id: ID!) {
//     id
//     organizationId
//     emailIdentityId
//     primaryColor
//     secondaryColor
//     mainLogo
//     productName
//     font
//     createdAt
//     updatedAt
//     deletedAt
//   }
// `);

export const createCampaignConnectBasedEmailIdentity = gql(`
  mutation createCampaignConnectBasedEmailIdentity($input: InitiateCampaignConnectEmailIdentityInput!) {
    createCampaignConnectBasedEmailIdentity(input: $input) {
      id
      status
    }
  }
`);
