import { useMemo, useCallback, useRef } from 'react';
import { FormSection, Field } from 'redux-form';
import { useDrag, useDrop } from 'react-dnd';

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DynamicForm } from 'src/components/ReduxForm';
import { configureInputs } from 'src/components/ReduxForm/helpers';

import { DRAG_ITEM_TYPE } from './Constants';
import { facetsInputs } from './inputConfigs';
import RenderParameterValues from './RenderParameterValues';
import RemoveButton from '../RemoveButton';

const DraggableFacetListItem = props => {
  const {
    facetsOfSelectedPrototype,
    field,
    fields,
    handleAccordionToggle,
    index,
    openIndexes,
    prototype,
    text,
    unitId,
    handleAccordionCloseAll,
    setHasMoved
  } = props;

  const draggableRef = useRef(null);
  const fieldData = fields.get(index);

  const facetsNameBySlug = useMemo(() => {
    return facetsOfSelectedPrototype.reduce((acc, facet) => {
      acc[facet.slug] = facet.name;
      return acc;
    }, {});
  }, [facetsOfSelectedPrototype]);

  const updatedFacetsInputs = useMemo(() => {
    return configureInputs({
      inputs: facetsInputs,
      enumInputs: {
        facet: 'facet'
      },
      enumerationValues: [
        {
          enumeration: 'facet',
          values: facetsOfSelectedPrototype.map(facet => {
            return {
              name: facet.name,
              value: facet.slug
            };
          })
        }
      ]
    });
  }, [facetsOfSelectedPrototype]);

  const removeFacet = useCallback(
    index => {
      fields.remove(index);
    },
    [fields]
  );

  const onDropPublisher = useCallback(
    (oldIndex, newIndex) => {
      handleAccordionCloseAll();

      fields.move(oldIndex, newIndex);
      // let parent component know that the list facet has been moved
      setHasMoved(true);
    },
    [fields, handleAccordionCloseAll, setHasMoved]
  );

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_ITEM_TYPE.facet,
    item: () => {
      return { field, index };
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAG_ITEM_TYPE.facet,
      drop: value => {
        // item being dragged index
        const oldIndex = value.index;
        // index of the item being dragged over
        const newIndex = index;
        onDropPublisher(oldIndex, newIndex);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [index]
  );

  return (
    <div ref={draggableRef}>
      <Accordion
        ref={drop}
        key={field}
        onChange={() => handleAccordionToggle(index)}
        expanded={openIndexes.includes(index)}
        sx={{
          opacity: isDragging ? 0.3 : 1,
          background: isOver
            ? theme => `${theme.palette.warning[100]}`
            : 'white'
        }}
      >
        <AccordionSummary ref={drag} expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <DragIndicatorIcon />
              <Typography
                sx={{
                  fontWeight: 'bold'
                }}
              >
                {fieldData?.name} {fieldData?.name ? '|' : null}{' '}
                <Box
                  component="span"
                  sx={
                    fieldData?.name
                      ? {
                          fontWeight: 'normal',
                          color: theme => theme.palette.grey[500]
                        }
                      : {}
                  }
                >
                  {facetsNameBySlug?.[fieldData?.facet] ?? fieldData?.facet}
                </Box>
              </Typography>
            </Box>
            <RemoveButton
              stopPropagation
              key={field}
              onClick={() => removeFacet(index)}
              text={text.removeButton}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FormSection name={field}>
            <DynamicForm inputs={updatedFacetsInputs} />
            <Field
              key={field}
              component={RenderParameterValues}
              name="parameterValues"
              facet={fieldData?.facet}
              unitId={unitId}
              prototype={prototype}
            />
          </FormSection>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DraggableFacetListItem;
