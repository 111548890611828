import {
  createContext,
  useContext,
  useEffect,
  useState,
  Component,
  ReactNode,
  useCallback
} from 'react';
import { has } from 'lodash';
import {
  Experiment,
  ExperimentClient,
  ExperimentUser
} from '@amplitude/experiment-js-client';

import { useGlobalContext } from 'src/GlobalContextProvider';
import SentryUtil from 'src/common/SentryUtil';
import { EXPERIMENTS_OVERRIDE_LOCAL_STORAGE } from 'src/pages/Admin/ExperimentSettings/Constants';

import {
  EXPERIMENT_NAMES,
  EXPERIMENT_VARIANTS,
  ExperimentName,
  ExperimentNameKey
} from './constants';

interface ExperimentContextType {
  client: ExperimentClient | null;
  testNames: typeof EXPERIMENT_NAMES;
  loaded: boolean;
}

export const ExperimentContext = createContext<ExperimentContextType>({
  client: null,
  testNames: EXPERIMENT_NAMES,
  loaded: false
});

const getExperimentFlagKey = (
  experimentName: ExperimentName | ExperimentNameKey
): ExperimentName => {
  return (EXPERIMENT_NAMES as any)[experimentName] || experimentName;
};

export const isExperimentActive = (
  experimentName: ExperimentName,
  experimentContext: ExperimentContextType
) => {
  if (!experimentName) {
    return experimentContext;
  }

  // just return the value of the experiment flag or false
  if (experimentContext?.client?.variant) {
    const experimentVariant = experimentContext?.client?.variant(
      getExperimentFlagKey(experimentName)
    )?.value;

    return (
      experimentVariant === EXPERIMENT_VARIANTS.TREATMENT ||
      experimentVariant === EXPERIMENT_VARIANTS.ON
    );
  }

  return false;
};

interface ExposureConfig {
  trackExposure?: boolean;
}

interface TrackExposureProps {
  experimentName: ExperimentName;
  experimentContext: ExperimentContextType;
  config?: ExposureConfig;
}

// Side effect function to track exposure events manually
const trackExposure = ({
  experimentName,
  experimentContext,
  config
}: TrackExposureProps) => {
  const trackExposure = config?.trackExposure ?? true;

  if (experimentName && experimentContext?.client?.exposure && trackExposure) {
    experimentContext?.client?.exposure(getExperimentFlagKey(experimentName));
  }
};

/**
 * Hook for getting the experiment client.
 * @param experimentName optional Explicitly pass the experiment name
 * @param config Fields include: trackExposure, boolean which determines if exposure is tracked
 * @returns  The experiment client or bool if params are passed
 */
export const useExperimentWithoutOverides = (
  experimentName: ExperimentName,
  config?: ExposureConfig
) => {
  const context = useContext(ExperimentContext);

  trackExposure({
    experimentName,
    experimentContext: context,
    config
  });

  return isExperimentActive(experimentName, context);
};

/*
 * Example usage:
 *
 * Track exposure and return if variant is treatment:
 * const isTreatment = useExperiment('experiment-flag-key');
 *
 * Conditionally track exposure and return if variant is treatment:
 * const shouldTrackExposure = ...conditional logic...
 * const isTreatment = useExperiment('experiment-flag-key', { trackExposure: shouldTrackExposure })
 */
export const useExperiment = (
  experimentName: ExperimentName,
  config = { trackExposure: true }
) => {
  const context = useContext(ExperimentContext);

  const experimentOverridesRaw = localStorage.getItem(
    EXPERIMENTS_OVERRIDE_LOCAL_STORAGE
  );

  let experimentOverrides = {} as Record<ExperimentName, boolean>;
  if (experimentOverridesRaw) {
    experimentOverrides = JSON.parse(experimentOverridesRaw);
  }

  const experimentWithoutOverride = useExperimentWithoutOverides(
    experimentName,
    config
  );

  if (has(experimentOverrides, experimentName)) {
    return {
      value: experimentOverrides[experimentName],
      experimentsLoaded: true
    };
  }

  return {
    value: experimentWithoutOverride,
    experimentsLoaded: context.loaded
  };
};

const config = {
  /*
   * The variant method will no longer automatically track an exposure event, the exposure method has to be called explicitly because of this config
   * https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#variant
   */
  automaticExposureTracking: false,
  fetchOnStart: true
  // debug: import.meta.env.MODE !== 'production'
};

export const withExperimentContext = (component: typeof Component) => {
  const Component = component;

  return (props: object) => {
    const experimentContext = useContext(ExperimentContext);
    // for now force admin settings to wait for this to load
    // it's the only consumer of this HOC
    if (!experimentContext.loaded) {
      return null;
    }
    return <Component {...props} experimentContext={experimentContext} />;
  };
};

export interface ExperimentProviderProps {
  children: ReactNode;
  amplitudeLoaded: boolean;
  experimentUser: ExperimentUser;
}
const isTestEnv = import.meta.env.EVOCALIZE_ENV === 'test';

export const ExperimentProvider = (props: ExperimentProviderProps) => {
  const { children, amplitudeLoaded } = props;

  const [loaded, setLoaded] = useState(false);

  const [experiment] = useState(() => {
    if (isTestEnv) {
      return null;
    }
    if (!import.meta.env.EVOCALIZE_AMPLITUDE_EXPERIMENT_KEY) {
      // eslint-disable-next-line no-console
      console.error(
        'Missing Amplitude experiment api key. Experiments will be disabled.'
      );
      setLoaded(true);
      return null;
    }
    return Experiment.initializeWithAmplitudeAnalytics(
      import.meta.env.EVOCALIZE_AMPLITUDE_EXPERIMENT_KEY,
      config
    );
  });

  const { me } = useGlobalContext();

  const startExperiment = useCallback(async () => {
    const user = {
      user_id: me?.id,
      // Duplicate user ID and add in org ID into the user properties so that
      // local flag evaluation works correctly
      user_properties: {
        user_id: me?.id,
        org_id: me?.organizationId
      }
    };

    try {
      await experiment?.start(user);
      setLoaded(true);
    } catch (error) {
      SentryUtil.addBreadcrumb({
        message: 'Error loading Amplitude experiment.'
      });
      SentryUtil.captureException({
        error
      } as any);
      // if this errors we will still just default back to no-experiments instead of blocking the app
      setLoaded(true);
    }
  }, [me?.id, me?.organizationId]);

  useEffect(() => {
    if (!loaded && amplitudeLoaded) {
      if (isTestEnv) {
        // skip amplitude setup in test env
        setLoaded(true);
      } else if (experiment) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        startExperiment(); // only once
      }
    }
  }, [isTestEnv, experiment, amplitudeLoaded]);

  return (
    <ExperimentContext.Provider
      value={{
        client: experiment,
        testNames: EXPERIMENT_NAMES,
        loaded
      }}
    >
      {children}
    </ExperimentContext.Provider>
  );
};

export { EXPERIMENT_NAMES };
