import { gql } from 'src/generated/gql';

export const getArchitectures = gql(`
  query getArchitecturesForContentTableSettings {
    us {
      id
      architectures(showInactive: true) {
        id
        name
        isContentSelectable
        isActive
      }
    }
  }
`);

export const getContentTables = gql(`
  query getContentTables {
    contentDocumentVersions {
      id
      status
      contentTable
      versionTimestamp
      errorMessage
    }
  }
`);

export const getContentByTableName = gql(`
  query contentDocumentVersions($contentTable: String) {
    contentDocumentVersions(contentTable: $contentTable) {
      id
      status
      contentTable
      errorMessage
      versionTimestamp
      document {
        contentTable
        isAccessControlled
        columns {
          name
          primaryKey
          nullable
          indexed
          type
          defaultValue
          unique
        }
      }
    }
  }
`);

export const columnTypeIntrospection = gql(`
  query columnTypeIntrospection {
    __type(name: "KnownColumnType") {
      name
      enumValues {
        name
      }
    }
  }
`);
