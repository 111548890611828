import {
  useEffect,
  useRef,
  forwardRef,
  useContext,
  createContext
} from 'react';
import { isEmpty } from 'lodash';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

import {
  styled,
  Autocomplete,
  InputAdornment,
  ListItem,
  ListItemIcon,
  Popper,
  TextField,
  FormControl
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';

import IconComponent, { supportedIcons } from './IconComponent';

const LISTBOX_PADDING = 8;
const ITEM_SIZE = 35;

const renderRow = ({ data, index, style }: ListChildComponentProps) => {
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  return (
    <ListItem key={dataSet?.key} style={inlineStyle} {...dataSet.props}>
      <ListItemIcon>
        <IconComponent icon={dataSet?.key} />
      </ListItemIcon>
      {dataSet.props.children}
    </ListItem>
  );
};

const OuterElementContext = createContext({});
const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  zIndex: theme.zIndex.modal + 1,
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
}));

// Adapter for react-window
const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const itemCount = itemData.length;
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          height={200}
          innerElementType="ul"
          itemCount={itemCount}
          itemData={itemData}
          itemSize={() => ITEM_SIZE}
          outerElementType={OuterElementType}
          overscanCount={5}
          ref={gridRef}
          width="100%"
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const renderInput = (params: any) => {
  const value = params?.inputProps?.value;

  return (
    <TextField
      {...params}
      label="Icon"
      InputProps={{
        ...params.InputProps,
        ...(!isEmpty(value)
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  <IconComponent icon={value} />
                </InputAdornment>
              )
            }
          : {})
      }}
    />
  );
};

const IconSelector = ({ onChange, value }: { onChange: any; value: any }) => {
  const iconNames = Object.keys(supportedIcons) as Array<
    keyof typeof supportedIcons
  >;

  const iconOptions = iconNames.map((iconName: keyof typeof supportedIcons) => {
    return {
      label: iconName,
      value: iconName
    };
  });

  return (
    <FormControl fullWidth>
      <Autocomplete
        id="icon-selector"
        ListboxComponent={ListboxComponent}
        options={iconOptions}
        PopperComponent={StyledPopper}
        renderInput={renderInput}
        onChange={onChange}
        value={value || ''}
      />
    </FormControl>
  );
};

export default IconSelector;
