import { Box } from '@mui/material';
import { GridToolbarFilterButton } from '@mui/x-data-grid-pro';

import ToolbarColumnsButton from 'src/components/DataTable/Toolbar/ToolbarColumnsButton';

type ToolbarProps = {
  noFilter?: boolean;
  noColumns?: boolean;
};

const Toolbar = ({ noFilter, noColumns }: ToolbarProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme => theme.spacing()
      }}
    >
      {!noFilter && <GridToolbarFilterButton />}
      {!noColumns && <ToolbarColumnsButton />}
    </Box>
  );
};

export default Toolbar;
