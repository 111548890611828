import { Box, Skeleton } from '@mui/material';

const WorkatoConnectSkeleton = () => {
  return (
    <Box
      sx={{
        background: 'white',
        width: '350px',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        flexDirection: 'column'
      }}
    >
      <Skeleton
        animation="wave"
        variant="circular"
        sx={{ width: '100px', height: '100px' }}
      />
      <Skeleton animation="wave" variant="text" sx={{ width: '50%' }} />
      <Skeleton animation="wave" variant="text" sx={{ width: '80%' }} />

      <Skeleton
        animation="wave"
        variant="rounded"
        sx={{ width: '100px', height: '40px', marginTop: '36px' }}
      />
    </Box>
  );
};

export default WorkatoConnectSkeleton;
