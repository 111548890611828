import { flow } from 'lodash';
import { Helmet } from 'react-helmet';

import { withRouter } from 'react-router-dom';
import { t } from 'i18next';

import { Toolbar, Paper, Typography, Button } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import Loading from 'src/components/Loading';
import { DevModeErrorHelper } from 'src/pages/Error/DevModeErrorHelper';

const SPACeING = '40px';

// Note: We have to have this wrapper component in order to be able to pass
//       theme to the auth component and to prevent the app from loading
//       without a theme.
const AppThemeLoading = props => {
  const { loading, children, error } = props;

  if (loading) {
    return (
      <div
        style={{
          alignItems: 'center',
          background: '#fafafa',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          alignItems: 'center',
          background: '#fafafa',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          width: '100%'
        }}
      >
        <Toolbar
          style={{
            borderBottom: '4px solid #bdbdbd',
            margin: '0',
            padding: '0',
            width: '100%'
          }}
        />
        <Helmet>
          <title>{t('skinErrorPageTitle')}</title>
        </Helmet>
        <Paper
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '50px',
            minWidth: '500px',
            padding: SPACeING,
            width: '50%'
          }}
        >
          <ReportIcon
            style={{
              color: '#ef5350',
              height: '300px',
              width: '300px'
            }}
          />
          <Typography
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              lineHeight: '26px',
              marginBottom: SPACeING
            }}
            variant="h1"
          >
            {t('skinErrorTitle')}
          </Typography>
          <Typography
            style={{
              fontSize: '18px',
              lineHeight: '24px',
              marginBottom: SPACeING,
              textAlign: 'center'
            }}
            variant="h2"
          >
            {t('skinError')}
          </Typography>
          <Button onClick={() => window.location.reload()} variant="outlined">
            {t('skinErrorButton')}
          </Button>
          <DevModeErrorHelper />
        </Paper>
      </div>
    );
  }

  return <>{children}</>;
};

export default flow(withRouter)(AppThemeLoading);
