import { gql } from 'src/generated/gql';

export const updateUserSettings = gql(`
  mutation modifyUserSettingsFromAutomatedProgram(
    $updateSettings: JSON!
    $updateType: SettingsUpdateType
  ) {
    modifyUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);

export const deleteProgramDraft = gql(`
  mutation deleteProgramDraft(
    $programDraftId: ID!
  ) {
    deleteProgramDraft(
      programDraftId: $programDraftId
    )
  }
`);
