import { useMemo, useState, useCallback } from 'react';
import { flow } from 'lodash';
import { t } from 'i18next';

import { Button, IconButton, Menu, MenuItem } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AddIcon from '@mui/icons-material/Add';
import AddRequiredIcon from '@mui/icons-material/PlaylistAdd';

const styles = theme => ({
  emptyStateButtonContainer: {
    display: 'flex',

    '& button:first-child': {
      marginRight: theme.spacing(2)
    }
  }
});

const pageText = () => ({
  addButton: t(
    'admin:blueprintBuilder.stepPublishers.facetParameter.addButton'
  ),
  addRequired: t(
    'admin:blueprintBuilder.stepPublishers.facetParameter.addRequiredButton'
  )
});

const AddParameterValue = props => {
  const {
    classes,
    onAddParameterValue,
    onAddRequiredParameters,
    parameterEnums = [],
    selectedParameters,
    variant = 'inline', // inline, emptyState
    parameterValues = []
  } = props;
  const text = useMemo(() => pageText(), []);
  const currentParameters = new Set(parameterValues.map(param => param.key));

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelect = useCallback(
    parameterValue => {
      onAddParameterValue(parameterValue);
      handleClose();
    },
    [parameterEnums]
  );

  const handleAddRequiredParameters = useCallback(() => {
    onAddRequiredParameters(selectedParameters);
    handleClose();
  }, [selectedParameters, parameterEnums]);

  return (
    <>
      {variant === 'inline' && (
        <IconButton onClick={handleClick} size="large">
          <AddIcon />
        </IconButton>
      )}

      {variant === 'emptyState' && (
        <div className={classes.emptyStateButtonContainer}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleClick}
            startIcon={<AddIcon />}
          >
            {text.addButton}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleAddRequiredParameters}
            startIcon={<AddRequiredIcon />}
          >
            {text.addRequired}
          </Button>
        </div>
      )}

      <Menu anchorEl={anchorEl} keepMounted onClose={handleClose} open={open}>
        {parameterEnums.map(option => {
          return (
            <MenuItem
              key={option?.value?.name}
              onClick={() => handleSelect(option?.value)}
              disabled={currentParameters.has(option.value.name)}
            >
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default flow(withStyles(styles))(AddParameterValue);
