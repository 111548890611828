import { gql } from 'src/generated/gql';

export const getAdPreviewSetForPreviewProgramId = gql(`
  query getAdPreviewSetForPreviewProgramId($previewProgramId: ID!) {
    getAdPreviewSetForPreviewProgramId(previewProgramId: $previewProgramId) {
      status
      executionId
      previews {
        status
        previewKey
        link
        iframe
        channel
        message
        creativeType
        fbSingleImageCreative {
          headline
          message
          imageUrl
          description
          callToAction
          facebookPageName
          includesInstagram
          isLeadAd
        }
        fbSingleImageFacetArgs {
          headline
          message
          description
        }
        fbSingleVideoCreative {
          headline
          message
          videoUrl
          imageUrl
          description
          callToAction
          facebookPageName
          includesInstagram
          isLeadAd
        }
        fbSingleVideoFacetArgs {
          headline
          message
          description
        }
        fbCarouselCreative {
          facebookPageName
          message
          includesInstagram
          isLeadAd
          cards {
            headline
            imageUrl
            description
            callToAction
            caption
            link
          }
        }
        fbCarouselFacetArgs {
          message
          cards {
            headline
            description
            caption
            link
          }
        }
        fbDcoCreative {
          isLeadAd
          singleImageAdPermutations {
            headline
            message
            imageUrl
            description
            callToAction
            facebookPageName
            includesInstagram
          }
        }
        fbDcoFacetArgs {
          singleImageAdPermutations {
            headline
            message
            description
          }
        }
        googleResponsiveDisplayCreative {
          permutations {
            headline
            longHeadline
            description
            businessName
            logoImage
            rectangularMarketingImage
            squareMarketingImage
          }
        }
        googleResponsiveDisplayFacetArgs {
          permutations {
            headline
            longHeadline
            description
          }
        }
        googleSearchCreative {
          permutations {
            headline
            description
            finalUrl
            path1
            path2
          }
        }
        googleSearchFacetArgs {
          permutations {
            finalUrl
          }
        }
        googleDiscoveryMultiAssetCreative {
          permutations {
            headline
            description
            rectangleImageUrl
            squareImageUrl
            portraitImageUrl
            logoImageUrl
            callToAction
            isLeadAd
            businessName
          }
        }
        googleDiscoveryMultiAssetFacetArgs {
          permutations {
            headline
            description
          }
        }
        tikTokSingleVideoCreative {
          callToAction
          adDisplayName
          adText
          coverImageUrl
          videoUrl
          identityImageUrl
        }
      }
    }
  }
`);

export const getBulkPreviewJob = gql(`
  query getBulkPreviewJob($jobId: ID!) {
    getBulkPreviewJob(jobId: $jobId) {
      jobId
      finishedProgramCreation
      programCreationOutcome
      previewSets {
        status
        executionId
        previewProgramId
        previews {
          status
          previewKey
          link
          iframe
          channel
          message
          creativeType
          fbSingleImageCreative {
            headline
            message
            imageUrl
            description
            callToAction
            facebookPageName
            includesInstagram
            isLeadAd
          }
          fbSingleImageValidationErrors {
            headline {
              errors {
                errorCode
              }
            }
            message {
              errors {
                errorCode
              }
            }
            description {
              errors {
                errorCode
              }
            }
          }
          fbSingleVideoCreative {
            headline
            message
            videoUrl
            imageUrl
            description
            callToAction
            facebookPageName
            includesInstagram
            isLeadAd
          }
          fbSingleVideoValidationErrors {
            headline {
              errors {
                errorCode
              }
            }
            message {
              errors {
                errorCode
              }
            }
            description {
              errors {
                errorCode
              }
            }
          }
          fbCarouselCreative {
            facebookPageName
            message
            includesInstagram
            isLeadAd
            cards {
              headline
              imageUrl
              description
              callToAction
              caption
              link
            }
          }
          fbCarouselValidationErrors {
            message {
              errors {
                errorCode
              }
            }
            cards {
              headline {
                errors {
                  errorCode
                }
              }
              description {
                errors {
                  errorCode
                }
              }
              caption {
                errors {
                  errorCode
                }
              }
            }
          }
          fbDcoCreative {
            isLeadAd
            singleImageAdPermutations {
              headline
              message
              imageUrl
              description
              callToAction
              facebookPageName
              includesInstagram
            }
          }
          fbDcoValidationErrors {
            singleImageAdPermutations {
              headline {
                errors {
                  errorCode
                }
              }
              message {
                errors {
                  errorCode
                }
              }
              description {
                errors {
                  errorCode
                }
              }
            }
          }
          googleResponsiveDisplayCreative {
            permutations {
              headline
              longHeadline
              description
              businessName
              logoImage
              rectangularMarketingImage
              squareMarketingImage
            }
          }
          googleResponsiveDisplayValidationErrors {
            permutations {
              headline {
                errors {
                  errorCode
                }
              }
              longHeadline {
                errors {
                  errorCode
                }
              }
              description {
                errors {
                  errorCode
                }
              }
            }
          }
          googleSearchCreative {
            permutations {
              headline
              description
              finalUrl
              path1
              path2
            }
          }
          googleSearchValidationErrors {
            permutations {
              headline {
                errors {
                  errorCode
                }
              }
              description {
                errors {
                  errorCode
                }
              }
              finalUrl {
                errors {
                  errorCode
                }
              }
            }
          }
          googleDiscoveryMultiAssetCreative {
            permutations {
              headline
              description
              rectangleImageUrl
              squareImageUrl
              portraitImageUrl
              logoImageUrl
              callToAction
              isLeadAd
              businessName
            }
          }
          googleDiscoveryMultiAssetValidationErrors {
            finalUrl {
              errors {
                errorCode
              }
            }
            permutations {
              headline {
                errors {
                  errorCode
                }
              }
              description {
                errors {
                  errorCode
                }
              }
            }
          }
          tikTokSingleVideoCreative {
            callToAction
            adDisplayName
            adText
            coverImageUrl
            videoUrl
            identityImageUrl
          }
        }
        locationId
        inputFieldValidationErrors {
          previewProgramId
          inputFieldId
          errorCode
        }
      }
    }
  }
`);
