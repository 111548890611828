import { useQuery } from '@apollo/client';

import { getMediaBillingHistory } from '../queries';

const useGetMediaBillingHistory = () => {
  const mediaBillingHistoryResult = useQuery(getMediaBillingHistory);

  const mediaBillingHistory = {
    error: mediaBillingHistoryResult?.error,
    loading: mediaBillingHistoryResult?.loading,
    rawRows: mediaBillingHistoryResult?.data?.mediaBillingHistory?.edges || []
  };

  return mediaBillingHistory;
};

export default useGetMediaBillingHistory;
