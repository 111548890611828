import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MaybeAccordionWrapper = ({
  index,
  children,
  shouldUseAccordions,
  openIndexes,
  handleAccordionToggle,
  headerItemText
}) => {
  if (!shouldUseAccordions) {
    return <div>{children}</div>;
  }

  return (
    <Accordion
      expanded={openIndexes.includes(index)}
      onChange={() => handleAccordionToggle(index)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
      >
        {headerItemText(index + 1)}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default MaybeAccordionWrapper;
