import { MouseEvent } from 'react';
import {
  GridRenderCellParams,
  GridApi,
  GridTreeNodeWithRender
} from '@mui/x-data-grid-pro';
import { Box, Button, IconButton } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AdStatus from 'src/components/Status/AdStatus';

interface StatusGroupingCellProps {
  params: GridRenderCellParams;
  setGroupExpandedByDefault: (expanded: boolean) => Promise<void>;
  isAutomation?: boolean;
}

const StatusGroupingCell = ({
  params,
  setGroupExpandedByDefault,
  isAutomation = false
}: StatusGroupingCellProps) => {
  const { id, row, api, rowNode } = params;

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (rowNode.type !== 'group') {
      return;
    }

    setGroupExpandedByDefault(!rowNode.childrenExpanded).catch(() => {});

    (api as GridApi).setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  };

  if (params.rowNode.type === 'group') {
    const rowNodeWithRender = params.rowNode as GridTreeNodeWithRender;
    const isChildrenExpanded =
      rowNodeWithRender && 'childrenExpanded' in rowNodeWithRender
        ? rowNodeWithRender.childrenExpanded
        : false;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button onClick={handleClick} size="large" data-cy="status-grouping">
          {isChildrenExpanded ? (
            <ExpandLessIcon
              sx={{ marginRight: theme => theme.spacing() }}
              color="action"
            />
          ) : (
            <ExpandMoreIcon
              sx={{ marginRight: theme => theme.spacing() }}
              color="action"
            />
          )}
          {isChildrenExpanded ? (
            <FolderOpenIcon color="action" />
          ) : (
            <FolderIcon color="action" />
          )}
        </Button>
      </Box>
    );
  }

  const expandedDetailPanels = (api as GridApi).getExpandedDetailPanels();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isAutomation && (
        <IconButton
          onClick={e => {
            e.stopPropagation();
            (api as GridApi).toggleDetailPanel(params.id);
          }}
        >
          {expandedDetailPanels.includes(id) ? (
            <ExpandLessIcon color="action" />
          ) : (
            <ExpandMoreIcon color="action" />
          )}
        </IconButton>
      )}
      <AdStatus
        order={row}
        tableView
        renderTextStatus
        isAutomation={isAutomation}
      />
    </Box>
  );
};

export default StatusGroupingCell;
