import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material';

import AssignmentIcon from '@mui/icons-material/Assignment';
import DeleteIcon from '@mui/icons-material/Delete';

import { translateMaps } from 'src/common/templateTranslator';

const BusinessObjectListItem = ({
  businessObject,
  displayImageTemplate,
  displayNameTemplate,
  onDelete,
  openModal
}) => {
  const imageUrl = translateMaps(displayImageTemplate, businessObject);
  const title = translateMaps(displayNameTemplate, businessObject);

  const handleDelete = e => {
    // Stops the click event from bubbling up to the ListItemButton
    e.stopPropagation();
    onDelete();
  };

  return (
    <ListItem
      data-cy="business-object-item"
      sx={{
        maxWidth: 500,
        sm: {
          // sm === [theme.breakpoints.down('sm')]
          px: 0
        }
      }}
    >
      <ListItemButton onClick={openModal}>
        <ListItemAvatar>
          <Avatar>
            {imageUrl ? (
              <img
                sx={{
                  objectFit: 'cover',
                  width: '50px',
                  height: '50px'
                }}
                src={imageUrl}
                alt={title}
              />
            ) : (
              <AssignmentIcon />
            )}
          </Avatar>
        </ListItemAvatar>
        {title && <ListItemText primary={title} sx={{ pr: 6 }} />}
        <ListItemSecondaryAction>
          <IconButton
            data-cy="bo-item-delete"
            aria-label="Delete"
            onClick={handleDelete}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

export default BusinessObjectListItem;
