import { t } from 'i18next';

interface GetEditButtonStatusArgs {
  disabled: boolean;
  hasOrder: boolean;
  architectureHasCatalog: boolean;
  hasBusinessObjects: boolean;
  hasWritePermission: boolean;
}

export const getEditButtonStatus = ({
  disabled,
  hasOrder,
  architectureHasCatalog,
  hasBusinessObjects,
  hasWritePermission
}: GetEditButtonStatusArgs) => {
  let tooltip = '';
  let isDisabled = false;

  if (disabled) {
    isDisabled = true;
    tooltip = t('programPerf:editProgram.cantEdit');
  }

  if (hasOrder && architectureHasCatalog && !hasBusinessObjects) {
    isDisabled = true;
    tooltip = t('programPerf:editProgram.cantEditWrongOffice');
  }

  if (!hasWritePermission) {
    isDisabled = true;
    tooltip = t('programPerf:editProgram.cantEditNoPermission');
  }

  return { tooltip, isDisabled };
};
