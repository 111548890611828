import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';

export interface CsvExampleTableProps {
  table: string[][];
}

export const CsvExampleTable = ({ table }: CsvExampleTableProps) => {
  const firstRow = table[0];
  const allOtherRows = table.filter((_value, index) => index > 0);
  return (
    <TableContainer
      component={Paper}
      sx={{ display: 'inline-block', width: 'auto' }}
    >
      <Table sx={{ width: 'auto' }} size="small">
        <TableHead>
          <TableRow
            sx={theme => ({ backgroundColor: theme.palette.grey[300] })}
          >
            {firstRow.map(cell => {
              return (
                <TableCell key={cell} align="center">
                  {cell}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        {allOtherRows.map(row => {
          return (
            <TableRow key={row[0]}>
              {row.map(cell => (
                <TableCell key={cell} align="center">
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </Table>
    </TableContainer>
  );
};
