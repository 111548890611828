import { isString } from 'lodash';
import { FormControl, Select, FormHelperText, MenuItem } from '@mui/material';
import { t } from 'i18next';
import { useController } from 'react-hook-form';

import { ContentSetFieldMetadata } from './helpers';

type RenderFilterValueProps = {
  selectedColumn: ContentSetFieldMetadata;
  name: string;
  validate: any;
};

const RenderFilterValue = ({
  selectedColumn,
  name,
  validate
}: RenderFilterValueProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, rules: { validate } });
  const showError = !!error;
  if (error && !isString(error?.message)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }

  const items: string[] = (selectedColumn?.enumValues ?? []) as string[];
  return (
    <FormControl error={showError}>
      <Select {...field} name={name} displayEmpty variant="standard">
        <MenuItem value="">
          {t('automatedProgramFilter:renderValueSelect.empty')}
        </MenuItem>
        {items.map(item => (
          <MenuItem value={item} key={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      {showError && (
        <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
          {error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderFilterValue;
