import { useCallback, useMemo, useState } from 'react';
import { t } from 'i18next';
import { flow, isEqual, some, uniq } from 'lodash';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';

import { Button, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Loading from 'src/components/Loading';

const CSV_EXT_REGEX = /.+(\.csv)$/;

const styles = theme => ({
  dropzoneContainer: {
    height: '200px',
    backgroundColor: '#fafafa',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    borderWidth: '2px',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    userSelect: 'none'
  },
  dropzoneText: {
    paddingTop: theme.spacing(2)
  }
});

const pageText = () => ({
  retry: t('adminContentDataManager:uploadModal.retry'),
  back: t('adminContentDataManager:uploadModal.back'),
  continue: t('adminContentDataManager:uploadModal.continue'),
  error: t('adminContentDataManager:uploadModal.error'),
  errorNumberOfColumns: t(
    'adminContentDataManager:uploadModal.errorNumberOfColumns'
  ),
  errorColumnNames: t('adminContentDataManager:uploadModal.errorColumnNames'),
  errorNoData: t('adminContentDataManager:uploadModal.errorNoData'),
  errorNotCsv: t('adminContentDataManager:uploadModal.errorNotCsv'),
  dragAndDropOrClick: t(
    'adminContentDataManager:uploadModal.dragAndDropOrClick'
  ),
  dragAndDrop: t('adminContentDataManager:uploadModal.dragAndDrop')
});

const StepUploadFile = ({
  classes,
  handleNext,
  handleBack,
  setFileParseResults,
  contentSetFields
}) => {
  const text = useMemo(() => pageText(), []);

  const [isUploading, setIsUploading] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    setFileErrors([]);

    if (some(fileRejections)) {
      const errors = fileRejections.flatMap(r => r.errors.map(e => e.message));

      setFileErrors(uniq(errors));
    } else if (some(acceptedFiles)) {
      setIsUploading(true);

      const file = acceptedFiles[0];

      Papa.parse(file, {
        delimiter: ',',
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: results => {
          setIsUploading(false);

          const { data, meta, errors } = results;

          if (some(errors)) {
            setFileErrors(errors.map(e => e.message));
            return;
          }

          const { fields } = meta;
          const expectedFields = contentSetFields.map(csf => csf.name);

          if (fields.length !== expectedFields.length) {
            setFileErrors([text.errorNumberOfColumns]);
            return;
          }

          if (!isEqual(fields.sort(), expectedFields.sort())) {
            setFileErrors([text.errorColumnNames]);
            return;
          }

          if (!some(data)) {
            setFileErrors([text.errorNoData]);
            return;
          }

          setFileParseResults(results);
          handleNext();
        }
      });
    }
  }, []);

  const validator = file => {
    const errors = [];

    if (!CSV_EXT_REGEX.test(file.name)) {
      errors.push({
        message: text.errorNotCsv,
        code: 'UNSUPPORTED_FILE_TYPE'
      });
    }

    return some(errors) ? errors : undefined;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    validator
  });

  const reset = () => {
    setIsUploading(false);
    setFileErrors(false);
  };

  const hasError = some(fileErrors);
  const isLoadingOrError = isUploading || hasError;

  return (
    <>
      {isLoadingOrError && (
        <Loading
          loading={isUploading}
          error={text.error}
          errorMessage={`${text.error}: ${fileErrors.join('; ')}`}
        />
      )}
      {!isLoadingOrError && (
        <div className={classes.dropzoneContainer} {...getRootProps()}>
          <input
            data-cy="content-data-manager-csv-drag-and-drop"
            {...getInputProps()}
          />
          <Typography className={classes.dropzoneText}>
            {isDragActive ? text.dragAndDrop : text.dragAndDropOrClick}
          </Typography>
        </div>
      )}
      <div>
        <Button
          disabled={isUploading}
          variant="outlined"
          onClick={() => {
            reset();
            handleBack();
          }}
          sx={{ mt: 1, mr: 1 }}
        >
          {text.back}
        </Button>{' '}
        {hasError && (
          <Button variant="outlined" onClick={reset} sx={{ mt: 1, mr: 1 }}>
            {text.retry}
          </Button>
        )}
      </div>
    </>
  );
};

export default flow(withStyles(styles))(StepUploadFile);
