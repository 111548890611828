import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { forEach, isEmpty } from 'lodash';

import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { getOneOfEachChannelKeys } from 'src/common/blueprints';
import SentryUtil from 'src/common/SentryUtil';
import { AdPreviewSet, Product } from 'src/generated/gql/graphql';
import { useArchitecture } from 'src/pages/Architecture/ArchitectureProvider';
import { UseFormTrigger } from 'react-hook-form';

import { fetchUrlValidation } from './queries';
import { validateChannels } from './validation';
import { getInputValidators } from './validation/validation';

type CreativeValidationErrors = Record<string, any>;

interface UseCreativeValidationsArgs {
  product: Product;
  isAutomated: boolean;
  formName: string;
  hasFormSubmitErrors: boolean;
  trigger: UseFormTrigger<any>;
}

const useCreativeValidationsHandler = ({
  product,
  isAutomated,
  trigger
}: UseCreativeValidationsArgs) => {
  const architecture = useArchitecture();

  const [inputValidators, setInputValidators] = useState<Record<
    string,
    any
  > | null>(null);

  const [previousInputValidators, setPreviousInputValidators] = useState<Record<
    string,
    any
  > | null>(null);

  useEffect(() => {
    if (previousInputValidators !== inputValidators) {
      setPreviousInputValidators(inputValidators);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      trigger();
    }
  }, [
    trigger,
    inputValidators,
    previousInputValidators,
    setPreviousInputValidators
  ]);

  // isPollingPreview state is not actually used in this hook but are only used for the purpose of creative validations
  // they are passed into the AdPreview and FormFooter components
  const [isPollingPreview, setIsPollingPreview] = useState(false);

  const [creativeValidationErrors, setCreativeValidationErrors] =
    useState<CreativeValidationErrors | null>(null);

  // This ensures that the user can't click through before initial validations kick off
  // if the feature flag is enabled
  const [isValidatingCreative, setIsValidatingCreative] =
    useState(!isAutomated);

  const [validateUrl] = useLazyQuery(fetchUrlValidation);

  const formatChannelValidationErrors = (
    errors: CreativeValidationErrors | null
  ) => {
    const allErrors = { ...errors };
    // Merge error messages from two related creative fields into one list of errors
    if (allErrors.headline && allErrors.longHeadline) {
      allErrors.headline = {
        ...allErrors.longHeadline,
        ...allErrors.headline
      };
      delete allErrors.longHeadline;
    }

    if (allErrors.message) {
      allErrors.description = {
        ...allErrors.message,
        ...(allErrors.description || [])
      };
      delete allErrors.message;
    }

    return isEmpty(allErrors) ? null : allErrors;
  };

  const clearUpdatedInputCreativeErrors = useCallback(() => {
    if (!isEmpty(creativeValidationErrors)) {
      setCreativeValidationErrors(null);
    }
  }, [creativeValidationErrors]);

  const handleAdContentChannelValidation = (adSetContent: AdPreviewSet) => {
    // Start async validation to show loading state
    // dispatch(startAsyncValidation(formName));

    const previews = adSetContent?.previews;

    const channelInputValidators = getInputValidators(previews);
    setInputValidators(channelInputValidators);

    // Validate ad set preview
    validateChannels({ previews, validateUrl })
      .then(result => {
        const errors = isEmpty(result) ? null : result;

        const formattedChannelValidationErrors =
          formatChannelValidationErrors(errors);

        // Set validation errors in state if any
        setCreativeValidationErrors(formattedChannelValidationErrors);
        // Send error analytics via amplitude
        forEach(
          formattedChannelValidationErrors,
          (errorData, creativeField) => {
            forEach(errorData?.errors, (_error, errorKey) => {
              Instrumentation.logEvent(Events.AdCreativeValidationError, {
                channel: getOneOfEachChannelKeys(product?.blueprint?.channels),
                productId: product?.id,
                architectureId: architecture?.id,
                error: errorKey,
                inputName: creativeField
              });
            });
          }
        );

        if (isValidatingCreative) {
          setIsValidatingCreative(false);
        }
      })
      .catch(error => {
        SentryUtil.captureException(error);
        if (isValidatingCreative) {
          setIsValidatingCreative(false);
        }
      });
  };

  return {
    handleAdContentChannelValidation,
    isValidatingCreative,
    setIsValidatingCreative,
    creativeValidationErrors,
    isPollingPreview,
    setIsPollingPreview,
    clearUpdatedInputCreativeErrors,
    inputValidators
  };
};

export default useCreativeValidationsHandler;
