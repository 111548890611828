import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const FORM_NAME = 'editQuickstart';

export const labelInputs = (isCustom = false) => {
  return [
    {
      name: 'id',
      displayName: 'Id',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: true,
      disabled: false,
      isHidden: true,
      displayParameters: {
        inputData: {
          columnWidth: 1
        }
      }
    },
    {
      name: 'priority',
      displayName: 'Priority',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      disabled: false,
      isHidden: true,
      displayParameters: {
        inputData: {
          columnWidth: 1
        }
      }
    },
    {
      name: 'isActive',
      displayName: 'Active',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: true,
      disabled: false,
      isHidden: true,
      displayParameters: {
        inputData: {
          columnWidth: 1
        }
      }
    },
    {
      name: 'name',
      displayName: 'Title',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: !isCustom, // required if not custom
      disabled: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 1
        }
      }
    },
    {
      name: 'description',
      displayName: 'Description',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      disabled: false,
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 1
        }
      }
    }
  ];
};

export const customDesignInputs = [
  {
    name: 'isCustom',
    displayName: 'Custom',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 3
      }
    }
  }
];
