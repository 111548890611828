import { gql } from 'src/generated/gql';

export const getCnames = gql(`
  query getCnames {
    myOrganization {
      id
      domains(showInactive: true) {
        id
        organizationId
        fqdn
        authenticationUrl
        logoutUrl
        isPrimary
        isActive
      }
    }
  }
`);
