import { isString } from 'lodash';
import {
  FormControl,
  TextField,
  FormHelperText,
  InputAdornment
} from '@mui/material';

import { useController } from 'react-hook-form';

type RenderFilterValueDateProps = {
  name: string;
  validate: any;
};

const RenderFilterValueDate = ({
  name,
  validate
}: RenderFilterValueDateProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, rules: validate });

  const showError = !!error;

  if (error && !isString(error?.message)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }

  return (
    <FormControl error={showError}>
      <TextField
        {...field}
        type="number"
        name={name}
        error={showError}
        variant="standard"
        InputProps={{
          endAdornment: <InputAdornment position="end">Days</InputAdornment>,
          inputProps: {
            step: 1,
            min: 0
          }
        }}
      />
      {showError && (
        <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
          {error?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderFilterValueDate;
