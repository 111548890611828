import { Button, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';

const TooltipButton = props => {
  const { displayTooltip, tooltip, children, ...rest } = props;
  const isProgramCreatePage = useIsProgramCreatePage();
  const ButtonComponent = isProgramCreatePage ? LoadingButton : Button;

  const baseButton = <ButtonComponent {...rest}>{children}</ButtonComponent>;
  // The button is wrapoped in a span element so the tooltip can display when the button is disabled
  // https://mui.com/material-ui/react-tooltip/#disabled-elements
  if (displayTooltip)
    return (
      <Tooltip title={tooltip}>
        <span>{baseButton}</span>
      </Tooltip>
    );

  return baseButton;
};

export default TooltipButton;
