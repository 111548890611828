import Instrumentation from 'src/instrumentation';
import { TrackingData } from 'src/pages/Program/utils/ProgramProvider';

export const actions = {
  modal: 'modal',
  table: 'table'
} as const;

type ActionsKeys = keyof typeof actions;
type Action = (typeof actions)[ActionsKeys];

interface LogClickMlpRestoreLocationToDefaultArgs {
  locationId: string;
  trackingData: TrackingData;
  action: Action;
}

export const logClickMlpRestoreLocationToDefault = ({
  locationId,
  trackingData,
  action
}: LogClickMlpRestoreLocationToDefaultArgs) => {
  Instrumentation.logEvent(
    Instrumentation.Events.ClickMlpRestoreLocationToDefault,
    { ...trackingData, locationId, action }
  );
};
