import { useState, useMemo, useEffect } from 'react';
import { flow, isNil, some } from 'lodash';
import { t } from 'i18next';
import { parse } from 'qs';

import { Button, Grid, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';
import { withRouter } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

import Auth from 'src/Auth/Auth';

import { useInterval } from 'src/hooks/useInterval';

import Loading from 'src/components/Loading';
import PageTitle from 'src/components/PageTitle';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  loadingContainer: {
    margin: '20px auto',
    padding: '20px',
    width: '350px'
  },
  loadingIcon: {
    marginTop: '10px'
  },
  icon: {
    color: theme.palette.primary.light,
    height: '300px',
    width: '300px'
  },
  button: {
    margin: theme.spacing(1)
  },
  companyLogo: {
    width: '80%'
  },
  errorIcon: {
    width: '50px',
    height: '50px',
    color: theme.palette.error.dark
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.error.dark
  },
  errorMessage: {
    color: theme.palette.error.dark
  },
  loadingText: {
    fontSize: '18px',
    margin: `${theme.spacing(4)} 0`
  }
});

const pageText = () => ({
  pageTitle: t('login:sso.pageTitle'),
  tryAgainButton: t('login:sso.tryAgainButton'),
  errorMessage: t('login:sso.error'),
  errorMessageImport: t('login:sso.errorImport'),
  loggingYouIn: t('login:sso.loggingYouIn'),
  importingUser: t('login:sso.importingUser')
});

const Sso = props => {
  const { classes, appSettings } = props;

  const text = useMemo(() => pageText(), []);

  const [polling, setPolling] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(text.errorMessage);

  const setErrorState = () => {
    setPolling(false);
    setLoading(false);
    setError(true);
  };

  const callExchangeToken = () => {
    const {
      location: { search }
    } = props;

    const currentQueryParams = parse(search.substr(1)) || {};
    const orgId = appSettings?.app?.general?.organizationId || null;
    const token = currentQueryParams?.token || currentQueryParams?.code || null;

    // if third party returns an error
    if (currentQueryParams.error && !error) {
      setError(true);
    }

    // no token in the url
    if (!token) {
      // no need to poll if there is not a token
      setErrorState();
      return;
    }

    Auth.exchangeToken(token, orgId)
      .then(response => {
        // token will be null for first time users that need their data imported
        const importing = isNil(response?.token);

        // if there is execution Errors we should be in an error state.
        if (
          importing &&
          some(
            response?.pullProviderExecutionStatuses,
            status => !isNil(status?.executionErrors)
          )
        ) {
          setErrorMessage(text.errorMessageImport);
          setErrorState();
          return;
        }

        // if importing user continue to poll
        setPolling(importing);
        setLoading(importing);
      })
      .catch(() => {
        // stop polling if error
        setErrorState();
      });
  };

  // initial load call
  useEffect(() => {
    callExchangeToken();
  }, []);

  // poll exchange token if needed
  useInterval(callExchangeToken, polling ? 5000 : null);

  const authenticationUrl = appSettings?.app?.auth?.authenticationUrl;

  const logo = appSettings?.evAssets?.logoUrl ? (
    <img
      alt={`${appSettings?.app.general.baseTitle} Logo`}
      className={classes.companyLogo}
      src={appSettings?.evAssets?.logoUrl}
    />
  ) : null;

  return (
    <Grid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle={text.pageTitle} />
      {(loading || polling) && (
        <Paper className={classes.loadingContainer}>
          {logo}
          <Typography className={classes.loadingText}>
            {polling ? text.importingUser : text.loggingYouIn}
          </Typography>
          <Loading className={classes.loadingIcon} />
        </Paper>
      )}
      {error && (
        <Paper className={classes.loadingContainer}>
          {logo}
          <div className={classes.errorMessageContainer}>
            <ErrorIcon className={classes.errorIcon} />
            {errorMessage}
            <br /> <br />
            <Button
              color="primary"
              size="large"
              variant="contained"
              href={authenticationUrl}
            >
              {text.tryAgainButton}
            </Button>
          </div>
        </Paper>
      )}
    </Grid>
  );
};

export default flow(withStyles(styles), withAppSettings, withRouter)(Sso);
