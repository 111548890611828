import { gql } from 'src/generated/gql';

export const getTikTokAdvertiserAuthUrl = gql(`
  query getTikTokAdvertiserAuthUrl($redirectUrl: String!) {
    getTikTokAdvertiserAuthUrl(redirectUrl: $redirectUrl) {
      authUrl
    }
  }
`);

export const getStripeConnectedAccountDetails = gql(`
  query getStripeConnectedAccountDetails {
    myOrganization {
      stripeConnectedAccountDetails {
        accountId
        accountName
        createdAt
        updatedAt
        completedAt
        connectedStatus
      }
    }
  }
`);
