import Draft, { EditorState, Modifier, SelectionState } from 'draft-js';
import { MouseEvent } from 'react';
import { noop } from 'lodash';
import CancelIcon from '@mui/icons-material/Cancel';
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import { makeStyles } from '@mui/styles';

const pageText = (label: string) => ({
  tooltip: t('renderTemplateStringTextField:missingTooltip', {
    label
  })
});

const useStyles = makeStyles(
  () =>
    ({
      chip: {
        borderRadius: '16px',
        whiteSpace: 'nowrap',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        position: 'relative',
        padding: '1px 0px',
        fontSize: '14px'
      },
      delete: {
        position: 'relative',
        top: '4px',
        fontSize: '17px',
        color: 'rgba(0, 0, 0, 0.26)',
        cursor: 'pointer',
        '&:hover': {
          color: 'rgba(0, 0, 0, 0.4)'
        }
      },
      tag: {
        '&::before': {
          content: '"-"',
          color: 'rgb(0,0,0,0)'
        },
        '&::after': {
          content: '"-"',
          color: 'rgb(0,0,0,0)'
        }
      }
    }) as const
);

const zeroWidthSpace = '\u200B';

export interface TokenSpanProps {
  decoratedText: string;
  contentState: Draft.ContentState;
  entityKey: string;
  offsetKey: string;
  blockKey: string;
  start: number;
  end: number;
  setEditorState: (callback: (editorState: Draft.EditorState) => void) => void;
  handleEditorChange: (editorState: Draft.EditorState) => void;
  missingColumns: Set<string>;
  readOnly?: boolean;
}

export const TokenSpan = ({
  decoratedText,
  contentState,
  entityKey,
  offsetKey,
  blockKey,
  start,
  end,
  setEditorState,
  handleEditorChange,
  missingColumns,
  readOnly
}: TokenSpanProps) => {
  const classes = useStyles();
  const { fieldName, label } = (contentState.getEntity(entityKey) as any).data;
  const text = pageText(label);

  const onTagDelete = (e: MouseEvent) => {
    // we want to avoid calling focus() events further up.
    e.preventDefault();
    e.stopPropagation();

    setEditorState(editorState => {
      const removedRange = Modifier.removeRange(
        editorState.getCurrentContent(),
        SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: end
        }),
        'forward'
      );
      const newState = EditorState.forceSelection(
        // new editor state
        EditorState.push(editorState, removedRange, 'remove-range'),
        // and set selection to where we deleted it.
        SelectionState.createEmpty(blockKey).merge({
          anchorOffset: start,
          focusOffset: start
        })
      );
      // we need to call this to make sure the form state is updated
      handleEditorChange(newState);
      return newState;
    });
  };

  const handleClick = (e: MouseEvent) => {
    // set selection to end of chip if they click on it.
    e.preventDefault();
    e.stopPropagation();
    setEditorState(editorState => {
      return EditorState.forceSelection(
        editorState,
        SelectionState.createEmpty(blockKey).merge({
          anchorOffset: end,
          focusOffset: end
        })
      );
    });
  };

  const isMissing = missingColumns.has(fieldName);

  const chip = (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      data-offset-key={offsetKey}
      data-cy="chip-body"
      contentEditable={false}
      className={classes.chip}
      onClick={
        readOnly
          ? noop
          : e => {
              handleClick(e);
            }
      }
      style={{
        userSelect: 'none',
        ...(isMissing && {
          color: 'red',
          borderColor: 'red'
        })
      }}
    >
      {/* in readOnly mode we don't have the icon so we need more padding */}
      <span style={{ paddingRight: '5px' }}>{zeroWidthSpace}</span>
      {decoratedText.slice(1, -1)}
      {!readOnly && (
        // don't show the delete icon if we are in readOnly mode
        <CancelIcon
          fontSize="small"
          className={classes.delete}
          onClick={e => onTagDelete(e)}
        />
      )}
      <span style={{ paddingLeft: readOnly ? '5px' : '1px' }}>
        {zeroWidthSpace}
      </span>
    </span>
  );

  if (isMissing) {
    return (
      <Tooltip
        title={text.tooltip}
        data-offset-key={offsetKey}
        contentEditable={false}
      >
        {chip}
      </Tooltip>
    );
  }

  return chip;
};
