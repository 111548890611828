import { merge } from 'lodash';
import { GridFilterModel, getGridStringOperators } from '@mui/x-data-grid-pro';

export const SELECTED_LOCATIONS_FIELD_NAME = 'selectedLocations';
export const LOCATIONS_OVERRIDES_FIELD_NAME = 'locationsOverride';
export const LOCATIONS_OVERRIDES_BY_ID_NAME = 'locationsOverrideById';

type mergeProps = {
  formDefaults: { [key: string]: any };
  overrides: { [key: string]: any };
};
export const mergeLocationOverrides = ({
  formDefaults,
  overrides
}: mergeProps) => {
  return merge(formDefaults, overrides);
};

const headersToFilterNameMap: { [key: string]: string } = {
  locationId: 'locationIds',
  name: 'names',
  street: 'streets',
  region: 'regions',
  city: 'cities',
  postalCode: 'postalCodes',
  district: 'districts',
  territory: 'territories',
  country: 'countries',
  latitude: 'latitudes',
  longitude: 'longitudes',
  websiteUrl: 'websiteUrls',
  phone: 'phones',
  emailAddress: 'emailAddresses',
  isOpen: 'isOpen'
};

export const filterModelToLocationsFilter = (filterModel: GridFilterModel) => {
  if (filterModel.items.length < 1) {
    return {};
  }
  return filterModel.items.reduce(
    (filter: { [key: string]: string[] }, item) => {
      const key = headersToFilterNameMap[item.field];
      return {
        ...filter,
        [key]: [...(filter?.[key] || []), item.value]
      };
    },
    {}
  );
};

export const locationsFilterOperators = getGridStringOperators().filter(
  ({ value }) => {
    return ['contains'].includes(value);
  }
);
