import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Tooltip, IconButton, Typography } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';

import { getOneOfEachChannelKeys } from 'src/common/blueprints';

import CancelProgramModal from './CancelProgramModal/CancelProgramModal';
import { getCancelButtonStatus } from './helpers';

const CancelProgramButton = props => {
  const {
    order = null,
    disabled = false,
    refetch,
    minimumDurationDays,
    hasMinimumDuration,
    hasDeletePermission
  } = props;

  const [open, setOpen] = useState(false);

  const cancelButtonStatus = getCancelButtonStatus({
    disabled,
    hasDeletePermission,
    hasMinimumDuration,
    minimumDurationDays
  });

  return (
    <>
      <Tooltip title={cancelButtonStatus.tooltip}>
        <span>
          <IconButton
            data-cy="cancel-program-button"
            data-amp-click-cancel-program={JSON.stringify({
              orderId: order?.orderItem?.id,
              architectureId: order?.architectureId,
              productId: order?.orderItem?.product?.id,
              paymentAmount: order?.billingDetails?.amount,
              channel: getOneOfEachChannelKeys(order?.channels?.[0]?.channels)
            })}
            color="primary"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0.5
            }}
            onClick={() => {
              // refetch order to be sure status is correct when canceling
              refetch();
              setOpen(true);
            }}
            disabled={cancelButtonStatus.isDisabled}
          >
            <CancelIcon fontSize="small" />
            <Typography component="span" variant="body2">
              <Trans i18nKey="programPerf:cancelModal.cancel" />
            </Typography>
          </IconButton>
        </span>
      </Tooltip>

      {open && (
        <CancelProgramModal
          open={open}
          onClose={modalState => setOpen(modalState)}
          order={order}
          refetchOrder={refetch}
        />
      )}
    </>
  );
};

export default CancelProgramButton;
