export const objectives = {
  productPurchase: 'product_purchase',
  applicationInstalls: 'application_installs',
  brandAwareness: 'brand_awareness',
  generateContacts: 'generate_contacts',
  generateTraffic: 'generate_traffic',
  messaging: 'messaging',
  postEngagement: 'post_engagement',
  reach: 'reach',
  videoViews: 'video_views',
  websiteRegistrations: 'website_registrations'
};

interface Objective {
  slug: string;
  generateContacts: string;
}
interface OrderItem {
  product: {
    blueprint: {
      objectives: Objective[];
    };
  };
}

const getObjectivesFromOrderItem = (orderItem: OrderItem) => {
  if (!orderItem) {
    return [];
  }

  return orderItem?.product?.blueprint?.objectives?.map(
    (objective: Objective) => objective?.slug
  );
};

export const isOrderItemLeadAd = (orderItem: OrderItem) => {
  const currentObjectives = getObjectivesFromOrderItem(orderItem);

  return currentObjectives?.includes(objectives?.generateContacts);
};
