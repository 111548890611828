import { useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, AppBar, Tabs, Tab, Box, styled, Theme } from '@mui/material';

import Modal from 'src/components/Modal';
import { s3BaseUrl } from 'src/components/AdPreview/S3Image';

interface StyledComponentProps {
  theme: Theme;
}

const ContentContainer = styled('div')(({ theme }: StyledComponentProps) => ({
  padding: theme.spacing(4)
}));

const ListContainer = styled('ol')(({ theme }: StyledComponentProps) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(2)
}));

const TabContent = [
  {
    label: <Trans i18nKey="facebookSettings:pageLink.pageAdminErrorLabel" />,
    key: 'pageAdmin',
    content: () => {
      return (
        <ContentContainer>
          <Trans i18nKey="facebookSettings:pageLink.pageAdminError_01" />

          <ListContainer>
            <Box component="li">
              <Trans
                i18nKey="facebookSettings:pageLink.pageAdminError_02"
                components={[<strong />]}
              />
              <br />
              <br />
              <img
                src={`${s3BaseUrl}help/facebook/pageAdmin/01-page-admin.png`}
                alt="Facebook Admin Settings"
              />
            </Box>
            <li>
              <Trans
                i18nKey="facebookSettings:pageLink.pageAdminError_03"
                components={[<strong />]}
              />

              <br />
              <br />
              <img
                src={`${s3BaseUrl}help/facebook/pageAdmin/02-page-admin.png`}
                alt="Facebook Admin Settings"
              />
            </li>
            <li>
              <Trans
                i18nKey="facebookSettings:pageLink.pageAdminError_04"
                components={[<strong />]}
              />

              <br />
              <br />
              <img
                src={`${s3BaseUrl}help/facebook/pageAdmin/03-page-admin.png`}
                alt="Facebook Admin Settings"
              />
            </li>
            <li>
              <Trans i18nKey="facebookSettings:pageLink.pageAdminError_05" />
            </li>
          </ListContainer>
        </ContentContainer>
      );
    }
  },
  {
    label: (
      <Trans i18nKey="facebookSettings:pageLink.businessManagerErrorLabel" />
    ),
    key: 'businessManagerAdmin',
    content: () => {
      return (
        <ContentContainer>
          <ListContainer
            sx={{
              '& li': {
                mt: 2
              }
            }}
          >
            <li>
              <Trans
                i18nKey="facebookSettings:pageLink.businessManagerError_01"
                components={[
                  // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label
                  <a
                    href="https://business.facebook.com/settings/people"
                    target="_blank"
                    rel="noreferrer"
                  />
                ]}
              />
            </li>
            <li>
              <Trans
                i18nKey="facebookSettings:pageLink.businessManagerError_02"
                components={[<strong />]}
              />
              <br />
              <br />
              <img
                src={`${s3BaseUrl}help/facebook/businessManager/01-business-manager.png`}
                alt="Facebook Admin Settings"
              />
            </li>
            <li>
              <Trans i18nKey="facebookSettings:pageLink.businessManagerError_03" />
            </li>
            <li>
              <Trans i18nKey="facebookSettings:pageLink.businessManagerError_04" />
            </li>
          </ListContainer>
        </ContentContainer>
      );
    }
  }
];

interface HelpModalProps {
  isOpen: boolean;
  close: () => void;
}

const HelpModal = ({ isOpen, close }: HelpModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_: any, newValue: number) => {
    setSelectedTab(newValue);
  };
  const SelectedTabContent = TabContent[selectedTab].content;

  return (
    <Modal
      fullWidth
      maxWidth="lg"
      open={isOpen}
      headerText={t('facebookSettings:pageLink.errorModalHeader')}
      showClose
      onClose={close}
      dialogContentSx={{ px: 0, pt: 0 }}
      FooterComponent={() => (
        <>
          <Button onClick={close}>
            {t('facebookSettings:pageLink.errorModalClose')}
          </Button>
        </>
      )}
    >
      <>
        <AppBar position="sticky" color="secondary">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="inherit"
          >
            {TabContent.map(tab => {
              return <Tab key={tab.key} label={tab.label} />;
            })}
          </Tabs>
        </AppBar>
        {SelectedTabContent()}
      </>
    </Modal>
  );
};

export default HelpModal;
