import { useMutation } from '@apollo/client';
import { useState, useEffect } from 'react';

import {
  CreateUserInOrganizationMutation,
  CreateUserInOrganizationMutationVariables
} from 'src/generated/gql/graphql';
import { createUserInOrganization } from '../mutations';

const useCreateUser = () => {
  const [createUser, { data, loading, error }] = useMutation<
    CreateUserInOrganizationMutation,
    CreateUserInOrganizationMutationVariables
  >(createUserInOrganization);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (data?.createUserInOrganization?.id) setIsSuccess(true);
    if (error) setIsSuccess(false);
  }, [loading, setIsSuccess, data]);

  return {
    createUser,
    data,
    loading,
    error,
    isSuccess,
    setIsSuccess
  };
};

export default useCreateUser;
