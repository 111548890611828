import { t } from 'i18next';
import { Prompt } from 'react-router-dom';

const NavigationBlocker = ({
  message = t('common:navigationBlocker.message')
  // block
}) => {
  // TODO: turning this off for now until we can sort out what todo with it
  const block = false;

  if (block) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }

  return <Prompt when={block} message={message} />;
};

export default NavigationBlocker;
