import { gql } from 'src/generated/gql';

export const attemptCmpUseLeadTagsWebhookDelivery = gql(`

mutation AttemptCmpUserLeadTagsWebhookDelivery(
	$input: CmpUserLeadTagsWebhookDeliveryInput!
) {
	attemptCmpUserLeadTagsWebhookDelivery(
		input: $input
	) {
		webhookDeliveryUuid
		webhookSubscriptionId
		webhookSubscriptionType
		status
		details
		tags {
			id
			name
		}
  }
}
`);
