import { gql } from 'src/generated/gql';

export const getPaymentMethods = gql(`
  query PaymentMethodForCheckout {
    paymentMethod {
      id
      userId
      payer
      organizationId
      officeId
      stripeSourceId
      stripeCustomerId
      stripeSource {
        cardBrand
        cardLastFour
        cardExpiryYear
        cardExpiryMonth
      }
    }
  }
`);
