import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { unitChannelCodes, unitChannelCodeEnum } from './Constants';

export const publishersInputs = ({ isCreate = false }) => [
  {
    name: 'prototype',
    displayName: 'Prototype',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    disabled: !isCreate,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is the "type" of publisher that is being used. And is what is selected when creating a publisher from the "Create New Publisher" Button'
      }
    }
  },
  {
    name: 'slug',
    displayName: 'Slug',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: !isCreate,
    isHidden: isCreate,
    disabled: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is autogenerated upon the creation of the publisher. This is also what is used when a facet of another publisher needs to reference another publisher.'
      }
    }
  },
  {
    name: 'name',
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This can be any value, but this is the name that will be shown in the Publisher Banner.'
      }
    }
  },
  {
    name: 'description',
    displayName: 'Description',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: true,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'contentSetAlias',
    displayName: 'Content Set Alias',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: 'primary',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is the value that is set up in the previous section (Configure Content). Since it is possible to have multiple Contents an alias is used to determine which one is being used for the publisher.'
      }
    }
  },
  {
    name: 'condition',
    displayName: 'Condition',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is a field that is optional, and is commonly used with the help of handlebar helpers to use values from content or user input to determine whether the Publisher should execute or not.'
      }
    }
  },
  {
    name: 'previewKeys',
    displayName: 'Preview Keys',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    isMultiInput: true,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

export const publisherInputSupplements = ({ hasCodeEnums = false }) => [
  {
    name: 'channelCode',
    displayName: 'Select Channel',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: unitChannelCodes.facebook,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 4,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: unitChannelCodeEnum
      }
    }
  },
  {
    name: 'code',
    displayName: 'Select Publisher Type',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 4,
    disabled: !hasCodeEnums,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        helperText: hasCodeEnums ? '' : 'Please select a channel first'
      }
    }
  }
];

export const facetsInputs = [
  {
    name: 'facet',
    displayName: 'Facet',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: true,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        tooltip:
          'This is the "type" of Facet that is being used. And is what is selected when creating a Facet from the "Add Facet" Button'
      }
    }
  },
  {
    name: 'slug',
    displayName: 'Slug',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'This is autogenerated upon the creation of the Facet.'
      }
    }
  },
  {
    name: 'priority',
    displayName: 'Priority',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: true,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        tooltip: 'This is autogenerated based on the drag and drop order.'
      }
    }
  },
  {
    name: 'name',
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This can be any value, but this is the name that will be shown in the Facet Banners.'
      }
    }
  },
  {
    name: 'condition',
    displayName: 'Condition',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is a field that is optional, and is commonly used with the help of handlebar helpers to use values from content or user input to determine whether the Facet should execute or not.'
      }
    }
  }
];
