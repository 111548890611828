import { SupervisorOrder } from 'src/generated/gql/graphql';
import { getDynamicUserInputInitialValues } from 'src/common/blueprints';

interface GetInitialDynamicUserInputsArgs {
  // order is too complex atm
  selectedBlueprint: any;
  orderToClone: any | undefined;
  automation: SupervisorOrder;
  defaultFacebookPage: {
    pageId: string;
    pageGroupId: string;
    instagramId: string;
  };
}

// Get dynamic user input initial values
export const getInitialDynamicUserInputs = ({
  selectedBlueprint,
  orderToClone,
  automation,
  defaultFacebookPage
}: GetInitialDynamicUserInputsArgs) => {
  const dynamicUserInputs =
    getDynamicUserInputInitialValues(selectedBlueprint) || {};

  const order = automation || orderToClone?.orderItem;

  if (order) {
    return order?.variableValues;
  }

  return {
    ...dynamicUserInputs,
    pageId: defaultFacebookPage?.pageId,
    pageGroupId: defaultFacebookPage?.pageGroupId,
    instagramActorId: defaultFacebookPage?.instagramId
  };
};
