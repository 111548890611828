import { gql } from 'src/generated/gql';

export const computeMinimumSpend = gql(`
  query ComputeMinimumSpend($minSpendInput: MinSpendInput!) {
    computeMinimumSpend(minSpendInput: $minSpendInput) {
      totalMinSpend
      minDailySpend
      minSpendPerChannel {
        channelCode
        amount
      }
      totalMinDailySpendInflated
    }
  }
`);

export const minimumSpend = gql(`
  query minSpend($input: MinSpendInput!, $orderAmount: Float!) {
    computeMinimumSpend(minSpendInput: $input) {
      minDailySpend
      expectedResults(orderAmount: $orderAmount) {
        predictions {
          objective
          kpi
          expectedValue
          lowerBound
          upperBound
          confidencePercent
        }
      }
    }
  }
`);
