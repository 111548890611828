import { GridLocaleText } from '@mui/x-data-grid-pro';
import { t } from 'i18next';

export const getTranslatedLocaleText = (): GridLocaleText => ({
  // Root
  noRowsLabel: t('dataTable:localeText.noRowsLabel'),
  noResultsOverlayLabel: t(
    'dataTable:localeText.noResultsOverlayLabel',
    'No results found.'
  ),

  // Density selector toolbar button text
  toolbarDensity: t('dataTable:localeText.toolbarDensity'),
  toolbarDensityLabel: t('dataTable:localeText.toolbarDensityLabel'),
  toolbarDensityCompact: t(
    'dataTable:localeText.toolbarDensityCompact',
    'Compact'
  ),
  toolbarDensityStandard: t(
    'dataTable:localeText.toolbarDensityStandard',
    'Standard'
  ),
  toolbarDensityComfortable: t(
    'dataTable:localeText.toolbarDensityComfortable',
    'Comfortable'
  ),

  // Columns selector toolbar button text
  toolbarColumns: t('dataTable:localeText.toolbarColumns'),
  toolbarColumnsLabel: t(
    'dataTable:localeText.toolbarColumnsLabel',
    'Select columns'
  ),

  // Filters toolbar button text
  toolbarFilters: t('dataTable:localeText.toolbarFilters'),
  toolbarFiltersLabel: t(
    'dataTable:localeText.toolbarFiltersLabel',
    'Show filters'
  ),
  toolbarFiltersTooltipHide: t(
    'dataTable:localeText.toolbarFiltersTooltipHide',
    'Hide filters'
  ),
  toolbarFiltersTooltipShow: t(
    'dataTable:localeText.toolbarFiltersTooltipShow',
    'Show filters'
  ),
  toolbarFiltersTooltipActive: count =>
    count !== 1
      ? t(
          'dataTable:localeText.toolbarFiltersTooltipActive.nonSingular',
          '{number} active filters',
          { number: count }
        )
      : t(
          'dataTable:localeText.toolbarFiltersTooltipActive.singular',
          '{number} active filter',
          { number: count }
        ),

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: t(
    'dataTable:localeText.toolbarQuickFilterPlaceholder',
    'Search…'
  ),
  toolbarQuickFilterLabel: t(
    'dataTable:localeText.toolbarQuickFilterLabel',
    'Search'
  ),
  toolbarQuickFilterDeleteIconLabel: t(
    'dataTable:localeText.toolbarQuickFilterDeleteIconLabel',
    'Clear'
  ),

  // Export selector toolbar button text
  toolbarExport: t('dataTable:localeText.toolbarExport'),
  toolbarExportLabel: t('dataTable:localeText.toolbarExportLabel'),
  toolbarExportCSV: t(
    'dataTable:localeText.toolbarExportCSV',
    'Download as CSV'
  ),
  toolbarExportPrint: t('dataTable:localeText.toolbarExportPrint'),
  toolbarExportExcel: t(
    'dataTable:localeText.toolbarExportExcel',
    'Download as Excel'
  ),

  // Columns panel text
  columnsPanelTextFieldLabel: t(
    'dataTable:localeText.columnsPanelTextFieldLabel',
    'Find column'
  ),
  columnsPanelTextFieldPlaceholder: t(
    'dataTable:localeText.columnsPanelTextFieldPlaceholder',
    'Column title'
  ),
  columnsPanelDragIconLabel: t(
    'dataTable:localeText.columnsPanelDragIconLabel',
    'Reorder column'
  ),
  columnsPanelShowAllButton: t(
    'dataTable:localeText.columnsPanelShowAllButton',
    'Show all'
  ),
  columnsPanelHideAllButton: t(
    'dataTable:localeText.columnsPanelHideAllButton',
    'Hide all'
  ),

  // Filter panel text
  filterPanelAddFilter: t(
    'dataTable:localeText.filterPanelAddFilter',
    'Add filter'
  ),
  filterPanelRemoveAll: t(
    'dataTable:localeText.filterPanelRemoveAll',
    'Remove all'
  ),
  filterPanelDeleteIconLabel: t(
    'dataTable:localeText.filterPanelDeleteIconLabel',
    'Delete'
  ),
  filterPanelLogicOperator: t(
    'dataTable:localeText.filterPanelLogicOperator',
    'Logic operator'
  ),
  filterPanelOperator: t(
    'dataTable:localeText.filterPanelOperator',
    'Operator'
  ),
  filterPanelOperatorAnd: t(
    'dataTable:localeText.filterPanelOperatorAnd',
    'And'
  ),
  filterPanelOperatorOr: t('dataTable:localeText.filterPanelOperatorOr'),
  filterPanelColumns: t('dataTable:localeText.filterPanelColumns'),
  filterPanelInputLabel: t(
    'dataTable:localeText.filterPanelInputLabel',
    'Value'
  ),
  filterPanelInputPlaceholder: t(
    'dataTable:localeText.filterPanelInputPlaceholder',
    'Filter value'
  ),

  // Filter operators text
  filterOperatorContains: t(
    'dataTable:localeText.filterOperatorContains',
    'contains'
  ),
  filterOperatorEquals: t(
    'dataTable:localeText.filterOperatorEquals',
    'equals'
  ),
  filterOperatorStartsWith: t(
    'dataTable:localeText.filterOperatorStartsWith',
    'starts with'
  ),
  filterOperatorEndsWith: t(
    'dataTable:localeText.filterOperatorEndsWith',
    'ends with'
  ),
  filterOperatorIs: t('dataTable:localeText.filterOperatorIs'),
  filterOperatorNot: t('dataTable:localeText.filterOperatorNot'),
  filterOperatorAfter: t(
    'dataTable:localeText.filterOperatorAfter',
    'is after'
  ),
  filterOperatorOnOrAfter: t(
    'dataTable:localeText.filterOperatorOnOrAfter',
    'is on or after'
  ),
  filterOperatorBefore: t(
    'dataTable:localeText.filterOperatorBefore',
    'is before'
  ),
  filterOperatorOnOrBefore: t(
    'dataTable:localeText.filterOperatorOnOrBefore',
    'is on or before'
  ),
  filterOperatorIsEmpty: t(
    'dataTable:localeText.filterOperatorIsEmpty',
    'is empty'
  ),
  filterOperatorIsNotEmpty: t(
    'dataTable:localeText.filterOperatorIsNotEmpty',
    'is not empty'
  ),
  filterOperatorIsAnyOf: t(
    'dataTable:localeText.filterOperatorIsAnyOf',
    'is any of'
  ),
  'filterOperator=': '=',
  'filterOperator!=': '!=',
  'filterOperator>': '>',
  'filterOperator>=': '>=',
  'filterOperator<': '<',
  'filterOperator<=': '<=',

  // Header filter operators text
  headerFilterOperatorContains: t(
    'dataTable:localeText.headerFilterOperatorContains',
    'Contains'
  ),
  headerFilterOperatorEquals: t(
    'dataTable:localeText.headerFilterOperatorEquals',
    'Equals'
  ),
  headerFilterOperatorStartsWith: t(
    'dataTable:localeText.headerFilterOperatorStartsWith',
    'Starts with'
  ),
  headerFilterOperatorEndsWith: t(
    'dataTable:localeText.headerFilterOperatorEndsWith',
    'Ends with'
  ),
  headerFilterOperatorIs: t(
    'dataTable:localeText.headerFilterOperatorIs',
    'Is'
  ),
  headerFilterOperatorNot: t(
    'dataTable:localeText.headerFilterOperatorNot',
    'Is not'
  ),
  headerFilterOperatorAfter: t(
    'dataTable:localeText.headerFilterOperatorAfter',
    'Is after'
  ),
  headerFilterOperatorOnOrAfter: t(
    'dataTable:localeText.headerFilterOperatorOnOrAfter',
    'Is on or after'
  ),
  headerFilterOperatorBefore: t(
    'dataTable:localeText.headerFilterOperatorBefore',
    'Is before'
  ),
  headerFilterOperatorOnOrBefore: t(
    'dataTable:localeText.headerFilterOperatorOnOrBefore',
    'Is on or before'
  ),
  headerFilterOperatorIsEmpty: t(
    'dataTable:localeText.headerFilterOperatorIsEmpty',
    'Is empty'
  ),
  headerFilterOperatorIsNotEmpty: t(
    'dataTable:localeText.headerFilterOperatorIsNotEmpty',
    'Is not empty'
  ),
  headerFilterOperatorIsAnyOf: t(
    'dataTable:localeText.headerFilterOperatorIsAnyOf',
    'Is any of'
  ),
  'headerFilterOperator=': t(
    'dataTable:localeText.headerFilterOperator=',
    'Equals'
  ),
  'headerFilterOperator!=': t(
    'dataTable:localeText.headerFilterOperator!=',
    'Not equals'
  ),
  'headerFilterOperator>': t(
    'dataTable:localeText.headerFilterOperator>',
    'Greater than'
  ),
  'headerFilterOperator>=': t(
    'dataTable:localeText.headerFilterOperator>=',
    'Greater than or equal to'
  ),
  'headerFilterOperator<': t(
    'dataTable:localeText.headerFilterOperator<',
    'Less than'
  ),
  'headerFilterOperator<=': t(
    'dataTable:localeText.headerFilterOperator<=',
    'Less than or equal to'
  ),

  // Filter values text
  filterValueAny: t('dataTable:localeText.filterValueAny'),
  filterValueTrue: t('dataTable:localeText.filterValueTrue'),
  filterValueFalse: t('dataTable:localeText.filterValueFalse'),

  // Column menu text
  columnMenuLabel: t('dataTable:localeText.columnMenuLabel'),
  columnMenuShowColumns: t(
    'dataTable:localeText.columnMenuShowColumns',
    'Show columns'
  ),
  columnMenuManageColumns: t(
    'dataTable:localeText.columnMenuManageColumns',
    'Manage columns'
  ),
  columnMenuFilter: t('dataTable:localeText.columnMenuFilter'),
  columnMenuHideColumn: t(
    'dataTable:localeText.columnMenuHideColumn',
    'Hide column'
  ),
  columnMenuUnsort: t('dataTable:localeText.columnMenuUnsort'),
  columnMenuSortAsc: t('dataTable:localeText.columnMenuSortAsc'),
  columnMenuSortDesc: t(
    'dataTable:localeText.columnMenuSortDesc',
    'Sort by DESC'
  ),

  // Column header text
  columnHeaderFiltersTooltipActive: count =>
    count !== 1
      ? t(
          'dataTable:localeText.columnHeaderFiltersTooltipActive.nonSingular',
          '{number} active filters',
          { number: count }
        )
      : t(
          'dataTable:localeText.columnHeaderFiltersTooltipActive.singular',
          '{number} active filter',
          { number: count }
        ),
  columnHeaderFiltersLabel: t(
    'dataTable:localeText.columnHeaderFiltersLabel',
    'Show filters'
  ),
  columnHeaderSortIconLabel: t(
    'dataTable:localeText.columnHeaderSortIconLabel',
    'Sort'
  ),

  // Rows selected footer text
  footerRowSelected: count =>
    count !== 1
      ? t(
          'dataTable:localeText.footerRowSelected.nonSingular',
          '{number} rows selected',
          { number: count }
        )
      : t(
          'dataTable:localeText.footerRowSelected.singular',
          '{number} row selected',
          { number: count }
        ),

  // Total row amount footer text
  footerTotalRows: t('dataTable:localeText.footerTotalRows'),

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    t(
      'dataTable.localeText.footerTotalVisibleRows',
      '{visibleCount} of {totalCount}',
      { visibleCount, totalCount }
    ),

  // Checkbox selection text
  checkboxSelectionHeaderName: t(
    'dataTable:localeText.checkboxSelectionHeaderName',
    'Checkbox selection'
  ),
  checkboxSelectionSelectAllRows: t(
    'dataTable:localeText.checkboxSelectionSelectAllRows',
    'Select all rows'
  ),
  checkboxSelectionUnselectAllRows: t(
    'dataTable:localeText.checkboxSelectionUnselectAllRows',
    'Unselect all rows'
  ),
  checkboxSelectionSelectRow: t(
    'dataTable:localeText.checkboxSelectionSelectRow',
    'Select row'
  ),
  checkboxSelectionUnselectRow: t(
    'dataTable:localeText.checkboxSelectionUnselectRow',
    'Unselect row'
  ),

  // Boolean cell text
  booleanCellTrueLabel: t('dataTable:localeText.booleanCellTrueLabel'),
  booleanCellFalseLabel: t('dataTable:localeText.booleanCellFalseLabel'),

  // Actions cell more text
  actionsCellMore: t('dataTable:localeText.actionsCellMore'),

  // Column pinning text
  pinToLeft: t('dataTable:localeText.pinToLeft'),
  pinToRight: t('dataTable:localeText.pinToRight'),
  unpin: t('dataTable:localeText.unpin'),

  // Tree Data
  treeDataGroupingHeaderName: t(
    'dataTable:localeText.treeDataGroupingHeaderName',
    'Group'
  ),
  treeDataExpand: t('dataTable:localeText.treeDataExpand'),
  treeDataCollapse: t('dataTable:localeText.treeDataCollapse'),

  // Grouping columns
  groupingColumnHeaderName: t(
    'dataTable:localeText.groupingColumnHeaderName',
    'Group'
  ),
  groupColumn: name => t('dataTable:localeText.groupColumn', { name }),
  unGroupColumn: name =>
    t('dataTable:localeText.unGroupColumn', 'Stop grouping by {name}', {
      name
    }),

  // Master/detail
  detailPanelToggle: t(
    'dataTable:localeText.detailPanelToggle',
    'Detail panel toggle'
  ),
  expandDetailPanel: t('dataTable:localeText.expandDetailPanel'),
  collapseDetailPanel: t(
    'dataTable:localeText.collapseDetailPanel',
    'Collapse'
  ),

  // Used core components translation keys
  MuiTablePagination: {},

  // Row reordering text
  rowReorderingHeaderName: t(
    'dataTable:localeText.rowReorderingHeaderName',
    'Row reordering'
  ),

  // Aggregation
  aggregationMenuItemHeader: t(
    'dataTable:localeText.aggregationMenuItemHeader',
    'Aggregation'
  ),
  aggregationFunctionLabelSum: t(
    'dataTable:localeText.aggregationFunctionLabelSum',
    'sum'
  ),
  aggregationFunctionLabelAvg: t(
    'dataTable:localeText.aggregationFunctionLabelAvg',
    'avg'
  ),
  aggregationFunctionLabelMin: t(
    'dataTable:localeText.aggregationFunctionLabelMin',
    'min'
  ),
  aggregationFunctionLabelMax: t(
    'dataTable:localeText.aggregationFunctionLabelMax',
    'max'
  ),
  aggregationFunctionLabelSize: t(
    'dataTable:localeText.aggregationFunctionLabelSize',
    'size'
  )
});
