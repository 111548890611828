import { useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Trans } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import { Button, Grid, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { UpdateQuickstartInBulkMutationVariables } from 'src/generated/gql/graphql';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import DraggableQuickStartItem from './DraggableQuickStartItem';
import { updateQuickstartInBulk } from '../mutations';

interface ActiveQuickstartsProps {
  activeQuickstarts: any;
  handleItemStatus: (id: string, isActive: boolean) => any;
  mutationText: any;
  refetch: (() => Promise<void>) | (() => void);
}

const ActiveQuickstarts = ({
  activeQuickstarts,
  handleItemStatus,
  mutationText,
  refetch
}: ActiveQuickstartsProps) => {
  const [sortableQuickstarts, setSortableQuickstarts] =
    useState(activeQuickstarts);
  const [savingChanges, setSavingChanges] = useState(false);
  const [draggingId, setDraggingId] = useState<string | null | number>(null);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const itemTypes = {
    QUICKSTARTS: 'quickstarts'
  };
  const noChangesRecorded = isEqual(sortableQuickstarts, activeQuickstarts);

  const moveItem = useCallback(
    (itemId, finalPosition) => {
      const moveValue = sortableQuickstarts.find(
        (qs: any) => qs?.id === itemId
      );
      const sortableQuickstartsWithoutValue = sortableQuickstarts.filter(
        (qs: any) => itemId !== qs?.id
      );

      setSortableQuickstarts(
        finalPosition === 0
          ? [moveValue, ...sortableQuickstartsWithoutValue]
          : sortableQuickstartsWithoutValue
              .slice(0, finalPosition)
              .concat(
                moveValue,
                sortableQuickstartsWithoutValue.slice(finalPosition)
              )
      );
    },
    [sortableQuickstarts]
  );

  const [updateQuickstartMutation] =
    useMutation<UpdateQuickstartInBulkMutationVariables>(
      updateQuickstartInBulk
    );

  const saveNewOrder = async (): Promise<void> => {
    const mutationParams = {
      quickstarts: sortableQuickstarts.map(
        (quickstart: any, index: number) => ({
          id: quickstart?.id,
          values: { priority: 10 * (index + 1) }
        })
      )
    };

    setSavingChanges(true);
    try {
      await updateQuickstartMutation({
        variables: {
          Input: { ...mutationParams }
        }
      });

      await refetch();
      setSavingChanges(false);
      enqueueSnackbar(mutationText?.statusUpdateSuccess, {
        variant: 'success'
      });
    } catch (err) {
      setSavingChanges(false);
      enqueueSnackbar(mutationText?.statusUpdateError, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (
      hoverIndex !== null &&
      draggingId !== hoverIndex &&
      draggingId !== null
    ) {
      moveItem(draggingId, hoverIndex);
    }
  }, [draggingId, hoverIndex, moveItem]);

  useEffect(() => {
    // To re arrange the grid when a qs is deactivated
    if (activeQuickstarts) setSortableQuickstarts(activeQuickstarts);
  }, [activeQuickstarts]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start'
        }}
      >
        <LoadingButton
          data-cy="save-changes"
          color="primary"
          disabled={noChangesRecorded}
          variant="contained"
          loading={savingChanges}
          onClick={() => {
            // this onClick requires a void return vs promise
            // so to avoid a linting error for no-misused-promises
            saveNewOrder()
              .then(() => {
                // handled in saveNewOrder
              })
              .catch(() => {
                // handled in saveNewOrder
              });
          }}
          sx={{
            marginBottom: theme => theme.spacing(2)
          }}
        >
          Save Changes
        </LoadingButton>
      </Box>
      <DndProvider backend={HTML5Backend}>
        <Grid data-cy="active-quickstarts-container" container spacing={0}>
          {isEmpty(sortableQuickstarts) ? (
            <Grid item>
              <Trans i18nKey="dashboard:headers.emptyQuickStarts">
                You don't have any quickstarts at this time
              </Trans>
            </Grid>
          ) : (
            <>
              {sortableQuickstarts.map((quickStartItem: any, index: number) => (
                <Grid
                  key={quickStartItem.id}
                  onDragEnter={e => {
                    e.preventDefault();
                    setHoverIndex(index);
                  }}
                  data-cy={`draggable-container-${index + 1}`}
                  item
                  sx={{
                    padding: theme => theme.spacing(1.5),
                    border: '1px dashed gray',
                    display: 'block'
                  }}
                >
                  <Box
                    sx={{
                      height: '100%',
                      position: 'relative',

                      '&:hover button': {
                        display: 'initial'
                      }
                    }}
                  >
                    {!draggingId ? (
                      <Button
                        sx={{
                          position: 'absolute',
                          zIndex: '1',
                          top: theme => theme.spacing(2),
                          right: theme => theme.spacing(2),
                          display: 'none'
                        }}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          const linkPath = generateLinkPath(
                            paths.admin.settings.quickStartEdit,
                            {
                              quickStartId: quickStartItem.id
                            }
                          );
                          history.push(linkPath);
                        }}
                      >
                        <Trans i18nKey="admin:manageQuickstart.editButton">
                          Edit
                        </Trans>
                      </Button>
                    ) : null}
                    <DraggableQuickStartItem
                      key={`quickStart-${quickStartItem.id}-${quickStartItem.architectureId}`}
                      architectureId={quickStartItem.architectureId}
                      catalogContentType={quickStartItem.catalogContentType}
                      channels={quickStartItem.channels}
                      handleItemStatus={handleItemStatus}
                      id={quickStartItem.id}
                      isCustom={quickStartItem.isCustom}
                      customImageUrl={quickStartItem.customImageUrl}
                      iconImageUrl={quickStartItem.iconImageUrl}
                      index={index}
                      isActive={quickStartItem.isActive}
                      itemType={itemTypes.QUICKSTARTS}
                      notes={quickStartItem.notes}
                      objective={quickStartItem.objective}
                      productIds={quickStartItem.productIds}
                      setDraggingId={(id: string | null) => setDraggingId(id)}
                      setHoverIndex={setHoverIndex}
                      description={quickStartItem.description}
                      name={quickStartItem.name}
                      productName={quickStartItem.productName}
                    />
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </DndProvider>
    </>
  );
};

export default ActiveQuickstarts;
