import withStyles from '@mui/styles/withStyles';

import CardMedia from './DisplayCardMedia';
import { styles } from './DisplaySquareAd';

const DisplayRectangleAd = props => {
  const { classes, rectangleImageUrl, headline, description, businessName } =
    props;
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.cardContainer}>
          <div className={classes.fakeTextContainer}>
            <div className={classes.placeholderText} />
            <div className={classes.placeholderText} />
            <div className={classes.placeholderText} />
          </div>
          <div className={classes.profileContainer}>
            <div className={classes.fakeImage} />
            <div className={classes.placeholderTextShort} />
          </div>
        </div>

        <CardMedia
          headline={headline}
          description={description}
          businessName={businessName}
          image={rectangleImageUrl}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(DisplayRectangleAd);
