import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { validateIsNumber } from 'src/common/validations';
import { GoogleUserListAccountSelectionStrategy } from 'src/generated/gql/graphql';
import { t } from 'i18next';

export const FORM_NAME_CREATE_FB_AUDIENCE_ACCOUNT_CREATION_RULE =
  'createFbAudienceAccountCreationRule';
export const FORM_NAME_CREATE_GOOGLE_USER_LIST_CREATION_RULE =
  'createGoogleUserListCreationRule';
export const FORM_NAME_CREATE_GOOGLE_ACCOUNT = 'createGoogleAccount';

export const ACCOUNT_SELECTION_STRATEGIES = {
  USER_METADATA: 'user_metadata',
  GROUP: 'group',
  ORG_DEFAULT: 'org_default'
} as const;

export const ACCOUNT_SELECTION_STRATEGIES_DISPLAY_NAMES = {
  [ACCOUNT_SELECTION_STRATEGIES.USER_METADATA]: 'User Metadata',
  [ACCOUNT_SELECTION_STRATEGIES.GROUP]: 'Group',
  [ACCOUNT_SELECTION_STRATEGIES.ORG_DEFAULT]: 'Organization'
} as const;

export type AccountSelectionStrategy =
  (typeof ACCOUNT_SELECTION_STRATEGIES)[keyof typeof ACCOUNT_SELECTION_STRATEGIES];

export const ACCOUNT_SELECTION_STRATEGY_DISPLAY_NAME_BY_GOOGLE = () =>
  ({
    [GoogleUserListAccountSelectionStrategy.Organization]: t(
      'audienceTools:generic.accountSelectionStrategyName.org'
    ),
    [GoogleUserListAccountSelectionStrategy.Group]: t(
      'audienceTools:generic.accountSelectionStrategyName.group'
    ),
    [GoogleUserListAccountSelectionStrategy.UserMetadata]: t(
      'audienceTools:generic.accountSelectionStrategyName.metadata'
    )
  }) as const;

export const defaultAudienceToolColumnSettings = {
  disableColumnMenu: true,
  disableReorder: true,
  editable: false,
  filterable: false,
  flex: 2,
  align: 'center',
  headerAlign: 'center',
  hideable: false,
  sortable: false
} as const;

interface Option {
  value: string;
  name: string;
}

const priorityInput = () => ({
  name: 'priority',
  displayName: t('audienceTools:generic.formInputs.priority.name'),
  displayMethodId: INPUT_TYPES.ANY_NUMBER,
  initialValue: {},
  reduxValidations: [validateIsNumber],
  isRequired: true,
  isHidden: false,
  displayParameters: {
    inputData: {
      helperText: t('audienceTools:generic.formInputs.priority.helperText')
    }
  }
});

interface CreateFacebookAudienceAccountCreationRuleInputsArgs {
  isUserMetadataStrategy: boolean;
  isGroupStrategy: boolean;
  resourceCollectionsOptions: Option[];
  resourceItemOptions: Option[];
}

export const createFacebookAudienceAccountCreationRuleInputs = ({
  isUserMetadataStrategy,
  isGroupStrategy,
  resourceCollectionsOptions = [],
  resourceItemOptions = []
}: CreateFacebookAudienceAccountCreationRuleInputsArgs) => {
  return [
    ...(!isUserMetadataStrategy
      ? [
          {
            name: 'resourceCollectionId',
            displayName: 'Resource Collections',
            displayMethodId: INPUT_TYPES.SINGLE_SELECT,
            initialValue: '',
            reduxValidations: [],
            isRequired: true,
            isHidden: false,
            displayParameters: {
              inputData: {
                options: resourceCollectionsOptions,
                isAutocomplete: true
              }
            }
          },
          {
            name: 'resourceItemId',
            displayName: `Resource Items ${
              resourceItemOptions?.length ? '' : 'Empty'
            }`,
            displayMethodId: INPUT_TYPES.SINGLE_SELECT,
            initialValue: '',
            reduxValidations: [],
            isRequired: true,
            isHidden: false,
            displayParameters: {
              inputData: {
                options: resourceItemOptions,
                isAutocomplete: true
              }
            }
          }
        ]
      : []),
    {
      name: 'accountSelectionStrategy',
      displayName: 'Account Selection Strategy',
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: '',
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displayParameters: {
        inputData: {
          helperText:
            'Create Organization Default/Group OR User Metadata rules but do not have both in the table',
          options: [
            {
              value: ACCOUNT_SELECTION_STRATEGIES.ORG_DEFAULT,
              name: ACCOUNT_SELECTION_STRATEGIES_DISPLAY_NAMES[
                ACCOUNT_SELECTION_STRATEGIES.ORG_DEFAULT
              ],
              description:
                'Everyone in this organization will have access to this rule. \n The account id is stored in the resource library.'
            },
            {
              value: ACCOUNT_SELECTION_STRATEGIES.GROUP,
              name: ACCOUNT_SELECTION_STRATEGIES_DISPLAY_NAMES[
                ACCOUNT_SELECTION_STRATEGIES.GROUP
              ],
              description:
                'Everyone in the specified group will have access to this rule. \n The account id is stored in the resource library.'
            },
            {
              value: ACCOUNT_SELECTION_STRATEGIES.USER_METADATA,
              name: ACCOUNT_SELECTION_STRATEGIES_DISPLAY_NAMES[
                ACCOUNT_SELECTION_STRATEGIES.USER_METADATA
              ],
              description: `Everyone in this organization will have access to this rule. \n The account id is determined by the user’s metadata.`
            }
          ]
        }
      }
    },
    ...(isUserMetadataStrategy
      ? [
          {
            name: 'userMetadataKey',
            displayName: 'User Metadata Key',
            displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
            initialValue: '',
            reduxValidations: [],
            isRequired: true,
            isHidden: false,
            displayParameters: {
              inputData: {
                helperText: 'Database field that stores the account id'
              }
            }
          }
        ]
      : []),
    ...(isGroupStrategy
      ? [
          {
            name: 'groupId',
            displayName: 'Group ID',
            displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
            initialValue: '',
            reduxValidations: [],
            isRequired: true,
            isHidden: false,
            displayParameters: {
              inputData: {
                helperText: 'The internal group id'
              }
            }
          }
        ]
      : []),
    priorityInput()
  ];
};

export const createGoogleUserListAccountCreationInputs = (
  accountSelectionStrategy: GoogleUserListAccountSelectionStrategy,
  googleAccounts: Array<{ id: string; name: string }> | undefined
) => [
  {
    name: 'accountSelectionStrategy',
    displayName: t(
      'audienceTools:googleUserListAccountCreationRule.formInputs.accountSelectionStrategy.name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        helperText: t(
          'audienceTools:googleUserListAccountCreationRule.formInputs.accountSelectionStrategy.helperText'
        ),
        options: [
          {
            value: GoogleUserListAccountSelectionStrategy.Organization,
            name: ACCOUNT_SELECTION_STRATEGY_DISPLAY_NAME_BY_GOOGLE()[
              GoogleUserListAccountSelectionStrategy.Organization
            ],
            description: t(
              'audienceTools:googleUserListAccountCreationRule.formInputs.accountSelectionStrategy.descriptionOrg'
            )
          },
          {
            value: GoogleUserListAccountSelectionStrategy.Group,
            name: ACCOUNT_SELECTION_STRATEGY_DISPLAY_NAME_BY_GOOGLE()[
              GoogleUserListAccountSelectionStrategy.Group
            ],
            description: t(
              'audienceTools:googleUserListAccountCreationRule.formInputs.accountSelectionStrategy.descriptionGroup'
            )
          },
          {
            value: GoogleUserListAccountSelectionStrategy.UserMetadata,
            name: ACCOUNT_SELECTION_STRATEGY_DISPLAY_NAME_BY_GOOGLE()[
              GoogleUserListAccountSelectionStrategy.UserMetadata
            ],
            description: t(
              'audienceTools:googleUserListAccountCreationRule.formInputs.accountSelectionStrategy.descriptionMetadata'
            )
          }
        ]
      }
    }
  },
  priorityInput(),

  ...(accountSelectionStrategy ===
    GoogleUserListAccountSelectionStrategy.Group ||
  accountSelectionStrategy ===
    GoogleUserListAccountSelectionStrategy.Organization
    ? [
        {
          name: 'googleAccountId',
          displayName: googleAccounts?.length
            ? t(
                'audienceTools:googleUserListAccountCreationRule.formInputs.googleAccountId'
              )
            : t(
                'audienceTools:googleUserListAccountCreationRule.formInputs.googleAccountEmpty'
              ),
          displayMethodId: INPUT_TYPES.SINGLE_SELECT,
          initialValue: '',
          reduxValidations: [],
          isRequired: true,
          isHidden: false,
          displayParameters: {
            inputData: {
              options: googleAccounts?.map(({ id, name }) => ({
                value: id,
                name
              }))
            }
          }
        }
      ]
    : []),

  ...(accountSelectionStrategy === GoogleUserListAccountSelectionStrategy.Group
    ? [
        {
          name: 'groupId',
          displayName: t(
            'audienceTools:googleUserListAccountCreationRule.formInputs.groupId'
          ),
          displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
          initialValue: '',
          reduxValidations: [],
          isRequired: true,
          isHidden: false
        }
      ]
    : []),

  ...(accountSelectionStrategy ===
  GoogleUserListAccountSelectionStrategy.UserMetadata
    ? [
        {
          name: 'managerAccountKey',
          displayName: t(
            'audienceTools:googleUserListAccountCreationRule.formInputs.managerAccountKey'
          ),
          displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
          initialValue: '',
          reduxValidations: [],
          isRequired: true,
          isHidden: false
        },
        {
          name: 'customerAccountKey',
          displayName: t(
            'audienceTools:googleUserListAccountCreationRule.formInputs.customerAccountKey'
          ),
          displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
          initialValue: '',
          reduxValidations: [],
          isRequired: true,
          isHidden: false
        }
      ]
    : [])
];

export const createGoogleAccountInputs = () => [
  {
    name: 'name',
    displayName: t('audienceTools:googleAccount.formInputs.name'),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false
  },
  {
    name: 'isActive',
    displayName: t('audienceTools:googleAccount.formInputs.isActive'),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: true,
    isHidden: false
  },
  {
    name: 'description',
    displayName: t('audienceTools:googleAccount.formInputs.description'),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 8
      }
    }
  },
  {
    name: 'managerAccountId',
    displayName: t('audienceTools:googleAccount.formInputs.managerAccountId'),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false
  },
  {
    name: 'customerAccountId',
    displayName: t('audienceTools:googleAccount.formInputs.customerAccountId'),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false
  }
];
