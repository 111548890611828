import React from 'react';
import { DynamicForm } from 'src/components/ReduxForm';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import { createCampaignConnectBasedEmailIdentity } from './mutations';

const inputs = [
  {
    name: 'subdomain',
    displayName: 'Subdomain',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        helperText:
          'does not need to include campaignconnect.ai EXAMPLE: partnersdesiredsubdomain'
      }
    }
  },
  {
    name: 'mailFromAddressPrefix',
    displayName: 'Mail From Address Prefix',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        helperText:
          'does not need to include @subdomain.campaignconnect.ai EXAMPLE: no-reply'
      }
    }
  },
  {
    name: 'senderName',
    displayName: 'Sender Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        helperText: 'EXAMPLE: Tom Jones'
      }
    }
  }
];

type CampaignConnectProps = {
  refetch: () => Promise<any>;
};

const CampaignConnectForm = ({ refetch }: CampaignConnectProps) => {
  const {
    handleSubmit,
    formState: { isDirty, isSubmitting }
  } = useFormContext();

  const { enqueueSnackbar } = useSnackbar();

  const [createCampaignConnectBasedEmailIdentityMutation] = useMutation(
    createCampaignConnectBasedEmailIdentity
  );

  const onSubmit = handleSubmit(async data => {
    const { mailFromAddressPrefix, senderName, subdomain } = data;
    await createCampaignConnectBasedEmailIdentityMutation({
      variables: {
        input: {
          mailFromAddressPrefix,
          senderName,
          subdomain
        }
      }
    });
  });

  const onSuccess = () => {
    enqueueSnackbar(t('adminDashboard:notificationPreferences.updateSuccess'), {
      variant: 'success'
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  };

  const onError = () => {
    enqueueSnackbar(t('adminDashboard:notificationPreferences.updateError'), {
      variant: 'error'
    });
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetch();
  };

  return (
    <>
      <DynamicForm inputs={inputs} isHookForm />
      <Button
        color="primary"
        variant="contained"
        disabled={!isDirty || isSubmitting}
        onClick={() => {
          onSubmit().then(onSuccess).catch(onError);
        }}
      >
        {t('adminDashboard:notificationPreferences.submitButton')}
      </Button>
    </>
  );
};

export default CampaignConnectForm;
