import { flow, get } from 'lodash';
import { Trans } from 'react-i18next';

import Button from '@mui/material/Button';

import { paths } from 'src/routes/paths';
import { withAppSettings } from 'src/AppSettings';

const FacebookAuthButton = props => {
  const { appSettings, text } = props;

  let redirectUrl = `https://${appSettings.organizationFqdn}/`;

  if (import.meta.env.EVOCALIZE_IS_LOCAL) {
    redirectUrl = 'https://lilo.evocalize.com:3003/';
  }

  const facebookSettings = appSettings.app.facebook;

  const REDIRECT_URL = `${redirectUrl}#${paths.facebook.link}`;

  const FACEBOOK_AUTH_URL =
    `${get(facebookSettings, 'oauthBaseUrl')}?` +
    `client_id=${get(facebookSettings, 'appId')}` +
    `&redirect_uri=${encodeURIComponent(REDIRECT_URL)}` +
    '&state={lfb=true}' +
    `&scope=${get(facebookSettings, 'scopes')}` +
    '&response_type=token,granted_scopes';

  return (
    <Button
      color="primary"
      size="large"
      variant="contained"
      data-amp-facebook-link-continue-to-fb-click
      href={FACEBOOK_AUTH_URL}
    >
      {text || (
        <Trans i18nKey="facebookSettings:authButton.continueWithFb">
          Continue with Facebook
        </Trans>
      )}
    </Button>
  );
};

export default flow(withAppSettings)(FacebookAuthButton);
