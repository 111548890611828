import { handleActions } from 'redux-actions';

import { audienceConstants } from './actions';

const initialState = {
  error: null,
  fileReady: false,
  invalidCount: 0,
  uploadStatus: {},
  validCount: 0
};

export default handleActions(
  {
    [audienceConstants.setUploadStatus]: (
      state,
      { payload: { statusObject } }
    ) => {
      // Example of a upload status
      // {
      //     assetId: {
      //         name: file name,
      //         progress: 0,
      //         status: uploadStatusTypes.error
      //     }
      // }

      return {
        ...state,
        uploadStatus: {
          ...state.uploadStatus,
          ...statusObject
        }
      };
    },

    [audienceConstants.removeUploadStatus]: (
      state,
      { payload: { assetId } }
    ) => {
      const newUploadStatus = { ...state.uploadStatus };
      delete newUploadStatus[assetId];
      return {
        ...state,
        uploadStatus: {
          ...newUploadStatus
        }
      };
    },

    [audienceConstants.clearUploadStatus]: () => {
      return {
        ...initialState
      };
    },

    [audienceConstants.setEmailCount]: (
      state,
      { payload: { invalidCount, validCount } }
    ) => {
      return {
        ...state,
        invalidCount,
        validCount
      };
    },

    [audienceConstants.setFileReady]: state => {
      return {
        ...state,
        error: null,
        fileReady: true
      };
    },

    [audienceConstants.setFileNotReady]: (state, { payload: { error } }) => {
      return {
        ...state,
        error,
        fileReady: true
      };
    }
  },
  initialState
);
