import { useMemo } from 'react';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { Grid, Box } from '@mui/material';

import useProgram from 'src/pages/Program/utils/useProgram';
import {
  scheduleTypes,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';

import { FormSection } from 'src/components/ReduxForm';
import ScheduleSelector from 'src/components/ScheduleSelector/ScheduleSelector';

interface DurationProps {
  formSectionName?: string;
}

const Duration = ({
  formSectionName = PROGRAM_FORM_SECTION_SPEND_NAME
}: DurationProps) => {
  const { selectedBlueprint, isAutomated, formName } = useProgram();
  const hookFormContext = useFormContext();

  const isSubscription =
    hookFormContext?.watch()?.spendStep?.scheduleType ===
    scheduleTypes.subscription.value;

  const sectionDescription = useMemo(() => {
    if (isAutomated) {
      return t('programCreate:spend.offerTypeDescriptionAutomation');
    }

    if (isSubscription) {
      return t('programCreate:spend.offerTypeDescriptionSubscription');
    }
    return t('programCreate:spend.offerTypeDescription');
  }, [isAutomated, isSubscription]);

  return (
    <Grid item xs={12}>
      <FormSection
        title={
          isSubscription
            ? t('programCreate:spend.offerTypeTitleSubscription')
            : t('programCreate:spend.offerTypeTitle')
        }
        description={sectionDescription}
      >
        <Box sx={{ pb: 1.5 }}>
          <ScheduleSelector
            selectedBlueprint={selectedBlueprint}
            formName={formName}
            isAutomated={isAutomated}
            isHookForm
            hookFormContext={hookFormContext}
            formSectionName={formSectionName}
          />
        </Box>
      </FormSection>
    </Grid>
  );
};

export default Duration;
