/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState, useEffect, SetStateAction } from 'react';
import { flow, get } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { DataValue, graphql } from '@apollo/client/react/hoc';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';

import {
  Button,
  FormControlLabel,
  List,
  Radio,
  Grid,
  Divider,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';

import RadioGroup from 'src/components/ReduxForm/RenderRadioGroup';
import CardLabel from 'src/components/Checkout/CardLabel';
import Loading from 'src/components/Loading';
import useSetStripeElementLocale from 'src/hooks/useSetStripeElementLocale';

import {
  extractPaymentMethodId,
  extractCcErrorMessage
} from 'src/common/paymentUtils';

import { Theme } from '@mui/system';
import { WithStyledClasses } from 'src/common/Style';
import { ErrorResponse } from '@apollo/client/link/error';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import { MutationFunction } from '@apollo/client';
import {
  AddPaymentMethodMutation,
  AddPaymentMethodMutationVariables,
  PaymentMethodQuery,
  PaymentMethodQueryVariables
} from 'src/generated/gql/graphql';
import { addPaymentMethod } from './mutations';
import { getPaymentMethods } from './queries';

const styles = (theme: Theme) =>
  ({
    container: {
      minWidth: '150px'
    },
    cardContainer: {
      marginBottom: theme.spacing()
    },
    creditCardList: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    cardBrand: {
      color: theme.palette.grey[600],
      fontSize: '20px',
      fontWeight: 'bold'
    },
    cardExp: {
      color: theme.palette.grey[600]
    },
    newCardHeader: {
      width: '100%'
    },
    newCardContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%'
    },
    addCardElements: {
      maxWidth: '400px',
      padding: theme.spacing(2),
      width: '100%'
    },
    formControlLabel: {
      width: 'min(90vh, 300px)',

      // Card label style for this specific use case
      '& > span:nth-child(2)': {
        width: '100%'
      }
    },
    error: {
      color: theme.palette.error.main
    }
  }) as const;

const required = (value: unknown) => (value ? undefined : 'Required');

const FORMNAME = 'updateCreditCard';

interface SelectOrAddCreditCardInjectedProps extends InjectedFormProps {
  classes: WithStyledClasses<typeof styles>;
  paymentMethods: DataValue<PaymentMethodQuery, PaymentMethodQueryVariables>;
  addPaymentMutation: MutationFunction<
    AddPaymentMethodMutation,
    AddPaymentMethodMutationVariables
  >;
}

const SelectOrAddCreditCard = ({
  classes,
  paymentMethods,
  addPaymentMutation,
  change
}: SelectOrAddCreditCardInjectedProps) => {
  const [disableAddCard, setDisableAddCard] = useState(true);
  const [updatingPayment, setUpdatingPayment] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const stripe = useStripe();
  const elements = useElements();

  useSetStripeElementLocale({ elements });

  const allPaymentMethods = get(paymentMethods, 'paymentMethod', []);

  const [expandAddCard, setExpandAddCard] = useState(false);
  useEffect(() => {
    // once the payment methods are laoded if there are cards on file
    // we don't need to show the add payment method
    setExpandAddCard((allPaymentMethods as any) <= 0);
  }, [paymentMethods.loading]);

  const validations = allPaymentMethods.length > 0 ? [required] : [];

  const cardFailed = () => {
    setDisableAddCard(true);
  };

  const cardSuccess = () => {
    setDisableAddCard(false);
  };

  const handleAddCard = async () => {
    setStripeError(null);

    setUpdatingPayment(true);

    const standardPaymentErrorMessage = t(
      'programCreate:Checkout.creditCardError'
    );

    const cardElement = elements!.getElement(CardElement)!;
    let stripeSource;

    // 1. Call Stripe directly to create the source.
    try {
      stripeSource = await stripe!.createSource(cardElement, {
        type: 'card'
      });
    } catch (error) {
      enqueueSnackbar(standardPaymentErrorMessage, {
        variant: 'error'
      });

      setUpdatingPayment(false);
      setStripeError(standardPaymentErrorMessage as SetStateAction<any>);

      return stripeSource;
    }

    // This will catch when the stripe.createSource call doesn't throw an
    // exception but still has an error.
    if (get(stripeSource, 'error')) {
      // Add any stripe-specific messaging if they provided us with it.
      const extraMessage = get(stripeSource, 'error.message');

      const message = (
        <span>
          {standardPaymentErrorMessage}
          {extraMessage && (
            <span>
              :<br />
              {extraMessage}
            </span>
          )}
        </span>
      );

      // Show the Stripe-generated message to the user with the Admiral.
      enqueueSnackbar(message, {
        variant: 'error'
      });

      setUpdatingPayment(false);
      setStripeError(message as SetStateAction<any>);

      // Return the stripeSource (containing the error) so
      // handleNextWithValidation can check the value to determine if it
      // should allow the user to go to the next stage.
      return stripeSource;
    }

    const stripeSourceId = get(stripeSource, 'source.id')!;

    // 2. Now make the request to our API to add the credit card to the
    //    user's account.
    try {
      await addPaymentMutation({
        variables: {
          stripeSourceId
        }
      });
    } catch (error) {
      const extraMessage = extractCcErrorMessage(error as ErrorResponse);

      enqueueSnackbar(
        <span>
          {standardPaymentErrorMessage}
          {extraMessage && (
            <span>
              :<br />
              {extraMessage}
            </span>
          )}
        </span>,
        {
          variant: 'error'
        }
      );

      setUpdatingPayment(false);

      // Return the error object so handleNextWithValidation can check the
      // value to determine if it should allow the user to go to the next
      // stage.
      return error;
    }

    // 3. Get the new card data to keep the UI in sync with the db.
    await paymentMethods.refetch();

    // 4. Extract the new payment method's ID from the refresh response.
    const updatedPaymentMethods = await paymentMethods.refetch();
    const paymentMethodId = extractPaymentMethodId(
      get(updatedPaymentMethods, 'data.paymentMethod'),
      stripeSourceId
    );

    // 5. Update the component with the new card's IDs.
    /* eslint-disable @typescript-eslint/await-thenable */
    await change('stripeSourceId', stripeSourceId);
    await change('paymentMethodId', paymentMethodId);

    // 6. Finally, unset loading state now that we've succeeded.
    await setUpdatingPayment(false);
    /* eslint-enable @typescript-eslint/await-thenable */

    setExpandAddCard(false);
  };

  if (paymentMethods.loading) {
    return <Loading />;
  }

  return (
    <form autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field name="paymentMethodId" component="input" type="hidden" />

          {updatingPayment && <Loading />}
          <Field
            component={RadioGroup}
            name="stripeSourceId"
            validate={validations}
            onChange={(e, value) => {
              const paymentMethodId = extractPaymentMethodId(
                allPaymentMethods,
                value
              );

              change('paymentMethodId', paymentMethodId);

              change('stripeSourceId', value);
            }}
          >
            <List className={classes.creditCardList}>
              {allPaymentMethods.map(({ id, stripeSourceId, stripeSource }) => (
                <FormControlLabel
                  className={classes.formControlLabel}
                  key={stripeSourceId}
                  control={<Radio />}
                  label={
                    <CardLabel
                      {...stripeSource}
                      paymentMethodId={id}
                      stripeSourceId={stripeSourceId}
                      refetch={get(paymentMethods, 'refetch')}
                    />
                  }
                  value={stripeSourceId}
                />
              ))}
              {!expandAddCard && (
                <Button
                  color="primary"
                  onClick={() => setExpandAddCard(true)}
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon />}
                >
                  <Trans i18nKey="billingDetails.addDifferentCC">
                    Add a different card
                  </Trans>
                </Button>
              )}
            </List>
          </Field>
        </Grid>

        {expandAddCard && (
          <>
            <Grid item className={classes.newCardHeader}>
              <Divider />
              <Typography variant="h6">
                <Trans i18nKey="billingDetails.addNewCC">Add New Card</Trans>
              </Typography>
            </Grid>

            <Grid item className={classes.newCardContainer}>
              <div className={classes.addCardElements}>
                <CardElement
                  onChange={({ complete }) => {
                    return complete ? cardSuccess() : cardFailed();
                  }}
                  options={{ disableLink: true }}
                />
              </div>
              <Button
                color="primary"
                onClick={handleAddCard}
                size="small"
                variant="outlined"
                disabled={disableAddCard}
              >
                <Trans i18nKey="billingDetails.addNewCC">Add New Card</Trans>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      {stripeError && <p className={classes.error}>{stripeError}</p>}
    </form>
  );
};

export default flow(
  reduxForm({
    form: FORMNAME,
    enableReinitialize: true,
    touchOnChange: true
  }),
  connect((state, ownProps: any) => {
    const {
      paymentMethods: { loading, paymentMethod },
      initialValues
    } = ownProps;

    if (loading) {
      return {};
    }

    return {
      initialValues: initialValues || {
        stripeSourceId: paymentMethod?.[0]?.stripeSourceId,
        paymentMethodId: paymentMethod?.[0]?.id
      }
    };
  }),
  graphql(addPaymentMethod, {
    name: 'addPaymentMutation'
  }),
  graphql(getPaymentMethods, {
    name: 'paymentMethods'
  }),
  withStyles(styles)
)(SelectOrAddCreditCard);
