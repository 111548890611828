import { useQuery } from '@apollo/client';

import { getFacebookPagesV2, getFacebookPagesV2Teams } from './queries';

interface UseGetFacebookPages {
  isTeamsEnabled: boolean;
}

const useGetFacebookPages = ({ isTeamsEnabled }: UseGetFacebookPages) => {
  const {
    data: facebookPageData,
    refetch: refetchPaymentMethods,
    loading: LoadingPageData,
    error: ErrorPageData
  } = useQuery(getFacebookPagesV2, {
    skip: isTeamsEnabled,
    fetchPolicy: 'no-cache'
  });

  const {
    data: teamFacebookPageData,
    refetch: refetchTeamPaymentMethods,
    loading: LoadingPageDataTeam,
    error: ErrorPageDataTeam
  } = useQuery(getFacebookPagesV2Teams, {
    skip: !isTeamsEnabled,
    fetchPolicy: 'no-cache'
  });

  return {
    facebookPageData: isTeamsEnabled
      ? teamFacebookPageData?.facebookPagesV2
      : facebookPageData?.facebookPagesV2,
    refetch: isTeamsEnabled ? refetchTeamPaymentMethods : refetchPaymentMethods,
    loading: isTeamsEnabled ? LoadingPageDataTeam : LoadingPageData,
    error: isTeamsEnabled ? ErrorPageDataTeam : ErrorPageData
  };
};

export default useGetFacebookPages;
