import { t } from 'i18next';
import { Button, Typography } from '@mui/material';

import PageLinkCard from '../PageLinkCard';
import ActionsContainer from '../ActionsContainer';

interface FacebookLeadsTos {
  onSkip: () => void;
  onConfirm: () => void;
}

const FacebookLeadsTos = ({ onSkip, onConfirm }: FacebookLeadsTos) => {
  return (
    <PageLinkCard
      title={t('facebookSettings:facebookLeadsTos.title')}
      actions={
        <ActionsContainer justifyContent="flex-end">
          <Button onClick={onSkip} variant="text">
            {t('facebookSettings:buttons.skip')}
          </Button>
          <Button onClick={onConfirm} variant="contained">
            {t('facebookSettings:buttons.continueToAccept')}
          </Button>
        </ActionsContainer>
      }
    >
      <Typography>
        {t('facebookSettings:facebookLeadsTos.description')}
      </Typography>
    </PageLinkCard>
  );
};

export default FacebookLeadsTos;
