import { t } from 'i18next';

import { Box, Button, Typography } from '@mui/material';
import { Image as MediaIcon } from '@mui/icons-material';

import Modal from 'src/components/Modal';
import {
  logAssetEvent,
  assetEventTypes,
  assetTypes
} from 'src/common/orgAssets';

import { modalTypes } from './constants';

interface PageTextArgs {
  groupName: string;
}

export type ModalType = keyof typeof modalTypes | null;

interface GalleryAssetModalProps {
  onClose: () => void;
  remove: () => void;
  toggleShare: () => void;
  hasTeamCapabilities: boolean;
  type: ModalType;
  groupName: string;
  open: boolean;
}

const pageText = ({ groupName }: PageTextArgs) => {
  return {
    confirmDeleteTeamAsset: t('gallery:assetConfirm.deleteTeamAsset', {
      groupName
    }),
    confirmDeleteAsset: t('gallery:assetConfirm.deleteAsset'),
    deleteConfirmButton: t('admin:billingSettings.deleteConfirmButton'),
    cancelButton: t('gallery:assetConfirm.cancelButton'),
    removeAssetHeader: t('gallery:assetConfirm.removeAssetModalHeader'),

    shareAssetHeader: t('gallery:assetConfirm.shareAssetModalHeader'),
    unshareAssetHeader: t('gallery:assetConfirm.unshareAssetModalHeader'),
    unknownType: t('gallery:assetConfirm.unknownType'),
    confirmShareAsset: t('gallery:assetConfirm.shareAsset', {
      groupName
    }),
    confirmUnshareAsset: t('gallery:assetConfirm.unshareAsset', {
      groupName
    }),
    shareConfirmButton: t('gallery:assetConfirm.shareConfirmButton'),
    unshareConfirmButton: t('gallery:assetConfirm.unshareConfirmButton')
  };
};

type TextOptions = ReturnType<typeof pageText>;

interface GenerateModalContentArgs {
  text: TextOptions;
  type: ModalType;
}

const generateBodyText = ({
  text,
  type,
  hasTeamCapabilities
}: GenerateModalContentArgs & { hasTeamCapabilities: boolean }) => {
  if (!hasTeamCapabilities && type === modalTypes.delete) {
    return text.confirmDeleteAsset;
  }

  if (hasTeamCapabilities && type === modalTypes.delete) {
    return text.confirmDeleteTeamAsset;
  }
  if (type === modalTypes.shareWithTeam) {
    return text.confirmShareAsset;
  }

  if (type === modalTypes.unshareWithTeam) {
    return text.confirmUnshareAsset;
  }
  return text.unknownType;
};

const generateHeaderText = ({ text, type }: GenerateModalContentArgs) => {
  if (type === modalTypes.delete) {
    return text.removeAssetHeader;
  }
  if (type === modalTypes.shareWithTeam) {
    return text.shareAssetHeader;
  }
  if (type === modalTypes.unshareWithTeam) {
    return text.unshareAssetHeader;
  }
  return text.unknownType;
};

const generateButtonText = ({ text, type }: GenerateModalContentArgs) => {
  if (modalTypes.delete === type) {
    return text.deleteConfirmButton;
  }
  if (modalTypes.shareWithTeam === type) {
    return text.shareConfirmButton;
  }
  if (modalTypes.unshareWithTeam === type) {
    return text.unshareConfirmButton;
  }
  return text.unknownType;
};

const GalleryAssetModal = ({
  onClose,
  remove,
  toggleShare,
  hasTeamCapabilities,
  groupName,
  type,
  open
}: GalleryAssetModalProps) => {
  const text = pageText({ groupName });

  return (
    <Modal
      showClose
      onClose={onClose}
      open={open}
      HeaderComponent={() => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, my: -1 }}>
          <MediaIcon />
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {generateHeaderText({ text, type })}
          </Typography>
        </Box>
      )}
    >
      {generateBodyText({ text, type, hasTeamCapabilities })}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          gap: 2,
          pt: 2
        }}
      >
        <Button onClick={onClose}>{text.cancelButton}</Button>
        <Button
          color="primary"
          variant="contained"
          data-cy="gallery-asset-modal-confirm-button"
          onClick={() => {
            const eventProperties = { object: assetTypes.mediaAsset };

            if (type === modalTypes.delete) {
              logAssetEvent({
                type: assetEventTypes.deleteConfirm,
                properties: eventProperties
              });
              return remove();
            }
            if (
              type === modalTypes.shareWithTeam ||
              type === modalTypes.unshareWithTeam
            ) {
              logAssetEvent({
                type:
                  type === modalTypes.shareWithTeam
                    ? assetEventTypes.shareConfirm
                    : assetEventTypes.unshareConfirm,
                properties: eventProperties
              });
              return toggleShare();
            }

            return undefined;
          }}
        >
          {generateButtonText({ text, type })}
        </Button>
      </Box>
    </Modal>
  );
};

export default GalleryAssetModal;
