import axios from 'axios';

import auth from '../Auth/Auth';
import isAuthError from '../Auth/isAuthError';

// This enables us to check for auth errors on every request and spin up
// the login modal automatically as needed.
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (isAuthError(null, error.response)) {
      auth.login();
    }

    return Promise.reject(error);
  }
);

export default axios;
