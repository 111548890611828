import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';

import { Paper, Tooltip, Typography, Box, useTheme } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';

import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import AdPreview from 'src/components/AdPreview';

const AdsList = ({ architecture, program }) => {
  const theme = useTheme();

  const adsTitleTooltip = (
    <Trans i18nKey="programPerf:headers.adsTitleTooltip" />
  );

  const emptyAdsList = (
    <TableEmptyState emptyMessage={t('programPerf:adsList.empty')} />
  );

  const blueprint = program?.orderItem?.product;
  const dynamicUserInputs = program?.orderItem?.variableValues ?? {};
  const catalogItems =
    program?.orderItem?.catalogItems?.edges?.[0]?.node?.items ?? [];
  const requiresContent = program?.orderItem?.product?.requiresContent;
  // Despite the fact this is program.id program is actually order.
  // It gets reassigned earlier up in the tree
  const orderId = program?.id;

  // formatting catalog items to work with the preview
  const businessObjects = catalogItems.reduce((accum, current) => {
    const fields = current?.fields;
    if (fields) {
      accum.push(fields);
    }
    return accum;
  }, []);

  // if not enough data return empty list messaging
  if (
    (isEmpty(businessObjects) && requiresContent) ||
    isEmpty(dynamicUserInputs) ||
    isEmpty(blueprint)
  ) {
    return emptyAdsList;
  }

  const previewData = {
    blueprint,
    dynamicUserInputs,
    businessObjects,
    orderId
  };

  return (
    <Paper sx={{ p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Typography
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
          variant="h6"
        >
          <Trans i18nKey="programPerf:headers.adsTitle" />
          <Tooltip
            sx={{
              ml: 1
            }}
            title={adsTitleTooltip}
            aria-label={adsTitleTooltip}
          >
            <InfoIcon />
          </Tooltip>
        </Typography>
      </Box>
      <br />
      <Box
        sx={{
          minWidth: '410px',
          maxWidth: '500px',

          [theme.breakpoints.down('sm')]: {
            minWidth: 'initial'
          }
        }}
      >
        <AdPreview architecture={architecture} previewData={previewData} />
      </Box>
    </Paper>
  );
};

export default AdsList;
