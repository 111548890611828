/* eslint-disable @typescript-eslint/no-non-null-assertion */

const generateColors = (color: string) => {
  const hexNumber = /\b[0-9A-F]{6}\b/gi;
  const colorNumber = color.match(hexNumber)![0];

  const ratio = 0.3;

  const hex = (x: number | string) => {
    x = x.toString(16); // eslint-disable-line no-param-reassign
    return x.length === 1 ? `0${x}` : x;
  };

  const white = 'FFFFFF';
  const black = '000000';

  const rWithWhite = Math.ceil(
    parseInt(white.substring(0, 2), 16) * ratio +
      parseInt(colorNumber.substring(0, 2), 16) * (1 - ratio)
  );
  const gWithWhite = Math.ceil(
    parseInt(white.substring(2, 4), 16) * ratio +
      parseInt(colorNumber.substring(2, 4), 16) * (1 - ratio)
  );
  const bWithWhite = Math.ceil(
    parseInt(white.substring(4, 6), 16) * ratio +
      parseInt(colorNumber.substring(4, 6), 16) * (1 - ratio)
  );

  const rWithBlack = Math.ceil(
    parseInt(black.substring(0, 2), 16) * ratio +
      parseInt(colorNumber.substring(0, 2), 16) * (1 - ratio)
  );
  const gWithBlack = Math.ceil(
    parseInt(black.substring(2, 4), 16) * ratio +
      parseInt(colorNumber.substring(2, 4), 16) * (1 - ratio)
  );
  const bWithBlack = Math.ceil(
    parseInt(black.substring(4, 6), 16) * ratio +
      parseInt(colorNumber.substring(4, 6), 16) * (1 - ratio)
  );

  const middleOfWhite = hex(rWithWhite) + hex(gWithWhite) + hex(bWithWhite);
  const middleOfBlack = hex(rWithBlack) + hex(gWithBlack) + hex(bWithBlack);

  return {
    300: `#${middleOfWhite}`,
    500: `#${colorNumber}`,
    700: `#${middleOfBlack}`
  };
};

export default generateColors;
