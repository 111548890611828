import { gql } from 'src/generated/gql';

export const getFacebookPagesV2 = gql(`
  query facebookPagesForChrome {
    facebookPagesV2 {
      id
      facebookUserId
      pageName
      pageId
      instagramId
      hasAcceptedTos
      pageStatus
    }
  }
`);

export const checkPullProviderExecutionStatuses = gql(`
  query checkPullProviderExecutionStatuses {
    me {
      id
      pullProviderExecutionStatuses {
        executionId
        pullProviderType
        isExecutionComplete
        jobIds
        jobDetails {
          totalItems
          remainingItems
          completedItems
          successfulItems
          errorItems
          isCompleted
        }
        executionErrors
      }
    }
  }
`);
