import { gql } from 'src/generated/gql';

export const updateEmailAutoResponderPreference = gql(`
  mutation UpdateEmailAutoResponderPreference(
    $autoResponderId: ID!
    $isEmailEnabled: Boolean!
    $emailBody: String!
    $emailSubject: String!
  ) {
    updateEmailAutoResponderPreference(
      autoResponderId: $autoResponderId
      isEmailEnabled: $isEmailEnabled
      emailBody: $emailBody
      emailSubject: $emailSubject
    ) {
      id
    }
  }
`);

export const updateSmsAutoResponderPreference = gql(`
  mutation UpdateSmsAutoResponderPreference(
    $autoResponderId: ID!
    $isSmsEnabled: Boolean!
    $smsBody: String!
  ) {
    updateSmsAutoResponderPreference(
      autoResponderId: $autoResponderId
      isSmsEnabled: $isSmsEnabled
      smsBody: $smsBody
    ) {
      id
    }
  }
`);

export const addUserNotificationPreference = gql(`
  mutation AddUserNotificationPreference(
    $mediumType: MediumType!
    $emailAddress: String
    $phoneNumber: String
    $notificationTypeId: ID!
  ) {
    addUserNotificationPreference(
      notificationTypeId: $notificationTypeId
      mediumType: $mediumType
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
    ) {
      id
    }
  }
`);

export const deleteUserNotificationPreference = gql(`
  mutation DeleteUserNotificationPreference($userPreferenceId: ID!) {
    deleteUserNotificationPreference(userPreferenceId: $userPreferenceId)
  }
`);

export const updateUserLocale = gql(`
  mutation updateUserLocaleFromLocalizationPreferences(
    $input: UpdateUserLocaleInput!
  ) {
    updateUserLocale(
      input: $input
    ) {
      locale
    }
  }
`);
