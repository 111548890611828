import { Helmet } from 'react-helmet';

import { generatePageTitle } from 'src/routes/RouteUtil';

import { useAppSettings } from 'src/AppSettings';

const PageTitle = ({ subPageTitle = '' }) => {
  const appSettings = useAppSettings();

  return (
    <Helmet>
      <title>
        {generatePageTitle(subPageTitle, appSettings?.app?.general?.baseTitle)}
      </title>
    </Helmet>
  );
};

export default PageTitle;
