interface ProgramOrderEdge {
  node: {
    id: string;
    name: string;
    architectureId: string;
  };
}

interface FormatProgramOrdersArgs {
  orders: {
    edges: ProgramOrderEdge[];
  };
}

interface AutomationOrderEdge {
  node: {
    id: string;
    name: string;
    offer: {
      product: {
        architectureId: string;
      };
    };
  };
}

interface FormatAutomationOrdersArgs {
  orders: {
    edges: AutomationOrderEdge[];
  };
}

export const formatProgramOrders = ({ orders }: FormatProgramOrdersArgs) => {
  return [...(orders.edges || [])].map(edge => {
    const { node } = edge;
    return {
      id: node.id,
      name: node.name,
      architectureId: node.architectureId
    };
  });
};

export const formatAutomationOrders = ({
  orders
}: FormatAutomationOrdersArgs) => {
  return orders.edges.map(edge => {
    const { node } = edge;

    return {
      id: node.id,
      name: node.name,
      architectureId: node.offer.product.architectureId
    };
  });
};
