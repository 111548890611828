import { cloneElement, useState, useContext } from 'react';
import { flow, omit, isEmpty } from 'lodash';
import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';

import Instrumentation from 'src/instrumentation';
import s3ImageUpload from 'src/common/s3ImageUpload';
import { GlobalContext } from 'src/GlobalContextProvider';
import Modal from 'src/components/Modal';

import {
  ASSET_STATUS,
  GALLERY_TYPE,
  MAX_FILE_SIZE_BY_GALLERY_TYPE,
  MAX_FILE_SIZE_MB_BY_GALLERY_TYPE,
  MEDIA_SOURCES,
  LIBRARY_SCOPES,
  UPLOAD_ACCEPTS,
  INTERNAL_UPLOAD_ACCEPTS,
  INTERNAL_UPLOAD_ACCEPTS_NO_SVG,
  MIN_IMG_HEIGHT,
  MIN_IMG_WIDTH,
  getGrantTypesForMe
} from './constants';

import { assetReservationv2, updateAssetv2 } from './mutations';

const GalleryUploadHandler = ({
  children,
  assetReservationv2,
  updateAssetv2,
  setSelectedGalleryItem,
  setUploadStatuses,
  internalOnly,
  internalOnlyAllowSvg,
  sizeConstraint,
  galleryRefetch,
  returnToGallery,
  noClick = false,
  galleryType
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useContext(GlobalContext);

  // we pass the async request to this and when the modal closes
  // it fullfills it with the scope the user selected
  const [scopeModalPromise, setScopeModalPromise] = useState();
  const [isAddingMedia, setIsAddingMedia] = useState(false);

  const grantTypesByScope = getGrantTypesForMe(me);

  const genericError = t('gallery:error.uploadingError');

  const uploadAsset = async (
    file,
    index,
    fileArray,
    // if we don't explicity pass a scope we default to user library
    // except if internal library which superseded all else
    // we check for ability to upload internal assets in gallery.jsx
    scope = internalOnly ? LIBRARY_SCOPES.Internal : LIBRARY_SCOPES.User
  ) => {
    setIsAddingMedia(true);
    const fileName = file?.name;
    const fileSize = file?.size;

    // Check the file size.
    let fileType;
    if (file.type.includes('image')) {
      fileType = GALLERY_TYPE.image;
    } else if (file.type.includes('video')) {
      fileType = GALLERY_TYPE.video;
    } else {
      enqueueSnackbar(
        t('gallery:error.badFileType', {
          fileName
        }),
        {
          variant: 'error'
        }
      );
      return;
    }

    if (
      MAX_FILE_SIZE_BY_GALLERY_TYPE[fileType] &&
      fileSize >= MAX_FILE_SIZE_BY_GALLERY_TYPE[fileType]
    ) {
      let errorMessage = '';

      switch (fileType) {
        case GALLERY_TYPE.image:
          errorMessage = t('gallery:error.imageFileSizeTooBig', {
            size: MAX_FILE_SIZE_MB_BY_GALLERY_TYPE[GALLERY_TYPE.image]
          });
          break;

        case GALLERY_TYPE.video:
          errorMessage = t('gallery:error.videoFileSizeTooLarge', {
            size: MAX_FILE_SIZE_MB_BY_GALLERY_TYPE[GALLERY_TYPE.video]
          });
          break;

        default:
          errorMessage = t('gallery:error.fileSizeTooLargeGeneric');
          break;
      }

      const message = (
        <div>
          {errorMessage}
          <br />
          <br />
          <span>
            <Trans i18nKey="gallery:error.fileName">File Name: </Trans>
            {fileName}
          </span>
        </div>
      );

      enqueueSnackbar(message, {
        variant: 'error'
      });

      return;
    }

    let assetId;
    let reservationURL;

    // decide the media source
    let source = MEDIA_SOURCES.User;
    if (scope) {
      source = scope;
    }

    try {
      ({
        data: {
          assetReservationv2: { assetId, reservationURL }
        }
      } = await assetReservationv2({
        variables: {
          input: {
            name: fileName,
            source,
            type: fileType,
            grants: grantTypesByScope[scope]
          }
        }
      }));
    } catch (e) {
      enqueueSnackbar(genericError, {
        variant: 'error'
      });
      return;
    }

    // 2. Upload file to s3
    try {
      await s3ImageUpload(file, reservationURL, onUploadProgress => {
        const progress = Math.round(
          (onUploadProgress.loaded * 100) / onUploadProgress.total
        );

        setUploadStatuses(currentStatuses => {
          return {
            ...currentStatuses,
            [assetId]: {
              assetId,
              name: file?.name,
              progress,
              status: ASSET_STATUS.pending
            }
          };
        });
      });
    } catch (e) {
      enqueueSnackbar(genericError, {
        variant: 'error'
      });

      await updateAssetv2({
        variables: {
          input: {
            assetId,
            status: ASSET_STATUS.error
          }
        }
      });
      // clear upload status
      setUploadStatuses(currentStatuses => {
        return omit(currentStatuses, [assetId]);
      });
      return;
    }

    // 3. Update asset status to complete and metadata
    let updateAssetData;
    try {
      ({ data: updateAssetData } = await updateAssetv2({
        variables: {
          input: {
            assetId,
            status: ASSET_STATUS.complete
          }
        }
      }));
      // clearupload status
      setUploadStatuses(currentStatuses => {
        return omit(currentStatuses, [assetId]);
      });
    } catch (e) {
      // error
      enqueueSnackbar(genericError, {
        variant: 'error'
      });
      // clearupload status
      setUploadStatuses(currentStatuses => {
        return omit(currentStatuses, [assetId]);
      });
      return;
    }

    const eventMeta = {
      assetId: updateAssetData?.id,
      mediaType: updateAssetData?.type,
      status: updateAssetData?.status,
      permissions: updateAssetData?.source
    };
    Instrumentation.logEvent(
      Instrumentation.Events.MediaLibraryAssetUploaded,
      eventMeta
    );

    await galleryRefetch();
    // if we upload more than one select the last one?
    if (index === fileArray.length - 1 && setSelectedGalleryItem) {
      setSelectedGalleryItem(updateAssetData?.updateAssetv2);
      setIsAddingMedia(false);
    }
  };

  const onDrop = (acceptedFiles, failedFiles) => {
    Instrumentation.logEvent(Instrumentation.Events.MediaLibraryUpload);
    if (!isEmpty(failedFiles)) {
      // create error snacks for all failed files
      failedFiles.forEach(({ name }) => {
        enqueueSnackbar(
          t('gallery:error.badAcceptedFile', {
            name
          }),
          {
            variant: 'error'
          }
        );
      });
    }

    // if you only uploaded failing files then better luck next time ╭∩╮༼☯۝☯༽╭∩╮ chump
    if (isEmpty(acceptedFiles)) {
      return;
    }

    const promises = [];
    const validatedFiles = [];

    // validate image attributes here: (min width/height)
    acceptedFiles.forEach(acceptedFile => {
      promises.push(
        new Promise(resolve => {
          // we are only checking image files;
          // and if it is an internal gallery we don't care
          if (!acceptedFile.type.includes('image') || internalOnly) {
            validatedFiles.push(acceptedFile);
            return resolve();
          }
          const fr = new FileReader();
          fr.onload = () => {
            // file is loaded
            const img = new Image();

            img.onload = () => {
              if (
                sizeConstraint &&
                (img.height < MIN_IMG_HEIGHT || img.width < MIN_IMG_WIDTH)
              ) {
                // set error
                enqueueSnackbar(
                  t('gallery:errors.minDimensions', {
                    fileName: acceptedFile.name,
                    MIN_IMG_WIDTH,
                    MIN_IMG_HEIGHT
                  }),
                  {
                    variant: 'error'
                  }
                );
              } else {
                validatedFiles.push(acceptedFile);
              }
              resolve();
            };

            img.src = fr.result; // is the data URL because called with readAsDataURL
          };

          fr.readAsDataURL(acceptedFile);
        })
      );
    });

    // here we do the logic for each accepted file (after size validation has cleared)
    Promise.allSettled(promises).then(() => {
      // if admin has the ability to upload corporate media
      // so we let them choose between corp or private via a modal
      if (grantTypesByScope[LIBRARY_SCOPES.Corporate] && !internalOnly) {
        const adminScopeChooser = new Promise((resolve, reject) => {
          // confusing async:
          // we send the resolve function in state to the modal where they choose the scope
          setScopeModalPromise({ resolve, reject });
        });

        adminScopeChooser
          .then(scope => {
            // upload the files with scope
            validatedFiles.forEach((f, i, a) => uploadAsset(f, i, a, scope));
            returnToGallery();
          })
          .catch(() => {
            /* we just canceled so do nothing */
          });
      } else {
        // upload the files default scope
        validatedFiles.forEach(uploadAsset);
        returnToGallery();
      }
    });
  };

  let accepted = UPLOAD_ACCEPTS[galleryType];

  if (internalOnly) {
    accepted = internalOnlyAllowSvg
      ? INTERNAL_UPLOAD_ACCEPTS[galleryType]
      : INTERNAL_UPLOAD_ACCEPTS_NO_SVG[galleryType];
  }

  const dropZone = useDropzone({
    noClick,
    onDrop,
    accept: accepted
  });

  return (
    <div>
      {cloneElement(children, {
        dropZone,
        isAddingMedia,
        setIsAddingMedia
      })}

      <Modal
        maxWidth="sm"
        onClose={() => {
          /* we disallow any closing outside the buttons */
        }}
        open={!!scopeModalPromise}
        data-cy="upload-modal"
        FooterComponent={() => (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                scopeModalPromise.resolve(LIBRARY_SCOPES.User);
                setScopeModalPromise(null);
              }}
              data-cy="upload-modal-private-button"
            >
              Save to private gallery
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                scopeModalPromise.resolve(LIBRARY_SCOPES.Corporate);
                setScopeModalPromise(null);
              }}
              data-cy="upload-modal-corporate-button"
            >
              Save as Corporate Shared
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                scopeModalPromise.reject();
                setScopeModalPromise(null);
              }}
            >
              Cancel
            </Button>
          </>
        )}
      >
        As an admin you can choose between saving an image to your own private
        user library or to save to the corporate shared library that all users
        in your organization have access to.
      </Modal>
    </div>
  );
};

export default flow(
  graphql(assetReservationv2, { name: 'assetReservationv2' }),
  graphql(updateAssetv2, { name: 'updateAssetv2' })
)(GalleryUploadHandler);
