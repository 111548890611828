import {
  validateIsNumber,
  maxValuePercentage,
  validateLength,
  minValue,
  validateNotBlank,
  validateRequired
} from 'src/common/validations';
import {
  promotionSupportType,
  promotionSupportTypeOptions
} from 'src/common/promotions';
import { dayjs } from 'src/common/dates';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

const uppercaseNumbersDashesRegex = /^[A-Z0-9][A-Z0-9-]*[A-Z0-9]$/;
const uppercaseNumbersDashes = str => {
  if (!uppercaseNumbersDashesRegex.test(str)) {
    return 'Promo code must contain only uppercase letters or - dash and must not start or end with dash';
  }
};

const platformFeeCalculationOptions = [
  { value: 'net', name: 'Net (default)' },
  { value: 'gross', name: 'Gross' }
];

export const getPromoInputs = (isUpdate = false) => [
  {
    name: 'promoCode',
    displayName: 'Promo Code',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: isUpdate
      ? []
      : [
          validateRequired,
          uppercaseNumbersDashes,
          validateNotBlank(),
          validateLength(2, 32)
        ],
    isRequired: !isUpdate,
    isHidden: false,
    disabled: isUpdate,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is the code your users will enter to apply the promotion.'
      }
    }
  },
  {
    name: 'startDate',
    displayName: 'Start Date',
    displayMethodId: INPUT_TYPES.DATE_UTC,
    initialValue: dayjs(),
    reduxValidations: [validateRequired],
    isRequired: !isUpdate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        datePickerProps: {
          disablePast: true
        }
      }
    }
  },
  {
    name: 'endDate',
    displayName: 'End Date',
    displayMethodId: INPUT_TYPES.DATE_UTC,
    initialValue: dayjs().add(1, 'months'),
    reduxValidations: [validateRequired],
    isRequired: !isUpdate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        datePickerProps: {
          disablePast: true
        }
      }
    }
  },
  {
    name: 'description',
    displayName: 'Description',
    displayMethodId: INPUT_TYPES.MULTI_LINE_STRING,
    initialValue: undefined,
    reduxValidations: isUpdate ? [] : [validateRequired, validateNotBlank()],
    isRequired: !isUpdate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'supportType',
    displayName: 'Support Type',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: promotionSupportType?.program?.value,
    reduxValidations: [validateRequired],
    isRequired: !isUpdate,
    isHidden: false,
    disabled: isUpdate,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: promotionSupportTypeOptions
      }
    }
  },
  {
    name: 'percentageDiscount',
    displayName: 'Percentage Discount',
    displayMethodId: INPUT_TYPES.PERCENTAGE_DECIMAL,
    initialValue: undefined,
    reduxValidations: [
      ...(isUpdate ? [] : [validateRequired, validateNotBlank()]),
      maxValuePercentage(100),
      validateIsNumber
    ],
    isRequired: !isUpdate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'percentage discount'
      }
    }
  },
  {
    name: 'maxDollarDiscountPerUse',
    displayName: 'Max Dollar Discount Per Use',
    displayMethodId: INPUT_TYPES.PRICE_DECIMAL,
    initialValue: undefined,
    reduxValidations: [
      ...(isUpdate ? [] : [validateRequired, validateNotBlank()]),
      validateIsNumber
    ],
    isRequired: !isUpdate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'totalDollarDiscount',
    displayName: 'Maximum Dollar Pool',
    displayMethodId: INPUT_TYPES.PRICE_DECIMAL,
    initialValue: undefined,
    reduxValidations: [
      ...(isUpdate ? [] : [validateRequired, validateNotBlank()]),
      validateIsNumber
    ],
    isRequired: !isUpdate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is the maximum amount that can be redeemed across the organization. For example, if this is set to $400, then once any number of users have redeemed a total of $400 off their purchases, no other users will be able to use this promotion.'
      }
    }
  },
  {
    name: 'minimumSpend',
    displayName: 'Min Spend',
    displayMethodId: INPUT_TYPES.PRICE_DECIMAL,
    initialValue: undefined,
    reduxValidations: [validateIsNumber],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'This is the minimum value of the order for the promo to be applied e.g. "you must spend $100 or more to use this promotion"'
      }
    }
  },
  // Platform Fee Calculation can not be updated once a promotion has beeen created
  {
    name: 'platformFeeCalculation',
    displayName: 'Platform Fee Calculation',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: platformFeeCalculationOptions[0].value,
    disabled: isUpdate,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: platformFeeCalculationOptions,
        tooltip: `Determines how we calculate platform fees when the promo is applied. 'Gross' calculates fees on the original order value (e.g., assuming a 20% fee, a $100 order with a $50 off promo results in a $20 fee). 'Net' calculates fees after promo deduction (e.g., a $100 order with a $50 off promo results in a $10 fee at 20%).`
      }
    }
  },
  {
    name: 'totalNumberOfUsesAllowed',
    displayName: 'Total Number of Uses Allowed Per Organization',
    displayMethodId: INPUT_TYPES.POSITIVE_INT,
    initialValue: undefined,
    reduxValidations: [validateIsNumber, minValue(1)],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'totalNumberOfUsesAllowedPerUser',
    displayName: 'Total Number of Uses Allowed Per User',
    displayMethodId: INPUT_TYPES.POSITIVE_INT,
    initialValue: undefined,
    reduxValidations: [validateIsNumber, minValue(1)],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'totalAmountRedeemablePerUser',
    displayName: 'Total Amount Redeemable Per User',
    displayMethodId: INPUT_TYPES.POSITIVE_INT,
    initialValue: undefined,
    reduxValidations: [validateIsNumber, validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'The maximum dollar amount that can be redeemed per user.'
      }
    }
  },
  ...(isUpdate
    ? [
        {
          name: 'markAsDeleted',
          displayName: 'Mark as Deleted',
          displayMethodId: INPUT_TYPES.BOOLEAN,
          initialValue: undefined,
          reduxValidations: [],
          isRequired: false,
          isHidden: false,
          displayParameters: {
            inputData: {
              columnWidth: 2
            }
          }
        }
      ]
    : [])
];
