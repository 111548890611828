import { useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { every, chunk, flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import withStyles from '@mui/styles/withStyles';

import { useInvokableQuery } from 'src/hooks/apollo/queryHooks';
import Loading from 'src/components/Loading';
import { useInterval } from 'src/hooks/useInterval';

import { createOrUpdateAdminContentSetItemBulk } from '../mutations';
import { getBulkOperationReportStatus } from '../queries';

const PUBLISH_CHUNK_SIZE = 500;
const POLLING_DELAY = 5000;

const styles = theme => ({
  loadingSpinner: {
    padding: theme.spacing(2)
  }
});

const pageText = () => ({
  error: t('adminContentDataManager:uploadModal.errorWaitingForPublish')
});

const StepWaitForPublish = ({
  classes,
  handleNext,
  contentSetSlug,
  fileParseResults,
  reportIds,
  setReportIds,
  createOrUpdateAdminContentSetItemBulk
}) => {
  const text = useMemo(() => pageText(), []);

  const [isPolling, setIsPolling] = useState(false);
  const [error, setError] = useState(false);

  const getReportStatus = useInvokableQuery(getBulkOperationReportStatus);

  const { data } = fileParseResults;

  const uploadContent = async () => {
    const tasks = chunk(data, PUBLISH_CHUNK_SIZE).map(async rows => {
      const request = {
        variables: {
          contentSetSlug,
          items: rows.map(row => ({
            values: {
              ...row,
              id: row.id.toString()
            }
          }))
        }
      };

      const response = await createOrUpdateAdminContentSetItemBulk(request);

      return response.data.createOrUpdateAdminContentSetItemBulk.reportId;
    });

    return Promise.all(tasks);
  };

  const fetchReportStatuses = async reportIds => {
    const statusTasks = reportIds.map(async reportId => {
      const response = await getReportStatus({
        reportId
      });

      return response.data.getBulkOperationReportStatus;
    });

    return Promise.all(statusTasks);
  };

  const checkForCompletion = async () => {
    try {
      const statuses = await fetchReportStatuses(reportIds);

      if (every(statuses, s => s.isCompleted)) {
        setIsPolling(false);
        handleNext();
      }
    } catch (error) {
      setError(true);
      setIsPolling(false);
    }
  };

  // perform the initial upload setps
  useEffect(() => {
    const startUpload = async () => {
      try {
        const reportIds = await uploadContent();
        setReportIds(reportIds);
        setIsPolling(true);
      } catch (error) {
        setError(true);
        setIsPolling(false);
      }
    };
    startUpload();
  }, [fileParseResults]);

  // poll for completion
  useInterval(checkForCompletion, isPolling ? POLLING_DELAY : null);

  return (
    <div
      className={classes.loadingSpinner}
      data-cy="content-data-manager-wait-for-publish"
    >
      <Loading loading={!error} error={error} errorMessage={text.error} />
    </div>
  );
};

export default flow(
  graphql(createOrUpdateAdminContentSetItemBulk, {
    name: 'createOrUpdateAdminContentSetItemBulk'
  }),
  withStyles(styles)
)(StepWaitForPublish);
