import { useState, useMemo, useCallback } from 'react';
import { unregisterField } from 'redux-form';
import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import { flow, isUndefined, each } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { Divider, Button, Alert, AlertTitle } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { t } from 'i18next';
import { connect } from 'react-redux';
import Table from 'src/components/Table';
import Modal from 'src/components/Modal';
import Loading from 'src/components/Loading';
import { DynamicForm } from 'src/components/ReduxForm';
import RemoveIcon from '@mui/icons-material/DeleteForever';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import { mapIntrospectionToProps } from 'src/common/ApolloUtil';

import { columnTypeIntrospection } from './queries';

import { CONTENT_TABLE_FORM_NAME, getColumnInputs } from './constants';

const pageText = () => ({
  modalHeading: t('admin:contentTable.editModalHeading'),
  addButton: t('admin:contentTable.listFormAddButton'),
  saveColumn: t('admin:contentTable.ColumnModalSave')
});

const styles = theme => ({
  removeContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%'
  },
  removeDivider: {
    margin: `${theme.spacing(2)} 0`
  },
  removeButton: {
    background: theme.palette.error.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.main
    }
  },
  error: {}
});

const RenderTableForm = props => {
  const {
    classes,
    fields,
    meta: { error },
    knownTypesEnumFields,
    knownTypesIntrospectionMeta: { loading },
    validateThen,
    unregisterField,
    formErrors
  } = props;

  // Make sure to only include existing ID columns. If we add a new ID column we
  // don't want to hide the primary toggle for it!
  const hasExistingIdColumn = fields
    .getAll()
    .some(f => f.name === 'id' && !f.isNew);

  const text = useMemo(pageText, []);

  const [editModal, setEditModal] = useState(null);

  const newItemsDefault = {
    ...getInitialValuesFromInputsConfig(getColumnInputs()),
    isNew: true,
    name: 'COLUMN_NAME',
    type: 'TEXT'
  };

  const addNewItem = useCallback(() => {
    fields.push(newItemsDefault);
    setEditModal(fields.length);
  }, [fields]);

  // quick and dirty schema for the table
  const columnSchema = getColumnInputs({
    knownTypesEnumFields: knownTypesEnumFields?.filter(
      ktef => ktef.value !== 'ID_TYPE'
    ),
    // We don't want to allow modification to the primary key column
    // UNLESS they don't have an ID column already.
    // In practice, we should always have an ID column, but in some older
    // tables we may not.
    hidePrimaryKey: !hasExistingIdColumn
  }).map(column => ({
    columnName: column.displayName,
    accessor: column.name,
    key: column.name,
    type: column.displayMethodId
  }));

  columnSchema.unshift({
    columnName: '',
    accessor: 'error',
    key: 'error',
    type: 'STRING',
    CellComponent: ({ data }) => {
      return (
        <>
          {data && (
            <div>
              <ErrorIcon color="error" />
            </div>
          )}
        </>
      );
    }
  });

  const getFormInputs = (data, name) =>
    getColumnInputs({
      knownTypesEnumFields: knownTypesEnumFields?.filter(
        // ktef = Known Types Enum Fields
        ktef => ktef.value !== 'ID_TYPE'
      ),
      data
    }).map(f => ({
      ...f,
      name: `${name}.${f.name}`
    }));

  // all column rows need a unique key
  const taken = new Set();
  const keyedRows =
    fields.getAll()?.map((r, i) => {
      let key = r.name;
      // key by name or index
      if (taken.has(key) || !key) {
        key = `${key}${i}`;
      }
      taken.add(r.name);
      return { ...r, key };
    }) || [];

  // add error to rows for error display
  each(formErrors?.columns, (error, i) => {
    if (!isUndefined(error)) {
      if (keyedRows[i]) {
        keyedRows[i].error = true;
      }
    }
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {error && (
        <Alert severity="error">
          <AlertTitle>Error:</AlertTitle>
          {error}
        </Alert>
      )}
      <Table
        rows={keyedRows}
        columnSchema={columnSchema}
        loading={false}
        onClickBodyRow={(row, index) => {
          setEditModal(index);
        }}
      />
      <Button color="primary" startIcon={<AddIcon />} onClick={addNewItem}>
        {text.addButton}
      </Button>
      {fields.map((member, index, fields) => {
        if (index !== editModal) {
          return null;
        }
        const field = fields.get(index);
        return (
          <Modal
            headerText={text.modalHeading}
            fullWidth
            open
            // eslint-disable-next-line react/no-array-index-key
            key={`modalKey_${index}`}
            maxWidth="lg"
            onClose={() => validateThen(() => setEditModal(null))}
            FooterComponent={
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  validateThen(() => setEditModal(null));
                }}
              >
                {text.saveColumn}
              </Button>
            }
          >
            <DynamicForm
              inputs={getFormInputs(fields.get(editModal), member)}
            />
            {field.isNew && (
              <div className={classes.removeContainer}>
                <Divider className={classes.removeDivider} />
                <Button
                  className={classes.removeButton}
                  variant="contained"
                  onClick={() => {
                    fields.remove(index);
                    unregisterField(
                      CONTENT_TABLE_FORM_NAME,
                      `${fields.name}[${index}]`
                    );
                    validateThen(() => setEditModal(null), fields);
                  }}
                  startIcon={<RemoveIcon />}
                >
                  {text.removeButton}
                </Button>
              </div>
            )}
          </Modal>
        );
      })}
    </>
  );
};

export default flow(
  connect(null, { unregisterField }),
  graphql(columnTypeIntrospection, {
    name: 'columnTypeIntrospection',
    props: ({ columnTypeIntrospection }) =>
      mapIntrospectionToProps(columnTypeIntrospection, 'knownTypes')
  }),
  withStyles(styles)
)(RenderTableForm);
