import { gql } from 'src/generated/gql';

export const fetchEmbeddedLink = gql(`
  query fetchEmbeddedLink {
    fetchEmbeddedLink {
      link
      errorMessage
    }
  }
`);

export const fetchEmbeddedLinkPublic = gql(`
  query fetchEmbeddedLinkPublic($fqdn: String! $externalId: String!) {
    public {
      fetchEmbeddedLinkPublic(fqdn: $fqdn, externalId: $externalId) {
        link
        errorMessage
      }
    }
  }
`);
