import { get } from 'lodash';
import {
  FacebookPageSettings,
  FacebookPageSelection
} from 'src/generated/gql/graphql';

export const facebookPageTypes = {
  pageGroup: 'PAGE_GROUP',
  page: 'PAGE'
};

// Facebook page returned from the Evcoclize APIs.
export type EvApiFacebookPage = FacebookPageSettings | FacebookPageSelection;

// Facebook page returned from the Fb API
interface FbApiFacebookPage {
  tasks?: string[];
}

export const isFacebookPageUsable = (
  facebookPage?: FacebookPageSettings | FacebookPageSelection,
  isLeadFormFacebookBlueprint?: boolean
) => {
  if (!facebookPage) {
    return false;
  }

  const needsToAcceptTos =
    !facebookPage.hasAcceptedTos && isLeadFormFacebookBlueprint;

  if (
    'type' in facebookPage &&
    facebookPage?.type === facebookPageTypes.pageGroup
  ) {
    return !!(
      !needsToAcceptTos &&
      facebookPage.pageStatus === 'completed' &&
      facebookPage.pageGroupId
    );
  }

  // individual page
  return !!(
    !needsToAcceptTos &&
    facebookPage.pageStatus === 'completed' &&
    facebookPage.instagramId &&
    facebookPage.pageId
  );
};

export const isFacebookSelectionUsable = (
  facebookPage?: Omit<FacebookPageSelection, 'facebookPageSettings'>,
  isLeadFormFacebookBlueprint?: boolean
) => {
  if (!facebookPage) {
    return false;
  }
  const needsToAcceptTos =
    !facebookPage.hasAcceptedTos && isLeadFormFacebookBlueprint;

  if (
    facebookPage.type === facebookPageTypes.page &&
    !needsToAcceptTos &&
    facebookPage.pageStatus === 'completed' &&
    facebookPage.instagramId &&
    facebookPage.pageId
  ) {
    return true;
  }

  if (
    facebookPage.type === facebookPageTypes.pageGroup &&
    'pageGroupId' in facebookPage &&
    facebookPage.pageGroupId
  ) {
    return true;
  }

  return false;
};

// Note: This requires a Facebook page object directly from the Facebook APIs,
//       not the Evocalize APIs.
export const isFacebookPageAdmin = (facebookPage: FbApiFacebookPage = {}) => {
  const tasks = get(facebookPage, 'tasks') || [];

  for (let i = 0; i < tasks.length; i++) {
    if (tasks[i] === 'MANAGE') {
      return true;
    }
  }

  return false;
};

export const isCorporateFacebookPage = (
  fbPage: EvApiFacebookPage | undefined
) => {
  if (!fbPage) {
    return false;
  }
  if ('isOrgDefault' in fbPage && fbPage?.isOrgDefault) {
    return true;
  }
  if ('facebookUserId' in fbPage && fbPage?.facebookUserId === 'system_user') {
    return true;
  }
  return false;
};

export const FACEBOOK_URLS = {
  leadgenTos: 'https://www.facebook.com/ads/leadgen/tos',
  support: 'https://www.facebook.com/business/help',
  businessManager: 'https://business.facebook.com/'
};
