import React, { Fragment, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { find, filter, sortBy } from 'lodash';
import { Trans } from 'react-i18next';

import {
  Button,
  Grid,
  Chip,
  Menu,
  MenuItem,
  TableContainer
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import { ThemeAugmentations } from 'src/AppThemeWrapper';
import { Theme } from '@mui/system';

import RulesList from './RulesList';
import {
  AutomatedProgramFilterType,
  AutomatedProgramFilterRule
} from './constants';
import {
  getOperatorsByColumn,
  getDefaultFilterValueByColumn,
  ContentSetFieldMetadata
} from './helpers';

const useStyles = makeStyles((theme: Theme & ThemeAugmentations) => ({
  tableContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  table: {
    // width: '100%',
    display: 'table',
    borderSpacing: 0,
    borderCollapse: 'separate',
    marginBottom: theme.spacing(2)
  },
  headerCell: {
    border: `1px solid ${grey[400]}`,
    borderRight: '0',
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    verticalAlign: 'top',
    fontSize: '1rem',
    textAlign: 'right',
    whiteSpace: 'nowrap',
    '& div:first-child': {
      paddingTop: '7px'
    }
  },
  tbody: {
    display: 'table-row-group',
    '& tr': {
      display: 'tableRow',
      outline: 0,
      verticalAlign: 'middle'
    },
    '& td, & th': {
      display: 'table-cell',
      textAlign: 'left',
      padding: theme.spacing(2),
      borderBottom: `1px solid ${grey[400]}`
    },
    '& td:last-child': {
      borderRight: `1px solid ${grey[400]}`
    },
    '& th:first-child, & td:first-child': {
      borderLeft: `1px solid ${grey[400]}`
    },
    '& tr:nth-child(1) td, & tr:nth-child(1) th': {
      borderTop: `1px solid ${grey[400]}`,
      '&:last-child': {
        borderTopRightRadius: theme.spacing(1)
      },
      '&:first-child': {
        borderTopLeftRadius: theme.spacing(1)
      }
    },
    '& tr:last-child td': {
      borderBottom: `1px solid ${grey[400]}`,
      '&:last-child': {
        borderBottomRightRadius: theme.spacing(1)
      },
      '&:first-child': {
        borderBottomLeftRadius: theme.spacing(1)
      }
    }
  },
  spacerRow: {
    position: 'relative',
    padding: theme.spacing(1)
  },
  roundIcon: {
    display: 'inline-block',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    lineHeight: theme.spacing(5),
    textAlign: 'center',
    fontWeight: 'bold',
    background: '#fff',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: '0',
    // and positioning
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  button: {},
  addIcon: {
    height: '40px',
    width: '40px'
  },
  addText: {
    fontSize: '18px'
  },
  testRulesButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const getDefaultColumn = (columns: ContentSetFieldMetadata[]) => {
  return columns?.[0];
};

const defaultFilterFromColumn = (
  column: ContentSetFieldMetadata,
  operator?: AutomatedProgramFilterRule['operator']
) => ({
  column: column?.fieldName,
  rules: [
    {
      operator: operator || getOperatorsByColumn(column)[0].value,
      value: getDefaultFilterValueByColumn(column, operator)
    }
  ]
});

type FiltersListProps = {
  filters: Record<'id', string>[];
  contentMeta: ContentSetFieldMetadata[];
  filtersArrayMethods: any;
  formNamespace: string;
};

const FiltersList = (props: FiltersListProps) => {
  const { filters, contentMeta, filtersArrayMethods, formNamespace } = props;
  const classes = useStyles();
  const [addRuleMenuAnchorEl, setAddRuleMenuAnchorEl] = useState(null);
  const allFilters: AutomatedProgramFilterType[] = useWatch({
    name: formNamespace,
    defaultValue: []
  });

  const hasFilters = filters.length > 0;

  const getUnfilteredColumns = () => {
    const filteredColumns = new Set(allFilters.map(field => field.column));
    return sortBy(
      filter(contentMeta, cm => !filteredColumns.has(cm.fieldName)),
      'displayName'
    );
  };

  const closeAddRuleMenu = () => {
    setAddRuleMenuAnchorEl(null);
  };

  const openAddRuleMenu = (event: React.BaseSyntheticEvent) => {
    setAddRuleMenuAnchorEl(event.currentTarget);
  };

  const handleAddFilter = (column: ContentSetFieldMetadata) => {
    if (contentMeta.length <= 0) {
      return;
    }
    filtersArrayMethods.append(
      defaultFilterFromColumn(column || getDefaultColumn(contentMeta))
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {!hasFilters && (
          <Grid item xs={12} key="automatedProgramChipList">
            <div className={classes.chipRoot}>
              {sortBy(contentMeta, 'displayName').map(meta => {
                return (
                  <Chip
                    label={meta.displayName}
                    color="primary"
                    onClick={() => handleAddFilter(meta)}
                    clickable
                    variant="outlined"
                    key={meta.fieldName}
                    data-cy={`content-rule-chip-${meta.fieldName}`}
                  />
                );
              })}
            </div>
          </Grid>
        )}
        {hasFilters && (
          <TableContainer className={classes.tableContainer}>
            <div>
              <table className={classes.table} aria-label="spanning table">
                {filters.map((filter, i) => {
                  const deleteColumn = () => {
                    filtersArrayMethods.remove(i);
                  };
                  const fieldColumn = allFilters?.[i]?.column;
                  const columnDisplayName =
                    find(contentMeta, ['fieldName', fieldColumn])
                      ?.displayName ?? fieldColumn;
                  return (
                    <Fragment key={filter.id}>
                      {i > 0 && (
                        <tbody>
                          <tr>
                            <td className={classes.spacerRow}>
                              <span className={classes.roundIcon}>And</span>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <tbody
                        className={classes.tbody}
                        data-cy={`filters[${i}]`}
                        data-cy-key={`filters[${i}]`}
                      >
                        <RulesList
                          name={`${formNamespace}.${i}.rules`}
                          contentMeta={contentMeta}
                          columnValue={allFilters?.[i]}
                          columnDisplayName={columnDisplayName}
                          deleteColumn={deleteColumn}
                          filterIndex={i}
                        />
                      </tbody>
                    </Fragment>
                  );
                })}
              </table>
              {hasFilters && (
                <Button
                  color="primary"
                  data-cy="add-content-button"
                  focusRipple
                  disabled={contentMeta.length <= 0}
                  onClick={openAddRuleMenu}
                  className={classes.button}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  <Trans i18nKey="automatedProgramFilter:addButton">
                    Add Content Rule
                  </Trans>
                </Button>
              )}
            </div>
          </TableContainer>
        )}
        {Boolean(addRuleMenuAnchorEl) && (
          <Menu
            id="automated-wizard-ad-rules-menu"
            anchorEl={addRuleMenuAnchorEl}
            keepMounted
            open
            onClose={closeAddRuleMenu}
          >
            {getUnfilteredColumns().map(meta => {
              return (
                <MenuItem
                  key={meta?.fieldName}
                  onClick={() => {
                    handleAddFilter(meta);
                    closeAddRuleMenu();
                  }}
                >
                  {meta.displayName}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </Grid>
    </>
  );
};

export default FiltersList;
