import { gql } from 'src/generated/gql';

export const createAdminSecret = gql(`
  mutation createAdminSecret {
    createAdminSecret {
      id
      clientKeyId
      clientKeySecret
      createdAt
    }
  }
`);

export const deleteAdminSecret = gql(`
  mutation deleteAdminSecret($clientKeyId: String!) {
    deleteAdminSecret(clientKeyId: $clientKeyId)
  }
`);
