import { Button, ButtonBase, Typography, Link as MuiLink } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import Link from 'src/components/Link';
import { useModalState } from 'src/components/Modal';
import { FACEBOOK_URLS } from 'src/common/FacebookUtil';

import PageLinkCard from '../PageLinkCard';
import HelpModal from '../HelpModal';
import ActionsContainer from '../ActionsContainer';

interface NoFacebookPagesProps {
  hasOrgDefaultPage: boolean;
  onCancel: () => void;
}

const NoFacebookPages = ({
  hasOrgDefaultPage,
  onCancel
}: NoFacebookPagesProps) => {
  const { closeModal, openModal, open } = useModalState(false);

  const translationComponents = [
    <MuiLink
      data-amp-facebook-link-page-need-help-click
      component={ButtonBase}
      onClick={openModal}
    />,
    <Link
      sx={{
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      }}
      to={FACEBOOK_URLS.support}
      target="_blank"
    />
  ];
  return (
    <>
      <HelpModal isOpen={open} close={closeModal} />
      <PageLinkCard
        width={545}
        title={t('facebookSettings:facebookPageSelector.title')}
        actions={
          <ActionsContainer justifyContent="flex-start">
            <Button variant="contained" onClick={onCancel}>
              {t('facebookSettings:buttons.goBack')}
            </Button>
          </ActionsContainer>
        }
      >
        <Typography>
          {hasOrgDefaultPage ? (
            <Trans
              i18nKey="facebookSettings:noPagesHasOrgDefault.description"
              components={translationComponents}
            />
          ) : (
            <Trans
              i18nKey="facebookSettings:noPagesNoOrgDefault.description"
              components={translationComponents}
            />
          )}
        </Typography>
      </PageLinkCard>
    </>
  );
};

export default NoFacebookPages;
