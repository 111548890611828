import { styled } from '@mui/system';
import { Box, Skeleton } from '@mui/material';

import { getGoogleDisplayImageHeight, getPreviewWidth } from '../helpers';
import { GOOGLE_PREVIEW_WIDTH } from './GoogleAdPreviewConstants';
import { Container, ActionRow } from './GoogleDisplayAdPreviews/DisplayAdOne';

const PreviewText = styled(Skeleton)({ width: '100%', height: 8 });

const GoogleAdPreviewSkeleton = ({ hasUniformPreviewWidth, isResponsive }) => {
  const imageHeight = getGoogleDisplayImageHeight(
    hasUniformPreviewWidth,
    isResponsive
  );

  const previewWidth = getPreviewWidth(
    hasUniformPreviewWidth,
    GOOGLE_PREVIEW_WIDTH,
    isResponsive
  );

  return (
    <Container width={previewWidth}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
          m: '0 auto',
          p: theme => theme.spacing(2, 0)
        }}
      >
        <Skeleton variant="text" sx={{ width: 16, fontSize: 24 }} />
        <Skeleton variant="text" sx={{ width: 16, fontSize: 24 }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          width: '100%',
          mb: 2
        }}
      >
        <PreviewText variant="rectangular" />
        <PreviewText variant="rectangular" />
        <PreviewText variant="rectangular" />
      </Box>
      <Box
        sx={{ height: imageHeight, overflow: 'hidden', width: previewWidth }}
      >
        <Skeleton variant="rectangular" sx={{ pt: '52%', height: 0 }} />
      </Box>
      <Box
        sx={{
          alignSelf: 'flex-start',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 12px',
          mb: 3,
          mt: 1,
          width: '100%'
        }}
      >
        <Skeleton variant="text" sx={{ width: '50%', fontSize: 14 }} />
        <Skeleton variant="text" sx={{ width: '50%', fontSize: 12 }} />
      </Box>
      <ActionRow>
        <Skeleton variant="text" sx={{ fontSize: 12, width: '25%' }} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Skeleton variant="text" sx={{ width: 40, fontSize: 20 }} />
          <Skeleton variant="text" sx={{ width: 12, fontSize: 20 }} />
        </Box>
      </ActionRow>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          width: '100%',
          mt: 1
        }}
      >
        <PreviewText variant="rectangular" />
        <PreviewText variant="rectangular" />
      </Box>
    </Container>
  );
};

export default GoogleAdPreviewSkeleton;
