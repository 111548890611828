import { useState } from 'react';
import { keys, has, flatMap, words, capitalize, sortBy } from 'lodash';
import { useWatch, useFormContext } from 'react-hook-form';
import { t } from 'i18next';

import {
  GridRenderCellParams,
  DataGridPro,
  GridActionsCellItem,
  GridAlignment
} from '@mui/x-data-grid-pro';
import type { GridFilterPanelProps } from '@mui/x-data-grid/components/panel/filterPanel/GridFilterPanel';
import { SxProps, Box, Tooltip, Chip } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';

import formatters from 'src/common/formatters';
import useProgram from 'src/pages/Program/utils/useProgram';
import {
  PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME,
  scheduleTypes
} from 'src/pages/Program/Constants';

import Toolbar from 'src/components/DataTable/Toolbar/Toolbar';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import Instrumentation from 'src/instrumentation';

import LocationOverrideForm from './LocationOverrideForm';
import LocationManagementFormRowMenu from './LocationManagementFormRowMenu';
import {
  LOCATIONS_OVERRIDES_BY_ID_NAME,
  locationsFilterOperators
} from './utils';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
  '& .row-in-error': {
    backgroundColor: lighten(theme.palette.error.main, 0.8),
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.main, 0.7)
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.error.main, 0.6),
      '&:hover': {
        backgroundColor: lighten(theme.palette.error.main, 0.5)
      }
    },
    '&.Mui-hovered': {
      backgroundColor: lighten(theme.palette.error.main, 0.6),
      '&:hover': {
        backgroundColor: lighten(theme.palette.error.main, 0.5)
      }
    }
  },
  '& .row-selected': {
    backgroundColor: lighten(theme.palette.info.main, 0.9),
    '&:hover': {
      backgroundColor: lighten(theme.palette.info.main, 0.85)
    },
    '&.Mui-selected': {
      backgroundColor: lighten(theme.palette.info.main, 0.9),
      '&:hover': {
        backgroundColor: lighten(theme.palette.info.main, 0.85)
      }
    },
    '&.Mui-hovered': {
      backgroundColor: lighten(theme.palette.info.main, 0.85),
      '&:hover': {
        backgroundColor: lighten(theme.palette.info.main, 0.85)
      }
    }
  }
}));

type LocationManagementFormProps = {
  selectedLocations: string[];
  removeLocation: (id: string) => void;
  restoreToDefaults: (id: string) => void;
  showAdPreview: (id: string) => void;
  isChannelValidationLoading: boolean;
  selectedBusinessObjects: any;
  selectedLocationsMetadata: any;
};

const NUM_PER_PAGE = 10;

const LocationManagementForm = ({
  selectedLocations,
  removeLocation,
  restoreToDefaults,
  showAdPreview,
  isChannelValidationLoading,
  selectedBusinessObjects,
  selectedLocationsMetadata
}: LocationManagementFormProps) => {
  const [editLocationId, setEditLocationId] = useState<string | undefined>();

  const {
    selectedLocation,
    setSelectedLocation,
    dynamicFieldFriendlyNamesByFieldName,
    dynamicFieldDisplayMethodByFieldName,
    allMlpLocations: { data, loading, error },
    trackingData,
    creativeValidationErrors
  } = useProgram();
  const allFormValues = useWatch();
  const { formState } = useFormContext();

  const formDefaults = allFormValues[PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME];
  const overridesById = allFormValues[LOCATIONS_OVERRIDES_BY_ID_NAME] || {};
  const locationErrors = formState.errors[LOCATIONS_OVERRIDES_BY_ID_NAME];

  const dataTableColumns = [
    {
      field: 'icons',
      id: 'MLPIcon',
      headerName: '',
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      width: 50,
      renderCell: (props: GridRenderCellParams) => {
        const locationId = props?.row?.id;

        const rowInError =
          has(locationErrors, locationId) ||
          creativeValidationErrors?.[locationId];

        if (rowInError) {
          return (
            <Box
              sx={{ display: 'flex', justifyContent: 'center' }}
              data-cy="location-management-table-error-icon"
            >
              <Tooltip
                title={t('programCreate:manageMultiLocation.errorLocationTip')}
              >
                <ErrorIcon
                  data-cy="location-validation-error-icon"
                  sx={{
                    marginRight: theme => theme.spacing(2),
                    color: 'error.main'
                  }}
                />
              </Tooltip>
            </Box>
          );
        }

        const overrideIds = keys(overridesById) || [];
        const rowModified = overrideIds.includes(props?.row?.id);
        if (rowModified) {
          return (
            <Box
              sx={{ display: 'flex', justifyContent: 'center' }}
              data-cy="location-management-table-modified-icon"
            >
              <Tooltip
                title={t('programCreate:manageMultiLocation.modifiedLocation')}
              >
                <EditLocationAltIcon
                  sx={{
                    marginRight: theme => theme.spacing(2)
                  }}
                />
              </Tooltip>
            </Box>
          );
        }
      }
    },
    // hardcoded from locations data
    {
      field: 'name',
      id: 'MLPname',
      editable: false,
      headerName: t('programCreate:manageMultiLocation.manageFormName'),
      hideable: false,
      filterable: true,
      filterOperators: locationsFilterOperators,
      disableColumnMenu: true,
      minWidth: 200,
      resizable: true
    },
    {
      field: 'budget',
      id: 'budget',
      headerName: t('programCreate:manageMultiLocation.budgetTableHeader'),
      filterable: true,
      filterOperators: locationsFilterOperators,
      disableColumnMenu: true,
      minWidth: 50,
      renderCell: (props: GridRenderCellParams) => {
        let budget;

        if (
          allFormValues?.spendStep?.scheduleType ===
          scheduleTypes.purchase.value
        ) {
          budget = props?.row?.oneTimeSpend;
        } else {
          budget = props?.row?.subscriptionSpend;
        }

        return budget ? formatters.CURRENCY(budget) : '-';
      }
    },
    // all the default fields o-O
    ...keys(formDefaults).map((field: any) => {
      return {
        field,
        headerName: dynamicFieldFriendlyNamesByFieldName[field] || field,
        editable: false,
        flex: 1,
        id: field,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        minWidth: 50,
        renderCell: ({ value }: GridRenderCellParams) => {
          const inputString = value;

          const prefixLocationTagField = (_: string, p1: string) => {
            return `{{location ${p1}}}`;
          };

          // Split the input string into an array of strings and chips
          const parts = inputString
            ?.replace(
              /{{#with \(LOCATION locationId\)}}{{([^{}]+)}}{{\/with}}/g,
              prefixLocationTagField
            )
            .split(/(\{\{.*?\}\})/);

          // Map over the parts and replace placeholders with React elements
          const updatedParts = parts?.map((part: any, index: number) => {
            const match = part?.match(/\{\{(.*?)\}\}/);

            if (match) {
              const content = match[1]?.split('_'); // Extract the content within {{ }}

              // Format chip to be upper case and have spaces between words
              const formattedContent = content
                .flatMap((word: string) => {
                  return words(word, /[A-Za-z][a-z]*/g);
                })
                .map(capitalize)
                .join(' ');

              return (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={`chippy-${content}-${index}`}
                  label={formattedContent}
                  sx={{ margin: theme => theme.spacing(0.5) }}
                />
              );
            }
            return part; // Return the original string part
          });
          return updatedParts;
        }
      };
    }),
    {
      field: 'actions',
      type: 'actions',
      hideable: false,
      editable: false,
      headerName: '',
      cellClassName: 'actions',
      align: 'right' as GridAlignment,
      width: 50,
      disableColumnMenu: true,
      resizable: false,
      getActions: ({ row }: GridRenderCellParams) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              Instrumentation.logEvent(
                Instrumentation.Events.ClickMlpModifyLocation,
                {
                  ...trackingData,
                  locationId: row.id
                }
              );
              setEditLocationId(row.id);
              setSelectedLocation(row.id);
            }}
            color="inherit"
          />
        ];
      }
    },
    {
      hideable: false,
      filterable: false,
      field: 'MLPMENU',
      id: 'MLPMENU',
      headerName: '',
      editable: false,
      align: 'right' as GridAlignment,
      width: 50,
      sortable: false,
      disableColumnMenu: true,
      resizable: false,
      renderCell: (props: GridRenderCellParams) => {
        return (
          <LocationManagementFormRowMenu
            {...props}
            removeLocation={removeLocation}
            restoreToDefaults={restoreToDefaults}
            showAdPreview={showAdPreview}
          />
        );
      }
    }
  ];

  const closeModal = () => {
    // close modal
    setEditLocationId(undefined);
  };

  const rows =
    flatMap(data?.locations?.edges, edge => {
      if (edge?.node && selectedLocations.includes(edge?.node?.id)) {
        const formDefaultDynamicUserInput = allFormValues?.dynamicUserInputs;
        const formDefaultSpendStepOverrides = allFormValues?.spendStep;

        const overrideDynamicUserInput =
          overridesById[edge?.node?.id]?.dynamicUserInputs;
        const overrideSpendStep = overridesById[edge?.node?.id]?.spendStep;

        return [
          {
            ...edge?.node,
            ...formDefaultDynamicUserInput,
            ...formDefaultSpendStepOverrides,
            ...overrideDynamicUserInput,
            ...overrideSpendStep
          }
        ];
      }
      return [];
    }) || [];

  const sortedRows = sortBy(rows, row => {
    return has(locationErrors, row.id) ? 0 : 1;
  });

  const columnVisibilityModel = keys(formDefaults).reduce(
    (accum, field: string) => {
      const displayMethodId = dynamicFieldDisplayMethodByFieldName[field];
      const showField =
        displayMethodId === INPUT_TYPES.SINGLE_LINE_STRING ||
        displayMethodId === INPUT_TYPES.MULTI_LINE_STRING;
      return {
        ...accum,
        [field]: showField
      };
    },
    {}
  );

  return (
    <>
      <LocationOverrideForm
        open={!!editLocationId}
        id={editLocationId}
        closeModal={closeModal}
        isChannelValidationLoading={isChannelValidationLoading}
        selectedBusinessObjects={selectedBusinessObjects}
        selectedLocationsMetadata={selectedLocationsMetadata}
      />

      {!error && (
        <StyledDataGridPro
          sx={{
            border: 'none',
            height: '100%',
            minHeight: '100px',
            padding: '0',
            width: '100%',
            '& .MuiDataGrid-virtualScroller': { minHeight: '50px' }
          }}
          initialState={{
            pinnedColumns: { left: [], right: ['actions', 'MLPMENU'] },
            columns: { columnVisibilityModel }
          }}
          getRowClassName={params => {
            const locationId = params?.row?.id;
            const rowInError =
              has(locationErrors, locationId) ||
              creativeValidationErrors?.[locationId];
            const isSelected = selectedLocation === params?.row?.id;

            // error style takes precedence over selected style
            if (rowInError) {
              return `row-in-error`;
            }

            if (isSelected) {
              return 'row-selected';
            }

            return '';
          }}
          autosizeOnMount
          autosizeOptions={{
            columns: ['name'],
            includeHeaders: true,
            includeOutliers: true,
            expand: true
          }}
          edit={false}
          columns={dataTableColumns}
          rows={sortedRows}
          pagination
          pageSizeOptions={[NUM_PER_PAGE, 25, 50, 100]}
          loading={loading}
          disableRowSelectionOnClick
          slots={{ toolbar: Toolbar }}
          slotProps={{
            filterPanel: {
              filterFormProps: {
                'data-cy': 'filterForm',
                logicOperatorInputProps: {
                  size: 'small',
                  sx: { display: 'none' }
                },
                columnInputProps: {
                  'data-cy': 'columnInput',
                  size: 'small',
                  sx: { mt: 'auto' }
                },
                operatorInputProps: {
                  size: 'small',
                  sx: { mt: 'auto' }
                },
                valueInputProps: {
                  'data-cy': 'valueInput',
                  InputComponentProps: {
                    size: 'small'
                  }
                },
                sx: {
                  '& .MuiDataGrid-filterFormLogicOperatorInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormColumnInput': { mr: 2 },
                  '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 }
                }
              } as GridFilterPanelProps['filterFormProps'] & { sx: SxProps }
            }
          }}
        />
      )}
    </>
  );
};

export default LocationManagementForm;
