import { gql } from 'src/generated/gql';

export const authProviderIntrospection = gql(`
  query authProviderIntrospection {
    __type(name: "AuthProviderType") {
      name
      enumValues {
        name
      }
    }
  }
`);

export const getIndustries = gql(`
  query getIndustriesForCreateOrganization {
    getIndustries {
      name
      slug
    }
  }
`);
