import { get, head } from 'lodash';
import { Trans } from 'react-i18next';

import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AssignmentIcon from '@mui/icons-material/Assignment';

import { translateMaps } from 'src/common/templateTranslator';
import { formatBlueprintsForIcon } from 'src/common/blueprints';

import BlueprintDisplayListItem from 'src/components/BlueprintSelector/BlueprintDisplayListItem';
import { BusinessObjectBulkListItem } from 'src/components/BusinessObjectBulkSelector';

const styles = theme => ({
  businessObjectsContainer: {
    margin: `${theme.spacing(1)} 0`,
    maxHeight: '200px',
    overflow: 'scroll',
    '& li': {
      paddingLeft: '0'
    }
  }
});

const LeadOrderDetails = ({
  architecture,
  classes,
  order,
  isDynamicAdCreative,
  contentFriendlyName,
  isContentSelectable
}) => {
  const displayImageTemplate = get(
    architecture,
    'data.catalog.displayImageTemplate'
  );
  const displayNameTemplate = get(
    architecture,
    'data.catalog.displayNameTemplate'
  );
  const contentNameLowercase = contentFriendlyName
    ? contentFriendlyName.toLowerCase()
    : 'content';

  let contentComponent = null;
  if (isDynamicAdCreative || !isContentSelectable) {
    contentComponent = (
      <BusinessObjectBulkListItem contentName={contentNameLowercase} />
    );
  } else {
    const catalogItems = get(order, 'orderItem.catalogItems.edges', []);

    contentComponent = (
      <List className={classes.businessObjectsContainer}>
        {catalogItems.map(catalogItem => {
          const businessObject = get(catalogItem, 'node.items[0]');
          const id = get(businessObject, 'id');
          const imageUrl = translateMaps(
            displayImageTemplate,
            get(businessObject, 'fields')
          );
          const title = translateMaps(
            displayNameTemplate,
            get(businessObject, 'fields')
          );

          return (
            <ListItem key={id}>
              <ListItemAvatar>
                <Avatar>
                  {imageUrl ? (
                    <img className={classes.image} src={imageUrl} alt={title} />
                  ) : (
                    <AssignmentIcon />
                  )}
                </Avatar>
              </ListItemAvatar>
              {title && <ListItemText primary={title} />}
            </ListItem>
          );
        })}
      </List>
    );
  }

  // Note: formatBlueprintsForIcon expects an array of blueprints
  const blueprintItem = head(
    formatBlueprintsForIcon([get(order, 'orderItem.product')])
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <strong>
          <Trans i18nKey="lead:orderDetails.blueprint">Blueprint</Trans>
        </strong>
        <BlueprintDisplayListItem
          blueprint={blueprintItem}
          container="div"
          disableGutters
          hasChannels
          key="programDetialsBlueprint"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <strong>{contentFriendlyName}</strong>
        {contentComponent}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(LeadOrderDetails);
