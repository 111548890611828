import { gql } from 'src/generated/gql';

export const getBillingConfig = gql(`
  query getBillingConfig {
    myOrganization {
      id
      billingConfiguration {
        stripePublicKey
        cancellationFeePercentage
      }
    }
  }
`);

export const getPrePayAccountsByOrganizationId = gql(`
  query getPrePayAccountsByOrganizationId($organizationId: ID!) {
    getPrePayAccountsByOrganizationId(organizationId: $organizationId) {
      id
      organizationId
      effectiveFrom
      effectiveUntil
      initialAmount
      balanceAmount
      createdAt
      deletedAt
    }
  }
`);
