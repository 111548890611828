import { LicenseInfo } from '@mui/x-license-pro';

import Logger from 'src/common/Logger';
import SentryUtil from 'src/common/SentryUtil';
import { facebookAuthInterceptor } from './Auth';

const setMuiProLicenseKey = () => {
  try {
    if (import.meta.env.EVOCALIZE_MUI_PRO_LICENSE_KEY) {
      LicenseInfo.setLicenseKey(import.meta.env.EVOCALIZE_MUI_PRO_LICENSE_KEY);
    } else {
      Logger.warn('Did not find Material-UI Pro license key.');
    }
  } catch (error) {
    Logger.error('Failed to set Material-UI Pro license key.');
  }
};

const configure = () => {
  // Note: this must be called before any other code is executed.
  const isFacebookBlocking = facebookAuthInterceptor();

  // Setup client-side error reporting.
  SentryUtil.install();

  // Set the license key for material-ui pro.
  setMuiProLicenseKey();
  return isFacebookBlocking;
};

export default configure;
