import { Trans } from 'react-i18next';

import { Paper, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import PageTitle from 'src/components/PageTitle';

import CloudinaryTemplateBuilder from './CloudinaryTemplateBuilder';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  pageHeading: {
    marginBottom: theme.spacing(2)
  }
});

const CloudinaryLanding = props => {
  const { classes } = props;

  return (
    <div>
      <PageTitle subPageTitle="Admin Image Template Builder" />
      <Typography variant="h5" className={classes.pageHeading}>
        <Trans i18nKey="admin:headings.imageTemplateBuilder">
          Image Template Builder
        </Trans>
      </Typography>
      <Paper className={classes.paper}>
        <CloudinaryTemplateBuilder />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(CloudinaryLanding);
