import Accessibility from '@mui/icons-material/AccessibilityNew';
import Add from '@mui/icons-material/Add';
import Apartment from '@mui/icons-material/Apartment';
import Brightness from '@mui/icons-material/Brightness2';
import Cached from '@mui/icons-material/Cached';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Construction from '@mui/icons-material/Construction';
import Create from '@mui/icons-material/Create';
import DeliveryDining from '@mui/icons-material/DeliveryDining';
import Email from '@mui/icons-material/Email';
import FitnessCenter from '@mui/icons-material/FitnessCenter';
import Home from '@mui/icons-material/Home';
import LocalOffer from '@mui/icons-material/LocalOffer';
import LunchDining from '@mui/icons-material/LunchDining';
import Map from '@mui/icons-material/Map';
import Nightlife from '@mui/icons-material/Nightlife';
import NotificationsActive from '@mui/icons-material/NotificationsActive';
import PanTool from '@mui/icons-material/PanTool';
import Payment from '@mui/icons-material/Payment';
import Place from '@mui/icons-material/Place';
import Restaurant from '@mui/icons-material/Restaurant';
import Save from '@mui/icons-material/Save';
import Send from '@mui/icons-material/Send';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import Star from '@mui/icons-material/Star';

export const supportedIcons = {
  Accessibility,
  Add,
  Apartment,
  Brightness,
  Cached,
  'Calendar Month': CalendarMonth,
  Construction,
  Create,
  'Delivery Dining': DeliveryDining,
  Email,
  'Fitness Center': FitnessCenter,
  Home,
  'Local Offer': LocalOffer,
  'Lunch Dining': LunchDining,
  Map,
  Nightlife,
  'Notifications Active': NotificationsActive,
  'Pan Tool': PanTool,
  Payment,
  Place,
  Restaurant,
  Save,
  Send,
  'Shopping Cart': ShoppingCart,
  Star
};

export type IconType = keyof typeof supportedIcons;

interface IconProps {
  icon: IconType;
}
const IconComponent = ({ icon }: IconProps) => {
  const Icon = icon && supportedIcons?.[icon];

  return Icon ? <Icon key={icon} fontSize="large" sx={{ px: 1 }} /> : null;
};

export default IconComponent;
