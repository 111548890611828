import { gql } from 'src/generated/gql';

export const createOrUpdateAdminContentSetItemBulk = gql(`
  mutation CreateOrUpdateAdminContentSetItemBulk(
    $contentSetSlug: String!
    $items: [AdminContentSetItemInput!]!
  ) {
    createOrUpdateAdminContentSetItemBulk(
      contentSetSlug: $contentSetSlug
      items: $items
    ) {
      reportId
    }
  }
`);
