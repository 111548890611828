import { FormHelperText, FormHelperTextOwnProps } from '@mui/material';

interface ErrorFooterProps {
  touched: boolean;
  error: string;
  variant?: FormHelperTextOwnProps['variant'];
}
/**
 * Renders the error footer for redux form components in a consistent way.
 */
export const ErrorFooter = ({ touched, error, variant }: ErrorFooterProps) => {
  const inputInError = error && touched;

  if (!inputInError) {
    return null;
  }

  return (
    <FormHelperText sx={{ marginLeft: '14px' }} error variant={variant}>
      {error}
    </FormHelperText>
  );
};
