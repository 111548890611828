import { Button, Typography, Link as MuiLink, ButtonBase } from '@mui/material';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import Link from 'src/components/Link';
import { useModalState } from 'src/components/Modal';
import { FACEBOOK_URLS } from 'src/common/FacebookUtil';

import PageLinkCard from '../PageLinkCard';
import HelpModal from '../HelpModal';
import ActionsContainer from '../ActionsContainer';

interface UnknownErrorProps {
  onConfirm: () => void;
  onCancel: () => void;
  pageName: string;
}

const UnknownError = ({ onConfirm, onCancel, pageName }: UnknownErrorProps) => {
  const { closeModal, openModal, open } = useModalState(false);

  return (
    <>
      <HelpModal isOpen={open} close={closeModal} />
      <PageLinkCard
        title={t('facebookSettings:selectedFacebookPage.title', { pageName })}
        actions={
          <ActionsContainer justifyContent="center">
            <Button variant="text" onClick={onCancel}>
              {t('facebookSettings:buttons.cancel')}
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              {t('facebookSettings:buttons.selectAnotherPage')}
            </Button>
          </ActionsContainer>
        }
      >
        <Typography sx={{ color: 'error.main' }}>
          <Trans
            i18nKey="facebookSettings:unknownError.description"
            components={[
              <MuiLink
                data-amp-facebook-link-page-need-help-click
                component={ButtonBase}
                onClick={openModal}
              />,
              <Link
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                to={FACEBOOK_URLS.support}
                target="_blank"
              />
            ]}
          />
        </Typography>
      </PageLinkCard>
    </>
  );
};

export default UnknownError;
