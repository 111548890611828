import { useMemo } from 'react';
import { flow } from 'lodash';
import { Link } from 'react-router-dom';

import {
  Typography,
  Card,
  CardContent,
  Button,
  Tooltip,
  Divider
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import withTheme from '@mui/styles/withTheme';
import EditIcon from '@mui/icons-material/Edit';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { statusTextMap, getStatusColorMap } from '../Constants';

const styles = theme => ({
  card: {
    marginRight: theme.spacing(2),
    width: '275px',
    position: 'relative',
    minHeight: '275px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&:last-child': {
      marginRight: '0px'
    }
  },
  cardHeading: {
    fontSize: '18px',
    lineHeight: '25px',
    marginTop: theme.spacing(2)
  },
  cardProductId: {
    fontSize: '14px',
    marginTop: theme.spacing(1)
  },
  cardDescription: {
    fontSize: '14px',
    marginTop: theme.spacing(1)
  },
  editButton: {
    width: '100%'
  },
  statusDot: {
    height: '10px',
    width: '10px',
    borderRadius: '100%',
    background: theme.palette.error.main,
    marginLeft: '10px',
    display: 'block',
    position: 'absolute',
    right: '10px',
    top: '10px'
  }
});

const GridViewCard = props => {
  const { classes, product, theme } = props;

  const statusColorMap = useMemo(() => getStatusColorMap(theme), []);

  return (
    <Card
      key={product.productId}
      className={classes.card}
      data-cy="grid-view-card"
    >
      <Tooltip
        title={
          statusTextMap[product.status]
            ? statusTextMap[product.status]
            : 'Unknown Status'
        }
      >
        <span
          className={classes.statusDot}
          style={{
            background: statusColorMap[product.status]
          }}
        />
      </Tooltip>
      <CardContent>
        <Typography component="h2" className={classes.cardHeading}>
          {product.document.name}
        </Typography>
        <Typography className={classes.cardProductId} color="textSecondary">
          {product.productId}
        </Typography>
        <Typography className={classes.cardDescription} component="p">
          {product.document.description}
        </Typography>
      </CardContent>
      <div>
        <Divider />
        <Button
          component={Link}
          to={generateLinkPath(paths.admin.blueprintBuilder, {
            productId: product.productId
          })}
          className={classes.editButton}
        >
          <EditIcon />
        </Button>
      </div>
    </Card>
  );
};

export default flow(withStyles(styles), withTheme)(GridViewCard);
