export const networkStatusNames = {
  loading: 'LOADING',
  setVariables: 'SET_VARIABLES',
  fetchMore: 'FETCH_MORE',
  refetch: 'REFETCH',
  poll: 'POLL',
  ready: 'READY',
  error: 'ERROR'
} as const;

// Note These were pulled directly from apollo
// https://github.com/apollographql/apollo-client/blob/master/packages/apollo-client/src/core/networkStatus.ts
export const statusCodes = {
  // The query has never been run before and the query is now currently running. A query will still
  // have this network status even if a partial data result was returned from the cache, but a
  // query was dispatched anyway.
  1: networkStatusNames.loading,

  // If `setVariables` was called and a query was fired because of that then the network status
  // will be `setVariables` until the result of that query comes back.
  2: networkStatusNames.setVariables,

  // Indicates that `fetchMore` was called on this query and that the query created is currently in
  // flight.
  3: networkStatusNames.fetchMore,

  // Similar to the `setVariables` network status. It means that `refetch` was called on a query
  // and the refetch request is currently in flight.
  4: networkStatusNames.refetch,

  // Indicates that a polling query is currently in flight. So for example if you are polling a
  // query every 10 seconds then the network status will switch to `poll` every 10 seconds whenever
  // a poll request has been sent but not resolved.
  6: networkStatusNames.poll,

  // No request is in flight for this query, and no errors happened. Everything is OK.
  7: networkStatusNames.ready,

  // No request is in flight for this query, but one or more errors were detected.
  8: networkStatusNames.error
} as const;

export const isApolloLoading = (networkStatus: number) => {
  // if ready or error the request has finished so we are not loading any more
  return !(
    (statusCodes as Record<number, string>)[networkStatus] ===
      networkStatusNames.ready ||
    (statusCodes as Record<number, string>)[networkStatus] ===
      networkStatusNames.error
  );
};
