import withTheme from '@mui/styles/withTheme';

const AutomatedProgramsSelectBlueprintIcon = props => {
  const { active, completed, theme } = props;

  const primary =
    active || completed ? theme.palette.primary.main : theme.palette.grey[300];
  const outline =
    active || completed ? theme.palette.grey[900] : theme.palette.grey[300];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 50 23"
      width="150"
    >
      <defs />
      <defs>
        <path id="a" d="M0 23h49.769V.041H0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M25.836 22.653h23.627V.306H25.836z" />
        <path
          fill={outline}
          d="M25.531 22.959h24.238V-.001H25.531v22.96zm.611-.611h23.016V.61H26.142v21.737z"
        />
        <path
          fill={primary}
          d="M27.393 16.675h20.43V5.881h-20.43zM27.393 5.274h3.125V2.149h-3.125z"
        />
        <path
          fill="#FFF"
          d="M35.555 11.338h.671l-.338-1.004-.333 1.004zm.82.448h-.969l-.184.553h-.589l1-2.684h.512l1.005 2.684h-.589l-.186-.553z"
        />
        <g transform="translate(0 -.041)">
          <path
            fill="#FFF"
            d="M37.953 10.144v1.792h.268c.216 0 .381-.07.495-.212.115-.142.174-.344.175-.607v-.142c0-.272-.056-.479-.169-.62-.113-.14-.278-.21-.496-.21h-.273zM37.4 12.38V9.696h.826c.236 0 .447.053.633.16.187.106.331.257.437.453.103.196.156.418.156.668v.124c0 .25-.05.471-.154.665a1.1 1.1 0 01-.434.452 1.273 1.273 0 01-.633.162h-.83z"
          />
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill={primary}
            d="M27.393 18.578h3.91v-.966h-3.91z"
            // mask="url(#b)"
          />
          <path
            fill={outline}
            d="M31.68 18.578h12.505v-.966H31.68z"
            // mask="url(#b)"
          />
          <path
            fill={primary}
            d="M27.393 20.12h3.91v-.966h-3.91zM31.303 3.464h16.52v-.966h-16.52zM31.302 5.006h5.125V4.04h-5.125z"
            // mask="url(#b)"
          />
          <path
            fill={outline}
            d="M31.68 20.12h4.884v-.966H31.68z"
            // mask="url(#b)"
          />
          <path
            fill={primary}
            d="M37.003 20.12h10.82v-.966h-10.82z"
            // mask="url(#b)"
          />
          <path
            fill={outline}
            d="M44.813 18.578h3.01v-.966h-3.01z"
            // mask="url(#b)"
          />
          <path fill="#FFF" d="M.304 22.66h23.555V.381H.304z" mask="url(#b)" />
          <path
            fill={outline}
            d="M.609 22.356h22.945V.686H.609v21.67zM0 22.965h24.164V.076H0v22.889z"
            // mask="url(#b)"
          />
          <path
            fill={primary}
            d="M1.856 14.504h20.368V2.402H1.856z"
            // mask="url(#b)"
          />
          <path
            fill="#FFF"
            d="M10.751 8.419h.669l-.336-1.001-.333 1.001zm.818.447h-.967l-.184.551h-.586l.996-2.676h.511l1.002 2.676h-.587l-.185-.551zM13.142 7.188v1.786h.267c.215 0 .38-.07.494-.21.114-.142.173-.344.175-.606v-.14c0-.273-.057-.479-.17-.62-.113-.14-.277-.21-.494-.21h-.272zm-.551 2.23V6.74h.823c.236 0 .446.053.63.16.187.105.332.256.436.451a1.4 1.4 0 01.156.667v.123c0 .248-.051.47-.153.663a1.098 1.098 0 01-.433.45 1.253 1.253 0 01-.631.162h-.828z"
            mask="url(#b)"
          />
          <path
            fill={primary}
            d="M1.856 18.674h3.375v-3.376H1.856zM5.945 16.87H9.06v-.963H5.945z"
            // mask="url(#b)"
          />
          <path
            fill={outline}
            d="M9.361 16.87h9.964v-.963H9.361z"
            mask="url(#b)"
          />
          <path
            fill={primary}
            d="M5.945 18.407H9.06v-.963H5.945z"
            // mask="url(#b)"
          />
          <path
            fill={outline}
            d="M9.36 18.407h3.892v-.963H9.36z"
            // mask="url(#b)"
          />
          <path
            fill={primary}
            d="M13.602 18.407h8.622v-.963h-8.622z"
            // mask="url(#b)"
          />
          <path
            fill={outline}
            d="M19.826 16.87h2.398v-.963h-2.398zM21.431 21.126l-.1-.115.777-.678-.777-.678.1-.114.909.792z"
            // mask="url(#b)"
          />
        </g>
      </g>
    </svg>
  );
};

export default withTheme(AutomatedProgramsSelectBlueprintIcon);
