import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { isEmpty, debounce } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeAugmentations } from 'src/AppThemeWrapper';
import { Theme } from '@mui/system';
import { ArchitectureType } from 'src/pages/Architecture/ArchitectureProvider';
import ContentCount from './ContentCount';
import ProgramFilterSelector from './ProgramFilterSelector';
import { AutomatedProgramFilterType } from './constants';
import {
  trimFilterRuleValues,
  convertFiltersArrayToJSON,
  ContentSetFieldMetadata
} from './helpers';

const useStyles = makeStyles((theme: Theme & ThemeAugmentations) => ({
  container: {},
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
    textAlign: 'center'
  },
  subHeading: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: theme.spacing(2)
  },
  subHeadingTwo: {
    fontSize: '18px',
    textAlign: 'center',
    margin: `${theme.spacing(7)} 0 ${theme.spacing(2)} 0`
  },
  textContainer: {
    margin: theme.spacing(2),
    textAlign: 'center'
  },
  title: {
    alignItems: 'center',
    display: 'flex'
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  testRulesTableHeader: {
    marginTop: theme.spacing(3)
  }
}));

type AutomatedProgramFilterProps = {
  architecture: ArchitectureType;
  contentNameLowerCase: string;
  contentMeta: ContentSetFieldMetadata[];
  formNamespace: string;
};

const formatTableFilters = (filters: AutomatedProgramFilterType[]) => {
  const filtersWithValue = filters.reduce<AutomatedProgramFilterType[]>(
    (accum, filter) => {
      accum.push(trimFilterRuleValues(filter));
      return accum;
    },
    []
  );
  if (isEmpty(filtersWithValue)) {
    return {};
  }
  return convertFiltersArrayToJSON(filtersWithValue);
};

const handleTestRules = debounce(fn => fn(), 1000);

const AutomatedProgramFilter = (props: AutomatedProgramFilterProps) => {
  const { architecture, contentNameLowerCase, contentMeta, formNamespace } =
    props;
  const classes = useStyles();

  const filters = useWatch({
    name: `${formNamespace}.filters`,
    defaultValue: []
  });

  const currentFilters = formatTableFilters(filters);
  const hasFilters = !isEmpty(filters);

  const [tableFilters, setTableFilters] = useState(currentFilters);
  const [showContent, toggleShowContent] = useState(false);

  const testRules = () => {
    setTableFilters(currentFilters);
  };

  // Check filters to see if we need to re-query the table
  if (JSON.stringify(tableFilters) !== JSON.stringify(currentFilters)) {
    handleTestRules(testRules);
  }

  return (
    <div className={classes.container} data-cy="filter-step">
      {hasFilters ? (
        <Typography variant="h3" className={classes.heading}>
          {t('automatedProgramFilter:Rules.body', {
            contentNameLowerCase
          })}
        </Typography>
      ) : (
        <>
          <Typography variant="h3" className={classes.heading}>
            {t('automatedProgramFilter:Rules.header', {
              contentNameLowerCase
            })}
          </Typography>

          <Typography variant="h4" className={classes.subHeading}>
            <Trans i18nKey="automatedProgramFilter:RulesEmpty.body">
              Here are some suggestions based on your data:
            </Trans>
          </Typography>
        </>
      )}
      <div data-amp-program-automation-wizard-filter-selector-click>
        <ProgramFilterSelector
          contentMeta={contentMeta}
          formNamespace={formNamespace}
        />
      </div>

      {!hasFilters && (
        <div>
          <Typography variant="h4" className={classes.subHeadingTwo}>
            {t('automatedProgramFilter:rulesEmpty.autoGenerateAll', {
              contentNameLowerCase
            })}
          </Typography>
        </div>
      )}

      {hasFilters && (
        <>
          <ContentCount
            architectureId={architecture?.id}
            tableFilters={tableFilters}
            displayCollapseKey={
              architecture?.catalog?.displayCollapseKey ?? 'id'
            }
            toggleShowContent={toggleShowContent}
            showContent={showContent}
            contentNameLowerCase={contentNameLowerCase}
          />
        </>
      )}
    </div>
  );
};

export default AutomatedProgramFilter;
