/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AudienceAsset, Gallery } from 'src/generated/gql/graphql';

interface AudienceEdge {
  node?: AudienceAsset | null;
}

export const extractAudienceRowsFromGallery = (gallery: Gallery) => {
  const audiences: AudienceEdge[] = (gallery as any)?.assets?.edges || [];

  return audiences.map(audience => ({
    id: audience?.node?.id,

    // User-provided name when they upload the audience.
    name: audience?.node?.name,

    // This will be the ID of the Facebook Audience in FB-land.
    value: audience?.node?.value,

    // Note: not 100% sure this will be available when we create the
    //       audience - this is a nice to have.
    potentialSize: audience?.node?.potentialSize,

    // completed, pending, error
    // TS migration note: Is this a bug? Shouldn't this be audience?.node?.status?
    status: audience?.node,

    // For right now, it will likely only be "email_list" - later it could
    // be some other type.
    type: audience?.node?.type,

    // 'private_' or 'public_' is how this is returned by the API.
    scope: audience?.node?.scope,

    // Note: The API cannot return this yet - needs code change on their end
    createdAt: audience?.node?.createdAt
    // createdAt: '2019-03-01 08:03:51-07'
  }));
};

/* 
  If you want to learn more about this function: 
  https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
*/

export const hashStringSHA256 = async (text: string) => {
  // Encode as UTF-8 (Uint8Array)
  const textBuffer = new TextEncoder().encode(text);

  // Hash using SHA-256 algorithm
  const hashBuffer = await crypto.subtle.digest('SHA-256', textBuffer);

  // convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // Convert bytes to hex string
  const hashHex = hashArray
    .map(byte => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
};
