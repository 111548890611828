import { Trans } from 'react-i18next';

const ClipboardButtonText = props => {
  const { isCopied, label, copiedLabel } = props;

  const copiedTextLabel = copiedLabel || (
    <Trans i18nKey="copyToClipBoardButton.copied" />
  );
  const copyTextLabel = label || <Trans i18nKey="copyToClipBoardButton.copy" />;

  return isCopied ? copiedTextLabel : copyTextLabel;
};

export default ClipboardButtonText;
