import { useQuery } from '@apollo/client';

import { useGlobalContext } from 'src/GlobalContextProvider';

import { getOrder, getTeamsOrder } from './queries';

interface UseGetOrderArgs {
  orderId: string;
}

const useGetOrder = ({ orderId }: UseGetOrderArgs) => {
  const globalContext = useGlobalContext();

  const isTeamsEnabled = globalContext?.office?.isTeamsEnabled;

  const variables = { orderId, first: 30, after: null };

  const groupOrder = useQuery(getOrder, { variables, skip: isTeamsEnabled });
  const teamsOrder = useQuery(getTeamsOrder, {
    variables,
    skip: !isTeamsEnabled
  });

  const orderResult = isTeamsEnabled ? teamsOrder : groupOrder;

  return { orderResult };
};

export default useGetOrder;
