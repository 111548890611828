import { gql } from 'src/generated/gql';

export const getArchitecturesAndCatalogs = gql(`
  query getArchitecturesAndCatalogs {
    myOrganization {
      id
      slug
      architectures(showInactive: true, types: [standard, organization]) {
        id
        name
        isContentSelectable
        isActive
        hasSupervisableOffer
        catalogs(showInactive: true) {
          id
          contentSetId
          displayImageTemplate
          displayNameTemplate
          friendlyName
          defaultGroupKey
          contentType
          displayCollapseKey
          displayCollapseKeyFriendlyName
          isActive
          catalogImplicitFilters {
            id
            catalogId
            parentId
            filterTemplate
            userRole
          }
          contentSet {
            id
            name
            description
            parameters
            isAccessTableControlled
            slug
            organizationSlug
            isWritable
          }
          fieldMetadata {
            id
            displayMethodId
            displayMethod {
              name
              id
            }
            displayParameters
            displaySortOrder
            fieldName
            displayName
            validationRules
            isRequired
            isEditable
            isHidden
            includeInLeadNotifications
            filterColumn
            contentColumnType
            isFilterable
            enumValues
          }
        }
      }
    }
  }
`);

export const getArchitectureAndCatalog = gql(`
  query getArchitectureAndCatalog($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      isActive
      hasSupervisableOffer
      catalogs(showInactive: true) {
        id
        contentSetId
        displayImageTemplate
        displayNameTemplate
        friendlyName
        defaultGroupKey
        contentType
        displayCollapseKey
        displayCollapseKeyFriendlyName
        isActive
        catalogImplicitFilters {
          id
          catalogId
          parentId
          filterTemplate
          userRole
        }
        contentSet {
          id
          name
          description
          parameters
          isAccessTableControlled
          slug
          organizationSlug
          isWritable
        }
        fieldMetadata {
          id
          displayMethodId
          displayMethod {
            name
            id
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          includeInLeadNotifications
          filterColumn
          contentColumnType
          isFilterable
          enumValues
        }
      }
    }
  }
`);

export const getContentSetFieldDisplayMethods = gql(`
  query contentSetFieldDisplayMethods {
    contentSetFieldDisplayMethods {
      id
      name
    }
  }
`);

export const fetchFieldDataFromContentTable = gql(`
  query fetchFieldDataFromContentTable($catalogId: Long!) {
    fetchFieldDataFromContentTable(catalogId: $catalogId) {
      fieldName
      type
      fieldMetadataId
      contentSetId
    }
  }
`);

export const getCatalogContentTypes = gql(`
  query catalogContentTypes {
    catalogContentTypes {
      id
      name
    }
  }
`);

export const getContentTableNames = gql(`
  query contentTableMetadata {
    contentTableMetadata {
      contentTableNames
    }
  }
`);

export const getContentTableColumns = gql(`
  query getContentTableColumns($tableName: String!) {
    contentTableMetadata {
      contentTableColumns(tableName: $tableName)
    }
  }
`);

export const userRoleEnumIntrospection = gql(`
  query userRoleEnumIntrospection {
    __type(name: "UserRole") {
      name
      enumValues {
        name
      }
    }
  }
`);
