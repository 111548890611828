import PromoCode from 'src/components/PromoCode';

const RenderPromoCode = props => {
  const {
    children,
    input: { value, ...inputRest },
    meta: { touched, error },
    ...rest
  } = props;

  return (
    <PromoCode
      error={touched && !!error}
      {...rest}
      {...inputRest}
      currentPromoCodes={value}
      helperText={touched && error}
    />
  );
};

export default RenderPromoCode;
