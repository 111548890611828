import { gql } from 'src/generated/gql';

export const associateFacebookPageV2 = gql(`
  mutation AssociateFacebookPageV2(
    $pageId: String!
    $pageToken: String!
    $pageName: String!
    $facebookUserId: String!
    $facebookDisplayName: String!
  ) {
    associateFacebookPageV2(
      pageId: $pageId
      pageToken: $pageToken
      pageName: $pageName
      facebookUserId: $facebookUserId
      facebookDisplayName: $facebookDisplayName
    ) {
      id
      pageId
      instagramId
      hasAcceptedTos
      pageStatus
    }
  }
`);

export const disassociateFacebookPage = gql(`
  mutation disassociateFacebookPage($facebookPageSettingsId: ID!) {
    disassociateFacebookPage(facebookPageSettingsId: $facebookPageSettingsId)
  }
`);

export const setDefaultFacebookPage = gql(`
  mutation updateUserSettingsFromFacebookLink(
    $updateSettings: JSON!
    $updateType: SettingsUpdateType
  ) {
    modifyUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);
