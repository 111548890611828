import { gql } from 'src/generated/gql';

export const getEmailIdentity = gql(`
  query fetchEmailIdentity($organizationId: ID!) {
    fetchEmailIdentity(organizationId: $organizationId) {
      id
      domain
      mailFromSubdomain
      status
      mailFromAddress
      senderName
      dnsEntries {
        name
        type
        value
        isComplete
      }
    }
  }
`);
