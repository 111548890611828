import {
  isFunction,
  isString,
  get,
  transform,
  isEqual,
  isArray,
  isObject,
  isEmpty,
  xorWith
} from 'lodash';

export const getByKeyOrFunc = (
  object: any,
  getter: string | ((obj: any) => unknown)
): unknown => (isFunction(getter) ? getter(object) : get(object, getter));

interface KeyByParamsObject {
  key?: string;
  keyBy?: string | ((obj: any) => unknown);
  id?: string;
}

export const keyByParams = (
  obj: KeyByParamsObject,
  accessor?: string | null
) => {
  const { key, keyBy, id } = obj;
  if (key) {
    return key;
  }
  if (keyBy) {
    return getByKeyOrFunc(obj, keyBy);
  }
  if (id) {
    return id;
  }
  if (accessor && isString(accessor)) {
    return accessor;
  }

  if (import.meta.env.EVOCALIZE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(
      'Hey Buckaroo: You\'re missing one of "id" "key" or "keyBy()" on your table Row or columnSchema objects'
    );
  }
  return Math.random().toString(36).substr(2, 16);
};

/**
 * Input:
 *   {
 *       foo: {
 *           bar: {
 *               baz: 'whammy'
 *           }
 *       },
 *       beep: {
 *           boop: 'boom'
 *       }
 *   }
 *
 * Output:
 *   ['foo.bar.baz', 'beep.boop']
 *
 * By default, includes arrays, but you can pass in 'ignoreArrays' to override
 * this behavior and exclude arrays.
 * @param obj - The object to get the keys from
 * @param ignoreArrays - Whether to ignore arrays or
 * not. Defaults to including arrays.
 */
export const getObjectKeysAsPath = (
  obj: Record<string, unknown>,
  ignoreArrays?: 'ignoreArrays'
): string[] => {
  // Create an inner helper func so that we can use recursion and have a prefix
  const privateGetObjectKeysAsPath = (
    innerObj: Record<string, unknown>,
    prefix: string
  ): string[] =>
    Object.keys(innerObj).reduce<string[]>((accumulator, current) => {
      if (Array.isArray(innerObj[current])) {
        if (ignoreArrays === 'ignoreArrays') {
          return accumulator;
        }
        return [...accumulator, prefix + current];
      }
      if (typeof innerObj[current] === 'object' && innerObj[current] !== null) {
        return [
          ...accumulator,
          ...privateGetObjectKeysAsPath(
            innerObj[current] as Record<string, unknown>,
            `${prefix}${current}.`
          )
        ];
      }
      return [...accumulator, prefix + current];
    }, []);

  return privateGetObjectKeysAsPath(obj, '');
};

/**
 * Flattens an object such that
 * ```
 * {
 *   foo: {
 *    bar: {
 *      baz: 'whammy'
 *    }
 *   }
 * }
 * ```
 * would turn into
 * ```
 * {
 *  'foo.bar.baz': 'whammy'
 * }
 * ```
 */
export const flattenObject = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  const flattened = {} as Record<string, unknown>;
  // Get all our keys in an array
  getObjectKeysAsPath(obj).forEach(key => {
    // Then for each of those keys, get the value and add it
    flattened[key] = get(obj, key);
  });
  return flattened;
};

export const diffTwoObjects = (origObj: object, newObj: object) => {
  const changes = (newObj: object, origObj: object) => {
    let arrayIndexCounter = 0;
    return transform(newObj, (result, value, key) => {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        // eslint-disable-next-line no-param-reassign
        (result as any)[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  };
  return changes(newObj, origObj);
};

export const isArrayEqual = (x: Array<unknown>, y: Array<unknown>) =>
  isEmpty(xorWith(x, y, isEqual));

// Recursive function to deeply omit empty strings key values from object
// Example: { x: { a: '', b: 'c' }, y: 'b'} = { x: { b: 'c' }, y: 'y'}
export const deepOmitEmpty = (
  obj: Record<string, unknown>
): Record<string, unknown> => {
  if (obj && !isArray(obj) && isObject(obj)) {
    return Object.entries(obj)
      .filter(([, value]) => ![''].includes(value as string))
      .reduce(
        (r, [key, value]) => ({ ...r, [key]: deepOmitEmpty(value as any) }),
        {}
      );
  }

  return obj;
};
