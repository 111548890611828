import { useContext } from 'react';

import {
  ThemeSwitcherContext,
  THEME_NAMES
} from 'src/context/ThemeSwitcherContext';

export const useSwitchTheme = () => {
  const setThemeName = useContext(ThemeSwitcherContext);

  return { setThemeName, THEME_NAMES };
};
