import {
  validateIsNumber,
  validateNotBlank,
  validateRequired,
  validateSnakeCase
} from 'src/common/validations';
import { isObject, isArray } from 'lodash';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const FORM_STATES = {
  edit: 'edit',
  new: 'new',
  closed: 'closed'
};

export const validateJsonObject = value => {
  const message = 'invalid json object';
  if (isObject(value) || isArray(value)) {
    try {
      JSON.stringify(value);
    } catch (error) {
      return message;
    }
  } else {
    return message;
  }
};

export const getFieldMetaFields = options => {
  const {
    enums: { contentTableColumns, displayMethodIds } = {},
    formState,
    numExistingColumns
  } = options;
  const isEdit = formState === FORM_STATES.edit;
  return [
    {
      name: 'fieldName',
      displayName: 'Field Name',
      displayMethodId: isEdit
        ? INPUT_TYPES.SINGLE_LINE_STRING
        : INPUT_TYPES.SINGLE_SELECT,
      initialValue: undefined,
      reduxValidations: [
        validateRequired,
        validateNotBlank(),
        validateSnakeCase()
      ],
      isRequired: true,
      readOnly: isEdit,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          ...(!isEdit && { options: contentTableColumns || [] })
        }
      }
    },
    {
      name: 'isRequired',
      displayName: 'isRequired',
      displayMethodId: INPUT_TYPES.BOOLEAN,
      initialValue: false,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'displayMethodId',
      displayName: 'displayMethodId',
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          options: displayMethodIds || []
        }
      }
    },
    {
      name: 'displaySortOrder',
      displayName: 'displaySortOrder',
      displayMethodId: INPUT_TYPES.POSITIVE_INT,
      initialValue:
        numExistingColumns != null ? numExistingColumns * 100 : undefined,
      reduxValidations: [validateIsNumber],
      isRequired: true,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'displayName',
      displayName: 'Display Name',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [validateRequired, validateNotBlank()],
      isRequired: true,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'isHidden',
      displayName: 'isHidden',
      displayMethodId: INPUT_TYPES.BOOLEAN,
      initialValue: false,
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'includeInLeadNotifications',
      displayName: 'Include In Lead Notifications',
      displayMethodId: INPUT_TYPES.BOOLEAN,
      initialValue: false,
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'filterColumn',
      displayName: 'filterColumn',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'enumValues',
      displayName: 'Enumerable Value',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isHidden: false,
      isMultiInput: true,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
    // we don't use this
    // validationRules: [String!]
  ];
};

export const getContentThumbnail = (
  { contentTableColumns } = {},
  isCreate = false
) => [
  {
    name: 'displayImageKey',
    displayName: 'Image',
    displayMethodId: contentTableColumns
      ? INPUT_TYPES.SINGLE_SELECT
      : INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: isCreate ? [validateRequired, validateNotBlank()] : [],
    isRequired: isCreate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Maps to the content field used in the image preview during the ad creation process. ex: image_1',
        ...(contentTableColumns && { options: contentTableColumns })
      }
    }
  },
  {
    name: 'displayNameKeys',
    displayName: 'Name',
    displayMethodId: contentTableColumns
      ? INPUT_TYPES.SINGLE_SELECT
      : INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: isCreate ? [validateRequired, validateNotBlank()] : [],
    isRequired: isCreate,
    isHidden: false,
    isMultiInput: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'The default fields to use for the ad preview: ex: ["lisiting_address", "listing_address_city"]',
        ...(contentTableColumns && { options: contentTableColumns })
      }
    }
  }
];

export const getCatalogUpdateInputs = (
  { contentTableColumns } = {},
  isCreate = false
) => [
  {
    name: 'friendlyName',
    displayName: 'Catalog Friendly Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: [validateRequired, validateNotBlank()],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Friendly name of the catalog'
      }
    }
  },
  {
    name: 'defaultGroupKey',
    displayName: 'Default Group Key',
    displayMethodId: contentTableColumns
      ? INPUT_TYPES.SINGLE_SELECT
      : INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: undefined,
    reduxValidations: isCreate ? [validateRequired, validateNotBlank()] : [],
    isRequired: isCreate,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'Grouping key used to group content to a specific user or group. ex: id',
        ...(contentTableColumns && { options: contentTableColumns })
      }
    }
  }
];

export const getContentSetInputs = ({
  enums: { tableNames } = {},
  isAccessTableControlled,
  isCreate
} = {}) => {
  return [
    {
      name: isCreate ? 'contentSetName' : 'name',
      displayName: 'Content Set Name',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      reduxValidations: [],
      isHidden: false,
      isRequired: true,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip:
            'content set name - this needs to be globally unique so good to start with org name like Orgname Listings Bla Bla'
        }
      }
    },
    {
      name: isCreate ? 'contentSetDescription' : 'description',
      displayName: 'Content Set Description',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      reduxValidations: [],
      isHidden: false,
      isRequired: true,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: 'content set description'
        }
      }
    },
    {
      name: 'slug',
      displayName: 'Content Set Slug',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      reduxValidations: [],
      isHidden: false,
      isRequired: true,
      readOnly: true,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip:
            'content set slug - these need to be unique globally so good to prevent with org eg. myorg-listings'
        }
      }
    },
    {
      name: 'isAccessTableControlled',
      displayName: 'isAccessTableControlled',
      displayMethodId: INPUT_TYPES.BOOLEAN,
      reduxValidations: [],
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'contentSetTable',
      displayName: 'Content Table',
      displayMethodId: INPUT_TYPES.SINGLE_SELECT,
      initialValue: tableNames?.[0]?.value,
      reduxValidations: [],
      isHidden: false,
      isRequired: !!isCreate,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: 'content set table',
          options: tableNames || []
        }
      }
    },
    ...(isAccessTableControlled
      ? [
          {
            name: 'contentSetAccessTable',
            displayName: 'Content Set AccessTable',
            displayMethodId: INPUT_TYPES.SINGLE_SELECT,
            initialValue: tableNames?.[0]?.value,
            reduxValidations: [validateRequired],
            isRequired: true,
            isHidden: false,
            displayParameters: {
              inputData: {
                columnWidth: 2,
                tooltip:
                  "It's the content set access table for when isAccessTableControlled",
                options: tableNames || []
              }
            }
          }
        ]
      : []),
    {
      name: 'organizationSlug',
      displayName: 'organizationSlug',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      reduxValidations: [],
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'isWritable',
      displayName: 'Is Writeable',
      displayMethodId: INPUT_TYPES.BOOLEAN,
      reduxValidations: [],
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  ];
};

export const getCatalogCreateInputs = ({
  enumFields: { catalogContentTypes, tableNames } = {},
  isAccessTableControlled
} = {}) => [
  ...getCatalogUpdateInputs({ tableNames }, true),
  ...getContentSetInputs({
    enums: { tableNames },
    isCreate: true,
    isAccessTableControlled
  }),
  {
    name: 'contentTypeName',
    displayName: 'Content Type Name',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: catalogContentTypes?.[0]?.value,
    isRequired: true,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'What type of content is this?',
        options: catalogContentTypes || []
      }
    }
  }
];

export const getCollapseKeyInputs = ({
  enums: { fieldMetaData } = {}
} = {}) => [
  {
    name: 'displayCollapseKey',
    displayName: 'Current Collapse Key Field Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    isRequired: false,
    reduxValidations: [],
    isHidden: false,
    readOnly: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'The name of the current collapse key if set (read only)'
      }
    }
  },
  {
    name: 'contentSetFieldMetadataId',
    displayName: 'Content Set FieldMetadata Id',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: fieldMetaData?.[0]?.value,
    isRequired: true,
    reduxValidations: [validateRequired, validateNotBlank()],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: fieldMetaData || [],
        tooltip:
          'Id of the contentset field metadata record you are wanting to reference. The field_name will be used.'
      }
    }
  },
  {
    name: 'displayCollapseKeyFriendlyName',
    displayName: 'Collapse Key Friendly Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    isRequired: true,
    reduxValidations: [validateRequired, validateNotBlank()],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Friendly name of the collapse key'
      }
    }
  }
];

export const getImplicitFilterInputs = ({ enumFields = [] } = {}) => [
  {
    name: 'userRole',
    displayName: 'userRole',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: enumFields?.[0],
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: enumFields,
        tooltip:
          'The role that this filter applies too (currently - group_user or group_admin)'
      }
    }
  },
  {
    name: 'filterTemplate',
    displayName: 'Filter Template',
    displayMethodId: INPUT_TYPES.JSON,
    initialValue: {},
    isRequired: true,
    reduxValidations: [validateJsonObject],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip:
          'The filter json. ex: {"group_id_external": {"eq": "{{group_external_id}}"}}'
      }
    }
  },
  {
    name: 'parentId',
    displayName: 'parentId',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: 'Parent filter to load - if one exists'
      }
    }
  }
];
