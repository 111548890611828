import { gql } from 'src/generated/gql';

export const getFacebookConfiguration = gql(`
  query getFacebookConfiguration {
    myOrganization {
      id
      name
      facebookConfiguration {
        id
        businessManagerId
        botUserId
        createdAt
        updatedAt
        isActive
      }
    }
  }
`);
