import { flow } from 'lodash';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import { Button, Typography, CircularProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import { CREATE_FORM_NAME, getInputs } from './Constants';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
});

const CreateWebhook = props => {
  const {
    classes,
    dirty,
    error,
    handleSubmit,
    onSubmit,
    reset,
    submitFailed,
    submitting
  } = props;
  const createWebhookSubscriptionInputs = getInputs();

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(data => onSubmit(data, CREATE_FORM_NAME, reset))}
    >
      <Typography component="h2" className={classes.cardHeading}>
        <Trans i18nKey="admin:createWebhook.cardHeading">
          Create your webhook
        </Trans>
      </Typography>

      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>
            <Trans i18nKey="admin:createWebhook.errorMessage">
              Oh no! There was an error while creating your webhook. Please
              ensure everything was filled out correctly and try again.
            </Trans>
          </ErrorMessage>
        </div>
      )}

      <DynamicForm inputs={createWebhookSubscriptionInputs} />

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty || error || submitting}
          type="submit"
          variant="contained"
          endIcon={submitting && <CircularProgress size={15} />}
        >
          <Trans i18nKey="admin:createWebhook.submitButton">
            Create Webhook
          </Trans>
        </Button>

        <Button onClick={reset}>
          <Trans i18nKey="admin:createWebhook.clearButton">Clear Form</Trans>
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = () => {
  const initialValues = getInitialValuesFromInputsConfig(getInputs());

  return {
    initialValues
  };
};

export default flow(
  reduxForm({
    form: CREATE_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps, {}),
  withStyles(styles)
)(CreateWebhook);
