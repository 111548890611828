import Instrumentation from 'src/instrumentation';

interface LogClickAiCopyArgs {
  input: string;
  productId: string;
  architectureId: string;
}

export const logClickAiCopy = ({
  input,
  productId,
  architectureId
}: LogClickAiCopyArgs) => {
  Instrumentation.logEvent(Instrumentation.Events.ClickAiCopy, {
    input,
    productId,
    architectureId
  });
};
