import { gql } from 'src/generated/gql';

export const getArchitectureById = gql(`
  query getArchitectureById($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
      catalog {
        displayCollapseKey
        defaultGroupKey
      }
    }
  }
`);
