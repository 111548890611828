import { gql } from 'src/generated/gql';

export const createArchitecture = gql(`
  mutation createArchitecture($createInput: ArchitectureCreateInput!) {
    createArchitecture(createInput: $createInput) {
      id
      isActive
      isContentSelectable
      name
    }
  }
`);

export const updateArchitecture = gql(`
  mutation updateArchitecture($updateInput: ArchitectureUpdateInput!) {
    updateArchitecture(updateInput: $updateInput) {
      id
      isActive
      isContentSelectable
      name
    }
  }
`);
