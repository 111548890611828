import { gql } from 'src/generated/gql';

export const getAllPricingPlans = gql(`
  query getAllPrincPlans {
    allPricingPlans {
      id
      currencyCode
      slug
      name
      description
      intervalUnit
      intervalLength
      isActive
      tiers {
        pricingPlan {
          id
        }
        id
        slug
        name
        amount
        isActive
      }
    }
  }
`);

export const getOnePricingPlan = gql(`
  query getOnePricingPlan($id: ID!) {
    pricingPlan(pricingPlanId: $id) {
      id
      organization {
        id
        name
      }
      currencyCode
      slug
      name
      description
      intervalUnit
      intervalLength
      tiers {
        pricingPlan {
          id
        }
        id
        slug
        name
        amount
        isActive
      }
      isActive
    }
  }
`);
