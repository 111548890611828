import { t } from 'i18next';
import { MouseEvent } from 'react';

import { Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const pageText = () => ({
  confirmButtonText: t('ConfirmationModalFooter:confirmButton.text'),
  cancelButtonText: t('ConfirmationModalFooter:cancelButton.text')
});

interface ConfirmationModalFooterProps {
  onCancel: (
    event?: any,
    reason?: any,
    mouseEvent?: MouseEvent<HTMLElement>
  ) => void;
  onConfirm?: any;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonDisabled?: boolean;
  confirmButtonLoading?: boolean;
}

const ConfirmationModalFooter = ({
  onCancel,
  onConfirm,
  confirmButtonText,
  cancelButtonText,
  confirmButtonDisabled,
  confirmButtonLoading
}: ConfirmationModalFooterProps) => {
  const text = pageText();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2
      }}
    >
      <Button data-cy="confirmation-modal-cancel-button" onClick={onCancel}>
        {cancelButtonText || text.cancelButtonText}
      </Button>
      {onConfirm && (
        <LoadingButton
          data-cy="confirmation-modal-confirm-button"
          variant="contained"
          onClick={onConfirm}
          disabled={confirmButtonDisabled}
          loading={confirmButtonLoading}
        >
          {confirmButtonText || text.confirmButtonText}
        </LoadingButton>
      )}
    </Box>
  );
};

export default ConfirmationModalFooter;
