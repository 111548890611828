import { gql } from 'src/generated/gql';

/*
⚠️Note that we query off of admin here which is fine, except for the fact
that admin has no ID field.
This causes errors in apollo since it tries to update the cache and since admin
doesn't have an ID it emits an error.
If you run into errors that direct you here
https://www.apollographql.com/docs/react/errors/#%7B%22version%22%3A%223.8.0%22%2C%22message%22%3A12%2C%22args%22%3A%5B%5D%7D
then you probably want to add `fetchPolicy: 'no-fetch'` on your query.
Which will omit that query from the cache.
*/

export const loadOrganizations = gql(`
  query LoadOrganizationsForImpersonation($masterToken: String!) {
    admin(masterToken: $masterToken) {
      organizations {
        id
        name
        fqdn
      }
    }
  }
`);

export const loadOrganization = gql(`
  query LoadOrganizationForImpersonation(
    $masterToken: String!
    $organizationId: ID!
  ) {
    admin(masterToken: $masterToken) {
      organizations(organizationId: $organizationId) {
        id
        name
        fqdn
        authProviderType
      }
    }
  }
`);

export const loadUsersWithSearch = gql(`
  query userSearch(
    $masterToken: String!
    $organizationId: ID!
    $searchString: String!
  ) {
    admin(masterToken: $masterToken) {
      organizations(organizationId: $organizationId) {
        id
        name
        fqdn
        authProviderType

        userSearch(searchString: $searchString) {
          id
          name
          email
          externalId
          userGroups {
            id
            userId
            groupId
            role
            group {
              id
              name
            }
          }
        }
      }
    }
  }
`);

export const generateSsoToken = gql(`
  query generateSsoToken(
    $masterToken: String!
    $targetUserId: ID!
    $lifespan: String
  ) {
    admin(masterToken: $masterToken) {
      generateSsoToken(
        input: { targetUserId: $targetUserId, lifespan: $lifespan }
      ) {
        token
      }
    }
  }
`);

export const getMyOrg = gql(`
  query getMyOrg {
    myOrganization {
      id
      fqdn
    }
  }
`);
