import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import { s3BaseUrl } from './S3Image';

const PREVIEW_DRAWER_IMAGE = 'phone-mock-up.svg';

/*
  If both the quick programs and checkout flow V3 both win out, look
  into combining their phone mock components into a single configurable component.
*/

const WIDTH = '100%';
const MAX_HEIGHT_MULTIPLIER = 0.75;
const PHONE_MOCK_MAX_HEIGHT = 350;
const PHONE_MOCK_WIDTH = 175;
// Max height is 75% of the original height so that we don't see the bottom of the phone mock
const PHONE_MOCK_ASPECT_RATIO =
  (PHONE_MOCK_MAX_HEIGHT * MAX_HEIGHT_MULTIPLIER) / PHONE_MOCK_WIDTH;

const PhoneMockAdPreviewWrapper = ({ children, width = WIDTH }) => {
  const [maxHeight, setMaxHeight] = useState();
  const containerRef = useRef(null);

  /* 
  Observes the contaier's width and sets the max height of the preview using the
  phone mock aspect ratio so that content never overflows the phone mock.
  */
  useEffect(() => {
    // This check is necessary to ensure that we don't have an infinite ResizeObserver loop!
    if (!containerRef.current) return;

    const containerRO = new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        const container = entries[0];

        const width = container?.contentRect?.width;
        const newMaxHeight =
          (width ?? 0) * PHONE_MOCK_ASPECT_RATIO || undefined;
        setMaxHeight(newMaxHeight);
      });

      containerRO.observe(containerRef.current);
    });

    return () => {
      containerRO.disconnect();
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        width: '100%'
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          backgroundImage: `url("${s3BaseUrl}${PREVIEW_DRAWER_IMAGE}")`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
          width: '100%',
          height: '100%',
          position: 'absolute',
          zIndex: theme => theme.zIndex.fab + 1,
          pointerEvents: 'none'
        }}
      >
        {/* For accessibility */}
        <span role="img" aria-label="phone mock up" />
      </Box>
      <Box
        sx={{
          position: 'relative',
          width,
          px: 2.5,
          py: '12%',
          pb: 1,
          maxHeight,
          overflow: 'auto',
          zIndex: theme => theme.zIndex.fab
        }}
        data-cy-id="program-checkout-sider-ad-preview"
      >
        {children}
      </Box>
    </Box>
  );
};

export default PhoneMockAdPreviewWrapper;
