import { get, isFunction, noop } from 'lodash';

import CloseIcon from '@mui/icons-material/Close';

import {
  getChannelIconByType,
  getAllChannels
} from 'src/components/BlueprintIcon/BlueprintIconUtil';

import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  list: {
    display: 'inline-flex',
    listStyle: 'none',
    marginTop: theme.spacing(1),
    padding: 0
  },
  icon: {
    marginRight: theme.spacing(0.5)
  }
});

const BlueprintDisplayListItem = ({
  blueprint,
  classes,
  container = 'li',
  disableGutters = false,
  hasChannels = true,
  onDelete,
  onItemClick,
  selected = false
}) => {
  const canClick = isFunction(onItemClick);

  return (
    <ListItem
      button={canClick}
      onClick={canClick ? onItemClick : noop}
      component={container}
      disableGutters={disableGutters}
      selected={selected}
    >
      <ListItemText
        secondaryTypographyProps={{ component: 'div' }}
        primary={get(blueprint, 'title')}
        secondary={
          <>
            {get(blueprint, 'subTitle')}
            <br />
            {hasChannels && (
              <ul className={classes.list}>
                {getAllChannels(get(blueprint, 'channels')).map(channel => {
                  const icon = getChannelIconByType(channel);
                  return (
                    <li
                      key={`program-blueprint-channel-li-${channel}`}
                      className={classes.icon}
                    >
                      {icon}
                    </li>
                  );
                })}
              </ul>
            )}
          </>
        }
      />
      {isFunction(onDelete) && (
        <ListItemSecondaryAction>
          <IconButton aria-label="Delete" onClick={onDelete} size="large">
            <CloseIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default withStyles(styles)(BlueprintDisplayListItem);
