import { keys } from 'lodash';

export const promotionSupportType = {
  program: { name: 'Program', value: 'program' },
  automation: { name: 'Automation', value: 'automation' }
} as const;

export const promotionSupportTypeOptions = keys(promotionSupportType).map(
  type => {
    return (promotionSupportType as any)[type];
  }
);
