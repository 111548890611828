import { useState } from 'react';
import { flow, filter, get, head, find } from 'lodash';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { initialize } from 'redux-form';
import { t } from 'i18next';

import { Grid } from '@mui/material';

import { withFeatures } from 'src/components/Feature';

import {
  notificationTypes,
  userLeadPreferencesMediumTypes,
  notificationFormNames
} from './constants';
import NotificationsForm from './NotificationsForm';
import NotificationAutoResponseForm from './NotificationAutoResponseForm';
import {
  autoresponderLeadPreferences,
  userNotificationPreferences,
  availableNotificationTypes
} from './queries';
import {
  updateEmailAutoResponderPreference,
  updateSmsAutoResponderPreference,
  deleteUserNotificationPreference,
  addUserNotificationPreference
} from './mutations';

// ℹ️ This component used to be called `NotificationsPreferences` and was renamed to `LeadAlertPreferences`
// when we added in actual in-app notifications. If you see any references to `NotificationsPreferences` in this file
// then it's likely just not cleaned up yet.

const pageText = () => ({
  autoresponseEmailError: t(
    'notificationsPreferences.error.autoResponseEmail',
    'Error updating your email auto response please refresh and try again'
  ),
  autoresponseSmsError: t(
    'notificationsPreferences.error.autoResponseSms',
    'Error updating your SMS auto response please refresh and try again'
  ),
  addEmailError: t(
    'notificationsPreferences.error.addEmail',
    'Error adding your email address please refresh and try again'
  ),
  addSmsError: t(
    'notificationsPreferences.error.addSms',
    'Error adding your phone number please refresh and try again'
  ),
  deleteEmailError: t(
    'notificationsPreferences.error.deleteEmail',
    'Error deleting your email address please refresh and try again'
  ),
  deleteSmsError: t(
    'notificationsPreferences.error.deleteSms',
    'Error deleting your phone number please refresh and try again'
  ),
  defaultEmailSubject: t(
    'notificationsPreferences.defaultEmailSubject',
    'Thank you for your interest!'
  ),
  defaultEmailBody: t(
    'notificationsPreferences.defaultEmailBody',
    'Thank you for sending your contact info. I will be in touch with you shortly to follow up.'
  ),
  defaultSmsBody: t(
    'notificationsPreferences.defaultSmsBody',
    'Thank you for sending your contact info. I will be in touch with you shortly to follow up.'
  )
});

const LeadAlertPreferences = props => {
  const {
    autoresponderLeadPreferencesRefetch,
    updateEmailAutoResponderPreference,
    updateSmsAutoResponderPreference,
    addUserNotificationPreference,
    availableNotificationTypes,
    userNotificationPreferencesRefetch,
    initialize,
    deleteUserNotificationPreference,
    autoresponderLeadPreferencesError,
    initialValues,
    autoresponderLeadPreferencesLoading,
    notificationEmails,
    notificationSms,
    userNotificationPreferencesError,
    userNotificationPreferencesLoading,
    features: { leadNotifications }
  } = props;

  const [addEmailError, setAddEmailError] = useState(null);
  const [addSmsError, setAddSmsError] = useState(null);
  const [autoResponseEmailError, setAutoResponseEmailError] = useState(null);
  const [autoResponseSmsError, setAutoResponseSmsError] = useState(null);

  const text = pageText();

  const handleAutoResponseSubmit = (
    {
      smsBody,
      emailBody,
      emailSubject,
      smsSwitch,
      emailSwitch,
      autoResponderId
    },
    type
  ) => {
    const isEmail = type === notificationTypes.email;

    if (isEmail) {
      return updateEmailAutoResponderPreference({
        variables: {
          autoResponderId,
          isEmailEnabled: emailSwitch,
          emailBody,
          emailSubject
        }
      })
        .then(() => {
          setAutoResponseEmailError(null);
          return autoresponderLeadPreferencesRefetch();
        })
        .catch(() => {
          setAutoResponseEmailError(text.autoresponseEmailError);
        });
    }

    updateSmsAutoResponderPreference({
      variables: {
        autoResponderId,
        isSmsEnabled: smsSwitch,
        smsBody
      }
    })
      .then(() => {
        setAutoResponseSmsError(null);
        return autoresponderLeadPreferencesRefetch();
      })
      .catch(() => {
        setAutoResponseSmsError(text.autoresponseSmsError);
      });
  };

  const handleUserLeadPreferencesSubmit = ({ email, sms }) => {
    const notificationTypeId = get(
      find(availableNotificationTypes, {
        code: 'leads'
      }),
      'id'
    );

    addUserNotificationPreference({
      variables: {
        notificationTypeId,
        mediumType: email ? 'email' : 'sms',
        emailAddress: email || null,
        phoneNumber: sms || null
      }
    })
      .then(() => {
        const isEmail = !!email;
        const form = isEmail
          ? notificationFormNames.email
          : notificationFormNames.sms;
        // reset form input after success
        initialize(form);
        // refetch preferences
        return userNotificationPreferencesRefetch();
      })
      .catch(() => {
        setAddEmailError(email ? text.addEmailError : null);
        setAddSmsError(sms ? text.addSmsError : null);
      });
  };

  const deleteUserNotificationPreferenceHandler = ({ id, medium }) => {
    deleteUserNotificationPreference({
      variables: {
        userPreferenceId: id
      }
    })
      .then(() => {
        return userNotificationPreferencesRefetch();
      })
      .catch(() => {
        setAddEmailError(
          medium === userLeadPreferencesMediumTypes.email
            ? text.deleteEmailError
            : null
        );
        setAddSmsError(
          medium === userLeadPreferencesMediumTypes.sms
            ? text.deleteSmsError
            : null
        );
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        {leadNotifications && (
          <>
            <Grid item xs={12} md={6}>
              <NotificationsForm
                loading={userNotificationPreferencesLoading}
                addError={addEmailError}
                error={userNotificationPreferencesError}
                listItems={notificationEmails}
                onDelete={deleteUserNotificationPreferenceHandler}
                type={notificationTypes.email}
                onSubmit={handleUserLeadPreferencesSubmit}
                form={notificationFormNames.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NotificationsForm
                loading={userNotificationPreferencesLoading}
                addError={addSmsError}
                error={userNotificationPreferencesError}
                listItems={notificationSms}
                onDelete={deleteUserNotificationPreferenceHandler}
                onSubmit={handleUserLeadPreferencesSubmit}
                form={notificationFormNames.sms}
                type={notificationTypes.sms}
              />
            </Grid>
          </>
        )}
        {leadNotifications && (
          <>
            <Grid item xs={12} md={6}>
              <NotificationAutoResponseForm
                initialValues={initialValues}
                form="autoresponderEmail"
                onSubmit={data =>
                  handleAutoResponseSubmit(data, notificationTypes.email)
                }
                type={notificationTypes.email}
                loading={autoresponderLeadPreferencesLoading}
                error={autoresponderLeadPreferencesError}
                updateError={autoResponseEmailError}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <NotificationAutoResponseForm
                initialValues={initialValues}
                form="autoresponderSms"
                onSubmit={data =>
                  handleAutoResponseSubmit(data, notificationTypes.sms)
                }
                type={notificationTypes.sms}
                loading={autoresponderLeadPreferencesLoading}
                error={autoresponderLeadPreferencesError}
                updateError={autoResponseSmsError}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (state, { autoresponder }) => {
  // Note: pulling i18n's t into this section is causing errors. Flagging this
  // for i18n work in the future.
  const text = pageText();

  return {
    initialValues: {
      emailSubject: get(
        autoresponder,
        'emailSubject',
        text.defaultEmailSubject
      ),
      emailBody: get(autoresponder, 'emailBody', text.defaultEmailBody),
      smsBody: get(autoresponder, 'smsBody', text.defaultSmsBody),
      emailSwitch: get(autoresponder, 'isEmailEnabled', false),
      smsSwitch: get(autoresponder, 'isSmsEnabled', false),
      autoResponderId: get(autoresponder, 'id')
    }
  };
};

export default flow(
  connect(mapStateToProps, { initialize }),
  graphql(userNotificationPreferences, {
    name: 'userNotificationPreferences',
    props: ({
      userNotificationPreferences: {
        userNotificationPreferences,
        loading,
        error,
        refetch
      }
    }) => {
      return {
        notificationEmails: filter(userNotificationPreferences, {
          medium: userLeadPreferencesMediumTypes.email
        }),
        notificationSms: filter(userNotificationPreferences, {
          medium: userLeadPreferencesMediumTypes.sms
        }),
        userNotificationPreferencesLoading: loading,
        userNotificationPreferencesError: error,
        userNotificationPreferencesRefetch: refetch
      };
    }
  }),
  graphql(autoresponderLeadPreferences, {
    name: 'autoresponderLeadPreferences',
    props: ({
      autoresponderLeadPreferences: {
        autoresponderLeadPreferences,
        loading,
        error,
        refetch
      }
    }) => {
      return {
        autoresponder: head(autoresponderLeadPreferences),
        autoresponderLeadPreferencesLoading: loading,
        autoresponderLeadPreferencesError: error,
        autoresponderLeadPreferencesRefetch: refetch
      };
    }
  }),
  graphql(updateEmailAutoResponderPreference, {
    name: 'updateEmailAutoResponderPreference'
  }),
  graphql(updateSmsAutoResponderPreference, {
    name: 'updateSmsAutoResponderPreference'
  }),
  graphql(deleteUserNotificationPreference, {
    name: 'deleteUserNotificationPreference'
  }),
  graphql(addUserNotificationPreference, {
    name: 'addUserNotificationPreference'
  }),
  graphql(availableNotificationTypes, {
    name: 'availableNotificationTypes',
    props: ({ availableNotificationTypes: { availableNotificationTypes } }) => {
      return {
        availableNotificationTypes
      };
    }
  }),
  withFeatures
)(LeadAlertPreferences);
