import { Tooltip, Typography } from '@mui/material';
import { t } from 'i18next';

interface CharacterCountProps {
  length: number;
  stringMaxLength: number;
}

const CharacterCount = ({ length, stringMaxLength }: CharacterCountProps) => {
  const isOverLimit = length > stringMaxLength;
  return (
    <Tooltip title={t('renderTemplateStringTextField:maxString.tootltip')}>
      <Typography
        component="span"
        fontSize="12px"
        sx={{ color: isOverLimit ? 'error.main' : 'text.secondary' }}
      >
        {length}/{stringMaxLength}
      </Typography>
    </Tooltip>
  );
};

export default CharacterCount;
