import { Box } from '@mui/material';

interface ActionsContainerProps {
  children: React.ReactNode;
  justifyContent: CSSStyleDeclaration['justifyContent'];
}

const ActionsContainer = ({
  children,
  justifyContent
}: ActionsContainerProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent,
        gap: 2,
        width: '100%'
      }}
    >
      {children}
    </Box>
  );
};

export default ActionsContainer;
