import { find, isEmpty } from 'lodash';

import { OFFER_TYPES } from 'src/common/offers';
import { Offer } from 'src/generated/gql/graphql';

type Product = {
  offers: Offer[];
};

export const getValidProductOffers = (
  product: Product,
  isAutomated: boolean = false
) => {
  return product?.offers?.filter((offer: any) => {
    const subscriptionOfferWithoutPlans =
      offer.type === OFFER_TYPES.subscription &&
      isEmpty(offer?.stripeSubscriptionPlans);

    const automationFormAndNonAutomationOffer =
      isAutomated && !offer.isSupervisable;

    if (
      !offer?.isActive ||
      subscriptionOfferWithoutPlans ||
      automationFormAndNonAutomationOffer
    ) {
      return false;
    }

    return true;
  });
};

interface HaveOffersChangedArgs {
  product: Product;
  orderOfferId: string;
  isAutomated?: boolean;
}

export const haveOffersChanged = ({
  orderOfferId,
  product,
  isAutomated = false
}: HaveOffersChangedArgs) => {
  const validProductOffers = getValidProductOffers(product, isAutomated);
  const sameOffer = !!find(validProductOffers, { id: orderOfferId });

  return !sameOffer;
};
