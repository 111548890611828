import { withAppSettings, useAppSettings } from 'src/AppSettings';
import { FEATURE_DEFAULTS } from './constants';

// this passes a feature prop to wrapped component
// that contains the feature values
// including defaults if not set
export const withFeatures = component => {
  const Component = component;
  return withAppSettings(props => {
    const { appSettings } = props;
    // we can use appSettings here but there is a cyclical import
    const features = appSettings?.app?.features || FEATURE_DEFAULTS;
    return <Component {...props} features={features} />;
  });
};

export const useFeatures = () => {
  const appSettings = useAppSettings();
  const features = appSettings?.app?.features || FEATURE_DEFAULTS;
  return features;
};
