import { gql } from 'src/generated/gql';

export const getAllLocations = gql(`
    query getAllLocationsForProgramProvider(
      $filter: LocationsQueryFilter
    ) {
      locations(
        first: 5000
        filter: $filter
      ) {
          edges {
            cursor
            node {
              id
              organizationId
              ownerId
              type
              name
              street
              region
              city
              postalCode
              websiteUrl
              phone
              emailAddress
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
      }
    }
  `);
