import { gql } from 'src/generated/gql';

export const getAuth0Config = gql(`
  query getAuth0Config {
    myOrganization {
      id
      auth0Config {
        id
        organizationId
        domain
        connectionType
        configurationBaseUrl
        spaClientId
        spaAudience
        spaCallbackUrl
        spaDomain
        spaLogoutUrl
        spaResponseType
        spaScope
        managementClientId
        managementBaseUrl
        managementAudience
        loginBaseUrl
      }
    }
  }
`);
