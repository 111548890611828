import { SVGProps } from 'react';

const GoogleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    {...props}
  >
    <path
      d="M39.611 16.083H38V16H20V24H31.303C29.654 28.657 25.223 32 20 32C13.373 32 8 26.627 8 20C8 13.373 13.373 8 20 8C23.059 8 25.842 9.154 27.961 11.039L33.618 5.382C30.046 2.053 25.268 0 20 0C8.955 0 0 8.955 0 20C0 31.045 8.955 40 20 40C31.045 40 40 31.045 40 20C40 18.659 39.862 17.35 39.611 16.083Z"
      fill="#FFC107"
    />
    <path
      d="M2.30566 10.691L8.87666 15.51C10.6547 11.108 14.9607 8 19.9997 8C23.0587 8 25.8417 9.154 27.9607 11.039L33.6177 5.382C30.0457 2.053 25.2677 0 19.9997 0C12.3177 0 5.65566 4.337 2.30566 10.691Z"
      fill="#FF3D00"
    />
    <path
      d="M20.0003 40C25.1663 40 29.8603 38.023 33.4093 34.808L27.2193 29.57C25.2113 31.091 22.7153 32 20.0003 32C14.7983 32 10.3813 28.683 8.71731 24.054L2.19531 29.079C5.50531 35.556 12.2273 40 20.0003 40Z"
      fill="#4CAF50"
    />
    <path
      d="M39.611 16.083H38V16H20V24H31.303C30.511 26.237 29.072 28.166 27.216 29.571L27.219 29.569L33.409 34.807C32.971 35.205 40 30 40 20C40 18.659 39.862 17.35 39.611 16.083Z"
      fill="#1976D2"
    />
  </svg>
);

export default GoogleIcon;
