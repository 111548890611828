import { gql } from 'src/generated/gql';

export const getContentSelectorByArchitectureId = gql(`
  query getContentSelectorByArchitectureId(
    $architectureId: ID!
    $filter: JSON
    $endCursor: String
    $first: Int!
    $groupKey: String
    $sort: SortSetting
    $alwaysInclude: JSON
  ) {
    architecture(architectureId: $architectureId) {
      id
      name
      catalog {
        id
        contentSetId
        friendlyName
        accessPattern
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isFilterable
          isHidden
        }
        contentV2(
          first: $first
          after: $endCursor
          groupKey: $groupKey
          filter: $filter
          sort: $sort
          alwaysInclude: $alwaysInclude
        ) {
          edges {
            node {
              items {
                id
                fields
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`);

export const getAutomationContentPreviewByArchitectureId = gql(`
  query getAutomationContentPreviewByArchitectureId(
    $architectureId: ID!
    $filter: JSON
    $endCursor: String
    $first: Int!
    $groupKey: String
  ) {
    architecture(architectureId: $architectureId) {
      id
      name
      catalog {
        id
        contentSetId
        friendlyName
        accessPattern
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isFilterable
          isHidden
        }
        content(
          first: $first
          after: $endCursor
          groupKey: $groupKey
          filter: $filter
        ) {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`);
