import { useCallback, useState } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm } from 'redux-form';
import { t } from 'i18next';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import { Paper, Button, CircularProgress, Grid } from '@mui/material';
import Alert from '@mui/material/Alert';

import { DynamicForm } from 'src/components/ReduxForm';
import Heading from 'src/components/PageElements/Heading';

import { sendMagicLinkEmailForPartnerAdmin } from './mutations';

const getText = () => ({
  title: t('admin.partnerLinkGen.title'),
  description: t('admin.partnerLinkGen.description'),
  notLoggedIn: t('partner:route.notLoggedIn'),
  warning: t('partner:route.warning'),
  submit: t('admin.partnerLinkGen.submit'),
  success: t('admin.partnerLinkGen.success'),
  error: t('admin.partnerLinkGen.error')
});

const PartnerPortalLink = ({
  handleSubmit,
  submitting,
  invalid,
  sendMagicLinkEmailForPartnerAdmin
}) => {
  const text = getText();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = useCallback(async formValues => {
    try {
      const { data } = await sendMagicLinkEmailForPartnerAdmin({
        variables: { input: formValues }
      });
      if (data?.sendMagicLinkEmailForPartnerAdmin?.emailSent) {
        setSuccess(true);
      } else {
        setError(
          'There was an error sending the email, we got a response fro the server but it returned an error'
        );
      }
    } catch (error) {
      setError(error?.message || error.toString() || '');
    }
  });

  const inputs = [
    // {
    //     name: 'fqdn',
    //     displayName: 'Org FQDN',
    //     displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    //     initialValue: undefined,
    //     reduxValidations: [],
    //     isRequired: true,
    //     isHidden: false,
    //     displaySortOrder: 1,
    //     displayParameters: {
    //         inputData: {
    //             columnWidth: 2,
    //             tooltip:
    //                 'This is the FQDN for the Org in which you want to create access. eg: org.example.com'
    //         }
    //     }
    // },
    {
      name: 'email',
      displayName: 'Client Email',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: undefined,
      reduxValidations: [],
      isRequired: true,
      isHidden: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip:
            'This is where you are gonna send the magic link to the partner portal'
        }
      }
    }
  ];
  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.description}
        pageTitle={text.title}
      />

      <Alert
        severity="warning"
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        {text.warning}
      </Alert>
      <br />
      <Paper
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {error && (
              <Alert severity="error">
                {text.error}: {error}
              </Alert>
            )}
            {success ? (
              <Alert severity="success">{text.success}</Alert>
            ) : (
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <DynamicForm disabled={false} inputs={inputs} />
                <br />
                <Button
                  color="primary"
                  disabled={submitting || invalid}
                  endIcon={submitting && <CircularProgress size={15} />}
                  variant="contained"
                  type="submit"
                >
                  {text.submit}
                </Button>
              </form>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: 'magicLinkForm',
    initialValues: {}
  }),
  graphql(sendMagicLinkEmailForPartnerAdmin, {
    name: 'sendMagicLinkEmailForPartnerAdmin'
  })
)(PartnerPortalLink);
