import { useWatch } from 'react-hook-form';
import useBulkValidations from 'src/hooks/useBulkValidations';
import useProgram from 'src/pages/Program/utils/useProgram';
import LocationManagment from './LocationManagement';
import MultiLocationFooter from '../StepFooters/MultiLocationFooter';

interface PublishProps {
  isChannelValidationLoading: boolean;
  setIsPollingPreview: (isPollingPreview: boolean) => void;
  setIsLoadingAd: (isLoadingAd: boolean) => void;
  isPollingPreview: boolean;
  conditionalInputsVisibility: any;
  architecture: any;
  formMethods: any;
  setIsValidatingCreative: (isValidatingCreative: boolean) => void;
  selectedBlueprint: any;
  selectedBusinessObjects: {
    selectedBusinessObjects: any[];
  };
  submitForm: () => void;
  selectedLocationsMetadata: any[];
}

const Publish = (props: PublishProps) => {
  const {
    isChannelValidationLoading,
    setIsPollingPreview,
    setIsLoadingAd,
    isPollingPreview: isPollingPreviewExternal,
    conditionalInputsVisibility,
    architecture,
    selectedBusinessObjects,
    setIsValidatingCreative,
    selectedBlueprint,
    submitForm,
    selectedLocationsMetadata
  } = props;

  const dynamicUserInputs = useWatch({ name: 'dynamicUserInputs' });
  const locationsOverrideById = useWatch({ name: 'locationsOverrideById' });
  const selectedLocations = useWatch({ name: 'selectedLocations' });
  const { selectedLocation, setCreativeValidationErrors } = useProgram();

  const previewData = {
    blueprint: selectedBlueprint,
    dynamicUserInputs,
    businessObjects: selectedBusinessObjects?.selectedBusinessObjects,
    selectedBusinessObjects,
    locationsOverrideById,
    selectedLocations
  };

  const { pollingPreview, adPreviewError, executeSubmitValidations } =
    useBulkValidations({
      setIsPollingPreview,
      setIsLoadingAd,
      isPollingPreviewExternal,
      conditionalInputsVisibility,
      architecture,
      setIsValidatingCreative,
      previewData,
      selectedLocation,
      setCreativeValidationErrors
    });

  return (
    <div>
      <LocationManagment
        isChannelValidationLoading={isChannelValidationLoading}
        selectedBusinessObjects={selectedBusinessObjects}
        selectedLocationsMetadata={selectedLocationsMetadata}
      />
      <MultiLocationFooter
        selectedBusinessObjects={selectedBusinessObjects}
        isChannelValidationLoading={isChannelValidationLoading}
        executeSubmitValidations={executeSubmitValidations}
        isSubmit
        pollingPreview={pollingPreview}
        adPreviewError={adPreviewError}
        submitForm={submitForm}
      />
    </div>
  );
};

export default Publish;
