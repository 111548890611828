import { gql } from 'src/generated/gql';

export const getArchitectures = gql(`
  query getArchitectures($showInactive: Boolean) {
    myOrganization {
      id
      architectures(
        showInactive: $showInactive
        types: [standard, organization]
      ) {
        id
        name
        isContentSelectable
        isActive
      }
    }
  }
`);
