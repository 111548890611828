import { useState } from 'react';

const useMinSpendState = () => {
  const [minSpendLoading, setMinSpendLoading] = useState(false);
  const [hasMinSpendError, setHasMinSpendError] = useState(false);
  return {
    minSpendLoading,
    setMinSpendLoading,
    hasMinSpendError,
    setHasMinSpendError
  };
};

export default useMinSpendState;
