import { useState } from 'react';
import { flow } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { t } from 'i18next';
import { connect } from 'react-redux';

import {
  Autocomplete,
  TextField,
  Grid,
  Button,
  Typography
} from '@mui/material';

import Modal from 'src/components/Modal';
import Loading from 'src/components/Loading';

import CreatePricingForm from './Forms/CreatePricingConfigurationForm';
import UpdatePricingForm from './Forms/UpdatePricingConfigurationForm';

const styles = theme => ({
  textFieldInput: {
    '& label': {
      color: theme.palette.text.primary,
      fontSize: theme.typography.h6.fontSize
    },
    '& input': {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.overline.fontSize
    }
  },
  loading: {
    margin: '0 auto'
  },
  createPlan: {
    marginTop: theme.spacing(2)
  }
});

const PricingConfiguration = props => {
  const {
    classes,
    isPricingPlansLoading,
    allPricingPlans,
    selectedPricingPlan,
    setSelectedPricingPlan,
    refetch
  } = props;
  const [openModal, setOpenModal] = useState(false);

  const headerText = t('admin:pricingConfiguration.header');

  return (
    <div>
      <Typography
        component="h6"
        variant="h6"
        sx={{ marginBottom: theme => theme.spacing(2) }}
      >
        Pricing Configuration
      </Typography>
      <Grid container spacing={3}>
        {isPricingPlansLoading ? (
          <Grid item xs={12}>
            <Loading className={styles.loading} />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="select-pricing-plan"
                data-cy="select-pricing-plan-select-pricing-combobox"
                disableClearable
                value={selectedPricingPlan || null}
                options={allPricingPlans || []}
                getOptionLabel={option => option.name}
                onChange={(_, pricingPlan) =>
                  setSelectedPricingPlan(pricingPlan)
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('admin:selectPricingPlan.primary')}
                    className={classes.textFieldInput}
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                disabled={openModal}
                onClick={() => {
                  setOpenModal(true);
                }}
                data-cy="toggle-create-new-pricing-plan"
              >
                Create New Pricing Plan
              </Button>
            </Grid>
            <UpdatePricingForm
              refetch={refetch}
              pricingPlan={selectedPricingPlan}
            />
            <Modal
              data-cy="pricing-plan-form-modal"
              fullWidth
              maxWidth="md"
              showClose
              headerText={headerText}
              open={openModal}
              onClose={() => {
                setOpenModal(false);
                refetch();
              }}
              FooterComponent={() => (
                <div>
                  <Button
                    onClick={() => {
                      setOpenModal(false);
                      refetch();
                    }}
                    variant="contained"
                    color="primary"
                    data-cy="pricing-plan-close-modal"
                  >
                    Cancel
                  </Button>
                </div>
              )}
            >
              <div className={classes.createPlan}>
                <Typography component="h6" variant="h6">
                  Create Pricing Plan
                </Typography>
                <CreatePricingForm
                  setOpenModal={setOpenModal}
                  pricingPlan={selectedPricingPlan}
                  refetch={refetch}
                />
              </div>
            </Modal>
          </>
        )}
      </Grid>
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  const initialValues = ownProps.pricingPlan;

  return { initialValues };
}

export default flow(
  connect(mapStateToProps),
  withStyles(styles)
)(PricingConfiguration);
