import { t } from 'i18next';

interface GetCancelButtonStatusArgs {
  disabled: boolean;
  hasDeletePermission: boolean;
  hasMinimumDuration: boolean;
  minimumDurationDays: number;
}

export const getCancelButtonStatus = ({
  disabled,
  hasDeletePermission,
  hasMinimumDuration,
  minimumDurationDays
}: GetCancelButtonStatusArgs) => {
  let tooltip = '';
  let isDisabled = false;

  if (disabled) {
    isDisabled = true;
    if (hasMinimumDuration) {
      tooltip = t('programPerf:editProgram.cantCancelMinimumDuration', {
        minimumDurationDays
      });
    } else {
      tooltip = t('programPerf:editProgram.cantCancel');
    }
  }

  if (!hasDeletePermission) {
    isDisabled = true;
    tooltip = t('programPerf:editProgram.cantCancelNoPermission');
  }

  return { tooltip, isDisabled };
};
