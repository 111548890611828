import { AutoAwesome as AiChatIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { t } from 'i18next';

interface AiChatAdCopywriterIconProps {
  onClick: () => void;
}

const AiChatAdCopywriterIcon = ({ onClick }: AiChatAdCopywriterIconProps) => {
  return (
    <Tooltip title={t('aiSuggestion:chat.inputIconTooltip')}>
      <IconButton data-cy="ai-chat-form-input-open-button" onClick={onClick}>
        <AiChatIcon sx={{ fontSize: '1rem' }} />
      </IconButton>
    </Tooltip>
  );
};

export default AiChatAdCopywriterIcon;
