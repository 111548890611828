import { Button } from '@mui/material';

import ClipboardButtonIcon from './ClipboardButtonIcon';
import ClipboardButtonText from './ClipboardButtonText';

const CopyToClipboardButton = props => {
  const {
    onClick,
    isCopied,
    variant = 'outlined',
    label,
    copiedLabel,
    color = 'secondary',
    iconColor
  } = props;

  return (
    <Button
      size="small"
      color={color}
      variant={variant}
      data-cy="copy-to-clipboard-button"
      startIcon={<ClipboardButtonIcon color={iconColor} isCopied={isCopied} />}
      onClick={onClick}
    >
      <ClipboardButtonText
        isCopied={isCopied}
        label={label}
        copiedLabel={copiedLabel}
      />
    </Button>
  );
};

export default CopyToClipboardButton;
