import { Trans } from 'react-i18next';

import { Typography, Divider, Grid } from '@mui/material';

import AddCreditCard from 'src/components/CreditCards/AddCreditCard';
import CreditCardList from 'src/components/CreditCards/CreditCardList';
import { cardListDirections } from 'src/components/CreditCards/Constants';

const ManageCreditCards = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Typography variant="body2">
          <Trans i18nKey="billing:creditCardTitle">Manage Credit Cards</Trans>
        </Typography>
        <Divider />

        <CreditCardList direction={cardListDirections.horizontal} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body2">
          <Trans i18nKey="billing:addCreditCard">Add New Card</Trans>
        </Typography>
        <Divider />
        <br />
        <div data-cy="payment-card-container">
          <AddCreditCard />
        </div>
      </Grid>
    </Grid>
  );
};

export default ManageCreditCards;
