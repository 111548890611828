import { useState } from 'react';

interface UseMenuArgs {
  stopPropagation?: boolean;
}

const useMenu = (args?: UseMenuArgs) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (args?.stopPropagation) {
      e.stopPropagation();
    }
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return {
    menuAnchorEl: anchorEl,
    isMenuOpen: !!anchorEl,
    handleOpenMenu,
    handleCloseMenu
  };
};

export default useMenu;
