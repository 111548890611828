import { useMemo, useCallback, useState, useEffect } from 'react';
import { isEmpty, range, find, filter, flow } from 'lodash';
import { change } from 'redux-form';
import { t } from 'i18next';
import { v1 as uuidv1 } from 'uuid';
import { connect } from 'react-redux';

import { usePrevious } from 'src/hooks/usePrevious';

import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';

import { BLUEPRINT_BUILDER_FORM_NAME } from '../../Constants';
import { AccordionListHeading, AccordionListWrapper } from '../AccordionList';

import { facetsInputs } from './inputConfigs';
import { facetPriorityFromIndex } from './helpers';
import AddFacet from './AddFacet';
import DraggableFacetListItem from './DraggableFacetListItem';

const pageText = () => ({
  heading: t('admin:blueprintBuilder.stepPublishersStepFacetHeading'),
  addButton: t('admin:blueprintBuilder.stepPublishersStepFacetAddButton'),
  removeButton: t('admin:blueprintBuilder.stepPublishersStepFacetRemoveButton')
});

const RenderFacetsInputs = props => {
  const { fields, selectedPrototype, selectedPrototypeUnit, change } = props;
  const text = useMemo(() => pageText(), []);
  const [hasMoved, setHasMoved] = useState(false);

  const prevFields = usePrevious(fields.getAll());
  useEffect(() => {
    if (prevFields === fields.getAll() && hasMoved) {
      fields.forEach((field, index) => {
        const priority = fields.get(index)?.priority;
        // the initial document sorts facets by priority so we are setting priority based on document order
        if (priority !== facetPriorityFromIndex(index)) {
          // if there is a priority mismatch, update the priority this accounts for when a is moved
          change(
            BLUEPRINT_BUILDER_FORM_NAME,
            `${fields.name}[${index}].priority`,
            facetPriorityFromIndex(index)
          );
        }
      });
      setHasMoved(false);
    }
  }, [change, fields, hasMoved, prevFields]);

  const unitId = selectedPrototypeUnit?.id;
  const prototype = selectedPrototype;

  const facetsOfSelectedPrototype = useMemo(() => {
    return (
      find(selectedPrototypeUnit?.prototypes, { slug: prototype })?.facets || []
    );
  }, [prototype, selectedPrototypeUnit?.prototypes]);

  const [openIndexes, setOpenIndexes] = useState([]);

  const handleAccordionToggle = useCallback(
    currentIndex => {
      const newOpenIndexes = new Set([...openIndexes]);
      if (newOpenIndexes.has(currentIndex)) {
        newOpenIndexes.delete(currentIndex);
      } else {
        newOpenIndexes.add(currentIndex);
      }

      setOpenIndexes([...newOpenIndexes]);
    },
    [openIndexes]
  );

  const handleAccordionToggleAll = useCallback(() => {
    if (isEmpty(openIndexes)) {
      setOpenIndexes(range(fields.length));
    } else {
      setOpenIndexes([]);
    }
  }, [fields, openIndexes]);

  const handleAccordionCloseAll = useCallback(() => {
    setOpenIndexes([]);
  }, []);

  const handleAddFacet = useCallback(
    selectedFacet => {
      const emptyFacet = {
        parameterValues: {},
        ...getInitialValuesFromInputsConfig(facetsInputs)
      };
      const newParameters =
        find(facetsOfSelectedPrototype, {
          slug: selectedFacet
        })?.parameters || [];
      const requiredParameters = filter(newParameters, {
        isRequired: true
      });

      emptyFacet.facet = selectedFacet;
      emptyFacet.slug = uuidv1();
      // new facets are added to the end of the array so we can set the length
      emptyFacet.priority = facetPriorityFromIndex(fields.length);

      // add required parameters for selected facet
      emptyFacet.parameterValues = requiredParameters.reduce((accum, param) => {
        const newAccum = accum;
        if (param?.isRequired) {
          newAccum[param?.name] = null;
        }
        return newAccum;
      }, {});

      fields.push(emptyFacet);

      handleAccordionToggle(fields.length);
    },
    [facetsOfSelectedPrototype, fields, handleAccordionToggle]
  );

  return (
    <>
      <AccordionListHeading
        title={text.heading}
        openIndexes={openIndexes}
        handleToggleAll={handleAccordionToggleAll}
      >
        <AddFacet
          onAddFacet={handleAddFacet}
          unitId={unitId}
          prototype={prototype}
        />
      </AccordionListHeading>

      <AccordionListWrapper>
        {fields.map((field, index) => {
          return (
            <DraggableFacetListItem
              facetsOfSelectedPrototype={facetsOfSelectedPrototype}
              field={field}
              fields={fields}
              handleAccordionToggle={handleAccordionToggle}
              index={index}
              openIndexes={openIndexes}
              prototype={prototype}
              text={text}
              unitId={unitId}
              handleAccordionCloseAll={handleAccordionCloseAll}
              setHasMoved={setHasMoved}
            />
          );
        })}
      </AccordionListWrapper>
    </>
  );
};

export default flow(connect(null, { change }))(RenderFacetsInputs);
