import { Typography, AppBar, Toolbar } from '@mui/material';

import PageTitle from 'src/components/PageTitle';

interface PartnerAppBarProps {
  title: string;
}

const PartnerAppBar = ({ title }: PartnerAppBarProps) => {
  return (
    <AppBar color="default" position="fixed">
      <Toolbar>
        <PageTitle subPageTitle={title} />
        <Typography variant="h5">{title}</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default PartnerAppBar;
