import { Breadcrumbs, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, SxProps, Theme } from '@mui/system';

const BreadcrumbLink = styled(Link)(() => ({
  fontSize: '15px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const BreadcrumbButton = styled(Button)(() => ({
  fontSize: '15px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

export interface BreadcrumbPiece {
  text: string;
  to?: string;
  onClick?: () => void;
}

export interface BreadcrumbTrailProps {
  pieces: BreadcrumbPiece[];
  dataCy?: string;
  sx?: SxProps<Theme>;
}

/**
 * A trail of breadcrumbs, with optional links or onClick handlers.
 * This component should be provided a list of breadcrumbs and EITHER a
 * `to` or `onClick` handler for each breadcrumb.
 */
export const BreadcrumbTrail = ({
  pieces,
  dataCy,
  sx
}: BreadcrumbTrailProps) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      data-cy={dataCy || 'breadcrumb-trail-root'}
      sx={sx}
    >
      {pieces.map(({ to, text, onClick }, index) => {
        const extraStyles =
          index === pieces.length - 1
            ? (theme: Theme) => ({ color: theme.palette.text.primary })
            : (theme: Theme) => ({ color: theme.palette.text.secondary });

        if (to != null) {
          return (
            <BreadcrumbLink key={text} to={to} sx={extraStyles}>
              {text}
            </BreadcrumbLink>
          );
        }

        if (onClick != null) {
          return (
            <BreadcrumbButton key={text} onClick={onClick} sx={extraStyles}>
              {text}
            </BreadcrumbButton>
          );
        }

        return (
          <Typography key={text} sx={extraStyles} onClick={onClick}>
            {text}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};
