import { useQuery } from '@apollo/client';

import { getPaymentMethods, getTeamPaymentMethods } from './queries';

interface UseGetPaymentMethodsArgs {
  isTeamsEnabled: boolean;
}

const useGetPaymentMethods = ({ isTeamsEnabled }: UseGetPaymentMethodsArgs) => {
  const { data: paymentMethodsData, refetch: refetchPaymentMethods } = useQuery(
    getPaymentMethods,
    {
      skip: isTeamsEnabled
    }
  );

  const { data: teamPaymentMethodsData, refetch: refetchTeamPaymentMethods } =
    useQuery(getTeamPaymentMethods, { skip: !isTeamsEnabled });

  const paymentMethods =
    (isTeamsEnabled
      ? teamPaymentMethodsData?.paymentMethod
      : paymentMethodsData?.paymentMethod) || [];

  return {
    paymentMethods,
    refetch: isTeamsEnabled ? refetchTeamPaymentMethods : refetchPaymentMethods
  };
};

export default useGetPaymentMethods;
