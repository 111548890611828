import { gql } from 'src/generated/gql';

export const getLeads = gql(`
  query userLeads {
    userLeads(first: 10000) {
      edges {
        node {
          id
          facebookId
          architectureId
          programId
          orderId
          submittedAt
          fields {
            name
            values
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`);

export const getArchitectureById = gql(`
  query getArchitectureByIdForLeadDetails($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      catalog {
        id
        friendlyName
        displayNameTemplate
        displayImageTemplate
      }
    }
  }
`);

export const getProgram = gql(`
  query OrderForLeadDetails($orderId: ID!, $showDeleted: Boolean) {
    order(orderId: $orderId, showDeleted: $showDeleted) {
      architectureId
      id
      isProgramActive
      programId
      paymentMethodId
      products {
        name
        kpis {
          slug
          channelCode
          friendlyName
        }
      }
      billingDetails {
        offerType
        amount
        startDate
        endDate
        planId
        planNickname
        interval
        renewsOn
        isSetToCancel
        currentPeriodEnd
        canceledOn
        promoCode
        cardInfo
        stripeSourceId
        planNickname
        cancellationDetails {
          originalPurchaseAmount
          amountAfterCalculatedUsage
          refundAmount
          cancellationFee
          cancellationDate
        }
      }
      adData {
        adPreviews
        totalAds
      }
      orderItem {
        id
        variableValues
        scheduleEnd
        scheduleStart
        timeZoneCode
        status
        product {
          id
          description
          architectureId
          name
          isAvailable
          requiresContent
          blueprintId
          messaging {
            statePendingHelpText
          }
          offers {
            productId
            name
            isActive
            availableToRoles
            type
            purchaseDefaultDurationDays
            purchasePriceAmount
            purchasePriceIsUserSet
            purchasePriceUserSetMin
            purchasePriceUserSetMax
            subscriptionStripeProductId
            cancellationType
            stripeSubscriptionPlans {
              id
              nickname
              amount
              interval
              productId
            }
          }
          inputSections {
            productId
            sectionId
            name
            isMutable
            displaySortOrder
            inputFields {
              productId
              sectionId
              blueprintVariableId
              displayName
              displayMethodId
              displaySortOrder
              displayParameters
              isEditable
              blueprintVariable {
                id
                blueprintId
                name
                type
                friendlyName
                description
                isRequired
                isExpressionAllowed
                defaultValue
                integerMinValue
                integerMaxValue
                decimalMinValue
                decimalMaxValue
                stringMinLength
                stringMaxLength
                stringRegex
                enumValues
                referenceTarget
                customValidationMethod
                isArray
                arrayMinItems
                arrayMaxItems
                validationRules
              }
            }
          }
          dynamicFieldMetadata {
            fieldName
            label
            sampleValue
          }
          blueprint {
            id
            publishers {
              id
              prototype {
                id
                code
                unit {
                  id
                  channelCode
                  code
                }
              }
              publisherFacets {
                id
                facet {
                  id
                  code
                }
                parameterValues
              }
            }
          }
        }
        catalogItems {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
      channels {
        adType
        channels
      }
    }
  }
`);
