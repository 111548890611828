import { Trans } from 'react-i18next';

import { Typography, LinearProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  uploadTile: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  },
  uploadProgress: {
    width: '100%'
  }
});

const UploadingProgress = ({ classes, totalFileCount, completedFileCount }) => {
  return (
    <div className={classes.uploadTile} data-cy="upload-progress-tile">
      <Typography variant="h6">
        <Trans
          i18nKey="gallery:progress.uploadingFiles"
          values={{ completedFileCount, totalFileCount }}
        />
      </Typography>
      <LinearProgress
        className={classes.uploadProgress}
        color="secondary"
        variant="query"
      />
    </div>
  );
};

export default withStyles(styles)(UploadingProgress);
