import { gql } from 'src/generated/gql';

export const setDismissModal = gql(`
  mutation updateUserSettingsFromChrome(
    $updateSettings: JSON!
    $updateType: UserSettingUpdateType
  ) {
    updateUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);
