import { isEmpty } from 'lodash';
import { useDrag, useDrop } from 'react-dnd';

import { Box, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { DRAG_ITEM_TYPE } from './Constants';

const DraggablePublisherListItem = props => {
  const {
    field,
    fieldData,
    index,
    selectedPublisher,
    selectedPrototypeFilters,
    prototypesBySlug,
    handleListItemClick,
    onDropPublisher
  } = props;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DRAG_ITEM_TYPE.publisher,
    item: () => {
      return { field, index };
    },
    collect: monitor => {
      return {
        isDragging: monitor.isDragging()
      };
    }
  }));

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DRAG_ITEM_TYPE.publisher,
      drop: value => {
        // item being dragged index
        const oldIndex = value.index;
        // index of the item being dragged over
        const newIndex = index;
        onDropPublisher(oldIndex, newIndex);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver()
      })
    }),
    [index]
  );

  return (
    <Box
      key={field}
      ref={drop}
      sx={{
        background: isOver
          ? theme => `${theme.palette.warning[100]}`
          : 'transparent'
      }}
    >
      <ListItemButton
        ref={drag}
        key={field}
        dense
        divider
        onClick={() =>
          selectedPublisher?.field === field
            ? handleListItemClick(null)
            : handleListItemClick(field, index)
        }
        style={{
          opacity: isDragging ? 0.3 : 1,
          display:
            isEmpty(selectedPrototypeFilters) ||
            selectedPrototypeFilters.some(e => e.value === fieldData.prototype)
              ? 'flex'
              : 'none'
        }}
        selected={selectedPublisher?.field === field}
      >
        <ListItemIcon>
          <DragIndicatorIcon />
        </ListItemIcon>
        <ListItemText
          primary={<strong>{fieldData.name ?? '< --- >'}</strong>}
          secondary={prototypesBySlug?.[fieldData?.prototype]?.name ?? '-'}
        />
      </ListItemButton>
    </Box>
  );
};

export default DraggablePublisherListItem;
