import { Children, cloneElement, useState } from 'react';

import { DATA_CY_TABLE_ROW } from './constants';

const GroupedRow = ({ children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      {Children.map(children, tableRow =>
        cloneElement(tableRow, {
          expanded,
          'data-cy': DATA_CY_TABLE_ROW,
          onClickBodyRow() {
            setExpanded(!expanded);
          }
        })
      )}
    </>
  );
};

export default GroupedRow;
