import { gql } from 'src/generated/gql';

export const cloneProduct = gql(`
  mutation cloneProduct(
    $sourceProductId: ID!
    $destinationArchitectureId: ID!
    $newProductName: String!
  ) {
    cloneProduct(
      sourceProductId: $sourceProductId
      destinationArchitectureId: $destinationArchitectureId
      newProductName: $newProductName
    )
  }
`);
