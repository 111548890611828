import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { t } from 'i18next';

import Instrumentation from 'src/instrumentation';
import { CloneProgramConfirmationModal } from 'src/components/Modal/ConfirmationModal';
import { useModalState } from 'src/components/Modal';
import { handleCloneProgram } from 'src/common/cloneProgram';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { CLONE_PROGRAM_MODAL_DISMISS } from 'src/common/userSettings';
import { OrderForProgram } from 'src/pages/Programs/queries';
import {
  getProgramTrackingType,
  programActions
} from 'src/pages/Program/Constants';
import {
  hasPermissions,
  PERMISSIONS,
  Permission
} from 'src/common/permissions';
import useQuickstartHandler from 'src/pages/Program/utils/useQuickstartHandler';

interface CloseProgramProps {
  order: OrderForProgram;
}

const CloneProgram = ({ order }: CloseProgramProps) => {
  const { open, setOpen, closeModal } = useModalState(null);
  const globalContext = useGlobalContext();
  const {
    office: { isTeamsEnabled }
  } = globalContext;

  const orderPermissions = order?.accessControl?.permissions || [];

  const hasWritePermission =
    !isTeamsEnabled ||
    hasPermissions({
      permissions: [PERMISSIONS.write as Permission],
      contentPermissions: orderPermissions
    });

  const history = useHistory();
  const onCloneProgramClick = () => {
    Instrumentation.logEvent(Instrumentation.Events.ClickCloneProgram, {
      path: history.location.pathname,
      productId: order?.products?.[0]?.id,
      programId: order?.programId,
      architectureId: order?.architectureId,
      type: getProgramTrackingType({ isMultiLocation: order?.product?.isMlp })
    });

    handleCloneProgram({
      history,
      order,
      setModalOpen: setOpen,
      bypassCloneProgramModal:
        globalContext?.me?.settings?.[CLONE_PROGRAM_MODAL_DISMISS]
    });
  };

  const { blueprints, loading: blueprintsLoading } = useQuickstartHandler(
    false,
    false,
    programActions.create
  );

  // user can't clone if you don't have access to the blueprint
  const hasProductAccess = blueprints.some((blueprint: any) => {
    return blueprint.id === order?.products?.[0]?.id;
  });

  const disabled =
    !hasWritePermission ||
    blueprintsLoading ||
    !hasProductAccess ||
    !order?.product?.isAvailable;

  return (
    <>
      <Tooltip
        arrow
        title={disabled && t('programPerf:cloneProgram.disabledTooltip')}
        disableFocusListener={!disabled}
        disableHoverListener={!disabled}
      >
        <Box component="span" display="inline-block">
          <IconButton
            data-cy="clone-program-button"
            disabled={disabled}
            onClick={onCloneProgramClick}
            color="primary"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0.5
            }}
          >
            <ContentCopyIcon fontSize="small" />
            <Typography component="span" variant="body2">
              {t('programPerf:cloneModal.clone')}
            </Typography>
          </IconButton>
        </Box>
      </Tooltip>

      <CloneProgramConfirmationModal open={open} onClose={closeModal} />
    </>
  );
};

export default CloneProgram;
