import { useMemo, useState } from 'react';
import { groupBy, map, some } from 'lodash';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ListSubheader,
  ListItemIcon,
  Typography,
  Box
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

import { formatValue, getSx } from 'src/components/ReduxForm/helpers';
import { ErrorFooter } from 'src/components/ReduxForm/ErrorFooter';
import { HelperTextFooter } from 'src/components/ReduxForm/HelperTextFooter';
import { InputTooltip } from 'src/components/ReduxForm/InputTooltip';
import InputConfirmationModal from './InputConfirmationModal';

const RenderSelect = props => {
  const {
    children,
    options,
    className,
    helperText,
    input: { value, name, onChange, ...restInput },
    label,
    margin = 'normal',
    meta: { touched, error },
    placeholder,
    variant = 'outlined',
    tooltip,
    businessObjects, // was causing a warning when getting passed with ...rest
    userMetadataFields, // was causing a warning when getting passed with ...rest
    isMultiSelect,
    sx,
    showOptionCheckbox = false,
    readOnly = false,
    confirmationModal,
    hookFormField,
    isHookForm,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState('');

  const onClose = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    onChange(formatValue(selectedVal));
    setOpen(false);
  };

  const optionsNameByValue = options?.reduce((accum, option) => {
    // eslint-disable-next-line no-param-reassign
    accum[option.value] = option?.name;
    return accum;
  }, {});

  const handleChange = e => {
    const value = formatValue(e?.target?.value, isMultiSelect);

    if (confirmationModal?.getShowModalCondition(value)) {
      setOpen(true);
      setSelectedVal(value);
    } else {
      onChange(e);
    }
  };

  const renderSelectOptions = opts => {
    return opts.map(option => {
      const { sx, name } = option;
      const optionValue = option?.value;
      const description = option?.description;
      const isSelected = !!value?.includes(optionValue);

      // we can be more graceful with the UX later but for fixing a bug
      // we don't want to show options if it is read only and not selected
      if (readOnly && !isSelected) return null;

      return (
        <MenuItem
          sx={getSx(sx, option)}
          value={optionValue}
          key={`menuItem-${optionValue}`}
        >
          {showOptionCheckbox && (
            <ListItemIcon>
              {isSelected ? (
                <CheckBoxOutlinedIcon />
              ) : (
                <CheckBoxOutlineBlankOutlinedIcon />
              )}
            </ListItemIcon>
          )}
          <Box>
            {name}
            <Typography
              sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}
              variant="body2"
            >
              {description}
            </Typography>
          </Box>
        </MenuItem>
      );
    });
  };

  const renderValue = selected => {
    if (isMultiSelect) {
      const selectedByName = selected.map(value => {
        return optionsNameByValue?.[value] || value;
      });
      return selectedByName.join(', ');
    }

    return optionsNameByValue?.[selected] || selected;
  };

  const isGrouped = useMemo(() => some(options, 'group'), [options]);
  const optionsWithDescription = some(options, 'description');

  const inputInError = error && touched;

  return (
    <>
      <FormControl
        className={className}
        error={inputInError}
        fullWidth
        margin={margin}
        variant={variant}
      >
        {(label || placeholder) && (
          <InputLabel htmlFor={name}>{label || placeholder}</InputLabel>
        )}
        <Select
          inputRef={hookFormField?.ref}
          label={label || placeholder}
          sx={getSx(sx, props)}
          endAdornment={tooltip && <InputTooltip tooltipText={tooltip} />}
          multiple={isMultiSelect}
          value={formatValue(value, isMultiSelect)}
          onChange={handleChange}
          // we only need to renderValue if we are showing the checkbox otherwise it will show check boxes in the input itself vs just the dropdown
          {...((showOptionCheckbox || optionsWithDescription) && {
            renderValue
          })}
          {...restInput}
          {...rest}
        >
          {options &&
            isGrouped &&
            map(groupBy(options, 'group'), (opts, groupName) => [
              <ListSubheader>{groupName}</ListSubheader>,
              renderSelectOptions(opts)
            ])}
          {options && !isGrouped
            ? renderSelectOptions(options)
            : children && children}
        </Select>
        <HelperTextFooter
          helperText={helperText}
          touched={touched}
          error={error}
          variant={variant}
        />
        <ErrorFooter touched={touched} error={error} variant={variant} />
      </FormControl>
      <InputConfirmationModal
        onClose={onClose}
        onConfirm={onConfirm}
        open={open}
        title={confirmationModal?.title}
        body={confirmationModal?.body}
      />
    </>
  );
};

export default RenderSelect;
