import { t } from 'i18next';
import { some } from 'lodash';
import { Trans } from 'react-i18next';
import { Tooltip, Box } from '@mui/material';

import { formatDate } from 'src/common/dates';
import { formatPriceWithCents } from 'src/common/numbers';

/* 
    These functions couldn't go into the utilities file because it imports BillingPreferences which these functions
    are imported into creating a circular dependency
 */
export const getBillingHistoryColumnSchema = ({
  billingHistory,
  isTeamsEnabled
}) => {
  const billingHistoryColumnSchema = [
    {
      columnName: t('billing.schema.date'),
      accessor: 'createdOn',
      type: 'DATE',
      CellComponent: ({ data }) => {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate({
              date: data,
              format: 'MMM DD, YYYY'
            })}
          </span>
        );
      }
    }
  ];

  if (isTeamsEnabled) {
    billingHistoryColumnSchema.push({
      columnName: t('billing.schema.initiatedBy'),
      accessor: 'billedBy',
      type: 'STRING'
    });
  }

  billingHistoryColumnSchema.push(
    {
      columnName: t('billing.schema.programName'),
      accessor: 'programName',
      type: 'STRING'
    },
    {
      columnName: t('billing.schema.type'),
      accessor: 'offerType',
      type: 'STRING'
    },
    {
      columnName: t('billing.schema.renewsOn'),
      accessor: 'renewsOn',
      type: 'DATE',
      CellComponent: blob => {
        return formatDate({
          date: blob?.data,
          format: 'MMM DD, YYYY'
        });
      }
    },
    {
      columnName: t('billing.schema.card'),
      accessor: 'cardInfo',
      type: 'STRING'
    },
    {
      columnName: t('billing.schema.promoCode'),
      accessor: 'promoCode',
      type: 'STRING',
      CellComponent: blob => {
        const promoCode = blob?.data;
        return promoCode || '-';
      }
    },
    {
      columnName: t('billing.schema.amount'),
      accessor: 'amount',
      type: 'CURRENCY',
      CellComponent: blob => {
        return formatPriceWithCents(blob?.data);
      }
    },
    {
      columnName: t('billing.schema.orderId'),
      accessor: 'orderId',
      type: 'STRING'
    }
  );

  // only add cancellation row if an order has a cancellation
  if (some(billingHistory?.rawRows, 'node.cancellationDetails')) {
    billingHistoryColumnSchema.push({
      columnName: t('billing.schema.refund'),
      key: 'cancellation',
      type: 'STRING',
      CellComponent: ({ row }) => {
        const details = row?.cancellationDetails;
        if (!details) {
          return '-';
        }
        return (
          <Tooltip
            title={
              <table>
                <tbody>
                  <tr>
                    <th>
                      <Trans i18nKey="programPerf:cancelModal.purchaseAmt">
                        Purchase Amount:
                      </Trans>
                    </th>
                    <td>
                      {formatPriceWithCents(details?.originalPurchaseAmount)}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans i18nKey="programPerf:cancelModal.spendRemaining">
                        Spend Remaining:
                      </Trans>
                    </th>
                    <td>
                      {formatPriceWithCents(
                        details?.amountAfterCalculatedUsage
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <Trans i18nKey="programPerf:cancelModal.cancelationFee">
                        Processing Fee:
                      </Trans>
                    </th>
                    <td>- {formatPriceWithCents(details?.cancellationFee)}</td>
                  </tr>
                  <tr>
                    <th>
                      <Trans i18nKey="programPerf:cancelModal.totalRefund">
                        Total Refund:
                      </Trans>
                    </th>
                    <td>{formatPriceWithCents(details?.refundAmount)}</td>
                  </tr>
                </tbody>
              </table>
            }
          >
            <Box
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
            >
              {formatPriceWithCents(details?.refundAmount)}
            </Box>
          </Tooltip>
        );
      }
    });
  }

  return billingHistoryColumnSchema;
};

export const getMediaBillingHistoryColumnSchema = () => {
  return [
    {
      columnName: t('billing.mediaSchema.date'),
      accessor: 'createdOn',
      type: 'DATE',
      CellComponent: ({ data }) => {
        return (
          <span style={{ whiteSpace: 'nowrap' }}>
            {formatDate({
              date: data,
              format: 'MMM DD, YYYY'
            })}
          </span>
        );
      }
    },
    {
      columnName: t('billing.mediaSchema.id'),
      accessor: 'mediaAssetId',
      type: 'ID'
    },
    {
      columnName: t('billing.mediaSchema.type'),
      accessor: 'mediaType',
      type: 'STRING'
    },
    {
      columnName: t('billing.mediaSchema.source'),
      accessor: 'mediaSource',
      type: 'STRING'
    },
    {
      columnName: t('billing.mediaSchema.card'),
      accessor: 'cardInfo',
      type: 'STRING'
    },
    {
      columnName: t('billing.mediaSchema.amount'),
      accessor: 'amount',
      type: 'CURRENCY',
      CellComponent: blob => {
        return formatPriceWithCents(blob?.data);
      }
    }
  ];
};
