import { gql } from 'src/generated/gql';

export const setDefaultFacebookPage = gql(`
  mutation updateUserSettingsFromFacebookPage(
    $updateSettings: JSON!
    $updateType: SettingsUpdateType
  ) {
    modifyUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);

export const shareFacebookPage = gql(`
  mutation ShareFacebookPage($facebookPageSettingsId: ID!) {
    shareFacebookPage(facebookPageSettingsId: $facebookPageSettingsId) {
      id
      userId
      groupId
      pageId
      pageName
      facebookUserId
      facebookDisplayName
      instagramId
      hasAcceptedTos
      pageStatus
      businessManagerId
      createdAt
      updatedAt
      teamCapabilities {
        isOwnedByMe
        isSharedWithMe
        isSharedWithTeam
      }
      activeOrders {
        id
        name
        architectureId
        supervisorOrder { id }
      }
      accessControl {
        permissions(filter: {})
      }
    }
  }
`);

export const unshareFacebookPage = gql(`
  mutation UnshareFacebookPage($facebookPageSettingsId: ID!) {
    unshareFacebookPage(facebookPageSettingsId: $facebookPageSettingsId) {
      id
      userId
      groupId
      pageId
      pageName
      facebookUserId
      facebookDisplayName
      instagramId
      hasAcceptedTos
      pageStatus
      businessManagerId
      createdAt
      updatedAt
      teamCapabilities {
        isOwnedByMe
        isSharedWithMe
        isSharedWithTeam
      }
      activeOrders {
        id
        name
        architectureId
        supervisorOrder { id }
      }
      accessControl {
        permissions(filter: {})
      }
    }
  }
`);

export const disassociateFacebookPage = gql(`
  mutation disassociateFacebookPage($facebookPageSettingsId: ID!) {
    disassociateFacebookPage(facebookPageSettingsId: $facebookPageSettingsId)
  }
`);
