import { Typography } from '@mui/material';

const SummaryLabel = ({ children }) => {
  return (
    <Typography variant="body1" sx={{ fontWeight: 500, textWrap: 'balance' }}>
      {children}
    </Typography>
  );
};

export default SummaryLabel;
