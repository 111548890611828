import { useState } from 'react';
import LazyLoad from 'react-lazy-load';

import { Tooltip, Skeleton } from '@mui/material';

const IMAGE_SIZE = 50;

const ImageCell = ({ value }: { value: string }) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Tooltip
      key={value}
      title={
        <LazyLoad width={200} height={200} threshold={0.95}>
          <img
            style={{
              objectFit: 'cover'
            }}
            width="200"
            height="200"
            src={value}
            alt=""
          />
        </LazyLoad>
      }
    >
      <div
        style={{
          width: `${IMAGE_SIZE}px`,
          height: `${IMAGE_SIZE}px`
        }}
      >
        {!loaded && <Skeleton width={IMAGE_SIZE} height={IMAGE_SIZE} />}
        <LazyLoad
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          onContentVisible={() => setLoaded(true)}
        >
          <img
            style={{
              objectFit: 'cover'
            }}
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
            src={value}
            alt=""
          />
        </LazyLoad>
      </div>
    </Tooltip>
  );
};

export default ImageCell;
