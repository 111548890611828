import { Component } from 'react';
import { flow, get, head, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { FetchMoreFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { ApolloError } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { History } from 'history';
import { i18n } from 'i18next';

import { Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { AppSettingsContextType, withAppSettings } from 'src/AppSettings';

import { isApolloLoading } from 'src/common/apollo';
import { demoUserIds } from 'src/common/demoUser/demoUsers';
import { demoUserData } from 'src/common/demoUser/demoUserData';
import formatters from 'src/common/formatters';

import { paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { withGlobalContext } from 'src/GlobalContextProvider';
import {
  UserLeadsByProgramIdQuery,
  UserLeadsByProgramIdQueryVariables
} from 'src/generated/gql/graphql';

import Loading from 'src/components/Loading';
import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import Table from 'src/components/Table';
import Modal from 'src/components/Modal';
import LoadMore from 'src/components/Pagination/LoadMore';

import Instrumentation from 'src/instrumentation';
import { WithStyledClasses } from 'src/common/Style';

import LeadsExport from './LeadsExport';
import LeadsModal from './LeadsModal';
import { getLeads } from './queries';

interface Edge {
  node: {
    id: string;
    architectureId: string;
    programId: string;
    orderId: string;
    submittedAt: Date;
    fields: {
      name: string;
      values: any[];
    }[];
  };
}

interface Row {
  leadId: string;
  key: string;
  architectureId: string;
  programId: string;
  orderId: string;
  date: Date;
}

type Data = Edge[];

const mungeData = (data: Data = []) =>
  data.reduce<Row[]>(
    (accum, { node: curr }) => [
      ...accum,
      {
        leadId: curr.id,
        key: curr.id,
        architectureId: curr.architectureId,
        programId: curr.programId,
        orderId: curr.orderId,
        date: curr.submittedAt,
        ...curr.fields.reduce<{ fullName?: string }>(
          (accum, curr) => ({
            ...accum,
            [curr.name]: head(curr.values),
            // combine first and last
            ...(curr.name === 'first_name' && {
              fullName: `${head(curr.values)} ${accum.fullName || ''}`
            }),
            ...(curr.name === 'last_name' && {
              fullName: `${accum.fullName || ''}${head(curr.values)}`
            }),
            ...(curr.name === 'full_name' && {
              fullName: head(curr.values)
            })
          }),
          {}
        )
      }
    ],
    []
  );

const getDemoLeads = (userId: string, appSettings: AppSettingsContextType) => {
  const localOverrideUserLeads = appSettings?.app?.demoOverrides?.userLeads;

  let demoUserLeads = null;

  // Demo overrides for Demo Users.
  if (demoUserIds[userId]) {
    demoUserLeads = demoUserData?.userLeads;
  }

  // Always return local overrides if those are set.
  if (localOverrideUserLeads) {
    demoUserLeads = localOverrideUserLeads;
  }

  return demoUserLeads?.edges || [];
};

const styles = () => ({
  root: {}
});

interface LeadsTableProps {
  programId?: string;
  // architectureId is not used in this component but it is passed in as a prop where this component is used.
  architectureId?: string;
}

interface InternalLeadsTableProps extends LeadsTableProps {
  classes: WithStyledClasses<typeof styles>;
  history: History;
  t: i18n['t'];
  loading: boolean;
  error?: ApolloError;
  userLeads: Data;
  appSettings: AppSettingsContextType;
  globalContext: { me: { id: string } };
  pageInfo: any;
  leadsError?: ApolloError;
  fetchMore: FetchMoreFunction<
    UserLeadsByProgramIdQueryVariables,
    UserLeadsByProgramIdQuery
  >;
}

interface LeadsTableState {
  modal: Row | null;
}

class LeadsTable extends Component<InternalLeadsTableProps, LeadsTableState> {
  constructor(props: InternalLeadsTableProps) {
    super(props);
    this.state = {
      modal: null
    };
  }

  openInfoModal = (row: Row) => {
    this.setState({
      modal: row
    });
  };

  closeInfoModal = () => {
    this.setState({
      modal: null
    });
  };

  navigatetoLeadDetails = (row: Row) => {
    const { history } = this.props;
    Instrumentation.logEvent(Instrumentation.Events.ViewContactClicked);
    history.push(
      generateLinkPath(paths.dashboard.leadDetails, {
        leadId: row.leadId
      })
    );
  };

  render() {
    const {
      classes,
      t,
      userLeads = [],
      fetchMore,
      pageInfo,
      programId,
      loading,
      error,
      leadsError
    } = this.props;
    const { modal } = this.state;

    const columnSchema = [
      {
        columnName: t('leads:leadsTable.nameTh'),
        accessor: 'fullName',
        type: 'NAME',
        CellComponent: ({ data }: { data: string }) => <div>{data}</div>
      },
      // Commenting out until data is connected.
      // {
      //     columnName: t('leads:leadsTable.sourceTh', ),
      //     accessor: 'adTitle',
      //     type: 'STRING'
      // },
      {
        columnName: t('leads:leadsTable.emailTh'),
        accessor: 'email',
        type: 'TEXT'
      },
      {
        columnName: t('leads:leadsTable.phoneTh'),
        // eslint-disable-next-line camelcase
        accessor: (d: { phone: string; phone_number: string }) => {
          if (d.phone || d.phone_number) {
            return formatters.PHONE(d.phone || d.phone_number);
          }
          return '-';
        },
        type: 'PHONE',
        key: 'phone'
      },
      {
        columnName: t('leads:leadsTable.dateTh'),
        accessor: 'date',
        type: 'datetime_utc'
      }
      // we don't need to have status for now (nice to have future feature)
      // {
      //     columnName: t('leads:leadsTable.statusTh', ),
      //     accessor: 'status',
      //     type: 'TEXT'
      // }
    ];

    const hasError = error || leadsError;

    if (loading || hasError) {
      return (
        <Loading error={hasError} errorMessage={t('leads:error.default')} />
      );
    }

    const fillData = mungeData(userLeads);

    let content: string | JSX.Element = '';

    if (isEmpty(fillData)) {
      content = <TableEmptyState emptyMessage={t('leads:table.emptyText')} />;
    } else {
      content = (
        <>
          <Paper className={classes.root}>
            <LeadsExport programId={programId} />
            <Table
              columnSchema={columnSchema}
              rows={fillData}
              onClickBodyRow={this.navigatetoLeadDetails}
              loading={false}
            />
          </Paper>
          <Modal
            open={!!modal}
            onClose={this.closeInfoModal}
            headerText={t('leads:detailsModal.title')}
          >
            {modal && <LeadsModal {...modal} />}
          </Modal>
        </>
      );
    }

    return (
      <>
        {content}
        <LoadMore
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          mergePath="userLeads.edges"
        />
      </>
    );
  }
}

export default flow(
  withStyles(styles),
  // Unable to properly type this graphql call. I verified that the props are being passed in correctly.
  // After struggling with a solution and consulting with Cameron, we decided to leave this as is.
  // When this component is refactored to use hooks, we should be able to acheive proper typing.
  graphql(getLeads, {
    name: 'userLeads',
    options: ({ programId }: any) => ({
      variables: {
        ...(programId && { programId }),
        first: 15
      }
    }),
    props: ({ userLeads, error, ownProps }: any) => {
      const {
        userLeads: leads,
        networkStatus,
        refetch,
        fetchMore,
        error: leadsError
      } = userLeads;

      const { appSettings, globalContext } = ownProps;
      const userId = globalContext?.me?.id;

      // Note: this is dumb hack but seems to be the only way to deal with the issues of apollo not
      //       triggering the loading state to change on setVariables when we change pages and the
      //       architectureId is updated.
      const isLoading = isApolloLoading(networkStatus);

      const edges = get(leads, 'edges') || [];

      const userLeadsDemoOverrides = getDemoLeads(userId, appSettings);

      // Merge demo override leads with leads that come from the db.
      const allLeads = [...userLeadsDemoOverrides, ...edges];

      return {
        userLeads: allLeads,
        pageInfo: get(leads, 'pageInfo'),
        refetch,
        fetchMore,
        loading: isLoading,
        error,
        leadsError
      };
    }
  }),
  withGlobalContext,
  withTranslation('leads'),
  withRouter,
  withAppSettings
)(LeadsTable) as React.ComponentType<LeadsTableProps>;
