import { gql } from 'src/generated/gql';

export const getLocationsForSelection = gql(`
  query getlocationsForSelection(
    $first: Int!
    $after: String
    $filter: LocationsQueryFilter
  ) {
    locations(
      first: $first
      after: $after
      filter: $filter
    ) {
        edges {
          cursor
          node {
            id
            organizationId
            ownerId
            type
            name
            street
            region
            city
            postalCode
            latitude
            longitude
            district
            territory
            country
            websiteUrl
            referenceLocation
            phone
            emailAddress
            description
            isOpen
            openDate
            createdAt
            updatedAt
            deletedAt
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
    }
  }
`);

export const getAllLocations = gql(`
  query getAllLocations(
    $filter: LocationsQueryFilter
  ) {
    locations(
      first: 5000
      filter: $filter
    ) {
        edges {
          cursor
          node {
            id
            organizationId
            ownerId
            type
            name
            street
            region
            city
            postalCode
            websiteUrl
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
    }
  }
`);

export const getLocationsForManagement = gql(`
  query getlocationsForManagement(
    $first: Int!
    $after: String
    $filter: LocationsQueryFilter
  ) {
    locations(
      first: $first
      after: $after
      filter: $filter
    ) {
        edges {
          cursor
          node {
            id
            organizationId
            ownerId
            type
            name
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
    }
  }
`);
