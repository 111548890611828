import { Box, MenuItem, Tooltip } from '@mui/material';
import {
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';

interface TooltipMenuItemProps {
  tooltipTitle: React.ReactNode;
  value: string;
  children: React.ReactNode;
  type?: 'error' | 'success';
}

const TooltipMenuItem = ({
  tooltipTitle,
  value,
  children,
  type = 'error'
}: TooltipMenuItemProps) => {
  return (
    <Tooltip
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -200]
              }
            }
          ]
        }
      }}
      placement="right"
      title={tooltipTitle}
    >
      {/* Span is required so that tooltip onHover works while disabled is true */}
      <Box
        component="span"
        onClick={e => {
          // This prevents the click from registering on the disabled menu item inside the tooltip
          e.preventDefault();
        }}
      >
        <MenuItem value={value} disabled>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5
            }}
          >
            {children}

            {type === 'error' ? (
              <ErrorIcon sx={{ fontSize: 18, color: 'error.main' }} />
            ) : (
              <CheckCircleIcon sx={{ fontSize: 18, color: 'success.main' }} />
            )}
          </Box>
        </MenuItem>
      </Box>
    </Tooltip>
  );
};

export default TooltipMenuItem;
