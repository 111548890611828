import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Typography, Link } from '@mui/material';
import Modal, { ModalHeader } from 'src/components/Modal';
import { useAppSettings } from 'src/AppSettings';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import { userAcceptTos } from './mutations';

// modal that shows the accept terms of service

const TermsOfServiceModal = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const appSettings = useAppSettings();
  const [acceptTos] = useMutation(userAcceptTos);
  const { enqueueSnackbar } = useSnackbar();
  const handleAcceptTerms = () => {
    acceptTos({
      variables: {
        input: {
          userAgent: navigator.userAgent
        }
      }
    })
      .then(() => {
        // no need to do anything fancy we're blocking the page load anyways
        window.location.reload();
      })
      .catch(() => {
        enqueueSnackbar(t('common:tos.modalError'), { variant: 'error' });
      });
  };
  return (
    <Modal
      fullWidth
      showClose={false}
      open
      HeaderComponent={() => (
        <ModalHeader>{t('common:tos.tosTitle')}</ModalHeader>
      )}
      FooterComponent={() => (
        <>
          <Button
            disabled={!isAccepted}
            variant="contained"
            onClick={handleAcceptTerms}
          >
            {t('common:tos.modalAccept')}
          </Button>
        </>
      )}
    >
      <Typography variant="body1" gutterBottom>
        {t('common:tos.modalBody')}
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        onClick={() => {
          setIsAccepted(x => !x);
        }}
      >
        <Checkbox
          checked={isAccepted}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setIsAccepted(event.target.checked);
          }}
        />{' '}
        {t('common:tos.modalDisclaimer')}{' '}
        <Link
          href={appSettings?.organizationTos?.link}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {t('common:tos.tosTitle')}
        </Link>
      </Typography>
    </Modal>
  );
};

export default TermsOfServiceModal;
