import { flow } from 'lodash';
import { Theme, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Trans } from 'react-i18next';

import { formatDate } from 'src/common/dates';
import { WithStyledClasses } from 'src/common/Style';

const style = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: theme.spacing(50)
  },
  dl: {
    float: 'left' as const,
    width: '100%'
  },
  dt: {
    fontWeight: 'bold',
    float: 'left' as const,
    clear: 'left' as const
  },
  dd: {
    float: 'left' as const,
    marginInlineStart: '0px',
    paddingLeft: '1em'
  },
  image: {
    width: theme.spacing(50)
  }
});

interface LeadsModalProps {
  classes: WithStyledClasses<typeof style>;
  fullName: string;
  adTitle: string;
  date: Date;
  email: string;
  adImageUrl: string;
}

const LeadsModal = ({
  classes,
  fullName,
  adTitle,
  date,
  email,
  adImageUrl
}: LeadsModalProps) => {
  return (
    <div className={classes.root}>
      <dl className={classes.dl}>
        <dt className={classes.dt}>
          <Trans i18nKey="programPerf:detailsModal.name">Name</Trans>:
        </dt>
        <dd className={classes.dd}>{fullName}</dd>
        <dt className={classes.dt}>
          <Trans i18nKey="programPerf:detailsModal.submitted">Submitted</Trans>:
        </dt>
        <dd className={classes.dd}>{formatDate({ date })}</dd>
        <dt className={classes.dt}>
          <Trans i18nKey="programPerf:detailsModal.email">Email</Trans>:
        </dt>
        <dd className={classes.dd}>{email}</dd>
      </dl>
      <Typography variant="h5" gutterBottom>
        <Trans i18nKey="programPerf:detailsModal.leadSource">
          Contact Source
        </Trans>
      </Typography>
      <img src={adImageUrl} alt={adTitle} className={classes.image} />
      <p>{adTitle}</p>
    </div>
  );
};

export default flow(withStyles(style))(LeadsModal);
