import { useState } from 'react';
import { t } from 'i18next';

import { Button, Grid, Typography } from '@mui/material';

import Modal from 'src/components/Modal';
import Table from 'src/components/Table';

import TierForm from './Forms/TierConfigurationForm';

const TieringConfiguration = props => {
  const {
    selectedPricingPlan,
    tiers,
    isPricingPlansLoading,
    selectedTier,
    refetch
  } = props;
  const [editModal, setEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(selectedTier);
  const [openModal, setOpenModal] = useState(false);
  const tiersDisplayed = tiers?.map(tier => ({
    ...tier,
    isActive: tier.isActive ? 'Active' : 'Inactive'
  }));

  const columnSchema = [
    { accessor: 'name', columnName: 'Name', key: 'name', type: 'String' },
    { accessor: 'slug', columnName: 'Slug', key: 'slug', type: 'String' },
    {
      accessor: 'amount',
      columnName: 'Amount',
      key: 'amount',
      type: 'Number'
    },
    {
      accessor: 'isActive',
      columnName: 'Is Active',
      key: 'isActive',
      type: 'String'
    }
  ];

  const closeModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  const titleText = t('admin:tierConfiguration.modalTitle');
  const bodyText = t('admin:tierConfiguration.description');
  const headerText = t('admin:tierConfiguration.header');

  return (
    <>
      <div>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <Typography
              component="h6"
              variant="h6"
              sx={{ marginBottom: theme => theme.spacing(2) }}
            >
              {titleText}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {bodyText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {tiersDisplayed.length ? (
              <div data-cy="tier-table">
                <Table
                  rows={tiersDisplayed}
                  columnSchema={columnSchema}
                  loading={isPricingPlansLoading}
                  onClickBodyRow={row => {
                    const isActive = row.isActive === 'Active';
                    setEditModal(true);
                    setOpenModal(true);
                    setSelectedRow({ ...row, isActive });
                  }}
                />
              </div>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              onClick={() => {
                setEditModal(false);
                setOpenModal(true);
              }}
              variant="contained"
              color="primary"
              data-cy="create-new-tier-button"
            >
              Create new
            </Button>
          </Grid>
        </Grid>
        <Modal
          data-cy="tier-form-modal"
          fullWidth
          maxWidth="md"
          showClose
          headerText={headerText}
          open={openModal}
          onClose={closeModal}
          FooterComponent={() => (
            <div>
              <Button
                onClick={() => {
                  setOpenModal(false);
                }}
                variant="contained"
                color="primary"
                data-cy="tier-close-modal"
              >
                Close Modal
              </Button>
            </div>
          )}
        >
          <div>
            <Typography variant="h4" color="textPrimary">
              Tier
            </Typography>
            <TierForm
              initalValues={selectedTier}
              pricingPlanId={selectedPricingPlan?.id}
              editModal={editModal}
              selectedTier={selectedRow}
              refetch={refetch}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TieringConfiguration;
