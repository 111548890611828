import { History } from 'history';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { UpdateSupervisorOrderMutation } from 'src/generated/gql/graphql';
import { formatAutomatedProgramsMutationParams } from 'src/pages/Program/utils/submitHandlers/utils';

import { trackOrderPlacementAutomation } from './trackOrders';
import { submitProgramPropsInterface } from './useSubmitHandlerByType';

// TODO: update type interface
export interface submitEditAutomationDataInterface {
  [any: string]: any;
}

export interface editAutomationResponseInterface {
  data: UpdateSupervisorOrderMutation;
}

// TODO: update type interface
export interface editAutomationSubmitDataInterface {
  data: submitEditAutomationDataInterface;
  programProps: submitProgramPropsInterface;
  mutation: any;
  history: History;
  enqueueSnackbar: any;
}

export const editAutomationHandleSubmit = async ({
  data,
  programProps,
  enqueueSnackbar,
  history,
  mutation
}: editAutomationSubmitDataInterface) => {
  const { architecture, selectedBlueprint } = programProps;

  const mutationParams = formatAutomatedProgramsMutationParams({
    data,
    architecture,
    selectedBlueprint,
    isEdit: true
  });

  let response: editAutomationResponseInterface;
  const postErrorMessage = t('automatedProgramCreate:snackbar.orderError');

  try {
    response = await mutation({
      variables: {
        input: mutationParams
      }
    });
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw {
      error,
      postErrorMessage
    };
  }

  return {
    response,
    afterSuccess: () => {
      enqueueSnackbar(t('automatedProgramCreate:snackbar.updateSuccess'), {
        variant: 'success',
        persist: false
      });

      trackOrderPlacementAutomation({
        mutationParams,
        response,
        selectedBlueprint: programProps?.selectedBlueprint,
        isEdit: true
      });

      history.push(
        generateLinkPath(paths.architecture.automatedProgram, {
          architectureId: architecture.id,
          programId: response?.data?.updateSupervisorOrder?.id
        })
      );
    }
  };
};
