import { cloneDeep, some } from 'lodash';

export const formatContentTableColumns = contentTableMetadata => {
  return (contentTableMetadata?.contentTableColumns || []).filter(
    column =>
      // we just filter out these system columns
      !some(
        [
          'feed_item_created_at',
          'feed_item_updated_at',
          'edit_item_created_at',
          'edit_item_updated_at'
        ],
        id => column === id
      )
  );
};

// We receive the access control field from the content set as `isAccessControlled`
// but in UpdateContentSetInput the field is `accessControlled`
export const formatContentSetSubmissionData = formData => {
  const formatted = cloneDeep(formData);
  formatted.accessControlled = formData.isAccessTableControlled;
  delete formatted.isAccessTableControlled;

  return formatted;
};
