import { gql } from 'src/generated/gql';

export const createPromotion = gql(`
  mutation CreatePromotion($createRequest: PromotionCreateRequest!) {
    createPromotion(createRequest: $createRequest) {
      id
    }
  }
`);

export const updatePromotion = gql(`
  mutation UpdatePromotion($updateRequest: PromotionUpdateRequest!) {
    updatePromotion(updateRequest: $updateRequest) {
      id
    }
  }
`);
