import { get } from 'lodash';

import { SvgIcon } from '@mui/material';

import withTheme from '@mui/styles/withTheme';
import { Theme } from '@mui/system';

export interface GoogleProps {
  theme: Theme;
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

const Google = ({ theme, fontSize = 'medium' }: GoogleProps) => {
  return (
    <SvgIcon fontSize={fontSize}>
      <g fill="none" fillRule="evenodd">
        {/* capline barb */}
        <path
          fill={get(theme, 'palette.blueprintIcons.channel.google.red')}
          d="M1.134 5.37L4.489 7.95a5.882 5.882 0 0 1 9.695-2.078l2.904-2.926A9.968 9.968 0 0 0 10 0a10 10 0 0 0-8.866 5.37"
        />
        {/* stress */}
        <g transform="translate(0 5.143)">
          <path
            fill={get(theme, 'palette.blueprintIcons.channel.google.yellow')}
            d="M4.474 6.868a5.876 5.876 0 0 1-.353-2.011c0-.721.13-1.412.368-2.05L1.134.226A9.952 9.952 0 0 0 0 4.858a9.95 9.95 0 0 0 1.098 4.56l3.376-2.55z"
          />
        </g>
        {/* baseline spur */}
        <g transform="translate(.571 11.429)">
          <path
            fill={get(theme, 'palette.blueprintIcons.channel.google.green')}
            d="M12.854 3.35A5.881 5.881 0 0 1 3.877.511L.52 3.117a10 10 0 0 0 8.91 5.454c2.58 0 4.93-.977 6.704-2.58l-3.279-2.643z"
          />
        </g>
        {/* counter / barb / chin */}
        <path
          fill={get(theme, 'palette.blueprintIcons.channel.google.blue')}
          d="M10.06 7.927v4.121h5.452a5.893 5.893 0 0 1-2.083 2.728l3.276 2.643A9.974 9.974 0 0 0 20 10c0-.71-.074-1.403-.215-2.072h-9.724z"
        />
      </g>
    </SvgIcon>
  );
};

export default withTheme<Theme, typeof Google>(Google);
