import { gql } from 'src/generated/gql';

export const getArchitectureById = gql(`
  query getArchitectureAndCatalogByArchitectureId($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      catalog {
        id
        contentSetId
        defaultGroupKey
        displayCollapseKey
        displayNameTemplate
        displayImageTemplate
        friendlyName
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          contentColumnType
          isFilterable
          enumValues
        }
      }
    }
  }
`);
