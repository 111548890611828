import { styled } from '@mui/system';
import { CircularProgress, Typography } from '@mui/material';

export const AiErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  paddingLeft: theme.spacing(1)
}));

export const AiLoadingSpinner = styled(CircularProgress)(() => ({
  // Then just vertically center it
  position: 'absolute',
  top: 0,
  bottom: 0,
  marginTop: 'auto',
  marginBottom: 'auto'
}));

// This should match up with the typography of the button, but for some reason
// the theme's button fontsize was just a bit too small. Hardcoding instead.
export const aiLoadingSpinnerSize = 20;
