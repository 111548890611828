import { useState } from 'react';
import { Field, FieldArray } from 'redux-form';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { IconButton, TextField, useTheme } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';
import RenderSwitch from 'src/components/ReduxForm/RenderSwitch';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';

import RenderSectionHeading from './RenderSectionHeading';
import RenderSectionInputs from './RenderSectionInputs';

const pageText = () => ({
  sectionDescriptionLabel: t(
    'blueprintBuilder:configureUserInputs.SectionDescription'
  ),
  sectionCollapsedLabel: t(
    'blueprintBuilder:configureUserInputs.CollpaseSection'
  ),
  sectionCollapsedTooltip: t(
    'blueprintBuilder:configureUserInputs.CollapsedTooltip'
  )
});

const RenderConfigureUserInputs = props => {
  const { fields } = props;
  const [newSectionName, setNewSectionName] = useState('');
  const theme = useTheme();

  const text = pageText();

  const addSection = () => {
    fields.push({
      fields: [],
      mutable: false,
      name: newSectionName,
      description: '',
      collapsed: false
    });
    setNewSectionName('');
  };

  return (
    <div>
      {fields.map((field, index) => {
        return (
          <div key={`${field}.container`}>
            <Field
              key={`${field}.heading`}
              component={RenderSectionHeading}
              name={`${field}.name`}
              onRemove={() => fields.remove(index)}
            />
            <Field
              key={`${field}.description`}
              component={RenderTextField}
              name={`${field}.description`}
              label={text.sectionDescriptionLabel}
            />
            <Field
              component={RenderSwitch}
              name={`${field}.collapsed`}
              label={text.sectionCollapsedLabel}
              tooltip={text.sectionCollapsedTooltip}
            />
            <FieldArray
              key={field}
              component={RenderSectionInputs}
              name={`${field}.fields`}
              validate={[fieldArrayFixDumbReduxFormError]}
            />
          </div>
        );
      })}

      <TextField
        sx={{
          margin: `${theme.spacing(2)} 0`,
          width: '100%'
        }}
        value={newSectionName}
        onChange={({ target: { value } }) => {
          setNewSectionName(value);
        }}
        onKeyPress={({ key }) => {
          if (key === 'Enter') {
            addSection();
          }
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={addSection} size="small">
              <AddIcon />
            </IconButton>
          )
        }}
        label={
          <Trans i18nKey="blueprintBuilder:configureUserInputs.AddSection">
            Add Section
          </Trans>
        }
      />
    </div>
  );
};

export default RenderConfigureUserInputs;
