import { gql } from 'src/generated/gql';

export const createBillingConfig = gql(`
  mutation createBillingConfig(
    $stripeKey: String!
    $stripePublicKey: String!
    $cancellationFeePercentage: Float!
  ) {
    createBillingConfig(
      stripeKey: $stripeKey
      stripePublicKey: $stripePublicKey
      cancellationFeePercentage: $cancellationFeePercentage
    ) {
      stripePublicKey
      cancellationFeePercentage
    }
  }
`);

export const updateBillingConfig = gql(`
  mutation updateBillingConfig(
    $stripeKey: String
    $stripePublicKey: String
    $cancellationFeePercentage: Float
  ) {
    updateBillingConfig(
      stripeKey: $stripeKey
      stripePublicKey: $stripePublicKey
      cancellationFeePercentage: $cancellationFeePercentage
    ) {
      stripePublicKey
      cancellationFeePercentage
    }
  }
`);

export const createPrePayAccount = gql(`
  mutation createPrePayAccount($input: PrePayAccountInput) {
    createPrePayAccount(input: $input) {
      id
      organizationId
      effectiveFrom
      effectiveUntil
      initialAmount
      balanceAmount
      createdAt
      deletedAt
    }
  }
`);

export const deletePrePayAccountById = gql(`
  mutation deletePrePayAccountById($id: ID!) {
    deletePrePayAccountById(id: $id)
  }
`);
