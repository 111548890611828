import { flow } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import Error from '@mui/icons-material/Error';

import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  errorIcon: {
    color: theme.palette.error.light,
    height: '300px',
    width: '300px'
  },
  button: {
    margin: theme.spacing(1)
  }
});

const InitialLoadError = ({
  classes,
  customErrorHeader,
  customErrorMessage = null,
  appSettings
}) => {
  let errorHeader = t('errorPage:initialLoadError.ohNo');

  if (customErrorHeader) {
    errorHeader = customErrorHeader;
  }

  let errorMessage = t('errorPage:initialLoadError.somethingWentWrong');

  if (customErrorMessage) {
    errorMessage = customErrorMessage;
  }

  const supportEmail = appSettings?.app?.support?.email;

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs>
        <Error className={classes.errorIcon} />
        <Typography component="h1" variant="h2">
          {errorHeader}
        </Typography>
        <Typography component="h2" variant="h5">
          {errorMessage}
        </Typography>
      </Grid>
      <Grid item xs>
        <Button
          onClick={() => window.location.reload()}
          className={classes.button}
          color="primary"
          size="large"
          variant="outlined"
        >
          <Trans i18nKey="errorPage:initialLoadError.buttons.refreshPage">
            Refresh Page
          </Trans>
        </Button>
        {supportEmail && (
          <Button
            className={classes.button}
            href={`mailto:${supportEmail}?subject=Office Site Error`}
            size="large"
            variant="outlined"
          >
            <Trans i18nKey="errorPage:initialLoadError.buttons.contactSupport">
              Contact Support
            </Trans>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default flow(withStyles(styles), withAppSettings)(InitialLoadError);
