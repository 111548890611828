import { s3BaseUrl } from 'src/components/AdPreview/S3Image';
import { ReactNode } from 'react';

export interface AudienceDownloadCsvLinkProps {
  children?: ReactNode;
}

export const AudienceDownloadCsvLink = ({
  children
}: AudienceDownloadCsvLinkProps) => {
  return (
    // These are covered since there will be text placed inside by i18next
    // eslint-disable-next-line jsx-a11y/control-has-associated-label,jsx-a11y/anchor-has-content
    <a
      href={`${s3BaseUrl}files/audience/audienceExample.csv`}
      target="_blank"
      data-cy="sample-csv-button"
      rel="noreferrer noopener"
    >
      {children}
    </a>
  );
};
