import { gql } from 'src/generated/gql';

export const getGalleries = gql(`
  query getGalleries {
    galleries {
      id
      name
      galleryType
    }
  }
`);

export const assetTypeIntrospection = gql(`
  query assetTypeIntrospection {
    __type(name: "AssetType") {
      name
      enumValues {
        name
      }
    }
  }
`);
