import { AiTextStylizationButton } from 'src/components/AiSuggestion/AiTextStylizationButton';
import { FlexExpander } from 'src/components/Styling/FlexExpander';
import AiTextSuggestionButton from 'src/components/AiSuggestion/AiTextSuggestionButton';
import { styled } from '@mui/system';

const AiButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.grey[100],
  borderBottomRightRadius: '4px',
  borderBottomLeftRadius: '4px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderTop: 'none',
  borderColor: 'rgba(0,0,0,0.23)',
  marginBottom: theme.spacing(1)
}));

/**
 * sx props to attach onto the parent text field.
 * This makes the AiTextFooter component visually line up with the
 * parent text field.
 */
export const AiTextFieldSx = {
  marginBottom: 0,
  '& .MuiInputBase-root': {
    // Cancel out the existing border radius
    borderRadius: 0,
    // So we only round the top
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderBottom: 'none'
  }
};

/**
 * sx props to attach onto the parent template text field.
 * This makes the AiTextFooter component visually line up with the
 * parent template text field.
 */
export const AiTemplateTextFieldSx = {
  borderRadius: 0,
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderBottom: 'none',
  marginBottom: 0
};

export interface AiTextFooterProps {
  onAiTextChange: (text: string) => void;
  productId: string;
  blueprintVariableId: string;
  catalogItemId: string | undefined;
  currentValue: string;
  storeGenAiText?: (text: string) => void;
}

/**
 * Component that wraps all the AI related text controls together.
 *
 * This is meant to be displayed as an attached footer on the bottom of a
 * text field.
 * Make sure to use AiTextFieldSx on the parent text field to make it visually
 * match.
 */
export const AiTextFooter = ({
  onAiTextChange,
  productId,
  blueprintVariableId,
  catalogItemId,
  currentValue
}: AiTextFooterProps) => {
  return (
    <AiButtonWrapper>
      <AiTextStylizationButton
        onAiStylizationReceived={onAiTextChange}
        blueprintVariableId={blueprintVariableId}
        inputValue={currentValue}
        productId={productId}
      />
      <FlexExpander />
      <AiTextSuggestionButton
        value={currentValue}
        onAiSuggestionReceived={onAiTextChange}
        productId={productId}
        blueprintVariableId={blueprintVariableId}
        catalogItemId={catalogItemId}
      />
    </AiButtonWrapper>
  );
};
