import { useState, MouseEvent, SetStateAction } from 'react';
import { t } from 'i18next';

import {
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import BuildIcon from '@mui/icons-material/Build';

const AddNewQuickAutomationButton = ({
  setModalOpen
}: {
  setModalOpen: SetStateAction<any>;
}) => {
  const [anchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const menuItems = [
    {
      key: 'buildRule',
      label: t('admin:manageQuickstart.newRuleMenu.buildRule'),
      icon: <BuildIcon />,
      action: () => {
        handleMenuClose();
        setModalOpen();
      }
    }
  ];

  return (
    <>
      <Button variant="contained" onClick={handleClick} endIcon={<AddIcon />}>
        New
      </Button>

      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        {menuItems.map(item => (
          <MenuItem key={item.key} onClick={item?.action || null}>
            {item?.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText>{item?.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddNewQuickAutomationButton;
