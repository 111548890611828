import { handleActions } from 'redux-actions';
import { actionConstants } from './actions';

const initialState = {
  globalGraphqlErrors: {}
};

export default handleActions(
  {
    [actionConstants.onGraphqlError]: (state, { payload }) => {
      return {
        ...state,
        globalGraphqlErrors: {
          ...state.globalGraphqlErrors,
          [payload.slug]: payload
        }
      };
    },
    [actionConstants.clearErrors]: () => {
      return initialState;
    }
  },
  initialState
);
