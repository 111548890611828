import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { t } from 'i18next';

import { ResourceCollection, ResourceItem } from 'src/generated/gql/graphql';

import { GridColDef } from '@mui/x-data-grid';
import {
  ACCOUNT_SELECTION_STRATEGIES_DISPLAY_NAMES,
  AccountSelectionStrategy
} from '../../constants';

interface RowModel {
  resourceCollection?: ResourceCollection;
  resourceItem?: ResourceItem;
  accountSelectionStrategy: AccountSelectionStrategy;
  userMetadataKey?: string;
  priority: number;
  group?: {
    id: string;
  };
}

export const getFacebookAccountCreationRulesColumns = (): GridColDef[] => {
  return [
    {
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      field: 'priority',
      filterable: false,
      headerAlign: 'center',
      headerName: t('facebookAccountCreationRulesForm:tableHeader.priority'),
      hideable: true,
      sortable: true,
      type: 'string',
      width: 150,
      renderCell: ({ row }: GridRenderCellParams<RowModel>) => {
        return row?.priority;
      }
    },
    {
      align: 'center',
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'accountSelectionStrategy',
      filterable: false,
      headerAlign: 'center',
      headerName: t(
        'facebookAccountCreationRulesForm:tableHeader.accountSelectionStrategy'
      ),
      hideable: true,
      sortable: false,
      type: 'string',
      width: 200,
      renderCell: ({
        row
      }: GridRenderCellParams<{
        accountSelectionStrategy: AccountSelectionStrategy;
      }>) => {
        const accountSelectionStrategy = row?.accountSelectionStrategy;

        return ACCOUNT_SELECTION_STRATEGIES_DISPLAY_NAMES[
          accountSelectionStrategy
        ];
      }
    },
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'resourceCollection',
      filterable: false,
      headerAlign: 'left',
      headerName: t(
        'facebookAccountCreationRulesForm:tableHeader.resourceCollection'
      ),
      hideable: true,
      sortable: false,
      type: 'string',
      width: 200,
      renderCell: ({ row }: GridRenderCellParams<RowModel>) => {
        return row?.resourceCollection?.key;
      }
    },
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'resourceItem',
      filterable: false,
      headerAlign: 'left',
      headerName: t(
        'facebookAccountCreationRulesForm:tableHeader.resourceItem'
      ),
      hideable: true,
      sortable: false,
      type: 'string',
      width: 200,
      renderCell: ({ row }: GridRenderCellParams<RowModel>) => {
        return row?.resourceItem?.key;
      }
    },

    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'userMetadataKey',
      filterable: false,
      headerAlign: 'left',
      headerName: t(
        'facebookAccountCreationRulesForm:tableHeader.userMetadataKey'
      ),
      hideable: true,
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: ({ row }: GridRenderCellParams<RowModel>) => {
        return row?.userMetadataKey;
      }
    },
    {
      align: 'left',
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      field: 'groupId',
      filterable: false,
      headerAlign: 'left',
      headerName: t('facebookAccountCreationRulesForm:tableHeader.groupId'),
      hideable: true,
      sortable: false,
      type: 'string',
      width: 150,
      renderCell: ({ row }: GridRenderCellParams<RowModel>) => {
        return row?.group?.id;
      }
    }
  ];
};
