import { findKey, forEach, isEmpty, uniq } from 'lodash';
import {
  AdPreview,
  AdPreviewCreativeType,
  AdPreviewSet,
  FacebookSingleImageValidationErrors,
  FacebookSingleVideoValidationErrors,
  FacebookCarouselValidationErrors,
  FacebookDcoValidationErrors,
  GoogleDiscoveryMultiAssetCreativeValidationErrors,
  GoogleResponsiveDisplayValidationErrors,
  GoogleResponsiveSearchValidationErrors
} from 'src/generated/gql/graphql';
import { allCreativeTypes } from './adChannels';
import { messages } from './validations';

const postFix = 'ValidationErrors';

type CreativeErrors =
  | FacebookSingleImageValidationErrors
  | FacebookSingleVideoValidationErrors
  | FacebookCarouselValidationErrors
  | FacebookDcoValidationErrors
  | GoogleDiscoveryMultiAssetCreativeValidationErrors
  | GoogleResponsiveDisplayValidationErrors
  | GoogleResponsiveSearchValidationErrors;

const mapErrorCodes = (errors: { errorCode: string }[]) => {
  const errorMessages = messages();

  return errors.map(error => errorMessages[error.errorCode] || error.errorCode);
};

// This says permutations but it really means any array of objects that have errors (cards/purmutations)
const consolidatePermutationErrors = (permutations?: any[]) => {
  const consolidatedErrors: Record<string, string[]> = {};

  forEach(permutations, card => {
    forEach(card, (value, key) => {
      if (key !== '__typename' && value?.errors?.length) {
        consolidatedErrors[key] = uniq([
          ...(consolidatedErrors?.[key] || []),
          ...mapErrorCodes(value.errors)
        ]);
      }
    });
  });

  return consolidatedErrors;
};

const getCreativeErrors = (creativeErrors: CreativeErrors) => {
  // That doggon dangle!
  // eslint-disable-next-line no-underscore-dangle
  switch (creativeErrors.__typename) {
    case 'FacebookSingleImageValidationErrors':
    case 'FacebookSingleVideoValidationErrors':
      // headline, description, message
      const hasErrors =
        creativeErrors?.description?.errors?.length ||
        creativeErrors?.headline?.errors?.length ||
        creativeErrors?.message?.errors?.length;
      return hasErrors
        ? {
            ...(creativeErrors.headline?.errors?.length && {
              headline: mapErrorCodes(creativeErrors.headline?.errors)
            }),
            ...(creativeErrors.description?.errors?.length && {
              description: mapErrorCodes(creativeErrors.description?.errors)
            }),
            ...(creativeErrors.message?.errors?.length && {
              message: mapErrorCodes(creativeErrors.message?.errors)
            })
          }
        : null;

    case 'FacebookCarouselValidationErrors':
      // cards => [{headline, description, caption}]
      // message
      return {
        ...consolidatePermutationErrors(creativeErrors.cards || []),
        ...(creativeErrors.message?.errors?.length && {
          message: creativeErrors.message?.errors
        })
      };

    case 'FacebookDcoValidationErrors':
      // singleImageAdPremutations => [{headline, description, message}]
      return {
        ...consolidatePermutationErrors(
          creativeErrors.singleImageAdPermutations || []
        )
      };

    // finalUrl
    // permutations => [{headline, longHeadline, description}]
    case 'GoogleResponsiveDisplayValidationErrors':
    case 'GoogleDiscoveryMultiAssetCreativeValidationErrors':
      // finalUrl
      // permutations => [{headline, description}]

      return {
        ...consolidatePermutationErrors(creativeErrors.permutations || []),
        ...(creativeErrors.finalUrl?.errors?.length && {
          finalUrl: creativeErrors.finalUrl?.errors
        })
      };

    case 'GoogleResponsiveSearchValidationErrors':
      // permutations => [{headline, description, finalUrl}]
      return {
        ...consolidatePermutationErrors(creativeErrors.permutations || [])
      };
    default:
      return null;
  }
};

const getCreativeErrorsFieldName = (creativeType: AdPreviewCreativeType) => {
  const creativeFieldName = findKey(allCreativeTypes, value => {
    return value === creativeType;
  });

  return `${creativeFieldName}${postFix}` as keyof AdPreview;
};

export const getAdPreviewSetErrors = (adPreviewSets?: AdPreviewSet[]) => {
  const allCreativeErrors: Record<string, any> = {};

  forEach(adPreviewSets, adPreviewSet => {
    const { locationId } = adPreviewSet;

    forEach(adPreviewSet?.previews, preview => {
      //   const creativeFieldName = findKey(allCreativeTypes, value => {
      //     return value === preview?.creativeType;
      //   });

      if (!preview.creativeType) {
        return;
      }

      const creativeErrorsFieldName = getCreativeErrorsFieldName(
        preview.creativeType
      );

      const creativeErrors = preview[creativeErrorsFieldName] as CreativeErrors;

      if (!creativeErrors) {
        return;
      }

      const errors = getCreativeErrors(creativeErrors);

      if (errors && !isEmpty(errors)) {
        allCreativeErrors[locationId || 'defaults'] = {
          ...allCreativeErrors[locationId || 'defaults'],
          ...errors
        };
      }
    });
  });

  return isEmpty(allCreativeErrors) ? null : allCreativeErrors;
};
