import gql from 'graphql-tag';

export const unsubscribeFromEmailNotifications = gql`
  query UnsubscribeFromEmailNotifications(
    $userId: Long!
    $category: String!
    $hmac: String!
  ) {
    public {
      unsubscribeFromEmailNotifications(
        userId: $userId
        category: $category
        hmac: $hmac
      ) {
        categories {
          label
          slug
          channels {
            label
            slug
            enabled
          }
        }
      }
    }
  }
`;
