import { useTranslation } from 'react-i18next';
import en from '@emoji-mart/data/i18n/en.json';
import es from '@emoji-mart/data/i18n/es.json';
import fr from '@emoji-mart/data/i18n/fr.json';
import tr from '@emoji-mart/data/i18n/tr.json';
import ja from '@emoji-mart/data/i18n/ja.json';
import de from '@emoji-mart/data/i18n/de.json';
import ar from '@emoji-mart/data/i18n/ar.json';
import it from '@emoji-mart/data/i18n/it.json';
import pl from '@emoji-mart/data/i18n/pl.json';
import cs from '@emoji-mart/data/i18n/cs.json';
import zh from '@emoji-mart/data/i18n/zh.json';
import vi from '@emoji-mart/data/i18n/vi.json';
import nl from '@emoji-mart/data/i18n/nl.json';

import { validationsByValue } from 'src/common/validations';

/**
 * Returns true if the given list of validations allows emoji input.
 */
export const validationAllowsEmoji = (validations: string[] = []) => {
  return !validations.includes(validationsByValue.NOT_EMOJI.value);
};

const LOCALE_MAP: Record<string, any> = {
  en,
  es,
  fr,
  tr,
  ja,
  de,
  ar,
  it,
  pl,
  cs,
  zh,
  vi,
  nl
};

export const useLocaleText = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const localeText: Record<string, any> = LOCALE_MAP[language] || LOCALE_MAP.en;

  return localeText;
};
