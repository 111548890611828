import { useState, useMemo } from 'react';
import { t } from 'i18next';

import { Button, Fab, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import EditVariablesIcon from '@mui/icons-material/Power';

import Modal from 'src/components/Modal';

import Variables from './Variables';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: '90px',
    right: '20px',
    zIndex: theme.zIndex.modal
  }
});

const pageText = () => ({
  tooltip: t('admin:blueprintBuilder.configureVariabesFabTooltip'),
  modalHeading: t('admin:blueprintBuilder.fabModalHeading'),
  modalClose: t('admin:blueprintBuilder.fabModalClose')
});

const EditVariablesFab = props => {
  const { classes } = props;
  const text = useMemo(() => pageText(), []);

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const openModal = () => setModalOpen(true);

  return (
    <div>
      <Tooltip arrow placement="left" title={text.tooltip}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="editVariables"
          onClick={openModal}
        >
          <EditVariablesIcon />
        </Fab>
      </Tooltip>
      <Modal
        open={modalOpen}
        headerText={text.modalHeading}
        fullWidth
        maxWidth="lg"
        onClose={closeModal}
        FooterComponent={() => (
          <>
            <Button onClick={closeModal}>{text.modalClose}</Button>
          </>
        )}
      >
        <Variables inModal />
      </Modal>
    </div>
  );
};

export default withStyles(styles)(EditVariablesFab);
