import { get } from 'lodash';
import { formatNumber } from 'libphonenumber-js';

import { Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarIcon from '@mui/icons-material/CalendarToday';

import { formatDate } from 'src/common/dates';
import { WithStyledClasses } from 'src/common/Style';

const styles = () => ({
  container: {
    marginTop: '25px'
  },
  name: {
    textTransform: 'capitalize' as const
  },
  contactRow: {
    verticalAlign: 'middle'
  }
});

interface LeadFields {
  phoneNumber: string;
  email: string;
}

interface LeadContactProps {
  classes: WithStyledClasses<typeof styles>;
  leadFields: LeadFields;
  date: Date;
}

const LeadContact = ({ classes, leadFields, date }: LeadContactProps) => {
  const phone = get(leadFields, 'phoneNumber');
  const email = get(leadFields, 'email');

  return (
    <table className={classes.container}>
      <tbody>
        {!!date && (
          <tr className={classes.contactRow}>
            <td>
              <CalendarIcon />
            </td>
            <td>:</td>
            <td>
              <Typography variant="subtitle1">
                {formatDate({
                  date,
                  format: 'MMM DD, YYYY'
                })}
              </Typography>
            </td>
          </tr>
        )}
        {!!email && (
          <tr className={classes.contactRow}>
            <td>
              <EmailIcon />
            </td>
            <td>:</td>
            <td>
              <Typography variant="subtitle1">{email}</Typography>
            </td>
          </tr>
        )}
        {!!phone && (
          <tr className={classes.contactRow}>
            <td>
              <PhoneIcon />
            </td>
            <td>:</td>
            <td>
              <Typography variant="subtitle1">
                {formatNumber(phone, 'US', 'International')}
              </Typography>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default withStyles(styles)(LeadContact);
