import { useState, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';

import { Box, FormHelperText, Grid, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import { ContactGroupCountApiResponse } from 'src/generated/gql/graphql';
import Logger from 'src/common/Logger';
import SentryUtil from 'src/common/SentryUtil';
import useIsMobile from 'src/hooks/useIsMobile';

import TotalExpertIcon from 'src/components/Icons/TotalExpert';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import RenderAutocomplete from 'src/components/ReduxForm/RenderAutocomplete/RenderAutocomplete';
import Loading from 'src/components/Loading';
import WorkatoConnect from 'src/components/Workato/WorkatoConnect';

import { jobStatus } from './constants';
import { crunchHelperTextNumbers } from './helpers';
import {
  initialFetchContactGroupCounts,
  fetchContactGroupCounts,
  checkForExistingActiveConnection
} from './queries';

interface WorkatoConnectionProps {
  readOnly?: boolean;
  helperText?: string;
  disabled: boolean;
  label: string;
  extraProps: {
    isMultiSelect: boolean;
  };
  meta: {
    error?: string;
  };
  input: {
    onChange: (value: any) => void;
    value: any;
  };
  hookFormField: {
    value: any;
  };
}

const pollInterval = 1000;

const RenderWorkatoConnection = (inputProps: WorkatoConnectionProps) => {
  const selectedOptions = inputProps?.hookFormField?.value;
  const readOnly = inputProps?.readOnly || false;
  const { enqueueSnackbar } = useSnackbar();
  const [showDelayedLoadingText, setShowDelayedLoadingText] = useState(false);

  const { data } = useQuery(checkForExistingActiveConnection);
  const connectionExists =
    data?.checkForExistingActiveConnection?.connectionExists;
  const isActive = data?.checkForExistingActiveConnection?.isActive;

  const [getInitialContactGroupCounts] = useLazyQuery(
    initialFetchContactGroupCounts
  );
  const [getContactGroupCounts, { stopPolling }] = useLazyQuery(
    fetchContactGroupCounts
  );

  const [workatoError, setWorkatoError] = useState<string | null | undefined>(
    null
  );
  const [options, setOptions] = useState<{ name: any; value: any }[]>([]);
  const [contactGroupCountResponse, setContactGroupCountResponse] = useState<
    ContactGroupCountApiResponse | undefined
  >(undefined);

  const [connectedToWorkato, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const setCloseModal = () => {
    setModalOpen(false);
  };

  const getSelectOptionMetadata = async (jobId: string) => {
    setLoading(true);

    try {
      await getContactGroupCounts({
        pollInterval,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
          jobId
        },
        onCompleted: data => {
          const status = data?.fetchContactGroupCounts?.jobStatus;

          // if (status === jobStatus.running) { keep polling }

          if (status === jobStatus.timedOut) {
            stopPolling();
            // we have to restart the job when it times out the BE has a limit of 5 minutes
            getInitialContactGroupCounts()
              .then(contactGroupCounts => {
                const newJobId =
                  contactGroupCounts?.data?.fetchContactGroupCounts?.jobId;

                if (newJobId) {
                  getSelectOptionMetadata(newJobId).catch(() => {
                    // recursive so will be caught by the parent
                  });
                }
              })
              .catch((error: any) => {
                SentryUtil.addBreadcrumb({
                  message:
                    'Failed to get initial contact group counts from Workato. Error fetching data.'
                });
                SentryUtil.captureException(error);
                Logger.error(error);
              });
          }

          if (status === jobStatus.complete) {
            stopPolling();

            const response = data?.fetchContactGroupCounts;

            const formattedOptions = response?.contactGroupCounts?.map(
              (option: any) => {
                const count = option?.size;

                const name = count ? (
                  <>
                    {option?.groupName}{' '}
                    <Box
                      component="span"
                      sx={{
                        fontWeight: '600',
                        marginLeft: theme => theme.spacing(0.5)
                      }}
                    >
                      ({count})
                    </Box>
                  </>
                ) : (
                  option?.groupName
                );

                return {
                  name,
                  value: option?.groupName
                };
              }
            );

            setContactGroupCountResponse(response);
            setOptions(formattedOptions || []);

            if (!isEmpty(formattedOptions)) {
              enqueueSnackbar(t('common:workato.snacks.optionsReady'), {
                variant: 'success'
              });
            }

            setLoading(false);
          }

          if (status === jobStatus.error) {
            stopPolling();
            setLoading(false);
            enqueueSnackbar(t('common:workato.snacks.optionsError'), {
              variant: 'error'
            });
            SentryUtil.addBreadcrumb({
              message:
                'Failed to get contact group counts from Workato. Error fetching data.'
            });
            SentryUtil.captureException(
              new Error(
                `Error fetching data. jobId: ${data?.fetchContactGroupCounts?.jobId}`
              )
            );
          }
        }
      });
    } catch (error: any) {
      setLoading(false);
      stopPolling();
      enqueueSnackbar(t('common:workato.snacks.optionsError'), {
        variant: 'error'
      });
      SentryUtil.addBreadcrumb({
        message:
          'Failed to poll for contact group counts from Workato. Error fetching data.'
      });
      SentryUtil.captureException(error);
      Logger.error(error);
    }
  };

  const initializeSelectOptions = async () => {
    try {
      setLoading(true);
      const contactGroupCounts = await getInitialContactGroupCounts();
      const jobId = contactGroupCounts?.data?.fetchContactGroupCounts?.jobId;

      if (jobId) {
        await getSelectOptionMetadata(jobId);
      } else {
        // should not hit this but just in case
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      SentryUtil.addBreadcrumb({
        message:
          'Failed to get initial contact group counts from Workato. Error fetching data.'
      });
      SentryUtil.captureException(error);
      Logger.error(error);
    }
  };

  const setConnection = (isConnected: boolean) => {
    // only update if the connection status has changed
    if (isConnected) {
      // if we are connected we need to get the select options
      initializeSelectOptions().catch(() => {});
    }
    setConnected(isConnected);
  };

  const connectWorkato = () => {
    setModalOpen(true);

    setShowDelayedLoadingText(false); // Ensure it starts fresh
    setTimeout(() => {
      setShowDelayedLoadingText(true);
    }, 10000); // 10 seconds delay
  };

  useEffect(() => {
    if (connectionExists && isActive) {
      setConnection(true);
    }
  }, [connectionExists]);

  const iframeOnSuccess = (connection: boolean) => {
    setConnection(connection);
  };

  const iframeOnError = () => {
    setConnection(false);
    setWorkatoError(t('common:workato.error.link'));
  };

  const { isMultiSelect } = inputProps.extraProps;
  const hasConnection = connectedToWorkato || !isEmpty(options);
  const isMobile = useIsMobile();
  const helperTextNumbers = crunchHelperTextNumbers({
    isMultiSelect,
    inputValue: inputProps.input.value,
    options,
    contactGroupCountResponse
  });
  const helperTextWithNumbers = isEmpty(selectedOptions)
    ? t('common:workato.input.helperTextAvailable', helperTextNumbers)
    : t('common:workato.input.helperTextSelected', helperTextNumbers);

  const helperText = (
    <Box
      sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
    >
      <FormHelperText error={!!workatoError}>
        {loading ? t('common:workato.modal.importing') : helperTextWithNumbers}
      </FormHelperText>

      <Tooltip title={readOnly ? null : t('common:workato.buttons.unlink')}>
        <span>
          <LoadingButton
            color={workatoError ? 'error' : 'primary'}
            size="small"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={connectWorkato}
            disabled={readOnly}
          >
            <LinkOffIcon sx={{ width: '20px', height: '20px' }} />
          </LoadingButton>
        </span>
      </Tooltip>
    </Box>
  );

  return (
    <Grid container>
      <Grid xs={12} sm={6}>
        <RenderAutocomplete
          {...inputProps}
          {...(workatoError && {
            meta: {
              ...inputProps.meta,
              error: workatoError,
              touched: true
            }
          })}
          // change label if we have no options and are not connected
          {...(!hasConnection && {
            label: t('common:workato.input.loadDataLabel')
          })}
          readOnly={isEmpty(options) || readOnly} // this is disabled for this input
          options={options}
          customHelperText={hasConnection && helperText}
          enableSelectAll={isMultiSelect}
          enableCheckboxes
          limitTags={readOnly ? -1 : 5} // -1 is disable limit
        />

        <ConfirmationModal
          cancelButtonText={t('common:workato.modal.cancel')}
          title={
            loading
              ? t('common:workato.modal.importing')
              : t('common:workato.modal.title')
          }
          open={modalOpen}
          onClose={setCloseModal}
          showFooter={false}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {loading && (
              <Box
                sx={{
                  width: '420px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Loading size={30} />
                <Box
                  component="span"
                  sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: theme => theme.palette.grey[600],
                    marginTop: theme => theme.spacing(1)
                  }}
                >
                  {t('common:workato.modal.gathering')}
                </Box>
                {showDelayedLoadingText && (
                  <Box
                    component="span"
                    sx={{
                      textAlign: 'center',
                      fontSize: '12px',
                      color: theme => theme.palette.grey[600],
                      marginTop: theme => theme.spacing(1)
                    }}
                  >
                    {t('common:workato.modal.loadingMayClose')}
                  </Box>
                )}
              </Box>
            )}
            <WorkatoConnect
              onSuccess={iframeOnSuccess}
              onError={iframeOnError}
            />
          </Box>
        </ConfirmationModal>

        {!hasConnection && !loading && (
          <LoadingButton
            loading={loading}
            color="primary"
            variant="contained"
            size="small"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={connectWorkato}
            sx={{
              opacity: hasConnection ? 0.5 : 1,
              width: '100%'
            }}
            disabled={readOnly}
          >
            <Box
              component="span"
              sx={{ marginRight: theme => theme.spacing() }}
            >
              {hasConnection
                ? t('common:workato.manage')
                : t('common:workato.connect')}
            </Box>
            {!loading && <TotalExpertIcon />}
          </LoadingButton>
        )}
      </Grid>

      <Grid sm={6} xs={12}>
        {!isEmpty(options) && (
          <Box
            sx={{
              padding: theme => theme.spacing(1),
              background: theme => theme.palette.grey[100],
              borderRadius: theme => theme.spacing(1),
              marginLeft: !isMobile ? theme => theme.spacing(1) : 0,
              marginTop: isMobile
                ? theme => theme.spacing(1)
                : theme => theme.spacing(2),
              fontSize: '12px'
            }}
          >
            {t('common:workato.input.sideTip')}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default RenderWorkatoConnection;
