import { useState } from 'react';
import { t } from 'i18next';
import { Paper } from '@mui/material';
import DataTable from 'src/components/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useLazyQuery } from '@apollo/client';
import {
  AudiencePlaceholderNode,
  fetchAudiencePageQuery
} from 'src/pages/Audiences/queries';
import { AudiencePlaceholderApiSource } from 'src/generated/gql/graphql';
import { numberToK } from 'src/common/utilities/numberUtil';
import SentryUtil from 'src/common/SentryUtil';
import {
  AudienceTableRow,
  RenderCellProps
} from 'src/pages/Audiences/AudiencesTableTypes';
import { AudiencesTableChannelCell } from 'src/pages/Audiences/AudiencesTableChannelCell';

const pageText = () => ({
  sourceHeader: t('audiences:leadsTable.sourceTh'),
  audienceNameHeader: t('audiences:leadsTable.nameTh'),
  sizeHeader: t('audiences:leadsTable.sizeTh'),
  channelsHeader: t('audiences:leadsTable.channelsTh'),
  dateCreatedHeader: t('audiences:leadsTable.dateCreatedTh'),
  crmSource: t('audiences:leadsTable.crmSource'),
  uploadedSource: t('audiences:leadsTable.uploadedSource')
});

const computeAudienceSizeMinMax = (placeholder: AudiencePlaceholderNode) => {
  let minAudienceSize = Number.MAX_SAFE_INTEGER;
  let maxAudienceSize = 0;
  placeholder.channelAudiences?.edges?.forEach(channelAudience => {
    // Potential sizes can be:
    // - null or "-1", both indicate the audience is still processing or invalid. Skip those.
    // - "< 1000", (which is a string) indicates the audience is too small, and can range from 300-1000
    //   since 300 is the min audience size.
    // - "<number>" indicates the actual audience size
    const potentialSize = channelAudience?.node?.potentialSize;
    if (potentialSize == null || potentialSize === '-1') {
      return;
    }

    if (potentialSize === '< 1000') {
      // We can't have an audience size smaller than 300, so we'll use that as the min.
      minAudienceSize = 300;
      maxAudienceSize = Math.max(1000, maxAudienceSize);
      return;
    }

    // At this point, to the best of our knowledge, we have a real number to use.
    const parsedSize = parseInt(potentialSize, 10);
    if (Number.isNaN(parsedSize)) {
      return;
    }
    minAudienceSize = Math.min(parsedSize, minAudienceSize);
    maxAudienceSize = Math.max(parsedSize, maxAudienceSize);
  });

  // Correct our min audience size if we never found a valid audience size.
  if (minAudienceSize === Number.MAX_SAFE_INTEGER) {
    minAudienceSize = 0;
  }

  return { min: minAudienceSize, max: maxAudienceSize };
};

const AudiencesTable = () => {
  const text = pageText();

  const defaultColumnSettings = {
    disableColumnMenu: true,
    disableReorder: true,
    editable: false,
    filterable: false,
    flex: 2,
    align: 'center',
    headerAlign: 'center',
    hideable: false,
    sortable: false
  } as const;

  const columns: GridColDef[] = [
    {
      ...defaultColumnSettings,
      field: 'name',
      headerName: text.audienceNameHeader,
      align: 'left',
      headerAlign: 'left'
    },
    {
      ...defaultColumnSettings,
      field: 'source',
      headerName: text.sourceHeader,
      flex: 1,
      valueGetter: ({ value }) => {
        switch (value) {
          case AudiencePlaceholderApiSource.PartnerApi:
            return text.crmSource;
          case AudiencePlaceholderApiSource.BpEngine:
          case AudiencePlaceholderApiSource.CmpUi:
            return text.uploadedSource;
          default:
            return null;
        }
      }
    },
    {
      ...defaultColumnSettings,
      field: 'size',
      headerName: text.sizeHeader,
      flex: 1,
      renderCell: ({ value }: RenderCellProps<'size'>) => {
        if (value == null || value.max === 0) {
          return null;
        }

        if (value.min === value.max) {
          return <div>{numberToK(value.min)}</div>;
        }

        return (
          <div>
            {numberToK(value.min)} - {numberToK(value.max)}
          </div>
        );
      }
    },
    {
      ...defaultColumnSettings,
      field: 'channelAudiences',
      headerName: text.channelsHeader,
      renderCell: AudiencesTableChannelCell
    },
    {
      ...defaultColumnSettings,
      field: 'createdAt',
      headerName: text.dateCreatedHeader,
      type: 'date',
      valueGetter: ({ value }) => value && new Date(value)
    }
  ];

  const [nextCursor, setNextCursor] = useState<string>();
  const [fetchAudiencePage] = useLazyQuery(fetchAudiencePageQuery);

  const loadMoreRows = async (
    reset: boolean,
    _tablePageSize?: number,
    _sortModel?: GridSortModel,
    _filterModel?: GridFilterModel
  ): Promise<{ rows: AudienceTableRow[]; hasMoreRows?: boolean }> => {
    const activeNextCursor = reset ? undefined : nextCursor;

    const pageResults = await fetchAudiencePage({
      // We re-render this component in order to fetch new data.
      // Therefore, we need to no-cache this
      fetchPolicy: 'no-cache',
      variables: {
        placeholderFirst: 100,
        placeholderAfter: activeNextCursor,
        placeholderFilter: {
          showDeleted: false
        },
        channelAudienceFirst: 10,
        channelAudienceQueryFilter: {
          showDeleted: true,
          channelCodes: ['fb', 'google'],
          channelAndAudienceIdFilters: [],
          showNonCreated: true
        }
      }
    });
    if (pageResults.error != null) {
      SentryUtil.captureException(pageResults.error);
      return { rows: [], hasMoreRows: false };
    }

    const placeholders = pageResults.data?.fetchPlaceholders?.edges;
    if (placeholders == null) {
      return { rows: [], hasMoreRows: false };
    }

    setNextCursor(
      pageResults.data?.fetchPlaceholders?.pageInfo?.endCursor || undefined
    );
    return {
      rows: placeholders
        .map(placeholder => {
          if (placeholder?.node == null) {
            return null;
          }
          return {
            id: placeholder.node.id,
            name: placeholder.node.name,
            source: placeholder.node.source,
            channelAudiences: placeholder.node.channelAudiences,
            createdAt: placeholder.node.createdAt,
            size: computeAudienceSizeMinMax(placeholder.node)
          };
        })
        .filter(n => n != null) as AudienceTableRow[],
      hasMoreRows: pageResults.data?.fetchPlaceholders?.pageInfo?.hasNextPage
    };
  };

  return (
    <Paper>
      <DataTable
        hideFooterPagination={false}
        hideFooter={false}
        columns={columns}
        loadMoreRows={loadMoreRows}
        rowDataCy="audience-table-row"
      />
    </Paper>
  );
};

export default AudiencesTable;
