import { useCallback, useMemo } from 'react';
import { flow } from 'lodash';

import { withRouter } from 'react-router-dom';

import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { Typography, Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Table from 'src/components/Table';
import Heading from 'src/components/PageElements/Heading';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import Loading from 'src/components/Loading';

import CreateNewCatalogForm from './CreateNewCatalogForm';

import { getArchitecturesAndCatalogs } from './queries';

const styles = () => ({});

const pageText = () => ({
  pageTitle: t('admin:catalogLanding.pageTitle'),
  pageHeading: t('admin:catalogLanding.pageHeading'),
  pageSubHeading: t('admin:catalogLandingSubhead'),
  createNew: t('admin:catalogLanding.createNew'),
  error: t('admin:contentTable.loadingError')
});

const columnSchema = [
  {
    columnName: 'Catalog',
    accessor: 'friendlyName',
    key: 'friendlyName',
    type: 'String'
  },
  {
    columnName: 'Catalog Id',
    accessor: 'id',
    key: 'id',
    type: 'String'
  },
  {
    columnName: 'Content Set',
    accessor: 'contentSet.name',
    key: 'contentSet_name',
    type: 'String'
  },
  {
    columnName: 'Content Set Id',
    accessor: 'contentSet.id',
    key: 'contentSet_id',
    type: 'String'
  },
  {
    columnName: 'Active',
    accessor: 'isActive',
    key: 'isActive',
    type: INPUT_TYPES.BOOLEAN
  }
];

const ContentTablesLanding = props => {
  const { architecturesAndCatalogs, history } = props;

  const text = useMemo(pageText, []);

  const handleRowClick = useCallback(
    architectureId =>
      ({ id }) => {
        const linkPath = generateLinkPath(paths.admin.settings.catalogContent, {
          architectureId,
          catalogId: id
        });

        return history.push(linkPath);
      },
    [history]
  );

  const {
    loading,
    error,
    myOrganization,
    refetch: refetchArchitecture
  } = architecturesAndCatalogs;

  if (loading || error) {
    return <Loading error={error} errorMessage={text.error} />;
  }

  const { architectures } = myOrganization;

  return (
    <div>
      <Heading
        title={text.pageHeading}
        subTitle={text.pageSubHeading}
        pageTitle={text.pageTitle}
      />

      {architectures.map(architecture => (
        <div key={architecture.id}>
          <Typography variant="h6">{architecture.name}</Typography>
          <Paper>
            <Table
              rows={architecture.catalogs}
              columnSchema={columnSchema}
              loading={false}
              onClickBodyRow={handleRowClick(architecture.id)}
            />
          </Paper>
          <CreateNewCatalogForm
            form={`newCatalog${architecture.id}`}
            selectedArchitecture={architecture}
            refetch={refetchArchitecture}
            orgSlug={myOrganization.slug}
          />
          <br />
          <br />
        </div>
      ))}
    </div>
  );
};

export default flow(
  graphql(getArchitecturesAndCatalogs, {
    name: 'architecturesAndCatalogs',
    options: () => ({
      variables: {
        antiCache: new Date()
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    })
  }),
  withRouter,
  withStyles(styles)
)(ContentTablesLanding);
