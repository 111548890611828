import { flow, get, sortBy, isUndefined, first, isEmpty, find } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';

import { ButtonGroup, Button, useMediaQuery } from '@mui/material';

import { withStyles, useTheme } from '@mui/styles';

import { formatPrice } from 'src/common/numbers';
import { PROGRAM_FORM_SECTION_SPEND_NAME } from 'src/pages/Program/Constants';

const styles = theme => ({
  buttonGroupContainer: {
    flexWrap: 'wrap',
    width: '100%'
  },
  selectedOption: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,

    '&.Mui-disabled': {
      color: theme.palette.primary.contrastText
    },

    '&:hover': {
      background: theme.palette.primary.light,
      borderColor: theme.palette.primary.light
    }
  }
});

const SubscriptionSelector = ({
  classes,
  subscriptionOffers,
  disabled,
  formNamespace = PROGRAM_FORM_SECTION_SPEND_NAME
}) => {
  const subscriptionFieldName = `${formNamespace}.subscription`;
  const subscriptionSpendFieldName = `${formNamespace}.subscriptionSpend`;

  const { setValue } = useFormContext();

  const subscription = useWatch({
    name: 'spendStep.subscription',
    defaultValue: ''
  });

  const billingMethod = useWatch({
    name: 'spendStep.billingMethod',
    defaultValue: ''
  });

  const selectedSubscriptionPlan = find(subscriptionOffers, {
    billingMethod
  });
  const stripeSubscriptionPlans =
    selectedSubscriptionPlan?.stripeSubscriptionPlans;
  const sortedSubscriptionPlans = sortBy(
    stripeSubscriptionPlans,
    o => o.amount
  );

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));

  // if nothing is selected or id is not in the current plans set the default selection to the first plan
  if (
    sortedSubscriptionPlans &&
    !isEmpty(sortedSubscriptionPlans) &&
    (isUndefined(subscription) ||
      !find(sortedSubscriptionPlans, { id: subscription }))
  ) {
    setValue(subscriptionFieldName, first(sortedSubscriptionPlans)?.id);
    setValue(
      subscriptionSpendFieldName,
      first(sortedSubscriptionPlans)?.amount
    );
  }

  return (
    <>
      <ButtonGroup
        orientation={isSmScreen ? 'vertical' : 'horizontal'}
        color="primary"
        className={classes.buttonGroupContainer}
        disabled={disabled}
      >
        {sortedSubscriptionPlans.map(item => {
          return (
            <Button
              size="large"
              key={`subscription-${get(item, 'id')}`}
              color={subscription === item?.id ? 'inherit' : 'primary'}
              className={
                subscription === item?.id ? classes.selectedOption : {}
              }
              onClick={() => {
                setValue(subscriptionFieldName, item?.id);
                setValue(subscriptionSpendFieldName, item?.amount);
              }}
            >
              {formatPrice(item?.amount)}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default flow(withStyles(styles))(SubscriptionSelector);
