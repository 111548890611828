import { gql } from 'src/generated/gql';

export const userAcceptTos = gql(`
mutation userAcceptTos($input: AcceptTosInput!) {
  userAcceptTos(input: $input) {
    userNeedsToAcceptTos
    tosDetails {
      organizationTosId
      version
      link
      acceptedOn
    }
  }
}`);
