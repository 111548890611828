import { CLOUDINARY_BASE_URL } from 'src/common/cloudinaryUtils';

export const cloudinaryDisplayAdRectangleBaseUrl = `${CLOUDINARY_BASE_URL}/image/fetch/w_300,h_160,c_fill,g_center/`;

export const cloudinaryDiscoveryAdRectangleBaseUrl = `${CLOUDINARY_BASE_URL}/image/fetch/w_295,h_155,c_fill_pad,g_auto/`;
export const cloudinaryDiscoveryAdRectangleVerticalBaseUrl = `${CLOUDINARY_BASE_URL}/image/fetch/w_295,h_370,c_fill_pad,g_auto/`;
export const cloudinaryDiscoveryAdSquareBaseUrl = `${CLOUDINARY_BASE_URL}/image/fetch/w_295,h_295,c_fill_pad,g_auto/`;

export const googlePublisherFacets = {
  businessName: 'businessName',
  description: 'description',
  headline: 'headline',
  rectangleMarketingImageReferenceLegacy: 'rectangle-marketing-image-reference',
  rectangleMarketingImageReference: 'rectangularMarketingImage',
  squareMarketingImageReference: 'square-marketing-image-reference',
  squareLogoImageReferenceLegacy: 'square-logo-image-reference',
  squareLogoImageReference: 'logoImage',
  finalUrls: 'finalUrls',
  displayPath1: 'path1',
  displayPath2: 'path2',
  descriptions: 'descriptions',
  headlines: 'headlines'
};

export const PUBLISHER_FACETS = {
  callToAction: 'call-to-action',
  creativeMain: 'creative-main',
  carouselCard: 'carousel-card',
  assetFeed: 'asset-feed', // Used for DCO
  dareFeed: 'dynamic-ad-template',
  videoDetails: 'video-details',
  videoUpload: 'video-upload-basics',
  leadFormRef: 'lead-form-reference'
};

export const MAX_POLLING_LIMIT = 50;
export const MAX_POLLING_INTERVAL = 2000;
export const POLLING_RATE = 500;
export const INITIAL_POLLING_INTERVAL = 0;

export const UNIFORM_PREVIEW_WIDTH = '385';

export const PHONE_MOCK_MESSAGE_WIDTH = 320;
