import { isEmpty } from 'lodash';

import { FormControlLabel, Radio } from '@mui/material';

import { scheduleTypes } from 'src/pages/Program/Constants';
import { Offer } from 'src/components/ScheduleSelector/PaymentTypeSelector';

export const getOfferOptions = (offers: Offer[]) => {
  const uniqueTypes = new Set();

  const offerOption = offers
    .reduce((accum: any[], offer) => {
      // If the offer's type isn't one we support in the UI,
      // ignore it to prevent the UI from blowing up.
      if (
        !scheduleTypes?.[offer?.type as keyof typeof scheduleTypes] ||
        uniqueTypes.has(offer?.type)
      ) {
        return accum;
      }

      // filter out subscription offers that don't have plans associated with them
      if (
        offer?.type === scheduleTypes.subscription.value &&
        isEmpty(offer?.stripeSubscriptionPlans)
      ) {
        return accum;
      }

      uniqueTypes.add(offer.type);

      accum.push(offer);

      return accum;
    }, [])
    .map(offer => {
      const type = offer?.type;

      return (
        <FormControlLabel
          key={type}
          control={<Radio />}
          label={scheduleTypes[type as keyof typeof scheduleTypes].name}
          value={scheduleTypes[type as keyof typeof scheduleTypes].value}
        />
      );
    });

  return offerOption;
};
