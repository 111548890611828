import { Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ReactChild } from 'react';
import Loading from 'src/components/Loading';
import { styled } from '@mui/system';
import { getContent } from './queries';

const TextContainer = styled(Typography)(({ theme }) => ({
  margin: `${theme.spacing(4)} 0 ${theme.spacing(2)} 0`,
  fontSize: '18px',
  fontWeight: 'bold'
}));

const CountContainer = styled('span')(() => ({
  fontSize: '24px'
}));

const MaybeCount = ({
  loading,
  children
}: {
  loading: boolean;
  children?: ReactChild;
}) => {
  if (loading) {
    return <Loading inline size={24} />;
  }
  return <CountContainer>{children}</CountContainer>;
};

export interface ContentCountProps {
  showContent: boolean;
  toggleShowContent: (showContent: boolean) => void;
  contentNameLowerCase: string;
  displayCollapseKey: string;
  tableFilters: unknown;
  architectureId: string;
}

const ContentCount = ({
  contentNameLowerCase,
  architectureId,
  displayCollapseKey,
  tableFilters
}: ContentCountProps) => {
  const { loading, data } = useQuery(getContent, {
    variables: {
      architectureId,
      groupKey: displayCollapseKey,
      first: 101,
      filter: tableFilters
    }
  });

  const contentCountRawValue =
    data?.architecture?.catalog?.content?.edges?.length;
  const contentCount =
    contentCountRawValue != null && contentCountRawValue > 100
      ? '> 100'
      : contentCountRawValue;

  return (
    <div style={{ textAlign: 'center' }}>
      <TextContainer variant="h5">
        <Trans
          i18nKey="automatedProgramFilter:matching.result"
          components={[<MaybeCount loading={loading} />]}
          values={{
            contentCount,
            contentNameLowerCase
          }}
        />
      </TextContainer>
    </div>
  );
};

export default ContentCount;
