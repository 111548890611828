import { getOneOfEachChannelKeys } from 'src/common/blueprints';
import Instrumentation from 'src/instrumentation';
import { MutationParamsInterface } from 'src/pages/Program/utils/submitHandlers/utils';
import { programTrackingTypes } from 'src/pages/Program/Constants';

import { createResponseInterface } from './createProgram';
import { createAutomationResponseInterface } from './createAutomation';
import { editAutomationResponseInterface } from './editAutomation';
import { createMlpResponseInterface } from './createMultiLocationProgram';

export const trackOrderPlacementProgram = ({
  mutationParams,
  response,
  selectedBlueprint,
  isClone
}: {
  mutationParams: any;
  response: createResponseInterface;
  selectedBlueprint: any; // TODO: type interface
  isClone: boolean;
}) => {
  Instrumentation.logEvent(Instrumentation.Events.PurchaseClicked, {
    paymentAmount: mutationParams?.paymentAmount,
    offerId: mutationParams?.offerId,
    chosenStripePlanId: mutationParams?.chosenStripePlanId,
    orderId: response?.data?.orderPlacement?.id,
    programId: response?.data?.orderPlacement?.programId,
    userId: response?.data?.orderPlacement?.userId,
    groupId: response?.data?.orderPlacement?.groupId,
    architectureId: response?.data?.orderPlacement?.architectureId,
    productId: selectedBlueprint?.id,
    channel: getOneOfEachChannelKeys(selectedBlueprint?.blueprint?.channels),
    isClone,
    type: programTrackingTypes.program
  });
};

export const trackOrderPlacementAutomation = ({
  mutationParams,
  // response,
  selectedBlueprint,
  isEdit = false
}: {
  mutationParams: MutationParamsInterface;
  response: createAutomationResponseInterface | editAutomationResponseInterface;
  selectedBlueprint: any; // TODO: type interface
  isEdit: boolean;
}) => {
  const event = isEdit
    ? Instrumentation.Events.AutomationEditPurchaseClicked
    : Instrumentation.Events.AutomationPurchaseClicked;

  Instrumentation.logEvent(event, {
    architectureId: mutationParams?.architectureId,
    paymentAmount: mutationParams?.paymentAmount,
    offerId: mutationParams?.offerId,
    // userId: response?.data?.userId, // TODO: what is going on here TS error
    childOrderDurationDays: mutationParams?.childOrderDurationDays,
    catalogId: mutationParams?.catalogId,
    // promoCode: response?.data?.createSupervisorOrder?.promoCode, // TODO: what is going on here TS error
    productId: selectedBlueprint?.id,
    channel: getOneOfEachChannelKeys(selectedBlueprint?.blueprint?.channels)
  });
};

export const trackMlpOrderPlacement = ({
  mutationParams,
  response,
  selectedBlueprint,
  isClone,
  summedPriceAmount
}: {
  mutationParams: any;
  response: createMlpResponseInterface;
  selectedBlueprint: any; // TODO: type interface
  isClone: boolean;
  summedPriceAmount: number;
}) => {
  Instrumentation.logEvent(Instrumentation.Events.PurchaseClicked, {
    paymentAmount: summedPriceAmount,
    offerId: mutationParams?.offerId,
    chosenStripePlanId: mutationParams?.chosenStripePlanId,
    mlpParentId: response?.data?.placeMultiLocationProgram?.id,
    userId: response?.data?.placeMultiLocationProgram?.userId,
    groupId: response?.data?.placeMultiLocationProgram?.groupId,
    architectureId: response?.data?.placeMultiLocationProgram?.architectureId,
    productId: selectedBlueprint?.id,
    channel: getOneOfEachChannelKeys(selectedBlueprint?.blueprint?.channels),
    isClone,
    type: programTrackingTypes.multiLocation
  });
};
