import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useQuery, useMutation } from '@apollo/client';
import { isEmpty, groupBy, keys } from 'lodash';

import {
  Grid,
  Paper,
  Typography,
  Alert,
  Chip,
  Divider,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AlertTitle
} from '@mui/material';
import {
  Done as DoneIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';

import { useAppSettings } from 'src/AppSettings';
import Logger from 'src/common/Logger';

import PartnerAppBar from 'src/pages/Partner/PartnerAppBar';
import { CopyToClipboardActionButton } from 'src/components/CopyToClipboard/CopyToClipboard';

import { getEmailIdentity } from './queries';
import { initiateEmailIdentityVerification } from './mutations';
import Form from './Form';

export const dnsStatus = {
  completed: 'completed',
  pending: 'pending',
  error: 'error'
};

const dnsExplainationText = {
  CNAME: t('partner:emailNotificationSetup.dnsCnameExplanation'),
  MX: t('partner:emailNotificationSetup.dnsMxExplanation'),
  TXT: t('partner:emailNotificationSetup.dnsTxtExplanation')
};

interface SubmitInputs {
  domain: string;
  mailFromSubdomain: string;
  mailFromAddress: string;
  senderName: string;
}

const SettingsLanding = () => {
  const appSettings = useAppSettings();
  const [detailsOpen, setDetailsOpen] = useState(true);
  const [mutationError, setMutationError] = useState(false);

  const { data, loading, error, startPolling, stopPolling, refetch } = useQuery(
    getEmailIdentity,
    {
      variables: {
        organizationId: appSettings?.organizationId
      }
    }
  );

  const emailIdentity = data?.fetchEmailIdentity;

  const dnsEntriesByType = groupBy(emailIdentity?.dnsEntries, 'type');

  useEffect(() => {
    if (emailIdentity) {
      if (emailIdentity.status === 'pending') {
        startPolling(5000);
      } else {
        stopPolling();
      }
    }
  }, [emailIdentity, startPolling, stopPolling]);

  const [
    initiateEmailIdentityVerificationMutation,
    { loading: loadingMutation }
  ] = useMutation(initiateEmailIdentityVerification);

  const onSubmit = async (input: SubmitInputs) => {
    const updatedInput = {
      ...input,
      mailFromSubdomain: `${input.mailFromSubdomain}.${input.domain}`,
      mailFromAddress: `${input.mailFromAddress}@${input.domain}`
    };

    try {
      await initiateEmailIdentityVerificationMutation({
        variables: {
          input: updatedInput
        }
      });
      await refetch();
      setMutationError(false);
    } catch (error) {
      setMutationError(true);
      Logger.error(t('partner:emailNotificationSetup.setVerificationError'));
    }
  };

  const getChip = (status: string | undefined) => {
    if (error || mutationError) {
      return (
        <Chip
          color="error"
          icon={<ErrorIcon fontSize="small" />}
          label={t('partner:emailNotificationSetup.statusError')}
          sx={{ color: 'white' }}
        />
      );
    }

    switch (status) {
      case dnsStatus.completed:
        return (
          <Chip
            color="success"
            icon={<DoneIcon fontSize="small" />}
            label={t('partner:emailNotificationSetup.statusSuccess')}
            sx={{ color: 'white' }}
          />
        );
      case dnsStatus.pending:
        return (
          <Chip
            color="warning"
            icon={<WarningIcon fontSize="small" />}
            label={t('partner:emailNotificationSetup.statusPending')}
            sx={{ color: 'white' }}
          />
        );
      case dnsStatus.error:
        return (
          <Chip
            color="error"
            icon={<ErrorIcon fontSize="small" />}
            label={t('partner:emailNotificationSetup.statusError')}
            sx={{ color: 'white' }}
          />
        );
      default:
        return (
          <Chip label={t('partner:emailNotificationSetup.statusNotSetup')} />
        );
    }
  };

  // we cannot update the values once they are set as the api does not support that currently
  const disabledAll = !isEmpty(emailIdentity) || loading || loadingMutation;
  const isLoading = loading || loadingMutation;

  return (
    <div>
      <PartnerAppBar title={t('partner:emailNotificationSetup.landingTitle')} />

      <Paper
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        <Alert severity="info">
          <AlertTitle>
            {t('partner:emailNotificationSetup.pageHelpTextTitle')}
          </AlertTitle>
          {t('partner:emailNotificationSetup.pageHelpText')}
        </Alert>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ margin: theme => `${theme.spacing()} 0px` }}>
              <Typography>
                {t('partner:emailNotificationSetup.cardTitle')}
              </Typography>
            </Box>

            <Divider sx={{ marginBottom: theme => theme.spacing() }} />

            {(error || mutationError) && (
              <Alert severity="error">
                {t('partner:emailNotificationSetup.setVerificationError')}
              </Alert>
            )}

            <Form
              emailIdentity={emailIdentity}
              onSubmit={onSubmit}
              disabledAll={disabledAll}
              isLoading={isLoading}
            />
          </Grid>

          <Grid item xs={12}>
            <Accordion
              expanded={detailsOpen}
              onChange={() => setDetailsOpen(!detailsOpen)}
              disabled={!emailIdentity}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="ses-email-identity-verification-details"
                id="ses-email-identity-verification-details"
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('partner:emailNotificationSetup.dnsDetailsTitle')}
                  {!detailsOpen && (
                    <span style={{ marginLeft: '5px' }}>
                      {getChip(emailIdentity?.status)}
                    </span>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {t('partner:emailNotificationSetup.dnsEntriesSetup')}
                </Box>
                <Divider />
                <br />

                {!isEmpty(dnsEntriesByType) &&
                  keys(dnsEntriesByType).map((dnsKey: string) => {
                    const dnsExplaination =
                      dnsExplainationText[
                        dnsKey as keyof typeof dnsExplainationText
                      ] || '';

                    return (
                      <>
                        <Box
                          sx={{
                            background: theme => theme.palette.grey[200],
                            width: '100%',
                            padding: theme => theme.spacing()
                          }}
                        >
                          <strong>{dnsKey}</strong>
                          <br />
                          <Typography>{dnsExplaination}</Typography>
                        </Box>
                        <Box sx={{ padding: theme => theme.spacing() }}>
                          <ul
                            style={{ listStyle: 'none', padding: 0, margin: 0 }}
                          >
                            {dnsEntriesByType[dnsKey].map(
                              (entry: {
                                type: string;
                                name: string;
                                value: string;
                                isComplete: boolean;
                              }) => {
                                return (
                                  <Box
                                    component="li"
                                    key={entry.name}
                                    sx={{
                                      marginBottom: '10px',
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                  >
                                    {getChip(
                                      entry?.isComplete
                                        ? dnsStatus.completed
                                        : dnsStatus.pending
                                    )}
                                    <Box
                                      sx={{
                                        marginLeft: theme => theme.spacing()
                                      }}
                                    >
                                      <strong>
                                        {t(
                                          'partner:emailNotificationSetup.entriesName'
                                        )}
                                        :
                                      </strong>{' '}
                                      {entry?.name}
                                      <CopyToClipboardActionButton
                                        text={entry?.name}
                                        variant="text"
                                      />
                                      <br />
                                      <strong>
                                        {t(
                                          'partner:emailNotificationSetup.entriesValue'
                                        )}
                                        :
                                      </strong>{' '}
                                      {entry?.value}
                                      <CopyToClipboardActionButton
                                        text={entry?.value}
                                        variant="text"
                                      />
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                          </ul>
                        </Box>
                      </>
                    );
                  })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default SettingsLanding;
