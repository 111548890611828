import { ReactNode, useContext, createContext, useState } from 'react';

import { DRAWER_OPEN } from 'src/pages/Chrome/constants';

interface AppStateContextType {
  sideNavOpen: boolean;
  setSideNavOpen: (open: boolean) => void;
  toggleSideNav: (skipSavePref?: boolean) => void;
}

export const AppStateContext = createContext({} as AppStateContextType);

export const useAppState = () => useContext(AppStateContext);

const AppStateProvider = ({ children }: { children: ReactNode }) => {
  const [sideNavOpen, setSideNavOpen] = useState(() => {
    // initialize drawer state from localStorage
    return localStorage.getItem(DRAWER_OPEN) === 'true';
  });

  const toggleSideNav = (skipSavePref = false) => {
    setSideNavOpen(prev => {
      const newState = !prev;

      // when we auto collapse the drawer, we don't want to save the preference
      if (skipSavePref) {
        localStorage.setItem(DRAWER_OPEN, newState.toString());
      }

      return newState;
    });
  };

  const context = {
    sideNavOpen,
    setSideNavOpen,
    toggleSideNav
  };

  return (
    <AppStateContext.Provider value={context}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
