import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import { filterToString } from 'src/components/AutomatedProgramFilter/helpers';
import { AutomatedProgramFilterType } from './constants';

type FilterListProps = {
  name?: string;
  filter?: AutomatedProgramFilterType;
};

const FilterList = ({ name, filter }: FilterListProps) => {
  return (
    <ListItem sx={{ paddingLeft: '0' }}>
      <ListItemIcon>
        <ListAltSharpIcon />
      </ListItemIcon>
      {name && <ListItemText primary={name} />}
      {filter && <ListItemText primary={`Where ${filterToString(filter)}`} />}
    </ListItem>
  );
};

export default FilterList;
