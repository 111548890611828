import { MouseEvent, MutableRefObject } from 'react';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { GridRowModel } from '@mui/x-data-grid-pro';

export interface FilterInterface {
  field: string;
  id: string;
  operator: string;
  value: string;
}

export const mapParamToFilter = (
  paramValues: any[],
  field: string,
  operator = 'is'
) => {
  return paramValues?.map((value: string, index: number) => {
    return {
      field,
      id: `${field}-${index}`,
      operator,
      value
    };
  });
};

interface CreateHandleDeleteClickArgs {
  id: string;
  api: MutableRefObject<GridApiPro>;
  onDeleteRow: (id: string) => Promise<{ success?: boolean } | undefined>;
}

export const createHandleDeleteClick =
  ({ id, api, onDeleteRow }: CreateHandleDeleteClickArgs) =>
  async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const row: GridRowModel = (api.current.getRowWithUpdatedValues as any)(id);
    // errors out putting this after its been deleted from the api even tho it
    // shows up in the UI still :|
    api.current.updateRows([{ id, _action: 'delete' }]);

    const deleteResponse = await onDeleteRow(id);

    if (!deleteResponse?.success) {
      // replace the row if the delete failed
      api.current.updateRows([row]);
    }
  };

export interface WindowDimensionsInterface {
  height: number;
}

export const calculateRowsInView = (
  windowDimensions: WindowDimensionsInterface,
  defaultPageSize: number,
  standardRowHeight: number
) => {
  const rowsInView = Math.ceil(windowDimensions.height / standardRowHeight);

  if (rowsInView < defaultPageSize) {
    return defaultPageSize;
  }

  return rowsInView;
};
