import { validateRequired, validateSnakeCase } from 'src/common/validations';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const CONTENT_TABLE_FORM_NAME = 'adminContentTableForm';
export const CREATE_NEW_KEY = 'createNew';

export const statusMap = {
  closed: 'closed',
  open: 'open',
  committed: 'committed',
  error: 'error'
};

export const getContentDocumentFields = ({ isCreateNew }) => [
  {
    name: 'contentTable',
    displayName: 'Content Table Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired, validateSnakeCase()],
    isRequired: true,
    isHidden: false,
    disabled: !isCreateNew,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'isAccessControlled',
    displayName: 'Is Access Controlled',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const getColumnInputs = ({
  knownTypesEnumFields,
  data = {},
  hidePrimaryKey = true
} = {}) => [
  {
    name: 'name',
    displayName: 'Name',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: [validateRequired, validateSnakeCase()],
    isRequired: true,
    isHidden: false,
    disabled: !data.isNew
  },
  {
    name: 'type',
    displayName: 'Type',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: null,
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: knownTypesEnumFields || []
      }
    }
  },
  {
    name: 'primaryKey',
    displayName: 'Primary Key',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: hidePrimaryKey
  },
  {
    name: 'nullable',
    displayName: 'Allow Null Values',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false
  },
  {
    name: 'indexed',
    displayName: 'Indexed',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false
  },
  {
    name: 'unique',
    displayName: 'Unique',
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false
  },
  {
    name: 'defaultValue',
    displayName: 'Default Value',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: [],
    isRequired: false,
    isHidden: false
  }
];

export const ALLOWED_FIELDS = ['contentTable', 'isAccessControlled', 'columns'];
export const ALLOWED_COLUMN_FIELDS = getColumnInputs().map(field => field.name);
