import React, { useEffect } from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import FiltersList from './FiltersList';
import { ContentSetFieldMetadata } from './helpers';

type ProgramFilterSelectorProps = {
  formNamespace?: string;
  fieldName?: string;
  contentMeta: ContentSetFieldMetadata[];
  onFilterChange?: (filters: any) => void;
};

const ProgramFilterSelector = ({
  formNamespace,
  fieldName = 'filters',
  contentMeta,
  onFilterChange
}: ProgramFilterSelectorProps) => {
  const newNamespace = formNamespace
    ? `${formNamespace}.${fieldName}`
    : fieldName;
  const { fields, ...filtersArrayMethods } = useFieldArray({
    name: newNamespace
  });

  // this is for when we want a redux form parent form to subscribe to changes
  // we can remove this when this component is no longer used in redux form forms
  const updatedFilters = useWatch({ name: newNamespace, defaultValue: [] });
  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(updatedFilters);
    }
  }, [updatedFilters]);

  return (
    <div>
      <FiltersList
        filters={fields}
        formNamespace={newNamespace}
        filtersArrayMethods={filtersArrayMethods}
        contentMeta={contentMeta}
      />
    </div>
  );
};

export default ProgramFilterSelector;
