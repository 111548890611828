import { SVGProps } from 'react';
import AmericanExpress from './AmericanExpress';
import CartesBancaires from './CartesBancaires';
import Diners from './Diners';
import Discover from './Discover';
import Eftpos from './Eftpos';
import JCB from './JCB';
import Mastercard from './Mastercard';
import UnionPay from './UnionPay';
import Visa from './Visa';

const CREDIT_CARD_BRANDS = {
  americanExpress: 'American Express',
  cartesBancaires: 'Cartes Bancaires',
  diners: 'Diners Club',
  discover: 'Discover',
  eftpos: 'eftpos',
  jcb: 'JCB',
  mastercard: 'MasterCard',
  unionpay: 'UnionPay',
  visa: 'Visa'
};

export interface CreditCardLogoProps extends SVGProps<SVGSVGElement> {
  brand: string;
}

const CreditCardLogo = (props: CreditCardLogoProps) => {
  const { brand, ...rest } = props;
  let BrandLogo = null;

  switch (brand) {
    case CREDIT_CARD_BRANDS.americanExpress:
      BrandLogo = AmericanExpress;
      break;

    case CREDIT_CARD_BRANDS.cartesBancaires:
      BrandLogo = CartesBancaires;
      break;

    case CREDIT_CARD_BRANDS.diners:
      BrandLogo = Diners;
      break;

    case CREDIT_CARD_BRANDS.discover:
      BrandLogo = Discover;
      break;

    case CREDIT_CARD_BRANDS.eftpos:
      BrandLogo = Eftpos;
      break;

    case CREDIT_CARD_BRANDS.jcb:
      BrandLogo = JCB;
      break;

    case CREDIT_CARD_BRANDS.mastercard:
      BrandLogo = Mastercard;
      break;

    case CREDIT_CARD_BRANDS.unionpay:
      BrandLogo = UnionPay;
      break;

    case CREDIT_CARD_BRANDS.visa:
      BrandLogo = Visa;
      break;

    default:
      return BrandLogo;
  }

  return <BrandLogo {...rest} />;
};

export default CreditCardLogo;
