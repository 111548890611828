export const GOOGLE_PREVIEW_WIDTH = '300';
export const GOOGLE_DISPLAY_IMAGE_HEIGHT = '160';

export const SQUARE_AD_PREVIEW_WIDTH = '318';

export const STYLES = {
  fontFamily: {
    roboto: 'Roboto, arial, sans-serif',
    googleSans: 'Google Sans, Arial, sans-serif'
  },
  color: {
    headline: '#212121',
    businessName: '#424242',
    description: '#757575'
  }
};
