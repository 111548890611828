import Numeral from 'numeral';

import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  spend: {
    padding: '0 0 5px 0',
    fontWeight: 'bold',
    minWidth: '150px'
  },
  discounted: {
    '& span:first-child': {
      textDecoration: 'line-through'
    },
    '& span:last-child': {
      fontSize: '10px'
    }
  }
});

const SpendCell = props => {
  const { data, classes } = props;

  return (
    <span>
      {data?.priceBeforeDiscount && (
        <div className={classes.discounted}>
          <span>{Numeral(data?.priceBeforeDiscount).format('$0,0')}</span>{' '}
          <span>Promo applied</span>
        </div>
      )}
      <div className={classes.spend}>
        {Numeral(data?.amount).format('$0,0')}
      </div>
    </span>
  );
};

export default withStyles(styles)(SpendCell);
