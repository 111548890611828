import { connect } from 'react-redux';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import { v1 as uuidv1 } from 'uuid';
import { change, getFormValues } from 'redux-form';

import { mapIntrospectionToProps } from 'src/common/ApolloUtil';
import { updateBillingMethodsEnums } from 'src/common/enumFormatters';
import Loading from 'src/components/Loading';
import {
  getInitialValuesFromInputsConfig,
  configureInputs
} from 'src/components/ReduxForm/helpers';
import {
  billingMethodIntrospection,
  getAllPricingPlans
} from 'src/pages/Admin/BlueprintBuilder/queries';
import { usePrevious } from 'src/hooks/usePrevious';

import { BLUEPRINT_BUILDER_FORM_NAME } from 'src/pages/Admin/BlueprintBuilder/Constants';
import { useAccordionState } from 'src/pages/Admin/BlueprintBuilder/BlueprintBuilderSteps/AccordionList/useAccordionState';
import { AccordionListHeading, AccordionListWrapper } from '../AccordionList';
import {
  offersInputs,
  invoiceSubscriptionInputs,
  BILLING_METHODS
} from './constants';
import DraggableOfferList from './DraggableOfferList';
import AddOffer from './AddOffer';

const pageText = () => ({
  addButton: t('admin:blueprintBuilder.stepOffersAddButton'),
  heading: t('admin:blueprintBuilder.stepsOffersHeading')
});

const RenderOffersInputs = props => {
  const { fields, change } = props;
  const [hasMoved, setHasMoved] = useState(false);

  const prevFields = usePrevious(fields.getAll());

  const {
    data: introspectionData,
    loading,
    error
  } = useQuery(billingMethodIntrospection);
  const { billingMethodsIntrospectionMeta, billingMethodsEnumFields = [] } =
    mapIntrospectionToProps(
      {
        // eslint-disable-next-line no-underscore-dangle
        __type: introspectionData?.__type,
        loading,
        error
      },
      'billingMethods'
    );

  const { data: pricingPlansData } = useQuery(getAllPricingPlans);
  const pricingPlans = pricingPlansData?.allPricingPlans || [];

  useEffect(() => {
    if (prevFields === fields.getAll() && hasMoved) {
      fields.forEach((field, index) => {
        const sortOrder = field?.displaySortOrder;

        if (sortOrder !== index) {
          // if the sort order is mismatched, chagne it to the array index order of the document
          change(
            BLUEPRINT_BUILDER_FORM_NAME,
            `${fields.name}[${index}].displaySortOrder`,
            index
          );
        }
      });
      setHasMoved(false);
    }
  }, [change, fields, hasMoved, prevFields]);

  const text = useMemo(() => pageText(), []);
  const disabledInputs = new Set(['slug']);

  const {
    openIndexes,
    handleAccordionToggleAll,
    handleAccordionCloseAll,
    handleAccordionToggle
  } = useAccordionState({ maxNumItems: fields.length });

  const getUpdatedOffersInputs = useCallback(
    (billingMethod, billingType) => {
      const updatedBillingMethodsEnumFields = updateBillingMethodsEnums(
        billingMethodsEnumFields
      );

      const cancellationTypeValues = [
        { name: 'Immediate', value: 'immediate' }
      ];

      if (billingType === 'subscription') {
        cancellationTypeValues.push({
          name: 'Deferred',
          value: 'deferred'
        });
      }

      return configureInputs({
        inputs: offersInputs,
        disabledInputs,
        hiddenInputs:
          billingMethod === BILLING_METHODS.direct
            ? new Set(['billingProfileId'])
            : new Set(),
        enumInputs: {
          billingMethod: 'billingMethods',
          cancellationType: 'cancellationType'
        },
        enumerationValues: [
          {
            enumeration: 'billingMethods',
            values: [...updatedBillingMethodsEnumFields]
          },
          {
            enumeration: 'cancellationType',
            values: cancellationTypeValues
          }
        ]
      }).map(input => {
        // These were already clone-deeped, so we can safely modify them in-place
        const updatedInput = input;
        // Make grantees collapsable
        if (input?.name === 'grantees') {
          updatedInput.displayParameters.inputData.collapseConfig = {
            numFieldsTillCollapse: 3,
            headerItemText: index =>
              t('blueprintBuilder:grantee.itemHeader', { index })
          };
        }
        return updatedInput;
      });
    },
    [offersInputs, billingMethodsEnumFields]
  );

  const invoiceSubscriptionInputsWithPricingPlans = useMemo(() => {
    const pricingPlanEnum = pricingPlans?.map(plan => {
      return {
        name: plan?.name,
        value: plan?.slug
      };
    });

    return configureInputs({
      inputs: invoiceSubscriptionInputs,
      disabledInputs,
      enumInputs: {
        pricingPlan: 'pricingPlan'
      },
      enumerationValues: [
        {
          enumeration: 'pricingPlan',
          values: [...pricingPlanEnum]
        }
      ]
    });
  }, [pricingPlans]);

  const addNewOffer = useCallback(
    type => {
      const emptyOffer = getInitialValuesFromInputsConfig(offersInputs);
      emptyOffer.slug = uuidv1();
      emptyOffer.type = type;
      emptyOffer.displaySortOrder = fields.length;

      fields.push(emptyOffer);
    },
    [fields]
  );

  const removeContentSet = useCallback(
    index => {
      fields.remove(index);
    },
    [fields]
  );

  if (billingMethodsIntrospectionMeta.loading) {
    return <Loading />;
  }

  return (
    <>
      <AccordionListHeading
        title={text.heading}
        openIndexes={openIndexes}
        handleToggleAll={handleAccordionToggleAll}
      >
        <AddOffer onAddOffer={addNewOffer} />
      </AccordionListHeading>
      <AccordionListWrapper>
        <DraggableOfferList
          fields={fields}
          removeContentSet={removeContentSet}
          invoiceSubscriptionInputsWithPricingPlans={
            invoiceSubscriptionInputsWithPricingPlans
          }
          getUpdatedOffersInputs={getUpdatedOffersInputs}
          handleAccordionToggle={handleAccordionToggle}
          handleAccordionCloseAll={handleAccordionCloseAll}
          openIndexes={openIndexes}
          setHasMoved={setHasMoved}
        />
      </AccordionListWrapper>
    </>
  );
};

export default connect(
  state => {
    const formValues = getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state)
      .document.offers;

    return { formValues };
  },
  { change }
)(RenderOffersInputs);
