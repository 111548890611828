import { gql } from 'src/generated/gql';

export const getArchitectureById = gql(`
  query getArchitectureByIdForProgramPerformance($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      catalog {
        id
        friendlyName
        contentSetId
        defaultGroupKey
        displayNameTemplate
        displayImageTemplate
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
        }
      }
    }
  }
`);

export const getAutomation = gql(`
  query Automation($supervisorOrderId: ID!) {
    automation(supervisorOrderId: $supervisorOrderId, showDeleted: true) {
      id
      paymentAmount
      catalogFilter
      variableValues
      deactivatedAt
      childOrderDurationDays
      scheduleStartEpochSeconds
      promoCode
      name
      tier {
        id
        amount
        pricingPlan {
          intervalUnit
          intervalLength
        }
      }
      offer {
        type
        product {
          id
          description
          architectureId
          name
          isAvailable
          requiresContent
          blueprintId
          blueprint {
            id
            publishers {
              id
              prototype {
                id
                code
                unit {
                  id
                  channelCode
                  code
                }
              }
              publisherFacets {
                id
                facet {
                  id
                  code
                }
                parameterValues
              }
            }
          }
        }
      }
      orders {
        architectureId
        id
        name
        isProgramActive
        programId
        products {
          name
          id
        }
        billingDetails {
          offerType
          amount
          startDate
          endDate
          planId
          planNickname
          interval
          renewsOn
          isSetToCancel
          currentPeriodEnd
          canceledOn
          billingMethod
          promoCode
          priceBeforeDiscount
        }
        adData {
          totalAds
        }
        channels {
          adType
          channels
        }
        orderItem {
          status
        }
      }
      catalog {
        id
        friendlyName
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          isRequired
          isEditable
          isHidden
          contentColumnType
          isFilterable
        }
      }
    }
  }
`);

export const getOrder = gql(`
  query OrderForProgramPerformance($orderId: ID!, $showDeleted: Boolean) {
    order(orderId: $orderId, showDeleted: $showDeleted) {
      architectureId
      id
      name
      isProgramActive
      programId
      paymentMethodId
      rebelIqAsset {
        mediaAssetId
        url
        isRebelIqSubscriptionActive
      }
      executionStatus {
        executionId
        status
        outcome
      }
      supervisorOrder {
        id
        name
      }
      belongsToMultiLocationProgram
      multiLocationProgram {
        id
        name
        architectureId
      }
      multiLocationChildProgramDetails {
        variableValuesAreOverridden
        scheduleIsOverridden
        budgetIsOverridden
        locationId
      }
      products {
        id
        name
        kpis {
          slug
          channelCode
          friendlyName
        }
      }
      product {
        isAvailable
        isMlp
        isRebelIqEnabled
      }
      billingDetails {
        offerType
        amount
        startDate
        endDate
        planId
        planNickname
        interval
        renewsOn
        isSetToCancel
        currentPeriodEnd
        canceledOn
        promoCode
        priceBeforeDiscount
        cardInfo
        stripeSourceId
        billingMethod
        tierId
        scheduledTierAmount
        scheduledBudgetDate
        amount
        cancellationDetails {
          originalPurchaseAmount
          amountAfterCalculatedUsage
          refundAmount
          cancellationFee
          cancellationDate
        }
        mostRecentBudgetAdjustment {
          stepFunctionsExecutionId
          outcome
          errorMessage
          errorCode
          updatedBudgetAmount
          newTierName
        }
      }
      adData {
        adPreviews
        totalAds
      }
      offer {
        subscriptionMinimumDurationDays
        purchaseScheduleAdjustmentEnabled
        purchaseOrderAmountAdjustmentEnabled
        subscriptionTierChangeMethod
        type
        cancellationType
        id
        productId
        billingMethod
        name
        isActive
        availableToRoles
        type
        purchasePriceAmount
        purchasePriceIsUserSet
        purchasePriceUserSetMin
        purchasePriceUserSetMax
        subscriptionStripeProductId
        cancellationType
        stripeSubscriptionPlans {
          id
          nickname
          amount
          interval
          productId
        }
      }
      orderItem {
        id
        variableValues
        scheduleEnd
        scheduleStart
        timeZoneCode
        status
        offerId
        product {
          id
          description
          architectureId
          name
          isAvailable
          requiresContent
          blueprintId
          messaging {
            id
            statePendingHelpText
            adPreviewFooterMessage
          }
          offers {
            id
            productId
            billingMethod
            name
            isActive
            availableToRoles
            type
            purchasePriceAmount
            purchasePriceIsUserSet
            purchasePriceUserSetMin
            purchasePriceUserSetMax
            subscriptionStripeProductId
            cancellationType
            stripeSubscriptionPlans {
              id
              nickname
              amount
              interval
              productId
            }
          }
          inputSections {
            productId
            sectionId
            name
            isMutable
            displaySortOrder
            inputFields {
              productId
              sectionId
              blueprintVariableId
              displayName
              displayMethodId
              displaySortOrder
              displayParameters
              isEditable
              isHidden
              blueprintVariable {
                id
                blueprintId
                name
                type
                friendlyName
                description
                isRequired
                isExpressionAllowed
                defaultValue
                integerMinValue
                integerMaxValue
                decimalMinValue
                decimalMaxValue
                stringMinLength
                stringMaxLength
                stringRegex
                enumValues
                referenceTarget
                customValidationMethod
                isArray
                arrayMinItems
                arrayMaxItems
                validationRules
              }
            }
          }
          dynamicFieldMetadata {
            fieldName
            label
            sampleValue
          }
          blueprint {
            id
            objectives {
              slug
            }
            publishers {
              id
              prototype {
                id
                code
                unit {
                  id
                  channelCode
                  code
                }
              }
              publisherFacets {
                id
                facet {
                  id
                  code
                }
                parameterValues
              }
            }
          }
        }
        catalogItems {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
      channels {
        adType
        channels
      }
      accessControl {
        permissions
      }
    }
  }
`);

export const getTeamsOrder = gql(`
  query TeamsOrderForProgramPerformance($orderId: ID!, $showDeleted: Boolean) {
    order(orderId: $orderId, showDeleted: $showDeleted) {
      architectureId
      id
      name
      isProgramActive
      programId
      paymentMethodId
      executionStatus {
        executionId
        status
        outcome
      }
      supervisorOrder {
        id
        name
      }
      belongsToMultiLocationProgram
      multiLocationProgram {
        id
        name
        architectureId
      }
      multiLocationChildProgramDetails {
        variableValuesAreOverridden
        scheduleIsOverridden
        budgetIsOverridden
        locationId
      }
      products {
        id
        name
        kpis {
          slug
          channelCode
          friendlyName
        }
      }
      product {
        isAvailable
        isMlp
      }
      billingDetails {
        offerType
        amount
        startDate
        endDate
        planId
        planNickname
        interval
        renewsOn
        isSetToCancel
        currentPeriodEnd
        canceledOn
        promoCode
        priceBeforeDiscount
        cardInfo
        stripeSourceId
        billingMethod
        tierId
        scheduledTierAmount
        scheduledBudgetDate
        amount
        cancellationDetails {
          originalPurchaseAmount
          amountAfterCalculatedUsage
          refundAmount
          cancellationFee
          cancellationDate
        }
        mostRecentBudgetAdjustment {
          stepFunctionsExecutionId
          outcome
          errorMessage
          errorCode
          updatedBudgetAmount
          newTierName
        }
      }
      adData {
        adPreviews
        totalAds
      }
      offer {
        subscriptionMinimumDurationDays
        purchaseScheduleAdjustmentEnabled
        purchaseOrderAmountAdjustmentEnabled
        subscriptionTierChangeMethod
        type
        cancellationType
        id
        productId
        billingMethod
        name
        isActive
        availableToRoles
        type
        purchasePriceAmount
        purchasePriceIsUserSet
        purchasePriceUserSetMin
        purchasePriceUserSetMax
        subscriptionStripeProductId
        cancellationType
        stripeSubscriptionPlans {
          id
          nickname
          amount
          interval
          productId
        }
      }
      orderItem {
        id
        variableValues
        scheduleEnd
        scheduleStart
        timeZoneCode
        status
        offerId
        product {
          id
          description
          architectureId
          name
          isAvailable
          requiresContent
          blueprintId
          messaging {
            id
            statePendingHelpText
            adPreviewFooterMessage
          }
          offers {
            id
            productId
            billingMethod
            name
            isActive
            availableToRoles
            type
            purchasePriceAmount
            purchasePriceIsUserSet
            purchasePriceUserSetMin
            purchasePriceUserSetMax
            subscriptionStripeProductId
            cancellationType
            stripeSubscriptionPlans {
              id
              nickname
              amount
              interval
              productId
            }
          }
          inputSections {
            productId
            sectionId
            name
            isMutable
            displaySortOrder
            inputFields {
              productId
              sectionId
              blueprintVariableId
              displayName
              displayMethodId
              displaySortOrder
              displayParameters
              isEditable
              isHidden
              blueprintVariable {
                id
                blueprintId
                name
                type
                friendlyName
                description
                isRequired
                isExpressionAllowed
                defaultValue
                integerMinValue
                integerMaxValue
                decimalMinValue
                decimalMaxValue
                stringMinLength
                stringMaxLength
                stringRegex
                enumValues
                referenceTarget
                customValidationMethod
                isArray
                arrayMinItems
                arrayMaxItems
                validationRules
              }
            }
          }
          dynamicFieldMetadata {
            fieldName
            label
            sampleValue
          }
          blueprint {
            id
            objectives {
              slug
            }
            publishers {
              id
              prototype {
                id
                code
                unit {
                  id
                  channelCode
                  code
                }
              }
              publisherFacets {
                id
                facet {
                  id
                  code
                }
                parameterValues
              }
            }
          }
        }
        catalogItems {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
      channels {
        adType
        channels
      }
      accessControl {
        permissions
      }
    }
  }
`);

export const getOrderHistory = gql(`
  query getOrderHistory($orderId: ID!, $first: Int!, $after: String) {
    order(orderId: $orderId) {
      id
      orderItem {
        id
        product {
          id
          inputSections {
            sectionId
            inputFields {
              blueprintVariable {
                name
                type
                friendlyName
              }
            }
          }
        }
        history(first: $first, after: $after)  {
          edges {
            cursor
            node {
              status
              scheduleStart
              scheduleEnd
              priceAmount
              variableValues
              createdAt
              updatedBy
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
      accessControl {
        permissions
      }
    }
  }
`);

export const calculateOrderItemRefund = gql(`
  query calculateOrderItemRefund($orderItemId: Long!) {
    calculateOrderItemRefund(orderItemId: $orderItemId) {
      totalRefund
      cancellationFee
      displayCalculation
      subTotal
      totalDays
      daysRemaining
      cancellationMessage
      cancellationMessageIsOverridden
      periodEnd
    }
  }
`);

export const getPaymentMethods = gql(`
  query PaymentMethod {
    paymentMethod {
      id
      userId
      payer
      organizationId
      officeId
      stripeSourceId
      stripeCustomerId
      stripeSource {
        cardBrand
        cardLastFour
        cardExpiryYear
        cardExpiryMonth
      }
    }
  }
`);

export const insights = gql(`
  query insights($programIds: [ID!]!, $kpis: [KpiInput!]!) {
    insights(programIds: $programIds, kpis: $kpis) {
      kpi {
        slug
        channelCode
        friendlyName
      }
      summary {
        sum
      }
      daily {
        date
        value
      }
    }
  }
`);

export const getMLPOrder = gql(`
  query MLPOrderForProgramPerformance(
    $multiLocationProgramId: ID!
  ) {
    getMultiLocationProgram(multiLocationProgramId: $multiLocationProgramId) {
      id
      architectureId
      userId
      name
      priceAmount
      scheduleStart
      scheduleEnd
      catalogId
      variableValues
      timeZoneCode
      tierId
      status
      accessControl {
        permissions
      }
      teamCapabilities {
        isOwnedByMe
        isSharedWithMe
        isSharedWithTeam
      }
      placementProgress {
        soFar
        outOf
      }
      product {
        id
        description
        architectureId
        name
        isAvailable
        requiresContent
        blueprintId
        blueprint {
          channels
        }
        messaging {
          id
          statePendingHelpText
          adPreviewFooterMessage
        }
        offers {
          id
          productId
          billingMethod
          name
          isActive
          availableToRoles
          type
          purchasePriceAmount
          purchasePriceIsUserSet
          purchasePriceUserSetMin
          purchasePriceUserSetMax
          subscriptionStripeProductId
          cancellationType
          stripeSubscriptionPlans {
            id
            nickname
            amount
            interval
            productId
          }
        }
        blueprint {
          id
          objectives {
            slug
          }
          publishers {
            id
            prototype {
              id
              code
              unit {
                id
                channelCode
                code
              }
            }
            publisherFacets {
              id
              facet {
                id
                code
              }
              parameterValues
            }
          }
        }
      }
      summedPriceAmount
    }
  }
`);
