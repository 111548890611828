import { gql } from 'src/generated/gql';

export const uploadAsset = gql(`
  mutation AssetReservation(
    $galleryId: ID!
    $assetType: AssetType!
    $assetScope: AssetScope!
    $fileName: String!
  ) {
    assetReservation(
      galleryId: $galleryId
      assetType: $assetType
      assetScope: $assetScope
      fileName: $fileName
    ) {
      assetId
      reservationURL
      expiration
    }
  }
`);

export const updateAsset = gql(`
  mutation UpdateAsset(
    $assetId: ID!
    $assetStatus: AssetStatus!
    $metadata: JSON!
  ) {
    updateAsset(
      assetId: $assetId
      assetStatus: $assetStatus
      metadata: $metadata
    ) {
      id
      galleryId
      userId
      value
      status
      metadata
      type
      scope
    }
  }
`);

export const deleteAsset = gql(`
  mutation DeleteGalleryAsset($assetId: ID!) {
    deleteAssetv2(assetId: $assetId)
  }
`);

export const assetReservationv2 = gql(`
  mutation assetReservationv2($input: AssetReservationInput!) {
    assetReservationv2(input: $input) {
      assetId
      reservationURL
      expiration
    }
  }
`);

export const updateAssetv2 = gql(`
  mutation updateAssetv2($input: UpdateAssetInput!) {
    updateAssetv2(input: $input) {
      ... on MediaAsset {
        id
        # galleryId Null error on BE
        name
        link
        status
        source
        type
        thirdPartyId
      }
    }
  }
`);

export const importGiphyAsset = gql(`
  mutation importGiphyAsset($input: ImportGiphyAssetInput!) {
    importGiphyAsset(input: $input) {
      ... on MediaAsset {
        id
        # galleryId Null error on BE
        name
        link
        status
        source
        type
        thirdPartyId
      }
    }
  }
`);

export const importShutterstockAsset = gql(`
  mutation importShutterstockAsset($input: ImportShutterstockAssetInput!) {
    importShutterstockAsset(input: $input) {
      ... on MediaAsset {
        id
        # galleryId Null error on BE
        name
        link
        status
        source
        type
        thirdPartyId
      }
    }
  }
`);

export const shareMediaAsset = gql(`
  mutation shareMediaAssetForGallery($mediaAssetId: ID!) {
    shareMediaAsset(mediaAssetId: $mediaAssetId) {... on MediaAsset {id}}
  }
`);

export const unshareMediaAsset = gql(`
  mutation unshareMediaAssetForGallery($mediaAssetId: ID!) {
    unshareMediaAsset(mediaAssetId: $mediaAssetId) {... on MediaAsset {id}}
  }
`);
