import { useState, useRef, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import { Button, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import ContentTable from 'src/components/ContentTable/ContentTable';
import Heading from 'src/components/PageElements/Heading';
import {
  calculateRowsInView,
  WindowDimensionsInterface
} from 'src/components/DataTable/helpers';

import { getArchitectureById } from './queries';

const defaultPageSize = 25;
const offestHeight = 330;
const standardRowHeight = 50;

const Content = () => {
  const [windowDimensions, setWindowDimensions] =
    useState<WindowDimensionsInterface>({
      height: window.innerHeight - offestHeight
    });

  // We are calculating the page size based on the window height so the table
  // will fill the screen & the onscroll loadMoreRows will work properly
  const pageSize = useRef(
    calculateRowsInView(windowDimensions, defaultPageSize, standardRowHeight)
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ height: window.innerHeight - offestHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [rowSelectionModel, setRowSelectionModel] = useState<any[]>([]);
  const params = useParams<{ architectureId: string }>();

  const setSelected = (newSelectionModel: any[] = []) => {
    setRowSelectionModel(newSelectionModel);
  };

  const { data, loading, error } = useQuery(getArchitectureById, {
    fetchPolicy: 'no-cache',
    variables: {
      architectureId: params?.architectureId
    }
  });

  const architecture = data?.architecture;

  if (loading) {
    return <Loading />;
  }

  if (error && !loading) {
    return (
      <ErrorMessage>
        {t('businessObjects:errors.errorGettingArchitecture')}
      </ErrorMessage>
    );
  }

  const architectureId = params?.architectureId;
  const displayCollapseKey = architecture?.catalog?.displayCollapseKey || 'id';
  const architectureName = architecture?.name || '';

  const title = t('businessObjects:headers.title', {
    architectureName
  });

  return (
    <>
      <Heading
        title={title}
        subTitle={t('businessObjects:headers.subTitle')}
        pageTitle={title}
      />

      <Button
        color="primary"
        component={Link}
        variant="contained"
        endIcon={<AddIcon />}
        disabled={rowSelectionModel.length === 0}
        sx={{ margin: '8px 0' }}
        to={generateLinkPathWithQueryParams(
          paths.architecture.programCreate,
          { architectureId },
          { contentIds: rowSelectionModel }
        )}
      >
        {t('businessObjects:buttons.createNewProgram')}
      </Button>
      <Box
        sx={{
          height: windowDimensions.height,
          marginTop: '20px',
          minHeight: '400px'
        }}
      >
        <ContentTable
          minMaxSelection={{ max: 3, min: 1 }}
          architectureId={architectureId}
          displayCollapseKey={displayCollapseKey}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setSelected}
          pageSize={pageSize.current}
          showBorder
        />
      </Box>
    </>
  );
};

export default Content;
