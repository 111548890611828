import { pick } from 'lodash';

import { ALLOWED_FIELDS, ALLOWED_COLUMN_FIELDS } from './constants';

// the data is slightly different so we can separate
// existing columns from new ones so we merge them back
// before we do anythign with it.
export const mergeColumnsData = formData => ({
  ...pick(formData, ALLOWED_FIELDS),
  columns: formData?.columns?.map(col => pick(col, ALLOWED_COLUMN_FIELDS)) || []
});

export const sortKeys = objectToSort => {
  return Object.keys(objectToSort)
    .sort()
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: objectToSort[key]
      }),
      {}
    );
};
