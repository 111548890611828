import { useMemo } from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { flow } from 'lodash';
import {
  Grid,
  Button,
  Divider,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { t } from 'i18next';
import { useSnackbar } from 'notistack';
import Modal from 'src/components/Modal';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { withAppSettings } from 'src/AppSettings';
import SelectOrAddCreditCard from 'src/components/CreditCards/SelectOrAddCreditCard';
import { graphql } from '@apollo/client/react/hoc';
import { importShutterstockAsset } from './mutations';
import { MEDIA_TYPE } from './constants';
import ThirdPartyMediaTile from './Tiles/ThirdPartyMediaTile';

const getText = () => ({
  infoText: t('gallery:purchaseModal.infoText'),
  invoiceInfo: t('gallery:purchaseModal.invoiceInfo'),
  cancel: t('gallery:purchaseModal.cancel'),
  purchaseButton: t('gallery:purchaseModal.purchaseButton'),
  failure: t('gallery:purchaseModal.failure'),
  total: t('gallery:purchaseModal.total'),
  free: t('gallery:purchaseModal.free'),
  shutterWatermark: t('gallery:purchaseModal.shutterWatermark'),
  shutterTerms: t('gallery:purchaseModal.shutterTerms'),
  termsHeader: t('gallery:purchaseModal.termHeader'),
  termsDescription: t('gallery:purchaseModal.termsDescription'),
  termsList: [
    t('gallery:purchaseModal.termsL1'),
    t('gallery:purchaseModal.termsL2'),
    t('gallery:purchaseModal.termsL3'),
    t('gallery:purchaseModal.termsL4')
  ]
});

const MediaPurchaseModal = ({
  closeModal,
  modalOpen,
  media,
  appSettings,
  formValues,
  returnToGallery,
  selectedImage,
  galleryRefetch,
  setIsAddingMedia,
  isAddingMedia,
  importShutterstockAsset
}) => {
  const text = getText();

  const { enqueueSnackbar } = useSnackbar();

  const invoiced = !media?.offer?.requirePaymentMethod;

  const stripeKey = appSettings?.app?.general?.stripeKey;
  const stripePromise = useMemo(() => loadStripe(stripeKey), [stripeKey]);
  const saveAsset = async () => {
    // turn this on when we have a shutterstock working.
    setIsAddingMedia(true);

    try {
      await importShutterstockAsset({
        variables: {
          input: {
            shutterstockId: selectedImage.id,
            name: selectedImage.id,
            searchId: selectedImage.searchId,
            ...(!invoiced && {
              paymentMethodId: formValues.paymentMethodId
            })
          }
        }
      });
      setIsAddingMedia(false);
      returnToGallery();
      galleryRefetch();
    } catch (error) {
      setIsAddingMedia(false);
      enqueueSnackbar(text.failure, { variant: 'error' });
    }
  };

  return (
    <Modal
      fullWidth
      headerText="Purchase Media"
      maxWidth="lg"
      onClose={closeModal}
      open={modalOpen}
      data-cy="purchase-media-modal"
      FooterComponent={() => (
        <>
          <Button onClick={closeModal}>{text.cancel}</Button>

          <Button
            color="primary"
            variant="contained"
            disabled={
              (!invoiced && !formValues?.paymentMethodId) || isAddingMedia
            }
            onClick={saveAsset}
            data-amp-media-library-shutterstock-purchase-click
            startIcon={isAddingMedia && <CircularProgress size={20} />}
          >
            {text.purchaseButton}
          </Button>
        </>
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={5}>
          <ThirdPartyMediaTile
            media={media}
            mediaType={MEDIA_TYPE.image}
            clipBottom="30px"
          />
        </Grid>
        <Grid item xs={7}>
          <Alert severity="info">
            {!invoiced ? text.infoText : text.invoiceInfo}{' '}
            {text.shutterWatermark}
            <br />
            <br />
            <Tooltip
              title={
                <>
                  <Typography variant="h6">{text?.termsHeader}</Typography>
                  <ul>
                    {text?.termsList?.map((li, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li key={`fart${i}`}>{li}</li>
                    ))}
                  </ul>
                </>
              }
            >
              <span style={{ textDecoration: 'underline' }}>
                {text.shutterTerms}
              </span>
            </Tooltip>
          </Alert>
          {!invoiced && (
            <Elements stripe={stripePromise}>
              <SelectOrAddCreditCard />
            </Elements>
          )}
          <br />
          <Divider />
          <br />
          <div
            style={{
              textAlign: 'right',
              fontSize: '20px',
              fontWeight: 'bold'
            }}
          >
            {text.total}:{' '}
            {media?.offer?.price ? `$${media?.offer?.price}` : text.free}
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default flow(
  connect(state => ({
    formValues: getFormValues('updateCreditCard')(state)
  })),
  graphql(importShutterstockAsset, { name: 'importShutterstockAsset' }),
  withAppSettings
)(MediaPurchaseModal);
