import { handleActions } from 'redux-actions';

import { actionConstants } from './actions';

const initialState = {
  selectedOffice: null,
  selectedOrgValue: '',
  selectedUser: null
};

export default handleActions(
  {
    [actionConstants.selectOrganization]: (state, { payload }) => {
      // Clear selected user & office when selecting an org
      return {
        selectedOffice: null,
        selectedOrgValue: payload,
        selectedUser: null
      };
    },
    [actionConstants.selectUser]: (state, { payload }) => {
      // Clear selected office when selecting a user
      return {
        ...state,
        selectedOffice: null,
        selectedUser: payload
      };
    },
    [actionConstants.selectOffice]: (state, { payload }) => {
      return {
        ...state,
        selectedOffice: payload
      };
    },
    [actionConstants.resetForm]: () => {
      return {
        selectedOffice: null,
        selectedOrgValue: '',
        selectedUser: null
      };
    }
  },
  initialState
);
