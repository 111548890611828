import { useState } from 'react';
import { flow, get } from 'lodash';
import { withApollo } from '@apollo/client/react/hoc';
import { unparse } from 'papaparse';
import { ApolloClient } from '@apollo/client';

import { Button, Theme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { grey } from '@mui/material/colors';
import { formatDate } from 'src/common/dates';
import { WithStyledClasses } from 'src/common/Style';

import { getLeads } from './queries';

interface Lead {
  node: {
    fields: {
      name: string;
      values: string[];
    }[];
    submittedAt: string;
  };
}

const MAX_LEADS = 1000;

const style = (theme: Theme) => ({
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${grey[300]}`
  },
  button: {}
});

const downloadFile = (filename: string, data: BlobPart) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const elem = window.document.createElement('a');
  elem.href = window.URL.createObjectURL(blob);
  elem.download = filename;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
};

const formatLeadsForCSV = (leads: Lead[]) =>
  leads.map(({ node }) => {
    // leads data is kind of ugly
    const fields = node.fields.reduce(
      (
        accum: Record<string, string>,
        field: { name: string; values: string[] }
      ) => ({
        ...accum,
        [field.name.replace(/_/gi, ' ')]: get(field, 'values[0]', '')
      }),
      {}
    );

    // get the values we want to sort first
    const {
      'first name': firstName,
      'last name': lastName,
      email,
      'phone number': phone,
      ...rest
    } = fields;

    return {
      name: `${firstName} ${lastName}`,
      email,
      phone,
      date: formatDate({ date: get(node, 'submittedAt') }),
      ...rest
    };
  });

const makeCSV = (
  client: ApolloClient<any>,
  setLoading: (loading: boolean) => void,
  programId: string
) => {
  setLoading(true);
  // disabled for TS migration
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  client
    .query({
      query: getLeads,
      variables: {
        ...(programId && { programId }),
        first: MAX_LEADS
      }
    })
    .then(({ data }) => {
      const edges = data?.userLeads?.edges;

      downloadFile('leads.csv', unparse(formatLeadsForCSV(edges as Lead[])));
      setLoading(false);
    });
};

interface LeadsExportProps {
  client: ApolloClient<any>;
  programId: string;
  classes: WithStyledClasses<typeof style>;
}

const LeadsExport = ({ client, programId, classes }: LeadsExportProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className={classes.flex}>
      <Button
        data-amp-click-download-contacts
        disabled={loading}
        variant="outlined"
        size="small"
        onClick={() => makeCSV(client, setLoading, programId)}
        className={classes.button}
      >
        Export As CSV
      </Button>
    </div>
  );
};

export default flow(withStyles(style), withApollo)(LeadsExport);
