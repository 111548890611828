import { gql } from 'src/generated/gql';

export const getAiTextSuggestions = gql(`
  query getAiTextSuggestions($inputs: [AiSuggestionInput!]!) {
    getAiTextSuggestions(inputs: $inputs) {
      suggestions
    }
  }
`);

export const stylizeTextWithAi = gql(`
  query stylizeWithAi($inputs: [AiStylizationInput!]!) {
    stylizeWithAi(inputs: $inputs) {
      styledText
    }
  }
`);
