import { useState, useMemo, useCallback } from 'react';
import { flow, some, find } from 'lodash';
import { FormSection, FieldArray } from 'redux-form';
import { t } from 'i18next';
import { graphql } from '@apollo/client/react/hoc';

import {
  useTheme,
  Button,
  Box,
  Typography,
  List,
  Autocomplete,
  TextField
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { fieldArrayFixDumbReduxFormError } from 'src/common/validations';
import { isDeveloper } from 'src/Auth/common';

import { DynamicForm } from 'src/components/ReduxForm';
import { useSnackbar } from 'notistack';
import { configureInputs } from 'src/components/ReduxForm/helpers';

import { ADD_PROTOTYPE_FORM_NAME } from './Constants';

import { publishersInputs } from './inputConfigs';
import { getPrototypeHelperData } from '../../queries';

import RenderFacetsInputs from './RenderFacetsInputs';
import AddPrototypeDrawer from './AddPrototypeDrawer';
import RemoveButton from '../RemoveButton';
import DraggablePublisherListItem from './DraggablePublisherListItem';

const styles = theme => ({
  contentContainer: {
    marginTop: theme.spacing(2)
  },
  emptySelection: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  moveContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  }
});

const pageText = ({ name }) => ({
  addButton: t('admin:blueprintBuilder.stepPublishersAddButton'),
  removeButton: t('admin:blueprintBuilder.stepPublishersRemoveButton'),
  undoButton: t('admin:blueprintBuilder.stepPublishersUndoButton'),
  removedSuccessMessage: t(
    'admin:blueprintBuilder.stepPublishersRemoveSuccessSnackMessage',
    {
      name
    }
  ),
  emptySelectionHeading: t(
    'admin:blueprintBuilder.stepPublishersEmptySelectionHeading'
  ),
  reorderPublisher: t('admin:blueprintBuilder.stepPublishersReorderPublisher')
});

const isDev = isDeveloper();
const devOnlyInputs = new Set([]);

const RenderPublishersInputs = props => {
  const { classes, fields, allUnitData } = props;
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const prototypesBySlug = useMemo(() => {
    return allUnitData.reduce((acc, unit) => {
      unit.prototypes.forEach(prototype => {
        acc[prototype?.slug] = prototype;
      });
      return acc;
    }, {});
  }, [allUnitData]);

  const prototypeFilterOptions = useMemo(() => {
    return fields.getAll().reduce((acc, publisher) => {
      if (prototypesBySlug?.[publisher.prototype]) {
        acc.push({
          name: prototypesBySlug?.[publisher.prototype]?.name,
          value: publisher.prototype
        });
      }

      return acc;
    }, []);
  }, [fields, prototypesBySlug]);

  const [selectedPublisher, setSelectedPublisher] = useState({
    field: `${fields.name}[0]`,
    index: 0
  });

  const [selectedPrototypeFilters, setSelectedPrototypeFilters] = useState([]);

  const handleSelectPrototypeFilter = useCallback(
    (e, newValue) => {
      setSelectedPrototypeFilters(newValue);
    },
    [setSelectedPrototypeFilters]
  );

  const handleSetSelectedPublisher = useCallback(newPublisher => {
    setSelectedPublisher(newPublisher);
  }, []);

  const text = useMemo(
    () =>
      pageText({
        name: selectedPublisher && fields.get(selectedPublisher.index)?.name
      }),
    [selectedPublisher, fields]
  );

  const selectedPrototypeUnit = useMemo(
    () =>
      find(allUnitData, unit => {
        return some(unit.prototypes, {
          slug: fields.get(selectedPublisher.index)?.prototype
        });
      }) || [],
    [allUnitData, fields, selectedPublisher.index]
  );

  const updatedPublishersInputs = useMemo(() => {
    const disabledInputs = isDev ? new Set() : devOnlyInputs;
    const prototypeOptions = selectedPrototypeUnit?.prototypes || [];
    return configureInputs({
      inputs: publishersInputs({}),
      disabledInputs,
      enumInputs: {
        prototype: 'prototype'
      },
      enumerationValues: [
        {
          enumeration: 'prototype',
          values: prototypeOptions.map(prototype => {
            return {
              name: prototype.name,
              value: prototype.slug
            };
          })
        }
      ]
    });
  }, [selectedPrototypeUnit]);

  const handleListItemClick = useCallback(
    (field, index) => {
      handleSetSelectedPublisher({ field, index });
    },
    [handleSetSelectedPublisher]
  );

  const addNewPublisher = useCallback(
    newPublisher => {
      fields.push(newPublisher);
    },
    [fields]
  );

  const removePublisher = useCallback(() => {
    const removedVariableData = fields.get(selectedPublisher.index);
    const removedVariableSelect = selectedPublisher;

    let afterRemoveSelectionIndex = null;

    // select the next item that will display after delete
    if (selectedPublisher.index - 1 >= 0) {
      afterRemoveSelectionIndex = {
        field: `${fields.name}[${selectedPublisher.index - 1}]`,
        index: selectedPublisher.index - 1
      };
    } else if (fields.length > 0) {
      afterRemoveSelectionIndex = {
        field: `${fields.name}[0]`,
        index: 0
      };
    }
    handleSetSelectedPublisher(afterRemoveSelectionIndex);

    // remove publisher
    fields.remove(selectedPublisher.index);

    // give option to undo
    enqueueSnackbar(text.removedSuccessMessage, {
      variant: 'success',
      persist: false,
      action: (
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              fields.push(removedVariableData);
              handleSetSelectedPublisher(removedVariableSelect);
            }}
          >
            {text.undoButton}
          </Button>
        </>
      )
    });
  }, [fields, selectedPublisher, handleSetSelectedPublisher, text]);

  const onDropPublisher = useCallback(
    (oldIndex, newIndex) => {
      // move the publisher
      fields.move(oldIndex, newIndex);

      // update selected data to match new index
      handleSetSelectedPublisher({
        field: `${fields.name}[${newIndex}]`,
        index: newIndex
      });
    },
    [fields, handleSetSelectedPublisher]
  );

  return (
    <>
      <Box
        sx={{
          marginBottom: theme => theme.spacing(2),
          display: 'flex',
          width: '100%'
        }}
      >
        <Autocomplete
          multiple
          limitTags={2}
          options={prototypeFilterOptions}
          getOptionLabel={option => option.name}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              label="Filter by Prototype"
              placeholder="Prototype"
            />
          )}
          onChange={handleSelectPrototypeFilter}
          sx={{
            minWidth: '300px',
            maxWidth: '33%'
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
          }
        }}
      >
        <List
          sx={{
            maxHeight: '1000px',
            overflowY: 'scroll',
            minWidth: '305px',
            padding: 0,
            border: theme => `1px solid ${theme.palette.grey[300]}`
          }}
        >
          <AddPrototypeDrawer
            key="AddPrototypeDrawerOne"
            form={`${ADD_PROTOTYPE_FORM_NAME}-1`}
            addNewPublisher={addNewPublisher}
            buttonType="listItemButton"
          />

          {fields.map((field, index) => {
            const fieldData = fields.get(index);
            return (
              <DraggablePublisherListItem
                field={field}
                fieldData={fieldData}
                index={index}
                selectedPublisher={selectedPublisher}
                selectedPrototypeFilters={selectedPrototypeFilters}
                prototypesBySlug={prototypesBySlug}
                handleListItemClick={handleListItemClick}
                onDropPublisher={onDropPublisher}
              />
            );
          })}
        </List>
        <Box
          sx={{
            paddingLeft: theme => theme.spacing(2),
            width: '100%'
          }}
        >
          {fields.length > 0 && selectedPublisher?.field ? (
            <div>
              <Box
                sx={{
                  alignItems: 'end',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <RemoveButton
                  key={selectedPublisher?.field}
                  onClick={removePublisher}
                  text={text.removeButton}
                />
              </Box>

              <FormSection name={selectedPublisher?.field}>
                <DynamicForm
                  key={`${selectedPublisher?.field}-publishers`}
                  inputs={updatedPublishersInputs}
                />

                <FieldArray
                  key={`${selectedPublisher?.field}-facets`}
                  component={RenderFacetsInputs}
                  name="facets"
                  validate={[fieldArrayFixDumbReduxFormError]}
                  selectedPrototypeUnit={selectedPrototypeUnit}
                  selectedPrototype={
                    fields.get(selectedPublisher.index)?.prototype
                  }
                />
              </FormSection>
            </div>
          ) : (
            <div className={classes.emptySelection}>
              <Typography variant="h6" align="center">
                {text.emptySelectionHeading}
              </Typography>
              <br />
              <AddPrototypeDrawer
                key="AddPrototypeDrawerTwo"
                addNewPublisher={addNewPublisher}
                form={`${ADD_PROTOTYPE_FORM_NAME}-2`}
              />
            </div>
          )}
        </Box>
      </Box>
    </>
  );
};

export default flow(
  withStyles(styles),
  graphql(getPrototypeHelperData, {
    name: 'getPrototypeHelperData',
    props: ({ getPrototypeHelperData }) => {
      const allUnitData = getPrototypeHelperData?.getUnits || [];

      return {
        allUnitData
      };
    }
  })
)(RenderPublishersInputs);
