import { useEffect } from 'react';
import { clone, isEmpty, isArray, some, omit, flow, isNil, get } from 'lodash';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import { DynamicForm } from 'src/components/ReduxForm';
import { BLUEPRINT_BUILDER_FORM_NAME } from 'src/pages/Admin/BlueprintBuilder/Constants';

import {
  displayParametersInputs,
  displayParameterConditionalRender,
  legacyDisplayParameterConditionalRender
} from '../constants';

const RenderDisplayParameters = props => {
  const { input, variableOptions, displayMethodId } = props;
  const conditionalRender = input?.value?.conditionalRender;

  const isLegacyConditionalRender =
    !isArray(conditionalRender) && !isEmpty(conditionalRender);

  let updatedDisplayParametersInputs = isLegacyConditionalRender
    ? // support legacy single conditionalRender ex : { name:'foo', value: 'bar' }
      [
        ...displayParametersInputs(displayMethodId),
        ...legacyDisplayParameterConditionalRender
      ]
    : // support new multiple conditionalRender ex : [{ name:'foo', value: 'bar' }]
      [
        ...displayParametersInputs(displayMethodId),
        ...displayParameterConditionalRender(variableOptions)
      ];

  if (
    isLegacyConditionalRender &&
    !conditionalRender?.name &&
    !conditionalRender?.value
  ) {
    // support legacy single conditional render value
    //   - when name & value are empty || null remove conditionalRender
    //     so we switch things over to multiple conditionals
    const updatedValues = input?.value;
    // when switching from the legacy conditionalRender we need to add an empty value so
    // redux does not get angry.
    updatedValues.conditionalRender = [{ name: '', value: '' }];

    input.onChange(updatedValues);

    updatedDisplayParametersInputs = [
      ...displayParametersInputs(displayMethodId),
      ...displayParameterConditionalRender(variableOptions)
    ];
  }

  // do any of the options have role filtering?
  const hasRoleFiltering = some(
    input?.value?.options,
    option => option?.metadata?.filtering?.li_group_role?.include.length > 0
  );
  // if so we want to automatically add the filtering option to the display parameters
  useEffect(() => {
    if (hasRoleFiltering) {
      input.onChange({ ...input.value, filtering: { groupFilter: true } });
    } else {
      input.onChange({ ...omit(input.value, 'filtering') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRoleFiltering]);

  const inputsToRender = updatedDisplayParametersInputs.map(parameterInput => {
    const updatedInput = clone(parameterInput);
    updatedInput.name = `${input?.name}.${parameterInput.name}`;

    return updatedInput;
  });

  return <DynamicForm inputs={inputsToRender} />;
};

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state);

  // get blueprint variables from the form state
  const variableOptionsFiltered = (
    formValues?.document?.blueprint?.variables || []
  ).reduce((options, val) => {
    if (!isNil(val.friendlyName) && !isNil(val.name)) {
      return [...options, { name: val.friendlyName, value: val.name }];
    }
    return options;
  }, []);

  const displayMethodId = get(
    formValues,
    ownProps?.selectedFieldName
  )?.displayMethod;

  return {
    variableOptions: variableOptionsFiltered,
    displayMethodId
  };
};

export default flow(connect(mapStateToProps))(RenderDisplayParameters);
