import { t } from 'i18next';

import { Grid } from '@mui/material';

import useProgram from 'src/pages/Program/utils/useProgram';

import { FormSection } from 'src/components/ReduxForm';
import PaymentTypeSelector from 'src/components/ScheduleSelector/PaymentTypeSelector';
import { getOfferOptions } from 'src/components/ScheduleSelector/utils';

const PaymentType = () => {
  const { selectedBlueprint, isAutomated } = useProgram();
  const paymentPlans = selectedBlueprint?.paymentPlans;

  const offers = isAutomated
    ? paymentPlans?.offers?.filter((offer: any) => offer.isSupervisable)
    : paymentPlans?.offers;

  return (
    <Grid
      sx={getOfferOptions(offers).length < 2 ? { display: 'none' } : {}}
      item
      xs={12}
    >
      <FormSection
        title={t('programCreate:spend.paymentTypeSectionTitle')}
        description={t('programCreate:spend.paymentTypeSectionDescription')}
      >
        <PaymentTypeSelector offers={offers} />
      </FormSection>
    </Grid>
  );
};

export default PaymentType;
