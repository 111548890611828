import { gql } from 'src/generated/gql';

export const getFacebookPagesV2 = gql(`
  query facebookPagesForProgram {
    facebookPagesV2 {
      id
      pageName
      pageId
      instagramId
      hasAcceptedTos
      pageStatus
      facebookUserId
    }
  }
`);

export const getFacebookPageSelections = gql(`
  query getFacebookPageSelections {
    getFacebookPageSelections {
      pageSettingsId
      pageId
      pageGroupId
      type
      name
      hasAcceptedTos
      instagramId
      isOrgDefault
      facebookUsername
      pageStatus
    }
  }
`);

export const getOrgDefaultFacebookPageGroup = gql(`
  query getOrgDefaultFacebookPageGroup {
    getOrgDefaultFacebookPageGroup {
      id
      name
      isOrgDefault
    }
  }
`);

export const getArchitectureById = gql(`
  query getArchitectureAndCatalogByArchitectureIds($architectureId: ID!) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      catalog {
        id
        contentSetId
        defaultGroupKey
        displayCollapseKey
        displayNameTemplate
        displayImageTemplate
        friendlyName
        fieldMetadata {
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          contentColumnType
          isFilterable
          enumValues
        }
      }
    }
  }
`);

export const getCatalogByArchitectureId = gql(`
  query getArchitectureAndCatalogByArchitectureIdForProgram(
    $architectureId: ID!
    $externalIds: [String]!
  ) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      catalog {
        id
        contentSetId
        defaultGroupKey
        displayCollapseKey
        displayNameTemplate
        displayImageTemplate
        friendlyName
        fieldMetadata {
          id
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          contentColumnType
          isFilterable
          enumValues
        }
        content(filter: { id: { in: $externalIds } }) {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
    }
  }
`);

export const getBlueprints = gql(`
  query ProductsForProgram(
    $architectureId: ID!
    $showInactiveBlueprints: Boolean
    $showInactiveOffers: Boolean
  ) {
    products(
      architectureId: $architectureId
      showInactive: $showInactiveBlueprints
    ) {
      id
      description
      architectureId
      name
      isAvailable
      requiresContent
      isMlp
      blueprintId
      offers(showInactive: $showInactiveOffers) {
        id
        isSupervisable
      }
      blueprint {
        id
        channels
        publishers {
          id
          prototype {
            id
            code
            unit {
              id
              channelCode
              code
            }
          }
        }
      }
    }
  }
`);

export const getSelectedBlueprint = gql(`
  query SelectedBlueprint(
    $architectureId: ID!
    $showInactiveBlueprints: Boolean
    $showInactiveOffers: Boolean,
    $productIds: [ID!]
  ) {
    products(
      productIds: $productIds
      architectureId: $architectureId
      showInactive: $showInactiveBlueprints
    ) {
      id
      description
      architectureId
      name
      isAvailable
      requiresContent
      isMlp
      blueprintId
      offers(showInactive: $showInactiveOffers) {
        id
        productId
        name
        isActive
        availableToRoles
        type
        purchaseDefaultDurationDays
        purchasePriceAmount
        purchasePriceIsUserSet
        purchasePriceUserSetMin
        purchasePriceUserSetMax
        subscriptionStripeProductId
        isSupervisable
        billingMethod
        stripeSubscriptionPlans {
          id
          nickname
          amount
          interval
          productId
        }
      }
      inputSections {
        productId
        sectionId
        name
        isMutable
        isCollapsed
        displaySortOrder
        description
        inputFields {
          id
          productId
          sectionId
          blueprintVariableId
          displayName
          displayMethodId
          displaySortOrder
          displayParameters
          isEditable
          isHidden
          blueprintVariable {
            id
            blueprintId
            name
            type
            friendlyName
            description
            isRequired
            isExpressionAllowed
            defaultValue
            integerMinValue
            integerMaxValue
            decimalMinValue
            decimalMaxValue
            stringMinLength
            stringMaxLength
            stringRegex
            enumValues
            referenceTarget
            customValidationMethod
            isArray
            arrayMinItems
            arrayMaxItems
            validationRules
          }
        }
      }
      dynamicFieldMetadata {
        fieldName
        label
        sampleValue
      }
      blueprint {
        id
        channels
        publishers {
          id
          prototype {
            id
            code
            unit {
              id
              channelCode
              code
            }
          }
          publisherFacets {
            id
            slug
            facet {
              id
              code
            }
            parameterValues
          }
        }
      }
      messaging {
        id
        spendMessage
        scheduleInfo
        endDateHelpText
        adPreviewFooterMessage
        sellingPoints
      }
    }
  }
`);

export const getAutomation = gql(`
  query AutomationForProgram($supervisorOrderId: ID!) {
    automation(supervisorOrderId: $supervisorOrderId) {
      id
      paymentAmount
      catalogFilter
      variableValues
      deactivatedAt
      childOrderDurationDays
      paymentMethodId
      name
      childOrderNameTemplate
      promoCode
      paymentMethod {
        id
        stripeSourceId
      }
      orders {
        id
        programId
      }
      tier {
        id
        amount
        pricingPlan {
          intervalUnit
          intervalLength
        }
      }
      offer {
        id
        productId
        name
        isActive
        availableToRoles
        type
        purchaseDefaultDurationDays
        purchasePriceAmount
        purchasePriceIsUserSet
        purchasePriceUserSetMin
        purchasePriceUserSetMax
        subscriptionStripeProductId
        billingMethod
        stripeSubscriptionPlans {
          id
          nickname
          amount
          interval
          productId
        }
        product {
          id
          description
          architectureId
          name
          isAvailable
          requiresContent
          isMlp
          blueprintId
          offers(showInactive: false) {
            id
            productId
            name
            isActive
            availableToRoles
            type
            isSupervisable
            purchaseDefaultDurationDays
            purchasePriceAmount
            purchasePriceIsUserSet
            purchasePriceUserSetMin
            purchasePriceUserSetMax
            subscriptionStripeProductId
            billingMethod
            stripeSubscriptionPlans {
              id
              nickname
              amount
              interval
              productId
            }
          }
          inputSections {
            productId
            sectionId
            name
            isMutable
            displaySortOrder
            inputFields {
              productId
              sectionId
              blueprintVariableId
              displayName
              displayMethodId
              displaySortOrder
              displayParameters
              isEditable
              isHidden
              blueprintVariable {
                id
                blueprintId
                name
                type
                friendlyName
                description
                isRequired
                isExpressionAllowed
                defaultValue
                integerMinValue
                integerMaxValue
                decimalMinValue
                decimalMaxValue
                stringMinLength
                stringMaxLength
                stringRegex
                enumValues
                referenceTarget
                customValidationMethod
                isArray
                arrayMinItems
                arrayMaxItems
                validationRules
              }
            }
          }
          dynamicFieldMetadata {
            fieldName
            label
            sampleValue
          }
          blueprint {
            id
            publishers {
              id
              prototype {
                id
                code
                unit {
                  id
                  channelCode
                  code
                }
              }
              publisherFacets {
                id
                facet {
                  id
                  code
                }
                parameterValues
              }
            }
          }
          messaging {
            id
            spendMessage
            scheduleInfo
            endDateHelpText
            adPreviewFooterMessage
          }
        }
      }
    }
  }
`);

export const getContentByArchitectureId = gql(`
  query getContentByArchitectureId(
    $architectureId: ID!
    $filter: JSON
    $endCursor: String
    $first: Int!
    $groupKey: String
  ) {
    architecture(architectureId: $architectureId) {
      id
      name
      isContentSelectable
      catalog {
        id
        accessPattern
        contentSetId
        defaultGroupKey
        displayCollapseKey
        displayNameTemplate
        displayImageTemplate
        friendlyName
        fieldMetadata {
          id
          displayMethodId
          displayMethod {
            id
            name
          }
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isEditable
          isHidden
          contentColumnType
          isFilterable
          enumValues
        }
        content(
          filter: $filter
          first: $first
          after: $endCursor
          groupKey: $groupKey
        ) {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`);

export const fetchUrlValidation = gql(`
query programValidateUrl($urlData: UrlValidationRequest!) {
  validateUrl(input: $urlData) {
    result
    httpStatus
  }
}
`);

export const getOrderToCloneProgram = gql(`
  query OrderForCloningProgram($orderId: ID!, $showDeleted: Boolean) {
    order(orderId: $orderId, showDeleted: $showDeleted) {
      architectureId
      id
      name
      isProgramActive
      programId
      paymentMethodId
      executionStatus {
        executionId
        status
        outcome
      }
      supervisorOrder {
        id
        name
      }
      belongsToMultiLocationProgram
      multiLocationProgram {
        id
        name
        architectureId
      }
      products {
        id
        name
        kpis {
          slug
          channelCode
          friendlyName
        }
      }
      billingDetails {
        offerType
        amount
        startDate
        endDate
        planId
        planNickname
        interval
        renewsOn
        isSetToCancel
        currentPeriodEnd
        canceledOn
        promoCode
        priceBeforeDiscount
        cardInfo
        stripeSourceId
        billingMethod
        tierId
        scheduledTierAmount
        scheduledBudgetDate
        amount
        cancellationDetails {
          originalPurchaseAmount
          amountAfterCalculatedUsage
          refundAmount
          cancellationFee
          cancellationDate
        }
        mostRecentBudgetAdjustment {
          stepFunctionsExecutionId
          outcome
          errorMessage
          errorCode
          updatedBudgetAmount
          newTierName
        }
      }
      adData {
        adPreviews
        totalAds
      }
      offer {
        id
        subscriptionMinimumDurationDays
        purchaseScheduleAdjustmentEnabled
        purchaseOrderAmountAdjustmentEnabled
        subscriptionTierChangeMethod
        type
        cancellationType
        billingMethod
      }
      orderItem {
        id
        variableValues
        scheduleEnd
        scheduleStart
        timeZoneCode
        status
        offerId
        product {
          id
          description
          architectureId
          name
          isAvailable
          requiresContent
          blueprintId
          messaging {
            id
            statePendingHelpText
            adPreviewFooterMessage
          }
          offers {
            productId
            billingMethod
            name
            isActive
            availableToRoles
            type
            purchasePriceAmount
            purchasePriceIsUserSet
            purchasePriceUserSetMin
            purchasePriceUserSetMax
            subscriptionStripeProductId
            cancellationType
            stripeSubscriptionPlans {
              id
              nickname
              amount
              interval
              productId
            }
          }
          inputSections {
            productId
            sectionId
            name
            isMutable
            displaySortOrder
            inputFields {
              productId
              sectionId
              blueprintVariableId
              displayName
              displayMethodId
              displaySortOrder
              displayParameters
              isEditable
              isHidden
              blueprintVariable {
                id
                blueprintId
                name
                type
                friendlyName
                description
                isRequired
                isExpressionAllowed
                defaultValue
                integerMinValue
                integerMaxValue
                decimalMinValue
                decimalMaxValue
                stringMinLength
                stringMaxLength
                stringRegex
                enumValues
                referenceTarget
                customValidationMethod
                isArray
                arrayMinItems
                arrayMaxItems
                validationRules
              }
            }
          }
          dynamicFieldMetadata {
            fieldName
            label
            sampleValue
          }
          blueprint {
            id
            objectives {
              slug
            }
            publishers {
              id
              prototype {
                id
                code
                unit {
                  id
                  channelCode
                  code
                }
              }
              publisherFacets {
                id
                facet {
                  id
                  code
                }
                parameterValues
              }
            }
          }
        }
        catalogItems {
          edges {
            node {
              id
              items {
                id
                fields
              }
            }
          }
        }
      }
      channels {
        adType
        channels
      }
    }
  }
`);

export const getMLPOrder = gql(`
  query getMLPOForEdit(
    $multiLocationProgramId: ID!
  ) {
    getMultiLocationProgram(multiLocationProgramId: $multiLocationProgramId) {
      id
      architectureId
      userId
      name
      priceAmount
      scheduleStart
      scheduleEnd
      catalogId
      variableValues
      timeZoneCode
      tierId
      status
      offerId
      accessControl {
        permissions
      }
      teamCapabilities {
        isOwnedByMe
        isSharedWithMe
        isSharedWithTeam
      }
      placementProgress {
        soFar
        outOf
      }
      product {
        id
        description
        architectureId
        name
        isAvailable
        requiresContent
        blueprintId
        messaging {
          id
          statePendingHelpText
          adPreviewFooterMessage
        }
        offers {
          id
          productId
          billingMethod
          name
          isActive
          availableToRoles
          type
          purchasePriceAmount
          purchasePriceIsUserSet
          purchasePriceUserSetMin
          purchasePriceUserSetMax
          subscriptionStripeProductId
          cancellationType
          stripeSubscriptionPlans {
            id
            nickname
            amount
            interval
            productId
          }
        }
        blueprint {
          id
          objectives {
            slug
          }
          publishers {
            id
            prototype {
              id
              code
              unit {
                id
                channelCode
                code
              }
            }
            publisherFacets {
              id
              facet {
                id
                code
              }
              parameterValues
            }
          }
        }
      }
      summedPriceAmount
      childOrders(first: 5000) {
        edges {
          cursor
          node {
            id
            orderItem {
              catalogItems {
                edges {
                  node {
                    id
                    items {
                      id
                      fields
                    }
                  }
                }
              }
            }
            multiLocationChildProgramDetails {
              variableValuesAreOverridden
              scheduleIsOverridden
              budgetIsOverridden
              locationId
            }
          }
        }
      }
    }
  }
`);

export const getSelectedLocations = gql(`
    query getLocationsForProgramEdit(
      $filter: LocationsQueryFilter
    ) {
      locations(
        first: 5000
        filter: $filter
      ) {
          edges {
            cursor
            node {
              id
              organizationId
              ownerId
              type
              name
              street
              region
              city
              postalCode
              websiteUrl
              phone
              emailAddress
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
      }
    }
  `);

export const getParentProgramToClone = gql(`
  query ParentProgramForCloning(
    $multiLocationProgramId: ID!
  ) {
    getMultiLocationProgram(multiLocationProgramId: $multiLocationProgramId) {
      id
      childOrders(first: 5000) {
        edges {
          node {
            architectureId
            id
            name
            isProgramActive
            programId
            belongsToMultiLocationProgram
            multiLocationProgram {
              id
              name
              architectureId
            }
            products(filter: { bypassPermissions: true }) {
              id
              name
            }
            product {
              isAvailable
            }
            billingDetails {
              offerType
              amount
              startDate
              endDate
              planId
              planNickname
              interval
              renewsOn
              isSetToCancel
              currentPeriodEnd
              canceledOn
              promoCode
              priceBeforeDiscount
              cardInfo
              stripeSourceId
              billingMethod
              tierId
              scheduledTierAmount
              scheduledBudgetDate
              amount
              cancellationDetails {
                originalPurchaseAmount
                amountAfterCalculatedUsage
                refundAmount
                cancellationFee
                cancellationDate
              }
              mostRecentBudgetAdjustment {
                stepFunctionsExecutionId
                outcome
                errorMessage
                errorCode
                updatedBudgetAmount
                newTierName
              }
            }
            offer {
              id
              subscriptionMinimumDurationDays
              purchaseScheduleAdjustmentEnabled
              purchaseOrderAmountAdjustmentEnabled
              subscriptionTierChangeMethod
              type
              cancellationType
              billingMethod
            }
            orderItem {
              id
              variableValues
              scheduleEnd
              scheduleStart
              timeZoneCode
              status
              offerId
              product {
                id
                description
                architectureId
                name
                isAvailable
                requiresContent
                blueprintId
                messaging {
                  id
                  statePendingHelpText
                  adPreviewFooterMessage
                }
                offers {
                  productId
                  billingMethod
                  name
                  isActive
                  availableToRoles
                  type
                  purchasePriceAmount
                  purchasePriceIsUserSet
                  purchasePriceUserSetMin
                  purchasePriceUserSetMax
                  subscriptionStripeProductId
                  cancellationType
                  stripeSubscriptionPlans {
                    id
                    nickname
                    amount
                    interval
                    productId
                  }
                }
                inputSections {
                  productId
                  sectionId
                  name
                  isMutable
                  displaySortOrder
                  inputFields {
                    productId
                    sectionId
                    blueprintVariableId
                    displayName
                    displayMethodId
                    displaySortOrder
                    displayParameters
                    isEditable
                    isHidden
                    blueprintVariable {
                      id
                      blueprintId
                      name
                      type
                      friendlyName
                      description
                      isRequired
                      isExpressionAllowed
                      defaultValue
                      integerMinValue
                      integerMaxValue
                      decimalMinValue
                      decimalMaxValue
                      stringMinLength
                      stringMaxLength
                      stringRegex
                      enumValues
                      referenceTarget
                      customValidationMethod
                      isArray
                      arrayMinItems
                      arrayMaxItems
                      validationRules
                    }
                  }
                }
                dynamicFieldMetadata {
                  fieldName
                  label
                  sampleValue
                }
                blueprint {
                  id
                  objectives {
                    slug
                  }
                  publishers {
                    id
                    prototype {
                      id
                      code
                      unit {
                        id
                        channelCode
                        code
                      }
                    }
                    publisherFacets {
                      id
                      facet {
                        id
                        code
                      }
                      parameterValues
                    }
                  }
                }
              }
              catalogItems {
                edges {
                  node {
                    id
                    items {
                      id
                      fields
                    }
                  }
                }
              }
            }
            multiLocationChildProgramDetails {
              variableValuesAreOverridden
              scheduleIsOverridden
              budgetIsOverridden
              locationId
            }
          }
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`);

export const DRAFT_MODELS = {
  MLP: 'MultiLocationProgramDraftModel',
  AUTOMATION: 'AutomationDraftModel',
  PROGRAM: 'ProgramDraftModel'
} as const;

export const getProgramDrafts = gql(`
  query GetProgramDraftsforProgram($first: Int!, $after: String, $filter: ProgramDraftsQueryFilterInput) {
    getProgramDrafts(first: $first, after: $after, filter: $filter) {
      edges {
        cursor
        node {
          id
          ... on ProgramDraftModel {
            architecture {
              id
            }
            product {
              id
              name
            }
            catalog {
              id
              friendlyName
            }
            propertiesId
            name
            priceAmount
            catalogFilter
            variableValues
            tierId
          }
          ... on MultiLocationProgramDraftModel {
            architecture {
              id
            }
            product {
              id
              name
            }
            catalog {
              id
              friendlyName
            }
            propertiesId
            name
            priceAmount
            catalogFilter
            variableValues
            tierId
            overrides {
              locationId
              variableValues
              priceAmount
              tierId
            }
          }
          ... on AutomationDraftModel {
            architecture {
              id
            }
            product {
              id
              name
            }
            catalog {
              id
              friendlyName
            }
            propertiesId
            name
            priceAmount
            catalogFilter
            variableValues
            tierId
            childOrderDurationDays
            childOrderNameTemplate
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`);
