import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { parse, stringify } from 'qs';

import { t } from 'i18next';

import { paths } from 'src/routes/paths';
import { Box } from '@mui/material';

import PageTitle from 'src/components/PageTitle';
import { useSwitchTheme } from 'src/hooks/useSwitchTheme';

import FacebookPageLinker from './FacebookPageLinker';
import { FACEBOOK_LINK_MODES, FACEBOOK_LINK_RETURN_URL } from './Constants';
import { AddFacebookAccount } from './PageLinkCard';

const FB_SEARCH_PARAMS = [
  {
    fbKey: 'denied_scopes',
    evKey: 'deniedScopes'
  },
  {
    fbKey: 'expires_in',
    evKey: 'expiresIn'
  },
  {
    fbKey: 'fb_access_token',
    evKey: 'fbAccessToken'
  },
  {
    fbKey: 'granted_scopes',
    evKey: 'grantedScopes'
  },
  {
    fbKey: 'state',
    evKey: 'state'
  },
  {
    fbKey: '?data_access_expiration_time',
    evKey: 'dataAccessExpirationTime'
  },
  { fbKey: 'long_lived_token', evKey: 'longLivedToken' }
];

const FacebookLink = props => {
  const { mode, continueCreateProgramUrl } = props;

  const history = useHistory();
  const location = useLocation();

  const searchParams = parse(location.search, {
    ignoreQueryPrefix: true
  });

  const { setThemeName, THEME_NAMES } = useSwitchTheme();

  useEffect(() => {
    setThemeName(THEME_NAMES.facebookPageLinking);

    return () => {
      setThemeName(THEME_NAMES.default);
    };
  }, [THEME_NAMES.default, THEME_NAMES.facebookPageLinking, setThemeName]);

  const getSearchParams = () => {
    const searchParams = parse(location.search.substr(1)) || {};
    const newSearch = {};

    FB_SEARCH_PARAMS.forEach(pmap => {
      if (
        searchParams[pmap.fbKey] === null ||
        searchParams[pmap.fbKey] === undefined
      ) {
        return;
      }

      newSearch[pmap.evKey] = searchParams[pmap.fbKey];
    });

    return newSearch;
  };

  const isLinkUseCase = () => {
    const search = getSearchParams();

    if (
      mode === FACEBOOK_LINK_MODES.NEW &&
      search.deniedScopes !== undefined &&
      search.expiresIn !== undefined &&
      search.fbAccessToken !== undefined &&
      search.grantedScopes !== undefined
    ) {
      return true;
    }

    return false;
  };

  const linkUseCase = isLinkUseCase();

  useEffect(() => {
    if (linkUseCase) {
      return;
    }

    if (Boolean(searchParams?.returnUrl) !== true) {
      localStorage.removeItem(FACEBOOK_LINK_RETURN_URL);
    }

    if (searchParams?.returnUrl) {
      const locationWithoutParams = { ...location, search: '' };
      history.replace(locationWithoutParams);
    }
  }, [linkUseCase]);

  const unsetLinkParams = () => {
    const { search = {} } = location;
    const currentParams = parse(search);

    FB_SEARCH_PARAMS.forEach(pmap => {
      if (
        currentParams[pmap.fbKey] !== null &&
        currentParams[pmap.fbKey] !== undefined
      ) {
        delete currentParams[pmap.fbKey];
      }
    });

    history.push(`${paths.facebook.link}?${stringify(currentParams)}`);
  };

  const handleCancelPageLink = () => {
    unsetLinkParams();
  };

  const HEIGHT_OFFSET = 128;

  return (
    <div>
      <PageTitle subPageTitle={t('facebookSettings:titleNewPage')} />
      <Box
        sx={theme => ({
          minHeight: `calc(100vh - ${
            HEIGHT_OFFSET + theme.evSizes.toolBarHeight
          }px)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        })}
      >
        {mode === FACEBOOK_LINK_MODES.NEW && linkUseCase ? (
          <FacebookPageLinker
            fbData={getSearchParams()}
            navigateToPageLinkStart={handleCancelPageLink}
            continueCreateProgramUrl={continueCreateProgramUrl}
          />
        ) : (
          <AddFacebookAccount />
        )}
      </Box>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const searchParams = parse(ownProps.location.search.substr(1)) || {};

  return {
    searchParams,
    continueCreateProgramUrl:
      state?.program?.continueCreateProgram ||
      localStorage.getItem(FACEBOOK_LINK_RETURN_URL) ||
      null
  };
};

export default connect(mapStateToProps, {})(FacebookLink);
