import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';

import { CircularProgress } from '@mui/material';

import i18n from './i18n';

interface TranslationProviderProps {
  children: JSX.Element;
}

const TranslationProvider = ({ children }: TranslationProviderProps) => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            alignItems: 'center',
            background: '#fafafa',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <CircularProgress />
        </div>
      }
    >
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </Suspense>
  );
};

export default TranslationProvider;
