import { Link, useLocation } from 'react-router-dom';

import { Divider } from '@mui/material';

import { paths } from 'src/routes/paths';
import { useAppSettings } from 'src/AppSettings';
import { useFeatures } from 'src/components/Feature';
import { useGlobalContext } from 'src/GlobalContextProvider';

import {
  getDashboardNavigationItems,
  getMenuIcon,
  isSideNavigationItemSelected,
  NAVIGATION_ITEM_TYPES,
  MenuIcon
} from './navigationUtil';
import DropdownMenuListItem from './DropdownMenuListItem';
import NavigationListItem from './NavigationListItem';

const NavigationListDashboard = () => {
  const location = useLocation<{ pathname: string }>();
  const { pathname } = location;

  const isAssetMenuItemSelected = (path: string) => {
    // Facebook asset menu item should be selected if the user is on any of these pages
    if (path === paths.facebook.pages) {
      return (
        pathname.includes(paths.facebook.pages) ||
        pathname.includes(paths.facebook.link) ||
        pathname.includes(paths.facebook.status)
      );
    }
    return pathname.includes(path);
  };

  const appSettings = useAppSettings();
  const globalContext = useGlobalContext();

  const stringOverrides = appSettings?.app?.strings ?? {};

  // This function ensures that the Assets dropdown menu item remains open when the page is refreshed/clicked into from a link
  // and the user lands on a page that is a child of the Assets dropdown menu item.
  const getDefaultAssetMenuState = () => {
    return (
      pathname.includes(paths.dashboard.audiences) ||
      pathname.includes(paths.facebook.pages) ||
      pathname.includes(paths.facebook.link) ||
      pathname.includes(paths.facebook.status) ||
      pathname.includes(paths.dashboard.leads) ||
      pathname.includes(paths.dashboard.gallery)
    );
  };

  const features = useFeatures();
  const architectures = globalContext?.architectures || [];

  const navigationItems = getDashboardNavigationItems({
    features,
    appSettings,
    stringOverrides,
    isAssetMenuItemSelected,
    getDefaultAssetMenuState,
    architectures
  });

  return (
    <div key="NavigationListDashboard">
      {navigationItems.map(subItem => {
        const divider = subItem.hasDivider ? <Divider /> : null;
        let listItem: null | JSX.Element = null;
        if (subItem.type === NAVIGATION_ITEM_TYPES.link || !subItem.type) {
          listItem = (
            <NavigationListItem
              key={subItem.key}
              component={Link}
              selected={isSideNavigationItemSelected(
                subItem.name,
                pathname,
                subItem.path || ''
              )}
              to={subItem.path}
              target={subItem.target}
              icon={getMenuIcon(subItem.key as MenuIcon)}
              name={subItem.name}
              subtitle={subItem.subtitle}
              customProps={subItem.customProps}
            />
          );
        }

        if (subItem.type === NAVIGATION_ITEM_TYPES.dropdown) {
          listItem = (
            <DropdownMenuListItem
              key={`${subItem.key}-dropdown`}
              name={subItem.name}
              menuItems={subItem.menuItems || []}
              getIcon={subItem?.getIcon}
              isSelected={subItem.isSelected}
              getDefaultState={subItem.getDefaultState}
              customProps={subItem.customProps}
            />
          );
        }

        return (
          <span key={`${subItem.key}-container`}>
            {listItem} {divider}
          </span>
        );
      })}
    </div>
  );
};

export default NavigationListDashboard;
