import {
  getGridSingleSelectOperators,
  getGridStringOperators
} from '@mui/x-data-grid-pro';

// Filter operators shared by Program and Automation tables
const supportedThemeOperators = ['is'];
export const getThemeOperators = (architectureNameById: any) => {
  const filteredOperators = getGridSingleSelectOperators().filter(
    ({ value }) => {
      return supportedThemeOperators.includes(value);
    }
  );

  const supportedOperators = filteredOperators.map(operator => {
    const updatedOperator = operator;

    updatedOperator.getValueAsString = value => {
      return architectureNameById?.[value];
    };

    return updatedOperator;
  });

  return supportedOperators;
};

interface TypeByIdFromArchitecturesInterface {
  [name: string]: string;
}
const supportedTypeOperators = ['is'];
export const getTypeOperators = (
  typeByIdFromArchitectures: TypeByIdFromArchitecturesInterface
) => {
  const filteredOperators = getGridSingleSelectOperators().filter(
    ({ value }) => {
      return supportedTypeOperators.includes(value);
    }
  );

  const supportedOperators = filteredOperators.map(operator => {
    const updatedOperator = operator;

    updatedOperator.getValueAsString = value => {
      return typeByIdFromArchitectures[value];
    };

    return updatedOperator;
  });

  return supportedOperators;
};

// Program Table Specific filter operators
export const supportedOrderIdOperators = ['equals'];
export const getProgramOrderIdOperators = () => {
  const filteredOperators = getGridStringOperators().filter(({ value }) => {
    return supportedOrderIdOperators.includes(value);
  });

  return filteredOperators;
};

export const supportedProgramNameOperators = ['contains'];
export const getProgramNameOperators = () => {
  const filteredOperators = getGridStringOperators().filter(({ value }) => {
    return supportedProgramNameOperators.includes(value);
  });

  return filteredOperators;
};

// Automation table specific filter operators
const supportedAutomationNameOperators = ['equals'];
export const getAutomationNameOperators = () => {
  const filteredOperators = getGridStringOperators().filter(({ value }) => {
    return supportedAutomationNameOperators.includes(value);
  });

  return filteredOperators;
};
