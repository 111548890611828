import { useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { t } from 'i18next';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import Instrumentation from 'src/instrumentation';
import { SettingsUpdateType } from 'src/generated/gql/graphql';
import { navigateToCloneProgram } from 'src/common/cloneProgram';
import { CLONE_PROGRAM_MODAL_DISMISS } from 'src/common/userSettings';
import SentryUtil from 'src/common/SentryUtil';
import { OrderForProgram } from 'src/pages/Programs/queries';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { getProgramTrackingType } from 'src/pages/Program/Constants';

import ConfirmationModal from '../ConfirmationModal';
import { modifyUserSettings as modifyUserSettingsMutation } from '../mutations';

interface CloneProgramConfirmationModalProps {
  open: OrderForProgram;
  onClose: () => void;
}

const CloneProgramConfirmationModal = ({
  open,
  onClose
}: CloneProgramConfirmationModalProps) => {
  const [bypassCloneProgramModal, setBypassCloneProgramModal] = useState(false);
  const history = useHistory();
  const [modifyUserSettings, { loading }] = useMutation(
    modifyUserSettingsMutation
  );
  const globalContext = useGlobalContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBypassCloneProgramModal(event.target.checked);
  };

  const eventData = {
    productId: open?.products?.[0]?.id,
    programId: open?.programId,
    path: history.location.pathname,
    architectureId: open?.architectureId,
    dontShowAgain: bypassCloneProgramModal,
    type: getProgramTrackingType({ isMultiLocation: open?.product?.isMlp })
  };

  const handleConfirm = async () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickCloneProgramConfirm,
      eventData
    );

    const order = open;

    // Save user preference to bypass the clone program modal next time
    if (bypassCloneProgramModal) {
      try {
        await modifyUserSettings({
          variables: {
            updateSettings: {
              [CLONE_PROGRAM_MODAL_DISMISS]: true
            },
            updateType: SettingsUpdateType.DeepMerge
          }
        });
        globalContext?.refetch();
      } catch (error: any) {
        SentryUtil.captureException(error);
      }
    }
    // Send user to create program form with clone flag set to true
    navigateToCloneProgram({ history, order });
  };

  const handleClose = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickCloneProgramCancel,
      eventData
    );
    onClose();
  };

  return (
    <ConfirmationModal
      open={!!open}
      onClose={handleClose}
      title={t('programs:cloneConfirmationModal.title')}
      onConfirm={handleConfirm}
      icon={<ContentCopyIcon />}
      maxWidth={660}
      confirmButtonLoading={loading}
      confirmButtonDisabled={loading}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography>{t('programs:cloneConfirmationModal.body')}</Typography>
        <FormControlLabel
          control={
            <Checkbox
              data-cy="bypass-clone-program-modal-checkbox"
              checked={bypassCloneProgramModal}
              onChange={handleChange}
              disabled={loading}
            />
          }
          label={t('programs:cloneConfirmationModal.checkboxLabel')}
        />
      </Box>
    </ConfirmationModal>
  );
};

export default CloneProgramConfirmationModal;
