import { gql } from 'src/generated/gql';

export const fetchEmailNotificationAsset = gql(`
  query fetchEmailNotificationAsset($organizationId: ID!) {
    fetchEmailNotificationAsset(organizationId: $organizationId) {
      id
      organizationId
      emailIdentityId
      primaryColor
      secondaryColor
      mainLogo
      productName
      font
      createdAt
      updatedAt
      deletedAt
    }
  }
`);

export const getEmailIdentityId = gql(`
  query fetchEmailIdentityId($organizationId: ID!) {
    fetchEmailIdentity(organizationId: $organizationId) {
      id
      domain
      mailFromSubdomain
      status
      mailFromAddress
      senderName
      dnsEntries {
        name
        type
        value
        isComplete
      }
    }
  }
`);
