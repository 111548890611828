import { t } from 'i18next';

import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';

import { formatPriceWithCents } from 'src/common/numbers';
import HelpTooltip from 'src/components/HelpTooltip';

interface Refund {
  daysRemaining: number;
  totalDays: number;
  subTotal: number;
  cancellationFee: number;
  totalRefund: number;
}

interface RefundTableProps {
  refund: Refund;
}

const pageText = ({
  daysRemaining,
  totalDays
}: {
  daysRemaining?: number;
  totalDays?: number;
}) => ({
  daysRemaining: t('programPerf:cancelModal.daysRemaining'),
  remainingBudget: t('programPerf:cancelModal.remainingBudget'),
  remainingBudgetTip: t('programPerf:cancelModal.remainingBudgetTip'),
  prepaidAmountTip: t('programPerf:cancelModal.prepaidAmountTip'),
  prepaidAmount: t('programPerf:cancelModal.prepaidAmount'),
  totalRefund: t('programPerf:cancelModal.totalRefund'),
  daysRemainingCount: t('programPerf:cancelModal.daysCount', {
    daysRemaining: daysRemaining || '',
    totalDays: totalDays || ''
  })
});

const RefundTable = ({ refund }: RefundTableProps) => {
  const text = pageText({
    totalDays: refund?.totalDays,
    daysRemaining: refund?.daysRemaining
  });
  return (
    <Box
      component="table"
      sx={{
        borderCollapse: 'collapse',
        margin: '15px auto 0px auto',
        '& th': {
          textAlign: 'right',
          padding: '0px 20px 0px 0px',
          fontWeight: 'normal'
        },
        '& td': {
          textAlign: 'right'
        }
      }}
    >
      <tbody>
        <tr>
          <th>{text.daysRemaining}</th>
          <td>{text.daysRemainingCount}</td>
        </tr>
        <tr>
          <th>
            <HelpTooltip title={text.remainingBudgetTip} />{' '}
            {text.remainingBudget}
          </th>
          <td>{formatPriceWithCents(refund?.subTotal)}</td>
        </tr>
        <Box
          component="tr"
          sx={{
            '& th, & td': {
              pb: 1
            }
          }}
        >
          <th>
            <HelpTooltip title={text.prepaidAmountTip} /> {text.prepaidAmount}
          </th>

          <td>{formatPriceWithCents(refund?.cancellationFee)}</td>
        </Box>
        <Box
          component="tr"
          sx={{
            borderTop: `1px solid ${grey[700]}`,
            fontWeight: 'bold',

            '& th, & td': {
              fontSize: '16px',
              pt: 1
            }
          }}
        >
          <th>
            <strong>{text?.totalRefund} </strong>
          </th>
          <td>
            <strong>{formatPriceWithCents(refund?.totalRefund)}</strong>
          </td>
        </Box>
      </tbody>
    </Box>
  );
};

export default RefundTable;
