import { get, isEmpty } from 'lodash';
import { t } from 'i18next';

import { facebookCreativeTypes } from 'src/common/adChannels';

import { translateMaps } from 'src/common/templateTranslator';

const allWhitespace = /^\s*$/;

export const generateDefaultProgramName = (
  selectedBlueprint = {},
  displayNameTemplate
) => {
  let defaultBlueprintName = get(selectedBlueprint, 'name', '');

  const blueprintType = get(
    selectedBlueprint,
    'blueprint.publishers[0].prototype.code'
  );

  switch (blueprintType) {
    // For Single Image ads, include the displayName template in the name.
    case facebookCreativeTypes.singleImage:
      if (displayNameTemplate) {
        defaultBlueprintName += ` - ${displayNameTemplate}`;
      }
      break;
    // For all other blueprint types, use the blueprint's name as the order.
    case facebookCreativeTypes.multiProductCarousel:
      break;
    case facebookCreativeTypes.dynamicAdCreative:
      break;
    case facebookCreativeTypes.dynamicCreativeOptimization:
      break;
    default:
    // Do nothing;
  }

  return defaultBlueprintName;
};

export const getProgramName = (
  programNameInputValue,
  selectedBlueprint = {},
  displayNameTemplate
) => {
  if (
    programNameInputValue &&
    !isEmpty(programNameInputValue) &&
    !allWhitespace.test(programNameInputValue)
  ) {
    return programNameInputValue;
  }

  return generateDefaultProgramName(selectedBlueprint, displayNameTemplate);
};

export const getProgramNameTranslated = (
  programNameInputValue,
  selectedBlueprint = {},
  businessObjects = [],
  displayNameTemplate,
  userMetadataFields = {}
) => {
  const defaultProgramName = generateDefaultProgramName(
    selectedBlueprint,
    displayNameTemplate
  );
  const businessObject =
    { ...get(businessObjects, '0'), ...userMetadataFields } || null;

  if (
    programNameInputValue &&
    !isEmpty(programNameInputValue) &&
    !allWhitespace.test(programNameInputValue)
  ) {
    return translateMaps(programNameInputValue, businessObject);
  }

  if (
    !programNameInputValue &&
    defaultProgramName &&
    !isEmpty(defaultProgramName) &&
    !allWhitespace.test(defaultProgramName)
  ) {
    return translateMaps(defaultProgramName, businessObject);
  }

  return defaultProgramName;
};

export const getProgramNameLabel = isMlp =>
  isMlp
    ? t('programCreate:programNameInput.labelMlp')
    : t('programCreate:programNameInput.label');

export const getInitialValuesProgramName = ({
  selectedBlueprint,
  displayNameTemplate,
  orderToClone = null,
  isDraft = false,
  automation
}) => {
  // account for edit automation
  if (automation?.name) {
    return automation.name;
  }

  const COPY = `(${t('program:clone.copy')})`;
  const isClone = !!orderToClone;

  if (isDraft && orderToClone?.name) {
    return orderToClone.name;
  }

  if (selectedBlueprint?.requiresContent) {
    // Generate dynamic name
    const dynamicName = generateDefaultProgramName(
      selectedBlueprint,
      displayNameTemplate
    );

    // Append (Copy) to the name if it's a clone
    return isClone && !isDraft ? `${dynamicName} ${COPY}` : dynamicName;
  }

  // If cloned, use the cloned programs static name and append (Copy) only if the
  // name doesn't already end in copy
  const shouldAppendCopy =
    isClone && !isDraft && !orderToClone?.name?.endsWith(COPY);

  return isClone
    ? `${orderToClone?.name}${shouldAppendCopy ? ` ${COPY}` : ''}`
    : selectedBlueprint?.name;
};
