import { t } from 'i18next';

import { Box, Button } from '@mui/material';

import Modal from 'src/components/Modal';

import CheckoutForm from './CheckoutForm';

const pageText = () => ({
  addCard: t('programCreate:Checkout.addCard'),
  addCardHeader: t('programCreate:Checkout.addCardHeader')
});

interface CheckoutControllerProps {
  inline: boolean;
  updatingPayment: boolean;
  stripeError: string | null;
  handleAddCard: () => void;
  toggleAddPayment: () => void;
  addPaymentMethodModal: boolean;
  onStripeReady: () => void;
}

const CheckoutController = (props: CheckoutControllerProps) => {
  const {
    inline,
    updatingPayment,
    stripeError,
    handleAddCard,
    toggleAddPayment,
    addPaymentMethodModal,
    onStripeReady
  } = props;

  const text = pageText();

  return (
    <>
      {inline && (
        <Box
          sx={{
            borderColor: 'inputBorderColor',
            borderRadius: 'evSizes.inputBorderRadius'
          }}
        >
          <CheckoutForm
            disabled={updatingPayment}
            handleAddCard={handleAddCard}
            handleClose={toggleAddPayment}
            inline={inline}
            onStripeReady={onStripeReady}
          />
          {stripeError && (
            <Box
              sx={{
                color: theme => theme.palette.error.main,
                fontSize: '0.8rem'
              }}
            >
              {stripeError}
            </Box>
          )}
        </Box>
      )}
      {!inline && !updatingPayment && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          <Button
            color="primary"
            onClick={toggleAddPayment}
            data-cy="add-card-button-checkout-controller"
          >
            {text.addCard}
          </Button>
        </Box>
      )}
      <Modal // `disableBackdropClick` is removed by codemod.
        // You can find more details about this breaking change in [the migration guide](https://mui.com/material-ui/migration/v5-component-changes/#modal)
        open={addPaymentMethodModal}
        onClose={toggleAddPayment}
        headerText={text.addCardHeader}
        fullWidth
        maxWidth="xs"
        showClose={false}
        disableEscapeKeyDown
      >
        <CheckoutForm
          handleAddCard={handleAddCard}
          handleClose={toggleAddPayment}
        />
      </Modal>
    </>
  );
};

export default CheckoutController;
