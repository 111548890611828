import { isEmpty, flow } from 'lodash';
import { t } from 'i18next';

import { Grid, Card, CardHeader, CardContent, Box } from '@mui/material';

import { withGlobalContext } from 'src/GlobalContextProvider';
import Heading from 'src/components/PageElements/Heading';

import {
  adminQuickStarts,
  settingsAdminQuickStarts,
  moneyAdminQuickStarts,
  contentAdminQuickStarts,
  toolsAdminQuickStarts,
  developerToolsAdminQuickStarts
} from './Constants';
import AdminQuickStartButton from './AdminQuickStartButton';

export const getAdminQuickStart = isEvocalizeOrg => [
  {
    heading: () => t('admin:quickStart.common'),
    items: adminQuickStarts(isEvocalizeOrg),
    key: 'admin-quick-start-common'
  },
  {
    heading: () => t('admin:quickStart.settings'),
    items: settingsAdminQuickStarts(),
    key: 'admin-quick-start-settings'
  },
  {
    heading: () => t('admin:quickStart.money'),
    items: moneyAdminQuickStarts(),
    key: 'admin-quick-start-money'
  },
  {
    heading: () => t('admin:quickStart.content'),
    items: contentAdminQuickStarts(),
    key: 'admin-quick-start-content'
  },
  {
    heading: () => t('admin:quickStart.tools'),
    items: toolsAdminQuickStarts(),
    key: 'admin-quick-start-tools'
  },
  {
    heading: () => t('admin:quickStart.developer'),
    items: developerToolsAdminQuickStarts(),
    key: 'admin-quick-start-developer-tools'
  }
  // The one tool in here is not that useful right now so removing from UI
  // {
  //     heading: () => t('admin:quickStart.utilities', ),
  //     items: utilitiesAdminQuickStarts(),
  //     key: 'admin-quick-start-utilities'
  // }
];

const Admin = ({ globalContext }) => {
  const {
    appPermissions: { isEvocalizeOrg }
  } = globalContext;

  const quickStartSections = getAdminQuickStart(isEvocalizeOrg);

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Heading
        title={t('admin:headings.dashboard')}
        subTitle={t('admin:headings.dashboardDescription')}
        pageTitle={t('admin:headings.dashboard')}
      />

      {quickStartSections
        .filter(section => !isEmpty(section.items))
        .map(section => {
          return (
            <Grid container spacing={3} key={section?.key}>
              <Grid item xs={12}>
                <Card elevation={6} sx={{ marginBottom: 2 }}>
                  <CardHeader title={section.heading()} />

                  <CardContent>
                    <Grid container spacing={3}>
                      {section.items
                        .filter(quickStart => !quickStart.hide)
                        .map(quickStart => {
                          return (
                            <Grid
                              item
                              xs={3}
                              sx={{
                                minWidth: '200px',
                                maxWidth: '350px'
                              }}
                              key={`${section.key}-${quickStart?.path}`}
                            >
                              <AdminQuickStartButton quickStart={quickStart} />
                            </Grid>
                          );
                        })}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          );
        })}
    </Box>
  );
};

export default flow(withGlobalContext)(Admin);
