import { useMemo } from 'react';
import { t } from 'i18next';

import { Fab, Tooltip } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import SaveChangesIcon from '@mui/icons-material/Save';

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: theme.zIndex.modal
  }
});

const pageText = () => ({
  tooltip: t('admin:blueprintBuilder.saveBlueprintChangesFabTooltip')
});

const SaveBlueprintChangesFab = props => {
  const { classes, setSubmitModalOpen } = props;
  const text = useMemo(() => pageText(), []);

  return (
    <div>
      <Tooltip arrow placement="left" title={text.tooltip}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="changeBlueprintChanges"
          onClick={() => {
            setSubmitModalOpen(true);
          }}
        >
          <SaveChangesIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(SaveBlueprintChangesFab);
