import { connect } from 'react-redux';
import { flow } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';

import { Button, CircularProgress, FormControl } from '@mui/material';

const required = value => (value ? undefined : 'Required');

const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? 'Passwords do not match' : undefined;

const UpdatePasswordForm = props => {
  const { submitting, invalid, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth>
        <Field
          name="password"
          component={RenderTextField}
          label={t('activation:passwordForm.password')}
          type="password"
          validate={[required]}
        />
        <br />
        <Field
          name="passwordConfirm"
          component={RenderTextField}
          label={t('activation:passwordForm.confirmPassword')}
          type="password"
          validate={[required, passwordsMustMatch]}
        />
        <br />
        <Field name="activationToken" component="input" type="hidden" />
        <Field name="audience" component="input" type="hidden" />
        <Field name="clientId" component="input" type="hidden" />
        <Button
          variant="contained"
          size="large"
          color="primary"
          disabled={submitting || invalid}
          type="submit"
        >
          <Trans i18nKey="activation:passwordForm.submit" />
        </Button>
      </FormControl>
      {submitting && <CircularProgress />}
    </form>
  );
};

export default flow(
  reduxForm({
    form: 'UpdatePasswordForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    updateUnregisteredFields: true,
    touchOnBlur: false,
    touchOnChange: true
  }),
  connect((state, { activationToken }) => ({
    initialValues: { activationToken }
  }))
)(UpdatePasswordForm);
