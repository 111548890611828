import { get, isEmpty } from 'lodash';

import { List, Button, Box } from '@mui/material';

import AddBoxIcon from '@mui/icons-material/AddBox';
import Loading from 'src/components/Loading';

import { ArchitectureProviderType } from 'src/pages/Architecture/ArchitectureProvider';
import { BusinessObject } from 'src/common/businessObjects';
import BusinessObjectListItem from './BusinessObjectListItem';

interface BusinessObjectListProps {
  architecture: ArchitectureProviderType;
  error?: boolean;
  selectedIds: string[];
  selectedBusinessObjects: {
    selectedBusinessObjects?: BusinessObject[];
    loading: boolean;
  };
  emptyBusinessObjectText: string;
  onDelete: (id: string) => void;
  openModal: () => void;
}

const BusinessObjectList = ({
  architecture,
  error,
  selectedIds,
  selectedBusinessObjects: { selectedBusinessObjects = [], loading },
  emptyBusinessObjectText,
  onDelete,
  openModal
}: BusinessObjectListProps) => {
  const displayImageTemplate = architecture?.catalog?.displayImageTemplate;
  const displayNameTemplate = architecture?.catalog?.displayNameTemplate;

  return (
    <List
      sx={{
        p: theme =>
          selectedBusinessObjects?.length ? 0 : theme.spacing(6.5, 0)
      }}
    >
      {isEmpty(selectedIds) ? (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            maxHeight: '90px'
          }}
        >
          <Button
            endIcon={<AddBoxIcon />}
            onClick={openModal}
            color={error ? 'error' : 'primary'}
            data-cy="configure-no-content-button"
          >
            {emptyBusinessObjectText}
          </Button>
        </Box>
      ) : (
        <>
          {loading && <Loading />}
          {!loading &&
            selectedBusinessObjects.map(businessObject => {
              const id = get(businessObject, 'id');
              return (
                <BusinessObjectListItem
                  key={`businessObject-${id}`}
                  businessObject={businessObject}
                  displayImageTemplate={displayImageTemplate}
                  displayNameTemplate={displayNameTemplate}
                  onDelete={() => onDelete(id)}
                  openModal={openModal}
                />
              );
            })}
        </>
      )}
    </List>
  );
};

export default BusinessObjectList;
