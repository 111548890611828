import { useState } from 'react';
import { t } from 'i18next';

const useCopyToClipboard = () => {
  const [copied, setCopied] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const copy = async (text: string) => {
    setIsLoading(true);
    if (!navigator?.clipboard) {
      setError(t('copyToClipboard:snackbar.clipboardNotSupported'));
      setIsLoading(false);
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopied(text);
      setIsLoading(false);
      return true;
    } catch (error) {
      setCopied(null);
      setError(t('copyToClipboard:snackbar.error'));
      setIsLoading(false);
      return false;
    }
  };

  return { copied, copy, error, isLoading };
};

export default useCopyToClipboard;
