import { EffectCallback, useEffect } from 'react';

/**
 * Invokes the given function on mount (which may happen more than once!)
 * including any cleanup.
 *
 * Note the docs for reusable state:
 * https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
 * notably, make sure that this function is resilient to double-mounting.
 *
 * So why use this custom hook instead of a plain useEffect?
 * Communication of intent!
 *
 * If someone uses useOnMount we know for a fact they wanted to make this code
 * run on mount of the component. If we were to use a plain useEffect with an
 * empty dep array then we may not understand the intent behind that empty dep
 * array. Was this meant to be used on mount only? did it coincendetally have
 * no deps? did it have a dep but it changed when we didn't want it to? We could
 * communicate these with comments, but we could also just call this function
 * and understand that "useOnMount === only call this on component mount".
 *
 * @param mountFunc - The function to invoke in our effect.
 *                    Follows all the rules of regular effect functions like
 *                    needing to return a cleanup func.
 */
const useOnMount = (mountFunc: EffectCallback) => {
  useEffect(() => {
    const unMountFunc = mountFunc();
    if (unMountFunc != null) {
      return unMountFunc;
    }
    // By definition, we need to ignore deps since this hook should only fire on mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useOnMount;
