import { Tooltip } from '@mui/material';
import type { TooltipProps } from '@mui/material';
import { HelpOutlineOutlined as HelpIcon } from '@mui/icons-material';

interface HelpTooltipProps {
  title?: TooltipProps['title'];
  placement?: TooltipProps['placement'];
}

const HelpTooltip = ({ title, placement = 'top' }: HelpTooltipProps) => (
  <Tooltip title={title} placement={placement}>
    <HelpIcon
      fontSize="inherit"
      sx={{
        color: 'grey.500',
        position: 'relative',
        top: '2px'
      }}
    />
  </Tooltip>
);

export default HelpTooltip;
