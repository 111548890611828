import { Typography } from '@mui/material';

const SummaryValue = ({ children }) => {
  return (
    <Typography
      variant="body1"
      color="InactiveCaptionText"
      sx={{ whiteSpace: 'normal', fontWeight: 500 }}
    >
      {children}
    </Typography>
  );
};

export default SummaryValue;
