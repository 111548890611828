import PageTitle from 'src/components/PageTitle';

const Settings = () => {
  return (
    <div>
      <PageTitle subPageTitle="Settings" />
      Settings
    </div>
  );
};

export default Settings;
