import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { DRAWER_FULL_SCREEN_BREAKPOINT } from 'src/pages/Program/ProgramPreviewDrawer/constants';

export const MainContainer = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(5),
  flexDirection: 'row',
  display: 'flex',
  gap: theme.spacing(5),
  flexGrow: 1,
  transition: 'padding 0ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  [theme.breakpoints.between(0, DRAWER_FULL_SCREEN_BREAKPOINT)]: {
    gap: 0
  },
  [theme.breakpoints.down('md')]: {
    padding: 0
  }
}));
