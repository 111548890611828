import { flow } from 'lodash';

import { Card, Grid, Theme } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ErrorIcon from '@mui/icons-material/Error';

import { WithStyledClasses } from 'src/common/Style';

const styles = (theme: Theme) => ({
  errorContainer: {
    maxWidth: '450px',
    padding: theme.spacing(2),
    color: theme.palette.error.dark
  },
  errorIcon: {
    width: '50px',
    height: '50px',
    color: theme.palette.error.dark
  }
});

interface ErrorMessageProps {
  classes: WithStyledClasses<typeof styles>;
  className?: string;
  children: JSX.Element | string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  const { classes, className, children, ...restProps } = props;

  let containerClassName = classes.errorContainer;

  if (className) {
    containerClassName = `${className} ${containerClassName}`;
  }

  return (
    <Card {...restProps} className={containerClassName}>
      <Grid container spacing={3}>
        <Grid item xs={2}>
          <ErrorIcon className={classes.errorIcon} />
        </Grid>
        <Grid item xs={10}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default flow(withStyles(styles))(ErrorMessage);
