import { dayjs } from 'src/common/dates';
import { AppSettingsContextType } from 'src/AppSettings';

const DISMISS_DEACTIVATION_NOTICE_TIME = 'evDismissedDeactivationNotice';

export const getDeactivationDateFromTheme = (
  appSettings: AppSettingsContextType
) => {
  return appSettings?.app?.deactivation?.deactivationDate;
};

export const dismissDeactivationNotice = () => {
  localStorage.setItem(
    DISMISS_DEACTIVATION_NOTICE_TIME,
    dayjs().valueOf() as any
  );
};

export const getDeactivationNotice = () => {
  return localStorage.getItem(DISMISS_DEACTIVATION_NOTICE_TIME);
};

export const showDeactivationNotice = () => {
  const lastDismissed =
    getDeactivationNotice() && Number(getDeactivationNotice());

  // has never been dismissed so show notice
  if (!lastDismissed) {
    return true;
  }

  const diffInDays = dayjs().diff(lastDismissed, 'hours');

  // show if it has been more than 24 hours
  return diffInDays > 24;
};
