import { flow, get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { getFormValues, reduxForm } from 'redux-form';
import { Trans } from 'react-i18next';

import { Button, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import LayersIcon from '@mui/icons-material/Layers';
import LinkIcon from '@mui/icons-material/Link';

import {
  addElement,
  addLayer,
  removeElement,
  removeLayer,
  restTemplate
} from './actions';
import { backgroundImage, baseUrl, FORM_NAME } from './constants';
import { formatInputValueForTemplate } from './helpers';
import TemplateLayer from './TemplateLayer';

const styles = theme => ({
  addTemplateButton: {
    marginRight: theme.spacing()
  }
});

const CloudinaryTemplateBuilder = props => {
  const {
    layersById,
    allFormValues,
    addElement,
    addLayer,
    classes,
    removeElement,
    removeLayer,
    restTemplate
  } = props;

  const generateCloudinaryLink = () => {
    const finalUrl = [baseUrl];

    if (!isEmpty(allFormValues)) {
      Object.keys(layersById).forEach(layerId => {
        const elements = layersById[layerId].elementsById;
        const layerElements = formatInputValueForTemplate({
          allFormValues,
          elements,
          layerId
        });
        finalUrl.push(...layerElements);
      });
    }

    // backgroundImage must be last
    finalUrl.push(backgroundImage);

    return finalUrl.join('/');
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Trans i18nKey="admin:cloudinaryTemplateBuilder.templateBuilderDescription">
          At this time we only support one asset per layer for example text +
          position or image + position.
        </Trans>
      </Grid>
      <Grid item xs={12}>
        <Button
          className={classes.addTemplateButton}
          color="primary"
          onClick={addLayer}
          startIcon={<LayersIcon />}
          variant="outlined"
        >
          <Trans i18nKey="admin:cloudinaryTemplateBuilder.addTemplate">
            Add Template Layer
          </Trans>
        </Button>
        <Button onClick={restTemplate}>Reset</Button>
      </Grid>
      <Grid item xs={12}>
        <form autoComplete="off">
          {Object.keys(layersById).map((layerId, index) => {
            return (
              <TemplateLayer
                addElement={addElement}
                key={layerId}
                layer={layersById[layerId]}
                layerId={layerId}
                layerName={`Layer ${index + 1}`}
                removeElement={removeElement}
                removeLayer={removeLayer}
              />
            );
          })}
        </form>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          onClick={() => window.open(generateCloudinaryLink(), '_blank')}
          variant="contained"
          startIcon={<LinkIcon />}
        >
          <Trans i18nKey="admin:cloudinaryTemplateBuilder.getLink">
            Get Link
          </Trans>
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = state => {
  const allFormValues = getFormValues(FORM_NAME)(state);
  const layersById = get(state, 'cloudinaryTemplateBuilder.layersById');

  return {
    allFormValues,
    layersById
  };
};

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME
  }),
  connect(mapStateToProps, {
    addElement,
    addLayer,
    removeElement,
    removeLayer,
    restTemplate
  }),
  withStyles(styles)
)(CloudinaryTemplateBuilder);
