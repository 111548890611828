import { gql } from 'src/generated/gql';

export const getOrderFragmentsBreakdown = gql(`
  query getOrderFragmentsBreakdown($orderOrProgramId: ID!) {
    getOrderFragmentsBreakdown(orderOrProgramId: $orderOrProgramId) {
      units {
        channelCode
        unitCode
        count
      }
      content {
        contentValue
        count
      }
    }
  }
`);

export const findOrderFragments = gql(`
  query findOrderFragments(
    $after: String
    $first: Int!
    $input: FindOrderFragmentsInput!
  ) {
    findOrderFragments(after: $after, first: $first, input: $input) {
      results {
        edges {
          node {
            channelCode
            contentValue
            executionElementId
            fragmentId
            isManuallyManaged
            isMissing
            outputValue
            projectId
            publisherId
            unitCode
          }
          cursor
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          startCursor
          endCursor
        }
      }
    }
  }
`);
