import { Tabs, Tab, CircularProgress, Box } from '@mui/material';

import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';
import { GroupedChannelIcon } from 'src/components/ChannelIcon';

const TREATMENT_CHANNEL_TAB_ICON_SIZE = 20;

interface ChannelTabsProps {
  showIndicator: boolean;
  channelTabs: string[];
  handleTabChange: (newValue: string) => void;
  selectedTab: string;
  isPollingPreview: boolean;
}

const ChannelTabs = ({
  showIndicator,
  channelTabs,
  handleTabChange,
  selectedTab,
  isPollingPreview
}: ChannelTabsProps) => {
  const isProgramCreatePage = useIsProgramCreatePage();

  const iconProps: Record<string, any> = {};
  if (isProgramCreatePage) iconProps.size = TREATMENT_CHANNEL_TAB_ICON_SIZE;
  return (
    <Tabs
      variant="fullWidth"
      value={selectedTab}
      onChange={(event, newValue) => handleTabChange(newValue)}
      TabIndicatorProps={{
        style: {
          ...(!showIndicator && { backgroundColor: 'transparent' })
        }
      }}
      sx={isProgramCreatePage ? { width: '100%' } : {}}
    >
      {channelTabs.map(tab => {
        return (
          <Tab
            key={tab}
            label={
              <Box sx={{ position: 'relative' }}>
                <GroupedChannelIcon channel={tab} />
                {isPollingPreview && (
                  <Box
                    component="span"
                    sx={{
                      position: 'absolute',
                      top: '2px',
                      left: '30px'
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}
              </Box>
            }
          />
        );
      })}
    </Tabs>
  );
};

export default ChannelTabs;
