import { isNaN, isNumber } from 'lodash';

import { TableCell, Tooltip } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { alpha } from '@mui/material/styles';
import LazyLoad from 'react-lazy-load';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { getByKeyOrFunc } from 'src/common/utilities';
import formatters from 'src/common/formatters';
import JsonCell from './JsonCell';

const IMAGE_SIZE = 50;

const styles = {
  baseContainer: {
    minWidth: '50px',
    maxWidth: '250px'
  },
  unknownContainer: {
    minWidth: '50px',
    maxWidth: '250px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  textContainer: {
    minWidth: '150px',
    maxWidth: '250px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  idContainer: {
    fontSize: '10px',
    lineHeight: '11px',
    width: '150px'
  },
  tableSquareImage: {
    objectFit: 'cover'
  },
  overallCell: {
    padding: '5px'
  }
};

const ColumnContent = ({ data, type, styleOverrides = {} }) => {
  switch (type) {
    case INPUT_TYPES.IMAGE_URL:
      return (
        <Tooltip
          key={data}
          title={
            <img
              style={{
                ...styles.tableSquareImage,
                ...styleOverrides
              }}
              width="200"
              height="200"
              src={data}
              alt=""
            />
          }
        >
          <div
            style={{
              width: `${IMAGE_SIZE}px`,
              height: `${IMAGE_SIZE}px`
            }}
          >
            <LazyLoad width={IMAGE_SIZE} height={IMAGE_SIZE}>
              <img
                style={{
                  ...styles.tableSquareImage,
                  ...styleOverrides
                }}
                width={IMAGE_SIZE}
                height={IMAGE_SIZE}
                src={data}
                alt=""
                onLoad={() => {}}
              />
            </LazyLoad>
          </div>
        </Tooltip>
      );

    case INPUT_TYPES.PRICE_DECIMAL:
    // falls through
    case INPUT_TYPES.PRICE_INT:
      return (
        <div style={{ ...styles.baseContainer, ...styleOverrides }}>
          {formatters.CURRENCY(data)}
        </div>
      );

    case INPUT_TYPES.POSITIVE_INT:
      return (
        <div style={{ ...styles.baseContainer, ...styleOverrides }}>
          {formatters.NUMBER(data)}
        </div>
      );

    case INPUT_TYPES.POSITIVE_NUM:
    // falls through
    case INPUT_TYPES.ANY_NUMBER:
      return (
        <div style={{ ...styles.baseContainer, ...styleOverrides }}>
          {formatters.FLOAT(data)}
        </div>
      );

    case 'datetime_utc':
    // falls through
    case INPUT_TYPES.DATE_UTC:
      return (
        <div style={{ ...styles.baseContainer, ...styleOverrides }}>
          {formatters.DATE(data)}
        </div>
      );
    case INPUT_TYPES.PERCENTAGE_DECIMAL:
      let display = data;
      const num = parseFloat(data);
      if (!isNaN(num) && isNumber(num)) {
        display = `${Math.round(num * 100)}%`;
      }
      return (
        <div style={{ ...styles.baseContainer, ...styleOverrides }}>
          {display}
        </div>
      );
    case INPUT_TYPES.PHONE_NUMBER:
      return (
        <div style={{ ...styles.baseContainer, ...styleOverrides }}>
          {formatters.PHONE(data)}
        </div>
      );

    case INPUT_TYPES.VIDEO_URL:
    // falls through
    case INPUT_TYPES.LINK_URL:
      return (
        <a href={data} rel="noopener noreferrer" target="_blank">
          {data}
        </a>
      );

    case INPUT_TYPES.ZIP:
    // falls through
    case INPUT_TYPES.BOOLEAN:
      return (
        <span style={{ ...styles.baseContainer, ...styleOverrides }}>
          {data === true ? 'true' : 'false'}
        </span>
      );
    case INPUT_TYPES.FB_AUDIENCE_ID:
    // falls through
    case INPUT_TYPES.SINGLE_LINE_STRING:
    // falls through
    case INPUT_TYPES.MULTI_LINE_STRING:
      const content = (
        <div style={{ ...styles.textContainer, ...styleOverrides }}>{data}</div>
      );

      // Hard-coded for now. We can make this more intelligent later. For
      // now, we will show the tooltip if the text length is over 30
      // characters long.
      if (data && data.length > 30) {
        return <Tooltip title={data}>{content}</Tooltip>;
      }

      return content;
    case 'ID':
      return (
        <span style={{ ...styles.idContainer, ...styleOverrides }}>{data}</span>
      );
    case INPUT_TYPES.JSON:
      return <JsonCell data={data} styleOverrides={styleOverrides} />;
    default:
      return (
        <span style={{ ...styles.unknownContainer, ...styleOverrides }}>
          {data}
        </span>
      );
  }
};

const BodyColumn = ({
  CellComponent = null,
  accessor = () => {},
  row,
  type = null,
  styleOverrides,
  groupParent,
  grouped,
  childSelected,
  allChildrenSelected,
  highlightedColumns = [],
  theme
}) => {
  const CustomCell = CellComponent;
  const data = getByKeyOrFunc(row, accessor);

  return (
    <TableCell
      className="notranslate"
      style={{
        ...styles.overallCell,
        ...(grouped && { background: 'rgb(0, 0, 0, 0.07)' }),
        ...(highlightedColumns.includes(accessor) && {
          background: alpha(theme.palette.warning[100], 0.5)
        })
      }}
      align="center"
    >
      {CustomCell ? (
        <CustomCell
          row={row}
          data={data}
          groupParent={groupParent}
          grouped={grouped}
          childSelected={childSelected}
          allChildrenSelected={allChildrenSelected}
        />
      ) : (
        <ColumnContent
          data={data}
          type={type}
          styleOverrides={styleOverrides}
        />
      )}
    </TableCell>
  );
};

export default withTheme(BodyColumn);
