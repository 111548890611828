import { mapValues } from 'lodash';
import { t } from 'i18next';

import { facebookCreativeTypes } from 'src/common/adChannels';

import Message from 'src/components/Containers/Message';
import ClientHtml from 'src/components/ClientHtml';

import { addChips, getMessageIconSize, getMessageMaxWidth } from '../helpers';
import FacebookAdPreview from './FacebookAdPreview';
import PhoneMockAdPreviewWrapper from '../PhoneMockAdPreviewWrapper';
import { PHONE_MOCK_MESSAGE_WIDTH } from '../Constants';

function shouldValidateBusinessObjectCountForCreative(creativeType) {
  return (
    creativeType !== facebookCreativeTypes.fbSingleProductCarousel &&
    creativeType !== facebookCreativeTypes.fbDareCarousel
  );
}

const pageText = ({ contentName }) => ({
  unsupportedMessage: t('adPreview:messages.unsupportedFacebookType'),
  dcoMessage: t('adPreview:messages.dcoMessage'),
  selectMoreMessage: t('adPreview:selectMoreBusinessObjects', {
    contentName: contentName.toLowerCase()
  })
});

const CreateFacebookPreview = ({
  architectureHasCatalog,
  blueprint,
  businessObjects,
  creativeType,
  adPreviewSet, // expects one of the previews from getAdPreviewSet
  facebookPageName,
  isAutomatedProgram,
  showMessaging = true,
  contentName,
  disableResponsiveStyles,
  hasUniformPreviewWidth,
  displayAsPhoneMockUp,
  isResponsive
}) => {
  let previewProps = {
    creativeType,
    isLeadAd: false,
    disableResponsiveStyles,
    hasUniformPreviewWidth,
    isResponsive
  };

  let extraMessage = null;
  let blueprintTypeMessage = null;
  const footerMessage = blueprint?.messaging?.adPreviewFooterMessage || null;

  const text = pageText({ contentName });

  switch (creativeType) {
    case facebookCreativeTypes?.fbSingleImage:
      const singleImageData = mapValues(
        adPreviewSet?.fbSingleImageCreative,
        item => {
          return addChips(item);
        }
      );

      previewProps = {
        ...previewProps,
        isLeadAd: adPreviewSet?.fbSingleImageCreative?.isLeadAd,
        cards: [
          {
            name: singleImageData?.headline,
            picture: singleImageData?.imageUrl,
            key: 'card1',
            ...singleImageData
          }
        ],
        callToAction: adPreviewSet?.fbSingleImageCreative?.callToAction
      };
      break;
    case facebookCreativeTypes?.fbSingleVideo:
      const singleVideoData = mapValues(
        adPreviewSet?.fbSingleVideoCreative,
        item => {
          return addChips(item);
        }
      );

      previewProps = {
        ...previewProps,
        isLeadAd: adPreviewSet?.fbSingleVideoCreative?.isLeadAd,
        cards: [
          {
            name: singleVideoData?.headline,
            url: singleVideoData?.videoUrl,
            key: 'card1',
            ...singleVideoData
          }
        ],
        callToAction: adPreviewSet?.fbSingleVideoCreative?.callToAction
      };
      break;
    // dare carousels and carousels function equivalently except for the
    // fact that dare carousels should not show content error messages
    case facebookCreativeTypes?.fbDareCarousel:
    case facebookCreativeTypes?.fbSingleProductCarousel:
    case facebookCreativeTypes?.fbCarousel:
      previewProps = {
        ...previewProps,
        isLeadAd: adPreviewSet?.fbCarousel?.isLeadAd,
        cards: adPreviewSet?.fbCarouselCreative?.cards.map((card, index) => {
          const carouselCardData = mapValues(card, item => {
            return addChips(item);
          });

          return {
            name: carouselCardData?.headline,
            picture: carouselCardData?.imageUrl,
            message: addChips(adPreviewSet?.fbCarouselCreative?.message),
            ...carouselCardData,
            key: `card${index}`
          };
        }),
        callToAction: adPreviewSet?.fbCarouselCreative?.callToAction
      };

      if (
        !isAutomatedProgram &&
        shouldValidateBusinessObjectCountForCreative(creativeType) &&
        blueprint?.requiresContent &&
        businessObjects.length < 3
      ) {
        extraMessage = text.selectMoreMessage;
      }
      break;
    case facebookCreativeTypes?.fbDco:
      // Note: We are just selecting the first one until the dco ad preview has been updated to page through the permutations.
      const dcoData = mapValues(
        adPreviewSet?.fbDcoCreative?.singleImageAdPermutations?.[0],
        item => {
          return addChips(item);
        }
      );

      previewProps = {
        ...previewProps,
        isLeadAd: adPreviewSet?.fbDcoCreative?.isLeadAd,
        cards: [
          {
            name: dcoData?.headline,
            picture: dcoData?.imageUrl,
            ...dcoData
          }
        ],
        message: dcoData?.message,
        callToAction: dcoData?.callToAction
      };

      blueprintTypeMessage = text.dcoMessage;
      break;
    default:
      return displayAsPhoneMockUp ? (
        <Message
          type="info"
          iconSize="small"
          maxWidth={PHONE_MOCK_MESSAGE_WIDTH}
        >
          {text.unsupportedMessage}
        </Message>
      ) : (
        text.unsupportedMessage
      );
  }

  const adPreview = (
    <FacebookAdPreview
      {...previewProps}
      pageName={facebookPageName}
      requireBusinessObjects={architectureHasCatalog === true}
      hasBusinessObjects={
        businessObjects.length ||
        creativeType === facebookCreativeTypes.dynamicAdCreative
      }
    />
  );

  const bottomMessageSx = !displayAsPhoneMockUp && {
    margin: '8px auto 8px auto',
    whiteSpace: 'normal !important',
    width: '100%'
  };

  return (
    <>
      {showMessaging && extraMessage && (
        <Message
          sx={
            !displayAsPhoneMockUp && {
              margin: '2px auto 18px auto',
              whiteSpace: 'normal !important'
            }
          }
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="warn"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          {extraMessage}
        </Message>
      )}
      {displayAsPhoneMockUp ? (
        <PhoneMockAdPreviewWrapper>{adPreview}</PhoneMockAdPreviewWrapper>
      ) : (
        adPreview
      )}
      {showMessaging && footerMessage && (
        <Message
          sx={bottomMessageSx}
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="info"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          <ClientHtml html={footerMessage} />
        </Message>
      )}

      {showMessaging && blueprintTypeMessage && (
        <Message
          sx={bottomMessageSx}
          iconSize={getMessageIconSize(displayAsPhoneMockUp)}
          type="info"
          maxWidth={getMessageMaxWidth(displayAsPhoneMockUp)}
        >
          {blueprintTypeMessage}
        </Message>
      )}
    </>
  );
};

export default CreateFacebookPreview;
