import { flow } from 'lodash';
import classnames from 'classnames';
import withStyles from '@mui/styles/withStyles';
import { cld } from 'src/common/cloudinaryUtils';
import { trim } from '@cloudinary/url-gen/actions/videoEdit';
import { AdvancedImage } from '@cloudinary/react';

const styles = () => ({
  asset: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  videoAsset: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  lightboxAsset: {
    objectFit: 'contain'
  }
});

const getCloudinaryVideoThumbnail = sourceUrl => {
  return cld
    .video(sourceUrl)
    .videoEdit(trim().startOffset(5.0))
    .setAssetType('video')
    .setDeliveryType('fetch')
    .format('jpg');
};

const VideoThumbnail = props => {
  const { asset, classes, sourceUrl } = props;

  return (
    <>
      <AdvancedImage
        alt={asset?.id || asset}
        className={classnames(classes.asset)}
        cldImg={getCloudinaryVideoThumbnail(sourceUrl)}
        data-cy={`gallery-asset-${asset?.id || asset}`}
      />
    </>
  );
};

export default flow(withStyles(styles))(VideoThumbnail);
