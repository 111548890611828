import { useState } from 'react';
import { flow } from 'lodash';
import { formatNumber } from 'libphonenumber-js';
import { t } from 'i18next';

import { Button, IconButton, Menu, Typography, Box } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { withAppSettings } from 'src/AppSettings';

import HelpIcon from '@mui/icons-material/Help';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import Link from 'src/components/Link';

const styles = theme => ({
  supportContainer: {
    padding: `0 ${theme.spacing(2)}`
  },
  contactContainer: {
    alignItems: 'center',
    display: 'flex'
  }
});

const SupportMenu = ({ classes, loading, appSettings }) => {
  const [supportAnchorElement, setSupportAnchorElement] = useState(null);
  const supportMenuOpen = Boolean(supportAnchorElement);
  const title =
    appSettings?.app?.support?.title || t('chrome:navigation.supportTitle');
  const subtitle = appSettings?.app?.support?.subTitle;
  const phone = appSettings?.app?.support?.phone;
  const email = appSettings?.app?.support?.email;
  const link = appSettings?.app?.support?.link;
  const linkText =
    appSettings?.app?.support?.linkText || t('chrome:navigation.supportLink');

  const faqLink = appSettings?.app?.support?.faqLink;
  const faqText =
    appSettings?.app?.support?.faqText || t('chrome:navigation.supportFaq');

  return (
    <>
      <IconButton
        data-amp-click-support={JSON.stringify({
          action: 'open',
          location: 'top'
        })}
        aria-haspopup="true"
        aria-owns={supportMenuOpen ? 'menu-appbar' : undefined}
        color="inherit"
        disabled={loading}
        onClick={event => setSupportAnchorElement(event.currentTarget)}
        size="large"
      >
        <HelpIcon />
      </IconButton>

      <Menu
        anchorEl={supportAnchorElement}
        id="menu-support-appbar"
        onClose={() => setSupportAnchorElement(null)}
        open={supportMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <li className={classes.supportContainer}>
          <Box sx={{ margin: 2 }}>
            <Typography variant="h6">{title}</Typography>
          </Box>
          {subtitle && (
            <Box sx={{ margin: 2 }}>
              <Typography variant="subtitle2">{subtitle}</Typography>
            </Box>
          )}

          {phone && (
            <Box sx={{ margin: 2 }}>
              <Typography variant="body1" className={classes.contactContainer}>
                <PhoneIcon />: {formatNumber(phone, 'US', 'International')}
              </Typography>
            </Box>
          )}

          {email && (
            <Box sx={{ margin: 2 }}>
              <Typography variant="body1" className={classes.contactContainer}>
                <EmailIcon />: {email}
              </Typography>
            </Box>
          )}

          {(link || faqLink) && (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-evenly',
                margin: 2
              }}
            >
              {link && (
                <Button
                  data-amp-click-support={JSON.stringify({
                    action: 'link',
                    location: 'top'
                  })}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={link}
                >
                  {linkText}
                </Button>
              )}
              {faqLink && (
                <Button
                  data-amp-click-support={JSON.stringify({
                    action: 'faq',
                    location: 'top'
                  })}
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={faqLink}
                  target="_blank"
                >
                  {faqText}
                </Button>
              )}
            </Box>
          )}
        </li>
      </Menu>
    </>
  );
};

export default flow(withStyles(styles), withAppSettings)(SupportMenu);
