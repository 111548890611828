import { t } from 'i18next';
import { Typography } from '@mui/material';
import { CsvExampleTable } from 'src/pages/Audiences/CsvExampleTable';
import { Trans } from 'react-i18next';
import { AudienceDownloadCsvLink } from 'src/pages/Audiences/AudienceDownloadCsvLink';

const pageText = () =>
  ({
    points: [
      t('audiences:requirements.facebook.point1'),
      t('audiences:requirements.facebook.point2'),
      t('audiences:requirements.facebook.point3'),
      t('audiences:requirements.facebook.point4')
    ],
    example1: t('audiences:requirements.facebook.example1'),
    example2: t('audiences:requirements.facebook.example2')
  }) as const;

// Note that these examples MUST NOT be translated!
// Our parsing relies on column headers like "email" being in EN
const tableData1 = [['A'], ['email'], ['test@gmail.com']];
const tableData2 = [
  ['A', 'B', 'C', 'D'],
  ['email', 'phone', 'firstname', 'lastname'],
  ['test@gmail.com', '15551231234', 'John', 'Smith']
];

export const FacebookAudienceRequirements = () => {
  const text = pageText();
  return (
    <>
      <Trans
        i18nKey="audiences.requirements.facebook.header"
        components={[<AudienceDownloadCsvLink />]}
      />
      <ul>
        {text.points.map(point => {
          return (
            <li>
              <Typography>{point}</Typography>
            </li>
          );
        })}
      </ul>
      <Typography
        sx={theme => ({
          marginBottom: theme.spacing(1)
        })}
      >
        {text.example1}
      </Typography>
      <CsvExampleTable table={tableData1} />
      <Typography
        sx={theme => ({
          marginBottom: theme.spacing(1),
          marginTop: theme.spacing(2)
        })}
      >
        {text.example2}
      </Typography>
      <CsvExampleTable table={tableData2} />
    </>
  );
};
