import { useState } from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  IconButton
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { t } from 'i18next';
import { useWatch } from 'react-hook-form';

import Instrumentation from 'src/instrumentation';
import useProgram from 'src/pages/Program/utils/useProgram';
import { LOCATIONS_OVERRIDES_BY_ID_NAME } from './utils';
import {
  actions,
  logClickMlpRestoreLocationToDefault
} from './amplitudeEventLoggers';

type LocationManagementFormRowMenuProps = GridRenderCellParams & {
  removeLocation: (id: string) => void;
  restoreToDefaults: (id: string) => void;
  showAdPreview: (id: string) => void;
};

const LocationManagementFormRowMenu = ({
  row,
  removeLocation,
  restoreToDefaults,
  showAdPreview
}: LocationManagementFormRowMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const locationsOverrideById = useWatch({
    name: LOCATIONS_OVERRIDES_BY_ID_NAME,
    defaultValue: {}
  });

  const { selectedLocation, trackingData } = useProgram();

  // If no override that matches location id, then restore to defaults is disabled
  const isRestoreToDefaultsDisabled = !locationsOverrideById[row.id];
  const isShowAdPreviewDisabled = row.id === selectedLocation;

  return (
    <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-cy="location-management-table-acton-menu-button"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            Instrumentation.logEvent(
              Instrumentation.Events.ClickMlpShowAdPreview,
              { ...trackingData, locationId: row.id }
            );
            showAdPreview(row.id);
            handleClose();
          }}
          disabled={isShowAdPreviewDisabled}
          data-cy="location-management-table-acton-menu-show-preview"
        >
          <ListItemIcon>
            <VisibilityIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {t('programCreate:manageMultiLocation.manageMenuShowPreview')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            logClickMlpRestoreLocationToDefault({
              action: actions.table,
              locationId: row.id,
              trackingData
            });
            restoreToDefaults(row.id);
            handleClose();
          }}
          disabled={isRestoreToDefaultsDisabled}
          data-cy="location-management-table-acton-menu-restore-default"
        >
          <ListItemIcon>
            <RestartAltIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {t('programCreate:manageMultiLocation.manageMenuRestoreDefault')}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            Instrumentation.logEvent(
              Instrumentation.Events.ClickMlpRemoveLocation,
              { ...trackingData, locationId: row.id }
            );
            removeLocation(row.id);
            handleClose();
          }}
          data-cy="location-management-table-acton-menu-remove"
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>
            {t('programCreate:manageMultiLocation.manageMenuRemove')}
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LocationManagementFormRowMenu;
