/* eslint-disable no-restricted-imports */
// Turned off because this is the one place where dayjs can be directly imported
import dayjs, { Dayjs } from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { has } from 'lodash';
import i18n, { DEFAULT_LOCALE } from 'src/i18n';
import 'dayjs/locale/es';
import 'dayjs/locale/fr-ca';
import 'dayjs/locale/fr';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/pt';
import 'dayjs/locale/tr';
import 'dayjs/locale/de';
import 'dayjs/locale/ar';
import 'dayjs/locale/it';
import 'dayjs/locale/el';
import 'dayjs/locale/he';
import 'dayjs/locale/ja';
import 'dayjs/locale/pl';
import 'dayjs/locale/cs';
import 'dayjs/locale/sl';
import 'dayjs/locale/sr';
import 'dayjs/locale/ro';
import 'dayjs/locale/ca';
import 'dayjs/locale/ml';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/vi';
import 'dayjs/locale/th';
import 'dayjs/locale/nl';

/* eslint-enable no-restricted-imports */

/*
  We can also create our own plugins if we need something super custom:
  https://day.js.org/docs/en/plugin/plugin
*/

const plugins = [
  calendar,
  isSameOrAfter,
  isSameOrBefore,
  utc,
  timezone,
  advancedFormat,
  duration,
  localizedFormat,
  isBetween
];

plugins.forEach(plugin => {
  dayjs.extend(plugin);
});

// local specific version of format: May 16, 2018 5:27 PM
export const DEFAULT_DATE_FORMAT = 'lll';
const MONTH_DAY = 'll'; // Sep 4, 1986

interface FormatDateArgs {
  date?: Date | string | number;
  locale?: string;
  type?: string;
  format?: string;
}

export const formatDate = ({
  date = new Date(),
  type = 'CUSTOM',
  format = DEFAULT_DATE_FORMAT
}: FormatDateArgs = {}) => {
  const localLocale = dayjs(date).locale(i18n.language || DEFAULT_LOCALE);

  const types = {
    CUSTOM(date: Dayjs) {
      return date.format(format);
    },
    // / 3 hours ago... Last Monday at 3:45pm
    CALENDAR(date: Dayjs) {
      return date.calendar();
    },
    DAY(date: Dayjs) {
      return date.format(MONTH_DAY);
    }
  };

  const dt = has(types, type) ? (types as any)[type](localLocale) : '';

  let finalOutput = '-';

  try {
    if (dt !== '' && localLocale.isValid()) {
      finalOutput = dt;
    }
  } catch (dte) {
    // Gobble any exceptions. This will cause it to return '-'.
  }

  return finalOutput;
};

export { dayjs };

export const convertToDayjs = (
  date: Date | string | number | Dayjs | null | undefined
) => {
  if (dayjs.isDayjs(date)) {
    return date;
  }

  // If date is null or undefined, return null
  // if we pass undefined to dayjs, it will return the current date
  // we want the date picker to be empty unless an explicit date or dayjs/Date object is passed in
  return dayjs(date ?? null);
};
