import MessageBackground from './MessageBackground';

interface TextMessageProps {
  text: string | JSX.Element;
}

const TextMessage = ({ text }: TextMessageProps) => {
  return (
    <MessageBackground data-cy="ai-chat-text-message">{text}</MessageBackground>
  );
};

export default TextMessage;
