export const RENDER_STATES = {
  LOADING: 0,
  READY: 1,
  TOS_NOT_ACCEPTED: 2
};

export const ERROR_TYPES = {
  UNAUTHORIZED: 0,
  SET_TOKEN_FAIL: 1,
  FACEBOOK_BUSY: 2,
  UNKNOWN: 3,
  PAGE_NOT_VISIBLE: 4,
  NOT_PAGE_ADMIN: 5
};

export const LOADING_TYPES = {
  GETTING_PAGES: 0,
  SETTING_PAGE: 1
};

export const FACEBOOK_LINK_MODES = {
  NEW: 0,
  CHECK_STATUS: 1
};

export const POLL_FOR_TOS_PARAM = 'pollForTos';

export const FACEBOOK_LINK_RETURN_URL = 'facebookLinkReturnUrl';
