import { get, some, find } from 'lodash';

// data returns like: { edges: [{ node: { items: [{ fields: { ... } }] } }] }
// we only care about fields
export const formatBOs = bo =>
  get(bo, 'node.items', []).reduce((accum, curr) => ({ ...accum, ...curr }), {})
    .fields; // right now it's just "fields" despite the nesting

const DEFAULT_TITLE_FIELDS = [
  'title',
  'description',
  'address',
  'address_1',
  'id'
];

export const getPrimaryAccessor = (
  (fields = []) =>
  fieldSet => {
    let accessor;
    fields.forEach(field => {
      if (!accessor && some(fieldSet, { fieldName: field })) {
        accessor = field;
      }
    });
    return accessor;
  }
)(DEFAULT_TITLE_FIELDS);

export const getImageAccessor = meta => {
  get(
    find(meta, {
      displayMethodId: 'image_url'
    }),
    'fieldName'
  );
};

// architecture.catalog is depricated and we need to be using catalogs
export const businessObjectsFromArchitecture = arch =>
  arch ? get(arch, 'catalog.content.edges', []).map(formatBOs) : [];

export const newBusinessObjectsFromArchitecture = arch =>
  arch ? arch?.catalogs?.[0]?.content?.edges.map(formatBOs) : [];

export const businessObjectsFromOrder = order => {
  const orderBusinessObjects =
    order?.orderItem?.catalogItems?.edges?.[0]?.node?.items || [];

  return orderBusinessObjects.map(item => {
    return item.fields;
  });
};
