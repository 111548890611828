import { Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { t } from 'i18next';

import ConfirmationModal from 'src/components/Modal/ConfirmationModal';
import { MouseEvent } from 'react';

interface DeleteConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (event: MouseEvent<HTMLElement>) => Promise<void>;
}

const pageText = () => {
  return {
    title: t('DeleteConfirmationModal:title'),
    body: t('DeleteConfirmationModal:body')
  };
};

const DeleteConfirmationModal = ({
  open,
  onClose,
  onConfirm
}: DeleteConfirmationModalProps) => {
  const text = pageText();
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      title={text.title}
      onConfirm={onConfirm}
      icon={<Delete />}
    >
      <Typography>{text.body}</Typography>
    </ConfirmationModal>
  );
};

export default DeleteConfirmationModal;
