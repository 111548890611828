import { gql } from 'src/generated/gql';

export const createPricingPlan = gql(`
  mutation createPricingPlan($Input: CreatePricingPlanInput!) {
    createPricingPlan(input: $Input) {
      id
    }
  }
`);

export const updatePricingPlan = gql(`
  mutation updatePricingPlan($Input: UpdatePricingPlanInput!) {
    updatePricingPlan(input: $Input) {
      id
    }
  }
`);

export const createTier = gql(`
  mutation createTier($Input: CreateTierInput!) {
    createTier(input: $Input) {
      id
    }
  }
`);

export const updateTier = gql(`
  mutation updateTier($Input: UpdateTierInput!) {
    updateTier(input: $Input) {
      id
    }
  }
`);
