import { ExtractRouteParams, generatePath } from 'react-router';
import { t } from 'i18next';

export const generateLinkPath = (
  path: string,
  replacementObject: ExtractRouteParams<string> = {}
) => {
  // Note: the replacementObject is what you want to replce in the path. The key will match in
  //       this object to the path param you want to replace.
  // example replacementObject:
  // {
  //     architectureId: 1234
  // }
  return generatePath(path, replacementObject);
};

export const generateQueryParams = (params: Record<string, unknown> = {}) => {
  // Note: expects params be in the following format:
  // {
  //     productIds : [123, 456, ...],
  //     contentIds: [987, 654, ...],
  //     contentFilter: {
  //         listing_description: {
  //             contains: 'Seattle'
  //         }
  //     }
  // }

  return Object.keys(params)
    .map(key => {
      return [key, params[key] as any].map(encodeURIComponent).join('=');
    })
    .join('&');
};

export const generateLinkPathWithQueryParams = (
  path: string,
  replacementObject: ExtractRouteParams<string> = {},
  params: Record<string, unknown>
) => {
  // Note: the replacementObject is what you want to replce in the path. The key will match in
  //       this object to the path param you want to replace.
  // example replacementObject:
  // {
  //     architectureId: 1234
  // }

  const queryParams = generateQueryParams(params);
  return `${generatePath(path, replacementObject)}?${queryParams}`;
};

export const generatePageTitle = (subPage: string, baseTitle?: string) => {
  let title = baseTitle || t('chrome:defaultPageTitle');

  if (subPage) {
    title += ` | ${subPage}`;
  }

  return title;
};
