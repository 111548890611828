import { t } from 'i18next';
import { useState, useMemo } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';

import { Box } from '@mui/material';

import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';

import { getResourceItemTypes } from './queries';

import ResourceCollectionsManager from './ResourceCollectionsManager';

import ResourceTypeSelector from './ResourceItemTypeSelector';

const pageText = () => ({
  title: t('adminResourceLibrary:title'),
  description: t('adminResourceLibrary:description')
});

const ResourceLibraryManager = ({
  resourceItemTypes,
  resourceItemTypesMeta: { resourceItemTypesLoading, resourceItemTypesError }
}) => {
  const text = useMemo(() => pageText(), []);

  const [resourceItemTypeSlug, setResourceItemTypeSlug] = useState('');

  if (resourceItemTypesLoading || resourceItemTypesError) {
    return <Loading error={resourceItemTypesError} />;
  }

  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.description}
        pageTitle={text.title}
        divider
      />

      <Box
        sx={{
          marginTop: theme => theme.spacing(2)
        }}
      >
        <ResourceTypeSelector
          setResourceItemType={setResourceItemTypeSlug}
          selectedResourceItemType={resourceItemTypeSlug}
          resourceItemTypes={resourceItemTypes}
        />
      </Box>
      {resourceItemTypeSlug && (
        <Box
          sx={{
            marginTop: theme => theme.spacing(3)
          }}
        >
          <ResourceCollectionsManager
            resourceItemTypeSlug={resourceItemTypeSlug}
          />
        </Box>
      )}
    </>
  );
};

export default flow(
  graphql(getResourceItemTypes, {
    name: 'getResourceItemTypes',
    options: () => ({
      fetchPolicy: 'no-cache'
    }),
    props: ({
      getResourceItemTypes: { error, loading, resourceItemTypes }
    }) => {
      return {
        resourceItemTypes,
        resourceItemTypesMeta: {
          resourceItemTypesLoading: loading,
          resourceItemTypesError: error
        }
      };
    }
  })
)(ResourceLibraryManager);
