import { styled } from '@mui/system';

/**
 * Helps to render an icon and text aligned together.
 */
export const TextIconAligner = styled('span')(
  ({ theme }) => `
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  & > * {
    margin-right: ${theme.spacing(1)};
  }
`
);
