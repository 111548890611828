export const getArchitecturesOptions = ({ architectures }) => {
  // Add architecture options
  const architectureOptions =
    architectures.map(architecture => {
      const name = `${architecture.name}${
        architecture.isActive ? ' (Active)' : ' (Hidden)'
      }`;

      return {
        name,
        value: architecture.id
      };
    }) || [];

  return { architectureOptions };
};

export const getProductAndCatalogOptions = ({
  architecturesById,
  selectedArchitectureId
}) => {
  let productOptions = [];
  let catalogOptions = [];
  let catalogGroupKeyOptions = [];

  if (selectedArchitectureId) {
    const selectedArchitecture = architecturesById[selectedArchitectureId];

    // Add product options
    productOptions =
      selectedArchitecture.products.map(product => {
        const name = `${product.name}${product.isAvailable ? ' (Active)' : ''}`;

        return {
          name,
          value: product.id
        };
      }) || [];

    // Add catalog options
    catalogOptions =
      selectedArchitecture.catalogs.map(catalog => {
        return {
          name: catalog.friendlyName,
          value: catalog.id
        };
      }) || [];

    // Add catalog options
    catalogGroupKeyOptions =
      selectedArchitecture.catalogs.map(catalog => {
        return {
          name: catalog.defaultGroupKey,
          value: catalog.defaultGroupKey
        };
      }) || [];
  }

  return { productOptions, catalogOptions, catalogGroupKeyOptions };
};
