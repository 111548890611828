import { useCallback, useMemo, useState } from 'react';
import { flow, groupBy, orderBy } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';

import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';

import { getProducts, getArchitectures } from './queries';
import BlueprintBuilderForm from './BlueprintBuilderForm';
import BlueprintTableView from './LandingComponents/BlueprintTableView';
import BlueprintGridView from './LandingComponents/BlueprintGridView';
import LandingHeader from './LandingComponents/LandingHeader';
import { UNASSOCIATED_KEY, landingViewStateOptions } from './Constants';

const styles = () => ({});

const pageText = () => ({
  pageTitle: t('admin:blueprintBuilder.pageTitle'),
  heading: t('admin:blueprintBuilder.landingHeading'),
  subheading: t('admin:blueprintBuilder.landingSubheading'),
  crumbAdminDashboard: t('admin:crumbs.adminDashboard'),
  crumbBlueprintBuilder: t('admin:crumbs.blueprintBuilder')
});

const BlueprintBuilderLanding = props => {
  const {
    match,
    history,
    productsByArch,
    loadingProducts,
    architectures,
    loadingArchitectures,
    refetchProducts
  } = props;
  const text = useMemo(() => pageText(), []);

  const [selectedArchitecture, setSelectedArchitecture] = useState('all');
  const handleSetSelectedArchitecture = useCallback(state => {
    setSelectedArchitecture(state);
  });

  const [isPolling, setIsPolling] = useState(false);
  const handleSetIsPolling = useCallback(state => {
    setIsPolling(state);
  });

  const [viewState, setViewState] = useState(landingViewStateOptions.grid);
  const handleSetViewState = useCallback(state => {
    setViewState(state);
  });

  const productId = match?.params?.productId;

  const handleRowClick = useCallback(
    ({ productId }) => {
      const linkPath = generateLinkPath(paths.admin.blueprintBuilder, {
        productId
      });

      return history.push(linkPath);
    },
    [history]
  );

  const sortedArchitectures = orderBy(architectures, ['name']);

  const filteredArchitectures = sortedArchitectures.filter(architecture => {
    return (
      selectedArchitecture === 'all' || selectedArchitecture === architecture.id
    );
  });

  const getViewComponent = () => {
    if (viewState === landingViewStateOptions.table) {
      return (
        <BlueprintTableView
          architectures={filteredArchitectures}
          productsByArch={productsByArch}
          handleRowClick={handleRowClick}
        />
      );
    }

    return (
      <BlueprintGridView
        architectures={filteredArchitectures}
        productsByArch={productsByArch}
        handleRowClick={handleRowClick}
      />
    );
  };

  if (loadingArchitectures || loadingProducts) {
    return <Loading />;
  }

  return (
    <div>
      {productId ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BlueprintBuilderForm
              productId={productId}
              isPolling={isPolling}
              setIsPolling={handleSetIsPolling}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Heading
            title={text.heading}
            subTitle={text.subheading}
            pageTitle={text.pageTitle}
          />

          <LandingHeader
            setViewState={handleSetViewState}
            viewState={viewState}
            architectures={sortedArchitectures}
            productsByArch={productsByArch}
            refetchProducts={refetchProducts}
            selectedArchitecture={selectedArchitecture}
            setSelectedArchitecture={handleSetSelectedArchitecture}
          />

          {getViewComponent()}
        </>
      )}
    </div>
  );
};

const mapBlueprintsToProps = ({ getProducts }) => {
  // Note: When getting all products it returns the latest productDocumentVersion so we don't
  //       need to filter / sort based on timestamp
  const products = getProducts?.productDocumentVersions ?? [];

  return {
    products,
    productsByArch: groupBy(products, product => {
      // check for slug, then id then unsorted
      if (product.document.architecture) {
        return product.document.architecture;
      }
      if (product.document.architectureId) {
        return product.document.architectureId;
      }
      return UNASSOCIATED_KEY;
    }),
    refetchProducts: getProducts.refetch,
    loadingProducts: getProducts.loading
  };
};

const mapArchitecturesToProps = ({ getArchitectures }) => {
  const architectures = getArchitectures?.us?.architectures || [];
  return {
    architectures,
    loadingArchitectures: getArchitectures?.loading
  };
};

export default flow(
  graphql(getProducts, {
    name: 'getProducts',
    options: () => {
      return { fetchPolicy: 'no-cache' };
    },
    props: mapBlueprintsToProps
  }),
  graphql(getArchitectures, {
    name: 'getArchitectures',
    props: mapArchitecturesToProps
  }),
  withStyles(styles),
  withRouter
)(BlueprintBuilderLanding);
