import { useMemo, useState } from 'react';
import { flow } from 'lodash';
import { t } from 'i18next';

import classnames from 'classnames';
import { Button, Typography, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';

import Modal from 'src/components/Modal';
import { formatDate } from 'src/common/dates';
import Heading from 'src/components/PageElements/Heading';

import { statusMap } from './constants';

const styles = theme => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonContainer: {
    display: 'flex',
    margin: `${theme.spacing(2)} 0 ${theme.spacing(4)}`,

    '& button': {
      marginRight: theme.spacing(3)
    },

    '& button:last-child': {
      marginRight: 0
    }
  },
  status: {
    padding: `0 ${theme.spacing(2)}`,
    borderRadius: theme.shape.borderRadius,

    '& svg': {
      verticalAlign: 'sub'
    }
  },
  statusClosed: {
    marginRight: theme.spacing(3),
    '& span': {
      color: theme.palette.success[400]
    }
  },
  errorButton: {
    background: theme.palette.error.main,
    borderColor: theme.palette.error.dark,
    color: '#fff',
    '&:hover': {
      background: theme.palette.error.dark
    }
  },
  iconButtonText: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),

    '& svg': {
      height: '40px',
      marginBottom: theme.spacing(2),
      width: '40px'
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  modified: {
    fontSize: `14px`,
    fontWeight: 'normal',
    color: theme.palette.grey[500]
  }
});

const pageText = () => ({
  createNewHeader: t('admin:contentTable.createNewHeader'),
  importExport: t('admin:contentTable.importExportButton'),
  namedHeader: t('admin:contentTable.namedHeader')
});

const ContentTablesHeader = props => {
  const { classes, selectedContent, openImportExport, isCreateNew } = props;
  const text = useMemo(() => pageText(), []);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const openErrorModal = () => {
    setErrorModalOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalOpen(false);
  };

  const title = isCreateNew ? (
    text.createNewHeader
  ) : (
    <span>
      {text.namedHeader} {selectedContent?.document?.contentTable}
    </span>
  );

  return (
    <div>
      <Heading
        title={title}
        subTitle={
          <span className={classes.modified}>
            Modified:{' '}
            {selectedContent?.versionTimestamp
              ? formatDate({ date: selectedContent?.versionTimestamp })
              : '-'}
          </span>
        }
        pageTitle={title}
        divider
      />

      <div className={classes.buttonContainer}>
        {selectedContent?.status === statusMap.error ? (
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={openErrorModal}
            startIcon={<ErrorIcon />}
            className={classes.errorButton}
          >
            Publishing error
          </Button>
        ) : (
          <Typography
            variant="button"
            className={classnames(classes.status, {
              [classes.statusClosed]:
                statusMap.closed === selectedContent?.status,
              [classes.statusOpen]: statusMap.open === selectedContent?.status,
              [classes.statusCommitted]:
                statusMap.committed === selectedContent?.status
            })}
          >
            <span>
              {statusMap.closed === selectedContent?.status && (
                <>
                  <CheckIcon /> Published
                </>
              )}
              {statusMap.open === selectedContent?.status && (
                <>
                  <EditIcon /> Draft
                </>
              )}
            </span>
            {statusMap.committed === selectedContent?.status && (
              <>
                <CircularProgress size={20} /> Publishing
              </>
            )}
          </Typography>
        )}
        <Button
          color="primary"
          disabled={selectedContent?.status === statusMap.committed}
          variant="outlined"
          size="small"
          onClick={openImportExport}
          startIcon={<ImportExportIcon />}
        >
          {text.importExport}
        </Button>
      </div>
      <Modal
        open={errorModalOpen}
        headerText="Error"
        fullWidth
        maxWidth="lg"
        onClose={closeErrorModal}
        FooterComponent={() => (
          <>
            <Button onClick={closeErrorModal}>{text.close}</Button>
          </>
        )}
      >
        {selectedContent?.errorMessage}
      </Modal>
    </div>
  );
};

export default flow(withStyles(styles))(ContentTablesHeader);
