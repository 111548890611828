import { gql } from 'src/generated/gql';

export const editOrder = gql(`
  mutation editOrder(
    $orderItemId: ID!
    $variableValues: JSON!
    $programName: String
    $updatedBudgetAmount: Float
    $scheduleStartEpochSeconds: Int
    $scheduleEndEpochSeconds: Int
    $updatedTierId: ID
  ) {
    editOrder(
      orderItemId: $orderItemId
      variableValues: $variableValues
      programName: $programName
      updatedBudgetAmount: $updatedBudgetAmount
      scheduleStartEpochSeconds: $scheduleStartEpochSeconds
      scheduleEndEpochSeconds: $scheduleEndEpochSeconds
      updatedTierId: $updatedTierId
    ) {
      id
      orderId
      priceAmount
      scheduleStart
      scheduleEnd
      status
      variableValues
    }
  }
`);

export const editMlp = gql(`
  mutation editMlp($input:  EditMultiLocationProgramInput!) {
    editMultiLocationProgram(input: $input) {
      id
    }
  }
`);

export const stopOrder = gql(`
  mutation stopOrder($orderId: Long!) {
    stopOrder(orderId: $orderId)
  }
`);

export const cancelOrder = gql(`
  mutation cancelOrderItem($orderItemId: Long!) {
    cancelOrderItem(orderItemId: $orderItemId) {
      executionId
    }
  }
`);

export const addPaymentMethod = gql(`
  mutation AddPaymentMethod($stripeSourceId: String!) {
    addPaymentMethod(stripeSourceId: $stripeSourceId) {
      id
      userId
      payer
      organizationId
      officeId
      stripeSourceId
      stripeCustomerId
      stripeSource {
        cardBrand
        cardLastFour
        cardExpiryMonth
        cardExpiryYear
      }
    }
  }
`);

export const updateOrderPaymentMethod = gql(`
  mutation UpdateOrderPaymentMethod($orderId: ID!, $newPaymentMethodId: ID!) {
    updateOrderPaymentMethod(
      orderId: $orderId
      newPaymentMethodId: $newPaymentMethodId
    ) {
      id
      isProgramActive
      programId
      paymentMethodId
    }
  }
`);

export const deactivateSupervisorOrder = gql(`
  mutation deactivateSupervisorOrder($supervisorOrderId: ID!) {
    deactivateSupervisorOrder(supervisorOrderId: $supervisorOrderId) {
      id
    }
  }
`);
