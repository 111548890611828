import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';

interface MenuIconButtonProps {
  ariaLabel: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  ariaHaspopup?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  id?: string;
  renderIcon?: () => JSX.Element;
  iconColor?: string;
  dataCyTag?: string;
  size?: 'small' | 'medium' | 'large';
}

const MenuIconButton = ({
  ariaLabel,
  ariaControls,
  ariaExpanded,
  ariaHaspopup = true,
  onClick,
  id,
  renderIcon,
  iconColor = 'text.primary',
  dataCyTag = 'menu-icon-button',
  size = 'medium'
}: MenuIconButtonProps) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      onClick={onClick}
      id={id}
      data-cy={dataCyTag}
      size={size}
    >
      {renderIcon ? renderIcon() : <MoreVert sx={{ color: iconColor }} />}
    </IconButton>
  );
};

export default MenuIconButton;
