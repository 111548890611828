import { useEffect, useState } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { flow, isEqual } from 'lodash';
import { t } from 'i18next';

import { Paper } from '@mui/material';

import Heading from 'src/components/PageElements/Heading';

import PricingConfiguration from './PricingConfiguration';
import TieringConfiguration from './TieringConfiguration';

import { getAllPricingPlans } from './queries';

const PricingSettings = props => {
  const { isPricingPlansLoading, allPricingPlans, defaultTier, refetch } =
    props;
  const [selectedPricingPlan, setSelectedPricingPlan] = useState(
    allPricingPlans && allPricingPlans[0]
  );
  const [selectedTier, setSelectedTier] = useState(defaultTier);

  useEffect(() => {
    if (!selectedPricingPlan) {
      setSelectedPricingPlan(allPricingPlans[0]);
    }

    if (selectedPricingPlan) {
      const pricingPlan = allPricingPlans.find(
        plan => selectedPricingPlan.id === plan.id
      );
      if (!isEqual(selectedPricingPlan, pricingPlan)) {
        setSelectedPricingPlan(pricingPlan);
      }
    }
  }, [allPricingPlans]);

  useEffect(() => {
    if (defaultTier) {
      setSelectedTier(defaultTier);
    }
  }, [defaultTier]);

  const pricingConfigurationProps = {
    isPricingPlansLoading,
    allPricingPlans,
    selectedPricingPlan,
    setSelectedPricingPlan,
    refetch
  };

  const tieringConfigurationProps = {
    isPricingPlansLoading,
    selectedPricingPlan,
    selectedTier,
    tiers: selectedPricingPlan?.tiers,
    refetch
  };

  return (
    <div>
      <Heading
        title={t('admin:headings.pricingSettings')}
        subTitle={t('admin:headings.pricingSettingsDescription')}
        pageTitle={t('admin:headings.pricingSettings')}
      />

      <Paper
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        <PricingConfiguration {...pricingConfigurationProps} />
        {selectedPricingPlan?.id && (
          <TieringConfiguration {...tieringConfigurationProps} />
        )}
      </Paper>
    </div>
  );
};

const mapAllPricingPlansToProps = ({ getAllPricingPlans }) => {
  const allPricingPlans = getAllPricingPlans?.allPricingPlans || [];
  const pricingPlan = allPricingPlans[0];
  const defaultTier = pricingPlan?.tiers && pricingPlan?.tiers[0];

  return {
    allPricingPlans,
    defaultTier,
    isPricingPlansLoading: getAllPricingPlans?.loading,
    error: getAllPricingPlans?.error,
    refetch: getAllPricingPlans?.refetch
  };
};

export default flow(
  graphql(getAllPricingPlans, {
    name: 'getAllPricingPlans',
    options: () => {
      return { fetchPolicy: 'no-cache' };
    },
    props: mapAllPricingPlansToProps
  })
)(PricingSettings);
