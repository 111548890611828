import { clone } from 'lodash';

const billingMethodFriendlyNames = {
  direct: 'Billing Service 1.0 (direct)', // what we do right now, using stripe
  virtual: 'Billing Service 2.0 (virtual)' // new future of billing
  // invoice: - not supported, for automation, but only for the child orders, f/e should never have to deal with it, the parent aka supervisor order uses the usual direct
};

interface BillingMethodField {
  name: string;
}

export const updateBillingMethodsEnums = (
  billingMethodsEnumFields: BillingMethodField[]
) => {
  const updatedBillingMethodsEnumFields = billingMethodsEnumFields.reduce<
    BillingMethodField[]
  >((accum, current) => {
    const currentClone = clone(current);

    // If it doesn't have a friendly name, exclude it.
    if (
      !(billingMethodFriendlyNames as Record<string, string>)[currentClone.name]
    ) {
      return accum;
    }

    currentClone.name = (billingMethodFriendlyNames as Record<string, string>)[
      currentClone.name
    ];

    accum.push(currentClone);
    return accum;
  }, []);

  return updatedBillingMethodsEnumFields;
};
