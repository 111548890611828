import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { t } from 'i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const useSnackbarOnSuccess = () => {
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (search) {
      enqueueSnackbar(t('adminSkinSettings:submit.success'), {
        variant: 'success',
        iconVariant: () => <CheckCircleOutlineIcon />
      });
      window.location.href = window.location.href.replace(search, '');
    }
  }, []); // Deps array is empty on purpose, this should only happen on initial render.
};

export default useSnackbarOnSuccess;
