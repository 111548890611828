import { t } from 'i18next';
import { isEmpty, mapValues } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';

import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';

import useProgram from 'src/pages/Program/utils/useProgram';
import { getLocationsForManagement } from 'src/pages/Program/ProgramSteps/MultiLocationPublish/queries';
import { LOCATIONS_OVERRIDES_BY_ID_NAME } from 'src/pages/Program/ProgramSteps/MultiLocationPublish/utils';

import ModalHeader from 'src/components/Modal/ModalHeader';
import Modal from 'src/components/Modal/Modal';
import {
  PROGRAM_FORM_SECTION_SPEND_NAME,
  scheduleTypes
} from 'src/pages/Program/Constants';
import Instrumentation from 'src/instrumentation';

interface MultiLocationFooterProps {
  closeModal: () => void;
  initialOrderTypeHasChanged?: boolean;
}

const ApplyDefaultsModal = ({
  closeModal,
  initialOrderTypeHasChanged = false
}: MultiLocationFooterProps) => {
  const {
    programStepper: { currentStep, selectExactStep },
    trackingData
  } = useProgram();

  const allFormValues = useWatch();

  const overrides = allFormValues?.[LOCATIONS_OVERRIDES_BY_ID_NAME] || {};
  const spendStep = allFormValues?.[PROGRAM_FORM_SECTION_SPEND_NAME];
  const locationsOverrideIds = Object.keys(overrides);

  const { setValue } = useFormContext();

  const hasLocationsOverrides = !isEmpty(overrides);

  const { data: locationsData, loading: locationsLoading } = useQuery(
    getLocationsForManagement,
    {
      skip: !hasLocationsOverrides,
      variables: {
        first: locationsOverrideIds.length + 1,
        filter: {
          locationIds: locationsOverrideIds
        }
      }
    }
  );

  const locationsOverrideNames = locationsData?.locations?.edges?.map(
    edge => edge?.node?.name
  );

  const actions = {
    applyAll: 'apply-all',
    applyUnmodified: 'apply-unmodified',
    cancel: 'cancel'
  } as const;

  type ActionKeys = keyof typeof actions;
  type Action = (typeof actions)[ActionKeys];

  const logClickMlpApplyDefaultsEvent = (action: Action) => {
    Instrumentation.logEvent(Instrumentation.Events.ClickMlpApplyDefaults, {
      ...trackingData,
      action
    });
  };

  const onCloseModal = () => {
    logClickMlpApplyDefaultsEvent(actions.cancel);
    closeModal();
  };

  return (
    <Modal
      open
      onClose={onCloseModal}
      HeaderComponent={
        <ModalHeader icon={<SaveIcon />}>
          <Box component="span" sx={{ fontSize: '20px' }}>
            {t('programStepFooter:overrideModal.title')}
          </Box>
        </ModalHeader>
      }
      FooterComponent={
        <>
          <LoadingButton onClick={onCloseModal}>
            {t('programStepFooter:overrideModal.cancel')}
          </LoadingButton>

          <LoadingButton
            variant="outlined"
            onClick={() => {
              logClickMlpApplyDefaultsEvent(actions.applyAll);
              // Note: validation check happens before modal opens

              // clear the overrides b/c we want toa apply changes to everything
              setValue(LOCATIONS_OVERRIDES_BY_ID_NAME, {});

              // go to next step
              selectExactStep(currentStep + 1, trackingData);
            }}
            loading={locationsLoading}
            data-cy="location-management-apply-defaults-all"
          >
            {t('programStepFooter:overrideModal.applyAll')}
          </LoadingButton>

          <LoadingButton
            autoFocus
            variant="contained"
            onClick={() => {
              logClickMlpApplyDefaultsEvent(actions.applyUnmodified);
              // Note: validation check happens before modal opens

              // default behavior is to apply changes to only the selected locations

              // edge case: if location overrides exist and the user has changed the order type, change override budgets to new default budget
              if (hasLocationsOverrides && initialOrderTypeHasChanged) {
                interface Override {
                  spendStep: {
                    subscription: string;
                    subscriptionSpend: number;
                    schedulType: string;
                    oneTimeSpend: string;
                  };
                }

                const updatedOverrides = mapValues(
                  overrides,
                  (override: Override) => {
                    return {
                      ...override,
                      spendStep: {
                        ...override.spendStep,
                        scheduleType: spendStep?.scheduleType,
                        ...(spendStep?.scheduleType ===
                          scheduleTypes.subscription.value && {
                          subscription: `tier:${spendStep?.subscription}`,
                          subscriptionSpend: spendStep?.subscriptionSpend
                        }),
                        ...(spendStep?.scheduleType ===
                          scheduleTypes.purchase.value && {
                          oneTimeSpend: spendStep?.oneTimeSpend
                        })
                      }
                    };
                  }
                );

                setValue(LOCATIONS_OVERRIDES_BY_ID_NAME, updatedOverrides);
              }
              // go to next step
              selectExactStep(currentStep + 1, trackingData);
            }}
            loading={locationsLoading}
            data-cy="location-management-apply-defaults-unmodified"
          >
            {t('programStepFooter:overrideModal.applyUnmodified')}
          </LoadingButton>
        </>
      }
    >
      {t('programStepFooter:overrideModal.content', {
        count: locationsOverrideIds.length
      })}
      <ul>{locationsOverrideNames?.map(name => <li>{name}</li>)}</ul>
    </Modal>
  );
};

export default ApplyDefaultsModal;
