import { gql } from 'src/generated/gql';
import {
  AudiencePlaceholderApiSource,
  AudiencePlaceholderStatus,
  AudienceSourceType
} from 'src/generated/gql/graphql';

export interface AudiencePlaceholderNode {
  id: string;
  name: string;
  source: AudiencePlaceholderApiSource;
  createdAt: any;
  status: AudiencePlaceholderStatus;
  channelAudiences?: {
    edges?: Array<{
      node?: {
        id: string;
        channelCode: string;
        source: AudienceSourceType;
        type: string;
        potentialSize?: string | null;
        status: string;
        createdAt: string;
      } | null;
    } | null> | null;
  } | null;
}

export const fetchAudiencePageQuery = gql(`
  query FetchPlaceholdersPage(
    $placeholderFirst: Int!
    $placeholderAfter: String
    $placeholderFilter: AudiencePlaceholderQueryFilter
    $channelAudienceQueryFilter: ChannelAudienceQueryFilter!
    $channelAudienceFirst: Int!
    $channelAudienceAfter: String
  ) {
    fetchPlaceholders(
      first: $placeholderFirst
      after: $placeholderAfter
      filter: $placeholderFilter
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          source
          createdAt
          status
          channelAudiences(
            filter: $channelAudienceQueryFilter
            first: $channelAudienceFirst
            after: $channelAudienceAfter
          ) {
            edges {
              node {
                id
                channelCode
                source
                type
                potentialSize
                status
                createdAt
              }
            }
          }
        }
      }
    }
  }
`);

// new audiences
export const getAudiences = gql(`
  query FetchPlaceholders(
    $showDeletedPlaceholders: Boolean!
    $placeholderFirst: Int!
    $placeholderAfter: String
    $channelAudienceQueryFilter: ChannelAudienceQueryFilter!
    $channelAudienceFirst: Int!
    $channelAudienceAfter: String
  ) {
    fetchPlaceholders(
      first: $placeholderFirst
      after: $placeholderAfter
      showDeleted: $showDeletedPlaceholders
    ) {
      edges {
        node {
          id
          organizationId
          name
          description
          createdAt
          updatedAt
          isDeleted
          channelAudiences(
            filter: $channelAudienceQueryFilter
            first: $channelAudienceFirst
            after: $channelAudienceAfter
          ) {
            edges {
              node {
                id
                audienceId
                channelCode
                name
                description
                source
                type
                potentialSize
                status
                createdAt
                updatedAt
                isDeleted
              }
            }
          }
          status
        }
      }
    }
  }
`);

// Legacy audiences
export const loadAudiences = gql(`
  query LoadAudiencesLegacy(
    $filter: AssetFilter!
    $first: Int!
    $after: String
    $types: [AssetType!]
  ) {
    galleries(types: $types) {
      id
      assetsv3(filter: $filter, first: $first, after: $after) {
        edges {
          cursor
          node {
            ... on AudienceAsset {
              id
              galleryId
              userId
              groupId
              value
              name
              type
              potentialSize
              status
              scope
              createdAt
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);
