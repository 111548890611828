import { gql } from 'src/generated/gql';

// CreateAdPreviewsInput:
// variableValues JSON!
// productId ID!
// catalogId ID
// catalogFilter JSON
// existingPreviewProgramId ID
export const createAdPreviews = gql(`
  mutation CreateAdPreviews($input: CreateAdPreviewsInput!) {
    createAdPreviews(createAdPreviewsInput: $input) {
      executionId
      previewProgramId
    }
  }
`);

export const startBulkAdPreviewCreation = gql(`
  mutation StartBulkAdPreviewCreation($input: BulkCreateAdPreviewsInput!) {
    startBulkAdPreviewCreation(input: $input) {
      jobId
      finishedProgramCreation
      programCreationOutcome
    }
  }
`);
