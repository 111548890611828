import { useState, useCallback, useMemo } from 'react';
import { flow, first, orderBy } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, change } from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Paper, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { paths } from 'src/routes/paths';
import { useSnackbar } from 'notistack';
import Loading from 'src/components/Loading';
import NavigationBlocker from 'src/components/NavigationBlocker';
import { BreadcrumbTrail } from 'src/components/BreadcrumbTrail/BreadcrumbTrail';

import BlueprintBuilderHeader from './BlueprintBuilderHeader';
import EditVariablesFab from './BlueprintBuilderSteps/EditVariablesFab';
import SaveBlueprintChangesFab from './BlueprintBuilderSteps/SaveBlueprintChangesFab';
import DiffDrawer from './BlueprintBuilderSteps/DiffDrawer';
import ConfirmModal from './BlueprintBuilderSteps/ConfirmModal';
import RollbackModal from './BlueprintBuilderSteps/RollbackModal';
import ImportExportModal from './BlueprintBuilderSteps/ImportExport/ImportExportModal';
import {
  selectExactStep,
  selectNextStep,
  selectPreviousStep,
  resetBlueprintBuilder
} from './actions';
import {
  updateProductDocumentVersion,
  createProductDocumentVersion,
  submitProductDocumentVersion
} from './mutations';
import { BLUEPRINT_BUILDER_FORM_NAME, statusMap } from './Constants';
import { getProductByProductId, getAllProductVersions } from './queries';
import { cleanData, sortInitialValues } from './helpers';
import BlueprintBuilderWarnings from './BlueprintBuilderWarnings';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  stepperRoot: {
    background: 'inherit',
    padding: 0
  },
  paper: {
    padding: theme.spacing(2)
  },
  step: {
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  stepLabel: {
    cursor: 'pointer',
    textTransform: 'uppercase'
  },
  publishButtonContainer: {
    margin: `${theme.spacing(3)} 0`,
    display: 'flex',
    justifyContent: 'center'
  }
});

const pageText = () => ({
  errorMessage: t('admin:blueprintBuilder.formErrorMessage'),
  successMessage: t('admin:blueprintBuilder.formSuccessMessage'),
  saveDraftMessage: t('admin:blueprintBuilder.formSuccessMessageDraft'),
  blueprintLoadError: t('admin:blueprintBuilder.blueprintLoadError'),
  crumbAdminDashboard: t('admin:crumbs.adminDashboard'),
  crumbBlueprintBuilder: t('admin:crumbs.blueprintBuilder'),
  crumbEditBlueprint: t('admin:crumbs.editBlueprint')
});

const BlueprintBuilderForm = props => {
  const {
    selectedStep,
    selectExactStep,
    steps,
    classes,
    handleSubmit,
    formValues = {},
    allProductVersions,
    currentlySelectedProduct,
    createProductDocumentVersion,
    updateProductDocumentVersion,
    submitProductDocumentVersion,
    refetchData,
    change,
    loading,
    error,
    dirty
  } = props;
  const text = useMemo(() => pageText(), []);
  const { enqueueSnackbar } = useSnackbar();

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [submitModalOpen, setSubmitModalState] = useState(false);
  const setSubmitModalOpen = useCallback(() => {
    setSubmitModalState(true);
  }, []);
  const setSubmitModalClose = useCallback(() => {
    setSubmitModalState(false);
  }, []);

  const [rollbackModalOpen, setRollbackModalState] = useState(false);
  const [importExportModalOpen, setImportExportModalState] = useState(false);

  const handleStepChange = step => {
    selectExactStep(step);
  };

  const confirmPublish = () => {
    setSubmitModalOpen();
  };

  const onPublish = useCallback(
    async ({ isDraft, rollbackData }) => {
      setFormSubmitting(true);
      // if document status is "open" or in "error" we just want to update
      let finalProduct = cleanData(formValues);

      if (rollbackData) {
        finalProduct = cleanData(rollbackData);
        // Note: If the product you are trying to rollback is
        //       already open you will only need to update. The
        //       mutation needs the current "id" not tho old version.

        //       FormValues is the source of truth for "id" and "status"

        // set the currently selected document "status" and "id"
        finalProduct.id = formValues?.id;
        finalProduct.status = formValues?.status;
      }

      try {
        // create new document that is in an "open" state for editing if currently closed
        if (finalProduct.status === statusMap.closed) {
          const newVersion = await createProductDocumentVersion({
            variables: { productId: formValues?.productId }
          });

          // Note: We are updating the form document with these new values so that if the
          //       form errors you can resubmit with the proper id and status.

          // update the submitting document to use new "open" id
          finalProduct.id = newVersion?.data?.createProductDocumentVersion?.id;
          // update the submitting document to use new "open" status
          finalProduct.status =
            newVersion?.data?.createProductDocumentVersion?.status;
        }

        // update open document / save draft
        await updateProductDocumentVersion({
          variables: {
            id: finalProduct?.id,
            document: finalProduct.document
          }
        });

        // if not a "draft" submit document to publish this will
        // put it into "committed" state
        if (!isDraft) {
          await submitProductDocumentVersion({
            variables: {
              id: finalProduct?.id
            }
          });
        }
      } catch (error) {
        setFormSubmitting(false);
        return enqueueSnackbar(
          `${text.errorMessage} : ${error?.graphQLErrors?.[0]?.message}`,
          {
            variant: 'error'
          }
        );
      }

      await refetchData();
      // close modal
      setSubmitModalClose();

      enqueueSnackbar(isDraft ? text.saveDraftMessage : text.successMessage, {
        variant: 'success'
      });

      // clear any import documents
      await change('importDocument', '');

      if (!rollbackData) {
        // navigate to first step
        await selectExactStep(0);
      }

      if (rollbackData) {
        setRollbackModalState(false);
      }

      setFormSubmitting(false);
    },
    [
      change,
      createProductDocumentVersion,
      formValues,
      refetchData,
      selectExactStep,
      setSubmitModalClose,
      submitProductDocumentVersion,
      text,
      updateProductDocumentVersion
    ]
  );

  const getStepContent = step => {
    const ContentComponent = steps?.[`${step}`]?.content;

    // Set the content for the stepper and pass down props.
    return (
      <ContentComponent
        selectedProduct={currentlySelectedProduct}
        productDataLoading={loading}
      />
    );
  };

  if (loading || error) {
    return <Loading error={error && text.blueprintLoadError} />;
  }

  const breadcrumbPieces = [
    {
      text: text.crumbAdminDashboard,
      to: paths.admin.base
    },
    {
      text: text.crumbBlueprintBuilder,
      to: paths.admin.blueprintBuilderOverview
    },
    {
      text: text.crumbEditBlueprint
    }
  ];

  return (
    <div>
      <BreadcrumbTrail
        sx={theme => ({ marginBottom: theme.spacing(1) })}
        pieces={breadcrumbPieces}
      />
      <BlueprintBuilderHeader
        selectedProduct={currentlySelectedProduct}
        productDataLoading={loading}
        openRollback={() => setRollbackModalState(true)}
        openImportExport={() => setImportExportModalState(true)}
      />
      <BlueprintBuilderWarnings formValues={cleanData(formValues, false)} />

      <DndProvider backend={HTML5Backend}>
        {currentlySelectedProduct?.status !== statusMap.committed && (
          <form onSubmit={handleSubmit(confirmPublish)}>
            <NavigationBlocker block={!!dirty} />
            <Stepper
              classes={{
                root: classes.stepperRoot
              }}
              activeStep={selectedStep}
              orientation="vertical"
              nonLinear
            >
              {Object.keys(steps).map((step, i) => (
                <Step
                  completed={steps?.[`${step}`]?.completed}
                  key={`createProgramStep-${step}`}
                >
                  <StepLabel
                    className={classes.stepLabel}
                    error={steps?.[`${step}`]?.error}
                    onClick={() => handleStepChange(i)}
                  >
                    {steps?.[`${step}`]?.label}
                  </StepLabel>
                  <StepContent>
                    <Paper className={classes.paper}>
                      {getStepContent(step)}
                    </Paper>
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            <SaveBlueprintChangesFab setSubmitModalOpen={setSubmitModalOpen} />

            <EditVariablesFab />

            <DiffDrawer
              formValues={cleanData(formValues, false)}
              allProductVersions={allProductVersions}
              currentDocument={currentlySelectedProduct}
            />

            <ConfirmModal
              currentDocument={currentlySelectedProduct}
              submitModalOpen={submitModalOpen}
              setSubmitModalOpen={setSubmitModalOpen}
              formValues={cleanData(formValues, false)}
              setSubmitModalClose={setSubmitModalClose}
              onPublish={onPublish}
              isSubmitting={formSubmitting}
            />

            <RollbackModal
              currentDocument={currentlySelectedProduct}
              isPublishing={formSubmitting}
              onPublish={onPublish}
              rollbackModalOpen={rollbackModalOpen}
              setRollbackModalClose={() => setRollbackModalState(false)}
            />

            {importExportModalOpen && (
              <ImportExportModal
                currentDocument={currentlySelectedProduct}
                formName={BLUEPRINT_BUILDER_FORM_NAME}
                setImportExportModalClose={() =>
                  setImportExportModalState(false)
                }
              />
            )}
          </form>
        )}
      </DndProvider>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    getProductByProductId,
    productId,
    isPolling,
    setIsPolling,
    getAllProductVersions
  } = ownProps;

  const formValues = getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state);
  const selectedProductId = productId;

  const orderedVersionsProducts = orderBy(
    getAllProductVersions?.productDocumentVersions,
    'versionTimestamp',
    'desc'
  );

  const currentlySelectedProduct = first(
    getProductByProductId?.productDocumentVersions ?? {}
  );

  const allProductVersions = orderedVersionsProducts;

  /*
    The isPolling state is a workaround because the productDocumentVersions document field
    returned from polling are all returning the same document (possible Apollo bug).
    This lead to blank/mostly unchanged diffs and the inability to rollback the version.
    A manual refetch returns the correct version of the documents. This at least avoids making
    the user refresh the page. If there is a better fix for this, please update.
  */
  if (currentlySelectedProduct?.status === statusMap.committed) {
    getProductByProductId.startPolling(4000);
    setIsPolling(true);
  } else {
    getProductByProductId.stopPolling();

    if (isPolling) {
      setIsPolling(false);
      getProductByProductId.refetch();
    }
  }

  let initialValues = {};

  const importDocument = formValues?.importDocument;

  if (currentlySelectedProduct && importDocument) {
    initialValues = {
      ...currentlySelectedProduct,
      document: {
        ...currentlySelectedProduct?.document,
        ...importDocument?.document,
        blueprint: {
          ...currentlySelectedProduct?.document?.blueprint,
          ...importDocument?.document?.blueprint
        }
      },
      importDocument
    };
  }

  if (currentlySelectedProduct && !importDocument) {
    initialValues = currentlySelectedProduct;
  }

  // Sort the parts of the document that have priority
  const finalInitialValues = sortInitialValues(initialValues);

  return {
    selectedStep: state?.blueprintBuilder?.selectedStep,
    steps: state?.blueprintBuilder?.steps,
    initialValues: finalInitialValues,
    selectedProductId,
    formValues,
    allProductVersions,
    currentlySelectedProduct,
    refetchData: getProductByProductId?.refetch,
    loading: getProductByProductId?.loading,
    productId
  };
};

export default flow(
  reduxForm({
    form: BLUEPRINT_BUILDER_FORM_NAME,
    // Note We set destroyOnUnmount=false because we need to preserve the form state for
    //      the stepper. By default redux form will remove a field from state if the field unmounts.
    destroyOnUnmount: false,
    enableReinitialize: true
  }),
  connect(mapStateToProps, {
    selectExactStep,
    selectNextStep,
    selectPreviousStep,
    resetBlueprintBuilder,
    change
  }),

  graphql(getProductByProductId, {
    name: 'getProductByProductId',
    options: ({ productId }) => {
      return {
        fetchPolicy: 'no-cache',
        variables: { productId, limit: 1 }
      };
    },
    props: ({ getProductByProductId }) => {
      return {
        getProductByProductId,
        loading: getProductByProductId?.loading,
        error: getProductByProductId?.error
      };
    }
  }),
  graphql(getAllProductVersions, {
    name: 'getAllProductVersions',
    options: ({ productId }) => {
      return {
        fetchPolicy: 'no-cache',
        variables: { productId }
      };
    },
    props: ({ getAllProductVersions }) => {
      return {
        getAllProductVersions,
        loading: getAllProductVersions?.loading,
        error: getAllProductVersions?.error
      };
    }
  }),
  graphql(createProductDocumentVersion, {
    name: 'createProductDocumentVersion'
  }),
  graphql(updateProductDocumentVersion, {
    name: 'updateProductDocumentVersion',
    options: {
      refetchQueries: ['getAllProductVersions'],
      awaitRefetchQueries: true
    }
  }),
  graphql(submitProductDocumentVersion, {
    name: 'submitProductDocumentVersion'
  }),
  withStyles(styles)
)(BlueprintBuilderForm);
