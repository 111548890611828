import { ReactElement, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';

const ICON_SIZE = 'small';

interface EditableFieldProps {
  labelComponent: ReactElement;
  children: ReactElement;
  canEdit?: boolean;
}

const EditableField = ({
  labelComponent,
  children,
  canEdit = false
}: EditableFieldProps) => {
  const [isEditable, setIsEditable] = useState(canEdit ?? false);

  const toggleEditable = () => {
    setIsEditable(prevState => !prevState);
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      {isEditable && children}
      {!isEditable && (
        <>
          {labelComponent}
          <IconButton onClick={toggleEditable}>
            <EditIcon fontSize={ICON_SIZE} />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default EditableField;
