import { Trans } from 'react-i18next';

import PageTitle from 'src/components/PageTitle';

import { CircularProgress, Grid, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%'
  },
  progress: {
    margin: theme.spacing(2)
  }
});

const Authenticating = ({ classes }) => {
  return (
    <Grid className={classes.root} container spacing={3}>
      <PageTitle subPageTitle="Authenticating" />
      <Grid item xs>
        <CircularProgress className={classes.progress} />
        <Typography component="h1" variant="h4">
          <Trans i18nKey="errorPage:auth.authenticatingHeader">
            Authenticating
          </Trans>
        </Typography>
        <Typography className={classes.errorCode} variant="subtitle1">
          <Trans i18nKey="errorPage:auth.pleaseStandby">Please standby</Trans>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Authenticating);
