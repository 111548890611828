import { clone, isEmpty } from 'lodash';
import { change, FieldArrayFieldsProps } from 'redux-form';

import { BLUEPRINT_BUILDER_FORM_NAME } from 'src/pages/Admin/BlueprintBuilder/Constants';
import { OfferType } from 'src/common/offers';

import DraggableOfferListItem, {
  OnBillingMethodChangeArgs,
  OnChangeToPurchaseTypeArgs,
  OnChangeToSubscriptionTypeArgs
} from './DraggableOfferListItem';
import {
  directPurchaseInputs,
  invoicePurchaseInputs,
  directSubscriptionInputs,
  BILLING_METHODS,
  BillingMethod,
  OfferInput
} from './constants';

interface OfferField {
  slug: string;
  type: OfferType;
  billingMethod: BillingMethod;
  subscription: any;
  purchase: any;
}

interface DraggableOfferListProps {
  fields: FieldArrayFieldsProps<OfferField>;
  getUpdatedOffersInputs: (
    BillingMethod: BillingMethod,
    BillingType: OfferType
  ) => OfferInput[];
  handleAccordionCloseAll: () => void;
  handleAccordionToggle: (index: number) => void;
  openIndexes: number[];
  setHasMoved: (hasMoved: boolean) => void;
  invoiceSubscriptionInputsWithPricingPlans: OfferInput[];
}

const DraggableOfferList = ({
  fields,
  getUpdatedOffersInputs,
  handleAccordionCloseAll,
  handleAccordionToggle,
  openIndexes,
  setHasMoved,
  invoiceSubscriptionInputsWithPricingPlans
}: DraggableOfferListProps) => {
  return (
    !isEmpty(fields) &&
    fields.map((field, index) => {
      const fieldData = fields.get(index);
      const billingType = fieldData?.type;
      const billingMethod = fieldData?.billingMethod;
      const isDirectBilling = billingMethod === BILLING_METHODS.direct;

      const onChangeToSubscriptionType = ({
        handleStorePurchaseValues,
        previousSubscriptionValues
      }: OnChangeToSubscriptionTypeArgs) => {
        const values = clone(fieldData?.purchase);
        handleStorePurchaseValues(values);

        change(
          BLUEPRINT_BUILDER_FORM_NAME,
          `${field}.subscription`,
          previousSubscriptionValues
        );
        change(BLUEPRINT_BUILDER_FORM_NAME, `${field}.purchase`, null);
      };

      const onChangeToPurchaseType = ({
        handleStoreSubscriptionValues,
        previousPurchaseValues
      }: OnChangeToPurchaseTypeArgs) => {
        const values = clone(fieldData?.subscription);
        handleStoreSubscriptionValues(values);

        change(
          BLUEPRINT_BUILDER_FORM_NAME,
          `${field}.purchase`,
          previousPurchaseValues
        );
        change(BLUEPRINT_BUILDER_FORM_NAME, `${field}.subscription`, null);
      };

      const onSubscriptionBillingMethodChange = ({
        storeCurrentValues,
        billingMethodSpecificValues,
        isInvoiceBilling
      }: OnBillingMethodChangeArgs) => {
        const invoiceSpecificInputs = [
          'pricingPlan',
          'tierChangeMethod',
          'applyTierChangeMethodToExistingOrders'
        ];
        const directSpecificInputs = ['stripeProductId'];
        const commonInputs = ['intervalDays', 'minimumDurationDays'];

        const inputsToStore = isInvoiceBilling
          ? directSpecificInputs
          : invoiceSpecificInputs;

        const valuesToStore = inputsToStore.reduce((values, inputName) => {
          const value = fieldData?.subscription?.[inputName];
          if (value) {
            return { ...values, [inputName]: value };
          }

          return values;
        }, {});

        storeCurrentValues(valuesToStore);

        const commonValues = commonInputs.reduce((values, inputName) => {
          const value = fieldData?.subscription?.[inputName];

          if (value) {
            return { ...values, [inputName]: value };
          }

          return values;
        }, {});

        change(BLUEPRINT_BUILDER_FORM_NAME, `${field}.subscription`, {
          ...commonValues,
          ...billingMethodSpecificValues
        });
      };

      const onPurchaseBillingMethodChange = ({
        storeCurrentValues,
        billingMethodSpecificValues,
        isInvoiceBilling
      }: OnBillingMethodChangeArgs) => {
        const invoiceSpecificInputs = [
          'scheduleAdjustmentEnabled',
          'applyScheduleAdjustmentEnabledChangeToExistingOrder',
          'orderAmountAdjustmentEnabled',
          'applyOrderAmountAdjustmentEnabledToExistingOrder'
        ];
        const directSpecificInputs: any[] = [];
        const commonInputs = [
          'price',
          'userSet',
          'userSetMin',
          'userSetMax',
          'defaultDurationDays'
        ];

        const inputsToStore = isInvoiceBilling
          ? directSpecificInputs
          : invoiceSpecificInputs;

        const valuesToStore = inputsToStore.reduce((values, inputName) => {
          const value = fieldData?.purchase?.[inputName];
          if (value) {
            return { ...values, [inputName]: value };
          }

          return values;
        }, {});

        storeCurrentValues(valuesToStore);

        const commonValues = commonInputs.reduce((values, inputName) => {
          const value = fieldData?.purchase?.[inputName];

          if (value) {
            return { ...values, [inputName]: value };
          }

          return values;
        }, {});

        change(BLUEPRINT_BUILDER_FORM_NAME, `${field}.purchase`, {
          ...commonValues,
          ...billingMethodSpecificValues
        });
      };

      const updatedOffersInputs = getUpdatedOffersInputs(
        billingMethod,
        billingType
      );
      const subscriptionSettingsInputs = isDirectBilling
        ? directSubscriptionInputs
        : invoiceSubscriptionInputsWithPricingPlans;

      const purchaseSettingsInputs = isDirectBilling
        ? directPurchaseInputs
        : invoicePurchaseInputs;

      return (
        <DraggableOfferListItem
          key={fieldData?.slug}
          updatedOffersInputs={updatedOffersInputs}
          subscriptionSettingsInputs={subscriptionSettingsInputs}
          purchaseSettingsInputs={purchaseSettingsInputs}
          fields={fields}
          field={field}
          index={index}
          handleAccordionCloseAll={handleAccordionCloseAll}
          handleAccordionToggle={handleAccordionToggle}
          openIndexes={openIndexes}
          setHasMoved={setHasMoved}
          onChangeToSubscriptionType={onChangeToSubscriptionType}
          onChangeToPurchaseType={onChangeToPurchaseType}
          onSubscriptionBillingMethodChange={onSubscriptionBillingMethodChange}
          onPurchaseBillingMethodChange={onPurchaseBillingMethodChange}
        />
      );
    })
  );
};

export default DraggableOfferList;
