import withStyles from '@mui/styles/withStyles';
import { Skeleton, Box } from '@mui/material';

import {
  styles as TiktokAdStyles,
  TIKTOK_PREVIEW_WIDTH
} from './TiktokAdPreview';
import { getPreviewWidth } from '../helpers';

const styles = () => ({
  previewContent: props => ({
    width: getPreviewWidth(
      props.hasUniformPreviewWidth,
      TIKTOK_PREVIEW_WIDTH,
      props.isResponsive
    ),
    height: '460px',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '12px',
    boxSizing: 'content-box',
    backgroundColor: '#ffffff',
    border: '1px solid #e5e5e5'
  })
});

const TiktokAdPreviewSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <div className={classes.previewContent}>
        <div className={classes.infoOverlay}>
          <div className={classes.topBanner}>
            <Skeleton width={100} />
          </div>
          <div className={classes.rightBar}>
            <div className={classes.avatar}>
              <Skeleton variant="circular" width={40} height={40} />
            </div>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2.5}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              marginTop={2}
              alignItems="center"
            >
              <Skeleton width={25} />
            </Box>
          </div>
          <div className={classes.infoContainer}>
            <Skeleton width={160} />
            <div className={classes.infoLabelContainer}>
              <Skeleton width={110} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withStyles(theme => ({
  ...TiktokAdStyles(theme),
  ...styles(theme)
}))(TiktokAdPreviewSkeleton);
