import { useState } from 'react';
import { Trans } from 'react-i18next';

import { Button, Menu, MenuItem } from '@mui/material';

import AddIcon from '@mui/icons-material/NoteAdd';

import { modifierTemplateElements, assetTemplateElements } from './constants';

const AddTemplateElement = props => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElModifier, setAnchorElModifier] = useState(null);
  const { addElement, disableAssets } = props;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickModifier = event => {
    setAnchorElModifier(event.currentTarget);
  };

  const handleCloseModifier = () => {
    setAnchorElModifier(null);
  };

  return (
    <div>
      <Button
        aria-controls="templateElementsMenu"
        aria-haspopup="true"
        color="primary"
        disabled={disableAssets}
        onClick={handleClick}
        startIcon={<AddIcon />}
      >
        <Trans i18nKey="admin:cloudinaryTemplateBuilder.addElementAsset">
          Add Layer Asset Element
        </Trans>
      </Button>
      <Menu
        id="templateElementsMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.keys(assetTemplateElements).map(element => {
          return (
            <MenuItem
              key={assetTemplateElements[element].name}
              onClick={() => {
                handleClose();
                addElement(assetTemplateElements[element]);
              }}
            >
              {assetTemplateElements[element].name}
            </MenuItem>
          );
        })}
      </Menu>

      <Button
        aria-controls="templateElementsMenuModifier"
        aria-haspopup="true"
        color="primary"
        onClick={handleClickModifier}
        startIcon={<AddIcon />}
      >
        <Trans i18nKey="admin:cloudinaryTemplateBuilder.addElementModifier">
          Add Layer Modifier Element
        </Trans>
      </Button>
      <Menu
        id="templateElementsMenuModifier"
        anchorEl={anchorElModifier}
        keepMounted
        open={Boolean(anchorElModifier)}
        onClose={handleCloseModifier}
      >
        {Object.keys(modifierTemplateElements).map(element => {
          return (
            <MenuItem
              key={modifierTemplateElements[element].name}
              onClick={() => {
                handleCloseModifier();
                addElement(modifierTemplateElements[element]);
              }}
            >
              {modifierTemplateElements[element].name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default AddTemplateElement;
