import { SvgIcon } from '@mui/material';

const Gmail = () => {
  return (
    <SvgIcon
      fill="none"
      viewBox="0 0 50 50"
      sx={{ width: '50px', height: '50px' }}
    >
      <g clip-path="url(#a)">
        <path
          fill="#4285F4"
          d="M7.727 40h6.364V24.546L5 17.727v19.546A2.727 2.727 0 0 0 7.727 40Z"
        />
        <path
          fill="#34A853"
          d="M35.91 40h6.363A2.727 2.727 0 0 0 45 37.273V17.727l-9.09 6.819"
        />
        <path
          fill="#FBBC04"
          d="M35.91 12.727v11.818L45 17.727v-3.636c0-3.373-3.85-5.296-6.545-3.273"
        />
        <path
          fill="#EA4335"
          d="M14.09 24.546v-11.82L25 20.91l10.909-8.182v11.819l-10.91 8.181"
        />
        <path
          fill="#C5221F"
          d="M5 14.09v3.637l9.09 6.818V12.727l-2.544-1.909C8.846 8.795 5 10.718 5 14.091Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 10h40v30H5z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default Gmail;
