import { get } from 'lodash';
import { handleActions } from 'redux-actions';

import { galleryConstants } from './actions';
import { GALLERY_SCOPES } from './constants';

const initialState = {
  selectedGallery: null,
  selectedScope: GALLERY_SCOPES.private,
  selectedGalleryIndex: 0,
  uploadStatus: {},
  selectedGalleryItems: {}
};

export default handleActions(
  {
    [galleryConstants.initialSelectGallery]: (state, { payload }) => {
      // Note we need this initial one because in selectGallery we reset
      //      the scope to initial state
      return {
        ...state,
        selectedGallery: get(payload, 'selectedGallery'),
        selectedGalleryIndex: get(payload, 'selectedGalleryIndex')
      };
    },

    [galleryConstants.selectGallery]: (state, { payload }) => {
      return {
        ...state,
        selectedGallery: get(payload, 'selectedGallery'),
        selectedGalleryIndex: get(payload, 'selectedGalleryIndex'),
        selectedScope: initialState.selectedScope
      };
    },

    [galleryConstants.selectSubGallery]: (state, { payload }) => {
      return {
        ...state,
        selectedScope: get(payload, 'scope')
      };
    },

    [galleryConstants.setUploadStatus]: (
      state,
      { payload: { statusObject } }
    ) => {
      // Example of a upload status
      // {
      //     assetId: {
      //         name: file name,
      //         progress: 0,
      //         status: uploadStatusTypes.error
      //     }
      // }

      return {
        ...state,
        uploadStatus: {
          ...state.uploadStatus,
          ...statusObject
        }
      };
    },

    [galleryConstants.removeUploadStatus]: (
      state,
      { payload: { assetId } }
    ) => {
      const newUploadStatus = { ...state.uploadStatus };
      delete newUploadStatus[assetId];
      return {
        ...state,
        uploadStatus: {
          ...newUploadStatus
        }
      };
    },

    [galleryConstants.clearUploadStatus]: state => {
      return {
        ...state,
        uploadStatus: {}
      };
    },

    [galleryConstants.selectGalleryItem]: (
      state,
      { payload: { assetUrl } }
    ) => {
      const newSelectedGalleryItems = {};
      newSelectedGalleryItems[assetUrl] = assetUrl;
      // Note For now we are only letting them select one item at a time to
      //      enable mulit select just add the currently selected items
      //      back in.
      //
      //      selectedGalleryItems: {
      //          ...state.selectedGalleryItems,
      //          ...newSelectedGalleryItems
      //      }
      return {
        ...state,
        selectedGalleryItems: {
          ...newSelectedGalleryItems
        }
      };
    },

    [galleryConstants.deselectGalleryItem]: (
      state,
      { payload: { assetUrl } }
    ) => {
      const newSelectedGalleryItems = { ...state.selectedGalleryItems };
      delete newSelectedGalleryItems[assetUrl];

      return {
        ...state,
        selectedGalleryItems: {
          ...newSelectedGalleryItems
        }
      };
    },

    [galleryConstants.clearSelectedGalleryItems]: state => {
      return {
        ...state,
        selectedGalleryItems: {}
      };
    }
  },
  initialState
);
