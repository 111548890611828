import { Fab, Fade, Tooltip } from '@mui/material';
import {
  AutoAwesome as AiChatIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { t } from 'i18next';

import { aiChatWindowDimensions } from './constants';

const iconStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translate(-50%, -50%)'
};

const fadeTimeout = 400;

interface AiChatFloatingButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

const AiChatFloatingButton = ({
  onClick,
  isOpen
}: AiChatFloatingButtonProps) => {
  return (
    <Tooltip title={t('aiSuggestion:chat.fabTooltip')}>
      <Fab
        data-cy="open-ai-chat-button"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: aiChatWindowDimensions.xPosition,
          zIndex: theme => theme.zIndex.appBar
        }}
        onClick={onClick}
      >
        <Fade in={isOpen} timeout={fadeTimeout} unmountOnExit>
          <CloseIcon sx={iconStyles} />
        </Fade>
        <Fade in={!isOpen} timeout={fadeTimeout} unmountOnExit>
          <AiChatIcon sx={iconStyles} />
        </Fade>
      </Fab>
    </Tooltip>
  );
};

export default AiChatFloatingButton;
