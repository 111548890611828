import { useState } from 'react';
import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';

import {
  Delete as DeleteIcon,
  GroupAdd as GroupAddIcon,
  GroupRemove as GroupRemoveIcon
} from '@mui/icons-material';
import { ListItem, ListItemText, ListItemIcon } from '@mui/material';

import { extractCcErrorMessage } from 'src/common/paymentUtils';
import { teamRoles } from 'src/common/teams';
import { useGlobalContext } from 'src/GlobalContextProvider';
import {
  assetEventTypes,
  assetTypes,
  logAssetEvent
} from 'src/common/orgAssets';

import { useSnackbar } from 'notistack';
import { AssetMenu, MenuIconButton, useMenu } from 'src/components/Menus';
import SharedAssetIcon from 'src/components/Teams/SharedAssetIcon';

import CreditCardModal from './CreditCardModal';
import {
  markPaymentMethodAsRemoved,
  sharePaymentMethod,
  unsharePaymentMethod
} from './mutations';
import { modalTypes } from './Constants';

const pageText = () => {
  return {
    billingItemButtonLabel: t('admin:billingSettings.billingItemButtonLabel'),
    deleteOptionLabel: t('admin:billingSettings.deleteOptionLabel'),

    expires: t('admin:billingSettings.expires'),
    ending: t('admin:billingSettings.ending'),

    shareWithTeamOptionLabel: t(
      'admin:billingSettings.shareWithTeamOptionLabel'
    ),
    unshareWithTeamOptionLabel: t(
      'admin:billingSettings.unshareWithTeamOptionLabel'
    ),
    shareError: t('admin:billingSettings.shareError'),
    unshareError: t('admin:billingSettings.unshareError')
  };
};

const CreditCardListItem = ({
  cardBrand,
  cardExpiryMonth,
  cardExpiryYear,
  cardLastFour,
  paymentMethodId,
  refetch,
  teamCapabilities,
  programs,
  automations,
  permissions = [],
  isVerticalList
}) => {
  const { handleCloseMenu, handleOpenMenu, isMenuOpen, menuAnchorEl } =
    useMenu();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const globalContext = useGlobalContext();
  const group = globalContext?.office;
  const isTeamsEnabled = group?.isTeamsEnabled;
  const teamRole = globalContext?.us?.role;

  const { enqueueSnackbar } = useSnackbar();

  const text = pageText();

  const [handleRemove, { loading: removeLoading }] = useMutation(
    markPaymentMethodAsRemoved,
    {
      onCompleted: () => {
        setModalOpen(false);
        refetch();
      },
      onError: error => {
        const extraErrorMessage = extractCcErrorMessage(error);

        return enqueueSnackbar(
          <span>
            {text.shareError}
            {extraErrorMessage && (
              <>
                <br />
                {extraErrorMessage}
              </>
            )}
          </span>,
          {
            variant: 'error'
          }
        );
      }
    }
  );

  const [handleSharePaymentMethod, { loading: shareLoading }] = useMutation(
    sharePaymentMethod,
    {
      onCompleted: () => {
        setModalOpen(false);
        refetch();
      },
      onError: error => {
        const extraErrorMessage = extractCcErrorMessage(error);

        return enqueueSnackbar(
          <span>
            {text.shareError}
            {extraErrorMessage && (
              <>
                <br />
                {extraErrorMessage}
              </>
            )}
          </span>,
          {
            variant: 'error'
          }
        );
      }
    }
  );

  const [handleUnsharePaymentMethod, { loading: unshareLoading }] = useMutation(
    unsharePaymentMethod,
    {
      onCompleted: () => {
        setModalOpen(false);
        refetch();
      },
      onError: error => {
        const extraErrorMessage = extractCcErrorMessage(error);

        return enqueueSnackbar(
          <span>
            {text.unshareError}
            {extraErrorMessage && (
              <>
                <br />
                {extraErrorMessage}
              </>
            )}
          </span>,
          {
            variant: 'error'
          }
        );
      }
    }
  );

  const openConfirmRemoval = () => {
    logAssetEvent({
      type: assetEventTypes.delete,
      properties: { object: assetTypes.paymentMethod }
    });

    setModalType(modalTypes.delete);
    setModalOpen(true);
    handleCloseMenu();
  };

  const openConfirmShareWithTeam = () => {
    logAssetEvent({
      type: teamCapabilities?.isSharedWithTeam
        ? assetEventTypes.unshare
        : assetEventTypes.share,
      properties: { object: assetTypes.paymentMethod }
    });
    if (teamCapabilities?.isSharedWithTeam) {
      // unshare
      setModalType(modalTypes.unshareWithTeam);
    }
    if (!teamCapabilities?.isSharedWithTeam) {
      // share
      setModalType(modalTypes.shareWithTeam);
    }

    setModalOpen(true);
    handleCloseMenu();
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  let cardExpiryMonthFormatted = '';

  if (cardExpiryMonth && cardExpiryYear) {
    cardExpiryMonthFormatted = `${cardExpiryMonth
      .toString()
      .padStart(2, '0')}/${cardExpiryYear}`;
  }

  let cardItemMenuOptions = [];

  const hasShareWithTeamOption =
    isTeamsEnabled &&
    permissions.includes('share') &&
    (teamRoles.admin === teamRole || teamRoles.lead === teamRole);

  if (hasShareWithTeamOption) {
    cardItemMenuOptions = [
      ...cardItemMenuOptions,
      {
        label: teamCapabilities?.isSharedWithTeam
          ? text.unshareWithTeamOptionLabel
          : text.shareWithTeamOptionLabel,
        onClick: openConfirmShareWithTeam,
        icon: teamCapabilities.isSharedWithTeam ? (
          <GroupRemoveIcon />
        ) : (
          <GroupAddIcon />
        )
      }
    ];
  }

  const hasDeleteOption =
    permissions.includes('delete') && refetch && paymentMethodId;

  if (hasDeleteOption) {
    cardItemMenuOptions = [
      ...cardItemMenuOptions,
      {
        label: text.deleteOptionLabel,
        onClick: openConfirmRemoval,
        icon: <DeleteIcon />
      }
    ];
  }

  const buttonId = `billing-item-button-${paymentMethodId}`;
  const menuId = `billing-item-menu-${paymentMethodId}`;

  const isTeamCardSharedWithTeam =
    teamCapabilities?.isSharedWithMe || teamCapabilities?.isSharedWithTeam;
  const isTeamCardOwnedByMe = teamCapabilities?.isOwnedByMe;

  const pending = shareLoading || unshareLoading || removeLoading;

  return (
    <ListItem
      sx={{
        marginLeft: '-11px',
        marginRight: '11px',
        ...(!isVerticalList && { width: '300px' }) // need to set a width so things can flex & flex-wrap when horizontal
      }}
      data-cy={`cc-item-${cardLastFour}`}
    >
      <ListItemIcon>
        <SharedAssetIcon
          isSharedAsset={isTeamCardSharedWithTeam}
          sx={{ fontSize: 28, color: 'text.primary' }}
        />
      </ListItemIcon>
      <ListItemText
        primary={`${cardBrand} ${text.ending} ${cardLastFour}`}
        secondary={`${cardExpiryMonthFormatted} ${text.expires}`}
        data-cy="cc-label"
        primaryTypographyProps={{ sx: { fontWeight: 500 } }}
      />
      {!isEmpty(cardItemMenuOptions) && (
        <>
          <MenuIconButton
            ariaLabel={text.billingItemButtonLabel}
            ariaControls={isMenuOpen ? menuId : undefined}
            ariaExpanded={isMenuOpen ? 'true' : undefined}
            ariaHaspopup="true"
            onClick={handleOpenMenu}
            id={buttonId}
          />

          <AssetMenu
            isYourAsset={isTeamCardOwnedByMe}
            id={menuId}
            handleClose={handleCloseMenu}
            open={isMenuOpen}
            ariaLabeledBy={buttonId}
            anchorEl={menuAnchorEl}
            options={cardItemMenuOptions}
            disabled={pending}
          />
        </>
      )}

      {/*
            Note: Without refetch we are assuming you are using this to just display the card
                  info so you cannot remove the card
        */}

      <CreditCardModal
        open={isModalOpen}
        onClose={handleModalClose}
        hasTeamCapabilities={!!teamCapabilities}
        handleRemoveCard={() => {
          logAssetEvent({
            type: assetEventTypes.deleteConfirm,
            properties: { object: assetTypes.paymentMethod }
          });
          handleRemove({ variables: { paymentMethodId } });
        }}
        programs={programs}
        automations={automations}
        groupName={group?.officeName}
        handleShareCard={() => {
          logAssetEvent({
            type: teamCapabilities?.isSharedWithTeam
              ? assetEventTypes.unshareConfirm
              : assetEventTypes.shareConfirm,
            properties: { object: assetTypes.paymentMethod }
          });

          if (teamCapabilities?.isSharedWithTeam) {
            handleUnsharePaymentMethod({ variables: { paymentMethodId } });
          } else {
            handleSharePaymentMethod({ variables: { paymentMethodId } });
          }
        }}
        type={modalType}
      />
    </ListItem>
  );
};

export default CreditCardListItem;
