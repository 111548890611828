import { get } from 'lodash';

import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import QuestionIcon from '@mui/icons-material/LiveHelp';

const styles = () => ({});

const fieldsToExclude = [
  'fullName',
  'first_name',
  'last_name',
  'email',
  'phoneNumber'
];

const underscoreRegExp = /_/g;
const firstLetterRegExp = /^\w/;

const extractLeadQuestions = (fields = []) => {
  return Object.keys(fields).reduce((accum, current) => {
    if (fieldsToExclude.includes(current)) {
      // skip
      return accum;
    }

    accum.push({
      name: current
        .replace(underscoreRegExp, ' ')
        .replace(firstLetterRegExp, c => c.toUpperCase()),
      value: fields[current].replace(underscoreRegExp, ' ')
    });
    return accum;
  }, []);
};

const LeadQuestions = ({ classes, leadFields }) => {
  const leadQuestions = extractLeadQuestions(leadFields);

  return (
    <List className={classes.root}>
      {leadQuestions.map(question => {
        return (
          <ListItem key={`leadQ: ${get(question, 'name')}`}>
            <ListItemAvatar>
              <QuestionIcon />
            </ListItemAvatar>
            <ListItemText
              primary={get(question, 'name')}
              secondary={get(question, 'value')}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default withStyles(styles)(LeadQuestions);
