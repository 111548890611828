import { Alert, AlertTitle } from '@mui/material';

const FormAlert = props => {
  const { children, title, severity } = props;

  return (
    <Alert severity={severity}>
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

export default FormAlert;
