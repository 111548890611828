import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const PADDING_Y = 1.5;

interface NavigationListItemProps {
  name: string;
  subtitle?: string;
  icon?: JSX.Element;
  isDropdownMenuItem?: boolean;
  component?: any;
  to?: string;
  onClick?: () => void;
  target?: string;
  children?: React.ReactNode;
  selected?: boolean;
  customProps?: object;
}

const NavigationListItem = ({
  name,
  subtitle,
  icon,
  isDropdownMenuItem,
  component,
  to,
  onClick,
  target,
  children,
  selected,
  customProps
}: NavigationListItemProps) => {
  let primaryTextColor;

  if (isDropdownMenuItem) {
    primaryTextColor = 'grey.600';
  }

  if (!isDropdownMenuItem) {
    primaryTextColor = 'grey.700';
  }

  return (
    <ListItemButton
      component={component}
      to={to}
      onClick={onClick}
      target={target}
      selected={selected}
      sx={{
        pl: isDropdownMenuItem ? 9 : 4,
        py: PADDING_Y
      }}
      {...customProps}
    >
      {icon && (
        <ListItemIcon
          sx={{ margin: 0, width: '24px !important', height: '24px' }}
        >
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        sx={{
          whiteSpace: 'initial',
          '& .MuiListItemText-primary': { color: primaryTextColor },
          '& .MuiListItemText-secondary': {
            color: 'grey.600'
          }
        }}
        primary={name}
        secondary={subtitle}
      />
      {children}
    </ListItemButton>
  );
};

export default NavigationListItem;
