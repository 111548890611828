import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import AccountCircle from '@mui/icons-material/AccountCircle';

const styles = () => ({
  listItem: {
    padding: '4px 16px'
  }
});

const UserListItem = props => {
  const { handleClick, user, classes, style, selected } = props;

  return (
    <ListItem
      button
      className={classes.listItem}
      style={style}
      selected={selected}
      onClick={() => handleClick(user)}
    >
      <ListItemIcon>
        <AccountCircle />
      </ListItemIcon>
      <ListItemText
        disableTypography
        primary={
          <Typography variant="body2" display="block">
            {user.name}
          </Typography>
        }
        secondary={
          <Typography variant="body2" color="textSecondary" display="block">
            {user.email}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default withStyles(styles)(UserListItem);
