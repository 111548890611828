import { ReactElement } from 'react';
import { useFeatures } from 'src/components/Feature';
import { useTheme } from '@mui/material';
import { useAppSettings } from 'src/AppSettings';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { MagicBellProvider } from '@magicbell/magicbell-react';

export interface NotificationGlobalWrapperProps {
  children: ReactElement;
}

/**
 * Wraps a top part of our app to allow for connection to our notification
 * store (MagicBell)
 */
export const NotificationGlobalWrapper = ({
  children
}: NotificationGlobalWrapperProps) => {
  const { showNotificationPanel } = useFeatures();
  const theme = useTheme();
  const appSettings = useAppSettings();
  const { loading, me, isAllowListPath } = useGlobalContext();
  const userId = me?.id;
  const userKey = me?.notificationKey;

  const fontFamily = appSettings?.fonts?.primary?.fontFamily;

  const magicBellTheme = {
    icon: { width: '48px' },
    header: {
      fontFamily,
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.primary.contrastText,
      borderRadius: '4px'
    },
    unseenBadge: {
      backgroundColor: theme.palette.error.main,
      fontFamily,
      fontSize: '8px',
      padding: '1px 2px'
    },
    notification: {
      default: {
        fontFamily,
        borderRadius: '4px',
        hover: {
          backgroundColor: theme.palette.grey[500],
          backgroundOpacity: 0.15
        },
        title: {
          fontFamily
        }
      }
    },
    footer: {
      backgroundColor: theme.palette.primary.main,
      textColor: theme.palette.primary.contrastText,
      fontFamily,
      borderRadius: '4px'
    }
  };

  if (
    import.meta.env.EVOCALIZE_MAGICBELL_API_KEY == null ||
    !showNotificationPanel ||
    isAllowListPath
  ) {
    return <>{children}</>;
  }

  return (
    !loading && (
      <MagicBellProvider
        apiKey={import.meta.env.EVOCALIZE_MAGICBELL_API_KEY}
        userExternalId={userId}
        userKey={userKey}
        theme={magicBellTheme}
      >
        {children}
      </MagicBellProvider>
    )
  );
};
