import { size, find, keyBy, isEmpty, isString, isObject } from 'lodash';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import {
  validateRequired,
  validateIsNumber,
  minValue
} from 'src/common/validations';
import { t } from 'i18next';
import { formatNumber } from 'src/common/numbers';
import { ArchitectureType } from 'src/pages/Architecture/ArchitectureProvider';

import {
  CONTENT_COL_TYPES,
  OPERATOR_TYPES,
  OPERATOR_VALUES,
  NOT_OPERATOR_PREFIX,
  AutomatedProgramFilterType,
  AutomatedProgramFilterRule,
  OperatorType
} from './constants';
import RenderFilterValueText from './RenderFilterValueText';
import RenderFilterValueNumber from './RenderFilterValueNumber';
import RenderFilterValueDate from './RenderFilterValueDate';
import RenderFilterValueSelect from './RenderFilterValueSelect';

export type ContentSetFieldMetadata = NonNullable<
  NonNullable<ArchitectureType['catalog']>['fieldMetadata']
>[0];

// content columns have 2 fields to base display on
// displayMethodId: the id of the filed type
// contentColumnType: the value expected by the backend
export const DISPLAY_METHOD_ID_TO_OPERATORS: {
  [key: string]: (contentColumnType: string) => Set<string>;
} = {
  // should return a Set of allowed Operators
  // [INPUT_TYPES.ExampleType]: contentColumnType => new Set(['eq', 'contains', 'noteq']),
  //
  // as we add overrides we can define them explicitly here:
  // [INPUT_TYPES.ZIP]: 'zip_code',
  // [INPUT_TYPES.ADDRESS]: 'address_autocomplete',
  // [INPUT_TYPES.SINGLE_LINE_STRING]: 'single_line_string',
  // [INPUT_TYPES.MULTI_LINE_STRING]: 'multi_line_string',
  // [INPUT_TYPES.BOOLEAN]: 'boolean',
  // [INPUT_TYPES.FB_AUDIENCE_ID]: 'facebook_audience_id',
  // [INPUT_TYPES.FB_AD_ACCOUNT_ID]: 'facebook_ad_account_id',
  // [INPUT_TYPES.FB_INSTAGRAM_ACTOR_ID]: 'facebook_instagram_actor_id',
  // [INPUT_TYPES.FB_PAGE_ID]: 'facebook_page_id',
  // [INPUT_TYPES.FB_REGION_CODE]: 'facebook_region_code',
  // [INPUT_TYPES.PRICE_DECIMAL]: 'price_decimal',
  // [INPUT_TYPES.PRICE_INT]: 'price_integer',
  // [INPUT_TYPES.ANY_NUMBER]: 'any_number',
  // [INPUT_TYPES.POSITIVE_INT]: 'positive_integer',
  // [INPUT_TYPES.POSITIVE_NUM]: 'positive_number',
  // [INPUT_TYPES.INT_SLIDER]: 'integer_slider',
  // [INPUT_TYPES.DATE_UTC]: 'date_utc',
  // [INPUT_TYPES.GALLERY_IMAGE_URL]: 'gallery_image_url',
  // [INPUT_TYPES.GALLERY_VIDEO_URL]: 'gallery_video_url',
  // [INPUT_TYPES.IMAGE_URL]: 'image_url',
  // [INPUT_TYPES.VIDEO_URL]: 'video_url',
  // [INPUT_TYPES.LINK_URL]: 'link_url',
  // [INPUT_TYPES.PHONE_NUMBER]: 'phone_number',
  // [INPUT_TYPES.SINGLE_SELECT]: 'single_select',
  // [INPUT_TYPES.RADIO_SELECT]: 'radio_select',
  // [INPUT_TYPES.COLOR_INPUT]: 'color_input',
  // [INPUT_TYPES.CUSTOM_LINKS]: 'custom_links',
  // [INPUT_TYPES.KEY_BASED_SETTINGS]: 'key_based_settings'
} as const;

const notOpRegex = new RegExp('^not(.+)');

const defaultColumnTypeToOperators = (contentColumnType: string) => {
  // prettier-ignore
  return ({
        [CONTENT_COL_TYPES.STRING]: new Set(['eq', 'contains', 'notcontains']),
        [CONTENT_COL_TYPES.NUMBER]: new Set(['eq', 'gt', 'lt', 'range']),
        [CONTENT_COL_TYPES.BOOLEAN]: new Set(['is_true', 'is_false']),
        [CONTENT_COL_TYPES.DATE_TIME]: new Set(['daysAfterNowRange', 'daysBeforeNowRange'])
    })[contentColumnType] || new Set(['eq', 'contains']);
};

export const getOperatorsByColumn = (column: ContentSetFieldMetadata) => {
  const allowedOps = (
    DISPLAY_METHOD_ID_TO_OPERATORS?.[column?.displayMethodId] ||
    defaultColumnTypeToOperators
  )(column?.contentColumnType);

  return OPERATOR_TYPES.filter(op => allowedOps.has(op.value));
};

// content columns have 2 fields to base display on
// displayMethodId: the id of the filed type
// contentColumnType: the value expected by the backend
export const DISPLAY_METHOD_ID_TO_INPUTS: {
  [key: string]: (contentColumnType: string) => any;
} = {
  // should return a react component
  // [INPUT_TYPES.ExampleType]: contentColumnType => RenderFilterValueText,
  //
  // as we add overrides we can define them explicitly here:
  [INPUT_TYPES.DATE_UTC]: () => RenderFilterValueDate
};

const defaultColumnTypeToInput = (contentColumnType: string) => {
  // prettier-ignore
  return ({
        [CONTENT_COL_TYPES.STRING]: RenderFilterValueText,
        [CONTENT_COL_TYPES.NUMBER]: RenderFilterValueNumber,
        // operator is true or false so it doesn't need a value
        [CONTENT_COL_TYPES.BOOLEAN]: () => null,
        [CONTENT_COL_TYPES.DATE_TIME]: RenderFilterValueDate
    })[contentColumnType] || RenderFilterValueText;
};

export const getInputByColumn = (column: ContentSetFieldMetadata) => {
  if (column?.enumValues && !isEmpty(column?.enumValues)) {
    return RenderFilterValueSelect;
  }
  return (
    DISPLAY_METHOD_ID_TO_INPUTS?.[column?.displayMethodId] ||
    defaultColumnTypeToInput
  )(column?.contentColumnType);
};

export const getDefaultFilterValueByColumn = (
  column: ContentSetFieldMetadata,
  operator?: AutomatedProgramFilterRule['operator']
) => {
  if (operator === 'range') {
    return { gt: 0, lt: 0 };
  }
  // prettier-ignore
  return ({
        [CONTENT_COL_TYPES.STRING]: '',
        [CONTENT_COL_TYPES.NUMBER]: 0,
        // operator is true or false so it doesn't need a value
        [CONTENT_COL_TYPES.BOOLEAN]: '',
        [CONTENT_COL_TYPES.DATE_TIME]: 0
    })[column.contentColumnType] ?? '';
};

//
// Validations
//

// validaiton ovverrides by display method id
const DISPLAY_METHOD_ID_TO_VALIDATION = {
  // should return a react component
  // [INPUT_TYPES.ExampleType]: contentColumnType => RenderFilterValueText,
};

export const defaultValidationByColumnType = (contentColumnType: string) => {
  // prettier-ignore
  return ({
        [CONTENT_COL_TYPES.STRING]: {validateRequired},
        [CONTENT_COL_TYPES.NUMBER]: {validateRequired, validateIsNumber},
        // operator is true or false so it doesn't need a value validation
        [CONTENT_COL_TYPES.BOOLEAN]: {},
        [CONTENT_COL_TYPES.DATE_TIME]: {validateRequired, validateIsNumber, validateMinValue: minValue(0)}
    })[contentColumnType] ?? {};
};

export const getValidationByColumn = (column: ContentSetFieldMetadata) => {
  return (
    (DISPLAY_METHOD_ID_TO_VALIDATION as any)?.[column?.displayMethodId] ||
    defaultValidationByColumnType
  )(column?.contentColumnType);
};

// validate filters are legit jus filters them out if not
export const validateFiltersByContentMeta = (
  filters: AutomatedProgramFilterType[] = [],
  fieldMeta: ContentSetFieldMetadata[] = []
) => {
  const fieldMap = keyBy(fieldMeta, 'fieldName');
  // go through all the filters
  return filters.reduce<AutomatedProgramFilterType[]>((filtered, filter) => {
    // if the column doesn't exist or isn't filterable remove it.
    if (fieldMap[filter.column] && fieldMap[filter.column]?.isFilterable) {
      const column = {
        column: filter.column,
        // go through all the rules
        rules: filter.rules.reduce<AutomatedProgramFilterType['rules']>(
          (filteredRules, rule) => {
            if (
              rule?.operator &&
              rule?.value !== undefined &&
              OPERATOR_VALUES[rule.operator]
              // this is a valid operator
            ) {
              // we could also validate value type here
              return [...filteredRules, rule];
            }
            return filteredRules;
          },
          []
        )
      };

      if (column.rules.length) {
        return [...filtered, column];
      }
      return filtered;
    }
    return filtered;
  }, []);
};

export const trimRuleValues = (rules: AutomatedProgramFilterRule[]) => {
  return rules.map(rule => ({
    ...rule,
    ...(isString(rule?.value) && { value: rule?.value.trim() })
  }));
};

export const trimFilterRuleValues = (filter: AutomatedProgramFilterType) => {
  return { ...filter, rules: trimRuleValues(filter?.rules) };
};

//
// conversion to and from json
//

const opIsBool = (operator: AutomatedProgramFilterRule['operator']) => {
  return operator === 'is_true' || operator === 'is_false';
};

const opIsNot = (operator: AutomatedProgramFilterRule['operator']) => {
  return notOpRegex.test(operator);
};

const formattedNotOperator = (
  operator: AutomatedProgramFilterRule['operator'],
  value: AutomatedProgramFilterRule['value']
) => {
  const regex = new RegExp(`^${NOT_OPERATOR_PREFIX}(.*)`);
  const formattedOperator = operator.match(regex)?.[1] || '';

  return {
    [formattedOperator]: value
  };
};

const jsonFromFilter = ({
  column,
  operator,
  value
}: {
  column: string;
  operator: AutomatedProgramFilterRule['operator'];
  value: AutomatedProgramFilterRule['value'];
}) => {
  if (opIsBool(operator)) {
    return { [column]: operator };
  }
  if (opIsNot(operator)) {
    return { not: { [column]: formattedNotOperator(operator, value) } };
  }
  if (operator === 'range') {
    return {
      and: [
        { [column]: { gt: (typeof value === 'object' && value?.gt) ?? 0 } },
        { [column]: { lt: (typeof value === 'object' && value?.lt) ?? 0 } }
      ]
    };
  }
  return { [column]: { [operator]: value } };
};

// creates a filter object from our array of filters
// and groups same columns as ORs
// {
//     and: [
//         { status: { eq: 'active' } },
//         { user_id: { eq: 'foo' } },
//         // more than one of the same type is 'or'ed
//         { or: [{ price: { gt: 200 } }, { price: { lt: 100 } }] }
//         // 'range' is 'and'ed
//         { and: [{ price: { gt: 100 } }, { price: { lt: 500 } }] }
//     ];
// }

type FilterCondition = {
  [key: string]: { [key in OperatorType]: string | number };
};

type OrCondition = { or: FilterCondition[] };
type AndCondition = { and: FilterCondition[] };

type FilterTypes = FilterCondition | OrCondition | AndCondition;

type FiltersJSON = {
  and?: FilterTypes[];
};

export const convertFiltersArrayToJSON = (
  filters: AutomatedProgramFilterType[]
): FiltersJSON => {
  // keep track of column types for 'or'ing
  // const columnIndexes = {};
  if (filters.length <= 0) {
    return {};
  }
  const sorted: Array<object> = filters
    .map(trimFilterRuleValues)
    .reduce<Array<object>>((json, filter) => {
      const { column } = filter;
      let newJson = json;

      if (filter.rules.length < 1) {
        // sanity check
        return json;
      }

      if (filter.rules.length > 1) {
        // we need to OR these
        newJson = [
          ...json,
          {
            or: filter.rules.map(rule => jsonFromFilter({ ...rule, column }))
          }
        ];
      } else {
        newJson = [...json, jsonFromFilter({ ...filter.rules[0], column })];
      }

      return newJson;
    }, []);

  return {
    // don't 'and' if it's 1 or zero filters
    ...(sorted.length > 1 ? { and: sorted } : sorted[0])
  };
};

const getKey = (obj: { [key: string]: any }) => Object.keys(obj)[0]; // returns key of single key obj
const getNested = (obj: { [key: string]: any }) => obj[getKey(obj)]; // returns value of first nested obj

// from { zip: { eq: '98122' } }
// to { column: 'zip', rules: [operator: 'eq', value: '98122'], key: '1' }
const filterToFilterObject = (filter: any): AutomatedProgramFilterType => {
  let filters = [];

  if (filter?.or) {
    // multiple rules
    filters = filter.or;
  } else {
    // single rule
    filters = [filter];
  }

  let key = '';

  const rules = filters.map((f: any) => {
    const filter = f.and || f.not || f;

    let nested = null;
    let nestedKey = null;
    let operator = null;
    let value = null;

    if (f.and) {
      key = getKey(filter[0]);
      operator = 'range';
      // from [{price:{gt: '1' }}, {price:{lt: '100' }}]
      // to { gt: 1, lt: 100 }
      value = filter.reduce(
        (value: { [key: string]: string | number }, filt: any) => {
          return {
            ...value,
            [getKey(getNested(filt))]: getNested(getNested(filt))
          };
        },
        {}
      );
    } else {
      key = getKey(filter);
      nested = getNested(filter);
      nestedKey = getKey(getNested(filter));
      operator = nestedKey;
      // not all filter operators have values e.g. 'is_true'
      value = nested?.[nestedKey] || '';
    }

    if (f.not) {
      operator = `${NOT_OPERATOR_PREFIX}${operator}`;
    }

    return {
      operator,
      value
    } as AutomatedProgramFilterRule;
  });

  return {
    column: key,
    rules
  };
};

export const convertFilterJSONtoFiltersArray = (
  filter: FiltersJSON = {}
): AutomatedProgramFilterType[] => {
  if (size(filter) < 1) {
    return [];
  }

  const isRange = (and: FilterTypes[]) => {
    const keyOne = Object.keys(and[0])[0];
    const keyTwo = Object.keys(and[1])[0];
    // between filters contain only 2 items
    // and that aren't combinators
    if (and.length !== 2 || keyOne === 'or' || keyOne === 'and') {
      return false;
    }

    // of the same column
    return keyOne && keyTwo && keyOne === keyTwo;
  };
  /*
        what the top level element means?
        - "or" means filters of same column
        - "and" can mean:
            - multiple columns
            - single filter 'and' i.e. 'range'
        - neither "or" or "and" means single filter
    */

  let topFilters = [];

  if (filter?.and && !isRange(filter?.and)) {
    topFilters = filter.and;
  } else {
    topFilters = [filter];
  }

  // break into objects by column
  return topFilters.map(filterToFilterObject);
};

// translation ovverrides by display method id
const DISPLAY_METHOD_ID_TO_TRANSLATION: {
  [key: string]: (value: string, columnType: string) => string;
} = {
  [INPUT_TYPES.PRICE_INT]: value => `$${formatNumber(value)}`
};

// translation defaults by contentColumnType
const filterValueTranslationByColumnType = (
  value: string,
  contentColumnType: string
) => {
  // prettier-ignore
  return ({
        [CONTENT_COL_TYPES.STRING]: value,
        [CONTENT_COL_TYPES.NUMBER]: value,
        // operator is true or false so it doesn't need a value
        [CONTENT_COL_TYPES.BOOLEAN]: '',
        [CONTENT_COL_TYPES.DATE_TIME]: `${value} days`
    })[contentColumnType] ?? value;
};

export const translateFilterValueByColumn = (
  value: string = '',
  column: ContentSetFieldMetadata
) => {
  // try translation by displayMethodId then default to by contentColumnType
  return (
    DISPLAY_METHOD_ID_TO_TRANSLATION?.[column.displayMethodId] ||
    filterValueTranslationByColumnType
  )(value, column.contentColumnType);
};

export const translateFiltersWithMeta = (
  filters: AutomatedProgramFilterType[] = [],
  meta: ContentSetFieldMetadata[] = []
): AutomatedProgramFilterType[] => {
  return filters.map(filter => {
    const filterColumnItem = find(meta, [
      'fieldName',
      filter?.column
    ]) as ContentSetFieldMetadata;

    const column =
      filterColumnItem?.displayName || filterColumnItem?.displayMethodId;

    const rules = filter.rules.map(rule => {
      const operator = find(OPERATOR_TYPES, ['value', rule?.operator])
        ?.displayName as string;

      const isRange = rule?.operator === 'range';

      let value = '';
      if (
        isRange &&
        filterColumnItem &&
        typeof rule?.value === 'object' &&
        rule?.value?.gt &&
        rule?.value?.lt
      ) {
        value = `${translateFilterValueByColumn(
          rule?.value?.gt,
          filterColumnItem
        )} and ${translateFilterValueByColumn(
          rule?.value?.lt,
          filterColumnItem
        )}`;
      } else if (filterColumnItem) {
        value = translateFilterValueByColumn(
          rule?.value as string,
          filterColumnItem
        );
      }

      return { operator, value };
    });

    return {
      column,
      rules: rules as AutomatedProgramFilterType['rules']
    };
  });
};

export const filterToString = (filter: AutomatedProgramFilterType) =>
  `${filter.column} ${filter.rules.reduce(
    (s, r) =>
      `${s}${s.length ? ' or ' : ''}${r.operator} ${isObject(r.value) ? `${r.value.gt} and ${r.value.lt}` : r.value}`,
    ''
  )}`;

export const filtersToProgramName = (
  filters: AutomatedProgramFilterType[],
  metaData: ContentSetFieldMetadata[],
  contentName: string
) => {
  const content =
    contentName || t('programCreate:automatedName.defaultContent');
  let name = t('programCreate:automatedName.defaultAll', {
    contentName: content
  });

  if (filters.length) {
    name = `${t('programCreate:automatedName.defaultFiltered', {
      contentName: content
    })} ${translateFiltersWithMeta(filters, metaData).reduce(
      (str, filter, i) => {
        return `${str}${i === 0 ? '' : '; '}${filterToString(filter)}`;
      },
      ''
    )}`;
  }

  return name;
};

export const contentMetaFromArchitecture = (architecture: ArchitectureType) => {
  const fields = architecture?.catalog?.fieldMetadata ?? [];
  return fields.filter(f => f.isFilterable);
};
