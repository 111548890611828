import { useState, useCallback, useMemo } from 'react';
import {
  flow,
  first,
  orderBy,
  merge,
  pick,
  find,
  keyBy,
  some,
  isEmpty
} from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import {
  reduxForm,
  getFormValues,
  getFormSyncErrors,
  FieldArray
} from 'redux-form';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';
import {
  nullEmptyStringValues,
  getInitialValuesFromInputsConfig
} from 'src/components/ReduxForm/helpers';
import { Grid, Divider, Button, Paper, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { validateUniqueField } from 'src/common/validations';
import PageTitle from 'src/components/PageTitle';
import { useSnackbar } from 'notistack';
import { DynamicForm } from 'src/components/ReduxForm';

import Loading from 'src/components/Loading';
import ImportExportModal from 'src/pages/Admin/BlueprintBuilder/BlueprintBuilderSteps/ImportExport/ImportExportModal';

import {
  updateContentDocumentVersion,
  createContentDocumentVersion,
  submitContentDocumentVersion,
  newContentDocument
} from './mutations';
import {
  CONTENT_TABLE_FORM_NAME,
  CREATE_NEW_KEY,
  ALLOWED_FIELDS,
  statusMap,
  getContentDocumentFields,
  getColumnInputs
} from './constants';
import { getContentByTableName } from './queries';
import { mergeColumnsData } from './helpers';

import ContentTablesHeader from './ContentTablesHeader';
import ConfirmModal from './ConfirmModal';
import RenderTableForm from './RenderTableForm';

const styles = () => ({});

const pageText = () => ({
  errorMessage: t('admin:contentTable.formErrorMessage'),
  successMessage: t('admin:contentTable.formSuccessMessage'),
  saveDraftMessage: t('admin:contentTable.formSuccessMessageDraft'),
  saveButton: t('admin:contentTable.reviewAndSubmit'),
  modalHeading: t('admin:contentTable.editModalHeading'),
  columnsHeading: t('admin:contentTable.columnsHeading'),
  columnsHeadingButton: t('admin:contentTable.columnsHeadingButton'),
  close: t('admin:contentTable.ColumnModalClose'),
  saveColumns: t('admin:contentTable.ColumnModalSave')
});

const validateNoDuplicateColumnNames = validateUniqueField('name');
const validateRequiredFieldName = (value = []) => {
  if (!some(value, column => column.name === 'id' && column.primaryKey)) {
    return 'Content tables require A field with name=id and primaryKey=true';
  }
};

const ContentTableSettings = props => {
  const {
    submitting,
    history,
    handleSubmit,
    formValues,
    allDocumentVersions,
    currentlySelectedContent,
    newContentDocument,
    createContentDocumentVersion,
    updateContentDocumentVersion,
    submitContentDocumentVersion,
    refetchData,
    isCreateNew,
    loading: contentLoading,
    formErrors
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const errors = !isEmpty(formErrors);
  const loading = contentLoading;
  const text = useMemo(() => pageText(), []);

  const [importExportModalOpen, setImportExportModalState] = useState(false);
  const [submitModalOpen, setSubmitModalState] = useState(false);

  const setSubmitModalOpen = useCallback(() => {
    setSubmitModalState(true);
  }, [submitModalOpen, setSubmitModalState]);

  const setSubmitModalClose = useCallback(() => {
    setSubmitModalState(false);
  }, [submitModalOpen, setSubmitModalState]);

  const confirmPublish = () => {
    setSubmitModalOpen();
  };

  const onPublish = useCallback(
    async ({ isDraft }) => {
      const formData = nullEmptyStringValues(
        pick(mergeColumnsData(formValues), ALLOWED_FIELDS)
      );

      // if document status is "open" or in "error" we just want to update
      let documentId = currentlySelectedContent?.id;

      try {
        if (isCreateNew) {
          const newVersion = await newContentDocument({
            variables: { document: formData }
          });
          documentId = newVersion?.data?.newContentDocument?.id;
        } else {
          if (currentlySelectedContent.status === statusMap.closed) {
            // create new document that is in an "open" state for editing if currently closed
            const newVersion = await createContentDocumentVersion({
              variables: {
                contentTable: formValues?.contentTable
              }
            });
            // update the submitting document to use new "open" id
            documentId = newVersion?.data?.createContentDocumentVersion?.id;
          }

          // update open document / save draft
          await updateContentDocumentVersion({
            variables: {
              id: documentId,
              document: formData
            }
          });
        }

        // if not a "draft" submit document to publish this will
        // put it into "committed" state
        if (!isDraft) {
          await submitContentDocumentVersion({
            variables: {
              id: documentId
            }
          });
        }

        if (isCreateNew) {
          const linkPath = generateLinkPath(
            paths.admin.settings.contentTables,
            {
              contentTable: formData.contentTable
            }
          );

          history.push(linkPath);
        }
      } catch (error) {
        return enqueueSnackbar(
          `${text.errorMessage} : ${error?.graphQLErrors?.[0]?.message}`,
          {
            variant: 'error'
          }
        );
      }

      if (refetchData) {
        // can't refetch if it's new!
        await refetchData();
      }

      // close modal
      setSubmitModalClose();

      enqueueSnackbar(isDraft ? text.saveDraftMessage : text.successMessage, {
        variant: 'success'
      });
    },
    [
      formValues,
      isCreateNew,
      refetchData,
      createContentDocumentVersion,
      updateContentDocumentVersion,
      submitContentDocumentVersion
    ]
  );

  // non-column inputs
  const formInputs = getContentDocumentFields({
    isCreateNew
  });

  const validateThen = callback => {
    handleSubmit(() => {})();
    return callback();
  };

  return (
    <div>
      <PageTitle subPageTitle={t('admin:contentTable.pageTitle')} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(confirmPublish)}>
            <ContentTablesHeader
              isCreateNew={isCreateNew}
              selectedContent={currentlySelectedContent}
              contentDataLoading={loading}
              openImportExport={() => setImportExportModalState(true)}
            />
            {loading ? (
              <Loading />
            ) : (
              <>
                {
                  /* if it's commited we can't edit until it's done */
                  currentlySelectedContent?.status !== statusMap.committed && (
                    <div>
                      <DynamicForm inputs={formInputs} />
                      <br />

                      <Typography variant="h6" component="h2">
                        {text.columnsHeading}
                      </Typography>
                      <Paper>
                        <FieldArray
                          name="columns"
                          component={RenderTableForm}
                          inModal
                          formErrors={formErrors}
                          validateThen={validateThen}
                          validate={[
                            validateNoDuplicateColumnNames,
                            validateRequiredFieldName
                          ]}
                        />
                      </Paper>
                      <br />
                      <Divider />
                      <br />

                      <Button
                        onClick={handleSubmit(confirmPublish)}
                        variant="contained"
                        color="primary"
                        // doesn't need to be dirty because draft can be submitted
                        disabled={submitting || errors}
                      >
                        {text.saveButton}
                      </Button>
                      <ConfirmModal
                        submitModalOpen={submitModalOpen}
                        currentlySelectedContentDoc={
                          currentlySelectedContent?.document
                        }
                        formValues={formValues}
                        setSubmitModalClose={setSubmitModalClose}
                        onPublish={onPublish}
                        submitting={submitting}
                      />
                      {importExportModalOpen && (
                        <ImportExportModal
                          formName={CONTENT_TABLE_FORM_NAME}
                          disableImport
                          allDocumentVersions={allDocumentVersions}
                          currentlySelectedDocument={currentlySelectedContent}
                          setImportExportModalClose={() =>
                            setImportExportModalState(false)
                          }
                        />
                      )}
                    </div>
                  )
                }
              </>
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    getContentByTableName,
    selectedContentTable,
    formValues,
    isCreateNew
  } = ownProps;

  const orderedContent = orderBy(
    getContentByTableName?.contentDocumentVersions,
    'versionTimestamp',
    'desc'
  );

  const currentlySelectedContent = first(orderedContent);
  const allDocumentVersions = orderedContent;

  let columns = currentlySelectedContent?.document?.columns || [];

  // poll while committing
  if (getContentByTableName) {
    if (currentlySelectedContent?.status === statusMap.committed) {
      getContentByTableName.startPolling(4000);
    } else {
      getContentByTableName.stopPolling();
    }
  }

  if (
    currentlySelectedContent &&
    currentlySelectedContent.status !== statusMap.closed
  ) {
    // draft or error we need to mark "editable columns"
    const lastClosedVersion = find(
      orderedContent,
      item => item.status === statusMap.closed
    );

    const oldColumns = keyBy(
      lastClosedVersion?.document?.columns || [],
      'name'
    );

    columns = columns.map(column =>
      !oldColumns[column.name] ? { ...column, isNew: true } : column
    );
  }

  let initialValues = {
    // this is always here (it's the currently selected table)
    contentTable: isCreateNew ? '' : selectedContentTable,
    columns
  };

  if (isCreateNew) {
    const defaultValues = getInitialValuesFromInputsConfig(
      getContentDocumentFields({ isCreateNew })
    );

    initialValues = merge(
      {
        ...defaultValues,
        // for create new a default id field is always required so might as well add it here.
        columns: [
          {
            ...getInitialValuesFromInputsConfig(getColumnInputs()),
            name: 'id',
            type: 'TEXT',
            primaryKey: true,
            nullable: false,
            unique: true
          }
        ]
      },
      initialValues
    );
  } else if (currentlySelectedContent) {
    initialValues = merge(
      { ...currentlySelectedContent?.document, columns },
      initialValues
    );
  }

  // figure out import later
  // const importDocument = formValues?.importDocument;

  // if (currentlySelectedContent && importDocument) {
  //     initialValues = merge(initialValues, importDocument);
  // }

  return {
    initialValues,
    formValues,
    formErrors: getFormSyncErrors(CONTENT_TABLE_FORM_NAME)(state),
    allDocumentVersions,
    currentlySelectedContent,
    refetchData: getContentByTableName?.refetch,
    loading: getContentByTableName?.loading
  };
};

export default flow(
  reduxForm({
    form: CONTENT_TABLE_FORM_NAME,
    enableReinitialize: true,
    forceUnregisterOnUnmount: false,
    destroyOnUnmount: false,
    onChange(values, dispatch, props, previousValues) {
      if (
        values?.columns &&
        previousValues?.columns &&
        values?.columns?.length < previousValues?.columns?.length
      ) {
        setTimeout(() => props.updateSyncErrors(), 1);
      }
    }
  }),
  connect(mapStateToProps),
  graphql(getContentByTableName, {
    name: 'getContentByTableName',
    skip: ({ selectedContentTable, isCreateNew }) =>
      isCreateNew || !selectedContentTable,
    options: ({ selectedContentTable }) => {
      return {
        fetchPolicy: 'no-cache',
        variables: { contentTable: selectedContentTable }
      };
    }
  }),
  connect((state, { match }) => {
    const selectedContentTable = match?.params?.contentTable;
    const formValues = getFormValues(CONTENT_TABLE_FORM_NAME)(state);
    return {
      formValues,
      selectedContentTable,
      isCreateNew: selectedContentTable === CREATE_NEW_KEY
    };
  }),
  graphql(createContentDocumentVersion, {
    name: 'createContentDocumentVersion'
  }),
  graphql(updateContentDocumentVersion, {
    name: 'updateContentDocumentVersion'
  }),
  graphql(submitContentDocumentVersion, {
    name: 'submitContentDocumentVersion'
  }),
  graphql(newContentDocument, {
    name: 'newContentDocument'
  }),
  withRouter,
  withStyles(styles)
)(ContentTableSettings);
