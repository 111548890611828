import { flow } from 'lodash';
import { Trans } from 'react-i18next';
import { useWatch, useFormContext } from 'react-hook-form';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import EditIcon from '@mui/icons-material/Edit';

import HookFormWrapper from 'src/components/ReduxForm/DynamicForm/HookFormWrapper';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import { filtersToProgramName } from 'src/components/AutomatedProgramFilter/helpers';
import { validateRequired } from 'src/common/validations';

const styles = theme => ({
  requiredText: {
    fontStyle: 'italic',
    fontSize: '14px'
  },
  info: {
    position: 'relative',
    bottom: theme.spacing(2),
    color: theme?.palette?.text?.hint
  },
  nameToggle: {
    marginBottom: theme.spacing(2)
  }
});

const CustomNameToggle = ({ defaultName, setValue, classes, input }) => {
  const { onChange, value } = input;
  const handleChange = () => {
    onChange(!value);
    setValue('configureStep.automatedProgramName', defaultName);
  };

  return (
    <div>
      {value ? (
        <div className={classes.nameToggle}>
          <Button color="primary" size="small" onClick={handleChange}>
            <Trans i18nKey="programCreate:automatedName.defaultButton">
              Use Default Name
            </Trans>
          </Button>
        </div>
      ) : (
        <div>
          <p>
            <span>
              <Trans i18nKey="programCreate:automatedName.defaultTitle">
                Automation Name:
              </Trans>
            </span>
            {' "'}
            <strong>{defaultName}</strong>
            {'" '}
            <Button
              color="primary"
              size="small"
              startIcon={<EditIcon />}
              onClick={handleChange}
              data-cy="edit-automated-name"
            >
              <Trans i18nKey="programCreate:automatedName.editButton">
                Edit
              </Trans>
            </Button>
          </p>
        </div>
      )}
    </div>
  );
};

let oldName = '';

const AutomatedProgramName = ({
  classes,
  architecture,
  contentName,
  isSummary
}) => {
  const { setValue } = useFormContext();
  const metaData = architecture?.catalog?.fieldMetadata;
  const automatedProgramNameCustom = useWatch({
    name: 'configureStep.automatedProgramNameCustom',
    defaultValue: false
  });
  const filters = useWatch({
    name: 'configureStep.automatedPrograms.filters',
    defaultValue: []
  });
  const defaultName = filtersToProgramName(filters, metaData, contentName);

  if (!automatedProgramNameCustom && defaultName !== oldName) {
    setValue('configureStep.automatedProgramName', defaultName);
    oldName = defaultName;
  }

  const label = (
    <span>
      <Trans i18nKey="programCreate:automatedName.label">Custom Name</Trans>
      <span className={classes.requiredText}> (Required)</span>
    </span>
  );

  if (isSummary) {
    // if we are in the summary side panel only show the input
    return (
      <HookFormWrapper
        component={RenderTextField}
        name="configureStep.automatedProgramName"
        validate={{ validateRequired }}
        extraProps={{ variant: 'standard' }}
      />
    );
  }

  return (
    <>
      {automatedProgramNameCustom && (
        <HookFormWrapper
          component={RenderTextField}
          label={label}
          name="configureStep.automatedProgramName"
          validate={{ validateRequired }}
        />
      )}
      <HookFormWrapper
        name="configureStep.automatedProgramNameCustom"
        component={CustomNameToggle}
        extraProps={{
          defaultName,
          setValue,
          classes
        }}
      />

      <div className={classes.info}>
        <Trans i18nKey="programCreate:automatedName.subheading">
          This name is a label for your reference. It is not displayed in any
          ads.
        </Trans>
      </div>
    </>
  );
};

export default flow(withStyles(styles))(AutomatedProgramName);
