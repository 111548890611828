import { Redirect } from 'react-router-dom';

import { isSuperAdmin } from 'src/Auth/common';

import PrivateRoute from './PrivateRoute';
import { paths } from './paths';

const SuperAdminRoute = props => {
  // Check to ensure its a super admin
  if (isSuperAdmin()) {
    return <PrivateRoute {...props} />;
  }
  // If not a super admin send to the dashboard
  return (
    <Redirect
      to={{
        pathname: paths.dashboard.base
      }}
    />
  );
};

export default SuperAdminRoute;
