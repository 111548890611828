import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';

import DataTable from 'src/components/DataTable/DataTable';

import { getAutomationContentPreviewByArchitectureId } from './queries';
import { getContentRows, getTableColumns } from './utils';

interface AutomationContentPreviewTableProps {
  architectureId: string;
  displayCollapseKey: string;
  tableFilters: any;
  highlightedColumns: any;
}

const defaultPageSize = 10;

const AutomationContentPreviewTable = (
  props: AutomationContentPreviewTableProps
) => {
  const {
    architectureId,
    displayCollapseKey,
    tableFilters,
    highlightedColumns
  } = props;

  const [columnRef, setColumnRef] = useState<any>([]);

  const [queryGetAutomationContentPreviewByArchitectureId] = useLazyQuery(
    getAutomationContentPreviewByArchitectureId
  );

  const loadMoreRows = async () => {
    const variables = {
      architectureId,
      groupKey: displayCollapseKey,
      first: defaultPageSize,
      filter: tableFilters
    };

    try {
      const getResponse =
        await queryGetAutomationContentPreviewByArchitectureId({
          fetchPolicy: 'no-cache',
          variables
        });

      const catalogFieldMetadata =
        getResponse?.data?.architecture?.catalog?.fieldMetadata;
      const catalogContent = getResponse?.data?.architecture?.catalog?.content;

      const formattedColumns = getTableColumns(
        catalogFieldMetadata,
        false,
        highlightedColumns
      );

      setColumnRef(formattedColumns);

      const rows = getContentRows(catalogContent?.edges);

      return {
        rows,
        hasMoreRows: false
      };
    } catch (error) {
      return { rows: [], hasMoreRows: false };
    }
  };

  return (
    <>
      <Box
        sx={{
          '.highlight-cell': {
            background: theme => alpha(theme.palette.warning.main, 0.5)
          }
        }}
      >
        <DataTable
          columns={columnRef}
          loadMoreRows={loadMoreRows}
          disableRowSelectionOnClick
          scrollEndThreshold={10}
          showBorder={false}
        />
      </Box>
    </>
  );
};

export default AutomationContentPreviewTable;
