import { useCallback } from 'react';
import { flow, pickBy, isEmpty } from 'lodash';
import {
  Button,
  Grid,
  Paper,
  Typography,
  CircularProgress
} from '@mui/material';

import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';
import { reduxForm } from 'redux-form';
import { t } from 'i18next';

import { useSnackbar } from 'notistack';
import Loading from 'src/components/Loading';
import { DynamicForm } from 'src/components/ReduxForm';
import Heading from 'src/components/PageElements/Heading';

import { FORM_NAME, getAuthInputs } from './constants';
import { updateAuth0Config, createAuth0Config } from './mutations';
import { getAuth0Config } from './queries';

const AdminAuthenticationSettings = ({
  submitting,
  invalid,
  dirty,
  reset,
  handleSubmit,
  initialValues,
  updateAuth0Config,
  createAuth0Config,
  auth0Settings,
  auth0SettingsMeta: { loading, error, refetch }
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isUpdate = !isEmpty(auth0Settings);

  const inputs = getAuthInputs(isUpdate);

  const onSubmit = useCallback(
    async data => {
      const resetForm = () => {
        refetch();
        reset();
      };

      // only send data that has changed from initialValues
      const onlyChanged = pickBy(
        data,
        (val, key) => val !== initialValues?.[key]
      );

      const request = isUpdate ? updateAuth0Config : createAuth0Config;

      try {
        await request({
          variables: {
            [isUpdate ? 'updateInput' : 'createInput']: onlyChanged
          }
        });
        resetForm();
      } catch (error) {
        enqueueSnackbar(t('admin:auth0Settings.errorDescription'), {
          variant: 'error'
        });
        resetForm();
        return;
      }

      enqueueSnackbar(t('admin:auth0Settings.successDescription'), {
        variant: 'success'
      });
    },
    [refetch, reset]
  );

  if (loading) {
    return <Loading error={error} />;
  }

  return (
    <>
      <Heading
        title={t('admin:headings.auth0SettingsTitle')}
        subTitle={t('admin:headings.auth0SettingsDescription')}
        pageTitle={t('admin:headings.auth0SettingsTitle')}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            sx={{
              marginTop: theme => theme.spacing(2),
              padding: theme => theme.spacing(2)
            }}
          >
            <Typography component="h1" variant="h6">
              <Trans i18nKey="admin:headings.auth0SettingsCreate">
                Auth0 Settings
              </Trans>
            </Typography>
            <form autoComplete="false" onSubmit={handleSubmit(onSubmit)}>
              <DynamicForm inputs={inputs} />
              <Button
                color="primary"
                disabled={!dirty || submitting || invalid}
                endIcon={submitting && <CircularProgress size={15} />}
                variant="contained"
                type="submit"
              >
                <Trans i18nKey="admin:auth0Settings.submitButtonSave">
                  Save Settings
                </Trans>
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const mapConfigToProps = auth0Configuration => {
  const loading = auth0Configuration?.loading;
  const error = auth0Configuration?.error;
  const refetch = auth0Configuration?.refetch;
  const auth0Settings = auth0Configuration?.myOrganization?.auth0Config;

  return {
    initialValues: auth0Settings || {},
    auth0Settings,
    auth0SettingsMeta: {
      loading,
      error,
      refetch
    }
  };
};

export default flow(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
  }),
  graphql(getAuth0Config, {
    name: 'auth0Configuration',
    options: () => ({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache'
    }),
    props: ({ auth0Configuration }) => mapConfigToProps(auth0Configuration)
  }),
  graphql(updateAuth0Config, {
    name: 'updateAuth0Config'
  }),
  graphql(createAuth0Config, {
    name: 'createAuth0Config'
  })
)(AdminAuthenticationSettings);
