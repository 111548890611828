import { useState, useCallback, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useMutation, useQuery } from '@apollo/client';

import { mapIntrospectionToProps } from 'src/common/ApolloUtil';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import { FormProvider, useForm } from 'react-hook-form';

import { getAccessToken } from 'src/Auth/common';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import Loading from 'src/components/Loading';
import Heading from 'src/components/PageElements/Heading';
import {
  formatIndustryOptions,
  getInitialValuesAndAuthConfigType
} from './utils';

import {
  getCreateOrganizationInputs,
  getCreateOrganizationInternalAuthInputs,
  getCreateOrganizationOktaDirectSSOAuthInputs,
  AUTH_CONFIG_TYPES,
  getCreateOrganizationAuth0Inputs
} from './Constants';
import { createOrganization as createOrganizationMutation } from './mutations';
import { authProviderIntrospection, getIndustries } from './queries';

const AdminCreateOrganization = () => {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [successData, setSuccessData] = useState({});
  const [authConfigType, setAuthConfigType] = useState('');

  const introspectionResponse = useQuery(authProviderIntrospection);

  const { authProviderTypeIntrospectionMeta, authProviderTypeEnumFields = [] } =
    mapIntrospectionToProps(
      {
        error: introspectionResponse.error,
        loading: introspectionResponse.loading,
        // eslint-disable-next-line no-underscore-dangle
        __type: introspectionResponse?.data?.__type
      },
      'authProviderType'
    );

  const formMethods = useForm({ mode: 'onBlur', shouldUnregister: true });
  const { isSubmitting, isValid } = formMethods.formState;
  const { reset } = formMethods;
  const formValues = formMethods.watch();

  useEffect(() => {
    const response = getInitialValuesAndAuthConfigType(formValues);
    reset(response.initialValues);
    setAuthConfigType(response.authConfigType);
  }, [formValues.authProviderType]);

  const getIndustriesResponse = useQuery(getIndustries);

  const industryOptions = formatIndustryOptions(
    getIndustriesResponse?.data?.getIndustries
  );

  const handleClearForm = useCallback(() => {
    setError(false);
    setSuccess(false);
    setSuccessData({});
    reset();
  });

  const [createOrganization] = useMutation(createOrganizationMutation);

  const onSubmit = useCallback(
    async data => {
      const createInput = cloneDeep(data);

      const mutationParams = {
        masterToken: getAccessToken(),
        createInput
      };
      let orgData;

      try {
        orgData = await createOrganization({
          variables: {
            ...mutationParams
          }
        });
      } catch (e) {
        setError(e.toString());
        return;
      }

      setSuccess(true);
      setSuccessData(orgData?.data?.createOrganization);
    },
    [createOrganization]
  );

  if (authProviderTypeIntrospectionMeta.loading) {
    return <Loading />;
  }

  return (
    <FormProvider {...formMethods}>
      <form autoComplete="off" onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Heading
          title={t('admin:headings.createOrganization')}
          subTitle={t('admin:headings.createOrganizationDescription')}
          pageTitle={t('admin:headings.createOrganization')}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {success ? (
              <Paper
                sx={{
                  mt: 2,
                  p: 2,
                  textAlign: 'center',

                  '& svg': {
                    color: 'success.main'
                  }
                }}
              >
                <CheckIcon />
                <Typography
                  component="h2"
                  sx={{
                    color: 'success.main',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Trans i18nKey="admin:createOrganization.success">
                    Success!
                  </Trans>
                  <br />
                  <Trans i18nKey="admin:createOrganization.successDescription">
                    You have initialized a new organization.
                  </Trans>
                </Typography>

                <Box
                  component="ul"
                  sx={{
                    mt: 1,
                    padding: 0,
                    listStyle: 'none'
                  }}
                >
                  <li>
                    id: <strong>{successData?.id}</strong>
                  </li>
                  <li>
                    fqdn: <strong>{successData?.fqdn}</strong>
                  </li>
                  <li>
                    name: <strong>{successData?.name}</strong>
                  </li>
                  <li>
                    slug: <strong>{successData?.slug}</strong>
                  </li>
                </Box>
                <br />
                <br />

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleClearForm}
                >
                  <Trans i18nKey="admin:createOrganization.createAnotherButton">
                    Create New Organization
                  </Trans>
                </Button>
              </Paper>
            ) : (
              <Paper
                sx={{
                  mt: 2,
                  p: 2
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    mb: 1
                  }}
                >
                  <Trans i18nKey="admin:headings.createOrganizationCardHeading">
                    Setup new production organization
                  </Trans>
                </Typography>
                <>
                  {error && (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                      }}
                    >
                      <ErrorMessage>
                        <Trans i18nKey="admin:createOrganization.errorMessage">
                          There was an error initializing your new organization.
                          Please ensure everything was filled out correctly and
                          that your Fully Qualified Domain Name has not already
                          been used as this is a unique identifier. If the error
                          is Stripe Connect related your org may have been
                          created but the stripe connect linking might have
                          failed and needs to be done manually
                        </Trans>
                        <br />
                        {error}
                      </ErrorMessage>
                    </Box>
                  )}
                </>
                <div data-cy="create-organization-form">
                  <DynamicForm
                    isHookForm
                    hookFormMethods={formMethods}
                    inputs={getCreateOrganizationInputs({
                      enums: { authProviderTypeEnumFields },
                      industryOptions
                    })}
                  />
                </div>
                <Typography
                  component="h3"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    mb: 1,
                    mt: 2
                  }}
                >
                  <Trans i18nKey="admin:headings.createOrganizationAuthHeading">
                    Setup new production authorization
                  </Trans>
                </Typography>
                <div data-cy="create-authorization-form">
                  {authConfigType === AUTH_CONFIG_TYPES.auth0 && (
                    <DynamicForm
                      isHookForm
                      hookFormMethods={formMethods}
                      inputs={getCreateOrganizationAuth0Inputs()}
                    />
                  )}
                  {authConfigType === AUTH_CONFIG_TYPES.internal && (
                    <DynamicForm
                      isHookForm
                      hookFormMethods={formMethods}
                      inputs={getCreateOrganizationInternalAuthInputs()}
                    />
                  )}
                  {authConfigType === AUTH_CONFIG_TYPES.okta_direct_sso && (
                    <DynamicForm
                      isHookForm
                      hookFormMethods={formMethods}
                      inputs={getCreateOrganizationOktaDirectSSOAuthInputs()}
                    />
                  )}
                </div>
                <Box
                  sx={{
                    mt: 1,
                    mb: 2,
                    '& button:first-child': {
                      mr: 1
                    }
                  }}
                >
                  <Button
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    type="submit"
                    variant="contained"
                    data-cy="create-organization-button"
                  >
                    <Trans i18nKey="admin:createOrganization.submitButton">
                      Create Organization
                    </Trans>
                  </Button>

                  <Button onClick={handleClearForm}>
                    <Trans i18nKey="admin:createOrganization.clearButton">
                      Clear Form
                    </Trans>
                  </Button>
                </Box>
              </Paper>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AdminCreateOrganization;
