export const s3BaseUrl = '//s3.amazonaws.com/ev-lithium/assets/images/';

export const s3ImageSubfolders = {
  adPreviewPlaceholders: 'ad-preview-placeholders'
};

const S3Image = ({ imageName, theme, alt, ...restProps }) => {
  const url = s3BaseUrl + imageName;

  return <img src={url} alt={alt || url} {...restProps} />;
};

export default S3Image;
