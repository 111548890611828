import { sortBy, flow } from 'lodash';
import { withApollo, graphql } from '@apollo/client/react/hoc';
import { TextField, FormControl, Autocomplete, Box } from '@mui/material';
import { t } from 'i18next';

import { getAccessToken } from 'src/Auth/common';

import { loadOrganizations } from './queries';

const RenderOrganizationSelector = props => {
  const {
    organizationsOptions,
    input,
    margin = 'normal',
    autoComplete = false
  } = props;
  const selectOrgText = t('admin:input.orgSelectLabel');

  const handleOnChange = (_, selected) => {
    input.onChange(selected);
  };

  const handleOnBlur = (_, selected) => {
    input.onBlur(selected);
  };

  return (
    <FormControl fullWidth variant="outlined" margin={margin}>
      <Autocomplete
        {...input}
        data-cy="render-organization-selector"
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        options={sortBy(organizationsOptions, o => o.label.toLowerCase())}
        autoComplete={autoComplete}
        freeSolo // allow user to enter any value & fixes warning saying "" is not a valid value
        forcePopupIcon
        renderInput={params => (
          <TextField {...params} label={selectOrgText} variant="outlined" />
        )}
        renderOption={(props, option) => {
          return (
            <Box component="li" {...props} key={props?.id}>
              {option.label}
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default flow(
  graphql(loadOrganizations, {
    name: 'organizations',
    options: () => {
      return {
        variables: {
          masterToken: getAccessToken()
        }
      };
    },
    props: response => {
      const { organizations } = response;

      const { loading } = organizations;

      const organizationsRaw = organizations?.admin?.organizations || [];
      const organizationsOptions = organizationsRaw.map(org => {
        return {
          label: org.name,
          value: org.id
        };
      });

      return {
        organizations: organizationsRaw,
        organizationsOptions,
        organizationsLoading: loading
      };
    }
  }),
  withApollo
)(RenderOrganizationSelector);
