import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { OFFER_TYPES, OfferType } from 'src/common/offers';

import {
  CANCELLATION_TYPES,
  ORDER_PROCESSING_STATUS,
  CancellationType,
  OrderProcessingStatus
} from 'src/pages/ProgramPerformance/Constants';

import HelpTooltip from 'src/components/HelpTooltip';

interface ModalDescriptionProps {
  isInsideSevenDayWindow: boolean;
  offerType: OfferType;
  cancellationType: CancellationType;
  expirationDate: string;
  refundAmount: string;
  orderStatus: OrderProcessingStatus;
  refundCalculationError: boolean;
  isRefundEnabled: boolean;
}

const ModalDescription = ({
  isInsideSevenDayWindow,
  offerType,
  cancellationType,
  expirationDate,
  refundAmount,
  orderStatus,
  refundCalculationError,
  isRefundEnabled
}: ModalDescriptionProps) => {
  // Created these constants to make the code more readable
  const isSubscription = offerType === OFFER_TYPES.subscription;
  const isPurchase = offerType === OFFER_TYPES.purchase;
  const isPendingOrder = orderStatus === ORDER_PROCESSING_STATUS.pending;
  const isDeferredCancellation =
    cancellationType === CANCELLATION_TYPES.deferred;
  const isImmediateCancellation =
    cancellationType === CANCELLATION_TYPES.immediate;

  const isDeferredSubscriptionInsideSevenDays =
    isInsideSevenDayWindow && isSubscription && isDeferredCancellation;

  const isDeferredSubscriptionOutsideSevenDays =
    !isInsideSevenDayWindow && isSubscription && isDeferredCancellation;

  const isImmediateSubscriptionInsideSevenDays =
    isInsideSevenDayWindow && isSubscription && isImmediateCancellation;

  const isImmediateSubscriptionOutsideSevenDays =
    !isInsideSevenDayWindow && isSubscription && isImmediateCancellation;

  const isPendingSubscription = isSubscription && isPendingOrder;
  const isPendingPurchase = isPurchase && isPendingOrder;

  const tooltipTitle = t('programPerf:cancelModal.tooltip');

  if (isPendingPurchase) {
    return <Trans i18nKey="programPerf:cancelModal.pendingPurchase" />;
  }

  if (isPendingSubscription) {
    return <Trans i18nKey="programPerf:cancelModal.pendingSubscription" />;
  }

  if (
    isSubscription &&
    isDeferredCancellation &&
    (refundCalculationError || !isRefundEnabled)
  ) {
    return (
      <Trans i18nKey="programPerf:cancelModal.subscriptionDeferredError" />
    );
  }

  if (
    isSubscription &&
    isImmediateCancellation &&
    (refundCalculationError || !isRefundEnabled)
  ) {
    return (
      <Trans i18nKey="programPerf:cancelModal.subscriptionImmediateError" />
    );
  }

  if ((refundCalculationError || !isRefundEnabled) && isPurchase) {
    return <Trans i18nKey="programPerf:cancelModal.oneTimePurchaseError" />;
  }

  if (isDeferredSubscriptionInsideSevenDays) {
    return (
      <Trans
        i18nKey="programPerf:cancelModal.subscriptionInsideSevenDays"
        values={{ expirationDate, refundAmount }}
      />
    );
  }

  if (isDeferredSubscriptionOutsideSevenDays) {
    return (
      <Trans
        i18nKey="programPerf:cancelModal.subscriptionDeferredOutsideSevenDays"
        values={{ expirationDate }}
      />
    );
  }

  if (
    isImmediateSubscriptionOutsideSevenDays ||
    isImmediateSubscriptionInsideSevenDays
  ) {
    return (
      <Trans
        i18nKey="programPerf:cancelModal.immediateSubscription"
        values={{ refundAmount }}
        components={[<HelpTooltip title={tooltipTitle} placement="top" />]}
      />
    );
  }

  if (isPurchase) {
    return (
      <Trans
        i18nKey="programPerf:cancelModal.oneTimePurchase"
        values={{ refundAmount }}
        components={[<HelpTooltip title={tooltipTitle} placement="top" />]}
      />
    );
  }

  return null;
};

export default ModalDescription;
