import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import { validateLength, validateNotBlank } from 'src/common/validations';

export const INPUT_TEST_FORM = 'INPUT_TEST_FORM';
export const CONDITIONAL_RENDER_IDENTIFIER = 'CONDITIONAL_RENDER_IDENTIFIER';
export const CONDITIONAL_RENDER_IDENTIFIER_LEGACY =
  'CONDITIONAL_RENDER_IDENTIFIER_LEGACY';

export const componentTypes = {
  formInput: 'FORM_INPUT'
};

export const supportedInputLevers = {
  isRequired: true,
  readOnly: true
};

export const supportedDisplayParameters = {
  helperText: true,
  tooltip: true,
  startAdornment: true,
  endAdornment: true,
  isMultiSelect: true,
  addressComponentRestrictions: true,
  types: true
};

export const boolParameters = ['isMultiSelect', 'isRequired', 'readOnly'];

export const supportedValidations = {
  validateLength: validateLength(5, 20),
  validateNotBlank: validateNotBlank()
};

export const testFormInputMap = {
  [INPUT_TYPES.ADDRESS]: {
    buttonText: 'Address Selector',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.ADDRESS
    })}`,
    inputData: {
      name: INPUT_TYPES.ADDRESS,
      displayName: 'Address Selector',
      displayMethodId: INPUT_TYPES.ADDRESS,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          children: [
            { name: 'latitude' },
            { name: 'longitude' },
            { name: 'addressZip' },
            { name: 'addressState' }
          ]
          // helperText: 'helper text',
          // tooltip: 'tooltip',
          // startAdornment: 'startAdornment',
          // endAdornment: 'endAdornment',
        }
      }
    }
  },
  [CONDITIONAL_RENDER_IDENTIFIER]: {
    buttonText: 'Conditional Render',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: CONDITIONAL_RENDER_IDENTIFIER
    })}`,
    inputData: {
      name: CONDITIONAL_RENDER_IDENTIFIER,
      displayName: 'Conditional Render Text',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          conditionalRender: [
            {
              name: 'selectionInput',
              value: '4200'
            },
            {
              name: 'radioInput',
              value: '42000'
            }
          ]
          // helperText: 'helper text',
          // tooltip: 'tooltip',
          // startAdornment: 'startAdornment',
          // endAdornment: 'endAdornment',
        }
      }
    }
  },
  [CONDITIONAL_RENDER_IDENTIFIER_LEGACY]: {
    buttonText: 'Legacy Conditional Render',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: CONDITIONAL_RENDER_IDENTIFIER_LEGACY
    })}`,
    inputData: {
      name: CONDITIONAL_RENDER_IDENTIFIER_LEGACY,
      displayName: 'Legacy Conditional Render Text',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          conditionalRender: {
            name: 'selectionInput',
            value: '4200'
          }
          // helperText: 'helper text',
          // tooltip: 'tooltip',
          // startAdornment: 'startAdornment',
          // endAdornment: 'endAdornment',
        }
      }
    }
  },
  [INPUT_TYPES.BUSINESS_OBJECT_SELECTOR]: {
    buttonText: 'business_object_selector',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.BUSINESS_OBJECT_SELECTOR
    })}`,
    inputData: {
      name: INPUT_TYPES.BUSINESS_OBJECT_SELECTOR,
      displayName: 'Content',
      displayMethodId: INPUT_TYPES.BUSINESS_OBJECT_SELECTOR,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          architectureId: 11006,
          columnWidth: 4,
          contentGroupKey: 'id',
          minMaxSelection: { min: 1, max: 1 },
          contentName: 'Content'
        }
      }
    }
  },
  [INPUT_TYPES.COLOR_INPUT]: {
    buttonText: 'COLOR_INPUT',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.COLOR_INPUT
    })}`,
    inputData: {
      name: INPUT_TYPES.COLOR_INPUT,
      displayName: 'Content',
      displayMethodId: INPUT_TYPES.COLOR_INPUT,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.CUSTOM_LINKS]: {
    buttonText: 'CUSTOM_LINKS',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.CUSTOM_LINKS
    })}`,
    inputData: {
      name: INPUT_TYPES.CUSTOM_LINKS,
      displayName: 'Content',
      displayMethodId: INPUT_TYPES.CUSTOM_LINKS,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          actionButtonText: 'Add Custom Menu Link'
        }
      }
    }
  },
  [INPUT_TYPES.DATE_UTC]: {
    buttonText: 'DATE_UTC',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.DATE_UTC
    })}`,
    inputData: {
      name: INPUT_TYPES.DATE_UTC,
      displayName: 'Content',
      displayMethodId: INPUT_TYPES.DATE_UTC,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.FB_AD_ACCOUNT_ID]: {
    buttonText: 'FB_AD_ACCOUNT_ID',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.FB_AD_ACCOUNT_ID
    })}`,
    inputData: {
      name: INPUT_TYPES.FB_AD_ACCOUNT_ID,
      displayName: 'FB_AD_ACCOUNT_ID',
      displayMethodId: INPUT_TYPES.FB_AD_ACCOUNT_ID,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.GALLERY_IMAGE_URL]: {
    buttonText: 'GALLERY_IMAGE_URL',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.GALLERY_IMAGE_URL
    })}`,
    inputData: {
      name: INPUT_TYPES.GALLERY_IMAGE_URL,
      displayName: 'GALLERY_IMAGE_URL',
      displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.IMAGE_URL]: {
    buttonText: 'IMAGE_URL',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.IMAGE_URL
    })}`,
    inputData: {
      name: INPUT_TYPES.IMAGE_URL,
      displayName: 'IMAGE_URL',
      displayMethodId: INPUT_TYPES.IMAGE_URL,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.JSON]: {
    buttonText: 'JSON',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.JSON
    })}`,
    inputData: {
      name: INPUT_TYPES.JSON,
      displayName: 'JSON',
      displayMethodId: INPUT_TYPES.JSON,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.KEY_BASED_SETTINGS]: {
    buttonText: 'KEY_BASED_SETTINGS',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.KEY_BASED_SETTINGS
    })}`,
    inputData: {
      name: INPUT_TYPES.KEY_BASED_SETTINGS,
      displayName: 'KEY_BASED_SETTINGS',
      displayMethodId: INPUT_TYPES.KEY_BASED_SETTINGS,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.LINK_URL]: {
    buttonText: 'LINK_URL',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.LINK_URL
    })}`,
    inputData: {
      name: INPUT_TYPES.LINK_URL,
      displayName: 'LINK_URL',
      displayMethodId: INPUT_TYPES.LINK_URL,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.ORG_CONTENT_SELECTOR]: {
    buttonText: 'ORG_CONTENT_SELECTOR',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.ORG_CONTENT_SELECTOR
    })}`,
    inputData: {
      name: INPUT_TYPES.ORG_CONTENT_SELECTOR,
      displayName: 'ORG_CONTENT_SELECTOR',
      displayMethodId: INPUT_TYPES.ORG_CONTENT_SELECTOR,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.PERCENTAGE_DECIMAL]: {
    buttonText: 'PERCENTAGE_DECIMAL',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.PERCENTAGE_DECIMAL
    })}`,
    inputData: {
      name: INPUT_TYPES.PERCENTAGE_DECIMAL,
      displayName: 'PERCENTAGE_DECIMAL',
      displayMethodId: INPUT_TYPES.PERCENTAGE_DECIMAL,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.PHONE_NUMBER]: {
    buttonText: 'PHONE_NUMBER',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.PHONE_NUMBER
    })}`,
    inputData: {
      name: INPUT_TYPES.PHONE_NUMBER,
      displayName: 'PHONE_NUMBER',
      displayMethodId: INPUT_TYPES.PHONE_NUMBER,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.POSITIVE_INT]: {
    buttonText: 'POSITIVE_INT',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.POSITIVE_INT
    })}`,
    inputData: {
      name: INPUT_TYPES.POSITIVE_INT,
      displayName: 'POSITIVE_INT',
      displayMethodId: INPUT_TYPES.POSITIVE_INT,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.PRICE_DECIMAL]: {
    buttonText: 'PRICE_DECIMAL',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.PRICE_DECIMAL
    })}`,
    inputData: {
      name: INPUT_TYPES.PRICE_DECIMAL,
      displayName: 'PRICE_DECIMAL',
      displayMethodId: INPUT_TYPES.PRICE_DECIMAL,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.PRICE_INT]: {
    buttonText: 'PRICE_INT',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.PRICE_INT
    })}`,
    inputData: {
      name: INPUT_TYPES.PRICE_INT,
      displayName: 'PRICE_INT',
      displayMethodId: INPUT_TYPES.PRICE_INT,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.VIDEO_URL]: {
    buttonText: 'VIDEO_URL',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.VIDEO_URL
    })}`,
    inputData: {
      name: INPUT_TYPES.VIDEO_URL,
      displayName: 'VIDEO_URL',
      displayMethodId: INPUT_TYPES.VIDEO_URL,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  },
  [INPUT_TYPES.ZIP]: {
    buttonText: 'ZIP',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.ZIP
    })}`,
    inputData: {
      name: INPUT_TYPES.ZIP,
      displayName: 'ZIP',
      displayMethodId: INPUT_TYPES.ZIP,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          children: [
            { name: 'latitude' },
            { name: 'longitude' },
            { name: 'addressZip' },
            { name: 'addressState' }
          ]
        }
      }
    }
  },
  [INPUT_TYPES.ORGANIZATION_SELECTOR]: {
    buttonText: 'ORGANIZATION_SELECTOR',
    type: componentTypes.formInput,
    path: `${generateLinkPath(paths.playground.testing.inputs, {
      componentIdentifier: INPUT_TYPES.ORGANIZATION_SELECTOR
    })}`,
    inputData: {
      name: INPUT_TYPES.ORGANIZATION_SELECTOR,
      displayName: 'ORGANIZATION_SELECTOR',
      displayMethodId: INPUT_TYPES.ORGANIZATION_SELECTOR,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      readOnly: false,
      displaySortOrder: 1,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  }
};
