import Link from './Link';

interface TooltipLinkProps {
  to: string;
  target?: string;
  children?: React.ReactNode;
}

const TooltipLink = ({ to, target, children }: TooltipLinkProps) => {
  return (
    <Link sx={{ color: 'warning.light' }} to={to} target={target}>
      {children}
    </Link>
  );
};

export default TooltipLink;
