import { useMemo, useState, useCallback } from 'react';
import { flow, isEmpty } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Typography, IconButton, Grid, Box, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  Delete as DeleteIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import RenderOrganizationSelector from 'src/components/ReduxForm/RenderOrganizationSelector';
import Table from 'src/components/Table';
import ConfirmationModal from 'src/components/Modal/ConfirmationModal';

import { SEARCH_FORM } from './constants';
import { getPrePayAccountsByOrganizationId } from './queries';
import { deletePrePayAccountById } from './mutations';
import PrePaymentConfiguration from './PrePaymentConfiguration';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  cardSubheading: {
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: theme.spacing(4)
  },
  createButtonContainer: {
    marginLeft: theme.spacing(2)
  },
  emptyPrePaymentAccounts: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const pageText = () => ({
  cardHeading: t('admin:prePaymentOrganization.cardHeading'),
  subHeading: t('admin:prePaymentOrganization.subHeading'),

  submitButton: t('admin:prePaymentOrganization.submitButton'),
  clearButton: t('admin:prePaymentOrganization.clearButton'),
  successSnackMessage: t('admin:prePaymentOrganization.successSnackMessage'),
  errorSnackMessage: t('admin:prePaymentOrganization.errorSnackMessage'),
  emptyPrePaymentAccounts: t('admin:prePaymentOrganization.empty'),
  removeBody: t('admin:prePaymentOrganization.removeBody'),
  cancelButton: t('admin:prePaymentOrganization.cancelButton'),
  removeButton: t('admin:prePaymentOrganization.removeButton'),
  modalHeading: t('admin:prePaymentOrganization.modalHeading'),
  createPrepaymentaccount: t(
    'admin:prePaymentOrganization.createPrepaymentaccount'
  )
});

const OrganizationPrePayment = props => {
  const {
    classes,
    prePaymentsAccounts = {},
    deletePrePayAccountById,
    selectedOrgValue
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const text = useMemo(() => pageText(), []);
  const [removePaymentAccountId, setRemovePaymentAccountId] = useState();
  const [openCreatePrepayment, setOpenCreatePrepayment] = useState(false);

  const openNewPrepayment = () => setOpenCreatePrepayment(true);

  const closeNewPrepayment = () => {
    setOpenCreatePrepayment(false);
  };

  const toggleModal = useCallback(
    id => {
      setRemovePaymentAccountId(id);
    },
    [removePaymentAccountId]
  );

  const handleRefresh = () => {
    prePaymentsAccounts.refetch();
  };

  const handleDeletePrePaymentAccount = async () => {
    try {
      await deletePrePayAccountById({
        variables: { id: removePaymentAccountId }
      });
      enqueueSnackbar(text.successSnackMessage, {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(text.errorSnackMessage, {
        variant: 'error'
      });
    }
    setRemovePaymentAccountId(null);
  };

  const getPrepaymentAccountTableColumnSchema = () => [
    {
      columnName: t('admin:prePaymentOrganization.table.initialAmount'),
      accessor: 'initialAmount',
      key: 'initialAmount',
      type: 'price_decimal'
    },
    {
      columnName: t('admin:prePaymentOrganization.table.balanceAmount'),
      accessor: 'balanceAmount',
      key: 'balanceAmount',
      type: 'price_decimal'
    },
    {
      columnName: t('admin:prePaymentOrganization.table.effectiveFrom'),
      accessor: 'effectiveFrom',
      key: 'effectiveFrom',
      type: 'date_utc'
    },
    {
      columnName: t('admin:prePaymentOrganization.table.effectiveUntil'),
      accessor: 'effectiveUntil',
      key: 'effectiveUntil',
      type: 'date_utc'
    },
    {
      columnName: t('admin:prePaymentOrganization.table.createdAt'),
      accessor: 'createdAt',
      key: 'createdAt',
      type: 'date_utc'
    },
    {
      columnName: t('admin:prePaymentOrganization.table.deletedAt'),
      accessor: 'deletedAt',
      key: 'deletedAt',
      type: 'date_utc'
    },
    {
      key: 'delete',
      CellComponent: ({ row }) => {
        const { id, deletedAt } = row;

        if (deletedAt) return null;

        return (
          <IconButton
            aria-label="Delete"
            onClick={() => toggleModal(id)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        );
      }
    }
  ];

  const prepaymentAccounts =
    prePaymentsAccounts?.getPrePayAccountsByOrganizationId?.sort((x, y) => {
      const xr = new Date(x.effectiveFrom);
      const yr = new Date(y.effectiveFrom);
      return xr - yr;
    });

  return (
    <>
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle2" className={classes.cardSubheading}>
          {text.subHeading}
        </Typography>
        <IconButton
          aria-label="refresh"
          size="large"
          disabled={!selectedOrgValue}
          onClick={handleRefresh}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={8}>
          <Field name="organizationId" component={RenderOrganizationSelector} />
        </Grid>
        <Grid item xs={4}>
          <div className={classes.createButtonContainer}>
            <Button
              color="primary"
              variant="contained"
              onClick={openNewPrepayment}
              disabled={!selectedOrgValue}
            >
              {text.createPrepaymentaccount}
            </Button>
          </div>
        </Grid>
      </Grid>

      {isEmpty(prepaymentAccounts) ? (
        selectedOrgValue && (
          <Grid container xs={12} justifyContent="center">
            <Grid item className={classes.emptyPrePaymentAccounts}>
              {text.emptyPrePaymentAccounts}
            </Grid>
          </Grid>
        )
      ) : (
        <Table
          columnSchema={getPrepaymentAccountTableColumnSchema()}
          rows={prepaymentAccounts}
          loading={prePaymentsAccounts?.loading}
        />
      )}
      <ConfirmationModal
        icon={<DeleteIcon />}
        cancelButtonText={text.cancelButton}
        confirmButtonText={text.removeButton}
        title={text.modalHeading}
        open={Boolean(removePaymentAccountId)}
        onClose={() => toggleModal(null)}
        onConfirm={handleDeletePrePaymentAccount}
      >
        {text.removeBody}
      </ConfirmationModal>
      <PrePaymentConfiguration
        open={openCreatePrepayment}
        close={closeNewPrepayment}
        prepaymentAccounts={prepaymentAccounts}
      />
    </>
  );
};

const mapStateToProps = state => {
  const formValues = getFormValues(SEARCH_FORM)(state);

  return {
    selectedOrgValue: formValues?.organizationId?.value
  };
};

export default flow(
  reduxForm({
    form: SEARCH_FORM,
    destroyOnUnmount: true
  }),
  graphql(getPrePayAccountsByOrganizationId, {
    name: 'prePaymentsAccounts',
    options: ownProps => {
      return {
        variables: {
          organizationId: ownProps?.selectedOrgValue
        },
        fetchPolicy: 'no-cache'
      };
    },
    skip: ownProps => {
      return !ownProps?.selectedOrgValue;
    }
  }),
  graphql(deletePrePayAccountById, {
    name: 'deletePrePayAccountById',
    options: {
      refetchQueries: ['getPrePayAccountsByOrganizationId'],
      awaitRefetchQueries: true
    }
  }),
  connect(mapStateToProps),
  withStyles(styles)
)(OrganizationPrePayment);
