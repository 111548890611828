import {
  get,
  transform,
  find,
  includes,
  every,
  last,
  isNil,
  findKey,
  flow,
  some,
  sortBy,
  filter
} from 'lodash';

import Logger from 'src/common/Logger';
import { googleCreativeTypesBySlug, channelCodes } from 'src/common/adChannels';

import { getJwtAttribute } from 'src/Auth/common';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { PUBLISHER_FACETS } from 'src/components/AdPreview/Constants';

const HIDDEN_FIELD_METHOD_IDS = [
  'facebook_page_id',
  'facebook_instagram_actor_id'
];

export const getChannelKeys = (channelsArray = []) => {
  return channelsArray.map(channel =>
    get(channel, 'prototype.unit.channelCode')
  );
};

export const getOneOfEachChannelKeys = (channelsArray = []) => {
  const channels = new Set(channelsArray);

  return [...channels].join(',');
};

export const getAllChannelCodes = program => {
  return (program?.channels || []).reduce((channels, channel) => {
    return new Set([...channels, ...(channel?.channels || [])]);
  }, new Set());
};

export const hasFacebookChannel = program =>
  getAllChannelCodes(program).has(channelCodes.facebook);

export const hasGoogleChannel = program =>
  getAllChannelCodes(program).has(channelCodes.google);

export const hasInstaChannel = program =>
  getAllChannelCodes(program).has(channelCodes.instagram);

export const hasTokTokChannel = program =>
  getAllChannelCodes(program).has(channelCodes.tiktok);

export const checkBlueprintHasFacebook = userInputSections => {
  // search inputs to find any that refrence facebook
  return some(userInputSections, section =>
    some(section?.inputFields || [], [
      'displayMethodId',
      INPUT_TYPES.FB_PAGE_ID
    ])
  );
};

export const getCreativeType = (channelsArray = []) => {
  const creativeTypes = [];

  channelsArray.forEach(channel => {
    creativeTypes.push(
      `${get(channel, 'prototype.unit.channelCode')}-${get(
        channel,
        'prototype.code'
      )}`
    );
  });

  // Note: We're flattening this down to just the first icon found because
  //       showing more than one confuses our users.
  return creativeTypes && [creativeTypes[0]];
};

export const formatBlueprintsForIcon = blueprints => {
  return transform(
    blueprints,
    (result, value) => {
      result.push({
        id: get(value, 'id'),
        adIconTypes: getCreativeType(get(value, 'blueprint.publishers')),
        architectureId: get(value, 'architectureId'),
        channels: getChannelKeys(get(value, 'blueprint.publishers')),
        productIds: [get(value, 'id')],
        subTitle: get(value, 'description'),
        title: get(value, 'name')
      });
    },
    []
  );
};

export const formatBlueprintIconsById = blueprintIcons => {
  return transform(
    blueprintIcons,
    (result, value) => {
      // eslint-disable-next-line no-param-reassign
      result[value.id] = value;
    },
    {}
  );
};

export const extractProductFromArchitecture = (architecture, productId) => {
  const products = get(architecture, 'products') || [];
  return products.find(o => o.id === productId);
};

const filterPaymentPlans = blueprint => {
  return {
    ...blueprint,
    paymentPlans: {
      purchaseOffers: filter(get(blueprint, 'offers', []), {
        type: 'purchase',
        isActive: true
      }),
      subscriptionOffers: filter(get(blueprint, 'offers', []), {
        type: 'subscription',
        isActive: true
      }),
      offers: get(blueprint, 'offers', [])
    }
  };
};

export const contentSelectable = (architecture, blueprint) => {
  return architecture?.isContentSelectable && blueprint?.requiresContent;
};

export const hasCatalog = (architecture, blueprint) => {
  return (
    !isNil(get(architecture, 'catalog')) && get(blueprint, 'requiresContent')
  );
};

// Field is considered hidden if it has a specific displayMethodId or has isHidden flag set to true
export const isHiddenField = field => {
  return (
    includes(HIDDEN_FIELD_METHOD_IDS, get(field, 'displayMethodId')) ||
    field?.isHidden
  );
};

const isHiddenSection = section => {
  return every(get(section, 'inputFields'), isHiddenField);
};

const isCollapsed = section => {
  if (!isNil(section?.isCollapsed)) {
    return section?.isCollapsed;
  }

  return every(
    section?.inputFields || [],
    field => !field?.blueprintVariable?.isRequired
  );
};

const formatDisplayParameters = displayParameters => {
  const inputData = displayParameters?.inputData;
  // Feature to filter option inputs by user role
  if (inputData?.filtering?.groupFilter) {
    const options = inputData?.options.filter(option => {
      // if no filters are set include everyone
      if (!option?.metadata?.filtering) {
        return true;
      }

      let allEmpty = true;

      // does any of the filters match?
      // we ONLY do inclusive right now
      // eg. filtering: { 'li_group_role': { include: ['group_admin'] }}
      // english: for any of the filter keys 'role' is the
      // users jwt value included in the allowed values
      const hasSome = some(option?.metadata?.filtering, (value, roleType) => {
        if (value?.include?.length > 0) {
          allEmpty = false;
          const usersRole = getJwtAttribute(roleType);
          return includes(value?.include, usersRole);
        }
        return false;
      });
      // if it's all empty arrays then return everything
      // else return the filtered result
      return allEmpty || hasSome;
    });
    // return the filtered options
    return {
      ...displayParameters,
      inputData: {
        ...inputData,
        options
      }
    };
  }
  return displayParameters;
};

const multipleInputIncludeList = new Set([
  INPUT_TYPES.SINGLE_LINE_STRING,
  INPUT_TYPES.MULTI_LINE_STRING
]);

const checkIsMultipleInput = field => {
  if (
    multipleInputIncludeList.has(field?.displayMethodId) &&
    field?.blueprintVariable?.isArray
  ) {
    return true;
  }
  return false;
};

const formatInputField = field => {
  return {
    ...field,
    ...(isHiddenField(field) && { isHidden: true }),
    displayParameters: formatDisplayParameters(field?.displayParameters),
    friendlyName: field?.blueprintVariable?.friendlyName,
    fieldName: field?.blueprintVariable?.name,
    defaultValue: field?.blueprintVariable?.defaultValue,
    isRequired: field?.blueprintVariable?.isRequired,
    isMultiInput: checkIsMultipleInput(field),
    isExpressionAllowed: field?.blueprintVariable?.isExpressionAllowed
  };
};

const formatDynamicFields = (bp = {}) => {
  return {
    ...bp,
    inputSections: (bp?.inputSections || []).map(section => ({
      ...section,
      ...(isHiddenSection(section) && { isHidden: true }),
      isCollapsed: isCollapsed(section),
      inputFields: section?.inputFields.map(formatInputField)
    }))
  };
};

export const getDynamicFieldFriendlyNamesByFieldName = (bp = {}) => {
  return bp?.inputSections?.reduce(
    (
      acc, // :{ [key: string]: string }
      section // :{ inputFields: { fieldName: string; friendlyName: string }[] }
    ) => {
      const newAcc = { ...acc };
      section?.inputFields?.forEach(field => {
        // field:{ fieldName: string; friendlyName: string }
        newAcc[field.fieldName] = field.friendlyName;
      });
      return newAcc;
    },
    {}
  );
};

export const getDynamicFieldDisplayMethodByFieldName = (bp = {}) => {
  return bp?.inputSections?.reduce(
    (
      acc, // :{ [key: string]: string }
      section // :{ inputFields: { fieldName: string; displayMethodId: string }[] }
    ) => {
      const newAcc = { ...acc };
      section?.inputFields?.forEach(field => {
        // field:{ fieldName: string; displayMethodId: string }
        if (!field?.blueprintVariable?.isArray) {
          newAcc[field.fieldName] = field.displayMethodId;
        }
      });
      return newAcc;
    },
    {}
  );
};

const addLeadFormBlueprintFlag = (bp = {}) => {
  const isLeadFormFacebookBlueprint = !!find(
    bp?.blueprint?.publishers,
    publisher => {
      const isCreative = publisher?.prototype?.unit?.code === 'creative';
      const isFacebookChannelCode =
        publisher?.prototype?.unit?.channelCode === channelCodes.facebook;

      const hasLeadFormReference = find(
        publisher?.publisherFacets,
        publisherFacet => {
          return publisherFacet?.facet?.code === 'lead-form-reference';
        }
      );

      return isCreative && isFacebookChannelCode && hasLeadFormReference;
    }
  );

  return {
    ...bp,
    isLeadFormFacebookBlueprint
  };
};

export const formatBlueprints = (bps = []) => {
  return bps.map(bp => {
    return flow(
      formatDynamicFields,
      filterPaymentPlans,
      addLeadFormBlueprintFlag
    )(bp);
  });
};

export const getDynamicUserInputInitialValues = product => {
  const initialValues = {};

  const dynamicUserInputSections = get(product, `inputSections`, []);

  dynamicUserInputSections.forEach(dynamicUserInputSection => {
    const dynamicUserInputs = get(dynamicUserInputSection, 'inputFields') || [];

    dynamicUserInputs.forEach(dynamicUserInput => {
      const fieldName = get(dynamicUserInput, 'blueprintVariable.name');
      let initialValue = get(dynamicUserInput, 'defaultValue');
      const displayMethodId = dynamicUserInput?.displayMethodId;

      // INPUT_TYPES.FB_REGION_CODE has to have an array initial value or
      // it will crash the app.
      if (!initialValue && displayMethodId === INPUT_TYPES.FB_REGION_CODE) {
        initialValue = get(dynamicUserInput, 'blueprintVariable.isArray')
          ? []
          : null;
      }

      // INPUT_TYPES.ADDRESS & INPUT_TYPES.ZIP need to have an array initial value when isMultiSelect or
      // it will crash the app.
      if (
        !initialValue &&
        (displayMethodId === INPUT_TYPES.ADDRESS ||
          displayMethodId === INPUT_TYPES.ZIP ||
          displayMethodId === INPUT_TYPES.SINGLE_SELECT)
      ) {
        initialValue = dynamicUserInput?.displayParameters?.inputData
          ?.isMultiSelect
          ? []
          : null;
      }

      if (!initialValue && displayMethodId === INPUT_TYPES.FB_AUDIENCE_ID) {
        initialValue = get(dynamicUserInput, 'blueprintVariable.isArray')
          ? []
          : '';
      }

      if (
        multipleInputIncludeList.has(displayMethodId) &&
        dynamicUserInput?.blueprintVariable?.isArray
      ) {
        if (initialValue) {
          try {
            const parsedValue = JSON.parse(initialValue);
            initialValue = parsedValue?.defaultArrayValue || [];
          } catch (error) {
            Logger.error('Failed to parse array initial value');
          }
        } else {
          initialValue = [];
        }
      }

      if (initialValue === undefined || !fieldName) {
        return;
      }

      initialValues[fieldName] = initialValue;
    });
  });

  return initialValues;
};

export const getTemplateData = (blueprint = {}) => {
  // we make some assumptions, must update if these change:
  // 1) blueprints only have one channel e.g. 'FB'
  // 2) blueprints have 1 "publisher" except Video/video-upload
  // 3) video/video-upload has multiple publishers but still only one channel
  // 4) we can just naively merge the video creative templates
  const publishers = blueprint?.blueprint?.publishers ?? [];
  const channel = get(last(publishers), 'prototype.unit.channelCode');

  const creativeType = get(last(publishers), 'prototype.code');

  const creativeTemplate = get(
    blueprint,
    'blueprint.publishers[0].publisherFacets[0].parameterValues'
  );

  // Flatten the publisher facets into an object with the key being the facet
  // code (creative-main, carousel-card, call-to-action, etc.)
  const publisherFacets = publishers
    .reduce((all, pub) => [...all, ...get(pub, 'publisherFacets', [])], []) // merge all publisher facets
    .reduce((accumulator, currentValue /* , currentIndex, arr */) => {
      const facetCode = get(currentValue, 'facet.code');

      accumulator[facetCode] = currentValue;

      return accumulator;
    }, {});

  return {
    channel,
    creativeType,
    creativeTemplate,
    publisherFacets
  };
};

export const getGoogleTemplateData = (blueprint = {}) => {
  const publishers = blueprint?.blueprint?.publishers ?? [];
  const channel = get(last(publishers), 'prototype.unit.channelCode');

  let creativeType = 'no-valid-google-creative-type';

  // Iterate through the publishers looking for a google-specific one.
  for (let i = 0; i < publishers.length; i++) {
    const publisher = publishers[i];
    const code = publisher?.prototype?.code;

    // Stop as soon as we've found a creative type
    if (googleCreativeTypesBySlug[code]) {
      creativeType = code;
      break;
    }
  }

  // Flatten the publisher facets into an object with the key being the facet code
  const publisherFacets = publishers
    .reduce((all, pub) => [...all, ...get(pub, 'publisherFacets', [])], []) // merge all publisher facets
    .reduce((accumulator, currentValue) => {
      const facetCode = currentValue?.facet?.code;

      // If the currentValue has publisherId this means it's a reference to another
      // publisher so we will need to modify its parameterValues.
      if (findKey(currentValue, 'publisherId')) {
        // Get reference id for the referenced publisher
        const refPublisherId = currentValue?.parameterValues?.publisherId;

        // Find referenced publisherFacets
        const refPublisherFacets = find(publishers, {
          id: refPublisherId
        })?.publisherFacets;

        if (!refPublisherFacets) {
          Logger.error(
            'getGoogleTemplateData unable to find reference publisher facet'
          );
          accumulator[facetCode] = currentValue;
          return accumulator;
        }

        const refAssetFacet = find(refPublisherFacets, facet => {
          // For images the parameterValues will always be assetUrl
          return findKey(facet, 'assetUrl');
        });

        if (refAssetFacet) {
          const currentValueCopy = { ...currentValue };

          // Add new parameterValues keyed off of facetCode this
          // is what we will key off of in the template.
          const newParameterValues = {
            [facetCode]: refAssetFacet?.parameterValues?.assetUrl
          };
          currentValueCopy.parameterValues = newParameterValues;

          accumulator[facetCode] = currentValueCopy;
          return accumulator;
        }

        return accumulator;
      }

      accumulator[facetCode] = currentValue;
      return accumulator;
    }, {});

  // extract the parameterValues from the publisherFacets to create the template
  // example:
  // {
  //     description: '{{__var__.description_1}}',
  //     ...
  // }
  const creativeTemplate = Object.keys(publisherFacets).reduce(
    (accum, current) => {
      return {
        ...accum,
        ...publisherFacets[current].parameterValues
      };
    },
    {}
  );

  return {
    channel,
    creativeType,
    creativeTemplate,
    publisherFacets
  };
};

export const getSingleProductCarouselTemplates = (blueprint = {}) => {
  const creativeTemplates = get(
    blueprint,
    'blueprint.publishers[0].publisherFacets'
  );

  // there is no good way to order how the publisher facets come back so we are sorting on the slug
  const creativeTemplatesSorted = sortBy(creativeTemplates, 'slug');

  const publisherFacets = creativeTemplatesSorted.reduce(
    (accumulator, currentValue) => {
      const facetCode = currentValue?.facet?.code;

      if (facetCode === PUBLISHER_FACETS.carouselCard) {
        accumulator.push(currentValue.parameterValues);
      }

      return accumulator;
    },
    []
  );

  return publisherFacets;
};
