import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';

import { Grid } from '@mui/material';

import Loading from 'src/components/Loading';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import { KpiInput } from 'src/generated/gql/graphql';
import { useQuery } from '@apollo/client';
import { useRef } from 'react';
import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { useLocation } from 'react-router-dom';
import KpiTile from './KpiTile';
import { InsightData, insightsQuery } from './queries';

const formatKpis = (insights: InsightData[] = []) =>
  insights.map(({ summary, kpi }) => {
    return {
      channelCode: kpi.channelCode,
      friendlyName: kpi.friendlyName,
      title: kpi.slug,
      value: summary.sum
    };
  });

export interface KpiProps {
  demoInsights?: InsightData[];
  programIds: string[];
  kpis: KpiInput[];
  sourcePage: 'dashboard' | 'programDetails';
}

const Kpi = ({ demoInsights, programIds, kpis, sourcePage }: KpiProps) => {
  const didLogMetrics = useRef(false);
  const { pathname } = useLocation();

  const { data, loading, error } = useQuery(insightsQuery, {
    skip: isEmpty(programIds),
    variables: {
      programIds,
      kpis
    }
  });

  if (loading) return <Loading />;

  if (error) {
    return (
      <ErrorMessage>
        <Trans i18nKey="programPerf:kpi.error">
          There was an error fetching metrics data
        </Trans>
      </ErrorMessage>
    );
  }

  let formattedKpis = formatKpis(data?.insights) || [];

  // If we got this far, we must've finished loaded our metrics.
  // Doing this before our demo insights means we won't log demo data.
  if (!didLogMetrics.current) {
    Instrumentation.logEvent(Events.KpiDataLoaded, {
      programIds,
      hasMetrics: formattedKpis.some(kpi => kpi.value > 0),
      sourcePage,
      pathname
    });

    // IMPORTANT! We only want to log metrics once per load.
    didLogMetrics.current = true;
  }

  // Override with demo insights if they've been set.
  if (demoInsights) {
    formattedKpis = formatKpis(demoInsights);
  }

  return (
    <>
      {formattedKpis.length > 0 && (
        <Grid container spacing={3}>
          {formattedKpis.map(kpi => {
            return <KpiTile {...kpi} key={kpi.title} />;
          })}
        </Grid>
      )}
    </>
  );
};

export default Kpi;
