import { gql } from 'src/generated/gql';

export const registerPlgUser = gql(`
  mutation registerPlgUser($input: RegisterPlgUserInput!) {
    registerPlgUser(input: $input) {
      userId
      groupId
      userGroupRole
      organizationId
      organizationUserRole
    }
  }
`);
