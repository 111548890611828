import React, { useEffect, useRef } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { useAsyncUrlValidation } from 'src/components/ReduxForm/RenderLinkUrl/helpers';
import { PreviewUrlButton } from 'src/components/ReduxForm/RenderLinkUrl/PreviewUrlButton';
import RenderTextField from '../RenderTextField';

interface RenderLinkUrlProps extends WrappedFieldProps {
  disabled?: boolean;
  readOnly?: boolean;
  previewUrl: boolean;
  change: (form: string, field: string, value: any) => void;
  noCrossDomainRedirects?: boolean;
  isHookForm?: boolean;
}

const RenderLinkUrl = (props: RenderLinkUrlProps) => {
  const {
    input,
    meta,
    disabled,
    readOnly = false,
    previewUrl,
    noCrossDomainRedirects,
    isHookForm,
    ...RenderTextFieldProps
  } = props;
  const { value, name } = input;

  const { error, dispatch, form } = meta;

  const { validateUrl } = useAsyncUrlValidation(
    dispatch,
    form,
    name,
    noCrossDomainRedirects
  );

  // Due to some other async stuff our noCrossDomainRedirects prop may change as the program
  // is updated. Since we only validate on input change that means the validation may be out of sync.
  // This effect will re-run validation if the noCrossDomainRedirects prop changes.
  const lastKnownCrossDomainRedirectValueForEffect = useRef(
    noCrossDomainRedirects
  );
  useEffect(() => {
    if (
      lastKnownCrossDomainRedirectValueForEffect.current !==
        noCrossDomainRedirects &&
      value?.trim() !== '' &&
      !isHookForm
    ) {
      validateUrl.current(value);
      lastKnownCrossDomainRedirectValueForEffect.current =
        noCrossDomainRedirects;
    }
  }, [noCrossDomainRedirects, validateUrl, value, isHookForm]);

  return (
    <>
      <RenderTextField
        {...RenderTextFieldProps}
        meta={meta}
        // When we're in the context of a link URL input we don't want emojis
        blockEmojiPicker
        input={{
          ...input,
          onChange: (event: React.SyntheticEvent) => {
            if (!isHookForm) {
              const updatedUrl = (event.target as HTMLInputElement).value;
              validateUrl.current(updatedUrl);
            }
            props.input.onChange(event);
          }
        }}
      />
      <PreviewUrlButton
        value={value}
        previewUrl={previewUrl}
        disabled={disabled}
        error={error}
        readOnly={readOnly}
      />
    </>
  );
};

export default RenderLinkUrl;
