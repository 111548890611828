import { useCallback } from 'react';
import {
  OperationVariables,
  TypedDocumentNode,
  useApolloClient
} from '@apollo/client';

/**
 * A hook for using a gql query outside of the React component lifecycle.
 *
 * Types are inferred from the document node you provide. Most likely you do
 * not need to manually provide types to this function!
 * @param query The gql query you want to invoke.
 */
export function useInvokableQuery<
  TQuery,
  TVariables extends OperationVariables = OperationVariables
>(query: TypedDocumentNode<TQuery, TVariables>) {
  const client = useApolloClient();
  return useCallback(
    (variables: TVariables) =>
      client.query<TQuery, TVariables>({
        query,
        variables,
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache'
      }),
    [query, client]
  );
}
