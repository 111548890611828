import Numeral from 'numeral';
import { isBlank } from 'src/common/strings';

export const formatNumber = (price: string | number) => {
  return Numeral(price).format('0,0');
};

export const formatPrice = (price: string | number) => {
  return Numeral(price).format('$0,0');
};

export const formatPriceWithCents = (price: string | number) => {
  return Numeral(price).format('$0,0.00');
};

export const formatDailySpend = (price: string | number) => {
  return Numeral(price).format('$0,0.00');
};

export const formatPriceByCents = (price: number) =>
  Numeral(price / 100).format('$0,0');

/**
 * The largest ID using our current backend system of storing IDs as Longs.
 * See Java's Long.MAX_VALUE
 */
const maxId = BigInt('9223372036854775807');

/**
 * Checks to see if the given string is an ID.
 * An ID is defined as any number, specifically a Long on the
 * backend.
 * Note that we must account for Number.MAX_SAFE_INTEGER since
 * Longs can be much larger than that!
 */
export const isId = (possibleId: string): boolean => {
  // Blank strings in bigint are set to 0, but we don't want those
  if (isBlank(possibleId)) {
    return false;
  }

  // Bigint doesn't give us a good way to check if something is
  // a valid bigint besides trying it and maybe failing.
  try {
    const parsedId = BigInt(possibleId);
    return parsedId <= maxId;
  } catch (ex: unknown) {
    // Ignore the error. We purposefully don't care about it.
    // Any error means this was not a valid bigint
    return false;
  }
};
