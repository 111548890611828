/* eslint no-unused-vars: 0, no-restricted-syntax: 0, no-restricted-globals: 0 */
import v from 'validator';
import Handlebars from 'handlebars';
import { t } from 'i18next';
import {
  isNil,
  isString,
  get,
  isEmpty,
  every,
  toString,
  uniq,
  keyBy,
  reduce,
  filter
} from 'lodash';
import {
  isValidNumber as isValidPhoneNumber,
  findPhoneNumbersInText
} from 'libphonenumber-js';

import { channelTypes } from 'src/common/adChannels';
import { dayjs } from 'src/common/dates';
import { UrlValidationResultCode } from 'src/generated/gql/graphql';

export const messages = (props = {}) => {
  return {
    EV_PHONE_NUMBERS_NOT_ALLOWED: t('validations:EV_PHONE_NUMBERS_NOT_ALLOWED'),
    EV_INVALID_PHONE_NUMBER: t('validations:EV_INVALID_PHONE_NUMBER'),
    EV_INVALID_URL: t('validations:EV_INVALID_URL'),
    EV_URIS_NOT_ALLOWED: t('validations:EV_URIS_NOT_ALLOWED'),
    EV_EMOJIS_NOT_ALLOWED: t('validations:EV_EMOJIS_NOT_ALLOWED'),
    EV_CONSECUTIVE_EXCLAMATION_MARKS: t(
      'validations:EV_CONSECUTIVE_EXCLAMATION_MARKS'
    ),
    EV_EXCLAMATION_MARK_NOT_ALLOWED: t(
      'validations:EV_EXCLAMATION_MARK_NOT_ALLOWED'
    ),
    EV_ONLY_ONE_EXCLAMATION_MARK_ALLOWED: t(
      'validations:EV_ONLY_ONE_EXCLAMATION_MARK_ALLOWED'
    ),
    EV_ONLY_TWO_EXCLAMATION_MARKS_ALLOWED: t(
      'validations:EV_ONLY_TWO_EXCLAMATION_MARKS_ALLOWED'
    ),
    EV_ALL_CAPS_WORDS_NOT_ALLOWED: t(
      'validations:EV_ALL_CAPS_WORDS_NOT_ALLOWED'
    ),
    EV_BLANK_VALUE_NOT_ALLOWED: t('validations:EV_BLANK_VALUE_NOT_ALLOWED'),
    validateNoInvalidSymbol: t('validations:validateNoInvalidSymbol'),
    EV_INVALID_SYMBOLS: t('validations:EV_INVALID_SYMBOLS'),
    EV_INVALID_REPEATED_SYMBOL: t('validations:EV_INVALID_REPEATED_SYMBOL'),
    EV_QUESTION_MARK_NOT_ALLOWED: t('validations:EV_QUESTION_MARK_NOT_ALLOWED'),
    validateQuestionMarkLimit: t('validations:validateQuestionMarkLimit', {
      allowedQuestions: props.allowedQuestions
    }),
    EV_ONLY_ONE_QUESTION_MARK_ALLOWED: t(
      'validations:EV_ONLY_ONE_QUESTION_MARK_ALLOWED'
    ),
    EV_ONLY_TWO_QUESTION_MARKS_ALLOWED: t(
      'validations:EV_ONLY_TWO_QUESTION_MARKS_ALLOWED'
    ),
    EV_INVALID_ZIP_CODE: t('validations:EV_INVALID_ZIP_CODE'),
    EV_PUNCTUATION_MUST_BE_FOLLOWED_BY_SPACE: t(
      'validations:EV_PUNCTUATION_MUST_BE_FOLLOWED_BY_SPACE'
    ),
    EV_EMOTICONS_NOT_ALLOWED: t('validations:EV_EMOTICONS_NOT_ALLOWED'),
    EV_INVALID_INT: t('validations:EV_INVALID_INT'),
    EV_INVALID_NUMBER: t('validations:EV_INVALID_NUMBER'),
    EV_MISSING_REQUIRED_VALUE: t('validations:EV_MISSING_REQUIRED_VALUE'),
    EV_NO_CONSECUTIVE_SPACE_BETWEEN_WORDS: t(
      'validations:EV_NO_CONSECUTIVE_SPACE_BETWEEN_WORDS'
    ),
    EV_NO_CROSS_DOMAIN_REDIRECTS: t('validations:EV_NO_CROSS_DOMAIN_REDIRECTS'),

    // validations without backend error messages
    isValidDate: t('validations:isValidDate'),
    validateNoBackToBackSymbols: t('validations:validateNoBackToBackSymbols'),
    validateUniqueField: t('validations:validateUniqueField', {
      fieldSelector: props.fieldSelector,
      notUniqueValue: props.notUniqueValue
    }),
    validateTemplate: t('validations:validateTemplate'),
    minMaxArrayValue: t('validations:minMaxArrayValue', {
      min: props.minArrayValue,
      max: props.maxArrayValue
    }),
    exactArrayValue: t('validations:exactArrayValue', {
      exact: props.exactArrayValue
    }),
    maxValuePercentage: t('validations:maxValuePercentage', {
      max: props.maxValuePercentage
    }),
    validateExclamationMarkLimit: t(
      'validations:validateExclamationMarkLimit',
      {
        allowedExclamations: props.allowedExclamations
      }
    ),

    // backend only validations
    EV_INVALID_EMAIL_ADDRESS: t('validations:EV_INVALID_EMAIL_ADDRESS'),
    EV_INVALID_URI: t('validations:EV_INVALID_URI'),
    EV_INVALID_SLUG: t('validations:EV_INVALID_SLUG'),
    EV_INVALID_SNAKE_CASE: t('validations:EV_INVALID_SNAKE_CASE'),
    EV_UNKNOWN_ERROR: t('validations:EV_UNKNOWN_ERROR'),
    EV_BAD_REQUEST: t('validations:EV_BAD_REQUEST'),
    EV_BAD_REQUEST_INVALID_FIELDS: t(
      'validations:EV_BAD_REQUEST_INVALID_FIELDS'
    ),
    EV_BAD_REQUEST_INVALID_USER_IDENTITY: t(
      'validations:EV_BAD_REQUEST_INVALID_USER_IDENTITY'
    ),
    EV_BAD_REQUEST_TOO_MANY_ITEMS: t(
      'validations:EV_BAD_REQUEST_TOO_MANY_ITEMS'
    ),
    EV_BAD_REQUEST_DUPLICATE: t('validations:EV_BAD_REQUEST_DUPLICATE'),
    EV_BAD_REQUEST_MALFORMED: t('validations:EV_BAD_REQUEST_MALFORMED'),
    EV_BAD_REQUEST_INVALID_BUDGET: t(
      'validations:EV_BAD_REQUEST_INVALID_BUDGET'
    ),
    EV_BAD_REQUEST_INVALID_SCHEDULE: t(
      'validations:EV_BAD_REQUEST_INVALID_SCHEDULE'
    ),
    EV_BAD_REQUEST_INVALID_VARIABLE_VALUE: t(
      'validations:EV_BAD_REQUEST_INVALID_VARIABLE_VALUE'
    ),
    EV_OBJECT_NOT_EDITABLE_TEMPORARILY: t(
      'validations:EV_OBJECT_NOT_EDITABLE_TEMPORARILY'
    ),
    EV_MAX_VALUE_EXCEEDED: t('validations:EV_MAX_VALUE_EXCEEDED'),
    EV_BELOW_MIN_VALUE: t('validations:EV_BELOW_MIN_VALUE'),
    EV_FACEBOOK_URL_NOT_ALLOWED: t('validations:EV_FACEBOOK_URL_NOT_ALLOWED'),
    EV_FAILED_TO_LOAD_URL: t('validations:EV_FAILED_TO_LOAD_URL'),
    EV_EXCEEDS_LENGTH_OF_30: t('validations:EV_EXCEEDS_LENGTH_OF_30'),
    EV_EXCEEDS_LENGTH_OF_40: t('validations:EV_EXCEEDS_LENGTH_OF_40'),
    EV_EXCEEDS_LENGTH_OF_90: t('validations:EV_EXCEEDS_LENGTH_OF_90'),
    EV_OBJECT_NOT_FOUND: t('validations:EV_OBJECT_NOT_FOUND'),
    EV_UNAUTHORIZED_INVALID_KEY: t('validations:EV_UNAUTHORIZED_INVALID_KEY'),
    EV_UNAUTHORIZED_MISSING_HEADERS: t(
      'validations:EV_UNAUTHORIZED_MISSING_HEADERS'
    ),
    EV_UNAUTHORIZED_INVALID_SIGNATURE: t(
      'validations:EV_UNAUTHORIZED_INVALID_SIGNATURE'
    ),
    EV_UNAUTHORIZED_INVALID_SECRET: t(
      'validations:EV_UNAUTHORIZED_INVALID_SECRET'
    ),
    EV_UNAUTHORIZED_EXPIRED_REQUEST: t(
      'validations:EV_UNAUTHORIZED_EXPIRED_REQUEST'
    ),
    EV_UNAUTHORIZED: t('validations:EV_UNAUTHORIZED'),
    EV_INTERNAL_SERVER_ERROR: t('validations:EV_INTERNAL_SERVER_ERROR'),
    EV_RATE_LIMITED: t('validations:EV_RATE_LIMITED'),
    EV_INTERPOLATION_FAILED: t('validations:EV_INTERPOLATION_FAILED'),
    EV_STRING_TOO_SHORT: t('validations:EV_STRING_TOO_SHORT'),
    EV_STRING_TOO_LONG: t('validations:EV_STRING_TOO_LONG'),
    EV_NOT_ENOUGH_ELEMENTS: t('validations:EV_NOT_ENOUGH_ELEMENTS'),
    EV_TOO_MANY_ELEMENTS: t('validations:EV_TOO_MANY_ELEMENTS'),
    EV_INVALID_MLP_OFFER: t('validations:EV_INVALID_MLP_OFFER'),
    EV_INVALID_MLP_PAYMENT_METHOD: t(
      'validations:EV_INVALID_MLP_PAYMENT_METHOD'
    ),
    EV_INVALID_MLP_ORDER_AMOUNT: t('validations:EV_INVALID_MLP_ORDER_AMOUNT'),
    EV_INVALID_MLP_SCHEDULE: t('validations:EV_INVALID_MLP_SCHEDULE'),
    EV_INVALID_MLP_TIER: t('validations:EV_INVALID_MLP_TIER'),
    EV_INVALID_MLP_CATALOG: t('validations:EV_INVALID_MLP_CATALOG'),
    EV_INVALID_MLP_CONTENT_SET: t('validations:EV_INVALID_MLP_CONTENT_SET'),
    EV_INVALID_MLP_LOCATION: t('validations:EV_INVALID_MLP_LOCATION')
  };
};

// I decided to separate these lists as I suspect they will diverge even further as
// we discover more differences
export const INVALID_SYMBOLS = {
  google: ['~', '^', '*', '<', '>', '{', '}', '•', '○', '●', '◎', '…', '‥'],
  facebook: ['~', '^', '<', '>', '{', '}', '•', '○', '●', '◎', '…', '‥']
};

export const VALID_SYMBOLS = {
  google: [
    '.',
    ',',
    '?',
    '!',
    ':',
    ';',
    '-',
    '(',
    ')',
    "'",
    '"',
    '&',
    '/',
    '+',
    '$',
    '%'
  ],
  facebook: [
    '.',
    ',',
    '?',
    '!',
    ':',
    ';',
    '-',
    '(',
    ')',
    "'",
    '"',
    '&',
    '/',
    '+',
    '$',
    '%',
    '*'
  ]
};

export const createStringValidator =
  (predicateFn, errorMsgFn, predicateOpts) =>
  (value, allValues, props, name) => {
    if (!predicateFn(value || '', predicateOpts)) {
      return errorMsgFn(name, value, props, allValues);
    }
  };

// same as createStringValidator above, but doesn't run the validation if the
// string is nil
const createStringValidatorIfNotNil =
  (predicateFn, errorMsgFn, predicateOpts) =>
  (value, allValues, props, name) => {
    if (!isNil(value) && !predicateFn(value, predicateOpts)) {
      return errorMsgFn(name, value, props, allValues);
    }
  };

export const validateLength =
  (min = 0, max) =>
  val => {
    if (!v.isLength(val || '', { min, max })) {
      return `The text length should be ${max ? 'between' : 'at least'} ${min} ${
        max ? `and ${max}` : ''
      } characters`;
    }
    // this checks the byte length of a string to warn against formatted garbage
    if (new Blob([val || '']).size > 255) {
      return `Your text is too long or contains too many special characters. Try shortening the text or removing formatting.`;
    }
  };

export const validateNotBlank = () => val => {
  const error = messages().EV_BLANK_VALUE_NOT_ALLOWED;
  if (isString(val)) {
    return createStringValidator(v.matches, name => error, /\S/)(val);
  }
  if (isNil(val)) {
    return error;
  }
};

export const VALID_UNICODE_CHARS = ['™', '℠', '©', '®'];

export const validateNotEmoji = () => val => {
  // [🇦-🇿] matches country flags which will be a combination of any two of those unicode chars
  const matchedList = val?.match(/\p{Extended_Pictographic}|[🇦-🇿]/gu);

  const invalidValues =
    matchedList?.filter(
      unicodeChar => !VALID_UNICODE_CHARS.includes(unicodeChar)
    ) || [];

  const isInvalid = invalidValues?.length > 0;

  if (isInvalid) {
    return messages().EV_EMOJIS_NOT_ALLOWED;
  }
};

export const validateNotAllCaps = () => val => {
  return createStringValidator(
    v.matches,
    name => messages().EV_ALL_CAPS_WORDS_NOT_ALLOWED,
    /^(?=.*[a-z])(?=.*\w)/
  )(val);
};

export const validateNotConsecutiveExclamationMarks = () => val => {
  return createStringValidator(
    v.matches,
    name => messages().EV_CONSECUTIVE_EXCLAMATION_MARKS,
    /^((?!!{2,}).)*$/
  )(val);
};

export const validateNotBlankIfNotNil = () =>
  createStringValidatorIfNotNil(
    v.matches,
    name => messages().EV_MISSING_REQUIRED_VALUE,
    /\S/
  );

export const validateRequired = value => {
  const msg = messages().EV_MISSING_REQUIRED_VALUE;
  if (isNil(value)) {
    return msg;
  }
  if ((Array.isArray(value) || isString(value)) && isEmpty(value)) {
    return msg;
  }
  if (isString(value) && value.match(/\S/gm) === null) {
    return msg;
  }
};

export const validateArrayMinLength =
  (min = 0) =>
  (valueArray = []) => {
    if (!Array.isArray(valueArray) || min > valueArray.length) {
      return `Select at least ${min} items`;
    }
  };

export const validateArrayMaxLength =
  (max = 0) =>
  (valueArray = []) => {
    if (!Array.isArray(valueArray) || max < valueArray.length) {
      return `You can only select up to ${max} items`;
    }
  };

export const validateZipCode = () =>
  createStringValidator(
    v.isPostalCode,
    (name, value) => messages().EV_INVALID_ZIP_CODE,
    'US'
  );

export const zipValidator = validateZipCode();

export const validateZipCodeArray =
  (min = 0, max) =>
  (valueArray = [], allValues, props, name) => {
    if (
      !Array.isArray(valueArray) ||
      min > valueArray.length ||
      valueArray.length < max
    ) {
      return `You must provide ${max ? 'between' : 'at least'} ${min} ${
        max ? `and ${max}` : ''
      } postal codes`;
    }

    for (const value of valueArray) {
      const result = zipValidator(value);
      if (result) {
        return result;
      }
    }
  };

const isEmail = (value, predicateOpts) => {
  if (!value) {
    return true;
  }
  return v.isEmail(value, predicateOpts);
};

export const validateEmail = () =>
  createStringValidator(isEmail, name => `Enter a valid email address`);

const doesStringContain = seed => value => {
  if (!value) {
    return true;
  }

  return v.contains(value, seed);
};

export const validateStringContains = (
  seed,
  validationMessage = `Must contain ${seed}`
) => createStringValidator(doesStringContain(seed), name => validationMessage);

// This custom function is needed because if we fail on an empty string then
// we will always require all urls to be set even if they aren't required.
export const isURL = value => {
  if (!value?.trim()) {
    return true;
  }
  return v.isURL(value.trim());
};

export const validateUrl = () =>
  createStringValidator(isURL, name => `Invalid URL`);

export const validateUrlWithProtocol = val => {
  if (!val?.trim()) {
    return;
  }

  if (
    !v.isURL(val?.trim(), {
      require_protocol: true,
      require_valid_protocol: true
    })
  ) {
    return "URLs should start with 'https://' or 'http://'";
  }
};

export const isFullyQualifiedDomainNameForOrg = value => {
  if (!value) {
    return true;
  }

  // we don't allow www. in org creation
  const checkForWWW = /(www\.)/;

  return !checkForWWW.test(value) && v.isFQDN(value);
};

export const validateUrlFqdnOrg = () =>
  createStringValidator(
    isFullyQualifiedDomainNameForOrg,
    name => `Please enter a valid URL without 'www.'`
  );

export const isHttpsUrl = value => {
  if (!value) {
    return true;
  }

  return v.isURL(value.trim(), {
    protocols: ['https'],
    require_protocol: true
  });
};

export const validateUrlHasHttps = () =>
  createStringValidator(isHttpsUrl, name => `URLs must start with 'https://'`);

export const nonFacebookLink = value => {
  if (!value || value?.length < 1) {
    return;
  }
  if (
    (value.toLowerCase().includes('facebook.com') ||
      value.toLowerCase().includes('fb.me')) &&
    // they allow facebook gaming links :rolling-eyes:
    !value.toLowerCase().includes('/gaming/')
  ) {
    return 'You cannot run an ad that links to a Facebook page url.';
  }
  if (value.toLowerCase().includes('instagram.com')) {
    return 'You cannot run an ad that links to a Instagram page url.';
  }
};

export const validateNotUrl = () => {
  const isNotUrl = (value, predicateOpts) => {
    return !isURL(value, predicateOpts);
  };
  return createStringValidator(isNotUrl, name => `URLs are not allowed`);
};

export const validateSlug = () => {
  return createStringValidator(
    v.matches,
    name => `Use only lowercase letters, numbers, and hyphens`,
    /^([a-z0-9-])+$/
  );
};

export const validateSlugIfNotNil = () => {
  return createStringValidatorIfNotNil(
    v.matches,
    name => `Use only lowercase letters, numbers, and hyphens`,
    /^([a-z0-9-])+$/
  );
};

export const validateSnakeCase = () => {
  return createStringValidatorIfNotNil(
    v.matches,
    name => 'Use only lowercase letters, numbers, and underscores',
    /^([a-z0-9_])+$/
  );
};

const isPhone = (value, predicateOpts) => {
  if (!value) {
    return true;
  }
  return isValidPhoneNumber(toString(value), 'US');
};

export const validatePhone = () =>
  createStringValidator(
    isPhone,
    name => `Enter a valid phone number (ex: 555-555-5555)`
  );

export const validateNumber = (opts = {}) =>
  createStringValidator(
    v.isFloat,
    name => `Enter a valid number (leave out "$", ",", etc)`,
    opts
  );

export const withinEpsilonOf = (n, targetNum, epsilon = 0.01) =>
  Math.abs(targetNum - n) < epsilon;

export const validateBed = () =>
  createStringValidator(
    str => withinEpsilonOf(Number(str) % 1, 0),
    (name, value) => {
      return 'Enter a valid number for beds';
    }
  );

export const validateBath = () =>
  createStringValidator(
    str => withinEpsilonOf(Number(str) % 0.25, 0),
    (name, value) => {
      // const f = Math.round((value - Math.trunc(Number(value))) * 100) / 100;
      return 'Enter a valid number for bathrooms';
    }
  );

export const validateCurrency = () =>
  createStringValidator(
    v.isCurrency,
    name => `Enter a positive currency amount`,
    {
      symbol: '$',
      requireSymbol: false,
      allowSpaceAfterSymbol: true,
      allowNegatives: false,

      allowDecimal: true,
      requireDecimal: false,
      digitsAfterDecimal: [2]
    }
  );

export const validateCurrencyGreaterThanZero = () =>
  createStringValidator(
    str => {
      const amountString = str.replace(/[^0-9.]/g, '');
      return v.isFloat(amountString, { gt: 0 });
    },
    name => `The amount must be greater than zero`
  );

export const isNumeric = n => {
  // Allow empty values to be considered numeric. Otherwise parseFloat('')
  // will return NaN and causes this function to require that the value be
  // set. That check should be handled by the isRequired code we have
  // elsewhere.
  if (n === '' || n === null || n === undefined) {
    return true;
  }

  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const minValue = min => value =>
  `${value}` && value < min ? `Must be at least ${min}` : undefined;

export const maxValue = max => value =>
  `${value}` && value > max
    ? `Must be less than or equal to ${max}`
    : undefined;

// somewhat confusing but we store percentages as .50 decimal and display them as 50 int
// so we need to validate against the stored value but show the error for the displayed value
// this takes a max as an int
export const maxValuePercentage = max => value =>
  value && value * 100 > max
    ? messages({ maxValuePercentage: max }).maxValuePercentage
    : undefined;

export const validateIsNumber = value =>
  !isNumeric(value) ? messages().EV_INVALID_NUMBER : undefined;

// Used to determine if the value is an empty string or completely unset.
const isUnsetOrEmpty = value => {
  return value === '' || isNil(value);
};

export const validateIsInt = (value = '') => {
  // v.isInt will fail for empty values so we need to not perform validations
  // if the value is empty. Empty is ok for this validation - if a field is
  // required, that's handled by a separate validation.
  if (isUnsetOrEmpty(value)) {
    return undefined;
  }

  return !v.isInt(`${value}`) ? messages().EV_INVALID_INT : undefined;
};

export const isValidDate = value => {
  // Empty value here is ok. The required validation is handled elsewhere.
  if (isUnsetOrEmpty(value)) {
    return undefined;
  }

  return dayjs(value).isValid() ? undefined : messages().isValidDate;
};

export const exactArrayValue =
  exact =>
  (valueArray = []) =>
    valueArray && valueArray.length !== exact
      ? messages({ exactArrayValue: exact }).exactArrayValue
      : undefined;

export const minMaxArrayValue =
  (min, max) =>
  (valueArray = []) =>
    valueArray && (valueArray.length < min || valueArray.length > max)
      ? messages({ minArrayValue: min, maxArrayValue: max }).minMaxArrayValue
      : undefined;

const isArray = value =>
  !Array.isArray(value) ? 'Must be an array' : undefined;

export const validateTemplate = value => {
  const msg = messages().validateTemplate;
  // Don't perform this check on an array for now - later we can add more
  // in-depth validations on individual array values.
  if (Array.isArray(value) || !isString(value)) {
    return;
  }

  try {
    Handlebars.compile(value);
  } catch (e) {
    return msg;
  }
};

// validates uniqueness of a particular field on a collection (only array atm)
export const validateUniqueField =
  fieldSelector =>
  (array = []) => {
    if (array.length < 2) {
      // obviously unique
      return;
    }
    const values = new Set();
    let notUniqueValue = '';
    if (
      !every(array, item => {
        const value = get(item, fieldSelector);
        if (values.has(value)) {
          notUniqueValue = value;
          return false;
        }
        values.add(value);
        return true;
      })
    ) {
      return messages({ fieldSelector, notUniqueValue }).validateUniqueField;
    }
  };

export const validateExclamationMarkLimit =
  (allowedExclamations = 0) =>
  (value = '') => {
    const count = isNil(value) ? 0 : (value.match(/!/g) || []).length;

    if (count > allowedExclamations) {
      return allowedExclamations === 0
        ? messages().EV_EXCLAMATION_MARK_NOT_ALLOWED
        : messages({ allowedExclamations }).validateExclamationMarkLimit;
    }
  };

export const validateQuestionMarkLimit =
  (allowedQuestions = 0) =>
  (value = '') => {
    const count = isNil(value) ? 0 : (value.match(/\?/g) || []).length;

    if (count > allowedQuestions) {
      return allowedQuestions === 0
        ? messages().EV_QUESTION_MARK_NOT_ALLOWED
        : messages({ allowedQuestions }).validateQuestionMarkLimit;
    }
  };

// For a given list of symbols, eg: ['?', '#', '%']
// validate that there are no more than 2 of any symbol in a consecutive row
export const validateNoRepeatSymbols =
  (symbols = [], countToFail = 2, whitelist = []) =>
  (value = '') => {
    const repeatSymbols = [];

    const regex = new RegExp(
      `[\\${symbols.join('\\')}]{${countToFail},}`,
      'gi'
    );

    const matchedStrings = value?.match(regex) || [];

    const invalidSymbols = reduce(
      matchedStrings,
      (acc, string) => {
        // Check if the string is white-listed
        if (whitelist.includes(string)) {
          return acc;
        }

        // Check if string is a repeat symbol
        symbols.forEach(symbol => {
          if (string.includes(symbol.repeat(countToFail))) {
            // Add to repeat symbols list so this can be displayed in the validation message
            repeatSymbols.push(symbol);
          }
        });
        return [...acc, string];
      },
      []
    );

    if (repeatSymbols.length > 0) {
      return `${messages().EV_INVALID_REPEATED_SYMBOL} ${repeatSymbols.join(' ')}`;
    }

    if (invalidSymbols.length > 0) {
      return messages().validateNoBackToBackSymbols;
    }
  };

const defaultPunctuationList = ['.', ',', '!', '?', ';', ':', '–', '(', ')'];

export const validateNoRepeatPunctuation = (value = '') => {
  return validateNoRepeatSymbols(defaultPunctuationList)(value);
};

export const validateNoRepeatPunctuationAllowElipses = (value = '') => {
  const whitelist = ['...'];
  return validateNoRepeatSymbols(defaultPunctuationList, 2, whitelist)(value);
};

export const validateNoRepeatPunctuationFacebook = (value = '') => {
  // accounts for elipses
  const whitelist = ['...', ').', ')?', ')!', '),', ');'];

  return validateNoRepeatSymbols(defaultPunctuationList, 2, whitelist)(value);
};

export const validateNoRepeatPunctuationGoogle = (value = '') => {
  const whitelist = [').', ')?', ')!', '),', ');'];

  return validateNoRepeatSymbols(defaultPunctuationList, 2, whitelist)(value);
};

export const validateNoInvalidSymbols =
  (symbols = []) =>
  (value = '') => {
    const symbolsJoined = symbols.join('');
    const regex = new RegExp(`[${symbolsJoined}]`, 'gi');

    const invalidSymbols = isNil(value) ? [] : value.match(regex) || [];
    const invalidSymbolsCount = invalidSymbols.length;

    if (invalidSymbolsCount > 0) {
      const uniqueSymbols = uniq(invalidSymbols);

      return `${
        invalidSymbolsCount > 1
          ? messages().EV_INVALID_SYMBOLS
          : messages().validateNoInvalidSymbol
      } ${uniqueSymbols.join(' ')}`;
    }
  };

export const validateValidGoogleSymbols = (value = '') => {
  return validateNoInvalidSymbols(INVALID_SYMBOLS.google)(value);
};

export const validateValidFacebookSymbols = (value = '') => {
  return validateNoInvalidSymbols(INVALID_SYMBOLS.facebook)(value);
};

export const validateNoPhoneNumbers = (val = '') => {
  const phoneNumbersInText = findPhoneNumbersInText(val, 'US');

  if (phoneNumbersInText.length > 0) {
    return messages().EV_PHONE_NUMBERS_NOT_ALLOWED;
  }
};

export const validateSpaceAfterPunctuation = (val = '') => {
  const noSpace = /\S?[.!?]\S/g;

  if (
    (val.match(noSpace) || [])
      // allow numbers 5.5 or .3 for example
      .filter(match => isNaN(parseFloat(match))).length > 0
  ) {
    return messages().EV_PUNCTUATION_MUST_BE_FOLLOWED_BY_SPACE;
  }
};

/**
 * We keep running into an issue where all caps words are
 * shorter than our check, but still aren't actually acronyms.
 * A perfect check here would be to have a list of known acronyms
 * and allow those, but I have no idea how to get such a list.
 * So instead we'll just have a list of known all caps words that
 * are not acronyms and thus should be blocked.
 *
 * This is certainly not perfect, and likely needs more to be added,
 * but it's an easy solution.
 */
const shortCapsWordBlockList = new Set([
  'BUY',
  'WIN',
  'NEW',
  'NOW',
  'TRY',
  'HOT',
  'BIG',
  'ACT',
  'VIP',
  'TOP',
  'WOW',
  'OFF',
  'FUN',
  'YES',
  'BUY',
  'GET',
  'BET',
  'JOY',
  'OUT',
  'FREE',
  'NO',
  'GO',
  'SALE',
  'FAST',
  'JOIN',
  'COOL',
  'DEAL',
  'CLICK',
  'HERE'
]);

const shortCapsWordAllowList = new Set(['HELOC']);

const filterAllowed = capsWords => {
  return filter(capsWords, word => !shortCapsWordAllowList.has(word[0]));
};

export const validateNoAllCapWords = (val = '') => {
  const moreThanFiveCapsRegex = /(\b([A-Z]{5,})\b)/g;
  const anyCapsRegex = /[A-Z]+/g;

  const capsWords = filterAllowed([...val.matchAll(anyCapsRegex)]);

  const hasBlockedCapsWord = capsWords.find(word =>
    shortCapsWordBlockList.has(word[0])
  );

  if (
    hasBlockedCapsWord ||
    filterAllowed([...val.matchAll(moreThanFiveCapsRegex)]).length > 0
  ) {
    return messages().EV_ALL_CAPS_WORDS_NOT_ALLOWED;
  }
};

export const validateNoEmoticons = (val = '') => {
  const regex =
    /(^|\s)(:\)|:\(|:-\)|:-\(|:\$|:D|:P|;\)|:O|:\/|:\||:S|:\*|:'\(|:3|:>|:-\/|:-\*|:-D|:-P|:-O|:-S|:\^\)|:o\)|:c\)|XD|:'-\)|;-P|;-D|:-o|>:\(|>:\)|O:\)|O:-\)|B-\)|:b)(?=\s|$)/g;

  if (regex.test(val)) {
    return messages().EV_EMOTICONS_NOT_ALLOWED;
  }
};

export const validateNoConsecutiveSpacesBetweenCharacters = (value = '') => {
  const regex = /\s{2,}/g;

  // Only validates spaces between characters, not leading or trailing spaces
  if (regex.test(value.trim())) {
    return messages().EV_NO_CONSECUTIVE_SPACE_BETWEEN_WORDS;
  }
};

// WARNING: This validator needs to receive the response from the `validateUrl` query.
// It is does not validate the URL itself!
export const validateNoCrossDomainRedirectsUrl = validationResult => {
  if (validationResult === UrlValidationResultCode.CrossDomainRedirect) {
    return messages().EV_NO_CROSS_DOMAIN_REDIRECTS;
  }
};

export const validationsByType = {
  boolean: [],
  any_number: [validateIsNumber],
  date_utc: [isValidDate],
  facebook_audience_id: [],
  image_url: [validateUrl()],
  link_url: [validateUrl(), maxValue(1000)],
  positive_integer: [validateIsNumber, validateIsInt, minValue(0)],
  positive_number: [validateIsNumber, minValue(0)],
  price_decimal: [validateIsNumber, minValue(0)],
  price_integer: [validateIsNumber, validateIsInt, minValue(0)],
  single_line_string: [],
  video_url: [validateUrl()],
  zip_code: [],
  phone_number: [validatePhone()],
  multi_line_string: [],
  facebook_region_code: [],
  radio_select: [],
  integer_slider: [validateIsNumber, validateIsInt]
};

const facebookValidationsByType = {
  link_url: [nonFacebookLink]
};

const googleValidationsByType = (type, field) => {
  const typeValidationMap = {
    link_url: [validateUrlWithProtocol]
  };
  const headlinesRegex = /(Long)?\s?Headline\s?\d*$/;

  const validations = typeValidationMap[type] || [];

  // Note: Until we have publisher or preview level validations we will have to have these field
  //       level validations based on display name. This will not account for the cases where
  //       a blueprint joins data together for an ad creative.
  if (headlinesRegex.test(field.displayName)) {
    validations.push(validateExclamationMarkLimit(0));
  }

  return validations;
};

const getValidationsByType = type => {
  return get(validationsByType, type, []);
};

export const getValidationsByDynamicField = (field, channels) => {
  const validations = [];

  const type = get(field, 'displayMethodId');

  // custom channel overrides
  let channelSpecificValidations = [];
  if (channels) {
    if (channels.includes(channelTypes.facebook)) {
      channelSpecificValidations = [
        ...channelSpecificValidations,
        ...(facebookValidationsByType[type] || [])
      ];
    }
    if (channels.includes(channelTypes.google)) {
      channelSpecificValidations = [
        ...channelSpecificValidations,
        ...googleValidationsByType(type, field)
      ];
    }
  }

  return [
    ...validations,
    ...getValidationsByType(type),
    ...channelSpecificValidations
  ];
};

export const isHexColor = value => {
  // any integer from 0 to 9 and any letter from A to F
  const hexColor = /^#[0-9A-F]{6}$/i;

  return hexColor.test(value) ? undefined : 'Please enter a valid HEX color';
};

export const fieldArrayFixDumbReduxFormError = () => {
  // this forces the fieldarray to re-run it's validations
  // if this isn't here deleted children's validations persist
  // making life horrible
};

export const isTodayOrAfter = value => {
  const today = dayjs().startOf('day');
  const selectedDate = dayjs(value).startOf('day');

  return today.isSameOrBefore(selectedDate)
    ? false
    : 'Date cannot be before today';
};

export const validationOptions = [
  {
    name: 'Not URL',
    value: 'NOT_URL',
    method: validateNotUrl
  },
  {
    name: 'Not Emojis',
    value: 'NOT_EMOJI',
    method: validateNotEmoji
  },
  {
    name: 'Not All Caps',
    value: 'NOT_ALL_CAPS',
    method: validateNotAllCaps
  },
  {
    name: 'No Consecutive Exclamation Marks',
    value: 'NOT_CONSECUTIVE_EXCLAMATION_MARKS',
    method: validateNotConsecutiveExclamationMarks
  },
  {
    name: 'Not Blank',
    value: 'NOT_BLANK',
    method: validateNotBlank
  },
  {
    name: 'Not Exclamation Marks',
    value: 'NOT_EXCLAMATION_MARKS',
    method: () => validateExclamationMarkLimit(0)
  },
  {
    name: 'Allow 1 Exclamation Mark',
    value: 'NOT_EXCLAMATION_MARKS_1',
    method: () => validateExclamationMarkLimit(1)
  },
  {
    name: 'Allow 2 Exclamation Marks',
    value: 'NOT_EXCLAMATION_MARKS_2',
    method: () => validateExclamationMarkLimit(2)
  },
  {
    name: 'Valid Google Symbols',
    value: 'VALID_GOOGLE_SYMBOLS',
    method: () => validateValidGoogleSymbols
  },
  {
    name: 'Not Question Mark',
    value: 'NOT_QUESTION_MARK',
    method: () => validateQuestionMarkLimit(0)
  },
  {
    name: 'Allow 1 Question Mark',
    value: 'NOT_QUESTION_MARK_1',
    method: () => validateQuestionMarkLimit(1)
  },
  {
    name: 'Allow 2 Question Mark',
    value: 'NOT_QUESTION_MARK_2',
    method: () => validateQuestionMarkLimit(2)
  },
  {
    name: 'Valid Zip Code',
    value: 'VALID_ZIP_CODE',
    method: () => validateZipCode()
  },
  {
    name: 'Valid Email',
    value: 'VALID_EMAIL',
    method: () => validateEmail()
  },
  {
    name: 'Valid URL',
    value: 'VALID_URL',
    method: () => validateUrl()
  },
  {
    name: 'Valid Slug',
    value: 'VALID_SLUG',
    method: () => validateSlug()
  },
  {
    name: 'Valid Snake Case',
    value: 'VALID_SNAKE_CASE',
    method: () => validateSnakeCase()
  },
  {
    name: 'Valid Phone',
    value: 'VALID_PHONE',
    method: () => validatePhone()
  },
  {
    name: 'Valid Number',
    value: 'VALID_NUMBER',
    method: () => validateNumber()
  },
  {
    name: 'Valid Date',
    value: 'VALID_DATE',
    method: () => isValidDate
  },
  {
    name: 'No Phone Numbers',
    value: 'NO_PHONE_NUMBERS',
    method: () => validateNoPhoneNumbers
  },
  {
    name: 'Space After Punctuation',
    value: 'SPACE_AFTER_PUNCTUATION',
    method: () => validateSpaceAfterPunctuation
  },
  {
    name: 'No All Caps Words',
    value: 'NO_ALL_CAP_WORDS',
    method: () => validateNoAllCapWords
  }
];

/**
 * A constant to access validation values by value.
 * This way you can do things like validationValues.NOT_URL and get the data.
 */
export const validationsByValue = keyBy(validationOptions, 'value');

export const getExtraValidationRules = (extraValidationRules = []) => {
  const newRules = extraValidationRules.reduce((rules, rule) => {
    const validationRule = validationOptions.find(
      option => option.value === rule
    );
    if (validationRule) {
      rules.push(validationRule?.method?.());
    }
    return rules;
  }, []);

  return newRules;
};
