import { CSSProperties } from 'react';
import { Box } from '@mui/material';

import { Channel, channelCodes } from 'src/common/adChannels';

import ChannelIcon from './ChannelIcon';

interface GroupedChannelIconProps {
  /**
   * The channel to render.
   *
   * Note this should really just be `Channel` but since we
   * gracefully handle unknown channels (by rendering null)
   * we allow `| string` to make it easier to use.
   */
  channel: Channel | string | undefined;
}

const iconDropShadow = 'drop-shadow(0px 4px 5px rgba(0,0,0,0.25)';

const fbIconStyle: CSSProperties = {
  transform: 'translateX(4px) rotate(-28deg)',
  filter: iconDropShadow
};

const instaIconStyle: CSSProperties = {
  transform: 'rotate(28deg)',
  filter: iconDropShadow
};

const GroupedChannelIcon = ({ channel }: GroupedChannelIconProps) => {
  switch (channel) {
    case channelCodes.facebook:
      return (
        <Box component="span" key={channel} sx={{ display: 'flex' }}>
          <ChannelIcon size={24} channel={channel} style={fbIconStyle} />
          <ChannelIcon
            channel={channelCodes.instagram}
            size={24}
            style={instaIconStyle}
          />
        </Box>
      );

    default:
      return (
        <ChannelIcon
          key={channel}
          channel={channel}
          size={24}
          style={{ filter: iconDropShadow }}
        />
      );
  }
};

export default GroupedChannelIcon;
