export const demoUserData = {
  userLeads: {
    edges: [
      {
        node: {
          id: '1',
          facebookId: 'f1',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2019-10-15T16:59Z',
          fields: [
            {
              name: 'email',
              values: ['zoey.moran@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Zoey']
            },
            {
              name: 'last_name',
              values: ['Moran']
            },
            {
              name: 'date',
              values: ['2021-02-17T16:59Z']
            }
          ]
        }
      },
      {
        node: {
          id: '2',
          facebookId: 'f2',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2021-02-16T17:32Z',
          fields: [
            {
              name: 'email',
              values: ['sammy.rodgers@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Sammy']
            },
            {
              name: 'last_name',
              values: ['Rodgers']
            }
          ]
        }
      },
      {
        node: {
          id: '3',
          facebookId: 'f3',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2021-02-15T17:35Z',
          fields: [
            {
              name: 'email',
              values: ['dominique.sims@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Dominique']
            },
            {
              name: 'last_name',
              values: ['Sims']
            }
          ]
        }
      }
    ]
  },
  dashboardInsights: [
    {
      kpi: {
        slug: 'impressions',
        channelCode: 'fb',
        friendlyName: 'Views',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 1162,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'link_click',
        channelCode: 'fb',
        friendlyName: 'Clicks',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 23,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    }
  ],
  programInsights: [
    // Google programInsights
    {
      kpi: {
        slug: 'impressions',
        channelCode: 'google',
        friendlyName: 'Views',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 56857,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'link_click',
        channelCode: 'google',
        friendlyName: 'Clicks',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 39,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'spend',
        channelCode: 'google',
        friendlyName: 'Cost',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 119.4,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'ad_network_type',
        channelCode: 'google',
        friendlyName: 'Ad Network Type',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 'CONTENT',
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'device',
        channelCode: 'google',
        friendlyName: 'Device',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: ['MOBILE', 'DESKTOP'],
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'conversions',
        channelCode: 'google',
        friendlyName: 'Conversions',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: '9',
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'all_conversions',
        channelCode: 'google',
        friendlyName: 'All Conversions',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: '14',
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    // Facebook programInsights
    {
      kpi: {
        slug: 'impressions',
        channelCode: 'fb',
        friendlyName: 'Views',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 500,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'link_click',
        channelCode: 'fb',
        friendlyName: 'Clicks',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 23,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    }
  ]
};
