import { GridRowId, GridSortModel } from '@mui/x-data-grid-pro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { MutableRefObject, useCallback } from 'react';

import DataTable from 'src/components/DataTable/DataTable';

import { sortRowsOnPriority } from 'src/pages/Admin/AudienceTools/Forms/helpers';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import {
  FacebookAudienceAccountCreationRule,
  getFacebookAudienceAccountCreationRules
} from '../../queries';
import {
  createFacebookAudienceAccountCreationRule,
  deleteFacebookAudienceAccountCreationRule
} from '../../mutations';
import { getFacebookAccountCreationRulesColumns } from './columns';

interface AccountCreationRulesTableProps {
  customApiRef?: MutableRefObject<GridApiPro>;
}

const AccountCreationRulesTable = ({
  customApiRef
}: AccountCreationRulesTableProps) => {
  const [getAudienceAccountCreationRules] = useLazyQuery(
    getFacebookAudienceAccountCreationRules
  );

  const [createAudienceAccountCreationRule] = useMutation(
    createFacebookAudienceAccountCreationRule
  );

  const [deleteAudienceAccountCreationRule] = useMutation(
    deleteFacebookAudienceAccountCreationRule
  );

  const loadMoreRows = async (
    reset: boolean,
    newRowsLength?: number,
    sortModel?: GridSortModel
  ) => {
    const getResponse = await getAudienceAccountCreationRules({
      fetchPolicy: 'no-cache',
      variables: { showDeleted: false }
    });

    return {
      rows: sortRowsOnPriority(
        sortModel,
        getResponse?.data?.facebookAudienceAccountCreationRules
      ) as FacebookAudienceAccountCreationRule[],
      hasMoreRows: false
    };
  };

  const saveRow = async (values: FacebookAudienceAccountCreationRule) => {
    const input = {
      accountSelectionStrategy: values.accountSelectionStrategy,
      priority: values.priority,
      groupId: values.group?.id,
      userMetadataKey: values.userMetadataKey,
      resourceCollectionId: values.resourceCollection?.id,
      resourceItemId: values.resourceItem?.id
    };
    const response = await createAudienceAccountCreationRule({
      variables: {
        Input: input
      }
    });

    return {
      success: true,
      values: response?.data?.createFacebookAudienceAccountCreationRule
    };
  };

  const deleteRow = async (id: GridRowId) => {
    await deleteAudienceAccountCreationRule({
      variables: { ruleId: id as string }
    });

    return { success: true };
  };

  const getRowId = useCallback(row => row.id, []);

  return (
    <DataTable
      deleteConfirmationModal
      editFocusField="priority"
      initialState={{
        sorting: {
          sortModel: [{ field: 'priority', sort: 'asc' }]
        }
      }}
      columns={getFacebookAccountCreationRulesColumns()}
      loadMoreRows={loadMoreRows}
      saveRow={saveRow}
      deleteRow={deleteRow}
      getRowId={getRowId}
      customApiRef={customApiRef}
    />
  );
};

export default AccountCreationRulesTable;
