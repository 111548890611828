import { useState } from 'react';
import { isObject, flow } from 'lodash';
import { t } from 'i18next';
import classnames from 'classnames';
import { graphql } from '@apollo/client/react/hoc';

import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import GroupIcon from '@mui/icons-material/Group';
import GifIcon from '@mui/icons-material/Gif';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { cld } from 'src/common/cloudinaryUtils';
import Video from 'src/components/Video';
import Loading from 'src/components/Loading';
import VideoThumbnail from './VideoThumbnail';

import { GALLERY_TYPE, MEDIA_SOURCES } from '../constants';
import { getMediaAsset } from '../queries';

const styles = theme => ({
  asset: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  videoAsset: {
    height: '100%',
    width: '100%',
    objectFit: 'contain'
  },
  shared: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.grey[600],
    color: '#fff',
    paddingTop: '2px',
    verticalAlign: 'middle',
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    borderRadius: '15px',
    textAlign: 'center'
  },
  lightboxAsset: {
    objectFit: 'contain'
  }
});

const getCloudinaryVideoUrl = (url, isExternal) => {
  if (isExternal) {
    // parnter images are links to their system so we should just display them:
    return url;
    // optionally we could pass them through cloudinary as such, but not sure why? possibly when we add thumbnails to these.
    // return `${CLOUDINARY_BASE_VIDEO_URL}${encodeURI(url)}`;
  }

  // you can now fetch videos from cloudinary
  return cld.video(url).setAssetType('video').setDeliveryType('fetch').toURL();
};

const MediaAsset = props => {
  const {
    asset,
    classes,
    isGiphy,
    isLightbox,
    loading,
    type,
    allowSelect,
    showThumbnail
  } = props;
  const [isPlaying, setIsPlaying] = useState(false);

  // when not an object we are dealing with a legacy input and asset will be just the url
  const sourceUrl = isObject(asset) ? asset?.link : asset;

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {type === GALLERY_TYPE.image && (
        <img
          alt={asset?.id || asset}
          className={classnames(classes.asset, {
            [classes.lightboxAsset]: isLightbox
          })}
          src={sourceUrl}
          data-cy={`gallery-asset-${asset?.id || asset}`}
        />
      )}
      {type === GALLERY_TYPE.video &&
        ((allowSelect || showThumbnail) &&
        !(isLightbox || isGiphy) &&
        // right now partner images aren't served from our buckets
        // we might need to find a way to thumbnail them, but in the mean time
        // we should just not show partner thumbnails for videos :`(
        asset.source !== MEDIA_SOURCES.Partner ? (
          <>
            <VideoThumbnail
              asset={asset}
              classes={classes}
              isLightbox={isLightbox}
              sourceUrl={sourceUrl}
            />
            <span
              className={classes.shared}
              style={{ right: 'auto', left: '10px' }}
            >
              <PlayArrowIcon />
            </span>
          </>
        ) : (
          <Video
            alt={asset?.id || asset}
            className={classes.videoAsset}
            data-cy={`gallery-asset-${asset?.id || asset}`}
            src={getCloudinaryVideoUrl(
              sourceUrl,
              asset.source === MEDIA_SOURCES.Partner ||
                asset.source === MEDIA_SOURCES.Giphy
            )}
            preload="metadata"
            controls
            loop={isGiphy}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        ))}
      {asset.source === MEDIA_SOURCES.Corporate && (
        <span className={classes.shared}>
          <Tooltip title={t('gallery:tile.sharedTooltip')}>
            <GroupIcon />
          </Tooltip>
        </span>
      )}
      {isGiphy && !isPlaying && (
        <span className={classes.shared}>
          <GifIcon viewBox="3 2 18 18" />
        </span>
      )}
    </>
  );
};

export default flow(
  graphql(getMediaAsset, {
    name: 'getMediaAsset',
    skip: ({ shouldFetchAsset }) => !shouldFetchAsset,
    options: ownProps => {
      return {
        variables: {
          assetId: ownProps?.asset?.id
        }
      };
    },
    props: response => {
      const asset = response?.getMediaAsset?.mediaAsset;
      // override the incoming asset prop with fetched data
      return {
        asset,
        loading: response?.getMediaAsset?.loading
      };
    }
  }),
  withStyles(styles)
)(MediaAsset);
