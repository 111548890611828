import { isString } from 'lodash';
import { FormControl, TextField, FormHelperText } from '@mui/material';
import { useController } from 'react-hook-form';

type RenderFilterValueProps = {
  name: string;
  validate: any;
};

const RenderFilterValue = ({ name, validate }: RenderFilterValueProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({ name, rules: { validate } });

  const showError = !!error;
  if (error && !isString(error.message)) {
    // there is a bug where reduxform passes an error for range which is an object
    // after this has been changed to a different operator... smh
    return null;
  }
  return (
    <FormControl error={showError}>
      <TextField
        {...field}
        name={name}
        error={showError}
        placeholder="Enter Value"
        variant="standard"
      />
      {showError && (
        <FormHelperText sx={{ color: theme => theme.palette.error.main }}>
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default RenderFilterValue;
