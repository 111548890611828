import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

const prodApiUrl =
  'https://dashboard-api-production.evocalize.com/lithium/graphql';
const stageApiUrl =
  'https://dashboard-api-staging.evocalize.com/lithium/graphql';
const localApiUrl = 'http://localhost:8080/lithium/graphql';

const getApiEnv = () => {
  if (import.meta.env.EVOCALIZE_LITHIUM_API_URL === prodApiUrl) {
    return 'production';
  }
  if (import.meta.env.EVOCALIZE_LITHIUM_API_URL === stageApiUrl) {
    return 'staging';
  }
  if (import.meta.env.EVOCALIZE_LITHIUM_API_URL === localApiUrl) {
    return 'local';
  }

  // Fine to turn off since this is a dev-only component
  // eslint-disable-next-line no-console
  console.warn('Unknown API URL', import.meta.env.EVOCALIZE_LITHIUM_API_URL);
  return 'local';
};

const hostOverrideForEnv = (env: string) => {
  if (env === 'production') {
    return 'office-prod.evocalize.com';
  }
  if (env === 'staging') {
    return 'office-staging.evocalize.com';
  }
  return 'lilo.evocalize.com';
};

/**
 * WARNING: This component should only ever render something in dev mode!
 * Make sure to keep everything after the env process check!
 *
 * That way this component effectively disappears in production.
 * Both code wise and render wise.
 *
 * This component isn't expected to follow all the normal rules for our
 * components since it'll only be rendered to devs.
 */
export const DevModeErrorHelper = () => {
  if (import.meta.env.EVOCALIZE_ENV !== 'development') {
    return <div />;
  }

  const setHostOverride = (override: string) => {
    localStorage.setItem('evHostnameOverride', override);
    window.location.reload();
  };

  const apiEnv = getApiEnv();

  const hostOverride = localStorage.getItem('evHostnameOverride');
  const expectedHost = hostOverrideForEnv(apiEnv);

  return (
    <Paper
      elevation={3}
      sx={{
        border: '4px dashed red',
        width: '100%',
        padding: '16px',
        margin: '16px',
        backgroundColor: 'rgba(255, 50, 50 , 0.1)'
      }}
    >
      <Typography variant="h2">Dev Mode Helpers</Typography>
      <Typography>These will disappear in production</Typography>
      {hostOverride !== expectedHost && (
        <Box>
          <Typography variant="h3">
            Mismatch detected in hostname override!
          </Typography>
          <Typography>
            Host is currently: <strong>{hostOverride}</strong> but should be{' '}
            <strong>{expectedHost}</strong>
          </Typography>
          <Button
            variant="contained"
            onClick={() => setHostOverride(expectedHost)}
          >
            Fix evHostnameOverride?
          </Button>
        </Box>
      )}
      <Typography variant="h3">Manually set evHostnameOverride</Typography>
      <Button
        variant="contained"
        sx={{ marginRight: 2 }}
        onClick={() => setHostOverride(hostOverrideForEnv('production'))}
      >
        Production
      </Button>
      <Button
        variant="contained"
        sx={{ marginRight: 2 }}
        onClick={() => setHostOverride(hostOverrideForEnv('staging'))}
      >
        Staging
      </Button>
      <Button
        variant="contained"
        sx={{ marginRight: 2 }}
        onClick={() => setHostOverride(hostOverrideForEnv('local'))}
      >
        local
      </Button>
    </Paper>
  );
};
