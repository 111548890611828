import { useEffect } from 'react';

// This hook will prevent scrolling of the overflow content in the body element if isScrollLocked is true
const useLockBodyScroll = (isScrollLocked: boolean) => {
  useEffect(() => {
    if (isScrollLocked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isScrollLocked]);
};

export default useLockBodyScroll;
