import { useMutation } from '@apollo/client';

import { shareMediaAsset, unshareMediaAsset } from '../mutations';

interface UseToggleShareMediaAssetArgs {
  isShared: boolean;
}

const useToggleShareMediaAsset = ({
  isShared
}: UseToggleShareMediaAssetArgs) => {
  const [shareMediaAssetMutation, shareMediaAssetResult] =
    useMutation(shareMediaAsset);

  const [unshareMediaAssetMutation, unshareMediaAssetResult] =
    useMutation(unshareMediaAsset);

  return {
    toggleShareMediaAsset: isShared
      ? unshareMediaAssetMutation
      : shareMediaAssetMutation,
    toggleShareMediaAssetResult: isShared
      ? unshareMediaAssetResult
      : shareMediaAssetResult
  };
};

export default useToggleShareMediaAsset;
