import { CSSProperties } from 'react';
import {
  FacebookIcon,
  Tiktok as TiktokIcon,
  GoogleIcon,
  InstagramIcon
} from 'src/components/Icons';
import { Channel, channelCodes } from 'src/common/adChannels';

const DEFAULT_ICON_SIZE = 24;

interface ChannelIconProps {
  /**
   * The channel to render.
   *
   * Note this should really just be `Channel` but since we
   * gracefully handle unknown channels (by rendering null)
   * we allow `| string` to make it easier to use.
   */
  channel: Channel | string | undefined;
  size?: number;
  style?: CSSProperties;
}

const ChannelIcon = ({
  channel,
  size = DEFAULT_ICON_SIZE,
  style
}: ChannelIconProps) => {
  const props = { width: size, height: size, style };

  switch (channel) {
    case channelCodes.facebook:
      return <FacebookIcon {...props} />;
    case channelCodes.tiktok:
      return <TiktokIcon {...props} />;
    case channelCodes.google:
      return <GoogleIcon {...props} />;
    case channelCodes.instagram:
      return <InstagramIcon {...props} />;

    default:
      return null;
  }
};

export default ChannelIcon;
