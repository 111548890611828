import { useState, useEffect, useCallback } from 'react';
import { some, uniq } from 'lodash';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import { parse } from 'qs';

import { programActions, ProgramActionType } from 'src/pages/Program/Constants';

import Instrumentation from 'src/instrumentation';
import { generateQueryParams, generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

import { formatBlueprints } from 'src/common/blueprints';

import { useFeatures } from 'src/components/Feature/Feature';

import { getBlueprints, getSelectedBlueprint, getAutomation } from '../queries';

const useQuickstartHandler = (
  isAutomation = false,
  isEdit = false,
  type: ProgramActionType
) => {
  const features = useFeatures();
  const {
    architectureId,
    programId
  }: { architectureId: string; programId?: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  const params: { productIds?: string; contentIds?: string } =
    parse(location.search.slice(1)) || {};

  const [modalOpen, setModalOpen] = useState(false);

  const [selectedBlueprint, setSelectedBlueprint] = useState<
    | {
        id: string;
        name: string;
        isMlp: boolean;
      }
    | undefined
  >(undefined);
  const [selectedBlueprintId, setSelectedBlueprintId] = useState(
    params?.productIds ? uniq(params?.productIds?.split(','))[0] : undefined
  );

  const {
    loading: loadingAutomatedProgramData,
    data: automatedProgramData,
    error: errorAutomatedProgramData
  } = useQuery(getAutomation, {
    skip: !isAutomation || !isEdit,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      supervisorOrderId: programId ?? ''
    }
  });

  const {
    loading: loadingBlueprints,
    data: allBlueprints,
    error: allBlueprintsError
  } = useQuery(getBlueprints, {
    skip: isEdit, // we don't need all the blueprints on edit
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      architectureId,
      ...(features?.showInactiveBlueprints && {
        showInactiveBlueprints: true
      }),
      ...(features?.showInactiveOffers && {
        showInactiveOffers: true
      })
    }
  });

  const filteredProductsInitial = allBlueprints?.products || [];
  let filteredProducts = filteredProductsInitial;

  // we skip the all blueprints query on automation edit no need to filter
  if (isAutomation && !isEdit) {
    // only list supervisable (automation) products
    filteredProducts = filteredProductsInitial.filter(p =>
      some(p.offers, { isSupervisable: true })
    );
  }

  const blueprints = formatBlueprints(filteredProducts);

  const [getBlueprint, { loading, error, data }] =
    useLazyQuery(getSelectedBlueprint);

  // auto load selected blueprint when id changes
  useEffect(() => {
    if (selectedBlueprintId && selectedBlueprint?.id !== selectedBlueprintId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      getBlueprint({
        variables: {
          productIds: [selectedBlueprintId],
          architectureId,
          ...(features?.showInactiveBlueprints && {
            showInactiveBlueprints: true
          }),
          ...(features?.showInactiveOffers && {
            showInactiveOffers: true
          })
        }
      });
    }
  }, [selectedBlueprintId, features, getBlueprint, selectedBlueprint?.id]);

  // auto select blueprint when data loads
  useEffect(() => {
    if (
      selectedBlueprintId &&
      !loading &&
      !error &&
      selectedBlueprint?.id !== selectedBlueprintId
    ) {
      setSelectedBlueprint(formatBlueprints(data?.products)[0]);
      setModalOpen(false);
    }
  }, [
    selectedBlueprintId,
    loading,
    data?.products,
    error,
    selectedBlueprint?.id
  ]);

  const openBlueprintsModal = () => {
    setModalOpen(true);
    Instrumentation.logEvent(
      Instrumentation.Events.ProgramChooseBlueprintModalClicked
    );
  };

  // set the selected blueprint id or open the modal
  useEffect(() => {
    // wait for BPS to load ya dingus
    if (loadingBlueprints) {
      return;
    }

    // handle automated case separately
    if (isAutomation) {
      // Automation Edit Case
      if (!selectedBlueprintId && isEdit) {
        setSelectedBlueprintId(
          automatedProgramData?.automation?.offer?.product?.id
        );
      }

      // Automation Create Case
      if (!selectedBlueprintId && !isEdit) {
        // just select the first blueprint if no id is set yet
        setSelectedBlueprintId(blueprints[0]?.id);
      }

      return;
    }

    // no programId in url so they must select one
    if (!isEdit && !modalOpen && !selectedBlueprintId) {
      openBlueprintsModal();
    }
  }, [
    blueprints,
    loadingBlueprints,
    isAutomation,
    modalOpen,
    selectedBlueprintId,
    automatedProgramData
  ]);

  const handleSelectBlueprint = useCallback(
    (productId, architectureId) => {
      // KRUSH KILL DESTROY
      if (isAutomation) {
        setSelectedBlueprintId(undefined);
      }

      const replacementObject = architectureId ? { architectureId } : params;

      let path: string = paths.architecture.programCreate;
      if (type === programActions.automatedCreate) {
        path = paths.architecture.automatedProgramCreate;
      }
      if (type === programActions.automatedEdit) {
        path = paths.architecture.automatedProgramEdit;
      }

      if (path) {
        history.push({
          pathname: generateLinkPath(path, replacementObject),
          search: generateQueryParams({
            productIds: [productId],
            ...(params?.contentIds && {
              contentIds: params?.contentIds
            })
          })
        });
      }
      setSelectedBlueprintId(productId);
    },
    [history, isAutomation, params?.contentIds]
  );

  const handleCloseModal = () => {
    // if we have a selected blueprint then close the modal
    if (selectedBlueprint) {
      setModalOpen(false);
      return;
    }

    // If they decide to bail send them to the /(automated)programs purgatory
    history.push({
      pathname: generateLinkPath(
        isAutomation ? paths.automations.base : paths.programs.base,
        { architectureId }
      )
    });
  };

  return {
    modalOpen,
    openBlueprintsModal,
    handleCloseModal,
    handleSelectBlueprint,
    blueprints,
    selectedBlueprint,
    selectedBlueprintId,
    loading: loadingBlueprints || loading || loadingAutomatedProgramData,
    error: allBlueprintsError || error || errorAutomatedProgramData,
    automation: automatedProgramData?.automation
  };
};

export default useQuickstartHandler;
