import { useMemo, useCallback } from 'react';
import { clone, isEmpty, flow, filter, find } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import { Grid, Divider, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { getParameterValueHelperData } from '../../queries';
import RenderParameterKeyValueInputs from './RenderParameterKeyValueInputs';
import AddParameterValue from './AddParameterValue';

const styles = theme => ({
  headingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1)
  },
  emptyState: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  emptyText: {
    margin: `${theme.spacing(2)} 0`
  }
});

const pageText = () => ({
  heading: t('admin:blueprintBuilder.stepPublishersStepFacetParameterHeading'),
  addButton: t(
    'admin:blueprintBuilder.stepPublishersStepFacetParameterAddButton'
  ),
  removeButton: t(
    'admin:blueprintBuilder.stepPublishersStepFacetParameterRemoveButton'
  ),
  emptyState: t(
    'admin:blueprintBuilder.stepPublishersStepFacetParameterEmptyState'
  )
});

const formatDataForOnChange = newParameterValues => {
  return newParameterValues.reduce((accum, { key, value }) => {
    const newAccum = accum;
    newAccum[key] = value;
    return newAccum;
  }, {});
};

const RenderParameterValues = props => {
  const {
    input,
    classes,
    facet,
    unitId,
    prototype,
    parameterEnums,
    selectedParameters
  } = props;
  const text = useMemo(() => pageText(), []);

  const parameterValues = useMemo(
    () =>
      input?.value
        ? Object.keys(input?.value).map(key => {
            return {
              key,
              value: input?.value[key]
            };
          })
        : [],
    [input]
  );

  const updateInputValue = useCallback(
    newParameterValues => {
      if (isEmpty(newParameterValues)) {
        return input.onChange({});
      }
      input.onChange(formatDataForOnChange(newParameterValues));
    },
    [input]
  );

  const handleParameterValuesSet = useCallback(
    ({ newKey, newValue, selectedIndex }) => {
      const newParameterValues = clone(parameterValues);

      newParameterValues[selectedIndex] = {
        key: newKey,
        value: newValue
      };

      updateInputValue(newParameterValues);
    },
    [parameterValues]
  );

  const handleAddParameter = useCallback(
    selectedParameter => {
      updateInputValue([
        ...parameterValues,
        {
          key: selectedParameter?.name,
          value: ''
        }
      ]);
    },
    [parameterValues]
  );

  const handleAddRequiredParameters = useCallback(
    selectedParameters => {
      const requiredParameters = filter(selectedParameters, {
        isRequired: true
      }).map(parameter => {
        return {
          key: parameter?.name,
          value: ''
        };
      });

      updateInputValue([...parameterValues, ...requiredParameters]);
    },
    [parameterValues]
  );

  const handleRemoveParameter = useCallback(
    index => {
      const newParameterValues = clone(parameterValues);

      newParameterValues.splice(index, 1);

      updateInputValue(newParameterValues);
    },
    [parameterValues]
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.headingContainer}>
          <Typography className={classes.heading}>{text.heading}</Typography>

          <AddParameterValue
            key="AddParameterValueInline"
            facet={facet}
            onAddParameterValue={handleAddParameter}
            prototype={prototype}
            unitId={unitId}
            variant="inline"
            parameterValues={parameterValues}
            parameterEnums={parameterEnums}
            selectedParameters={selectedParameters}
          />
        </div>
        <Divider />
        {isEmpty(parameterValues) ? (
          <div className={classes.emptyState}>
            <Typography className={classes.emptyText}>
              {text.emptyState}
            </Typography>

            <AddParameterValue
              key="AddParameterValueEmptyState"
              facet={facet}
              onAddParameterValue={handleAddParameter}
              prototype={prototype}
              unitId={unitId}
              onAddRequiredParameters={handleAddRequiredParameters}
              variant="emptyState"
              parameterEnums={parameterEnums}
              selectedParameters={selectedParameters}
            />
          </div>
        ) : (
          parameterValues.map(({ key, value }, index) => {
            return (
              <RenderParameterKeyValueInputs
                key={`${input?.name}-${key}`}
                parameterKey={key}
                parameterValue={value}
                parameterIndex={index}
                onParameterValuesSet={handleParameterValuesSet}
                removeParameter={handleRemoveParameter}
                facet={facet}
                parameterEnums={parameterEnums}
                selectedParameters={selectedParameters}
              />
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

export default flow(
  graphql(getParameterValueHelperData, {
    name: 'getParameterValueHelperData',
    options: props => {
      const { unitId, prototype } = props;

      const variables = {
        prototype,
        unitId
      };

      return {
        variables
      };
    },
    props: ({ getParameterValueHelperData, ownProps }) => {
      const facets =
        getParameterValueHelperData?.getUnits?.[0]?.prototypes?.[0]?.facets ||
        [];

      const selectedParameters =
        find(facets, { slug: ownProps?.facet })?.parameters || [];

      const parameterEnums = selectedParameters.map(facet => {
        return {
          name: facet?.friendlyName,
          value: facet
        };
      });

      return {
        parameterEnums,
        selectedParameters
      };
    }
  }),
  withStyles(styles)
)(RenderParameterValues);
