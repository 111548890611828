import { handleActions } from 'redux-actions';

import { actionConstants } from './actions';

const initialState = {
  modalOpen: false
};

export default handleActions(
  {
    [actionConstants.closeBusinessObjectSelectorModal]: state => {
      return {
        ...state,
        modalOpen: false
      };
    },
    [actionConstants.showBusinessObjectSelectorModal]: state => {
      return {
        ...state,
        modalOpen: true
      };
    }
  },
  initialState
);
