import { useState, useMemo } from 'react';
import { noop } from 'lodash';
import { t } from 'i18next';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import { Button, Typography, Box } from '@mui/material';
import { Cancel as CancelIcon } from '@mui/icons-material';
import Instrumentation from 'src/instrumentation';

import { formatPriceWithCents } from 'src/common/numbers';
import { getOneOfEachChannelKeys } from 'src/common/blueprints';
import { OFFER_TYPES } from 'src/common/offers';

import Loading from 'src/components/Loading';
import Modal, { ModalHeader } from 'src/components/Modal';
import { formatDate } from 'src/common/dates';

import { calculateOrderItemRefund } from '../../queries';
import { CANCELLATION_TYPES, ORDER_PROCESSING_STATUS } from '../../Constants';
import { cancelOrder as cancelOrderMutation } from '../../mutations';
import ModalDescription from './ModalDescription';
import RefundTable from './RefundTable';
import RefundTableToggle from './RefundTableToggle';

const pageText = () => ({
  cancelSuccessMessage: t('programPerf:cancelSnack.success'),
  cancelFailMessage: t('programPerf:cancelSnack.error'),
  modalHeader: t('programPerf:cancelModal.title'),
  modalCloseButton: t('programPerf:cancelModal.close'),
  modalConfirmButton: t('programPerf:cancelModal.save')
});

const CancelProgramModal = ({
  order = null,
  refetchOrder = noop,
  open = false,
  onClose
}) => {
  const text = useMemo(() => pageText(), []);
  const { enqueueSnackbar } = useSnackbar();

  const {
    isProgramActive,
    orderItem: { id: orderItemId, status: orderStatus },
    offer: { type: offerType, cancellationType = CANCELLATION_TYPES.immediate }
  } = order;

  const [cancelOrder] = useMutation(cancelOrderMutation);

  const {
    data: refundData,
    loading: refundLoading,
    error: refundCalculationError
  } = useQuery(calculateOrderItemRefund, {
    skip: order?.orderItem?.status !== ORDER_PROCESSING_STATUS.fulfilled,
    variables: {
      orderItemId
    }
  });

  const refund = refundData?.calculateOrderItemRefund || null;
  // I stored this in a variable to name it something less ambiguous
  const isRefundEnabled = refund?.displayCalculation;
  const isInsideSevenDayWindow = refund?.daysRemaining <= 7;
  const isSubscription = offerType === OFFER_TYPES.subscription;
  const isImmediateCancellation =
    cancellationType === CANCELLATION_TYPES.immediate;
  const expirationDate = formatDate({
    format: 'MM/DD/YYYY',
    date: refund?.periodEnd
  });
  const refundAmount = formatPriceWithCents(refund?.totalRefund);

  const [fetching, setFetching] = useState(false);

  if (!isProgramActive) {
    return null;
  }

  const isLoading = fetching || refundLoading;

  const handleConfirm = () => {
    setFetching(true);

    Instrumentation.logEvent(Instrumentation.Events.CancelProgramClicked, {
      actions: 'confirm',
      orderId: order?.orderItem?.id,
      architectureId: order?.architectureId,
      productId: order?.orderItem?.product?.id,
      paymentAmount: order?.billingDetails?.amount,
      channel: getOneOfEachChannelKeys(order?.channels?.[0]?.channels)
    });

    cancelOrder({ variables: { orderItemId } })
      .then(() => {
        enqueueSnackbar(text?.cancelSuccessMessage, {
          variant: 'success'
        });
        refetchOrder();
        setFetching(false);
        onClose(false);

        return null;
      })
      .catch(() => {
        enqueueSnackbar(text?.cancelFailMessage, {
          variant: 'error'
        });
        refetchOrder();
        setFetching(false);
        onClose(true);
      });
  };

  return (
    <Modal
      open={open}
      headerText={text?.modalHeader}
      onClose={() => onClose(false)}
      HeaderComponent={() => (
        <ModalHeader
          icon={
            <CancelIcon sx={theme => ({ color: theme.palette.grey[600] })} />
          }
        >
          {text.modalHeader}
        </ModalHeader>
      )}
      FooterComponent={() => (
        <>
          <Button
            onClick={() => {
              Instrumentation.logEvent(
                Instrumentation.Events.CancelProgramClicked,
                {
                  actions: 'cancel'
                }
              );
              onClose(false);
            }}
            disabled={isLoading}
          >
            {text?.modalCloseButton}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirm}
            disabled={isLoading}
          >
            {text?.modalConfirmButton}
          </Button>
        </>
      )}
    >
      {isLoading ? (
        <Box
          sx={{
            minWidth: 600,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loading />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}
        >
          <Typography variant="body1" gutterBottom>
            {refund?.cancellationMessageIsOverridden ? (
              refund?.cancellationMessage
            ) : (
              <ModalDescription
                isInsideSevenDayWindow={isInsideSevenDayWindow}
                offerType={offerType}
                cancellationType={cancellationType}
                expirationDate={expirationDate}
                refundAmount={refundAmount}
                orderStatus={orderStatus}
                refundCalculationError={!!refundCalculationError}
                isRefundEnabled={isRefundEnabled}
              />
            )}
          </Typography>
          {refund &&
            isRefundEnabled &&
            isSubscription &&
            isInsideSevenDayWindow &&
            isImmediateCancellation && (
              <RefundTableToggle>
                <RefundTable refund={refund} />
              </RefundTableToggle>
            )}
        </Box>
      )}
    </Modal>
  );
};

export default CancelProgramModal;
