import { useMemo, useState } from 'react';
import { flow, keyBy } from 'lodash';
import { reduxForm, getFormValues, change, untouch } from 'redux-form';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { t } from 'i18next';

import {
  Button,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';

import {
  TEST_FORM_NAME,
  getTestWebhookInputs,
  advancedTestWebhookInputs
} from './Constants';
import {
  getProductAndCatalogOptions,
  getArchitecturesOptions
} from './helpers';
import { getArchitectures } from './queries';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  advancedPanel: {
    margin: '16px 0'
  }
});

const pageText = () => ({
  cardHeading: t('admin:testWebhook.cardHeading'),
  cancelButton: t('admin:testWebhook.cancelButton'),
  errorMessage: t('admin:testWebhook.errorMessage'),
  submitButton: t('admin:testWebhook.submitButton'),
  advanced: t('admin:testWebhook.advancedPanel')
});

const TestWebhook = props => {
  const {
    architectures,
    architecturesById,
    catalogGroupKey,
    change,
    classes,
    contentName,
    dirty,
    handleSubmit,
    onSubmit,
    selectedArchitectureId,
    submitFailed,
    submitting,
    toggleForm,
    untouch
  } = props;

  const text = useMemo(() => pageText(), []);

  const [advancedOpen, setAdvanced] = useState(false);

  const { architectureOptions } = useMemo(
    () =>
      getArchitecturesOptions({
        architectures
      }),
    [architectures]
  );

  const { productOptions, catalogOptions, catalogGroupKeyOptions } = useMemo(
    () =>
      getProductAndCatalogOptions({
        architecturesById,
        selectedArchitectureId
      }),
    [architecturesById, selectedArchitectureId]
  );

  const inputs = useMemo(
    () =>
      getTestWebhookInputs({
        architectureOptions,
        catalogGroupKey,
        catalogGroupKeyOptions,
        catalogOptions,
        contentName,
        productOptions,
        selectedArchitectureId
      }),
    [
      architectureOptions,
      catalogGroupKey,
      catalogGroupKeyOptions,
      catalogOptions,
      contentName,
      productOptions,
      selectedArchitectureId
    ]
  );

  const resetForm = () => {
    // reset form if architecture id changes
    [...inputs, ...advancedTestWebhookInputs()].forEach(input => {
      if (input.name !== 'architectureId') {
        change(input.name, input.initialValues);
        untouch(input.name);
      }
    });
  };

  const cancelForm = () => {
    resetForm();
    toggleForm(false);
  };

  useMemo(() => resetForm(), [selectedArchitectureId]);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(data => onSubmit(data))}>
      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>{text.errorMessage}</ErrorMessage>
        </div>
      )}

      <DynamicForm inputs={inputs} />

      <Accordion
        expanded={advancedOpen}
        key="test-webhook-advanced-inputs"
        onChange={() => setAdvanced(!advancedOpen)}
        className={classes.advancedPanel}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">{text.advanced}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DynamicForm inputs={advancedTestWebhookInputs()} />
        </AccordionDetails>
      </Accordion>

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty}
          type="submit"
          variant="contained"
          endIcon={submitting && <CircularProgress size={15} />}
        >
          {text.submitButton}
        </Button>
        <Button onClick={cancelForm}>{text.cancelButton}</Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  const formValues = getFormValues(TEST_FORM_NAME)(state);
  const selectedArchitectureId = formValues?.architectureId;
  const selectedCatalogId = formValues?.catalogId;
  const catalogGroupKey = formValues?.catalogGroupKey;
  const contentName =
    ownProps.architecturesById?.[selectedArchitectureId]?.catalogs?.[0]
      ?.friendlyName || 'Content';

  return {
    selectedArchitectureId,
    selectedCatalogId,
    catalogGroupKey,
    contentName,
    initialValues: {
      isDryRun: true,
      contentItemId: []
    }
  };
};

const mapArchitecturesToProps = ({ getArchitectures }) => {
  const architectures = getArchitectures?.us?.architectures ?? [];
  const filteredArchitectures = architectures.filter(
    architecture => architecture?.isContentSelectable
  );

  return {
    architectures: filteredArchitectures,
    architecturesById: keyBy(filteredArchitectures, 'id')
  };
};

export default flow(
  reduxForm({
    form: TEST_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps, { change, untouch }),
  graphql(getArchitectures, {
    name: 'getArchitectures',
    props: mapArchitecturesToProps
  }),
  withStyles(styles)
)(TestWebhook);
