import { gql } from 'src/generated/gql';

export const getContent = gql(`
  query getContent(
    $architectureId: ID!
    $filter: JSON
    $endCursor: String
    $first: Int!
    $groupKey: String
  ) {
    architecture(architectureId: $architectureId) {
      id
      name
      catalog {
        friendlyName
        content(
          filter: $filter
          first: $first
          after: $endCursor
          groupKey: $groupKey
        ) {
          edges {
            node {
              id
            }
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
        }
      }
    }
  }
`);
