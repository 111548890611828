import { useState } from 'react';
import v from 'validator';
import { isString } from 'lodash';

import { IconButton, Skeleton, Box } from '@mui/material';
import { styled } from '@mui/system';

import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { isAdPreviewTemplate } from 'src/common/templateTranslator';
import { useGetVerticalPlaceholderImages } from 'src/common/verticals';
import useIsProgramCreatePage from 'src/routes/useIsProgramCreatePage';

import { s3BaseUrl } from '../S3Image';
import {
  googlePublisherFacets,
  cloudinaryDisplayAdRectangleBaseUrl
} from '../Constants';
import { getPreviewWidth } from '../helpers';
import { GOOGLE_PREVIEW_WIDTH } from './GoogleAdPreviewConstants';

import DisplayAdOne from './GoogleDisplayAdPreviews/DisplayAdOne';
import DisplayAdTwo from './GoogleDisplayAdPreviews/DisplayAdTwo';
import DisplayAdThree from './GoogleDisplayAdPreviews/DisplayAdThree';
import DisplayAdNoMainImage from './GoogleDisplayAdPreviews/DisplayAdNoMainImage';

const s3BaseUrlFull = `https:${s3BaseUrl}`;

const Image = ({ src, alt, style, errorImg }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [imgSrc, setImageSrc] = useState(src);

  return (
    <>
      <img
        src={imgSrc}
        alt={alt}
        onLoad={() => setIsLoaded(true)}
        onError={() => setImageSrc(errorImg)}
        style={isLoaded ? style : { display: 'none' }}
      />
      {!isLoaded && <Skeleton variant="rectangular" {...style} />}
    </>
  );
};

export const getImage = ({
  adDataImage,
  placeholder,
  styles = {},
  handleOnLoad = () => {}
}) => {
  let image = placeholder ? (
    <Image src={placeholder} alt="Placeholder" style={styles} />
  ) : (
    ''
  );

  if (
    adDataImage &&
    !isAdPreviewTemplate(adDataImage) &&
    !isAdPreviewTemplate(decodeURIComponent(adDataImage)) &&
    isString(adDataImage) &&
    v.isURL(adDataImage)
  ) {
    image = (
      <Image
        key={adDataImage}
        src={adDataImage}
        alt="Ad Creative"
        style={styles}
        onLoad={handleOnLoad}
        errorImg={placeholder}
      />
    );
  }

  return image;
};

const PlaceHolderText = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  height: theme.spacing(1),
  margin: '5px 0',
  width: '100%'
}));

const GoogleDisplayAdPreview = props => {
  const {
    adData,
    hideGoogleAdNavigationButtons,
    hasUniformPreviewWidth,
    isResponsive
  } = props;
  const [selectedAd, setSelectedAd] = useState(0);
  const isProgramCreatePage = useIsProgramCreatePage();
  const placeholderImages = useGetVerticalPlaceholderImages();

  const FakeTextContainer = styled('div')(({ theme }) => ({
    margin: `${theme.spacing(1)} 0`,
    width: isProgramCreatePage
      ? '100%'
      : getPreviewWidth(hasUniformPreviewWidth, GOOGLE_PREVIEW_WIDTH)
  }));

  const mainImage = getImage({
    adDataImage:
      adData?.[googlePublisherFacets.rectangleMarketingImageReferenceLegacy] ||
      adData?.[googlePublisherFacets.rectangleMarketingImageReference],
    placeholder: `${cloudinaryDisplayAdRectangleBaseUrl}${s3BaseUrlFull}${placeholderImages?.image}`,
    showError: true
  });

  // NOTE: logoImage does not have a placeholder b/c it does not show up if it does not
  //       exist & is not currently required.
  const logoImage = getImage({
    adDataImage:
      adData?.[googlePublisherFacets.squareLogoImageReferenceLegacy] ||
      adData?.[googlePublisherFacets.squareLogoImageReference],
    showError: false
  });

  const headline = adData?.[googlePublisherFacets.headline];
  const description = adData?.[googlePublisherFacets.description];
  const businessName = adData?.[googlePublisherFacets.businessName];

  const allAdComponents = [
    DisplayAdOne,
    DisplayAdTwo,
    DisplayAdThree,
    DisplayAdNoMainImage
  ];
  const SelectedAdComponent = allAdComponents[selectedAd];

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%'
      }}
      className="notranslate"
    >
      {!hideGoogleAdNavigationButtons && (
        <Box>
          <IconButton
            onClick={() =>
              setSelectedAd(
                selectedAd === 0 ? allAdComponents.length - 1 : selectedAd - 1
              )
            }
            size="large"
          >
            <ArrowLeftIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              setSelectedAd(
                selectedAd === allAdComponents.length - 1 ? 0 : selectedAd + 1
              )
            }
            size="large"
          >
            <ArrowRightIcon />
          </IconButton>
        </Box>
      )}

      <FakeTextContainer>
        <PlaceHolderText />
        <PlaceHolderText />
        <PlaceHolderText />
        <Box
          sx={{
            background: 'grey[200]',
            height: 1,
            margin: '5px 0',
            width: '75%'
          }}
        />
      </FakeTextContainer>

      <SelectedAdComponent
        mainImage={mainImage}
        logoImage={logoImage}
        headline={headline}
        description={description}
        businessName={businessName}
        hasUniformPreviewWidth={hasUniformPreviewWidth}
        isResponsive={isResponsive}
      />

      <FakeTextContainer>
        <PlaceHolderText />
        <PlaceHolderText />
      </FakeTextContainer>
    </Box>
  );
};

export default GoogleDisplayAdPreview;
