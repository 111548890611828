import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import Modal from 'src/components/Modal';

import { clearSkinPreview } from './helpers';

const styles = () => ({});

const AdminSkinResetModal = props => {
  const { open, close } = props;

  const handleReset = () => {
    clearSkinPreview();
    window.location.reload();
  };

  return (
    <Modal
      fullWidth
      open={open}
      headerText={t('adminSkinSettings:modal.resetHeader')}
      onClose={close}
      FooterComponent={() => (
        <>
          <Button onClick={close}>
            <Trans i18nKey="adminSkinSettings:modal.cancel">Cancel</Trans>
          </Button>
          <Button color="primary" onClick={handleReset}>
            <Trans i18nKey="adminSkinSettings:modal.reset">Reset</Trans>
          </Button>
        </>
      )}
    >
      <div>
        <Typography variant="h5">
          <Trans i18nKey="adminSkinSettings:modal.resetInfoHeading">
            You are about to reset the skin settings
          </Trans>
        </Typography>
        <br />
        <Typography variant="subtitle1">
          <Trans i18nKey="adminSkinSettings:modal.resetInfo">
            Doing this will clear your current preview skin setting and discard
            your changes. This will cause the page to refresh so you can see
            your changes.
          </Trans>
        </Typography>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(AdminSkinResetModal);
