import { useState } from 'react';
import { flow, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { graphql } from '@apollo/client/react/hoc';
import { Trans } from 'react-i18next';

import { Paper } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import PageTitle from 'src/components/PageTitle';
import SuccessContainer from 'src/components/Containers/SuccessContainer';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import { withAppSettings } from 'src/AppSettings';
import { AUTH_PROVIDER_TYPES } from 'src/Auth/common';
import { activateUserByEmail } from './mutations';
import ActivateEmailForm from './ActivateEmailForm';

const style = theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  maxWidthContainer: {
    display: 'flex',
    flexGrow: '1',
    maxWidth: '500px'
  },
  centeredWrap: {
    padding: theme.spacing(2),
    width: '100%'
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  companyLogo: {
    width: `${theme.evSizes.navigationWidth}px`
  },
  statusContainer: {
    padding: theme.spacing(2)
  }
});

const activateResponseOptions = {
  success: 'EMAIL_SENT',
  fail: 'USER_NOT_FOUND'
};

const Activate = props => {
  const {
    classes,
    activateUserByEmail,
    appSettings,
    location: { search }
  } = props;

  const { email } = parse(search);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSendActivationEmail = ({ email }) => {
    activateUserByEmail({
      variables: {
        emailAddress: email,
        organizationId: appSettings.app.general.organizationId
      }
    })
      .then(({ data }) => {
        const response = get(data, 'activateUserByEmail');

        if (response === activateResponseOptions.success) {
          setSuccess(true);
        }

        if (response === activateResponseOptions.fail) {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  let Content = (
    <ActivateEmailForm email={email} onSubmit={handleSendActivationEmail} />
  );

  if (error) {
    Content = (
      <ErrorMessage
        data-cy="error-container"
        className={classes.statusContainer}
      >
        {/* TODO Add email link to support when we have it */}
        <Trans i18nKey="activation:activate.errorMessage" />
      </ErrorMessage>
    );
  }

  if (success) {
    Content = (
      <SuccessContainer data-cy="success" className={classes.statusContainer}>
        <Trans i18nKey="activation:activate.successMessage" />
      </SuccessContainer>
    );
  }

  return (
    <div className={classes.container}>
      <PageTitle subPageTitle="Activate Email" />
      <div className={classes.maxWidthContainer}>
        <Paper className={classes.centeredWrap}>
          <div className={classes.logoContainer}>
            <img
              alt={`${appSettings.app.general.baseTitle} Logo`}
              className={classes.companyLogo}
              src={appSettings.evAssets.logoUrl}
            />
          </div>

          {appSettings?.app?.auth?.authProviderType ===
            AUTH_PROVIDER_TYPES.auth0 && (
            <>
              <h1 style={{ margin: '20px 0' }}>
                <Trans i18nKey="activation:activate.header" />
              </h1>
              <p>
                <Trans i18nKey="activation:activate.subheader" />
              </p>
              {Content}
            </>
          )}
        </Paper>
      </div>
    </div>
  );
};

export default flow(
  graphql(activateUserByEmail, {
    name: 'activateUserByEmail'
  }),
  withStyles(style),
  withAppSettings,
  withRouter
)(Activate);
