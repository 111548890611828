import { isFunction } from 'lodash';
import { stringify } from 'qs';

import Logger from 'src/common/Logger';

import { padUserId } from 'src/Auth/common';
import AmplitudeInstrumentation from './amplitude';
import ExternalInstrumentation from './external';

const providers = [AmplitudeInstrumentation, ExternalInstrumentation];

/**
 * Initializes the instrumentation module.
 * @param {object} settings Settings available to instrumentation providers.
 */
const initialize = settings => {
  Logger.debug('Initializing instrumentation.');
  const promises = [];

  providers.forEach(p => {
    if (p && isFunction(p.initialize)) {
      const promise = p.initialize(settings);
      if (promise) {
        promises.push(promise);
      }
    } else {
      Logger.error(
        'Found instrumentation provider without initialize function'
      );
    }
  });

  Logger.debug('Finished initializing instrumentation.');

  return Promise.all(promises);
};

/**
 * Sets properties related to the current user.
 * @param {string} userId
 * @param {object} userProperties
 */
const setUserProperties = (userId, userProperties) => {
  Logger.debug('Setting instrumentation user properties.');
  const promises = [];
  providers.forEach(p => {
    if (p && isFunction(p.setUserProperties)) {
      const promise = p.setUserProperties(padUserId(userId), userProperties);
      promises.push(promise);
    } else {
      Logger.error(
        'Found instrumentation provider without setUserProperties function'
      );
    }
  });

  Logger.debug('Finished setting instrumentation user properties.');
  return Promise.all(promises);
};

/**
 * Logs an instrumentation event.
 * @param {string} event The event to log.
 * @param {*} eventProperties Properties associated with the event.
 */
const logEvent = (event, eventProperties = {}) => {
  Logger.debug(`Logging event ${event}.`, eventProperties);

  providers.forEach(p => {
    if (p && isFunction(p.logEvent)) {
      p.logEvent(event, eventProperties);
    } else {
      Logger.error('Found instrumentation provider without logEvent function');
    }
  });

  Logger.debug(`Finished logging event ${event}.`, eventProperties);
};

/**
 * Gets the instrumentation context header value.
 * @returns A string to be used as a header value to share instrumentation
 * context with the backend.
 */
const getContextHeader = () => {
  // Call each provider to build a map of key-value pairs that will be
  // included in the intrumentation context header.
  const contextHeaderMap = providers
    .map(p => p.getContextHeaderMap())
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  // Transform the map of key-value pairs into a query string
  return stringify(contextHeaderMap);
};

/**
 * Sets amplitude group(s) for account level reporting.
 * @param {string} groupType
 * @param {string} groupName
 */

const setGroup = (groupType, groupName) => {
  Logger.debug(`Instrumenting group of type ${groupType}.`);

  const promise = AmplitudeInstrumentation.setGroup(groupType, groupName);

  Logger.debug(`Finished instrumenting group of type ${groupType}.`);
  return promise;
};

export { initialize, setUserProperties, logEvent, getContextHeader, setGroup };
