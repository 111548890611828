import { gql } from 'src/generated/gql';

export const getResourceItemTypes = gql(`
  query GetResourceItemTypesForResourceLibrary {
    resourceItemTypes {
      slug
      friendlyName
    }
  }
`);

export const getResourceCollections = gql(`
  query GetResourceCollections($type: String!) {
    resourceCollections(type: $type) {
      id
      type
      key
      description
      attributes
    }
  }
`);

export const getResourceCollection = gql(`
  query GetResourceCollection($type: String!, $key: String!) {
    resourceCollection(type: $type, key: $key) {
      id
      type
      key
      description
      attributes
      resources {
        id
        value
        key
        description
        attributes
      }
    }
  }
`);

export const createResourceCollection = gql(`
  mutation CreateResourceCollection($input: CreateResourceCollectionInput!) {
    createResourceCollection(input: $input) {
      id
      type
      key
      description
      attributes
    }
  }
`);

export const updateResourceCollection = gql(`
  mutation UpdateResourceCollection($input: UpdateResourceCollectionInput!) {
    updateResourceCollection(input: $input) {
      id
      type
      key
      description
      attributes
    }
  }
`);

export const deleteResourceCollection = gql(`
  mutation DeleteResourceCollection($id: ID!) {
    deleteResourceCollection(id: $id)
  }
`);

export const createResourceItem = gql(`
  mutation CreateResourceItem($input: CreateResourceItemInput!) {
    createResourceItem(input: $input) {
      id
      value
      key
      description
      attributes
    }
  }
`);

export const updateResourceItem = gql(`
  mutation UpdateResourceItem($input: UpdateResourceItemInput!) {
    updateResourceItem(input: $input) {
      id
      value
      key
      description
      attributes
    }
  }
`);

export const deleteResourceItem = gql(`
  mutation DeleteResourceItem($id: ID!) {
    deleteResourceItem(id: $id)
  }
`);
