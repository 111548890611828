import { flow } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';

import { Button } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

const styles = () => ({
  container: {
    height: '100%',
    width: '100%'
  }
});

const SANDBOX_FORM = 'sandboxForm';

export const sandboxInputs = [
  {
    name: 'testInput',
    displayName: 'Sample Input',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

const Sandbox = props => {
  const { classes, handleSubmit } = props;
  const onSubmit = data => {
    // eslint-disable-next-line no-console
    console.log('submit data', data);
  };

  return (
    <div className={classes.container}>
      <form autoComplete="off" onSubmit={handleSubmit(data => onSubmit(data))}>
        <DynamicForm inputs={sandboxInputs} />
        <Button color="primary" type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  const formValues = getFormValues(SANDBOX_FORM)(state);
  return {
    formValues
  };
};

export default flow(
  reduxForm({
    form: SANDBOX_FORM,
    enableReinitialize: true
  }),
  connect(mapStateToProps, {}),
  withStyles(styles)
)(Sandbox);
