import { gql } from 'src/generated/gql';

export const getUser = gql(`
  query getUser($showInactive: Boolean) {
    me {
      id
      locale
      email
      name
      organizationId
      settings
      notificationKey
      userTosDetails {
        userNeedsToAcceptTos
      }
      availableGroups {
        id
        name
        role
      }
      metadata {
        fields
        fieldMetadata {
          id
          displayMethodId
          displayParameters
          displaySortOrder
          fieldName
          displayName
          validationRules
          isRequired
          isHidden
        }
      }
    }
    us {
      id
      name
      role
      settings
      architectures(showInactive: $showInactive) {
        id
        name
        isContentSelectable
        hasSupervisableOffer
        catalog {
          id
          friendlyName
          contentSetId
        }
        products(showInactive: $showInactive) {
          id
          name
        }
      }
    }
    myOrganization {
      id
      name
      slug
      pullProviders {
        userPullProviderExists
        contentPullProviderExists
        audiencePullProviderExists
      }
    }
  }
`);

export const getLocales = gql(`
  query getLocalesForPostAuthGlobals {
   localeDocumentation {
      locale
      description
   }
  }
`);

export const checkForExistingActiveConnection = gql(`
  query checkForExistingActiveConnectionForPostAuthGlobals {
    checkForExistingActiveConnection {
      isActive
      connectionExists
    }
  }
`);
