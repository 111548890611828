import { snakeCase } from 'lodash';
import { useGlobalContext } from 'src/GlobalContextProvider';
import { t } from 'i18next';
import { useWatch } from 'react-hook-form';

import { List } from '@mui/material';
import { ArchitectureType } from 'src/pages/Architecture/ArchitectureProvider';

import {
  translateFiltersWithMeta,
  contentMetaFromArchitecture,
  filterToString
} from './helpers';
import SelectedFilterListItem from './SelectedFilterListItem';

type SelectedFilterListProps = {
  architecture: ArchitectureType;
  formNamespace: string;
};

const SelectedFilterList = ({
  architecture,
  formNamespace
}: SelectedFilterListProps) => {
  const globalContext = useGlobalContext();
  const officeName = globalContext?.office?.officeName;
  const filters = useWatch({
    name: `${formNamespace}.filters`,
    defaultValue: []
  });
  const baseSet = useWatch({
    name: `${formNamespace}.baseSet`,
    defaultValue: {}
  });

  const contentMeta = contentMetaFromArchitecture(architecture);
  const translatedFilters = translateFiltersWithMeta(filters, contentMeta);
  const displayName = baseSet?.displayName;
  const baseSetDisplayNameWithOffice = t(
    'automatedProgramFilter:selectedFilterList.baseSet',
    {
      displayName,
      officeName
    }
  );

  return (
    <List id="selectedFiltersList">
      <SelectedFilterListItem key="fart" name={baseSetDisplayNameWithOffice} />
      {translatedFilters.map(f => (
        <SelectedFilterListItem key={snakeCase(filterToString(f))} filter={f} />
      ))}
    </List>
  );
};

export default SelectedFilterList;
