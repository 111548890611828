import {
  filtersToProgramName,
  ContentSetFieldMetadata
} from 'src/components/AutomatedProgramFilter/helpers';
import { SupervisorOrder } from 'src/generated/gql/graphql';
import { AutomatedProgramFilterType } from 'src/components/AutomatedProgramFilter/constants';

interface GetInitialConfigureStepValuesArgs {
  preselectedBusinessObjectIds: string[];
  filters: AutomatedProgramFilterType[];
  contentName: string;
  fieldMetadata: ContentSetFieldMetadata[];
  isAutomationCreate: boolean;
  isAutomationEdit: boolean;
  automation: SupervisorOrder;
  isDraft: boolean;
  draftOrder: any;
}

// Get configure step intial form values
export const getInitialConfigureStepValues = ({
  preselectedBusinessObjectIds,
  filters,
  contentName,
  fieldMetadata,
  isAutomationCreate,
  isAutomationEdit,
  automation,
  isDraft,
  draftOrder
}: GetInitialConfigureStepValuesArgs) => {
  return {
    businessObjectSelector: preselectedBusinessObjectIds,
    automatedPrograms: {
      filters,
      baseSet: {
        displayName: `New ${contentName}`,
        id: 'someId123'
      }
    },
    ...(((filters.length > 0 && !isAutomationEdit) || isAutomationCreate) && {
      automatedProgramName: filtersToProgramName(
        filters,
        fieldMetadata,
        contentName
      )
    }),
    ...(isAutomationEdit && {
      automatedProgramName: automation?.name,
      automatedProgramNameCustom: true
    }),
    ...(isDraft &&
      draftOrder?.name && {
        automatedProgramName: draftOrder?.name,
        automatedProgramNameCustom: true
      })
  };
};
