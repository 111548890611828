import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import { Box, useTheme } from '@mui/system';

import {
  OrderForProgramPerformanceQuery,
  SupervisorOrder
} from 'src/generated/gql/graphql';

import {
  getStatusColor,
  getStatusText,
  getAutomationStatusText,
  getStatusColorAutomation
} from './Constants';

interface AdStatusProps {
  order?: OrderForProgramPerformanceQuery['order'] | SupervisorOrder;
  hideHelpTooltip?: boolean;
  renderTextStatus?: boolean;
  tableView?: boolean;
  isAutomation?: boolean;
}

const AdStatus = ({
  order,
  hideHelpTooltip = false,
  renderTextStatus = false,
  tableView = false,
  isAutomation = false
}: AdStatusProps) => {
  const theme = useTheme();

  let text = '';
  let statusColor = '#000000';

  const pendingMessage =
    order && 'orderItem' in order
      ? order?.orderItem?.product?.messaging?.statePendingHelpText
      : '';
  const orderStatus =
    order && 'orderItem' in order ? order?.orderItem?.status : '';
  const isProgramActive =
    order && 'isProgramActive' in order ? order.isProgramActive : false;

  text = getStatusText({
    isProgramActive,
    orderStatus
  });

  statusColor = getStatusColor({
    isProgramActive,
    orderStatus,
    theme
  });

  if (isAutomation) {
    text = getAutomationStatusText({
      deactivatedAt:
        order && 'deactivatedAt' in order ? order?.deactivatedAt : undefined,
      orderItem: order && 'orderItem' in order ? order?.orderItem : undefined
    });

    statusColor = getStatusColorAutomation({
      theme,
      deactivatedAt:
        order && 'deactivatedAt' in order ? order?.deactivatedAt : undefined,
      orderItem: order && 'orderItem' in order ? order?.orderItem : undefined
    });
  }

  return (
    <>
      <Tooltip title={renderTextStatus ? '' : text}>
        <Box
          component="span"
          sx={{ fontSize: '20px', cursor: 'default', color: statusColor }}
        >
          ●{' '}
          {renderTextStatus && (
            <Box component="span" sx={{ fontSize: '14px' }}>
              {tableView ? `${text}` : `(${text})`}
            </Box>
          )}
        </Box>
      </Tooltip>
      {!hideHelpTooltip && pendingMessage && orderStatus === 'pending' && (
        <Tooltip
          sx={{ transform: 'translateY(5px)', marginLeft: '4px' }}
          title={pendingMessage}
        >
          <HelpIcon fontSize="small" />
        </Tooltip>
      )}
    </>
  );
};

export default AdStatus;
