import { gql } from 'src/generated/gql';

export const createOrUpdateWebhookSubscription = gql(`
  mutation createOrUpdateWebhookSubscription(
    $url: String!
    $authenticationParameters: JSON
  ) {
    createOrUpdateWebhookSubscription(
      url: $url
      authenticationParameters: $authenticationParameters
    ) {
      id
      organizationId
      url
      hasAuthenticationParameters
    }
  }
`);

export const deleteWebhookSubscription = gql(`
  mutation DeleteWebhookSubscription {
    deleteWebhookSubscription
  }
`);

export const sendTestFacebookLeadWebhookNotification = gql(`
  mutation SendTestFacebookLeadWebhookNotification(
    $productId: ID!
    $catalogId: ID!
    $catalogGroupKey: String!
    $contentItemId: String!
    $onBehalfOfUserExternalId: String
    $onBehalfOfGroupExternalId: String
    $isDryRun: Boolean
  ) {
    sendTestFacebookLeadWebhookNotification(
      productId: $productId
      catalogId: $catalogId
      catalogGroupKey: $catalogGroupKey
      contentItemId: $contentItemId
      onBehalfOfUserExternalId: $onBehalfOfUserExternalId
      onBehalfOfGroupExternalId: $onBehalfOfGroupExternalId
      isDryRun: $isDryRun
    ) {
      message
    }
  }
`);
