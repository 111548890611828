import { History } from 'history';
import { t } from 'i18next';

import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';
import { PlaceMultiLocationProgramMutation } from 'src/generated/gql/graphql';
import { scheduleTypes } from 'src/pages/Program/Constants';
import { calcMLPTotal } from 'src/pages/Program/ProgramSteps/PriceSummary/helpers';
import { TrackUserflowEvent } from 'src/PostAuthGlobals';

import { submitProgramPropsInterface } from './useSubmitHandlerByType';
import { trackMlpOrderPlacement } from './trackOrders';
import {
  extractProgramMutationParams,
  createMlpMutiationParams
} from './utils';

// TODO: update type interface
export interface submitCreateProgramDataInterface {
  [any: string]: any;
}

export interface createMlpResponseInterface {
  data: PlaceMultiLocationProgramMutation;
}

export interface createProgramSubmitDataInterface {
  data: submitCreateProgramDataInterface;
  programProps: submitProgramPropsInterface;
  draftId?: string;
  mutation: any;
  history: History;
  enqueueSnackbar: any;
  trackUserflowEvent: TrackUserflowEvent;
}

// type LocationProgramInput = {
//   locationId: string;
//   amountOverride?: number;
//   tierIdOverride?: string;
//   variableValuesOverride?: any;
//   scheduleStartEpochSecondsOverride?: number;
//   scheduleEndEpochSecondsOverride?: number;
// };

// type PlaceMultiLocationProgramInput = {
//   offerId: string;
//   paymentMethodId: string;
//   defaultOrderAmount: number;
//   defaultTierId: string;
//   scheduleStartEpochSeconds: number;
//   scheduleEndEpochSeconds: number;
//   catalogId: string;
//   catalogFilter: any;
//   variableValues: any;
//   timeZoneRegionName: string;
//   name?: string;
//   locations?: [LocationProgramInput];
// };

export const createMultiLocationProgramHandleSubmit = async ({
  data,
  programProps,
  draftId,
  mutation,
  history,
  enqueueSnackbar,
  trackUserflowEvent
}: createProgramSubmitDataInterface) => {
  const {
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    userMetadataFields
  } = programProps;

  const mutationParams = extractProgramMutationParams({
    data,
    architecture,
    selectedBlueprint,
    selectedBusinessObjects,
    userMetadataFields,
    draftId
  });

  const isOneTimePurchase =
    data.spendStep.scheduleType === scheduleTypes.purchase.value;
  const isSubscription =
    data.spendStep.scheduleType === scheduleTypes.subscription.value;

  const multiLocationProgramInput: any = createMlpMutiationParams({
    data,
    isOneTimePurchase,
    isSubscription,
    mutationParams
  });

  // ask backend do we need any of these?
  delete multiLocationProgramInput.aiGeneratedText;
  delete multiLocationProgramInput.aiGeneratedTextModified;
  delete multiLocationProgramInput.architectureId;
  delete multiLocationProgramInput.catalogGroupBy;
  delete multiLocationProgramInput.validatePaymentMethod;
  delete multiLocationProgramInput.chosenStripePlanId;

  let response: createMlpResponseInterface;

  const postErrorMessage = t('programCreate:snackbar.submitError');

  try {
    response = await mutation({
      variables: {
        input: multiLocationProgramInput
      }
    });
  } catch (error) {
    // eslint-disable-next-line no-throw-literal
    throw {
      error,
      postErrorMessage
    };
  }

  const summedPriceAmount = calcMLPTotal({
    oneTimeSpend: data?.spendStep?.oneTimeSpend,
    subscriptionSpend: data?.spendStep?.subscriptionSpend,
    subscription: data?.spendStep?.subscription,
    numLocations: data?.selectedLocations?.length || 0,
    locationOverridesByID: data?.locationsOverrideById,
    isSubscription:
      data?.spendStep?.scheduleType === scheduleTypes.subscription.value,
    billingMethod: data?.spendStep?.billingMethod,
    selectedBlueprint
  });

  trackUserflowEvent(events => events.PurchaseClicked);

  trackMlpOrderPlacement({
    mutationParams,
    response,
    selectedBlueprint,
    isClone: false,
    summedPriceAmount
  });

  return {
    response,
    afterSuccess: () => {
      enqueueSnackbar(t('programCreate:snackbar.orderSuccess'), {
        variant: 'success',
        persist: false
      });

      history.push(
        generateLinkPath(paths.architecture.multiLocationProgram, {
          architectureId:
            response?.data?.placeMultiLocationProgram?.architectureId,
          orderId: response?.data?.placeMultiLocationProgram?.id
        })
      );
    }
  };
};
