import { isString, isEmpty, isNil, reject } from 'lodash';
import { MultiLocationProgramDraftOverrideModel } from 'src/generated/gql/graphql';

import {
  PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';

import { getInitialSpendStepValues } from './spendStep';

interface GetInitialLocationOverrideByIdArgs {
  mlpParentToClone: any | undefined;
  cloneChildLocationId: string | string[] | undefined;
  startDate: string;
  endDate: string;
  offersChanged: boolean;
  selectedBlueprint: any;
  architecture: any;
  isAutomated: boolean;
  isDraft: boolean;
}
interface GetInitialLocationIdsArgs {
  mlpParentToClone: any | undefined;
  cloneChildLocationId: string | string[] | undefined;
  overrideIds?: string[];
}

interface Edge {
  node: {
    multiLocationChildProgramDetails: {
      locationId: string;
      variableValuesAreOverridden: boolean;
      scheduleIsOverridden: boolean;
      budgetIsOverridden: boolean;
    };
    orderItem: {
      variableValues: { [key: string]: string };
    };
  };
}

interface Accum {
  [locationId: string]: {
    [PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME]: {
      [key: string]: string;
    };
    [PROGRAM_FORM_SECTION_SPEND_NAME]: {
      [key: string]: string | number | undefined;
    };
  };
}

export const getInitialLocationOverrideById = ({
  mlpParentToClone,
  cloneChildLocationId,
  startDate,
  endDate,
  selectedBlueprint,
  offersChanged,
  architecture,
  isAutomated
}: GetInitialLocationOverrideByIdArgs) => {
  // checking string here because technically you can send many in the
  // query params and we only support one or the parent
  if (cloneChildLocationId && isString(cloneChildLocationId)) {
    // cloning a child location makes the default values the same as the child location so we don't need an override
    return {};
  }

  if (mlpParentToClone) {
    const locationOverrideById = mlpParentToClone?.childOrders?.edges.reduce(
      (accum: Accum, edge: Edge): Accum => {
        const newAccum = { ...accum };

        const { multiLocationChildProgramDetails } = edge.node;

        const hasOverrides =
          multiLocationChildProgramDetails.variableValuesAreOverridden ||
          multiLocationChildProgramDetails.scheduleIsOverridden ||
          multiLocationChildProgramDetails.budgetIsOverridden;

        if (hasOverrides) {
          newAccum[multiLocationChildProgramDetails.locationId] = {
            [PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME]: {
              ...edge.node.orderItem.variableValues
            },
            [PROGRAM_FORM_SECTION_SPEND_NAME]: getInitialSpendStepValues({
              startDate,
              endDate,
              preselectedPromoCodes: [],
              orderToClone: edge.node,
              offersChanged,
              selectedBlueprint,
              architecture,
              isAutomated,
              isDraft: false
            })
          };
        }
        return newAccum;
      },
      {} as Accum
    );

    return locationOverrideById;
  }

  return {};
};

export const getInitialLocationIds = ({
  mlpParentToClone,
  cloneChildLocationId,
  overrideIds
}: GetInitialLocationIdsArgs) => {
  if (overrideIds) {
    return overrideIds;
  }

  if (cloneChildLocationId && isString(cloneChildLocationId)) {
    // clone a single child order
    return [cloneChildLocationId];
  }

  if (mlpParentToClone) {
    // clone the parent order
    const locationIds = mlpParentToClone?.childOrders?.edges.map(
      (edge: {
        node: { multiLocationChildProgramDetails: { locationId: string } };
      }) => edge?.node?.multiLocationChildProgramDetails?.locationId
    );

    return locationIds;
  }

  return [];
};

const checkNilOrEmpty = (value: any) => isNil(value) || isEmpty(value);

export const getDraftOverrrides = (
  overrides: Partial<MultiLocationProgramDraftOverrideModel>[]
) => {
  // the backend is sending us empty overrides for some reason :(
  const cleanOverrides = reject(
    overrides,
    override =>
      checkNilOrEmpty(override?.priceAmount) &&
      checkNilOrEmpty(override?.tierId) &&
      checkNilOrEmpty(override?.variableValues) &&
      checkNilOrEmpty(override?.priceAmount)
  );
  return cleanOverrides.reduce((accum: Accum, override) => {
    const newAccum = { ...accum };
    if (!override?.locationId) {
      return newAccum;
    }
    newAccum[override.locationId] = {
      [PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME]: {
        ...override?.variableValues
      },
      [PROGRAM_FORM_SECTION_SPEND_NAME]: {
        ...(override?.priceAmount && { oneTimeSpend: override?.priceAmount }),
        ...(override?.tierId && { subscription: `tier:${override?.tierId}` }),
        ...(override?.priceAmount &&
          override?.tierId && { subscriptionSpend: override?.priceAmount })
      }
    };
    return newAccum;
  }, {} as Accum);
};
