import { Typography, Link } from '@mui/material';
import { Trans } from 'react-i18next';

const PreviewDrawerFooter = ({ supportPhone, supportEmail }) => {
  const emailLinkComponent = <Link href={`mailto:${supportEmail}`} />;
  const phoneLinkComponent = <Link href={`tel:${supportPhone}`} />;
  return (
    <Typography
      variant="body2"
      sx={{ fontWeight: 500, textAlign: 'center', whiteSpace: 'normal' }}
    >
      {supportEmail && supportPhone && (
        <Trans
          i18nKey="programPreviewDrawer:footer.fullSupport"
          values={{ supportEmail, supportPhone }}
          components={[emailLinkComponent, phoneLinkComponent]}
        />
      )}
      {supportEmail && !supportPhone && (
        <Trans
          i18nKey="programPreviewDrawer:footer.emailSupport"
          values={{ supportEmail }}
          components={[emailLinkComponent]}
        />
      )}
      {!supportEmail && supportPhone && (
        <Trans
          i18nKey="programPreviewDrawer:footer.phoneSupport"
          values={{ supportPhone }}
          components={[phoneLinkComponent]}
        />
      )}
    </Typography>
  );
};

export default PreviewDrawerFooter;
