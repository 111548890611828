import { Link } from 'react-router-dom';

import ListAlt from '@mui/icons-material/ListAlt';

import { Typography, Button, CircularProgress, Box } from '@mui/material';

const TableEmptyState = ({
  buttonTo = null,
  buttonText = null,
  customButton = null,
  emptyMessage,
  loading = false
}) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme => `${theme.spacing(3)} 0`,
        gap: 3
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <ListAlt sx={{ height: '100px', width: '100px', mb: -1.5 }} />
          {emptyMessage && (
            <Typography
              sx={{ flexGrow: 1, textAlign: 'center' }}
              component="h3"
              variant="h5"
              fontWeight={400}
            >
              {emptyMessage}
            </Typography>
          )}
          {buttonTo && buttonText ? (
            <Button
              sx={{ mt: 3 }}
              color="primary"
              component={Link}
              size="large"
              to={buttonTo}
              variant="outlined"
            >
              {buttonText}
            </Button>
          ) : (
            customButton
          )}
        </>
      )}
    </Box>
  );
};

export default TableEmptyState;
