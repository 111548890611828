import { gql } from 'src/generated/gql';

export interface GoogleAccount {
  id: string;
  managerAccountId: string;
  customerAccountId: string;
  name: string;
  description?: string | null;
  isActive: boolean;
}

export const fetchGoogleAccountsQuery = gql(`
query GoogleAccounts {
    googleAccounts(filter: { showInactive: true }) {
        id
        managerAccountId
        customerAccountId
        name
        description
        isActive
    }
}
`);

export const upsertGoogleAccountMutation = gql(`
  mutation UpsertGoogleAccount($input: UpsertGoogleAccountInput!) {
    upsertGoogleAccount(
        input: $input
    ) {
        id
        channelCode
        managerAccountId
        customerAccountId
        name
        description
        isActive
        createdAt
        updatedAt
    }
}
`);
