import { get } from 'lodash';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { Breadcrumb } from '@sentry/browser';
import Logger from './Logger';

const sentryOptions = {
  environment: import.meta.env.EVOCALIZE_SENTRY_ENVIRONMENT || 'development',
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2
};

interface Me {
  id: string;
  name: string;
  organizationId: string;
}

class SentryUtil {
  static setUserContext = (me: Me) => {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: get(me, 'id'),
        name: get(me, 'name'),
        organizationId: get(me, 'organizationId')
      });
    });
  };

  static addBreadcrumb = (breadcrumb: Breadcrumb) => {
    Sentry.addBreadcrumb(breadcrumb);
  };

  static captureException = (err: Error, context?: any) => {
    Logger.debug('handleException with Sentry: ', err);
    Sentry.captureException(err, context);
  };

  /**
   * Sets the context for the given name in the sentry object.
   * Checkout this page for more info:
   * https://docs.sentry.io/platforms/javascript/enriching-events/context
   */
  static addContext = (name: string, context: Record<string, unknown>) => {
    Sentry.setContext(name, context);
  };

  static install = () => {
    // Don't initialize Sentry if we don't have a DSN.
    if (!import.meta.env.EVOCALIZE_SENTRY_DSN) {
      return;
    }

    try {
      Sentry.init({
        dsn: import.meta.env.EVOCALIZE_SENTRY_DSN,
        ...sentryOptions
      });
    } catch (err) {
      Logger.debug('Error setting up Raven: ', err);
    }

    // Handle uncaught Promise exceptions from bluebird.
    window.onunhandledrejection = eventOrError => {
      let errObject = eventOrError;

      if (eventOrError instanceof Error) {
        errObject = eventOrError;
      } else if (eventOrError instanceof Event) {
        // Attempt to extract an actual Error object from the event.
        const extracted =
          get(eventOrError, 'reason') || get(eventOrError, 'detail.reason');
        if (extracted) {
          errObject = extracted;
        }
      }

      Sentry.captureException(errObject);
    };
  };

  static showReportDialog = () => {
    Sentry.showReportDialog();
  };
}

export default SentryUtil;
