import { StripeElements } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

interface UseSetStripeElementsArgs {
  elements: StripeElements | null;
}

const useSetStripeElementLocale = ({ elements }: UseSetStripeElementsArgs) => {
  const { i18n } = useTranslation();

  if (elements) {
    elements.update({ locale: i18n.language });
  }
};

export default useSetStripeElementLocale;
