import { forEach, isArray, mapKeys, clone, isEmpty } from 'lodash';
import { translateMaps, isTemplate } from 'src/common/templateTranslator';

export interface FormValues {
  [key: string]: any;
}

export interface UserMetaDataFields {
  [key: string]: string;
}

interface ConditionalOptions {
  name: string;
  value: string;
}

interface ConditionalInputsVisibility {
  [key: string]: {
    isVisible: boolean;
    conditionalRender: ConditionalOptions[];
  };
}

export interface Input {
  blueprintVariable: any;
  blueprintVariableId: string;
  defaultValue: string;
  displayMethodId: string;
  displayName: string;
  displayParameters: { inputData: any };
  displaySortOrder: string;
  fieldName: string;
  friendlyName: string;
  isEditable: boolean;
  isExpressionAllowed: boolean;
  isHidden: boolean;
  isMultiInput: boolean;
  isRequired: boolean;
  productId: string;
  sectionId: string;
}

export interface BusinessObjects {
  [key: string]: string;
}

export const findFormValueByKey = (
  formValues: FormValues,
  keyToFind: string
) => {
  if (!formValues || !keyToFind) {
    return;
  }

  let value;

  Object.keys(formValues).some(key => {
    if (key === keyToFind) {
      value = formValues[key];
      return true;
    }

    if (formValues[key] && typeof formValues[key] === 'object') {
      value = findFormValueByKey(formValues[key], keyToFind);
      return value !== undefined;
    }

    return false;
  });

  return value;
};

export const isConditionalVisible = (data: {
  inputMetadata: Input;
  formValues: FormValues;
  conditionalRender: ConditionalOptions[];
  userMetadataFields: UserMetaDataFields;
  businessObjects: BusinessObjects[];
  selectedLocationsMetadata?: Record<string, any>[];
}) => {
  const {
    inputMetadata,
    formValues,
    conditionalRender,
    userMetadataFields = [],
    businessObjects = [],
    selectedLocationsMetadata = []
  } = data;

  if (!inputMetadata || !formValues || !conditionalRender) {
    return false;
  }

  // we need to support legacy conditionalRender that only had one conditional vs an array of them
  const conditionals = isArray(conditionalRender)
    ? conditionalRender
    : [conditionalRender];

  let isVisible = false;

  const boList = businessObjects.length > 0 ? businessObjects : [{}];
  const locationList =
    selectedLocationsMetadata.length > 0 ? selectedLocationsMetadata : [{}];

  boList.forEach(businessObject => {
    locationList.forEach(locationMetadata => {
      // combine translation values for the current combination
      const translationValues = {
        ...businessObject,
        ...userMetadataFields,
        ...locationMetadata,
        __var__: formValues?.dynamicUserInputs
      };

      // iterate through each conditional
      forEach(conditionals, condition => {
        // Find the value of the input
        let conditionalParentValue = findFormValueByKey(
          formValues,
          condition?.name
        );

        // find the value that determines if we show the conditional input
        let conditionalMatchValue = condition?.value;

        if (condition?.name === 'evSpecialInputConditional') {
          // handle special input conditional
          // ex: {{present image_5}} - this will return true if image_5 is present
          const translatedValue = translateMaps(
            conditionalMatchValue,
            translationValues
          );

          // convert handlebars boolean result to actual boolean
          isVisible = translatedValue === 'true';
          return false; // break out of forEach
        }

        if (isTemplate(conditionalParentValue)) {
          const translatedValueParentValue = translateMaps(
            conditionalParentValue,
            translationValues
          );

          conditionalParentValue = translatedValueParentValue;
        }

        if (isTemplate(conditionalMatchValue)) {
          const translatedValueMatchValue = translateMaps(
            conditionalMatchValue,
            translationValues
          );

          conditionalMatchValue = translatedValueMatchValue;
        }

        if (conditionalParentValue === conditionalMatchValue) {
          isVisible = true;
          return false; // break out of forEach
        }
      });

      if (isVisible) return false; // exit early if visible is true
    });

    if (isVisible) return false; // exit early if visible is true
  });

  return isVisible;
};

const getConditionalInputVisibilityFromInputs = (
  inputs: Input[],
  blueprint: any,
  formValues: FormValues,
  userMetadataFields: UserMetaDataFields,
  businessObjects: BusinessObjects[],
  selectedLocationsMetadata?: Record<string, any>[]
) => {
  const conditionalInputsVisibility: ConditionalInputsVisibility = {};

  inputs.forEach(input => {
    const conditionalRender =
      input?.displayParameters?.inputData?.conditionalRender;

    if (conditionalRender && !isEmpty(conditionalRender)) {
      const isVisible = isConditionalVisible({
        inputMetadata: input,
        formValues,
        conditionalRender,
        userMetadataFields,
        businessObjects,
        selectedLocationsMetadata
      });

      conditionalInputsVisibility[input.fieldName] = {
        isVisible,
        conditionalRender
      };
    }
  });

  return conditionalInputsVisibility;
};

export const getConditionalInputVisibilityFromBlueprint = (
  blueprint: any,
  formValues: FormValues,
  userMetadataFields: UserMetaDataFields,
  businessObjects: BusinessObjects[],
  selectedLocationsMetadata?: Record<string, any>[]
) => {
  return blueprint?.inputSections.reduce(
    (acc: ConditionalInputsVisibility, section: any) => {
      return {
        ...acc,
        ...getConditionalInputVisibilityFromInputs(
          section.inputFields,
          blueprint,
          formValues,
          userMetadataFields,
          businessObjects,
          selectedLocationsMetadata
        )
      };
    },
    {}
  );
};

export const filterOutConditionalInputs = (
  conditionalInputsVisibility: ConditionalInputsVisibility,
  formValues: FormValues
) => {
  const updatedFromValues = clone(formValues);

  // check if input is visible and remove the form values accordingly
  mapKeys(conditionalInputsVisibility, (value, key) => {
    if (!value.isVisible) {
      delete updatedFromValues[key];
    }
  });

  return updatedFromValues;
};
