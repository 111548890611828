import { History } from 'history';
import querystring from 'query-string';

import { paths } from 'src/routes/paths';
import { generateLinkPathWithQueryParams } from 'src/routes/RouteUtil';
import { OrderForProgram } from 'src/pages/Programs/queries';
import { isEmpty } from 'lodash';

interface NavigateToCloneProgramArgs {
  history: History;
  order: OrderForProgram;
}

type CloneSearchParams = {
  clone: boolean;
  productIds: string;
  orderId?: string;
  contentIds?: string[];
  locationId?: string;
};

export const navigateToCloneProgram = ({
  history,
  order
}: NavigateToCloneProgramArgs) => {
  const contentIds =
    order?.orderItem?.catalogItems?.edges?.[0]?.node?.items?.map(
      item => item.id
    );

  const locationId = order?.multiLocationChildProgramDetails?.locationId;

  const searchParams: CloneSearchParams = {
    clone: true,
    productIds: order?.products?.[0]?.id,
    orderId: order?.id,
    ...(locationId && { locationId })
  };

  if (!isEmpty(contentIds)) {
    searchParams.contentIds = contentIds;
  }

  history.push(
    generateLinkPathWithQueryParams(
      paths.architecture.programCreate,
      {
        architectureId: order?.architectureId
      },
      searchParams
    )
  );
};

interface HandleCloneProgramArgs {
  history: History;
  order: OrderForProgram;
  setModalOpen: (order: OrderForProgram) => void;
  bypassCloneProgramModal: boolean;
}

export const handleCloneProgram = ({
  history,
  order,
  setModalOpen,
  bypassCloneProgramModal
}: HandleCloneProgramArgs) => {
  if (bypassCloneProgramModal) {
    navigateToCloneProgram({ history, order });
  } else {
    // Open the confirmation modal
    setModalOpen(order);
  }
};

export const isProgramClone = (search?: string) => {
  if (!search) return false;

  const params = querystring.parse(search) || {};

  return Boolean(params.clone);
};
