import { flow } from 'lodash';
import withStyles from '@mui/styles/withStyles';
import { Skeleton } from '@mui/material';
import { styles as FacebookAdStyles } from './FacebookAdPreview';
import { styles as FacebookAdCardStyles } from './FacebookAdPreviewCard';

const FacebookAdPreviewSkeleton = props => {
  const { classes } = props;
  return (
    <>
      <div className={classes.preview}>
        <div className={classes.previewHeader} style={{ gridArea: '1 / 1' }}>
          <Skeleton width={50} />
        </div>
        <div className={classes.companyRow} style={{ gridArea: '2 / 1' }}>
          <div
            className={classes.companyNameContainer}
            style={{ gridArea: '1 / 1' }}
          >
            <div style={{ display: 'inline-block' }}>
              <Skeleton variant="rectangular" width={25} height={25} />
            </div>
            <div
              className={classes.companyNameInline}
              style={{ marginLeft: '10px' }}
            >
              <div className={classes.companyName}>
                <Skeleton width={50} />
              </div>
              <Skeleton width={50} />
            </div>
          </div>
        </div>
        <div
          className={classes.bodyText}
          style={{ gridArea: '3 / 1', marginTop: '10px' }}
        >
          <Skeleton width={200} />
        </div>
        <div className={classes.previewCardsWrap}>
          <div className={classes.previewCards}>
            <div style={{ width: '100%' }}>
              <Skeleton variant="rectangular" width={361} height={271} />
              <div className={classes.previewCardFooter}>
                <div className={classes.previewCardLeadAdText}>
                  <Skeleton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default flow(
  withStyles(theme => ({
    ...FacebookAdStyles(theme),
    ...FacebookAdCardStyles(theme)
  }))
)(FacebookAdPreviewSkeleton);
