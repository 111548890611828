import { Divider, Typography } from '@mui/material';

import PageTitle from 'src/components/PageTitle';

interface HeadingProps {
  title?: string;
  subTitle?: string;
  pageTitle?: string;
  divider?: boolean;
}

const Heading = ({
  title,
  subTitle,
  pageTitle,
  divider = false
}: HeadingProps) => {
  return (
    <>
      {pageTitle && <PageTitle subPageTitle={pageTitle} />}

      {title && (
        <Typography
          variant="h1"
          sx={{
            fontSize: '30px',
            fontWeight: '600',
            marginBottom: '4px'
          }}
        >
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography
          variant="body1"
          sx={{ fontSize: '15px', marginBottom: theme => theme.spacing(2) }}
        >
          {subTitle}
        </Typography>
      )}
      {divider && <Divider sx={{ marginBottom: theme => theme.spacing(2) }} />}
    </>
  );
};

export default Heading;
