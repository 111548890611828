import { t } from 'i18next';

import { IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import { paths } from 'src/routes/paths';
import {
  validateRequired,
  validateUrl,
  validateEmail,
  validatePhone,
  validateIsNumber,
  validateIsInt,
  isHexColor,
  validateUrlFqdnOrg
} from 'src/common/validations';
import { validateSkinAssetUrl } from './validations';

export const FORM_NAME = 'skinSettings';
export const FORM_NAME_CNAME = 'cnameForm';
export const SKIN_PREVIEW_LOCAL_STORAGE = 'evSkinPreviewMode';

export const skinSections = {
  cname: () => t('adminSkinSettings:sections.cname'),
  general: () => t('adminSkinSettings:sections.general'),
  palette: () => t('adminSkinSettings:sections.palette'),
  auth: () => t('adminSkinSettings:sections.auth'),
  support: () => t('adminSkinSettings:sections.support'),
  fonts: () => t('adminSkinSettings:sections.typography'),
  strings: () => t('adminSkinSettings:sections.strings'),
  trackers: () => t('adminSkinSettings:sections.trackers'),
  features: () => t('adminSkinSettings:sections.features'),
  assets: () => t('adminSkinSettings:sections.assets'),
  size: () => t('adminSkinSettings:sections.size')
};

export const cnameInputs = () => [
  {
    name: 'domains',
    displayName: t(
      'admin:skinSettings.cnameInputs.domains.displayName',
      'Organization CNAMEs'
    ),
    displayMethodId: null,
    initialValue: false,
    reduxValidations: [],
    isHidden: false,
    isMultiInput: true,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        allowDelete: true
      }
    },
    childInputs: [
      {
        name: 'fqdn',
        displayName: t(
          'admin:skinSettings.cnameInputs.fdqn.displayName',
          'Fully Qualified Domain Name'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [validateRequired, validateUrlFqdnOrg()],
        isRequired: false,
        isHidden: false,
        displayParameters: {
          inputData: {
            columnWidth: 4
          }
        }
      },
      {
        name: 'authenticationUrl',
        displayName: t(
          'admin:skinSettings.cnameInputs.authenticationUrl.displayName',
          'Authentication URL'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [],
        isRequired: false,
        isHidden: false,
        displayParameters: {
          inputData: {
            columnWidth: 4
          }
        }
      },
      {
        name: 'logoutUrl',
        displayName: t(
          'admin:skinSettings.cnameInputs.logoutUrl.displayName',
          'Logout URL'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [],
        isRequired: false,
        isHidden: false,
        displayParameters: {
          inputData: {
            columnWidth: 4
          }
        }
      },
      {
        name: 'id',
        displayName: ('admin:skinSettings.cnameInputs.id.displayName', 'ID'),
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [],
        isRequired: false,
        isHidden: true,
        displayParameters: {
          inputData: {
            columnWidth: 4
          }
        }
      }
    ]
  }
];

export const paletteSkinInputs = () => [
  {
    name: 'primary.main',
    displayName: t(
      'admin:skinSettings.paletteSkinInputs.primaryMain.displayName',
      'Primary Color'
    ),
    displayMethodId: INPUT_TYPES.COLOR_INPUT,
    initialValue: '',
    reduxValidations: [validateRequired, isHexColor],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'primary.contrastText',
    displayName: t(
      'admin:skinSettings.paletteSkinInputs.primaryContrastText.displayName',
      'Primary Contrast Text'
    ),
    displayMethodId: INPUT_TYPES.COLOR_INPUT,
    initialValue: '',
    reduxValidations: [validateRequired, isHexColor],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.paletteSkinInputs.primaryContrastText.tooltip',
          'Color used to contrast your primary color. So if you have a light primary color you will want dark color here. The default is white. (example case: button text)'
        )
      }
    }
  },
  {
    name: 'secondary.main',
    displayName: t(
      'admin:skinSettings.paletteSkinInputs.secondaryMain.displayName',
      'Secondary Color'
    ),
    displayMethodId: INPUT_TYPES.COLOR_INPUT,
    initialValue: '',
    reduxValidations: [validateRequired, isHexColor],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'secondary.contrastText',
    displayName: t(
      'admin:skinSettings.paletteSkinInputs.secondaryContrastText.displayName',
      'Secondary Contrast Text'
    ),
    displayMethodId: INPUT_TYPES.COLOR_INPUT,
    initialValue: '',
    reduxValidations: [validateRequired, isHexColor],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.paletteSkinInputs.secondaryContrastText.tooltip',
          'Color used to contrast your secondary color. So if you have a light primary color you will want dark color here. The default is white. (example case: button text)'
        )
      }
    }
  }
];

export const getDefaultFontList = () => [
  {
    name: t('admin:skinSettings.font.roboto'),
    value: 'Roboto, serif'
  },
  { name: t('admin:skinSettings.font.arial'), value: 'Arial, serif' },
  {
    name: t('admin:skinSettings.font.garamond'),
    value: 'Garamond, sans-serif'
  },
  {
    name: t('admin:skinSettings.font.georgia'),
    value: 'Georgia, sans-serif'
  },
  {
    name: t('admin:skinSettings.font.gillSans'),
    value: 'Gill Sans, serif'
  },
  {
    name: t('admin:skinSettings.font.helvetica'),
    value: 'Helvetica, serif'
  },
  {
    name: t('admin:skinSettings.font.tahoma'),
    value: 'Tahoma, serif'
  },
  {
    name: t('admin:skinSettings.font.timesNewRoman'),
    value: 'Times New Roman, sans-serif'
  },
  {
    name: t('admin:skinSettings.font.trebuchetMs'),
    value: 'Trebuchet MS, serif'
  },
  {
    name: t('admin:skinSettings.font.verdana'),
    value: 'Verdana, serif'
  }
];

export const FONT_INPUT_METHOD = {
  selectDefault: 'selectDefault',
  customCdn: 'customCdn'
};

export const typographySkinInputs = fontInputMethod => {
  const isCustomCdn = fontInputMethod === FONT_INPUT_METHOD.customCdn;

  let inputs = [
    {
      displayAsRow: true,
      name: 'fontInputMethod',
      displayMethodId: INPUT_TYPES.RADIO_SELECT,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 4
        },
        options: [
          {
            name: t(
              'admin:skingSettings.typographySkinInputs.existingFonts.displayName',
              'Choose from existing fonts'
            ),
            value: FONT_INPUT_METHOD.selectDefault
          },
          {
            name: t(
              'admin:skingSettings.typographySkinInputs.customFont.displayName',
              'Add a custom font'
            ),
            value: FONT_INPUT_METHOD.customCdn
          }
        ]
      }
    }
  ];

  if (!isCustomCdn) {
    inputs = [
      ...inputs,
      {
        name: 'primary.fontFamily',
        displayName: t(
          'admin:skingSettings.typographySkinInputs.fontFamily.displayName',
          'Font Family'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_SELECT,
        initialValue: '',
        reduxValidations: [validateRequired],
        isRequired: false,
        isHidden: false,
        sx: props => ({ fontFamily: props?.input?.value }),
        displayParameters: {
          inputData: {
            columnWidth: 2
          },
          options: getDefaultFontList().map(font => ({
            ...font,
            sx: option => ({ fontFamily: option?.value })
          }))
        }
      }
    ];
  } else {
    inputs = [
      ...inputs,
      {
        name: 'primary.url',
        displayName: t(
          'admin:skingSettings.typographySkinInputs.customFontUrl.displayName',
          'Custom Font URL'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [validateRequired, validateUrl()],
        isRequired: true,
        isHidden: false,
        displayParameters: {
          inputData: {
            columnWidth: 2,
            tooltip: t(
              'admin:skingSettings.typographySkinInputs.customFontUrl.tooltip',
              'At this time you will need to work with UX to get the font files and css file setup unless you have a google url like ( https://fonts.googleapis.com/css?family=Lobster ). This url can be found in the embedded section when looking at a Google font.'
            )
          }
        }
      },
      {
        name: 'primary.cdnFontFamily',
        displayName: t(
          'admin:skingSettings.typographySkinInputs.cdnFontFamily.displayName',
          'Font Family'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
        initialValue: '',
        reduxValidations: [validateRequired],
        isRequired: true,
        isHidden: false,
        displayParameters: {
          inputData: {
            columnWidth: 2
          }
        }
      }
    ];
  }

  return inputs;
};

export const evAppSkinInputs = () => ({
  general: [
    {
      name: 'apiBaseUrl',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.apiBaseUrl.displayName',
        'API Base URL'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.apiBaseUrl.tooltip',
            'This can completely break the app if incorrect please be careful'
          )
        }
      }
    },
    {
      name: 'baseTitle',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.baseTitle.displayName',
        'Base Title'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.baseTitle.tooltip',
            'First text that will show up in the browser tab. Defaults to "Office" (example: Office | Dashboard)'
          )
        }
      }
    },
    {
      name: 'appName',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.appName.displayName',
        'App Name'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.appName.tooltip',
            'Used when we are talking about the platform it defaults to CMP'
          )
        }
      }
    },
    {
      name: 'officeName',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.officeName.displayName',
        'Office / Group Name'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: 'Office',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.officeName.tooltip',
            'Used in the left navigation office selector defaults to Office'
          )
        }
      }
    },
    {
      name: 'linkBack.primary',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.linkBackPrimary.displayName',
        'Link Back Text'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.linkBackPrimary.tooltip',
            'Main link text. This will default to "Back to"'
          )
        }
      }
    },
    {
      name: 'linkBack.secondary',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.linkBackSecondary.displayName',
        'Link Back SubText'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.linkBackSecondary.tooltip',
            'Sub link text. This will need to be set to get the link to show up.'
          )
        }
      }
    },
    {
      name: 'linkBack.url',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.linkBackUrl.displayName',
        'Link Back Url'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateUrl()],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.linkBackUrl.tooltip',
            'Must be set to get the link to show up. Must have http:// -or- https:// in the link'
          )
        }
      }
    },
    {
      name: 'customMenuLinks',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.customMenuLinks.displayName',
        'Custom Menu Links'
      ),
      displayMethodId: INPUT_TYPES.CUSTOM_LINKS,
      initialValue: [],
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 4,
          actionButtonText: t(
            'admin:skinSettings.evAppSkinInputs.customMenuLinks.actionButtonText',
            'Add Custom Menu Link'
          )
        }
      }
    }
  ],
  strings: [
    {
      name: 'corpName',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.corporateName.displayName',
        'Corporate Name'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  ],
  trackers: [
    {
      name: 'facebookPixelId',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.facebookPixelId.displayName',
        'Evocalize Facebook Pixel ID'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'clientFacebookPixelId',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.clientFacebookPixelId.displayName',
        'Client Facebook Pixel ID'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'googleAnalyticsId',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.googleAnalyticsId.displayName',
        'Evocalize Google Analytics ID'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'clientGoogleAnalyticsId',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.clientGoogleAnalyticsId.displayName',
        'Client Google Analytics ID'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'clientGoogleTagManagerId',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.clientGoogleTagManagerId.displayName',
        'Client Google Tag Manager ID'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    }
  ],
  support: [
    {
      name: 'email',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.supportEmail.displayName',
        'Email'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateEmail()],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'phone',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.supportPhone.displayName',
        'Phone'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validatePhone()],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'title',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.supportTitle.displayName',
        'Title'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.supportTitle.tooltip',
            'Defaults to "Need Help?"'
          )
        }
      }
    },
    {
      name: 'subTitle',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.supportSubTitle.displayName',
        'Subtitle'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2
        }
      }
    },
    {
      name: 'faqText',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.faqText.displayName',
        'FAQ Text'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.faqText.tooltip',
            'Defaults to "FAQ"'
          )
        }
      }
    },
    {
      name: 'faqLink',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.faqLink.displayName',
        'FAQ Link'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateUrl()],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.faqLink.tooltip',
            'Must have http:// -or- https:// in the link'
          )
        }
      }
    },
    {
      name: 'linkText',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.supportLinkText.displayName',
        'Link Text'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.supportLinkText.tooltip',
            'Default is "Contact Support"'
          )
        }
      }
    },
    {
      name: 'link',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.supportLink.displayName',
        'Link'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateUrl()],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.supportLink.tooltip',
            'Must have http:// -or- https:// in the link'
          )
        }
      }
    },
    {
      name: 'contactText',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.contactText.displayName',
        'Contact Text'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.contactText.tooltip',
            'Default is "Contact Us"'
          )
        }
      }
    },
    {
      name: 'contactLink',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.contactLink.displayName',
        'contactLink'
      ),
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateUrl()],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.contactLink.tooltip',
            'Must have http:// -or- https:// in the link'
          )
        }
      }
    },
    {
      name: 'enableAppBar',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.enableAppBar.displayName',
        'Enable App Bar'
      ),
      displayMethodId: INPUT_TYPES.BOOLEAN,
      initialValue: false,
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.enableAppBar.tooltip',
            'Will turn on/off support menu above in the App Bar'
          )
        }
      }
    },
    {
      name: 'enableSideNav',
      displayName: t(
        'admin:skinSettings.evAppSkinInputs.enableSideNav.displayName',
        'Enable Side Navigation'
      ),
      displayMethodId: INPUT_TYPES.BOOLEAN,
      initialValue: false,
      reduxValidations: [],
      isRequired: false,
      isHidden: false,
      displayParameters: {
        inputData: {
          columnWidth: 2,
          tooltip: t(
            'admin:skinSettings.evAppSkinInputs.enableSideNav.tooltip',
            'Will turn on/off support menu in the side navigation'
          )
        }
      }
    }
  ]
});

export const featuresInputs = () => [
  {
    name: 'audienceUpload',
    displayName: t(
      'admin:skinSettings.featuresInputs.audienceUpload.displayName',
      'Audience Upload'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.audienceUpload.tooltip',
          'Activates the audience upload button'
        )
      }
    }
  },
  {
    name: 'loginBackLink',
    displayName: t(
      'admin:skinSettings.featuresInputs.loginBackLink.displayName',
      'Log back in link'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.loginBackLink.tooltip',
          'Provides a log back in link on the logout page'
        )
      }
    }
  },
  {
    name: 'gallery',
    displayName: t(
      'admin:skinSettings.featuresInputs.gallery.displayName',
      'Media Library'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.gallery.tooltip',
          'Enable the Media Library page'
        )
      }
    }
  },
  {
    name: 'giphySearch',
    displayName: t(
      'admin:skinSettings.featuresInputs.giphySearch.displayName',
      'Media Library: Giphy Search'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.giphySearch.tooltip',
          'Enable giphy search on the Media Library'
        )
      }
    }
  },
  {
    name: 'shutterstockSearch',
    displayName: t(
      'admin:skinSettings.featuresInputs.shutterstockSearch.displayName',
      'Media Library: Shutterstock Search'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.shutterstockSearch.tooltip',
          'Enable Shutterstock search on the Media Library'
        )
      }
    }
  },
  {
    name: 'galleryAdminImages',
    displayName: t(
      'admin:skinSettings.featuresInputs.galleryAdminImages.displayName',
      'Media Library: Shared Corporate Images'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.galleryAdminImages.tooltip',
          'Enable corporate images uploaded by organization admins to be viewed and used in the media gallery by all users.'
        )
      }
    }
  },
  {
    name: 'contacts',
    displayName: t(
      'admin:skinSettings.featuresInputs.contacts.displayName',
      'Contacts'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.contacts.tooltip',
          'Enable the contacts page'
        )
      }
    }
  },
  {
    name: 'leadNotifications',
    displayName: t(
      'admin:skinSettings.featuresInputs.leadNotifications.displayName',
      'Lead Notifications'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.leadNotifications.tooltip',
          'Enable lead notifications settings section on the notification preferences page'
        )
      }
    }
  },
  {
    name: 'audiences',
    displayName: t(
      'admin:skinSettings.featuresInputs.audiences.displayName',
      'Audiences'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.audiences.tooltip',
          'Enable the audience page'
        )
      }
    }
  },
  {
    name: 'addProgramButton',
    displayName: t(
      'admin:skinSettings.featuresInputs.addProgramButton.displayName',
      'Add Program Button'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.addProgramButton.tooltip',
          'Enable the "Add Program" button on /architecture/{id}/programs'
        )
      }
    }
  },
  {
    name: 'facebookModal',
    displayName: t(
      'admin:skinSettings.featuresInputs.facebookModal.displayName',
      'Facebook Modal'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.facebookModal.tooltip',
          'Shows Facebook modal when you login & do not have a linked account'
        )
      }
    }
  },
  {
    name: 'dashboardKpis',
    displayName: t(
      'admin:skinSettings.featuresInputs.dashboardKpis.displayName',
      'Dashboard KPIs'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxCalidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.dashboardKpis.tooltip',
          'Enable KPIs on the dashboard page'
        )
      }
    }
  },
  {
    name: 'programKpis',
    displayName: t(
      'admin:skinSettings.featuresInputs.programKpis.displayName',
      'Program KPIs'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.programKpis.tooltip',
          'Enable the KPIs on the program performance page'
        )
      }
    }
  },
  {
    name: 'showInactiveBlueprints',
    displayName: t(
      'admin:skinSettings.featuresInputs.showInactiveBlueprints.displayName',
      'Show Inactive Blueprints'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'showInactiveOffers',
    displayName: t(
      'admin:skinSettings.featuresInputs.showInactiveOffers.displayName',
      'Show Inactive Offers'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'promoCodes',
    displayName: t(
      'admin:skinSettings.featuresInputs.prommoCodes.displayName',
      'Promo Codes'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.prommoCodes.tooltip',
          'Turns on/off the promo code field'
        )
      }
    }
  },
  {
    name: 'dashboard',
    displayName: t(
      'admin:skinSettings.featuresInputs.dashboard.displayName',
      'Dashboard'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.dashboard.tooltip',
          'Turns on/off the Dashboard side navigation link'
        )
      }
    }
  },
  {
    name: 'facebook',
    displayName: t(
      'admin:skinSettings.featuresInputs.facebook.displayName',
      'Facebook'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.facebook.tooltip',
          'Turns on/off the Facebook side navigation link'
        )
      }
    }
  },
  {
    name: 'architectures',
    displayName: t(
      'admin:skinSettings.featuresInputs.architectures.displayName',
      'Architectures'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.architectures.tooltip',
          'Turns on/off all Architecture side navigation links'
        )
      }
    }
  },
  {
    name: 'officeSelector',
    displayName: t(
      'admin:skinSettings.featuresInputs.officeSelector.displayName',
      'Office Selector'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.officeSelector.tooltip',
          'Turns on/off Office Seletor side navigation dropdown'
        )
      }
    }
  },
  {
    name: 'accountMenu',
    displayName: t('admin:skinSettings.featuresInputs.accountMenu.displayName'),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t('admin:skinSettings.featuresInputs.accountMenu.tooltip')
      }
    }
  },
  {
    name: 'myAccount',
    displayName: t(
      'admin:skinSettings.featuresInputs.myAccount.displayName',
      'My Account'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.myAccount.tooltip',
          'Turns on/off My Account menu item within Account Menu'
        )
      }
    }
  },
  {
    name: 'logoClick',
    displayName: t(
      'admin:skinSettings.featuresInputs.logoClick.displayName',
      'Logo Click'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.logoClick.tooltip',
          'Turns on/off on click event on the left nav logo'
        )
      }
    }
  },
  {
    name: 'userflow',
    displayName: t(
      'admin:skinSettings.featuresInputs.userflow.displayName',
      'Enable Userflow'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.userflow.tooltip',
          'Turns on userflow user engagement tool for the organization'
        )
      }
    }
  },
  {
    name: 'extraFacebookPageLinkButtons',
    displayName: t(
      'admin:skinSettings.featuresInputs.extraFacebookPageLinkButtons.displayName',
      'Extra Facebook Page Link Buttons'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.extraFacebookPageLinkButtons.tooltip',
          'Turns on/off buttons in "What would you like to do now?" section of a successful Facebook page link'
        )
      }
    }
  },
  {
    name: 'postLoginPageOverride',
    displayName: t(
      'admin:skinSettings.featuresInputs.postLoginPageOverride.displayName',
      'Post Login Page Override'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.postLoginPageOverride.tooltip',
          'This will put the user on a specific page every time they login and not put them back on the page they were logged out of.'
        ),
        options: [
          {
            name: t(
              'admin:skinSettings.featuresInputs.postLoginPageOverride.option.noOverride',
              'No Override'
            ),
            value: null
          },
          // these are going to be relative paths
          {
            name: t(
              'admin:skinSettings.featuresInputs.postLoginPageOverride.option.dashboard',
              'Dashboard'
            ),
            value: `/#${paths.dashboard.base}`
          },
          {
            name: t(
              'admin:skinSettings.featuresInputs.postLoginPageOverride.option.facebookPages',
              'Facebook Pages'
            ),
            value: `/#${paths.facebook.pages}`
          }
        ]
      }
    }
  },
  {
    name: 'defaultSubGalleryType',
    displayName: t(
      'admin:skinSettings.featuresInputs.defaultSubGalleryType.displayName',
      'Default Selected Sub Gallery'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.defaultSubGalleryType.tooltip',
          'Default gallery that will be selected by default'
        ),
        options: [
          {
            name: t(
              'admin:skinSettings.featuresInputs.defaultSubGalleryType.option.user',
              'User'
            ),
            value: 'public'
          },
          {
            name: t(
              'admin:skinSettings.featuresInputs.defaultSubGalleryType.option.corporate',
              'Corporate'
            ),
            value: 'private'
          }
        ]
      }
    }
  },
  {
    name: 'manageCreditCards',
    displayName: t(
      'admin:skinSettings.featuresInputs.manageCreditCards.displayName',
      'Manage Credit Cards'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: true,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 1
      }
    }
  },
  {
    name: 'allowAiGeneratedContent',
    displayName: t(
      'admin:skinSettings.featuresInputs.allowAiGeneratedContent.displayName',
      'Allow AI Generated Content'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.allowAiGeneratedContent.tooltip'
        )
      }
    }
  },
  {
    name: 'showNotificationPanel',
    displayName: t(
      'admin:skinSettings.featuresInputs.showNotificationsPanel.displayName',
      'Show Notification Panel'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.featuresInputs.showNotificationsPanel.tooltip'
        )
      }
    }
  }
];

export const automationFeaturesInputs = openAutomationAdoptionConfig => [
  {
    name: 'automations',
    displayName: t(
      'admin:skinSettings.automationFeaturesInputs.automations.displayName',
      'Automations'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.automationFeaturesInputs.automations.tooltip',
          'Enables Automations UI formerly known as Automated Programs'
        )
      }
    }
  },
  {
    name: 'automationPromoCodes',
    displayName: t(
      'admin:skinSettings.automationFeaturesInputs.automationPromoCodes.displayName',
      'Automation Promo Codes'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.automationFeaturesInputs.automationPromoCodes.tooltip',
          'Enables Promo Codes for Automations'
        )
      }
    }
  },
  {
    name: 'automationAdoptionModal',
    displayName: (
      <div>
        Automation Adoption Modal{' '}
        <IconButton size="small" onClick={openAutomationAdoptionConfig}>
          <SettingsIcon />
        </IconButton>
      </div>
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.automationFeaturesInputs.automationAdoptionModal.displayName',
          'Enables Automation Adoption Modal on the Dashboard'
        )
      }
    }
  }
];

export const experimentsInputs = [];

export const evAssetsSkinInputs = () => [
  {
    name: 'logoUrl',
    displayName: t(
      'admin:skinSettings.evAssetsSkinInputs.logoUrl.displayName',
      'Logo URL'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateSkinAssetUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true,
        tooltip: t(
          'admin:skinSettings.evAssetsSkinInputs.logoUrl.tooltip',
          'Main company logo that shows up in the side navigation'
        )
      }
    }
  },
  {
    name: 'favIconUrl',
    displayName: t(
      'admin:skinSettings.evAssetsSkinInputs.favIconUrl.displayName',
      'Fav Icon URL'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateSkinAssetUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true,
        tooltip: t(
          'admin:skinSettings.evAssetsSkinInputs.favIconUrl.tooltip',
          'Icon that shows up in the browser tab. Will likely be a .ico file or .png'
        ),
        sizeConstraint: false
      }
    }
  },

  {
    name: 'tosUrl',
    displayName: t(
      'admin:skinSettings.evAssetsSkinInputs.tosUrl.displayName',
      'Terms of Service URL'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.evAssetsSkinInputs.tosUrl.tooltip',
          'Must be set to get the link to show up. Must have http:// -or- https:// in the link'
        )
      }
    }
  },
  {
    name: 'tosName',
    displayName: t(
      'admin:skinSettings.evAssetsSkinInputs.tosName.displayName',
      'Terms of Service Menu Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'privacyUrl',
    displayName: t(
      'admin:skinSettings.evAssetsSkinInputs.privacyUrl.displayName',
      'Privacy URL'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.evAssetsSkinInputs.privacyUrl.tooltip',
          'Must be set to get the link to show up. Must have http:// -or- https:// in the link'
        )
      }
    }
  },
  {
    name: 'privacyName',
    displayName: t(
      'admin:skinSettings.evAssetsSkinInputs.privacyName.displayName',
      'Privacy Menu Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

export const evAssetsQuickstarts = () => [
  {
    name: 'contentTypeIconsDefault.foreground',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsDefault.foreground.displayName',
      'Foreground Default Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true,
        tooltip: t(
          'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsDefault.foreground.tooltip',
          'Fallback foreground icon'
        )
      }
    }
  },
  {
    name: 'contentTypeIconsDefault.background',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsDefault.background.displayName',
      'Background Default Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true,
        tooltip: t(
          'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsDefault.background.tooltip',
          'Fallback background icon'
        )
      }
    }
  },
  {
    name: 'contentTypeIconsListing.foreground',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsListing.foreground.displayName',
      'Foreground Listing Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'contentTypeIconsListing.background',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsListing.background.displayName',
      'Background Listing Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'contentTypeIconsAgent.foreground',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsAgent.foreground.displayName',
      'Foreground Agent Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'contentTypeIconsAgent.background',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsAgent.background.displayName',
      'Background Agent Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'contentTypeIconsFranchise.foreground',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsFranchise.foreground.displayName',
      'Foreground Franchise Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'contentTypeIconsFranchise.background',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.contentTypeIconsFranchise.background.displayName',
      'Background Franchise Icon'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'objectiveIconsTraffic',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.objectiveIconsTraffic.displayName',
      'Objective Icon Traffic'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'objectiveIconsLeadGeneration',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.objectiveIconsLeadGeneration.displayName',
      'Objective Icon Lead Generation'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'objectiveIconsConversion',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.objectiveIconsConversion.displayName',
      'Objective Icon Conversion'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  },
  {
    name: 'objectiveIconsAwareness',
    displayName: t(
      'admin:skinSettings.evAssetsQuickstarts.objectiveIconsAwareness.displayName',
      'Objective Icon Awareness'
    ),
    displayMethodId: INPUT_TYPES.GALLERY_IMAGE_URL,
    initialValue: '',
    reduxValidations: [validateUrl()],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        internalOnly: true
      }
    }
  }
];

export const evSizesSkinInputs = () => [
  {
    name: 'navigationWidth',
    displayName: t(
      'admin:skinSettings.evSizesSkinInputs.navigationWidth.displayName',
      'Navigation Width'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '240',
    reduxValidations: [validateIsNumber, validateIsInt],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.evSizesSkinInputs.navigationWidth.tooltip',
          'Defaults to 250'
        )
      }
    }
  },
  {
    name: 'previewDrawerWidth',
    displayName: t(
      'admin:skinSettings.evSizesSkinInputs.previewDrawerWidth.displayName',
      'Preview Drawer Width'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '450',
    reduxValidations: [validateIsNumber, validateIsInt],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.evSizesSkinInputs.previewDrawerWidth.tooltip',
          'Defaults to 450'
        )
      }
    }
  },
  {
    name: 'authLogoWidth',
    displayName: t(
      'admin:skinSettings.evSizesSkinInputs.authLogoWidth.displayName',
      'Auth0 Logo Width'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.evSizesSkinInputs.authLogoWidth.tooltip',
          'Defaults to 100% !important'
        )
      }
    }
  },
  {
    name: 'authLogoHeight',
    displayName: t(
      'admin:skinSettings.evSizesSkinInputs.authLogoHeight.displayName',
      'Auth0 Logo Height'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:skinSettings.evSizesSkinInputs.authLogoHeight.tooltip',
          'Defaults to auto'
        )
      }
    }
  }
];

export const demoOverrides = {
  userLeads: {
    edges: [
      {
        node: {
          id: '1',
          facebookId: 'f1',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2019-10-15T16:59Z',
          fields: [
            {
              name: 'email',
              values: ['zoey.moran@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Zoey']
            },
            {
              name: 'last_name',
              values: ['Moran']
            },
            {
              name: 'date',
              values: ['2021-02-17T16:59Z']
            }
          ]
        }
      },
      {
        node: {
          id: '2',
          facebookId: 'f2',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2021-02-16T17:32Z',
          fields: [
            {
              name: 'email',
              values: ['sammy.rodgers@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Sammy']
            },
            {
              name: 'last_name',
              values: ['Rodgers']
            }
          ]
        }
      },
      {
        node: {
          id: '3',
          facebookId: 'f3',
          architectureId: '100101',
          programId: '417446556602556989',
          orderId: '417446556619334206',
          submittedAt: '2021-02-15T17:35Z',
          fields: [
            {
              name: 'email',
              values: ['dominique.sims@evocalize.com']
            },
            {
              name: 'phone',
              values: ['18009356891']
            },
            {
              name: 'first_name',
              values: ['Dominique']
            },
            {
              name: 'last_name',
              values: ['Sims']
            }
          ]
        }
      }
    ]
  },
  dashboardInsights: [
    {
      kpi: {
        slug: 'impressions',
        channelCode: 'fb',
        friendlyName: 'Views',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 23600,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'link_click',
        channelCode: 'fb',
        friendlyName: 'Clicks',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 349,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    }
  ],
  programInsights: [
    // Google programInsights
    {
      kpi: {
        slug: 'impressions',
        channelCode: 'google',
        friendlyName: 'Views',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 56857,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'link_click',
        channelCode: 'google',
        friendlyName: 'Clicks',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 39,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'spend',
        channelCode: 'google',
        friendlyName: 'Cost',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 119.4,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'ad_network_type',
        channelCode: 'google',
        friendlyName: 'Ad Network Type',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 'CONTENT',
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'device',
        channelCode: 'google',
        friendlyName: 'Device',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: ['MOBILE', 'DESKTOP'],
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'conversions',
        channelCode: 'google',
        friendlyName: 'Conversions',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: '9',
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'all_conversions',
        channelCode: 'google',
        friendlyName: 'All Conversions',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: '14',
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    // Facebook programInsights
    {
      kpi: {
        slug: 'impressions',
        channelCode: 'fb',
        friendlyName: 'Views',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 500,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    },
    {
      kpi: {
        slug: 'link_click',
        channelCode: 'fb',
        friendlyName: 'Clicks',
        __typename: 'KpiOutputView'
      },
      summary: {
        sum: 23,
        __typename: 'MetricSummaryView'
      },
      daily: [],
      __typename: 'MetricData'
    }
  ]
};
