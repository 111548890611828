import { gql } from 'src/generated/gql';

export const getPaymentMethods = gql(`
  query PaymentMethodForCC {
    paymentMethod {
      id
      userId
      payer
      organizationId
      officeId
      stripeSourceId
      stripeCustomerId
      stripeSource {
        cardBrand
        cardLastFour
        cardExpiryYear
        cardExpiryMonth
      }
      purchaseOrders(filter: { orderItemStatuses: [pending] }) {
        edges {
          node {
            id
            name
            architectureId
          }
        }
      }
      subscriptionOrders(filter: { orderItemStatuses: [pending, fulfilled] }) {
        edges {
          node {
            id
            name
            architectureId
          }
        }
      }
      automationOrders {
        edges {
          node {
            id
            name
            offer {
              product {
                architectureId
              }
            }
          }
        }
      }
      accessControl {
        permissions(filter: {})
      }
    }
  }
`);

export const getTeamPaymentMethods = gql(`
  query TeamPaymentMethodForCC {
    paymentMethod {
      id
      userId
      payer
      organizationId
      officeId
      stripeSourceId
      stripeCustomerId
      stripeSource {
        cardBrand
        cardLastFour
        cardExpiryYear
        cardExpiryMonth
      }
      teamCapabilities {
        isOwnedByMe
        isSharedWithMe
        isSharedWithTeam
      }
      accessControl {
        permissions(filter: {})
      }
      purchaseOrders(filter: { orderItemStatuses: [pending] }) {
        edges {
          node {
            id
            name
            architectureId
          }
        }
      }
      subscriptionOrders(filter: { orderItemStatuses: [pending, fulfilled] }) {
        edges {
          node {
            id
            name
            architectureId
          }
        }
      }
      automationOrders {
        edges {
          node {
            id
            name
            offer {
              product {
                architectureId
              }
            }
          }
        }
      }
    }
  }
`);
