import { gql } from 'src/generated/gql';

export const fetchUrlValidation = gql(`
query renderLinkUrlValidateUrl($urlData: UrlValidationRequest!) {
  validateUrl(input: $urlData) {
    result
    httpStatus
  }
}
`);
