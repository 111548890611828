import { GridFilterItem, GridColDef } from '@mui/x-data-grid-pro';

export const DEFAULT_NEW_ITEM_ID = 'new-item-id';

/**
 * Determines if the filterItem is valid.
 * @param filterItem The DataGrid filter item.
 * @returns True if the filter item is valid, false otherwise.
 */
export const isFilterItemValid = (filterItem: GridFilterItem) =>
  !!filterItem.value ||
  (filterItem as any).operatorValue === 'isEmpty' ||
  (filterItem as any).operatorValue === 'isNotEmpty';

/**
 * Columns to filter out.
 * checkbox / radio column
 * custom actions / menus column
 */
export const columnsToFilterOut = [
  '__check__',
  'customAction',
  'actions',
  'actionMenu',
  'icons',
  'MLPMENU'
];

export const getColumnNamesFiltered = (columns: GridColDef[]) => {
  const finalColumns: string[] = columns
    .filter(column => !columnsToFilterOut.includes(column.field))
    .map(column => column.field);

  return finalColumns;
};
