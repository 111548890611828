export const PROGRAM_TABLE_FIELDS = {
  theme: 'theme',
  type: 'type',
  id: 'id',
  name: 'name',
  status: 'status',
  billingDetails: 'billingDetails',
  startDate: 'startDate',
  endDate: 'endDate'
};

export const DATE_FORMAT = 'L';
