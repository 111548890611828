import {
  OptionsObject,
  ProviderContext,
  SnackbarKey,
  SnackbarMessage
} from 'notistack';

export interface SnackbarNotification {
  message: SnackbarMessage;
  options?: OptionsObject;
}

export interface SnackbarState extends SnackbarNotification {
  key: number;
}

export interface SnackbarEnqueueAction {
  type: 'ENQUEUE_SNACKBAR';
  notification: SnackbarState;
}

export interface SnackbarRemoveAction {
  type: 'REMOVE_SNACKBAR';
  key: SnackbarKey;
}

export const enqueueSnackbar = (
  notification: SnackbarNotification
): SnackbarEnqueueAction => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification
  }
});

export const removeSnackbar = (key: SnackbarKey): SnackbarRemoveAction => ({
  type: 'REMOVE_SNACKBAR',
  key
});

export interface WithEnqueueSnackbar {
  enqueueSnackbar: ProviderContext['enqueueSnackbar'];
}
