import { t } from 'i18next';

import { Box, Chip, Grid, Typography } from '@mui/material';
import {
  LocalOfferOutlined as TagIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { formatPromoPrice } from 'src/components/PromoCode/helpers';

import { PROMO_SUMMARY_VARIANT } from './constants';

interface PromoCode {
  promoCode: string;
  discountAmount: number;
  promoDescription?: string;
}

interface PromoCodeListProps {
  removePromo: () => void;
  promoCodes?: PromoCode[];
  spendAmount: number;
}

const pageText = () => {
  return {
    promoCode: t('programCreate:priceSummary.promoCode')
  };
};

const PromoCodeList = ({
  promoCodes = [],
  removePromo,
  spendAmount
}: PromoCodeListProps) => {
  const text = pageText();

  return (
    <Box component="ul" data-cy="promoCode-list" sx={{ m: 0, p: 0 }}>
      {promoCodes.map(promoCode => {
        return (
          <Grid
            container
            key={promoCode.promoCode}
            component="li"
            columnSpacing={2}
          >
            <Grid item xs={7} sm={10}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  alignItems: 'flex-start'
                }}
              >
                <Typography variant={PROMO_SUMMARY_VARIANT}>
                  {text.promoCode}
                </Typography>
                <Chip
                  icon={
                    <TagIcon
                      sx={{ transform: 'rotate(90deg)', fontSize: 16 }}
                    />
                  }
                  label={
                    <span>
                      <Box component="span" sx={{ fontWeight: 'bold' }}>
                        {promoCode.promoCode}
                      </Box>
                      {promoCode?.promoDescription && (
                        <span>
                          {' '}
                          {'\u00A0'} {promoCode?.promoDescription}
                        </span>
                      )}
                    </span>
                  }
                  onDelete={removePromo}
                  deleteIcon={
                    <CloseIcon
                      data-cy={`promoCode-list-remove-promo-${promoCode.promoCode}`}
                    />
                  }
                  sx={{
                    fontSize: 12,
                    borderRadius: 1,
                    '& .MuiChip-deleteIcon': { fontSize: 18 }
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={5}
              sm={2}
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              <Typography variant={PROMO_SUMMARY_VARIANT}>
                {`- ${formatPromoPrice(promoCode.discountAmount, spendAmount)}`}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Box>
  );
};

export default PromoCodeList;
