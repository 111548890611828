import { gql } from 'src/generated/gql';

export const modifyUserSettings = gql(`
  mutation modifyUserSettingsFromCloneProgramConfirmationModal(
    $updateSettings: JSON!
    $updateType: SettingsUpdateType
  ) {
    modifyUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);
