import { Component } from 'react';
import { flow, get } from 'lodash';
import { Field } from 'redux-form';
import { Trans } from 'react-i18next';

import {
  Button,
  Divider,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
  MenuItem,
  Typography
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/DeleteForever';

import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import RenderSelect from 'src/components/ReduxForm/RenderSelect';

import { elementInputTypes, elementNames } from './constants';
import AddTemplateElement from './AddTemplateElement';

const styles = theme => ({
  elementInput: {
    marginRight: theme.spacing(2),
    width: '200px'
  }
});

class TemplateLayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpand = () => {
    const { expanded } = this.state;

    this.setState({
      expanded: !expanded
    });
  };

  generateElement = element => {
    const { layerId, classes } = this.props;
    const inputs = get(element, 'inputs');

    return inputs.map(input => {
      switch (get(input, 'type')) {
        case elementInputTypes.select:
          return (
            <Field
              className={classes.elementInput}
              component={RenderSelect}
              key={`select-${get(input, 'name')}-${layerId}`}
              label={get(input, 'label')}
              name={`${layerId}-${get(input, 'name')}`} // not sure how to do this differently we need unique names for each input
            >
              {get(input, 'options').map(option => (
                <MenuItem
                  key={get(option, 'value')}
                  value={get(option, 'value')}
                >
                  {get(option, 'name')}
                </MenuItem>
              ))}
            </Field>
          );
        case elementInputTypes.text:
          return (
            <div key={`elementInputText-${layerId}`}>
              <Field
                className={classes.elementInput}
                component={RenderTextField}
                key={`text-${layerId}`}
                label={get(input, 'label')}
                name={`${layerId}-${get(input, 'name')}`}
              />
              <Field
                className={classes.elementInput}
                component={RenderSelect}
                key={`fontFamily-${layerId}`}
                label="Select Font Family"
                name={`${layerId}-${elementNames.fontFamily}`}
              >
                {get(input, 'options').map(position => (
                  <MenuItem
                    key={get(position, 'value')}
                    value={get(position, 'value')}
                  >
                    {get(position, 'name')}
                  </MenuItem>
                ))}
              </Field>
              <Field
                className={classes.elementInput}
                component={RenderTextField}
                key={`fontSize-${layerId}`}
                label="Font Size"
                name={`${layerId}-${elementNames.fontSize}`}
              />
              <Field
                className={classes.elementInput}
                component={RenderTextField}
                key={`fontColor-${layerId}`}
                label="Font Color"
                name={`${layerId}-${elementNames.fontColor}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">#</InputAdornment>
                  )
                }}
              />
            </div>
          );
        case elementInputTypes.color:
          return (
            <Field
              className={classes.elementInput}
              component={RenderTextField}
              key={`color-${get(input, 'name')}-${layerId}`}
              label="Font Color"
              name={`${layerId}-${elementNames.fontColor}`}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                )
              }}
            />
          );
        case elementInputTypes.string:
        // fall through for now
        case elementInputTypes.number:
        // fall through for now
        default:
          return (
            <Field
              className={classes.elementInput}
              component={RenderTextField}
              key={`${get(input, 'name')}-${layerId}`}
              label={get(input, 'label')}
              name={`${layerId}-${get(input, 'name')}`} // not sure how to do this differently we need unique names for each input
            />
          );
      }
    });
  };

  render() {
    const { expanded } = this.state;
    const {
      addElement,
      layer,
      layerId,
      layerName,
      removeElement,
      removeLayer
    } = this.props;
    const elementsById = get(layer, 'elementsById');
    const elementKeys = Object.keys(elementsById);

    let disableAssets = false;
    for (let index = 0; index < elementKeys.length; index++) {
      const key = elementKeys[index];
      if (get(elementsById[key], 'type') === 'asset') {
        disableAssets = true;
        break;
      }
    }

    return (
      <Accordion expanded={expanded} onChange={this.toggleExpand}>
        <AccordionSummary
          aria-controls="panel1c-content"
          expandIcon={<ExpandMoreIcon />}
          id="panel1c-header"
        >
          <Typography>{layerName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {elementsById &&
                  Object.keys(elementsById).map(element => {
                    return (
                      <Grid item xs={12} key={element}>
                        {this.generateElement(elementsById[element])}
                        <div>
                          <Button
                            onClick={() =>
                              removeElement({
                                layerId,
                                elementId: element
                              })
                            }
                            size="small"
                            startIcon={<DeleteIcon />}
                          >
                            <Trans i18nKey="admin:cloudinaryTemplateBuilder.removeElement">
                              Remove Element
                            </Trans>
                          </Button>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AddTemplateElement
                addElement={element => addElement({ layerId, element })}
                key={layerId}
                disableAssets={disableAssets}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          <Button
            color="secondary"
            onClick={() => removeLayer(layerId)}
            size="small"
            startIcon={<DeleteIcon />}
          >
            <Trans i18nKey="admin:cloudinaryTemplateBuilder.removeLayer">
              Remove Layer
            </Trans>
          </Button>
        </AccordionActions>
      </Accordion>
    );
  }
}

export default flow(withStyles(styles))(TemplateLayer);
