/**
 * Wrapper around Error so that we can get rich error reporting in Sentry.
 * By default, if you feed a non-error object to Sentry.captureException it will
 * just report that it got "an object with keys: x, y, z" which is not very
 * helpful.
 */
export class SentryWrappedError extends Error {
  public readonly extraData?: object;

  constructor(message: string, name?: string, extraData?: object) {
    super(message);
    // Fix built-in classes like Error wiping away your prototype chain
    Object.setPrototypeOf(this, SentryWrappedError.prototype);

    this.extraData = extraData;
    // Overwrite the name so that we can make our errors de-dupe in sentry.
    // They're keyed off of the name.
    this.name = name || 'SentryWrappedError';
  }
}
