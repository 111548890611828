import { sortBy, startCase, reduce } from 'lodash';

// Google autocomplete supported types https://developers.google.com/maps/documentation/places/web-service/supported_types
const placeTypes = [
  'accounting',
  'airport',
  'amusement_park',
  'aquarium',
  'art_gallery',
  'atm',
  'bakery',
  'bank',
  'bar',
  'beauty_salon',
  'bicycle_store',
  'book_store',
  'bowling_alley',
  'bus_station',
  'cafe',
  'campground',
  'car_dealer',
  'car_rental',
  'car_repair',
  'car_wash',
  'casino',
  'cemetery',
  'church',
  'city_hall',
  'clothing_store',
  'convenience_store',
  'courthouse',
  'dentist',
  'department_store',
  'doctor',
  'drugstore',
  'electrician',
  'electronics_store',
  'embassy',
  'fire_station',
  'florist',
  'funeral_home',
  'furniture_store',
  'gas_station',
  'gym',
  'hair_care',
  'hardware_store',
  'hindu_temple',
  'home_goods_store',
  'hospital',
  'insurance_agency',
  'jewelry_store',
  'laundry',
  'lawyer',
  'library',
  'light_rail_station',
  'liquor_store',
  'local_government_office',
  'locksmith',
  'lodging',
  'meal_delivery',
  'meal_takeaway',
  'mosque',
  'movie_rental',
  'movie_theater',
  'moving_company',
  'museum',
  'night_club',
  'painter',
  'park',
  'parking',
  'pet_store',
  'pharmacy',
  'physiotherapist',
  'plumber',
  'police',
  'post_office',
  'primary_school',
  'real_estate_agency',
  'restaurant',
  'roofing_contractor',
  'rv_park',
  'school',
  'secondary_school',
  'shoe_store',
  'shopping_mall',
  'spa',
  'stadium',
  'storage',
  'store',
  'subway_station',
  'supermarket',
  'synagogue',
  'taxi_stand',
  'tourist_attraction',
  'train_station',
  'transit_station',
  'travel_agency',
  'university',
  'veterinary_care',
  'zoo'
];

const additionalTypes = [
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
  'administrative_area_level_6',
  'administrative_area_level_7',
  'archipelago',
  'colloquial_area',
  'continent',
  'country',
  'finance',
  'floor',
  'food',
  'general_contractor',
  'geocode',
  'health',
  'intersection',
  'landmark',
  'locality',
  'natural_feature',
  'neighborhood',
  'place_of_worship',
  'plus_code',
  'point_of_interest',
  'political',
  'post_box',
  'postal_code',
  'postal_code_prefix',
  'postal_code_suffix',
  'postal_town',
  'premise',
  'room',
  'route',
  'street_address',
  'street_number',
  'sublocality',
  'sublocality_level_1',
  'sublocality_level_2',
  'sublocality_level_3',
  'sublocality_level_4',
  'sublocality_level_5',
  'subpremise',
  'town_square'
];

const collectionTypes = [
  'geocode',
  'address',
  'establishment',
  '(regions)',
  '(cities)'
];

export const getPlacesAutocomplete = () => {
  const tableTypes = { collectionTypes, placeTypes, additionalTypes };
  const types = reduce(
    tableTypes,
    (prev, tableOptions, tableKey) => {
      return [
        ...prev,
        ...tableOptions.map(key => ({
          name: startCase(key),
          value: key,
          group: startCase(tableKey)
        }))
      ];
    },
    []
  );

  return sortBy(types);
};

// According to the docs, it will error out if Address, cities or regions are mixed with any other type
// https://developers.google.com/maps/documentation/places/web-service/autocomplete#types:~:text=You%20cannot%20mix%20type%20collections%20(address%2C%20(cities)%20or%20(regions))%20with%20any%20other%20type%2C%20or%20an%20error%20occurs.
export const validateSelectedTypes = value => {
  const notMixableTypes = ['address', '(regions)', '(cities)'];

  if (
    (value?.some(type => notMixableTypes.includes(type)) &&
      value?.some(
        type => placeTypes.includes(type) || additionalTypes.includes(type)
      )) ||
    value?.filter(type => notMixableTypes.includes(type)).length > 1
  ) {
    return `Cannot mix Address, Cities or Regions with any other type.`;
  }
};
