import { validateRequired } from 'src/common/validations';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';
import { dashEmptyCell } from 'src/components/Table/helpers';

export const SEARCH_FORM_NAME = 'searchOrdersFragments';
export const LINK_FORM_NAME = 'manuallyLinkOrders';

export const supportedChannelCodes = ['fb'];
export const supportedUnitCodes = ['ad_unit', 'ad_set', 'campaign'];

export const initialChannelCode = 'fb';
export const initialUnitCode = 'ad_unit';

// channelCode: String
// unitCode: String
// contentGroupKey: String
// outputValue: String
export const findOrderExtraInputs = [
  {
    name: 'channelCode',
    displayName: 'Channel Code',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        options: [],
        isAutocomplete: true
      }
    }
  },
  {
    name: 'unitCode',
    displayName: 'Unit Code',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        options: [],
        isAutocomplete: true
      }
    }
  },
  {
    name: 'contentValue',
    displayName: 'Content Value',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: false,
    displayParameters: {
      inputData: {
        columnWidth: 1,
        options: [],
        isAutocomplete: true
      }
    }
  },
  {
    name: 'outputValue',
    displayName: 'Output Value',
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    disabled: false,
    displayParameters: {
      inputData: {
        columnWidth: 1
      }
    }
  }
];

export const manuallyManageInputs = isManuallyManaged => {
  const inputs = [
    {
      name: 'projectId',
      displayName: 'Project Id',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: true,
      disabled: true,
      displayParameters: {
        inputData: {
          columnWidth: 4
        }
      }
    },
    {
      name: 'publisherId',
      displayName: 'Publisher Id',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: true,
      disabled: true,
      displayParameters: {
        inputData: {
          columnWidth: 4
        }
      }
    },
    {
      name: 'contentValue',
      displayName: 'Content Value',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: true,
      disabled: true,
      displayParameters: {
        inputData: {
          columnWidth: 4
        }
      }
    }
  ];

  if (!isManuallyManaged) {
    inputs.push({
      name: 'outputValue',
      displayName: 'New Output Value',
      displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
      initialValue: '',
      reduxValidations: [validateRequired],
      isRequired: true,
      isHidden: false,
      disabled: false,
      displayParameters: {
        inputData: {
          columnWidth: 4
        }
      }
    });
  }

  return inputs;
};

// executionElementId: ID!
// projectId: ID!
// publisherId: ID!
// contentValue: String!
// channelCode: String!
// unitCode: String!
// fragmentId: ID
// outputValue: String
// isMissing: Boolean!
// isManuallyManaged: Boolean!
export const columnSchemaBase = [
  {
    columnName: 'Fragment Id',
    accessor: 'fragmentId',
    key: 'fragmentId',
    type: 'STRING',
    CellComponent: dashEmptyCell
  },
  {
    columnName: 'Publisher Id',
    accessor: 'publisherId',
    key: 'publisherId',
    type: 'STRING',
    CellComponent: dashEmptyCell
  },
  {
    columnName: 'Channel Code',
    accessor: 'channelCode',
    key: 'channelCode',
    type: 'STRING',
    CellComponent: dashEmptyCell
  },
  {
    columnName: 'Unit Code',
    accessor: 'unitCode',
    key: 'unitCode',
    type: 'STRING',
    CellComponent: dashEmptyCell
  },
  {
    columnName: 'Content Value',
    accessor: 'contentValue',
    key: 'contentValue',
    type: 'STRING',
    CellComponent: dashEmptyCell
  },
  {
    columnName: 'Output Value',
    accessor: 'outputValue',
    key: 'outputValue',
    type: 'STRING',
    CellComponent: dashEmptyCell
  },
  {
    columnName: 'Missing',
    accessor: 'isMissing',
    key: 'isMissing',
    type: 'STRING',
    CellComponent: blob => {
      return `${!!blob?.data}`;
    }
  }
];
