import { Box, Typography } from '@mui/material';

import Link from 'src/components/Link';
import { generateLinkPath } from 'src/routes/RouteUtil';
import { paths } from 'src/routes/paths';

export interface Order {
  name: string;
  id: string;
  architectureId: string;
}

interface BlockingOrdersListProps {
  title: string;
  orders: Order[];
  isAutomation?: boolean;
}

const BlockingOrdersList = ({
  title,
  orders,
  isAutomation = false
}: BlockingOrdersListProps) => {
  return (
    <Box>
      <Typography component="h2" variant="body1" sx={{ fontWeight: 500 }}>
        {title}
      </Typography>
      <Box component="ul" sx={{ margin: 0, px: 0, listStyle: 'none' }}>
        {orders?.map(order => {
          const path = isAutomation
            ? paths.architecture.automatedProgramEdit
            : paths.architecture.program;

          const params = {
            architectureId: order.architectureId,
            ...(isAutomation ? { programId: order.id } : { orderId: order.id })
          };

          return (
            <Box key={order.id} component="li">
              <Link
                to={generateLinkPath(path, params)}
                sx={{
                  textDecoration: 'none',
                  color: 'primary.main'
                }}
              >{`• ${order.name}`}</Link>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BlockingOrdersList;
