import Logger from '../common/Logger';

export default function facebookAuthInterceptor() {
  const { href } = window.location;

  const urlString = decodeURIComponent(href);

  const lfbreState = new RegExp('.*state=.*');
  const lfbreToken = new RegExp('.*access_token=.*');
  const isFacebook = new RegExp('.*state={lfb=true}*');
  const stringTrim = new RegExp(`^[/#]+`);

  if (
    urlString.match(lfbreState) &&
    urlString.match(lfbreToken) &&
    urlString.match(isFacebook)
  ) {
    const hasQuery = urlString.includes('?');
    // manually update our query string
    const queryString = (urlString.split('?')[1] || urlString.split('#')[1])
      // remove malfomred url fragments
      .replace(stringTrim, '')
      // remove our facebook state identifier
      .replace('&state={lfb=true}', '')
      // cahnge the access token to fb_access_token so we don't confuse with other access tokerns
      .replace('access_token=', 'fb_access_token=');

    const newFbPagePath = '/facebook/new';
    const hash = `#${newFbPagePath}`;

    const newUrl = `https://${window.location.host}/${hash}?${queryString}`;

    Logger.debug(`New URL: ${newUrl}`);
    window.location.href = newUrl;
    if (!hasQuery) {
      window.location.reload();
    }
  }
}
