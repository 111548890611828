import { forwardRef } from 'react';
import { kebabCase } from 'lodash';
import {
  InputAdornment,
  Tooltip,
  FormHelperText,
  FormControl,
  InputLabel,
  OutlinedInput
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import NumberFormat from 'react-number-format';

import HelpIcon from '@mui/icons-material/HelpOutline';
import ClientHtml from 'src/components/ClientHtml';

const styles = theme => ({
  helpTip: {
    color: theme.palette.grey[500],
    position: 'absolute',
    top: '13px',
    right: '3px'
  },
  helpIcon: {
    width: '20px'
  },
  helperText: {
    marginTop: '0'
  }
});

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  return <NumberFormat {...props} getInputRef={ref} />;
});

const RenderTemplateField = props => {
  const {
    children,
    input,
    meta: { touched, error },
    // variant = 'outlined',
    businessObjects, // was causing a warning when getting passed with ...rest
    tooltip,
    helperText,
    classes,
    type,
    label,
    inputProps,
    startAdornment,
    endAdornment,
    margin = 'normal',
    isHookForm,
    ...rest
  } = props;

  const { onChange, name, value, ...inputRest } = input;

  const inputInError = isHookForm ? error : error && touched;

  const deDotName = kebabCase(name);
  return (
    <FormControl variant="outlined" margin={margin} fullWidth>
      <InputLabel
        htmlFor={`template-${deDotName}`}
        variant="outlined"
        error={inputInError}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id={`template-${deDotName}`}
        inputComponent={NumberFormatCustom}
        value={value}
        onChange={onChange}
        error={inputInError}
        label={label}
        fullWidth
        inputProps={inputProps}
        {...{
          ...(type !== 'hidden' &&
            tooltip && {
              endAdornment: (
                <InputAdornment className={classes.helpTip} position="end">
                  <Tooltip arrow title={tooltip}>
                    <HelpIcon className={classes.helpIcon} />
                  </Tooltip>
                </InputAdornment>
              )
            }),
          ...(type !== 'hidden' &&
            startAdornment && {
              startAdornment: (
                <InputAdornment
                  disablePointerEvents // This allows the adornment to focus the input on click
                  position="start"
                >
                  {startAdornment}
                </InputAdornment>
              )
            })
        }}
        {...rest}
        {...inputRest}
      />
      {helperText && (
        <FormHelperText
          className={classes.helperText}
          error={inputInError}
          variant="outlined"
        >
          <ClientHtml html={helperText} />
        </FormHelperText>
      )}
      {inputInError && (
        <FormHelperText
          className={helperText ? '' : classes.helperText} // if no helper text remove margin
          error
          variant="outlined"
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(RenderTemplateField);
