import { t } from 'i18next';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import {
  validateRequired,
  validateArrayMinLength
} from 'src/common/validations';

export const CREATE_FORM_NAME = 'createFacebookConfiguration';
export const ACTIVATE_FORM_NAME = 'activateFacebookConfiguration';
export const DEFAULT_FACEBOOK_PAGE_FORM_NAME =
  'organizationDefaultFacebookPage';
export const FACEBOOK_PAGE_LINK_BUTTON_FORM_NAME = 'facebookLinkStatusButtons';

export const createFacebookConfigurationInputs = () => [
  {
    name: 'botUserId',
    displayName: t(
      'admin:facebookSettings.createFacebookConfigurationInputs.botUserId.displayName',
      'Bot User ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'botToken',
    displayName: t(
      'admin:facebookSettings.createFacebookConfigurationInputs.botToken.displayName',
      'Bot Token'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const createFacebookConfigurationAdvancedInputs = () => [
  // Note: Putting these in an advaced section that will collapsed to start. These fields have default
  //       values set on the BE when they are not sent.
  {
    name: 'clientId',
    displayName: t(
      'admin:facebookSettings.createFacebookConfigurationAdvancedInputs.facebookConfigId.displayName.createFacebookConfigurationAdvancedInputs.clientId.displayName',
      'App ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 3,
    displayParameters: {
      inputData: {
        tooltip: t(
          'admin:facebookSettings.createFacebookConfigurationAdvancedInputs.facebookConfigId.displayName.createFacebookConfigurationAdvancedInputs.clientId.tooltip',
          'formerly know as Client ID'
        ),
        columnWidth: 4
      }
    }
  },
  {
    name: 'clientSecret',
    displayName: t(
      'admin:facebookSettings.createFacebookConfigurationAdvancedInputs.facebookConfigId.displayName.createFacebookConfigurationAdvancedInputs.clientSecret.displayName',
      'App Secret'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 4,
    displayParameters: {
      inputData: {
        tooltip: t(
          'admin:facebookSettings.createFacebookConfigurationAdvancedInputs.facebookConfigId.displayName.createFacebookConfigurationAdvancedInputs.clientSecret.tooltip',
          'formerly know as Client Secret'
        ),
        columnWidth: 4
      }
    }
  },
  {
    name: 'businessManagerId',
    displayName: t(
      'admin:facebookSettings.createFacebookConfigurationAdvancedInputs.facebookConfigId.displayName.createFacebookConfigurationAdvancedInputs.businessManagerId.displayName',
      'Business Manager ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 5,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'adminToken',
    displayName: t(
      'admin:facebookSettings.createFacebookConfigurationAdvancedInputs.facebookConfigId.displayName.createFacebookConfigurationAdvancedInputs.adminToken.displayName',
      'Admin Token'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 6,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const defaultFacebookPageSettingsInputs = () => [
  {
    name: 'pageId',
    displayName: t(
      'admin:facebookSettings.defaultFacebookPageSettingsInputs.facebookConfigId.displayName.defaultFacebookPageSettingsInputs.pageId.displayName',
      'Page ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'pageName',
    displayName: t(
      'admin:facebookSettings.defaultFacebookPageSettingsInputs.facebookConfigId.displayName.defaultFacebookPageSettingsInputs.pageName.displayName',
      'Page Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'facebookUserId',
    displayName: t(
      'admin:facebookSettings.defaultFacebookPageSettingsInputs.facebookConfigId.displayName.defaultFacebookPageSettingsInputs.facebookUserId.displayName',
      'Facebook User ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 3,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'facebookDisplayName',
    displayName: t(
      'admin:facebookSettings.defaultFacebookPageSettingsInputs.facebookConfigId.displayName.defaultFacebookPageSettingsInputs.facebookDisplayName.displayName',
      'Facebook Display Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 4,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const getActivateFacebookConfigurationInputs =
  facebookConfigurationOptions => {
    return [
      {
        name: 'facebookConfigId',
        displayName: t(
          'admin:facebookSettings.getActivateFacebookConfigurationInputs.facebookConfigId.displayName',
          'Active Facebook Configuration'
        ),
        displayMethodId: INPUT_TYPES.SINGLE_SELECT,
        initialValue: false,
        reduxValidations: [validateRequired],
        isRequired: true,
        isHidden: false,
        displaySortOrder: 1,
        displayParameters: {
          inputData: {
            columnWidth: 4,
            options: facebookConfigurationOptions
          }
        }
      }
    ];
  };

export const facebookPageLinkOverrideDashboard = () => [
  {
    name: 'overrideBackToDashboard',
    displayName: t(
      'admin:facebookSettings.facebookPageLinkOverrideDashboard.overrideBackToDashboard.displayName',
      'Override "Back to Dashboard"'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [],
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const facebookPageLinkButtonSettings = () => [
  {
    name: 'linkStatusButtons',
    displayName: t(
      'admin:facebookSettings.facebookPageLinkButtonSettings.linkStatusButton.displayName',
      'Custom Menu Links'
    ),
    displayMethodId: INPUT_TYPES.CUSTOM_LINKS,
    initialValue: [],
    reduxValidations: [validateArrayMinLength(1)],
    isRequired: false,
    isHidden: false,
    disabled: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 4,
        actionButtonText: t(
          'admin:facebookSettings.facebookPageLinkButtonSettings.linkStatusButton.actionButtonText',
          'Add Custom Menu Link'
        ),
        previewUrl: true
      }
    }
  }
];
