import { get, isEmpty } from 'lodash';
import { elementNames } from './constants';

export const generateOverlayAsset = (url = '') => {
  const assetRegex = /(?:v\d{8,}.+?(?=\.))/;
  let path = url.match(assetRegex);

  if (path && path[0]) {
    path = path[0].split('/').join(':');
  }

  return path;
};

export const formatInputValueForTemplate = ({
  elements,
  layerId,
  allFormValues
}) => {
  const assetModifierPrefix = 'fl_layer_apply';
  const elementUrl = [];
  const assetModifiers = [];

  Object.keys(elements).forEach(elementId => {
    elements[elementId].inputs.forEach(input => {
      const inputName = get(input, 'name');
      const inputValue = allFormValues[`${layerId}-${inputName}`];

      if (inputValue) {
        switch (inputName) {
          case elementNames.overlayLinkCloudinary:
            const overlayAsset = generateOverlayAsset(inputValue);
            if (overlayAsset) {
              elementUrl.push(`${get(input, 'prefix')}${overlayAsset}`);
            }
            break;
          case elementNames.overlayLinkBase64:
            if (inputValue) {
              elementUrl.push(`${get(input, 'prefix')}:${inputValue}`);
            }
            break;
          case elementNames.text:
            const fontFamily =
              allFormValues[`${layerId}-${elementNames.fontFamily}`];
            const fontSize =
              allFormValues[`${layerId}-${elementNames.fontSize}`];
            const fontColor =
              allFormValues[`${layerId}-${elementNames.fontColor}`];
            elementUrl.push(
              `${get(
                input,
                'prefix'
              )}:${fontFamily}_${fontSize}:${encodeURIComponent(
                inputValue
              )},co_rgb:${fontColor}`
            );
            break;
          case elementNames.xPosition:
          // fall through
          case elementNames.yPosition:
          // fall through
          case elementNames.positionOrigin:
          // fall through
          case elementNames.width:
          // fall through
          case elementNames.height:
          // fall through
          default:
            assetModifiers.push(`${get(input, 'prefix')}${inputValue}`);
            break;
        }
      }
    });
  });

  if (!isEmpty(assetModifiers)) {
    // add modifiers prefix
    assetModifiers.unshift(assetModifierPrefix);
    // combine modifiers
    elementUrl.push(assetModifiers.join(','));
  }

  return elementUrl;
};
