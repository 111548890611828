import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

import { getPreviewWidth, getGoogleDisplayImageHeight } from '../../helpers';
import { GOOGLE_PREVIEW_WIDTH, STYLES } from '../GoogleAdPreviewConstants';

export const Container = styled('div')(props => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: props.width
}));

const ImageRow = styled('div')(props => ({
  height: props.height,
  overflow: 'hidden',
  width: props.width,
  '& img': {
    width: '100%'
  }
}));

const Headline = styled('div')({
  color: STYLES.color.headline,
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: '14px',
  fontWeight: '500',
  marginBottom: '2px',
  padding: '0',
  whiteSpace: 'nowrap',
  width: '216px'
});

const Description = styled('div')({
  color: STYLES.color.description,
  display: 'inline-block',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: ' 12px',
  fontWeight: ' 300',
  padding: '0',
  whiteSpace: 'normal',
  width: '216px'
});

const BusinessName = styled('div')({
  color: STYLES.color.businessName,
  display: 'inline-block',
  fontFamily: STYLES.fontFamily.roboto,
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '14px',
  padding: '3px 9px 0 0',
  whiteSpace: 'nowrap',
  width: '203px'
});

export const ActionRow = styled('div')({
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'flex',
  marginTop: '2px',
  padding: '0 12px',
  width: '100%'
});

export const HeadlineContainer = styled('div')({});

const DisplayAdOne = props => {
  const {
    mainImage,
    logoImage,
    headline,
    description,
    businessName,
    hasUniformPreviewWidth,
    isResponsive
  } = props;

  const imageHeight = getGoogleDisplayImageHeight(
    hasUniformPreviewWidth,
    isResponsive
  );

  const previewWidth = getPreviewWidth(
    hasUniformPreviewWidth,
    GOOGLE_PREVIEW_WIDTH,
    isResponsive
  );

  return (
    <Container width={previewWidth}>
      <ImageRow height={imageHeight} width={previewWidth}>
        {mainImage}
      </ImageRow>
      <Box
        sx={{
          alignSelf: 'flex-start',
          display: 'flex',
          padding: '12px 12px 0 12px'
        }}
      >
        {!!logoImage && (
          <Box
            sx={{
              '& img': {
                marginRight: '12px',
                width: '50px'
              }
            }}
          >
            {logoImage}
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Headline>{headline}</Headline>
          <Description>
            {description && description.length > 70
              ? `${description.substring(0, 70)} ...`
              : description}
          </Description>
        </Box>
      </Box>
      <ActionRow>
        <BusinessName>{businessName}</BusinessName>
        <Box
          sx={{
            alignItems: 'center',
            color: '#212121',
            display: 'flex'
          }}
        >
          Open <ArrowRightIcon />
        </Box>
      </ActionRow>
    </Container>
  );
};

export default DisplayAdOne;
