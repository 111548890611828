import { flow, isEmpty, reduce, has } from 'lodash';
import { FormSection, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
  Paper,
  Grid,
  Tooltip
} from '@mui/material';
import HelpIcon from '@mui/icons-material/HelpOutline';

import withStyles from '@mui/styles/withStyles';
import { DynamicForm } from 'src/components/ReduxForm';
import {
  isExperimentActive,
  withExperimentContext
} from 'src/experiments/ExperimentContextProvider';
import Heading from 'src/components/PageElements/Heading';

import {
  experimentsInputs,
  FORM_NAME,
  EXPERIMENTS_OVERRIDE_LOCAL_STORAGE
} from './Constants';

const styles = theme => ({
  listItem: {
    marginRight: theme.spacing(4)
  },
  buttonContainer: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  helpIcon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    width: '20px'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  footerButton: {
    marginRight: theme.spacing(1)
  }
});

const ExperimentSettings = props => {
  const { classes, handleSubmit, experimentContext, experimentOverrides } =
    props;

  const handleSubmitHandler = data => {
    const experimentsWithOverrides = reduce(
      data?.experiments,
      (overrides, isActiveExperiment, experimentKey) => {
        if (
          isActiveExperiment !==
          isExperimentActive(experimentKey, experimentContext)
        ) {
          return { ...overrides, [experimentKey]: isActiveExperiment };
        }
        return overrides;
      },
      {}
    );

    localStorage.setItem(
      EXPERIMENTS_OVERRIDE_LOCAL_STORAGE,
      JSON.stringify(experimentsWithOverrides)
    );
  };

  const handleClearOverrides = () => {
    localStorage.removeItem(EXPERIMENTS_OVERRIDE_LOCAL_STORAGE);
    window.location.reload();
  };

  const StatusChip = name => {
    const isActive = isExperimentActive(name, experimentContext);
    const label = isActive ? 'Active' : 'Inactive';
    const color = isActive ? 'success' : 'error';
    return (
      <Chip
        label={label}
        color={color}
        sx={{ color: 'white' }}
        className={classes.listItem}
      />
    );
  };

  return (
    <>
      <Heading
        title={t('adminExperimentSettings:mainPage.pageHeading')}
        subTitle={t('adminExperimentSettings:mainPage.pageSubHeading')}
        pageTitle={t('adminExperimentSettings:mainPage.pageHeading')}
      />

      <Paper className={classes.paper}>
        <Grid item xs={12}>
          <FormSection name="experiments">
            <List>
              {isEmpty(experimentsInputs) ? (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <Trans i18nKey="adminExperimentSettings:sections.experimentsEmpty" />
                    }
                  />
                </ListItem>
              ) : (
                experimentsInputs.map(input => {
                  return (
                    <ListItem
                      alignItems="flex-center"
                      sx={{ flexWrap: 'wrap' }}
                    >
                      <ListItemAvatar>
                        <Avatar>UIX</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={input.displayName}
                        secondary={input.name}
                        sx={{ maxWidth: '250px' }}
                        className={classes.listItem}
                      />
                      <StatusChip name={input.name} />
                      <div className={classes.inputContainer}>
                        <DynamicForm inputs={[input]} />
                        {Object.keys(experimentOverrides).includes(
                          input.name
                        ) && (
                          <Tooltip title="Currently overriding this experiment">
                            <HelpIcon className={classes.helpIcon} />
                          </Tooltip>
                        )}
                      </div>
                    </ListItem>
                  );
                })
              )}
            </List>
            <div className={classes.buttonContainer}>
              <Button
                data-cy="skin-publish-button"
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={handleSubmit(handleSubmitHandler)}
              >
                <Trans i18nKey="experimentSettings:mainPage.save" />
              </Button>
              <Button
                color="secondary"
                onClick={handleClearOverrides}
                variant="outlined"
              >
                <Trans i18nKey="experimentSettings:mainPage.clear" />
              </Button>
            </div>
          </FormSection>
        </Grid>
      </Paper>
    </>
  );
};

function mapStateToProps(_, ownProps) {
  const { experimentContext } = ownProps;

  const experimentOverridesRaw = localStorage.getItem(
    EXPERIMENTS_OVERRIDE_LOCAL_STORAGE
  );

  let experimentOverrides = {};
  if (experimentOverridesRaw) {
    experimentOverrides = JSON.parse(experimentOverridesRaw);
  }

  // if the experiment is not overriten use the amplitud status, so in case of an active state in amplitd we can override it to false.
  const remainingExperiments = reduce(
    experimentsInputs,
    (remainingExperiments, experimentInput) => {
      // get the experiments that are not overriten
      if (!has(experimentOverrides, experimentInput.name)) {
        // shape into an object with the amplitud status
        return {
          ...remainingExperiments,
          [experimentInput.name]: isExperimentActive(
            experimentInput.name,
            experimentContext
          )
        };
      }
      return remainingExperiments;
    },
    {}
  );

  return {
    initialValues: {
      [FORM_NAME]: {
        ...experimentOverrides,
        ...remainingExperiments
      }
    },
    experimentOverrides
  };
}

export default flow(
  reduxForm({
    enableReinitialize: true,
    form: FORM_NAME
  }),
  connect(mapStateToProps),
  withStyles(styles),
  withExperimentContext
)(ExperimentSettings);
