import { CLOUDINARY_BASE_URL } from 'src/common/cloudinaryUtils';

export const baseUrl = `${CLOUDINARY_BASE_URL}/image/upload`;
export const backgroundImage =
  'v1587614581/ev-overlay/background/exterior-house-002.jpg';

export const FORM_NAME = 'cloudinaryTemplateBuilderForm';

export const elementInputTypes = {
  string: 'STRING',
  number: 'NUMBER',
  select: 'SELECT',
  text: 'TEXT'
};

export const elementNames = {
  xPosition: 'xPosition',
  yPosition: 'yPosition',
  positionOrigin: 'positionOrigin',
  overlayLinkCloudinary: 'overlayLinkCloudinary',
  overlayLinkBase64: 'overlayLinkBase64',
  text: 'text',
  fontFamily: 'fontFamily',
  fontSize: 'fontSize',
  fontColor: 'fontColor',
  circleCrop: 'circleCrop',
  outline: 'outline',
  height: 'height',
  width: 'width',
  gravityCrop: 'gravityCrop'
};

export const positions = {
  north: 'North',
  south: 'South',
  east: 'East',
  west: 'West',
  north_west: 'North West',
  north_east: 'North East',
  south_west: 'South West',
  south_east: 'South East'
};

const universalFonts = {
  Roboto: 'Roboto',
  Arial: 'Arial',
  Times: 'Times',
  Courier: 'Courier',
  Verdana: 'Verdana',
  Georgia: 'Georgia',
  Palatino: 'Palatino',
  Bookman: 'Bookman',
  Impact: 'Impact'
};

export const cloudinaryOverlayAssets = [
  {
    name: 'Open House -> (south west)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582678473/ev-overlay/sold/open-house-south-west.png`
  },
  {
    name: 'Open House <- (south east)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582678473/ev-overlay/sold/open-house-south-east.png`
  },
  {
    name: 'Sold Corner Triangle (north west)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582677014/ev-overlay/sold/sold-corner-north-west.png`
  },
  {
    name: 'Sold Corner Triangle (north east)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582676551/ev-overlay/sold/sold-corner-north-east.png`
  },
  {
    name: 'Sold Corner Triangle (north west)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582676551/ev-overlay/sold/sold-banner-north-west.png`
  },
  {
    name: 'Sold Corner Triangle (north east)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582675623/ev-overlay/sold/sold-banner-north-east.png`
  },
  {
    name: 'Light Sold Sign Top (south)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582676552/ev-overlay/sold/sold-sign-top-south-white.png`
  },
  {
    name: 'Dark Sold Sign Top (south)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582676551/ev-overlay/sold/sold-sign-top-south-black.png`
  },
  {
    name: 'Dark Sold Sign Middle (south)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582676551/ev-overlay/sold/sold-sign-middle-south-white.png`
  },
  {
    name: 'Dark Sold Sign Middle (south)',
    value: `${CLOUDINARY_BASE_URL}/image/upload/v1582676551/ev-overlay/sold/sold-sign-middle-south-black.png`
  }
];

export const elementInputsByName = {
  [elementNames.xPosition]: {
    label: 'X Position',
    name: elementNames.xPosition,
    defaultValue: '',
    type: elementInputTypes.number,
    prefix: 'x_'
  },
  [elementNames.yPosition]: {
    label: 'Y Position',
    name: elementNames.yPosition,
    defaultValue: '',
    type: elementInputTypes.number,
    prefix: 'y_'
  },
  [elementNames.positionOrigin]: {
    label: 'Position Origin',
    name: elementNames.positionOrigin,
    defaultValue: '',
    type: elementInputTypes.select,
    prefix: 'g_',
    options: Object.keys(positions).map(position => ({
      name: positions[position],
      value: position
    }))
  },
  [elementNames.overlayLinkCloudinary]: {
    label: 'Overlay Cloudinary Asset Link',
    name: elementNames.overlayLinkCloudinary,
    defaultValue: '',
    type: elementInputTypes.select,
    prefix: 'l_',
    options: cloudinaryOverlayAssets
  },
  [elementNames.overlayLinkBase64]: {
    label: 'Overlay Base64 Asset Link',
    name: elementNames.overlayLinkBase64,
    defaultValue: '',
    type: elementInputTypes.string,
    prefix: 'l_fetch'
  },
  [elementNames.text]: {
    label: 'Text',
    name: elementNames.text,
    defaultValue: '',
    type: elementInputTypes.text,
    prefix: 'l_text',
    options: Object.keys(universalFonts).map(font => ({
      name: universalFonts[font],
      value: font
    }))
  },
  [elementNames.height]: {
    label: 'Height',
    name: elementNames.height,
    defaultValue: '',
    type: elementInputTypes.string,
    prefix: 'h_'
  },
  [elementNames.width]: {
    label: 'Width',
    name: elementNames.width,
    defaultValue: '',
    type: elementInputTypes.string,
    prefix: 'w_'
  },
  [elementNames.gravityCrop]: {
    label: 'Gravity Crop',
    name: elementNames.gravityCrop,
    defaultValue: '',
    type: elementInputTypes.select,
    prefix: 'g_',
    options: [
      { name: 'Face', value: 'face' },
      { name: 'Auto', value: 'auto' }
    ]
  },
  [elementNames.circleCrop]: {
    label: 'Circle Crop',
    name: elementNames.circleCrop,
    defaultValue: 'max',
    type: elementInputTypes.select,
    prefix: 'r_',
    options: [{ name: 'Circle', value: 'max' }]
  }
};

export const templateElementsTypes = {
  addOverlayCloudinary: 'addOverlayCloudinary',
  addOverlayBase64: 'addOverlayBase64',
  position: 'position',
  addText: 'addText',
  setSize: 'setSize',
  gravityCrop: 'gravityCrop',
  circleCrop: 'circleCrop'
};

export const modifierTemplateElements = {
  [templateElementsTypes.position]: {
    name: 'Position',
    value: templateElementsTypes.position,
    inputs: [
      elementInputsByName.positionOrigin,
      elementInputsByName.xPosition,
      elementInputsByName.yPosition
    ],
    type: 'modifier'
  },
  [templateElementsTypes.setSize]: {
    name: 'Set Dimensions',
    value: templateElementsTypes.setSize,
    inputs: [elementInputsByName.height, elementInputsByName.width],
    type: 'modifier'
  },
  [templateElementsTypes.gravityCrop]: {
    name: 'Crop (Gravity)',
    value: templateElementsTypes.gravityCrop,
    inputs: [elementInputsByName.gravityCrop],
    type: 'modifier'
  },
  [templateElementsTypes.circleCrop]: {
    name: 'Crop (Circle)',
    value: templateElementsTypes.circleCrop,
    inputs: [elementInputsByName.circleCrop],
    type: 'modifier'
  }
};

export const assetTemplateElements = {
  [templateElementsTypes.addOverlayCloudinary]: {
    name: 'Add Overlay (Cloudinary)', // select name
    value: templateElementsTypes.addOverlayCloudinary, // select value
    inputs: [elementInputsByName.overlayLinkCloudinary],
    type: 'asset'
  },
  [templateElementsTypes.addOverlayBase64]: {
    name: 'Add Overlay (Base64 Link)',
    value: templateElementsTypes.addOverlayBase64,
    inputs: [elementInputsByName.overlayLinkBase64],
    type: 'asset'
  },
  [templateElementsTypes.addText]: {
    name: 'Add Text',
    value: templateElementsTypes.addText,
    inputs: [elementInputsByName.text],
    type: 'asset'
  }
};

export const defaultInitialValues = {
  [elementNames.fontFamily]: 'Roboto',
  [elementNames.fontSize]: 42,
  [elementNames.fontColor]: 'ffffff',
  [elementNames.gravityCrop]: 'face'
};
