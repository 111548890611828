import { Card, Skeleton, Box } from '@mui/material';

const FacebookPageCardSkeleton = () => {
  return (
    <Card sx={{ width: '350px', height: '175px' }}>
      <Skeleton variant="rectangular" width="100%" height="42px" />
      <Box
        sx={{
          padding: theme => theme.spacing(2)
        }}
      >
        <Skeleton variant="text" width="150px" height="32px" />
        <Skeleton variant="text" width="130px" height="24px" />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme =>
            `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`
        }}
      >
        <div />
        <Skeleton
          variant="rounded"
          width="85px"
          height="32px"
          sx={{ borderRadius: '16px' }}
        />
      </Box>
    </Card>
  );
};

export default FacebookPageCardSkeleton;
