import { gql } from 'src/generated/gql';

export const createUserInOrganization = gql(`
  mutation CreateUserInOrganization(
    $createUserInOrgInput: CreateUserInOrgInput
  ) {
    createUserInOrganization(input: $createUserInOrgInput) {
      id
      email
      name
      organizationId
      pullProviderExecutionStatuses {
        executionId
      }
    }
  }
`);
