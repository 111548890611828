import { flow } from 'lodash';
import { Route } from 'react-router-dom';
import qs from 'qs';
import { AUTH0_KEYS, isPartnerAdmin, isGroupAdmin } from 'src/Auth/common';
import { Typography, Alert, AlertTitle } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Auth from 'src/Auth/Auth';
import { t } from 'i18next';
import PageTitle from 'src/components/PageTitle/PageTitle';

const styles = theme => ({
  content: {
    margin: theme.spacing(2),
    maxWidth: '700px'
  }
});

const PartnerRoute = ({
  component: Component,
  componentProps,
  classes,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        const { location, history } = props;
        const { token, ...rest } = qs.parse(location.search, {
          ignoreQueryPrefix: true
        });
        let isAuthenticated = Auth.isAuthenticated();

        if (token) {
          const date = new Date();
          // add a day
          date.setDate(date.getDate() + 1);
          // do soemthing
          localStorage.setItem(AUTH0_KEYS.EV_ACCESS_TOKEN, token);
          localStorage.setItem(AUTH0_KEYS.EXPIRES_AT, date.getTime());
          // remove token from url
          let search = '';
          if (rest && Object.keys(rest).length) {
            search = `?${qs.stringify(rest)}`;
          }
          history.replace({ pathname: location.pathname, search });
          // whe're authenticated
          isAuthenticated = true;
        }

        if (isAuthenticated && (isPartnerAdmin() || isGroupAdmin())) {
          return <Component {...componentProps} {...props} />;
        }

        const title = t('partner:route.title');

        // you aren't allowed here bozo
        return (
          <>
            <PageTitle subPageTitle={title} />
            <Typography variant="h5">{title}</Typography>
            <Alert severity="warning">
              <AlertTitle>{t('partner:route.noAuthHeader')}</AlertTitle>
              {t('partner:route.noAuthBody')}
            </Alert>
          </>
        );
      }}
    />
  );
};

export default flow(withStyles(styles))(PartnerRoute);
