import { useRef, useMemo } from 'react';
import { flow } from 'lodash';
import { Field, getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';
import classnames from 'classnames';

import { Button, Typography } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';

import { readUploadedFileAsText } from 'src/common/fileReader';

import { GALLERY_TYPE } from 'src/pages/Gallery/constants';

import RenderDropzone from 'src/components/ReduxForm/RenderDropzone';

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: `${theme.spacing(2)} 0`
  },
  dropzoneContainer: {
    width: '100%',
    height: '300px',
    border: `5px dashed ${theme.palette.grey[500]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dragDropText: {
    fontWeight: 'bold',
    fontSize: '30px',
    marginBottom: theme.spacing(3)
  },
  fileContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  fileIcon: {
    height: '100px',
    width: '100px',
    marginBottom: theme.spacing(3),
    color: theme.palette.success.main
  },
  dragDropTextSuccess: {
    color: theme.palette.success.main
  }
});

const pageText = () => ({
  dragDrop: t('admin:blueprintBuilder.importExportDragDrop'),
  remove: t('admin:blueprintBuilder.importExportRemoveButton'),
  import: t('admin:blueprintBuilder.importExportImportButton'),
  success: t('admin:blueprintBuilder.importExportSuccessMessage')
});

const ImportBlueprint = props => {
  const { classes, formValues, change, formName, onClose } = props;
  const text = useMemo(() => pageText(), []);
  const inputRef = useRef();
  const fileUploaded = !!formValues?.importDocument;

  const handleSubmit = async file => {
    if (file) {
      try {
        const fileContents = await readUploadedFileAsText(file[0]);
        const fileContentsParsed = await JSON.parse(fileContents);
        // these are unique so we don't want to set them on import
        delete fileContentsParsed.document.id;
        delete fileContentsParsed.document.architectureId;
        delete fileContentsParsed.document.slug;
        delete fileContentsParsed.document.organization;
        delete fileContentsParsed.document.architecture;
        delete fileContentsParsed.document.blueprint.slug;

        change(formName, 'importDocument', fileContentsParsed);
        onClose();
      } catch {
        // nothing to update if we failed to read file
        change(formName, 'importDocument', '');
      }
    }
  };

  const clearUpload = () => {
    change(formName, 'importDocument', '');
  };

  return (
    <div className={classes.container}>
      <Field
        component={RenderDropzone}
        galleryType={GALLERY_TYPE.blueprint}
        inputRef={inputRef}
        name="importDocument"
        containerClass={classes.dropzoneContainer}
        customOnChange={handleSubmit}
      >
        <Typography
          className={classnames(classes.dragDropText, {
            [classes.dragDropTextSuccess]: fileUploaded
          })}
        >
          {fileUploaded ? text.success : text.dragDrop}
        </Typography>
        {fileUploaded ? (
          <div className={classes.fileContainer}>
            <DescriptionIcon className={classes.fileIcon} />
            <Button
              color="primary"
              onClick={clearUpload}
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              {text.remove}
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            onClick={() => inputRef.current.click()}
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            {text.import}
          </Button>
        )}
      </Field>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { formName } = ownProps;
  const formValues = getFormValues(formName)(state);
  return {
    formValues
  };
};

export default flow(
  connect(mapStateToProps, { change }),
  withStyles(styles)
)(ImportBlueprint);
