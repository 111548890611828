import { Accordion, AccordionSummary, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const EditQuickstartSkeleton = () => {
  return (
    <>
      <Accordion key="loadingLabel">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton variant="text" width="100%" />
        </AccordionSummary>
      </Accordion>

      <Accordion key="loadingCustomDesign">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton variant="text" width="100%" />
        </AccordionSummary>
      </Accordion>

      <Accordion key="loadingAutomation">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Skeleton variant="text" width="100%" />
        </AccordionSummary>
      </Accordion>
    </>
  );
};

export default EditQuickstartSkeleton;
