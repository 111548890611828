import { useState } from 'react';
import { CircularProgress, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

const pageText = () => ({
  defaultErrorMessage: t('common:defaultLoadingErrorMessage')
});

export interface LoadingProps {
  loading?: boolean;
  error?: unknown;
  errorMessage?: string | null;
  className?: string;
  inline?: boolean;
  size?: number;
}

const Loading = (props: LoadingProps) => {
  const text = pageText();
  const {
    loading = true, // if we just want to wrap this in a conditional
    error = null,
    errorMessage = text.defaultErrorMessage,
    className,
    inline = false,
    size = 40 // material default
  } = props;

  const [prevError, setPrevError] = useState(error);

  if (error && !prevError) {
    enqueueSnackbar(`${errorMessage as any}`, {
      variant: 'error'
    });
    setPrevError(error);
  }

  if (!loading && !error) return null;

  return (
    <div
      className={`${(className as any) && className}`}
      style={{
        textAlign: 'center',
        ...(inline
          ? {
              display: 'inline-block'
            }
          : {})
      }}
    >
      {error ? (
        <div
          style={{
            color: '#c62828'
          }}
        >
          <ErrorIcon
            style={{
              color: '#c62828',
              width: '40px',
              height: '40px'
            }}
          />
          <br />
          <Typography
            style={{
              color: '#c62828',
              fontSize: '1.1rem'
            }}
          >
            {errorMessage}
          </Typography>
        </div>
      ) : (
        <CircularProgress size={size} />
      )}
    </div>
  );
};

export default Loading;
