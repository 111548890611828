import { gql } from 'src/generated/gql';

export const createOrUpdateSkinSettings = gql(`
  mutation CreateOrUpdateSkinSettings(
    $updateSettings: JSON!
    $updateType: SettingsUpdateType!
  ) {
    createOrUpdateSkinSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      previousSettings
      newSettings
      updateType
    }
  }
`);

export const deleteDomain = gql(`
  mutation DeleteDomain($id: ID!) {
    deleteDomain(id: $id)
  }
`);

export const reactivateDomain = gql(`
  mutation reactivateDomain($id: ID!) {
    reactivateDomain(id: $id) {
      id
      organizationId
      fqdn
      isPrimary
      isActive
    }
  }
`);

export const updateDomain = gql(`
  mutation UpdateDomain($updateInput: UpdateOrganizationDomainInput!) {
    updateDomain(updateInput: $updateInput) {
      id
      organizationId
      fqdn
      authenticationUrl
      logoutUrl
      isPrimary
      isActive
    }
  }
`);

export const createDomain = gql(`
  mutation CreateDomain($createInput: CreateOrganizationDomainInput!) {
    createDomain(createInput: $createInput) {
      id
      organizationId
      fqdn
      authenticationUrl
      logoutUrl
      isPrimary
      isActive
    }
  }
`);

export const setDismissModal = gql(`
  mutation updateUserSettingsFromSkinSettings(
    $updateSettings: JSON!
    $updateType: UserSettingUpdateType
  ) {
    updateUserSettings(
      updateSettings: $updateSettings
      updateType: $updateType
    ) {
      newSettings
      previousSettings
    }
  }
`);
