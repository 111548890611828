// Disable eslint because we are just migrating this file to TS
/* eslint-disable @typescript-eslint/no-floating-promises */
import { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react';
import { isEmpty, head } from 'lodash';
import { useWatch } from 'react-hook-form';
import { t } from 'i18next';

import { Box, TextField, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Instrumentation from 'src/instrumentation';

type PromoCode = {
  promoCode: string;
};

interface PromoCodeProps {
  promoCodes: PromoCode[];
  currentPromoCodes: PromoCode[];
  spendAmount: number;
  promoCodeError: string;
  onChange: (promoCodes: PromoCode[]) => void;
  validatePromoCode: (promoCode?: string) => Promise<PromoCode>;
}

const pageText = () => {
  return {
    tooltipMessage: t('programCreate:Checkout.promoMissingSpend'),
    applyButton: t('programCreate:Checkout.applyButton'),
    addPromoLabel: t('programCreate:Checkout.promoLabel'),
    changePromoLabel: t('programCreate:Checkout.promoLabelReplace')
  };
};

const PromoCode = (props: PromoCodeProps) => {
  const {
    currentPromoCodes = [],
    spendAmount,
    promoCodeError,
    onChange,
    validatePromoCode
  } = props;

  const promoCodes: PromoCode[] = useWatch({
    name: 'spendStep.promoCodes',
    defaultValue: []
  });

  const [promoText, setPromoText] = useState('');
  const [addPromoLoading, setAddPromoLoading] = useState(false);
  const text = pageText();

  const setPromo = async (promoCode: string | undefined) => {
    let promotion: PromoCode | undefined;
    if (!promoCode || promoCode === '') {
      return;
    }

    setAddPromoLoading(true);

    try {
      promotion = await validatePromoCode(promoCode);
      Instrumentation.logEvent(
        Instrumentation.Events.SubmitProgramPromoCodeClicked,
        { action: 'success' }
      );
    } catch (error) {
      setAddPromoLoading(false);
      setPromoText('');
      Instrumentation.logEvent(
        Instrumentation.Events.SubmitProgramPromoCodeClicked,
        { action: 'fail' }
      );
      // we handle the error in validatePromoCode
      return;
    }

    setPromoText('');
    setAddPromoLoading(false);
    return onChange([promotion]);
  };

  useEffect(() => {
    // componentWillMount
    // Calculate promo on initialization
    if (!isEmpty(promoCodes)) {
      setPromo(head(promoCodes)?.promoCode);
    }

    // If we have an applied promo we want to recalculate
    // on unmount incase the user has changed the price
    return () => {
      // componentWillUnmount
      if (!isEmpty(promoCodes)) {
        setPromo(head(promoCodes)?.promoCode);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if the spend amount changes we want to recalculate
  useEffect(() => {
    setPromo(head(promoCodes)?.promoCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spendAmount]);

  const handleChange = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPromoText(value.toUpperCase());
  };

  const addPromo = () => {
    setPromo(promoText);
  };

  const handelKeyPress = ({ key }: KeyboardEvent<HTMLElement>) => {
    if (key === 'Enter') {
      addPromo();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        gap: 2
      }}
    >
      <Tooltip
        // Note: '' will cause the tooltip not to render this seems
        //       to be the way to get them to conditionally render
        title={spendAmount ? '' : text.tooltipMessage}
        aria-label={spendAmount ? '' : text.tooltipMessage}
      >
        <TextField
          autoComplete="off"
          sx={{ width: '100%', margin: 0 }}
          disabled={!spendAmount || addPromoLoading}
          error={!!promoCodeError}
          helperText={promoCodeError}
          id="promoCodeInput"
          margin="normal"
          onChange={handleChange}
          onKeyPress={handelKeyPress}
          value={promoText}
          size="small"
          variant="filled"
          label={
            isEmpty(currentPromoCodes)
              ? text.addPromoLabel
              : text.changePromoLabel
          }
        />
      </Tooltip>
      <LoadingButton
        loading={addPromoLoading}
        aria-label="add promo"
        disabled={!spendAmount}
        onClick={addPromo}
        sx={{ px: 3 }}
        size="large"
      >
        {text.applyButton}
      </LoadingButton>
    </Box>
  );
};

export default PromoCode;
