import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import {
  validateNotBlankIfNotNil,
  validateRequired,
  validateSlugIfNotNil,
  validateUrl,
  validateUrlFqdnOrg
} from 'src/common/validations';

import { AUTH_PROVIDER_TYPES } from 'src/Auth/common';
import { t } from 'i18next';

export const AUTH_CONFIG_TYPES = {
  auth0: 'auth0',
  internal: 'internal',
  okta_direct_sso: 'okta_direct_sso'
};

export const FORM_NAME = 'createOrganization';
export const auth0SectionName = 'createAuth0ConfigInput';
export const internalAuthSectionName = 'createInternalAuthConfigInput';
export const oktaDirectSSOAuthSectionName = 'createOktaDirectSSOConfigInput';
export const createIntegrationStripeConnectProfileName =
  'createIntegrationStripeConnectProfile';

export const formatAuth0ConfigInputName = name => {
  return `${auth0SectionName}.${name}`;
};

export const formatInternalAuthInputName = name => {
  return `${internalAuthSectionName}.${name}`;
};

export const formatOktaDirectSSOAuthInputName = name => {
  return `${oktaDirectSSOAuthSectionName}.${name}`;
};

export const authConfigByProviderType = provider => {
  // default is auth0
  if (!provider) {
    return AUTH_CONFIG_TYPES.auth0;
  }
  // most of these map 1:1
  // some provider types use the same config type
  if (provider === AUTH_PROVIDER_TYPES.auth0_sso) {
    return AUTH_CONFIG_TYPES.auth0;
  }
  return AUTH_CONFIG_TYPES[provider];
};

export const getCreateOrganizationInputs = ({
  enums,
  industryOptions
} = {}) => [
  {
    name: 'name',
    displayName: t(
      'admin:createOrganization.createOrganizationInputs.organizationName.displayName',
      'Organization Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'fqdn',
    displayName: t(
      'admin:createOrganization.createOrganizationInputs.fdqn.displayname',
      'Fully Qualified Domain Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: {
      validateRequired,
      validateUrlFqdnOrg: validateUrlFqdnOrg()
    },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'slug',
    displayName: t(
      'admin:createOrganization.createOrganizationInputs.slug.displayName',
      'Slug'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateSlugIfNotNil: validateSlugIfNotNil() },
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:createOrganization.createOrganizationInputs.slug.tooltip',
          'A human-readable organization idenitifer. This must be the same in Staging and Production.'
        )
      }
    }
  },
  {
    name: 'authProviderType',
    displayName: t(
      'admin:createOrganization.createOrganizationInputs.authProviderType.displayName',
      'Auth Provider Type'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: enums?.authProviderTypeEnumFields?.[0],
    reduxValidations: {},
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        options: enums?.authProviderTypeEnumFields || [],
        tooltip: t(
          'admin:createOrgnization.createOrganizationInputs.authProviderType.tooltip',
          'Type of authorization e.g. auth0 = auth0, internal = sso token exchange'
        )
      }
    }
  },
  {
    name: 'industrySlug',
    displayName: 'Industry',
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        options: industryOptions || [],
        columnWidth: 2,
        tooltip:
          'The type of business the partner org is in. This will be used to determine the industry specific branding and will affect the quality of the AI chat ad copy suggestion results.'
      }
    }
  },
  {
    name: createIntegrationStripeConnectProfileName,
    displayName: t(
      'admin:createOrganization.createOrganizationInputs.createIntegrationStripeConnectProfileName.displayName',
      'Setup stripe connect allong with org creation'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    reduxValidations: {},
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:createOrganization.createOrganizationInputs.createIntegrationStripeConnectProfileName.tooltip',
          'after the org is created we will make a call to add this integration org to a shared integration stripe connect account'
        )
      }
    }
  }
];

export const getCreateOrganizationAuth0Inputs = () => [
  {
    name: formatAuth0ConfigInputName('domain'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.domain.displayName',
      'Domain'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired, validateUrl: validateUrl() },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('connectionType'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.connectionType.displayName',
      'Connection Type'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: 'Username-Password-Authentication',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: true,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('configurationBaseUrl'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.configurationBaseUrl.displayName',
      'Configuration Base Url'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: 'https://cdn.auth0.com',
    reduxValidations: { validateRequired, validateUrl: validateUrl() },
    isRequired: true,
    isHidden: true,
    displaySortOrder: 3,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaClientId'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaClientId.displayName',
      'SPA Client ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 4,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaAudience'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaAudience.displayName',
      'SPA Audience'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 5,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaSigningSecret'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaSigningSecret.displayName',
      'SPA Signing Secret'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 6,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaCallbackUrl'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaCallbackUrl.displayName',
      'SPA Callback Url'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired, validateUrl: validateUrl() },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 7,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaDomain'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaDomain.displayName',
      'SPA Domain'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 8,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaLogoutUrl'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaLogoutUrl.displayName',
      'SPA Logout Url'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired, validateUrl: validateUrl() },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 9,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaResponseType'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaResponseType.displayName',
      'SPA Response Type'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: 'token id_token',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: true,
    displaySortOrder: 10,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('spaScope'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.spaScope.displayName',
      'SPA Scope'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: 'openid profile email offline_access',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: true,
    displaySortOrder: 11,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('managementClientSecret'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.managementClientSecret.displayName',
      'Management Client Secret'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 12,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('managementClientId'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.managementClientId.displayName',
      'Management Client ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 13,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('managementBaseUrl'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.managementBaseUrl.displayName',
      'Management Base Url'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired, validateUrl: validateUrl() },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 14,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('managementAudience'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.managementAudience.displayName',
      'Management Audience'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 15,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('loginBaseUrl'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.loginBaseUrl.displayName',
      'Login Base Url'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired, validateUrl: validateUrl() },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 16,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: formatAuth0ConfigInputName('loginClientSecret'),
    displayName: t(
      'admin:createOrganization.createOrganizationAuth0Inputs.loginClientSecret.displayName',
      'Login Client Secret'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: { validateRequired },
    isRequired: true,
    isHidden: false,
    displaySortOrder: 17,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

export const getCreateOrganizationOktaDirectSSOAuthInputs = () => [
  {
    name: formatOktaDirectSSOAuthInputName('clientId'),
    displayName: t(
      'admin:createOrganization.createOrganizationOktaDirectSSOAuthInputs.clientId.displayName',
      'OKTA Client Id'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: { validateNotBlankIfNotNil: validateNotBlankIfNotNil() },
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:createOrganization.createOrganizationOktaDirectSSOAuthInputs.clientId.tooltip',
          'The okta client id'
        )
      }
    }
  },
  {
    name: formatOktaDirectSSOAuthInputName('clientSecret'),
    displayName: t(
      'admin:createOrganization.createOrganizationOktaDirectSSOAuthInputs.clientSecret.displayName',
      'OKTA Client Secret'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: { validateNotBlankIfNotNil: validateNotBlankIfNotNil() },
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:createOrganization.createOrganizationOktaDirectSSOAuthInputs.clientSecret.tooltip',
          'The okta client secret'
        )
      }
    }
  },
  {
    name: formatOktaDirectSSOAuthInputName('authServerFqdn'),
    displayName: t(
      'admin:createOrganization.createOrganizationOktaDirectSSOAuthInputs.authServerFqdn.displayName',
      'OKTA Authorization Server FQDN'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: { validateNotBlankIfNotNil: validateNotBlankIfNotNil() },
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:createOrganization.createOrganizationOktaDirectSSOAuthInputs.authServerFqdn.tooltip',
          'The okta login fqdn eg. client123.okta.com'
        )
      }
    }
  }
];

export const getCreateOrganizationInternalAuthInputs = () => [
  {
    name: formatInternalAuthInputName('jwtSigningSecret'),
    displayName: t(
      'admin:createOrganization.getCreateOrganizationInternalAuthInputs.jwtSigningSecret.displayName',
      'jwtSigningSecret'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: null,
    reduxValidations: { validateNotBlankIfNotNil: validateNotBlankIfNotNil() },
    isRequired: false,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 2,
        tooltip: t(
          'admin:createOrganization.getCreateOrganizationInternalAuthInputs.jwtSigningSecret.tooltip',
          'The secret used to sign JWT tokens. If left blank, the server will generate one automatically, which is preferred.'
        )
      }
    }
  }
];
