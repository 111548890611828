import { t } from 'i18next';

import { Box, Button } from '@mui/material';

import Modal from 'src/components/Modal';
import QuickStartList from 'src/pages/Dashboard/QuickStartList';
import useIsMobile from 'src/hooks/useIsMobile';

const pageText = () => {
  return {
    title: t('programs:selectQuickStartModal.title'),
    cancelButton: t('programs:selectQuickStartModal.cancelButton')
  };
};

interface SelectQuickStartModalProps {
  open: boolean;
  onClose: () => void;
  isSupervisable: boolean | undefined;
  selectedProductId?: string;
  selectProduct?: (productId: string, architectureId: string) => void;
}

const SelectQuickStartModal = ({
  open,
  onClose,
  isSupervisable = false,
  selectedProductId,
  selectProduct
}: SelectQuickStartModalProps) => {
  const text = pageText();
  const isMobile = useIsMobile();
  return (
    <Modal
      fullWidth
      data-cy="select-quick-start-modal"
      height={isMobile ? '90vh' : '70vh'}
      maxWidth="1244px"
      open={open}
      onClose={onClose}
      headerText={text.title}
      FooterComponent={() => {
        return (
          <Box>
            <Button onClick={onClose}>{text.cancelButton}</Button>
          </Box>
        );
      }}
    >
      <Box sx={{ py: 0.5 }}>
        <QuickStartList
          displayFilters
          isSupervisable={isSupervisable}
          selectedProductId={selectedProductId}
          selectProduct={selectProduct}
        />
      </Box>
    </Modal>
  );
};

export default SelectQuickStartModal;
