import { useState } from 'react';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Box, ButtonBase, Collapse, Typography } from '@mui/material';

interface RefundTableToggleProps {
  children: JSX.Element;
}

const RefundTableToggle = ({ children }: RefundTableToggleProps) => {
  const [refundBreakdownExpanded, setRefundBreakdownExpanded] = useState(false);

  const toggleExpandRefundBreakdown = () => {
    setRefundBreakdownExpanded(prevState => !prevState);
  };

  return (
    <Box>
      <ButtonBase
        sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
        onClick={toggleExpandRefundBreakdown}
      >
        <ExpandMoreIcon
          sx={{
            fontSize: 30,
            transform: `rotate(${refundBreakdownExpanded ? 180 : 0}deg)`,
            position: 'relative',
            bottom: 1
          }}
        />
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: 'primary.main'
          }}
        >
          Refund Breakdown
        </Typography>
      </ButtonBase>
      <Collapse in={refundBreakdownExpanded}>{children}</Collapse>
    </Box>
  );
};

export default RefundTableToggle;
