import { get } from 'lodash';

import { AUTH0_KEYS } from './common';

export const getContextFromJwt = accessToken => {
  let context;

  try {
    const base64Url = accessToken.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    context = JSON.parse(atob(base64));
  } catch (err) {
    // Do nothing - this catch occurs when the accessToken isn't defined.
  }

  return context;
};

// This enables pulling values from ev_access_token.
export const getAuthJson = () => {
  const accessToken = localStorage.getItem(AUTH0_KEYS.EV_ACCESS_TOKEN);

  return getContextFromJwt(accessToken);
};

export const getOrganizationIdFromToken = () => {
  const context = getAuthJson();

  return get(context, 'li_organization_id');
};

// https://gist.github.com/jed/982883
export const randomUUID = a => {
  return a
    ? // eslint-disable-next-line no-bitwise
      (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, randomUUID);
};
