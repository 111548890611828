import { useState, useMemo } from 'react';
import { t } from 'i18next';

import { Button, AppBar, Tabs, Tab } from '@mui/material';

import Modal from 'src/components/Modal';

import ExportBlueprint from './ExportBlueprint';
import ImportBlueprint from './ImportBlueprint';

const pageText = () => ({
  modalHeader: t('admin:blueprintBuilder.importExportModalHeader')
});

const TabContent = [
  {
    label: 'Import',
    key: 'import',
    content: ImportBlueprint
  },
  {
    label: 'Export',
    key: 'export',
    content: ExportBlueprint
  }
];

const ImportExportModal = props => {
  const {
    formName,
    disableImport,
    setImportExportModalClose,
    currentDocument = {}
  } = props;
  const text = useMemo(() => pageText(), []);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // saving for content to figure out later
  if (disableImport) {
    TabContent.shift();
  }
  const SelectedTabContent = TabContent[selectedTab].content;

  return (
    <Modal
      open
      headerText={text.modalHeader}
      fullWidth
      maxWidth="lg"
      onClose={setImportExportModalClose}
      FooterComponent={() => (
        <>
          <Button onClick={setImportExportModalClose}>Close</Button>
        </>
      )}
    >
      <AppBar position="static" color="secondary">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
        >
          {TabContent.map(tab => {
            return <Tab key={tab.key} label={tab.label} />;
          })}
        </Tabs>
      </AppBar>

      <SelectedTabContent
        onClose={setImportExportModalClose}
        formName={formName}
        currentDocument={currentDocument}
      />
    </Modal>
  );
};

export default ImportExportModal;
