import { gql } from 'src/generated/gql';

export const activateCatalog = gql(`
  mutation activateCatalog($architectureId: ID!, $catalogId: ID!) {
    activateCatalog(architectureId: $architectureId, catalogId: $catalogId) {
      id
    }
  }
`);

export const deactivateCatalog = gql(`
  mutation deactivateCatalog($architectureId: ID!, $catalogId: ID!) {
    deactivateCatalog(architectureId: $architectureId, catalogId: $catalogId) {
      id
    }
  }
`);

export const createCatalogAndContentSet = gql(`
  mutation createCatalogAndContentSet(
    $architectureId: ID!
    $input: CatalogInput!
  ) {
    createCatalogAndContentSet(architectureId: $architectureId, input: $input) {
      id
    }
  }
`);

export const updateCatalog = gql(`
  mutation updateCatalog($catalogId: ID!, $input: UpdateCatalogInput) {
    updateCatalog(catalogId: $catalogId, input: $input) {
      id
    }
  }
`);

// do we show content set?
export const updateContentSet = gql(`
  mutation updateContentSet($catalogId: ID!, $input: UpdateContentSetInput) {
    # name: String,
    # description: String
    # contentSetTable: String
    updateContentSet(catalogId: $catalogId, input: $input) {
      id
    }
  }
`);

export const createFieldMetadata = gql(`
  mutation createFieldMetadata(
    $catalogId: ID!
    $input: [ContentSetFieldMetadataInput!]!
  ) {
    createFieldMetadata(catalogId: $catalogId, input: $input) {
      id
    }
  }
`);

export const updateFieldMetadata = gql(`
  mutation updateFieldMetadata(
    $catalogId: ID!
    $input: [ContentSetFieldMetadataUpdateInput!]!
  ) {
    updateFieldMetadata(catalogId: $catalogId, input: $input) {
      id
    }
  }
`);

// what does this do and do we need to expose it?
// if so where do we get the data?
export const createCatalogImplicitFilter = gql(`
  mutation createCatalogImplicitFilter(
    $catalogId: ID!
    $input: CatalogImplicitFilterInput!
  ) {
    createCatalogImplicitFilter(catalogId: $catalogId, input: $input) {
      id
    }
  }
`);

export const updateCatalogImplicitFilter = gql(`
  mutation updateCatalogImplicitFilter(
    $implicitFilterId: ID!
    $input: CatalogImplicitFilterInput!
  ) {
    updateCatalogImplicitFilter(
      implicitFilterId: $implicitFilterId
      input: $input
    ) {
      id
    }
  }
`);

export const createOrUpdateCatalogCollapseKey = gql(`
  mutation createOrUpdateCatalogCollapseKey(
    $catalogId: ID!
    $input: CollapseKeyInput
  ) {
    createOrUpdateCatalogCollapseKey(catalogId: $catalogId, input: $input) {
      id
    }
  }
`);
