import { isEmpty } from 'lodash';
import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { Grid, Alert, Box } from '@mui/material';

import useProgram from 'src/pages/Program/utils/useProgram';
import ProgramDynamicUserInputs from 'src/pages/Program/ProgramDynamicUserInputs';

import NoFacebookPage from 'src/pages/Program/EmptyStates/NoFacebookPage';
import NoBlueprint from 'src/pages/Program/EmptyStates/NoBlueprint';
import NoUserInputs from 'src/pages/Program/EmptyStates/NoUserInputs';
import Duration from 'src/pages/Program/ProgramSteps/Duration';
import Budget from 'src/pages/Program/ProgramSteps/MultiLocationConfigure/Budget';
import PaymentType from 'src/pages/Program/ProgramSteps/PaymentType';
import {
  PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME,
  PROGRAM_FORM_SECTION_SPEND_NAME
} from 'src/pages/Program/Constants';

import { LOCATIONS_OVERRIDES_BY_ID_NAME } from '../MultiLocationPublish/utils';

interface DefaultValuesFormProps {
  selectedBusinessObjects: {
    selectedBusinessObjects: any[];
  };
  selectedLocationsMetadata: Location[];
  locationId?: string;
  hidePaymentType?: boolean;
  hideDuration?: boolean;
}

const DefaultValuesForm = ({
  selectedBusinessObjects: { selectedBusinessObjects },
  selectedLocationsMetadata = [],
  locationId,
  hidePaymentType = false,
  hideDuration = false
}: DefaultValuesFormProps) => {
  const {
    selectedBlueprint,
    contentName,
    blueprintHasFacebook,
    hasFacebookPage,
    contentColumns,
    isContentSelectable,
    offersChanged,
    formName,
    isMultiLocation,
    isEditing,
    isDraft
  } = useProgram();

  const dynamicUserInputSections = selectedBlueprint?.inputSections;
  const formSectionDynamicUserInputs = `${LOCATIONS_OVERRIDES_BY_ID_NAME}.${locationId}.${PROGRAM_FORM_SECTION_DYNAMIC_INPUTS_NAME}`;
  const formSectionSpendStep = `${LOCATIONS_OVERRIDES_BY_ID_NAME}.${locationId}.${PROGRAM_FORM_SECTION_SPEND_NAME}`;
  const hookFormMethods = useFormContext();

  // Check for invalid productId setup.
  if (blueprintHasFacebook && !hasFacebookPage) {
    return <NoFacebookPage />;
  }

  if (!selectedBlueprint) {
    // Note: the user shouldn't ever be able to get to the page if these
    //       conditions are true. That said, sometimes shit happens so this will
    //       be a nice safety net.
    //
    //       When a blueprint is not selected the modal is open and if you close it
    //       you are routed to the programs or automations table pages

    return <NoBlueprint />;
  }

  // no dynamic user inputs
  if (isEmpty(dynamicUserInputSections)) {
    return <NoUserInputs />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ProgramDynamicUserInputs
        blueprint={selectedBlueprint}
        dynamicUserInputSections={dynamicUserInputSections}
        contentColumns={contentColumns}
        businessObjects={selectedBusinessObjects}
        isContentSelectable={isContentSelectable}
        formName={formName}
        contentName={contentName}
        // TODO: we need to still account for these props
        // adCreativeErrors={adCreativeErrors}
        // isChannelValidationLoading={isChannelValidationLoading}
        // formSyncErrors={formSyncErrors}
        // formAsyncErrors={formAsyncErrors}
        // channelInputValidators={channelInputValidators}
        {...(locationId && { formSectionName: formSectionDynamicUserInputs })}
        isHookForm
        isMultiLocation={isMultiLocation}
        hookFormMethods={hookFormMethods}
        selectedLocationsMetadata={selectedLocationsMetadata}
      />
      <Grid container spacing={2}>
        {offersChanged && !isDraft && (
          <Grid item xs={12}>
            <Alert severity="warning">
              {t('programCreate:spend.offersChanged')}
            </Alert>
          </Grid>
        )}

        {!hidePaymentType && <PaymentType />}

        {!isEditing && !hideDuration && (
          <Duration
            {...(locationId && { formSectionName: formSectionSpendStep })}
          />
        )}

        <Budget
          {...(locationId && { formSectionName: formSectionSpendStep })}
        />
      </Grid>
    </Box>
  );
};

export default DefaultValuesForm;
