import { t } from 'i18next';
import { useMemo } from 'react';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid-pro';

import { Tooltip, Button } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import { DEFAULT_NEW_ITEM_ID } from './constants';

const pageText = () => ({
  addRecord: t('dataTable:addRecord')
});

const DataTableToolbar = props => {
  const {
    setTableIsEditing,
    editFocusField,
    isTableEditing,
    alreadyEditingTooltip
  } = props;
  const apiRef = useGridApiContext();
  const allColumns = useMemo(() => apiRef.current.getAllColumns(), [apiRef]);

  const text = useMemo(() => pageText(), []);

  const handleAddClick = () => {
    const id = DEFAULT_NEW_ITEM_ID;

    apiRef.current.updateRows([{ id, isNew: true }]);

    // Wait for the grid to render with the new row
    setTimeout(() => {
      // scroll to it
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1
      });
      // put in edit mode
      apiRef.current.startRowEditMode({
        id,
        fieldToFocus: editFocusField || allColumns[0].field
      });
      // set table to edit mode
      setTableIsEditing(true);
    }, 150);
  };

  return (
    <GridToolbarContainer>
      <Tooltip title={alreadyEditingTooltip}>
        <div>
          <Button
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleAddClick}
            disabled={isTableEditing}
          >
            {text.addRecord}
          </Button>
        </div>
      </Tooltip>
    </GridToolbarContainer>
  );
};

export default DataTableToolbar;
