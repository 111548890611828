import { useCallback, useMemo } from 'react';
import { flow } from 'lodash';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, SubmissionError } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Button, Typography, CircularProgress } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { getInitialValuesFromInputsConfig } from 'src/components/ReduxForm/helpers';
import { DynamicForm } from 'src/components/ReduxForm';
import ErrorMessage from 'src/components/Containers/ErrorMessage';
import { useSnackbar } from 'notistack';

import {
  DEFAULT_FACEBOOK_PAGE_FORM_NAME,
  defaultFacebookPageSettingsInputs
} from './Constants';
import { createOrganizationDefaultFacebookPageSettings } from './mutations';

const styles = theme => ({
  cardHeading: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),

    '& button:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
});

const pageText = () => ({
  cardHeading: t('admin:defaultFacebookPage.cardHeading'),
  errorMessage: t('admin:defaultFacebookPage.errorMessage'),
  submitButton: t('admin:defaultFacebookPage.submitButton'),
  clearButton: t('admin:defaultFacebookPage.clearButton'),
  successSnackMessage: t('admin:defaultFacebookPage.successSnack'),
  errorSnackMessage: t('admin:defaultFacebookPage.errorSnack')
});

const DefaultFacebookPage = props => {
  const {
    classes,
    createOrganizationDefaultFacebookPage,
    dirty,
    handleSubmit,
    reset,
    submitFailed,
    submitting
  } = props;

  const { enqueueSnackbar } = useSnackbar();

  const text = useMemo(() => pageText(), []);

  const onSubmit = useCallback(async data => {
    try {
      await createOrganizationDefaultFacebookPage({
        variables: {
          ...data
        }
      });

      enqueueSnackbar(text.successSnackMessage, {
        variant: 'success'
      });
    } catch (error) {
      const message = text.errorSnackMessage;

      enqueueSnackbar(message, {
        variant: 'error'
      });

      throw new SubmissionError(error);
    }
  }, []);

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Typography component="h2" className={classes.cardHeading}>
        {text.cardHeading}
      </Typography>

      {submitFailed && (
        <div className={classes.errorContainer}>
          <ErrorMessage>{text.errorMessage}</ErrorMessage>
        </div>
      )}

      <DynamicForm inputs={defaultFacebookPageSettingsInputs()} />

      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          disabled={!dirty || submitting}
          type="submit"
          variant="contained"
          endIcon={submitting && <CircularProgress size={15} />}
        >
          {text.submitButton}
        </Button>

        <Button onClick={reset}>{text.clearButton}</Button>
      </div>
    </form>
  );
};

const mapStateToProps = () => {
  const initialValues = getInitialValuesFromInputsConfig(
    defaultFacebookPageSettingsInputs()
  );

  return {
    initialValues
  };
};

export default flow(
  reduxForm({
    form: DEFAULT_FACEBOOK_PAGE_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps),
  graphql(createOrganizationDefaultFacebookPageSettings, {
    name: 'createOrganizationDefaultFacebookPage'
  }),
  withStyles(styles)
)(DefaultFacebookPage);
