import dompurify from 'dompurify';
import { HTMLAttributes, JSXElementConstructor } from 'react';

const Div = (props: HTMLAttributes<HTMLDivElement>) => <div {...props} />;

dompurify.addHook('afterSanitizeAttributes', (node: Element) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    // if we decide to set blank to all links we can uncomment here
    // node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export interface ClientHtmlProps {
  element?: JSXElementConstructor<any>;
  html: string | HTMLElement;
}

const ClientHtml = ({ element, html }: ClientHtmlProps) => {
  const Component = element || Div;
  return (
    <Component // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: dompurify.sanitize(html, { ADD_ATTR: ['target'] })
      }}
    />
  );
};

export default ClientHtml;
