import withTheme from '@mui/styles/withTheme';
import { SVGProps } from 'react';

const Messenger = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={20}
      height={20}
      {...props}
    >
      <radialGradient
        id="messengerGradientA"
        cx={11.087}
        cy={7.022}
        r={47.612}
        gradientTransform="matrix(1 0 0 -1 0 50)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#1292ff" />
        <stop offset={0.079} stopColor="#2982ff" />
        <stop offset={0.23} stopColor="#4e69ff" />
        <stop offset={0.351} stopColor="#6559ff" />
        <stop offset={0.428} stopColor="#6d53ff" />
        <stop offset={0.754} stopColor="#df47aa" />
        <stop offset={0.946} stopColor="#ff6257" />
      </radialGradient>
      <path
        fill="url(#messengerGradientA)"
        d="M44 23.5C44 34.27 35.05 43 24 43c-1.651 0-3.25-.194-4.784-.564a2.015 2.015 0 0 0-1.379.145L13.46 44.77A1.7 1.7 0 0 1 11 43.249v-4.025c0-.575-.257-1.111-.681-1.499C6.425 34.165 4 29.11 4 23.5 4 12.73 12.95 4 24 4s20 8.73 20 19.5z"
      />
      <path
        d="M34.992 17.292c-.428 0-.843.142-1.2.411l-5.694 4.215c-.133.1-.28.15-.435.15a.678.678 0 0 1-.41-.136l-3.972-2.99a4.586 4.586 0 0 0-2.757-.918 4.587 4.587 0 0 0-3.876 2.116l-1.211 1.891-4.12 6.695a1.96 1.96 0 0 0-.071 2.014 2.018 2.018 0 0 0 1.764 1.06c.428 0 .843-.142 1.2-.411l5.694-4.215c.133-.1.28-.15.435-.15.15 0 .291.047.41.136l3.972 2.99c.809.602 1.76.918 2.757.918a4.587 4.587 0 0 0 3.876-2.116l1.211-1.891 4.12-6.695a1.96 1.96 0 0 0 .071-2.014 2.018 2.018 0 0 0-1.764-1.06z"
        opacity={0.05}
      />
      <path
        d="M34.992 17.792c-.319 0-.63.107-.899.31l-5.697 4.218a1.205 1.205 0 0 1-.732.248c-.259 0-.504-.082-.71-.236l-3.973-2.991a4.087 4.087 0 0 0-2.457-.817 4.088 4.088 0 0 0-3.455 1.887l-1.21 1.891-4.115 6.688a1.468 1.468 0 0 0-.058 1.511c.266.486.787.8 1.325.8.319 0 .63-.107.899-.31l5.697-4.218c.216-.163.468-.248.732-.248.259 0 .504.082.71.236l3.973 2.991a4.087 4.087 0 0 0 2.457.817 4.088 4.088 0 0 0 3.455-1.887l1.21-1.891 4.115-6.688c.297-.465.32-1.033.058-1.511a1.538 1.538 0 0 0-1.325-.8z"
        opacity={0.07}
      />
      <path
        fill="#fff"
        d="m34.394 18.501-5.7 4.22c-.61.46-1.44.46-2.04.01L22.68 19.74a3.604 3.604 0 0 0-5.19.94l-1.21 1.89-4.11 6.68c-.6.94.55 2.01 1.44 1.34l5.7-4.22c.61-.46 1.44-.46 2.04-.01l3.974 2.991c1.68 1.25 4.06.82 5.19-.94l1.21-1.89 4.11-6.68c.6-.94-.55-2.01-1.44-1.34z"
      />
    </svg>
  );
};

export default withTheme(Messenger);
