import { useState, useMemo, useCallback, useEffect } from 'react';
import { flow, isEmpty, uniqBy } from 'lodash';
import { reduxForm, getFormValues, Field, change } from 'redux-form';
import { t } from 'i18next';
import { useMutation, useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';

import {
  Grid,
  Typography,
  Paper,
  Divider,
  Switch,
  MenuItem,
  Alert,
  Button,
  Box
} from '@mui/material';
import { styled } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';

import SearchIcon from '@mui/icons-material/Search';

import { validateRequired } from 'src/common/validations';
import { configureInputs } from 'src/components/ReduxForm/helpers';
import { DynamicForm } from 'src/components/ReduxForm';
import RenderSelect from 'src/components/ReduxForm/RenderSelect';
import Table from 'src/components/Table';
import RenderTextField from 'src/components/ReduxForm/RenderTextField';
import TableEmptyState from 'src/components/EmptyStates/TableEmptyState';
import PaginationControls from 'src/components/Pagination/PaginationControls';
import { usePagination } from 'src/components/Pagination/hooks';
import Heading from 'src/components/PageElements/Heading';

import {
  SEARCH_FORM_NAME,
  findOrderExtraInputs,
  columnSchemaBase,
  supportedChannelCodes,
  supportedUnitCodes,
  initialChannelCode,
  initialUnitCode
} from './Constants';
import ConfirmModal from './ConfirmModal';
import { getOrderFragmentsBreakdown, findOrderFragments } from './queries';
import {
  setManuallyManagedFragment,
  resetManuallyManagedFragment
} from './mutations';

const DEFAULT_RESULTS_PER_PAGE = 20;

const errorOutcomes = {
  OK: 'OK',
  INVALID_STATUS: 'INVALID_STATUS',
  INVALID_TYPE: 'INVALID_TYPE',
  UNSUPPORTED: 'UNSUPPORTED'
};

const PaperContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(2)
}));

const AlertAligned = styled(Alert)(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const getText = () => ({
  title: t('admin:manuallyLinkOrders.title'),
  subtitle: t('admin:manuallyLinkOrders.subtitle'),
  searchTitle: t('admin:manuallyLinkOrders.searchTitle'),
  filterTitle: t('admin:manuallyLinkOrders.filterTitle'),
  findOrderInputLabel: t('admin:manuallyLinkOrders.findOrderInputLabel'),
  searchButton: t('admin:manuallyLinkOrders.searchButton'),
  resultsPerPageLabel: t('admin:manuallyLinkOrders.resultsPerPageLabel'),
  emptyTable: t('admin:manuallyLinkOrders.emptyTable'),
  errorFetchingData: t('admin:manuallyLinkOrders.errorFetchingData'),
  errorSetFragment: t('admin:manuallyLinkOrders.errorSetFragment'),
  successSetFragmentButton: t(
    'admin:manuallyLinkOrders.successSetFragmentButton'
  ),
  successSetFragment: t('admin:manuallyLinkOrders.successSetFragment')
});

const ManuallyLinkOrders = props => {
  const { handleSubmit, formValues } = props;
  const text = useMemo(() => getText(), []);

  const [confirmModalOpen, setConfirmModal] = useState(false);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [aboveTableError, setAboveTableError] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [updatedOutputValue, setUpdatedOutputValue] = useState(null);
  const [aboveTableSuccess, setAboveTableSuccess] = useState(null);

  // data to be used in the dropdowns
  const [
    queryOrderFragmentsBreakdown,
    { data: breakdownData, loading: breakdownDataLoading }
  ] = useLazyQuery(getOrderFragmentsBreakdown);
  const noBreakdownData = isEmpty(breakdownData);

  const onGetOrderFragmentsBreakdown = useCallback(
    findOrderOrProgramId => {
      queryOrderFragmentsBreakdown({
        variables: { orderOrProgramId: findOrderOrProgramId }
      });
    },
    [queryOrderFragmentsBreakdown]
  );

  const [
    getOrderFragmentsQuery,
    {
      loading: orderFragmentsLoading,
      refetch: orderFragmentsRefetch,
      data: orderFragmentData,
      error: orderFragmentError
    }
  ] = useLazyQuery(findOrderFragments);

  useEffect(() => {
    if (orderFragmentError) {
      return setAboveTableError(text.errorFetchingData);
    }
    setAboveTableError(null);
  }, [orderFragmentError, text.errorFetchingData]);

  const onGetOrderFragment = useCallback(
    inputData => {
      // clear success message
      setAboveTableSuccess(null);
      // set id so we can query data for the dropdowns
      onGetOrderFragmentsBreakdown(inputData?.orderOrProgramId);
      // get data for the table
      getOrderFragmentsQuery({
        variables: {
          first: inputData?.resultsPerPage,
          input: {
            orderOrProgramId: inputData?.orderOrProgramId,
            ...(inputData?.channelCode && {
              channelCode: inputData?.channelCode
            }),
            ...(inputData?.unitCode && {
              unitCode: inputData?.unitCode
            }),
            ...(inputData?.contentValue && {
              contentValue: inputData?.contentValue
            }),
            ...(inputData?.outputValue && {
              outputValue: inputData?.outputValue
            })
          }
        }
      });
    },
    [getOrderFragmentsQuery, onGetOrderFragmentsBreakdown]
  );

  const { navigateNext, navigatePrev } = usePagination({
    edges: orderFragmentData?.findOrderFragments?.results?.edges,
    resultsPerPage: formValues?.resultsPerPage,
    refetchCallback: orderFragmentsRefetch,
    refetchOptions: {}
  });

  const [setFragment, { loading: setFragmentLoading }] = useMutation(
    setManuallyManagedFragment,
    {
      onCompleted: ({ setManuallyManagedFragment }) => {
        if (
          setManuallyManagedFragment?.outcome ===
            errorOutcomes.INVALID_STATUS ||
          setManuallyManagedFragment?.outcome === errorOutcomes.INVALID_TYPE ||
          setManuallyManagedFragment?.outcome === errorOutcomes.UNSUPPORTED
        ) {
          // clear the updated value b/c it failed
          setUpdatedOutputValue(null);
          return setModalError(setManuallyManagedFragment?.message);
        }

        // clear the error
        setModalError(null);
        // close the modal
        setConfirmModal(false);
        // clear the selected fragment
        setSelectedFragment(null);
        // refetch the fragment table data
        orderFragmentsRefetch();

        // set success message with button to refetch the table data
        setAboveTableSuccess(
          <>
            {text.successSetFragment}
            <Button
              variant="text"
              size="small"
              onClick={() => {
                // refetch the fragment table data and filter by the new updated output value
                orderFragmentsRefetch({
                  input: {
                    orderOrProgramId: formValues?.orderOrProgramId,
                    ...(updatedOutputValue && {
                      outputValue: updatedOutputValue
                    })
                  }
                });
              }}
            >
              {text.successSetFragmentButton}
            </Button>
          </>
        );
      },
      onError: () => {
        // clear the success message
        setAboveTableSuccess(null);
        // clear the updated value b/c it failed
        setUpdatedOutputValue(null);
        // set error
        setModalError(text.errorSetFragment);
      }
    }
  );

  const onConfirm = useCallback(
    data => {
      // reset the error states
      setAboveTableError(null);
      setModalError(null);
      // clear the success message
      setAboveTableSuccess(null);

      // store the updated output value so we can filter by it in the success message
      setUpdatedOutputValue(data?.outputValue);

      setFragment({
        variables: {
          projectId: data?.projectId,
          publisherId: data?.publisherId,
          contentValue: data?.contentValue,
          outputValue: data?.outputValue
        }
      });
    },
    [setFragment]
  );

  const onCancelConfirm = useCallback(() => {
    setConfirmModal(false);
    setSelectedFragment(null);
    setModalError(null);
  }, []);

  const onToggleManuallyManaged = useCallback(row => {
    setSelectedFragment(row);
    setConfirmModal(true);
  }, []);

  const [resetFragment, { loading: resetFragmentLoading }] = useMutation(
    resetManuallyManagedFragment,
    {
      onCompleted: () => {
        // clear the error
        setModalError(null);
        // close the modal
        setConfirmModal(false);
        // clear the selected fragment
        setSelectedFragment(null);
        // refetch the fragment table data
        orderFragmentsRefetch();

        // set success message with button to refetch the table data
        setAboveTableSuccess(text.successSetFragment);
      },
      onError: () => {
        // clear the success message
        setAboveTableSuccess(null);
        // clear the updated value b/c it failed
        setUpdatedOutputValue(null);
        // set error
        setModalError(text.errorSetFragment);
      }
    }
  );

  const onResetManuallyManaged = useCallback(
    fragmentId => {
      resetFragment({
        variables: {
          fragmentId
        }
      });
    },
    [resetFragment]
  );

  const updatedFindOrderExtraInputs = useMemo(() => {
    const units = breakdownData?.getOrderFragmentsBreakdown?.units;
    const content = breakdownData?.getOrderFragmentsBreakdown?.content;

    const disabledInputs = new Set([]);

    // disable inputs if no data
    if (noBreakdownData || isEmpty(units)) {
      disabledInputs.add('channelCode');
      disabledInputs.add('unitCode');
    }
    // disable inputs if no data
    if (noBreakdownData || isEmpty(content)) {
      disabledInputs.add('contentValue');
    }
    // disable inputs if no data
    if (noBreakdownData) {
      disabledInputs.add('outputValue');
    }

    return configureInputs({
      inputs: findOrderExtraInputs,
      disabledInputs,
      enumInputs: {
        channelCode: 'channelCode',
        unitCode: 'unitCode',
        contentValue: 'contentValue'
      },
      enumerationValues: [
        {
          enumeration: 'channelCode',
          values: [
            ...uniqBy(units, 'channelCode').reduce((accum, { channelCode }) => {
              // we only want to show supported channels
              if (supportedChannelCodes.includes(channelCode)) {
                accum.push({
                  name: channelCode,
                  value: channelCode
                });
              }

              return accum;
            }, [])
          ]
        },
        {
          enumeration: 'unitCode',
          values: [
            ...uniqBy(units, 'unitCode').reduce((accum, { unitCode }) => {
              // we only want to show supported unit codes
              if (supportedUnitCodes.includes(unitCode)) {
                accum.push({
                  name: unitCode,
                  value: unitCode
                });
              }

              return accum;
            }, [])
          ]
        },
        {
          enumeration: 'contentValue',
          values: [
            ...uniqBy(content, 'contentValue').map(({ contentValue }) => ({
              name: contentValue,
              value: contentValue
            }))
          ]
        }
      ]
    });
  }, [breakdownData, noBreakdownData]);

  const columnSchema = [
    ...columnSchemaBase,
    {
      columnName: 'Manually Managed',
      type: 'STRING',
      accessor: 'isManuallyManaged',
      CellComponent: ({ row }) => {
        return (
          <Switch
            checked={row?.isManuallyManaged}
            onChange={() => onToggleManuallyManaged(row)}
          />
        );
      }
    }
  ];

  const tableData = orderFragmentData?.findOrderFragments?.results?.edges.map(
    ({ node }) => node
  );

  return (
    <div>
      <Heading
        title={text.title}
        subTitle={text.subTitle}
        pageTitle={text.title}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaperContainer>
            <form autoComplete="off">
              <Typography variant="body1">{text.searchTitle}</Typography>
              <Divider />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <Field
                    component={RenderTextField}
                    label={text.findOrderInputLabel}
                    name="orderOrProgramId"
                    validate={[validateRequired]}
                    onKeyPress={({ key }) => {
                      if (key === 'Enter') {
                        handleSubmit(onGetOrderFragment)();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    component={RenderSelect}
                    label={text.resultsPerPageLabel}
                    name="resultsPerPage"
                    sx={{
                      minWidth: 120
                    }}
                    onChange={(_, newValue) => {
                      change(SEARCH_FORM_NAME, 'resultsPerPage', newValue);
                      handleSubmit(onGetOrderFragment)();
                    }}
                  >
                    {[10, 20, 50, 100]?.map(result => (
                      <MenuItem value={result} key={result}>
                        {result}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>

              <Typography variant="body1">{text.filterTitle}</Typography>
              <Divider />

              <DynamicForm inputs={updatedFindOrderExtraInputs} />

              <Box sx={{ display: 'flex', mt: 2, mb: 2 }}>
                <LoadingButton
                  loading={orderFragmentsLoading || breakdownDataLoading}
                  disabled={
                    !formValues?.orderOrProgramId ||
                    orderFragmentsLoading ||
                    breakdownDataLoading
                  }
                  loadingPosition="start"
                  startIcon={<SearchIcon />}
                  variant="contained"
                  color="primary"
                  data-cy="admin-manually-linked-search-button"
                  onClick={handleSubmit(onGetOrderFragment)}
                >
                  {text.searchButton}
                </LoadingButton>
              </Box>
            </form>
          </PaperContainer>
        </Grid>
        <Grid item xs={12}>
          <PaperContainer>
            {aboveTableError && (
              <AlertAligned severity="error">{aboveTableError}</AlertAligned>
            )}
            {aboveTableSuccess && (
              <AlertAligned severity="success">
                {aboveTableSuccess}
              </AlertAligned>
            )}

            {isEmpty(tableData) ? (
              <TableEmptyState
                emptyMessage={text.emptyTable}
                loading={orderFragmentsLoading}
              />
            ) : (
              <>
                <Table
                  columnSchema={columnSchema}
                  loading={orderFragmentsLoading}
                  rows={tableData}
                />
                <PaginationControls
                  pageInfo={
                    orderFragmentData?.findOrderFragments?.results?.pageInfo
                  }
                  navigateNext={navigateNext}
                  navigatePrev={navigatePrev}
                />
              </>
            )}
          </PaperContainer>
        </Grid>
      </Grid>

      <ConfirmModal
        onCancelConfirm={onCancelConfirm}
        onConfirm={onConfirm}
        onReset={onResetManuallyManaged}
        open={confirmModalOpen}
        selectedFragment={selectedFragment}
        columnSchema={columnSchema}
        loading={setFragmentLoading || resetFragmentLoading}
        modalError={modalError}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const formValues = getFormValues(SEARCH_FORM_NAME)(state);

  return {
    formValues,
    initialValues: {
      resultsPerPage: DEFAULT_RESULTS_PER_PAGE,
      channelCode: initialChannelCode,
      unitCode: initialUnitCode
    }
  };
};

export default flow(
  reduxForm({
    form: SEARCH_FORM_NAME,
    destroyOnUnmount: true
  }),
  connect(mapStateToProps)
)(ManuallyLinkOrders);
