import { cloneDeep } from 'lodash';
import { t } from 'i18next';

import { handleActions } from 'redux-actions';

import Instrumentation from 'src/instrumentation';

import AutomatedProgramGetStarted from 'src/components/AutomatedProgramFilter/AutomatedProgramGetStarted';
import AutomatedProgramFilter from 'src/components/AutomatedProgramFilter/AutomatedProgramFilter';
import AutomatedProgramChooseBlueprint from 'src/components/AutomatedProgramFilter/AutomatedProgramChooseBlueprint';
import AutomatedProgramsGetStartedIcon from 'src/components/AutomatedProgramFilter/AutomatedProgramsGetStartedIcon';
import AutomatedProgramsRefineIcon from 'src/components/AutomatedProgramFilter/AutomatedProgramsRefineIcon';
import AutomatedProgramsSelectBlueprintIcon from 'src/components/AutomatedProgramFilter/AutomatedProgramsSelectBlueprintIcon';

import { actionConstants } from './actions';
import { programActions } from './Constants';

// Needs to be a function, otherwise returns undefined inside of reducer initial state functions.
const pageText = () => ({
  programStepConfigure: t('program:step.configure'),
  programStepSpend: t('program:step.spend'),
  programStepSummary: t('program:step.summary'),
  automationStepGetStarted: t('program:step.getStarted'),
  automationStepBlueprint: t('program:step.chooseBlueprint'),
  automationStepRefine: t('program:step.refineContent'),
  programCondensedStepConfigure: t('program:condensedStep.configure'),
  programCondensedStepSpend: t('program:condensedStep.spend'),
  automationCondensedStepConfigure: t('automations:condensedStep.configure'),
  automationCondensedStepSpend: t('automations:condensedStep.spend')
});

const getAutomatedProgramSteps = pageType => {
  const text = pageText();

  return {
    0: {
      completed: false,
      content: AutomatedProgramGetStarted,
      error: false,
      label: text.automationStepGetStarted,
      icon: AutomatedProgramsGetStartedIcon,
      amplitudeKey: 'get-started'
    },
    ...(pageType !== programActions.automatedEdit && {
      1: {
        completed: false,
        content: AutomatedProgramChooseBlueprint,
        error: false,
        label: text.automationStepBlueprint,
        icon: AutomatedProgramsSelectBlueprintIcon,
        amplitudeKey: 'choose-blueprint'
      }
    }),
    [pageType !== programActions.automatedEdit ? 2 : 1]: {
      completed: false,
      content: AutomatedProgramFilter,
      error: false,
      label: text.automationStepRefine,
      icon: AutomatedProgramsRefineIcon,
      amplitudeKey: 'filter-content'
    }
  };
};

// This was converted to a function so that I can call pageText and get translation strings. When this is an object, the strings return as undefined.
const initialState = () => {
  return {
    previewDrawerOpen: true,
    automatedWizardSelectedStep: 0,
    automatedWizardSteps: {},
    // we care between not set and false
    // for when switching back and forth steps.
    automatedWizardOpen: undefined
  };
};

export default handleActions(
  {
    [actionConstants.togglePreviewDrawer]: state => {
      const { previewDrawerOpen } = state;

      Instrumentation.logEvent(
        Instrumentation.Events.ProgramPreviewVisibilityClicked,
        {
          showPreview: !previewDrawerOpen
        }
      );

      return {
        ...state,
        previewDrawerOpen: !previewDrawerOpen
      };
    },

    [actionConstants.reset]: ({ continueCreateProgram }) => {
      return {
        ...initialState(),
        continueCreateProgram
      };
    },
    [actionConstants.setContinueCreateProgram]: (state, { payload }) => {
      return {
        ...state,
        continueCreateProgram: payload
      };
    },

    [actionConstants.automatedWizardSelectStep.exact]: (state, { payload }) => {
      // Note automatedWizardSelectedStep needs to be a number for the stepper component and the
      //      payload is returned as a string
      const automatedWizardSelectedStep = Number(payload);
      return {
        ...state,
        automatedWizardSelectedStep
      };
    },

    [actionConstants.automatedWizardSelectStep.next]: state => {
      const { automatedWizardSelectedStep } = state;
      const newSelectedStep = automatedWizardSelectedStep + 1;

      return {
        ...state,
        automatedWizardSelectedStep: newSelectedStep
      };
    },

    [actionConstants.automatedWizardSelectStep.previous]: state => {
      const { automatedWizardSelectedStep } = state;
      const newSelectedStep = automatedWizardSelectedStep - 1;

      return {
        ...state,
        automatedWizardSelectedStep: newSelectedStep
      };
    },

    [actionConstants.automatedWizardSelectStep.completeStep]: (
      state,
      { payload }
    ) => {
      const { automatedWizardSteps } = state;

      return {
        ...state,
        automatedWizardSteps: {
          ...automatedWizardSteps,
          [payload]: {
            ...automatedWizardSteps[payload],
            completed: true
          }
        }
      };
    },

    [actionConstants.automatedWizardSelectStep.completeAllSteps]: state => {
      const { automatedWizardSteps } = state;
      const newCompletedSteps = Object.keys(automatedWizardSteps).reduce(
        (accum, step) => {
          const newAccum = cloneDeep(accum);
          newAccum[step].completed = true;
          return newAccum;
        },
        automatedWizardSteps
      );

      return {
        ...state,
        automatedWizardSteps: newCompletedSteps
      };
    },

    [actionConstants.automatedWizardSelectStep.setSteps]: (
      state,
      { payload }
    ) => {
      const automatedWizardSteps = getAutomatedProgramSteps(payload);

      return {
        ...state,
        automatedWizardSteps
      };
    },
    [actionConstants.setAutomatedProgramsModal]: (state, { payload }) => {
      return {
        ...state,
        automatedWizardOpen: payload
      };
    }
  },
  initialState()
);
