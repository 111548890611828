import { t } from 'i18next';

import { Box, Typography } from '@mui/material';

import { SupervisorOrder } from 'src/generated/gql/graphql';

import { getRulesAsChips } from '../helpers';
import ProgramsTable from '../ProgramsTable';

const pageText = () => {
  return {
    tableHeading: t('automations:detailPanel.tableHeading'),
    rulesHeading: t('automations:detailPanel.rulesHeading')
  };
};

const AutomationDetailPanel = ({
  currentRow
}: {
  currentRow: SupervisorOrder;
}) => {
  const text = pageText();

  const {
    catalogFilter,
    catalog: { fieldMetadata, friendlyName }
  } = currentRow;

  const supervisorOrderId = currentRow?.id;

  return (
    <Box key={supervisorOrderId} sx={{ padding: theme => theme.spacing(2) }}>
      <Box>
        <Typography
          variant="h1"
          sx={{
            fontSize: '16px',
            fontWeight: '600',
            margin: theme => `${theme.spacing(2)} 0`
          }}
        >
          {text.rulesHeading}
        </Typography>
        {getRulesAsChips(catalogFilter, fieldMetadata, false, friendlyName)}
      </Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: '16px',
          fontWeight: '600',
          margin: theme => `${theme.spacing(2)} 0`
        }}
      >
        {text.tableHeading}
      </Typography>
      <Box sx={{ height: '300px' }}>
        <ProgramsTable
          supervisorOrderIds={[supervisorOrderId]}
          showDrafts={false}
        />
      </Box>
    </Box>
  );
};

export default AutomationDetailPanel;
