import { gql } from 'src/generated/gql';

export interface InsightData {
  kpi: {
    slug: string;
    channelCode: string;
    friendlyName: string;
  };
  summary: { sum: number };
  daily: Array<{
    date: any;
    value: number;
  }>;
}

export const insightsQuery = gql(`
  query insights($programIds: [ID!]!, $kpis: [KpiInput!]!) {
    insights(programIds: $programIds, kpis: $kpis) {
      kpi {
        slug
        channelCode
        friendlyName
      }
      summary {
        sum
      }
      daily {
        date
        value
      }
    }
  }
`);
