import { t } from 'i18next';

import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

import { validateRequired } from 'src/common/validations';

export const CREATE_FORM_NAME = 'createArchitecture';
export const UPDATE_FORM_NAME = 'updateArchitecture';

export const createArchitectureInputs = () => [
  {
    name: 'name',
    displayName: t(
      'admin:architectureSettings.createArchitectureInputs.name.displayName',
      'Architecture Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 1,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'isContentSelectable',
    displayName: t(
      'admin:architectureSettings.createArchitectureInputs.isContentSelectable.displayName',
      'Is Content Selectable'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 2,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  },
  {
    name: 'isActive',
    displayName: t(
      'admin:architectureSettings.createArchitectureInputs.isActive.displayName',
      'Is Active'
    ),
    displayMethodId: INPUT_TYPES.BOOLEAN,
    initialValue: false,
    reduxValidations: [validateRequired],
    isRequired: true,
    isHidden: false,
    displaySortOrder: 3,
    displayParameters: {
      inputData: {
        columnWidth: 2
      }
    }
  }
];

export const updateArchitectureInputs = createArchitectureInputs;
