import { useState } from 'react';
import { isEmpty, noop, isFunction } from 'lodash';
import { Trans } from 'react-i18next';

import {
  ImageList,
  ImageListItem,
  Backdrop,
  Box,
  useTheme,
  styled
} from '@mui/material';

import { useGlobalContext } from 'src/GlobalContextProvider';
import { hasPermissions, PERMISSIONS } from 'src/common/permissions';
import { teamRoles } from 'src/common/teams';
import PaginationControls from 'src/components/Pagination/PaginationControls';
import Loading from 'src/components/Loading';

import TileWrapper from './Tiles/TileWrapper';
import { useGalleryColumnCount, getCompletedCount } from './utilities';
import UploadingProgress from './UploadingProgress';
import {
  MEDIA_SOURCES,
  getGrantTypesForMe,
  GALLERY_ROW_HEIGHT,
  IMAGE_GALLERY_HEIGHT,
  GALLERY_TYPE
} from './constants';

import GalleryContentItem from './GalleryContentItem';

const DropZoneContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2),
  fontSize: '25px',
  fontWeight: 'bold',
  color: theme.palette.grey[500],
  cursor: 'pointer',
  borderRadius: '10px',
  border: `1px dashed ${theme.palette.grey[400]}`,
  boxShadow: `0px 0px 0px 1px ${theme.palette.grey[300]}`
}));

const GalleryContent = ({
  allowSelect = false,
  assets,
  noAssets,
  selectedGalleryItem = {},
  setSelectedGalleryItem,
  uploadStatuses = {},
  galleryRefetch,
  pageInfo = {},
  navigateNext,
  navigatePrev,
  dropZone,
  galleryLoading,
  galleryType,
  setAsset = () => {}
}) => {
  const [hoveredId, setHoveredId] = useState(null);
  const [lightboxedItem, setLightboxedItem] = useState(null);
  const globalContext = useGlobalContext();
  const {
    me,
    office: { isTeamsEnabled },
    us
  } = globalContext;
  const teamRole = us?.role;
  const theme = useTheme();
  const columnCount = useGalleryColumnCount(theme);

  const grantTypesByScope = getGrantTypesForMe(me);

  const { getRootProps, getInputProps, isDragActive } = dropZone;

  const completedFileCount = getCompletedCount(uploadStatuses);
  const totalFileCount = Object.keys(uploadStatuses).length;
  const allUploadsComplete = completedFileCount === totalFileCount;

  return (
    <>
      <Box
        {...getRootProps({
          sx: {
            position: 'relative',
            minHeight: `${IMAGE_GALLERY_HEIGHT}px`
          }
        })}
      >
        <input {...getInputProps({ 'data-cy': 'drag-and-drop' })} />
        <Backdrop
          open={!!lightboxedItem || galleryLoading || isDragActive}
          onClick={() => setLightboxedItem(null)}
          sx={{
            backgroundColor: isDragActive
              ? 'rgba(255, 255, 255, 1)'
              : 'rgba(255, 255, 255, 0.7)',
            zIndex: theme => theme.zIndex.modal,
            position: 'absolute'
          }}
        >
          {lightboxedItem && (
            <TileWrapper
              asset={lightboxedItem}
              type={lightboxedItem?.type}
              isLightbox
            />
          )}
          {galleryLoading && <Loading />}
          {isDragActive && (
            <DropZoneContainer>
              <Trans i18nKey="gallery:content.dragHere">Drop Files here</Trans>
            </DropZoneContainer>
          )}
        </Backdrop>

        {noAssets && (
          <DropZoneContainer>
            <Trans i18nKey="gallery:messages.uploadSomeContent">
              Add assets to get started
            </Trans>
          </DropZoneContainer>
        )}

        <ImageList
          sx={{
            mt: theme => `${theme.spacing(2)} !important`,
            p: 2
          }}
          cols={columnCount}
          rowHeight={GALLERY_ROW_HEIGHT}
        >
          {!allUploadsComplete && !isEmpty(uploadStatuses) && (
            <ImageListItem key="uploading-tile" cols={1}>
              <UploadingProgress
                completedFileCount={completedFileCount}
                totalFileCount={totalFileCount}
              />
            </ImageListItem>
          )}
          {assets.map(asset => {
            const { link, id, source, accessControl } = asset;

            const hasTeamsDeletePermissions =
              !isTeamsEnabled ||
              hasPermissions({
                permissions: [PERMISSIONS.delete],
                contentPermissions: accessControl?.permissions
              });

            const isSelected =
              galleryType === GALLERY_TYPE.media
                ? selectedGalleryItem?.id === id
                : selectedGalleryItem === link; // legacy
            const canDelete =
              !allowSelect &&
              // if uploaded from dev-tools or other "partner" source don't allow delete
              source !== MEDIA_SOURCES.Partner &&
              // this is a user media or they have corporate in their scope
              (source === MEDIA_SOURCES.User ||
                grantTypesByScope[MEDIA_SOURCES.Corporate]) &&
              // Either does not have teams enabled role or has teams delete permissions
              hasTeamsDeletePermissions;

            const canShare =
              !allowSelect &&
              isTeamsEnabled &&
              hasPermissions({
                permissions: [PERMISSIONS.share],
                contentPermissions: accessControl?.permissions
              }) &&
              (teamRoles.admin === teamRole || teamRoles.lead === teamRole);

            const tileClick = () => {
              if (isFunction(setSelectedGalleryItem)) {
                setAsset(asset);
                return setSelectedGalleryItem(asset);
              }
              return noop;
            };

            return (
              <GalleryContentItem
                key={id}
                allowSelect={allowSelect}
                isSelected={isSelected}
                setHoveredId={setHoveredId}
                hoveredId={hoveredId}
                asset={asset}
                galleryType={galleryType}
                tileClick={tileClick}
                canDelete={canDelete}
                canShare={canShare}
                setLightboxedItem={setLightboxedItem}
                galleryRefetch={galleryRefetch}
              />
            );
          })}
        </ImageList>
        {!noAssets && (
          <PaginationControls
            pageInfo={pageInfo}
            navigateNext={navigateNext}
            navigatePrev={navigatePrev}
            isBottom={false}
            variant="icon"
          />
        )}
      </Box>
    </>
  );
};

export default GalleryContent;
