import numeral from 'numeral';
import { t } from 'i18next';

export const formatRecurrence = (key: string) => {
  const map: { [key: string]: string } = {
    month: t('programPerf:details.perMonth')
  };

  return map?.[key] || key;
};

interface BillingDetailsInterface {
  amount: string;
  endDate: string;
  interval: string;
  priceBeforeDiscount: string;
  renewsOn: string;
  startDate: string;
  mostRecentBudgetAdjustment: {
    updatedBudgetAmount: string;
  };
}

export const getSpendAmount = (billingDetails: BillingDetailsInterface) => {
  const { mostRecentBudgetAdjustment, amount, interval } = billingDetails;

  return (
    <>
      {numeral(
        mostRecentBudgetAdjustment?.updatedBudgetAmount || amount
      ).format('$0,0')}
      {interval && <span>{formatRecurrence(interval)}</span>}
    </>
  );
};
