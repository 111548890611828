import { useCallback, useState } from 'react';
import { isEmpty, range } from 'lodash';

export interface AccordionStateProps {
  maxNumItems?: number;
}

/**
 * Custom hook to manage the state of an accordion component.
 * This is ONLY necessary if you need to manually open and close all accordion
 * panels. Otherwise, MUI can do the rest for you.
 */
export const useAccordionState = ({ maxNumItems }: AccordionStateProps) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleAccordionToggle = useCallback(
    (currentIndex: number) => {
      const newOpenIndexes = new Set([...openIndexes]);
      if (newOpenIndexes.has(currentIndex)) {
        newOpenIndexes.delete(currentIndex);
      } else {
        newOpenIndexes.add(currentIndex);
      }

      setOpenIndexes([...newOpenIndexes]);
    },
    [openIndexes]
  );

  const handleAccordionToggleAll = useCallback(() => {
    if (isEmpty(openIndexes)) {
      setOpenIndexes(range(maxNumItems ?? 0));
    } else {
      setOpenIndexes([]);
    }
  }, [maxNumItems, openIndexes]);

  const handleAccordionCloseAll = useCallback(() => {
    setOpenIndexes([]);
  }, []);

  return {
    openIndexes,
    handleAccordionToggle,
    handleAccordionToggleAll,
    handleAccordionCloseAll
  };
};
