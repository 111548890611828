import { FieldValues, UseFormReturn } from 'react-hook-form';
import { AiChatMessageType, aiChatMessageTypes } from '../constants';
import { InputField } from '../helpers';
import AdCopySuggestionMessage from './AdCopySuggestionMessage';
import ButtonInputMessage, { ButtonConfig } from './ButtonInputMessage';
import TextMessage from './TextMessage';

interface AiChatMessageProps {
  type: AiChatMessageType;
  text?: string | JSX.Element;
  buttons?: ButtonConfig[];
  input?: InputField;
  sharedInputProps: Record<string, any>;
  setIsThinking: (isThinking: boolean) => void;
  parentFormMethods: UseFormReturn<FieldValues, any, undefined>;
  highlightInputs?: (blueprintVariableIds?: string[]) => void;
  inputsCurrentSuggestion: Record<string, any>;
  setInputsCurrentSuggestion: (
    inputsCurrentSuggestion: Record<string, any>
  ) => void;
}

const AiChatMessage = ({
  type,
  text,
  buttons,
  input,
  sharedInputProps,
  setIsThinking,
  parentFormMethods,
  highlightInputs,
  inputsCurrentSuggestion,
  setInputsCurrentSuggestion
}: AiChatMessageProps) => {
  if (aiChatMessageTypes.text === type && text) {
    return <TextMessage text={text} />;
  }

  if (aiChatMessageTypes.buttonInput === type && buttons?.length) {
    return <ButtonInputMessage buttons={buttons} />;
  }

  if (
    aiChatMessageTypes.adCopySuggestion === type &&
    input &&
    sharedInputProps &&
    setIsThinking &&
    highlightInputs &&
    inputsCurrentSuggestion &&
    setInputsCurrentSuggestion
  ) {
    const currentSuggestionIndex =
      inputsCurrentSuggestion[input.blueprintVariableId] || 0;
    const setCurrentSuggestionIndex = (index: number) => {
      setInputsCurrentSuggestion((prev: Record<string, any>) => ({
        ...prev,
        [input.blueprintVariableId]: index
      }));
    };

    return (
      <AdCopySuggestionMessage
        input={input}
        sharedInputProps={sharedInputProps}
        setIsThinking={setIsThinking}
        parentFormMethods={parentFormMethods}
        highlightInputs={highlightInputs}
        setCurrentSuggestionIndex={setCurrentSuggestionIndex}
        currentSuggestionIndex={currentSuggestionIndex}
      />
    );
  }

  return null;
};

export default AiChatMessage;
