import { Trans } from 'react-i18next';

import { Box, Paper, Typography } from '@mui/material';

import { useFeatures } from 'src/components/Feature';
import PageTitle from 'src/components/PageTitle';

import QuickStartList from './QuickStartList';
import DashboardKPIs from './DashboardKPIs';
import ContentAwareDashboard from './ContentAwareDashboard';

const Dashboard = () => {
  const { dashboardKpis } = useFeatures();

  return (
    <Box data-cy="dashboard">
      <PageTitle subPageTitle="Dashboard" />
      {dashboardKpis && <DashboardKPIs />}
      <Paper
        sx={{
          color: 'text.secondary',
          padding: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2.5
        }}
      >
        <ContentAwareDashboard />

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: '500', color: 'text.primary' }}
        >
          <Trans i18nKey="dashboard:headers.quickStart">Quick Start</Trans>
        </Typography>

        <QuickStartList displayFilters />
      </Paper>
    </Box>
  );
};

export default Dashboard;
