import { t } from 'i18next';
import { INPUT_TYPES } from 'src/components/ReduxForm/DynamicForm/constants';

export const CLONE_BLUEPRINT_FORM = 'cloneBlueprintForm';

export const getCloneFromInputs = () => [
  {
    name: 'sourceProductId',
    displayName: t(
      'admin:blueprintBuilder.cloneBlueprintModal.productId',
      'Product ID'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];

export const getCloneToInputs = () => [
  {
    name: 'destinationArchitectureId',
    displayName: t(
      'admin:blueprintBuilder.cloneBlueprintModal.destinationArchitecture',
      'Destination Architecture'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_SELECT,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  },
  {
    name: 'newProductName',
    displayName: t(
      'admin:blueprintBuilder.cloneBlueprintModal.newName',
      'New Name'
    ),
    displayMethodId: INPUT_TYPES.SINGLE_LINE_STRING,
    initialValue: '',
    reduxValidations: [],
    isRequired: true,
    isHidden: false,
    displayParameters: {
      inputData: {
        columnWidth: 4
      }
    }
  }
];
