import { t } from 'i18next';
import { useMemo } from 'react';

import { Box } from '@mui/material';

import Heading from 'src/components/PageElements/Heading';

import ResourceCollectionsManager from '../ResourceLibrary/ResourceCollectionsManager';

const pageText = () => ({
  title: t('adminGoogleSearch:title'),
  description: t('adminGoogleSearch:description')
});

const GoogleSearchSettings = () => {
  const text = useMemo(() => pageText(), []);

  return (
    <>
      <Heading
        title={text.title}
        subTitle={text.description}
        pageTitle={text.title}
        divider
      />

      <Box
        sx={{
          marginTop: theme => theme.spacing(3)
        }}
      >
        <ResourceCollectionsManager
          isEditItemValuesOnly
          resourceItemTypeSlug="google-search-setting"
        />
      </Box>
    </>
  );
};

export default GoogleSearchSettings;
