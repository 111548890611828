import { gql } from 'src/generated/gql';

export const getAiTextSuggestions = gql(`
    query getAiTextSuggestionsForAiChat($input: AiTextSuggestionsInput!) {
        aiTextSuggestions(input: $input) {
            fields {
                blueprintVariableId
                suggestions
            }
        }
    }
`);

export const getAiTextStylizations = gql(`
    query getAiTextStylizationsForAiChat($input: AiTextStylizationsInput!) {
        aiTextStylizations(input: $input) {
            fields {
                blueprintVariableId
                stylizations
            }
        }
    }
`);
