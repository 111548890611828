import { createAction } from 'redux-actions';

import { FACEBOOK_LINK_RETURN_URL } from 'src/pages/FacebookLink/Constants';

export const actionConstants = {
  togglePreviewDrawer: createAction('PROGRAM_TOGGLE_PREVIEW_DRAWER'),
  reset: createAction('PROGRAM_RESET'),
  setContinueCreateProgram: createAction('SET_CONTINUE_CREATE_PROGRAM'),
  automatedWizardSelectStep: {
    next: createAction('AUTOMATED_WIZARD_SELECT_NEXT_STEP'),
    previous: createAction('AUTOMATED_WIZARD_SELECT_PREVIOUS_STEP'),
    exact: createAction('AUTOMATED_WIZARD_SELECT_EXACT_STEP'),
    completeStep: createAction('AUTOMATED_WIZARD_COMPLETE_STEP'),
    completeAllSteps: createAction('AUTOMATED_WIZARD_COMPLETE_ALL_STEPS'),
    setSteps: createAction('AUTOMATED_WIZARD_SET_STEPS')
  },
  setAutomatedProgramsModal: createAction('SET_AUTOMATED_PROGRAMS_MODAL')
};

export const togglePreviewDrawer = () => dispatch => {
  dispatch(actionConstants.togglePreviewDrawer());
};

export const resetProgram = () => dispatch => {
  dispatch(actionConstants.reset());
};

export const setContinueCreateProgram = url => dispatch => {
  localStorage.setItem(FACEBOOK_LINK_RETURN_URL, url);
  dispatch(actionConstants.setContinueCreateProgram(url));
};

export const automatedWizardSelectExactStep = step => dispatch => {
  dispatch(actionConstants.automatedWizardSelectStep.exact(step));
};

export const automatedWizardSelectNextStep = () => dispatch => {
  dispatch(actionConstants.automatedWizardSelectStep.next());
};

export const automatedWizardSelectPreviousStep = () => dispatch => {
  dispatch(actionConstants.automatedWizardSelectStep.previous());
};

export const automatedWizardCompleteStep = step => dispatch => {
  dispatch(actionConstants.automatedWizardSelectStep.completeStep(step));
};

export const automatedWizardCompleteAllSteps = () => dispatch => {
  dispatch(actionConstants.automatedWizardSelectStep.completeAllSteps());
};

export const automatedWizardSetSteps = pageType => dispatch => {
  dispatch(actionConstants.automatedWizardSelectStep.setSteps(pageType));
};

export const setAutomatedProgramsModal = state => dispatch => {
  dispatch(actionConstants.setAutomatedProgramsModal(state));
};
