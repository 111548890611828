import { t } from 'i18next';
import { noop } from 'lodash';

import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';

type OnClick = (arg: any) => void;
export interface AssetMenuOption {
  label: string;
  icon?: React.ReactElement | Element;
  onClick?: OnClick;
  menuItemProps?: any;
  disabled?: boolean;
}

interface AssetMenuProps {
  id: string;
  handleClose: () => void;
  open: boolean;
  ariaLabeledBy: string;
  anchorEl: HTMLElement | null;
  options: AssetMenuOption[];
  isYourAsset?: boolean;
  disabled?: boolean;
  onClickArgValue?: any;
}

const pageText = () => {
  return {
    yourAsset: t('admin:billingSettings.yourAsset')
  };
};

const AssetMenu = ({
  id,
  handleClose,
  open,
  ariaLabeledBy,
  anchorEl,
  options,
  isYourAsset,
  disabled: disableAll,
  onClickArgValue
}: AssetMenuProps) => {
  const text = pageText();

  const handleOptionClick = (onClick: OnClick) => () => {
    onClick(onClickArgValue);
    handleClose();
  };

  return (
    <Menu
      sx={{ zIndex: theme => theme.zIndex.tooltip + 1 }}
      id={id}
      aria-disabled={disableAll}
      MenuListProps={{
        'aria-labelledby': ariaLabeledBy
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5
        }
      }}
    >
      {options.map(option => (
        <MenuItem
          disabled={disableAll || option?.disabled || false}
          key={option.label}
          onClick={option?.onClick ? handleOptionClick(option.onClick) : noop}
          {...option?.menuItemProps}
        >
          {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
          {option.label}
        </MenuItem>
      ))}
      {isYourAsset && [
        <Divider key="divider" />,
        <MenuItem key="your asset" disabled>
          <ListItemIcon>
            <CheckCircleIcon sx={{ fontSize: 20 }} />
          </ListItemIcon>
          {text.yourAsset}
        </MenuItem>
      ]}
    </Menu>
  );
};

export default AssetMenu;
