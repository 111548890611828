import { List } from '@mui/material';

import NavigationListDashboard from './NavigationListDashboard';

const NavigationList = () => {
  return (
    <List
      sx={theme => ({
        py: 0,
        width: `${theme.evSizes.navigationWidth}px`
      })}
      component="nav"
    >
      <NavigationListDashboard />
    </List>
  );
};

export default NavigationList;
