import React from 'react';
import { LoadingButton } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import useProgram from 'src/pages/Program/utils/useProgram';
import Instrumentation from 'src/instrumentation';

type EditDefaultsButtonProps = {
  children: string;
};

const EditDefaultsButton = ({ children }: EditDefaultsButtonProps) => {
  const {
    programStepper: { stepRefs, currentStep, selectExactStep },
    trackingData
  } = useProgram();
  return (
    <LoadingButton
      onClick={() => {
        Instrumentation.logEvent(
          Instrumentation.Events.ClickMlpEditDefaults,
          trackingData
        );

        if (stepRefs?.[currentStep - 1]?.current) {
          // Scroll to top of last section
          stepRefs[currentStep - 1]?.current?.scrollIntoView();
        }
        // select the previous step
        selectExactStep(currentStep - 1);
      }}
      data-cy="location-management-edit-defaults-button"
      id="step-footer-back-button"
      startIcon={<EditIcon />}
    >
      {children}
    </LoadingButton>
  );
};

export default EditDefaultsButton;
