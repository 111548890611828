import { useMemo } from 'react';
import { flow } from 'lodash';
import { FormSection, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { t } from 'i18next';

import { Typography, Divider } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { DynamicForm } from 'src/components/ReduxForm';

import {
  BLUEPRINT_BUILDER_FORM_NAME,
  getMessagingInputs,
  getContentAwareDashboardInputs,
  getSellingPointsInputs
} from '../Constants';
import BlueprintBuilderStepFooter from './BlueprintBuilderStepFooter';

const styles = theme => ({
  subHeading: {
    marginBottom: theme.spacing(2)
  },
  sectionHeading: {
    margin: `${theme.spacing(2)} 0 ${theme.spacing(1)} 0`
  }
});

const pageText = () => ({
  stepHeading: t('admin:blueprintBuilder.stepFinalizeStepHeading'),
  quickstartSection: t(
    'admin:blueprintBuilder.stepFinalizeStepQuickstartSection'
  ),
  contentAwareDashboardSection: t(
    'admin:blueprintBuilder.stepFinalizeStepContentAwareDashboard'
  ),
  messagingSection: t(
    'admin:blueprintBuilder.stepFinalizeStepMessagingSection'
  ),
  sellingPoints: t('admin:blueprintBuilder.stepFinalizeStepSellingPoints')
});

const StepFinalize = props => {
  const { classes, formValues } = props;
  const contentAwareDashboardEnabled =
    !!formValues?.document?.contentDrivenPreviewConfig?.enabled;

  const text = useMemo(() => pageText(), []);

  return (
    <div>
      <Typography variant="h6">{text.stepHeading}</Typography>

      <Typography className={classes.sectionHeading} variant="h6">
        {text.contentAwareDashboardSection}
      </Typography>
      <Divider />
      <FormSection name="document.contentDrivenPreviewConfig">
        <DynamicForm
          inputs={getContentAwareDashboardInputs(contentAwareDashboardEnabled)}
        />
      </FormSection>

      <Typography className={classes.sectionHeading} variant="h6">
        {text.messagingSection}
      </Typography>
      <Divider />
      <FormSection name="document.messaging">
        <DynamicForm inputs={getMessagingInputs()} />
        <DynamicForm inputs={getSellingPointsInputs()} />
      </FormSection>

      <BlueprintBuilderStepFooter />
    </div>
  );
};

export default flow(
  withStyles(styles),
  connect(state => {
    return { formValues: getFormValues(BLUEPRINT_BUILDER_FORM_NAME)(state) };
  })
)(StepFinalize);
