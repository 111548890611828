import { gql } from 'src/generated/gql';

export interface UpdateQuickstartInBulkValues {
  isActive: string;
  priority: string;
  isCustom: boolean;
  name: string;
  description: string;
}

export interface UpdateQuickstartInBulkInput {
  Input: {
    id: string;
    values: UpdateQuickstartInBulkValues;
  };
}

export interface FormSaveValues extends UpdateQuickstartInBulkValues {
  id: string;
  automationQuickStarts: any;
  localizedImages: {
    locale: string;
    customImageUrl: string;
  }[];
}

export const updateQuickstartInBulk = gql(`
  mutation updateQuickstartInBulk($Input: QuickStartBulkInput) {
    updateQuickstartInBulk(input: $Input)
  }
`);

export const deleteAutomationQuickstart = gql(`
  mutation deleteAutomationQuickstart($quickstartAutomationIds: [ID!]!) {
    deleteAutomationQuickstart(quickstartAutomationIds: $quickstartAutomationIds)
  }
`);

export const createAutomationQuickstart = gql(`
  mutation createAutomationQuickstart($automationQuickstarts: [AutomationQuickStartCreateInput!]!) {
    createAutomationQuickstart(automationQuickstarts: $automationQuickstarts) {
      id
      productQuickStartId
      name
      icon
      priority
      catalogFilter
    }
  }
`);

export const updateAutomationQuickstart = gql(`
  mutation updateAutomationQuickstart($automationQuickstarts: [AutomationQuickStartUpdateInput!]!) {
  updateAutomationQuickstart(automationQuickstarts: $automationQuickstarts){
      id
      productQuickStartId
      name
      icon
      priority
      catalogFilter
    }
  }
`);

export const upsertProductQuickStartLocalizedImages = gql(`
  mutation upsertProductQuickStartLocalizedImages($input: ProductQuickStartLocalizedImageUpsertInput!) {
    upsertProductQuickStartLocalizedImages(input: $input) {
      locale
      customImageUrl
      iconImageUrl
    }
  }
`);

export const deleteProductQuickStartLocalizedImages = gql(`
  mutation deleteProductQuickStartLocalizedImages($productQuickStartId: ID!, $locale: Locale!) {
    deleteProductQuickStartLocalizedImages(productQuickStartId: $productQuickStartId, locale: $locale)
  }
`);
