import { gql } from 'src/generated/gql';

export const createOrganization = gql(`
  mutation createOrganization(
    $masterToken: String!
    $createInput: CreateOrganizationInput!
  ) {
    createOrganization(masterToken: $masterToken, createInput: $createInput) {
      id
      name
      slug
      fqdn
    }
  }
`);

export const createIntegrationStripeConnectProfile = gql(`
  mutation createIntegrationStripeConnectProfile {
    createIntegrationStripeConnectProfile {
      accountId
    }
  }
`);
