import { gql } from 'src/generated/gql';

export const getMediaAsset = gql(`
  query mediaAsset($assetId: ID!) {
    mediaAsset(assetId: $assetId) {
      ... on MediaAsset {
        id
        name
        link
        status
        source
        type
      }
    }
  }
`);

export const loadGalleriesV2 = gql(`
  query LoadGalleryV2AssetsV3(
    $galleryId: ID
    $filter: AssetFilter!
    $first: Int!
    $after: String
  ) {
    gallery(galleryId: $galleryId) {
      id
      assetsv3(filter: $filter, first: $first, after: $after) {
        edges {
          cursor
          node {
            ... on MediaAsset {
              id
              galleryId
              name
              link
              status
              source
              type
              thirdPartyId
              createdAt
              isDeleted
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);

export const teamsLoadGalleriesV2 = gql(`
  query TeamsLoadGalleryV2AssetsV3(
    $galleryId: ID
    $filter: AssetFilter!
    $first: Int!
    $after: String
  ) {
    gallery(galleryId: $galleryId) {
      id
      assetsv3(filter: $filter, first: $first, after: $after) {
        edges {
          cursor
          node {
            ... on MediaAsset {
              id
              galleryId
              name
              link
              status
              source
              type
              thirdPartyId
              createdAt
              isDeleted,
              accessControl {
                permissions
              }
              teamCapabilities {
                isSharedWithTeam
                isOwnedByMe
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`);

export const giphyQuery = gql(`
  query giphySearchVideos(
    $input: GiphySearchVideosInput!
    $first: Int!
    $last: Int!
    $before: String
    $after: String
  ) {
    giphySearchVideos(input: $input) {
      query
      media(first: $first, last: $last, before: $before, after: $after) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            previewUrl
            fullSizeUrl
            site
            metadata
          }
        }
      }
    }
  }
`);

export const shutterstockQuery = gql(`
  query shutterstockSearchImages(
    $input: ShutterstockSearchImagesInput!
    $first: Int!
    $last: Int!
    $before: String
    $after: String
  ) {
    shutterstockSearchImages(input: $input) {
      query
      media(first: $first, last: $last, before: $before, after: $after) {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            previewUrl
            fullSizeUrl
            site
            metadata
            searchId
            offer {
              id
              organizationId
              billingProfileId
              source
              name
              description
              price
              platformFee
              requirePaymentMethod
            }
          }
        }
      }
    }
  }
`);
