import { gql } from 'src/generated/gql';

export const createResourceCollection = gql(`
  mutation createAudienceResourceCollection($Input: CreateResourceCollectionInput!) {
    createResourceCollection(input: $Input) {
      id
    }
  }
`);

export const createResourceItem = gql(`
  mutation createAudienceResourceItem($Input: CreateResourceItemInput!) {
    createResourceItem(input: $Input) {
      id
    }
  }
`);

export const createFacebookAudienceAccountCreationRule = gql(`
  mutation createFacebookAudienceAccountCreationRule(
    $Input: CreateFacebookAudienceAccountCreationRuleInput!
  ) {
    createFacebookAudienceAccountCreationRule(input: $Input) {
      id
      accountSelectionStrategy
      group {
        id
      }
      resourceCollection {
        id
        key
    
      }
      resourceItem {
        id
        key
      }
      userMetadataKey
      priority
    }
  }
`);

export const deleteFacebookAudienceAccountCreationRule = gql(`
  mutation DeleteFacebookAudienceAccountCreationRule($ruleId: ID!) {
    deleteFacebookAudienceAccountCreationRule(ruleId: $ruleId)
  }
`);

export const createFacebookAudienceAccountSharingRule = gql(`
  mutation createFacebookAudienceAccountSharingRule(
    $Input: CreateFacebookAudienceAccountSharingRuleInput!
  ) {
    createFacebookAudienceAccountSharingRule(input: $Input) {
      id
    }
  }
`);

export const createFacebookAudienceCreationRequestSpec = gql(`
  mutation createFacebookAudienceCreationRequestSpec(
    $input: CreateFacebookAudienceCreationRequestSpecInput!
  ) {
    createFacebookAudienceCreationRequestSpec(input: $input) {
      id
    }
  }
`);

export const addAudienceCreationRule = gql(`
  mutation AddAudienceCreationRule($input: AudienceCreationRuleInput!) {
    addAudienceCreationRule(input: $input)
  }
`);
