import { isEmpty } from 'lodash';

import { OfferType } from 'src/generated/gql/graphql';

import { dayjs, formatDate } from 'src/common/dates';
import { diffTwoObjects } from 'src/common/utilities';

import { programOrderStatusCode } from 'src/components/Status/Constants';
import { hasFacebookChannel } from 'src/common/blueprints';

import {
  programHistoryChangeTypes,
  mostRecentBudgetAdjustmentOutcomes,
  SUBSCRIPTION_TIER_CHANGE_METHODS
} from './Constants';

export const getKpiMetadata = program => {
  const kpis = program?.products[0]?.kpis || [];
  return kpis.map(k => ({ slug: k.slug, channelCode: k.channelCode }));
};

export const canMakeScheduleUpdates = ({
  isEdit,
  orderStatus,
  programStartDate,
  programEndDate,
  isSubscription
}) => {
  const today = dayjs();
  /*
    create program start date: always editable
    edit program start date: only editable if program is pending & is today or in the future

    the diff is in days so if it is less than 0 days that means its
    already started so disable editing start date
  */
  const startDateEditable =
    (!isEdit ||
      (orderStatus === programOrderStatusCode.pending &&
        dayjs(programStartDate).diff(today, 'days') > 0)) &&
    !isSubscription;

  /*
      create program end date: always editable
      edit program end date: only editable if end date is 24 hours in the future

      the diff is in hours so if it is less than 24 hours we cannot edit & the BE will
      throw an exception
      */
  const endDateEditable =
    (!isEdit || dayjs(programEndDate).diff(today, 'hours') > 24) &&
    !isSubscription;

  return {
    startDateEditable,
    endDateEditable
  };
};

export const getDemoInsights = ({
  userId,
  appSettings,
  channel,
  demoUserIds,
  demoUserData
}) => {
  const localOverrideInsights =
    appSettings?.app?.demoOverrides?.programInsights;

  let demoInsights = null;

  // Demo overrides for Demo Users.
  if (demoUserIds[userId]) {
    demoInsights = demoUserData?.programInsights;
  }

  // Always return local overrides if those are set.
  if (localOverrideInsights) {
    demoInsights = localOverrideInsights;
  }

  if (demoInsights) {
    // if demoInsights filter out insights that don't match current channel
    return demoInsights.filter(insight => {
      return insight.kpi.channelCode === channel;
    });
  }
  return demoInsights;
};

export const getProgramHistoryChangeType = (
  currentRow = {},
  previousRow = {}
) => {
  const diff = diffTwoObjects(previousRow, currentRow);

  const hasVariableValuesChanged = !!diff?.variableValues;
  const hasScheduleChanged = !!diff?.scheduleEnd || !!diff?.scheduleStart;
  const hasPriceChanged = !!diff?.priceAmount;

  const isFulfilled = diff.status === programOrderStatusCode.fulfilled;
  const isCancelled = diff.status === programOrderStatusCode.cancelled;

  const changeLog = [];

  if (isEmpty(previousRow)) {
    // placed
    return programHistoryChangeTypes.orderPlaced;
  }

  if (isCancelled) {
    // cancelled
    return programHistoryChangeTypes.cancelled;
  }

  if (isFulfilled && !hasVariableValuesChanged && !hasScheduleChanged) {
    // pending -> fulfilled
    changeLog.push(programHistoryChangeTypes.fulfilled);
  }

  if (hasScheduleChanged) {
    // schedule changed
    changeLog.push(programHistoryChangeTypes.scheduleChange);
  }

  if (hasPriceChanged) {
    // price changed
    changeLog.push(programHistoryChangeTypes.priceChange);
  }

  if (hasVariableValuesChanged) {
    // ad changed
    changeLog.push(programHistoryChangeTypes.adChanged);
  }

  return isEmpty(previousRow)
    ? programHistoryChangeTypes.unknown
    : changeLog.join(', ');
};

export const getProgramHistoryChangeColumns = text => {
  return [
    {
      field: 'updatedBy',
      headerName: text.activityHistoryTableHeaderUser,
      type: 'string',
      valueGetter: ({ value }) => {
        return value;
      },
      sortable: false,
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'change',
      headerName: text.activityHistoryTableHeaderChange,
      type: 'string',
      renderCell: ({ value }) => {
        return value;
      },
      sortable: false,
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      editable: true,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'createdAt',
      headerName: text.activityHistoryTableHeaderDateTime,
      type: 'Date',
      valueGetter: ({ value }) => {
        return formatDate({
          date: value,
          format: 'MMM DD, YYYY h:mm:ss A'
        });
      },
      sortable: false,
      width: 200,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      editable: false,
      align: 'center',
      headerAlign: 'center'
    }
  ];
};

export const formatOrderHistoryRows = (rows, hasMoreRows) => {
  return rows.reduce((accum, edge, index) => {
    const newAccum = [...accum];
    const historyItem = edge?.node;
    const pastHistoryItem = rows[index + 1]?.node;

    const isDiffCalculationRow = index === rows?.length - 1;
    // if we have more rows we don't need to show the row used to calculate the diff
    // but if we don't have more rows we need to show the last row as there are no more rows to calculate the diff
    if (hasMoreRows && isDiffCalculationRow) {
      return newAccum;
    }

    newAccum.push({
      id: historyItem?.createdAt,
      change: getProgramHistoryChangeType(historyItem, pastHistoryItem),
      updatedBy: historyItem?.updatedBy,
      createdAt: historyItem?.createdAt,
      priceAmount: historyItem?.priceAmount,
      scheduleEnd: historyItem?.scheduleEnd,
      scheduleStart: historyItem?.scheduleStart,
      status: historyItem?.status
    });

    return newAccum;
  }, []);
};

export const getIsWithin48HoursOfEndDate = endDate => {
  return dayjs(endDate).diff(dayjs(), 'hours') <= 48;
};

export const getIsUnderMinimumDuration = program => {
  const minimumDurationDays =
    program?.offer?.subscriptionMinimumDurationDays ?? 0;

  const isUnderMinimumDuration =
    // today is after the start date
    dayjs().isAfter(program?.orderItem?.scheduleStart) &&
    // today is before the minimum duration end date
    dayjs().isBefore(
      dayjs(program?.orderItem?.scheduleStart).add(minimumDurationDays, 'day')
    );

  return isUnderMinimumDuration;
};

export const getProgramStatuses = program => {
  const isExecutionRunning =
    program?.executionStatus?.status === programOrderStatusCode.running;

  const programStatus = program?.orderItem?.status;
  const isCancelled = programStatus === programOrderStatusCode.cancelled;
  const isCancelling = programStatus === programOrderStatusCode.cancelling;
  const isPending = programStatus === programOrderStatusCode.pending;
  const offerType = program?.offer?.type;
  const isSubscription = offerType === OfferType.Subscription;
  const isOneTimePurchase = offerType === OfferType.Purchase;

  return {
    isExecutionRunning,
    isCancelled,
    isCancelling,
    isPending,
    isSubscription,
    programStatus,
    isOneTimePurchase
  };
};

export const getDisableProgramCancel = program => {
  const { isExecutionRunning, isCancelled, isCancelling, isSubscription } =
    getProgramStatuses(program);
  const isUnderMinimumDuration = getIsUnderMinimumDuration(program);
  const mostRecentBudgetAdjustment =
    program?.billingDetails?.mostRecentBudgetAdjustment;

  // disable cancel when program is not active
  if (!program?.isProgramActive) {
    return true;
  }

  // disable cancel when execution is running or when there has been a budget adjustment request
  if (
    isExecutionRunning ||
    mostRecentBudgetAdjustment?.outcome ===
      mostRecentBudgetAdjustmentOutcomes.pending
  ) {
    return true;
  }

  // disable cancel when not cancelled or cancelling
  if (isCancelled || isCancelling) {
    return true;
  }

  // disable cancel when subscription and isUnderMinimumDuration
  if (isSubscription && isUnderMinimumDuration) {
    return true;
  }

  return false;
};

export const getDisableProgramEdit = program => {
  const { isExecutionRunning, isCancelled, isCancelling, isSubscription } =
    getProgramStatuses(program);
  const isfacebookChannel = hasFacebookChannel(program);
  const isUnderMinimumDuration = getIsUnderMinimumDuration(program);
  const mostRecentBudgetAdjustment =
    program?.billingDetails?.mostRecentBudgetAdjustment;

  // disable edit when program is not active
  if (!program?.isProgramActive) {
    return true;
  }

  // disable edit when execution is running or when there has been a budget adjustment request
  if (
    isExecutionRunning ||
    mostRecentBudgetAdjustment?.outcome ===
      mostRecentBudgetAdjustmentOutcomes.pending
  ) {
    return true;
  }

  // disable edit when not subscription and is cancelled or cancelling
  if (!isSubscription && (isCancelled || isCancelling)) {
    return true;
  }

  // disable edit when subscription is cancelled or cancelling and isUnderMinimumDuration
  if (isSubscription) {
    if (isUnderMinimumDuration && (isCancelled || isCancelling)) {
      return true;
    }
  }

  // don't allow facebook ads to be edited within 24 hours of end date
  const endDate = dayjs(program?.orderItem?.scheduleEnd);
  if (
    isfacebookChannel &&
    endDate.isValid() &&
    endDate.subtract(1, 'day').isBefore(dayjs())
  ) {
    return true;
  }

  return false;
};

export const getEditPermissionsFromOrder = order => {
  const {
    billingDetails,
    offer: {
      purchaseOrderAmountAdjustmentEnabled,
      purchaseScheduleAdjustmentEnabled,
      subscriptionTierChangeMethod
    }
  } = order;

  const { endDate } = billingDetails;

  const { isCancelled, isCancelling, isSubscription, isOneTimePurchase } =
    getProgramStatuses(order);

  // Applied promos should not allow editing of budget/tiers
  const hasPromoApplied = billingDetails?.promoCode != null;

  const isDisabledSubscriptionTierChange =
    subscriptionTierChangeMethod === SUBSCRIPTION_TIER_CHANGE_METHODS.disabled;

  const isWithin48HoursOfEndDate = getIsWithin48HoursOfEndDate(endDate);

  const canEditSubscriptionTier =
    isSubscription &&
    !hasPromoApplied &&
    !isDisabledSubscriptionTierChange &&
    !isCancelled &&
    !isCancelling;

  const canEditPurchaseOrderAmount =
    isOneTimePurchase &&
    !isCancelled &&
    !isCancelling &&
    !hasPromoApplied &&
    purchaseOrderAmountAdjustmentEnabled &&
    // The reason for this check is because if the user adjusts their budget
    // within 48 hours of the end date, they need to increase the end date
    // to be at least 3 days or more from the current end date
    // in this scenario they are not able to so we don't allow them to adjust the budget within
    // 48 hours of the end date
    (purchaseScheduleAdjustmentEnabled || !isWithin48HoursOfEndDate);

  const canEditPurchaseSchedule =
    isOneTimePurchase &&
    !isCancelled &&
    !isCancelling &&
    purchaseScheduleAdjustmentEnabled;

  return {
    canEditSubscriptionTier,
    canEditPurchaseOrderAmount,
    canEditPurchaseSchedule,
    isDisabledSubscriptionTierChange,
    isWithin48HoursOfEndDate
  };
};

export const editProgramInitValuesFromOrder = order => {
  const {
    name,
    orderItem: { variableValues },
    billingDetails
  } = order;

  const { offerType, startDate, endDate, billingMethod, amount, tierId } =
    billingDetails;

  const { isSubscription, isOneTimePurchase } = getProgramStatuses(order);

  const {
    canEditSubscriptionTier,
    canEditPurchaseOrderAmount,
    canEditPurchaseSchedule
  } = getEditPermissionsFromOrder(order);

  return {
    configureStep: {},
    spendStep: {
      programName: name,
      billingMethod,
      scheduleType: offerType,
      ...(canEditPurchaseOrderAmount &&
        isOneTimePurchase && {
          oneTimeSpend: amount
        }),
      ...(canEditPurchaseSchedule &&
        isOneTimePurchase && {
          startDate: dayjs(startDate),
          endDate: dayjs(endDate)
        }),
      ...(canEditSubscriptionTier &&
        isSubscription && {
          subscription: `tier:${tierId}`,
          subscriptionSpend: amount
        })
    },
    dynamicUserInputs: { ...variableValues }
  };
};
