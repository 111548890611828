import { useEffect, useRef, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { t } from 'i18next';
import { Box, Button } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import Modal from 'src/components/Modal/Modal';
import useProgram from 'src/pages/Program/utils/useProgram';
import Instrumentation from 'src/instrumentation';
import FooterValidationErrors from 'src/pages/Program/FooterValidationErrors';

import DefaultValuesForm from '../MultiLocationConfigure/DefaultValuesForm';
import { LOCATIONS_OVERRIDES_BY_ID_NAME } from './utils';
import SaveOverridesConfirmationModal from './SaveOverridesConfirmationModal';
import {
  logClickMlpRestoreLocationToDefault,
  actions
} from './amplitudeEventLoggers';

type LocationOverrideFormProps = {
  closeModal: () => void;
  open: boolean;
  id?: string;
  isChannelValidationLoading: boolean;
  selectedBusinessObjects: any;
  selectedLocationsMetadata: any;
};

const LocationOverrideForm = ({
  closeModal,
  open,
  id,
  isChannelValidationLoading,
  selectedBusinessObjects,
  selectedLocationsMetadata
}: LocationOverrideFormProps) => {
  const [saveConfirmationModalOpen, setSaveConfirmationModalOpen] =
    useState(false);

  const {
    trackingData,
    creativeValidationErrors,
    setCreativeValidationErrors
  } = useProgram();
  const { trigger, clearErrors, setValue, formState } = useFormContext();
  const programFormValues = useWatch();
  const shouldDiscard = useRef(false);
  const resetValues = useRef();
  const initialInputValues = useRef<{
    dynamicUserInputs: Record<string, any>;
    spendStep: Record<string, any>;
  }>();
  const [showRestoreToDefaults, setShowRestoreToDefaults] = useState(true);

  const currentOverrideFormSection =
    `${LOCATIONS_OVERRIDES_BY_ID_NAME}.${id}` as const;

  const onSubmit = async () => {
    try {
      const isValid = await trigger();

      if (isValid) {
        if (saveConfirmationModalOpen) {
          setSaveConfirmationModalOpen(false);
        }
        closeModal();
      }
    } catch (error) {
      // nothing to do here
    }
  };

  const hasUnsavedChanges =
    id &&
    !isEqual(initialInputValues.current, {
      dynamicUserInputs:
        programFormValues?.[LOCATIONS_OVERRIDES_BY_ID_NAME]?.[id]
          ?.dynamicUserInputs,
      spendStep:
        programFormValues?.[LOCATIONS_OVERRIDES_BY_ID_NAME]?.[id]?.spendStep
    });

  const restoreToDefaults = () => {
    if (id) {
      logClickMlpRestoreLocationToDefault({
        trackingData,
        action: actions.modal,
        locationId: id
      });

      const newValues = { ...programFormValues };
      const overrides = newValues[LOCATIONS_OVERRIDES_BY_ID_NAME];

      delete overrides[id];

      setValue(LOCATIONS_OVERRIDES_BY_ID_NAME, overrides);
      setShowRestoreToDefaults(false);
      closeModal();
    }
  };

  const handleClose = () => {
    if (id && hasUnsavedChanges && !shouldDiscard.current) {
      setSaveConfirmationModalOpen(true);
      return;
    }

    if (resetValues.current && id) {
      // we have a previous value, so we need to reset the form to that
      setValue(currentOverrideFormSection, resetValues.current);
    }

    // if the user closes the modal without saving, we need to remove the override
    if (!resetValues.current && id) {
      // we don't have a previous value, so we need to remove the override
      const newValues = { ...programFormValues };
      const overrides = newValues[LOCATIONS_OVERRIDES_BY_ID_NAME];

      delete overrides[id];

      setValue(LOCATIONS_OVERRIDES_BY_ID_NAME, overrides);
    }

    // clear the errors for this location
    clearErrors(`${LOCATIONS_OVERRIDES_BY_ID_NAME}.${id}`);
    setCreativeValidationErrors((errors: Record<string, any>) => {
      const newErrors = { ...errors };
      if (id) {
        delete newErrors[id];
      }
      return newErrors;
    });

    closeModal();
  };

  useEffect(() => {
    if (id) {
      // check if the location has overrides already
      const overrides =
        id && programFormValues?.[LOCATIONS_OVERRIDES_BY_ID_NAME]?.[id];

      setShowRestoreToDefaults(!!overrides);

      // Set initial values so that we can revert back if the user closes without saving
      resetValues.current = overrides;
      initialInputValues.current = overrides;

      if (!overrides) {
        const initialOverrideValues = {
          dynamicUserInputs: programFormValues?.dynamicUserInputs,
          spendStep: programFormValues?.spendStep
        };

        // set the initial override values for this location
        setValue(currentOverrideFormSection, initialOverrideValues);
        initialInputValues.current = initialOverrideValues;
      }
    }
  }, [id]);

  const onSaveConfirmationModalDiscard = () => {
    shouldDiscard.current = true;
    setSaveConfirmationModalOpen(false);
    handleClose();
    shouldDiscard.current = false;
  };

  const onSaveConfirmationModalClose = () => {
    setSaveConfirmationModalOpen(false);
  };

  const onSave = () => {
    Instrumentation.logEvent(
      Instrumentation.Events.ClickMlpModifyLocationConfirm,
      { ...trackingData, locationId: id }
    );

    onSubmit().catch(_ => {
      // nothing to do here
    });
  };

  const locationSpecificCreativeErrors = id
    ? creativeValidationErrors?.[id]
    : null;

  const hasErrors =
    !isEmpty(formState.errors) || !isEmpty(locationSpecificCreativeErrors);

  return (
    <Modal
      fullWidth
      maxWidth="lg"
      data-cy="mlp-location-override-modal"
      open={open}
      onClose={handleClose}
      headerText={t('programCreate:manageMultiLocation.editOverrideTitle')}
      FooterComponent={
        <Box sx={{ display: 'flex', gap: 2 }}>
          {showRestoreToDefaults && (
            <Button onClick={restoreToDefaults}>
              {t('programCreate:manageMultiLocation.editOverrideRestore')}
            </Button>
          )}
          <LoadingButton
            variant="contained"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSave}
            loading={isChannelValidationLoading}
            disabled={hasErrors}
            data-cy="save-location-override-button"
          >
            {t('programCreate:manageMultiLocation.editOverrideSave')}
          </LoadingButton>
        </Box>
      }
    >
      {id && (
        <>
          <Box sx={{ pb: 2 }}>
            <DefaultValuesForm
              hidePaymentType
              hideDuration
              locationId={id}
              selectedBusinessObjects={selectedBusinessObjects}
              selectedLocationsMetadata={selectedLocationsMetadata}
            />
          </Box>
          <FooterValidationErrors
            channelValidationErrors={locationSpecificCreativeErrors}
          />
          <SaveOverridesConfirmationModal
            open={saveConfirmationModalOpen}
            onClose={onSaveConfirmationModalClose}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSave={onSave}
            onDiscard={onSaveConfirmationModalDiscard}
            onSaveLoading={isChannelValidationLoading}
            hasErrors={hasErrors}
          />
        </>
      )}
    </Modal>
  );
};

export default LocationOverrideForm;
