import { useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@mui/material';

import { formatDate } from 'src/common/dates';

import { getAllProductVersions } from '../queries';

const pageText = () => ({
  selectLabel: t('admin:blueprintBuilder.documentVersionSelector.label'),
  currentVersionOption: t(
    'admin:blueprintBuilder.documentVersionSelector.currentOptionLabel'
  ),
  errorMessage: t('admin:blueprintBuilder.documentVersionSelector.error')
});

const DocumentVersionSelector = props => {
  const {
    productId,
    currentDocumentId,
    setSelected,
    initialValue = ''
  } = props;
  const text = useMemo(() => pageText(), []);
  const [selectedId, setSelectedId] = useState(initialValue);

  const setSelection = ({ target: { value } }) => {
    setSelectedId(value);
    setSelected(value);
  };

  const { data, error } = useQuery(getAllProductVersions, {
    skip: !productId,
    fetchPolicy: 'no-cache',
    variables: {
      productId
    }
  });

  const allDocumentVersions = useMemo(
    () => orderBy(data?.productDocumentVersions, 'versionTimestamp', 'desc'),
    [data]
  );

  return (
    <FormControl variant="outlined" sx={{ width: '455px' }} error={!!error}>
      <InputLabel id="selectDocumentVersion">{text.selectLabel}</InputLabel>
      <Select
        label={text.selectLabel}
        labelId="selectDocumentVersion"
        onChange={setSelection}
        value={selectedId || ''}
      >
        {allDocumentVersions.map(version => {
          return (
            <MenuItem key={version.id} value={version.id}>
              {currentDocumentId === version.id ? (
                text.currentVersionOption
              ) : (
                <>
                  {formatDate({
                    format: 'MMMM Do YYYY, h:mm:ss a',
                    date: version?.versionTimestamp
                  })}
                  {' - '}
                  id: {version.id}
                </>
              )}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{text.errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default DocumentVersionSelector;
