import { useMemo } from 'react';
import { clone, isEmpty } from 'lodash';
import { t } from 'i18next';

import { TextField, Typography, IconButton, Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/DeleteForever';

const styles = theme => ({
  container: {
    margin: '5px',
    height: '770px',
    width: '100%'
  },
  buttonContainer: {
    margin: `${theme.spacing(2)} 0`
  },
  emptyText: {
    margin: `${theme.spacing(1)} 0`
  },
  innerContainer: {
    alignItems: 'center',
    display: 'flex',
    margin: `${theme.spacing(1)} 0`
  },
  colon: {
    margin: `0 ${theme.spacing(1)}`
  },
  valueInput: {
    flexGrow: '1'
  },
  keyInputContainer: {
    display: 'flex',
    width: '300px'
  }
});

const pageText = () => ({
  heading: t('admin:blueprintBuilder.stepDefaultVariableValuesHeadline'),
  addButton: t('admin:blueprintBuilder.stepDefaultVariableValuesAddButton'),
  removeButton: t(
    'admin:blueprintBuilder.stepDefaultVariableValuesRemoveButton'
  ),
  emptyState: t('admin:blueprintBuilder.stepDefaultVariableValuesEmptyState')
});

const RenderDefaultVariableValues = props => {
  const { input, classes } = props;
  const text = useMemo(() => pageText(), []);

  const defaultVariables = input?.value
    ? Object.keys(input?.value).map(key => {
        return {
          key,
          value: input?.value[key]
        };
      })
    : [];

  const updateInputValue = newParameterValues => {
    input.onChange(
      newParameterValues.reduce((accum, { key, value }) => {
        const newAccum = accum;
        newAccum[key] = value;
        return newAccum;
      }, {})
    );
  };

  const handleAddParameter = () => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues.push({
      key: '',
      value: ''
    });

    updateInputValue(newParameterValues);
  };

  const handleRemoveParameter = index => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues.splice(index, 1);

    updateInputValue(newParameterValues);
  };

  const onKeyChange = (index, value) => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues[index].key = value;

    updateInputValue(newParameterValues);
  };

  const onValueChange = (index, value) => {
    const newParameterValues = clone(defaultVariables);

    newParameterValues[index].value = value;

    updateInputValue(newParameterValues);
  };

  return (
    <div className={classes.container}>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddParameter}
          startIcon={<AddIcon />}
        >
          {text.addButton}
        </Button>
      </div>
      <div>
        {isEmpty(defaultVariables) ? (
          <Typography className={classes.emptyText}>
            {text.emptyState}
          </Typography>
        ) : (
          defaultVariables.map(({ key, value }, index) => {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`default-variable-${index}`}
                className={classes.innerContainer}
              >
                <TextField
                  className={classes.keyInputContainer}
                  variant="outlined"
                  value={key}
                  onChange={({ target: { value } }) =>
                    onKeyChange(index, value)
                  }
                />

                <span className={classes.colon}>{' : '}</span>

                <TextField
                  fullWidth
                  variant="outlined"
                  value={value}
                  onChange={({ target: { value } }) =>
                    onValueChange(index, value)
                  }
                />

                <IconButton
                  onClick={() => {
                    handleRemoveParameter(index);
                  }}
                  size="large"
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(RenderDefaultVariableValues);
