import { useLazyQuery } from '@apollo/client';
import {
  AudienceCreationRule,
  getAudienceCreationRules
} from 'src/pages/Admin/AudienceTools/queries';
import { Box } from '@mui/system';
import DataTable from 'src/components/DataTable';
import { defaultAudienceToolColumnSettings } from 'src/pages/Admin/AudienceTools/constants';
import { t } from 'i18next';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { GroupedChannelIcon } from 'src/components/ChannelIcon';
import { GridColDef } from '@mui/x-data-grid';

const pageText = () => ({
  nameHeader: t('audienceTools:audienceCreationRules.tableHeader.name'),
  channelCodeHeader: t(
    'audienceTools:audienceCreationRules.tableHeader.channelCode'
  ),
  descriptionHeader: t(
    'audienceTools:audienceCreationRules.tableHeader.description'
  ),
  requestSpecHeader: t(
    'audienceTools:audienceCreationRules.tableHeader.requestSpec'
  )
});

type TableRenderCellParams<K extends keyof AudienceCreationRule> =
  GridRenderCellParams<AudienceCreationRule, AudienceCreationRule[K]>;

export const AudienceCreationRuleTable = () => {
  const text = pageText();

  const columns: GridColDef[] = [
    {
      ...defaultAudienceToolColumnSettings,
      field: 'name',
      headerName: text.nameHeader,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...defaultAudienceToolColumnSettings,
      field: 'channelCode',
      headerName: text.channelCodeHeader,
      maxWidth: 150,
      renderCell: ({ value }: TableRenderCellParams<'channelCode'>) => (
        <GroupedChannelIcon channel={value} />
      )
    },
    {
      ...defaultAudienceToolColumnSettings,
      field: 'description',
      headerName: text.descriptionHeader,
      headerAlign: 'left',
      align: 'left'
    },
    {
      ...defaultAudienceToolColumnSettings,
      field: 'requestSpec',
      headerName: text.requestSpecHeader,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ value }: TableRenderCellParams<'requestSpec'>) => (
        // We need to adjust the font-size down a little bit from our usual.
        // This is because the pre tag uses a monospace font, which is a bit
        // visually larger than our default font.
        // Even though it actually IS the same font size...
        <pre style={{ fontSize: '0.8rem' }}>
          {JSON.stringify(value, null, 2)}
        </pre>
      )
    }
  ];

  const [fetchAudienceCreationRules] = useLazyQuery(getAudienceCreationRules);

  const loadMoreRows = async () => {
    const results = await fetchAudienceCreationRules();

    return {
      rows: results.data?.audienceCreationRules as AudienceCreationRule[],
      // This endpoint is not paginated
      hasMoreRows: false
    };
  };

  return (
    <Box>
      <DataTable
        columns={columns}
        loadMoreRows={loadMoreRows}
        // Our requestSpec column is full JSON data, so it'll wrap a lot
        // and generally look bad if we don't set a dynamic row height
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
};
