/**
 * Converts a number to a string with a K suffix if it's over 1000.
 * ex: 1000 -> 1K, 10000 -> 10K, 24500 -> 24.5K
 */
export const numberToK = (num: number) => {
  if (num < 1000) {
    return num.toString();
  }
  const part1 = Math.floor(num / 1000);
  const part2 = Math.floor((num % 1000) / 100);
  if (part2 === 0) {
    return `${part1}k`;
  }
  return `${part1}.${part2}k`;
};
