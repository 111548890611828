import { connect, DefaultRootState } from 'react-redux';
import {
  getFormValues,
  reduxForm,
  isSubmitting,
  SubmitHandler
} from 'redux-form';
import { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { find, flow, isEmpty } from 'lodash';
import { t } from 'i18next';
import { useMutation, useQuery } from '@apollo/client';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';

import { Box, Button, Typography } from '@mui/material';

import { DynamicForm } from 'src/components/ReduxForm';
import {
  FacebookAudienceAccountCreationRuleModel,
  ResourceCollection
} from 'src/generated/gql/graphql';

import {
  ACCOUNT_SELECTION_STRATEGIES,
  createFacebookAudienceAccountCreationRuleInputs,
  FORM_NAME_CREATE_FB_AUDIENCE_ACCOUNT_CREATION_RULE
} from '../../constants';
import { createFacebookAudienceAccountCreationRule } from '../../mutations';
import {
  getFacebookAudienceAccountCreationRules,
  getResourceCollection,
  getResourceCollections
} from '../../queries';
import AccountCreationRulesTable from './AccountCreationRulesTable';
import {
  formatSubmissionData,
  FormValues,
  getIsEditingExistingRule
} from './helpers';

interface OwnProps {
  reset: () => void;
}

interface CreateFacebookAudienceAccountCreationRuleFormProps extends OwnProps {
  dirty: boolean;
  submitting: boolean;
  invalid: boolean;
  formValues: FormValues;
  handleSubmit: SubmitHandler;
}

const pageText = () => {
  return {
    createRuleSuccess: t(
      'createFacebookAudienceAccountCreationRuleForm:snackbar.createSuccess'
    ),
    editRuleSuccess: t(
      'createFacebookAudienceAccountCreationRuleForm:snackbar.editSuccess'
    ),
    networkError: t(
      'createFacebookAudienceAccountCreationRuleForm:snackbar.error'
    ),
    createButton: t(
      'createFacebookAudienceAccountCreationRuleForm:button.createRule'
    ),
    editRule: t('createFacebookAudienceAccountCreationRuleForm:button.editRule')
  };
};

const CreateFacebookAudienceAccountCreationRuleForm = ({
  reset,
  dirty,
  submitting,
  invalid,
  formValues,
  handleSubmit
}: CreateFacebookAudienceAccountCreationRuleFormProps) => {
  const gridApiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();
  const text = pageText();

  // Get list of resource collections for the resource collection dropdown
  const { data } = useQuery(getResourceCollections, {
    variables: {
      type: 'facebook-account-id'
    }
  });

  const resourceCollections = data?.resourceCollections || [];

  const { data: audienceAccountRules, refetch } = useQuery(
    getFacebookAudienceAccountCreationRules
  );

  // This detects if the user has entered inputs in the form that match an existing rule
  // in the table, if so, that rule's priority will be overwritten by whatever value is
  // entered into the priority form input.
  // This check allows us to display a warning to the user that they are editing an existing rule.
  const isEditingExistingRule = getIsEditingExistingRule({
    formValues,
    gridApiRef,
    audienceAccountRules:
      (audienceAccountRules?.facebookAudienceAccountCreationRules as FacebookAudienceAccountCreationRuleModel[]) ||
      []
  });

  const resourceCollectionsOptions = resourceCollections?.map(collection => ({
    name: collection?.key,
    value: collection?.id
  }));

  // Get the resource collection data if the user has selected a resource collection in the form
  const selectedCollection = find(resourceCollections, {
    id: formValues?.resourceCollectionId
  });

  // Fetch resource collection items if the user has selected a resource collection
  const { data: resourceCollectionItem } = useQuery(getResourceCollection, {
    variables: {
      type: 'facebook-account-id',
      key: (selectedCollection as ResourceCollection)?.key
    },
    skip: !selectedCollection
  });

  const resourceItemOptions =
    resourceCollectionItem?.resourceCollection?.resources?.map(resource => ({
      value: resource?.id,
      name: resource?.key
    }));

  const accountSelectionStrategy = formValues?.accountSelectionStrategy;

  const isUserMetadataStrategy =
    accountSelectionStrategy === ACCOUNT_SELECTION_STRATEGIES.USER_METADATA;

  const isGroupStrategy =
    accountSelectionStrategy === ACCOUNT_SELECTION_STRATEGIES.GROUP;

  const [createAudienceAccountCreationRule, { loading: isCreateRuleLoading }] =
    useMutation(createFacebookAudienceAccountCreationRule);

  const onSubmit = useCallback(
    async formValues => {
      const input = formatSubmissionData({ formValues });

      try {
        const response = await createAudienceAccountCreationRule({
          variables: {
            Input: input
          }
        });

        enqueueSnackbar(
          isEditingExistingRule ? text.editRuleSuccess : text.createRuleSuccess,
          {
            variant: 'success'
          }
        );

        const newRow = {
          ...response?.data?.createFacebookAudienceAccountCreationRule
        };

        if (!isEmpty(newRow)) {
          gridApiRef.current?.updateRows([newRow]);
          await refetch();
        }

        reset();
      } catch (error) {
        enqueueSnackbar(text.networkError, {
          variant: 'error'
        });
      }
    },
    [
      createAudienceAccountCreationRule,
      enqueueSnackbar,
      gridApiRef,
      isEditingExistingRule,
      refetch,
      reset,
      text.createRuleSuccess,
      text.editRuleSuccess,
      text.networkError
    ]
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}
      data-cy="create-facebook-audience-account-creation-rule-form"
    >
      <Box sx={{ maxWidth: 800 }}>
        <Typography variant="body2">
          <Trans i18nKey="audienceTools:audienceAccountCreationRules.sectionDescriptionIntro">
            This section constains rules for determining which Facebook account
            ID to use when creating an audience:
          </Trans>
        </Typography>
        <Box component="ul">
          <Box component="li">
            <Typography variant="body2">
              <Trans i18nKey="audienceTools:audienceAccountCreationRules.sectionFirstDescriptionItem">
                The top rule, which has highest priority and is accessible
                (determined by the account selection strategy) to the user
                creating the audience, will be used for determining which
                account ID to use for this audience.
              </Trans>
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body2">
              <Trans i18nKey="audienceTools:audienceAccountCreationRules.sectionSecondDescriptionItem">
                If the user creating an audience matches multiple rules, the
                rule with the highest priority (smallest value has the highest
                priority) will be used.
              </Trans>
            </Typography>
          </Box>
          <Box component="li">
            <Typography variant="body2">
              <Trans i18nKey="audienceTools:audienceAccountCreationRules.sectionThirdDescriptionItem">
                At most one Facebook audience will be created.
              </Trans>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography component="h3">
          <Trans i18nKey="audienceTools:audienceAccountCreationRules.tableTitle">
            Rules
          </Trans>
        </Typography>

        <AccountCreationRulesTable customApiRef={gridApiRef} />
      </Box>
      <Box>
        <Typography component="h3">
          <Trans i18nKey="audienceTools:audienceAccountCreationRules.formCreateTitle">
            Create A Rule
          </Trans>
        </Typography>
        {isEditingExistingRule ? (
          <Typography sx={{ color: 'warning.main' }}>
            <Trans i18nKey="audienceTools:audienceAccountCreationRules.editWarning">
              You are editing an existing rule
            </Trans>
          </Typography>
        ) : null}
        <form
          autoComplete="off"
          data-cy="audience-form-tool"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DynamicForm
            inputs={createFacebookAudienceAccountCreationRuleInputs({
              isUserMetadataStrategy,
              isGroupStrategy,
              resourceCollectionsOptions,
              resourceItemOptions: resourceItemOptions || []
            })}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!dirty || invalid || submitting || isCreateRuleLoading}
            type="submit"
          >
            {isEditingExistingRule ? text.editRule : text.createButton}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

function mapStateToProps(state: DefaultRootState) {
  const formValues = getFormValues(
    FORM_NAME_CREATE_FB_AUDIENCE_ACCOUNT_CREATION_RULE
  )(state);
  const submitting = isSubmitting(
    FORM_NAME_CREATE_FB_AUDIENCE_ACCOUNT_CREATION_RULE
  )(state);

  const initialValues = {
    accountSelectionStrategy: 'org_default'
  };

  return { formValues, initialValues, submitting };
}

export default flow(
  reduxForm({
    form: FORM_NAME_CREATE_FB_AUDIENCE_ACCOUNT_CREATION_RULE,
    enableReinitialize: true,
    destroyOnUnmount: true
  }),
  connect<any, null, OwnProps>(mapStateToProps)
)(CreateFacebookAudienceAccountCreationRuleForm);
