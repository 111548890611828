import { channelTypes } from 'src/common/adChannels';
import Loading from 'src/components/Loading';

import FacebookAdPreviewSkeleton from './FacebookAdPreview/FacebookAdPreviewSkeleton';
import TiktokAdPreviewSkeleton from './TiktokAdPreview/TiktokAdPreviewSkeleton';
import GoogleAdPreviewSkeleton from './GoogleAdPreview/GoogleAdPreviewSkeleton';

interface AdPreviewSkeletonProps {
  channel: string;
  hasUniformPreviewWidth: boolean;
  isResponsive: boolean;
}

const AdPreviewSkeleton = ({
  channel,
  hasUniformPreviewWidth,
  isResponsive
}: AdPreviewSkeletonProps) => {
  switch (channel) {
    case channelTypes.facebook:
      return (
        <FacebookAdPreviewSkeleton
          hasUniformPreviewWidth={hasUniformPreviewWidth}
          isResponsive={isResponsive}
        />
      );
    case channelTypes.tiktok:
      return (
        <TiktokAdPreviewSkeleton
          hasUniformPreviewWidth={hasUniformPreviewWidth}
          isResponsive={isResponsive}
        />
      );
    case channelTypes.google:
      return (
        <GoogleAdPreviewSkeleton
          hasUniformPreviewWidth={hasUniformPreviewWidth}
          isResponsive={isResponsive}
        />
      );
    default:
      return <Loading />;
  }
};

export default AdPreviewSkeleton;
