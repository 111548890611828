import { useState } from 'react';

const useModalState = (initialState: any = false) => {
  const [open, setOpen] = useState(initialState);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return { open, setOpen, closeModal, openModal };
};

export default useModalState;
