import { t } from 'i18next';
import { AiStyle } from 'src/generated/gql/graphql';

/**
 * Map of known styles the backend supports.
 * The values of this map MUST be sync'ed with the backend.
 * These are direct enums in the graphQL API.
 */
export const selectableStyles = {
  artistic: AiStyle.Artistic,
  practical: AiStyle.Practical,
  vibrant: AiStyle.Vibrant,
  caring: AiStyle.Caring,
  emotional: AiStyle.Emotional
} as const;

export type SelectableStyle = keyof typeof selectableStyles;

export const getAiStylizeText = () => ({
  styleDropdown: t('aiSuggestion:styleDropdown'),
  errorMessage: t('aiSuggestion:stylizationError'),
  styleOption: {
    // Using the variables directly causes this to not get extracted
    // If we drop support for `defaultValue` and place defaults in the
    // JSON, replace this block with mapping over the styles object
    artistic: t('aiSuggestion:styleOption.artistic'),
    practical: t('aiSuggestion:styleOption.practical'),
    vibrant: t('aiSuggestion:styleOption.vibrant'),
    caring: t('aiSuggestion:styleOption.caring'),
    emotional: t('aiSuggestion:styleOption.emotional')
  }
});
