import {
  validateNoAllCapWords,
  validateNoPhoneNumbers,
  validateNotEmoji,
  validateSpaceAfterPunctuation,
  validateValidGoogleSymbols,
  validateNoConsecutiveSpacesBetweenCharacters,
  validateNoEmoticons,
  validateNoCrossDomainRedirectsUrl,
  validateValidFacebookSymbols,
  validateNoRepeatPunctuationFacebook,
  validateNoRepeatPunctuationGoogle
} from 'src/common/validations';

export const VALIDATION_NAMES = {
  noRepeatPunctuation: 'noRepeatPunctuation',
  onlyValidGoogleSymbols: 'onlyValidGoogleSymbols',
  onlyValidFacebookSymbols: 'onlyValidFacebookSymbols',
  noPhoneNumbers: 'noPhoneNumbers',
  noExcessiveCapitalization: 'noExcessiveCapitalization',
  noEmojis: 'noEmojis',
  spaceAfterPunctuation: 'spaceAfterPunctuation',
  noLeadingOrTrailingSpaces: 'noLeadingOrTrailingSpaces',
  noConsecutiveSpacesBetweenCharacters: 'noConsecutiveSpacesBetweenCharacters',
  noEmoticons: 'noEmoticons',
  hostnameMaxLength: 'hostnameMaxLength',
  noCrossDomainRedirectUrl: 'noCrossDomainRedirectUrl',
  validateNoRepeatPunctuationFacebook: 'validateNoRepeatPunctuationFacebook',
  validateNoRepeatPunctuationGoogle: 'validateNoRepeatPunctuationGoogle'
} as const;

export type ValidationName =
  (typeof VALIDATION_NAMES)[keyof typeof VALIDATION_NAMES];

export const GENERIC_FACEBOOK_VALIDATIONS = {
  [VALIDATION_NAMES.onlyValidFacebookSymbols]: validateValidFacebookSymbols,
  [VALIDATION_NAMES.validateNoRepeatPunctuationFacebook]:
    validateNoRepeatPunctuationFacebook
};

export const GENERIC_GOOGLE_VALIDATIONS = {
  [VALIDATION_NAMES.validateNoRepeatPunctuationGoogle]:
    validateNoRepeatPunctuationGoogle,
  [VALIDATION_NAMES.noPhoneNumbers]: validateNoPhoneNumbers,
  [VALIDATION_NAMES.noExcessiveCapitalization]: validateNoAllCapWords,
  [VALIDATION_NAMES.noEmojis]: validateNotEmoji(),
  [VALIDATION_NAMES.spaceAfterPunctuation]: validateSpaceAfterPunctuation,
  [VALIDATION_NAMES.noConsecutiveSpacesBetweenCharacters]:
    validateNoConsecutiveSpacesBetweenCharacters,
  [VALIDATION_NAMES.noEmoticons]: validateNoEmoticons,
  [VALIDATION_NAMES.onlyValidGoogleSymbols]: validateValidGoogleSymbols
};

export const GOOGLE_VALIDATIONS = {
  default: {
    headline: {
      ...GENERIC_GOOGLE_VALIDATIONS
    },
    description: {
      ...GENERIC_GOOGLE_VALIDATIONS
    },
    longHeadline: {
      ...GENERIC_GOOGLE_VALIDATIONS
    },
    finalUrl: {
      [VALIDATION_NAMES.noCrossDomainRedirectUrl]:
        validateNoCrossDomainRedirectsUrl
    }
  }
} as const;

export const FACEBOOK_VALIDATIONS = {
  default: {
    caption: {
      ...GENERIC_FACEBOOK_VALIDATIONS
    },
    description: {
      ...GENERIC_FACEBOOK_VALIDATIONS
    },
    message: {
      ...GENERIC_FACEBOOK_VALIDATIONS
    },
    headline: {
      ...GENERIC_FACEBOOK_VALIDATIONS
    }
  }
} as const;
