import { useContext } from 'react';

import { ProgramContext } from './ProgramProvider';

const useProgram = () => {
  const context = useContext(ProgramContext);

  if (!context) {
    throw new Error('useProgram should be used inside StepperProvider');
  }

  return {
    ...context
  };
};

export default useProgram;
