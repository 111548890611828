import { INotification } from '@magicbell/react-headless';
import { isObject, isString } from 'lodash';
import Instrumentation from 'src/instrumentation';
import { Events } from 'src/instrumentation/constants';
import { History } from 'history';

enum KnownCategories {
  ProgramEnding = 'program-ending'
}

const getCustomAttributes = (notification: INotification) => {
  if (isString(notification.customAttributes)) {
    return { default: notification.customAttributes };
  }

  if (isObject(notification.customAttributes)) {
    return notification.customAttributes;
  }

  return {};
};

/**
 * Extracts custom data specific to each notification category, since they all
 * can differ.
 */
const extractCategorySpecificData = (notification: INotification) => {
  const customData = getCustomAttributes(notification);

  switch (notification.category) {
    case KnownCategories.ProgramEnding:
      return {
        programId: customData.programId,
        architectureId: customData.architectureId,
        orderId: customData.orderId
      };
    default:
      return {};
  }
};

export const logNotificationClickEvent = (
  notification: INotification,
  history: History
) => {
  Instrumentation.logEvent(Events.NotificationClick, {
    id: notification.id,
    category: notification.category,
    path: history.location.pathname,
    ...extractCategorySpecificData(notification)
  });
};
