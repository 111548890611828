import { gql } from 'src/generated/gql';

export const initiateEmailIdentityVerification = gql(`
  mutation initiateEmailIdentityVerification(
    $input: InitiateEmailIdentityInput!
  ) {
    initiateEmailIdentityVerification(
      input: $input
    ) {
      id
      domain
      mailFromSubdomain
      status
      mailFromAddress
      senderName
      dnsEntries {
        name
        type
        value
      }
    }
  }
`);
