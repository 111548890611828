import { TextField, MenuItem } from '@mui/material';
import { useController } from 'react-hook-form';
import { getOperatorsByColumn, ContentSetFieldMetadata } from './helpers';

type RenderFilterOperatorProps = {
  name: string;
  selectedColumn: ContentSetFieldMetadata;
  onChangeOperator: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    selectedColumn: ContentSetFieldMetadata
  ) => void;
};

const RenderFilterOperator = ({
  name,
  selectedColumn,
  onChangeOperator
}: RenderFilterOperatorProps) => {
  const { field } = useController({ name });
  const { value } = field;
  return (
    <TextField
      select
      value={value}
      variant="standard"
      onChange={e => {
        onChangeOperator(e, selectedColumn);
      }}
      data-cy="operator-select"
    >
      {getOperatorsByColumn(selectedColumn).map(column => (
        <MenuItem value={column?.value} key={column?.value}>
          {column?.displayName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default RenderFilterOperator;
