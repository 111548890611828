import { flow } from 'lodash';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withAppSettings } from 'src/AppSettings';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';

import { getAppName } from 'src/common/appBranding';

const styles = theme => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    textAlign: 'center',
    width: '100%'
  },
  errorIcon: {
    color: theme.palette.primary.main,
    height: '300px',
    width: '300px'
  },
  button: {
    margin: theme.spacing(1)
  }
});

const DeactivationError = ({ classes, appSettings }) => {
  const appName = getAppName(appSettings);

  const errorHeader = t('errorPage:deactivation.heading', {
    appName
  });
  const errorMessage = t('errorPage:deactivation.subHeading');

  const supportEmail = appSettings?.app?.support?.email;

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs>
        <DesktopAccessDisabledIcon className={classes.errorIcon} />
        <Typography component="h1" variant="h3">
          {errorHeader}
        </Typography>
        <br />
        <Typography component="h2" variant="h5">
          {errorMessage}
        </Typography>
      </Grid>
      <Grid item xs>
        {supportEmail && (
          <Button
            className={classes.button}
            href={`mailto:${supportEmail}?subject=Office Site Error`}
            size="large"
            variant="outlined"
          >
            <Trans i18nKey="errorPage:deactivation.contactSupport">
              Contact Support
            </Trans>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default flow(withStyles(styles), withAppSettings)(DeactivationError);
